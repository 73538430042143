import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, Input, NgModule, OnDestroy, OnInit, Optional, QueryList, TemplateRef } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { LmTemplateDirective, LmTemplateModule } from '../../directives/template.directive';
import { LmDialogContext } from '../../../model/dialog';
import { LmNotificationService } from '../../../core/services/notification.service';
import { LMLoadingModule } from '../../misc/loading/loading.component';
import { LMPanelModule } from '../panel/panel.component';
import { LmButtonConfig, LmButtonModule } from '@app/shared/structure/button/button.component';

@Component({
  selector: 'lm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class LmDialogComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @Input() visible = true;
  @Input() label: string;
  @Input() position: string = 'center';
  @Input() transitionOptions: string = '150ms cubic-bezier(0, 0, 0.2, 1)';
  @Input() closable = true;
  @Input() dismissableMask = false;
  @Input() modal = false;
  @Input() loading = false;
  @Input() style = { width: '65vw', height: 'auto', 'max-height': '85vh', display: 'flex', 'flex-direction': 'column' };
  @Input() headerTpl: TemplateRef<unknown>;
  @Input() bodyTpl: TemplateRef<unknown>;
  @Input() footerTpl: TemplateRef<unknown>;
  @Input() contentOverflowVisible = false;
  @Input() headerIconClass: string
  @Input() headerActions: LmButtonConfig[];
  @Input() headerActionsGroup: boolean;

  @ContentChildren(LmTemplateDirective) templates: QueryList<LmTemplateDirective>;

  constructor(@Optional() public ctx: LmDialogContext<unknown>, public _elRef: ElementRef, protected notificationSvc: LmNotificationService) {
    notificationSvc.setActiveDialog(this);
  }

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      switch (tpl.name) {
        case 'header':
          this.headerTpl = tpl.template;
          break;
        case 'body':
          this.bodyTpl = tpl.template;
          break;
        case 'footer':
          this.footerTpl = tpl.template;
          break;
        default:
          throw new Error(`Unsupported template type ${tpl.name}`);
      }
    });
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy(): void {
    this.notificationSvc.setActiveDialog(null);
  }

  setHeight() {
    if (this.style.height !== 'auto') return;

    const elRef = this._elRef.nativeElement;
    if (!elRef) return;


    console.log('header', elRef.getElementsByClassName('p-dialog-header'))

    const headerHeight = elRef.getElementsByClassName('p-dialog-header')?.item(0)?.scrollHeight;
    const bodyHeight = elRef.getElementsByClassName('p-dialog-content')?.item(0)?.scrollHeight;
    const footerHeight = elRef.getElementsByClassName('p-dialog-footer')?.item(0)?.scrollHeight;
    const totalHeight = headerHeight + bodyHeight + footerHeight;

    this.style = { ...this.style, height: `${totalHeight}px` } as any;
  }

  close(result?: unknown): void {
    this.visible = false;
    this.ctx?.close(result);
  }
}

@NgModule({
  imports: [CommonModule, DialogModule, LmTemplateModule, LMPanelModule, LMLoadingModule, LmButtonModule],
  declarations: [LmDialogComponent],
  exports: [LmDialogComponent, LmTemplateModule]
})
export class LMDialogModule {}
