import { CookieService } from 'ngx-cookie-service';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { NewShipmentsGridComponent } from '@app/grids/new-shipments-grid/new-shipments-grid.component';
import moment from 'moment';
import { UploadOutput, UploadInput, UploadFile, UploaderOptions, humanizeBytes } from 'ngx-uploader';
import { ImporterService } from '@app/services/importer.service';
import { MapComponent } from '@app/map/map.component';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemViewUtils } from '@app/utils/project-problem-view-utils';
import { GenericService } from '@app/services/generic.service';
import { MilyService } from '@app/services/mily.service';
import { Router } from '@angular/router';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { ComponentCanDeactivate } from '@app/pending-changes-guard/pending-changes.guard';
import { HostListener } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { ImportUtils } from '@app/utils/import-utils';

@Component({
    selector: 'app-new-shipments-view',
    templateUrl: './new-shipments-view.component.html',
    styleUrls: ['./new-shipments-view.component.scss']
})
export class NewShipmentsViewComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        if (this.isPickupRequestEnabled && !this.hasPickupRequestPerformed && this.hasUnsentVouchersInGrid) {
            return false; // false shows confirmation
        } else {
            return true; // true just leaves
        }
    }

    @ViewChild('dropdownOptionFile', { static: false }) dropdownOptionFile;
    @ViewChild('dropdownOptionWarehouse', { static: false }) dropdownOptionWarehouse;
    @ViewChild('dropdownMain', { static: false }) dropdownMain;

    @ViewChild(MapComponent, { static: false }) mapComponent: MapComponent;
    @ViewChild(NewShipmentsGridComponent, { static: false }) newShipmentsGridComponent: NewShipmentsGridComponent;
    @ViewChild('fileInput', { read: ElementRef, static: false }) fileInput: ElementRef;

    requestLimit = 10;
    loadingData = false;
    isPickupRequestEnabled = false;
    hasPickupRequestPerformed = false;
    hasUnsentVouchersInGrid = false;

    // pagesCount = 0;
    cacheBlockSize = 50;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    dropdownOpen;
    pickupLabel;
    assignDeliveriesLabel;
    foodPickupRequestsMsg;

    averageWeight = 0;
    maximumWeight = 0;
    droppedStopsCount = 0;
    limitedTimeWindowStopsCount = 0;
    defaultTimeWindow = [];

    options: UploaderOptions;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;

    listen = [];
    intervals = [];
    data;
    stopPoints = [];

    helperMsgShown = false;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private milyService: MilyService,
        public router: Router,
        private cookieService: CookieService,
        private importerService: ImporterService,
        private projectProblemDataService: ProjectProblemDataService,
        private viewProjectProblemService: ViewProjectProblemService,
        private projectProblemViewUtils: ProjectProblemViewUtils,
        private genericService: GenericService,
        private stopPointUtils: StopPointUtils,
        private importUtils: ImportUtils
    ) {
        // file options
        this.options = { concurrency: 1 };
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
        this.humanizeBytes = humanizeBytes;

        // refresh grid data
        this.listen.push(this.genericService.listenNewShipmentsGenerateGridData().subscribe(() => {
            this.generateGridData();
        }));
        this.listen.push(this.genericService.listenSubmitPartner().subscribe(() => {
            this.generateGridData();
        }));
        this.listen.push(this.genericService.listenNewShipmentsPickupButtonToggle().subscribe((makeDisabled) => {
            this.loadingData = makeDisabled;
        }));
        this.listen.push(this.viewProjectProblemService.removeStopPointFromMapListen().subscribe(id => {
            this.mapComponent.removeStopPoint(id);
        }));
    }

    // upload stop points file
    uploadStops() {
        document.getElementById('general-csv-uploader').click();
    }

    openWarehouseGrid() {
        this.viewProjectProblemService.openAddStopsModal('cancelled');
    }

    openRecipientsGrid() {
        this.viewProjectProblemService.openAddStopsModal('recipients');
    }

    onUploadOutput(output: UploadOutput): void {
        const jwt = this.cookieService.get('jwt');
        switch (output.type) {
            case 'allAddedToQueue':
                // uncomment this if you want to auto upload files when added
                const event: UploadInput = {
                    type: 'uploadAll',
                    // url: '/api/v1/import-analyzer',
                    url: '/api/v1/project/problems/' + 1 + '/import-analyzer',
                    fieldName: 'companyOrders',
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + jwt,
                        'language': this.globals.currentLang
                    }
                };
                this.uploadInput.emit(event);
                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    this.files.push(output.file);
                }
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files[index] = output.file;
                    // this.gridApi.showLoadingOverlay();
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'done':
                // handle import analyzer errors
                this.importUtils.handleAnalyzerErrors(output['file']['response']['errors']);

                // The file is uploaded
                // this.gridApi.hideOverlay();
                if (this.files) {
                    const file = this.files[this.files.length - 1];
                    const data = file.response.items;
                    if (data) {
                        const fileHash = data.csvSheets[0].csvFile;
                        const constantFieldsArray = [];
                        this.importerService.openImporterMatcher(data);
                        // this.updateGrid();
                    }
                }

                break;
        }
    }

    // pickup button
    pickup() {
        // mily message on pickup (only if not in food mode)
        if (!(this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled)) {
            this.milyService.newShipmentsPickup();
            this.milyService.openMily();
        }
        // food mode mily map feedback
        else {
            this.viewProjectProblemService.showMapMessage(this.foodPickupRequestsMsg);
        }

        // get all stop points & post
        // const selectedStopPoints = this.newShipmentsGridComponent.gridApi.getSelectedRows();
        // let selectedStopPointsIds = [];
        // selectedStopPoints.forEach(stopPoint => {
        //     selectedStopPointsIds.push(stopPoint.id);
        // });

        const selectedStopPoints = this.newShipmentsGridComponent.gridApi.getSelectedNodes();
        let selectedStopPointsIds = [];
        if (selectedStopPoints.length) {
            // create stop points array from selected rows & request only those for pickup
            selectedStopPoints.forEach(row => {
                selectedStopPointsIds.push(row.data.objData.id);
            });
        } else {
            this.stopPoints.forEach(stopPoint => {
                selectedStopPointsIds.push(stopPoint.id);
            });
        }

        const pickupObj = {
            pickup_stop_point_ids: selectedStopPointsIds
        };

        this.http.post('api/v1/partner-pickup-stop-points', pickupObj).pipe(take(1)).subscribe(() => {
            this.hasPickupRequestPerformed = true;
        });
    }

    // load a stop point to map with all its data
    loadStopToMap(currentStopPoint, bulk) {
        const self = this;
        let lat, lon, id, locationIndex, markerColour, editColours = [], enabled, priority, arrivalTime, recurring;
        let complete = false, cancelled = false, cancelledByRecipient = false, merged = false, mergedToText = '';
        let data = {};
        if (currentStopPoint) {
            // if sp is not an intermediate depot
            if (currentStopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {

                id = currentStopPoint.id;

                lat = currentStopPoint.address.lat;
                lon = currentStopPoint.address.lon;
                // if there is a be fix for sps in the same position overlapping, show the fixed lat-lon
                if (currentStopPoint.address.display_address) {
                    lat = currentStopPoint.address.display_address.lat;
                    lon = currentStopPoint.address.display_address.lon;
                }

                priority = currentStopPoint.priority === self.globals.stopPointPriorityConstants['HIGH'] ? true : false;

                if (
                    currentStopPoint.fulfillment_status === self.globals.stopPointFulfillmentStatusConstants['DROPPED'] &&
                    currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE']
                ) {
                    self.droppedStopsCount++;
                } else if (currentStopPoint.fulfillment_status === self.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
                    complete = true;
                } else if (currentStopPoint.fulfillment_status === self.globals.stopPointFulfillmentStatusConstants['CANCELED']) {
                    cancelled = true;
                    // if the stop was cancelled by the recipient, make the cancelled:false and cancelledByRecipient:true
                    if (currentStopPoint.fulfillment_events) {
                        currentStopPoint.fulfillment_events.forEach(event => {
                            if (event.reason > 799 && event.application_type === this.globals.applicationTypes['CLIENT_PORTAL_APP']) {
                                cancelled = false;
                                cancelledByRecipient = true;
                            }
                        });
                    }
                }

                editColours = [];
                recurring = false;
                if (currentStopPoint.customer) {
                    if (currentStopPoint.customer.reappears) {
                        currentStopPoint.customer.reappears.forEach(function (reappear) {
                            // if (reappear.weekday === self.projectProblemDayOfWeek && reappear.reappear) {
                            //     recurring = true;
                            // }
                        });
                    }
                }

                const stopsData = { stopPoint: currentStopPoint, relatedStopPoint: currentStopPoint.relatedStopPoint };
                const loads = this.stopPointUtils.getPickupAndDeliveryLoad(stopsData);
                const weight = loads.deliveryLoad ?? loads.pickupLoad ?? 0;

                if (lat && lon) {
                    // const stopPointSolutionData = this.projectProblemDataService.stopPointSolutionData ? this.projectProblemDataService.stopPointSolutionData : null;
                    enabled = true;
                    if (currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['DISABLED']) {
                        enabled = false;
                    }
                    let editSequenceStopPoints = null, edited = false;
                    let timeWindowState = this.calculateTimeWindowState(currentStopPoint.time_windows);
                    markerColour = '#00aeba';
                    if (currentStopPoint.error_status === this.globals.stopPointErrorStatusConstants['ERROR']) {
                        markerColour = '#666666';
                        locationIndex = null;
                        // if (timeWindowState === 'limited' || timeWindowState === 'late') { this.limitedTimeWindowStopsCount++; }
                    } else if (
                        currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE'] ||
                        currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['DISABLED']
                    ) {
                        arrivalTime = null;
                        locationIndex = null;
                        // if (stopPointSolutionData[id]) {
                        //     locationIndex = stopPointSolutionData[id].sequence;
                        //     markerColour = this.colourService.colourCalculator(stopPointSolutionData[id].routeIndex);
                        // }
                        if (currentStopPoint.solution) {
                            arrivalTime = currentStopPoint.solution.latest_estimated_arrival_datetime;
                        }
                        if (currentStopPoint.fulfillment_events) {
                            currentStopPoint.fulfillment_events.forEach(event => {
                                if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']]['AT_TIME']) {
                                    arrivalTime = event.fulfillment_datetime;
                                }
                            });
                        }
                        if (currentStopPoint.mergeData) {
                            // if stop is merged in another partner stop
                            if (currentStopPoint.mergeData.mergedStopPointId) {
                                merged = true;
                                // find partner sequence
                                // if (this.optimizationState === this.optimizationStates['OPTIMIZED']) {
                                //     const targetStopPointId = currentStopPoint.mergeData.mergedStopPointId;
                                //     if (stopPointSolutionData[targetStopPointId]) {
                                //         mergedToText = stopPointSolutionData[targetStopPointId].sequence;
                                //         markerColour = this.colourService.colourCalculator(stopPointSolutionData[targetStopPointId].routeIndex);
                                //     }
                                //     // find if partner is recurring
                                //     if (self.projectProblemDataService.stopPointIndexInArray[targetStopPointId]) {
                                //         const index = self.projectProblemDataService.stopPointIndexInArray[targetStopPointId];
                                //         const targetStopPointData = self.projectProblemDataService.stopPointsArray[index];
                                //         if (targetStopPointData.customer) {
                                //             if (targetStopPointData.customer.reappears) {
                                //                 targetStopPointData.customer.reappears.forEach(function (reappear) {
                                //                     if (reappear.weekday === self.projectProblemDayOfWeek && reappear.reappear) {
                                //                         mergedToText = 'R';
                                //                     }
                                //                 });
                                //             }
                                //         }
                                //     }
                                // }
                            }
                        }
                        // if (this.projectProblemDataService.stopPointModifications[id]) {
                        //     const modifications = this.projectProblemDataService.stopPointModifications[id];
                        //     if (modifications.routeIndex) {
                        //         modifications.routeIndex.forEach(routeIndex => {
                        //             editColours.push(this.colourService.colourCalculator(routeIndex));
                        //         });
                        //     }
                        //     if (modifications.sequence) {
                        //         if (modifications.sequence.before.length || modifications.sequence.after.length) {
                        //             editSequenceStopPoints = {
                        //                 beforeIds: [],
                        //                 afterIds: []
                        //             };
                        //             editSequenceStopPoints.beforeIds = modifications.sequence['before'];
                        //             editSequenceStopPoints.afterIds = modifications.sequence['after'];
                        //         }
                        //     }
                        // }
                        timeWindowState = this.calculateTimeWindowState(currentStopPoint.time_windows, arrivalTime);
                        if (timeWindowState === 'limited' || timeWindowState === 'late') { this.limitedTimeWindowStopsCount++; }
                    }
                    edited = (editColours.length || editSequenceStopPoints) ? true : false;

                    // stopZoomLevel is the minimum zoom level that the sp is visible
                    // 20 is the full zoom in and 0 is full zoom out
                    // so, in any level less than the max, the stop will be visible
                    // if we have stopZoomLevel=9, the stop will be visible in zoom levels 8 to 0 (far zoom out)
                    let stopZoomLevel = null;
                    if (currentStopPoint.zoom_levels) {
                        currentStopPoint.zoom_levels.forEach(zoomLevel => {
                            if (stopZoomLevel < zoomLevel.level || !stopZoomLevel) {
                                stopZoomLevel = zoomLevel.level;
                            }
                        });
                    }

                    // NOTE stop point
                    data = {
                        id: id,
                        colour: markerColour,
                        editColours: editColours,
                        editSequenceStopPoints: editSequenceStopPoints,
                        lat: lat,
                        lon: lon,
                        sequence: locationIndex,
                        enabled: enabled,
                        priority: priority,
                        overweight: this.calculateWeightState(weight),
                        timeWindowState: timeWindowState,
                        edited: edited,
                        recurring: recurring,
                        complete: complete,
                        cancelled: cancelled,
                        cancelledByRecipient: cancelledByRecipient,
                        merged: merged,
                        mergedToText: mergedToText,
                        maxZoomLevel: stopZoomLevel,
                    };

                    // markersToAdd.push(defaultMarker);
                    //   this.projectProblemDataService.stopPointsMarkerData[id] = data;

                    this.mapComponent.addStopPoint(data, bulk);
                }
            }
        }
    }

    calculateTimeWindowState(timeWindows, arrivalTime = null) {
        const self = this;
        const timeWindowDurationMinutes = [];
        let timeWindowRange, timeWindowRangeMinutes;
        let timeWindowStartOffsetFromDeparture, timeWindowStartOffsetFromDepartureMinutes;
        const timeWindowStartArray = [], timeWindowEndArray = [];

        timeWindows.forEach(function (timeWindow, index) {
            timeWindowRange = timeWindow.time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowDurationMinutes.push(timeWindowRangeMinutes);
            timeWindowStartOffsetFromDeparture = timeWindow.time_window_start_offset_from_departure;
            timeWindowStartOffsetFromDepartureMinutes = moment.duration(timeWindowStartOffsetFromDeparture).asMinutes();
            //   timeWindowStartArray.push(moment(self.projectProblemDepartureDatetime).add(timeWindowStartOffsetFromDepartureMinutes, 'minutes').format());
            timeWindowEndArray.push(moment(timeWindowStartArray[index]).add(timeWindowRangeMinutes, 'minutes').format());
        });

        if (this.defaultTimeWindow.length) {
            const minimumDurationMinutes = Math.min(...timeWindowDurationMinutes);
            const defaultTimeWindowOffsetMinutes = moment.duration(this.defaultTimeWindow[0].time_window_start_offset_from_departure).asMinutes();
            const timeWindowOffsetMinutes = moment.duration(timeWindows[0].time_window_start_offset_from_departure).asMinutes();
            const defaultTimeWindowRangeMinutes = moment.duration(this.defaultTimeWindow[0].time_window_range).asMinutes();
            const firstTimeWindowRangeMinutes = moment.duration(timeWindows[0].time_window_range).asMinutes();


            if (
                firstTimeWindowRangeMinutes >= defaultTimeWindowRangeMinutes &&
                timeWindowOffsetMinutes <= defaultTimeWindowOffsetMinutes
            ) {
                return 'default';
            } else {
                if (!this.globals.isLargeClockDisabled && arrivalTime) {
                    let timeWindowEndToArrivalMinutes = null, timeWindowStartToArrivalMinutes = null;
                    const timeWindowEndToArrivalMinutesArray = [];
                    const timeWindowStartToArrivalMinutesArray = [];
                    const arrivalTimeMoment = moment(arrivalTime);
                    timeWindowEndArray.forEach(timeWindowEnd => {
                        const timeWindowEndMoment = moment(timeWindowEnd);
                        const timeWindowEndToArrival = moment.duration(timeWindowEndMoment.diff(arrivalTimeMoment));
                        timeWindowEndToArrivalMinutesArray.push(timeWindowEndToArrival.asMinutes());
                    });
                    timeWindowStartArray.forEach(timeWindowStart => {
                        const timeWindowStartMoment = moment(timeWindowStart);
                        const timeWindowArrivalToStart = moment.duration(arrivalTimeMoment.diff(timeWindowStartMoment));
                        timeWindowStartToArrivalMinutesArray.push(timeWindowArrivalToStart.asMinutes());
                    });

                    // check if the arrival time is between tw start and end and set the difference
                    timeWindowStartToArrivalMinutesArray.forEach(function (currentTimeWindowStartToArrivalMinutes, index) {
                        const currentTimeWindowEndToArrivalMinutes = timeWindowEndToArrivalMinutesArray[index];
                        if (currentTimeWindowStartToArrivalMinutes > 0 && currentTimeWindowEndToArrivalMinutes > 0) {
                            timeWindowStartToArrivalMinutes = currentTimeWindowStartToArrivalMinutes;
                            timeWindowEndToArrivalMinutes = currentTimeWindowEndToArrivalMinutes;
                        }
                    });

                    // if the arrival time is now between start and end, the difference is not set, so set it as the closest to zero
                    if (!timeWindowStartToArrivalMinutes) {
                        timeWindowStartToArrivalMinutes = timeWindowStartToArrivalMinutesArray.reduce(function (prev, curr) {
                            // get the timeWindowEndToArrivalMinutes value closest to zero
                            return (Math.abs(curr) < Math.abs(prev) ? curr : prev);
                        });
                    }
                    if (!timeWindowEndToArrivalMinutes) {
                        timeWindowEndToArrivalMinutes = timeWindowEndToArrivalMinutesArray.reduce(function (prev, curr) {
                            // get the timeWindowEndToArrivalMinutes value closest to zero
                            return (Math.abs(curr) < Math.abs(prev) ? curr : prev);
                        });
                    }

                    if (
                        (timeWindowEndToArrivalMinutes <= 15 && timeWindowEndToArrivalMinutes >= -5) ||
                        (timeWindowStartToArrivalMinutes <= 15 && timeWindowStartToArrivalMinutes >= -5)
                    ) {
                        return 'limited';
                    } else if (timeWindowEndToArrivalMinutes < -5 || timeWindowStartToArrivalMinutes < -5) {
                        return 'late';
                    }
                } else {
                    if (minimumDurationMinutes < 3) {
                        return 'strict';
                    }
                }
                return 'regular';
            }
        }
    }

    calculateWeightState(weight) {
        if (weight >= this.maximumWeight) {
            return true;
        } else {
            return false;
        }
        // if (weight > this.averageWeight * 5) { return true; } else { return false; }
    }

    // dropdown
    toggleDropdown() {
        if (!this.dropdownOpen) {
            this.dropdownOpen = true;
            this.dropdownOptionFile.nativeElement.classList.remove('hidden');
            if (this.dropdownOptionWarehouse) {
                this.dropdownOptionWarehouse.nativeElement.classList.remove('hidden');
            }
        } else {
            this.dropdownOpen = false;
            this.dropdownOptionFile.nativeElement.classList.add('hidden');
            if (this.dropdownOptionWarehouse) {
                this.dropdownOptionWarehouse.nativeElement.classList.add('hidden');
            }
        }
    }

    openStopsForm() {
        this.viewProjectProblemService.openStopFormModal();
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    printImage() {
        this.stopPointUtils.printImageVouchers(this.stopPoints);
    }

    printAction() {
        const selectedRows = this.newShipmentsGridComponent.gridApi.getSelectedNodes();
        let selectedStopPoints = [];
        if (selectedRows.length) {
            // create stop points array from selected rows & print their vouchers
            selectedRows.forEach(row => {
                selectedStopPoints.push(row.data.objData);
            });

            this.stopPointUtils.printVouchers(this.getStopPointsToPrint(selectedStopPoints));
            this.markSelectedStopPointsAsPrinted(selectedStopPoints)
        } else {
            this.stopPointUtils.printVouchers(this.getStopPointsToPrint(this.stopPoints));
            this.markAllStopPointsAsPrinted();
        }
    }

    getStopPointsToPrint(stopPoints) {
        const stopPointsToPrint = [];
        stopPoints.forEach(stopPoint => {
            const stopPointToPrint = { stopPoint: stopPoint };
            if (stopPoint.related_stop_point_id) {
                this.stopPoints.forEach(stopPointData => {
                    if (stopPointData.id === stopPoint.related_stop_point_id) {
                        stopPointToPrint['relatedStopPoint'] = stopPointData;
                    }
                });
            }
            stopPointsToPrint.push(stopPointToPrint);
        });
        return stopPointsToPrint;
    }

    markAllStopPointsAsPrinted() {
        let markedStopPoints = [];
        this.stopPoints.forEach(stopPoint => {
            if (stopPoint.voucher) {
                let stopPointObj = {
                    stopPoint: {
                        project_problem_id: stopPoint.project_problem_id ? stopPoint.project_problem_id : null,
                        stopPointId: stopPoint['id'],
                        voucher: {
                            options: {
                                is_printed: true
                            }
                        }
                    }
                };
                markedStopPoints.push(stopPointObj);
            }

        });

        if (markedStopPoints.length) {
            // this is hard coded 1 because BE does not care
            this.http.put('api/v1/partner-stop-points/1', { stopPoints: markedStopPoints }).pipe(take(1)).subscribe(response => {
                this.generateGridData();
            });
        }
    }

    markSelectedStopPointsAsPrinted(selectedStopPoints) {
        let markedStopPoints = [];
        selectedStopPoints.forEach(stopPoint => {
            if (stopPoint.voucher) {
                let stopPointObj = {
                    stopPoint: {
                        project_problem_id: stopPoint['projectProblem'] ? stopPoint['projectProblem']['id'] : null,
                        stopPointId: stopPoint['id'],
                        voucher: {
                            options: {
                                is_printed: true
                            }
                        }
                    }
                };
                markedStopPoints.push(stopPointObj);
            }

        });

        if (markedStopPoints.length) {
            // this is hard coded 1 because BE does not care
            this.http.put('api/v1/partner-stop-points/1', { stopPoints: markedStopPoints }).pipe(take(1)).subscribe(response => {
                this.generateGridData();
            });
        }
    }

    filterNotPrintedChanged(event) {
        this.generateGridData(event.target.checked);
    }

    getData(onlyPrinted = false) {
        let url = 'api/internal/v2/partner-new-stop-points';
        url += '?searchQuery=' + this.searchString + '&pageSize=50&page=0';
        if (onlyPrinted) { url += '&isPrinted=0'; }

        return this.http.get(url);
    }

    generateGridData(onlyPrinted = false) {
        const checkbox = document.getElementById('only-printed-checkbox') as HTMLInputElement;
        if (checkbox) {
            if (checkbox.checked) { onlyPrinted = true }
        }
        // enable pickup button only if no faulty stop points are found
        this.http.get('api/v1/partner-helper-stop-points').pipe(take(1)).subscribe(response => {
            this.mapComponent.disableRightClickOnStopPoint = true;
            if (response['items']) {
                if (response['items'].length) {
                    if (!this.helperMsgShown) {
                        this.milyService.helper('importer');
                        this.helperMsgShown = true;
                    }
                } else {
                    this.isPickupRequestEnabled = true;
                    this.hasPickupRequestPerformed = false;
                }
            }
        });

        // stop points requests (begin with empty stopPoints)
        this.stopPoints = [];

        this.loadingData = true;
        document.getElementById('only-printed-checkbox').setAttribute('disabled', 'true');
        this.getData(onlyPrinted).pipe(take(1)).subscribe(response => {

            if (response['items'] && response['itemsMeta']) {
                // store page 0 stop points
                this.stopPoints = this.stopPoints.concat(response['items']['stopPoints']);
                this.newShipmentsGridComponent.setShipmentsGridData(this.stopPoints);
                let totalPages = response['itemsMeta'].pagesCount;

                if (totalPages) {
                    let endRequestCounter = 1;
                    if (totalPages > 1) {
                        for (let page = 1; page < totalPages; page++) {
                            if (page <= this.requestLimit) {
                                let url = 'api/internal/v2/partner-new-stop-points?' + `searchQuery=${this.searchString}&pageSize=50&page=${page}`;
                                if (onlyPrinted) { url += '&isPrinted=0'; }
                                this.http.get(url).pipe(take(1)).subscribe(response => {
                                    if (endRequestCounter <= totalPages) {
                                        // add stop points
                                        this.stopPoints = this.stopPoints.concat(response['items']['stopPoints']);

                                        // send all stop points to grid
                                        this.newShipmentsGridComponent.setShipmentsGridData(this.stopPoints);

                                        this.projectProblemDataService.stopPointsArray = this.stopPoints;
                                        this.projectProblemDataService.setStopPoints(this.stopPoints);

                                        endRequestCounter++;
                                    }

                                    if (endRequestCounter >= totalPages || endRequestCounter === this.requestLimit) {
                                        const self = this;
                                        const mapCheckerIntervalId = setInterval(dataChecker, 200);
                                        this.intervals.push(mapCheckerIntervalId);

                                        function dataChecker() {
                                            if (self.mapComponent.map) {
                                                clearInterval(mapCheckerIntervalId);
                                                self.loadStopsToMap();
                                            }
                                        }

                                    }
                                });
                            } else {
                                console.warn(`Maximum amount of requests reached (max. ${this.requestLimit})! Some stop points might not be loaded!`);
                                break;
                            }
                        }
                    } else {
                        // this.loadStopsToMap();
                        const self = this;
                        const mapCheckerIntervalId = setInterval(dataChecker, 200);
                        this.intervals.push(mapCheckerIntervalId);
                        function dataChecker() {
                            if (self.mapComponent.map) {
                                clearInterval(mapCheckerIntervalId);
                                self.loadStopsToMap();
                            }
                        }

                        this.newShipmentsGridComponent.setShipmentsGridData(this.stopPoints);
                        this.projectProblemDataService.stopPointsArray = this.stopPoints;
                        this.projectProblemDataService.setStopPoints(this.stopPoints);
                    }
                } else {
                    this.newShipmentsGridComponent.setShipmentsGridData(null);
                    document.getElementById('only-printed-checkbox').removeAttribute('disabled');
                }
            } else {
                this.newShipmentsGridComponent.setShipmentsGridData(null);
                document.getElementById('only-printed-checkbox').removeAttribute('disabled');
            }
            this.hasUnsentVouchersInGrid = this.newShipmentsGridComponent.hasUnsentVouchersInGrid;
        });
    }

    // get all route ids of all stops in map
    // to use them to get all routes of drivers that come for pickup
    getRouteIds() {
        const routeIds = [];
        this.stopPoints.forEach(stopPoint => {
            // TODO check if the SP is collaborator's depot
            if (stopPoint.route_id) {
                if (!routeIds.includes(stopPoint.route_id)) {
                    routeIds.push(stopPoint.route_id);
                }
            }
        });
        return routeIds;
    }

    loadStopsToMap() {
        const self = this;

        this.maximumWeight = this.projectProblemViewUtils.calculateAverageWeight();
        // create map markers
        this.stopPoints.forEach(stopPoint => {
            this.loadStopToMap(stopPoint, true);
        });

        const mapRefreshIntervalId = setInterval(mapChecker, 200);
        this.intervals.push(mapRefreshIntervalId);
        function mapChecker() {
            if (self.mapComponent.map) {
                clearInterval(mapRefreshIntervalId);
                self.mapComponent.addAllStopsToZoomLevels();
                self.mapComponent.showLevelsStopPoints();
                self.mapComponent.checkWhenDoneRendering();
            }
        }
        this.loadingData = false;
        const routeIds = this.getRouteIds();
        if (routeIds.length) {
            this.trackVehicles(routeIds);
        }
        document.getElementById('only-printed-checkbox').removeAttribute('disabled');
    }

    public getDriversData(routeIds): Observable<any> {
        const data = {
            view_mode: true,
            route_ids: routeIds
        }
        return this.http.post('api/v1/partner-drivers-new-stop-points-locations ', data);
    }

    trackVehicles(routeIds) {
        const self = this;
        let trackRequestDone = true;

        // get drivers data
        let partnerDrivers;
        self.http.get('api/v1/partner-drivers').subscribe(drivers => {
            partnerDrivers = drivers['items'];
        });

        // uncomment these lines to re-enabled vehicle location polling in new shipments view (re-enables polylines, too)
        // vehicleTracker();
        let interval;
        // interval = setInterval(vehicleTracker, 10000);

        this.intervals.push(interval);
        function vehicleTracker() {
            if (trackRequestDone) {
                trackRequestDone = false;
                self.getDriversData(routeIds).subscribe(
                    response => {
                        self.mapComponent.mapDummyComponent.collaboratorDriverBoxes.nativeElement.innerHTML = ''; // remove boxes before re-inserting them in the dom
                        trackRequestDone = true;

                        if (response) {
                            self.mapComponent.emptyDriverPickupRoutesGroup();
                            const routes = response['items']['routes'];
                            const driversObject = self.getDriversDataObject(response['items'].drivers);
                            const vehiclesObject = self.getVehiclesDataObject(response['items'].vehicles);
                            if (routes) {
                                routes.forEach((route, index) => {
                                    let companyImage;
                                    let driverIndex;
                                    let driverId = route.driver_id;
                                    if (partnerDrivers.length) {
                                        driverIndex = partnerDrivers.findIndex(driver => {
                                            return driver.driver.id === driverId;
                                        });

                                        self.globals.partnersArray.forEach(partner => {
                                            if (response['items'].drivers[driverIndex].driver_data.driver.company_id == partner.id) {
                                                companyImage = partner.logo;
                                            }
                                        });
                                    }

                                    const driverInfo = driversObject[driverId];

                                    let vehicleId = route.vehicle_id;
                                    const vehicleInfo = vehiclesObject[vehicleId];

                                    let estimatedArrival = '-';
                                    if (route.atTime) {
                                        estimatedArrival = moment(route.atTime).format('HH:mm');
                                    }

                                    if (route.solution !== 'null') {
                                        // driver image
                                        let driverImage = {
                                            base64: null
                                        };
                                        if (partnerDrivers.length) {
                                            if (partnerDrivers[driverIndex].driverImage['base64']) {
                                                driverImage.base64 = partnerDrivers[driverIndex].driverImage['base64']
                                            } else {
                                                driverImage.base64 = self.globals.driverDefaultBase64
                                            }
                                        }

                                        const data = {
                                            colour: '#00aeba',
                                            dateTime: estimatedArrival,
                                            companyImage: companyImage ? companyImage.base64 : null,
                                            driverName: driverInfo.userProfile.name,
                                            // driverImage: driverInfo.driverImage,
                                            driverImage: driverImage,
                                            plateNumber: '',
                                            vehicleId: vehicleInfo.vehicle.id,
                                            vehicleType: vehicleInfo.vehicle.type,
                                            isStatic: route.is_static,
                                            angle: route.angle,
                                            estimatedArrival: estimatedArrival
                                        }
                                        self.mapComponent.addDriverRoute(route.solution, data);
                                    }
                                });
                            }
                        } else {
                            clearInterval(interval);
                        }
                    },
                    error => {
                        console.error(error);
                        clearInterval(interval);
                    }
                );
            }
        }
    }

    getDriversDataObject(driversArray: Array<any>) {
        const drivers = {};
        driversArray.forEach(driver => {
            drivers[driver.driver_data.driver.id] = driver.driver_data;
        });
        return drivers;
    }

    getVehiclesDataObject(vehiclesArray: Array<any>) {
        const vehicles = {};
        vehiclesArray.forEach(vehicle => {
            vehicles[vehicle.vehicle.id] = vehicle;
        });
        return vehicles;
    }

    getTranslations() {
        this.listen.push(this.translate.get('PARTNER_SHIPMENTS.PICKUP').subscribe((res: string) => { this.pickupLabel = res; }));
        this.listen.push(this.translate.get('PARTNER_SHIPMENTS.ASSIGN_DELIVERIES').subscribe((res: string) => { this.assignDeliveriesLabel = res; }));
        this.listen.push(this.translate.get('PARTNER_SHIPMENTS.FOOD_REQUEST_PICKUP_MSG').subscribe((res: string) => { this.foodPickupRequestsMsg = res; }));
    }

    ngOnDestroy() {
        this.intervals.forEach(interval => {
            clearInterval(interval);
        });
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

    ngAfterViewInit() {
        this.fileInput.nativeElement.onclick = (event) => { event.target.value = null; };
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.generateGridData();

        // search
        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.generateGridData();
            });
    }
}
