import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TermsModalComponent } from '@app/modals/terms-modal/terms-modal.component';
import { PrivacyModalComponent } from '@app/modals/privacy-modal/privacy-modal.component';
import { PreloadStopPointsService } from '@app/services/preload-stop-points.service';
import { take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild(PrivacyModalComponent, { static: false }) privacyModalComponent: PrivacyModalComponent;
    @ViewChild(TermsModalComponent, { static: false }) termsModalComponent: TermsModalComponent;

    listen = [];

    myForm: FormGroup;
    forgotPasswordForm: FormGroup;
    username: String = '';
    password: String = '';
    isClickedOnce = false;
    isClickedOnceForgotPass = false;
    applicationType = '';
    rememberMe = false;
    partnerToken = null;

    alertMessage = '';

    recentEmail = '';
    emailSentOriginalMessage = '';
    emailSentMessage = '';
    emailReSendMessage = '';
    countDownSeconds;
    resendMailIntervalSeconds = 10;
    resendEnabled = true;
    counterIntervalId;

    isInMobile = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        formBuilder: FormBuilder,
        public globals: Globals,
        public translate: TranslateService,
        private cookieService: CookieService,
        public preloadStopPointsService: PreloadStopPointsService,
    ) {
        this.myForm = formBuilder.group({
            'userAccount': formBuilder.group({
                'username': [this.username],
                'password': [this.password],
                'partner_token': [this.partnerToken]
            }),
            'rememberMe': [this.rememberMe],
            'applicationType': ['web-app'],
            'provider': [0],
        });
        this.forgotPasswordForm = formBuilder.group({
            'mail': [this.username, Validators.required],
        });
    }

    openForgotPasswordForm() {
        this.forgotPasswordForm.patchValue({
            'mail': this.myForm.value.userAccount.username
        });
        M.updateTextFields();
        document.getElementById('forgot-password-screen').classList.remove('hidden');
    }

    closeForgotPasswordForm() {
        document.getElementById('forgot-password-screen').classList.add('hidden');
    }

    enableResendMail() {
        const self = this;
        self.resendEnabled = false;
        let seconds = this.resendMailIntervalSeconds;
        self.counterIntervalId = setInterval(function () {
            self.countDownSeconds = seconds;
            if (--seconds < 0) {
                clearInterval(self.counterIntervalId);
                if (document.getElementById('resend-email')) {
                    document.getElementById('resend-email').classList.remove('disabled');
                }
                self.resendEnabled = true;
            }
        }, 1000);
    }

    resendEmail() {
        const resendButton = document.getElementById('resend-email');
        const myObserver = {
            next: (response) => { },
            error: (error) => {
                console.error(error);
            },
            complete: () => { },
        };
        if (this.resendEnabled) {
            this.http.post('api/v1/account/register/forgot-password', { mail: this.recentEmail }).pipe(take(1)).subscribe(myObserver);
            resendButton.classList.add('disabled');
            this.enableResendMail();
        }
    }

    forgotPassword() {
        const myObserver = {
            next: (response) => {
                document.getElementById('resend-email').classList.add('disabled');
                this.enableResendMail();
                this.emailSentMessage = this.emailSentOriginalMessage.replace('EMAIL', this.recentEmail);
                document.getElementById('forgot-password-details').classList.remove('no-visibility');
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {
            },
        };
        if (this.forgotPasswordForm.value.mail) {
            this.recentEmail = this.forgotPasswordForm.value.mail;
            this.isClickedOnceForgotPass = true;
            this.http.post('api/v1/account/register/forgot-password', this.forgotPasswordForm.value).pipe(take(1)).subscribe(myObserver);
        }
    }

    underDevelopmentAlert() {
        alert("It's under development and will soon be available!\r\nThank you!");
    }

    public loginToApi() {
        this.isClickedOnce = true;
        const myObserver = {
            next: (response) => {
                console.log('response:', response)
                const domain = window.location.hostname;
                if (this.myForm.value.rememberMe) {
                    this.cookieService.set('jwt', response['items']['accessToken'], 365, '/', domain, true, 'Strict');
                    this.cookieService.set('csrfToken', response['items']['csrfToken'], 365, '/', 'localhost', true, 'Strict');
                } else {
                    this.cookieService.delete('jwt', '/', domain, true, 'Strict');
                    this.cookieService.delete('csrfToken', '/', domain, true, 'Strict');
                    this.cookieService.set('jwt', response['items']['accessToken'], 0, '/', domain, false, 'Strict');
                    this.cookieService.set('csrfToken', response['items']['csrfToken'], 0, '/', domain, true, 'Strict');
                }

                // Google Analytics User ID
                window['dataLayer'] = window['dataLayer'] || [];
                window['dataLayer'].push({
                    'event': 'Login',
                    'user_id': response['items']['userProfile']['company_id']
                });
            },
            error: (error) => {
                console.error(error);
                this.isClickedOnce = false;
            },
            complete: () => {
                this.globals.load().then(() => {
                    if(this.globals.companyIsPudo) return this.router.navigate(['']);
                    
                    // if the demo was not initialized (the user never selected wizard or demo), let the globals navigate you to the choices screen
                    if (this.globals.demoState !== this.globals.companyDemoStateConstants['NOT_INITIALIZED']) {
                        this.preloadStopPointsService.loadPossibleProjectProblemsStopPoints();
                        // if(!this.globals.companyIsPudo) this.preloadStopPointsService.loadPossibleProjectProblemsStopPoints();
                        this.router.navigate(['']);
                    }
                    
                });
                this.isClickedOnce = false;
            },
        };
        // debugger;
        this.http.post('api/account/session', this.myForm.value).pipe(take(1)).subscribe(myObserver);
        
    }

    openPrivacyModal() {
        this.privacyModalComponent.openPrivacyModal();
    }

    openTermsModal() {
        this.termsModalComponent.openTermsModal();
    }

    getTranslations() {
        this.listen.push(this.translate.get('LOGIN.MAIL_SENT').subscribe((res: string) => { this.emailSentOriginalMessage = res; }));
        this.listen.push(this.translate.get('LOGIN.MAIL_RE_SEND').subscribe((res: string) => { this.emailReSendMessage = res; }));
        this.listen.push(this.translate.get('LOGIN.ALERT').subscribe((res: string) => { this.alertMessage = res; }));
    }

    ngOnInit() {
        // Google Analytics
        window['dataLayer'] = window['dataLayer'] || {};

        if (window.screen.width < 500 || window.screen.height < 500) { // catch portrait and landscape
            this.isInMobile = true;
        }

        // store hashToken
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            if (params['partnerToken']) {
                this.partnerToken = params['partnerToken'];
                this.myForm.patchValue({
                    'userAccount': {
                        'partner_token': this.partnerToken
                    }
                });
            }
        }));

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
        if (this.counterIntervalId) {
            clearInterval(this.counterIntervalId);
        }
    }

}
