import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';

import { LmLoggerService } from '../logger.service';
import { LmMemoryCacheService } from './memory-cache.service';

@Injectable()
export class LmSelectorCacheService extends LmMemoryCacheService {
  private _keys: string[] = [];
  private _emittedKeys: string[] = [];

  constructor(private _loggerSvc: LmLoggerService) {
    super();
  }

  getOrAdd(key: string, cacheCb: () => Observable<any>): ReplaySubject<any> {
    if (this.storage.has(key)) {
      return this.storage.get(key);
    }

    this._keys.push(key);
    const obs = new ReplaySubject(1);
    this.storage.set(key, obs);

    cacheCb().subscribe(
      (data) => {
        obs.next(data);
        this._emittedKeys.push(key);
      },
      (error) => {
        obs.error(error);
        this._loggerSvc.logError(`Selector failed to getData for ${key}. Error: ${error}`);
      }
    );
    return this.storage.get(key);
  }

  getValue(key: string): Observable<any> {
    let obs$: ReplaySubject<any>;

    if (this.storage.has(key)) {
      obs$ = this.storage.get(key) as ReplaySubject<any>;
    } else {
      this._keys.push(key);
      obs$ = new ReplaySubject(1);
      this.storage.set(key, obs$);
    }

    return obs$;
  }

  set(key: string, value: any): Observable<boolean> {
    let obs$: ReplaySubject<any>;

    if (this.storage.has(key)) {
      obs$ = this.storage.get(key) as ReplaySubject<any>;
    } else {
      this._keys.push(key);
      obs$ = new ReplaySubject(1);
      this.storage.set(key, obs$);
    }
    obs$.next(value);
    this._emittedKeys.push(key);

    return of(true);
  }

  remove(key: string): Observable<boolean> {
    const itemIdx = this._keys.findIndex((p) => p === key);
    if (itemIdx >= 0) {
      this.storage.delete(this._keys[itemIdx]);
      this._keys.splice(itemIdx, 1);
      return of(true);
    }
  }

  cacheContainsKey(key: string): boolean {
    return this._keys.some((p) => p === key);
  }

  hasEmittedValue(key: string): boolean {
    return this._emittedKeys.some((p) => p === key);
  }
}
