import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DriversModalGridComponent } from '@app/grids/drivers-modal-grid/drivers-modal-grid.component';
import { StopsModalGridComponent } from '@app/grids/stops-modal-grid/stops-modal-grid.component';
import { GenericService } from '@app/services/generic.service';
import { Globals } from '@app/services/globals';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { ProjectProblemModalComponent } from '../project-problem-modal.component';

@Component({
  selector: 'app-project-problem-modal-dummy',
  templateUrl: './project-problem-modal-dummy.component.html',
  styleUrls: ['./project-problem-modal-dummy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectProblemModalDummyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() projectProblemData: ProjectProblemData;
  @Input() uploadInput: EventEmitter<UploadInput>;
  @Input() options: UploaderOptions;
  @Output() openModalFromButtonsEvent = new EventEmitter<string>();
  @Output() stopsCountEvent = new EventEmitter<string>();
  @Output() driversCountEvent = new EventEmitter<string>();
  @Output() openStopFormEvent = new EventEmitter<string>();
  @Output() togglePlusButtonOptionsEvent = new EventEmitter<string>();
  @Output() plusNewShipmentEvent = new EventEmitter<string>();
  @Output() plusFromCustomersEvent = new EventEmitter<string>();
  @Output() searchEvent = new EventEmitter<string>();
  @Output() closeEvent = new EventEmitter<string>();
  @Output() openStopPointFormEvent = new EventEmitter<string>();
  @Output() plusImportEvent = new EventEmitter<string>();
  @Output() onUploadOutputEvent = new EventEmitter<string>();
  @Output() requestForPickupEvent = new EventEmitter<string>();

  @ViewChild(DriversModalGridComponent, { static: false }) driversModalGridComponent: DriversModalGridComponent;
  @ViewChild(StopsModalGridComponent, { static: false }) stopsModalGridComponent: StopsModalGridComponent;
  @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;
  @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
  @ViewChild('stopsGridButton', { static: false, read: ElementRef }) stopsGridButton: ElementRef;
  @ViewChild('driversGridButton', { static: false, read: ElementRef }) driversGridButton: ElementRef;
  @ViewChild('addNewShipmentButton', { static: false, read: ElementRef }) addNewShipmentButton: ElementRef;
  @ViewChild('generalSearch', { static: false, read: ElementRef }) generalSearch: ElementRef;
  @ViewChild('stopsGrid', { static: false, read: ElementRef }) stopsGrid: ElementRef;
  @ViewChild('driversGrid', { static: false, read: ElementRef }) driversGrid: ElementRef;

  constructor(
    public globals: Globals,
    public router: Router,
    public genericService: GenericService,
    public projectProblemDataService: ProjectProblemDataService
  ) { }

  showModalButtons() {
    this.stopsGridButton.nativeElement.classList.add('shown');
    this.driversGridButton.nativeElement.classList.add('shown');
    this.generalSearch.nativeElement.classList.add('shown');
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      this.addNewShipmentButton.nativeElement.classList.add('shown');
    }
  }

  closeModal() {
    this.closeEvent.emit('');
  }

  openStopPointFormListen(event) {
    this.openStopPointFormEvent.emit(event);
  }

  setStopPointsGridCount(event) {
    this.stopsCountEvent.emit(String(event));
  }

  setDriversGridCount(event) {
    this.driversCountEvent.emit(String(event));
  }

  search(event) {
    this.searchEvent.emit(event);
  }

  openModalFromButtons(step) {
    this.openModalFromButtonsEvent.emit(step);
  }

  togglePlusButtonOptions() {
    this.togglePlusButtonOptionsEvent.emit();
  }

  plusNewShipment() {
    this.plusNewShipmentEvent.emit();
  }

  plusFromCustomers() {
    this.plusFromCustomersEvent.emit();
  }

  plusImport() {
    this.plusImportEvent.emit();
  }

  onUploadOutput(event) {
    this.onUploadOutputEvent.emit(event);
  }

  requestForPickup() {
    this.requestForPickupEvent.emit();
  }

  openStopFormButtonPressed(event) {
    this.openStopFormEvent.emit(event);
  }

  ngAfterViewInit() { }

  ngOnInit() { }

  ngOnDestroy() { }

}

// interface
export interface ProjectProblemData {
  projectProblemId: Number;
  stopPointsGridCount: Number,
  driversGridCount: Number,
  modalOpen: Boolean,
  searchString: String,
  buttonName: String,
}