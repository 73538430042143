import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const LM_VALIDATOR_PROVIDERS = new InjectionToken<LMValidatorProvider[]>('LMValidatorProvider');
export const SERVER_RESPONSE_VALIDATOR_NAME = '$$Server$$';

export interface LMValidatorProvider {
  getValidators(): LMValidatorInfo[];
}

export interface LMValidatorInfo {
  propertyPath: string;
  isNotice?: boolean;
  validationsFn: (model: any, target: any) => LMValidationMessage[] | Observable<LMValidationMessage[]>;
}

export enum LmValidationMessageType {
  Error,
  Warning,
  Information
}

export class LMValidationMessage {
  messageType: LmValidationMessageType;
  message: string;
}

export interface LMValidatorInfoResult {
  propertyPath: string;
  isNotice?: boolean;
  validationMessages: LMValidationMessage[];
}
