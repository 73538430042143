import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { GridsService } from '@app/services/grids.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { CollaboratorService } from '@app/services/collaborator.service';
import { Globals } from '@app/services/globals';
import QRCode from 'easyqrcodejs';

@Component({
    selector: 'app-customer-invoice-print',
    templateUrl: './customer-invoice-print.component.html',
    styleUrls: ['./customer-invoice-print.component.scss']
})
export class CustomerInvoicePrintComponent implements OnInit, OnDestroy {

    listen = [];

    receiptMode = false;

    companyName;
    companyAddress;
    companyWebsite;
    companyTaxId;
    companyPhone;
    companyTaxOffice;
    companyEmail;
    logoBase64 = '';
    logoSrc = 'data:image/png;base64,LOGO_HASH';
    qrLink;

    tax = 0.24;
    surchargeTax = 0.24;
    total = 0;
    subtotal = 0;
    taxTotal = 0;
    discountAmount = 0;
    mergedTaxesTotalCharge = 0; // used for merge same % VATs

    // titles
    currency;
    itemsTitle = 'Items';
    quantityTitle = 'Quantity';
    priceTitle = 'Price';
    amountTitle = 'Amount';
    courierServicesTitle = 'Courier services';

    // grid settings
    gridApi;
    gridColumnApi;
    domLayout = 'autoHeight';
    itemsDataArray = [];
    rowData: any;
    columnDefs;

    receiptData = {};

    // print values
    invoiceNumber;
    createdDate;
    dueOnDate;
    leftAmount;

    invoiceData;
    collaboratorData;

    // collaborator data/info
    collaboratorName;
    collaboratorAddress;
    collaboratorTaxId;
    collaboratorTaxOffice;
    collaboratorEmail;
    collaboratorJobDescription;

    invoiceNotesHtml = '';

    // partner details
    partnerName;
    partnerAddress;
    partnerTaxId;
    partnerTaxOffice;
    partnerWebsite;

    // invoice grid titles
    servicesTitle;
    addonChargesTitle;
    addonServicesTitle;
    shipmentsTitle;
    totalAmountTitle;
    serviceTitle = '';

    useAlternativeGrid;

    taxes = [];
    constructor(
        public gridsService: GridsService,
        public translate: TranslateService,
        public router: Router,
        private collaboratorService: CollaboratorService,
        public globals: Globals
    ) {
        // this.listenUpdateCustomerGrid = this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
        //     // this.pagesCount = 0;
        //     // this.gridApi.purgeServerSideCache([]);
        // });
    }

    setCompanyData(companyData) {
        if (companyData) {
            this.companyName = companyData.name;
            this.companyAddress = companyData.address_text;
            this.companyWebsite = companyData.website;
            this.companyTaxId = companyData.tin;
            this.companyPhone = companyData.telephone;
            this.companyTaxOffice = companyData.tax_office;
            this.companyEmail = companyData.email;
            this.invoiceNotesHtml = '';
            if (companyData.invoice_notes) {
                this.invoiceNotesHtml = '<div>';
                this.invoiceNotesHtml += (companyData.invoice_notes).replace(/\r?\n/g, '</div><div>');
                this.invoiceNotesHtml += '</div>';
            }

            if (companyData.companyLogo) {
                if (companyData.companyLogo['base64']) {
                    this.logoBase64 = this.logoSrc.replace('LOGO_HASH', companyData.companyLogo['base64']);
                }
            }
            // in partners request, the logo is logo['base64'] instead of companyLogo['base64']
            if (companyData.logo) {
                if (companyData.logo['base64']) {
                    this.logoBase64 = this.logoSrc.replace('LOGO_HASH', companyData.logo['base64']);
                }
            }
        }
    }

    setCollaboratorData(company) {
        if (company) {
            if (company.collaboratorData) {
                this.collaboratorName = company.collaboratorData.collaborator_name;
                this.collaboratorAddress = company.address.value;
                this.collaboratorTaxId = company.collaboratorData.tin;
                this.collaboratorTaxOffice = company.collaboratorData.tax_office;
                this.collaboratorEmail = company.collaboratorData.mail;
                this.collaboratorJobDescription = company.collaboratorData.job_description;
            } else {
                this.collaboratorName = company.name;
                this.collaboratorAddress = company.address_text;
                this.collaboratorTaxId = company.tin;
                this.collaboratorTaxOffice = company.tax_office;
                this.collaboratorEmail = company.email;
                this.collaboratorJobDescription = company.job_description;
            }
        }
    }

    // sets print component values
    setPrintData(data) {
        this.invoiceNumber = this.collaboratorService.generateIdWithZeros(data.id);
        this.createdDate = moment(data.creation_datetime).format('MMMM DD, YYYY');
        if (this.receiptMode) { this.createdDate = moment(data.creation_datetime).format('DD/MM/YYYY HH:mm'); }

        if (data.due_on_datetime) {
            this.dueOnDate = moment(data.due_on_datetime).format('MMMM DD, YYYY');
        }

        if (data.amount) {
            this.leftAmount = data.amount.toFixed(2);
        }
    }

    // calculate total
    setInvoicesGridData(data) {
        // use alternative grid, if not detail categories are found
        if (data['invoice_detailed_categories']) {
            if (data['invoice_detailed_categories'].length) {
                this.useAlternativeGrid = true;
                this.taxes = data['taxes'];
            } else {
                this.useAlternativeGrid = false;
            }
        } else {
            this.useAlternativeGrid = false;
        }

        let gridObject = {};
        this.itemsDataArray = [];

        // invoice data
        if (this.globals.isInRoute('invoicePrint') || 
        this.globals.isInRoute('collaboratorView') || 
        this.globals.isInRoute('collaboratorPartnerView') || 
        this.globals.isInRoute('partnerView') || 
        this.globals.isInRoute('customerCollaborators') ||
        this.globals.isInRoute('collaboratorInvoices')) {
            this.discountAmount = 0;
            this.subtotal = 0;
            data['invoice_detailed_categories'].forEach(category => {
                // match basic voucher service id w/ its name from globals
                let servicePrefix;
                this.globals.companyBasicVoucherServices.forEach(service => {
                    if (service['id'] == category['basic_voucher_service_id']) {
                        servicePrefix = service['name'];
                    }
                });

                if (category['discount_amount']) {
                    this.discountAmount += Number(category['discount_amount']);
                }

                if (category['net_price']) {
                    this.subtotal += Number(category['net_price']);
                }

                gridObject = {
                    service: `${category['invoice_category_name']}` ?? this.serviceTitle,
                    shipment: category['count'],
                    total: category['net_price'].toFixed(2) + this.currency
                };

                this.itemsDataArray.push(gridObject);
            });

            this.discountAmount = Number(this.discountAmount.toFixed(2));
            this.subtotal = Number(this.subtotal.toFixed(2));
            this.total = Number(data.amount.toFixed(2));
            this.getLinkAndGenerateQr(data['my_data_invoice_link']);
            this.initGrid();
        }
        // receipt data
        else {
            const netPrice = data.net_price ?? 0;
            
            gridObject = {
                name: this.courierServicesTitle,
                quantity: 1,
                price: netPrice.toFixed(2),
                amount: netPrice.toFixed(2),
            };
            this.itemsDataArray.push(gridObject);

            // if addon services price exists, then add it to grid
            if (data.addon_services) {
                gridObject = {
                    name: this.addonServicesTitle,
                    quantity: 1,
                    price: data.addon_services.toFixed(2),
                    amount: data.addon_services.toFixed(2)
                };
                this.itemsDataArray.push(gridObject);
            }
            // if add on charges price exists, then add it to grid
            if (data.addon_charges) {
                gridObject = {
                    name: this.addonChargesTitle,
                    quantity: 1,
                    price: data.addon_charges.toFixed(2),
                    amount: data.addon_charges.toFixed(2)
                };
                this.itemsDataArray.push(gridObject);
            }

            this.receiptData = JSON.parse(localStorage.getItem('receiptData'));

            // load general subtotal (is used in both receipt & invoice)
            this.subtotal = this.receiptData['net_price'];

            // this.calculateSum(data);
        }

        this.rowData = of(this.itemsDataArray);
    }

    getLinkAndGenerateQr(link) {
        if (link) {
            document.getElementById('qr-invoice-container').innerHTML = '';
            this.qrLink = link;
            const options = {
                text: link,
                // logo: 'assets/lastmilyAssets/logo.png',
                quietZone: 20,
                colorLight: '#ffffff',
            };
            new QRCode(document.getElementById('qr-invoice-container'), options).resize(150, 150);
        }
    }

    onFirstDataRendered(params) { }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    initGrid() {
        // timeout is required because useAlternativeGrid hasn't been set yet
        setTimeout(() => {
            // create grid columns
            if (this.globals.isInRoute('receiptPrint')) {
                this.columnDefs = [
                    { headerName: this.itemsTitle, field: 'name', width: this.gridsService.widthCalculatorContainerId(45, 'customer-invoices-print-container') },
                    { headerName: this.quantityTitle, field: 'quantity', width: this.gridsService.widthCalculatorContainerId(20, 'customer-invoices-print-container') },
                    { headerName: this.priceTitle, field: 'price', width: this.gridsService.widthCalculatorContainerId(25, 'customer-invoices-print-container') },
                    { headerName: this.amountTitle, field: 'amount', width: this.gridsService.widthCalculatorContainerId(35, 'customer-invoices-print-container') },
                ];
            } else {
                if ((this.globals.isInRoute('invoicePrint') || this.globals.isInRoute('collaboratorView') || this.globals.isInRoute('partnerView') || this.globals.isInRoute('collaboratorPartnerView') || this.globals.isInRoute('customerCollaborators')) && this.useAlternativeGrid) {
                    // invoice grid columns
                    this.columnDefs = [
                        { headerName: this.servicesTitle, field: 'service', width: this.gridsService.widthCalculatorContainerId(60, 'customer-invoices-alternative-print-container') },
                        { headerName: this.shipmentsTitle, field: 'shipment', width: this.gridsService.widthCalculatorContainerId(20, 'customer-invoices-alternative-print-container') },
                        { headerName: this.totalAmountTitle, field: 'total', width: this.gridsService.widthCalculatorContainerId(20, 'customer-invoices-alternative-print-container') },
                    ];
                } else {
                    this.columnDefs = [
                        { headerName: this.itemsTitle, field: 'name' },
                        { headerName: this.quantityTitle, field: 'quantity' },
                        { headerName: this.priceTitle, field: 'price' },
                        { headerName: this.amountTitle, field: 'amount' },
                    ];
                }
            }
        }, 1000);
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ITEMS').subscribe((res: string) => { this.itemsTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.QUANTITY').subscribe((res: string) => { this.quantityTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PRICE').subscribe((res: string) => { this.priceTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SERVICES').subscribe((res: string) => { this.servicesTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ADD_ON_CHARGES').subscribe((res: string) => { this.addonChargesTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ADD_ON_SERVICES').subscribe((res: string) => { this.addonServicesTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SHIPMENTS').subscribe((res: string) => { this.shipmentsTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.TOTAL_AMOUNT').subscribe((res: string) => { this.totalAmountTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SERVICE').subscribe((res: string) => { this.serviceTitle = res; }));

        this.initGrid();
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
