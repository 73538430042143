import countries from 'i18n-iso-countries';
import enCountriesJson from "i18n-iso-countries/langs/en.json";
import elCountriesJson from "i18n-iso-countries/langs/el.json";
import deCountriesJson from "i18n-iso-countries/langs/de.json";
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { MilyService } from '@app/services/mily.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { Component, OnInit, ViewChild, Injectable, OnDestroy, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ExtraOptions } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { StopsModalGridComponent } from '@app/grids/stops-modal-grid/stops-modal-grid.component';
import * as moment from 'moment-timezone';
import { Observable, Subject, concat, of, merge, scheduled, isObservable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, mergeAll, finalize, count, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { AddressService } from '@app/services/address.service';
import { Globals } from '@app/services/globals';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { CollaboratorMapComponent } from './collaborator-map/collaborator-map.component';
import { RecipientMapComponent } from './recipient-map/recipient-map.component';
import { StopPointService } from '@app/services/stop-point.service';
import { GenericService } from '@app/services/generic.service';
import QRCode from 'easyqrcodejs';
import { ModalService } from '@app/services/modal.service';
import * as libphonenumber from 'libphonenumber-js';
import * as Sentry from "@sentry/angular";
import { DepotUtils } from '@app/utils/depot-utils';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';
import { LmNotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-voucher-form',
  templateUrl: './voucher-form.component.html',
  // styleUrls: ['./voucher-form.component.scss']
  styleUrls: ['./voucher-form.component.scss', '../../../styles/base/verona-form-migration.scss']
})

@Injectable()
export class VoucherFormComponent implements OnInit, OnDestroy {

  @ViewChild(StopsModalGridComponent, { static: false }) stopsModalGridComponent: StopsModalGridComponent;
  @ViewChild('stopPointNgSelect', { static: false }) stopPointNgSelect: NgSelectComponent;
  @ViewChild('collaboratorAddressNgSelect', { static: false }) collaboratorAddressNgSelect: NgSelectComponent;
  @ViewChild('collaboratorNameNgSelect', { static: false }) collaboratorNameNgSelect: NgSelectComponent;
  @ViewChild('recipientNameNgSelect', { static: false }) recipientNameNgSelect: NgSelectComponent;
  @ViewChild('consignorNameNgSelect', { static: false }) consignorNameNgSelect: NgSelectComponent;
  @ViewChild(CollaboratorMapComponent, { static: false }) collaboratorMapComponent: CollaboratorMapComponent;
  @ViewChild(RecipientMapComponent, { static: false }) recipientMapComponent: RecipientMapComponent;
  @Output() toggleModal = new EventEmitter<string>();

  loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  myForm: FormGroup;
  errors = [];
  data;
  isClickedOnce = false;
  priceListsRequestPending = false;
  priceRequestPending = false;
  isWaitingForUpsAddress = false;
  priceListsRequest;
  priceRequest;
  projectProblemId = null;
  pickupProjectProblemId = null;
  projectProblemIdForRequest = null;
  projectProblemEntityStatus;
  projectProblemDepartureDatetime;
  projectProblemDayOfWeek;
  stopPointId = null;
  companyId = null;
  address = null;
  consignorTypeRadioButtonSelected = 1;
  thirdPartyCheckboxSelected;
  isThroughHub = false;
  sameAsConsignorCheckboxSelected;
  serviceTypeSelected = 'both';
  noDeliverySelected = false;
  serviceType = 1;
  consignorType = 1;
  transactionDocumentType = this.globals.transactionDocumentTypes['RECEIPT'];
  isSignatureMandatory = false;
  isUpsAddressEnabled = false;
  isUpsAddressValid = false;
  shipmentContents = '';
  totalPackageValue;
  shipmentContentsDescription;
  chargeTaxTo = this.globals.voucherUpsChargeToConstants['CHARGE_TAX_TO_RECEIVER'];
  upsAddressLine;
  upsCity;
  upsZipCode;
  upsCountry;
  contactName = '';
  supplier = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  recipientEmail = '';
  pickupTelephone = '';
  pickupPhoneNumber = '';
  pickupCountryPrefix = '';
  doubleTimeWindow = false;
  selectedTimeWindow = null;
  selectedTimeWindowId = null;
  customTimeWindows = [];
  timeWindows = [];
  pickupTimeWindows = [];
  defaultTimeWindow = [480, 1200];
  timeWindow = [480, 1200];
  timeWindowDouble = [480, 840, 1020, 1200];
  pickupTimeWindow = [480, 1200];
  defaultTimeWindowUnformatted = ['08:00', '20:00'];
  timeWindowUnformatted = ['08:00', '20:00'];
  pickupTimeWindowUnformatted = ['08:00', '20:00'];
  durationUnformatted = '02:00';
  pickupDurationUnformatted = '02:00';
  durationSeconds = [120];
  pickupDurationSeconds = [120];
  duration = '';
  pickupDuration = '';
  defaultLoad = 0;
  deliveryLoad = null;
  pickupLoad = null;
  deliverySourceModelName = '';
  deliverySourceModelId = null;
  pickupDestinationModelName = '';
  pickupDestinationModelId = null;
  smartPoint;
  isPaymentEditable = true;
  final = false;
  notes = '';
  payAmount = '';
  priority = '';
  priorityOn = false;
  pickupPriorityOn = false;
  pickupPriority = '';
  customerEntityType = null;
  addressTerm: String = '';
  email = '';
  timeZone = '';
  portalAccess = 0;
  portalOn = false;
  pickupPortalOn = false;
  pickupPortalAccess = 0;
  portalPlus = false;
  previousAgreedShipping = {};
  agreedShipping = [];
  agreedShippingDate = '';
  agreedShippingDateChanged = false;
  fulfillmentStatus = null;
  fulfillmentEvent = {};
  relatedCustomerId = null;
  timeWindowOptions;
  timeWindowOptionsDouble;
  timeWindowIds = [];
  pickupTimeWindowIds = [];
  durationOptions;
  positionSet = false;
  customInput;
  listen = [];
  selectedDepotItem;
  selectedPartnerItem;
  warehouseId;
  depots = [];
  partners = [];
  timeWindowErrorMsg;
  barcode = '';
  pickupBarcode = '';
  deliveryBarcode = '';
  loaded = false;
  selectedDrivers = [];
  drivers = [];
  foodDepots = [];
  selectedFoodDepot = this.globals.depotsWithNamesArray[0];
  recipientAndCollaboratorLatestResults = [];
  currency;

  collaboratorSectionLabel = '';
  stopPointLabel = '';
  collaboratorTitleLabel = '';

  collaboratorLabel = '';
  consignorLabel = '';
  customerLabel = '';
  receiverLabel = '';
  assignorLabel = '';
  creditCardLabel = '';
  payOnDeliveryLabel = '';
  cashLabel = '';
  bankDepositLabel = '';
  creditText = '';
  receiptText = '';
  wrongUpsAddressError;
  podSmartPointWarning;
  priceListNotFoundLabel;
  chequeLabel: string;
  chequeDayLabel: string;
  inCreditLabel: string;

  sameDayDelivery = false;
  chargesEnabled = false;

  inWarehouse = false;
  inGeneralWarehouse = false;

  massAndDimensions = [];

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();

  collaboratorAddresses: Observable<any>;
  collaboratorAddressesLoading = false;
  collaboratorAddressInput = new Subject<string>();

  collaboratorNames: Observable<any>;
  collaboratorNamesLoading = false;
  collaboratorNameInput = new Subject<string>();
  selectedCollaborator;

  recipientNames: Observable<any>;
  recipientNamesLoading = false;
  recipientNameInput = new Subject<string>();
  selectedRecipient;

  consignorNames: Observable<any>;
  consignorNamesLoading = false;
  consignorNameInput = new Subject<string>();
  selectedConsignor;

  isPaidByEntityCollaborator: boolean;

  freeformAddress = '';
  lat = '';
  lon = '';

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';

  selectedAddress: any = <any>[];

  collaboratorFreeformAddress = '';
  collaboratorLat = '';
  collaboratorLon = '';

  collaboratorCountryCode = '';
  collaboratorState = '';
  collaboratorCounty = '';
  collaboratorCity = '';
  collaboratorDistrict = '';
  collaboratorStreet = '';
  collaboratorStreet2 = '';
  collaboratorHouseNumber = '';
  collaboratorPostalCode = '';
  collaboratorIsPlace = false;
  collaboratorPlaceName = '';

  addressFrom = {};

  collaboratorSelectedAddress: any = <any>[];

  collaboratorId = null;
  collaboratorType = '';
  collaboratorName = '';
  recipientName = '';
  recipientId;
  consignorName = '';
  consignorId;
  collaboratorCountryPrefix = '';
  collaboratorPhoneNumber = '';
  collaboratorTelephone = [];

  collaboratorsArray = [];
  collaboratorNormal;
  collaboratorReceiver;
  collaboratorConsignor;
  collaboratorFetchedNormal;
  collaboratorFetchedReceiver;
  collaboratorFetchedConsignor;
  collaboratorNormalAddress;
  collaboratorReceiverAddress;
  collaboratorConsignorAddress;
  collaboratorNormalVoucherId;
  collaboratorReceiverVoucherId;
  collaboratorConsignorVoucherId;

  temporaryAssignor;

  fetchedCollaboratorsData = [];

  hasManuallyAlteredTimeWindows = false;

  noNameLabel = '';
  returnLabel = '';
  deliverToNoteLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';
  taxPercentageLabel = '';
  changeUnavailableEditTitle;
  taxPercentageHintLabel = '';
  invalidUpsZoneError;

  selectedService;
  defaultService;
  basicServicesOptions = [];
  shipmentTypeOptions = [];
  shipmentServiceType = null;
  surcharges = [];
  priceLists = [];
  priceListsData = {};

  countryDropdownOptions = [];
  selectedPriceListId = null;
  systemicCourierName = null;
  addonServicesForSelectedPriceList = [];
  companyServices = [];
  addonCharges = [];
  savedSurcharges = null;
  savedServices = null;
  basicVoucherServiceId = null;
  discount = 0;
  shipmentType = null;
  insurance = false;
  insuranceAmount = null;
  hasManuallyAlteredDiscount = false;
  hash = '';
  payer = 1;
  paidBy = null;
  requestedPayOnDeliveryPaymentMethod = null;
  executedPayOnDeliveryPaymentMethod = null;
  chequeCollectionDatePayOnDelivery;
  requestedPaymentMethodCourierFare = null;
  executedPayOnDeliveryPaymentMethodCourierFare = null;
  chequeCollectionDateCourierFare;
  paymentOptions = [];
  transactionDocumentTypeOptions = [];
  chargeDetails = {
    net_price: 0,
    discount_amount: 0,
    addon_services: 0,
    addon_charges: 0,
    vat: 0,
    total: 0
  }
  servicesOptions = [];
  chargesOptions = [];
  basicVoucherServiceOptions = [];
  chargeOptions = [];
  upsShipmentTypeOptions = [];
  chargeTaxOptions = [];
  volumeCategoryOptions = [];
  recipientStartPrefixDays = 0;
  pickupStartPrefixDays = 0;
  barcodes = [];
  isBarcodesListOpen = false;

  // shipment type labels
  shipmentTypeLabel1;
  shipmentTypeLabel2;
  shipmentTypeLabel3;
  shipmentTypeLabel4;
  shipmentTypeLabel5;
  shipmentTypeLabel6;
  shipmentTypeLabel7;
  shipmentTypeLabel8;

  // charge type labels
  chargeTypeLabel1;
  chargeTypeLabel2;

  chargesChangedLabel;
  serviceChangedLabel;
  stopPointSavedLabel;
  
  onlyPickupMsg = '';
  onlyDeliveryMsg = '';
  expressMsg;
  throughHubMsg;
  nextDayMsg;

  load;
  weight;

  deliveryToSave;
  pickupToSave;

  hasPriceListManuallyAltered = false; // overrides the price lists returned from 'charges' request with the user-selected price list
  isUserStillChangingWeight; // used to trigger changes for volume when changing the weight if volume was empty

  items = [];

  public stopFormLabels = {
    phone: 'Phone'
  };

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public router: Router,
    private dataService: DataService,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    private addressService: AddressService,
    private formBuilder: FormBuilder,
    public globals: Globals,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private stopPointService: StopPointService,
    private genericService: GenericService,
    private modalGridService: ModalGridService,
    public modalService: ModalService,
    private milyService: MilyService,
    private stopPointUtils: StopPointUtils,
    private depotUtils: DepotUtils,
    private formErrorsUtils: FormErrorsUtils,
    private _notificationSvc: LmNotificationService,
  ) {
    // get latest search data
    this.listen.push(this.genericService.listenStoreLatestFromSearchInVoucher().subscribe((data) => {
      this.recipientAndCollaboratorLatestResults = data;
    }));

    // get submitted date for day cheque
    this.listen.push(this.modalService.submitDateListen().subscribe((data) => {
      if (data.customMode == 'podChequeDay') {
        this.chequeCollectionDatePayOnDelivery = moment(data.date).format('YYYY-MM-DD');
        this.myForm.patchValue({
          'stopPoint': {
            'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery
          }
        });
      } else if (data.customMode == 'fareChequeDay') {
        this.chequeCollectionDateCourierFare = moment(data.date).format('YYYY-MM-DD');
        this.myForm.patchValue({
          'stopPoint': {
            'cheque_collection_date_courier_fare': this.chequeCollectionDateCourierFare
          }
        });
      }

      M.updateTextFields();
    }));

    countries.registerLocale(enCountriesJson);
    countries.registerLocale(elCountriesJson);
    countries.registerLocale(deCountriesJson);

    this.initializeSlider();
    this.collaboratorType = String(this.globals.voucherPayerConstants['COMPANY']);
    if (this.globals.foodModeEnabled && this.globals.collaboratorModeEnabled) {
      this.serviceTypeSelected = 'sameDay';
      this.selectedService = this.basicServicesOptions.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.Express.key)) ?? this.basicServicesOptions[0];
    }

    this.myForm = formBuilder.group({
      'company_partner': [this.selectedPartnerItem],
      'stopPoint': formBuilder.group({
        'id': [this.stopPointId],
        'noVoucher': false,
        'stopPointId': [this.stopPointId],
        'project_problem_id': [this.projectProblemIdForRequest],
        'service_type': [this.serviceType],
        'service': [this.selectedService],
        'consignorTypeRadioButtonSelected': [this.consignorTypeRadioButtonSelected],
        'no_delivery': [this.noDeliverySelected],
        'contact_name': [this.contactName],
        'supplier': [this.supplier],
        'temporary_assignor': [this.temporaryAssignor],
        'selectedRecipient': [this.selectedRecipient],
        'pay_amount': [this.payAmount],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'telephone': [this.telephone],
        'recipient_email': [this.recipientEmail],
        'pickup_telephone': [this.pickupTelephone],
        'pickupCountryPrefix': [this.pickupCountryPrefix],
        'pickupPhoneNumber': [this.pickupPhoneNumber],
        'companyTimeWindow': [this.selectedTimeWindow],
        'companyTimeWindowCategoryId': [this.selectedTimeWindowId],
        'doubleTimeWindow': [this.doubleTimeWindow],
        'time_windows': [this.timeWindows],
        'time_window_unformatted': [this.timeWindow],
        'time_window_unformatted2': [this.timeWindowDouble],
        'pickup_time_window_unformatted': [this.pickupTimeWindow],
        'duration_seconds': [this.durationSeconds],
        'duration': [this.duration],
        'pickup_duration_seconds': [this.pickupDurationSeconds],
        'final': [this.final],
        'customerEntityType': [this.customerEntityType],
        'relatedCustomerId': [this.relatedCustomerId],
        'note': [this.notes],
        'priority': [this.priority],
        'priorityOn': [this.priorityOn],
        'pickupPriorityOn': [this.pickupPriorityOn],
        'depot': [{ value: this.selectedDepotItem, disabled: false }], // disabled in pp & on edit
        'warehouse_id': [this.warehouseId],
        'portal_access': [this.portalAccess],
        'portalOn': [this.portalOn],
        'pickupPortalOn': [this.pickupPortalOn],
        'portalPlus': [this.portalPlus],
        'agreedShippingDate': [this.agreedShippingDate],
        'agreed_shipping': [this.agreedShipping],
        'fulfillment_status': [this.fulfillmentStatus],
        'fulfillment_event': [this.fulfillmentEvent],
        'barcode': [this.barcode],
        'loaded': [this.loaded],
        'selectedDrivers': [{ value: this.selectedDrivers, disabled: this.stopPointId ? false : true }],
        'selectedFoodDepot': [this.selectedFoodDepot],
        'volume_category': [null],
        'is_signature_mandatory': [this.isSignatureMandatory],
        'is_ups_address_enabled': [this.isUpsAddressEnabled],
        'requested_pay_on_delivery_payment_method': [this.requestedPayOnDeliveryPaymentMethod],
        'executed_pay_on_delivery_payment_method': [this.executedPayOnDeliveryPaymentMethod],
        'cheque_collection_date_pay_on_delivery': [this.chequeCollectionDatePayOnDelivery],
        'requested_payment_method_courier_fare': [this.requestedPaymentMethodCourierFare],
        'executed_payment_method_courier_fare': [this.executedPayOnDeliveryPaymentMethodCourierFare],
        'cheque_collection_date_courier_fare': [this.chequeCollectionDateCourierFare],
        'shipment_type': [this.shipmentType],
        'shipment_service_type': [this.shipmentServiceType],
        'deliverySources': formBuilder.group({
          'modelName': [this.deliverySourceModelName],
          'modelId': [this.deliverySourceModelId],
        }),
        'pickupDestinations': formBuilder.group({
          'modelName': [this.pickupDestinationModelName],
          'modelId': [this.pickupDestinationModelId],
        }),
        'address': formBuilder.group({
          'countryCode': [this.countryCode],
          'state': [this.state],
          'county': [this.county],
          'city': [this.city],
          'district': [this.district],
          'street': [this.street],
          'street2': [this.street2],
          'houseNumber': [this.houseNumber],
          'postalCode': [this.postalCode],
          'isPlace': [this.isPlace],
          'placeName': [this.placeName],
          'value': [this.freeformAddress, Validators.required],
          'lat': [this.lat],
          'lon': [this.lon],
          'term': [this.selectedAddress],
        }),
        'mass_and_dimensions': [this.massAndDimensions],
        'mass_and_dimensions_data': formBuilder.array([]),
        'voucher': formBuilder.group({
          'company_voucher_collaborators': [this.collaboratorsArray],
          'options': formBuilder.group({
            'basic_voucher_service_id': [this.basicVoucherServiceId],
            'discount': [this.discount],
            'hash': [this.hash],
            'payer': [this.payer],
            'paid_by': [this.paidBy],
            'addon_services': [this.companyServices],
            'addon_charges': [this.addonCharges],
            'price_list_id': [this.selectedPriceListId],
            'insurance': [this.insurance],
            'insurance_amount': [this.insuranceAmount],
            'collaborator_type': [this.consignorType],
            'transaction_document_type': [this.transactionDocumentType],
            'address_from': [this.addressFrom],
            'voucher_ups_extra_fields': formBuilder.group({
              'shipment_contents': [this.shipmentContents],
              'total_package_value': [this.totalPackageValue],
              'shipment_contents_description': [this.shipmentContentsDescription],
              'charge_tax_to': [this.chargeTaxTo],
              'address_line': [this.upsAddressLine],
              'city': [this.upsCity],
              'zip_code': [this.upsZipCode],
              'country_code_iso3': [this.upsCountry]
            })
          }),
        }),
        'load': [this.load],
        'weight': [this.weight],
        'collaborator': formBuilder.group({
          'collaboratorData': formBuilder.group({
            'id': [this.collaboratorId],
            'collaborator_name': [this.collaboratorName],
            'consignor_name': [this.consignorName],
            'consignor_id': [this.consignorId],
            'countryPrefix': [this.collaboratorCountryPrefix],
            'phoneNumber': [this.collaboratorPhoneNumber],
            'telephone': [this.collaboratorTelephone],
            'collaborator_type': [this.collaboratorType],
            'selectedCollaborator': [this.selectedCollaborator],
            'selectedConsignor': [this.selectedConsignor],
            'thirdPartyCheckboxSelected': [this.thirdPartyCheckboxSelected],
            'isThroughHub': [this.isThroughHub],
            'sameAsConsignorCheckboxSelected': [this.sameAsConsignorCheckboxSelected]
          }),
          'collaborator_address': formBuilder.group({
            'id': null,
            'countryCode': [this.collaboratorCountryCode],
            'state': [this.collaboratorState],
            'county': [this.collaboratorCounty],
            'city': [this.collaboratorCity],
            'district': [this.collaboratorDistrict],
            'street': [this.collaboratorStreet],
            'street2': [this.collaboratorStreet2],
            'houseNumber': [this.collaboratorHouseNumber],
            'postalCode': [this.collaboratorPostalCode],
            'isPlace': [this.collaboratorIsPlace],
            'placeName': [this.collaboratorPlaceName],
            'value': [this.collaboratorFreeformAddress, Validators.required],
            'lat': [this.collaboratorLat],
            'lon': [this.collaboratorLon],
            'term': [this.collaboratorSelectedAddress],
          }),
        }),
      }),
      'customer': formBuilder.group({
        'email': [this.email],
        'timeZone': [this.timeZone],
        'portal_access': [this.portalAccess],
      }),
    });
    this.addItemToVoucher(null);
  }

  setBarcodesListVisibility(isOpen) {
    this.isBarcodesListOpen = isOpen;
  }

  openStopForm() {
    this.toggleModal.emit('close');
    setTimeout(() => {
      this.viewProjectProblemService.openStopFormModal(true);
    }, 1000);
  }

  createStopFromMapClick(data) {
    if (!data.forSameDayDelivery) {
      if (!data.helperOpen) {
        this.positionSet = true;
        this.lat = data.lat;
        this.lon = data.lng;
        this.isClickedOnce = true;
        this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
          this.isClickedOnce = false;
          // ups company address handle
          if (this.globals.isUpsCompany) {
            if (location['data']['addresses']['items']) {
              if (location['data']['addresses']['items'].length) {
                // ups address fields
                this.upsAddressLine = `${location['data']['addresses']['items'][0]['address']['street']}`;
                if (location['data']['addresses']['items'][0]['address']['houseNumber']) {
                  this.upsAddressLine += ` ${location['data']['addresses']['items'][0]['address']['houseNumber']}`;
                }
                this.upsZipCode = `${location['data']['addresses']['items'][0]['address']['postalCode']}`;
                this.upsCity = `${location['data']['addresses']['items'][0]['address']['city']}`;
                this.upsCountry = countries.toAlpha2(location['data']['addresses']['items'][0]['address']['countryCode']);

                // set normal (HERE) recipient address
                this.setAddress(location['data']['addresses']['items'][0]['address']);

                setTimeout(() => {
                  if (document.getElementById('recipient-address-custom-input')) {
                    (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
                  }
                  this.recipientMapComponent.showDraggableMarker(Number(data.lat), Number(data.lng));
                }, 200);

                this.myForm.patchValue({
                  'stopPoint': {
                    'voucher': {
                      'options': {
                        'voucher_ups_extra_fields': {
                          'address_line': this.upsAddressLine,
                          'city': this.upsCity,
                          'zip_code': this.upsZipCode,
                          'country_code_iso3': this.upsCountry
                        }
                      }
                    }
                  }
                })
              }
            }
          }
          // non-ups company address handle
          else {
            if (location['data']['addresses']['items']) {
              if (location['data']['addresses']['items'].length) {
                this.setAddress(location['data']['addresses']['items'][0]['address']);
              } else {
                this.freeformAddress = 'Address';
              }
            }

            setTimeout(() => {
              if (document.getElementById('recipient-address-custom-input')) {
                (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
              }
              this.recipientMapComponent.showDraggableMarker(Number(data.lat), Number(data.lng));
            }, 200);
          }
        });
        this.getPriceLists();
      }
    }
  }

  removeMassAndDimensionsItem(massAndDimensionIndex) {
    this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].removeAt(massAndDimensionIndex);
    this.items.splice(massAndDimensionIndex, 1);
    document.getElementById('mass-and-dimensions-item-' + massAndDimensionIndex).remove();

    this.weightsAndLoadsCompare();
  }

  discountChanged(event) {
    const keyPressed = event.data;
    if (keyPressed !== '-') {
      this.discount = Number(this.myForm.value.stopPoint.voucher.options.discount);
      this.hasManuallyAlteredDiscount = true;
    } else {
      this.discount = 0;
      this.myForm.patchValue({
        'stopPoint': {
          'voucher': {
            'options': {
              'discount': this.discount
            },
          },
        },
      });
    }

    this.calculatePrice();
  }

  getSelectedConsignor() {
    let selectedConsignor;
    // selected shipper
    if (this.myForm.value.stopPoint.collaborator.collaboratorData.selectedConsignor) {
      selectedConsignor = this.myForm.value.stopPoint.collaborator.collaboratorData.selectedConsignor;
    } else if (this.collaboratorConsignor) {
      selectedConsignor = { companyCollaborator: { collaborator: { collaboratorData: this.collaboratorConsignor } } };
    }
    return selectedConsignor;
  }

  getSelectedSenderCollaborator() {
    let selectedCollaborator;
    if (this.myForm.value.stopPoint.collaborator.collaboratorData.selectedCollaborator) {
      selectedCollaborator = this.myForm.value.stopPoint.collaborator.collaboratorData.selectedCollaborator;
    } else if (this.collaboratorNormal) {
      selectedCollaborator = { companyCollaborator: { collaborator: { collaboratorData: this.collaboratorNormal } } };
    }
    return selectedCollaborator;
  }

  getChargedEntity() {
    let chargedEntity = null;
    const selectedConsignor = this.getSelectedConsignor();
    const selectedCollaborator = this.getSelectedSenderCollaborator();
    const selectedRecipient = this.myForm.value.stopPoint.selectedRecipient;
    const paidByType = this.myForm.value.stopPoint.voucher.options.paid_by;

    // send the charged collaborator id based on who is being charged (if there isn't a collaborator being charged, then collaboratorId is null)
    if (paidByType == this.globals.stopPointCollaboratorTypesConstants['SENDER'] && selectedCollaborator?.companyCollaborator) {
      chargedEntity = selectedCollaborator;
    } else if (paidByType == this.globals.stopPointCollaboratorTypesConstants['RECEIVER'] && selectedRecipient?.companyCollaborator) {
      chargedEntity = selectedRecipient;
    } else if (paidByType == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'] && selectedConsignor) {
      chargedEntity = selectedConsignor;
    }
    return chargedEntity;
  }

  // calculates the amount to charge for the extra charges (location and mass and dimensions) and adds it to the voucher price
  getPriceLists(isLoadSpecificPriceList = false, isExpectingSystemic = false) {
    let chargeUrl = '';
    let data;
    const stopPointData = this.myForm.value.stopPoint;
    const voucherOptions = stopPointData.voucher.options;
    const senderAddress = this.getSenderAddress();
    const recipientAddress = this.getRecipientAddress();
    this.calculateShipmentType();

    // data object & url for new shipments' voucher form
    if (this.globals.collaboratorModeEnabled || this.router.url.includes('newShipmentsView')) {
      // chargeUrl = 'api/v1/partner-charges';
      chargeUrl = 'api/v1/partner-price-lists-list';
      data = {
        charge: {
          service_id: stopPointData.service.id,
          partner_courier_id: this.selectedPartnerItem['id'],
          shipment_type: this.shipmentType,
          stop_point: {
            weight: Number(this.myForm.value.stopPoint.weight),
            load: Number(this.myForm.value.stopPoint.load),
            mass_and_dimensions: this.getMassAndDimensions(),
            address: recipientAddress,
          },
          collaborator: {
            id: this.getChargedEntity()?.companyCollaborator.collaborator.collaboratorData.id,
            address: senderAddress,
          },
          company_partner: {
            id: this.selectedPartnerItem['id'],
          }
        }
      };
    }
    // data object & url for general voucher form
    else {
      // charge settings
      // only collaborators have charges 
      // (if customer is selected in the field that is being referred from paid_by, then the charges request returns generic price lists)
      // (address is always the pickup address)
      // selected sender

      // request settings
      chargeUrl = 'api/v1/price-lists-list';

      data = {
        charge: {
          service_id: stopPointData.service.id,
          shipment_type: this.shipmentType,
          stop_point: {
            weight: Number(this.myForm.value.stopPoint.weight),
            load: Number(this.myForm.value.stopPoint.load),
            mass_and_dimensions: this.getMassAndDimensions(),
            address: recipientAddress,
          },
          collaborator: {
            id: this.getChargedEntity()?.companyCollaborator.collaborator.collaboratorData.id,
            address: senderAddress,
          },
        }
      };
    }

    // whenever charges are re-calculated, check if the entity that 'paid_by' points to.
    // if that entity is a collaborator, then set the courier fare's payment method to null, disable the field & set its label to "In credit"
    this.isPaidByEntityCollaborator = this.checkCourierFareCollaborator();    

    // get the charges for the current voucher
    // make sure only one charges request is running at a time (always keep the most recent)!
    this.priceListsRequestPending = true;
    if (this.priceListsRequest) {
      this.priceListsRequest.unsubscribe();
    }

    if (recipientAddress.lat && recipientAddress.lon && senderAddress.lat && senderAddress.lon) {
      this.priceListsRequest = this.http.post(chargeUrl, data).pipe(take(1)).subscribe(response => {
        this.priceListsRequestPending = false;
        if (response['items']) {

          const priceLists = response['items'];
          if (priceLists) {
            // update price lists dropdown items
            this.populatePriceLists(priceLists);

            const archivedCharges = this.data?.stopPoint?.voucher?.charges;
            if (priceLists.length) {
              // [ONLY IF EXPECTING SYSTEMIC (UPS) ZONE] check the first zone is systemic, if not then alert the user that the address doesn't match a valid UPS zone
              // if (isExpectingSystemic) {
              //   let isInvalid = true;
              //   if (chargeCategories[0].charge_category_X_systemic_courier_services.length) {
              //     if (chargeCategories[0].charge_category_X_systemic_courier_services[0]?.systemic_courier_name_const == this.globals.systemicCouriersNames['UPS']) {
              //       isInvalid = false;
              //     }
              //   }
              //   if (isInvalid) {
              //     this.isUpsAddressValid = false;
              //     alert(this.invalidUpsZoneError);
              //   } else {
              //     this.isUpsAddressValid = true;
              //   }
              // }

              // select a price list from response
              let priceListIndex = 0;
              if (isLoadSpecificPriceList || this.hasPriceListManuallyAltered) {
                priceLists.forEach((priceList, index) => {
                  if (priceList.id == this.selectedPriceListId) {
                    priceListIndex = index;
                  }
                });
              }
              this.selectedPriceListId = priceLists[priceListIndex].id;
              if (!this.hasManuallyAlteredDiscount) {
                this.discount = priceLists[priceListIndex].default_discount ?? 0;
              }

              this.myForm.patchValue({
                'stopPoint': {
                  'voucher': {
                    'options': {
                      'discount': this.discount
                    },
                  },
                },
              });

              // don't alter the selected price list if it has been changed manually
              if (!this.hasPriceListManuallyAltered) {
                this.myForm.patchValue({
                  'stopPoint': {
                    'voucher': {
                      'options': {
                        'price_list_id': priceLists[priceListIndex].id
                      },
                    },
                  },
                });
              }

              if (!isLoadSpecificPriceList) {
                // empty selected service charges
                this.patchCompanyServicesValue([]);
              }

              if (archivedCharges && this.chargeDetails.total && archivedCharges.total != this.chargeDetails.total) {
                delete this.data.stopPoint.voucher.charges;
                this._notificationSvc.showWarning('', this.chargesChangedLabel);
                alert(this.chargesChangedLabel);
              }

              // change service types
              this.populateServicesOptions(priceLists[priceListIndex].addon_services);

              if (!isLoadSpecificPriceList) {
                // select default service charges as needed
                // calculate new charge sum if it doesn't happen in service types
                this.calculatePayOnDeliveryCharge();
                this.calculateTimeWindowCharge();
              }
              this.surcharges = priceLists[priceListIndex].surcharges;
              this.calculateSurcharges();

              this.calculatePrice();

            } else {
              this.selectedPriceListId = null;
              this.myForm.patchValue({
                'stopPoint': {
                  'voucher': {
                    'options': {
                      'price_list_id': null
                    },
                  },
                },
              });
              this.patchCompanyServicesValue([]);
              if (archivedCharges) {
                this.chargeDetails = archivedCharges;
              } else {
                Object.keys(this.chargeDetails).forEach(key => {
                  this.chargeDetails[key] = 0;
                });
              }
            }
          }
        }
      },
        error => {
          this.priceListsRequestPending = false;
        });
    }
  }

  calculatePrice() {
    let chargeUrl = 'api/v1/price-list-price-calculator';
    const stopPointData = this.myForm.value.stopPoint;
    const voucherOptions = stopPointData.voucher.options;
    const payOnDeliveryWithCard = stopPointData.requested_pay_on_delivery_payment_method === this.globals.paymentOptionsConstants['CREDIT_CARD'];
    const payCourierFareWithCard = stopPointData.requested_payment_method_courier_fare === this.globals.paymentOptionsConstants['CREDIT_CARD'];
    const paidByType = this.myForm.value.stopPoint.voucher.options.paid_by;
    this.calculateShipmentType();
    const data = {
      service_id: stopPointData.service.id,
      price_list_id: this.selectedPriceListId,
      shipment_type: this.shipmentType,
      insurance_amount: voucherOptions.insurance ? voucherOptions.insurance_amount : null,
      discount: this.discount,
      pay_amount: this.payAmount,
      pay_with_card: (payOnDeliveryWithCard || payCourierFareWithCard),
      sender_location: {
        ...this.getSenderAddress(), 
        collaborator_id: this.getSelectedSenderCollaborator()?.companyCollaborator?.collaborator.collaboratorData.id ?? null
      },
      recipient_location: {
        ...this.getRecipientAddress(),
        collaborator_id: this.myForm.value.stopPoint.selectedRecipient?.companyCollaborator?.collaborator.collaboratorData.id ?? null
      },
      mass_and_dimensions: this.getMassAndDimensions(),
      addon_services: voucherOptions.addon_services,
      addon_charges: voucherOptions.addon_charges,
      // collaborator: {
      //   collaborator_id: 1,
      //   address: this.getSenderAddress(),
      // },
      paid_by: paidByType,
      consignor: {
        collaborator_id: this.getSelectedConsignor()?.companyCollaborator.collaborator.collaboratorData?.id ?? null,
      },
    }
    if (this.globals.collaboratorModeEnabled || this.router.url.includes('newShipmentsView')) {
      chargeUrl = 'api/v1/partner-price-list-price-calculator';
      data['partner_courier_id'] = this.selectedPartnerItem['id'];
    }
    if (this.priceRequest) {
      this.priceRequest.unsubscribe();
    }
    this.priceRequestPending = true;
    this.priceRequest = this.http.post(chargeUrl, data).pipe(take(1)).subscribe(response => {
      this.priceRequestPending = false;
      // charge detailed analysis

      if (response['items']) {
        if (!this.hasManuallyAlteredDiscount) {
          this.discount = 0;
          const collaboratorDiscount = response['items']['collaborator_discount'];
          if (collaboratorDiscount && collaboratorDiscount.length) {
            this.discount = response['items']['collaborator_discount'][0]['percentage'];
          }
          this.myForm.patchValue({
            'stopPoint': {
              'voucher': {
                'options': {
                  'discount': this.discount
                },
              },
            },
          });
        }

        this.chargeDetails = response['items']['charges'];

        const archivedCharges = this.data?.stopPoint?.voucher?.charges;
        if (archivedCharges && this.chargeDetails.total && archivedCharges.total != this.chargeDetails.total) {
          delete this.data.stopPoint.voucher.charges;
          this._notificationSvc.showWarning('', this.chargesChangedLabel);
          alert(this.chargesChangedLabel);
        }
      } else if (response['error']) {
        alert(response['error']);
      }
    },
    error => {
      this.priceRequestPending = false;
    });
  }

  calculateSurcharges() {
    this.chargesOptions = this.formatSelectOptionsForSurcharges(this.surcharges);
    let chargesToSelect = [];
    if (this.savedSurcharges) {
      chargesToSelect = this.formatSelectOptionsForSurcharges(this.savedSurcharges);
      this.addonCharges = chargesToSelect.map(obj => obj.value);
    } else {
      this.addonCharges = this.chargesOptions.map(obj => obj.value);
    }
    this.patchChargesValue();
  }

  private getRecipientAddress() {
    return {
      lat: this.lat,
      lon: this.lon,
      countryCode: this.countryCode,
      isPlace: this.isPlace,
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
      houseNumber: this.houseNumber,
      district: this.district,
      street: this.street
    };
  }

  private getSenderAddress() {
    return {
      lat: this.collaboratorLat,
      lon: this.collaboratorLon,
      countryCode: this.collaboratorCountryCode,
      isPlace: this.collaboratorIsPlace,
      city: this.collaboratorCity,
      state: this.collaboratorState,
      postalCode: this.collaboratorPostalCode,
      houseNumber: this.collaboratorHouseNumber,
      district: this.collaboratorDistrict,
      street: this.collaboratorStreet
    };
  }

  private getMassAndDimensions() {
    const massAndDimensionsArray = [];
    const massAndDimensionsData = this.myForm.value.stopPoint.mass_and_dimensions_data;
    if (massAndDimensionsData) {
      massAndDimensionsData.forEach(element => {
        // calculate volume from w, h, l & mass separately and keep the highest
        const volume = element.volume ? Number(element.volume) : null;
        const mass = element.mass ? Number(element.mass) : null;
        const weightVolumetric = element.weightVolumetric ? Number(element.weightVolumetric) : null;
        const height = element.height ? Number(element.height) : null;
        const length = element.length ? Number(element.length) : null;
        const width = element.width ? Number(element.width) : null;
        const count = element.count ? Number(element.count) : 1;

        massAndDimensionsArray.push({
          mass: mass,
          weightVolumetric: weightVolumetric,
          volume: volume,
          height: height,
          length: length,
          width: width,
          count: count,
        });
      });
    }
    return massAndDimensionsArray;
  }

  private formatSelectOptionsForSurcharges(surchargesArray) {
    const stopPointData = this.myForm.value.stopPoint;
    const voucherOptions = stopPointData.voucher.options;
    const payOnDeliveryWithCard = stopPointData.requested_pay_on_delivery_payment_method === this.globals.paymentOptionsConstants['CREDIT_CARD'];
    const payCourierFareWithCard = stopPointData.requested_payment_method_courier_fare === this.globals.paymentOptionsConstants['CREDIT_CARD'];
    const hasInsurance = voucherOptions.insurance && voucherOptions.insurance_amount;
    let chargesForSelect = [];
    surchargesArray.forEach(surcharge => {
      if (surcharge.addon_charge_type && !surcharge.type) { 
        surcharge['type'] = surcharge.addon_charge_type;
        surcharge['name'] = surcharge.service_name;
        surcharge['id'] = surcharge.addon_charge_id;
      }
      const chargeOption = {
        label: surcharge.name,
        value: surcharge.id
      }
      if (surcharge.type === this.globals.priceListSurchargesEnum.FuelSurcharge.key) {
        chargeOption.label = this.globals.priceListSurchargesEnum.FuelSurcharge.caption;
        chargesForSelect.push(chargeOption);
      } else if(surcharge.type === this.globals.priceListSurchargesEnum.InsuranceSurcharge.key && hasInsurance) {
        chargesForSelect.push(chargeOption);
      } else if(surcharge.type === this.globals.priceListSurchargesEnum.PayWithCardSurcharge.key && (payOnDeliveryWithCard || payCourierFareWithCard)) {
        chargesForSelect.push(chargeOption);
      }
    });
    return chargesForSelect;
  }

  checkCourierFareCollaborator() {
    const patchCourierFareMethod = () => {
      this.myForm.patchValue({
        'stopPoint': {
          'requested_payment_method_courier_fare': this.requestedPaymentMethodCourierFare,
          'executed_payment_method_courier_fare': this.executedPayOnDeliveryPaymentMethodCourierFare,
        }
      });
    }
    const patchTransactionDocumentType = () => {
      this.myForm.patchValue({
        'stopPoint': {
          'voucher': {
            'options': {
              'transaction_document_type': this.transactionDocumentType
            }
          }
        }
      });
    }

    const paidBy = this.myForm.value.stopPoint.voucher.options.paid_by;
    let foundCollaborator = false;
    if (paidBy == this.globals.stopPointCollaboratorTypesConstants['SENDER'] && this.collaboratorNormal) {
      foundCollaborator = true;
    } else if (paidBy == this.globals.stopPointCollaboratorTypesConstants['RECEIVER'] && this.collaboratorReceiver) {
      foundCollaborator = true;
    } else if (paidBy == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'] && this.collaboratorConsignor) {
      foundCollaborator = true;
    }

    // if NO collaborator is found, preserve the selected courier fare payment method (if it's null, reset it to POD)
    if (!foundCollaborator) {
      if (!this.stopPointId) this.transactionDocumentType = this.globals.transactionDocumentTypes['RECEIPT'];
      this.requestedPaymentMethodCourierFare = this.requestedPaymentMethodCourierFare ?? this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
      if (this.stopPointId) {
        this.executedPayOnDeliveryPaymentMethodCourierFare = this.requestedPaymentMethodCourierFare ?? this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
      }

      patchCourierFareMethod();
      if (!this.stopPointId) patchTransactionDocumentType();
      return false;
    }

    // if collaborator is found, set requested_payment_method_courier_fare & executed_payment_method_courier_fare to null & return false
    // also select credit as transaction_document_type
    this.requestedPaymentMethodCourierFare = null;
    this.executedPayOnDeliveryPaymentMethodCourierFare = null;
    if (!this.stopPointId) this.transactionDocumentType = this.globals.transactionDocumentTypes['INVOICE'];
    patchCourierFareMethod();
    if (!this.stopPointId) patchTransactionDocumentType();
    return true;
  }

  // update volume of [index] package whenever the first three values are changed
  updateVolumeAndWeight(index) {
    const width = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].width);
    const height = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].height);
    const length = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].length);

    if (width && height && length) {
      const volumeValue = width * height * length / 1000;
      const weightValue = volumeValue * this.globals.volumeDenominatorConstant;

      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'volume': volumeValue.toFixed(3),
        'mass': weightValue.toFixed(3),
      });
    }
    this.weightsAndLoadsCompare();
  }

  // update volume & volumetric weight of [index] package whenever the first three values are changed
  updateVolumeAndVolumetricWeight(index) {
    const width = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].width);
    const height = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].height);
    const length = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].length);

    if (width && height && length) {
      const volumeValue = width * height * length / 1000;
      const volumetricWeightValue = volumeValue / this.globals.volumeDenominatorConstant;

      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'volume': volumeValue.toFixed(3),
        'weight_volumetric': volumetricWeightValue.toFixed(3),
      });
    }
    this.weightsAndLoadsCompare();
  }

  // update weight of [index] package whenever the volume is changed
  updateWeightFromVolume(index) {
    const volume = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].volume);

    if (volume) {
      const weightValue = volume / this.globals.volumeDenominatorConstant;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'mass': weightValue.toFixed(3),
      });
    }
    this.weightsAndLoadsCompare();
  }

  // update volume of [index] package whenever the weight is changed (happens only if volume is empty/0!)
  // keep updating volume if user hasn't focused out of weight (used for weight of more than 1 digit)
  updateVolumeFromWeight(index) {
    const mass = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].mass);
    const volume = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].volume);

    if ((mass && !volume) || (mass && this.isUserStillChangingWeight)) {
      this.isUserStillChangingWeight = true;
      const volumeValue = mass * this.globals.volumeDenominatorConstant;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'volume': volumeValue.toFixed(3),
      });
    }

    this.weightsAndLoadsCompare();
  }
  stopUpdatingVolumeFromWeight() {
    this.isUserStillChangingWeight = false;
  }

  // compare weights sum with total weight
  weightsAndLoadsCompare() {
    this.weight = Number(this.myForm.controls.stopPoint.value['weight']);
    this.load = Number(this.myForm.controls.stopPoint.value['load']);

    // set weight & load to 0 if it is undefined/null
    if (!this.weight) {
      this.weight = 0;
    }
    if (!this.load) {
      this.load = 0;
    }

    if (this.weight || this.weight === 0) {
      let weightSum = 0;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.value.forEach(data => {
        let currMass = Number(data.mass) ?? 0;
        let currVolWeight = Number(data.weight_volumetric) ?? 0;
        let weightToCalculate = currMass > currVolWeight ? currMass : currVolWeight;

        for (let i = 0; i < data.count; i++) {
          weightSum += weightToCalculate;
        }
      });

      if (weightSum) {
        this.weight = weightSum.toFixed(2);
      }
      this.patchWeightAndLoad();
    }

    if (this.load || this.load === 0) {
      let loadSum = 0;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.value.forEach(data => {
        for (let i = 0; i < data.count; i++) {
          loadSum += Number(data.volume);
        }
      });

      if (loadSum) {
        this.load = loadSum.toFixed(2);
      }
      this.patchWeightAndLoad();
    }
  }

  // don't allow manual changes of totals that are less than the calculated totals
  compareSums() {
    // let currentTotalWeight = this.myForm.controls.stopPoint['controls'].value.weight;
    // let currentTotalLoad = this.myForm.controls.stopPoint['controls'].value.load;
  }
  
  priceListChanged(event) {
    this.hasPriceListManuallyAltered = true;
    const selectedPriceListId = event.value;
    this.selectedPriceListId = selectedPriceListId;

    const priceList = this.priceListsData[selectedPriceListId];

    this.discount = 0;
    this.myForm.patchValue({
      'stopPoint': {
        'voucher': {
          'options': {
            'discount': this.discount
          },
        },
      },
    });

    // empty selected service charges
    this.patchCompanyServicesValue([]);

    // change service types
    this.populateServicesOptions(priceList.addon_services);
    this.calculatePayOnDeliveryCharge();
    this.calculateTimeWindowCharge();

    this.surcharges = priceList.surcharges;
    this.calculateSurcharges();

    this.calculatePrice();
  }

  // check if selected price list is a systemic price list & show appropriate fields based on its systemic name const
  checkSelectedZoneForSystemic(priceListId) {
    this.systemicCourierName = null;

    // ups
    // if (this.zonesData[priceListId]['charge_category_X_systemic_courier_services'].length) {
    //   if (this.zonesData[priceListId]['charge_category_X_systemic_courier_services'][0]['systemic_courier_name_const'] == this.globals.systemicCouriersNames['UPS']) {
    //     this.systemicCourierName = this.globals.systemicCouriersNames['UPS'];

    //     setTimeout(() => {
    //       document.getElementById('weight-load-totals').scrollIntoView({ behavior: 'smooth' });
    //     }, 200);
    //   }
    // }
  }

  // calculates the amount to charge for the services selected and adds it to the voucher price
  calculateServiceCharge() {
    const addonServices = this.myForm.value.stopPoint.voucher.options.addon_services;
    if (addonServices) {
      addonServices.forEach(serviceChargeId => {
        this.addonServicesForSelectedPriceList.forEach(serviceCharge => {
          if (serviceChargeId === serviceCharge.id) {
            if (serviceCharge.is_per_item) {
              let itemsCount = 0;
              const massAndDimensionsData = this.myForm.value.stopPoint.mass_and_dimensions_data;
              if (massAndDimensionsData) {
                massAndDimensionsData.forEach(item => {
                  itemsCount += Number(item.count);
                });
              }
            }
          }
        });
      });
      this.calculatePrice();
    }
  }

  payOnDeliveryChanged() {
    this.payAmount = this.myForm.value.stopPoint.pay_amount;
    this.savedSurcharges = null;
    this.calculateSurcharges();
    this.calculatePayOnDeliveryCharge();
  }

  // adds/removes pay on delivery charge
  calculatePayOnDeliveryCharge() {
    const payAmount = this.myForm.value.stopPoint.pay_amount;
    const payOnDeliveryServiceChargeId = this.getServiceChargeIdForType(this.globals.priceListAddonServiceTypeEnum.CodService.key);
    const addonServices = this.myForm.value.stopPoint.voucher.options.addon_services;
    if (payAmount > 0 && payOnDeliveryServiceChargeId) {
      if (!addonServices.includes(payOnDeliveryServiceChargeId)) {
        addonServices.push(payOnDeliveryServiceChargeId);
        this.patchCompanyServicesValue(addonServices);
      }
    } else if (payOnDeliveryServiceChargeId) {
      if (addonServices.includes(payOnDeliveryServiceChargeId)) {
        addonServices.splice(addonServices.indexOf(payOnDeliveryServiceChargeId), 1);
        this.patchCompanyServicesValue(addonServices);
      }
    }
    this.calculatePrice();
  }

  calculateTimeWindowCharge() {
    const addonServices = this.myForm.value.stopPoint.voucher.options.addon_services;
    const timeWindowServiceChargeId = this.getServiceChargeIdForType(this.globals.priceListAddonServiceTypeEnum.TimeWindowService.key);
    if (!this.isTimeWindowDefault() && timeWindowServiceChargeId) {
      if (!addonServices.includes(timeWindowServiceChargeId)) {
        addonServices.push(timeWindowServiceChargeId);
        this.patchCompanyServicesValue(addonServices);
      }
    } else if (timeWindowServiceChargeId) {
      if (addonServices.includes(timeWindowServiceChargeId)) {
        addonServices.splice(addonServices.indexOf(timeWindowServiceChargeId), 1);
        this.patchCompanyServicesValue(addonServices);
      }
    }
  }

  getServiceChargeIdForType(type) {
    let serviceChargeId = null;
    const priceListId = this.myForm.value.stopPoint.voucher.options.price_list_id;
    if (priceListId) {
      const priceListsData = this.priceListsData[priceListId];
      if (priceListsData) {
        const addonServices = priceListsData['addon_services'];
        if (addonServices) {
          addonServices.forEach(addonService => {//7
            if (addonService['type'] === type && addonService['is_generic']) {
              serviceChargeId = addonService['id'];
            }
          });
        }
      }

      // check if selected price list is a systemic price list & show appropriate fields based on its systemic name const
      // this.checkSelectedZoneForSystemic(priceListId);
    }
    return serviceChargeId;
  }

  isTimeWindowDefault() {
    const doubleTimeWindow = this.myForm.value.stopPoint.doubleTimeWindow;
    const deliveryTimeWindowUnformatted = this.myForm.value.stopPoint.time_window_unformatted;
    const pickupTimeWindowUnformatted = this.myForm.value.stopPoint.pickup_time_window_unformatted;
    const timeWindowUnformattedDefault = [480, 1200];

    if (doubleTimeWindow) {
      return false;
    } else {
      const isDeliveryTimeWindowDefault = JSON.stringify(deliveryTimeWindowUnformatted) === JSON.stringify(timeWindowUnformattedDefault);
      const isPickupTimeWindowDefault = JSON.stringify(pickupTimeWindowUnformatted) === JSON.stringify(timeWindowUnformattedDefault);
      if (isDeliveryTimeWindowDefault && isPickupTimeWindowDefault) {
        return true;
      } else {
        return false;
      }
    }
  }

  loadAddonServices() {
    const ids = this.savedServices.map(service => service.addon_charge_id);
    this.patchCompanyServicesValue(ids);
    if (this.savedServices && this.servicesOptions.length) this.savedServices = null;    
  }

  patchCompanyServicesValue(addonServices) {
    this.myForm.patchValue({
      'stopPoint': {
        'voucher': {
          'options': {
            'addon_services': addonServices,
          },
        },
      },
    });
  }

  patchChargesValue() {
    this.myForm.patchValue({
      'stopPoint': {
        'voucher': {
          'options': {
            'addon_charges': this.addonCharges,
          },
        },
      },
    });
    const stopPointData = this.myForm.value.stopPoint;
    const voucherOptions = stopPointData.voucher.options;
  }

  addItemToVoucher(data) {
    if (!data) {
      data = {
        height: null,
        length: null,
        width: null,
        mass: null,
        weightVolumetric: null,
        parcels_info: [],
        count: 1,
        id: null
      }
    }
    this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].push(this.formBuilder.group({
      height: data.height,
      length: data.length,
      width: data.width,
      volume: data.volume,
      mass: data.mass,
      weight_volumetric: data.weightVolumetric,
      count: data.count,
      parcels_info: data.parcels_info,
      id: data.id
    }));
    this.items.push(data);
    this.items = [...this.items];

    setTimeout(() => {
      M.updateTextFields();
    }, 100);
  }

  public setStopFormData(projectProblemId, projectProblemDepartureDatetime) {
    this.projectProblemId = projectProblemId;
    this.projectProblemDepartureDatetime = projectProblemDepartureDatetime;
    this.projectProblemDayOfWeek = this.days[moment(projectProblemDepartureDatetime).day()];

    this.setForm();
  }

  onSelectedDepotChanged(event) {
    this.selectedDepotItem = {
      companyDepot: {
        id: event.companyDepot.id,
        address: {
          value: this.addressService.getAddressLabel(event.companyDepot.address),
        },
      },
      name: this.depotUtils.getDepotName(event.companyDepot)
    };
    this.warehouseId = event.companyDepot.warehouse_ids[0];
    this.myForm.patchValue({
      'stopPoint': {
        'depot': this.selectedDepotItem,
        'warehouse_id': this.warehouseId
      }
    });
  }

  public getFormData(stopPointId, projectProblemId = null) {

    this.resetCollaborators();

    if (!this.projectProblemId) {
      this.inGeneralWarehouse = true;
      document.getElementById('voucher-form-modal').classList.add('one-bar-height');
    }

    this.inWarehouse = false;
    if (!projectProblemId) {
      this.inWarehouse = true;
    }

    this.stopPointService.getStopPoint(stopPointId, projectProblemId).pipe(take(1)).subscribe(response => {
      this.data = response['item'];

      // [food mode]: match depot through address (temporary)
      if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
        for (let depot of this.globals.depotsWithNamesArray) {
          if (depot['companyDepot']['address'].value == this.data['relatedStopPoint']['address'].value) {
            this.selectedFoodDepot = depot;
            this.myForm.patchValue({
              'stopPoint': {
                'selectedFoodDepot': this.selectedFoodDepot
              }
            });
            break;
          }
        }
      }     

      const stopPoint = this.data.stopPoint;
      const collaborator = stopPoint.collaborator;
      const voucher = stopPoint.voucher;

      // loadData
      // TODO change projectProblem for optimization
      if (this.data.projectProblem) {
        this.projectProblemDepartureDatetime = this.data.projectProblem.departure_datetime;
      }
      this.stopPointId = stopPoint.id;
      this.serviceType = stopPoint.service_type;

      this.selectedService = this.basicServicesOptions.find(service => service.id == voucher?.service_id);
      if (!this.chargesEnabled) {
        this.selectedService = this.basicServicesOptions.find(service => service.shipment_service_type == stopPoint.shipment_service_type);
      }
      if (!this.selectedService && voucher && this.chargesEnabled) {
        // for vouchers with a service id that is not available to this user, just show the saved service
        this.basicServicesOptions = [{ name: voucher.service_name, id: voucher.service_id }];
        this.selectedService = this.basicServicesOptions[0];
      } else if (!this.selectedService) {
        this.selectedService = this.defaultService;
      }
      this.savedSurcharges = voucher?.voucher_surcharges;
      this.savedServices = voucher?.voucher_services;
      if (this.savedServices) this.loadAddonServices();

      // load depot & disable depot selection on edit
      const depot = this.depotUtils.getFirstDepotWithWarehouseId(stopPoint.warehouse_id);
      this.selectedDepotItem = {
        companyDepot: {
          id: depot.companyDepot.id,
          address: {
            value: this.addressService.getAddressLabel(depot.companyDepot.address),
          },
        },
        name: this.depotUtils.getDepotName(depot.companyDepot)
      };
      this.warehouseId = depot.companyDepot.warehouse_ids[0];
      this.myForm.controls['stopPoint']['controls']['depot'].disable();

      this.projectProblemIdForRequest = stopPoint.project_problem_id;

      this.contactName = stopPoint.contact_name;
      this.supplier = stopPoint.supplier;

      this.barcode = stopPoint.barcode;

      this.payAmount = stopPoint.pay_amount;

      // get appropriate barcode data based on stop point's service type
      if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
        this.pickupBarcode = stopPoint.barcode;
      } else {
        this.deliveryBarcode = stopPoint.barcode;
      }

      this.isSignatureMandatory = stopPoint.is_signature_mandatory;
      this.recipientEmail = stopPoint.recipient_email;
      if (this.contactName.includes(this.noNameConstant)) {
        this.contactName = this.contactName.replace(this.noNameConstant, this.noNameLabel);
      }
      if (this.contactName.includes(this.returnConstant)) {
        this.contactName = this.contactName.replace(this.returnConstant, this.returnLabel);
      }

      if (stopPoint.telephone === 'n/a') { stopPoint.telephone = ''; }
      if (stopPoint.telephone === '+30n/a') { stopPoint.telephone = ''; }
      if (stopPoint.telephone) {
        // make sure the telephone number is long enough too avoid TOO_SHORT parse errors
        // (very short numbers should probably not be stored in db, but this will do for now)
        if (stopPoint.telephone.length > 5) {
          const phoneObj = libphonenumber.parsePhoneNumber(stopPoint.telephone);
          this.phoneNumber = phoneObj.nationalNumber;
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }
      }

      // get partner
      if (stopPoint.company_partner_id) {
        this.selectedPartnerItem = {
          id: this.globals.partners[stopPoint.company_partner_id]['id'],
          name: this.globals.partners[stopPoint.company_partner_id]['name'],
          default_stop_point_delivery_load: this.globals.partners[stopPoint.company_partner_id]['default_stop_point_delivery_load'],
          basicVoucherServices: this.globals.partners[stopPoint.company_partner_id]['basicVoucherServices']
        };
      }

      this.priority = stopPoint.priority;
      if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
        this.priorityOn = true;
      } else {
        this.priorityOn = false;
      }


      // find sender for sender name (and data), otherwise use supplier name
      let sender = this.stopPointUtils.getSenderCollaborator(stopPoint?.voucher?.company_voucher_collaborators)?.collaborator;
      if (sender) {
        this.collaboratorId = sender.collaboratorData.id;

        this.collaboratorTelephone = sender.collaboratorData.telephone;
        this.pickupTelephone = this.telephone;
        if (sender.collaboratorData.telephone) {
          if (sender.collaboratorData.telephone[0]) {
            this.collaboratorCountryPrefix = this.collaboratorTelephone[0]['telephone_code'];
            this.collaboratorPhoneNumber = sender.collaboratorData.telephone[0]['telephone_number'];
            this.pickupCountryPrefix = this.collaboratorTelephone[0]['telephone_code'];
            this.pickupTelephone = sender.collaboratorData.telephone;
            this.pickupPhoneNumber = sender.collaboratorData.telephone[0]['telephone_number'];
          }
        }

        if (this.collaboratorTelephone) {
          if (this.collaboratorTelephone.length > 5) {
            const phoneObj = libphonenumber.parsePhoneNumber(this.collaboratorTelephone[0]['telephone_code'] + sender.collaboratorData.telephone[0]['telephone_number']);
            this.pickupPhoneNumber = phoneObj.nationalNumber;
            this.pickupCountryPrefix = '+' + phoneObj.countryCallingCode;
          }
        }

        this.collaboratorName = sender.collaboratorData.collaborator_name;
        this.supplier = sender.collaboratorData.collaborator_name;
        this.collaboratorType = String(sender.collaboratorData.collaborator_type);

        if (sender.collaborator_depot) {
          if (sender.collaborator_depot.address) {
            if (sender.collaborator_depot.address.lat && sender.collaborator_depot.address.lon) {
              this.setCollaboratorAddress(sender.collaborator_depot.address);
              (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
              this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
            }
          }
        } else if (sender.address) {
          if (sender.address.lat && sender.address.lon) {
            this.setCollaboratorAddress(sender.address);
            (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
            this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
          }
        }
      }

      // load smart point
      this.smartPoint = stopPoint.smartPoint;
      if (stopPoint.smartPoint) {
        this.isPaymentEditable = stopPoint.smartPoint.is_payment_available;
      }

      const loads = this.stopPointUtils.getPickupAndDeliveryLoad(this.data)
      this.deliveryLoad = loads.deliveryLoad;
      this.pickupLoad = loads.pickupLoad;
      this.weight = stopPoint.weight;
      this.load = stopPoint.load;
      this.fulfillmentStatus = stopPoint.fulfillment_status;

      if (stopPoint.agreed_shipping) {
        if (stopPoint.agreed_shipping[0]) {
          this.previousAgreedShipping = stopPoint.agreed_shipping[0];
          if (stopPoint.agreed_shipping[0].date) {
            this.agreedShippingDate = moment(stopPoint.agreed_shipping[0].date, 'YYYY-MM-DD').format('DD MMM YYYY');
          }
        }
      }

      if (stopPoint.note) {
        this.notes = stopPoint.note;
      }

      // load sp that has never been in a PP
      if (!stopPoint.deliverySources.length && !stopPoint.pickupDestinations.length) {
        if (this.data.relatedStopPoint) {
          if (stopPoint.service_type === this.globals.stopPointServiceTypeConstants['PICKUP']) {
            // this.serviceTypeRadioButtonSelected = 'pickup';
            this.serviceTypeSelected = 'both';
            this.noDeliverySelected = false;
            this.getFormData(this.data.relatedStopPoint.id, null);
          } else {
            // this.serviceTypeRadioButtonSelected = 'pickup';
            this.serviceTypeSelected = 'both';
            const pickupStopId = this.data.relatedStopPoint.id;
            this.stopPointService.getStopPoint(pickupStopId, null).pipe(take(1)).subscribe(response => {
              const pickupStopPoint = response['item']['stopPoint'];
              this.populatePickupData(pickupStopPoint);
            });
          }
        } else {
          if (this.serviceType === this.globals.stopPointServiceTypeConstants['PICKUP']) {
            this.serviceTypeSelected = 'pickup';
            // this.serviceTypeRadioButtonSelected = 'pickup';
            this.noDeliverySelected = true;
            this.collaboratorSectionLabel = this.assignorLabel;
            this.stopPointLabel = this.collaboratorLabel;
          } else if (this.serviceType === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            this.serviceTypeSelected = 'delivery';
            // this.serviceTypeRadioButtonSelected = 'delivery';
          }
        }
      }

      // requested method must fetch its value from executed instead of requested if requested is not NO_PAYMENT!
      this.requestedPayOnDeliveryPaymentMethod = stopPoint.requested_pay_on_delivery_payment_method;
      if (stopPoint.executed_pay_on_delivery_payment_method != this.globals.paymentOptionsConstants['NO_PAYMENT']) {
        this.requestedPayOnDeliveryPaymentMethod = stopPoint.executed_pay_on_delivery_payment_method;
      }

      this.requestedPaymentMethodCourierFare = stopPoint.requested_payment_method_courier_fare;
      if (stopPoint.executed_payment_method_courier_fare != this.globals.paymentOptionsConstants['NO_PAYMENT']) {
        this.requestedPaymentMethodCourierFare = stopPoint.executed_payment_method_courier_fare;
      }

      this.executedPayOnDeliveryPaymentMethod = stopPoint.executed_pay_on_delivery_payment_method;
      this.executedPayOnDeliveryPaymentMethodCourierFare = stopPoint.executed_payment_method_courier_fare;

      // load dates for cheques
      if (stopPoint.cheque_collection_date_pay_on_delivery) {
        this.chequeCollectionDatePayOnDelivery = stopPoint.cheque_collection_date_pay_on_delivery;
      }
      if (stopPoint.cheque_collection_date_courier_fare) {
        this.chequeCollectionDateCourierFare = stopPoint.cheque_collection_date_courier_fare;
      }

      if (stopPoint.deliverySources.length) {
        this.deliverySourceModelName = stopPoint.deliverySources[0].modelName;
        this.deliverySourceModelId = stopPoint.deliverySources[0].modelId;
        if (this.deliverySourceModelName === this.globals.companyDepotModelName) {
          // load next day delivery if there is one
          if (this.data.relatedStopPoint) {
            this.serviceTypeSelected = 'both';
            // this.serviceTypeRadioButtonSelected = 'pickup';
            const pickupStopId = this.data.relatedStopPoint.id;
            this.stopPointService.getStopPoint(pickupStopId, null).pipe(take(1)).subscribe(response => {
              const pickupStopPoint = response['item']['stopPoint'];
              this.populatePickupData(pickupStopPoint);
            });
          } else {
            this.serviceTypeSelected = 'delivery';
            // this.serviceTypeRadioButtonSelected = 'delivery';
          }
          this.noDeliverySelected = false;

          this.depots.forEach(depot => {
            if (depot.companyDepot.id === this.deliverySourceModelId) {
              this.selectedDepotItem = {
                companyDepot: {
                  id: this.deliverySourceModelId,
                  address: {
                    value: this.addressService.getAddressLabel(depot.companyDepot.address),
                  },
                },
                name: this.depotUtils.getDepotName(depot.companyDepot)
              };
              this.warehouseId = this.globals.depots[depot.companyDepot.id].companyDepot.warehouse_ids[0];
            }
          });
        } else {
          const pickupMode = stopPoint.deliverySources[0].pickupMode
          if (pickupMode == this.globals.importPickupModes['SAME_DAY_DELIVERY'] || pickupMode == this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB']) {
            this.serviceTypeSelected = 'sameDay';
            // this.serviceTypeRadioButtonSelected = 'sameDay';
            if (pickupMode == this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB']) {
              this.isThroughHub = true;
            }
          } else {
            this.serviceTypeSelected = 'both';
            // this.serviceTypeRadioButtonSelected = 'pickup';
            this.noDeliverySelected = false;
          }
          const pickupStopId = this.deliverySourceModelId;
          let pickupProjectProblemId = null;
          if (this.data.relatedStopPoint) {
            if (projectProblemId == this.data.relatedStopPoint.project_problem_id) pickupProjectProblemId = projectProblemId;
          }
          this.stopPointService.getStopPoint(pickupStopId, pickupProjectProblemId).subscribe(response => {
            // if (response) {
            const pickupStopPoint = response['item']['stopPoint'];
            this.populatePickupData(pickupStopPoint);
            // }
          });
        }
      }

      if (stopPoint.pickupDestinations.length) {
        this.pickupDestinationModelName = stopPoint.pickupDestinations[0].modelName;
        this.pickupDestinationModelId = stopPoint.pickupDestinations[0].modelId;
        if (this.pickupDestinationModelName === this.globals.companyDepotModelName) {
          // this.serviceTypeRadioButtonSelected = 'pickup';
          // load next day delivery if there is one
          if (this.data.relatedStopPoint) {
            this.serviceTypeSelected = 'both';
            this.noDeliverySelected = false;
            this.getFormData(this.data.relatedStopPoint.id, null);
          } else {
            this.serviceTypeSelected = 'pickup';
            this.noDeliverySelected = true;
            this.collaboratorSectionLabel = this.assignorLabel;
            this.stopPointLabel = this.collaboratorLabel;
          }
          this.depots.forEach(depot => {
            if (depot.companyDepot.id === this.pickupDestinationModelId) {
              this.selectedDepotItem = {
                companyDepot: {
                  id: this.pickupDestinationModelId,
                  address: {
                    value: this.addressService.getAddressLabel(depot.companyDepot.address),
                  },
                },
                name: this.depotUtils.getDepotName(depot.companyDepot)
              };
              this.warehouseId = this.globals.depots[this.pickupDestinationModelId].companyDepot.warehouse_ids[0];
            }
          });
        } else {
          // load same day delivery form
          const pickupMode = stopPoint.pickupDestinations[0].pickupMode
          if (pickupMode == this.globals.importPickupModes['SAME_DAY_DELIVERY'] || pickupMode == this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB']) {
            this.serviceTypeSelected = 'sameDay';
            // this.serviceTypeRadioButtonSelected = 'sameDay';
            const deliveryStopId = this.pickupDestinationModelId;
            this.getFormData(deliveryStopId, projectProblemId);
            if (pickupMode == this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB']) {
              this.isThroughHub = true;
            }
          } else {
            this.serviceTypeSelected = 'both';
            // this.serviceTypeRadioButtonSelected = 'pickup';
            this.noDeliverySelected = false;
            this.getFormData(this.data.relatedStopPoint.id, null);
          }
        }
      }

      this.timeWindows = stopPoint.time_windows;
      this.setTimeWindows();

      this.duration = stopPoint.duration;
      this.durationSeconds = [moment.duration(this.duration).asSeconds()];
      this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);

      // const loadedCheckbox = document.getElementById('stop-point-modal-loaded-checkbox') as HTMLInputElement;
      // if (loadedCheckbox) {
      if (this.projectProblemDataService.loadedStops[this.stopPointId]) {
        this.loaded = true;
        // loadedCheckbox.checked = true;
      } else {
        this.loaded = false;
        // loadedCheckbox.checked = false;
      }
      // }

      const data = this.projectProblemDataService.manualModifiedRouteItems;
      if (data) {
        if (data[this.stopPointId]) {
          const routeIds = data[this.stopPointId];
          this.selectedDrivers = [];
          routeIds.forEach(routeId => {
            if (this.projectProblemDataService.routeSettingsById[routeId]) {
              const driverId = this.projectProblemDataService.routeSettingsById[routeId]['driver']['id'];
              const driver = this.projectProblemDataService.drivers[driverId];
              this.selectedDrivers.push(driver);
            }
          });
        }
      }

      this.portalAccess = stopPoint.portal_access ? stopPoint.portal_access : this.globals.portalAccessConstants['NO_ACCESS'];
      this.setPortalSwitches();

      this.setAddress(stopPoint.address);
      if ((<HTMLInputElement>document.getElementById('recipient-address-custom-input'))) {
        (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
      }
      this.recipientMapComponent.showDraggableMarker(Number(this.lat), Number(this.lon));
      this.positionSet = true;

      this.items = [];
      this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].controls = [];
      this.barcodes = [];
      if (stopPoint.mass_and_dimensions) {
        stopPoint.mass_and_dimensions.forEach(item => {
          // const volume = item.volume ? Number(item.volume) : null;
          const mass = item.mass ? Number(item.mass) : null;
          const weightVolumetric = item.weightVolumetric ? Number(item.weightVolumetric) : null;
          const height = item.height ? Number(item.height) : null;
          const length = item.length ? Number(item.length) : null;
          const width = item.width ? Number(item.width) : null;
          const count = item.count ? Number(item.count) : 1;
          const id = item.id ? item.id : null;

          const volumeFromDimensions = width * height * length / (this.globals.volumeDenominatorConstant * 1000);
          const volumeFromMass = mass / this.globals.volumeDenominatorConstant;
          const volumeValue = volumeFromMass > volumeFromDimensions ? volumeFromMass : volumeFromDimensions;

          item.parcels_info.forEach(parcel => {
            if (parcel.barcode) this.barcodes.push(parcel.barcode);
          });

          if (count) {
            const itemData = {
              height: height ? height.toFixed(2) : null,
              length: length ? length.toFixed(2) : null,
              width: width ? width.toFixed(2) : null,
              volume: volumeValue ? volumeValue.toFixed(2) : null,
              mass: mass ? mass.toFixed(2) : null,
              weightVolumetric: weightVolumetric ? weightVolumetric.toFixed(2) : null,
              count: count,
              parcels_info: item.parcels_info,
              id: id
            }
            this.addItemToVoucher(itemData);
          }
        });
      }

      if (stopPoint.shipment_type) {
        this.shipmentType = stopPoint.shipment_type;
      } else if (this.data.relatedStopPoint?.shipment_type) {
        this.shipmentType = this.data.relatedStopPoint.shipment_type;
      }

      if (voucher) {
        this.consignorType = voucher.collaborator_type;
        this.collaboratorType = voucher.collaborator_type;
        this.consignorTypeRadioButtonSelected = Number(voucher.collaborator_type);
        this.transactionDocumentType = voucher.transaction_document_type;

        // load collaborators
        if (voucher.company_voucher_collaborators) {
          if (voucher.company_voucher_collaborators.length) {
            this.fetchedCollaboratorsData = voucher.company_voucher_collaborators;
            voucher.company_voucher_collaborators.forEach(currCollaborator => {
              switch (currCollaborator.type) {
                case this.globals.stopPointCollaboratorTypesConstants['SENDER']:
                  this.collaboratorNormal = currCollaborator.collaborator.collaboratorData;
                  this.collaboratorNormalAddress = currCollaborator.collaborator.address;
                  this.collaboratorFetchedNormal = currCollaborator.collaborator.collaboratorData;
                  this.collaboratorNormalVoucherId = currCollaborator.id;
                  break;
                case this.globals.stopPointCollaboratorTypesConstants['RECEIVER']:
                  this.collaboratorReceiver = currCollaborator.collaborator.collaboratorData;
                  this.collaboratorReceiverAddress = currCollaborator.collaborator.address;
                  this.collaboratorFetchedReceiver = currCollaborator.collaborator.collaboratorData;
                  this.collaboratorReceiverVoucherId = currCollaborator.id;
                  break;
                case this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']:
                  this.collaboratorConsignor = currCollaborator.collaborator.collaboratorData;
                  this.collaboratorConsignorAddress = currCollaborator.collaborator.address;
                  this.collaboratorFetchedConsignor = currCollaborator.collaborator.collaboratorData;
                  this.collaboratorConsignorVoucherId = currCollaborator.id;
                  break;
              }
            });
          }
        }

        // if no sender collaborator is found, use temporary_assignor to fill name & address fields (isn't needed if noDelivery is selected)
        this.temporaryAssignor = stopPoint.temporary_assignor;
        if (!this.collaboratorNormal && !this.noDeliverySelected) {
          this.setCollaboratorAddress(stopPoint.temporary_assignor.address);
          (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
          this.collaboratorMapComponent.showDraggableMarker(Number(stopPoint.temporary_assignor?.address?.lat), Number(stopPoint.temporary_assignor?.address?.lon));
          this.pickupPhoneNumber = stopPoint.temporary_assignor?.telephone;
          this.pickupCountryPrefix = stopPoint.temporary_assignor?.telephone_code;
        }

        // IF no delivery is selected -> show consignor data on collaborator form (otherwise address & phone of sender are used)
        if (this.noDeliverySelected) {
          if (this.collaboratorConsignor) {
            this.setCollaboratorAddress(this.collaboratorConsignorAddress);
            (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorConsignorAddress;
            this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorConsignorAddress?.lat), Number(this.collaboratorConsignorAddress?.lon));
            if (this.collaboratorConsignor.telephone?.length) {
              this.pickupCountryPrefix = this.collaboratorConsignor.telephone[0].telephone_code;
              this.pickupPhoneNumber = this.collaboratorConsignor.telephone[0].telephone_number;
            }
          }
        }

        // load ups address fields checkbox status (if the th)
        // if (voucher.chargeCategories[0].charge_category_X_systemic_courier_services.length) {
        //   if (voucher.chargeCategories[0].charge_category_X_systemic_courier_services.length) {
        //     if (voucher.chargeCategories[0].charge_category_X_systemic_courier_services[0]) {
        //       if (voucher.chargeCategories[0].charge_category_X_systemic_courier_services[0].systemic_courier_name_const == this.globals.systemicCouriersNames['UPS'])
        //         this.isUpsAddressEnabled = true;
        //       this.isUpsAddressValid = true;
        //     }
        //   }
        // }

        // load systemic voucher fields
        if (voucher.voucher_ups_extra_fields) {
          this.shipmentContents = voucher.voucher_ups_extra_fields.shipment_contents;
          this.totalPackageValue = voucher.voucher_ups_extra_fields.total_package_value;
          this.shipmentContentsDescription = voucher.voucher_ups_extra_fields.shipment_contents_description;
          this.chargeTaxTo = voucher.voucher_ups_extra_fields.charge_tax_to;
          this.upsAddressLine = voucher.voucher_ups_extra_fields.address_line;
          this.upsCity = voucher.voucher_ups_extra_fields.city;
          this.upsZipCode = voucher.voucher_ups_extra_fields.zip_code;
          this.upsCountry = countries.toAlpha2(stopPoint.address.countryCode);
        }
        // basic voucher service
        // if (voucher.basicVoucherService['id']) {
        //   this.basicVoucherServiceId = voucher.basicVoucherService['id'];
        // }

        // if collaborator exists, load them normally
        if (collaborator) {
          if (voucher.collaborator_type == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
            this.consignorName = collaborator.collaboratorData.collaborator_name;
            this.consignorId = collaborator.collaboratorData.id;
            this.thirdPartyCheckboxSelected = true;
            this.selectedConsignor = { companyCollaborator: { collaborator: collaborator } };
          } else if (voucher.collaborator_type == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
            this.selectedCollaborator = { companyCollaborator: { collaborator: collaborator } };
            this.collaboratorId = collaborator.collaboratorData.id;
          } else if (voucher.collaborator_type == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
            this.selectedRecipient = { companyCollaborator: { collaborator: collaborator } };
            this.recipientId = collaborator.collaboratorData.id;
            if (this.globals.collaboratorModeEnabled) {
              this.collaboratorId = null;
            }
          }
        }

        // check if consignor exists, too
        const consignor = this.stopPointUtils.getConsignorCollaborator(voucher.company_voucher_collaborators);
        if (consignor) {
          this.consignorName = consignor.collaborator.collaboratorData.collaborator_name;
          this.consignorId = consignor.collaborator.collaboratorData.id;
          this.thirdPartyCheckboxSelected = true;
          this.selectedConsignor = { companyCollaborator: { collaborator: consignor.collaborator } };
        }

        // if (this.paidBy === this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
        this.populateChargeOptions();
        // }

        this.paidBy = voucher.paid_by;

        if (voucher.hash) {
          this.getHashAndGenerateQr(voucher.hash);
        }
        this.discount = voucher.discount;
        // don't allow discount changes from charges request on stop point edit
        this.hasManuallyAlteredDiscount = true;

        this.payer = voucher.payer;

        if (voucher.price_list_id) {
          this.selectedPriceListId = voucher.price_list_id;
          // this.checkSelectedZoneForSystemic(this.selectedPriceListId);
        }

        // this is not in the be response, maybe remove it? 
        if (voucher.addon_services) {
          const serviceChargeIds = [];
          voucher.addon_services.forEach(service => {
            serviceChargeIds.push(service.id);
          });
          this.companyServices = serviceChargeIds;
        }
        this.insuranceAmount = Number(voucher.insurance_amount);
        this.insurance = this.insuranceAmount ? true : false;

        // we set the form again because the getPriceLists function calculates based on weight values of the form, not the component
        this.setForm();
        this.getPriceLists(true);
      }

      this.setForm();
    });
  }

  populatePickupData(stopPoint) {
    this.supplier = stopPoint.contact_name;
    this.pickupProjectProblemId = stopPoint.project_problem_id ? stopPoint.project_problem_id : null;

    this.pickupTelephone = stopPoint.telephone;
    if (this.pickupTelephone === 'n/a') { this.pickupTelephone = ''; }
    if (this.pickupTelephone) {
      if (this.pickupTelephone.length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(this.pickupTelephone);
        this.pickupPhoneNumber = phoneObj.nationalNumber;
        this.pickupCountryPrefix = '+' + phoneObj.countryCallingCode;
      }
    }

    this.pickupTimeWindows = stopPoint.time_windows;
    this.pickupDuration = stopPoint.duration;
    this.pickupDurationSeconds = [moment.duration(this.pickupDuration).asSeconds()];
    this.pickupDurationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.pickupDuration, true);
    this.setTimeWindows();
    this.priority = stopPoint.priority;
    if (stopPoint.priority === this.globals.stopPointPriorityConstants['HIGH']) {
      this.pickupPriorityOn = true;
    } else {
      this.pickupPriorityOn = false;
    }
    const portalAccess = stopPoint.portal_access ? stopPoint.portal_access : this.globals.portalAccessConstants['NO_ACCESS'];
    if (portalAccess === this.globals.portalAccessConstants['NO_ACCESS']) {
      this.pickupPortalOn = false;
    } else {
      this.pickupPortalOn = true;
    }

    if (stopPoint.address) {
      this.setCollaboratorAddress(stopPoint.address);
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
      this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
    }

    this.recipientName = stopPoint.contact_name;

    this.myForm.patchValue({
      'stopPoint': {
        'supplier': this.supplier,
        'pickup_telephone': this.pickupTelephone,
        'pickupPhoneNumber': this.pickupPhoneNumber,
        'pickupCountryPrefix': this.pickupCountryPrefix,
        'pickup_time_window_unformatted': this.pickupTimeWindow,
        'pickup_duration_seconds': this.pickupDurationSeconds,
        'pickupPriorityOn': this.pickupPriorityOn,
        'pickupPortalOn': this.pickupPortalOn,
      }
    });

    this.getPriceLists(true);
    M.updateTextFields();
  }

  // test() {
  // }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    this.patchAddresses();

    // check country codes of both addresses to show appropriate  basic voucher services
    this.populateBasicVoucherServices();
  }

  setCollaboratorAddress(address) {
    this.collaboratorFreeformAddress = this.addressService.getAddressLabel(address);
    this.collaboratorPlaceName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.collaboratorCountryCode = address.countryCode;
    } else {
      this.collaboratorCountryCode = '';
    }
    if (address.state) {
      this.collaboratorState = address.state;
    } else {
      this.collaboratorState = '';
    }
    if (address.county) {
      this.collaboratorCounty = address.county;
    } else {
      this.collaboratorCounty = '';
    }
    if (address.city) {
      this.collaboratorCity = address.city;
    } else {
      this.collaboratorCity = '';
    }
    if (address.district) {
      this.collaboratorDistrict = address.district;
    } else {
      this.collaboratorDistrict = '';
    }
    if (address.street) {
      this.collaboratorStreet = address.street;
    } else {
      this.collaboratorStreet = '';
    }
    if (address.street2) {
      this.collaboratorStreet2 = address.street2;
    } else {
      this.collaboratorStreet2 = '';
    }
    if (address.houseNumber) {
      this.collaboratorHouseNumber = address.houseNumber;
    } else {
      this.collaboratorHouseNumber = '';
    }
    if (address.postalCode) {
      this.collaboratorPostalCode = address.postalCode;
    } else {
      this.collaboratorPostalCode = '';
    }
    if (address.isPlace) {
      this.collaboratorIsPlace = address.isPlace;
    } else {
      this.collaboratorIsPlace = false;
    }
    if (address.lat) {
      this.collaboratorLat = address.lat;
    }
    if (address.lon) {
      this.collaboratorLon = address.lon;
    }
    this.patchCollaboratorAddresses();

    // check country codes of both addresses to show appropriate  basic voucher services
    this.populateBasicVoucherServices();
  }

  setTimeWindows() {
    if (this.timeWindows.length) {
      this.timeWindowUnformatted = [];
      const timeWindowData = this.getTimeWindowData(this.timeWindows);
      this.pickupStartPrefixDays = timeWindowData.startPrefixDays;
      this.timeWindowUnformatted = timeWindowData.timeWindowUnformatted;
      this.timeWindow = timeWindowData.timeWindow;
      this.timeWindowIds = timeWindowData.ids;


      this.myForm.patchValue({
        'stopPoint': {
          'time_window_unformatted': this.timeWindow,
        }
      });
    }
    if (this.pickupTimeWindows.length) {
      this.pickupTimeWindowUnformatted = [];
      const pickupTimeWindowData = this.getTimeWindowData(this.pickupTimeWindows);
      this.pickupStartPrefixDays = pickupTimeWindowData.startPrefixDays;
      this.pickupTimeWindowUnformatted = pickupTimeWindowData.timeWindowUnformatted;
      this.pickupTimeWindowIds = pickupTimeWindowData.ids;
      this.pickupTimeWindow = pickupTimeWindowData.timeWindow;
      this.myForm.patchValue({
        'stopPoint': {
          'pickup_time_window_unformatted': this.pickupTimeWindow,
        }
      });
    }
  }

  getTimeWindowData(timeWindows) {
    let timeWindowRange, timeWindowRangeMinutes;
    let timeWindowUnformatted = [], finalTimeWindow = [], ids = [];
    let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
    let timeWindowStartUnformatted, timeWindowEndUnformatted;
    let startPrefixDays;
    // if (!timeWindows.length) {
    //   timeWindowUnformatted = this.defaultTimeWindowUnformatted;
    //   finalTimeWindow = this.defaultTimeWindow;
    // }
    timeWindows.forEach(timeWindow => {
      ids.push(timeWindow.id);
      startPrefixDays = timeWindow.start_prefix_days;
      timeWindowRange = timeWindow.time_window_range;
      timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
      if (timeWindow.time_window_start_datetime) {
        timeWindowStart = moment(timeWindow.time_window_start_datetime).format();
      } else if (timeWindow.start) {
        timeWindowStart = moment(timeWindow.start, 'HH:mm:SS').format();
      }
      timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
      timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
      timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');


      timeWindowUnformatted.push(timeWindowStartUnformatted);
      timeWindowUnformatted.push(timeWindowEndUnformatted);

      if (timeWindow.start_prefix_days == 1) {
        timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes() + 1440;
      } else {
        timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
      }

      // timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();
      timeWindowEndMinutes = timeWindowStartMinutes + moment.duration(timeWindowRange).asMinutes();


      finalTimeWindow = [timeWindowStartMinutes, timeWindowEndMinutes];
    });

    return { timeWindow: finalTimeWindow, timeWindowUnformatted: timeWindowUnformatted, startPrefixDays: startPrefixDays, ids: ids }
  }

  timeWindowSelected() {
    if (this.myForm.value.stopPoint.companyTimeWindow) {
      this.timeWindows = this.myForm.value.stopPoint.companyTimeWindow['CompanyTimeWindowCategory']['time_windows'][this.projectProblemDayOfWeek];
      this.setTimeWindows();
    }
  }

  patchAddresses() {
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });
  }

  patchCollaboratorAddresses() {
    this.myForm.patchValue({
      'stopPoint': {
        'collaborator': {
          'collaborator_address': {
            'countryCode': this.collaboratorCountryCode,
            'state': this.collaboratorState,
            'county': this.collaboratorCounty,
            'city': this.collaboratorCity,
            'district': this.collaboratorDistrict,
            'street': this.collaboratorStreet,
            'street2': this.collaboratorStreet2,
            'houseNumber': this.collaboratorHouseNumber,
            'postalCode': this.collaboratorPostalCode,
            'isPlace': this.collaboratorIsPlace,
            'value': this.collaboratorFreeformAddress,
            'lat': this.collaboratorLat,
            'lon': this.collaboratorLon,
          },
        },
      },
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'stopPoint': {
        'contact_name': this.contactName,
        'service': this.selectedService,
        'supplier': this.supplier,
        'temporary_assignor': this.temporaryAssignor,
        'service_type': this.serviceType,
        'project_problem_id': this.projectProblemIdForRequest,
        'telephone': this.telephone,
        'recipient_email': this.recipientEmail,
        'pickup_telephone': this.pickupTelephone,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'time_windows': this.timeWindows,
        'duration': this.duration,
        'priority': this.priority,
        'portal_access': this.portalAccess,
        'portalOn': this.portalOn,
        'portalPlus': this.portalPlus,
        'countryPrefix': this.countryPrefix,
        'agreed_shipping': this.agreedShipping,
        'fulfillment_status': this.fulfillmentStatus,
        'fulfillment_event': this.fulfillmentEvent,
        'mass_and_dimensions': this.massAndDimensions,
        'weight': this.weight,
        'load': this.load,
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery,
        'requested_payment_method_courier_fare': this.requestedPaymentMethodCourierFare,
        'executed_payment_method_courier_fare': this.executedPayOnDeliveryPaymentMethodCourierFare,
        'cheque_collection_date_courier_fare': this.chequeCollectionDateCourierFare,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
        },
        'pickupDestinations': {
          'modelName': this.pickupDestinationModelName,
          'modelId': this.pickupDestinationModelId,
        },
        'collaborator': {
          'collaboratorData': {
            'telephone': this.collaboratorTelephone,
            'countryPrefix': this.collaboratorCountryPrefix,
            'collaborator_name': this.collaboratorName,
            'id': this.collaboratorId,
            'consignor_id': this.consignorId,
            'consignor_name': this.consignorName
          },
        },
        'voucher': {
          'company_voucher_collaborators': this.collaboratorsArray,
          'options': {
            'hash': this.hash,
            'collaborator_type': this.consignorType,
            'basic_voucher_service_id': this.basicVoucherServiceId,
          }
        }
      },
    });

    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress && document.getElementById('recipient-address-custom-input')) {
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress.valueOf();
    }
    if (this.collaboratorFreeformAddress) {
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress.valueOf();
    }

  }

  patchWeightAndLoad() {
    this.myForm.patchValue({
      'stopPoint': {
        'mass_and_dimensions': this.massAndDimensions,
        'weight': this.weight,
        'load': this.load,
      },
    });

    M.updateTextFields();
  }

  patchCollaborator() {
    this.myForm.patchValue({
      'stopPoint': {
        'pickup_telephone': this.pickupTelephone,
        'pickupPhoneNumber': this.pickupPhoneNumber,
        'pickupCountryPrefix': this.pickupCountryPrefix,
        'collaborator': {
          'collaboratorData': {
            'telephone': this.collaboratorTelephone,
            'phoneNumber': this.collaboratorPhoneNumber,
            'countryPrefix': this.collaboratorCountryPrefix,
            'isThroughHub': this.isThroughHub,
            'collaborator_name': this.collaboratorName,
            'id': this.collaboratorId,
            'collaborator_type': this.collaboratorType
          },
        },
      },
    });

    M.updateTextFields();
  }

  patchPickup() {
    this.myForm.patchValue({
      'stopPoint': {
        'pickup_telephone': this.pickupTelephone,
        'pickupPhoneNumber': this.pickupPhoneNumber,
        'pickupCountryPrefix': this.pickupCountryPrefix,
        'supplier': this.supplier,
        'pickup_time_window_unformatted': this.pickupTimeWindow,
        'pickup_duration_seconds': this.pickupDurationSeconds,
        'pickupPriorityOn': this.pickupPriorityOn,
        'pickupPortalOn': this.pickupPortalOn,
        'voucher': {
          'options': {
            'payer': this.payer,
          },
        },
      },
    });
    M.updateTextFields();
  }

  setForm() {
    this.myForm.patchValue({
      'company_partner': this.selectedPartnerItem,
      'stopPoint': {
        'id': this.stopPointId,
        'stopPointId': this.stopPointId,
        'no_delivery': this.noDeliverySelected,
        'service_type': this.serviceType,
        'service': this.selectedService,
        'consignorTypeRadioButtonSelected': this.consignorTypeRadioButtonSelected,
        'contact_name': this.contactName,
        'supplier': this.supplier,
        'temporary_assignor': this.temporaryAssignor,
        'recipient_email': this.recipientEmail,
        'is_signature_mandatory': this.isSignatureMandatory,
        'is_ups_address_enabled': this.isUpsAddressEnabled,
        'pay_amount': this.payAmount,
        // 'email': this.email,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'telephone': this.telephone,
        'pickup_telephone': this.pickupTelephone,
        'pickupCountryPrefix': this.pickupCountryPrefix,
        'pickupPhoneNumber': this.pickupPhoneNumber,
        'companyTimeWindow': this.selectedTimeWindow,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'doubleTimeWindow': this.doubleTimeWindow,
        'time_windows': this.timeWindows,
        'time_window_unformatted': this.timeWindow,
        'time_window_unformatted2': this.timeWindowDouble,
        'duration_seconds': this.durationSeconds,
        'duration': this.duration,
        'final': this.final,
        'customerEntityType': this.customerEntityType,
        'relatedCustomerId': this.relatedCustomerId,
        'note': this.notes,
        'priority': this.priority,
        'priorityOn': this.priorityOn,
        'depot': this.selectedDepotItem,
        'portal_access': this.portalAccess,
        'portalOn': this.portalOn,
        'portalPlus': this.portalPlus,
        'agreedShippingDate': this.agreedShippingDate,
        'agreed_shipping': this.agreedShipping,
        'fulfillment_status': this.fulfillmentStatus,
        'fulfillment_event': this.fulfillmentEvent,
        'barcode': this.barcode,
        'loaded': this.loaded,
        'selectedDrivers': this.selectedDrivers,
        'selectedRecipient': this.selectedRecipient,
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery,
        'requested_payment_method_courier_fare': this.requestedPaymentMethodCourierFare,
        'executed_payment_method_courier_fare': this.executedPayOnDeliveryPaymentMethodCourierFare,
        'cheque_collection_date_courier_fare': this.chequeCollectionDateCourierFare,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
          'load': this.deliveryLoad,
        },
        'pickupDestinations': {
          'modelName': this.pickupDestinationModelName,
          'modelId': this.pickupDestinationModelId,
          'load': this.pickupLoad,
        },
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'term': this.selectedAddress,
        },
        'mass_and_dimensions': this.massAndDimensions,
        'mass_and_dimensions_data': [],
        'weight': this.weight,
        'load': this.load,
        'shipment_type': this.shipmentType,
        'shipment_service_type': this.shipmentServiceType,
        'voucher': {
          'company_voucher_collaborators': this.collaboratorsArray,
          'options': {
            'basic_voucher_service_id': this.basicVoucherServiceId,
            'discount': this.discount,
            'hash': this.hash,
            'payer': this.payer,
            'paid_by': this.paidBy,
            'addon_services': this.companyServices,
            'addon_charges': this.addonCharges,
            'price_list_id': this.selectedPriceListId,
            'insurance': this.insurance,
            'insurance_amount': this.insuranceAmount,
            'collaborator_type': this.consignorType,
            'transaction_document_type': this.transactionDocumentType,
            'voucher_ups_extra_fields': {
              'shipment_contents': this.shipmentContents,
              'total_package_value': this.totalPackageValue,
              'shipment_contents_description': this.shipmentContentsDescription,
              'charge_tax_to': this.chargeTaxTo,
              'address_line': this.upsAddressLine,
              'city': this.upsCity,
              'zip_code': this.upsZipCode,
              'country_code_iso3': this.upsCountry
            }
          },
        },
        'collaborator': {
          'collaboratorData': {
            'id': this.collaboratorId,
            'collaborator_name': this.collaboratorName,
            'collaborator_type': this.collaboratorType,
            'phoneNumber': this.collaboratorPhoneNumber,
            'telephone': this.collaboratorTelephone,
            'consignor_name': this.consignorName,
            'consignor_id': this.consignorId,
            'selectedCollaborator': this.selectedCollaborator,
            'selectedConsignor': this.selectedConsignor,
            'thirdPartyCheckboxSelected': this.thirdPartyCheckboxSelected,
            'isThroughHub': this.isThroughHub,
            'sameAsConsignorCheckboxSelected': this.sameAsConsignorCheckboxSelected
          },
          'collaborator_address': {
            'id': null,
            'countryCode': this.collaboratorCountryCode,
            'state': this.collaboratorState,
            'county': this.collaboratorCounty,
            'city': this.collaboratorCity,
            'district': this.collaboratorDistrict,
            'street': this.collaboratorStreet,
            'street2': this.collaboratorStreet2,
            'houseNumber': this.collaboratorHouseNumber,
            'postalCode': this.collaboratorPostalCode,
            'isPlace': this.collaboratorIsPlace,
            'value': this.collaboratorFreeformAddress,
            'lat': this.collaboratorLat,
            'lon': this.collaboratorLon,
            'term': this.collaboratorSelectedAddress,
          },
        },
      },
      'customer': {
        'email': this.email,
        'timeZone': this.timeZone,
        'portal_access': this.portalAccess,
      },
    });

    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress && document.getElementById('recipient-address-custom-input')) {
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress.valueOf();
    }
    if (this.collaboratorFreeformAddress) {
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress.valueOf();
    }
  }

  resetForm() {
    this.errors = [];
    this.recipientName = '';
    this.collaboratorType = String(this.globals.voucherPayerConstants['COMPANY']);
    this.stopPointId = null;
    this.serviceType = this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
    this.selectedService = this.defaultService;
    this.serviceTypeSelected = 'both';
    this.thirdPartyCheckboxSelected = false;
    this.isThroughHub = false;
    this.sameAsConsignorCheckboxSelected = false;
    if (this.globals.foodModeEnabled && this.globals.collaboratorModeEnabled) {
      this.serviceTypeSelected = 'sameDay';
      this.selectedService = this.basicServicesOptions.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.Express.key));
    }
    this.temporaryAssignor = null;
    this.resetCollaborators();
    this.systemicCourierName = null;
    this.projectProblemId = null;
    this.pickupProjectProblemId = null;
    this.projectProblemIdForRequest = null;
    this.isPaidByEntityCollaborator = false;
    this.insurance = false;
    this.insuranceAmount = null;
    this.contactName = '';
    this.supplier = '';
    this.smartPoint = null;
    this.isPaymentEditable = true;
    this.countryPrefix = this.globals.defaultCountryCode;
    this.pickupCountryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.pickupPhoneNumber = '';
    this.telephone = '';
    this.recipientEmail = '';
    this.pickupTelephone = '';
    this.transactionDocumentType = this.globals.transactionDocumentTypes['RECEIPT'];
    this.barcodes = [];
    this.isBarcodesListOpen = false;
    this.doubleTimeWindow = false;
    this.selectedPartnerItem = null;
    this.timeWindows = [];
    this.timeWindow = [480, 1200];
    this.timeWindowDouble = [480, 840, 1020, 1200];
    this.timeWindowUnformatted = ['08:00', '20:00'];
    this.timeWindowIds = [];
    this.pickupTimeWindowIds = [];
    this.duration = this.globals.defaultDuration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.pickupDuration = this.globals.defaultDuration;
    this.pickupDurationSeconds = [moment.duration(this.duration).asSeconds()];
    this.pickupDurationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.deliveryLoad = this.defaultLoad;
    this.pickupLoad = this.defaultLoad;
    this.deliverySourceModelName = '';
    this.deliverySourceModelId = null;
    this.pickupDestinationModelName = '';
    this.pickupDestinationModelId = null;
    this.isSignatureMandatory = false;
    this.isUpsAddressEnabled = false;
    this.shipmentContents = null;
    this.totalPackageValue = null;
    this.shipmentContentsDescription = null;
    this.upsAddressLine = null;
    this.upsCity = null;
    this.upsZipCode = null;
    this.upsCountry = null;
    this.chargeTaxTo = this.globals.voucherUpsChargeToConstants['CHARGE_TAX_TO_RECEIVER'];
    this.basicVoucherServiceId = null;
    this.final = false;
    this.requestedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.executedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.requestedPaymentMethodCourierFare = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.executedPayOnDeliveryPaymentMethodCourierFare = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');
    this.chequeCollectionDateCourierFare = moment().add(8, 'days').format('YYYY-MM-DD');
    this.notes = '';
    this.priority = '';
    this.priorityOn = false;
    this.pickupPriorityOn = false;
    this.freeformAddress = '';
    this.lat = '';
    this.lon = '';
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    this.collaboratorFreeformAddress = '';
    this.collaboratorLat = '';
    this.collaboratorLon = '';
    this.collaboratorCountryCode = '';
    this.collaboratorState = '';
    this.collaboratorCounty = '';
    this.collaboratorCity = '';
    this.collaboratorDistrict = '';
    this.collaboratorStreet = '';
    this.collaboratorStreet2 = '';
    this.collaboratorHouseNumber = '';
    this.collaboratorPostalCode = '';
    this.collaboratorIsPlace = false
    this.collaboratorPlaceName = '';
    this.addressFrom = {};
    this.collaboratorId = null;
    this.collaboratorName = '';
    this.consignorId = null;
    this.consignorName = '';
    this.recipientName = '';
    this.collaboratorPhoneNumber = '';
    this.collaboratorTelephone = [];
    this.customerEntityType = null;
    this.relatedCustomerId = null;
    this.email = '';
    this.payAmount = '';
    this.previousAgreedShipping = {};
    this.agreedShipping = [];
    this.agreedShippingDate = '';
    this.barcode = '';
    this.pickupBarcode = '';
    this.deliveryBarcode = '';
    this.selectedDrivers = [];
    this.agreedShippingDateChanged = false;
    this.inGeneralWarehouse = false;
    this.inWarehouse = false;
    this.fulfillmentStatus = null;
    this.fulfillmentEvent = {};
    this.timeZone = moment.tz.guess();
    this.portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    this.setPortalSwitches();
    this.positionSet = false;
    this.selectedTimeWindow = null;
    this.selectedTimeWindowId = null;
    this.addonServicesForSelectedPriceList = [];
    this.companyServices = [];
    this.addonCharges = [];
    this.massAndDimensions = [];
    this.weight = null;
    this.load = null;
    this.discount = null;
    this.hasManuallyAlteredDiscount = false;
    this.hash = '';
    this.payer = this.globals.voucherPayerConstants['COMPANY'];
    this.paidBy = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
    this.selectedRecipient = null;
    this.selectedCollaborator = null;
    this.selectedConsignor = null;
    this.warehouseId = null;
    this.shipmentType = this.shipmentTypeOptions[0]['value'];
    this.shipmentServiceType = null;
    this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].controls = [];
    this.items = [];
    this.collaboratorNameNgSelect.filter('');
    this.collaboratorAddressNgSelect.filter('');
    if (this.stopPointNgSelect) {
      this.stopPointNgSelect.filter('');
    }
    if (document.getElementById('recipient-address-custom-input')) {
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = '';
    }
    (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = '';
    this.recipientMapComponent.removeDraggableMarker();
    this.collaboratorMapComponent.removeDraggableMarker();
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    const dateElement = document.querySelector('#agreed-shipping-date-voucher');
    const dateInstances = M.Datepicker.init(dateElement);
    dateInstances.setDate(new Date(''));
    this.addItemToVoucher(null);
    this.hasPriceListManuallyAltered = false;

    // re-enable depot selection on reset
    this.myForm.controls['stopPoint']['controls']['depot'].enable();
  }

  collaboratorTypeChange(event) {
    this.collaboratorType = event.target.value;
    this.payer = Number(event.target.value);
    this.calculatePrice();
  }

  setPortalSwitches() {
    if (this.portalAccess === this.globals.portalAccessConstants['NO_ACCESS']) {
      this.portalOn = false;
      this.portalPlus = false;
    } else if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_NO_HISTORY']) {
      this.portalOn = true;
      this.portalPlus = false;
    } else if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
      this.portalOn = true;
      this.portalPlus = true;
    }
  }

  portalEnabled() {
    if (!this.globals.portalAccess && (this.myForm.value.stopPoint.portalOn || this.myForm.value.stopPoint.pickupPortalOn)) {
      this.viewProjectProblemService.enableSettingsPortal();
    }
    if (this.stopPointId && this.projectProblemDataService.solutionData && (this.myForm.value.stopPoint.portalOn || this.myForm.value.stopPoint.pickupPortalOn)) {
      const solutionInfoByRouteSettingIdByStopPointId = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'];
      if (this.stopPointId && solutionInfoByRouteSettingIdByStopPointId) {
        Object.keys(solutionInfoByRouteSettingIdByStopPointId).forEach(routeSettingId => {
          const solutionInfoByRouteSettingId = solutionInfoByRouteSettingIdByStopPointId[routeSettingId];
          if (solutionInfoByRouteSettingId[this.stopPointId]) {
            const driver = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId];
            if (driver) {
              if (driver.id) {
                const driverId = driver.id;
                const driverData = this.projectProblemDataService.drivers[driverId];
                if (!driverData.driver.portal_access_sw) {
                  this.viewProjectProblemService.enableDriverPortal(driverId);
                }
              }
            }
          }
        });
      }
    }
  }

  portalHistoryEnabled() {
  }

  loadAssignedDrivers(data) {
    if (this.stopPointId) {
      if (data.route) {
        const routeIds = data.route[this.stopPointId];
        this.selectedDrivers = [];
        routeIds.forEach(routeId => {
          if (this.projectProblemDataService.routeSettingsById[routeId]) {
            const driverId = this.projectProblemDataService.routeSettingsById[routeId]['driver']['id'];
            const driver = this.projectProblemDataService.drivers[driverId];
            this.selectedDrivers.push(driver);
          }
        });
        this.myForm.patchValue({
          'stopPoint': {
            'selectedDrivers': this.selectedDrivers,
          }
        });
      }
    }
  }

  assignToDrivers() {
    if (this.stopPointId) {
      // this.viewProjectProblemService.moveStopPoints([this.stopPointId]);
    }
  }

  changeLoadedStatus() {
    const checkbox = document.getElementById('stop-point-form-loaded-checkbox') as HTMLInputElement;
    if (checkbox.checked) {
      this.viewProjectProblemService.loadStopPoints([this.stopPointId]);
    } else {
      const loadStopsUrl = this.loadStopsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      const myObserver = {
        next: (response) => {
          if (response) {
            this.projectProblemDataService.loadedStops = response['items'];
          }
        },
        error: (error) => { },
        complete: () => { },
      };

      this.http.delete(loadStopsUrl + '?stopPointIds=' + this.stopPointId).pipe(take(1)).subscribe(myObserver);
    }
  }

  initializeSlider() {
    this.timeWindowOptions = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 1200],
      connect: [false, true, false],
      range: {
        min: 0,
        max: 1560
      },
    };
    this.timeWindowOptionsDouble = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 840, 1020, 1200],
      connect: [false, true, false, true, false],
      range: {
        min: 0,
        max: 1560
      },
    };
    this.durationOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 15,
      start: [120],
      connect: 'lower',
      range: {
        min: 0,
        max: 1800
      },
    };
  }

  convertSecondsToTime() {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.myForm.value.stopPoint.duration_seconds);
    seconds = this.convertToMinute(this.myForm.value.stopPoint.duration_seconds, minutes);
    this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  }

  pickupConvertSecondsToTime() {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.myForm.value.stopPoint.pickup_duration_seconds);
    seconds = this.convertToMinute(this.myForm.value.stopPoint.pickup_duration_seconds, minutes);
    this.pickupDurationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  }

  timeWindowChange() {
    this.calculateTimeWindowCharge();
    this.calculatePrice();
  }

  convertValuesToTime(valuesInMinutes) {
    if (valuesInMinutes[0] >= 1440) {
      this.recipientStartPrefixDays = 1;
    } else {
      this.recipientStartPrefixDays = 0;
    }

    this.hasManuallyAlteredTimeWindows = true;
    let hours = 0, minutes = 0;
    if (this.myForm.value.stopPoint.doubleTimeWindow) {
      this.myForm.value.stopPoint.time_window_unformatted2.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        hours = this.checkHoursForNextDay(hours);
        this.timeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
      });
    } else {
      this.myForm.value.stopPoint.time_window_unformatted.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        hours = this.checkHoursForNextDay(hours);
        this.timeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
      });
    }
  }

  pickupConvertValuesToTime(valuesInMinutes) {
    if (valuesInMinutes[0] >= 1440) {
      this.pickupStartPrefixDays = 1;
    } else {
      this.pickupStartPrefixDays = 0;
    }

    let hours = 0, minutes = 0;
    this.myForm.value.stopPoint.pickup_time_window_unformatted.forEach((timeWindow, i) => {
      hours = this.convertToHour(timeWindow);
      minutes = this.convertToMinute(timeWindow, hours);
      hours = this.checkHoursForNextDay(hours);
      this.pickupTimeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
    });
  }

  // converts values higher than 24 to their time equivalents
  checkHoursForNextDay(hours) {
    if (hours > 24) {
      switch (hours) {
        case 25:
          hours = 1;
          break;
        case 26:
          hours = 2;
          break;
      }
    }
    return hours;
  }

  convertToHour(value) {
    return Math.floor(value / 60);
  }

  convertToMinute(value, hour) {
    return value - hour * 60;
  }

  formatHoursAndMinutes(hours, minutes) {
    if (hours.toString().length === 1) {
      hours = '0' + hours;
    }
    if (minutes.toString().length === 1) {
      minutes = '0' + minutes;
    }
    if (hours == 24) {
      hours = '00';
    }
    return hours + ':' + minutes;
  }

  shipmentTypeChange() {
    this.shipmentType = this.myForm.value.stopPoint.shipment_type;
    this.getPriceLists();
  }

  // calls a service to determine if the stop point is for pickup or not to set the right same day delivery
  serviceChange(event) {
    this.selectedService = event;
    if (event.type == this.globals.priceListServicesEnum.NextDay.key) {
      if (this.noDeliverySelected) {
        this.serviceTypeSelected = 'pickup';
        this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
        this.collaboratorSectionLabel = this.assignorLabel;
        this.stopPointLabel = this.collaboratorLabel;
      } else {
        this.serviceTypeSelected = 'both';
        this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
        this.collaboratorSectionLabel = this.collaboratorLabel;
        this.stopPointLabel = this.receiverLabel;
      }
    } else if(event.type == this.globals.priceListServicesEnum.ThroughHub.key) {
      this.serviceTypeSelected = 'sameDay';
      this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
      this.collaboratorSectionLabel = this.collaboratorLabel;
      this.stopPointLabel = this.receiverLabel;
      this.isThroughHub = true;
    } else if(event.type == this.globals.priceListServicesEnum.Express.key) {
      this.serviceTypeSelected = 'sameDay';
      this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
      this.collaboratorSectionLabel = this.collaboratorLabel;
      this.stopPointLabel = this.receiverLabel;
    }
    this.calculateShipmentType();
    this.getPriceLists();
  }

  toggleConsignor(event) {
    this.thirdPartyCheckboxSelected = event.target.checked;

    if (this.thirdPartyCheckboxSelected) {
      this.consignorType = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
    } else {
      this.selectedConsignor = null;
      this.collaboratorConsignor = null;
      this.paidBy = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
    }

    this.myForm.patchValue({
      'stopPoint': {
        'collaborator': {
          'collaboratorData': {
            'thirdPartyCheckboxSelected': this.thirdPartyCheckboxSelected,
            'selectedConsignor': this.selectedConsignor
          }
        },
        'voucher': {
          'options': {
            'paid_by': this.paidBy
          },
        },
      }
    });

    this.populateChargeOptions();
    this.getPriceLists();
  }

  consignorTypeChange(event = null) {
    const prevTypeSelected = this.consignorTypeRadioButtonSelected;
    this.consignorTypeRadioButtonSelected = event ? Number(event.target.value) : this.consignorTypeRadioButtonSelected;
    const isDifferentRadioButtonClicked = prevTypeSelected == this.consignorTypeRadioButtonSelected ? false : true;
    const collaborator = this.getCollaboratorFromAllEntities();
    const selectedPartnerCollaboratorId = this.selectedPartnerItem['collaborator_id'];
    const selectedPartnerCollaboratorName = this.selectedPartnerItem['collaborator_name'];
    if (isDifferentRadioButtonClicked) {
      if (this.consignorTypeRadioButtonSelected == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
        // if prev type was receiver
        if (prevTypeSelected == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
          this.consignorType = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
          this.collaboratorType = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
          this.thirdPartyCheckboxSelected = false;
          this.selectedRecipient = {};
          this.contactName = this.supplier;

          this.supplier = this.myForm.value.stopPoint.contact_name;

          this.populateChargeOptions();

          this.swapAddresses();

          // when switching to sender, set sender as the logged in collaborator
          this.collaboratorId = selectedPartnerCollaboratorId;
          this.collaboratorName = selectedPartnerCollaboratorName;
          this.selectedCollaborator = {
            companyCollaborator: {
              collaborator: {
                collaboratorData: {
                  id: selectedPartnerCollaboratorId,
                  collaborator_name: this.collaboratorName,
                }
              }
            }
          };

          this.collaboratorReceiver = null;
          this.collaboratorConsignor = null;
          this.collaboratorNormal = collaborator;

          this.myForm.patchValue({
            'stopPoint': {
              'selectedRecipient': this.selectedRecipient,
              'contact_name': this.contactName,
              'phoneNumber': this.myForm.value.stopPoint.collaborator.collaboratorData.phoneNumber,
              'supplier': this.collaboratorName,
              'pickupPhoneNumber': this.myForm.value.stopPoint.phoneNumber,
              'collaborator': {
                'collaboratorData': {
                  'phoneNumber': this.myForm.value.stopPoint.phoneNumber,
                  'selectedCollaborator': this.selectedCollaborator,
                }
              }
            },
          });
        }
        // if prev type was assignor
        else if (prevTypeSelected == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
          this.consignorType = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
          this.collaboratorType = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
          this.thirdPartyCheckboxSelected = false;
          this.populateChargeOptions();

          // when switching to sender, set sender as the logged in collaborator
          this.collaboratorId = selectedPartnerCollaboratorId;
          this.collaboratorName = selectedPartnerCollaboratorName;
          this.selectedCollaborator = {
            companyCollaborator: {
              collaborator: {
                collaboratorData: {
                  id: selectedPartnerCollaboratorId,
                  collaborator_name: this.collaboratorName,
                }
              }
            }
          };

          this.collaboratorReceiver = null;
          this.collaboratorConsignor = null;
          this.collaboratorNormal = collaborator;
          this.selectedConsignor = null;
          this.consignorId = null;

          const shipperAddress = this.globals.partnersArray[0]['partner_depot_address'];
          this.setCollaboratorAddress(shipperAddress);
          (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
          this.collaboratorMapComponent.showDraggableMarker(Number(shipperAddress.lat), Number(shipperAddress.lon));

          this.myForm.patchValue({
            'stopPoint': {
              'supplier': this.collaboratorName,
              'collaborator': {
                'collaboratorData': {
                  'phoneNumber': this.myForm.value.stopPoint.phoneNumber,
                  'selectedCollaborator': this.selectedCollaborator,
                  'selectedConsignor': this.selectedConsignor
                }
              }
            },
          });
        }
      } else if (this.consignorTypeRadioButtonSelected == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
        // if prev type was sender
        if (prevTypeSelected == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
          this.consignorType = this.globals.stopPointCollaboratorTypesConstants['RECEIVER'];
          this.collaboratorType = this.globals.stopPointCollaboratorTypesConstants['RECEIVER'];
          this.thirdPartyCheckboxSelected = false;
          this.supplier = this.myForm.value.stopPoint.contact_name;
          this.contactName = this.collaboratorName;
          this.collaboratorId = null;
          this.selectedCollaborator = {};
          this.recipientId = selectedPartnerCollaboratorId;
          this.populateChargeOptions();
          this.selectedRecipient = {
            companyCollaborator: {
              collaborator: {
                collaboratorData: {
                  id: this.recipientId,
                  collaborator_name: this.collaboratorName,
                }
              }
            }
          };

          this.collaboratorNormal = null;
          this.collaboratorConsignor = null;
          this.collaboratorReceiver = collaborator;

          this.swapAddresses();

          this.myForm.patchValue({
            'stopPoint': {
              'selectedRecipient': this.selectedRecipient,
              'contact_name': this.contactName,
              'phoneNumber': this.myForm.value.stopPoint.collaborator.collaboratorData.phoneNumber,
              'supplier': this.supplier,
              'pickupPhoneNumber': this.myForm.value.stopPoint.phoneNumber,
              'collaborator': {
                'collaboratorData': {
                  'phoneNumber': this.myForm.value.stopPoint.phoneNumber,
                  'selectedCollaborator': this.selectedCollaborator,
                }
              }
            },
          });
        }
        // if prev type was assignor
        else if (prevTypeSelected == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
          this.consignorType = this.globals.stopPointCollaboratorTypesConstants['RECEIVER'];
          this.collaboratorType = this.globals.stopPointCollaboratorTypesConstants['RECEIVER'];
          this.thirdPartyCheckboxSelected = false;
          this.supplier = this.myForm.value.stopPoint.contact_name;
          this.contactName = this.collaboratorName;
          this.collaboratorId = null;
          this.selectedCollaborator = {};
          this.recipientId = selectedPartnerCollaboratorId;
          this.populateChargeOptions();
          this.selectedRecipient = {
            companyCollaborator: {
              collaborator: {
                collaboratorData: {
                  id: this.recipientId,
                  collaborator_name: this.collaboratorName,
                }
              }
            }
          };

          this.collaboratorNormal = null;
          this.collaboratorConsignor = null;
          this.collaboratorReceiver = collaborator;
          this.selectedConsignor = null;
          this.consignorId = null;

          const shipperAddress = this.globals.partnersArray[0]['partner_depot_address'];
          this.setAddress(shipperAddress);
          (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
          this.recipientMapComponent.showDraggableMarker(Number(shipperAddress.lat), Number(shipperAddress.lon));

          this.myForm.patchValue({
            'stopPoint': {
              'selectedRecipient': this.selectedRecipient,
              'contact_name': this.contactName,
              'phoneNumber': this.myForm.value.stopPoint.collaborator.collaboratorData.phoneNumber,
              'collaborator': {
                'collaboratorData': {
                  'selectedConsignor': this.selectedConsignor
                }
              }
            },
          });
        }
      } else {
        // when choosing "I will be Assignor", only empty the form that was selected before choosing "I will be assignor"
        // e.g. Moving from sender to assignor empties the sender's form & moving from receiver to assignor empties the recipient's form
        this.consignorType = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
        this.collaboratorType = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
        this.thirdPartyCheckboxSelected = true;
        this.selectedRecipient = {};
        this.consignorName = this.collaboratorName;
        this.consignorId = selectedPartnerCollaboratorId;
        this.selectedConsignor = {
          companyCollaborator: {
            collaborator: {
              collaboratorData: {
                id: selectedPartnerCollaboratorId,
                collaborator_name: this.collaboratorName,
              }
            }
          }
        };

        this.collaboratorNormal = null;
        this.collaboratorReceiver = null;
        this.collaboratorConsignor = collaborator;

        // Receiver to Assignor
        if (prevTypeSelected == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
          this.contactName = '';
          this.phoneNumber = null;
          this.populateChargeOptions();
          this.setAddress(this.addressService.emptyAddressObj);
          this.address = null;
          (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = '';
          this.recipientMapComponent.removeDraggableMarker();
        }
        // Sender to Assignor
        else if (prevTypeSelected == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
          this.contactName = this.myForm.value.stopPoint.contact_name;
          this.phoneNumber = this.myForm.value.stopPoint.phoneNumber;
          this.collaboratorId = null;
          this.supplier = '';
          this.selectedCollaborator = {};
          this.pickupPhoneNumber = '';
          (<HTMLInputElement>document.getElementById('consignor-name-voucher-form')).value = this.globals.companyData['name'];
          this.populateChargeOptions();
          this.setCollaboratorAddress(this.addressService.emptyAddressObj);
          (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = '';
          this.collaboratorMapComponent.removeDraggableMarker();
        }

        this.myForm.patchValue({
          'stopPoint': {
            'selectedRecipient': this.selectedRecipient,
            'contact_name': this.contactName,
            'address': this.address,
            'phoneNumber': this.phoneNumber,
            'pickupPhoneNumber': this.pickupPhoneNumber,
            'supplier': this.supplier,
            'collaborator': {
              'collaborator_address': this.myForm.value.stopPoint.collaborator.collaborator_address,
              'collaboratorData': {
                'collaborator_name': '',
                'id': null,
                'selectedCollaborator': this.selectedCollaborator,
                'selectedConsignor': this.selectedConsignor,
              }
            }
          },
        });
      }

      if (this.globals.efoodHackEnabled) {
        this.paidBy = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
        this.myForm.patchValue({
          'stopPoint': {
            'voucher': {
              'options': {
                'paid_by': this.paidBy
              },
            },
          }
        });
      }

      M.updateTextFields();
    }

    this.isPaidByEntityCollaborator = this.checkCourierFareCollaborator();
    this.getPriceLists();
  }

  pickupModeChange(event) {
    this.noDeliverySelected = event.target.checked;

    if (this.noDeliverySelected) {
      this.toggleConsignor({ target: { checked: true } });
      this.serviceTypeSelected = 'pickup';
      this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
      this.collaboratorSectionLabel = this.assignorLabel;
      this.stopPointLabel = this.collaboratorLabel;
      this.paidBy = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
      this.myForm.patchValue({
        'stopPoint': {
          'voucher': {
            'options': {
              'paid_by': this.paidBy
            }
          }
        }
      });
    } else {
      this.serviceTypeSelected = 'both';
      this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
      this.collaboratorSectionLabel = this.collaboratorLabel;
      this.stopPointLabel = this.receiverLabel;
      this.paidBy = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
      this.myForm.patchValue({
        'stopPoint': {
          'voucher': {
            'options': {
              'paid_by': this.paidBy
            }
          }
        }
      });
    }

    this.populateChargeOptions();

  }

  getCollaboratorFromAllEntities() {
    if (this.collaboratorNormal) {
      return this.collaboratorNormal;
    } else if (this.collaboratorReceiver) {
      return this.collaboratorReceiver;
    } else if (this.collaboratorConsignor) {
      return this.collaboratorConsignor;
    }
  }

  swapAddresses() {
    const collaboratorAddress = { ...this.myForm.value.stopPoint.collaborator.collaborator_address };
    const address = { ...this.myForm.value.stopPoint.address };

    if (collaboratorAddress.lat && collaboratorAddress.lon) {
      this.setAddress(collaboratorAddress);
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
      this.recipientMapComponent.showDraggableMarker(Number(collaboratorAddress.lat), Number(collaboratorAddress.lon));
    }
    // in case of empty address, empty the address field & remove draggable marker
    else {
      this.setAddress(collaboratorAddress);
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
      this.recipientMapComponent.removeDraggableMarker();
    }

    if (address.lat && address.lon) {
      this.setCollaboratorAddress(address);
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
      this.collaboratorMapComponent.showDraggableMarker(Number(address.lat), Number(address.lon));
    }
    // in case of empty address, empty the address field & remove draggable marker
    else {
      this.setCollaboratorAddress(address);
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
      this.collaboratorMapComponent.removeDraggableMarker();
    }

    this.myForm.patchValue({
      'stopPoint': {
        'address': this.myForm.value.stopPoint.address,
        'collaborator': {
          'collaborator_address': this.myForm.value.stopPoint.collaborator.collaborator_address
        }
      },
    });
  }

  throwErrors(error) {
    const errors = this.errors;
    const keyify = (obj, prefix = '') =>
      Object.keys(obj).reduce((res, el) => {
        if (obj[el]) {
          if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
            errors.push(obj[el]['0']);
          }
          if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
            if (obj[el]['0']['load']) {
              errors.push(obj[el]['0']['load']['0']);
            }
          }
        }
        if (Array.isArray(obj[el])) {
          return res;
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
          return [...res, ...keyify(obj[el], prefix + el + '.')];
        } else {
          return [...res, prefix + el];
        }
      }, []);
    const output = keyify(error);
    this.errors = errors;
  }

  checkForAlreadyOptimizingError(error) {
    if (error['error']['errors']) {
      if (error['error']['errors']['project']['problem']) {
        if (error['error']['errors']['project']['problem'][this.projectProblemId]) {
          if (error['error']['errors']['project']['problem'][this.projectProblemId]['base'][0] == "Already optimizing") {
            this.resetForm();
            this.toggleModal.emit('close');
            this.milyService.alreadyOptimizing();
          }
        }
      }
    }
  }

  openPrintTab() {
    window.open(window.location.origin + '/receiptPrint', '_blank').focus();
  }

  loadReceipt(data) {
    let receiptData = this.chargeDetails;
    receiptData['id'] = data.voucher.id;
    receiptData['creation_datetime'] = data.voucher.creation_datetime;
    receiptData['qrLink'] = data.voucher.my_data_receipt_link;
    localStorage.setItem('receiptData', JSON.stringify(receiptData));
    // opening a tab async causes browsers to block the popup
    // so we click at an invisible button and this triggers the window.open
    document.getElementById('print-receipt-button-fix').click();
  }

  checkAndLoadReceipt(deliveryData) {
    // wrapper to make this work for single & multiple sp creation
    if (deliveryData.items) { deliveryData = deliveryData.items[0]; }


    if (deliveryData) {
      let stopPointData = deliveryData['stopPoint'];
      if (stopPointData) {
        const voucherOptions = stopPointData.voucher;
        if (voucherOptions) {
          if (voucherOptions.transaction_document_type == this.globals.transactionDocumentTypes['RECEIPT']) { //company
            this.loadReceipt(stopPointData);
          }
        }
      }
    }
  }

  upsAddressCheckboxToggle(event) {
    this.isUpsAddressEnabled = event.target.checked;

    // reset receiver address on un-check
    if (!this.isUpsAddressEnabled) {

    }

    // reset ups address fields
    this.upsAddressLine = '';
    this.upsCity = '';
    this.upsZipCode = '';
    this.upsCountry = '';
    this.isWaitingForUpsAddress = false;

    this.myForm.patchValue({
      'stopPoint': {
        'is_ups_address_enabled': this.isUpsAddressEnabled,
        'voucher': {
          'options': {
            'voucher_ups_extra_fields': {
              'address_line': this.upsAddressLine,
              'city': this.upsCity,
              'zip_code': this.upsZipCode,
              'country_code_iso3': this.upsCountry
            }
          },
        },
      }
    });
  }

  checkUpsAddress() {
    const stopFormValues = this.myForm.value.stopPoint;
    const addressLine = stopFormValues.voucher.options.voucher_ups_extra_fields.address_line;
    const postalCode = stopFormValues.voucher.options.voucher_ups_extra_fields.zip_code;
    const city = stopFormValues.voucher.options.voucher_ups_extra_fields.city;
    const countryCode = countries.toAlpha3(stopFormValues.voucher.options.voucher_ups_extra_fields.country_code_iso3);

    // perform a request to HERE with the custom address fields provided & fill the actual address object of the recipient
    // then perform a request to charges and check if
    if (addressLine && postalCode && city && countryCode) {
      this.isWaitingForUpsAddress = true;
      const searchTerm = `${addressLine} ${city} ${postalCode}`;
      const params = `?searchQuery=${searchTerm}&type=0`;
      this.http.get('api/v1/search/places' + params).subscribe(res => {
        // if no address is found
        if (!res['items']['items'].length) {
          alert(this.wrongUpsAddressError);
        } else {
          const address = res['items']['items'][0]['address'];
          const lat = res['items']['items'][0]['position'][0];
          const lon = res['items']['items'][0]['position'][1];
          address['lat'] = lat;
          address['lon'] = lon;
          this.setAddress(address);
          setTimeout(() => {
            (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
          }, 200);
          this.recipientMapComponent.showDraggableMarker(lat, lon);
          this.getPriceLists(false, true);
        }

        this.isWaitingForUpsAddress = false;
      });
    }
  }

  calculateShipmentType() {
    if (!this.chargesEnabled) {
      this.shipmentServiceType = !this.chargesEnabled ? this.selectedService['shipment_service_type'] : null;
      const stopFormValues = this.myForm.value.stopPoint;
      
      if (this.chargesEnabled) {
        this.shipmentType = stopFormValues.shipment_type;
      } else {
        if (stopFormValues.service.constant) {
          this.shipmentType = stopFormValues.service.constant;
        } else {
          this.shipmentType = stopFormValues.shipment_type;
        }
      }
      this.myForm.patchValue({
        'stopPoint': {
          'shipment_type': this.shipmentType,
          'shipment_service_type': this.shipmentServiceType,
        }
      });
    }
  }

  // used as a way to pass default values to the form (and optionally perform another charges request with the updated data) on submit
  finalizeFieldsOnSubmit() {
    const stopFormValues = this.myForm.value.stopPoint;
    this.calculateShipmentType();

    if (stopFormValues.load !== '' && stopFormValues.load) {
      this.load = Number(stopFormValues.load);
    } else {
      this.load = this.defaultLoad;
    }

    if (stopFormValues.weight !== '' && stopFormValues.weight) {
      this.weight = Number(stopFormValues.weight);
    } else {
      this.weight = this.defaultLoad / this.globals.volumeDenominatorConstant;
    }

    // if charges enabled and shop to shop/door select defaultExpressService
    this.selectedService = stopFormValues.service;
    const defaultExpressService = this.basicServicesOptions.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.Express.key));
    const defaultNextDayService = this.basicServicesOptions.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.NextDay.key));

    // this agreement is canceled, I will keep it just in case...
    // if (this.chargesEnabled && defaultExpressService) {
    //   const shipmentType = stopFormValues.shipment_type;
    //   if (shipmentType == this.globals.priceListShipmentEnum.ShopShop.key || shipmentType == this.globals.priceListShipmentEnum.ShopDoor.key) {
    //     this.selectedService = defaultExpressService;
    //   }
    // }

    if (this.chargesEnabled && defaultNextDayService) {
      const shipmentType = stopFormValues.shipment_type;
      if (shipmentType == this.globals.priceListShipmentEnum.DoorShop.key) {
        this.selectedService = defaultNextDayService;
      }
    }

    this.myForm.patchValue({
      'stopPoint': {
        'service': this.selectedService,
        'load': this.load,
        'weight': this.weight
      }
    });
  }

  bufferSubmitButtonPress() {
    this.finalizeFieldsOnSubmit();

    // buffer the user's click on the submit button & wait for charges request to finish loading - then process the submit
    let bufferInterval = setInterval(() => {

      if (!this.priceListsRequestPending && !this.priceRequestPending && !this.isWaitingForUpsAddress) {
        this.submitButtonPressed();
        clearInterval(bufferInterval);
        bufferInterval = null;
      }
    }, 100);
  }

  submitButtonPressed(cancelConfirmed = 'notSet') {
    this.isClickedOnce = true;
    // skip confirm if:
    // confirm was shown OR
    // sp is unscheduled OR
    // sp is newly added (so unscheduled)
    // agreedShippingDateChanged field was not changed OR
    // we are in the warehouse grid
    if (
      cancelConfirmed !== 'notSet' ||
      this.fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED'] ||
      !this.stopPointId ||
      !this.agreedShippingDateChanged ||
      this.inGeneralWarehouse ||
      !this.projectProblemId
    ) {
      if (this.serviceTypeSelected === 'sameDay' || this.serviceTypeSelected === 'both') {

        // enable depot field to use its data
        this.myForm.controls['stopPoint']['controls']['depot'].enable();

        this.setupSameDayOrNextDayPickupForm(cancelConfirmed);
        this.setupSameDayOrNextDayDeliveryForm();

        const stopPointCreationObserver = {
          next: (response) => {
            let deliveryData, pickupData;

            if (response['items'].length) {
              response['items'].forEach(stopPoint => {
                if (stopPoint['stopPoint']['service_type'] == this.globals.stopPointServiceTypeConstants['PICKUP']) {
                  pickupData = stopPoint;
                } else {
                  deliveryData = stopPoint;
                }
              });
            }

            // [food mode]: refresh grid count in collaborator overview
            if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && this.globals.isInRoute('collaboratrOverview')) {
              this.viewProjectProblemService.updateStopPointsCounter();
            }
            // [food mode]: allow request for pickup when form is submitted successfully (only for creation - not edit)
            if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
              this.genericService.canRequestForPickup = true;
            }

            this.genericService.newShipmentsGenerateGridData();
            this.checkAndLoadReceipt(deliveryData); // this needs to work for both types of requests (in case it's a delivery for example)
            this.isClickedOnce = false;
            if (this.projectProblemId) {
              this.viewProjectProblemService.updateProjectProblemStatus();
              // show response sp in map
              // if is create
              // or if the sp exists in pp
              if (pickupData['stopPoint']['project_problem_id'] === this.projectProblemId) {
                this.projectProblemDataService.addStopPoint(pickupData);
              }

              // show response sp in map
              // if is create and is same day
              // or if the sp exists in pp
              if (
                this.projectProblemDataService.stopPoints[deliveryData['stopPoint']['id']] ||
                deliveryData['stopPoint']['project_problem_id'] === this.projectProblemId ||
                (!this.stopPointId && this.serviceTypeSelected === 'sameDay')
              ) {
                // if the delivery SP has project_problem_id same as the one we are in, add it in PP or update it
                // if the delivery SP doesn't have project_problem_id same as the one we are in, remove it if it is in this PP
                // this means it is not in this PP
                if (deliveryData['stopPoint']['project_problem_id'] === this.projectProblemId) {
                  this.projectProblemDataService.addStopPoint(deliveryData);
                } else {
                  const removeSPFromPPMessage =
                    'Remove Stop Point from Project Problem log: ' +
                    'Removing sp with id: ' +
                    deliveryData['stopPoint']['id'] +
                    ' because it did not have PPID. ' +
                    'Response was: ' +
                    JSON.stringify(deliveryData);

                  Sentry.captureMessage(removeSPFromPPMessage);

                  this.projectProblemDataService.removeStopPoint(this.projectProblemDataService.stopPoints[deliveryData['stopPoint']['id']]);
                }
              }
              this.viewProjectProblemService.updateWarehouseGrid();
              this.modalGridService.updateVouchersGrid();
              this.modalService.updateStopPointsGrid();
              // this.modalService.updateReportShipmentsModal();

              this._notificationSvc.showSuccess('', this.stopPointSavedLabel);
            } else {
              this.viewProjectProblemService.updateWarehouseGrid();
              this.modalGridService.updateVouchersGrid();
              this.modalService.updateStopPointsGrid();
              // this.modalService.updateReportShipmentsModal();
            }
            this.toggleModal.emit('close');
          },
          error: (error) => {
            const stopPointsErrors = error?.error?.errors?.project?.problem?.stopPoints;
            const firstVoucherError = stopPointsErrors?.['1']?.voucher?.[0];
            const secondVoucherError = stopPointsErrors?.['2']?.voucher?.[0];
            const errorString = "No applicable price list found on stop point creation.";
            if (firstVoucherError === errorString || secondVoucherError === errorString) {
              this._notificationSvc.showWarning('', this.priceListNotFoundLabel);
              alert(this.priceListNotFoundLabel);
            }

            this.formErrorsUtils.checkResponseForErrorCodes(error);
            this.checkForAlreadyOptimizingError(error);
            this.isClickedOnce = false;
            this.throwErrors(error);
          },
        };

        // new stop point
        if (!this.stopPointId) {
          const hash = moment().format('ssSSS') + String(Math.round(Math.random() * (500 - 100) + 100));
          this.deliveryToSave['stopPoint']['deliverySources']['requestItemIdentifierHash'] = hash;
          this.pickupToSave['stopPoint']['requestItemIdentifierHash'] = hash;
          const stopPointsForSubmit = {
            stopPoints: [
              this.pickupToSave,
              this.deliveryToSave
            ]
          };

          this.stopPointService.saveStopPointWithPickup(stopPointsForSubmit, this.stopPointId, this.projectProblemId, this.inWarehouse).subscribe(stopPointCreationObserver);
        }
        // edit stop point
        else {
          const stopPointsForSubmit = {
            stopPoints: [
              this.pickupToSave,
              this.deliveryToSave
            ]
          };

          this.stopPointService.saveStopPointWithPickup(stopPointsForSubmit, this.stopPointId, this.projectProblemId, this.inWarehouse).subscribe(stopPointCreationObserver);
        }
      } else {
        this.submitStopForm(cancelConfirmed).pipe(take(1)).subscribe(data => {
          this.genericService.newShipmentsGenerateGridData();

          this.checkAndLoadReceipt(data);

          this.isClickedOnce = false;
          const stopFormValues = this.myForm.value.stopPoint;

          if (this.projectProblemId) {
            this.viewProjectProblemService.updateProjectProblemStatus();
            data['items'].forEach(stopPointResponseData => {
              this.projectProblemDataService.addStopPoint(stopPointResponseData);
            });
          } else { }

          this.viewProjectProblemService.updateWarehouseGrid();
          this.modalGridService.updateVouchersGrid();
          this.modalService.updateStopPointsGrid();
          // this.modalService.updateReportShipmentsModal();

          this.toggleModal.emit('close');
        }, error => {
          this.checkForAlreadyOptimizingError(error);
          this.isClickedOnce = false;
          this.throwErrors(error);
        });
      }
    } else {
      this.viewProjectProblemService.throwCancelConfirm();
    }
  }

  prepareForSubmit(cancelConfirmed) {
    this.myForm.controls['stopPoint']['controls']['depot'].enable();

    const stopFormValues = this.myForm.value.stopPoint;
    const collaboratorFormValues = stopFormValues.collaborator;
    const voucherFormValues = stopFormValues.voucher;
    const customerFormValues = this.myForm.value.customer;

    // prepare collaborators array (only for voucher)
    if (voucherFormValues) {
      this.collaboratorsArray = this.prepareCollaboratorsArray(stopFormValues);
    }

    // prepare temporary assignor in case of no delivery / no pickup
    if (voucherFormValues) {
      this.prepareTemporaryAssignor();
    }

    if (stopFormValues.contact_name) {
      this.contactName = stopFormValues.contact_name;
      if (stopFormValues.contact_name === this.noNameLabel) {
        this.contactName = this.noNameConstant;
      }
    } else {
      this.contactName = this.noNameConstant;
    }
    if (stopFormValues.priorityOn) {
      this.priority = this.globals.stopPointPriorityConstants['HIGH'];
    } else {
      this.priority = this.globals.stopPointPriorityConstants['NORMAL'];
    }
    if (stopFormValues.portalOn) {
      if (stopFormValues.portalPlus) {
        this.portalAccess = this.globals.portalAccessConstants['ACCESS_WITH_HISTORY'];
      } else {
        this.portalAccess = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
      }
    } else {
      this.portalAccess = this.globals.portalAccessConstants['NO_ACCESS'];
    }
    if (stopFormValues.phoneNumber) {
      this.telephone = stopFormValues.countryPrefix + stopFormValues.phoneNumber;
    }
    if (stopFormValues.recipient_email) {
      this.recipientEmail = stopFormValues.recipient_email;
    }
    if (stopFormValues.pickupPhoneNumber) {
      this.pickupTelephone = stopFormValues.pickupCountryPrefix + stopFormValues.pickupPhoneNumber;
    }
    if (collaboratorFormValues.collaboratorData.phoneNumber) {
      this.collaboratorTelephone = [
        {
          'id': null,
          'telephone_code': collaboratorFormValues.collaboratorData.countryPrefix,
          'telephone_number': collaboratorFormValues.collaboratorData.phoneNumber,
          'label': 'phone'
        }
      ];
    }

    if (stopFormValues.serviceTypeRadioButtonSelected === 'delivery') {
      if (!this.deliverySourceModelId) {
        this.deliverySourceModelName = this.globals.companyDepotModelName;
        if (stopFormValues.depot) {
          this.deliverySourceModelId = stopFormValues.depot.companyDepot.id;
        } else {
          this.deliverySourceModelId = this.globals.depotId;
        }
      }
      // empty pickup destinations
      this.pickupDestinationModelName = '';
      this.pickupDestinationModelId = null;
    }

    if (stopFormValues.mass_and_dimensions_data.length) {
      this.massAndDimensions = [];
      stopFormValues.mass_and_dimensions_data.forEach((item, index) => {
        const itemData = {
          count: Number(item.count),
          height: Number(item.height),
          width: Number(item.width),
          length: Number(item.length),
          volume: Number(item.volume),
          parcels_info: this.items[index]['parcels_info'],
          mass: Number(item.mass),
          id: item.id ? item.id : null,
        };
        this.massAndDimensions.push(itemData);
      });
    }

    if (stopFormValues)

      if (!this.positionSet) {
        if (stopFormValues.address.term.customer) {
          this.lat = stopFormValues.address.term.customer.address.lat;
          this.lon = stopFormValues.address.term.customer.address.lon;
          this.freeformAddress = this.addressService.getAddressLabel(stopFormValues.address.term.customer.address);
        } else if (stopFormValues.address.term.position) {
          this.lat = stopFormValues.address.term.position[0];
          this.lon = stopFormValues.address.term.position[1];
          this.freeformAddress = stopFormValues.address.term.label;
        }
      }

    let durationSeconds;
    if (Array.isArray(stopFormValues.duration_seconds)) {
      durationSeconds = stopFormValues.duration_seconds[0];
    } else {
      durationSeconds = stopFormValues.duration_seconds;
    }
    this.duration = moment.duration(durationSeconds, 'seconds').toISOString();
    let pickupDurationSeconds;
    if (Array.isArray(stopFormValues.pickup_duration_seconds)) {
      pickupDurationSeconds = stopFormValues.pickup_duration_seconds[0];
    } else {
      pickupDurationSeconds = stopFormValues.pickup_duration_seconds;
    }
    this.pickupDuration = moment.duration(pickupDurationSeconds, 'seconds').toISOString();

    if (stopFormValues.load !== '' && stopFormValues.load) {
      this.load = Number(stopFormValues.load);
    } else {
      this.load = null;
    }

    if (stopFormValues.weight !== '' && stopFormValues.weight) {
      this.weight = Number(stopFormValues.weight);
    } else {
      this.weight = null;
    }

    this.timeWindows = [];
    this.pickupTimeWindows = [];
    const projectProblemDepartureDatetime = this.projectProblemDepartureDatetime;
    const projectProblemTimeStart = moment(projectProblemDepartureDatetime).format('HH:mm');
    const projectProblemTimeStartMoment = moment(projectProblemTimeStart, 'HH:mm');

    let index = 0;
    for (let i = 0; i < this.timeWindowUnformatted.length; i = i + 2) {
      const timeWindowStartMoment = moment(this.timeWindowUnformatted[i], 'HH:mm');


      const timeWindowEndMoment = moment(this.timeWindowUnformatted[i + 1], 'HH:mm');

      const timeWindowStart = timeWindowStartMoment.format('HH:mm:SS');

      if (
        moment(timeWindowEndMoment).isBefore(projectProblemTimeStartMoment) &&
        i === this.timeWindowUnformatted.length - 1
      ) {
        this.errors.push(this.timeWindowErrorMsg + ' ' + projectProblemTimeStart);
      }

      const timeWindowRangeDuration = moment.duration(timeWindowEndMoment.diff(timeWindowStartMoment));
      let timeWindowRangeMinutes = timeWindowRangeDuration.asMinutes();

      // if the range's minutes are negative, then it means that a next day's end hour been selected, so we add 24hours to it
      if (timeWindowRangeMinutes < 0) {
        timeWindowRangeMinutes += 1440;
      }

      const timeWindowRange = moment.duration(timeWindowRangeMinutes, 'minutes').toISOString();

      this.timeWindows.push({
        id: this.timeWindowIds[index] ? this.timeWindowIds[index] : null,
        time_window_range: timeWindowRange,
        start: timeWindowStart,
        start_prefix_days: this.recipientStartPrefixDays
      });
      index++;
    }
    index = 0;
    for (let i = 0; i < this.pickupTimeWindowUnformatted.length; i = i + 2) {
      const timeWindowStartMoment = moment(this.pickupTimeWindowUnformatted[i], 'HH:mm');
      let timeWindowEndMoment = moment(this.pickupTimeWindowUnformatted[i + 1], 'HH:mm');
      // if (this.pickupStartPrefixDays == 1) {
      //   const hour = Number(this.pickupTimeWindowUnformatted[i + 1].split(':')[0]);
      //   const minute = Number(this.pickupTimeWindowUnformatted[i + 1].split(':')[1]);
      //   const nextDayTimeWindow = moment().add('days', 1).set('hour', hour).set('minute', minute).set('millisecond', 0);
      //   timeWindowEndMoment = moment(nextDayTimeWindow, 'HH:mm');
      // } else {
      timeWindowEndMoment = moment(this.pickupTimeWindowUnformatted[i + 1], 'HH:mm');
      // }

      const timeWindowStart = timeWindowStartMoment.format('HH:mm:SS');

      if (
        moment(timeWindowEndMoment).isBefore(projectProblemTimeStartMoment) &&
        i === this.pickupTimeWindowUnformatted.length - 1
      ) {
        this.errors.push(this.timeWindowErrorMsg + ' ' + projectProblemTimeStart);
      }

      const timeWindowRangeDuration = moment.duration(timeWindowEndMoment.diff(timeWindowStartMoment));
      let timeWindowRangeMinutes = timeWindowRangeDuration.asMinutes();

      // if the range's minutes are negative, then it means that a next day's end hour been selected, so we add 24hours to it
      if (timeWindowRangeMinutes < 0) {
        timeWindowRangeMinutes += 1440;
      }

      const timeWindowRange = moment.duration(timeWindowRangeMinutes, 'minutes').toISOString();

      this.pickupTimeWindows.push({
        id: this.pickupTimeWindowIds[index] ? this.pickupTimeWindowIds[index] : null,
        time_window_range: timeWindowRange,
        start: timeWindowStart,
        start_prefix_days: this.pickupStartPrefixDays
      });
      index++;
    }
    if (stopFormValues.companyTimeWindow) {
      this.selectedTimeWindowId = stopFormValues.companyTimeWindow['CompanyTimeWindowCategory']['id'];
    }

    this.agreedShipping = [];
    if (this.previousAgreedShipping['id']) { this.agreedShipping = [this.previousAgreedShipping]; }

    // set appropriate consignor type (based on paid_by & existing entity ids)
    if (stopFormValues.voucher.options.paid_by) {
      this.paidBy = stopFormValues.voucher.options.paid_by;
    }

    if (!this.globals.collaboratorModeEnabled) {
      if (
        (this.collaboratorId && this.recipientId && this.consignorId) ||
        (this.collaboratorId && this.recipientId) ||
        (this.collaboratorId && this.consignorId) ||
        (this.recipientId && this.consignorId)
      ) {
        this.consignorType = this.paidBy;
      }
      else if (this.collaboratorId) {
        this.consignorType = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
      } else if (this.recipientId) {
        this.consignorType = this.globals.stopPointCollaboratorTypesConstants['RECEIVER'];
        stopFormValues.contact_name = stopFormValues.collaborator.collaboratorData.contact_name;
      } else if (this.consignorId) {
        this.consignorType = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
      }
    }

    if (cancelConfirmed === 'yes' || this.fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED'] || !this.stopPointId) {
      if (this.agreedShippingDateChanged) {
        const dateElement = document.querySelector('#agreed-shipping-date-voucher');
        const date = M.Datepicker.getInstance(dateElement).date;
        if (date) {
          const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:SS').format('YYYY-MM-DD');
          this.agreedShipping = [
            {
              id: this.previousAgreedShipping['id'] ? this.previousAgreedShipping['id'] : null,
              date: formattedDate,
              start: this.previousAgreedShipping['start'] ? this.previousAgreedShipping['start'] : '',
              end: this.previousAgreedShipping['end'] ? this.previousAgreedShipping['end'] : ''
            }
          ];

          if (cancelConfirmed === 'yes') {
            const canceledConstant = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];
            this.fulfillmentStatus = canceledConstant;
            this.fulfillmentEvent = {
              reason: this.globals.stopPointFulfillmentEventConstants[canceledConstant]['AGREED_SHIPPING'],
              description: ''
            }
          }
        } else {
          this.agreedShipping = [
            {
              id: this.previousAgreedShipping['id'] ? this.previousAgreedShipping['id'] : null,
              date: '',
              start: this.previousAgreedShipping['start'] ? this.previousAgreedShipping['start'] : '',
              end: this.previousAgreedShipping['end'] ? this.previousAgreedShipping['end'] : ''
            }
          ];

          if (cancelConfirmed === 'yes') {
            const canceledConstant = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];
            this.fulfillmentStatus = canceledConstant;
            this.fulfillmentEvent = {
              reason: this.globals.stopPointFulfillmentEventConstants[canceledConstant]['AGREED_SHIPPING'],
              description: ''
            }
          }
        }
      }
    }

    if (!this.fulfillmentEvent['reason']) {
      this.fulfillmentStatus = null;
    }

    this.patchForm();
  }

  // ANCHOR
  public submitStopForm(cancelConfirmed) {
    this.errors = [];
    this.prepareForSubmit(cancelConfirmed);
    return this.stopPointService.saveStopPoint(this.checkForNoDelivery(), this.stopPointId, this.projectProblemId, this.inWarehouse);
  }

  public checkForNoDelivery() {
    // [ONLY ON PICKUP WITHOUT DELIVERY] set collaborator name
    const stopFormValues = this.myForm.value.stopPoint;
    let stopPointData = JSON.parse(JSON.stringify(this.myForm.value));
    const noPickupSelected = false;
    // if (this.noDeliverySelected || this.serviceTypeRadioButtonSelected === 'delivery') {
    if (this.noDeliverySelected || noPickupSelected) {
      const consignor = stopFormValues.collaborator?.collaboratorData?.selectedConsignor?.companyCollaborator;
      if (consignor && (stopFormValues.voucher.options.paid_by == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'] || this.thirdPartyCheckboxSelected)) {
        stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;
        stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;

        // [COURIER ONLY] if we have the same collaborator as sender & consignor, then always use sender (except if no delivery is selected)
        if (!this.globals.collaboratorModeEnabled) {
          // if we have the same collaborator as sender & consignor, then always use sender (except if no delivery is selected)
          if (
            stopFormValues.collaborator?.collaboratorData?.selectedCollaborator?.companyCollaborator &&
            stopFormValues.collaborator?.collaboratorData?.selectedCollaborator?.companyCollaborator?.collaborator?.collaboratorData?.id == stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id &&
            !this.noDeliverySelected
          ) {
            stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
          } else if (this.noDeliverySelected) {
            const sender = this.stopPointUtils.getSenderCollaborator(stopFormValues.voucher.company_voucher_collaborators);
            const consignor = this.stopPointUtils.getConsignorCollaborator(stopFormValues.voucher.company_voucher_collaborators);
            if (sender) {
              stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
            } else if (consignor) {
              stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
            }
          } else {
            stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
          }
        }
      }
      else if (stopFormValues.voucher.options.paid_by == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
        if (this.collaboratorId && stopFormValues.collaborator?.collaboratorData?.selectedCollaborator?.companyCollaborator) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        } else if (this.consignorId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        } else if (this.recipientId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.address;
        }
        // in case of no collaborator existing, send pickup name w/ null id
        else {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.supplier;
          stopPointData.stopPoint.collaborator.collaboratorData.id = null;
          stopPointData.stopPoint.collaborator.collaboratorData.telephone = this.pickupTelephone;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        }
      }
      else if (stopFormValues.voucher.options.paid_by == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
        if (this.recipientId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.address;
        } else if (this.consignorId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        } else if (this.collaboratorId && stopFormValues?.collaborator?.collaboratorData?.selectedCollaborator?.companyCollaborator) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        }
        // in case of no collaborator existing, send pickup name w/ null id
        else {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.contact_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = null;
          stopPointData.stopPoint.collaborator.collaboratorData.telephone = this.pickupTelephone;
          stopPointData.stopPoint.collaborator.address = stopFormValues.address;
        }
      }
    }

    delete stopPointData.stopPoint.collaborator;

    return stopPointData;
  }

  public setupSameDayOrNextDayPickupForm(cancelConfirmed) {
    this.errors = [];
    this.prepareForSubmit(cancelConfirmed);

    const stopFormValues = this.myForm.value.stopPoint;
    const stopPointData = JSON.parse(JSON.stringify(this.myForm.value));

    let pickupId = null;
    // if same day delivery is selected and is edit mode
    if (stopFormValues.deliverySources.modelName === this.globals.stopPointModelName && this.stopPointId) {
      pickupId = stopFormValues.deliverySources.modelId;
    }
    // if next day delivery is selected and is edit mode
    if (this.data && this.stopPointId) {
      if (this.data.relatedStopPoint) {
        pickupId = this.data.relatedStopPoint.id;
      }
    }

    stopPointData.stopPoint.id = pickupId;
    stopPointData.stopPoint.stopPointId = pickupId;

    stopPointData.stopPoint.address = stopFormValues.collaborator.collaborator_address;

    // set contact name of stop point based on pickup type during submit
    stopPointData.stopPoint.contact_name = stopFormValues.supplier;
    stopPointData.stopPoint.telephone = this.pickupTelephone;

    // don't send relatedCustomerId if the selected collaborator is not a saved customer
    const selectedCollaborator = stopFormValues.collaborator.collaboratorData.selectedCollaborator;
    if (selectedCollaborator) {
      if (selectedCollaborator.companyCollaborator) {
        stopPointData.stopPoint.relatedCustomerId = null;
      } else if (selectedCollaborator.customer) {
        stopPointData.stopPoint.relatedCustomerId = selectedCollaborator.customer.id;
      } else {
        stopPointData.stopPoint.relatedCustomerId = null;
      }
    } else {
      stopPointData.stopPoint.relatedCustomerId = null;
    }

    // remove collaborator for pickup if no delivery is not selected, otherwise determine who the collaborator should be for this no delivery pickup sp
    // also remove mass and dimensions from pickup (only delivery must have them unless there is no delivery)
    if (!this.noDeliverySelected) {
      delete stopPointData.stopPoint.collaborator;
      delete stopPointData.stopPoint.mass_and_dimensions;
    } else {
      // set collaborator name & id to be that of the consignor in case of consignor is selected as the consignor type
      if (stopFormValues.voucher.options.paid_by == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'] || this.thirdPartyCheckboxSelected) {
        // stopPointData.stopPoint.contact_name = stopPointData.stopPoint.contact_name;
        stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;
        stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        if (stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.id == stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id) {
          stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
        } else {
          stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
        }
      }
      else if (stopFormValues.voucher.options.paid_by == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
        if (this.collaboratorId && stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        } else if (this.consignorId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        } else if (this.recipientId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.address;
        }
        // in case of no collaborator existing, send pickup name w/ null id & recipient telephone details
        else {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.supplier;
          stopPointData.stopPoint.collaborator.collaboratorData.id = null;
          stopPointData.stopPoint.collaborator.collaboratorData.telephone = this.pickupTelephone;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        }
      }
      else if (stopFormValues.voucher.options.paid_by == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
        if (this.recipientId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.address;
        } else if (this.consignorId) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        } else if (this.collaboratorId && stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator) {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = stopFormValues.collaborator.collaboratorData.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.id;
          stopPointData.stopPoint.collaborator.address = stopFormValues.collaborator.collaborator_address;
        }
        // in case of no collaborator existing, send pickup name w/ null id
        else {
          stopPointData.stopPoint.collaborator.collaboratorData.collaborator_name = stopFormValues.contact_name;
          stopPointData.stopPoint.collaborator.collaboratorData.id = null;
          stopPointData.stopPoint.collaborator.collaboratorData.telephone = this.pickupTelephone;
          stopPointData.stopPoint.collaborator.address = stopFormValues.address;
        }
      }
    }

    delete stopPointData.stopPoint.collaborator;

    stopPointData.stopPoint.deliverySources.modelId = null;
    stopPointData.stopPoint.deliverySources.pickupMode = null;
    stopPointData.stopPoint.deliverySources.modelName = '';
    stopPointData.stopPoint.pickupDestinations.modelId = this.stopPointId;
    stopPointData.stopPoint.pickupDestinations.modelName = this.stopPointId ? this.globals.stopPointModelName : '';
    stopPointData.stopPoint.pickupDestinations.pickupMode = null;

    if (this.stopPointId && this.serviceTypeSelected === 'sameDay') {
      stopPointData.stopPoint.pickupDestinations.pickupMode = this.globals.importPickupModes['SAME_DAY_DELIVERY'];
      if (this.isThroughHub) {
        stopPointData.stopPoint.pickupDestinations.pickupMode = this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB'];
      }
    }

    stopPointData.stopPoint.time_windows = this.pickupTimeWindows;
    stopPointData.stopPoint.duration = this.pickupDuration;
    if (stopFormValues.pickupPriorityOn) {
      stopPointData.stopPoint.priority = this.globals.stopPointPriorityConstants['HIGH'];
    } else {
      stopPointData.stopPoint.priority = this.globals.stopPointPriorityConstants['NORMAL'];
    }

    if (stopFormValues.pickupPortalOn) {
      stopPointData.stopPoint.portal_access = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
    } else {
      stopPointData.stopPoint.portal_access = this.globals.portalAccessConstants['NO_ACCESS'];
    }

    stopPointData.stopPoint.project_problem_id = this.pickupProjectProblemId ?? null;

    stopPointData.stopPoint.pay_amount = null;
    stopPointData.stopPoint.service_type = this.globals.stopPointServiceTypeConstants['PICKUP'];

    stopPointData.stopPoint.note = this.deliverToNoteLabel + this.contactName;

    // set appropriate barcode
    stopPointData.stopPoint.barcode = this.pickupBarcode;

    delete stopPointData.stopPoint.voucher;

    this.pickupToSave = stopPointData;

    // [food mode]: set pickup name as the selected food depot name
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      stopPointData['stopPoint']['contact_name'] = this.selectedFoodDepot['companyDepot']['address']['label'];
    }

    // return this.stopPointService.saveStopPoint(stopPointData, pickupId, this.projectProblemId, this.inWarehouse);
  }

  public setupSameDayOrNextDayDeliveryForm() {
    const stopFormValues = this.myForm.value.stopPoint;
    const stopPointData = JSON.parse(JSON.stringify(this.myForm.value));
    stopPointData.stopPoint.deliverySources.pickupMode = null;
    stopPointData.stopPoint.deliverySources.modelName = this.globals.stopPointModelName;
    stopPointData.stopPoint.deliverySources.modelId = this.pickupToSave.stopPoint.id;
    stopPointData.stopPoint.pickupDestinations.modelId = null;
    stopPointData.stopPoint.pickupDestinations.pickupMode = null;
    stopPointData.stopPoint.pickupDestinations.modelName = '';

    // [food mode]: if time windows have not been manually altered, then re-calculate time window on submit
    if (!this.hasManuallyAlteredTimeWindows && (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled)) {
      stopPointData.stopPoint.time_windows[0].start = moment().format("HH:mm:ss");
    }

    const paidByValue = stopFormValues.voucher.options.paid_by;
    const formCollaboratorData = stopFormValues.collaborator.collaboratorData;
    const formSelectedCollaborator = formCollaboratorData.selectedCollaborator;
    const formSelectedConsignor = formCollaboratorData.selectedConsignor;
    const formSelectedConsignorCollaborator = formSelectedConsignor?.companyCollaborator;
    const stopPointCollaborator = stopPointData.stopPoint.collaborator;
    const stopPointCollaboratorData = stopPointCollaborator.collaboratorData;
    // set collaborator name & id to be that of the consignor in case of consignor is selected as the consignor type
    if (paidByValue == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'] || this.thirdPartyCheckboxSelected) {
      if (formSelectedConsignorCollaborator) {
        stopPointCollaboratorData.collaborator_name = formSelectedConsignorCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = formSelectedConsignorCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.collaborator.collaborator_address;
        stopPointData.stopPoint.voucher.options.collaborator_type = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
      }
    }
    else if (paidByValue == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
      if (this.collaboratorId && formSelectedCollaborator.companyCollaborator) {
        stopPointCollaboratorData.collaborator_name = formSelectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = formSelectedCollaborator.companyCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.collaborator.collaborator_address;
      } else if (this.consignorId) {
        stopPointCollaboratorData.collaborator_name = formSelectedConsignorCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = formSelectedConsignorCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.collaborator.collaborator_address;
      } else if (this.recipientId) {
        stopPointCollaboratorData.collaborator_name = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.address;
      }
      // in case of no collaborator existing, send pickup name w/ null id & recipient telephone details
      else {
        stopPointCollaboratorData.collaborator_name = stopFormValues.supplier;
        stopPointCollaboratorData.id = null;
        stopPointCollaboratorData.telephone = this.pickupTelephone;
        stopPointCollaborator.address = stopFormValues.collaborator.collaborator_address;
      }
    }
    else if (paidByValue == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
      if (this.recipientId && stopFormValues.selectedRecipient.companyCollaborator) {
        stopPointCollaboratorData.collaborator_name = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = stopFormValues.selectedRecipient.companyCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.address;
      } else if (this.consignorId) {
        stopPointCollaboratorData.collaborator_name = formSelectedConsignorCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = formSelectedConsignorCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.collaborator.collaborator_address;
      } else if (this.collaboratorId && formSelectedCollaborator.companyCollaborator) {
        stopPointCollaboratorData.collaborator_name = formSelectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        stopPointCollaboratorData.id = formSelectedCollaborator.companyCollaborator.collaborator.collaboratorData.id;
        stopPointCollaborator.address = stopFormValues.collaborator.collaborator_address;
      }
      // in case of no collaborator existing, send pickup name w/ null id
      else {
        stopPointCollaboratorData.collaborator_name = stopFormValues.contact_name;
        stopPointCollaboratorData.id = null;
        stopPointCollaboratorData.telephone = this.telephone;
        stopPointCollaborator.address = stopFormValues.address;
      }
    }

    // set appropriate barcode
    stopPointData.stopPoint.barcode = this.deliveryBarcode;

    delete stopPointData.stopPoint.collaborator;

    if (this.serviceTypeSelected === 'sameDay') {
      stopPointData.stopPoint.deliverySources.pickupMode = this.globals.importPickupModes['SAME_DAY_DELIVERY'];
      if (this.isThroughHub) {
        stopPointData.stopPoint.deliverySources.pickupMode = this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB'];
      }
    }
    stopPointData.stopPoint.voucher.options.address_from = stopFormValues.collaborator.collaborator_address;

    this.deliveryToSave = stopPointData;
  }

  prepareCollaboratorsArray(stopFormValues) {
    let collaboratorArray = [];
    if (stopFormValues.collaborator) {

      // if "I will be receiver" is selected, set collaboratorReceiver as collaboratorNormal
      if (this.globals.collaboratorModeEnabled && this.consignorTypeRadioButtonSelected == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']) {
        this.collaboratorReceiver = { ...this.collaboratorNormal };
        this.collaboratorNormal = null;
      }

      // if "I will be assignor" is selected, set collaboratorConsignor as collaboratorNormal
      if (this.globals.collaboratorModeEnabled && this.consignorTypeRadioButtonSelected == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
        this.collaboratorConsignor = { ...this.collaboratorNormal };
        this.collaboratorNormal = null;
      }

      // normal collaborator
      if (this.collaboratorNormal) {
        let idToSend = null;
        if (this.collaboratorNormal?.id == this.collaboratorFetchedNormal?.id) {
          idToSend = this.collaboratorNormalVoucherId;
        }

        collaboratorArray.push({
          type: this.globals.stopPointCollaboratorTypesConstants['SENDER'],
          collaborator_id: this.collaboratorNormal?.id,
          id: idToSend
        });
      }

      // receiver collaborator (w/ delivery)
      if (this.collaboratorReceiver && !this.noDeliverySelected) {
        // guarantee a collaborator id on edit w/ temporary assignor as sender!
        let shipperId;
        if (this.globals.collaboratorModeEnabled && !this.collaboratorReceiver?.id) {
          shipperId = this.globals.partnersArray[0]['collaborator_id'];
        } else {
          shipperId = this.collaboratorReceiver?.id;
        }

        let idToSend = null;
        if (this.collaboratorReceiver == this.collaboratorFetchedReceiver) {
          idToSend = this.collaboratorReceiverVoucherId;
        }

        collaboratorArray.push({
          type: this.globals.stopPointCollaboratorTypesConstants['RECEIVER'],
          collaborator_id: shipperId,
          id: idToSend
        });
      }
      // receiver collaborator (w/o delivery)
      else if (this.collaboratorReceiver && this.noDeliverySelected) {
        let idToSend = null;
        if (this.collaboratorReceiver?.id == this.collaboratorFetchedReceiver?.id) {
          idToSend = this.collaboratorReceiverVoucherId;
        }

        collaboratorArray.push({
          type: this.globals.stopPointCollaboratorTypesConstants['SENDER'],
          collaborator_id: this.collaboratorReceiver?.id,
          id: idToSend
        });
      }

      // consginor collaborator
      if (this.collaboratorConsignor) {
        // guarantee a collaborator id on edit w/ temporary assignor as consignor!
        let shipperId;
        if (this.globals.collaboratorModeEnabled && !this.collaboratorConsignor?.id) {
          shipperId = this.globals.partnersArray[0]['collaborator_id'];
        } else {
          shipperId = this.collaboratorConsignor?.id;
        }

        let idToSend = null;
        if (this.collaboratorConsignor?.id == this.collaboratorFetchedConsignor?.id) {
          idToSend = this.collaboratorConsignorVoucherId;
        }

        collaboratorArray.push({
          type: this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'],
          collaborator_id: shipperId,
          id: idToSend
        });
      }
    }
    collaboratorArray = [...collaboratorArray];

    return collaboratorArray;
  }

  prepareTemporaryAssignor() {
    const pickupAddress = this.myForm.value.stopPoint.collaborator.collaborator_address;
    const pickupTelephone = this.myForm.value.stopPoint.pickupPhoneNumber;
    const pickupCountryPrefix = this.myForm.value.stopPoint.pickupCountryPrefix;

    // if on edit, use existing assignor address id
    const assignorAddressId = this.temporaryAssignor?.address?.id ? this.temporaryAssignor?.address?.id : null;

    // use supplier & collaborator_address as temporary_assignor
    if (!this.collaboratorNormal) {
      this.temporaryAssignor = {
        name: this.supplier,
        address: { ...pickupAddress, id: assignorAddressId },
        telephone: pickupTelephone,
        telephone_code: pickupCountryPrefix
      };
    }
    else {
      this.temporaryAssignor = null;
    }

    this.myForm.patchValue({
      'stopPoint': {
        'temporary_assignor': this.temporaryAssignor
      }
    });
  }

  timeWindowCheckboxClicked() {
    if (this.myForm.value.stopPoint.doubleTimeWindow) {
      this.doubleTimeWindow = false;
      this.timeWindowUnformatted = ['08:00', '20:00'];
    } else {
      this.doubleTimeWindow = true;
      this.timeWindowUnformatted = ['08:00', '14:00', '17:00', '20:00'];
    }
  }

  recipientFocusOut() {
    const currentRecipientName = (<HTMLInputElement>document.getElementById('recipient-name-voucher-form')).value;
    // let recipientNameFound = false;
    this.recipientAndCollaboratorLatestResults.forEach(data => {
      if (data['customer']) {
        const filteredOutParenthesesName = data['customer'].contact_name.split('(')[0];
        if (filteredOutParenthesesName == currentRecipientName) {
          // recipientNameFound = true;
          this.myForm.patchValue({
            'stopPoint': {
              'selectedRecipient': data
            }
          });
        }
      } else if (data['companyCollaborator']) {
        const filteredOutParenthesesName = data['companyCollaborator'].collaborator.collaboratorData.collaborator_name.split('(')[0];
        if (filteredOutParenthesesName == currentRecipientName) {
          // recipientNameFound = true;
          this.myForm.patchValue({
            'stopPoint': {
              'selectedRecipient': data
            }
          });
        }
      }
    });
    this.onRecipientNameChange();
  }

  collaboratorFocusOut() {
    const currentCollaboratorName = (<HTMLInputElement>document.getElementById('collaborator-name-voucher-form')).value;
    // let collaboratorNameFound = false;
    this.recipientAndCollaboratorLatestResults.forEach(data => {
      if (data['customer']) {
        const filteredOutParenthesesName = data['customer'].contact_name.split('(')[0];
        if (filteredOutParenthesesName == currentCollaboratorName) {
          // collaboratorNameFound = true;
          this.myForm.patchValue({
            'stopPoint': {
              'collaborator': {
                'collaboratorData': {
                  'selectedCollaborator': data
                }
              }
            }
          });
        }
      } else if (data['companyCollaborator']) {
        const filteredOutParenthesesName = data['companyCollaborator'].collaborator.collaboratorData.collaborator_name.split('(')[0];
        if (filteredOutParenthesesName == currentCollaboratorName) {
          // collaboratorNameFound = true;
          this.selectedCollaborator = data;
          this.myForm.patchValue({
            'stopPoint': {
              'collaborator': {
                'collaboratorData': {
                  'selectedCollaborator': data
                }
              }
            }
          });
        }
      }
    });
    this.onCollaboratorNameChange();
  }

  consignorFocusOut() {
    const currentConsignorName = (<HTMLInputElement>document.getElementById('consignor-name-voucher-form')).value;
    this.recipientAndCollaboratorLatestResults.forEach(data => {
      if (data['companyCollaborator']) {
        const filteredOutParenthesesName = data['companyCollaborator'].collaborator.collaboratorData.collaborator_name.split('(')[0];
        if (filteredOutParenthesesName == currentConsignorName) {
          this.myForm.patchValue({
            'stopPoint': {
              'collaborator': {
                'collaboratorData': {
                  'selectedConsignor': data
                }
              }
            }
          });
        }
      }
    });
    this.onConsignorNameChange();
  }

  collaboratorInputFocusOut() {
    if (!this.myForm.value.stopPoint.collaborator.collaborator_address.term.timeZone) {
      if (this.collaboratorAddressNgSelect.itemsList['_filteredItems']) {
        const firstItem = this.collaboratorAddressNgSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.collaboratorAddressNgSelect.select(firstItem);
        }
      }
    }
  }

  inputFocusOut() {
    if (!this.myForm.value.stopPoint.address.term.timeZone && !this.myForm.value.stopPoint.address.term.customer) {
      if (this.stopPointNgSelect.itemsList['_filteredItems']) {
        const firstItem = this.stopPointNgSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.stopPointNgSelect.select(firstItem);
        }
      }
    }
  }

  collaboratorInputName() {
    this.collaboratorSelectedAddress = '';
    this.supplier = this.myForm.value.stopPoint.supplier;
    this.collaboratorNameNgSelect.filter(this.myForm.value.stopPoint.supplier);
  }

  recipientInputName() {
    this.recipientName = this.myForm.value.stopPoint.contact_name;
    this.recipientNameNgSelect.filter(this.myForm.value.stopPoint.contact_name);
  }

  consignorInputName() {
    this.consignorName = this.myForm.value.stopPoint.collaborator.collaboratorData.consignor_name;
    this.consignorNameNgSelect.filter(this.myForm.value.stopPoint.collaborator.collaboratorData.consignor_name);
  }

  collaboratorInputAddress(isPaste = false) {
    this.collaboratorSelectedAddress = '';
    this.myForm.patchValue({
      'stopPoint': {
        'collaborator': {
          'collaborator_address': {
            'term': this.collaboratorSelectedAddress,
          },
        },
      },
    });

    if (isPaste) {
      setTimeout(() => {
        this.collaboratorAddressNgSelect.filter((<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value);
      }, 50);
    } else {
      this.collaboratorAddressNgSelect.filter((<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value);
    }
  }

  stopPointInputAddress(isPaste = false) {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });

    if (isPaste) {
      setTimeout(() => {
        this.stopPointNgSelect.filter((<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value);
      }, 50);
    } else {
      this.stopPointNgSelect.filter((<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value);
    }
  }

  onAgreedShippingDateChange() {
    this.agreedShippingDateChanged = true;
  }

  onCollaboratorNameChange() {
    const collaboratorFormValues = this.myForm.value.stopPoint.collaborator;
    // TODO update the day of week and selected timeWindows
    this.selectedCollaborator = collaboratorFormValues.collaboratorData.selectedCollaborator;

    if (this.selectedCollaborator) {
      if (this.selectedCollaborator.companyCollaborator) {
        this.collaboratorNormal = this.selectedCollaborator.companyCollaborator.collaborator.collaboratorData;
      }
    }

    if (this.selectedCollaborator) {
      // in collaborator mode if the collaborator is not shipper this.selectedCollaborator.companyCollaborator is not empty but we don't want to change the shipper name input 
      const collaboratorIsNotSender = this.globals.collaboratorModeEnabled && this.consignorTypeRadioButtonSelected != this.globals.stopPointCollaboratorTypesConstants['SENDER'];
      if (this.selectedCollaborator.companyCollaborator && !this.globals.collaboratorModeEnabled) {
        (<HTMLInputElement>document.getElementById('collaborator-name-voucher-form')).value = this.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        this.supplier = this.selectedCollaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        this.hasPriceListManuallyAltered = false;
        this.loadCollaboratorData(this.selectedCollaborator.companyCollaborator);
        this.getPriceLists();
      } else if (this.selectedCollaborator.customer) {
        (<HTMLInputElement>document.getElementById('collaborator-name-voucher-form')).value = this.selectedCollaborator.customer.contact_name;
        this.loadCollaboratorFromCustomer(this.selectedCollaborator.customer);
        this.getPriceLists();
      }
    }
  }

  onConsignorNameChange() {
    const collaboratorFormValues = this.myForm.value.stopPoint.collaborator;
    this.selectedConsignor = collaboratorFormValues.collaboratorData.selectedConsignor;
    if (this.selectedConsignor) {
      if (this.selectedConsignor.companyCollaborator) {
        this.collaboratorConsignor = this.selectedConsignor.companyCollaborator.collaborator.collaboratorData;

        // if no delivery is selected, then add the assignor's address to the sender's address on the left form & do the same with the assignor's phone
        if (this.noDeliverySelected) {
          this.setCollaboratorAddress(this.selectedConsignor.companyCollaborator.collaborator.address);
          (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
          this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
          if (this.collaboratorConsignor['telephone']) {
            this.pickupPhoneNumber = this.collaboratorConsignor['telephone'][0]['telephone_number'];
            this.pickupCountryPrefix = this.collaboratorConsignor['telephone'][0]['telephone_code'];
            this.pickupTelephone = this.collaboratorConsignor['telephone'][0]['telephone_code'] + this.collaboratorConsignor['telephone'][0]['telephone_number'];
          }
          this.myForm.patchValue({
            'stopPoint': {
              'pickup_telephone': this.pickupTelephone,
              'pickupCountryPrefix': this.pickupCountryPrefix,
              'pickupPhoneNumber': this.pickupPhoneNumber,
            },
          });
          this.getPriceLists();
        }
      }
    }

    if (this.selectedConsignor) {
      if (this.selectedConsignor.companyCollaborator) {
        (<HTMLInputElement>document.getElementById('consignor-name-voucher-form')).value = this.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        this.consignorName = this.selectedConsignor.companyCollaborator.collaborator.collaboratorData.collaborator_name;
        this.consignorId = this.selectedConsignor.companyCollaborator.collaborator.collaboratorData.id;

        this.getPriceLists();
        M.updateTextFields();
      }
    }
  }

  onRecipientNameChange() {
    const stopPointFormValues = this.myForm.value.stopPoint;
    this.selectedRecipient = stopPointFormValues.selectedRecipient;
    this.collaboratorReceiver = null;

    if (this.selectedRecipient) {
      if (this.selectedRecipient.companyCollaborator) {
        const collaborator = this.selectedRecipient.companyCollaborator.collaborator;
        this.collaboratorReceiver = this.selectedRecipient.companyCollaborator.collaborator.collaboratorData;
        (<HTMLInputElement>document.getElementById('recipient-name-voucher-form')).value = collaborator.collaboratorData.collaborator_name;
        this.recipientName = collaborator.collaboratorData.collaborator_name;
        this.contactName = collaborator.collaboratorData.collaborator_name;
        this.recipientId = collaborator.collaboratorData.id;
        this.myForm.patchValue({
          'stopPoint': {
            'contact_name': this.contactName,
          },
        });

        if (collaborator.collaborator_depot) {
          const selectedRecipientAddress = collaborator.collaborator_depot.address;
          if (selectedRecipientAddress) {
            if (collaborator.collaborator_depot.address.lat && collaborator.collaborator_depot.address.lon) {
              this.setAddress(collaborator.collaborator_depot.address);
              (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
              this.recipientMapComponent.showDraggableMarker(Number(selectedRecipientAddress.lat), Number(selectedRecipientAddress.lon));
              this.myForm.patchValue({
                'stopPoint': {
                  'address': collaborator.collaborator_depot.address,
                },
              });
            }
          }
        } else if (collaborator.address) {
          const selectedRecipientAddress = collaborator.address;
          if (collaborator.address.lat && collaborator.address.lon) {
            this.setAddress(collaborator.address);
            (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
            this.recipientMapComponent.showDraggableMarker(Number(selectedRecipientAddress.lat), Number(selectedRecipientAddress.lon));
            this.myForm.patchValue({
              'stopPoint': {
                'address': collaborator.address,
              },
            });
          }
        }

        this.telephone = collaborator.collaboratorData.telephone;
        if (collaborator.collaboratorData.telephone) {
          if (collaborator.collaboratorData.telephone[0]) {
            this.countryPrefix = collaborator.collaboratorData.telephone[0]['telephone_code'];
            this.phoneNumber = collaborator.collaboratorData.telephone[0]['telephone_number'];
          }
        }

        this.myForm.patchValue({
          'stopPoint': {
            'countryPrefix': this.countryPrefix,
            'phoneNumber': this.phoneNumber,
            'telephone': this.telephone,
          },
        });
      } else if (this.selectedRecipient.customer) {
        this.loadRecipientFromCustomer(this.selectedRecipient.customer);
      }
    }

    this.populateBasicServices();
    this.getPriceLists();
    M.updateTextFields();
  }

  loadCollaboratorData(companyCollaborator) {
    const collaborator = companyCollaborator.collaborator;
    this.collaboratorId = collaborator.collaboratorData.id;
    this.collaboratorTelephone = collaborator.collaboratorData.telephone;
    this.pickupTelephone = collaborator.collaboratorData.telephone;
    if (collaborator.collaboratorData.telephone) {
      if (collaborator.collaboratorData.telephone[0]) {
        this.collaboratorCountryPrefix = this.collaboratorTelephone[0]['telephone_code'];
        this.collaboratorPhoneNumber = collaborator.collaboratorData.telephone[0]['telephone_number'];
        this.pickupCountryPrefix = this.collaboratorTelephone[0]['telephone_code'];
        this.pickupPhoneNumber = collaborator.collaboratorData.telephone[0]['telephone_number'];
      }
    }

    this.collaboratorName = collaborator.collaboratorData.collaborator_name;
    this.collaboratorType = String(collaborator.collaboratorData.collaborator_type);
    if (!this.collaboratorType) {
      this.collaboratorType = String(this.globals.voucherPayerConstants['COMPANY']);
    }
    // this.isThroughHub = this.myForm.value.stopPoint.collaborator.collaboratorData.isThroughHub;
    if (companyCollaborator.connection.is_through_hub) {
      this.isThroughHub = companyCollaborator.connection.is_through_hub;
    }

    this.populateBasicServices();

    if (collaborator.collaborator_depot) {
      if (collaborator.collaborator_depot.address) {
        if (collaborator.collaborator_depot.address.lat && collaborator.collaborator_depot.address.lon) {
          this.setCollaboratorAddress(collaborator.collaborator_depot.address);
          (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
          this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
        }
      }
    } else if (collaborator.address) {
      if (collaborator.address.lat && collaborator.address.lon) {
        this.setCollaboratorAddress(collaborator.address);
        (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
        this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
      }
    }

    this.patchCollaborator();
  }

  onAddressChange() {
    const stopFormValues = this.myForm.value.stopPoint;
    // TODO update the day of week and selected timeWindows
    (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = stopFormValues.address.term.label;
    if (stopFormValues.address.term) {
      if (stopFormValues.address.term.timeZone) {
        const timeZone = stopFormValues.address.term.timeZone;
        const targetDateTime = moment(this.projectProblemDepartureDatetime).tz(timeZone);
        this.projectProblemDayOfWeek = this.days[moment(targetDateTime).day()];
        if (this.selectedTimeWindow) {
          if (this.selectedTimeWindow['CompanyTimeWindowCategory']['time_windows']) {
            this.timeWindows = this.selectedTimeWindow['CompanyTimeWindowCategory']['time_windows'][this.projectProblemDayOfWeek];
            this.setTimeWindows();
          }
        }
      }
      if (stopFormValues.address.term.customer) {
        const customer = stopFormValues.address.term.customer;
        this.loadRecipientFromCustomer(customer);
      } else {
        this.lat = stopFormValues.address.term.position[0];
        this.lon = stopFormValues.address.term.position[1];
        this.freeformAddress = stopFormValues.address.term.label;
        this.setAddress(stopFormValues.address.term.address);
        this.recipientMapComponent.showDraggableMarker(Number(this.lat), Number(this.lon));
      }

      this.getPriceLists();
    }
  }

  loadCollaboratorFromCustomer(customer) {
    this.collaboratorLat = customer.address.lat;
    this.collaboratorLon = customer.address.lon;
    this.setCollaboratorAddress(customer.address);
    (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
    this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));

    this.supplier = customer.contact_name;
    if (this.supplier.includes(this.noNameConstant)) {
      this.supplier = this.supplier.replace(this.noNameConstant, this.noNameLabel);
    }

    if (customer.telephone === 'n/a') { customer.telephone = ''; }
    this.pickupTelephone = customer.telephone;
    if (this.pickupTelephone === 'n/a') { this.pickupTelephone = ''; }
    if (this.pickupTelephone === '+30n/a') { this.pickupTelephone = ''; }
    if (this.pickupTelephone) {
      if (this.pickupTelephone.length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(this.pickupTelephone);
        this.pickupPhoneNumber = phoneObj.nationalNumber;
        this.pickupCountryPrefix = '+' + phoneObj.countryCallingCode;
      }
    }

    this.pickupTimeWindows = customer.time_windows[this.projectProblemDayOfWeek];
    this.setTimeWindows();

    this.pickupDuration = customer.stop_point_default_duration;
    this.pickupDurationSeconds = [moment.duration(this.pickupDuration).asSeconds()];
    this.pickupDurationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.pickupDuration, true);

    this.pickupPriority = customer.priority;
    if (this.pickupPriority === this.globals.stopPointPriorityConstants['HIGH']) {
      this.pickupPriorityOn = true;
    } else {
      this.pickupPriorityOn = false;
    }

    this.patchPickup();
  }

  loadRecipientFromCustomer(customer) {
    this.relatedCustomerId = customer.id;
    this.setAddress(customer.address);
    this.selectedAddress = this.myForm.value.stopPoint.address.term;
    this.recipientMapComponent.showDraggableMarker(Number(this.lat), Number(this.lon));

    this.contactName = customer.contact_name;
    if (this.contactName.includes(this.noNameConstant)) {
      this.contactName = this.contactName.replace(this.noNameConstant, this.noNameLabel);
    }
    if (this.contactName.includes(this.returnConstant)) {
      this.contactName = this.contactName.replace(this.returnConstant, this.returnLabel);
    }
    if (customer.telephone === 'n/a') { customer.telephone = ''; }
    this.telephone = customer.telephone;
    this.phoneNumber = this.telephone;
    if (this.telephone === 'n/a') { this.telephone = ''; }
    if (this.telephone === '+30n/a') { this.telephone = ''; }
    if (this.telephone) {
      if (this.telephone.length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
        this.phoneNumber = phoneObj.nationalNumber;
        this.countryPrefix = '+' + phoneObj.countryCallingCode;
      }
    }

    this.email = '';
    if (customer.email) {
      this.email = customer.email;
    }

    this.timeWindows = customer.time_windows[this.projectProblemDayOfWeek];
    this.setTimeWindows();

    this.duration = customer.stop_point_default_duration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);

    this.deliveryLoad = customer.stop_point_default_delivery_load;
    this.pickupLoad = customer.stop_point_default_pickup_load;

    this.customerEntityType = null;
    this.priority = customer.priority;
    if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
      this.priorityOn = true;
    } else {
      this.priorityOn = false;
    }

    this.notes = customer.note ? customer.note : '';

    // this.setForm();
    // patch recipient fields
    this.myForm.patchValue({
      'stopPoint': {
        'contact_name': this.contactName,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'time_windows': this.timeWindows,
        'time_window_unformatted': this.timeWindow,
        'time_window_unformatted2': this.timeWindowDouble,
        'duration_seconds': this.durationSeconds,
        'duration': this.duration,
        'customerEntityType': this.customerEntityType,
        'relatedCustomerId': this.relatedCustomerId,
        'note': this.notes,
        'priority': this.priority,
        'priorityOn': this.priorityOn,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
          'load': this.deliveryLoad,
        },
        'pickupDestinations': {
          'modelName': this.pickupDestinationModelName,
          'modelId': this.pickupDestinationModelId,
          'load': this.pickupLoad,
        },
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'term': this.selectedAddress,
        },
      },
      'customer': {
        'email': this.email,
      },
    });

    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress.valueOf();
    }
    M.updateTextFields();
  }

  onCollaboratorAddressChange() {
    const collaboratorFormValues = this.myForm.value.stopPoint.collaborator;
    // TODO update the day of week and selected timeWindows
    (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = collaboratorFormValues.collaborator_address.term.label;
    if (collaboratorFormValues.collaborator_address.term) {
      this.collaboratorLat = collaboratorFormValues.collaborator_address.term.position[0];
      this.collaboratorLon = collaboratorFormValues.collaborator_address.term.position[1];
      this.collaboratorFreeformAddress = collaboratorFormValues.collaborator_address.term.label;
      this.setCollaboratorAddress(collaboratorFormValues.collaborator_address.term.address);
      this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));
      this.getPriceLists();
    }
  }

  collaboratorAddressListen(event) {
    this.positionSet = true;
    this.collaboratorLat = this.collaboratorMapComponent.lat;
    this.collaboratorLon = this.collaboratorMapComponent.lon;
    this.isClickedOnce = true;
    this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.collaboratorLat},${this.collaboratorLon}`).pipe(take(1)).subscribe(location => {
      this.isClickedOnce = false;
      if (location['data']['addresses']['items']) {
        if (location['data']['addresses']['items'].length) {
          this.setCollaboratorAddress(location['data']['addresses']['items'][0]['address']);
        } else {
          this.freeformAddress = 'Address';
        }
        this.getPriceLists();
      }
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
    });
  }

  recipientAddressListen(event) {
    this.positionSet = true;
    this.lat = this.recipientMapComponent.lat;
    this.lon = this.recipientMapComponent.lon;
    this.isClickedOnce = true;
    this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
      this.isClickedOnce = false;
      if (location['data']['addresses']['items']) {
        if (location['data']['addresses']['items'].length) {
          this.setAddress(location['data']['addresses']['items'][0]['address']);
        } else {
          this.freeformAddress = 'Address';
        }
        this.getPriceLists();
      }
      (<HTMLInputElement>document.getElementById('recipient-address-custom-input')).value = this.freeformAddress;
    });
  }

  collaboratorDragListen(event) {
    this.positionSet = true;
    this.collaboratorLat = this.collaboratorMapComponent.lat;
    this.collaboratorLon = this.collaboratorMapComponent.lon;
    this.patchCollaboratorAddresses();
  }

  recipientDragListen(event) {
    this.positionSet = true;
    this.lat = this.recipientMapComponent.lat;
    this.lon = this.recipientMapComponent.lon;
    this.patchAddresses();
  }

  initPickers() {
    const select = document.querySelectorAll('select');
    const instancesSelect = M.FormSelect.init(select);
    const dateElement = document.querySelectorAll('.datepicker');
    const dateInstances = M.Datepicker.init(dateElement);
  }

  updateSelectDrivers() {
    let driverId;
    // empty drivers array because otherwise the options are not assigned to the dropdown for some twisted reason
    this.drivers = [];
    this.projectProblemDataService.driversArray.forEach(driver => {
      driverId = driver.driver.id;
      this.drivers.push(this.projectProblemDataService.drivers[driverId]);
    });
  }

  populateServicesOptions(addonServices) {
    this.addonServicesForSelectedPriceList = addonServices;
    this.servicesOptions = addonServices;
    if (this.savedServices) this.loadAddonServices();
  }

  populateChargeOptions() {
    if (this.globals.efoodHackEnabled) {
      this.chargeOptions = [
        {
          value: this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'],
          label: this.assignorLabel
        },
      ];
    } else {
      if (this.sameAsConsignorCheckboxSelected) {
        this.chargeOptions = [
          {
            value: this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'],
            label: this.assignorLabel
          },
        ];
      } else if (this.thirdPartyCheckboxSelected && !this.noDeliverySelected) {
        this.chargeOptions = [
          {
            value: this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'],
            label: this.assignorLabel
          },
          {
            value: this.globals.stopPointCollaboratorTypesConstants['SENDER'],
            label: this.collaboratorLabel
          },
          {
            value: this.globals.stopPointCollaboratorTypesConstants['RECEIVER'],
            label: this.receiverLabel
          },
        ];
      } else if (this.noDeliverySelected) {
        this.chargeOptions = [
          {
            value: this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'],
            label: this.assignorLabel
          },
          {
            value: this.globals.stopPointCollaboratorTypesConstants['SENDER'],
            label: this.collaboratorLabel
          }
        ];
      } else {
        this.chargeOptions = [
          {
            value: this.globals.stopPointCollaboratorTypesConstants['SENDER'],
            label: this.collaboratorLabel
          },
          {
            value: this.globals.stopPointCollaboratorTypesConstants['RECEIVER'],
            label: this.receiverLabel
          },
        ];
      }
    }
  }

  populateUpsShipmentTypeOptions() {
    this.upsShipmentTypeOptions = [
      {
        value: "This is a present",
        label: this.shipmentTypeLabel1
      },
      {
        value: "I'm sending my company's data",
        label: this.shipmentTypeLabel2
      },
      {
        value: "I'm selling these goods",
        label: this.shipmentTypeLabel3
      },
      {
        value: "I'm sending product samples",
        label: this.shipmentTypeLabel4
      },
      {
        value: "I'm sending products for repair",
        label: this.shipmentTypeLabel5
      },
      {
        value: "I'm returning these goods",
        label: this.shipmentTypeLabel6
      },
      {
        value: "Documents with no market value",
        label: this.shipmentTypeLabel7
      },
      {
        value: "None of the above",
        label: this.shipmentTypeLabel8
      },
    ];
  }

  populateTaxToOptions() {
    this.chargeTaxOptions = [
      {
        value: this.globals.voucherUpsChargeToConstants['CHARGE_TAX_TO_ACCOUNT'],
        label: this.chargeTypeLabel1
      },
      {
        value: this.globals.voucherUpsChargeToConstants['CHARGE_TAX_TO_RECEIVER'],
        label: this.chargeTypeLabel2
      },
    ];
  }

  populateBasicVoucherServices() {
    // set basic voucher services to check (in shipper portal, we check the selected partner's basic voucher services)
    let basicVoucherServices = this.globals.collaboratorModeEnabled ? this.selectedPartnerItem.basicVoucherServices : this.globals.companyBasicVoucherServices;

    this.basicVoucherServiceOptions = [];
    if (this.collaboratorCountryCode != '') {
      if (basicVoucherServices.length) {
        basicVoucherServices.forEach(voucherService => {
          // national
          if (this.collaboratorCountryCode == 'GRC') {
            if (voucherService['type'] == this.globals.basicVoucherServices['NATIONAL'] || voucherService['type'] == this.globals.basicVoucherServices['GLOBAL']) {
              this.basicVoucherServiceOptions.push({
                id: voucherService['id'],
                name: voucherService['name'],
                invoiceTaxPercentage: voucherService['invoice_tax_percentage'],
                receiptTaxPercentage: voucherService['receipt_tax_percentage'],
              });
            }
          }
          // international / global
          else {
            if (voucherService['type'] == this.globals.basicVoucherServices['INTERNATIONAL'] || voucherService['type'] == this.globals.basicVoucherServices['GLOBAL']) {
              this.basicVoucherServiceOptions.push({
                id: voucherService['id'],
                name: voucherService['name'],
                invoiceTaxPercentage: voucherService['invoice_tax_percentage'],
                receiptTaxPercentage: voucherService['receipt_tax_percentage'],
              });
            }
          }
        });
      }
    }

    this.basicVoucherServiceOptions = [...this.basicVoucherServiceOptions];

    // if no option is selected, then select the first available one (only on create)
    if (!this.stopPointId) {
      if (this.basicVoucherServiceOptions.length) {
        this.updateSelectedServiceOption(this.basicVoucherServiceOptions[0]['id']);
      }
    }
  }

  updateSelectedServiceOption(id) {
    this.basicVoucherServiceId = id;
    this.getPriceLists();
    this.myForm.patchValue({
      'stopPoint': {
        'voucher': {
          'options': {
            'basic_voucher_service_id': this.basicVoucherServiceId,
          }
        }
      }
    });
  }

  insuranceChanged() {
    this.savedSurcharges = null;
    this.calculateSurcharges();
    this.calculatePrice();
  }

  updateSelectedPaymentMethod(data) {
    this.savedSurcharges = null;
    this.requestedPayOnDeliveryPaymentMethod = data['value'];
    this.chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');

    if (this.stopPointId) {
      this.executedPayOnDeliveryPaymentMethod = data['value'];
    } else {
      this.executedPayOnDeliveryPaymentMethod = null;
    }

    this.myForm.patchValue({
      'stopPoint': {
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery
      }
    });

    M.updateTextFields();
    this.calculateSurcharges();
    this.calculatePrice();
  }

  updateSelectedPaymentMethodCourierFare(data) {
    this.savedSurcharges = null;
    this.requestedPaymentMethodCourierFare = data['value'];
    this.chequeCollectionDateCourierFare = moment().add(8, 'days').format('YYYY-MM-DD');

    if (this.stopPointId) {
      this.executedPayOnDeliveryPaymentMethodCourierFare = data['value'];
    } else {
      this.executedPayOnDeliveryPaymentMethodCourierFare = null;
    }

    this.myForm.patchValue({
      'stopPoint': {
        'requested_payment_method_courier_fare': this.requestedPaymentMethodCourierFare,
        'executed_payment_method_courier_fare': this.executedPayOnDeliveryPaymentMethodCourierFare,
        'cheque_collection_date_courier_fare': this.chequeCollectionDateCourierFare
      }
    });

    M.updateTextFields();
    this.calculateSurcharges();
    this.calculatePrice();
  }

  populatePaymentOptions() {
    this.paymentOptions = [
      {
        value: this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'],
        label: this.cashLabel
      },
      {
        value: this.globals.paymentOptionsConstants['CREDIT_CARD'],
        label: this.creditCardLabel
      },
      {
        value: this.globals.paymentOptionsConstants['BANK_DEPOSIT'],
        label: this.bankDepositLabel
      },
      {
        value: this.globals.paymentOptionsConstants['CHEQUE'],
        label: this.chequeLabel
      },
      {
        value: this.globals.paymentOptionsConstants['DAY_CHEQUE'],
        label: this.chequeDayLabel
      }
    ];
  }

  populateTransactionDocumentTypeOptions() {
    this.transactionDocumentTypeOptions = [
      {
        label: this.receiptText,
        value: this.globals.transactionDocumentTypes['RECEIPT']
      },
      {
        label: this.creditText,
        value: this.globals.transactionDocumentTypes['INVOICE']
      },
    ]
  }

  populatePriceLists(priceListsToAdd) {
    const priceListsForDropdown = [];
    this.priceListsData = {};
    priceListsToAdd.forEach(priceList => {
      priceListsForDropdown.push({
        label: priceList.name,
        value: priceList.id
      });
      this.priceListsData[priceList.id] = priceList;
    });
    this.priceLists = priceListsForDropdown;
  }

  populateVolumeCategoryOptions() {

  }

  getHashAndGenerateQr(hash) {
    if (hash) {
      document.getElementById('qr-container').innerHTML = '';
      this.hash = hash;
      const options = {
        text: hash,
        logo: 'assets/lastmilyAssets/logo.png',
        quietZone: 20,
        colorLight: '#fafafa',
      };
      new QRCode(document.getElementById('qr-container'), options);
    }
  }

  setupConsignorIds() {
      if (this.consignorTypeRadioButtonSelected == this.globals.stopPointCollaboratorTypesConstants['SENDER']) {
        this.selectedCollaborator = {
          companyCollaborator: {
            collaborator: {
              collaboratorData: {
                id: this.selectedPartnerItem['collaborator_id'],
                collaborator_name: this.collaboratorName,
              }
            }
          }
        };
        this.myForm.patchValue({
          'stopPoint': {
            'collaborator': {
              'collaboratorData': {
                'selectedCollaborator': this.selectedCollaborator,
              }
            }
          },
        });
      } else if (this.consignorTypeRadioButtonSelected == this.globals.stopPointCollaboratorTypesConstants['RECEIVER']){
        this.selectedRecipient = {
          companyCollaborator: {
            collaborator: {
              collaboratorData: {
                id: this.selectedPartnerItem['collaborator_id'],
                collaborator_name: this.collaboratorName,
              }
            }
          }
        };
        this.myForm.patchValue({
          'stopPoint': {
            'selectedRecipient': this.selectedRecipient,
          },
        });
      } else {
        this.selectedConsignor = {
          companyCollaborator: {
            collaborator: {
              collaboratorData: {
                id: this.selectedPartnerItem['collaborator_id'],
                collaborator_name: this.collaboratorName,
              }
            }
          }
        };
        this.myForm.patchValue({
          'stopPoint': {
            'collaborator': {
              'collaboratorData': {
                'selectedConsignor': this.selectedConsignor,
              }
            }
          },
        });
      }

  }

  populateDepots() {
    const depots = [];
    this.globals.depotsWithNamesArray.forEach(depot => {
      if (depot.companyDepot.company_id === this.selectedPartnerItem['id']) {
        const currDepotName = depot.companyDepot.address.label ? depot.companyDepot.address.label : depot.companyDepot.address.value;
        const currDepot = { ...depot, name: currDepotName };
        depots.push(currDepot);
      }
    });
    this.depots = [...depots];

    const depotName = this.depotUtils.getDepotName(this.depots[0].companyDepot);
    this.selectedDepotItem = {
      companyDepot: {
        id: this.depots[0].companyDepot.id,
      },
      name: depotName
    };
    this.warehouseId = this.depots[0].companyDepot.warehouse_ids[0];

    this.myForm.patchValue({
      'stopPoint': {
        'depot': this.selectedDepotItem,
        'warehouse_id': this.warehouseId
      },
    });
    M.updateTextFields();
  }

  partnerChange(event) {
    this.selectedPartnerItem = event;
    this.defaultLoad = this.stopPointUtils.getDefaultLoad(event);
    this.setDefaultCollaboratorAddress();
    this.populateBasicVoucherServices();
    this.populateDepots();
    this.setupConsignorIds();
    this.selectedService = null; // force a new service to be selected
    this.populateBasicServices();
    this.getPriceLists();
  }

  setDefaultCollaboratorAddress() {
    let address = this.globals.depots[this.globals.depotId]['companyDepot']['address'];
    if (this.selectedPartnerItem) {
      if (this.selectedPartnerItem['id']) {
        const partnerData = this.globals.partners[this.selectedPartnerItem['id']];
        if (partnerData) {
          if (partnerData['partner_depot_address']) {
            address = partnerData['partner_depot_address'];
          }
        }
      }
    }
    this.setCollaboratorAddress(address);

    // [food mode]: set phone number of first depot, too
    if (this.globals.foodModeEnabled && this.globals.collaboratorModeEnabled) {
      this.onFoodDepotChange(this.globals.depotsWithNamesArray[0]);
    }
  }

  onFoodDepotChange(newDepot) {
    this.selectedFoodDepot = newDepot;

    // address
    this.setCollaboratorAddress(newDepot['companyDepot']['address']);
    (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
    this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));

    // phone
    if (newDepot['companyDepot']['telephone']) {
      if (newDepot['companyDepot']['telephone'].length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(newDepot['companyDepot']['telephone']);
        this.pickupPhoneNumber = phoneObj.nationalNumber;
        this.pickupCountryPrefix = '+' + phoneObj.countryCallingCode;
      }

      this.myForm.patchValue({
        'stopPoint': {
          'pickupPhoneNumber': this.pickupPhoneNumber,
          'pickupCountryPrefix': this.pickupCountryPrefix
        }
      });

      M.updateTextFields();
    }
  }

  resetCollaborators() {
    this.collaboratorNormal = null;
    this.collaboratorConsignor = null;
    this.collaboratorReceiver = null;
    this.collaboratorFetchedNormal = null;
    this.collaboratorFetchedConsignor = null;
    this.collaboratorFetchedReceiver = null;
    this.collaboratorNormalAddress = null;
    this.collaboratorConsignorAddress = null;
    this.collaboratorReceiverAddress = null;
    this.collaboratorNormalVoucherId = null;
    this.collaboratorReceiverVoucherId = null;
    this.collaboratorConsignorVoucherId = null;
    this.collaboratorType = null;
    this.collaboratorsArray = [];
  }

  setCollaboratorData() {
    // set 'Collaborator' as the currently logged-in collaborator in new shipments
    if (this.router.url.includes('newShipmentsView') || (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled)) {
      this.collaboratorId = this.globals.partnersArray[0]['collaborator_id'];
      this.collaboratorName = this.globals.partnersArray[0]['collaborator_name'];
      this.collaboratorType = this.globals.companyData['mode'];
      this.collaboratorPhoneNumber = this.globals.companyData['telephone'];
      this.collaboratorTelephone = this.globals.companyData['telephone'];
      if (this.collaboratorPhoneNumber === 'n/a') { this.collaboratorPhoneNumber = ''; }
      if (this.collaboratorPhoneNumber) {
        if (this.collaboratorPhoneNumber.length > 5) {
          const phoneObj = libphonenumber.parsePhoneNumber(this.collaboratorPhoneNumber);
          this.collaboratorPhoneNumber = phoneObj.nationalNumber;
          // this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }
      }

      this.collaboratorType = String(this.globals.voucherPayerConstants['COMPANY']);

      // collaborator portal only (shipper is collaborator, too)
      if (this.globals.collaboratorModeEnabled) {
        this.consignorName = this.collaboratorName;
        this.supplier = this.collaboratorName;
        this.pickupPhoneNumber = this.collaboratorPhoneNumber;
        this.pickupCountryPrefix = this.collaboratorCountryPrefix;
        this.selectedCollaborator = {
          companyCollaborator: {
            collaborator: {
              collaboratorData: {
                id: this.collaboratorId,
                collaborator_name: this.collaboratorName,
                collaborator_type: this.collaboratorType
              }
            }
          }
        };
        this.collaboratorNormal = this.selectedCollaborator.companyCollaborator.collaborator.collaboratorData;

        this.myForm.patchValue({
          'stopPoint': {
            'supplier': this.supplier,
            'pickupPhoneNumber': this.collaboratorPhoneNumber,
            'pickupCountryPrefix': this.collaboratorCountryPrefix,
            'collaborator': {
              'collaboratorData': {
                'selectedCollaborator': this.selectedCollaborator,
                'selectedConsignor': this.selectedConsignor,
                'thirdPartyCheckboxSelected': this.thirdPartyCheckboxSelected
              }
            }
          }
        });

        // document.getElementById('consignor-name-voucher-form').setAttribute('disabled', 'true');
      }

      this.myForm.patchValue({
        'stopPoint': {
          'collaborator': {
            'collaboratorData': {
              'id': this.collaboratorId,
              'collaborator_name': this.collaboratorName,
              'collaborator_type': this.collaboratorType,
              'phoneNumber': this.collaboratorPhoneNumber,
              'telephone': this.collaboratorTelephone,
              'consignor_id': this.consignorId,
              'consignor_name': this.consignorName
            },
          },
        }
      });

      this.setDefaultCollaboratorAddress();
      (<HTMLInputElement>document.getElementById('collaborator-address-custom-input')).value = this.collaboratorFreeformAddress;
      this.collaboratorMapComponent.showDraggableMarker(Number(this.collaboratorLat), Number(this.collaboratorLon));

      // set disabled fields
      // document.getElementById('radio-collaborator-person').setAttribute('disabled', 'true');
      // document.getElementById('collaborator-name-voucher-form').setAttribute('disabled', 'true');

      // document.getElementById('collaborator-address-custom-input').setAttribute('disabled', 'true');
      // document.getElementById('country-code-dropdown').setAttribute('disabled', 'true');
      // document.getElementById('collaborator-telephone-voucher-form').setAttribute('disabled', 'true');

      this.getPriceLists();
    }
  }

  // disable autofill inputs for all browsers (default html attribute doesn't always work)
  forceDisableAutofillElements() {
    setTimeout(() => {
      if (document.getElementById('recipient-address-custom-input')) {
        document.getElementById('recipient-address-custom-input').addEventListener('onautocomplete', (e) => { e.preventDefault(); });
      }
      document.getElementById('collaborator-address-custom-input').addEventListener('onautocomplete', (e) => { e.preventDefault(); });
    }, 1000);
  }

  setUpPartners() {
    // get partners
    this.selectedPartnerItem = {};
    if (this.globals.partnersData) {
      if (this.globals.partnersData['companies']) {
        this.globals.partnersData['companies'].forEach(partner => {
          this.partners.push({
            id: partner.id,
            name: partner.name,
            collaborator_id: partner.collaborator_id,
            collaborator_name: partner.collaborator_name,
            default_stop_point_delivery_load: partner.default_stop_point_delivery_load,
            basicVoucherServices: partner.basicVoucherServices,
            is_pricelist_enabled: partner.is_pricelist_enabled
          });
        });
      } else {
        this.globals.partnersData.forEach(partner => {
          if (partner.companyCollaborator) {
            if (partner.companyCollaborator.partnerCompany) {
              this.partners.push({
                id: partner.companyCollaborator.partnerCompany.id,
                name: partner.companyCollaborator.collaborator.collaboratorData.collaborator_name,
                collaborator_id: partner.companyCollaborator.partnerCompany.id, // chaeck this
                collaborator_name: partner.companyCollaborator.collaborator.collaboratorData.collaborator_name, // chaeck this
                default_stop_point_delivery_load: partner.companyCollaborator.partnerCompany.default_stop_point_delivery_load,
                basicVoucherServices: partner.basicVoucherServices,
                is_pricelist_enabled: partner.is_pricelist_enabled
              });
            }
          }
        });
      }
      this.selectedPartnerItem = this.partners[0];
      if (this.globals.collaboratorModeEnabled) { 
        this.chargesEnabled = this.selectedPartnerItem.is_pricelist_enabled ?? false 
      }
      M.updateTextFields();
    }
  }

  populateBasicServices() {
    const services = [];
    let consignorsBasicServiceIds = [];
    let currentBasicServices = [...this.globals.basicServices];
    if (this.globals.collaboratorModeEnabled) {
      currentBasicServices = this.globals.partners[this.selectedPartnerItem['id']].services;
      this.chargesEnabled = this.selectedPartnerItem.is_pricelist_enabled ?? false;
    }
    if (this.chargesEnabled) {
      // if the voucher is paid by a consignor, use the consignor's services
      this.isPaidByEntityCollaborator = this.checkCourierFareCollaborator();
      if (this.isPaidByEntityCollaborator && !this.globals.collaboratorModeEnabled) {
        consignorsBasicServiceIds = this.getChargedEntity()?.companyCollaborator.connection?.basic_service_ids ?? [];
        // const consignorsBasicServiceIds = [1,2];
        currentBasicServices = this.globals.basicServices.filter(item => consignorsBasicServiceIds.includes(item.id));

        // if the selected service is no longer available, select the fist available and notify the user
        if (!consignorsBasicServiceIds.includes(this.selectedService.id) && !this.stopPointId) {
          this._notificationSvc.showWarning('', this.serviceChangedLabel);
        }
      }
      currentBasicServices.forEach(service => {
        services.push({
          name: service.name,
          id: service.id,
          type: service.type,
          is_default: service.is_default,
          constant: null,
        });
      });
    } else {
      const defaultExpressService = currentBasicServices.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.Express.key));
      const defaultExpressServiceId = defaultExpressService ? defaultExpressService.id : null;
      const defaultNextDayService = currentBasicServices.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.NextDay.key));
      const defaultNextDayServiceId = defaultNextDayService ? defaultNextDayService.id : null;
      const defaultThroughHubService = currentBasicServices.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.ThroughHub.key));
      const defaultThroughHubServiceId = defaultThroughHubService ? defaultThroughHubService.id : null;

      services.push({
        name: this.onlyDeliveryMsg,
        id: defaultExpressServiceId,
        type: this.globals.priceListServicesEnum.Express.key,
        is_default: false,
        constant: this.globals.priceListShipmentEnum.ShopDoor.key,
        shipment_service_type: this.globals.importPickupModes['ONLY_DELIVERY']
      });
      services.push({
        name: this.onlyPickupMsg,
        id: defaultNextDayServiceId,
        type: this.globals.priceListServicesEnum.NextDay.key,
        is_default: false,
        constant: this.globals.priceListShipmentEnum.DoorShop.key,
        shipment_service_type: this.globals.importPickupModes['ONLY_PICKUP']
      });
      services.push({
        name: this.nextDayMsg,
        id: defaultNextDayServiceId,
        type: this.globals.priceListServicesEnum.NextDay.key,
        is_default: true,
        constant: this.globals.priceListShipmentEnum.DoorDoor.key,
        shipment_service_type: this.globals.importPickupModes['NEXT_DAY_DELIVERY']
      });
      services.push({
        name: this.throughHubMsg,
        id: defaultThroughHubServiceId,
        type: this.globals.priceListServicesEnum.ThroughHub.key,
        is_default: true,
        constant: this.globals.priceListShipmentEnum.DoorDoor.key,
        shipment_service_type: this.globals.importPickupModes['SAME_DAY_DELIVERY_THROUGH_HUB']
      });
      services.push({
        name: this.expressMsg,
        id: defaultExpressServiceId,
        type: this.globals.priceListServicesEnum.Express.key,
        is_default: true,
        constant: this.globals.priceListShipmentEnum.DoorDoor.key,
        shipment_service_type: this.globals.importPickupModes['SAME_DAY_DELIVERY']
      });
    }
    this.basicServicesOptions = [...services];
    this.populateShipmentTypeOptions();

    this.defaultService = this.basicServicesOptions.find(service => service.type == this.globals.priceListServicesEnum.NextDay.key);
    if (!this.defaultService) {this.defaultService = this.basicServicesOptions[0]; console.warn('Could not find defaultBasicService, selecting the first one');}
    if (this.selectedService) console.log(consignorsBasicServiceIds.includes(this.selectedService.id));
    const isServiceAvailableInCollaborator = this.selectedService && consignorsBasicServiceIds.length && !consignorsBasicServiceIds.includes(this.selectedService.id)
    if (!this.selectedService || (isServiceAvailableInCollaborator && !this.stopPointId)) {
      this.selectedService = this.defaultService;
      this.myForm.patchValue({
        'stopPoint': {
          'service': this.selectedService,
        },
      });
    }    
  }

  populateShipmentTypeOptions() {
    let shipmentTypeOptions = [];

    Object.keys(this.globals.priceListShipmentEnum).forEach(key => {
      shipmentTypeOptions.push({
        label: this.globals.priceListShipmentEnum[key]['caption'],
        value: this.globals.priceListShipmentEnum[key]['key'],
        constant: this.globals.convertToUpperSnakeCase(key),
      });
    });
    const defaultNextDayService = this.basicServicesOptions.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.NextDay.key));
    if (!defaultNextDayService) shipmentTypeOptions = shipmentTypeOptions.filter(obj => obj.value !== this.globals.priceListShipmentEnum.DoorShop.key);
    this.shipmentTypeOptions = shipmentTypeOptions;

    if (!this.shipmentType || !this.shipmentTypeOptions.find(option => option.value === this.shipmentType)) {
      this.shipmentType = this.shipmentTypeOptions[0]['value'];    
      this.myForm.patchValue({
        'stopPoint': {
          'shipment_type': this.shipmentType,
        }
      });
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
      this.collaboratorLabel = res['SENDER'];
      this.consignorLabel = res['CONSIGNOR'];
      this.customerLabel = res['CUSTOMER'];
      this.receiverLabel = res['RECEIVER'];
      this.assignorLabel = res['ASSIGNOR'];
      this.creditCardLabel = res['CREDIT_CARD'];
      this.cashLabel = res['CASH'];
      this.payOnDeliveryLabel = res['PAY_ON_DELIVERY'];
      this.bankDepositLabel = res['BANK_DEPOSIT'];
      this.chequeLabel = res['CHEQUE'];
      this.chequeDayLabel = res['DAY_CHEQUE'];
      this.receiptText = res['RECEIPT'];
      this.creditText = res['CREDIT'];
      this.populateChargeOptions();
      this.populatePaymentOptions();
      this.populateTransactionDocumentTypeOptions();
      this.collaboratorSectionLabel = this.collaboratorLabel;
      this.stopPointLabel = this.receiverLabel;
      this.collaboratorTitleLabel = res['COLLABORATOR'];
      
      this.onlyDeliveryMsg = res['ONLY_DELIVERY']; 
      this.onlyPickupMsg = res['ONLY_PICKUP']; 
      this.nextDayMsg = res['NEXT_DAY']; 
      this.throughHubMsg = res['THROUGH_HUB']; 
      this.expressMsg = res['SAME_DAY_DELIVERY']; 

      this.populateBasicServices();
      this.defaultService = this.basicServicesOptions.find(service => (service.is_default && service.type == this.globals.priceListServicesEnum.NextDay.key));
      if (!this.defaultService) this.defaultService = this.basicServicesOptions[0];
      this.selectedService = this.defaultService;
    }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('ERROR.TIME_WINDOW_ERROR_MSG').subscribe((res: string) => { this.timeWindowErrorMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.DELIVER_TO_NOTE').subscribe((res: string) => { this.deliverToNoteLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_1').subscribe((res: string) => { this.shipmentTypeLabel1 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_2').subscribe((res: string) => { this.shipmentTypeLabel2 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_3').subscribe((res: string) => { this.shipmentTypeLabel3 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_4').subscribe((res: string) => { this.shipmentTypeLabel4 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_5').subscribe((res: string) => { this.shipmentTypeLabel5 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_6').subscribe((res: string) => { this.shipmentTypeLabel6 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_7').subscribe((res: string) => { this.shipmentTypeLabel7 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SHIPMENT_TYPE_LABEL_8').subscribe((res: string) => { this.shipmentTypeLabel8 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.CHARGE_TYPE_LABEL_1').subscribe((res: string) => { this.chargeTypeLabel1 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.CHARGE_TYPE_LABEL_2').subscribe((res: string) => { this.chargeTypeLabel2 = res; }));
    this.listen.push(this.translate.get('STOP_POINT.VAT_HINT').subscribe((res: string) => { this.taxPercentageLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.CHARGES_CHANGED').subscribe((res: string) => { this.chargesChangedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SERVICE_CHANGED').subscribe((res: string) => { this.serviceChangedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SAVED_NOTIFICATION').subscribe((res: string) => { this.stopPointSavedLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CHANGE_UNAVAILABLE_EDIT').subscribe((res: string) => { this.changeUnavailableEditTitle = res; }));
    this.listen.push(this.translate.get('SYSTEMIC_COURIER_SERVICES.SYSTEMIC_COURIER_UPS_ADDRESS_ERROR').subscribe((res: string) => { this.invalidUpsZoneError = res; }));
    this.listen.push(this.translate.get('STOP_POINT.IN_CREDIT').subscribe((res: string) => { this.inCreditLabel = res; }));
    this.listen.push(this.translate.get('ERROR.WRONG_ADDRESS').subscribe((res: string) => { this.wrongUpsAddressError = res; }));
    this.listen.push(this.translate.get('WARNINGS.DISABLED_SMART_POINT_PAYMENTS').subscribe((res: string) => { this.podSmartPointWarning = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PRICE_LIST_NOT_FOUND').subscribe((res: string) => { this.priceListNotFoundLabel = res; }));

    this.populateUpsShipmentTypeOptions();
    this.populateTaxToOptions();
  }

  ngOnInit(): void {
    this.chargesEnabled = this.globals.chargesEnabled;
    this.setUpPartners();
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.forceDisableAutofillElements();

    // [food mode] default timewindow: current time + 53mins time & pickup time window start is now + 0 mins until end of day
    if (this.globals.foodModeEnabled && this.globals.collaboratorModeEnabled) {
      const momentFromMidnight = moment().clone().startOf('day');
      const momentMidnight = moment().clone().endOf('day');
      this.timeWindow = [moment().diff(momentFromMidnight, 'minutes'), moment().diff(momentFromMidnight, 'minutes') + 53];
      this.timeWindowUnformatted = [moment().format('HH:mm'), moment().add(53, 'minutes').format('HH:mm')];

      this.pickupTimeWindow = [moment().diff(momentFromMidnight, 'minutes') + 0, 1200];
      this.pickupTimeWindowUnformatted = [moment().add(0, 'minutes').format('HH:mm'), moment(momentMidnight).format('HH:mm')];
    }

    this.timeZone = moment.tz.guess();
    this.initPickers();
    // this.populateServicesOptions([]);
    this.populateVolumeCategoryOptions();

    this.payer = this.globals.voucherPayerConstants['COMPANY'];

    if (this.globals.efoodHackEnabled) {
      this.paidBy = this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR'];
    } else {
      this.paidBy = this.globals.stopPointCollaboratorTypesConstants['SENDER'];
    }

    this.requestedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.requestedPaymentMethodCourierFare = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.myForm.patchValue({
      'stopPoint': {
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'requested_payment_method_courier_fare': this.requestedPaymentMethodCourierFare,
        'voucher': {
          'options': {
            'payer': this.payer,
            'paid_by': this.paidBy
          },
        },
      }
    });

    this.duration = this.globals.defaultDuration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.pickupDuration = this.globals.defaultDuration;
    this.pickupDurationSeconds = [moment.duration(this.duration).asSeconds()];
    this.pickupDurationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    const portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    if (portalAccess === this.globals.portalAccessConstants['NO_ACCESS']) {
      this.pickupPortalOn = false;
    } else {
      this.pickupPortalOn = true;
    }
    this.myForm.patchValue({
      'stopPoint': {
        'duration': this.duration,
        'duration_seconds': this.durationSeconds,
        'pickup_duration_seconds': this.pickupDurationSeconds,
        'pickupPortalOn': this.pickupPortalOn,
      }
    });

    const countriesObject = countries.getNames(this.globals.currentLang, { select: 'official' });
    if (countriesObject) {
      const countryDropdownOptions = [];
      Object.keys(countriesObject).forEach(countryCode => {
        const countryName = countriesObject[countryCode];
        countryDropdownOptions.push({
          name: countryName,
          countryCode: countryCode
        })
      });
      this.countryDropdownOptions = countryDropdownOptions;
    }

    this.selectedDepotItem = {};
    const depotsDataRefreshIntervalId = setInterval(() => {
      if (this.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);

        this.depots = [...this.globals.depotsWithNamesArray];

        // if in pp, use that pp's depot else use the first depot of the company (also disable the field in pp)
        if (this.globals.isInRoute('projectView')) {
          const depot = this.globals.depots[this.projectProblemDataService.projectData.company_depot_id].companyDepot;
          const depotName = this.depotUtils.getDepotName(this.globals.depots[this.projectProblemDataService.projectData.company_depot_id].companyDepot);
          this.selectedDepotItem = {
            companyDepot: {
              id: depot.id,
            },
            name: depotName
          };
          this.warehouseId = this.globals.depots[depot.id].companyDepot.warehouse_ids[0];
        } else {
          const depotName = this.depotUtils.getDepotName(this.globals.depots[this.globals.depotId].companyDepot);
          this.selectedDepotItem = {
            companyDepot: {
              id: this.globals.depotId,
            },
            name: depotName
          };
          this.warehouseId = this.globals.depots[this.globals.depotId].companyDepot.warehouse_ids[0];
          if (this.globals.collaboratorModeEnabled) this.populateDepots();
        }

        this.myForm.patchValue({
          'stopPoint': {
            'depot': this.selectedDepotItem,
            'warehouse_id': this.warehouseId
          },
        });
        M.updateTextFields();

        // will be re-enabled on reset & on submit
        if (this.globals.isInRoute('projectView')) {
          this.myForm.controls['stopPoint']['controls']['depot'].disable();
        }
      }
    }, 200);

    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false
    });

    const elements = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elements);

    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddressesAndCustomers(term, this.projectProblemId).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );

    // courier: collaborators & recipients, collaborator: recipients
    this.collaboratorNames = concat(
      of([]), // default items
      this.collaboratorNameInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.collaboratorNamesLoading = true),
        switchMap(term => this.dataService.getCustomersAndCollaborators(term, this.customerLabel, this.consignorLabel).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.collaboratorNamesLoading = false)
        ))
      )
    );

    // courier portal: collaborators & recipients, collaborator portal: recipients
    if (!this.globals.collaboratorModeEnabled) {
      this.recipientNames = concat(
        of([]), // default items
        this.recipientNameInput.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.recipientNamesLoading = true),
          switchMap(term => this.dataService.getCustomersAndCollaborators(term, this.customerLabel, this.consignorLabel).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.recipientNamesLoading = false)
          ))
        )
      );
    } else {
      this.recipientNames = concat(
        of([]), // default items
        this.recipientNameInput.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.recipientNamesLoading = true),
          switchMap(term => this.dataService.getCustomers(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.recipientNamesLoading = false)
          ))
        )
      );
    }

    this.consignorNames = concat(
      of([]), // default items
      this.consignorNameInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.consignorNamesLoading = true),
        switchMap(term => this.dataService.getCollaborators(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.consignorNamesLoading = false)
        ))
      )
    );

    this.collaboratorAddresses = concat(
      of([]), // default items
      this.collaboratorAddressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.collaboratorAddressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.collaboratorAddressesLoading = false)
        ))
      )
    );

    const timeWindowsDataRefreshIntervalId = setInterval(timeWindowsDataChecker.bind(this), 200);
    function timeWindowsDataChecker() {
      if (this.globals.timeWindowsDataDone) {
        clearInterval(timeWindowsDataRefreshIntervalId);
        this.customTimeWindows = this.globals.customTimeWindows;
      }
    }

    const projectProblemDataServiceDataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(projectProblemDataServiceDataRefreshIntervalId);
        this.drivers = this.projectProblemDataService.driversArray;
        // this.updateSelectDrivers();
      }
    }

    this.defaultLoad = this.stopPointUtils.getDefaultLoad(this.router.url.includes('newShipmentsView') ? this.selectedPartnerItem : null);

    this.deliveryLoad = this.defaultLoad;
    this.pickupLoad = this.defaultLoad;
    this.portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    this.countryPrefix = this.globals.defaultCountryCode;
    this.pickupCountryPrefix = this.globals.defaultCountryCode;
    this.collaboratorCountryPrefix = this.globals.defaultCountryCode;
    this.setPortalSwitches();
    this.patchForm();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
