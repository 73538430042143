import { DepotUtils } from '@app/utils/depot-utils';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { Router } from '@angular/router';
import { CustomerCollaboratorsModalComponent } from '@app/modals/customer-collaborators-modal/customer-collaborators-modal.component';
import { CollaboratorViewBalanceComponent } from '@app/collaborator-view/collaborator-view-balance/collaborator-view-balance.component';
import { GenericService } from '@app/services/generic.service';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-partners-grid',
    templateUrl: './partners-grid.component.html',
    styleUrls: ['./../grids.scss', './partners-grid.component.scss']
})
export class PartnersGridComponent implements OnInit, OnDestroy {
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('openChartButton', { static: false }) openChartButton;
    @ViewChild('openMapButton', { static: false }) openMapButton;

    @ViewChild(CustomerCollaboratorsModalComponent, { static: false }) customerCollaboratorsModalComponent: CustomerCollaboratorsModalComponent;
    @ViewChild(CollaboratorViewBalanceComponent, { static: false }) collaboratorViewBalanceComponent: CollaboratorViewBalanceComponent;

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];
    columnDefs;
    rowModelType = 'serverSide';
    cacheBlockSize = 50;
    gridApi;
    gridColumnApi;
    rowData = [];

    pagesCount = 0;
    partnersDataArray = [];
    partnersCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    nameTitle;
    telephoneTitle;
    addressTitle;
    taxDetailsTitle;
    basePriceTitle;
    toGetPaidTitle;
    toPayTitle;
    avPointsPerMonthTitle: string;
    avRatingTitle: string;
    currency;
    allDepotsLabel;
    actionsTitle;
    mostVisitedLabel;
    showSortDropdown = false;
    selectedOrder = '';
    depots = [];

    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;
    selectedDepot;

    chartExpanded = true;
    chartDisabled = false;

    noNameLabel = '';
    listen = [];

    // rating (doughnut chart)
    totalRating = 0;
    usersRating = 0;

    toPayTotal = 0;
    toGetPaidTotal = 0;

    collaboratorIdToLogoMap = {};
    avatarHashes = [];

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private addressService: AddressService,
        private genericService: GenericService,
        private router: Router,
        private depotUtils: DepotUtils
    ) {
        this.listen.push(this.genericService.listenSubmitPartner().subscribe(() => {
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }));
    }

    createTotalRatingDoughnut() {
        let max = 5;
        let extraCurve = 0.6;
        let quarter = max / 5 + extraCurve;

        let canvas = <HTMLCanvasElement>document.getElementById('totalRatingChart');
        let ctx = canvas.getContext('2d');
        let totalRatingChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [quarter, this.totalRating, max - this.totalRating],
                    backgroundColor: [
                        'rgba(0, 0, 0, 0)',
                        'rgb(0, 174, 186)',
                        'rgb(217, 243, 245)',
                    ],
                }]
            },
            options: {
                //tooltips: { enabled: false },
                hover: { mode: null },
                //cutoutPercentage: 70,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                }
            }
        });
    }

    createUsersRatingDoughnut() {
        let max = 5;
        let extraCurve = 0.6;
        let quarter = max / 5 + extraCurve;

        let canvas = <HTMLCanvasElement>document.getElementById('usersRatingChart');
        let ctx = canvas.getContext('2d');
        let usersRatingChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [quarter, this.usersRating, max - this.usersRating],
                    backgroundColor: [
                        'rgba(0, 0, 0, 0)',
                        'rgb(0, 174, 186)',
                        'rgb(217, 243, 245)',
                    ],
                }]
            },
            options: {
                //tooltips: { enabled: false },
                hover: { mode: null },
                //cutoutPercentage: 70,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
            }
        });
    }

    createDummyRatings() {
        // ratings
        this.totalRating = 3.2;
        this.usersRating = 4;

        // rating charts
        this.createTotalRatingDoughnut();
        this.createUsersRatingDoughnut();
    }

    newButtonClicked() {
        this.modalService.openCustomerCollaboratorsModal(null);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getDataForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'most-visited':
                data = this.mostVisited;
                break;
        }
        return data;
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
        this.openChartButton.nativeElement.classList.add('active');
        this.openMapButton.nativeElement.classList.remove('active');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
        this.openChartButton.nativeElement.classList.remove('active');
        this.openMapButton.nativeElement.classList.add('active');
    }

    openMap() {
        this.globals.comingSoonAlert();
    }

    emptyChartArrays() {
        this.customerNames = [];
        this.customerShortNames = [];
        this.mostVisited = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    displayDummyChartValues() {
        this.customerShortNames = [];
        for (let i = 0; i < 10; i++) {
            this.customerShortNames.push('');
        }
        this.mostVisited = [25, 24, 20, 16, 16, 15, 12, 10, 8, 8];
        this.chartDisabled = true;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&ids=';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;

        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            params += `&depotId=${this.selectedDepot.companyDepot.id}`
        }
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        setTimeout(() => {
            this.dataLoading = false;
            this.displayDummyChartValues();
            this.initChart();
        }, 200);
        // this.http.get('api/v1/stats/customer-stats' + params).pipe(take(1)).subscribe(response => {
        //     this.dataLoading = false;
        //     const data = response['items'];
        //     if (Object.keys(data).length) {
        //         if (Object.keys(data).length < this.pageSize) {
        //             this.lastPage = true;
        //         } else if (Object.keys(data).length === this.pageSize) {
        //             this.lastPage = false;
        //         }
        //         Object.keys(data).forEach(id => {
        //             if (data[id]) {
        //                 this.chartData[id] = data[id];
        //                 this.customerNames.push(data[id].contact_name);
        //                 this.customerShortNames.push(this.getFirstWord(data[id].contact_name));
        //                 this.mostVisited.push(data[id].stopPointsCount);
        //             }
        //         });
        //         if (this.checkIfAllZero()) {
        //             this.displayDummyChartValues();
        //         }
        //     } else {
        //         this.lastPage = true;
        //         this.displayDummyChartValues();
        //     }
        //     this.initChart();
        // });
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('collaborators-chart');
        const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }
        // const chartOptions:ChartOptions = this.chartService.chartOptions;
        const customerNames = this.customerNames;
        // chartOptions['tooltips'] = {
        //     callbacks: {
        //         title: function (tooltipItem, tooltipData) {
        //             return customerNames[tooltipItem[0].index];
        //         }
        //     }
        // };
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.customerShortNames,
                    datasets: [{
                        barPercentage: 0.9,
                        barThickness: 'flex',
                        maxBarThickness: 150,
                        minBarLength: 2,
                        data: data,
                        backgroundColor: (() => {
                            const backgroundColours = [];
                            for (let i = 0; i < data.length; ++i) {
                                backgroundColours.push(this.chartService.chartColours[i % this.chartService.chartColours.length]);
                            }
                            return backgroundColours;
                        })()
                    }]
                }
                // ,
                // options: this.chartService.chartOptions
            });
        }
    }

    // get request
    getPartnersGridData(pageSize) {
        let url = '';

        if (this.globals.collaboratorModeEnabled) {
            url = 'api/v1/partner';
            url += '?pageSize=' + pageSize;
            url += '&page=' + this.pagesCount;
            url += '&searchQuery=' + this.searchString;
        }
        else {
            url = 'api/v1/company-collaborators';
            url += '?partnerType=' + this.globals.companyModes['NORMAL'];
            url += '&pageSize=' + pageSize;
            url += '&page=' + this.pagesCount;
            url += '&searchQuery=' + this.searchString;
        }

        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            url += '&depotId=' + String(this.selectedDepot.companyDepot.id);
        }

        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setPartnersGridData(dataArray), lastRow);
        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setPartnersGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getPartnersGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
            const collaborators = response['items'];
            // load avatars
            if (this.globals.collaboratorModeEnabled) {
                if (collaborators.companies.length) {
                    collaborators.companies.forEach(partner => {
                        const imageHash = partner?.logo?.imageHash;
                        if (imageHash) {
                            this.avatarHashes.push(imageHash);
                            this.http.get(`api/internal/v1/images/partner-company-logo/${imageHash}`).subscribe(image => {
                                this.collaboratorIdToLogoMap[partner.id] = image['base64'];
                            });
                        }
                    });
                }
            } else {
                if (collaborators.length) {
                    collaborators.forEach(collaborator => {
                        const imageHash = collaborator.companyCollaborator?.partnerCompany?.companyLogo?.imageHash;
                        if (imageHash) {
                            this.avatarHashes.push(imageHash);
                            this.http.get(`api/internal/v1/images/partner-company-logo/${imageHash}`).subscribe(image => {
                                this.collaboratorIdToLogoMap[collaborator.companyCollaborator.partnerCompany.id] = image['base64'];
                            });
                        }
                    });
                }
            }

            // load partners data
            const readyToLoadInterval = setInterval(() => {
                if (this.avatarHashes.length == Object.keys(this.collaboratorIdToLogoMap).length) {
                    this.partnersCount = response['itemsMeta'].totalCount;
                    if (this.globals.collaboratorModeEnabled) {
                        this.partnersDataArray = response['items']['companies'];
                    } else {
                        this.partnersDataArray = response['items'];
                    }

                    // set avatars
                    this.displayDataToGrid(params, this.partnersDataArray, response['itemsMeta']['pagesCount']);
                    this.gridApi.hideOverlay();
                    clearInterval(readyToLoadInterval);
                }
            }, 500);
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }

    createServerSideDatasource() {
        const self = this;
        return {
            getRows: function (params) {
                self.gridApi.showLoadingOverlay();
                self.loadSinglePage(params);
            },
        };
    }

    setPartnersGridData(partners) {
        this.partnersDataArray = []; // empty partners data array first
        let gridObject = {};

        if (partners.length) {
            partners.forEach(partner => {
                // collaborator login
                if (this.globals.collaboratorModeEnabled) {
                    if (partner) {
                        const addressValueLabel = this.addressService.getAddressLabel(partner.address);
                        const addressValue = addressValueLabel ? addressValueLabel : '-';
                        const basePriceValue = partner.collaborator_price ? partner.collaborator_price.toFixed(2) + this.currency : '-';
                        const toPayValue = partner.collaboratorToPay ? partner.collaboratorToPay.toFixed(2) + this.currency : '-';
                        const toGetPaidValue = partner.partnerToPay ? partner.partnerToPay.toFixed(2) + this.currency : '-';

                        this.toPayTotal += partner.collaboratorToPay;
                        this.toGetPaidTotal += partner.partnerToPay;

                        let logoValue = this.collaboratorIdToLogoMap[partner.id];

                        gridObject = {
                            id: partner.collaborator_id,
                            details: {
                                name: partner.name,
                                logoBase64: logoValue
                            },
                            address: addressValue,
                            avPointsPerMonth: partner.avPointsPerMonth,
                            avRating: partner.avRating,
                            basePrice: basePriceValue,
                            toGetPaid: toGetPaidValue,
                            toPay: toPayValue,
                            objData: partner
                        };
                    }
                }
                // company login (partner view)
                else {
                    if (partner.companyCollaborator) {
                        const partnerCompany = partner.companyCollaborator.partnerCompany;
                        const collaboratorData = partner.companyCollaborator.collaborator.collaboratorData;

                        const basePriceValue = collaboratorData.collaborator_price ? collaboratorData.collaborator_price + this.currency : '-';
                        const toPayValue = collaboratorData.collaboratorToPay ? collaboratorData.collaboratorToPay + this.currency : '-';
                        const toGetPaidValue = collaboratorData.partnerToPay ? collaboratorData.partnerToPay + this.currency : '-';

                        let logoValue = this.collaboratorIdToLogoMap[partnerCompany?.id];

                        let addressValue = '-';
                        if (partner.companyCollaborator.collaborator) {
                            const addressValueLabel = this.addressService.getAddressLabel(partner.companyCollaborator.collaborator.address);
                            addressValue = addressValueLabel;
                        }

                        gridObject = {
                            id: partner.companyCollaborator.collaborator.collaboratorData.id,
                            details: {
                                name: partner.companyCollaborator.collaborator.collaboratorData.collaborator_name,
                                logoBase64: logoValue
                            },
                            address: addressValue,
                            avPointsPerMonth: partner.avPointsPerMonth,
                            avRating: partner.avRating,
                            basePrice: basePriceValue,
                            toGetPaid: toGetPaidValue,
                            toPay: toPayValue,
                            objData: partner
                        };
                    }
                }

                this.partnersDataArray.push(gridObject);
            });

            // set partners' balance
            const balanceObj = {
                toPay: this.toPayTotal,
                toGetPaid: this.toGetPaidTotal
            };
            this.genericService.updatePartnerBalance(balanceObj);
        } else {
            const noData = { noDataText: 'No data' }
            this.partnersDataArray.push(noData);
        }

        return this.partnersDataArray;
    }

    // custom cell renderer for name & logo
    nameLogoRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="single-cell standard-width display-flex">';
            if (params.getValue().logoBase64) {
                columnObject += '<div class="logo-col" style="background-image: url(data:image/png;base64,' + params.getValue().logoBase64 + ');"></div>';
            } else {
                columnObject += '<div class="logo-replacement">' + (params.getValue().name).charAt(0) + '</div>';
            }
            columnObject += params.getValue().name;
            columnObject += '</div>';

        }
        return columnObject;
    }

    // Go to collaborators view on row clicked
    rowClicked(event) {
        const target = event.event.target.closest('div');
        if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
            this.router.navigateByUrl('/partners/collaboratorPartnerView/' + event.data.id);
        }
    }

    onFirstDataRendered(params) { }

    createdDateRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.setServerSideDatasource(this.createServerSideDatasource());
    }

    getTranslations() {
        this.listen.push(this.translate.get('PARTNERS.NAME').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('PARTNERS.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
        this.listen.push(this.translate.get('PARTNERS.AV_POINTS_MONTH').subscribe((res: string) => { this.avPointsPerMonthTitle = res; }));
        this.listen.push(this.translate.get('PARTNERS.AV_RATING').subscribe((res: string) => { this.avRatingTitle = res; }));
        this.listen.push(this.translate.get('PARTNERS.BASE_PRICE').subscribe((res: string) => { this.basePriceTitle = res; }));
        this.listen.push(this.translate.get('PARTNERS.TO_GET_PAID').subscribe((res: string) => { this.toGetPaidTitle = res; }));
        this.listen.push(this.translate.get('PARTNERS.TO_PAY').subscribe((res: string) => { this.toPayTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));

        this.charts = [
            {
                label: this.mostVisitedLabel,
                value: 'most-visited',
            },
        ];
        this.selectedChart = this.charts[0];

        // add all depots option
        this.depots = this.depotUtils.getDepotsWithAllDepotsOption();
        this.selectedDepot = this.depots[0];

        this.columnDefs = [
            {
                headerName: this.nameTitle,
                field: 'details',
                cellRenderer: this.nameLogoRenderer,
                width: this.gridsService.widthCalculator(35)
            },
            {
                headerName: this.addressTitle,
                field: 'address',
                cellRenderer: this.gridsService.addressRenderer,
                width: this.gridsService.widthCalculator(45) // normally 25
            },
            // { headerName: this.avPointsPerMonthTitle, field: 'avPointsPerMonth', width: this.gridsService.widthCalculator(10) },
            // { headerName: this.avRatingTitle, field: 'avRating', width: this.gridsService.widthCalculator(10) },
            // { headerName: this.basePriceTitle, field: 'basePrice', width: this.gridsService.widthCalculator(10) },
            { headerName: this.toGetPaidTitle, field: 'toGetPaid', width: this.gridsService.widthCalculator(10) },
            { headerName: this.toPayTitle, field: 'toPay', width: this.gridsService.widthCalculator(10) },
        ];
    }

    ngAfterContentInit() {
        // for testing only!

    }

    ngOnInit(): void {
        this.createDummyRatings();

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.periods = this.chartService.periods;
        this.selectedPeriod = this.periods[4];

        this.getChartData();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.pagesCount = 0;
                // this.gridApi.purgeServerSideCache([]);
                this.gridApi.refreshServerSideStore({ purge: true });
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
