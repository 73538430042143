import { ColourService } from './../../../services/colour.service';
import { take } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { DashboardConsignorsDummyComponent } from './dashboard-consignors-dummy/dashboard-consignors-dummy.component';
import { HttpClient } from '@angular/common/http';
// import { Chart } from 'chart.js';
import { ChartService } from '@app/services/chart.service';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-dashboard-consignors',
    templateUrl: './dashboard-consignors.component.html'
})

export class DashboardConsignorsComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardConsignorsDummyComponent, { static: false }) dashboardConsignorsDummyComponent: DashboardConsignorsDummyComponent;

    listen = [];

    chart;
    chartData = {};

    showSampleDataText = false;

    // these are set after the response of the request
    consignorsData = []; // names
    shipmentsData = [];
    partnersRatingData = [];
    partnersData = []; // names
    consignorColourData = [];

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        public chartService: ChartService,
        public colourService: ColourService,
    ) {
        // this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
        //     this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
        //     this.updateView();
        // }));
    }

    // CHART
    initChart() {
        const chartOptions:ChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend:{
                    display: false,
                    position: 'top'
                },
                tooltip:{
                    callbacks: {
                        // la
                        // label: function (tooltipItem, data) {
                        //     return data.labels[tooltipItem.index] + ' : ' +
                        //         data.datasets[0].data[tooltipItem.index] + '%';
                        // }
                    }
                }
            }
            // tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return data.labels[tooltipItem.index] + ' : ' +
            //                 data.datasets[0].data[tooltipItem.index] + '%';
            //         }
            //     }
            // },
            // legend: {
            //     display: false,
            //     position: 'top'
            // }
        };

        // dummy data
        if (!this.shipmentsData.length) {
            this.shipmentsData = [30, 20, 70];
            chartOptions.plugins.tooltip['enabled'] = false;
            chartOptions['hover'] = { mode: null };
            this.showSampleDataText = true;
        } else {
            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('consignors-chart');
        if (this.chart) { this.chart.destroy(); }
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: this.consignorsData,
                    datasets: [
                        {
                            data: this.shipmentsData,
                            backgroundColor: this.consignorColourData,
                        }
                    ]
                },

                options: chartOptions
            });
        }
    }

    initPartnersChart() {
        const chartOptions:ChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend:{
                    display: false,
                    position: 'top'
                },
                tooltip:{
                    callbacks: {
                        // la
                        // label: function (tooltipItem, data) {
                        //     return data.labels[tooltipItem.index] + ' : ' +
                        //         data.datasets[0].data[tooltipItem.index] + '%';
                        // }
                    }
                }
            }
        };

        // dummy data
        if (!this.partnersRatingData.length) {
            this.partnersRatingData = [30, 20, 70];
            chartOptions.plugins.tooltip['enabled'] = false;
            chartOptions['hover'] = { mode: null };
        }

        const ctx = <HTMLCanvasElement>document.getElementById('consignors-chart');
        if (this.chart) { this.chart.destroy(); }
        chartOptions.plugins.legend.display = true;
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: this.partnersData,
                    datasets: [
                        {
                            data: this.partnersRatingData,
                            backgroundColor: this.consignorColourData,
                        }
                    ]
                },

                options: chartOptions
            });
        }
    }

    getPercentagesPerCollaborator(collaboratorsStats) {
        let total = 0;
        let collaboratorStatsPercentages = [];

        collaboratorsStats.forEach(amount => {
            total += amount;
        });

        collaboratorsStats.forEach(amount => {
            const totalFixedDecimals = Number(((amount / total) * 100).toFixed(2));
            collaboratorStatsPercentages.push(totalFixedDecimals);
        });

        return collaboratorStatsPercentages;
    }

    getPercentagesPerPartner(partnerStats) {
        let total = 0;
        let partnerStatsPercentages = [];

        partnerStats.forEach(amount => {
            total += amount;
        });

        partnerStats.forEach(amount => {
            const totalFixedDecimals = Number(((amount / total) * 100).toFixed(2));
            partnerStatsPercentages.push(totalFixedDecimals);
        });

        return partnerStatsPercentages;
    }

    getTranslations() {
        // this.listen.push(this.translate.get('DASHBOARD.LEGEND_SHIPMENTS_COMPLETED').subscribe((res: string) => { this.shipmentsCompletedLegend = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
    }
}
