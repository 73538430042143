import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private listeners = new Subject<any>();
  private openCustomerModalListeners = new Subject<any>();
  private openCustomerDateModalListeners = new Subject<any>();
  private openInvoiceModalListeners = new Subject<any>();
  private openPaymentModalListeners = new Subject<any>();
  private updatePaymentModalListeners = new Subject<any>();
  private openCustomerCollaboratorsModalListeners = new Subject<any>();
  private openPartnerModalListeners = new Subject<any>();
  private openDriverModalListeners = new Subject<any>();
  private updateStopPointsDateListener = new Subject<any>();
  private submitDateRangeListener = new Subject<any>();
  private submitDateListener = new Subject<any>();
  private openDateModalListener = new Subject<any>();
  private updateInvoiceDateListener = new Subject<any>();
  private updateCustomerDateListener = new Subject<any>();
  private updateStopPointsGridListener = new Subject<any>();
  private refreshStopsGridListener = new Subject<any>();
  private openShipmentsModalListener = new Subject<any>();
  private updateReportShipmentsModalListener = new Subject<any>();
  private closeAddStopModalListener = new Subject<any>();
  private openAddStopModalListener = new Subject<any>();

  listen(): Observable<any> {
    return this.listeners.asObservable();
  }
  openCustomerModalListen(): Observable<any> {
    return this.openCustomerModalListeners.asObservable();
  }
  openCustomerDateModalListen(): Observable<any> {
    return this.openCustomerDateModalListeners.asObservable();
  }
  openInvoiceModalListen(): Observable<any> {
    return this.openInvoiceModalListeners.asObservable();
  }
  openPaymentModalListen() {
    return this.openPaymentModalListeners.asObservable();
  }
  updatePaymentModalListen() {
    return this.updatePaymentModalListeners.asObservable();
  }
  openCustomerCollaboratorsModalListen(): Observable<any> {
    return this.openCustomerCollaboratorsModalListeners.asObservable();
  }
  openPartnerModalListen(): Observable<any> {
    return this.openPartnerModalListeners.asObservable();
  }
  openDriverModalListen(): Observable<any> {
    return this.openDriverModalListeners.asObservable();
  }
  updateStopPointsDateListen(): Observable<any> {
    return this.updateStopPointsDateListener.asObservable();
  }
  submitDateRangeListen(): Observable<any> {
    return this.submitDateRangeListener.asObservable();
  }
  openDateModalListen(): Observable<any> {
    return this.openDateModalListener.asObservable();
  }
  submitDateListen(): Observable<any> {
    return this.submitDateListener.asObservable();
  }
  updateInvoiceDateListen(): Observable<any> {
    return this.updateInvoiceDateListener.asObservable();
  }
  updateCustomerDateListen(): Observable<any> {
    return this.updateCustomerDateListener.asObservable();
  }
  updateStopPointsGridListen(): Observable<any> {
    return this.updateStopPointsGridListener.asObservable();
  }
  refreshStopsGridListen(): Observable<any> {
    return this.refreshStopsGridListener.asObservable();
  }
  openShipmentsModalListen(): Observable<any> {
    return this.openShipmentsModalListener.asObservable();
  }
  updateReportShipmentsModalListen(): Observable<any> {
    return this.updateReportShipmentsModalListener.asObservable();
  }
  closeAddStopModalListen(): Observable<any> {
    return this.closeAddStopModalListener.asObservable();
  }
  openAddStopModalListen(): Observable<any> {
    return this.openAddStopModalListener.asObservable();
  }

  filter(formName: string, type, id) {
    this.listeners.next({
      form: formName,
      type: type,
      id: id
    });
  }

  openCustomerModal(id) {
    this.openCustomerModalListeners.next(id);
  }
  openCustomerDateModal() {
    this.openCustomerDateModalListeners.next(1);
  }
  openInvoiceModal(data) {
    this.openInvoiceModalListeners.next(data);
  }
  openPaymentModal(data) {
    this.openPaymentModalListeners.next(data);
  }
  updatePaymentModal() {
    this.updatePaymentModalListeners.next(1);
  }
  openCustomerCollaboratorsModal(data) {
    this.openCustomerCollaboratorsModalListeners.next(data);
  }
  openPartnerModal(data) {
    this.openPartnerModalListeners.next(data);
  }
  openDriverModal() {
    this.openDriverModalListeners.next(1);
  }
  updateStopPointsDate(dates) {
    this.updateStopPointsDateListener.next(dates);
  }
  submitDateRange(dates) {
    this.submitDateRangeListener.next(dates);
  }
  submitDate(date) {
    this.submitDateListener.next(date);
  }
  openDateModal(type) {
    this.openDateModalListener.next(type);
  }
  updateInvoiceDate(date) {
    this.updateInvoiceDateListener.next(date);
  }
  updateCustomerDate(date) {
    this.updateCustomerDateListener.next(date);
  }
  updateStopPointsGrid() {
    this.updateStopPointsGridListener.next(1);
  }
  refreshStopsGrid() {
    this.refreshStopsGridListener.next(1);
  }
  openShipmentsModal(type) {
    this.openShipmentsModalListener.next(type);
  }
  updateReportShipmentsModal() {
    this.updateReportShipmentsModalListener.next(1);
  }
  closeAddStopModal() {
    this.closeAddStopModalListener.next(1);
  }
  openAddStopModal() {
    this.openAddStopModalListener.next(1);
  }
}
