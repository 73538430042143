import { MilyService } from '@app/services/mily.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { VoucherFormComponent } from '@app/forms/voucher-form/voucher-form.component';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { DeliveryFormComponent } from '@app/forms/delivery-form/delivery-form.component';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { GenericService } from '@app/services/generic.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';

@Component({
    selector: 'app-voucher-form-modal',
    templateUrl: './voucher-form-modal.component.html',
    styleUrls: ['./voucher-form-modal.component.scss']
})
export class VoucherFormModalComponent implements OnInit, OnDestroy {
    @ViewChild(VoucherFormComponent, { static: false }) voucherFormComponent: VoucherFormComponent;
    @ViewChild(DeliveryFormComponent, { static: false }) deliveryFormComponent: DeliveryFormComponent;
    @ViewChild('modal', { static: false }) modal;

    updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
    loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';
    stopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/';

    modalOpen = false;

    projectProblemId; // is set in project view component
    projectProblemDepartureDatetime;
    listen = [];

    stopPointId = null;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private viewProjectProblemService: ViewProjectProblemService,
        private draggableMarkerService: DraggableMarkerService,
        private milyService: MilyService,
        public router: Router,
        public genericService: GenericService,
        public stopPointUtils: StopPointUtils,
    ) {
        this.listen.push(this.viewProjectProblemService.openStopFormModalListen().subscribe((noVoucher) => {
            if (this.globals.vouchersEnabled && !noVoucher && this.projectProblemId) {
                this.openModal(true);
            } else if (this.globals.vouchersEnabled && !noVoucher && !this.projectProblemId) {
                this.openModal(true);
            }
        }));
        this.listen.push(this.viewProjectProblemService.listenEditStopPoint().subscribe((data) => {
            // open voucher form if voucher are enabled and the SP has a voucher or is same day delivery with voucher
            if (this.globals.vouchersEnabled && data.hasVoucher) {
                this.openModal();
                setTimeout(() => {
                    // this.voucherFormComponent.tempProjectProblemId = data.tempProjectProblemId;
                    this.voucherFormComponent.getFormData(data.stopPointId, data.projectProblemId);
                    this.viewProjectProblemService.closeStopModal();
                }, 100);
            }
        }));
        this.listen.push(this.viewProjectProblemService.createStopFromMapClickListen().subscribe((data) => {
            if (!this.viewProjectProblemService.isStopPointModalOpen) {
                if (!data.helperOpen && this.globals.vouchersEnabled && (this.projectProblemId || this.router.url.includes('newShipmentsView'))) {
                    this.openModal(true);
                    setTimeout(() => {
                        this.voucherFormComponent.createStopFromMapClick(data);
                    }, 100);
                }
            }
        }));
    }

    dataLoadingListen(event) {
        if (event === 'true') {
            this.voucherFormComponent.isClickedOnce = true;
        } else if (event === 'false') {
            this.voucherFormComponent.isClickedOnce = false;
        }
    }

    toggleModalListen(event) {
        if (event === 'open') {
            this.openModal();
        } else if (event === 'close') {
            this.closeModal();
        }
    }

    shrinkModal() {
        this.modal.nativeElement.classList.remove('expanded');

    }

    public setStopFormData(projectProblemId, projectProblemDepartureDatetime) {
        this.projectProblemId = projectProblemId;
        this.projectProblemDepartureDatetime = projectProblemDepartureDatetime;
    }

    openModal(createBarcode = false, askForCharges = false) {
        this.genericService.comm100ZIndexFix();
        this.modalOpen = true;
        this.viewProjectProblemService.isStopPointModalOpen = true;
        setTimeout(() => {
            this.voucherFormComponent.setStopFormData(this.projectProblemId, this.projectProblemDepartureDatetime);
            if (!this.voucherFormComponent.recipientMapComponent.map) {
                this.voucherFormComponent.recipientMapComponent.initMap();
            }
            if (!this.voucherFormComponent.collaboratorMapComponent.map) {
                this.voucherFormComponent.collaboratorMapComponent.initMap();
            }

            this.voucherFormComponent.setCollaboratorData();

            if (createBarcode) {
                this.http.get('api/v1/voucher-barcode').pipe(take(1)).subscribe(response => {
                    if (response['items']) {
                        if (response['items']['voucherBarcode']) {
                            const hash = response['items']['voucherBarcode']
                            this.voucherFormComponent.hash = hash;
                            this.milyService.closeMily();
                            this.voucherFormComponent.getHashAndGenerateQr(hash);
                            const modal = document.querySelector('.voucher-form-modal');
                            const modalBackground = document.querySelector('.voucher-form-modal-background');
                            modal.classList.remove('closed');
                            modal.classList.add('open');
                            modalBackground.classList.remove('hidden');
                            this.voucherFormComponent.agreedShippingDateChanged = false;
                        }
                    }
                });
            } else {
                this.milyService.closeMily();
                const modal = document.querySelector('.voucher-form-modal');
                const modalBackground = document.querySelector('.voucher-form-modal-background');
                modal.classList.remove('closed');
                modal.classList.add('open');
                modalBackground.classList.remove('hidden');
                this.voucherFormComponent.agreedShippingDateChanged = false;
            }
            if (askForCharges) {
                this.voucherFormComponent.getPriceLists();
            }
        }, 100);
    }

    closeModal() {
        const modal = document.querySelector('.voucher-form-modal');
        const modalBackground = document.querySelector('.voucher-form-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.shrinkModal();
        this.voucherFormComponent.resetForm();
        this.deliveryFormComponent.resetForm();
        this.draggableMarkerService.removeDraggableMarker();
        setTimeout(() => {
            this.modalOpen = false;
            this.viewProjectProblemService.isStopPointModalOpen = false;
        }, 1000);
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
