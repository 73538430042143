import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { LmTableViewModelService } from '@app/shared/structure/table/table-viewmodel.service';
import { LmBaseEntity } from '@app/model/base-entity';
import { LmButtonConfig, LmButtonModule } from '@app/shared/structure/button/button.component';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { PrimeIcons } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';



@Component({
  selector: 'lm-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss']
})
export class LmFormTableComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() striped = true;
    @Input() rows: any[];
    @Input() cols: LmTableBaseColumnDefs[]
    editBtn:LmButtonConfig;
    saveBtn:LmButtonConfig;
    cancelBtn:LmButtonConfig;
    deleteBtn:LmButtonConfig;
    
    editingRows: { [r:string]: any} = {}
    
    constructor(){
        this.editBtn = { 
            icon: PrimeIcons.PENCIL, 
            textOnly: true, 
            command: (row) => this.editRow(row)
        }
        this.deleteBtn = { 
            icon: PrimeIcons.TRASH, 
            textOnly: true, 
            command: (row, index) => this.deleteRow(row, index)
        }
        this.saveBtn = { 
            icon: PrimeIcons.CHECK, 
            textOnly: true, 
            command: (row) => this.saveRow(row)
        }
        this.cancelBtn = { 
            icon: PrimeIcons.TIMES, 
            textOnly: true, 
            styleClass: 'lm-txt-color-red',
            command: (row, index) => this.cancelEdit(row, index)
        }
    }

    editRow(row) {
        this.editingRows[row.id] = {...row};
    }

    saveRow(row) {
        console.log(row);
        delete this.editingRows[row.id];
    } 
    
    deleteRow(row, index){
        console.log('index:', index)
        this.rows.splice(index,1)
    }
    cancelEdit(row, index) {
        this.rows[index] = this.editingRows[row.id];
        delete this.editingRows[row.id];
    }
   

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TableModule, DropdownModule, LmButtonModule],
  exports: [LmFormTableComponent, TableModule, DropdownModule],
  declarations: [LmFormTableComponent]
})
export class LmFormTableModule {}

