import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, HostBinding, InjectFlags, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NgModel, Validator } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dropdown } from 'primeng/dropdown';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LmFilterOperatorId } from '../../../model/operator-types';
import { LMValidationMessage } from '../../../model/validator';
import { LmLoggerService } from '../../../core/services/logger.service';
import { LmModelProxyService } from '../../../core/services/model-proxy/model-proxy.service';
import { LmModelValidationService } from '../../../core/services/model-proxy/model-validation.service';
import { LmNotificationService } from '@app/core/services/notification.service';

@UntilDestroy()
@Directive()
export abstract class LmInputBase implements OnInit, AfterContentInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() hint: string;
  @Input() name: string;
  @Input() description: string;
  @Input() isGridEditor = false;
  @Input() requiredError = 'Το πεδίο δεν έχει τιμή';
  @Input() required = false;
  @Input() inputAsLabel: boolean;
  @Input() placeholder: string = '';
  @Input() fieldName: string;
  @Input() filterOperator: LmFilterOperatorId;
  @Input() filterOperators: LmFilterOperatorId[];
  @Input() filterOperatorEditable = true;
  @Input() tabindex: number;
  @Input() updateOn: 'change' | 'blur' = 'blur';
  @Input() validationMessages: LMValidationMessage[];
  @Input() disabled = false;
  @Output() filterOperatorChange = new EventEmitter<LmFilterOperatorId>();

  @HostBinding('class.lm-input-inline') @Input() inlineLabel = false;
  @HostBinding('class.lm-input-clean-display') @Input() cleanDisplayLabel = false;

  abstract model: NgModel;

  id: string;
  searchType: string;
  operatorCtrl: Dropdown;

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach((f) => f(value));
    }
  }

  protected changed = new Array<(value: any) => void>();
  protected touched = new Array<() => void>();
  protected innerValue: any;
  protected cdr: ChangeDetectorRef;
  protected loggerSvc: LmLoggerService;
  protected modelProxySvc: LmModelProxyService<any>;
  protected modelValidationSvc: LmModelValidationService;
  protected ngValidators: (Function | Validator)[];
  protected notificationSvc: LmNotificationService;

  constructor(protected injector: Injector) {
    this.modelProxySvc = injector.get(LmModelProxyService, null);
    this.modelValidationSvc = injector.get(LmModelValidationService, null);
    this.loggerSvc = injector.get<LmLoggerService>(LmLoggerService);
    this.cdr = injector.get(ChangeDetectorRef);
    // this.ngValidators = injector.get(NG_VALIDATORS, null, InjectFlags.Self); //InjectFlags got deprecated 
    this.ngValidators = injector.get(NG_VALIDATORS, null);
    this.notificationSvc = injector.get(LmNotificationService);
  }

  ngOnInit(): void {
    this.setupValidation();
  }

  ngAfterContentInit(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: any): void {
    this.touched.push(fn);
  }

  markForCheck() {
    this.cdr.markForCheck();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.markForCheck();
  }

  blur() {
    this.touched.forEach((f) => f());
  }

  refreshFilterOperator(e: LmFilterOperatorId): void {
    this.filterOperator = e;
    this.filterOperatorChange.next(e);
  }

  resetValidation(): void {
    this.model?.control.markAsPristine();
    this.model?.control.markAsUntouched();
    this.validationMessages = null;
    this.markForCheck();
  }

  private setupValidation() {
    if (!this.name) {
      this.loggerSvc.logError('Invalid control name', this);
      return;
    }

    if (this.modelProxySvc) {
      this.modelProxySvc.isDirty$.pipe(untilDestroyed(this)).subscribe((isDirty) => {
        if (!isDirty) {
          this.resetValidation();
        }
      });
    }

    if (this.modelValidationSvc) {
      if (this.ngValidators) {
        const ngRequiredValidator = this.ngValidators.find((p) => 'required' in p);
        if (ngRequiredValidator && (ngRequiredValidator as any).required) {
          this.modelValidationSvc.addRequiredField(this.name);
        }
      }

      this.modelValidationSvc.validation$
        .pipe(
          untilDestroyed(this),
          filter((validator) => validator.propertyPath === this.name)
        )
        .subscribe((validator) => {
          this.validationMessages = validator.validationMessages;
          this.markForCheck();
        });

      merge(this.modelValidationSvc.resetValidations$, this.modelValidationSvc.resetValidation$.pipe(filter((validator) => validator.propertyPath === this.name)))
        .pipe(untilDestroyed(this))
        .subscribe(() => this.resetValidation());
    }
  }
}
