import { StopPointUtils } from './../utils/stop-point-utils';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { Globals } from '@app/services/globals';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FulfillmentUtils } from '@app/utils/fulfillment-event-utils';


@Component({
  selector: 'app-progress-timeline-food',
  templateUrl: './progress-timeline-food.component.html',
  styleUrls: ['./progress-timeline-food.component.scss']
})
export class ProgressTimelineFoodComponent implements OnInit, OnDestroy {

  listen = [];
  stopPointData;
  timelineStatusTimes = [];
  timelineCompletedParts = [false, false, false, false, false];
  routePercentage = 0;
  isTimelineCanceled;
  deliveryArrivalDelay = 0;
  deliveryCompletedDelay = 0;
  pickupArrivalDelay = 0;
  pickupCompletedDelay = 0;
  driverName;
  cancelledLabel;
  cancelledTime;
  cancelledEventReasonsLabels = {};

  startReloadLabel;
  arrivedLabel;
  acceptedLabel;
  pickedUpLabel;
  completedLabel;
  estimatedArrivalLabel;
  noDriverName;
  isNavigatorDisabled;

  // stages of the timeline
  fulfillmentEvents = [];

  constructor(
    public translate: TranslateService,
    public globals: Globals,
    private projectProblemDataService: ProjectProblemDataService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private stopPointUtils: StopPointUtils,
    private fulfillmentUtils: FulfillmentUtils,
  ) {
    // this.listen.push(this.viewProjectProblemService.setModalDataListen().subscribe((data) => {
    //   this.setModalData(data);
    // }
    // ));
  }

  resetTimeline() {
    this.stopPointData = null;
    this.timelineStatusTimes = [];
    this.timelineCompletedParts = [false, false, false, false, false];
    this.routePercentage = 0;
    this.isTimelineCanceled = false;
    this.deliveryArrivalDelay = 0;
    this.deliveryCompletedDelay = 0;
    this.pickupArrivalDelay = 0;
    this.pickupCompletedDelay = 0;
    this.driverName = this.noDriverName;
    this.cancelledLabel = null;
    this.cancelledTime = null;
    this.isNavigatorDisabled = false;

    this.setNavigator(2); // default navigator position
    this.resetLinePercentages(); // reset all lines
  }

  timelineCalculate(stopPointData) {
    this.resetTimeline();
    this.stopPointData = this.stopPointUtils.getDeliveryAndPickupData(stopPointData);
    let isCompleted = false;

    if (this.globals.foodModeEnabled) {
      // driver name
      if (stopPointData?.stopPoint?.solution) {
        this.driverName = stopPointData.stopPoint.solution.driver?.userProfile?.name;
      } else {
        this.driverName = this.noDriverName;
      }

      // stages of the timeline
      this.fulfillmentEvents = [this.acceptedLabel, this.arrivedLabel, this.pickedUpLabel, this.arrivedLabel, this.completedLabel];
      this.timelineStatusTimes = [];
      this.timelineCompletedParts = [false, false, false, false, false];
      let arrowOffsetPercentage = 6;
      let linePercentage = 0;

      // arrow position
      // COMPLETED (delivery)
      if (this.stopPointUtils.isCompleted(this.stopPointData.deliveryData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, true, true, true, true];
        arrowOffsetPercentage = 83;
        linePercentage = 100;
        isCompleted = true;
        this.setNavigator(arrowOffsetPercentage);

        this.fulfillmentEvents[1] = this.arrivedLabel;
        this.fulfillmentEvents[3] = this.arrivedLabel;
      }
      // ARRIVED (delivery)
      else if (this.stopPointUtils.isArrived(this.stopPointData.deliveryData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, true, true, true, false];
        arrowOffsetPercentage = 63;
        linePercentage = 100;
        this.setNavigator(arrowOffsetPercentage);

        this.fulfillmentEvents[1] = this.arrivedLabel;
        this.fulfillmentEvents[3] = this.arrivedLabel;
      }
      // IN-PROGRESS (delivery) [w/ pickup completed!]
      else if (
        this.stopPointUtils.isInProgress(this.stopPointData.deliveryData['fulfillment_events'][0].reason)
        && this.stopPointUtils.isCompleted(this.stopPointData.pickupData['fulfillment_events'][0].reason)
      ) {
        this.timelineCompletedParts = [true, true, true, false, false];

        // calculate percentage (get mins difference between in_progress datetime & estimated arrival time & convert to percentage in order to get a decimal -> e.g 0.2 = 20%)
        const start = this.stopPointData.deliveryData['fulfillment_events'][0]['fulfillment_datetime'];
        const end = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(this.stopPointData.deliveryData, this.stopPointData.deliveryData['id'], this.stopPointData.deliveryData['solution']['routeSettingId'], null).estimatedArrivalTimeTimestamp;
        // const end = this.stopPointData.deliveryData['solution']['latest_estimated_arrival_datetime'];
        console.warn('DELIVERY START: ' + String(moment(start).format('HH:mm (DD-MM-YYYY)')));
        console.warn('DELIVERY ARRIVAL ESTIMATION: ' + String(moment(end).format('HH:mm (DD-MM-YYYY)')));

        const maxDiff = Math.abs(moment(end).diff(start, 'minutes')); // 0%
        const currentDiff = Math.abs(moment(end).diff(moment(), 'minutes')); // compares end time w/ current time
        const isAfterEnd = moment().isAfter(moment(end));
        console.warn(isAfterEnd);

        arrowOffsetPercentage = 43;
        let routePercentageValue = 1 - (currentDiff / maxDiff);
        if (routePercentageValue > 1 || isAfterEnd) { routePercentageValue = 1; } // clamp max route percentage to 1
        this.routePercentage = routePercentageValue;
        console.warn('route percentage: ' + String(this.routePercentage));
        this.setLinePercentage('progressive-delivery-food');
        this.setNavigator(arrowOffsetPercentage);

        this.fulfillmentEvents[1] = this.arrivedLabel;
        this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
      }
      // CANCELED (delivery)
      else if (this.stopPointUtils.isCanceled(this.stopPointData.deliveryData['fulfillment_events'][0].reason)) {
        // canceled + arrived
        if (this.stopPointData.deliveryData['fulfillment_events'][1].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME) {
          this.timelineCompletedParts = [true, true, true, true, false];
          this.isTimelineCanceled = true;
          arrowOffsetPercentage = 72;
          this.setNavigator(arrowOffsetPercentage);
          this.setLinePercentageCanceled('progressive-delivery-arrived-food');

          this.fulfillmentEvents[1] = this.arrivedLabel;
          this.fulfillmentEvents[3] = this.arrivedLabel;
        }
        // canceled + anything else
        else {
          this.timelineCompletedParts = [true, true, true, false, false];
          this.isTimelineCanceled = true;
          arrowOffsetPercentage = 52;
          this.setNavigator(arrowOffsetPercentage);
          this.setLinePercentageCanceled('progressive-delivery-food');

          this.fulfillmentEvents[1] = this.arrivedLabel;
          this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
        }

        this.cancelledTime = moment(this.stopPointData.deliveryData['fulfillment_events'][0].fulfillment_datetime).format('HH:mm');
        this.cancelledLabel = this.cancelledEventReasonsLabels[this.stopPointData.deliveryData['fulfillment_events'][0].reason];

        if (this.stopPointData.deliveryData['fulfillment_events'][0].description) {
          this.cancelledLabel += '\n\n' + this.stopPointData.deliveryData['fulfillment_events'][0].description;
        }
      }
      // COMPLETED (pickup)
      else if (this.stopPointUtils.isCompleted(this.stopPointData.pickupData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, true, true, false, false];
        arrowOffsetPercentage = 43;
        linePercentage = 100;
        this.setNavigator(arrowOffsetPercentage);

        this.fulfillmentEvents[1] = this.arrivedLabel;
        this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
      }
      // ARRIVED (pickup)
      else if (this.stopPointUtils.isArrived(this.stopPointData.pickupData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, true, false, false, false];
        arrowOffsetPercentage = 23;
        linePercentage = 100;
        this.setNavigator(arrowOffsetPercentage);

        this.fulfillmentEvents[1] = this.arrivedLabel;
        this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
      }
      // IN-PROGRESS (pickup)
      else if (this.stopPointUtils.isInProgress(this.stopPointData.pickupData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, false, false, false, false];

        // calculate percentage (get mins difference between in_progress datetime & estimated arrival time & convert to percentage in order to get a decimal -> e.g 0.2 = 20%)
        const start = this.stopPointData.pickupData['fulfillment_events'][0]['fulfillment_datetime'];
        // const end = this.stopPointData.pickupData['solution']['latest_estimated_arrival_datetime'];
        const end = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(this.stopPointData.pickupData, this.stopPointData.pickupData['id'], this.stopPointData.pickupData['solution']['routeSettingId'], null).estimatedArrivalTimeTimestamp;
        console.warn('PICKUP START: ' + String(moment(start).format('HH:mm (DD-MM-YYYY)')));
        console.warn('PICKUP ARRIVAL ESTIMATION: ' + String(moment(end).format('HH:mm (DD-MM-YYYY)')));

        const maxDiff = Math.abs(moment(end).diff(start, 'minutes')); // 0%
        const currentDiff = Math.abs(moment(end).diff(moment(), 'minutes')); // compares end time w/ current time
        const isAfterEnd = moment().isAfter(moment(end));
        console.warn(isAfterEnd);

        arrowOffsetPercentage = 1;
        let routePercentageValue = 1 - (currentDiff / maxDiff);
        if (routePercentageValue > 1 || isAfterEnd) { routePercentageValue = 1; } // clamp max route percentage to 1
        this.routePercentage = routePercentageValue;
        console.warn('route percentage: ' + String(this.routePercentage));
        this.setLinePercentage('progressive-pickup-food');
        this.setNavigator(arrowOffsetPercentage);

        this.fulfillmentEvents[1] = this.estimatedArrivalLabel;
        this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
      }
      // CANCELED (pickup)
      else if (this.stopPointUtils.isCanceled(this.stopPointData.pickupData['fulfillment_events'][0].reason)) {
        // canceled + arrived
        if (this.stopPointData.pickupData['fulfillment_events'][1].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME) {
          this.timelineCompletedParts = [true, true, false, false, false];
          this.isTimelineCanceled = true;
          arrowOffsetPercentage = 32;
          this.setNavigator(arrowOffsetPercentage);
          this.setLinePercentageCanceled('progressive-pickup-arrived-food');

          this.fulfillmentEvents[1] = this.arrivedLabel;
          this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
        }
        // canceled + anything else
        else {
          this.timelineCompletedParts = [true, false, false, false, false];
          this.isTimelineCanceled = true;
          arrowOffsetPercentage = 12;
          this.setNavigator(arrowOffsetPercentage);
          this.setLinePercentageCanceled('progressive-pickup-food');

          this.fulfillmentEvents[1] = this.estimatedArrivalLabel;
          this.fulfillmentEvents[3] = this.estimatedArrivalLabel;
        }

        this.cancelledTime = moment(this.stopPointData.pickupData['fulfillment_events'][0].fulfillment_datetime).format('HH:mm');
        this.cancelledLabel = this.cancelledEventReasonsLabels[this.stopPointData.pickupData['fulfillment_events'][0].reason];

        if (this.stopPointData.deliveryData['fulfillment_events'][0].description) {
          this.cancelledLabel += '\n\n' + this.stopPointData.deliveryData['fulfillment_events'][0].description;
        }
      }
      // DEFAULT
      else {
        this.timelineCompletedParts = [false, false, false, false, false];
        arrowOffsetPercentage = 3;
        this.routePercentage = 0;
        this.setNavigator(arrowOffsetPercentage);
        this.setLinePercentage('progressive-pickup-food');

        this.fulfillmentEvents[1] = this.estimatedArrivalLabel;
        this.fulfillmentEvents[3] = this.estimatedArrivalLabel;

        if (this.stopPointData.pickupData) {
          if (this.stopPointUtils.isNotStarted(this.stopPointData.pickupData['fulfillment_events'][0].reason)) {
            this.isNavigatorDisabled = true;
          }
        }
      }

      // get times of events //
      // DELIVERY times
      this.stopPointData.deliveryData['fulfillment_events'].forEach(event => {
        // completed
        if (this.stopPointUtils.isCompleted(event.reason)) {
          this.timelineStatusTimes[4] = moment(event.fulfillment_datetime).format('HH:mm');

          // completed delay
          this.stopPointData.deliveryData['fulfillment_events'].forEach(event => {
            if (this.stopPointUtils.isCompleted(event.reason)) {
              const estimatedArrivalDatetime = moment(this.stopPointData.deliveryData.solution.latest_estimated_arrival_datetime);
              const durationMinutes = moment.duration(stopPointData.duration, 'minutes').asMinutes();
              const completedDatetime = moment(event.fulfillment_datetime);
              const estimatedCompleteTime = moment(estimatedArrivalDatetime).add(durationMinutes, 'minutes').format();
              this.deliveryCompletedDelay = this.dateTimeCalculatorService.calculateTimeDifferenceInMinutes(estimatedCompleteTime, completedDatetime);
            }
          });
        }
        if (this.stopPointUtils.isArrived(event.reason)) {
          this.timelineStatusTimes[3] = moment(event.fulfillment_datetime).format('HH:mm');

          // arrived delay
          this.stopPointData.deliveryData['fulfillment_events'].forEach(event => {
            if (this.stopPointUtils.isArrived(event.reason)) {
              const estimatedArrivalDatetime = moment(this.stopPointData.deliveryData.solution.latest_estimated_arrival_datetime);
              const arrivalDatetime = moment(event.fulfillment_datetime);
              this.deliveryArrivalDelay = this.dateTimeCalculatorService.calculateTimeDifferenceInMinutes(estimatedArrivalDatetime, arrivalDatetime);
            }
          });
        }
      });

      // PICKUP times
      this.stopPointData.pickupData['fulfillment_events'].forEach(event => {
        // completed
        if (this.stopPointUtils.isCompleted(event.reason)) {
          this.timelineStatusTimes[2] = moment(event.fulfillment_datetime).format('HH:mm');

          // completed delay
          this.stopPointData.pickupData['fulfillment_events'].forEach(event => {
            if (this.stopPointUtils.isCompleted(event.reason)) {
              const estimatedArrivalDatetime = moment(this.stopPointData.pickupData.solution.latest_estimated_arrival_datetime);
              const durationMinutes = moment.duration(stopPointData.duration, 'minutes').asMinutes();
              const completedDatetime = moment(event.fulfillment_datetime);
              const estimatedCompleteTime = moment(estimatedArrivalDatetime).add(durationMinutes, 'minutes').format();
              this.pickupCompletedDelay = this.dateTimeCalculatorService.calculateTimeDifferenceInMinutes(estimatedCompleteTime, completedDatetime);
            }
          });
        }

        // arrived
        if (this.stopPointUtils.isArrived(event.reason)) {
          this.timelineStatusTimes[1] = moment(event.fulfillment_datetime).format('HH:mm');

          // arrived delay
          this.stopPointData.pickupData['fulfillment_events'].forEach(event => {
            if (this.stopPointUtils.isArrived(event.reason)) {
              const estimatedArrivalDatetime = moment(this.stopPointData.pickupData.solution.latest_estimated_arrival_datetime);
              const arrivalDatetime = moment(event.fulfillment_datetime);
              this.pickupArrivalDelay = this.dateTimeCalculatorService.calculateTimeDifferenceInMinutes(estimatedArrivalDatetime, arrivalDatetime);
            }
          });
        }

        // scheduled
        if (this.stopPointUtils.isScheduledPickup(event.reason)) {
          this.timelineStatusTimes[0] = moment(event.fulfillment_datetime).format('HH:mm');
        }
      });

      // ESTIMATIONS
      // (pickup) if estimated arrival doesn't exist & the pickup is not completed, then get the estimation from the solution
      if (!this.timelineStatusTimes[1] && !this.stopPointUtils.isCompleted(this.stopPointData.pickupData['fulfillment_events'][0].reason)) {
        if (this.stopPointData.pickupData['solution']) {
          this.timelineStatusTimes[1] = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(this.stopPointData.pickupData, this.stopPointData.pickupData['id'], this.stopPointData.pickupData['solution']['routeSettingId'], this.stopPointData.pickupData.project_problem_id).estimatedArrivalTime;
        }
      }

      // (delivery) if estimated arrival doesn't exist & the delivery is not completed, then get the estimation from the solution
      if (!this.timelineStatusTimes[3] && !this.stopPointUtils.isCompleted(this.stopPointData.deliveryData['fulfillment_events'][0].reason)) {
        if (this.stopPointData.deliveryData['solution']) {
          this.timelineStatusTimes[3] = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(this.stopPointData.deliveryData, this.stopPointData.deliveryData['id'], this.stopPointData.deliveryData['solution']['routeSettingId'], this.stopPointData.deliveryData.project_problem_id).estimatedArrivalTime;
        }
      }
    }
  }

  setNavigator(offset) {
    const startOffset = 24;
    const extraOffset = 3.5;
    if (this.routePercentage > 0.1) {
      document.getElementById('timeline-navigator-food').style.left = `${offset + (startOffset * this.routePercentage) + extraOffset}%`;
    } else {
      document.getElementById('timeline-navigator-food').style.left = `${offset + extraOffset}%`;
    }
  }

  setLinePercentage(lineElementClassName) {
    setTimeout(() => {
      const line = document.getElementsByClassName(lineElementClassName) as HTMLCollectionOf<HTMLElement>;
      if (this.routePercentage > 0.1) {
        const linePercentage = this.routePercentage * 100;
        line[0].style.background = `linear-gradient(45deg, #00aeba ${linePercentage}%, #bbb 1%)`;
      } else {
        line[0].style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
      }
    }, 200);
  }

  setLinePercentageCanceled(lineElementClassName) {
    setTimeout(() => {
      const line = document.getElementsByClassName(lineElementClassName) as HTMLCollectionOf<HTMLElement>;
      const linePercentage = 0.35 * 100;
      line[0].style.background = `linear-gradient(45deg, #00aeba ${linePercentage}%, #bbb 1%)`;
    }, 200);
  }

  resetLinePercentages() {
    setTimeout(() => {
      const line1 = document.getElementsByClassName('progressive-pickup-food') as HTMLCollectionOf<HTMLElement>;
      const line2 = document.getElementsByClassName('progressive-pickup-arrived-food') as HTMLCollectionOf<HTMLElement>;
      const line3 = document.getElementsByClassName('progressive-delivery-food') as HTMLCollectionOf<HTMLElement>;
      const line4 = document.getElementsByClassName('progressive-delivery-arrived-food') as HTMLCollectionOf<HTMLElement>;
      if (line1[0] && line2[0] && line3[0] && line4[0]) {
        line1[0].style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
        line2[0].style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
        line3[0].style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
        line4[0].style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
      }
    }, 100);
  }

  getTranslations() {
    this.listen.push(this.translate.get('STATUS.ACCEPTED').subscribe((res: string) => {
      this.acceptedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.ARRIVED').subscribe((res: string) => {
      this.arrivedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.PICKED_UP').subscribe((res: string) => {
      this.pickedUpLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.completedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.ESTIMATED_ARRIVAL').subscribe((res: string) => {
      this.estimatedArrivalLabel = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.NO_DRIVER_NAME').subscribe((res: string) => {
      this.noDriverName = res;
    }));
    this.listen.push(this.translate.get('STATUS.CANCELLED').subscribe((res: string) => {
      this.cancelledLabel = res;
    }));

    // canceled event reason labels
    this.listen.push(this.translate.get('CANCEL').subscribe((res: string) => {
      this.fulfillmentUtils.setupReasonLabels(res);
      this.cancelledEventReasonsLabels = this.fulfillmentUtils.cancelledEventReasonsLabels;
    }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
