import { Component, OnInit, ViewChild, OnDestroy, HostBinding } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ColourService } from '@app/services/colour.service';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-move-stops-modal',
  templateUrl: './move-stops-modal.component.html',
  styleUrls: ['./move-stops-modal.component.scss']
})
export class MoveStopsModalComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  listen = [];
  isClickedOnce = false;

  manualModifiedRouteUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/manual-modified-route-items';

  projectProblemId;
  routeSettings = [];
  // selectedRouteSettingId;
  selectedStopPointIds = [];
  updateStopPointIds = [];
  // manualModifiedRouteData;
  selectedRouteSettings = [];
  sequenceAlert;

  constructor(
    public translate: TranslateService,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    private colourService: ColourService,
    public genericService: GenericService,
  ) {
    this.listen.push(this.viewProjectProblemService.moveStopPointsListen().subscribe((selectedStopPointIds) => {
      this.loadMoveStopsData(selectedStopPointIds);
      this.openMoveStopsModal();
    }));
  }

  sortBySolution(array) {
    function compare(a, b) {
      // true values first
      return (a.routeSetting.load === b.routeSetting.load) ? 0 : a.routeSetting.load ? -1 : 1;
    }
    array.sort(compare);
  }

  loadMoveStopsData(selectedStopPointIds) {
    const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const self = this;
    const dataRefreshIntervalId = setInterval(dataChecker, 200);
    function dataChecker() {
      if (self.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        self.routeSettings = self.projectProblemDataService.routeSettingsArray;
        self.calculateRoutesStats();
        self.selectedStopPointIds = selectedStopPointIds;
        self.sortBySolution(self.routeSettings);
      }
    }
  }

  calculateRoutesStats() {
    this.routeSettings.forEach(routeSetting => {
      const routeSettingId = routeSetting.routeSetting.id;
      const routeIndex = routeSetting.routeSetting.route_index;
      let colour = this.colourService.colourCalculator(routeIndex);
      if (this.projectProblemDataService.sequenceArrayPerRouteSettingId) {
        if (this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId]) {
          if (!this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId].length) {
            colour += '40';
          }
        }
      }
      routeSetting['colour'] = colour;
      routeSetting['class'] = 'route-index-' + (routeIndex + 1);
      routeSetting['stats'] = {
        load: routeSetting.routeSetting.load,
        capacity: routeSetting.vehicle.maximum_cargo_capacity,
        duration: '',
        horizon: routeSetting.driver.daily_working_hours
      };
    });
  }

  routeBoxClicked(routeSetting) {
    if (routeSetting.selected) {
      routeSetting.selected = false;
      this.selectedRouteSettings.splice(this.selectedRouteSettings.indexOf(routeSetting.routeSetting.id), 1);
    } else {
      routeSetting.selected = true;
      this.selectedRouteSettings.push(routeSetting.routeSetting.id);
    }
  }

  submitStops() {
    if (this.selectedRouteSettings.length) {
      const self = this;
      const data = {
        route: {},
        ignoreKeepRoutes: false
      };
      self.selectedStopPointIds.forEach(selectedStopPointId => {
        data.route[selectedStopPointId] = self.selectedRouteSettings;
      });
      this.viewProjectProblemService.moveStops(data);
    }
  }

  updateStopPoints() {
    this.viewProjectProblemService.updateProjectProblemStatus();
    this.projectProblemDataService.updateStopPointsModifications();
    this.closeMoveStopsModal();
    this.viewProjectProblemService.disableMapMultipleSelectAfterUpdate();
    this.updateStopPointIds = [];
  }

  emptyMoveStopsModal() {
    const selectedBox = document.querySelector('.modal-box.selected');
    if (selectedBox) {
      selectedBox.classList.remove('selected');
    }
    this.routeSettings.forEach(function (routeSetting, index) {
      routeSetting.selected = false;
    });
    this.routeSettings = [];
    this.selectedRouteSettings = [];
    this.selectedStopPointIds = [];
  }

  openMoveStopsModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.move-stops-modal');
    const modalBackground = document.querySelector('.move-stops-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
  }

  cancelAssignStopsToDriver() {
    this.closeMoveStopsModal();
    this.viewProjectProblemService.disableMapMultipleSelect();
  }

  closeMoveStopsModal() {
    const modal = document.querySelector('.move-stops-modal');
    const modalBackground = document.querySelector('.move-stops-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyMoveStopsModal();
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => { this.sequenceAlert = res; }));
    }));
    this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => { this.sequenceAlert = res; }));
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
