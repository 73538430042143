
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import {LmButtonConfig, LmButtonModule} from '../button/button.component'

@Component({
    selector: 'lm-header',
    template: `<div class="flex justify-content-between align-items-center flex-row">
        <h2 class="lm-mrg0 lm-mrgr10">
            <span *ngIf="iconClass">
                <i [attr.class]="iconClass"></i>
            </span>
            <span>{{title}}</span> 
            <span class="lm-header-count" *ngIf="showCount">{{count}}</span>
        </h2>
        <lm-button *ngIf="showBttn" [config]="bttn" (click)="bttn.command()"></lm-button>
    </div>`,
    styles: [
        `
            h2{font-size:1.7rem}
            h2 i{color: #ccc;}
            :host ::ng-deep button{background: #00aeba !important; width: 33px; overflow: hidden; padding-left: 10px !important;}
            :host ::ng-deep .p-button .p-button-icon-left{font-size: 1rem; margin-right: 20px;}
            :host ::ng-deep button:hover{width: 63px; opacity:1;}
            :host ::ng-deep button:hover .p-button-icon-left{margin-right: 5px;}
            :host ::ng-deep .lm-header-count{    
                border-radius: 10px;
                font-size: .9rem;
                background: #ededed;
                color: #8e8e8e;
                font-weight: normal;
                padding: 5px;
                margin-left: 10px;
                min-width: 20px;
                height: 20px;
                display: inline-block;
                line-height: 90%;
                text-align: center;
            }
        `
    ]
})

export class LMHeaderComponent implements OnInit, AfterViewInit, OnDestroy{
    @Input() title: string;
    @Input() bttn: LmButtonConfig;
    @Input() showBttn: boolean = true;
    @Input() count: number;
    @Input() showCount: boolean = false;
    @Input() iconClass: string;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {}

}

@NgModule({
  imports: [CommonModule, LmButtonModule],
  exports: [LMHeaderComponent],
  declarations: [LMHeaderComponent]
})
export class LmHeaderModule {}
