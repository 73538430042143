import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Globals } from '@app/services/globals';
import { CustomerVouchersPrintGridComponent } from '@app/grids/customer-vouchers-print-grid/customer-vouchers-print-grid.component';
import { Router } from '@angular/router';
import { CustomerPaymentPrintComponent } from '@app/prints/customer-payment-invoice-print/customer-payment-invoice-print.component';
import { CollaboratorService } from '@app/services/collaborator.service';
import { take } from 'rxjs';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-customer-payment-modal',
  templateUrl: './customer-payment-modal.component.html',
  styleUrls: ['./customer-payment-modal.component.scss']
})
export class CustomerPaymentModalComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerPaymentPrintComponent, { static: false }) customerPaymentPrintComponent: CustomerPaymentPrintComponent;
  @ViewChild(CustomerVouchersPrintGridComponent, { static: false }) customerVouchersPrintGridComponent: CustomerVouchersPrintGridComponent;

  listen = [];

  dataLoading = false;

  // values / labels
  paymentId;
  paymentHash;
  transactionNumber;
  paymentStatus;
  paymentStatusLabel;
  paymentAmountDue;
  paymentDoneDatetime;
  paymentDueDate;
  paymentCreatedDate;
  paymentSentDate
  paymentIssuedByDate;
  paymentSentByDate;
  collaboratorId;
  sentLabel;
  viewedLabel;
  paidLabel;
  overdueLabel;
  createdLabel;
  currency;
  issuedByMily;
  issuedByYou;
  createdBy;
  createdByOnDate;
  sentBy;
  sentDateBy;
  paidAmount;
  notSentYetLabel;
  leftAmount;
  payStatusLabel;
  payStatusNotPaid;
  payStatusPaid;
  payButtonLabel;
  payButtonDetails;
  payButtonPay;
  hasBeenSent;

  paymentData;

  paymentsReceived;
  paymentDates = [];

  setPaymentsData(data) {
    this.paymentsReceived = data.payments;
    this.paymentDates.push(moment(data.creation_datetime).format('DD/MM/YYYY'));
  }

  // titles
  amountDueTitle;
  dueDateTitle;
  scheduleRemindersTitle;
  sendInvoiceTitle;
  getPaidTitle;
  paymentsReceivedTitle;
  paidOnTitle;
  paidTitle;
  sendReceiptLinkTitle;
  editLinkTitle;
  cloneLinkTitle;
  removeLinkTitle;
  sendActionLabel;
  resendActionLabel;
  sendResendActionLabel;
  sendDateValue;
  linkCopiedLabel = "";

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    public translate: TranslateService,
    private modalGridService: ModalGridService,
    public globals: Globals,
    public router: Router,
    private collaboratorService: CollaboratorService,
    public genericService: GenericService,
  ) {
    this.listen.push(this.modalService.openPaymentModalListen().subscribe((data: number) => {
      this.loadPaymentModal(data);
      this.openModal();
    }));
    this.listen.push(this.modalService.updatePaymentModalListen().subscribe((data: number) => {
      this.updatePaymentModal();
    }));
    this.listen.push(this.modalGridService.listenCloseRightModal().subscribe((response: any) => {
      this.closeModal();
    }));
  }

  updatePaymentModal() {
    this.http.get('api/v1/pay-on-delivery/' + this.paymentId).pipe(take(1)).subscribe(response => {
      if (response['items']?.pay_on_delivery_payments?.length) {
        this.loadPaymentModal(response['items']['pay_on_delivery_payments'][0], false);
        this.modalGridService.updateCustomerGrid();
      }
    });
  }

  loadPaymentModal(data, isInitialization = true) {
    let hasBeenSent;
    let hasBeenPaid;
    this.paymentData = data;
    this.paymentId = data.id;
    this.paymentHash = data.hash;
    this.collaboratorId = data.collaborator_id;
    this.transactionNumber = this.collaboratorService.generateIdWithZeros(data.id);
    this.paymentAmountDue = data.left_payment_amount;
    this.paymentDoneDatetime = data.paid_datetime ? moment(data.paid_datetime).format('DD/MM/YYYY @ hh:mm') : '';
    this.paymentDueDate = moment(data.due_on_datetime).format('DD/MM/YYYY');
    this.paymentStatus = data.status;
    this.paymentCreatedDate = moment(data.creation_datetime).format('DD/MM/YYYY @ hh:mm');
    this.paidAmount = data.amount_paid;
    let paymentIssuedBy = data.issued_by;
    let paymentSentBy = data.sent_by;

    if (data.sent_by) { // data.sent_date must exist too here (maybe..?)
      this.paymentSentDate = moment(data.sent_datetime).format('DD/MM/YYYY @ hh:mm');
      hasBeenSent = true;
    }

    if (data.status == this.globals.invoiceStatusConstants['PAID']) {
      hasBeenPaid = true;
    }

    // Created Date + Issued by
    if (paymentIssuedBy == this.globals.invoiceStatusCollaboratorConstants['MILY']) {
      this.paymentIssuedByDate = this.createdBy + ' ' + this.issuedByMily + ' ' + this.createdByOnDate + ' ' + this.paymentCreatedDate;
    } else {
      this.paymentIssuedByDate = this.createdBy + ' ' + this.issuedByYou + ' ' + this.createdByOnDate + ' ' + this.paymentCreatedDate;
    }

    // Sent Date + Sent by
    if (paymentSentBy == this.globals.invoiceStatusCollaboratorConstants['MILY']) {
      this.paymentSentByDate = this.sentBy + ' ' + this.issuedByMily + ' ' + this.createdByOnDate + ' ' + this.paymentSentDate;
    } else if (paymentSentBy == this.globals.invoiceStatusCollaboratorConstants['COLLABORATOR']) {
      this.paymentSentByDate = this.sentBy + ' ' + this.issuedByYou + ' ' + this.createdByOnDate + ' ' + this.paymentSentDate;
    } else {
      this.paymentSentByDate = this.notSentYetLabel;
    }

    // Pay Status
    if (this.paymentAmountDue <= 0) {
      this.payStatusLabel = this.payStatusPaid;
      this.payButtonLabel = this.payButtonDetails;
    } else {
      this.payStatusLabel = this.payStatusNotPaid;
      this.payButtonLabel = this.payButtonPay;
    }

    // set data on print invoice component
    this.customerPaymentPrintComponent.setPrintData(data);
    this.customerPaymentPrintComponent.setPaymentsGridData(data);
    const companyData = this.globals.companyData;
    companyData['companyLogo'] = this.globals.companyLogo;
    this.customerPaymentPrintComponent.setCompanyData(companyData);
    // this.customerPaymentPrintComponent.setCollaboratorData(this.collaboratorService.collaboratorData);


    // get partner data only when logged in as a collaborator (else get collaborator data for 'bill to' information)
    if (this.globals.collaboratorModeEnabled) {
      const partnerCompanyId = data.company_partner_id;
      this.customerPaymentPrintComponent.setCompanyData(this.globals.partners[partnerCompanyId]);
      this.customerPaymentPrintComponent.setCollaboratorData(companyData);
    } else {
      // 'company' refers to the logged in user in this case
      this.customerPaymentPrintComponent.setCompanyData(companyData);
      this.customerPaymentPrintComponent.setCollaboratorData(this.collaboratorService.collaboratorData);
    }

    // send data to vouchers grid (inside modal)
    this.customerVouchersPrintGridComponent.collaboratorId = this.collaboratorId;
    this.customerVouchersPrintGridComponent.invoiceOrPaymentQuery = 'pay_on_delivery_id=' + this.paymentId;
    if (isInitialization) { this.customerVouchersPrintGridComponent.initGrid(); }

    // set modal payments
    this.setPaymentsData(data);

    // Status Label
    switch (this.paymentStatus) {
      case this.globals.invoiceStatusConstants['CREATED']:
        this.paymentStatusLabel = this.createdLabel;
        document.querySelector('.value-status-payment').classList.add('status-created');
        document.querySelector('.value-status-payment').classList.remove('status-sent');
        document.querySelector('.value-status-payment').classList.remove('status-viewed');
        document.querySelector('.value-status-payment').classList.remove('status-paid');
        document.querySelector('.value-status-payment').classList.remove('status-overdue');
        break;
      case this.globals.invoiceStatusConstants['VIEWED']:
        this.paymentStatusLabel = this.viewedLabel;
        document.querySelector('.value-status-payment').classList.add('status-viewed');
        break;
      case this.globals.invoiceStatusConstants['PAID']:
        this.paymentStatusLabel = this.paidLabel;
        document.querySelector('.value-status-payment').classList.add('status-paid');
        document.querySelector('.value-status-payment').classList.remove('status-sent');
        document.querySelector('.value-status-payment').classList.remove('status-viewed');
        document.querySelector('.value-status-payment').classList.remove('status-created');
        document.querySelector('.value-status-payment').classList.remove('status-overdue');
        break;
      case this.globals.invoiceStatusConstants['OVERDUE']:
        this.paymentStatusLabel = this.overdueLabel;
        document.querySelector('.value-status-payment').classList.add('status-overdue');
        document.querySelector('.value-status-payment').classList.remove('status-paid');
        document.querySelector('.value-status-payment').classList.remove('status-viewed');
        document.querySelector('.value-status-payment').classList.remove('status-created');
        document.querySelector('.value-status-payment').classList.remove('status-sent');
        break;
      case this.globals.invoiceStatusConstants['SENT']:
        this.paymentStatusLabel = this.sentLabel;
        document.querySelector('.value-status-payment').classList.add('status-sent');
        document.querySelector('.value-status-payment').classList.remove('status-paid');
        document.querySelector('.value-status-payment').classList.remove('status-viewed');
        document.querySelector('.value-status-payment').classList.remove('status-created');
        document.querySelector('.value-status-payment').classList.remove('status-overdue');
        break;
      default:
        this.paymentStatusLabel = 'CASE ERROR';
    }

    // don't allow 'Pay' on collaborator login
    if (hasBeenSent) {
      this.sendResendActionLabel = this.resendActionLabel;

      if (!this.globals.collaboratorModeEnabled) {
        document.querySelector('.resend-button-payment .normal-button').classList.remove('primary-button');
      }
      document.querySelector('.send-invoice-payment path').classList.remove('grey-icon');
      document.querySelector('.send-invoice-payment path').classList.add('blue-icon');
    } else {
      this.sendResendActionLabel = this.sendActionLabel;
      if (!this.globals.collaboratorModeEnabled) {
        document.querySelector('.resend-button-payment .normal-button').classList.add('primary-button');
      }
      document.querySelector('.send-invoice-payment path').classList.add('grey-icon');
      document.querySelector('.send-invoice-payment path').classList.remove('blue-icon');
    }

    if (hasBeenPaid) {
      document.querySelector('.get-paid-payment path').classList.remove('grey-icon');
      document.querySelector('.get-paid-payment path').classList.add('blue-icon');
      document.querySelector('.record-payment-button').classList.add('hidden');
    } else if (!hasBeenPaid && hasBeenSent) {
      document.querySelector('.get-paid-payment path').classList.add('grey-icon');
      document.querySelector('.get-paid-payment path').classList.remove('blue-icon');
      if (!this.globals.collaboratorModeEnabled) {
        document.querySelector('.record-payment-button .normal-button').classList.add('primary-button');
      }

      document.querySelector('.record-payment-button').classList.remove('hidden');
    } else if (!hasBeenPaid && !hasBeenSent) {
      document.querySelector('.get-paid-payment path').classList.add('grey-icon');
      document.querySelector('.get-paid-payment path').classList.remove('blue-icon');
      if (!this.globals.collaboratorModeEnabled) {
        document.querySelector('.record-payment-button .normal-button').classList.remove('primary-button');
      }
      document.querySelector('.record-payment-button').classList.remove('hidden');
    }
  }

  dataLoadingListen(event) {
    if (event === 'true') {
      this.dataLoading = true;
    } else if (event === 'false') {
      this.dataLoading = false;
    }
  }

  openModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.customer-payment-modal');
    const modalBackground = document.querySelector('.customer-payment-modal-background');
    modal.classList.add('open');
    modal.classList.remove('closed');
    modalBackground.classList.remove('hidden');
  }

  closeModal() {
    const modal = document.querySelector('.customer-payment-modal');
    const modalBackground = document.querySelector('.customer-payment-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
  }

  // resend
  edit() {
    this.globals.comingSoonAlert();
  }

  // resend
  resend() {
    this.globals.comingSoonAlert();
  }

  // mark as read
  markAsSent() {
    this.globals.comingSoonAlert();
  }

  // set payment as paid
  setPaid() {
    let paidPayment = {
      "status": this.globals.invoiceStatusConstants['PAID']
    };

    const myObserver = {
      next: (response) => {
        this.loadPaymentModal(response['items']['0']);
        // this.paymentStatus = 3;
        // this.paymentStatusLabel = this.paidLabel;
        // this.sendResendActionLabel = this.resendActionLabel;
        // document.querySelector('.value-status-payment').classList.remove('status-created');
        // document.querySelector('.value-status-payment').classList.add('status-paid');
        this.modalGridService.updateCustomerGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {

      },
    };

    this.http.put('api/v1/pay-on-delivery/' + this.paymentId, JSON.stringify(paidPayment)).subscribe(myObserver);
  }

  getLink() {
    navigator.clipboard.writeText(window.location.hostname + '/paymentPrint/' + this.paymentData.hash);
    alert(this.linkCopiedLabel);
  }

  exportAsPdf() {
    this.globals.comingSoonAlert();
  }

  routerPrint() {
    window.open('/paymentPrint/' + this.paymentHash, '_blank');
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT').subscribe((res: string) => { this.sentLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.VIEWED').subscribe((res: string) => { this.viewedLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID').subscribe((res: string) => { this.paidLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED').subscribe((res: string) => { this.createdLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SCHEDULE_REMINDERS').subscribe((res: string) => { this.scheduleRemindersTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_MILY').subscribe((res: string) => { this.issuedByMily = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_YOU').subscribe((res: string) => { this.issuedByYou = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED_BY').subscribe((res: string) => { this.createdBy = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT_BY').subscribe((res: string) => { this.sentBy = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.NOT_SENT_YET').subscribe((res: string) => { this.notSentYetLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.ON_DATE').subscribe((res: string) => { this.createdByOnDate = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAYMENTS_RECEIVED').subscribe((res: string) => { this.paymentsReceivedTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTION_SEND').subscribe((res: string) => { this.sendActionLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTION_SEND_AGAIN').subscribe((res: string) => { this.resendActionLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID_ON').subscribe((res: string) => { this.paidOnTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID_AMOUNT').subscribe((res: string) => { this.paidTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SEND_RECEIPT_RESEND').subscribe((res: string) => { this.sendReceiptLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.EDIT').subscribe((res: string) => { this.editLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.CLONE').subscribe((res: string) => { this.cloneLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.REMOVE').subscribe((res: string) => { this.removeLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.STATUS_PAID').subscribe((res: string) => { this.payStatusPaid = res; }));
    this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.STATUS_NOT_PAID').subscribe((res: string) => { this.payStatusNotPaid = res; }));
    this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.DETAILS').subscribe((res: string) => { this.payButtonDetails = res; }));
    this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.PAY').subscribe((res: string) => { this.payButtonPay = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('GENERIC.LINK_COPIED').subscribe((res: string) => { this.linkCopiedLabel = res; }));
  }
}
