import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { ImporterService } from '@app/services/importer.service';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { AddressService } from '@app/services/address.service';
import { Router } from '@angular/router';
import { StopPointService } from '@app/services/stop-point.service';
import { GenericService } from '@app/services/generic.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';

@Component({
  selector: 'app-helper-modal',
  templateUrl: './helper-modal.component.html',
  styleUrls: ['./helper-modal.component.scss']
})
export class HelperModalComponent implements OnInit, OnDestroy {

  updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
  getHelperDataUrl = 'api/v1/helper-stop-points';
  getPartnerHelperDataUrl = 'api/v1/partner-helper-stop-points';
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  listen = [];
  dataLoading = false;
  nextButtonDisabled = true;
  errors = [];

  projectProblemId;
  stopPointsDataObject = {};
  stopPointsDataArray = [];
  helperType = 'importer';
  importerStopPointsDataArray = [];
  canceledStopPointsDataArray = [];
  currentStopPointIndexInArray = 0;
  stopsCount = 0;
  possibleAddresses = [];

  stopPointName = '';
  importedAddress = '';
  timeWindowString = '';
  durationString = '';
  phone = '';
  load = 0;
  collaborator = '';
  serialNo = '';
  notes = '';
  toggleMoreDetailsTexts = {
    more: '',
    less: ''
  };
  toggleMoreDetailsText;
  detailsOpen = false;
  priorityOn = false;
  notAccurate = false;
  priority = this.globals.stopPointPriorityConstants['NORMAL'];
  accuracy = this.globals.stopPointPriorityConstants['HIGH'];
  importFieldsHash = '';
  latestStopPointId = null;
  latestImportFieldsHash = null;

  addressType: String = 'houseNumber';
  freeformAddress = '';
  country = 'GR';
  lat = '';
  lon = '';

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';

  rightClickMsg = '';

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();
  selectedAddress: any = <any>[];
  positionSet = false;

  customerEntityType = null;
  noNameLabel = '';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private viewProjectProblemService: ViewProjectProblemService,
    private draggableMarkerService: DraggableMarkerService,
    private importerService: ImporterService,
    private dataService: DataService,
    private projectProblemDataService: ProjectProblemDataService,
    public globals: Globals,
    private addressService: AddressService,
    private stopPointService: StopPointService,
    public router: Router,
    private genericService: GenericService,
    private stopPointUtils: StopPointUtils,
  ) {
    this.listen.push(this.draggableMarkerService.markerDragListen().subscribe((response) => {
      this.positionSet = true;
      this.lat = response.lat;
      this.lon = response.lng;
      this.freeformAddress = this.selectedAddress.label;
    }));
    this.listen.push(this.importerService.openHelperListen().subscribe((stopPointsData) => {
      this.setHelperDataFromRequest(stopPointsData);
      this.loadAndOpenHelper();
    }));
    this.listen.push(this.importerService.markerClickedListen().subscribe((index) => {
      this.addressBoxClicked(this.possibleAddresses[index]);
      this.focusOnHelperBox(index);
    }));
    this.listen.push(this.importerService.loadAndOpenHelperListen().subscribe(type => {
      this.loadHelperDataAndOpen(type);
    }));
    this.listen.push(this.viewProjectProblemService.createStopFromMapClickListen().subscribe((response) => {
      if (response.helperOpen) {
        this.positionSet = true;
        this.lat = response.lat;
        this.lon = response.lng;
        this.dataLoading = true;
        this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).subscribe(location => {
          this.dataLoading = false;
          this.nextButtonDisabled = false;
          if (location['data']['addresses']['items'].length) {
            this.setAddress(location['data']['addresses']['items'][0]['address']);
          } else {
            this.freeformAddress = 'Address';
          }
          (<HTMLInputElement>document.getElementById('helper-custom-input')).value = this.freeformAddress;
        });
        this.draggableMarkerService.addDraggableMarker(Number(response.lat), Number(response.lng));
      }
    }));
  }

  focusOnHelperBox(index) {
    document.querySelectorAll('.helper-box')[index].scrollIntoView();
  }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);

    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    if (address.accuracy === this.globals.stopPointAccuracyConstants['LOW']) {
      this.notAccurate = true;
    } else {
      this.notAccurate = false;
    }
  }

  getHelperItems() {
    let getHelperDataUrl = this.getHelperDataUrl;
    if (this.globals.collaboratorModeEnabled) {
      getHelperDataUrl = this.getPartnerHelperDataUrl;
    }
    if (this.projectProblemId) {
      getHelperDataUrl += '?projectProblemId=' + this.projectProblemId + '&pageSize=200';
    }
    return this.http.get(getHelperDataUrl)
  }

  // get the helper items and set modal data and open the helper
  loadHelperDataAndOpen(type) {
    this.helperType = type;
    this.stopPointsDataArray = [];
    this.importerStopPointsDataArray = [];
    this.canceledStopPointsDataArray = [];
    this.dataLoading = true;

    let stopPointsForHelper = [];

    let getHelperDataUrl = this.getHelperDataUrl + '?page=PAGE_NUMBER';
    if (this.globals.collaboratorModeEnabled || this.globals.isInRoute('partnerView')) {
      getHelperDataUrl = this.getPartnerHelperDataUrl + '?page=PAGE_NUMBER';
    }
    if (this.projectProblemId) {
      getHelperDataUrl += '&projectProblemId=' + this.projectProblemId + '&pageSize=200';
    }

    let totalPages = 1;
    const fetchData = async (currentPage) => {
      this.getHelperItemsRequest(currentPage.toString()).subscribe(response => {
        if (response['items'].length) {
          const pageItems = response['items'];
          const pagesCount = response['itemsMeta']['pagesCount'];
  
          stopPointsForHelper = stopPointsForHelper.concat(pageItems);
          totalPages = pagesCount;
  
          if (response['importConflictData']) {
            this.latestStopPointId = response['importConflictData']['_latestStopPointId'];
            this.latestImportFieldsHash = response['importConflictData']['_latestImportFieldsHash'];
          }
  
          if ((currentPage + 1) < totalPages) {
            fetchData(currentPage + 1);
          } else {
            this.setHelperDataFromRequest(stopPointsForHelper);
            this.loadAndOpenHelper();
          }
        }        
      });
    };

    fetchData(0);
  }

  getHelperItemsRequest(pageNumber){
    let getHelperDataUrl = this.getHelperDataUrl + '?page=PAGE_NUMBER';
    if (this.globals.collaboratorModeEnabled || this.globals.isInRoute('partnerView')) {
      getHelperDataUrl = this.getPartnerHelperDataUrl + '?page=PAGE_NUMBER';
    }
    if (this.projectProblemId) {
      getHelperDataUrl += '&projectProblemId=' + this.projectProblemId + '&pageSize=20';
      // getHelperDataUrl += '&projectProblemId=' + this.projectProblemId + '&pageSize=200';
    }
    if (this.latestImportFieldsHash && this.latestStopPointId) {
      getHelperDataUrl += '&_latestImportFieldsHash=' + this.latestImportFieldsHash + '&_latestStopPointId=' + this.latestStopPointId;
    }
    return this.http.get(getHelperDataUrl.replace('PAGE_NUMBER', pageNumber)).pipe(take(1));
  }

  // set the helper data for the helper to show
  setHelperDataFromRequest(helperResponseData) {
    const stopPointsDataObject = {};
    this.dataLoading = false;
    if (helperResponseData) {
      helperResponseData.forEach(helperItem => {
        if (helperItem.importConflictItem.conflict_type === 'address_conflict' || helperItem.importConflictItem.conflict_type === 'cancel_wrong_address') {
          const stopPointId = helperItem.importConflictItem.stopPointId;
          let stopPointData = this.projectProblemDataService.stopPoints[stopPointId];
          if (!stopPointData) {
            const info = helperItem.importConflictItem.info;
            stopPointData = {
              id: stopPointId,
              contact_name: info.recipient,
              priority: '',
              duration: '',
              time_window: '',
              pickup_load: '',
              delivery_load: '',
              note: ''
            };
          }
          stopPointsDataObject[stopPointId] = {
            stopPointData: stopPointData,
            projectProblemData: this.projectProblemDataService.projectProblemData,
            importConflictItem: {
              conflict_type: helperItem.importConflictItem.conflict_type,
              alternativeItems: helperItem.importConflictItem.alternativeItems,
              info: helperItem.importConflictItem.info,
              importFieldsHash: helperItem.importConflictItem.importFieldsHash,
              stopPointId: stopPointId,
              _id: helperItem.importConflictItem._id
            },
          };
        }
      });
    }
    this.currentStopPointIndexInArray = 0;
    this.stopPointsDataObject = stopPointsDataObject;
    this.populateStopPointsDataArrays();
  }

  loadAndOpenHelper() {
    if (this.helperType === 'importer') {
      this.stopPointsDataArray = this.importerStopPointsDataArray;
    } else {
      this.stopPointsDataArray = this.canceledStopPointsDataArray;
    }

    if (this.stopPointsDataArray.length) {
      this.stopsCount = this.stopPointsDataArray.length;
      this.importerService.mapEmpty();
      this.loadStopPointData();
      this.openHelperModal();
    }

    if (!this.projectProblemId) {
      document.getElementById('helper-modal').classList.add('one-bar-height');
    }
  }

  populateStopPointsDataArrays() {
    this.importerStopPointsDataArray = [];
    this.canceledStopPointsDataArray = [];

    Object.keys(this.stopPointsDataObject).forEach(stopPointId => {
      if (this.stopPointsDataObject[stopPointId].importConflictItem.conflict_type === 'address_conflict') {
        if (this.stopPointsDataObject[stopPointId].stopPointData) {
          this.importerStopPointsDataArray.push(this.stopPointsDataObject[stopPointId]);
        }
      } else if (this.stopPointsDataObject[stopPointId].importConflictItem.conflict_type === 'cancel_wrong_address') {
        if (this.stopPointsDataObject[stopPointId].stopPointData) {
          this.canceledStopPointsDataArray.push(this.stopPointsDataObject[stopPointId]);
        }
      }
    });
  }

  fixCustomerEntityTypeChanged(event) {
    if (event.target.defaultValue == 'null') {
      this.customerEntityType = null;
    }
  }

  loadNextStopPoint(save) {
    let nextStopPointIndexInArray = this.currentStopPointIndexInArray;
    nextStopPointIndexInArray++;
    if (save) {
      if (this.stopPointsDataArray[nextStopPointIndexInArray]) {
        this.saveStopPoint().pipe(take(1)).subscribe(response => {
          this.emptyHelperModal();
          this.currentStopPointIndexInArray++;
          this.loadStopPointData();
          this.dataLoading = false;
        },
          error => {
            console.error(error);
            this.showErrors(error);
            this.dataLoading = false;
          });
      } else {
        this.saveStopPoint().pipe(take(1)).subscribe(response => {
          this.closeHelperModal();
        },
          error => {
            console.error(error);
            this.showErrors(error);
            this.dataLoading = false;
          });
      }
    } else {
      if (this.stopPointsDataArray[nextStopPointIndexInArray]) {
        this.emptyHelperModal();
        this.currentStopPointIndexInArray++;
        this.loadStopPointData();
      } else {
        this.closeHelperModal();
      }
    }
  }

  loadPreviousStopPoint() {
    if (this.lat && this.lon && this.freeformAddress) {
      this.saveStopPoint().pipe(take(1)).subscribe(response => {
        this.emptyHelperModal();
        this.currentStopPointIndexInArray++;
        this.loadStopPointData();
        this.dataLoading = false;
      },
        error => {
          console.error(error);
          this.showErrors(error);
          this.dataLoading = false;
        });
    }
    this.currentStopPointIndexInArray--;
    if (this.stopPointsDataArray[this.currentStopPointIndexInArray]) {
      this.importerService.mapEmpty();
      this.emptyHelperModal();
      this.loadStopPointData();
    } else {
      this.currentStopPointIndexInArray++;
    }
  }

  showErrors(error) {
    const errorsObject = error.error.errors;
    const errors = [];
    const keyify = (obj, prefix = '') =>
      Object.keys(obj).reduce((res, el) => {
        if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
          errors.push(obj[el]['0']);
        }
        if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
          if (obj[el]['0']['load']) {
            errors.push(obj[el]['0']['load']['0']);
          }
        }
        if (Array.isArray(obj[el])) {
          return res;
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
          return [...res, ...keyify(obj[el], prefix + el + '.')];
        } else {
          return [...res, prefix + el];
        }
      }, []);
    const output = keyify(errorsObject);
    this.errors = errors;
  }

  saveStopPoint() {
    if (this.customerEntityType == 'null') {
      this.customerEntityType = null;
    }

    this.errors = [];
    const currentStopPointId = this.stopPointsDataArray[this.currentStopPointIndexInArray]['stopPointData'].id;
    const currentStopPointsProjectProblemId = this.projectProblemId;
    const helperId = this.stopPointsDataArray[this.currentStopPointIndexInArray]['importConflictItem']._id;
    const stopPointData = {
      importFieldsHash: this.importFieldsHash,
      stopPoint: {
        stopPointId: currentStopPointId,
        priority: this.priorityOn ? this.globals.stopPointPriorityConstants['HIGH'] : this.globals.stopPointPriorityConstants['NORMAL'],
        accuracy: this.notAccurate ? 'LO' : 'HI',
        customerEntityType: this.customerEntityType,
        error_status: this.globals.stopPointErrorStatusConstants['NO_ERROR'],
        address: {
          countryCode: this.countryCode,
          state: this.state,
          county: this.county,
          city: this.city,
          district: this.district,
          street: this.street,
          street2: this.street2,
          houseNumber: this.houseNumber,
          postalCode: this.postalCode,
          isPlace: this.isPlace,
          placeName: this.placeName,
          value: this.freeformAddress,
          lat: this.lat,
          lon: this.lon,
        }
      },
      customer: {
        timeZone: moment.tz.guess(),
        portal_access: 0,
        hasDefaultTimeWindows: true,
      },
      helper: {
        _id: helperId
      }
    };
    this.dataLoading = true;
    let getHelperDataUrl = this.getHelperDataUrl;
    if (this.globals.collaboratorModeEnabled || this.globals.isInRoute('partnerView')) {
      getHelperDataUrl = this.getPartnerHelperDataUrl;
    }
    if (this.projectProblemId) {
      getHelperDataUrl += '?projectProblemId=' + this.projectProblemId;
    }
    return this.http.post(getHelperDataUrl, [stopPointData]).pipe(take(1));
  }

  loadStopPointData() {
    const currentStopPointData = this.stopPointsDataArray[this.currentStopPointIndexInArray];
    console.log(currentStopPointData);
    this.nextButtonDisabled = true;
    if (
      currentStopPointData.importConflictItem.conflict_type === 'address_conflict' ||
      currentStopPointData.importConflictItem.conflict_type === 'cancel_wrong_address'
    ) {
      if (currentStopPointData.stopPointData) {
        let lat, lon, title, vicinity, tempAddressObject = {}, place;
        if (currentStopPointData.importConflictItem.alternativeItems) {
          document.getElementById('helper-scroll-area').scrollTop = 0;
          document.documentElement.scrollTop = 0;
          currentStopPointData.importConflictItem.alternativeItems.forEach((alternativeAddress, index) => {
            lat = alternativeAddress.position[0];
            lon = alternativeAddress.position[1];
            title = this.addressService.getAddressTitle(alternativeAddress.address);
            vicinity = this.addressService.getAddressVicinity(alternativeAddress.address);
            place = alternativeAddress.address.placeName ? alternativeAddress.address.placeName : '';
            tempAddressObject = {
              title: title,
              vicinity: vicinity,
              lat: lat,
              lon: lon,
              selected: false,
              index: index,
              place: place,
              addressObject: JSON.stringify(alternativeAddress.address),
              address: alternativeAddress.address
            };
            this.possibleAddresses.push(tempAddressObject);
            this.importerService.addMarker(index, lat, lon);
          });
        }

        const importedAddressData = currentStopPointData.importConflictItem.info;
        this.stopPointName = importedAddressData.recipient;
        this.importFieldsHash = currentStopPointData.importConflictItem.importFieldsHash;
        if (this.stopPointName === '_NO_NAME') {
          this.stopPointName = this.noNameLabel;
        }
        if (currentStopPointData.stopPointData.priority) {
          this.priority = currentStopPointData.stopPointData.priority;
          if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
            this.priorityOn = true;
          } else {
            this.priorityOn = false;
          }
        }
        let state = '';
        if (importedAddressData.state) {
          state = ', ' + importedAddressData.state;
        }
        this.importedAddress = importedAddressData.recipient + ', ' + importedAddressData.address + state;
        this.importedAddress += ', ' + importedAddressData.postalCode + ', ' + importedAddressData.city + ', ' + importedAddressData.region;
        this.phone = importedAddressData.telephone;

        if (currentStopPointData.stopPointData.time_window) {
          const timeWindowTimeStartMoment = moment(currentStopPointData.stopPointData.time_window.start, 'HH:mm:SS');
          const timeWindowTimeStart = timeWindowTimeStartMoment.format('HH:mm');
          const timeWindowRangeDuration = moment.duration(currentStopPointData.stopPointData.time_window.time_window_range).asMinutes();
          const timeWindowTimeEnd = moment(timeWindowTimeStartMoment).add(timeWindowRangeDuration, 'minutes').format('HH:mm');
          this.timeWindowString = timeWindowTimeStart + '-' + timeWindowTimeEnd;
        }

        if (currentStopPointData.stopPointData.duration) {
          const durationMinutes = moment.duration(currentStopPointData.stopPointData.duration, 'minutes').asMinutes();
          const durationMinutesFloor = Math.floor(durationMinutes);
          const durationSeconds = Math.round(moment.duration((durationMinutes - durationMinutesFloor), 'minutes').asSeconds());
          this.durationString = durationMinutesFloor + 'm ' + durationSeconds + 's';
        }

        if (this.stopPointUtils.isDelivery(currentStopPointData.stopPointData.service_type)) {
          this.load += currentStopPointData.stopPointData.load;
        }
        if (this.stopPointUtils.isPickup(currentStopPointData.stopPointData.service_type)) {
          this.load += currentStopPointData.stopPointData.load;
        }

        this.notes = currentStopPointData.stopPointData.note;
      } else {
        this.loadNextStopPoint(false);
      }
    } else {
      this.loadNextStopPoint(false);
    }
  }

  addressBoxClicked(address) {
    let clickedAddressIndex;
    this.possibleAddresses.forEach(function (possibleAddress, index) {
      if (index === address.index) {
        clickedAddressIndex = index;
        address.selected = true;
      } else {
        possibleAddress.selected = false;
      }
    });
    this.freeformAddress = address.title + ', ' + address.vicinity;
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [address.lat, address.lon],
    };
    this.lat = address.lat;
    this.lon = address.lon;
    this.setAddress(address.address);
    (<HTMLInputElement>document.getElementById('helper-custom-input')).value = this.freeformAddress;
    this.importerService.focusOnMarker(clickedAddressIndex);
    this.nextButtonDisabled = false;
  }

  toggleMoreDetails() {
    const detailsDiv = document.getElementById('stop-point-more-details');
    if (detailsDiv.classList.contains('open')) {
      detailsDiv.classList.remove('open');
      this.toggleMoreDetailsText = this.toggleMoreDetailsTexts.more;
      this.detailsOpen = false;
    } else {
      detailsDiv.classList.add('open');
      this.toggleMoreDetailsText = this.toggleMoreDetailsTexts.less;
      this.detailsOpen = true;
    }
  }

  emptyHelperModal() {
    this.errors = [];
    this.helperType = 'importer';
    this.dataLoading = false;
    this.importerService.mapEmpty();
    this.possibleAddresses = [];
    this.lat = '';
    this.lon = '';
    this.freeformAddress = '';
    this.stopPointName = '';
    this.timeWindowString = '';
    this.durationString = '';
    this.load = 0;
    this.collaborator = '';
    this.phone = '';
    this.serialNo = '';
    this.notes = '';
    this.priorityOn = false;
    this.notAccurate = false;
    this.customerEntityType = null;
    this.nextButtonDisabled = true;
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    this.latestStopPointId = null;
    this.importFieldsHash = null;
    (<HTMLInputElement>document.getElementById('helper-custom-input')).value = '';
    this.draggableMarkerService.removeDraggableMarker();
  }

  inputFocusOut() {
    if (!this.selectedAddress) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  inputAddress() {
    this.selectedAddress = '';
    this.ngSelect.filter((<HTMLInputElement>document.getElementById('helper-custom-input')).value);
  }

  onAddressChange() {
    (<HTMLInputElement>document.getElementById('helper-custom-input')).value = this.selectedAddress.label;
    if (this.selectedAddress) {
      this.notAccurate = false;
      this.nextButtonDisabled = false;
      this.lat = this.selectedAddress.position[0];
      this.lon = this.selectedAddress.position[1];
      this.freeformAddress = this.selectedAddress.label;
      this.setAddress(this.selectedAddress.address);
      this.draggableMarkerService.addDraggableMarker(Number(this.lat), Number(this.lon));
    }
  }

  hideIntro() {
    const intro = document.getElementById('helper-intro');
    if (intro) {
      intro.classList.add('hidden');
    }
  }

  showIntro() {
    const intro = document.getElementById('helper-intro');
    if (intro) {
      intro.classList.remove('hidden');
    }
  }

  finishHelperChanges() {
    const requestData = { project_problem_id: this.projectProblemId };
    return this.http.post('api/v1/helper-stop-points-algorithms', requestData).pipe(take(1));
  }

  openHelperModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.helper-modal');
    const modalBackground = document.querySelector('.helper-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
    this.viewProjectProblemService.showMapMessage(this.rightClickMsg);
    this.showIntro();
  }

  closeHelperModal() {
    this.finishHelperChanges().subscribe(() => {
      this.emptyHelperModal();
      this.stopPointsDataArray = [];
      this.stopPointsDataObject = {};
      this.currentStopPointIndexInArray = 0;
      const modal = document.querySelector('.helper-modal');
      const modalBackground = document.querySelector('.helper-modal-background');
      modal.classList.remove('open');
      modal.classList.add('closed');
      modalBackground.classList.add('hidden');
      this.projectProblemDataService.updateStopPoints(true);
      this.genericService.newShipmentsGenerateGridData();
      this.importerService.mapRefill();
      this.viewProjectProblemService.showMapMessage('');
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('IMPORTER.SHOW_DETAILS').subscribe((res: string) => { this.toggleMoreDetailsTexts.more = res; }));
    this.listen.push(this.translate.get('IMPORTER.HIDE_DETAILS').subscribe((res: string) => { this.toggleMoreDetailsTexts.less = res; }));
    this.listen.push(this.translate.get('IMPORTER.RIGHT_CLICK').subscribe((res: string) => { this.rightClickMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.toggleMoreDetailsText = this.toggleMoreDetailsTexts.more;
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );
    this.projectProblemId = null;
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
