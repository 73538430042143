import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { GuideTourService } from '@app/services/guide-tour.service';

@Component({
    selector: 'app-customers-grid-guided-tour',
    template: ''
})

export class CustomersGridGuidedTourComponent implements OnInit, AfterViewInit, OnDestroy {
    listen = [];

    defaultButtons = [];
    firstButtons = [];
    lastButtons = [];

    nextMsg = '';
    backMsg = '';
    finishMsg = '';

    newCustomerMsg = '';
    newCustomerDescriptionMsg = '';

    customersListMsg = '';
    customersListDescriptionMsg = '';

    chartsMsg = '';
    chartsDescriptionMsg = '';

    searchMsg = '';
    searchDescriptionMsg = '';

    chartToolsMsg = '';
    chartToolsDescriptionMsg = '';

    constructor(
        private customersGuide: ShepherdService,
        public translate: TranslateService,
        private guideTourService: GuideTourService,
    ) { }

    startGuide() {
        const self = this;
        const steps = [];
        steps.push({
            id: 'newCustomer',
            attachTo: {
                element: '#new-customer-button',
                on: 'right'
            },
            buttons: this.firstButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.newCustomerMsg,
            text: this.guideTourService.generateMilyMessage(this.newCustomerDescriptionMsg),
        });
        steps.push({
            id: 'customersList',
            attachTo: {
                element: '#customers-grid-container',
                on: 'top'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.customersListMsg,
            text: this.guideTourService.generateMilyMessage(this.customersListDescriptionMsg),
        });
        if (document.getElementById('customer-chart-container').classList.contains('expanded')) {
            steps.push({
                id: 'charts',
                attachTo: {
                    element: '#customers-chart',
                    on: 'bottom'
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.chartsMsg,
                text: this.guideTourService.generateMilyMessage(this.chartsDescriptionMsg),
            });
        }
        steps.push({
            id: 'search',
            attachTo: {
                element: '#search-container',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.searchMsg,
            text: this.guideTourService.generateMilyMessage(this.searchDescriptionMsg),
        });
        steps.push({
            id: 'chartTools',
            attachTo: {
                element: '#customer-chart-tools',
                on: 'left'
            },
            buttons: this.lastButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.chartToolsMsg,
            text: this.guideTourService.generateMilyMessage(this.chartToolsDescriptionMsg),
        });
        if (steps.length) {
            self.customersGuide.addSteps(steps);
            self.customersGuide.start();
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('DEMO.NEXT').subscribe((res: string) => {
            this.nextMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.BACK').subscribe((res: string) => {
            this.backMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.FINISH').subscribe((res: string) => {
            this.finishMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.NEW_CUSTOMER').subscribe((res: string) => {
            this.newCustomerMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.NEW_CUSTOMER_DESCRIPTION').subscribe((res: string) => {
            this.newCustomerDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.CUSTOMERS_LIST').subscribe((res: string) => {
            this.customersListMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.CUSTOMERS_LIST_DESCRIPTION').subscribe((res: string) => {
            this.customersListDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.CHARTS').subscribe((res: string) => {
            this.chartsMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.CHARTS_DESCRIPTION').subscribe((res: string) => {
            this.chartsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.SEARCH').subscribe((res: string) => {
            this.searchMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.SEARCH_DESCRIPTION').subscribe((res: string) => {
            this.searchDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.CHART_TOOLS').subscribe((res: string) => {
            this.chartToolsMsg = res;
        }));
        this.listen.push(this.translate.get('CUSTOMERS_DEMO.CHART_TOOLS_DESCRIPTION').subscribe((res: string) => {
            this.chartToolsDescriptionMsg = res;
        }));
        this.defaultButtons = [
            {
                classes: 'shepherd-button-secondary',
                text: this.backMsg,
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: this.nextMsg,
                type: 'next'
            }
        ];
        this.firstButtons = [
            {
                classes: 'shepherd-button-primary',
                text: this.nextMsg,
                type: 'next'
            }
        ];
        this.lastButtons = [
            {
                classes: 'shepherd-button-secondary',
                text: this.backMsg,
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: this.finishMsg,
                type: 'next'
            }
        ];
    }

    ngAfterViewInit() {
        const self = this;
        const defaultStepOptions = {
            classes: '',
            scrollTo: false,
            cancelIcon: {
                enabled: true
            }
        };

        this.customersGuide.defaultStepOptions = defaultStepOptions;
        this.customersGuide.modal = true;
        this.customersGuide.confirmCancel = false;
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
