import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CollaboratorService {

    collaboratorData = {};
    partnerData;

    generateIdWithZeros(id) {
        let newId = id.toString();
        if (newId.length < 6) {
            for (let i = newId.length; i < 6; i++) {
                newId = '0' + newId;
            }
        }

        return newId;
    }

}
