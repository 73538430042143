import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, AfterViewInit, NgModule, HostBinding, Inject, forwardRef } from '@angular/core';
import { ChartModule } from 'primeng/chart';

import { ChartOptions } from 'chart.js';
import { ILmChartWidgetTab } from '@app/model/widget';
import { mergeObjects } from '../utils';
import { LM_PIE_CHART_BASE_OPTIONS } from '@app/model/charts-config';
import { LmBaseChartComponent, LM_BASE_CHART_OPTIONS } from './chart-base';

@Component({
    selector: 'lm-pie-chart',
    providers:[
        { provide: LmBaseChartComponent, useExisting: forwardRef(() => LmPieChartComponent) },
        { provide: LM_BASE_CHART_OPTIONS, useValue: LM_PIE_CHART_BASE_OPTIONS }
    ],
    template:`
        <div class="grid lm-height lm-pdt10">
            <div class="col-12 lm-height">
                <p-chart 
                    type="pie" 
                    [data]="data" 
                    [options]="chartOptions"
                    [responsive]="responsive"
                    [width]="width"
                    [height]="height"
                    [responsive]="responsive"
                    (onDataSelect)="dataSelect($event)"
                >
                </p-chart>
            </div>
        </div>
    `
})
export class LmPieChartComponent extends LmBaseChartComponent implements OnInit, OnDestroy, AfterViewInit {
    static nextId = 0;
    @HostBinding() id = `lm-pie-chart-${LmPieChartComponent.nextId++}`;    


    constructor(@Inject(LM_BASE_CHART_OPTIONS) _baseOptions: ChartOptions) {
        super(_baseOptions)
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngAfterViewInit();
    }
}

@NgModule({
    imports: [CommonModule, ChartModule],
    exports: [LmPieChartComponent],
    declarations: [LmPieChartComponent]
  })
  export class LmPieChartModule {}