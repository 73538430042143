import { LoaderService } from './../app/services/loader.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    loadingTimeout;

    constructor(
        public loaderService: LoaderService,
    ) { }

    // uses timeout to not flicker on multiple http requests
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // placed in a timeout to avoid NG100 errors
        setTimeout(() => {
            this.loaderService.isLoading.next(true);
            this.loaderService.requestsCount += 1;
        }, 10);

        return next.handle(req).pipe(
            finalize(() => {
                setTimeout(() => {
                    this.loaderService.requestsCount -= 1;
                    
                    if (this.loaderService.requestsCount == 0) {
                        this.loaderService.isLoading.next(false);
                    }
                }, 2000);
            })
        );
    }
}
