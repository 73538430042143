import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Globals } from '@app/services/globals';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DynamicFieldService {
    private savePolylineListeners = new Subject<any>();
    private updateDriverDataListeners = new Subject<any>();
    private requests$ = new Subject<any>();

    constructor(
        private http: HttpClient,
        public globals: Globals,
    ) { }

    savePolylineListen(): Observable<any> {
        return this.savePolylineListeners.asObservable();
    }

    updateDriverDataListen(): Observable<any> {
        return this.updateDriverDataListeners.asObservable();
    }

    savePolyline(data) {
        this.savePolylineListeners.next(data);
    }

    updateDriverData(data) {
        this.updateDriverDataListeners.next(data);
    }

    getRulesData() {
        return this.http.get('api/v1/company/import-rules?returnRulesOnlyWithCustomAreaFlag=true');
    }

    getDriversRuleData(driverId) {
        return this.http.get('api/v1/company/import-rules?ruleActionDriverIds=' + driverId);
    }

    filterRegionRules(rules) {
        const regionRules = [];
        rules.forEach(ruleData => {
            const rule = ruleData['importRule'];
            // if the trigger operation is of type INSIDE
            if (rule.rule.trigger.expression.operator_type === 'INS') {
                regionRules.push(rule);
            }
        });
        return regionRules;
    }

    polygonCorrectorForSubmit(polygon) {
        let polygonPoints = polygon.replace('POLYGON ((', '');
        polygonPoints = polygonPoints.replace('))', '');
        const points = polygonPoints.split(',');
        if (points[0] !== points[points.length - 1]) {
            polygon = polygon.replace('))', ',' + points[0] + '))');
        }
        return polygon;
    }

    formAction(dynamicFieldsData, oldRule) {
        const oldRuleDataByDynamicFieldId = {};
        if (oldRule) {
            oldRule.rule.actions[0].expression.operands.forEach(dynamicFieldOperand => {
                let firstOperand, secondOperand;
                dynamicFieldOperand.expression.operands.forEach(operand => {
                    if (operand.order === 0) {
                        firstOperand = operand;
                    } else {
                        secondOperand = operand;
                    }
                });
                oldRuleDataByDynamicFieldId[secondOperand.import_dynamic_field_id] = {
                    expressionId: dynamicFieldOperand.expression.id,
                    firstOperandId: firstOperand.id,
                    secondOperandId: secondOperand.id
                };
            });
        }
        const actionOperands = [];
        dynamicFieldsData.forEach(field => {
            const dynamicFieldConstantOperand: Operand = {
                id: oldRuleDataByDynamicFieldId[field.id] ? oldRuleDataByDynamicFieldId[field.id]['firstOperandId'] : null,
                value: field.value,
                value_type: this.globals.ruleValueTypeConstants['VOLUME'],
                order: 0
            };
            const dynamicFieldValueOperand: Operand = {
                id: oldRuleDataByDynamicFieldId[field.id] ? oldRuleDataByDynamicFieldId[field.id]['secondOperandId'] : null,
                value: field.name,
                import_dynamic_field_id: field.id,
                order: 1
            };
            const fieldExpression: Expression = {
                id: oldRuleDataByDynamicFieldId[field.id] ? oldRuleDataByDynamicFieldId[field.id]['expressionId'] : null,
                operator_type: this.globals.ruleExpressionOperatorTypeConstants['MULTIPLY'],
                operands: [dynamicFieldConstantOperand, dynamicFieldValueOperand]
            };
            actionOperands.push({expression: fieldExpression});
        });
        const actionExpression: Expression = {
            id: oldRule ? oldRule.rule.actions[0].expression.id : null,
            operator_type: this.globals.ruleExpressionOperatorTypeConstants['ADDITION'],
            operands: actionOperands
        };
        const action: Action = {
            id: oldRule ? oldRule.rule.actions[0].id : null,
            action_type: this.globals.ruleActionTypeConstants['SET'],
            result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CALCULATED_LOAD']['constant_name'],
            expression: actionExpression
        };
        return action;
    }

    formRule(collaborator, dynamicFieldsData, oldRule) {
        let firstTriggerOperand, secondTriggerOperand;
        if (oldRule) {
            oldRule.rule.trigger.expression.operands.forEach(operand => {
                if (operand.order === 0) {
                    firstTriggerOperand = operand;
                } else {
                    secondTriggerOperand = operand;
                }
            });
        }
        const dynamicFieldConstantOperand: Operand = {
            id: firstTriggerOperand ? firstTriggerOperand.id : null,
            value: collaborator,
            value_type: this.globals.ruleValueTypeConstants['STRING'],
            order: 0
        };
        const dynamicFieldValueOperand: Operand = {
            id: secondTriggerOperand ? secondTriggerOperand.id : null,
            value: this.globals.stopPointImportFieldConfigs['STOP_POINT_SUPPLIER']['constant_name'],
            order: 1
        };
        const triggerExpression: Expression = {
            id: oldRule ? oldRule.rule.trigger.expression.id : null,
            operator_type: this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE'],
            operands: [dynamicFieldConstantOperand, dynamicFieldValueOperand]
        };
        const trigger: Trigger = {
            id: oldRule ? oldRule.rule.trigger.id : null,
            expression: triggerExpression
        };
        const action = this.formAction(dynamicFieldsData, oldRule);
        const rule: Rule = {
            id: oldRule ? oldRule.rule.id : null,
            name: '',
            trigger: trigger,
            actions: [action]
        };
        const finalRule: ImportRule = {
            id: oldRule ? oldRule.id : null,
            rule: rule
        };
        return finalRule;
    }

    submitPackagingRule(collaborator, dynamicFieldsData, oldRule) {
        const data = { importRule: this.formRule(collaborator, dynamicFieldsData, oldRule) };
        if (oldRule) {
            const importRuleId = oldRule.id;
            return this.http.put('api/v1/company/import-rules/' + importRuleId, data);
        } else {
            return this.http.post('api/v1/company/import-rules', data);
        }
    }

}

interface ImportRule {
    id: Number;
    rule: Object;
}
interface Rule {
    id: Number;
    name: String;
    trigger: Object;
    actions: Array<Object>;
}
interface Trigger {
    id: Number;
    expression: Object;
}
interface Action {
    id: Number;
    action_type: String;
    result_attribute: String;
    expression?: Object;
    actionValue?: Object;
    ruleActionXDriver?: Object;
    // result_model_attribute_type: String;
}
interface Expression {
    id: Number;
    operator_type: String;
    operands: Array<Object>;
}
interface Operand {
    id: Number;
    value_type?: String;
    import_dynamic_field_id?: String;
    value: String;
    order: Number;
}
interface ActionValue {
    id: Number;
    value_type: String;
    value: String;
}
