import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmCheckboxComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmCheckboxComponent) };

@Component({
  selector: 'lm-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LmCheckboxComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  searchType = 'string';

  @HostBinding() id = `lm-checkbox-${LmCheckboxComponent.nextId++}`;

  @ViewChild(NgModel) model: NgModel;

  @Input() iconClass = '';
  @Input() iconSvgClass = '';
  @Input() iconSvg?: string;
  @Input() iconSize?: string;
  @Input() iconColor?: string;
  @Input() inputId?: string;
  @Input() ariaLabelledBy?: string;
  @Input() ariaLabel?: string;
  @Input() style?: object;
  @Input() styleClass?: string;
  @Input() labelStyleClass?: string;
  @Input() checkboxIcon?: 'pi pi-check';
  @Input() trueValue?: any;
  @Input() falseValue?: any;
  @Input() binary = false;
  iconStyle: LmTextIconStyle;
  iconSvgPath: string;
  private svgsPath = 'assets/icons/icons.svg'

  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.iconSvg) this.iconSvgPath = this.svgsPath + this.iconSvg;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule
  ],
  exports: [LmCheckboxComponent],
  declarations: [LmCheckboxComponent]
})
export class LMCheckboxModule {}
