import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ActivatedRoute } from '@angular/router';
import { CustomerVouchersPrintGridComponent } from '@app/grids/customer-vouchers-print-grid/customer-vouchers-print-grid.component';
import { CustomerPaymentPrintComponent } from '@app/prints/customer-payment-invoice-print/customer-payment-invoice-print.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-customer-payment-invoice-print-single',
    templateUrl: './customer-payment-invoice-print-single.component.html',
    styleUrls: ['./customer-payment-invoice-print-single.component.scss']
})
export class CustomerPaymentInvoicePrintSingleComponent implements OnInit, OnDestroy {
    @ViewChild(CustomerPaymentPrintComponent, { static: false }) customerPaymentPrintComponent: CustomerPaymentPrintComponent;
    @ViewChild(CustomerVouchersPrintGridComponent, { static: false }) customerVouchersPrintGridComponent: CustomerVouchersPrintGridComponent;

    listen = [];

    hasLoadedData = false;

    constructor(
        public globals: Globals,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
    ) {
        // this.listenUpdateCustomerGrid = this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
        //     // this.pagesCount = 0;
        //     // this.gridApi.purgeServerSideCache([]);
        // });
    }

    loadPayment(hash) {
        this.http.get('api/unregistered/v1/pay-on-delivery-view?hash=' + hash).pipe(take(1)).subscribe(response => {
            if (response) {
                this.hasLoadedData = true;
                const data = response['items'];
                // set data on print invoice component
                this.customerPaymentPrintComponent.setPrintData(data);
                this.customerPaymentPrintComponent.setPaymentsGridData(data);
                this.customerPaymentPrintComponent.setCompanyData(data.company);
                this.customerPaymentPrintComponent.collaboratorData = {};
                this.customerPaymentPrintComponent.setCollaboratorData(data.collaborator);

                this.customerVouchersPrintGridComponent.collaboratorId = data.collaborator.collaboratorData.id;
                this.customerVouchersPrintGridComponent.invoiceOrPaymentQuery = 'is_pay_on_delivery=1';
                this.customerVouchersPrintGridComponent.hash = hash;
                this.customerVouchersPrintGridComponent.initGrid();
            }
        });
    }

    ngOnInit() {
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            const hash = params['hash'];
            this.loadPayment(hash);

            let dataInterval = setInterval(() => {
                if (this.hasLoadedData) {
                    this.customerVouchersPrintGridComponent.gridWidthFix();
                    window.print();

                    clearInterval(dataInterval);
                    dataInterval = null;
                }
            }, 1200);
        }));
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
