import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, delay, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment';
import { VehicleSelectEditorComponent } from './vehicle-select-editor/vehicle-select-editor.component';
import { DriverSwapSelectEditorComponent } from './driver-swap-select-editor/driver-swap-select-editor.component';
import { DepartureTimeSelectEditorComponent } from './departure-time-select-editor/departure-time-select-editor/departure-time-select-editor.component';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { Module } from '@ag-grid-community/core';
import { enableDebugTools } from '@angular/platform-browser';
import { MilyService } from '@app/services/mily.service';
import { ColourService } from '@app/services/colour.service';
import { GridsService } from '@app/services/grids.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { TimePickerModalComponent } from '@app/modals/time-picker-modal/time-picker-modal.component';
import { StepsService } from '@app/services/steps.service';

@Component({
  selector: 'app-drivers-modal-grid',
  templateUrl: './drivers-modal-grid.component.html',
  styleUrls: ['./../grids.scss']
})
export class DriversModalGridComponent implements OnInit, OnDestroy {

  @ViewChild(VehicleSelectEditorComponent, { static: false }) vehicleSelectEditorComponent: VehicleSelectEditorComponent;
  @ViewChild(DriverSwapSelectEditorComponent, { static: false }) driverSwapSelectEditorComponent: DriverSwapSelectEditorComponent;
  @ViewChild(DepartureTimeSelectEditorComponent, { static: false }) departureTimeSelectEditorComponent: DepartureTimeSelectEditorComponent;
  @Output() driversCount = new EventEmitter<string>();
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(TimePickerModalComponent, { static: false }) timePickerModalComponent: TimePickerModalComponent;

  vehicleTypes = [];

  bicycleLabel = '';
  scooterLabel = '';
  carLabel = '';
  vanLabel = '';
  largeVanLabel = '';
  truckLabel = '';

  editingDriverId = null;
  editingRouteSettingId = null;
  editingVehicleId = null;

  public modules: Module[] = [ClientSideRowModelModule, RowGroupingModule, MenuModule, ColumnsToolPanelModule];
  rowSelection = 'multiple';
  gridApi;
  gridColumnApi;
  rowNode;
  routeSettingsDataArray;
  driversWithRouteArray;
  driversWithoutRouteArray;
  rowData: any;

  alert;
  vehicleInUseAlert;
  statusTitle;
  vehicleTitle;
  capacityTitle;
  timeTitle;
  completedTitle;
  departureTimeTitle;
  driverTitle;
  swapTitle;
  cellSaved = false;
  searchString: String = '';

  domLayout = 'autoHeight';
  columnDefs;
  columnDefsManage;
  rowClassRules;
  headerHeight = 30;

  listen = [];
  vehicle: Observable<any>;
  vehicles: Observable<any>[] = new Array();
  vehiclesLoading = new Array();
  vehicleInputs: Subject<string>[] = new Array();
  selectedVehicle: any = <any>[];
  driversVehicle = [];
  driverIds = new Array();

  gridUrl = 'api/internal/v2/drivers';
  routeSettingsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/route-settings';
  projectProblemId = null;
  projectProblemDepartureDatetime;
  driversIdToRouteSettingId = {};

  routesWithCloseOvertime = [];
  vehiclesWithCloseCapacity = [];

  driversLoading = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private dataService: DataService,
    private projectProblemDataService: ProjectProblemDataService,
    private viewProjectProblemService: ViewProjectProblemService,
    private milyService: MilyService,
    private colourService: ColourService,
    public gridsService: GridsService,
    private sanitizer: DomSanitizer,
    private stepsService: StepsService,
  ) {
    this.listen.push(this.viewProjectProblemService.openStopModalListen().subscribe(id => {
      this.hideCheckBoxes();
    }));
    this.listen.push(this.viewProjectProblemService.updateDriversGridListen().subscribe(id => {
      this.projectProblemDataService.loadDrivers().subscribe(response => {
        this.projectProblemDataService.setDrivers(response['items']);
        this.loadRouteSettings();
      });
    }));
    this.listen.push(this.viewProjectProblemService.loadRouteSettingsListen().subscribe(() => {
      this.loadRouteSettings();
    }));
    this.listen.push(this.stepsService.transmitProjectProblemDataListen().subscribe((data) => {
      this.setRouteSettingsData(this.projectProblemId, this.projectProblemDepartureDatetime);
    }));
  }

  timeSelected(time) {
    const myObserver = {
      next: (response) => {
        this.viewProjectProblemService.updateProjectProblemRouteSettings();
      },
      error: (error) => {
        this.gridApi.hideOverlay();
      },
      complete: () => { },
    };
    const departureDatetimeOffset = this.calculateDepartureDatetimeOffset(time);
    const updateData = {
      routeSetting: {
        driver_id: this.editingDriverId,
        start_offset_from_departure: departureDatetimeOffset,
        vehicle_id: this.editingVehicleId,
      }
    };
    if (this.editingDriverId && this.editingRouteSettingId !== '') {
      this.gridApi.showLoadingOverlay();
      const routeSettingsUrl = this.routeSettingsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      this.http.put(routeSettingsUrl + '/' + this.editingRouteSettingId, updateData).pipe(take(1)).subscribe(myObserver);
    }
  }

  cellStyling(params: any) {
    let colour;
    if (params.data.routeIndex !== '') {
      colour = this.colourService.colourCalculator(params.data.routeIndex);
      // if there is a solution and this route index doesn't have stops, make the border pale
      if (!params.data.hasSolutionItems && Object.keys(this.projectProblemDataService.stopPointSolutionData).length) {
        colour = colour + '40';
      }
    } else {
      colour = '#ffffff';
    }
    return { 'border-left': '5px solid ' + colour };
  }

  vehicleColumnRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().vehicle_type || params.getValue().vehicle_type === 0) {
        columnObject += '<div class="vehicle-icon-cell">' + this.vehicleTypes[params.getValue().vehicle_type].image + '</div>';
      }
      if (params.getValue().plate_number) {
        columnObject += '<div class="vehicle-cell">' + params.getValue().plate_number + '</div>';
      }
    }
    return columnObject;
  }

  vehicleColumnRendererArrow(params) {
    let columnObject = '';
    columnObject += '<div class="display-flex"><div>';
    if (params.getValue()) {
      if (params.getValue().vehicle_type || params.getValue().vehicle_type === 0) {
        columnObject += '<div class="vehicle-icon-cell">' + this.vehicleTypes[params.getValue().vehicle_type].image + '</div>';
      }
      if (params.getValue().plate_number) {
        columnObject += '<div class="vehicle-cell">' + params.getValue().plate_number + '</div>';
      }
    }
    columnObject += '</div><div class="arrow-container"><i class="fas fa-caret-down"></i></div>';
    return columnObject;
  }

  nameRendererArrow(params) {
    let columnObject = '';
    columnObject += '<div class="display-flex"><div>';
    if (params.getValue().name) {
      columnObject += '<div class="double-cell standard-width bold-letters" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
      columnObject += '<div class="double-cell">' + params.getValue().phone + '</div>';
    }
    columnObject += '</div><div class="arrow-container"><i class="fas fa-caret-down"></i></div>';
    return columnObject;
  }

  capacityRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().load) {
        const load = params.getValue().load + '/' + params.getValue().capacity;
        const percentage = ((params.getValue().load * 100) / params.getValue().capacity).toFixed(2);
        columnObject += '<div class="double-cell standard-width" title="' + percentage + '%">' + percentage + '%</div>';
        columnObject += '<div class="double-cell standard-width" title="' + load + '">' + load + '</div>';
      } else if (params.getValue().capacity) {
        columnObject += '<div title="' + params.getValue().capacity + '">' + params.getValue().capacity + '</div>';
      } else {
        columnObject = '-';
      }
    }
    return columnObject;
  }

  hoursRenderer(params) {
    let columnObject = '';
    const text = params.getValue().routeDuration ? params.getValue().routeDuration + '/' + params.getValue().workingHours : params.getValue().workingHours;
    if (params.getValue().percentage) {
      columnObject += '<div class="double-cell standard-width" title="' + params.getValue().percentage + '%">' + params.getValue().percentage + '%</div>';
      columnObject += '<div class="double-cell standard-width" title="' + text + '">' + text + '</div>';
    } else {
      columnObject += '<div title="' + text + '">' + text + '</div>';
    }
    return columnObject;
  }

  completedRenderer(params) {
    let columnObject = '';
    const percentage = params.getValue();
    if (percentage != null) {
      if (percentage === 100) {
        columnObject += '<div class="blue-circle-icon"><i class="fas fa-flag"></i></div>';
      } else {
        const degrees = (Number(percentage) / 100) * 180;
        columnObject += '<div class="circle-wrap">';
        columnObject += '<div class="circle">';

        columnObject += '<div class="mask full" style="transform: rotate(' + degrees + 'deg)">';
        columnObject += '<div class="fill" style="transform: rotate(' + degrees + 'deg)"></div>';
        columnObject += '</div>';

        columnObject += '<div class="mask half">';
        columnObject += '<div class="fill" style="transform: rotate(' + degrees + 'deg)"></div>';
        columnObject += '</div>';

        columnObject += '<div class="inside-circle">';
        columnObject += percentage + '%';
        columnObject += '</div>';

        columnObject += '</div>';
        columnObject += '</div>';
      }
    }
    return columnObject;
  }

  public setRouteSettingsData(id, datetime) {
    this.projectProblemId = id;
    this.projectProblemDepartureDatetime = datetime;
    const dataRefreshIntervalId = setInterval(() => {
      if (this.gridApi) {
        clearInterval(dataRefreshIntervalId);
        this.loadRouteSettings();
      }
    }, 200);
  }

  loadRouteSettings() {
    this.driversLoading = true;
    this.gridApi.showLoadingOverlay();
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        const routeSettings = this.projectProblemDataService.routeSettingsArray;
        this.projectProblemDataService.setRouteSettings(routeSettings);
        this.driversIdToRouteSettingId = {};
        routeSettings.forEach(element => {
          this.driversIdToRouteSettingId[element.driver.id] = element.routeSetting.id;
          this.driversVehicle[element.routeSetting.driver_id] = {
            vehicle: { id: element.vehicle.id, plate_number: element.vehicle.plate_number },
            driver: { id: element.routeSetting.driver_id },
          };
        });
        this.dataService.driversVehicle = this.driversVehicle;
        this.getDriversData();
        this.gridApi.hideOverlay();
      }
    }
  }

  public getDriversData(): void {
    this.routeSettingsDataArray = [];
    this.driversWithRouteArray = [];
    this.driversWithoutRouteArray = [];
    this.updateDriversGridData();
  }

  public loadDriversData(): void {
    let url = 'api/internal/v2/drivers?searchQuery=' + this.searchString;
    if (this.projectProblemId) {
      url += '&projectProblemId=' + this.projectProblemId;
    }
    this.http.get(url).pipe(take(1)).subscribe(response => {
      this.routeSettingsDataArray = [];
      this.driversWithRouteArray = [];
      this.driversWithoutRouteArray = [];
      this.updateDriversGridData(response['items']);
    });
  }

  updateDriversGridData(data = null) {
    let driverHasRoute = false;
    let routeSettingsData = {};
    let driverId, driverName, vehicleId, vehicleMake, vehicleModel, vehiclePlateNumber, vehicleType, vehicleCapacity, departureDatetime;
    let departureDatetimeDuration, departureDatetimeDurationMinutes, capacity, load, workingHours, routeDuration, percentage, stopsPercentage, routeIsActive;
    if (!data) {
      data = this.projectProblemDataService.driversArray;
    }
    let routeIndex = '', hasSolutionItems = false, routeSettingId = null;
    let count = 0;
    this.routesWithCloseOvertime = [];
    this.vehiclesWithCloseCapacity = [];
    if (data.length) {
      data.forEach(row => {
        this.vehicles[row.driver.id] = new Observable<any>();
        this.vehicleInputs[row.driver.id] = new Subject<string>();
        this.vehiclesLoading[row.driver.id] = false;
        this.driverIds[row.driver.id] = row.driver.id;
        driverId = row.driver.id;
        driverName = row.userProfile.name;

        let workDuration = row.driver.daily_working_hours;
        let workDurationHours = moment.duration(workDuration).asHours();

        routeDuration = '';
        percentage = '';
        load = 0;
        capacity = 0;
        vehicleMake = '';
        vehicleModel = '';
        vehiclePlateNumber = '';
        vehicleType = null;
        vehicleCapacity = 0;
        stopsPercentage = null;

        routeSettingId = this.driversIdToRouteSettingId[row.driver.id];
        if (routeSettingId) {
          driverHasRoute = true;
          routeIndex = this.projectProblemDataService.getRouteIndexByRouteSettingId(routeSettingId);
          const currentRouteSetting = this.projectProblemDataService.routeSettings[routeIndex];
          vehicleId = currentRouteSetting.vehicle.id;
          if (this.projectProblemDataService.vehicles[vehicleId]) {
            if (this.projectProblemDataService.vehicles[vehicleId].vehicleInfoMake.name) {
              vehicleMake = this.projectProblemDataService.vehicles[vehicleId].vehicleInfoMake.name;
            } else if (this.projectProblemDataService.vehicles[vehicleId].vehicleInfoMakeCompany.name) {
              vehicleMake = this.projectProblemDataService.vehicles[vehicleId].vehicleInfoMakeCompany.name;
            }
            if (this.projectProblemDataService.vehicles[vehicleId].vehicleInfoModel.name) {
              vehicleModel = this.projectProblemDataService.vehicles[vehicleId].vehicleInfoModel.name;
            } else if (this.projectProblemDataService.vehicles[vehicleId].vehicleInfoModelCompany.name) {
              vehicleModel = this.projectProblemDataService.vehicles[vehicleId].vehicleInfoModelCompany.name;
            }
            vehiclePlateNumber = this.projectProblemDataService.vehicles[vehicleId].vehicle.plate_number;
            vehicleType = this.projectProblemDataService.vehicles[vehicleId].vehicle.vehicle_type;
            vehicleCapacity = this.projectProblemDataService.vehicles[vehicleId].vehicle.maximum_cargo_capacity;
          }

          workDuration = currentRouteSetting.routeSetting.driver_working_hours;
          workDurationHours = moment.duration(workDuration).asHours();
          routeIsActive = currentRouteSetting['routeSetting']['is_active'];
          departureDatetimeDuration = currentRouteSetting.routeSetting.start_offset_from_departure;
          departureDatetimeDurationMinutes = moment.duration(departureDatetimeDuration).asMinutes();
          const departureDatetimeMoment = moment(this.projectProblemDepartureDatetime).add(departureDatetimeDurationMinutes, 'minutes');
          departureDatetime = departureDatetimeMoment.format('HH:mm');
          load = currentRouteSetting.routeSetting.load;
          capacity = currentRouteSetting.vehicle.maximum_cargo_capacity;

          const lastStopPointId = currentRouteSetting.routeSetting.finish_stop_point_id;
          if (this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId']) {
            if (this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'][routeSettingId][lastStopPointId]) {
              const lastStopPointData = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'][routeSettingId][lastStopPointId];
              const lastDatetime = lastStopPointData.latestEstimatedArrivalDatetime;
              const routeDurationHours = moment.duration((moment(lastDatetime).utc()).diff(departureDatetimeMoment.utc())).asHours();
              const routeDurationHoursFloor = Math.floor(routeDurationHours);
              const routeDurationMinutes = moment.duration((routeDurationHours - routeDurationHoursFloor), 'hours').asMinutes();
              routeDuration = routeDurationHoursFloor + 'h ';
              if (Math.round(routeDurationMinutes)) {
                routeDuration += Math.round(routeDurationMinutes) + 'm';
              }
              percentage = ((routeDurationHours * 100) / workDurationHours).toFixed(2);
            }
          }
          if (!isNaN(this.projectProblemDataService.routeIndexesPercentage[routeIndex])) {
            stopsPercentage = this.projectProblemDataService.routeIndexesPercentage[routeIndex];
          }
        } else {
          driverHasRoute = false;
          routeIndex = '';
          if (row.vehicle) {
            vehicleId = row.vehicle.id;
            if (row.vehicleInfoMake.name) {
              vehicleMake = row.vehicleInfoMake.name;
            } else if (row.vehicleInfoMakeCompany) {
              if (row.vehicleInfoMakeCompany.name) {
                vehicleMake = row.vehicleInfoMakeCompany.name;
              }
            }
            if (row.vehicleInfoModel.name) {
              vehicleModel = row.vehicleInfoModel.name;
            } else if (row.vehicleInfoModelCompany.name) {
              vehicleModel = row.vehicleInfoModelCompany.name;
            }
            vehiclePlateNumber = row.vehicle.plate_number;
            vehicleType = row.vehicle.vehicle_type;
            vehicleCapacity = row.vehicle.maximum_cargo_capacity;
            capacity = row.vehicle.maximum_cargo_capacity;
          } else {
            vehicleId = null;
            vehicleMake = '';
            vehicleModel = '';
            vehiclePlateNumber = '';
            vehicleType = null;
            vehicleCapacity = 0;
          }
          departureDatetime = moment(row.driver.usual_departure_datetime).format('HH:mm');
          load = 0;
          percentage = '';
          stopsPercentage = null;
        }

        const workDurationHoursFloor = Math.floor(workDurationHours);
        const workDurationHoursMinutes = moment.duration((workDurationHours - workDurationHoursFloor), 'hours').asMinutes();
        workingHours = workDurationHoursFloor + 'h ';
        if (Math.round(workDurationHoursMinutes)) {
          workingHours += Math.round(workDurationHoursMinutes) + 'm';
        }

        if (load / capacity > .95) {
          this.vehiclesWithCloseCapacity.push({
            name: row.userProfile.name,
            info: load + '/' + capacity,
            colour: this.colourService.colourCalculator(routeIndex)
          });
        }

        hasSolutionItems = false;
        if (this.projectProblemDataService.sequenceArrayPerRouteSettingId) {
          if (this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId]) {
            hasSolutionItems = Object.keys(this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId]).length ? true : false;
          }
        }

        this.driversVehicle[row.driver.id] = {
          vehicle: {
            id: vehicleId,
            make: vehicleMake,
            model: vehicleModel,
            plate_number: vehiclePlateNumber,
            vehicle_type: vehicleType,
            maximum_cargo_capacity: vehicleCapacity,
          },
          driver: { id: row.driver.id }
          // row.routeSetting.is_active
        };
        routeSettingsData = {
          name: {
            id: row.driver.id,
            name: row.userProfile.name,
            phone: row.userProfile.telephone,
          },
          vehicle: {
            id: vehicleId,
            make: vehicleMake,
            model: vehicleModel,
            plate_number: vehiclePlateNumber,
            vehicle_type: vehicleType,
            maximum_cargo_capacity: vehicleCapacity,
          },
          departure: departureDatetime,
          capacity: {
            capacity: capacity,
            load: load
          },
          workTime: {
            workingHours: workingHours,
            routeDuration: routeDuration,
            percentage: percentage
          },
          completed: stopsPercentage,
          // status: '',
          driverId: row.driver.id,
          routeIndex: routeIndex,
          constantRouteIndex: routeIndex,
          hasSolutionItems: hasSolutionItems,
          routeIsActive: routeIsActive
          // fullData: row.routeSetting
        };
        count = hasSolutionItems ? count + 1 : count;
        this.routeSettingsDataArray.push(routeSettingsData);
        if (driverHasRoute) {
          this.driversWithRouteArray.push(routeSettingsData);
        } else {
          this.driversWithoutRouteArray.push(routeSettingsData);
        }
      });
      this.dataService.driversVehicle = this.driversVehicle;
      this.driversLoading = false;
      this.setDropdowns();
      this.sortBySolution(this.routeSettingsDataArray);
      this.sortBySolution(this.driversWithRouteArray);
      // if the pp is optimized, show only drivers with route index when not in manage mode
      if (this.projectProblemDataService['projectProblemData']) {
        if (this.projectProblemDataService['projectProblemData']['optimization_state'] === this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']) {
          const checkbox = document.getElementById('route-settings-checkbox') as HTMLInputElement;
          if (checkbox) {
            if (checkbox.checked) {
              this.rowData = of(this.routeSettingsDataArray);
            } else {
              this.rowData = of(this.driversWithRouteArray);
            }
          } else {
            this.rowData = of(this.driversWithRouteArray);
          }
        } else {
          this.rowData = of(this.routeSettingsDataArray);
          count = this.driversWithRouteArray.length;
        }
      }
      const refreshParams = { force: true };
      this.gridApi.refreshCells(refreshParams);
      this.updateCheckboxes();
    } else {
      const noData = [{ noDataText: 'No data' }];
      this.rowData = of(noData);
    }
    this.driversCount.emit(String(count));
  }

  rowClicked(event) {
  }

  sortBySolution(array) {
    function compare(a, b) {
      // true values first
      return (a.hasSolutionItems === b.hasSolutionItems) ? 0 : a.hasSolutionItems ? -1 : 1;
    }
    array.sort(compare);

  }

  setDropdowns() {
    this.driverIds.forEach(element => {
      this.vehicles[element] = concat(
        of([]), // default items
        this.vehicleInputs[element].pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.vehiclesLoading[element] = true),
          switchMap(term => this.dataService.getVehicles(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.vehiclesLoading[element] = false)
          ))
        )
      );
    });
  }

  getVehiclesRouteSetting(vehicleId) {
    let targetRouteSetting = null;
    this.projectProblemDataService.routeSettingsArray.forEach(routeSetting => {
      if (routeSetting.vehicle.id === vehicleId) {
        targetRouteSetting = routeSetting;
      }
    });
    return targetRouteSetting;
  }

  cellValueChanged(event) {
    const myObserver = {
      next: (response) => {
        this.viewProjectProblemService.updateProjectProblemRouteSettings();
      },
      error: (error) => {
        if (error.error.errors.project.problem.routeSetting['vehicle_id-driver-id']) {
          this.viewProjectProblemService.updateProjectProblemRouteSettings();
          this.milyService.alert(error.error.errors.project.problem.routeSetting['vehicle_id-driver-id'][0]);
        } else if (error.error.errors.project.problem.routeSetting['vehicle_id']) {
          this.milyService.alert(error.error.errors.project.problem.routeSetting['vehicle_id'][0]);
          this.viewProjectProblemService.updateProjectProblemRouteSettings();
        } else {
          this.gridApi.hideOverlay();
        }

      },
      complete: () => { },
    };
    const routeSettingsUrl = this.routeSettingsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    // if the driver is active in the pp
    if (event.data.routeIndex !== '') {
      this.cellSaved = true;
      const routeIndex = event.data.routeIndex;
      let vehicleId, driverId, driverDepartureDatetime, driverDepartureDatetimeMoment, departureDatetimeOffset, swapRouteMode, targetRouteIndex;
      // swap drivers' routes
      if (event.column.colId.includes('name')) {
        if (event.newValue) {
          driverId = event.data.name.id;
          const routeSettingId = this.driversIdToRouteSettingId[driverId];
          if (routeSettingId) {
            targetRouteIndex = this.projectProblemDataService.getRouteIndexByRouteSettingId(routeSettingId);
            vehicleId = this.projectProblemDataService.routeSettingsById[routeSettingId].vehicle.id;
            departureDatetimeOffset = this.projectProblemDataService.routeSettingsById[routeSettingId].start_offset_from_departure;
            const vehicleIdsToColours = {};
            vehicleIdsToColours[vehicleId] = this.colourService.colourCalculator(routeIndex);
            vehicleIdsToColours[event.data.vehicle.id] = this.colourService.colourCalculator(targetRouteIndex);
            this.viewProjectProblemService.transmitNewVehicleColours(vehicleIdsToColours);
          } else {
            if (this.projectProblemDataService.drivers[driverId].vehicle) {
              vehicleId = this.projectProblemDataService.drivers[driverId].vehicle.id;
            } else {
              vehicleId = null;
            }
            driverDepartureDatetime = this.projectProblemDataService.drivers[driverId].usual_departure_datetime;
            driverDepartureDatetimeMoment = moment(driverDepartureDatetime);
          }
          swapRouteMode = true;
        }
        // change departure time
      } else if (event.column.colId.includes('departure')) {
        if (!event.data.departure) {
          event.data.departure = event.oldValue;
        }
        vehicleId = event.data.vehicle.id;
        driverId = event.data.driverId;
        swapRouteMode = false;
        // change vehicle
      } else if (event.column.colId.includes('vehicle')) {
        if (event.oldValue.id !== event.newValue.id) {
          vehicleId = event.data.vehicle.id;
          driverId = event.data.driverId;
          swapRouteMode = false;
        }
      }
      if (!departureDatetimeOffset) {
        departureDatetimeOffset = this.calculateDepartureDatetimeOffset(event.data.departure);
      }
      const updateData = {
        routeSetting: {
          vehicle_id: vehicleId,
          driver_id: driverId,
          start_offset_from_departure: departureDatetimeOffset,
          swapRouteMode: swapRouteMode
        }
      };
      if (driverId && vehicleId) {
        this.gridApi.showLoadingOverlay();
        this.http.put(routeSettingsUrl + '/' + routeIndex, updateData).pipe(take(1)).subscribe(myObserver);
      }
      // if the driver is not active in the pp
    } else {
      // select vehicle and enable this driver
      if (event.column.colId.includes('vehicle')) {
        this.cellSaved = true;
        if (event.oldValue.id !== event.newValue.id) {
          const routeSettingsData = {
            routeSetting: {
              vehicle_id: event.data.vehicle.id,
              driver_id: event.data.driverId,
              start_offset_from_departure: this.calculateDepartureDatetimeOffset(event.data.departure),
              swapRouteMode: false
            }
          };
          if (event.data.driverId && event.data.vehicle.id) {
            this.gridApi.showLoadingOverlay();
            this.http.post(routeSettingsUrl, routeSettingsData).pipe(take(1)).subscribe(myObserver);
          }
        }
      } else {

      }
    }
    // if the departure time is not saved, show the old one
    if (!this.cellSaved && event.column.colId === 'departure') {
      this.cellSaved = true;
      event.node.setDataValue('departure', event.oldValue);
    } else if (!this.cellSaved && event.column.colId === 'name') {
      this.cellSaved = true;
      event.node.setDataValue('name', event.oldValue);
    }
  }

  calculateDepartureDatetimeOffset(departure) {
    let driverDepartureDatetime, driverDepartureDatetimeMoment, departureDatetimeOffset;
    const projectProblemDepartureDatetimeMoment = moment(this.projectProblemDepartureDatetime);
    const projectProblemDepartureDate = projectProblemDepartureDatetimeMoment.format('YYYY-MM-DD');
    driverDepartureDatetime = projectProblemDepartureDate + ' ' + departure;
    driverDepartureDatetimeMoment = moment(driverDepartureDatetime, 'YYYY-MM-DD HH:mm');
    const departureDatetimeMinutes = moment.duration(driverDepartureDatetimeMoment.diff(projectProblemDepartureDatetimeMoment)).asMinutes();
    departureDatetimeOffset = moment.duration(departureDatetimeMinutes, 'minutes').toISOString();
    if (departureDatetimeMinutes < 0) {
      this.milyService.alert(this.alert);
      departureDatetimeOffset = moment.duration(0, 'minutes').toISOString();
    }
    return departureDatetimeOffset;
  }

  // TODO disable toggling while updating
  rowCheckboxToggled(event) {
    const node = event.node;
    const nodeData = node.data;
    const myObserver = {
      next: (response) => {
        this.viewProjectProblemService.updateProjectProblemRouteSettings();
      },
      error: (error) => {
        
          if (error.error.errors.project.problem.routeSetting['vehicle_id']) {
            this.milyService.alert(error.error.errors.project.problem.routeSetting['vehicle_id'][0]);
            this.viewProjectProblemService.updateProjectProblemRouteSettings();
          } else {
            this.gridApi.hideOverlay();
          }
          if (node.isSelected()) { node.setSelected(false); } else { node.setSelected(true); }
          console.error(error);
        
      },
      complete: () => { },
    };
    const routeSettingsUrl = this.routeSettingsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    if (node.isSelected() && (!node.data.routeIndex && node.data.routeIndex !== 0)) {
      const projectProblemDepartureDatetimeMoment = moment(this.projectProblemDepartureDatetime);
      const projectProblemDepartureDate = projectProblemDepartureDatetimeMoment.format('YYYY-MM-DD');
      const driverDepartureDatetime = projectProblemDepartureDate + ' ' + node.data.departure;
      const driverDepartureDatetimeMoment = moment(driverDepartureDatetime, 'YYYY-MM-DD HH:mm');
      const departureDatetimeMinutes = moment.duration(driverDepartureDatetimeMoment.diff(projectProblemDepartureDatetimeMoment)).asMinutes();
      let departureDatetimeOffset = moment.duration(departureDatetimeMinutes, 'minutes').toISOString();
      if (departureDatetimeMinutes < 0) {
        this.milyService.alert(this.alert);
        departureDatetimeOffset = moment.duration(0, 'minutes').toISOString();
      }
      this.gridApi.showLoadingOverlay();
      const routeSettingData = {
        'routeSetting': {
          'vehicle_id': node.data.vehicle.id,
          'driver_id': node.data.driverId,
          'start_offset_from_departure': departureDatetimeOffset
        }
      };
      const routeIndex = node.data.constantRouteIndex;
      if (routeIndex == parseInt(routeIndex, 10)) {
        this.http.delete(routeSettingsUrl + '/' + routeIndex).pipe(take(1)).subscribe(myObserver);
      } else {
        this.http.post(routeSettingsUrl, routeSettingData).pipe(take(1)).subscribe(myObserver);
      }
    } else if (!node.isSelected() && (node.data.routeIndex || node.data.routeIndex === 0)) {
      this.gridApi.showLoadingOverlay();
      const routeIndex = node.data.constantRouteIndex;
      if (!isNaN(routeIndex)) {
        this.http.delete(routeSettingsUrl + '/' + routeIndex).pipe(take(1)).subscribe(myObserver);
      }
    }
  }

  toggleCheckBoxes(event) {
    if (event.target.checked) {
      this.rowData = of(this.routeSettingsDataArray);
      // empty columnDefs before updating them for changes to be detected by grid
      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.columnDefsManage);
      this.dataService.vehicles = this.vehicles;
      this.dataService.vehicleInputs = this.vehicleInputs;
      this.dataService.vehiclesLoading = this.vehiclesLoading;
      this.dataService.driversVehicle = this.driversVehicle;
      this.updateCheckboxes();
    } else {
      if (this.projectProblemDataService.projectProblemData['optimization_state'] === this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']) {
        this.rowData = of(this.driversWithRouteArray);
      } else {
        this.rowData = of(this.routeSettingsDataArray);
      }
      const refreshParams = { force: true };
      this.gridApi.refreshCells(refreshParams);
      // empty columnDefs before updating them for changes to be detected by grid
      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.columnDefs);
    }
  }

  hideCheckBoxes() {
    const checkbox = document.getElementById('route-settings-checkbox') as HTMLInputElement;
    if (checkbox) {
      if (checkbox.checked) {
        checkbox.checked = false;
        if (this.projectProblemDataService.projectProblemData['optimization_state'] === this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']) {
          this.rowData = of(this.driversWithRouteArray);
        } else {
          this.rowData = of(this.routeSettingsDataArray);
        }
        const refreshParams = { force: true };
        this.gridApi.refreshCells(refreshParams);
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    }
  }

  updateCheckboxes() {
    setTimeout(() => {
      this.gridApi.forEachNode(function (node, index) {
        if ((node.data.routeIndex || node.data.routeIndex === 0) && node.data.routeIsActive) {
          node.setSelected(true);
        } else {
          node.setSelected(false);

          const routeSettingsData = node.data;
          routeSettingsData.routeIndex = '';
          node.setData(routeSettingsData);
        }
      });
    }, 100);
  }

  onFirstDataRendered(params) {
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  timeCellClicked(event) {
    if (event.data.routeIndex !== '') {
      this.editingDriverId = event.data.driverId;
      this.editingRouteSettingId = event.data.routeIndex;
      this.editingVehicleId = event.data.vehicle.id;
      this.timePickerModalComponent.setTime(event.value);
      this.timePickerModalComponent.toggleModal();
    }
  }

  completeCellClicked(event) {
    if (event.data.completed === 100 && event.data.driverId) {
      this.viewProjectProblemService.openDriverReports(event.data.driverId);
    }
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
        typeName: this.bicycleLabel
      },
      {
        index: 1,
        image: scooter,
        typeName: this.scooterLabel
      },
      {
        index: 2,
        image: car,
        typeName: this.carLabel
      },
      {
        index: 3,
        image: van,
        typeName: this.vanLabel
      },
      {
        index: 4,
        image: largeVan,
        typeName: this.largeVanLabel
      },
      {
        index: 5,
        image: truck,
        typeName: this.truckLabel
      }
    ];
  }

  getTranslations() {
    this.listen.push(this.translate.get('DRIVER.ALERT').subscribe((res: string) => { this.alert = res; }));
    this.listen.push(this.translate.get('ALERTS.VEHICLE_IN_USE').subscribe((res: string) => { this.vehicleInUseAlert = res; }));
    this.listen.push(this.translate.get('GENERIC.DEPARTURE').subscribe((res: string) => { this.departureTimeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.CAPACITY').subscribe((res: string) => { this.capacityTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.TIME').subscribe((res: string) => { this.timeTitle = res; }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => { this.completedTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.VEHICLE').subscribe((res: string) => { this.vehicleTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DRIVER').subscribe((res: string) => { this.driverTitle = res; }));
    this.listen.push(this.translate.get('DRIVER.SWAP').subscribe((res: string) => { this.swapTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));
    this.columnDefs = [
      {
        headerName: this.driverTitle,
        field: 'name',
        sortable: true,
        filter: true,
        cellRenderer: this.gridsService.nameRenderer,
        checkboxSelection: false,
        cellStyle: this.cellStyling.bind(this),
        width: this.gridsService.widthCalculator(19)
      },
      {
        headerName: this.vehicleTitle,
        field: 'vehicle',
        sortable: true,
        editable: true,
        cellRenderer: this.vehicleColumnRendererArrow.bind(this),
        cellEditorFramework: VehicleSelectEditorComponent,
        width: this.gridsService.widthCalculator(6)
      },
      {
        headerName: this.departureTimeTitle,
        field: 'departure',
        onCellClicked: this.timeCellClicked.bind(this),
        cellClass: ['editable-time'],
        cellEditorFramework: DepartureTimeSelectEditorComponent,
        width: this.gridsService.widthCalculator(5)
      },
      {
        headerName: this.capacityTitle,
        field: 'capacity',
        cellRenderer: this.capacityRenderer,
        width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: this.timeTitle,
        field: 'workTime',
        cellRenderer: this.hoursRenderer,
        width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: this.completedTitle,
        field: 'completed',
        onCellClicked: this.completeCellClicked.bind(this),
        cellRenderer: this.completedRenderer,
        width: this.gridsService.widthCalculator(6)
      },
    ];
    this.columnDefsManage = [
      {
        headerName: this.driverTitle,
        field: 'name',
        editable: true,
        checkboxSelection: true,
        cellClass: ['select-driver'],
        cellRenderer: this.nameRendererArrow,
        cellEditorFramework: DriverSwapSelectEditorComponent,
        width: this.gridsService.widthCalculator(19)
      },
      {
        headerName: this.vehicleTitle,
        field: 'vehicle',
        sortable: true,
        editable: true,
        cellRenderer: this.vehicleColumnRendererArrow.bind(this),
        cellEditorFramework: VehicleSelectEditorComponent,
        width: this.gridsService.widthCalculator(6)
      },
      {
        headerName: this.departureTimeTitle,
        field: 'departure',
        onCellClicked: this.timeCellClicked.bind(this),
        cellClass: ['editable-time'],
        cellEditorFramework: DepartureTimeSelectEditorComponent,
        width: this.gridsService.widthCalculator(5)
      },
      {
        headerName: this.capacityTitle,
        field: 'capacity',
        cellRenderer: this.capacityRenderer, width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: this.timeTitle,
        field: 'workTime',
        cellRenderer: this.hoursRenderer,
        width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: this.completedTitle,
        field: 'completed',
        onCellClicked: this.completeCellClicked.bind(this),
        cellRenderer: this.completedRenderer,
        width: this.gridsService.widthCalculator(6)
      },
    ];
    this.rowClassRules = {
      'grey-letters': function (params) {
        if (params.data.hasSolutionItems || params.data.routeIndex !== '') {
          return false;
        } else {
          return true;
        }
      },
    };
  }

  ngOnInit() {
    setTimeout(() => {
      this.setVehicleTypes();

      this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.getTranslations();
      }));
      this.getTranslations();
    }, 300);

  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
