import { GenericService } from '@app/services/generic.service';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollaboratorService } from '@app/services/collaborator.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratorVouchersGridComponent } from '@app/grids/collaborator-vouchers-grid/collaborator-vouchers-grid.component';
import { CustomerCollaboratorsModalComponent } from '@app/modals/customer-collaborators-modal/customer-collaborators-modal.component';
import { CustomerInvoicesGridComponent } from '@app/grids/customer-invoices-grid/customer-invoices-grid.component';
import { CustomerPaymentsGridComponent } from '@app/grids/customer-payments-grid/customer-payments-grid.component';
import { StopsCustomDateModalComponent } from '@app/modals/stops-custom-date-modal/stops-custom-date-modal.component';
import * as moment from 'moment-timezone';
import { ModalService } from '@app/services/modal.service';

@Component({
    selector: 'app-collaborator-view',
    templateUrl: './collaborator-view.component.html',
    styleUrls: ['../grids/grids.scss', './collaborator-view.component.scss']
})
export class CollaboratorViewComponent implements OnInit, OnDestroy {
    @ViewChild('container', { static: false }) container;
    @ViewChild('charges', { static: false }) charges;
    @ViewChild('invoices', { static: false }) invoices;
    @ViewChild('vouchers', { static: false }) vouchers;
    @ViewChild('payments', { static: false }) payments;
    @ViewChild('openVouchersChartsButton', { static: false }) openVouchersChartsButton;
    @ViewChild('openVouchersFiltersButton', { static: false }) openVouchersFiltersButton;
    @ViewChild('openChargesButton', { static: false }) openChargesButton;
    @ViewChild('openInvoicesButton', { static: false }) openInvoicesButton;
    @ViewChild('openPaymentsButton', { static: false }) openPaymentsButton;
    @ViewChild('openXlsButton', { static: false }) openXlsButton;
    @ViewChild('openXlsPaymentsButton', { static: false }) openXlsPaymentsButton;

    @ViewChild(CustomerCollaboratorsModalComponent, { static: false }) customerCollaboratorsModalComponent: CustomerCollaboratorsModalComponent;
    @ViewChild(CollaboratorVouchersGridComponent, { static: false }) collaboratorVouchersGridComponent: CollaboratorVouchersGridComponent;
    @ViewChild(CustomerInvoicesGridComponent, { static: false }) customerInvoicesGridComponent: CustomerInvoicesGridComponent;
    @ViewChild(CustomerPaymentsGridComponent, { static: false }) customerPaymentsGridComponent: CustomerPaymentsGridComponent;

    @ViewChild(StopsCustomDateModalComponent, { static: false }) stopsCustomDateModalComponent: StopsCustomDateModalComponent;

    gridApi;
    gridColumnApi;
    pagesCount = 0;
    cacheBlockSize = 50;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    selectedDate = '';
    stopsPeriods = [];
    selectedStopsPeriod;
    customDateString = '';
    recentLabel: string;
    lastWeekLabel: string;
    lastMonthLabel: string;
    customLabel: string;

    collaboratorId;
    collaboratorData;
    collaboratorName;
    collaboratorCode;

    currentActiveView = 'chart'; // chart, filters, map, payments, invoices, charges

    listen = [];

    constructor(
        public translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private collaboratorService: CollaboratorService,
        private modalGridService: ModalGridService,
        private modalService: ModalService,
        public globals: Globals,
        private genericService: GenericService
    ) {
        this.listen.push(this.modalGridService.listenUpdateCustomerCollaborators().subscribe((response) => {
            this.updateCollaboratorData();
        }));
        this.listen.push(this.modalGridService.listenOpenInvoicesGrid().subscribe(res => {
            this.openInvoicesGrid();
        }));
        // get custom date range from modal
        this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
            this.collaboratorVouchersGridComponent.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
            this.customDateString = dates.start + ' - ' + dates.end;
            this.collaboratorVouchersGridComponent.updateGrid();
            this.selectedStopsPeriod = {
                label: this.customDateString,
                value: 'custom'
            };
        }));
    }

    updateCollaboratorData() {
        let url = 'api/v1/company-collaborators/' + this.collaboratorId;
        this.http.get(url).pipe(take(1)).subscribe(response => {
            this.customerInvoicesGridComponent.customerInvoiceModalComponent.customerInvoicePrintComponent.collaboratorData = response;
            this.collaboratorData = response['items']['0'];
            this.collaboratorService.collaboratorData = this.collaboratorData.companyCollaborator.collaborator;
            this.collaboratorId = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.id;
            this.collaboratorName = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.collaborator_name;

            this.collaboratorCode = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.id;
            this.collaboratorCode = this.collaboratorService.generateIdWithZeros(this.collaboratorCode);
        });
    }

    setCollaboratorData() {
        // collaborator portal
        let url = 'api/v1/company-collaborators/' + this.collaboratorId;
        this.http.get(url).pipe(take(1)).subscribe(response => {
            this.customerInvoicesGridComponent.customerInvoiceModalComponent.customerInvoicePrintComponent.collaboratorData = response;
            this.collaboratorData = response['items']['0'];
            this.collaboratorService.collaboratorData = this.collaboratorData.companyCollaborator.collaborator;
            this.collaboratorId = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.id;
            this.collaboratorName = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.collaborator_name;

            this.collaboratorCode = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.id;
            this.collaboratorCode = this.collaboratorService.generateIdWithZeros(this.collaboratorCode);

            this.collaboratorVouchersGridComponent.initGrid(this.collaboratorId);
            this.customerInvoicesGridComponent.initGrid(this.collaboratorId);
            this.customerPaymentsGridComponent.initGrid(this.collaboratorId);

            this.customerCollaboratorsModalComponent.collaboratorId = this.collaboratorId;
        });
    }

    stopsPeriodChange() {
        let startDate = '', endDate = '', timezone = '';
        timezone = moment.tz.guess();
        if (this.selectedStopsPeriod.value === 'custom') {
            this.stopsCustomDateModalComponent.openModal();
        } else {
            startDate = moment().subtract(this.selectedStopsPeriod.value, 'days').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            this.collaboratorVouchersGridComponent.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone;
            this.collaboratorVouchersGridComponent.updateGrid();

            // set balance for selected period
            const dateObj = {
                startDate: startDate, 
                endDate: endDate, 
                timezone: timezone
            };
            this.genericService.balanceAmountsUpdate(dateObj);
        }
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    backButtonClicked() {
        this.router.navigateByUrl('/customerCollaborators');
    }

    removeActiveClass() {
        document.querySelector('.white-bar-right-button.active').classList.remove('active');
    }

    openChargesView() {
        this.currentActiveView = 'charges';
        // this.openCharts();

        this.charges.nativeElement.classList.remove('hidden');
        this.invoices.nativeElement.classList.add('hidden');
        this.vouchers.nativeElement.classList.add('hidden');
        this.payments.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openChargesButton.nativeElement.classList.add('active');
    }

    openInvoicesGrid() {
        this.currentActiveView = 'invoices';
        this.openCharts();

        this.invoices.nativeElement.classList.remove('hidden');
        this.vouchers.nativeElement.classList.add('hidden');
        this.payments.nativeElement.classList.add('hidden');
        this.charges.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openInvoicesButton.nativeElement.classList.add('active');
    }

    openVouchersGridWithChart() {
        this.currentActiveView = 'chart';
        this.openCharts();

        this.invoices.nativeElement.classList.add('hidden');
        this.vouchers.nativeElement.classList.remove('hidden');
        this.payments.nativeElement.classList.add('hidden');
        this.charges.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openVouchersChartsButton.nativeElement.classList.add('active');
    }

    openVouchersGridWithFilters() {
        this.currentActiveView = 'filters';
        this.openFilters();

        this.invoices.nativeElement.classList.add('hidden');
        this.vouchers.nativeElement.classList.remove('hidden');
        this.payments.nativeElement.classList.add('hidden');
        this.charges.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openVouchersFiltersButton.nativeElement.classList.add('active');
    }

    openPaymentsGrid() {
        this.currentActiveView = 'payments';
        this.openCharts();

        this.invoices.nativeElement.classList.add('hidden');
        this.vouchers.nativeElement.classList.add('hidden');
        this.payments.nativeElement.classList.remove('hidden');
        this.charges.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openPaymentsButton.nativeElement.classList.add('active');
    }

    // activates/deactivates charts for all components
    openCharts() {
        this.collaboratorVouchersGridComponent.openChart();
        this.customerInvoicesGridComponent.openChart();
        this.customerPaymentsGridComponent.openChart();
        this.collaboratorVouchersGridComponent.closeFilters();

        this.removeActiveClass();
        this.openVouchersChartsButton.nativeElement.classList.add('active');
    }

    openFilters() {
        this.customerInvoicesGridComponent.closeChart();
        this.collaboratorVouchersGridComponent.closeChart();
        this.customerPaymentsGridComponent.closeChart();
        this.collaboratorVouchersGridComponent.openFilters();

        this.removeActiveClass();
        this.openVouchersFiltersButton.nativeElement.classList.add('active');
    }

    openCollaboratorsModal() {
        if (this.collaboratorData) {
            this.customerCollaboratorsModalComponent.openModal();
            this.customerCollaboratorsModalComponent.customerCollaboratorsFormComponent.loadCollaboratorsData(this.collaboratorData);
        }
    }

    openXlsAction() {
        this.collaboratorVouchersGridComponent.openXlsAction();
    }

    openXlsPaymentsAction() {
        this.customerPaymentsGridComponent.openXlsAction();
    }

    openMap() {
        this.globals.comingSoonAlert();
    }

    getTranslations() {
        // translations
        this.listen.push(this.translate.get('GENERIC.RECENT').subscribe((res: string) => { this.recentLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.CUSTOM').subscribe((res: string) => { this.customLabel = res; }));

        this.stopsPeriods = [
            {
                label: this.recentLabel,
                value: 1
            },
            {
                label: this.lastWeekLabel,
                value: 6
            },
            {
                label: this.lastMonthLabel,
                value: 29
            },
            {
                label: this.customLabel,
                value: 'custom'
            },
        ];
        this.selectedStopsPeriod = {
            label: this.recentLabel,
            value: 1
        };
        // set selectedDatesQuery after collaboratorVouchersGridComponent is set
        setTimeout(() => {
            const timezone = moment.tz.guess();
            const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            const endDate = moment().format('YYYY-MM-DD');
            this.collaboratorVouchersGridComponent.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone;
        }, 200);
    }

    ngOnInit() {
        // send 'collaboratorId' to vouchers, invoices & payments grid components
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            this.collaboratorId = params['id'];
            this.setCollaboratorData();
        }));

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {

                if (this.openInvoicesButton.nativeElement.classList.contains('active')) {
                    this.customerInvoicesGridComponent.searchString = this.searchString;
                    this.customerInvoicesGridComponent.updateGrid();
                } else if (this.openVouchersChartsButton.nativeElement.classList.contains('active')
                    || this.openVouchersFiltersButton.nativeElement.classList.contains('active')) {
                    this.collaboratorVouchersGridComponent.searchString = this.searchString;
                    this.collaboratorVouchersGridComponent.updateGrid();
                }

            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
