import { ILmBaseEnum } from '@app/model/enums';
import { hasValue } from './../shared/utils';

export function getEnumInfoValues(enumObject: {}): ILmBaseEnum[] {
  return Object.values(enumObject);
}

export function getEnumItemByKey(enumObject: {}, key: number) {
  return getEnumItem(enumObject, 'key', key);
}

export function getEnumItemByValue(enumObject: {}, value: string) {
  return getEnumItem(enumObject, 'value', value);
}

export function getEnumCaptionByKey(enumObject: {}, key: number) {
  if (!hasValue(key)) {
    return '';
  }
  const enumObj = getEnumItemByKey(enumObject, key);
  if (!enumObj) {
    return 'Λανθασμένη τιμή';
  } else {
    return enumObj.caption;
  }
}

export function getEnumCaptionByValue(enumObject: {}, value: string) {
  if (!hasValue(value)) {
    return '';
  }
  const enumObj = getEnumItemByValue(enumObject, value);
  if (!enumObj) {
    return 'Λανθασμένη τιμή';
  } else {
    return enumObj.caption;
  }
}

function getEnumItem(enumObject: {}, selector: string, selectorValue: string | number) {
  for (const keyProp in enumObject) {
    if (enumObject[keyProp][selector] === selectorValue) {
      return enumObject[keyProp];
    }
  }
}

export function enumCellRenderer(cell: any, enumObject: {}): string {
  if (cell.value === undefined || cell.value === null) {
    return '';
  }
  const key = cell.value.key === null || cell.value.key === undefined ? cell.value : cell.value.key;
  const enumInfo = getEnumItemByKey(enumObject, key);

  return enumInfo ? enumInfo.caption : '';
}
