import { ModuleWithProviders, NgModule } from '@angular/core';
import { ID_GENERATOR } from './services/id-generator/id-generator';
import { LMUlidGeneratorService } from './services/id-generator/ulid-generator.service';
import { LmNotificationService } from './services/notification.service';
import {LmLoggerService} from './services/logger.service';
import { LmSelectorCacheService } from './services/cache/selector-cache.service';
import { LOCALSTORAGE_CACHE, MEMORY_CACHE, SESSIONSTORAGE_CACHE } from '@app/model/cache-service';
import { SelectMapperService } from './services/select-mapper.service';
import { MessageService } from 'primeng/api';
import { LmBrowserUtilsService } from './services/browser-service';

@NgModule()
export class LMCoreModule {
  static forRoot(): ModuleWithProviders<LMCoreModule> {
    return {
      ngModule: LMCoreModule,
      providers: [
        // LMInitService,
        MessageService,
        LmNotificationService,
        LmLoggerService,
        LmSelectorCacheService ,
        SelectMapperService,
        LmBrowserUtilsService,
        SESSIONSTORAGE_CACHE,
        LOCALSTORAGE_CACHE,
        MEMORY_CACHE,
        { provide: ID_GENERATOR, useClass: LMUlidGeneratorService }
      ]
    };
  }
}
