import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewProjectProblemService {
  isStopPointModalOpen = false;

  private listeners = new Subject<any>();
  private displayStopPointsToMapListeners = new Subject<any>();
  private updateStopPointDataInGridListeners = new Subject<any>();
  private createStopListeners = new Subject<any>();
  private createSameDayDeliveryStopFromMapClickListeners = new Subject<any>();
  private viewStopListeners = new Subject<any>();
  private openStopFormModalListeners = new Subject<any>();
  private closeStopModalListeners = new Subject<any>();
  private trackVehiclesListeners = new Subject<any>();
  private editStopPointListeners = new Subject<any>();
  private updateProjectProblemListeners = new Subject<any>();
  private updateVehicleIconListeners = new Subject<any>();
  private showOnlyActiveVehiclesOnMapListeners = new Subject<any>();
  private updateProjectProblemStatusListeners = new Subject<any>();
  private updateProjectProblemRouteSettingsListeners = new Subject<any>();
  private showProjectProblemButtonsListeners = new Subject<any>();
  private transmitNewVehicleColoursListeners = new Subject<any>();
  private addStopPointToMapListeners = new Subject<any>();
  private removeStopPointFromMapListeners = new Subject<any>();
  private removeStopPointFromClusterListeners = new Subject<any>();
  private loadStopPointsListeners = new Subject<any>();
  private moveStopPointsListeners = new Subject<any>();
  private moveStopPointsToProblemListeners = new Subject<any>();
  private changeStopPointEntityStatusListeners = new Subject<any>();
  private returnStopPointsListeners = new Subject<any>();
  private changeMultipleStopPointsEntityStatusListeners = new Subject<any>();
  private disableMapMultipleSelectListeners = new Subject<any>();
  private disableMapMultipleSelectAfterUpdateListeners = new Subject<any>();
  private freeStopPointTimeWindowListeners = new Subject<any>();
  private freeMultipleStopPointsTimeWindowListeners = new Subject<any>();
  private unModifyStopPointsListeners = new Subject<any>();
  private cancelStopPointsListeners = new Subject<any>();
  private deleteStopPointsListeners = new Subject<any>();
  private mergeStopPointsListeners = new Subject<any>();
  private unMergeStopPointsListeners = new Subject<any>();
  private sequenceStopPointsListeners = new Subject<any>();
  private emptyStopPointsListeners = new Subject<any>();
  private removeFiltersFromMapListeners = new Subject<any>();
  private filterStopPointsListeners = new Subject<any>();
  private setStopPointsGridListeners = new Subject<any>();
  private updateStopPointsGridListeners = new Subject<any>();
  private openDriversGridListeners = new Subject<any>();
  private changeStopPointPriorityListeners = new Subject<any>();
  private openAddStopsModalListeners = new Subject<any>();
  private setActiveStopPointsGridListeners = new Subject<any>();
  private createVehicleLocationListeners = new Subject<any>();
  private updateCancelledGridListeners = new Subject<any>();
  private updateWarehouseGridListeners = new Subject<any>();
  private updateDriversGridListeners = new Subject<any>();
  private moveStopsListeners = new Subject<any>();
  private loadStopsListeners = new Subject<any>();
  private enableDriverPortalListeners = new Subject<any>();
  private enableSettingsPortalListeners = new Subject<any>();
  private startGeneralTourListeners = new Subject<any>();
  private showMapMessageListeners = new Subject<any>();
  private throwCancelConfirmListeners = new Subject<any>();
  private setModalDataListeners = new Subject<any>();
  private openProjectProblemReportsListeners = new Subject<any>();
  private openDriverReportsListeners = new Subject<any>();
  private resetMilyOpenedForCanceledErrorStopsListeners = new Subject<any>();
  private focusOnMarkerListeners = new Subject<any>();
  private focusOnMarkersListeners = new Subject<any>();
  private openPortalIssuesModalListeners = new Subject<any>();
  private updateSelectDriversListeners = new Subject<any>();
  private loadRouteSettingsListeners = new Subject<any>();
  private updateStopPointsCounterListeners = new Subject<any>();
  private updateDriversModalGridListeners = new Subject<any>();
  private updateSmartPointsListeners = new Subject<any>();
  private closeStopModalListsListeners = new Subject<any>();

  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  displayStopPointsToMapListen(): Observable<any> {
    return this.displayStopPointsToMapListeners.asObservable();
  }

  updateStopPointDataInGridListen(): Observable<any> {
    return this.updateStopPointDataInGridListeners.asObservable();
  }

  createStopFromMapClickListen(): Observable<any> {
    return this.createStopListeners.asObservable();
  }

  createSameDayDeliveryStopFromMapClickListen(): Observable<any> {
    return this.createSameDayDeliveryStopFromMapClickListeners.asObservable();
  }

  openStopModalListen(): Observable<any> {
    return this.viewStopListeners.asObservable();
  }

  openStopFormModalListen(): Observable<any> {
    return this.openStopFormModalListeners.asObservable();
  }

  closeStopModalListen(): Observable<any> {
    return this.closeStopModalListeners.asObservable();
  }

  trackVehiclesListen(): Observable<any> {
    return this.trackVehiclesListeners.asObservable();
  }

  listenEditStopPoint(): Observable<any> {
    return this.editStopPointListeners.asObservable();
  }

  updateProjectProblemListen(): Observable<any> {
    return this.updateProjectProblemListeners.asObservable();
  }

  updateVehicleIconListen(): Observable<any> {
    return this.updateVehicleIconListeners.asObservable();
  }

  showOnlyActiveVehiclesOnMapListen(): Observable<any> {
    return this.showOnlyActiveVehiclesOnMapListeners.asObservable();
  }

  updateProjectProblemStatusListen(): Observable<any> {
    return this.updateProjectProblemStatusListeners.asObservable();
  }

  updateProjectProblemRouteSettingsListen(): Observable<any> {
    return this.updateProjectProblemRouteSettingsListeners.asObservable();
  }

  setStopPointsGridListen(): Observable<any> {
    return this.setStopPointsGridListeners.asObservable();
  }

  updateStopPointsGridListen(): Observable<any> {
    return this.updateStopPointsGridListeners.asObservable();
  }

  openDriversGridListen(): Observable<any> {
    return this.openDriversGridListeners.asObservable();
  }

  showProjectProblemButtonsListen(): Observable<any> {
    return this.showProjectProblemButtonsListeners.asObservable();
  }

  transmitNewVehicleColoursListen(): Observable<any> {
    return this.transmitNewVehicleColoursListeners.asObservable();
  }

  addStopPointToMapListen(): Observable<any> {
    return this.addStopPointToMapListeners.asObservable();
  }

  removeStopPointFromMapListen(): Observable<any> {
    return this.removeStopPointFromMapListeners.asObservable();
  }

  removeStopPointFromClusterListen(): Observable<any> {
    return this.removeStopPointFromClusterListeners.asObservable();
  }

  loadStopPointsListen(): Observable<any> {
    return this.loadStopPointsListeners.asObservable();
  }

  moveStopPointsListen(): Observable<any> {
    return this.moveStopPointsListeners.asObservable();
  }

  moveStopPointsToProblemListen(): Observable<any> {
    return this.moveStopPointsToProblemListeners.asObservable();
  }

  changeStopPointEntityStatusListen(): Observable<any> {
    return this.changeStopPointEntityStatusListeners.asObservable();
  }

  returnStopPointsListen(): Observable<any> {
    return this.returnStopPointsListeners.asObservable();
  }

  changeMultipleStopPointsEntityStatusListen(): Observable<any> {
    return this.changeMultipleStopPointsEntityStatusListeners.asObservable();
  }

  disableMapMultipleSelectListen(): Observable<any> {
    return this.disableMapMultipleSelectListeners.asObservable();
  }

  disableMapMultipleSelectAfterUpdateListen(): Observable<any> {
    return this.disableMapMultipleSelectAfterUpdateListeners.asObservable();
  }

  freeStopPointTimeWindowListen(): Observable<any> {
    return this.freeStopPointTimeWindowListeners.asObservable();
  }

  freeMultipleStopPointsTimeWindowListen(): Observable<any> {
    return this.freeMultipleStopPointsTimeWindowListeners.asObservable();
  }

  unModifyStopPointsListen(): Observable<any> {
    return this.unModifyStopPointsListeners.asObservable();
  }

  cancelStopPointsListen(): Observable<any> {
    return this.cancelStopPointsListeners.asObservable();
  }

  deleteStopPointsListen(): Observable<any> {
    return this.deleteStopPointsListeners.asObservable();
  }

  mergeStopPointsListen(): Observable<any> {
    return this.mergeStopPointsListeners.asObservable();
  }

  unMergeStopPointsListen(): Observable<any> {
    return this.unMergeStopPointsListeners.asObservable();
  }

  sequenceStopPointsListen(): Observable<any> {
    return this.sequenceStopPointsListeners.asObservable();
  }

  emptyStopPointsListen(): Observable<any> {
    return this.emptyStopPointsListeners.asObservable();
  }

  removeFiltersFromMapListen(): Observable<any> {
    return this.removeFiltersFromMapListeners.asObservable();
  }

  filterStopPointsListen(): Observable<any> {
    return this.filterStopPointsListeners.asObservable();
  }

  changeStopPointPriorityListen(): Observable<any> {
    return this.changeStopPointPriorityListeners.asObservable();
  }

  openAddStopsModalListen(): Observable<any> {
    return this.openAddStopsModalListeners.asObservable();
  }

  setActiveStopPointsGridListen(): Observable<any> {
    return this.setActiveStopPointsGridListeners.asObservable();
  }

  createVehicleLocationListen(): Observable<any> {
    return this.createVehicleLocationListeners.asObservable();
  }

  updateCancelledGridListen(): Observable<any> {
    return this.updateCancelledGridListeners.asObservable();
  }

  updateWarehouseGridListen(): Observable<any> {
    return this.updateWarehouseGridListeners.asObservable();
  }

  updateDriversGridListen(): Observable<any> {
    return this.updateDriversGridListeners.asObservable();
  }

  moveStopsListen(): Observable<any> {
    return this.moveStopsListeners.asObservable();
  }

  loadStopsListen(): Observable<any> {
    return this.loadStopsListeners.asObservable();
  }

  enableDriverPortalListen(): Observable<any> {
    return this.enableDriverPortalListeners.asObservable();
  }

  enableSettingsPortalListen(): Observable<any> {
    return this.enableSettingsPortalListeners.asObservable();
  }

  startGeneralTourListen(): Observable<any> {
    return this.startGeneralTourListeners.asObservable();
  }

  showMapMessageListen(): Observable<any> {
    return this.showMapMessageListeners.asObservable();
  }

  throwCancelConfirmListen(): Observable<any> {
    return this.throwCancelConfirmListeners.asObservable();
  }

  setModalDataListen(): Observable<any> {
    return this.setModalDataListeners.asObservable();
  }

  openProjectProblemReportsListen(): Observable<any> {
    return this.openProjectProblemReportsListeners.asObservable();
  }

  openDriverReportsListen(): Observable<any> {
    return this.openDriverReportsListeners.asObservable();
  }

  resetMilyOpenedForCanceledErrorStopsListen(): Observable<any> {
    return this.resetMilyOpenedForCanceledErrorStopsListeners.asObservable();
  }

  focusOnMarkerListen(): Observable<any> {
    return this.focusOnMarkerListeners.asObservable();
  }

  focusOnMarkersListen(): Observable<any> {
    return this.focusOnMarkersListeners.asObservable();
  }

  openPortalIssuesModalListen(): Observable<any> {
    return this.openPortalIssuesModalListeners.asObservable();
  }

  updateSelectDriversListen(): Observable<any> {
    return this.updateSelectDriversListeners.asObservable();
  }

  loadRouteSettingsListen(): Observable<any> {
    return this.loadRouteSettingsListeners.asObservable();
  }

  updateStopPointsCounterListen(): Observable<any> {
    return this.updateStopPointsCounterListeners.asObservable();
  }

  updateDriversModalGridListen(): Observable<any> {
    return this.updateDriversModalGridListeners.asObservable();
  }

  updateSmartPointsListen(): Observable<any> {
    return this.updateSmartPointsListeners.asObservable();
  }

  closeStopModalListsListen(): Observable<any> {
    return this.closeStopModalListsListeners.asObservable();
  }

  filter(projectProblemId) {
    this.listeners.next(projectProblemId);
  }

  displayStopPointsToMap(data) {
    this.displayStopPointsToMapListeners.next(data);
  }

  updateStopPointDataInGrid(data) {
    this.updateStopPointDataInGridListeners.next(data);
  }

  createStopFromMapClick(lat, lng, helperOpen, forSameDayDelivery = false) {
    this.createStopListeners.next({
      lat: lat,
      lng: lng,
      helperOpen: helperOpen,
      forSameDayDelivery: forSameDayDelivery
    });
  }

  createSameDayDeliveryStopFromMapClick(lat, lng, helperOpen) {
    this.createSameDayDeliveryStopFromMapClickListeners.next({
      lat: lat,
      lng: lng,
      helperOpen: helperOpen,
    });
  }

  openStopModal(stopPointId, projectProblemId = null, tempProjectProblemDepartureDatetime = null) {
    this.viewStopListeners.next({
      stopPointId: stopPointId,
      projectProblemId: projectProblemId,
      tempProjectProblemDepartureDatetime: tempProjectProblemDepartureDatetime
    });
  }

  openStopFormModal(noVoucher = false) {
    this.openStopFormModalListeners.next(noVoucher);
  }

  closeStopModal() {
    this.closeStopModalListeners.next(1);
  }

  trackVehicles(vehicleId, colour, coords, dateTime, plateNumber, driverName, driverImage, companyImage, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour, showPickupBox, estimatedArrival) {
    this.trackVehiclesListeners.next({
      vehicleId: vehicleId,
      colour: colour,
      coords: coords,
      dateTime: dateTime,
      plateNumber: plateNumber,
      driverName: driverName,
      driverImage: driverImage,
      companyImage: companyImage,
      vehicleType: vehicleType,
      driverFinishedStops: driverFinishedStops,
      driverTotalStops: driverTotalStops,
      driverTotalProgressDegrees: driverTotalProgressDegrees,
      driverColour: driverColour,
      showPickupBox: showPickupBox,
      estimatedArrival: estimatedArrival
    });
  }

  editStopPoint(stopPointId, projectProblemId = null, hasVoucher = false, tempProjectProblemId = null, tempProjectProblemDepartureDatetime = null) {
    this.editStopPointListeners.next({
      stopPointId: stopPointId,
      projectProblemId: projectProblemId,
      hasVoucher: hasVoucher,
      tempProjectProblemId: tempProjectProblemId,
      tempProjectProblemDepartureDatetime: tempProjectProblemDepartureDatetime
    });
  }

  updateProjectProblem() {
    this.updateProjectProblemListeners.next(1);
  }

  updateVehicleIcon(vehicleData) {
    this.updateVehicleIconListeners.next(vehicleData);
  }

  showOnlyActiveVehiclesOnMap(activeVehicleIdsArray) {
    this.showOnlyActiveVehiclesOnMapListeners.next(activeVehicleIdsArray);
  }

  updateProjectProblemStatus() {
    this.updateProjectProblemStatusListeners.next(1);
  }

  updateProjectProblemRouteSettings() {
    this.updateProjectProblemRouteSettingsListeners.next(1);
  }

  setStopPointsGrid() {
    this.setStopPointsGridListeners.next(1);
  }

  updateStopPointsGrid(data) {
    this.updateStopPointsGridListeners.next(data);
  }

  openDriversGrid() {
    this.openDriversGridListeners.next(1);
  }

  showProjectProblemButtons(projectProblemId) {
    this.showProjectProblemButtonsListeners.next(projectProblemId);
  }

  transmitNewVehicleColours(vehicleIdsToColours) {
    this.transmitNewVehicleColoursListeners.next(vehicleIdsToColours);
  }

  addStopPointToMap(data) {
    this.addStopPointToMapListeners.next(data);
  }

  removeStopPointFromMap(id) {
    this.removeStopPointFromMapListeners.next(id);
  }

  removeStopPointFromCluster(stopPointId, zoomLevel, centerId) {
    this.removeStopPointFromClusterListeners.next({
      stopPointId: stopPointId,
      zoomLevel: zoomLevel,
      centerId: centerId,
    });
  }

  loadStopPoints(ids) {
    this.loadStopPointsListeners.next(ids);
  }

  moveStopPoints(id) {
    this.moveStopPointsListeners.next(id);
  }

  moveStopPointsToProblem(ids) {
    this.moveStopPointsToProblemListeners.next(ids);
  }

  changeStopPointEntityStatus(id, enabled) {
    this.changeStopPointEntityStatusListeners.next({
      id: id,
      enabled: enabled
    });
  }

  returnStopPoints(ids) {
    this.returnStopPointsListeners.next(ids);
  }

  changeMultipleStopPointsEntityStatus(ids, enabled) {
    this.changeMultipleStopPointsEntityStatusListeners.next({
      ids: ids,
      enabled: enabled
    });
  }

  disableMapMultipleSelect() {
    this.disableMapMultipleSelectListeners.next(1);
  }

  disableMapMultipleSelectAfterUpdate() {
    this.disableMapMultipleSelectAfterUpdateListeners.next(1);
  }

  freeStopPointTimeWindow(id) {
    this.freeStopPointTimeWindowListeners.next(id);
  }

  freeMultipleStopPointsTimeWindow(ids) {
    this.freeMultipleStopPointsTimeWindowListeners.next(ids);
  }

  unModifyStopPoints(stopPointIds) {
    this.unModifyStopPointsListeners.next(stopPointIds);
  }

  cancelStopPoints(stopPointIds) {
    this.cancelStopPointsListeners.next(stopPointIds);
  }

  deleteStopPoints(stopPointIds) {
    this.deleteStopPointsListeners.next(stopPointIds);
  }

  mergeStopPoints(stopPointIds, targetStopPointId) {
    this.mergeStopPointsListeners.next({
      stopPointIds: stopPointIds,
      targetStopPointId: targetStopPointId
    });
  }

  unMergeStopPoints(stopPointIds) {
    this.unMergeStopPointsListeners.next(stopPointIds);
  }

  sequenceStopPoints(stopPointIds, targetStopPointId, mode) {
    this.sequenceStopPointsListeners.next({
      stopPointIds: stopPointIds,
      targetStopPointId: targetStopPointId,
      mode: mode
    });
  }

  changeStopPointPriority(ids, priority) {
    this.changeStopPointPriorityListeners.next({
      priority: priority,
      ids: ids
    });
  }

  emptyStopPoints() {
    this.emptyStopPointsListeners.next(1);
  }

  removeFiltersFromMap() {
    this.removeFiltersFromMapListeners.next(1);
  }

  filterStopPoints(shownStopPoints, shownRoutes) {
    this.filterStopPointsListeners.next({
      shownStopPoints: shownStopPoints,
      shownRoutes: shownRoutes
    });
  }

  openAddStopsModal(mode) {
    this.openAddStopsModalListeners.next(mode);
  }

  setActiveStopPointsGrid(state) {
    this.setActiveStopPointsGridListeners.next(state);
  }

  createVehicleLocation(coords) {
    this.createVehicleLocationListeners.next(coords);
  }

  updateCancelledGrid() {
    this.updateCancelledGridListeners.next(1);
  }

  updateWarehouseGrid() {
    this.updateWarehouseGridListeners.next(1);
  }

  updateDriversGrid() {
    this.updateDriversGridListeners.next(1);
  }

  moveStops(data) {
    this.moveStopsListeners.next(data);
  }

  loadStops(data) {
    this.loadStopsListeners.next(data);
  }

  enableDriverPortal(id) {
    this.enableDriverPortalListeners.next(id);
  }

  enableSettingsPortal() {
    this.enableSettingsPortalListeners.next(1);
  }

  startGeneralTour() {
    this.startGeneralTourListeners.next(1);
  }

  showMapMessage(text) {
    this.showMapMessageListeners.next(text);
  }

  throwCancelConfirm() {
    this.throwCancelConfirmListeners.next(1);
  }

  setModalData(data) {
    this.setModalDataListeners.next(data);
  }

  openProjectProblemReports() {
    this.openProjectProblemReportsListeners.next(1);
  }

  openDriverReports(id) {
    this.openDriverReportsListeners.next(id);
  }

  resetMilyOpenedForCanceledErrorStops() {
    this.resetMilyOpenedForCanceledErrorStopsListeners.next(1);
  }

  focusOnMarker(id) {
    this.focusOnMarkerListeners.next(id);
  }

  focusOnMarkers(ids) {
    this.focusOnMarkersListeners.next(ids);
  }

  openPortalIssuesModal() {
    this.openPortalIssuesModalListeners.next(1);
  }

  updateSelectDrivers() {
    this.updateSelectDriversListeners.next(1);
  }

  loadRouteSettings() {
    this.loadRouteSettingsListeners.next(1);
  }

  updateStopPointsCounter() {
    this.updateStopPointsCounterListeners.next(1);
  }

  updateDriversModalGrid() {
    this.updateDriversModalGridListeners.next(1);
  }

  updateSmartPoints() {
    this.updateSmartPointsListeners.next(1);
  }

  closeStopModalLists() {
    this.closeStopModalListsListeners.next(1);
  }

}
