import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment-timezone';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-wizard-driver-settings',
    templateUrl: './wizard-driver-settings.component.html',
    styleUrls: ['./wizard-driver-settings.component.scss']
})
export class WizardDriverSettingsComponent implements OnInit {

    @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;

    myForm: FormGroup;

    defaultWorkingHoursUnformatted = '08:00';
    defaultWorkingHoursMinutes = [480];
    defaultWorkingHours = '';
    defaultWorkingHoursOptions;

    driverOvertime = false;

    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private dataService: DataService,
        private settingsService: SettingsService,
        public translate: TranslateService,
        public globals: Globals,
    ) {
        this.initializeSlider();
        this.myForm = formBuilder.group({
            'company': formBuilder.group({
                // 'id': [this.companyId],
            }),
            'companySettings': formBuilder.group({
                'default_working_hours': [this.defaultWorkingHours],
                'defaultWorkingHoursUnformatted': [this.defaultWorkingHoursUnformatted],
                'defaultWorkingHoursMinutes': [this.defaultWorkingHoursMinutes],
                'driver_overtime': [this.driverOvertime],
            }),
        });
    }

    patchFormForLoad() {
        this.myForm.patchValue({
            'company': {
            },
            'companySettings': {
                'default_working_hours': this.defaultWorkingHours,
                'driver_overtime': this.driverOvertime,
            },
        });
        M.updateTextFields();
    }

    patchForm() {
        this.myForm.patchValue({
            'companySettings': {
                'default_working_hours': this.defaultWorkingHours,
            },
        });
        M.updateTextFields();
    }

    loadData() {
        const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        this.http.get(companyUrl).pipe(take(1)).subscribe(response => {
            const data = response['items'];

            this.driverOvertime = data.companySettings.driver_overtime;

            if (data.companySettings.default_working_hours) {
                this.defaultWorkingHours = data.companySettings.default_working_hours;
                this.defaultWorkingHoursUnformatted = moment(data.companySettings.default_working_hours, 'HH:mm:SS').format('HH:mm');
                this.defaultWorkingHoursMinutes = [moment.duration(this.defaultWorkingHours).asMinutes()];
            }

            this.patchFormForLoad();
        });

    }

    initializeSlider() {
        this.defaultWorkingHoursOptions = {
            step: 15,
            start: [480],
            connect: 'lower',
            range: {
                min: 60,
                max: 720
            },
        };
    }

    convertMinutesToTime() {
        let hours = 0, minutes = 0;
        hours = this.convertToHour(this.myForm.value.companySettings.defaultWorkingHoursMinutes);
        minutes = this.convertToMinute(this.myForm.value.companySettings.defaultWorkingHoursMinutes, hours);
        this.defaultWorkingHoursUnformatted = this.formatHoursAndMinutes(hours, minutes);
    }

    convertToHour(value) {
        return Math.floor(value / 60);
    }

    convertToMinute(value, hour) {
        return value - hour * 60;
    }

    formatHoursAndMinutes(hours, minutes) {
        if (hours.toString().length === 1) { hours = '0' + hours; }
        if (minutes.toString().length === 1) { minutes = '0' + minutes; }
        return hours + ':' + minutes;
    }

    scrollFix() {
        // window.scrollTo(0, 0);
    }

    submitWizardForm() {
        const companySettingsFormValues = this.myForm.value.companySettings;

        let defaultWorkingHoursMinutes;
        if (Array.isArray(companySettingsFormValues.defaultWorkingHoursMinutes)) {
            defaultWorkingHoursMinutes = companySettingsFormValues.defaultWorkingHoursMinutes[0];
        } else {
            defaultWorkingHoursMinutes = companySettingsFormValues.defaultWorkingHoursMinutes;
        }
        this.defaultWorkingHours = moment.duration(defaultWorkingHoursMinutes, 'minutes').toISOString();
        if (this.defaultWorkingHours) { this.globals.defaultWorkingHours = this.defaultWorkingHours; }

        this.patchForm();
        const myObserver = {
            next: (response) => { },
            error: (error) => {
                console.error(error);
            },
            complete: () => { },
        };

        const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        this.http.put(companyUrl, this.myForm.value).pipe(take(1)).subscribe(myObserver);
    }

    ngOnInit() {
        this.loadData();

        const departureTimeElement = document.querySelectorAll('.timepicker');
        const timeInstances = M.Timepicker.init(departureTimeElement, {
            twelveHour: false
        });
    }

}
