import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapService } from '@app/services/map.service';
import { DriverRegionRuleService } from '@app/services/driver-region-rule.service';
import { AddressService } from '@app/services/address.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

declare var H: any;

@Component({
    selector: 'app-portal-address-map',
    templateUrl: './portal-address-map.component.html',
    styleUrls: ['./portal-address-map.component.scss']
})
export class PortalAddressMapComponent implements OnInit, AfterViewInit {

    private platform: any;
    @ViewChild('map', { static: false })
    public mapElement: ElementRef;
    @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
    @Output() addressSelected = new EventEmitter<string>();

    map;
    ui;
    behavior;
    rightClickOnMap;
    draggableMarkerGroup = new H.map.Group();
    draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
    positionSet = false;
    myForm: FormGroup;

    bounds;
    greeceLat = '38.65238769104773';
    greeceLon = '24.0691524677357';

    title = '';
    addressType = 7;
    freeformAddress = '';
    country = 'GR';
    lat = '';
    lon = '';
    countryCode = '';
    state = '';
    county = '';
    city = '';
    district = '';
    street = '';
    street2 = '';
    houseNumber = '';
    postalCode = '';
    isPlace = false;
    placeName = '';

    addresses: Observable<any>;
    addressesLoading = false;
    addressInput = new Subject<string>();
    selectedAddress: any = <any>[];

    constructor(
        private http: HttpClient,
        private mapService: MapService,
        public driverRegionRuleService: DriverRegionRuleService,
        private addressService: AddressService,
        private dataService: DataService,
        formBuilder: FormBuilder,
    ) {
        this.platform = this.mapService.platform;
        this.myForm = formBuilder.group({
            'address': formBuilder.group({
                'countryCode': [this.countryCode],
                'state': [this.state],
                'county': [this.county],
                'city': [this.city],
                'district': [this.district],
                'street': [this.street],
                'street2': [this.street2],
                'houseNumber': [this.houseNumber],
                'postalCode': [this.postalCode],
                'isPlace': [this.isPlace],
                'placeName': [this.placeName],
                'value': [this.freeformAddress],
                'lat': [this.lat],
                'lon': [this.lon],
                'label': [this.title],
                'term': [this.selectedAddress],
            }),
        });
    }

    patchAddresses() {
        this.myForm.patchValue({
            'address': {
                'countryCode': this.countryCode,
                'state': this.state,
                'county': this.county,
                'city': this.city,
                'district': this.district,
                'street': this.street,
                'street2': this.street2,
                'houseNumber': this.houseNumber,
                'postalCode': this.postalCode,
                'isPlace': this.isPlace,
                'placeName': this.placeName,
                'lat': this.lat,
                'lon': this.lon,
                'value': this.freeformAddress,
            },
        });
    }

    resetForm() {
        this.freeformAddress = '';
        this.lat = '';
        this.lon = '';
        this.countryCode = '';
        this.state = '';
        this.county = '';
        this.city = '';
        this.district = '';
        this.street = '';
        this.street2 = '';
        this.houseNumber = '';
        this.postalCode = '';
        this.isPlace = false;
        this.placeName = '';
        (<HTMLInputElement>document.getElementById('custom-input')).value = '';
        this.removeDraggableMarker();
        this.patchAddresses();
    }

    getAddress() {
        return this.myForm.value;
    }

    inputFocusOut() {
        if (!this.myForm.value.address.term.timeZone) {
            if (this.ngSelect.itemsList['_filteredItems']) {
                const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
                if (firstItem) {
                    this.ngSelect.select(firstItem);
                }
            }
        }
    }

    inputAddress() {
        this.selectedAddress = '';
        this.myForm.patchValue({
            'address': {
                'term': this.selectedAddress,
            },
        });
        this.ngSelect.filter((<HTMLInputElement>document.getElementById('custom-input')).value);
    }

    onAddressChange() {
        const addressFormValue = this.myForm.value.address.term;
        (<HTMLInputElement>document.getElementById('custom-input')).value = addressFormValue.label;
        if (addressFormValue) {
            this.lat = addressFormValue.position[0];
            this.lon = addressFormValue.position[1];
            this.freeformAddress = addressFormValue.label;
            this.showDraggableMarker(Number(this.lat), Number(this.lon));
            this.setAddress(addressFormValue.address);
            this.addressSelected.emit('true');
        }
    }

    setAddress(address) {
        this.freeformAddress = this.addressService.getAddressLabel(address);
        this.placeName = this.addressService.getAddressPlace(address);
        if (address.countryCode) {
            this.countryCode = address.countryCode;
        }
        if (address.state) {
            this.state = address.state;
        }
        if (address.county) {
            this.county = address.county;
        }
        if (address.city) {
            this.city = address.city;
        }
        if (address.district) {
            this.district = address.district;
        }
        if (address.street) {
            this.street = address.street;
        }
        if (address.street2) {
            this.street2 = address.street2;
        }
        if (address.houseNumber) {
            this.houseNumber = address.houseNumber;
        }
        if (address.postalCode) {
            this.postalCode = address.postalCode;
        }
        if (address.isPlace) {
            this.isPlace = address.isPlace;
        }
        if (address.lat) {
            this.lat = address.lat;
        }
        if (address.lon) {
            this.lon = address.lon;
        }
        this.patchAddresses();
    }

    mapToggled() {
        this.map.getViewPort().resize();
        this.setMapBounds();
    }

    setMapBounds() {
        if (this.bounds) {
            this.map.getViewModel().setLookAtData({ bounds: this.bounds }, true);
        }
    }

    centerToMarker(bounds) {
        const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
        this.map.getViewModel().setLookAtData({ bounds: newBounds }, true);
    }

    showDraggableMarker(lat, lng) {
        const self = this;
        this.draggableMarker.setGeometry({ lat: lat, lng: lng });
        this.draggableMarker.draggable = true;
        this.draggableMarkerGroup.addObjects([this.draggableMarker]);

        const bounds = this.draggableMarkerGroup.getBoundingBox();
        this.centerToMarker(bounds);

        self.map.addEventListener('dragstart', function (ev) {
            const target = ev.target,
                pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                const targetPosition = self.map.geoToScreen(target.getGeometry());
                target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                self.behavior.disable();
            }
        }, false);

        // re-enable the default draggability of the underlying map
        // when dragging has completed
        this.map.addEventListener('dragend', function (ev) {
            const target = ev.target;
            if (target instanceof H.map.Marker) {
                self.behavior.enable();
                self.positionSet = true;
                self.freeformAddress = self.selectedAddress.label;
                self.lat = target.getGeometry()['lat'];
                self.lon = target.getGeometry()['lng'];
            }
        }, false);

        this.map.addEventListener('drag', function (ev) {
            const target = ev.target,
                pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                target.setGeometry(self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
            }
        }, false);
    }

    initMap(lat, lon) {
        const self = this;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 6,
                center: { lat: lat, lng: lon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.rightClickOnMap = function (event) {
            const coord = self.map.screenToGeo(event.viewportX, event.viewportY);
            self.positionSet = true;
            self.lat = coord.lat;
            self.lon = coord.lng;
            self.addressSelected.emit('false');
            self.http.get(`api/unregistered/v1/search/reverse-locations?searchQuery=${self.lat},${self.lon}`).pipe(take(1)).subscribe(location => {
                if (location) {
                    self.addressSelected.emit('true');
                    if (location['data']['addresses']['items'].length) {
                        self.setAddress(location['data']['addresses']['items'][0]['address']);
                    } else {
                        self.freeformAddress = 'Address';
                        self.country = 'GR';
                    }
                    (<HTMLInputElement>document.getElementById('custom-input')).value = self.freeformAddress;
                }
            });
            self.showDraggableMarker(Number(coord.lat), Number(coord.lng));
        };
        this.map.addEventListener('contextmenu', this.rightClickOnMap);
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');
        this.map.addObject(this.draggableMarkerGroup);
    }

    removeDraggableMarker() {
        this.draggableMarkerGroup.removeAll();
    }


    // get addresses suggestions matched to a term and form their labels to show to the user in a dropdown
    getAddresses(term: string = ''): Observable<any[]> {
        if (!term) {
            term = ' ';
        }
        const params = `?searchQuery=${term}&type=0&at=40.643,22.932`;
        return this.http.get('api/unregistered/v1/search/places' + params).pipe(
            map(response => {
                const data = response['items']['items'];
                for (let i = 0; i < data.length; i++) {
                    let addressLabel = '';
                    if (data[i]['address']) {
                        addressLabel = this.addressService.getAddressLabel(data[i]['address']);
                    } else if (data[i]['title'] && data[i]['vicinity']) {
                        addressLabel = data[i]['title'] + ', ' + data[i]['vicinity'].replace('<br/>', ' ');
                    }
                    data[i]['label'] = addressLabel;
                }
                return data;
            })
        );
    }

    ngOnInit() {
        this.addresses = concat(
            of([]), // default items
            this.addressInput.pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => this.addressesLoading = true),
                switchMap(term => this.getAddresses(term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.addressesLoading = false)
                ))
            )
        );

    }

    public ngAfterViewInit() {

        this.initMap(this.greeceLat, this.greeceLon);
    }

    ngOnDestroy() {
        this.map.removeEventListener('contextmenu', this.rightClickOnMap);
    }
}
