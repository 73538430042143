import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MilyService {
    confirmMessageObservable: Observable<any>;
    
    private openMilyListeners = new Subject<any>();
    private closeMilyListeners = new Subject<any>();
    private loadRecurringPointsListeners = new Subject<any>();
    private loadCancelledPointsListeners = new Subject<any>();
    private loadNewStopPointsFromDriversListeners = new Subject<any>();
    private loadNewShipmentsListeners = new Subject<any>();
    private newShipmentsPickupListeners = new Subject<any>();
    private loadPortalPointsListeners = new Subject<any>();
    private uploadPointsListeners = new Subject<any>();
    private uploadingPointsListeners = new Subject<any>();
    private uploadPointsDoneListeners = new Subject<any>();
    private reloadsDetectedListeners = new Subject<any>();
    private stopPointsConditionListeners = new Subject<any>();
    private driversWorkingHoursListeners = new Subject<any>();
    private vehicleCapacityListeners = new Subject<any>();
    private optimizationListeners = new Subject<any>();
    private dispatchListeners = new Subject<any>();
    private completeListeners = new Subject<any>();
    private alertListeners = new Subject<any>();
    private helperListeners = new Subject<any>();
    private helperPrintListeners = new Subject<any>();
    private tourListeners = new Subject<any>();
    private wizardListeners = new Subject<any>();
    private projectProblemReportListeners = new Subject<any>();
    private importFileLineErrorsListeners = new Subject<any>();
    private cancelCollaboratorStopPointsListeners = new Subject<any>();
    private alreadyOptimizingListeners = new Subject<any>();
    private generateConfirmMessageListeners = new Subject<any>();
    private setConfirmMessageTypeListeners = new Subject<any>();

    openMilyListen(): Observable<any> {
        return this.openMilyListeners.asObservable();
    }

    closeMilyListen(): Observable<any> {
        return this.closeMilyListeners.asObservable();
    }

    loadRecurringPointsListen(): Observable<any> {
        return this.loadRecurringPointsListeners.asObservable();
    }

    loadCancelledPointsListen(): Observable<any> {
        return this.loadCancelledPointsListeners.asObservable();
    }

    loadNewStopPointsFromDriversListen(): Observable<any> {
        return this.loadNewStopPointsFromDriversListeners.asObservable();
    }

    loadNewShipmentsListen(): Observable<any> {
        return this.loadNewShipmentsListeners.asObservable();
    }

    newShipmentsPickupListen(): Observable<any> {
        return this.newShipmentsPickupListeners.asObservable();
    }

    loadPortalPointsListen(): Observable<any> {
        return this.loadPortalPointsListeners.asObservable();
    }

    uploadPointsListen(): Observable<any> {
        return this.uploadPointsListeners.asObservable();
    }

    uploadingPointsListen(): Observable<any> {
        return this.uploadingPointsListeners.asObservable();
    }

    uploadPointsDoneListen(): Observable<any> {
        return this.uploadPointsDoneListeners.asObservable();
    }

    reloadsDetectedListen(): Observable<any> {
        return this.reloadsDetectedListeners.asObservable();
    }

    stopPointsConditionListen(): Observable<any> {
        return this.stopPointsConditionListeners.asObservable();
    }

    driversWorkingHoursListen(): Observable<any> {
        return this.driversWorkingHoursListeners.asObservable();
    }

    vehicleCapacityListen(): Observable<any> {
        return this.vehicleCapacityListeners.asObservable();
    }

    optimizationListen(): Observable<any> {
        return this.optimizationListeners.asObservable();
    }

    dispatchListen(): Observable<any> {
        return this.dispatchListeners.asObservable();
    }

    completeListen(): Observable<any> {
        return this.completeListeners.asObservable();
    }

    alertListen(): Observable<any> {
        return this.alertListeners.asObservable();
    }

    helperListen(): Observable<any> {
        return this.helperListeners.asObservable();
    }

    helperPrintListen(): Observable<any> {
        return this.helperPrintListeners.asObservable();
    }

    tourListen(): Observable<any> {
        return this.tourListeners.asObservable();
    }

    wizardListen(): Observable<any> {
        return this.wizardListeners.asObservable();
    }

    projectProblemReportListen(): Observable<any> {
        return this.projectProblemReportListeners.asObservable();
    }

    importFileLineErrorsListen(): Observable<any> {
        return this.importFileLineErrorsListeners.asObservable();
    }

    cancelCollaboratorStopPointsListen(): Observable<any> {
        return this.cancelCollaboratorStopPointsListeners.asObservable();
    }

    alreadyOptimizingListen(): Observable<any> {
        return this.alreadyOptimizingListeners.asObservable();
    }

    generateConfirmMessageListen(): Observable<any> {
        return this.generateConfirmMessageListeners.asObservable();
    }

    setConfirmMessageTypeListen(): Observable<any> {
        return this.setConfirmMessageTypeListeners.asObservable();
    }

    openMily() {
        this.openMilyListeners.next(1);
    }

    closeMily() {
        this.closeMilyListeners.next(1);
    }

    loadRecurringPoints() {
        this.loadRecurringPointsListeners.next(1);
    }

    loadCancelledPoints() {
        this.loadCancelledPointsListeners.next(1);
    }

    loadNewStopPointsFromDrivers() {
        this.loadNewStopPointsFromDriversListeners.next(1);
    }

    loadNewShipments() {
        this.loadNewShipmentsListeners.next(1);
    }

    newShipmentsPickup() {
        this.newShipmentsPickupListeners.next(1);
    }

    loadPortalPoints(stopsCount, newStopsCount) {
        this.loadPortalPointsListeners.next({
            stopsCount: stopsCount,
            newStopsCount: newStopsCount
        });
    }

    uploadPoints() {
        this.uploadPointsListeners.next(1);
    }

    uploadingPoints() {
        this.uploadingPointsListeners.next(1);
    }

    uploadPointsDone() {
        this.uploadPointsDoneListeners.next(1);
    }

    reloadsDetected(routes) {
        this.reloadsDetectedListeners.next(routes);
    }

    driversWorkingHours(routes) {
        this.driversWorkingHoursListeners.next(routes);
    }

    vehicleCapacity(routes) {
        this.vehicleCapacityListeners.next(routes);
    }

    stopPointsCondition(limitedTimeWindowStopsCount, droppedStopsCount) {
        this.stopPointsConditionListeners.next({
            limitedTimeWindowStopsCount: limitedTimeWindowStopsCount,
            droppedStopsCount: droppedStopsCount
        });
    }

    optimization(optimizingOption) {
        this.optimizationListeners.next(optimizingOption);
    }

    dispatch() {
        this.dispatchListeners.next(1);
    }

    complete() {
        this.completeListeners.next(1);
    }

    alert(text) {
        this.alertListeners.next(text);
    }

    helperPrint() {
        this.helperPrintListeners.next(1);
    }

    helper(type) {
        this.helperListeners.next(type);
    }

    tour() {
        this.tourListeners.next(1);
    }

    wizard() {
        this.wizardListeners.next(1);
    }

    projectProblemReport() {
        this.projectProblemReportListeners.next(1);
    }

    importFileLineErrors(errors) {
        this.importFileLineErrorsListeners.next(errors);
    }

    cancelCollaboratorStopPoints(stopPointNames) {
        this.cancelCollaboratorStopPointsListeners.next(stopPointNames);
    }

    alreadyOptimizing() {
        this.alreadyOptimizingListeners.next(1);
    }

    generateConfirmMessage(obs) {
        this.generateConfirmMessageListeners.next(obs);
    }

    setConfirmMessageType(type) {
        this.setConfirmMessageTypeListeners.next(type);
    }

}
