import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment-timezone';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-wizard-route-settings',
    templateUrl: './wizard-route-settings.component.html',
    styleUrls: ['./wizard-route-settings.component.scss']
})
export class WizardRouteSettingsComponent implements OnInit {

    @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;

    listen = [];

    myForm: FormGroup;

    defaultDepartureTime = '';
    defaultDepartureDatetime = '';
    defaultDepartureTimezone = '';

    // lateStops = false;
    reloadSystemSwitch = true;

    // durationUnformatted = '04:00';
    // durationSeconds = [120];
    // duration = '';
    // durationOptions;

    // defaultLoad = null;

    reloadDurationAtIntermediateDepot = '';
    reloadTimeUnformatted = '00:30';
    reloadTimeMinutes = [30];
    reloadTimeOptions;

    minuteLabel = '';

    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private dataService: DataService,
        private settingsService: SettingsService,
        public translate: TranslateService,
        public globals: Globals,
    ) {
        this.initializeSlider();
        this.myForm = formBuilder.group({
            'company': formBuilder.group({
                // 'id': [this.companyId],
            }),
            'companySettings': formBuilder.group({
                'default_departure_time': [this.defaultDepartureTime],
                'default_departure_datetime': [this.defaultDepartureDatetime],
                'default_departure_datetime_timezone': [this.defaultDepartureTimezone],
                'reload_system_switch': [this.reloadSystemSwitch],
                'reload_duration_at_intermediate_depot': [this.reloadDurationAtIntermediateDepot],
                'reloadTimeMinutes': [this.reloadTimeMinutes],
                // 'allow_late_stops': [this.lateStops],
            }),
        });
    }

    patchFormForLoad() {
        this.myForm.patchValue({
            'company': {
            },
            'companySettings': {
                'default_departure_time': this.defaultDepartureTime,
                'default_departure_datetime': this.defaultDepartureDatetime,
                'default_departure_datetime_timezone': this.defaultDepartureTimezone,
                'reload_system_switch': this.reloadSystemSwitch,
                'reload_duration_at_intermediate_depot': this.reloadDurationAtIntermediateDepot,
                'reloadTimeMinutes': this.reloadTimeMinutes,
                // 'allow_late_stops': this.lateStops,
            },
        });
        M.updateTextFields();
    }

    patchForm() {
        this.myForm.patchValue({
            'companySettings': {
                'default_departure_time': this.defaultDepartureTime,
                'default_departure_datetime': this.defaultDepartureDatetime,
                'default_departure_datetime_timezone': this.defaultDepartureTimezone,
                'reload_duration_at_intermediate_depot': this.reloadDurationAtIntermediateDepot,
            },
        });
        M.updateTextFields();
    }

    loadData() {
        const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        this.http.get(companyUrl).pipe(take(1)).subscribe(response => {
            const data = response['items'];
            // this.lateStops = data.companySettings.allow_late_stops;
            // this.reload = data.companySettings.reload;

            if (data.companySettings.default_departure_datetime) {
                this.defaultDepartureDatetime = data.companySettings.default_departure_datetime;
                this.defaultDepartureTimezone = data.companySettings.default_departure_datetime_timezone;
                this.defaultDepartureTime = moment(this.defaultDepartureDatetime).format('HH:mm');
            }
            if (data.companySettings.reload_duration_at_intermediate_depot) {
                this.reloadDurationAtIntermediateDepot = data.companySettings.reload_duration_at_intermediate_depot;
                this.reloadTimeUnformatted = moment.duration(this.reloadDurationAtIntermediateDepot).asMinutes() + this.minuteLabel;
                this.reloadTimeMinutes = [moment.duration(this.reloadDurationAtIntermediateDepot).asMinutes()];
            }

            this.patchFormForLoad();
        });

    }

    initializeSlider() {
        this.reloadTimeOptions = {
            step: 5,
            start: [30],
            connect: 'lower',
            range: {
                min: 15,
                max: 90
            },
        };
    }

    showReloadTimeMinutes() {
        this.reloadTimeUnformatted = this.myForm.value.companySettings.reloadTimeMinutes + this.minuteLabel;
    }

    convertToHour(value) {
        return Math.floor(value / 60);
    }

    convertToMinute(value, hour) {
        return value - hour * 60;
    }

    formatHoursAndMinutes(hours, minutes) {
        if (hours.toString().length === 1) { hours = '0' + hours; }
        if (minutes.toString().length === 1) { minutes = '0' + minutes; }
        return hours + ':' + minutes;
    }

    scrollFix() {
        // window.scrollTo(0, 0);
    }

    submitWizardForm() {
        const companyFormValues = this.myForm.value.company;
        const companySettingsFormValues = this.myForm.value.companySettings;

        let reloadTimeMinutes;
        if (Array.isArray(companySettingsFormValues.reloadTimeMinutes)) {
            reloadTimeMinutes = companySettingsFormValues.reloadTimeMinutes[0];
        } else {
            reloadTimeMinutes = companySettingsFormValues.reloadTimeMinutes;
        }
        this.reloadDurationAtIntermediateDepot = moment.duration(reloadTimeMinutes, 'minutes').toISOString();

        const departureTimeElement = document.querySelector('#departure-time-settings-form');
        const departureTime = M.Timepicker.getInstance(departureTimeElement).time;
        const today = moment().format('YYYY-MM-DD');
        if (moment(departureTime, 'HH:mm', true).isValid()) {
            const departureDatetimeUnformatted = today + ' ' + departureTime;
            this.defaultDepartureDatetime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format();
            this.defaultDepartureTime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format('HH:mm');
            this.defaultDepartureTimezone = moment.tz.guess();
        }
        if (this.defaultDepartureDatetime) { this.globals.defaultDepartureDateTime = this.defaultDepartureDatetime; }


        this.patchForm();
        const myObserver = {
            next: (response) => { },
            error: (error) => {
                console.error(error);
            },
            complete: () => { },
        };

        const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        this.http.put(companyUrl, this.myForm.value).pipe(take(1)).subscribe(myObserver);
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.MINUTE_LABEL').subscribe((res: string) => { this.minuteLabel = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.loadData();

        const departureTimeElement = document.querySelectorAll('.timepicker');
        const timeInstances = M.Timepicker.init(departureTimeElement, {
            twelveHour: false
        });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
