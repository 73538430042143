import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Globals } from '@app/services/globals';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';

declare var H: any;

@Component({
  selector: 'app-wizard-depots',
  templateUrl: './wizard-depots.component.html',
  styleUrls: ['./wizard-depots.component.scss']
})
export class WizardDepotsComponent implements OnInit, AfterViewInit {

  listen = [];

  // form
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  myForm: FormGroup;
  depotId = null;
  title = '';
  addressType = 7;
  freeformAddress = '';
  country = 'GR';
  lat = '';
  lon = '';
  isClickedOnce = false;

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();
  selectedAddress: any = <any>[];

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';

  // grid
  // @ViewChild('depotsGrid', { static: false }) depotsGrid: AgGridAngular;
  gridApi;
  titleHeader;
  addressHeader;
  phoneHeader;
  gridColumnApi;
  domLayout = 'autoHeight';
  depotsDataArray = [];
  depotsCountWithDemo = 0;
  rowData: any;
  columnDefs;

  // map
  @ViewChild('map', { static: false }) public mapElement: ElementRef;
  private platform: any;
  map;
  ui;
  behavior;
  rightClickOnMap;
  draggableMarkerGroup = new H.map.Group();
  draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
  positionSet = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    formBuilder: FormBuilder,
    private dataService: DataService,
    public globals: Globals,
    public gridsService: GridsService,
    private addressService: AddressService,
  ) {
    this.platform = this.globals.platform;
    this.myForm = formBuilder.group({
      'companyDepot': formBuilder.group({
        'id': [this.depotId],
        'address': formBuilder.group({
          'countryCode': [this.countryCode],
          'state': [this.state],
          'county': [this.county],
          'city': [this.city],
          'district': [this.district],
          'street': [this.street],
          'street2': [this.street2],
          'houseNumber': [this.houseNumber],
          'postalCode': [this.postalCode],
          'isPlace': [this.isPlace],
          'placeName': [this.placeName],
          'value': [this.freeformAddress],
          'lat': [this.lat],
          'lon': [this.lon],
          'label': [this.title],
          'term': [this.selectedAddress],
        }),
      }),
    });
  }

  cancelRenderer(params) {
    return '<span class="grey-letters"><i class="fas fa-times"></i></span>';
  }

  updateGrid() {
    this.setDepotsGridData();
  }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    this.patchAddresses();
  }

  setDepotsGridData() {
    this.http.get('api/v1/company/depots').pipe(take(1)).subscribe(response => {
      const self = this;
      let depot, gridObject = {};
      self.depotsDataArray = [];
      this.depotsCountWithDemo = response['items'].length;
      response['items'].forEach(depotData => {
        depot = depotData.companyDepot;
        this.depotId = depot.id;
        if (depot.address.label) {
          if (!depot.address.label.includes('demo0')) {
            gridObject = {
              title: depot.address.label,
              address: this.addressService.getAddressLabel(depot.address),
              data: depot
            };
            self.depotsDataArray.push(gridObject);
            this.loadDepotData(depot);
          }
        } else {
          gridObject = {
            title: '',
            address: this.addressService.getAddressLabel(depot.address),
            data: depot
          };
          self.depotsDataArray.push(gridObject);
          this.loadDepotData(depot);
        }
      });
      if (!self.depotsDataArray.length) {
        const noData = { noDataText: 'No data' }
        self.depotsDataArray.push(noData);
      }
      self.rowData = of(self.depotsDataArray);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  cellClicked(event) {
    if (event.column.colId.includes('delete')) {
      this.deleteDepot(event.data.data.id);
    } else {
      if (!event.data.noDataText) {
        this.loadDepotData(event.data.data);
      }
    }
  }

  deleteDepot(id) {
    const myObserver = {
      next: (response) => {
        this.updateGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    this.http.delete('api/v1/company/depots/' + id).pipe(take(1)).subscribe(myObserver);
  }

  inputFocusOut() {
    if (!this.myForm.value.companyDepot.address.term.timeZone) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  inputAddress() {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'companyDepot': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });
    this.ngSelect.filter((<HTMLInputElement>document.getElementById('depot-custom-input')).value);
  }

  onAddressChange() {
    const addressFormValue = this.myForm.value.companyDepot.address.term;
    (<HTMLInputElement>document.getElementById('depot-custom-input')).value = addressFormValue.label;
    if (addressFormValue) {
      this.lat = addressFormValue.position[0];
      this.lon = addressFormValue.position[1];
      this.freeformAddress = addressFormValue.label;
      this.showDraggableMarker(Number(this.lat), Number(this.lon));
      this.setAddress(addressFormValue.address);
    }
  }

  patchAddresses() {
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'lat': this.lat,
          'lon': this.lon,
          'value': this.freeformAddress,
        },
      },
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'companyDepot': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('depot-custom-input')).value = this.freeformAddress.valueOf();
    }
  }

  setForm() {
    this.myForm.setValue({
      'companyDepot': {
        'id': this.depotId,
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'label': this.title,
          'term': this.selectedAddress,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('depot-custom-input')).value = this.freeformAddress.valueOf();
    }
  }

  loadDepotData(data) {
    this.positionSet = true;
    this.depotId = data.id;
    this.title = data.address.label;
    this.freeformAddress = this.addressService.getAddressLabel(data.address);
    this.lat = data.address.lat;
    this.lon = data.address.lon;
    this.showDraggableMarker(this.lat, this.lon);
    this.setForm();
  }

  emptyDepotsData() {
    this.positionSet = false;
    // this.depotId = null;
    this.title = '';
    this.selectedAddress = null;
    this.freeformAddress = '';
    this.lat = '';
    this.lon = '';
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    (<HTMLInputElement>document.getElementById('depot-custom-input')).value = '';
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    this.removeDraggableMarker();
  }

  addDepot() {
    // this.submitWizardForm();
    // this.emptyDepotsData();
  }

  checkIfDepotExists() {
    if (this.depotsCountWithDemo || this.positionSet || this.myForm.value.companyDepot.address.term.position) {
      return true;
    } else {
      return false;
    }
  }

  submitWizardForm() {
    const formValue = this.myForm.value.companyDepot;
    const addressFormValue = this.myForm.value.companyDepot.address.term;
    this.isClickedOnce = true;

    if (!this.positionSet) {
      if (addressFormValue) {
        if (addressFormValue.customer) {
          this.lat = addressFormValue.customer.address.lat;
          this.lon = addressFormValue.customer.address.lon;
          this.freeformAddress = this.addressService.getAddressLabel(addressFormValue.customer.address);
        } else if (addressFormValue.position) {
          this.lat = addressFormValue.position[0];
          this.lon = addressFormValue.position[1];
          this.freeformAddress = addressFormValue.label;
        }
      }
    }

    this.patchForm();
    const myObserver = {
      next: (response) => {
        this.globals.getDepots();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isClickedOnce = false;
      },
    };

    const depotsUrl = 'api/v1/company/depots';
    const editUrl = depotsUrl + '/' + this.depotId;
    if (this.myForm.value.companyDepot.address.value) {
      if (this.depotId) {
        this.http.put(editUrl, this.myForm.value).pipe(take(1)).subscribe(myObserver);
      } else {
        this.http.post(depotsUrl, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
      }
    }
  }

  centerToMarker(bounds) {
    const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
    this.map.getViewModel().setLookAtData({ bounds: newBounds }, true);
  }

  showDraggableMarker(lat, lng) {
    const self = this;
    this.draggableMarker.setGeometry({ lat: lat, lng: lng });
    this.draggableMarker.draggable = true;
    this.draggableMarkerGroup.addObjects([this.draggableMarker]);

    const bounds = this.draggableMarkerGroup.getBoundingBox();
    this.centerToMarker(bounds);

    // disable the default draggability of the underlying map
    // when starting to drag a marker object:
    // this.map.addEventListener('dragstart', function (ev) {
    //     const target = ev.target;
    //     if (target instanceof H.map.Marker) {
    //         self.behavior.disable();
    //     }
    // }, false);
    self.map.addEventListener('dragstart', function (ev) {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        const targetPosition = self.map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        self.behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    this.map.addEventListener('dragend', function (ev) {
      const target = ev.target;
      if (target instanceof H.map.Marker) {
        self.behavior.enable();
        self.positionSet = true;
        self.freeformAddress = self.selectedAddress.label;
        self.lat = target.getGeometry()['lat'];
        self.lon = target.getGeometry()['lng'];
      }
    }, false);

    // Listen to the drag event and move the position of the marker
    // as necessary
    // this.map.addEventListener('drag', function (ev) {
    //     const target = ev.target,
    //         pointer = ev.currentPointer;
    //     if (target instanceof H.map.Marker) {
    //         target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY));
    //     }
    // }, false);
    this.map.addEventListener('drag', function (ev) {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
      }
    }, false);
  }

  removeDraggableMarker() {
    if (this.draggableMarkerGroup.contains(this.draggableMarker)) {
      this.draggableMarkerGroup.removeObject(this.draggableMarker);
    }
  }

  initMap(depotLat, depotLon) {
    const self = this;
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 6,
        center: { lat: depotLat, lng: depotLon }
      }
    );
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    const mapEvents = new H.mapevents.MapEvents(this.map);
    this.rightClickOnMap = function (event) {
      const coord = self.map.screenToGeo(event.viewportX, event.viewportY);
      self.positionSet = true;
      self.lat = coord.lat;
      self.lon = coord.lng;
      self.isClickedOnce = true;
      self.http.get(`api/v1/search/reverse-locations?searchQuery=${self.lat},${self.lon}`).pipe(take(1)).subscribe(location => {
        self.isClickedOnce = false;
        if (location['data']['addresses']['items'].length) {
          self.setAddress(location['data']['addresses']['items'][0]['address']);
        } else {
          self.freeformAddress = 'Address';
          self.country = 'GR';
        }
        (<HTMLInputElement>document.getElementById('depot-custom-input')).value = self.freeformAddress;
      });
      self.showDraggableMarker(Number(coord.lat), Number(coord.lng));
    };
    this.map.addEventListener('contextmenu', this.rightClickOnMap);
    // Instantiate the default behavior, providing the mapEvents object:
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const mapSettings = this.ui.getControl('mapsettings');
    mapSettings.setAlignment('top-left');
    this.map.addObject(this.draggableMarkerGroup);
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.titleHeader = res; }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressHeader = res; }));
    this.listen.push(this.translate.get('GENERIC.PHONE').subscribe((res: string) => { this.phoneHeader = res; }));
    this.columnDefs = [
      {
        headerName: this.titleHeader,
        field: 'title',
        sortable: true,
        width: this.gridsService.widthCalculatorContainerId(40, 'depots-grid')
      },
      {
        headerName: this.addressHeader,
        field: 'address',
        sortable: true,
        width: this.gridsService.widthCalculatorContainerId(50, 'depots-grid')
      },
      // {
      //   headerName: '',
      //   field: 'delete',
      //   width: this.gridsService.widthCalculatorContainerId(10, 'depots-grid'),
      //   cellRenderer: this.cancelRenderer
      // },
    ];
    this.setDepotsGridData();
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );
  }

  public ngAfterViewInit() {
    const self = this;
    self.initMap(38.65238769104773, 24.0691524677357);
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
