import { Component, Input, OnInit } from '@angular/core';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';

@Component({
  selector: 'app-multiple-shipments-list-box',
  templateUrl: './multiple-shipments-list-box.component.html',
  styleUrls: ['./multiple-shipments-list-box.component.scss']
})

export class MultipleShipmentsListBoxComponent implements OnInit {
  @Input() shipments: Array<string>;

  constructor(
    public viewProjectProblemService: ViewProjectProblemService
  ) { }

  handleShipmentClick(shipment) {
    this.viewProjectProblemService.closeStopModalLists();
    this.viewProjectProblemService.openStopModal(shipment.stop_point_id, null);
  }

  ngOnInit() {
  }

}
