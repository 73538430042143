import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { AddButtonBaseConfig as abbc } from '@app/model/buttons-model';
import { SettingsBasicServicesViewModelService } from './settings-basic-services-viewmodel.service';
import { SettingsAddonServicesViewModelService } from './settings-addon-services-viewmodel.service';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { BasicServiceModalComponent } from './new-basic-service-modal/new-basic-service-modal.component';
import { LmNotificationService } from '@app/core/services/notification.service';
import { AddonServiceModalComponent } from './new-addon-service-modal/new-addon-service-modal.component';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { tap } from 'rxjs';


@Component({
    selector: 'settings-services',
    template: `
        <div class="grid">
            <div class="col-6 lm-pdr30">
                <lm-table-list [cols]="basicCols" [VM]="basicVM" [headerBtn]="basicHdrBtn" [title]="basicTitle"></lm-table-list>
            </div>
            <div class="col-6 lm-pdl30">
                <lm-table-list [cols]="addonCols" [VM]="addonVM" [headerBtn]="addonHdrBtn" [title]="addonTitle"></lm-table-list>
            </div>
        </div>
    `,
    providers: [SettingsBasicServicesViewModelService, SettingsAddonServicesViewModelService, LmModelProxyService]
})
export class SettingsServicesComponent implements OnInit, OnDestroy {
    @Input() basicTitle: string;
    @Input() addonTitle: string;
    showBasicServiceModal = BasicServiceModalComponent;
    showAddonServiceModal = AddonServiceModalComponent;
    basicCols: LmTableBaseColumnDefs[];
    basicHdrBtn: LmButtonConfig;

    addonCols: LmTableBaseColumnDefs[];
    addonHdrBtn: LmButtonConfig;
    basicServicesTranslations;
    addonServicesTranslations;
    listen = [];

    constructor(
        public basicVM: SettingsBasicServicesViewModelService,
        public addonVM: SettingsAddonServicesViewModelService,
        private notificationSvc: LmNotificationService, 
        private _injector: Injector,
        public translate: TranslateService,
        public globals: Globals
    ) {
        this.basicHdrBtn = {...abbc, command: this.showBasicModal};
        this.addonHdrBtn = {...abbc, command: this.handleAddonModal};
        this.translate.get('BASIC_SERVICES').pipe(
            tap(response => {
                this.basicServicesTranslations = response; 
                this.basicCols = [{header: this.basicServicesTranslations['SERVICE'], field: 'nameAndType'}];
                this.basicVM.modalConfig.data['translations'] = response;
            })
        ).subscribe();
        this.translate.get('ADDON_SERVICES').pipe(
            tap(response => {
                this.addonServicesTranslations = response; 
                this.addonCols = [
                    { header: this.addonServicesTranslations['SERVICE'], field: 'nameAndDescription' }, 
                    { header: this.addonServicesTranslations['CHARGE'], field: 'valueCaption', plus: true, currency: true }
                ];
                this.addonVM.modalConfig.data['translations'] = response;
            })
        ).subscribe();
        this.translate.get('WARNINGS').pipe(
            tap(response => {
                this.basicVM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
                this.addonVM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
            })
        ).subscribe();
    }

    private showBasicModal = () => {
        this.notificationSvc.showDialog<boolean>({ 
            componentType: this.showBasicServiceModal,   
            injector: this._injector, 
            data: {
                VM: this.basicVM, 
                globals: this.globals, 
                item: {...this.basicVM.newItem()},
                translations: this.basicServicesTranslations,
            } 
        })
        .subscribe();
    }
    
    private handleAddonModal = () => {
        this.notificationSvc.showDialog<boolean>({ 
            componentType: this.showAddonServiceModal,   
            injector: this._injector, 
            data: {
                VM: this.addonVM, 
                globals: this.globals, 
                item: {...this.addonVM.newItem()},
                translations: this.addonServicesTranslations,
            } 
        })
        .subscribe();
    }

    ngOnInit() {}
    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
