// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host ::ng-deep .p-toast-icon-close{position: absolute;left: auto; right: 10px; bottom: auto; top: 3px;color: #999;}
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvYXN0LmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0lBQ0ksb0NBQW9DLGtCQUFrQixDQUFDLFVBQVUsRUFBRSxXQUFXLEVBQUUsWUFBWSxFQUFFLFFBQVEsQ0FBQyxXQUFXLENBQUMiLCJmaWxlIjoidG9hc3QuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgOmhvc3QgOjpuZy1kZWVwIC5wLXRvYXN0LWljb24tY2xvc2V7cG9zaXRpb246IGFic29sdXRlO2xlZnQ6IGF1dG87IHJpZ2h0OiAxMHB4OyBib3R0b206IGF1dG87IHRvcDogM3B4O2NvbG9yOiAjOTk5O31cbiAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/containers/toast/toast.component.ts"],"names":[],"mappings":";IACI,oCAAoC,kBAAkB,CAAC,UAAU,EAAE,WAAW,EAAE,YAAY,EAAE,QAAQ,CAAC,WAAW,CAAC;;AAEvH,geAAge","sourcesContent":["\n    :host ::ng-deep .p-toast-icon-close{position: absolute;left: auto; right: 10px; bottom: auto; top: 3px;color: #999;}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
