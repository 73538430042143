import { DepotUtils } from '@app/utils/depot-utils';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment-timezone';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { Router } from '@angular/router';
import { CustomerCollaboratorsModalComponent } from '@app/modals/customer-collaborators-modal/customer-collaborators-modal.component';
import { CustomerPaymentsGridComponent } from '@app/grids/customer-payments-grid/customer-payments-grid.component';
import { StopsCustomDateModalComponent } from '@app/modals/stops-custom-date-modal/stops-custom-date-modal.component';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-customer-collaborators-grid',
    templateUrl: './customer-collaborators-grid.component.html',
    styleUrls: ['./../grids.scss', './customer-collaborators-grid.component.scss']
})
export class CustomerCollaboratorsGridComponent implements OnInit, OnDestroy {
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('openChartButton', { static: false }) openChartButton;
    @ViewChild('closeChartButton', { static: false }) closeChartButton;
    @ViewChild('openPaymentsButton', { static: false }) openPaymentsButton;
    @ViewChild('openInvoicesButton', { static: false }) openInvoicesButton;
    @ViewChild('invoicesGrid', { static: false }) invoicesGrid;
    @ViewChild('paymentsGrid', { static: false }) paymentsGrid;

    @ViewChild(CustomerCollaboratorsModalComponent, { static: false }) customerCollaboratorsModalComponent: CustomerCollaboratorsModalComponent;
    @ViewChild(CustomerPaymentsGridComponent, { static: false }) customerPaymentsGridComponent: CustomerPaymentsGridComponent;
    @ViewChild(StopsCustomDateModalComponent, { static: false }) stopsCustomDateModalComponent: StopsCustomDateModalComponent;

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];
    columnDefs;
    rowModelType = 'serverSide';
    cacheBlockSize = 50;
    gridApi;
    gridColumnApi;
    rowData = [];

    pagesCount = 0;
    customerCollaboratorsDataArray = [];
    collaboratorsCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    nameTitle;
    telephoneTitle;
    addressTitle;
    taxDetailsTitle;
    baseAmountTitle;
    toGetPaidTitle;
    toPayTitle;
    currency;
    mostVisitedLabel;
    lastWeekLabel;
    lastMonthLabel;
    lastThreeMonthsLabel;
    lastSixMonthsLabel;
    lastYearLabel;
    allDepotsLabel;
    actionsTitle;
    showSortDropdown = false;
    selectedOrder = '';

    selectedDepot;
    depots = [];
    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;

    noNameLabel = '';
    listen = [];

    isInvoicesGridOpen = false;
    isPaymentsGridOpen = false;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private addressService: AddressService,
        private dateTimeCalculatorService: DateTimeCalculatorService,
        private router: Router,
        private depotUtils: DepotUtils
    ) {
        this.listen.push(this.modalGridService.listenUpdateCustomerCollaborators().subscribe((response) => {
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }));
        // get custom date range from modal
        this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
            this.customerPaymentsGridComponent.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
            const customDateString = dates.start + ' - ' + dates.end;
            this.customerPaymentsGridComponent.updateGrid();
            this.selectedPeriod = {
                label: customDateString,
                value: 'custom'
            };
        }));
    }

    newButtonClicked() {
        this.modalService.openCustomerCollaboratorsModal(null);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getDataForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'most-visited':
                data = this.mostVisited;
                break;
        }
        return data;
    }

    onDepotChanged() {
        this.chartPeriodChange();
        this.pagesCount = 0;
        this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
        this.openChartButton.nativeElement.classList.add('active');
        this.closeChartButton.nativeElement.classList.remove('active');
        this.openPaymentsButton.nativeElement.classList.remove('active');
        this.openInvoicesButton.nativeElement.classList.remove('active');

        this.isInvoicesGridOpen = false;
        this.isPaymentsGridOpen = false;
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
        this.openChartButton.nativeElement.classList.remove('active');
        this.openPaymentsButton.nativeElement.classList.remove('active');
        this.openInvoicesButton.nativeElement.classList.remove('active');
        this.closeChartButton.nativeElement.classList.add('active');
        this.isInvoicesGridOpen = false;
        this.isPaymentsGridOpen = false;
    }


    openPaymentsGrid() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
        this.openChartButton.nativeElement.classList.remove('active');
        this.openPaymentsButton.nativeElement.classList.add('active');
        this.openInvoicesButton.nativeElement.classList.remove('active');
        this.closeChartButton.nativeElement.classList.remove('active');
        this.isInvoicesGridOpen = false;
        this.isPaymentsGridOpen = true;
    }

    openInvoicesGrid() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
        this.openChartButton.nativeElement.classList.remove('active');
        this.openPaymentsButton.nativeElement.classList.remove('active');
        this.openInvoicesButton.nativeElement.classList.add('active');
        this.closeChartButton.nativeElement.classList.remove('active');
        this.isInvoicesGridOpen = true;
        this.isPaymentsGridOpen = false;
    }

    openXlsPaymentsAction() {
        this.customerPaymentsGridComponent.openXlsAction();
    }

    emptyChartArrays() {
        this.customerNames = [];
        this.customerShortNames = [];
        this.mostVisited = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.customerShortNames = [];
        for (let i = 0; i < 10; i++) {
            this.customerShortNames.push('');
        }
        this.mostVisited = [25, 24, 20, 16, 16, 15, 12, 10, 8, 8];
        this.chartDisabled = true;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&ids=';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            params += `&depotId=${this.selectedDepot.companyDepot.id}`
        }
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        setTimeout(() => {
            this.dataLoading = false;
            this.displayDummyChartValues();
            this.initChart();
        }, 200);
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();

        if (this.selectedPeriod.value === 'custom') {
            this.stopsCustomDateModalComponent.openModal();
        } else if (this.selectedPeriod.value === 1) {
            this.customerPaymentsGridComponent.selectedDatesQuery = '';
            this.customerPaymentsGridComponent.updateGrid();
        } else {
            const startDate = moment().subtract(this.selectedPeriod.value, 'days').format('YYYY-MM-DD');
            const endDate = moment().format('YYYY-MM-DD');
            this.customerPaymentsGridComponent.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + moment.tz.guess();
            this.customerPaymentsGridComponent.updateGrid();
        }
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('collaborators-chart');
        const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }
        // const chartOptions:ChartOptions = this.chartService.chartOptions;
        const customerNames = this.customerNames;
        // chartOptions['tooltips'] = {
        //     callbacks: {
        //         title: function (tooltipItem, tooltipData) {
        //             return customerNames[tooltipItem[0].index];
        //         }
        //     }
        // };
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.customerShortNames,
                    datasets: [{
                        barPercentage: 0.9,
                        barThickness: 'flex',
                        maxBarThickness: 150,
                        minBarLength: 2,
                        data: data,
                        backgroundColor: (() => {
                            const backgroundColours = [];
                            for (let i = 0; i < data.length; ++i) {
                                backgroundColours.push(this.chartService.chartColours[i % this.chartService.chartColours.length]);
                            }
                            return backgroundColours;
                        })()
                    }]
                }
                // ,
                // options: this.chartService.chartOptions
                // options: this.chartService.chartOptions
            });
        }
    }

    // get request
    getCustomerCollaboratorsGridData(pageSize) {
        let url = 'api/v1/company-collaborators';
        url += '?partnerType=' + this.globals.companyModes['COLLABORATOR'];
        url += '&pageSize=' + pageSize;
        url += '&page=' + this.pagesCount;
        url += '&searchQuery=' + this.searchString;

        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            url += '&depotId=' + String(this.selectedDepot.companyDepot.id);
        }

        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setCustomerCollaboratorsGridData(dataArray), lastRow);

        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setCustomerCollaboratorsGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getCustomerCollaboratorsGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
            this.collaboratorsCount = response['itemsMeta'].totalCount;
            this.customerCollaboratorsDataArray = response['items'];
            this.displayDataToGrid(params, this.customerCollaboratorsDataArray, response['itemsMeta']['pagesCount']);
            this.gridApi.hideOverlay();
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }

    createServerSideDatasource() {
        return {
            getRows: (params) => {
                this.gridApi.showLoadingOverlay();
                this.loadSinglePage(params);
            },
        };
    }

    setCustomerCollaboratorsGridData(collaborators) {
        this.customerCollaboratorsDataArray = []; // empty collaborators data array first
        let gridObject = {};
        if (collaborators.length) {
            collaborators.forEach(collaborator => {
                let telephoneValue;
                let tinValue;
                let taxOfficeValue;
                let addressValue;
                let baseAmountValue;
                let toGetPaidValue;
                let toPayValue;

                collaborator.companyCollaborator.collaborator.collaboratorData.telephone ? telephoneValue = collaborator.companyCollaborator.collaborator.collaboratorData.telephone[0].telephone_number : telephoneValue = '-';
                collaborator.companyCollaborator.collaborator.collaboratorData.tin ? tinValue = collaborator.companyCollaborator.collaborator.collaboratorData.tin : tinValue = '-';
                collaborator.companyCollaborator.collaborator.collaboratorData.tax_office ? taxOfficeValue = collaborator.companyCollaborator.collaborator.collaboratorData.tax_office : taxOfficeValue = '-';
                this.addressService.getAddressLabel(collaborator.companyCollaborator.collaborator.address) ? addressValue = this.addressService.getAddressLabel(collaborator.companyCollaborator.collaborator.address) : addressValue = '-';
                // collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_price ? baseAmountValue = collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_price + this.currency : baseAmountValue = '-';
                collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_to_get_paid_amount ? toGetPaidValue = collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_to_get_paid_amount + this.currency : toGetPaidValue = '-';
                collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_to_pay_amount ? toPayValue = collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_to_pay_amount + this.currency : toPayValue = '-';

                gridObject = {
                    id: collaborator.companyCollaborator.collaborator.collaboratorData.id,
                    details: {
                        name: collaborator.companyCollaborator.collaborator.collaboratorData.collaborator_name,
                        phone: telephoneValue,
                    },
                    address: addressValue,
                    tax_details: {
                        tin: tinValue,
                        tax_office: taxOfficeValue,
                    },
                    // base_amount: baseAmountValue,
                    to_get_paid: toGetPaidValue,
                    to_pay: toPayValue,
                    objData: collaborator
                };
                this.customerCollaboratorsDataArray.push(gridObject);
            });
        } else {
            const noData = { noDataText: 'No data' }
            this.customerCollaboratorsDataArray.push(noData);
        }

        return this.customerCollaboratorsDataArray;
    }

    // custom cell renderer for tax details
    taxDetailsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().tin) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().tin + '">' + params.getValue().tin + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-building grey-let ters"></i> ' + params.getValue().tax_office + '</div>';
            }
        }
        return columnObject;
    }

    // Go to collaborators view on row clicked
    rowClicked(event) {
        const target = event.event.target.closest('div');
        if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
            this.router.navigateByUrl('/customerCollaborators/collaboratorView/' + event.data.id);
        }
    }

    onFirstDataRendered(params) { }

    createdDateRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.setServerSideDatasource(this.createServerSideDatasource());
    }

    getTranslations() {
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.NAME').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.TELEPHONE').subscribe((res: string) => { this.telephoneTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.TAX_DETAILS').subscribe((res: string) => { this.taxDetailsTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.BASE_AMOUNT').subscribe((res: string) => { this.baseAmountTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.TO_GET_PAID').subscribe((res: string) => { this.toGetPaidTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.TO_PAY').subscribe((res: string) => { this.toPayTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.listen.push(this.translate.get('GENERIC.MOST_VISITED').subscribe((res: string) => { this.mostVisitedLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_THREE_MONTHS').subscribe((res: string) => { this.lastThreeMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_SIX_MONTHS').subscribe((res: string) => { this.lastSixMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_YEAR').subscribe((res: string) => { this.lastYearLabel = res; }));
        this.listen.push(this.translate.get('GENERIC').subscribe((res: string) => {
            this.periods = this.dateTimeCalculatorService.getDateSelectPeriods(res);
            this.selectedPeriod = this.periods[0];
            this.getChartData();
        }));

        // add all depots option
        this.depots = this.depotUtils.getDepotsWithAllDepotsOption();
        this.selectedDepot = this.depots[0];
        this.charts = [
            {
                label: this.mostVisitedLabel,
                value: 'most-visited',
            },
        ];
        this.chartService.setupPeriodsTranslations(this.lastWeekLabel, this.lastMonthLabel, this.lastThreeMonthsLabel, this.lastSixMonthsLabel, this.lastYearLabel);
        this.selectedChart = this.charts[0];

        this.columnDefs = [
            {
                headerName: this.nameTitle,
                field: 'details',
                cellRenderer: this.gridsService.nameRenderer,
                width: this.gridsService.widthCalculator(37.5)
            },
            {
                headerName: this.addressTitle,
                field: 'address',
                cellRenderer: this.gridsService.addressRenderer,
                width: this.gridsService.widthCalculator(25)
            },
            {
                headerName: this.taxDetailsTitle,
                field: 'tax_details',
                cellRenderer: this.taxDetailsRenderer,
                width: this.gridsService.widthCalculator(12.5)
            },
            // { headerName: this.baseAmountTitle, field: 'base_amount', width: this.gridsService.widthCalculator(12.5) },
            { headerName: this.toGetPaidTitle, field: 'to_get_paid', width: this.gridsService.widthCalculator(12.5) },
            { headerName: this.toPayTitle, field: 'to_pay', width: this.gridsService.widthCalculator(12.5) },
        ];
    }

    ngOnInit(): void {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
        this.periods = this.chartService.periods;
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[4];

        this.getChartData();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.pagesCount = 0;
                // this.gridApi.purgeServerSideCache([]);
                this.gridApi.refreshServerSideStore({ purge: true });
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
