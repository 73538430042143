import { LM_STACKED_CHART_BORDER_RADIUS } from "@app/model/charts-config";
import { ILmChartWidgetTab } from "@app/model/widget";

export const IN_TOTAL: ILmChartWidgetTab = {
    id: 'total',
    title: 'Shipments',
    subtitle: 'In total',
    btn:{id: 'total', icon: 'lm-icon icon-pin', textOnly: true, color: "#ccc", activeColor: '#00aeba', active: true},
    data: {
        labels: [],
        datasets: [
            {
                label: 'Delivered',
                backgroundColor: ['#00aeba'],
                data: [],
                borderRadius: LM_STACKED_CHART_BORDER_RADIUS,
            },
            {
                label: 'Canceled',
                backgroundColor: ['#ffe2e4'],
                data: []
            }
        ]
    }, 
    filters: [],
    chartOptions: {
        scales: {
            x: {
                stacked: true
            }
        },
        plugins:{
            legend:{
                display: true,
                position: 'bottom',
                align: 'start',
            },
        }
    },
    chartType: 'bar'
};

export const DELIVERIES_AND_PICKUPS: ILmChartWidgetTab = {
    id: 'pickups',
    title: 'Shipments',
    subtitle: 'Deliveries & pickups',
    btn: {id: 'pickups', icon: 'lm-icon icon-check', textOnly: true, color: "#ccc", activeColor: '#00AEBA'} ,
    data: {
        labels: [],
        datasets: [
            {
                label: 'Dataset 1',
                backgroundColor: ['#00AEBA'],
                data: [],
                order: 1,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                stack: 'Stack 0'
            },
            {
                label: 'Dataset 2',
                backgroundColor: ['#BCDDE0'],
                data: [],
                order: 1,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                stack: 'Stack 0'
            },
            {
                label: 'Dataset 3',
                backgroundColor: ['#0AB14A'],
                data: [],
                order: 1,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                stack: 'Stack 1'
            },
            {
                label: 'Dataset 4',
                backgroundColor: ['#D4E5C6'],
                data: [],
                order: 1,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                stack: 'Stack 1'
            },
            {
                label: 'Line',
                type: 'line',
                data: [],
                borderColor: '#c74c96',
                borderWidth: 1,
                pointBackgroundColor: '#c74c96',
                fill: false,
                spanGaps: false,
                order: 0,
            }
        ]
    },
    filters: [],
    chartOptions: {
        datasets:{
            bar:{
                barThickness: 22,
                borderColor: 'rgba(0,0,0,0)',
                borderWidth: {
                    bottom:0,
                    top:0,
                    left:4,
                    right:4
                    
                },
                borderSkipped: 'bottom'
            }
        },
        plugins:{
            legend:{
                display: true,
                position: 'bottom',
                align: 'start',
                labels:{
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle',
                    color: '#999',
                    font:{
                        size: 12
                    }
                }
            }
        },
    },
    chartType: 'bar'
};

export const ON_TIME: ILmChartWidgetTab = {
    id: 'ontime',
    title: 'Shipments',
    subtitle: 'On time deliveries',
    btn: {id: 'ontime', icon: 'lm-icon icon-timecheck', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#ba6ef5'],
                data: [],
            }
        ]
    },
    chartType: 'bar'
}

export const FIRST_ATTEMPT_SUCCESS_RATE: ILmChartWidgetTab = {
    id: 'first',
    title: 'Shipments',
    subtitle: 'First time attempted',
    btn: {id: 'first', icon: 'lm-icon icon-one', textOnly: true, color: "#ccc", activeColor: '#00aeba'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#00aeba'],
                data: [],
            }
        ]
    },
    chartType: 'bar'
}

export const TOTAL_DISTANCE: ILmChartWidgetTab = {
    id: 'totalDistance',
    title: 'Shipments',
    subtitle: 'Total Distance',
    btn: {id: 'totalDistance', icon: 'lm-icon icon-road', textOnly: true, color: "#ccc", activeColor: '#666b7d'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#666b7d'],
                data: [],
            }
        ]
    },
    chartType: 'bar'
}

export const CO2_EMISSSIONS: ILmChartWidgetTab = {
    id: 'co2Emissions',
    title: 'Shipments',
    subtitle: 'CO2 emissions / delivery',
    btn: {id: 'co2Emissions', icon: 'lm-icon icon-leaf', textOnly: true, color: "#ccc", activeColor: '#27ab83'},
    data: {
        labels: [],
        datasets: [
            { 
                backgroundColor: ['#27ab83'],
                data: [],
            }
        ]
    },
    chartType: 'bar'
}