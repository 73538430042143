import { ColourService } from './../../../services/colour.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as turf from '@turf/turf';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { MapService } from '@app/services/map.service';
import { DriverRegionRuleService } from '@app/services/driver-region-rule.service';
import { take } from 'rxjs/operators';

declare var H: any;

@Component({
    selector: 'app-regional-map',
    templateUrl: './regional-map.component.html',
    styleUrls: ['./regional-map.component.scss']
})
export class RegionalMapComponent implements OnInit, AfterViewInit {

    private platform: any;
    @ViewChild('map', { static: false }) 
    public mapElement: ElementRef;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @Output() regionDataReceived = new EventEmitter<string>();

    map;
    ui;
    behavior;

    // clickOnMap;

    depots = [];
    depotsGroup = new H.map.Group();
    polygonGroup = new H.map.Group();
    polygonMarkersGroup = new H.map.Group();
    polygonLineString = new H.geo.LineString();
    polygonLineStringPolyline;
    polygonVerticesCoords = [];

    drawModeEnabled = true;
    savingChanges = false;

    driverId = null;
    colour = '#00aeba';
    rule = null;

    constructor(
        private http: HttpClient,
        public globals: Globals,
        private mapService: MapService,
        public driverRegionRuleService: DriverRegionRuleService,
        private colourService: ColourService
    ) {
        this.platform = this.mapService.platform;
    }

    mapToggled() {
        this.map?.getViewPort().resize();
    }

    loadDriverRegion(id) {
        this.driverId = id;
        this.driverRegionRuleService.getDriversRuleData(id).pipe(take(1)).subscribe(response => {
            if (response['items'].length) {
                const rule = response['items'][0];
                // response['items'].forEach(rule => {
                // if this driver has somehow more than one region, show only the 1st
                this.rule = rule['importRule'];
                this.regionDataReceived.emit('data');
                let polygon = '';
                rule['importRule']['rule']['trigger']['expression']['operands'].forEach(operand => {
                    if (operand.order === 1) {
                        polygon = operand.value;
                    }
                });
                const lineString = this.mapService.calculatePolygon(polygon);
                this.createResizablePolygon(lineString, '#00aeba', id);
                this.centerToPolygon();
                // });
            } else {
                this.regionDataReceived.emit('noData');
            }
        });
    }

    loadDriverModalRegion(id) {
        this.driverId = id;
        this.driverRegionRuleService.getDriversRuleData(id).pipe(take(1)).subscribe(response => {
            const {items} = response as any;

            if (items && !!items.length) {
                this.regionDataReceived.emit('data');
                
                const {importRule} = items[0];
                const {rule:{trigger:{expression:{operands}}}} = importRule
                const polygon = operands.filter(({order}) => order === 1)[0].value
                const lineString = this.mapService.calculatePolygon(polygon);
                this.rule = importRule;

                this.createResizablePolygon(lineString, '#00aeba', id);
            } 
            else this.regionDataReceived.emit('noData');
        });
    }

    centerToPolygon() {
        setTimeout(() => {
            const bounds = this.polygonGroup.getBoundingBox();
            this.map.getViewModel().setLookAtData({ bounds: bounds }, true);
        }, 100);
    }

    createResizablePolygon(lineString, colour, driverId) {
        const self = this;
        const rgb = this.colourService.hexToRGB(colour.replace('#', ''));
        const rgbaColour = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.3)';
        const circleIcon = this.svgIconsComponent.svgCircleColour.replace('markerColour', colour);
        const polygonData = this.mapService.createPolygonMainGroup(lineString, circleIcon, driverId, rgbaColour, colour);
        const verticeGroup = polygonData.verticeGroup;
        const mainGroup = polygonData.mainGroup;
        const polygon = polygonData.polygon;
        self.polygonGroup.addObject(mainGroup);
        // self.polygonWKT = mainGroup.getObjects()[0].getGeometry().toString();
        // add group with polygon and it's vertices (markers) on the map
        // self.map.addObject(self.polygonGroup);
        verticeGroup.setData({ id: driverId });

        // event listener for main group to show markers if moved in with mouse (or touched on touch devices)
        mainGroup.addEventListener('pointerenter', function (evt) {
            // show vertice markers
            verticeGroup.setVisibility(true);
        }, true);

        // event listener for main group to hide vertice markers if moved out with mouse (or released finger on touch devices)
        // the vertice markers are hidden on touch devices after specific timeout
        self.polygonGroup.addEventListener('pointerleave', function (evt) {
            const timeout = (evt.currentPointer.type === 'touch') ? 1000 : 0;
            // hide vertice markers
            verticeGroup.setVisibility(false);
        }, true);

        // event listener for vertice markers group to change the cursor to pointer
        verticeGroup.addEventListener('pointerenter', function (evt) {
            document.body.style.cursor = 'pointer';
        }, true);

        // event listener for vertice markers group to change the cursor to default
        verticeGroup.addEventListener('pointerleave', function (evt) {
            document.body.style.cursor = 'default';
        }, true);

        // event listener for vertice markers group to resize the geo polygon object if dragging over markers
        verticeGroup.addEventListener('drag', function (evt) {
            const pointer = evt.currentPointer,
                geoLineString = polygon.getGeometry().getExterior(),
                geoPoint = self.map.screenToGeo(pointer.viewportX, pointer.viewportY);

            // set new position for vertice marker
            evt.target.setGeometry(geoPoint);

            // set new position for polygon's vertice
            geoLineString.removePoint(evt.target.getData()['verticeIndex']);
            geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
            polygon.setGeometry(new H.geo.Polygon(geoLineString));

            // stop propagating the drag event, so the map doesn't move
            evt.stopPropagation();
        }, true);
    }

    updatePolygon(lineString, colour, driverId) {
        const polygons = this.polygonGroup.getObjects();
        const mainGroups = [];
        polygons.forEach(mainGroup => {
            if (mainGroup.getData().id === driverId) {
                mainGroups.push(mainGroup);
            }
        });
        if (mainGroups.length) {
            mainGroups.forEach(mainGroup => {
                this.polygonGroup.removeObject(mainGroup);
            });
            this.createResizablePolygon(lineString, colour, driverId);
        }
    }

    emptyDrawMode() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonLineString = new H.geo.LineString();
        this.polygonMarkersGroup.removeAll();
    }

    emptyPolygons() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonLineString = new H.geo.LineString();
        this.polygonGroup.removeAll();
        this.polygonMarkersGroup.removeAll();
    }

    createPolygonMarker(lat, lon) {
        const icon = new H.map.Icon(this.svgIconsComponent.svgCircle);
        const coords = { lat: lat, lng: lon };
        const marker = new H.map.Marker(coords, { icon: icon });
        this.polygonMarkersGroup.addObject(marker);
    }

    createLineString(lat, lon) {
        if (!this.polygonLineStringPolyline) {
            const firstPointCoords = this.polygonVerticesCoords[0];
            this.polygonLineString.pushLatLngAlt(firstPointCoords.lat, firstPointCoords.lng, 0);
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline = new H.map.Polyline(this.polygonLineString, {
                style: {
                    lineWidth: 5,
                    strokeColor: '#00aeba',
                }
            });
            this.map.addObject(this.polygonLineStringPolyline);
        } else {
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline.setGeometry(this.polygonLineString);
        }
    }

    setMapBounds() {
        this.map?.getViewModel().setLookAtData({ bounds: this.depotsGroup.getBoundingBox() }, true);
    }

    resetMap() {
        this.driverId = null;
        this.rule = null;
        this.drawModeEnabled = true;
        this.emptyPolygons();
        this.setMapBounds();
    }

    clickOnMap(event) {
        const self = this;
        const target = event.target;
        if (
            self.drawModeEnabled &&
            !(target instanceof H.map.DomMarker) &&
            (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')
        ) {
            if (target instanceof H.map.Marker) {
                if (target.getParentGroup() === self.polygonMarkersGroup) {
                    self.createResizablePolygon(self.polygonLineString, this.colour, this.driverId);
                    self.emptyDrawMode();
                }
            } else {
                const coords = self.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
                self.polygonVerticesCoords.push(coords);
                if (self.polygonVerticesCoords.length === 1) {
                    self.polygonGroup.removeAll();
                    self.createPolygonMarker(coords.lat, coords.lng);
                } else if (self.polygonVerticesCoords.length === 2) {
                    self.createLineString(coords.lat, coords.lng);
                } else if (self.polygonVerticesCoords.length > 2) {
                    self.createLineString(coords.lat, coords.lng);
                }
            }
        }
    }

    initMap(depotLat, depotLon) {
        const self = this;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 12,
                center: { lat: depotLat, lng: depotLon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.map.addEventListener('tap', this.clickOnMap.bind(this));
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');
        this.displayDepots();
        this.map.addObject(this.depotsGroup);
        this.map.addObject(this.polygonMarkersGroup);
        self.map.addObject(self.polygonGroup);
    }

    displayDepots() {
        const icon = new H.map.Icon(this.svgIconsComponent.svgDepotMarker.replace('markerColour', '#00aeba'));
        const iconBackground = new H.map.Icon(this.svgIconsComponent.svgMakerBackground);
        let coords, lat, lon, marker, markerBackground;
        this.depots.forEach(depot => {
            lat = depot['companyDepot']['address']['lat'];
            lon = depot['companyDepot']['address']['lon'];
            coords = { lat: lat, lng: lon };
            marker = new H.map.Marker(coords, { icon: icon });
            markerBackground = new H.map.Marker(coords, { icon: iconBackground });
            this.depotsGroup.addObject(markerBackground);
            this.depotsGroup.addObject(marker);
        });
        // });
    }

    deleteRegion() {
        if (this.rule) {
            this.savingChanges = true;
            this.http.delete('api/v1/company/import-rules/' + this.rule.id).pipe(take(1)).subscribe(response => {
                this.savingChanges = false;
            });
        }
    }

    savePolygonChanges() {
        // let driverData, index;
        // this.driversRegions.forEach(tempData => {
        //     if (tempData.driverId === id) {
        //         driverData = tempData;
        //         index = tempData.index;
        //     }
        // });
        const polygons = this.polygonGroup.getObjects();
        let polyline = '';
        const id = this.driverId;
        polygons.forEach(mainGroup => {
            // if (mainGroup.getData().id === id) {
            mainGroup.getObjects().forEach(polygon => {
                if (polygon instanceof H.map.Polygon) {
                    polyline = polygon.getGeometry().toString();
                }
            });
            // }
        });
        const rule = this.driverRegionRuleService.formRule(polyline, this.rule, this.driverId);
        const data = {
            id: this.driverId,
            polygon: polyline,
            rule: rule
        };
        this.savingChanges = true;
        if (this.rule) {
            this.driverRegionRuleService.invoke('put', null, null, data).pipe(take(1)).subscribe(response => {
                this.savingChanges = false;
            });
        } else {
            this.driverRegionRuleService.invoke('post', null, null, data).pipe(take(1)).subscribe(response => {
                this.savingChanges = false;
            });
        }
    }

    ngOnInit() {

    }

    public ngAfterViewInit() {
        const self = this;
        const dataRefreshIntervalId = setInterval(dataChecker, 200);
        function dataChecker() {
            if (self.globals.depotsDataDone) {
                clearInterval(dataRefreshIntervalId);
                self.depots = self.globals.depotsArray;
                const depotLat = self.globals.currentLat;
                const depotLon = self.globals.currentLon;
                self.initMap(depotLat, depotLon);
            }
        }
    }

    ngOnDestroy() {
        this.map.removeEventListener('tap', this.clickOnMap);
        //   this.listen.forEach(element => {
        //     element.unsubscribe();
        //   });
    }
}
