import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private cookieService: CookieService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => { },
            (error: any) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status !== 401 && error.status !== 403) {
                        return;
                    }
                    const domain = window.location.hostname;
                    this.cookieService.delete('jwt', '/', domain, true, 'Strict');
                    this.cookieService.delete('jwt');
                    if (this.cookieService.get('jwt')) {
                        this.cookieService.deleteAll();
                    }
                    this.router.navigate(['login']);
                    window.location.reload();
                }
            })
        );
    }
}
