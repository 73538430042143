// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  width: 100%;
  height: 100%;
  border-left: 1px solid #ccc;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hcC1ydWxlcy1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNJLFdBQUE7RUFDQSxZQUFBO0VBQ0EsMkJBQUE7QUFESiIsImZpbGUiOiJtYXAtcnVsZXMtbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICdzdHlsZXMvYWJzdHJhY3RzL3ZhcmlhYmxlcyc7XHJcblxyXG4jbWFwIHtcclxuICAgIHdpZHRoOiAxMDAlO1xyXG4gICAgaGVpZ2h0OiAxMDAlO1xyXG4gICAgYm9yZGVyLWxlZnQ6IDFweCBzb2xpZCAkbGlnaHQtZ3JleTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/modals/rules-modal/map-rules-modal/map-rules-modal.component.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,YAAA;EACA,2BAAA;AADJ;AACA,4eAA4e","sourcesContent":["@import 'styles/abstracts/variables';\r\n\r\n#map {\r\n    width: 100%;\r\n    height: 100%;\r\n    border-left: 1px solid $light-grey;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
