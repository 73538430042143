import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '@app/auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { LoaderService } from '@app/services/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpUtils } from '@app/utils/http-utils';

@Component({
    selector: 'app-collaborator-navbar',
    templateUrl: './collaborator-navbar.component.html',
    styleUrls: ['./collaborator-navbar.component.scss']
})
export class CollaboratorNavbarComponent implements OnInit, AfterViewInit {

    listen = [];

    companyName;
    usersInitial = '';
    activePageTitle;
    currentActivePage;
    currentLang;
    navVisible = false;
    isAuthenticated = false;

    soonMsg = '';

    constructor(
        public authService: AuthService,
        public globals: Globals,
        public translate: TranslateService,
        public router: Router,
        public loaderService: LoaderService,
        public cookieService: CookieService,
        public httpUtils: HttpUtils
    ) {
    }

    showHoverBubble(event) {
        document.getElementById('hover-bubble').style.left = document.getElementById(event.target.id).getBoundingClientRect().left + 12 + 'px';
        if (event.target.id == 'collaborator-settings-page') {
            document.getElementById('bubble').style.right = '-84px';
        } else {
            document.getElementById('bubble').style.right = '-128px';
        }
        document.getElementById('hover-bubble').style.display = 'block';
    }

    hideHoverBubble() {
        document.getElementById('hover-bubble').style.display = 'none';
    }

    updateAuth() {
        this.isAuthenticated = this.authService.isAuthenticated();
    }

    langChanged() {
        this.translate.use(this.currentLang);
        this.globals.currentLang = this.currentLang;
        localStorage.setItem('lang', this.currentLang);
    }

    toggleAccountDropDown() {
        const dropDown = document.getElementById('account-drop-down');
        dropDown.classList.toggle('hidden');
    }

    alertNotExist() {
        alert(this.soonMsg);
    }

    navigateToMembers() {
        this.router.navigate(['members']);
    }

    calculateNameInitials(name) {
        const array = name.split(' ');
        let initials = '';
        if (array.length) {
            initials = array[0].charAt(0);
        }
        return initials;
    }

    getTranslations() {
        this.listen.push(this.translate.get('ALERTS.SOON').subscribe((res: string) => { this.soonMsg = res; }));
    }

    ngAfterViewInit() {
        const self = this;
        setTimeout(() => {
            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach(link => {
                link.addEventListener('mouseover', function (event) {
                    if (link.getAttribute('page-title')) {
                        self.listen.push(self.translate.get('NAVBAR.' + link.getAttribute('page-title')).subscribe((res: string) => {
                            self.activePageTitle = res;

                            // [collaborator portal]: override customers label
                            if (self.globals.collaboratorModeEnabled) {
                                if (link.getAttribute('page-title') == 'CUSTOMERS') {
                                    self.listen.push(self.translate.get('NAVBAR.CUSTOMERS_COLLABORATOR').subscribe((res: string) => {
                                        self.activePageTitle = res;
                                    }));
                                }
                            }
                        }));
                    }
                });
                link.addEventListener('mouseout', function (event) {
                    self.activePageTitle = self.currentActivePage;

                    // [collaborator portal]: override customers label
                    if (self.globals.collaboratorModeEnabled) {
                        if (link.getAttribute('page-title') == 'CUSTOMERS') {
                            self.listen.push(self.translate.get('NAVBAR.CUSTOMERS_COLLABORATOR').subscribe((res: string) => {
                                self.activePageTitle = res;
                            }));
                        }
                    }
                });
            });
        }, 500);
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
        this.companyName = this.globals.companyName;
        const elems = document.querySelectorAll('select');
        const instances = M.FormSelect.init(elems);
        this.currentLang = this.translate.currentLang;
        this.usersInitial = this.calculateNameInitials(this.globals.userName);
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
