import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CustomerInvoicePrintComponent } from '@app/prints/customer-invoice-print/customer-invoice-print.component';
import { Globals } from '@app/services/globals';
import QRCode from 'easyqrcodejs';

@Component({
    selector: 'app-receipt-print',
    templateUrl: './receipt-print.component.html',
    styleUrls: ['./receipt-print.component.scss']
})
export class ReceiptPrintComponent implements OnInit, OnDestroy {
    @ViewChild(CustomerInvoicePrintComponent, { static: false }) customerInvoicePrintComponent: CustomerInvoicePrintComponent;

    qrLink;

    constructor(
        public globals: Globals,
    ) {
        // this.listenUpdateCustomerGrid = this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
        //     // this.pagesCount = 0;
        //     // this.gridApi.purgeServerSideCache([]);
        // });
    }

    getLinkAndGenerateQr(link) {
        if (link) {
            document.getElementById('qr-container').innerHTML = '';
            this.qrLink = link;
            const options = {
                text: this.qrLink,
                // logo: 'assets/lastmilyAssets/logo.png',
                quietZone: 20,
                colorLight: '#ffffff',
            };
            new QRCode(document.getElementById('qr-container'), options);
        }
    }

    loadReceipt(data) {
        // set data on print invoice component
        this.customerInvoicePrintComponent.receiptMode = true;
        this.customerInvoicePrintComponent.setPrintData(data);
        this.customerInvoicePrintComponent.setInvoicesGridData(data);
        const companyData = this.globals.companyData;
        companyData['companyLogo'] = this.globals.companyLogo;
        this.customerInvoicePrintComponent.setCompanyData(companyData);
        this.customerInvoicePrintComponent.collaboratorData = {};
        this.customerInvoicePrintComponent.setCollaboratorData(data.collaborator);
        
        if (data['qrLink']) {
            this.getLinkAndGenerateQr(data['qrLink']);
        }
    }


    ngOnDestroy() { }

    ngOnInit() {
        var comm100 = document.querySelector('[id^="comm100-button"]');
        if (comm100) {
            comm100.classList.add('hidden');
        }
        var comm1002 = document.querySelector('[id^="comm100-container"]');
        if (comm1002) {
            comm1002.classList.add('hidden');
        }

        const itemsStr = localStorage.getItem('receiptData');
        const parsed = JSON.parse(itemsStr);
        if (parsed) {
            setTimeout(() => {
                this.loadReceipt(parsed);
                setTimeout(() => {
                    window.print();
                }, 1200);
            }, 100);
        }

    }
}
