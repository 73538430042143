import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './formCheckbox.component.html',
  styleUrls: ['./formCheckbox.component.scss']
})
export class FormCheckboxComponent {
  config;
  group: FormGroup;
}
