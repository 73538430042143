import { chartImageAnnotation, chartAnnotationY, chartBarThicknessOffset } from "@app/shared/utils";
import { BubbleDataPoint, Chart, ChartDataset, ChartOptions, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import { AnnotationOptions, PartialEventContext } from "chartjs-plugin-annotation";


export const LM_BAR_CHART_BASE_OPTIONS: ChartOptions = {
    datasets:{
        bar:{
            barThickness: 40
        }
    },
    elements:{
        bar:{
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false
        }
    },
    plugins:{
        legend:{
            display: false,
            labels:{
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle',
              color: '#999',
              font:{
                  size: 12
              }
          }
        },
        tooltip: {
          enabled: false
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
              color: '#ccc',
            },
            grid: {
                color: '#f6f6f6'
            },
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
              color: '#ccc',
            }
        }
    }
}

export const LM_BAR_CHART_HEART_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + ' ❤';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_BAR_CHART_RATING_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + ' ★';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_BAR_CHART_MONEY_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + '€';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_PIE_CHART_BASE_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins:{
      legend:{
          display: false,
          position: 'top'
      },
  }
}

export const LM_DOUGHNUT_CHART_BASE_OPTIONS: ChartOptions<'doughnut'> = {
  cutout: '65%',
  plugins:{
      legend:{
          display: false
      }
  }
}


export function lmVerticalLabelAnnotation(label): AnnotationOptions{
  return {
    type: 'label',
    padding: {left:0, bottom: 10},
    content: label,
    yValue: label,
    xValue: 0,
    position: { y: 'end', x: '0%' }
  }
}


export function lmAvatarAnnotation(label, img): AnnotationOptions{
  return {
    type: 'label',
    drawTime: 'afterDatasetsDraw',
    init: true,
    padding: 0,
    borderWidth: 4,
    borderColor: 'rgba(255,255,255,.3)',
    borderRadius: 30,
    content: (e:PartialEventContext) => chartImageAnnotation(e, img),
    position: { x: 'center', y: 'end' },
    xValue: label,
    yValue: (e:PartialEventContext, anno) =>  chartAnnotationY(e, anno),
    yAdjust: (e) => chartBarThicknessOffset(e, 1)
  }
}

export const LM_STACKED_CHART_BORDER_RADIUS = {
  topLeft: 0,
  topRight: 0,
  bottomLeft: 100,
  bottomRight: 100,
}



// export const LM_BARCHART_AVATAR =  [
//   {
//       id: 'avatar',
//       afterDraw(chart, args, options) {
//         const metaData = (_i) => chart.getDatasetMeta(_i)?.data;
//         const pixelX = (_i) => chart.scales.x.getPixelForValue(_i);
//         const pixelY = (_i) => chart.scales.y.getPixelForValue(_i);

//           const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
//           ctx.save();

  
//           chart.data.datasets.forEach((set, setIndex) => {

//             metaData(setIndex).forEach((bar, barIndex) => {
//               const dataIndex = set?.data?.index;
//               const sourceIndex = set?.avatars?.index;
//               const img = new Image();
              
//               if(sourceIndex) img.src = sourceIndex; 

//               img.onload = function() {
//                   const size = 24; // Adjust the size as needed
//                   const xOffset = -size / 2; // Center the image
//                   const yOffset = -size - 10; // Position above the bar
//                   const xPos = bar.x + xOffset;
//                   const yPos = bar.y + yOffset;
//                   // ctx.drawImage(driverImg, xPos, yPos, size, size);

//                   ctx.save();
//                   ctx.beginPath();
//                   ctx.arc(pixelX(barIndex), pixelY(dataIndex) - 28, 24, 0, Math.PI * 2, false);
//                   ctx.stroke();
//                   ctx.clip();
//                   // ctx.drawImage(driverImg, xPos, yPos, size, size);
//                   ctx.drawImage(img, pixelX(barIndex) - (48 / 2), pixelY(dataIndex) - (48 + 4), 48, 48);
//                   ctx.restore();
//               };
//           });


//               // const meta = chart.getDatasetMeta(datasetIndex);
//               // meta.data.forEach((bar, index) => {
//               //     const img = new Image();
//               //     if (dataset['avatars'] && dataset['avatars'][index]) {
//               //         img.src = dataset['avatars'][index];
//               //     }
//               //     img.onload = function() {
//               //         const size = 24; // Adjust the size as needed
//               //         const xOffset = -size / 2; // Center the image
//               //         const yOffset = -size - 10; // Position above the bar
//               //         const xPos = bar.x + xOffset;
//               //         const yPos = bar.y + yOffset;
//               //         // ctx.drawImage(driverImg, xPos, yPos, size, size);

//               //         ctx.save();
//               //         ctx.beginPath();
//               //         ctx.arc(chart.scales.x.getPixelForValue(index), chart.scales.y.getPixelForValue(dataset.data[index]) - 28, 24, 0, Math.PI * 2, false);
//               //         ctx.stroke();
//               //         ctx.clip();
//               //         // ctx.drawImage(driverImg, xPos, yPos, size, size);
//               //         ctx.drawImage(img, (chart).scales.x.getPixelForValue(index) - (48 / 2), chart.scales.y.getPixelForValue(dataset.data[index]) - (48 + 4), 48, 48);
//               //         ctx.restore();
//               //     };
//               // });
//           });
//       }
//   }
// ];


/*



export type ChartType = keyof ChartTypeRegistry;


export interface ChartTypeRegistry {
  bar: {
    chartOptions: BarControllerChartOptions;
    datasetOptions: BarControllerDatasetOptions;
    defaultDataPoint: number;
    metaExtensions: {};
    parsedDataType: BarParsedData,
    scales: keyof CartesianScaleTypeRegistry;
  };
  line: {
    chartOptions: LineControllerChartOptions;
    datasetOptions: LineControllerDatasetOptions & FillerControllerDatasetOptions;
    defaultDataPoint: ScatterDataPoint | number | null;
    metaExtensions: {};
    parsedDataType: CartesianParsedData;
    scales: keyof CartesianScaleTypeRegistry;
  };
  scatter: {
    chartOptions: ScatterControllerChartOptions;
    datasetOptions: ScatterControllerDatasetOptions;
    defaultDataPoint: ScatterDataPoint | number | null;
    metaExtensions: {};
    parsedDataType: CartesianParsedData;
    scales: keyof CartesianScaleTypeRegistry;
  };
  bubble: {
    chartOptions: unknown;
    datasetOptions: BubbleControllerDatasetOptions;
    defaultDataPoint: BubbleDataPoint;
    metaExtensions: {};
    parsedDataType: BubbleParsedData;
    scales: keyof CartesianScaleTypeRegistry;
  };
  pie: {
    chartOptions: PieControllerChartOptions;
    datasetOptions: PieControllerDatasetOptions;
    defaultDataPoint: PieDataPoint;
    metaExtensions: PieMetaExtensions;
    parsedDataType: number;
    scales: keyof CartesianScaleTypeRegistry;
  };
  doughnut: {
    chartOptions: DoughnutControllerChartOptions;
    datasetOptions: DoughnutControllerDatasetOptions;
    defaultDataPoint: DoughnutDataPoint;
    metaExtensions: DoughnutMetaExtensions;
    parsedDataType: number;
    scales: keyof CartesianScaleTypeRegistry;
  };
  polarArea: {
    chartOptions: PolarAreaControllerChartOptions;
    datasetOptions: PolarAreaControllerDatasetOptions;
    defaultDataPoint: number;
    metaExtensions: {};
    parsedDataType: RadialParsedData;
    scales: keyof RadialScaleTypeRegistry;
  };
  radar: {
    chartOptions: RadarControllerChartOptions;
    datasetOptions: RadarControllerDatasetOptions & FillerControllerDatasetOptions;
    defaultDataPoint: number | null;
    metaExtensions: {};
    parsedDataType: RadialParsedData;
    scales: keyof RadialScaleTypeRegistry;
  };
}
*/