import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { LmDialogContext } from "@app/model/dialog";
import { LmProjectProblemRoutingSettingsService } from "@app/api/services/project-problem-routing-settings.service";
import { IProjectProblemRoutingSettingsDto } from "@app/api/models/project-problem-routing-settings-dto";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take } from "rxjs/operators";
import moment from "moment";
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { LmDialogComponent } from "@app/shared/containers/dialog/dialog.component";

@UntilDestroy()
@Component({
  templateUrl: "project-problem-routing-settings-modal.component.html",
  styleUrls: ["./project-problem-routing-settings-modal.component.scss"],
  providers: [],
})
export class ProjectProblemRoutingSettingsModalComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChild(LmDialogComponent) dialog:LmDialogComponent;
  @ViewChild('routingSettingsButton') closeButton:ElementRef;
  globals;
  isInit = true;
  values: IProjectProblemRoutingSettingsDto;

  driversWorkingHoursOffset = 0;
  after_dispatch_respect_order_strictness;
  avoid_tolls;
  routing_optimize_mode;
  
  radio = [];
  sliderStartText = '';
  sliderEndText = '';

  sliderEnd: () => void;

  constructor(
    private viewProjectProblemService: ViewProjectProblemService,
    public config: LmDialogContext<any>,
    private service: LmProjectProblemRoutingSettingsService,
    private renderer: Renderer2
  ) {
    const {data:{values, globals}} = config;
    const {drivers_working_duration_offset, project:{after_dispatch_respect_order_strictness, avoid_tolls, routing_optimize_mode }} = values;

    this.driversWorkingHoursOffset = moment.duration(drivers_working_duration_offset).asHours();
    this.after_dispatch_respect_order_strictness = after_dispatch_respect_order_strictness*(-1);
    this.avoid_tolls = avoid_tolls;
    this.routing_optimize_mode = routing_optimize_mode;
    this.globals = globals;

    this.sliderEnd = () => {
      this.onValueChanged();
    }

    this.sliderStartText = config.data.translations["NONE"];
    this.sliderEndText = config.data.translations["FULLY"];
    this.radio = [
      {label: config.data.translations["TIME"],value: this.globals.projectRoutingOptimizeModeConstants["TIME"],},
      {label: config.data.translations["DISTANCE"],value: this.globals.projectRoutingOptimizeModeConstants["DISTANCE"]},
    ];
  }

  private provideModel(comp:ProjectProblemRoutingSettingsModalComponent):IProjectProblemRoutingSettingsDto{
    return {
        drivers_working_duration_offset:  moment.duration(comp.driversWorkingHoursOffset, "hours").toISOString(),
        project: {
          after_dispatch_respect_order_strictness: Math.abs(comp.after_dispatch_respect_order_strictness),
          routing_optimize_mode: comp.routing_optimize_mode,
          avoid_tolls: comp.avoid_tolls,
        }
    }
  }

  onValueChanged() {    
    if (!this.isInit) {
      this.service
        .apiProjectProblemRoutingSettingsPost({
          body: this.provideModel(this),
          id: this.config.data.id,
        })
        .pipe(take(1), untilDestroyed(this))
        .subscribe((_) => {
            this.viewProjectProblemService.updateProjectProblem();
        });
    }
  }

  close(){
    this.renderer.addClass(this.dialog._elRef.nativeElement, 'lm-dialog-discard-left');
    setTimeout(_=> {
      this.renderer.removeClass(document.body, 'lm-routing-settings-modal');
      this.config.close();
    },800)
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(_=> this.isInit=false)
  }

  ngOnDestroy(): void {}
}
