import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-demo-loader',
    templateUrl: './demo-loader.component.html',
    styleUrls: ['./demo-loader.component.scss']
})
export class DemoLoaderComponent implements OnInit {

    cities = [];
    chosenCityCode = '';

    constructor(
        private http: HttpClient,
        private router: Router,
        public globals: Globals,
    ) {
    }

    del() {
        // this.http.delete('api/v1/demos').pipe(take(1)).subscribe(response => {
        // const projectProblemId = response['id'];
        // this.router.navigate(['projectView/' + projectProblemId]);
        // });

    }

    loadCity(id, vehicleCount, cityCode) {
        // load image
        this.chosenCityCode = cityCode;
        document.getElementById('loading-view').classList.remove('hidden');
        const data = {
            demos: {
                id: id,
                vehicleCount: vehicleCount
            }
        }


        this.http.post('api/v1/demos', data).pipe(take(1)).subscribe(response => {
            const projectProblemId = response['projectProblem']['id'];
            this.globals.getDepots();
            this.globals.getCompanyData();
            const self = this;
            const dataRefreshIntervalId = setInterval(dataChecker, 200);
            function dataChecker() {
                if (self.globals.depotsDataDone) {
                    clearInterval(dataRefreshIntervalId);
                    self.router.navigate(['projects/projectView/' + projectProblemId]);
                }
            }
        });
    }

    ngOnInit() {
        const self = this;
        // const dataRefreshIntervalId = setInterval(dataChecker, 200);
        // function dataChecker() {
        // if (self.globals.settingsDataDone) {
        // clearInterval(dataRefreshIntervalId);
        if (self.globals.demoState !== this.globals.companyDemoStateConstants['NOT_INITIALIZED']) {
            self.router.navigate(['']);
        } else {
            const cityCodes = ['thes', 'ath', 'ny'];
            self.http.get('api/v1/demos').pipe(take(1)).subscribe(response => {
                if (document.getElementById('loading-view')) {
                    document.getElementById('loading-view').classList.add('hidden');
                }
                response['items'].forEach((city, index) => {
                    const data = {
                        name: city.city,
                        cityCode: cityCodes[index],
                        driversCount: '2',
                        pointsCount: city.selfStopPointsCount,
                        id: city.id
                    };
                    self.cities.push(data);
                });
            });
        }
        // }
        // }
    }

}
