import { Injectable } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FulfillmentUtils {
    listen = [];
    cancelReasons: number[] = [];
    cancelReasonsObject = {};
    pickupCancelReasons = [];
    deliveryCancelReasons = [];
    pickupHandedCancelReasons = [];
    deliveryHandedCancelReasons = [];
    pickupNotHandedCancelReasons = [];
    deliveryNotHandedCancelReasons = [];
    handedCancelReasons = [];
    notHandedCancelReasons = [];
    cancelledEventReasonsLabels = {};


    constructor(
        public globals: Globals,
        public translate: TranslateService,
    ) {
        this.setupReasonsArrays()
    }

    setupReasonLabels(strings) {
        const eventConstants = this.globals.stopPointFulfillmentEventConstants;
        const statusConstants = this.globals.stopPointFulfillmentStatusConstants;

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['CUSTOM']] = strings['CANCEL_OTHER_REASON_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['CUSTOM_HANDED']] = strings['CANCEL_OTHER_REASON_MSG'];

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['NOT_THERE']] = strings['CANCEL_NOT_THERE_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['NOT_THERE_HANDED']] = strings['CANCEL_NOT_THERE_MSG'];

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['WRONG_ADDRESS']] = strings['CANCEL_WRONG_ADDRESS_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['WRONG_ADDRESS_HANDED']] = strings['CANCEL_WRONG_ADDRESS_MSG'];

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['DID_NOT_ACCEPT_IT']] = strings['CANCEL_DID_NOT_ACCEPT_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['DID_NOT_ACCEPT_IT_HANDED']] = strings['CANCEL_DID_NOT_ACCEPT_MSG'];

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['PICKUP_FROM_DEPOT']] = strings['CANCEL_PICKUP_FROM_DEPOT_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['PICKUP_FROM_DEPOT_HANDED']] = strings['CANCEL_PICKUP_FROM_DEPOT_MSG'];

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['AGREED_SHIPPING']] = strings['CANCEL_AGREED_SHIPPING_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['AGREED_SHIPPING_HANDED']] = strings['CANCEL_AGREED_SHIPPING_MSG'];

        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS']] = strings['AGREED_SHIPPING_WRONG_ADDRESS_MSG'];
        this.cancelledEventReasonsLabels[eventConstants[statusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS_HANDED']] = strings['AGREED_SHIPPING_WRONG_ADDRESS_MSG'];

        if (this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']) {
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']] = strings['CANCEL_DID_NOT_ACCEPT_CHARGE'];
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE_HANDED']] = strings['CANCEL_DID_NOT_ACCEPT_CHARGE'];
        }
        if (this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']) {
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']] = strings['CANCEL_DID_NOT_ACCEPT_POD'];;
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY_HANDED']] = strings['CANCEL_DID_NOT_ACCEPT_POD'];;
        }
        if (this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND']) {
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND']] = strings['NOTHING_TO_SEND'];;
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND_HANDED']] = strings['NOTHING_TO_SEND'];;
        }
        if (this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT']) {
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT']] = strings['UNKNOWN_SENDER_RECIPIENT'];;
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT_HANDED']] = strings['UNKNOWN_SENDER_RECIPIENT'];;
        }
        if (this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE']) {
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE']] = strings['NOT_AWARE'];;
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE_HANDED']] = strings['NOT_AWARE'];;
        }
        if (this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY']) {
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY']] = strings['PARCELS_NOT_READY'];;
            this.cancelledEventReasonsLabels[this.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY_HANDED']] = strings['PARCELS_NOT_READY'];;
        }
    }

    private setupReasonsArrays() {
        this.pickupCancelReasons = [];
        this.deliveryCancelReasons = [];
        this.pickupHandedCancelReasons = [];
        this.pickupNotHandedCancelReasons = [];
        this.deliveryHandedCancelReasons = [];
        this.deliveryNotHandedCancelReasons = [];
        Object.keys(this.globals.pickupCancelFulfillmentEventConstants).forEach(reason => {
            this.pickupCancelReasons.push(this.globals.pickupCancelFulfillmentEventConstants[reason]);
            this.cancelReasonsObject[reason] = this.globals.pickupCancelFulfillmentEventConstants[reason];
            if (this.isHanded(reason)) {
                this.pickupHandedCancelReasons.push(this.globals.pickupCancelFulfillmentEventConstants[reason]);
            } else {
                this.pickupNotHandedCancelReasons.push(this.globals.pickupCancelFulfillmentEventConstants[reason]);
            }
        });
        Object.keys(this.globals.deliveryCancelFulfillmentEventConstants).forEach(reason => {
            this.deliveryCancelReasons.push(this.globals.deliveryCancelFulfillmentEventConstants[reason]);
            this.cancelReasonsObject[reason] = this.globals.deliveryCancelFulfillmentEventConstants[reason];
            if (this.isHanded(reason)) {
                this.deliveryHandedCancelReasons.push(this.globals.deliveryCancelFulfillmentEventConstants[reason]);
            } else {
                this.deliveryNotHandedCancelReasons.push(this.globals.deliveryCancelFulfillmentEventConstants[reason]);
            }
        });
        // Convert the concatenated array to a Set to remove duplicates
        this.cancelReasons = [...new Set(this.deliveryCancelReasons.concat(this.pickupCancelReasons))];
        this.handedCancelReasons = [...new Set(this.deliveryHandedCancelReasons.concat(this.pickupHandedCancelReasons))];
        this.notHandedCancelReasons = [...new Set(this.deliveryNotHandedCancelReasons.concat(this.pickupNotHandedCancelReasons))];
    }

    private isHanded(reason: string) {
        if (reason.includes("_HANDED")) {
            return true
        }
        return false
    }
}