// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0b3BzLWdyaWQtbWFwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVVBO0VBQ0ksV0FBQTtFQUNBLFlBQUE7QUFUSiIsImZpbGUiOiJzdG9wcy1ncmlkLW1hcC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ3N0eWxlcy9hYnN0cmFjdHMvdmFyaWFibGVzJztcblxuLy8gLm1hcC1kZXNjcmlwdGlvbntcbi8vICAgICBoZWlnaHQ6IDg1cHg7XG4vLyAgICAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbi8vICAgICBib3JkZXItbGVmdDogMXB4IHNvbGlkICRsaWdodC1ncmV5O1xuLy8gICAgIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCAkbGlnaHQtZ3JleTtcbi8vICAgICBwYWRkaW5nOiAyMHB4O1xuLy8gfVxuXG4jbWFwIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgLy8gaGVpZ2h0OiBjYWxjKDEwMCUgLSA4NXB4KTtcbiAgICAvLyBib3JkZXItbGVmdDogMXB4IHNvbGlkICRsaWdodC1ncmV5O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/grids/stops-grid/stops-grid-map/stops-grid-map.component.scss"],"names":[],"mappings":"AAUA;EACI,WAAA;EACA,YAAA;AATJ;AACA,gwBAAgwB","sourcesContent":["@import 'styles/abstracts/variables';\n\n// .map-description{\n//     height: 85px;\n//     box-sizing: border-box;\n//     border-left: 1px solid $light-grey;\n//     border-bottom: 1px solid $light-grey;\n//     padding: 20px;\n// }\n\n#map {\n    width: 100%;\n    height: 100%;\n    // height: calc(100% - 85px);\n    // border-left: 1px solid $light-grey;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
