// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard-welcome-text {
  text-align: center;
  font-weight: 600;
  font-size: 2em;
}

.wizard-welcome-subtext {
  text-align: center;
  padding-top: 18vh;
  margin: auto;
  font-size: 1.2em;
  color: #555;
}

.name-input {
  width: 35vw;
  margin: auto;
  margin-top: 10vh;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndpemFyZC13ZWxjb21lLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksa0JBQUE7RUFDQSxnQkFBQTtFQUNBLGNBQUE7QUFDSjs7QUFFQTtFQUNJLGtCQUFBO0VBQ0EsaUJBQUE7RUFDQSxZQUFBO0VBQ0EsZ0JBQUE7RUFDQSxXQUFBO0FBQ0o7O0FBRUE7RUFDSSxXQUFBO0VBQ0EsWUFBQTtFQUNBLGdCQUFBO0FBQ0oiLCJmaWxlIjoid2l6YXJkLXdlbGNvbWUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIud2l6YXJkLXdlbGNvbWUtdGV4dHtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgICBmb250LXNpemU6IDJlbTtcbn1cblxuLndpemFyZC13ZWxjb21lLXN1YnRleHR7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIHBhZGRpbmctdG9wOiAxOHZoO1xuICAgIG1hcmdpbjogYXV0bztcbiAgICBmb250LXNpemU6IDEuMmVtO1xuICAgIGNvbG9yOiAjNTU1O1xufVxuXG4ubmFtZS1pbnB1dHtcbiAgICB3aWR0aDogMzV2dztcbiAgICBtYXJnaW46IGF1dG87XG4gICAgbWFyZ2luLXRvcDogMTB2aDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/wizard/wizard-welcome/wizard-welcome.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACA,w1BAAw1B","sourcesContent":[".wizard-welcome-text{\n    text-align: center;\n    font-weight: 600;\n    font-size: 2em;\n}\n\n.wizard-welcome-subtext{\n    text-align: center;\n    padding-top: 18vh;\n    margin: auto;\n    font-size: 1.2em;\n    color: #555;\n}\n\n.name-input{\n    width: 35vw;\n    margin: auto;\n    margin-top: 10vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
