// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        lm-bar-chart{width: 100%}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhcmNoYXJ0LXdpZGdldC5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtRQUNRLGFBQWEsV0FBVyIsImZpbGUiOiJiYXJjaGFydC13aWRnZXQuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgICAgIGxtLWJhci1jaGFydHt3aWR0aDogMTAwJX1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/widgets/barchart-widget.component.ts"],"names":[],"mappings":";QACQ,aAAa,WAAW;;AAEhC,4TAA4T","sourcesContent":["\n        lm-bar-chart{width: 100%}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
