import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { AddButtonBaseConfig as abbc } from '@app/model/buttons-model';
import { LmNotificationService } from '@app/core/services/notification.service';
import { TransportationRegionModalComponent } from './new-transportation-region-modal/new-transportation-region-modal.component';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { TransportationViewModelService } from './transportation-regions-viewmodel.service';
import { switchMap, tap } from 'rxjs';

@Component({
    selector: 'transportation-regions',
    template: `<lm-table-list class="lm-tr-table" [cols]="cols" [VM]="VM" [headerBtn]="headerBtn" [title]="title"></lm-table-list>`,
    styleUrls: ['./transportation-regions.component.scss'],
    providers: [TransportationViewModelService, LmModelProxyService] // model proxy -> forwardref
})
export class TransportationRegionsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() title: string;
    transportationRegionModal = TransportationRegionModalComponent;
    cols: LmTableBaseColumnDefs[];
    headerBtn: LmButtonConfig;
    translations;
    listen = [];

    constructor (
        public VM: TransportationViewModelService,
        private notificationSvc: LmNotificationService, 
        public translate: TranslateService,
        public globals: Globals,  
        private _injector: Injector
    ) {
      this.headerBtn = {...abbc, command: this.showTransportationRegionNodal};
      this.translate.get('TRANSPORTATION_REGIONS').pipe(
        tap(response => {
          this.translations = response; 
          this.cols = [
            {header: response['NAME'], field: 'nameCaption', cellClass:"lm-tr-table-name-cell"},
            {header: response['VALUE'], field: 'areasCaption', cellClass:"lm-tr-table-areas-cell"},
          ];
          this.VM.modalConfig.data['translations'] = response;
        })
      ).subscribe();
      this.translate.get('WARNINGS').pipe(
          tap(response => {
              this.VM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
          })
      ).subscribe();
    }

    showTransportationRegionNodal = () => {
      this.notificationSvc
      .showDialog<boolean>({ 
        componentType: this.transportationRegionModal,   
        injector: this._injector, 
        data: {VM: this.VM, globals: this.globals, item: {...this.VM.newItem()}} 
      })
      .subscribe();
    }

    ngAfterViewInit(): void {}

    ngOnInit() {}
    ngOnDestroy() {
      this.listen.forEach(element => {
        element.unsubscribe();
      });
    }
}
