import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-barcodes-list-box',
  templateUrl: './barcodes-list-box.component.html',
  styleUrls: ['./barcodes-list-box.component.scss']
})

export class BarcodesListBoxComponent implements OnInit {
  @Input() barcodes: Array<string>;

  constructor() { }

  ngOnInit() {
  }

}
