import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { Globals } from '@app/services/globals';
import { ISettings_Addon_Services_Dto } from '@app/api/models/settings-services-dto';
import { IPriceListAddonServiceType } from '@app/model/price-list';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LmSettingsAddonServicesService } from '@app/api/services/settings-addon-services.service';
import { AddonServiceModalComponent } from './new-addon-service-modal/new-addon-service-modal.component';


@Injectable()
export class SettingsAddonServicesViewModelService extends LmTableListViewModelService<ISettings_Addon_Services_Dto> implements OnInit, OnDestroy {
    addonServicesEnum: IPriceListAddonServiceType;
    modalConfig: any;
    showAddonServiceModal = AddonServiceModalComponent;

    constructor(
        injector: Injector, 
        public globals: Globals,
        @Inject(forwardRef(() => LmSettingsAddonServicesService)) public apiSvc: LmSettingsAddonServicesService
    ){
        super(injector);
        const {priceListAddonServiceTypeEnum} = globals;
        this.addonServicesEnum = priceListAddonServiceTypeEnum;
        this.modalConfig = { componentType: this.showAddonServiceModal, injector: injector,data: {VM: this, globals: this.globals} }
    }
    
    private transformRows = (res) => {
        res.items.forEach(item => {
            let description = '';
            if (this.modalConfig.data['translations']) {
                if (item.type == this.globals.priceListAddonServiceTypeEnum.CodService.key) {
                    description = this.modalConfig.data['translations']['PAY_ON_DELIVERY_DESCRIPTION'];
                } else if (item.type == this.globals.priceListAddonServiceTypeEnum.TimeWindowService.key) {
                    description = this.modalConfig.data['translations']['TIME_WINDOW_DESCRIPTION'];
                }
            }
            item.nameAndDescription = `${item.name}</br><small class="lm-txt-color-grey lm-font-weight-normal">${description}</small>`;
            item.valueCaption = `<b>${item.value}</b>`;
            item.hideDelete = item.type !== this.globals.priceListAddonServiceTypeEnum['ManualService'].key;
        });
        return res;
    }
    
    getRows(){
        return this.apiSvc.apiSettingsAddonServicesAllGet().pipe(switchMap(res => of(this.transformRows(res))));
    }
    getById(id){

    }
    post(item: any) {
        return this.apiSvc.apiSettingsAddonServicesPost({body: item})
    }
    put(id) {
        
    }
    delete(id) {
        return this.apiSvc.apiSettingsAddonServicesSingleDelete({id:id})
    }
    newItem() {
        return INITIAL_DATA.newAddonServiceItem;
    }
    search(query: string) {
        
    }

    ngOnInit(): void {
        INITIAL_DATA.newAddonServiceItem.type = this.globals.priceListAddonServiceTypeEnum['ManualService'].key;
        INITIAL_DATA.newServiceChargeTableRowItem.type = this.globals.priceListAddonServiceTypeEnum['ManualService'].key;
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
