import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

declare var H: any;

@Injectable({
    providedIn: 'root'
})
export class MapService {

    platform = new H.service.Platform({
        // move key to env vars
        'apikey': 'bd63CgaPUjQlOT5A_hCfWTkbWY8JM5cM-II59BTLuN8',
        useHTTPS: true
    });
    iconsPerColor = {};

    createPolygonMainGroup(lineString, circle, id, colour, strokeColour = colour) {
        const polygon = new H.map.Polygon(
                new H.geo.Polygon(lineString),
                {
                    style: {
                        fillColor: colour,
                        strokeColor: strokeColour,
                        lineWidth: 5
                    }
                }
            ),
            verticeGroup = new H.map.Group({
                visibility: false
            }),
            mainGroup = new H.map.Group({
                volatility: true, // mark the group as volatile for smooth dragging of all it's objects
                objects: [polygon, verticeGroup]
            });

        polygon.setData({ id: id });
        mainGroup.setData({ id: id });

        // ensure that the polygon can receive drag events
        polygon.draggable = true;

        if(!this.iconsPerColor[colour]) {
            this.iconsPerColor[colour] = new H.map.Icon(circle, { anchor: { x: 10, y: 10 } })
        }

        // create markers for each polygon's vertice which will be used for dragging
        polygon.getGeometry().getExterior().eachLatLngAlt( (lat, lng, alt, index) => {
            const vertice = new H.map.Marker(
                { lat, lng },
                {
                    icon: this.iconsPerColor[colour]
                }
            );
            vertice.draggable = true;
            vertice.setData({ 'verticeIndex': index, id: id });
            verticeGroup.addObject(vertice);
        });
        return { mainGroup: mainGroup, verticeGroup: verticeGroup, polygon: polygon };
    }

    polygonRemoveSameStartAndEnd(polygonCoords) {
        if (
            polygonCoords[polygonCoords.length - 3] === polygonCoords[0] &&
            polygonCoords[polygonCoords.length - 2] === polygonCoords[1]
        ) {
            polygonCoords.splice(polygonCoords.length - 3, 3);
        }
        return polygonCoords;
    }

    calculatePolygon(wktPolygon) {
        const lineString = new H.geo.LineString();
        // replace ", " with " " and "," with " " so that both "lon,lat" and "lon, lat" work fine without causing blank array items in split
        let wktPolygonPlainCoords = wktPolygon.replace('POLYGON ((', '').replace('))', '').replace(/, /g, ' ').replace(/,/g, ' ');
        const wktPolygonPlainCoordsArray = wktPolygonPlainCoords.split(' ');
        const polygonCoords = this.polygonRemoveSameStartAndEnd(wktPolygonPlainCoordsArray);
        if (polygonCoords) {
            for (let i = 0; i < polygonCoords.length - 1; i = i + 2) {
                lineString.pushLatLngAlt(polygonCoords[i + 1], polygonCoords[i], 0);
            }
        }
        return lineString;
    }

    calculateMultiPolygons(wktPolygon) {
        const lineStrings = [];
        // replace ", " with " " and "," with " " so that both "lon,lat" and "lon, lat" work fine without causing blank array items in split
         // Remove outer parentheses with optional spaces
        let wktPolygonsPlainCoordsArray = wktPolygon.replace('MULTIPOLYGON ', '').replace(/^\s*\(+|\)\s*$/g, '').split(/\s*\)\s*,\s*\(\s*/);
        wktPolygonsPlainCoordsArray.forEach(wktPolygonPlainCoordsPlain => {
            const wktPolygonPlainCoords = wktPolygonPlainCoordsPlain.replace(/[()]+/g, '').replace(/, /g, ' ').replace(/,/g, ' ');
            const lineString = new H.geo.LineString();
            const wktPolygonPlainCoordsArray = wktPolygonPlainCoords.split(' ');
            const polygonCoords = this.polygonRemoveSameStartAndEnd(wktPolygonPlainCoordsArray);
            if (polygonCoords) {
                for (let i = 0; i < polygonCoords.length - 1; i = i + 2) {
                    lineString.pushLatLngAlt(polygonCoords[i + 1], polygonCoords[i], 0);
                }
            }
            lineStrings.push(lineString);
        });
        return lineStrings
    }
}
