import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Globals } from '@app/services/globals';
// import PubNub from 'pubnub';
// import Pusher from 'pusher-js/node';
// import { CometChat } from "@cometchat-pro/chat";
// import AgoraRTM from 'agora-rtm-sdk';
// import AgoraRTC from "agora-rtc-sdk-ng"
import ConnectyCube from "connectycube"
// import ConnectyCube from "react-native-connectycube";


// declare var Pusher: any;
// declare var axios: any;

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})

export class TestComponent implements OnInit, AfterViewInit {

    pubnub;
    chatText = '';
    // pusher;
    channel;
    client;

    messages = [];

    username;
    session;

    constructor(
        public globals: Globals,
    ) { }

    // listenForPubNubMessages() {
    //     this.pubnub.addListener({
    //         message: (m) => {
    //             // handle messages
    //             console.log(m);
    //             console.log(m.message.description);
    //             this.messages.push(m.message.description);
    //         },
    //         presence: function (p) {
    //             // handle presence  
    //             console.log(p);
    //         },
    //         signal: function (s) {
    //             // handle signals
    //             console.log(s);
    //         },
    //         objects: (objectEvent) => {
    //             // handle objects
    //             console.log(objectEvent);
    //         },
    //         messageAction: function (ma) {
    //             // handle message actions
    //             console.log(ma);
    //         },
    //         file: function (event) {
    //             // handle files  
    //             console.log(event);
    //         },
    //         status: function (s) {
    //             // handle status 
    //             console.log(s);
    //         },
    //     });

    //     this.pubnub.subscribe({
    //         channels: ["hello_world"]
    //     });

    //     this.pubnub.fetchMessages(
    //         {
    //             channels: ['hello_world'],
    //             end: '15343325004275466',
    //             count: 100
    //         },
    //         (status, response) => {
    //             // handle response
    //             console.log(status);
    //             console.log(response);
    //             if (response) {
    //                 response['channels']['hello_world'].forEach(message => {
    //                     this.messages.push(message.message.description);
    //                 });
    //             }

    //         }
    //     );
    // }

    // sendPubNubMessage() {
    //     var publishPayload = {
    //         channel: "hello_world",
    //         message: {
    //             title: "greeting",
    //             description: this.chatText
    //         }
    //     }

    //     this.pubnub.publish(publishPayload, function (status, response) {
    //         console.log(status, response);
    //     })
    // }

    // listenForPusherMessages() {
    //     // var channel = this.pusher.subscribe("my-channel");
    //     // channel.bind("my-event", (data) => {
    //     //     // Method to be dispatched on trigger.
    //     //     console.log(data);
    //     // });

    //     // this.channel = this.pusher.subscribe('my-channel');
    //     // this.channel.bind('new-message', function (data) {
    //     //     console.log(data.message);
    //     //     console.log(data);
    //     // });

    //     // this.channel.bind('my-event', function () {
    //     //     console.log(`hi ${this.name}`);
    //     // }, { name: 'Pusher' });

    //     // const channel = pusher.channel('private-my-channel');

    // }

    // sendPusherMessage() {
    //     console.log(1);
    //     this.channel.trigger('client-my-event', { message: 'Hello, world!' })

    //     // this.pusher.trigger("my-channel", "my-event", { message: "hello world" });
    // }

    // async listenForCometChatMessages() {
    // let listenerID: string = "user2";

    //     CometChat.addMessageListener(
    //         listenerID,
    //         new CometChat.MessageListener({
    //             onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
    //                 console.log("Text message received successfully", textMessage);
    //             },
    //             onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
    //                 console.log("Media message received successfully", mediaMessage);
    //             },
    //             onCustomMessageReceived: (customMessage: CometChat.CustomMessage) => {
    //                 console.log("Custom message received successfully", customMessage);
    //             }
    //         })
    //     );

    //     let UID: string = "user2",
    //         limit: number = 30,
    //         latestId: number = await CometChat.getLastDeliveredMessageId(),
    //         messagesRequest: CometChat.MessagesRequest = new CometChat.MessagesRequestBuilder()
    //             .setUID(UID)
    //             .setMessageId(latestId)
    //             .setLimit(limit)
    //             .build();

    //     messagesRequest.fetchNext().then(
    //         (messages: CometChat.BaseMessage[]) => {
    //             console.log("Message list fetched:", messages);
    //         }, (error: CometChat.CometChatException) => {
    //             console.log("Message fetching failed with error:", error);
    //         }
    //     );
    // }

    // sendCometChatMessage() {
    //     let receiverID: string = "user1",
    //         receiverType: string = CometChat.RECEIVER_TYPE.USER,
    //         textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, this.chatText, receiverType);

    //     let metadata: Object = {
    //         latitude: "50.6192171633316",
    //         longitude: "-72.68182268750002"
    //     };

    //     textMessage.setMetadata(metadata);

    //     let tags = ["starredMessage"];

    //     textMessage.setTags(tags);

    //     CometChat.sendMessage(textMessage).then(
    //         (message: CometChat.TextMessage) => {
    //             console.log("Message sent successfully:", message);
    //         }, (error: CometChat.CometChatException) => {
    //             console.log("Message sending failed with error:", error);
    //         }
    //     );
    // }

    sendMessage() {
        // console.log(1);
        // this.sendPusherMessage();
        // this.sendPubNubMessage();
        // this.sendCometChatMessage();
    }

    // createCometChatUser() {
    //     let authKey = "7c7ba7e10aa73b2194a8743e4399473249ebefef";
    //     var uid = "user2";
    //     var name = "mariamat";

    //     var user = new CometChat.User(uid);
    //     user.setName(name);
    //     CometChat.createUser(user, authKey).then(
    //         user => {
    //             console.log("user created", user);
    //         }, error => {
    //             console.log("error", error);
    //         }
    //     )
    // }

    // loginCometChatUser() {
    //     const authKey = "7c7ba7e10aa73b2194a8743e4399473249ebefef";
    //     const uid = "user2";

    //     CometChat.login(uid, authKey).then(
    //         (user) => {
    //             console.log("Login Successful:", { user });
    //         },
    //         (error) => {
    //             console.log("Login failed with exception:", { error });
    //         }
    //     );
    // }

    // initCometChat() {
    //     const appID = "2132403928563324";
    //     const region = "eu";
    //     const appSetting = new CometChat.AppSettingsBuilder()
    //         .subscribePresenceForAllUsers()
    //         .setRegion(region)
    //         .build();
    //     CometChat.init(appID, appSetting).then(
    //         () => {
    //             console.log("Initialization completed successfully");
    //             // You can now call login function.
    //         },
    //         (error) => {
    //             console.log("Initialization failed with error:", error);
    //             // Check the reason for error and take appropriate action.
    //         }
    //     );

    // }

    // runAgora() {
    //     console.log('run agora')
    //     // Params for login
    //     let options = {
    //         uid: "",
    //         token: ""
    //     }

    //     // Your app ID
    //     // const appID = "42bc81225ec74b7181e0206d572000f5"
    //     const appID = 'da110372214d44339dd96c41ef9b76be'; // lefteris

    //     // Your token
    //     // options.token = "006ca4b07f7a2f443809470cf138bd5fe4eIADAiPtt3BKL0l3jHnZvHzewPWbnfdjRm6Can3nOy+dq/3ZXrgMAAAAAEADaJAv9c0TFYgEAAQBzRMVi"
    //     // options.token = "006ca4b07f7a2f443809470cf138bd5fe4eIAD7KeKlzEgCk8RtVcqf/MupONsuaJrRaGr+RAg00e0Jl56FuVQAAAAAEADaJAv9Fl/FYgEAAQAWX8Vi"

    //     // mariamat
    //     // options.token = "006ca4b07f7a2f443809470cf138bd5fe4eIAC4lGCEO+RhWP5NnJFQXle6ouYgMLqsMDKtasRIa0nV8HKz3jkAAAAAEAC70EEa5mPFYgEA6APmY8Vi"

    //     // options.uid = '843075275';
    //     // options.token = "00642bc81225ec74b7181e0206d572000f5IADIxe7n8DB1bzb9TOSGskU7KW8bAbkMV78RJKCdLQqbNm2qOdoAAAAAEAC70EEaB3rFYgEA6AMHesVi"

    //     // options.uid = '843075276'
    //     // options.token = "00642bc81225ec74b7181e0206d572000f5IABeU8qE+MUPD+Tq+XDsBBFHZR2nG4Q6Jc7e0KfT6Qz6G9f7MEMAAAAAEAC70EEaSnrFYgEA6ANKesVi"

    //     // lefteris test
    //     options.uid = 'user1';
    //     options.token = '006da110372214d44339dd96c41ef9b76beIAAUUMrwzE3Bm4iXSdL53T68VKm+pzJYJ1xRgHEBswTmA1WFUYwAAAAAEACFNgDlxG3JYgEA6APEbcli';

    //     // Initialize client
    //     const client = AgoraRTM.createInstance(appID);

    //     // Client Event listeners
    //     // Display messages from peer
    //     client.on('MessageFromPeer', function (message, peerId) {

    //         console.log("Message from: " + peerId + " Message: ");
    //         console.log(message);
    //         document.getElementById("log").appendChild(document.createElement('div')).append("Message from: " + peerId + " Message: " + message['text'])
    //     })
    //     // Display connection state changes
    //     client.on('ConnectionStateChanged', function (state, reason) {

    //         console.log("State changed To: " + state + " Reason: " + reason);
    //         document.getElementById("log").appendChild(document.createElement('div')).append("State changed To: " + state + " Reason: " + reason)

    //     })

    //     let channel = client.createChannel("demoChannel")
    //     console.log(channel)

    //     //     // leave channel
    //     //     document.getElementById("leave").onclick = async function () {

    //     //         if (channel != null) {
    //     //             await channel.leave()
    //     //         }

    //     //         else {
    //     //             console.log("Channel is empty")
    //     //         }

    //     //     }



    //     //     // send peer-to-peer message
    //     //     document.getElementById("send_peer_message").onclick = async function () {

    //     // Button behavior
    //     // window.onload = function () {
    //     // Buttons
    //     // login
    //     // console.log('load');
    //     // document.getElementById("login").onclick = async () => {
    //     //     console.log('login');
    //     //     // let uidElement = document.getElementById("userID") as HTMLInputElement;
    //     //     // options.uid = uidElement.value.toString();
    //     //     console.log(options);

    //     //     console.log((<HTMLInputElement>document.getElementById('userID')).value)
    //     //     console.log((<HTMLInputElement>document.getElementById('userToken')).value)
    //     //     options.uid = (<HTMLInputElement>document.getElementById('userID')).value;
    //     //     options.token = (<HTMLInputElement>document.getElementById('userToken')).value;

    //     //     await client.login(options)
    //     // this.login();
    // }

    //         await client.sendMessageToPeer(
    //             { text: peerMessage },
    //             peerId,
    //         ).then(sendResult => {
    //             if (sendResult.hasPeerReceived) {

    //                 console.log("Message has been received by: " + peerId + " Message: " + peerMessage);
    //                 document.getElementById("log").appendChild(document.createElement('div')).append("Message has been received by: " + peerId + " Message: " + peerMessage)

    //             } else {

    //                 console.log("Message sent to: " + peerId + " Message: " + peerMessage);
    //                 document.getElementById("log").appendChild(document.createElement('div')).append("Message sent to: " + peerId + " Message: " + peerMessage)

    //             }
    //         })
    //     }

    //     // send channel message
    //     document.getElementById("send_channel_message").onclick = async function () {

    //         let channelMessageElement = document.getElementById("channelMessage") as HTMLInputElement;
    //         let channelMessage = channelMessageElement.value.toString();

    //         if (channel != null) {
    //             await channel.sendMessage({ text: channelMessage }).then(() => {

    //                 document.getElementById("log").appendChild(document.createElement('div')).append("Channel message: " + channelMessage + " from " + channel.channelId)

    //             }

    // await client.sendMessageToPeer(
    //     { text: peerMessage },
    //     peerId,
    //     { enableHistoricalMessaging: true }                
    // ).then(sendResult => {
    //     if (sendResult.hasPeerReceived) {

    // runAgoraCall() {
    //     let rtc = {
    //         localAudioTrack: null,
    //         client: null
    //     };

    //     let options = {
    //         // Pass your App ID here.
    //         appId: "ca4b07f7a2f443809470cf138bd5fe4e",
    //         // Set the channel name.
    //         channel: "test",
    //         // Pass your temp token here.
    //         token: "006ca4b07f7a2f443809470cf138bd5fe4eIADAiPtt3BKL0l3jHnZvHzewPWbnfdjRm6Can3nOy+dq/3ZXrgMAAAAAEADaJAv9c0TFYgEAAQBzRMVi",
    //         // Set the user ID.
    //         uid: 123456
    //     };

    //     async function startBasicCall() {
    //         // Create an AgoraRTCClient object.
    //         rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    //         // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
    //             // If the remote user publishes an audio track.
    //             if (mediaType === "audio") {
    //                 // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
    //                 const remoteAudioTrack = user.audioTrack;
    //                 // Play the remote audio track.
    //                 remoteAudioTrack.play();
    //             }

    //             // Listen for the "user-unpublished" event
    //             rtc.client.on("user-unpublished", async (user) => {
    //                 // Unsubscribe from the tracks of the remote user.
    //                 await rtc.client.unsubscribe(user);
    //             });

    //         });
    // { enableHistoricalMessaging: true }
    //             await rtc.client.join(options.appId, options.channel, options.token, options.uid);
    //         }
    //             // Leave the channel.
    // console.log('message')
    //     }

    // runAgoraCall() {
    //     let rtc = {
    //         localAudioTrack: null,
    //         client: null
    //     };
    //     //     startBasicCall()
    // }

    CCsignup() {
        // logins mariamat, mariamat2
        const userProfile = {
            login: this.username,
            password: "awesomepwd",
            email: "awesomemat2@gmail.com",
            full_name: "Maria Mat 2",
            phone: "47802323142",
            website: "https://dozensofdreams.com",
            tag_list: ["iphone", "apple"],
            custom_data: JSON.stringify({ middle_name: "Bartoleo" })
        };

        ConnectyCube.users
            .signup(userProfile)
            .then((user) => { console.log(user); })
            .catch((error) => { console.log(error); });
    }

    initConnectyCube() {
        const CREDENTIALS = {
            appId: 6396,
            authKey: "4QsX-4LxXsjvZRW",
            authSecret: "AFAMdGnuhcHMKYe",
        };
        const CONFIG = {
            debug: { mode: 1 }, // enable DEBUG mode (mode 0 is logs off, mode 1 -> console.log())
        };

        ConnectyCube.init(CREDENTIALS, CONFIG);

        ConnectyCube.createSession()
            .then((session) => { console.log(session); })
            .catch((error) => { console.log(error); });

        // const userCredentials = {
        //     userId: 4448514,
        //     password: "awesomepwd",
        // };

        // ConnectyCube.chat
        //     .connect(userCredentials)
        //     .then(() => {
        //         // connected
        //         console.log('usr connected');
        //     })
        //     .catch((error) => { console.log('error'); });

        // const isConnected = ConnectyCube.chat.isConnected;
        // console.log(isConnected);
    }

    public connectToChat(id: number, password: string) {
        const userCredentials = {
            userId: id,
            password: password,
        };

        return ConnectyCube.chat.connect(userCredentials);
    }

    CClogin() {
        const pass = 'awesomepwd'
        const userCredentials = { login: this.username, password: pass };
        // const userCredentials = { email: 'cubeuser@gmail.com', password: 'awesomepwd' };
        // const userCredentials = { provider: 'facebook', keys: {token: 'a876as7db...asg34dasd8wqe'} };

        ConnectyCube.login(userCredentials)
            .then((user) => {
                console.log(user);

                const userCredentials = { userId: user.id, password: pass };

                // console.log('ConnectyCube');
                // console.log(ConnectyCube.chat.onMessageListener);
                // console.log(ConnectyCube);
                // console.log('ConnectyCube');
                // console.log(ConnectyCube.chat.onMessageListener);
                ConnectyCube.chat
                    .connect(userCredentials)
                    .then(() => {
                        // connected
                        console.log(ConnectyCube.chat.isConnected);
                        console.log(ConnectyCube.chat);

                        ConnectyCube.chat.onMessageListener = function onMessage(userId, message) {
                            console.log('[ConnectyCube.chat.onMessageListener] callback:', userId, message)
                            console.log(message);
                            console.log(userId);
                        };

                        ConnectyCube.videochat.onCallListener = (session, extension) => {
                            console.log(session);
                            console.log(extension);
                            console.log('call');
                            this.session = session;
                        };

                        ConnectyCube.videochat.onUserNotAnswerListener = function (session, userId) {
                            console.log(session);
                            console.log(userId);
                            console.log('no answer');
                        };

                        ConnectyCube.videochat.onAcceptCallListener = function (session, userId, extension) {
                            console.log(session);
                            console.log(userId);
                            console.log(extension);
                            console.log('accept');
                        };

                        ConnectyCube.videochat.onRemoteStreamListener = function (session, userID, remoteStream) {
                            // attach the remote stream to DOM element
                            console.log(session);
                            console.log(userID);
                            console.log(remoteStream);
                            session.attachMediaStream("myVideoElementId", remoteStream);
                        };


                    })
                    .catch((error) => { console.log(error); });
            })
            .catch((error) => { console.log(error); });

        const isConnected = ConnectyCube.chat.isConnected;
    }

    CCisConnected() {
        console.log(ConnectyCube.chat.isConnected);
    }

    CCCreateChat() {
        // 6056736 mariamat2
        const params = {
            type: 3,
            occupants_ids: [6056736],
        };

        ConnectyCube.chat.dialog
            .create(params)
            .then((dialog) => { })
            .catch((error) => { });
    }

    CCGetChats() {
        const filters = {};
        ConnectyCube.chat.dialog
            .list(filters)
            .then((result) => { console.log(result); })
            .catch((error) => { console.log(error); });
    }

    sendMsg() {
        // 6056692 1, 6056736 2
        // const dialog = ...;
        let opponentId = 6056692;
        if (this.username === 'mariamat') { opponentId = 6056736 }
        const message = {
            type: 'chat',
            body: "test",
            extension: {
                save_to_history: 1,
                dialog_id: '62cbcb2083afe10057ca6b8d'
            },
            markable: 1
        };

        message['id'] = ConnectyCube.chat.send(opponentId, message);

        // ...

    }

    history() {
        const dialogId = "62cbcb2083afe10057ca6b8d";
        const params = {
            chat_dialog_id: dialogId,
            sort_desc: "date_sent",
            limit: 100,
            skip: 0,
        };

        ConnectyCube.chat.message
            .list(params)
            .then((messages) => { console.log(messages); })
            .catch((error) => { });
    }

    createVideoSession() {
        let opponentId = 6056692;
        if (this.username === 'mariamat') { opponentId = 6056736 }
        const calleesIds = [opponentId]; // User's ids
        // const calleesIds = [6056692, 6056736]; // User's ids
        const sessionType = ConnectyCube.videochat.CallType.AUDIO; // AUDIO is also possible
        const additionalOptions = {};
        const session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);

        const mediaParams = {
            audio: true,
            video: true
        };

        session
            .getUserMedia(mediaParams)
            .then((localStream) => {
                console.log(session);
                console.log(localStream);
                // session.attachMediaStream("myVideoElementId", localStream, {
                //     muted: true,
                //     mirror: true,
                // });
                session.call(extension, (error) => { console.log(error); });
            })
            .catch((error) => { console.log(error); });

        const extension = {};

    }

    acceptCall() {
        const mediaParams = {
            audio: true,
            video: true
        };
        this.session
            .getUserMedia(mediaParams)
            .then((localStream) => {
                console.log(this.session);
                console.log(localStream);
                const extension = {};
                this.session.accept(extension);
            })
            .catch((error) => { console.log(error); });
    }

    ngOnInit() {
        const username = this.globals.userName;
        // this.runAgora();
        this.initConnectyCube();
        // this.runAgora();
        // this.runAgoraCall();
        // this.initCometChat();
        // // this.createCometChatUser();
        // this.loginCometChatUser();
        // setTimeout(() => {
        //     this.listenForCometChatMessages();
        // }, 2000);

        // Pusher
        // var pusher = new Pusher("dc16a43b076bb87931ed", {
        //     cluster: "eu",
        // });

        // this.pusher = new Pusher('dc16a43b076bb87931ed', {
        //     cluster: 'eu',
        // });

        // this.pusher = new Pusher({
        //     appId: "1429209",
        //     key: "dc16a43b076bb87931ed",
        //     secret: "b69a0e343d1cb2c4607c",
        //     cluster: "eu",
        // });

        // PubNub
        // this.pubnub = new PubNub({
        //     publishKey: "pub-c-601e464a-52a0-4d6e-ac23-5ff34a84d9a6",
        //     subscribeKey: "sub-c-7123eefc-7dbd-4b58-a1e8-5c399c170738",
        //     uuid: username
        // });
        // this.listenForPubNubMessages();
    }

    ngAfterViewInit() {
    }
}
