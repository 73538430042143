import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DataService } from '@app/services/data.service';

@Component({
    selector: 'app-payment-status-select-editor',
    templateUrl: './payment-status-select-editor.component.html'
})
export class PaymentStatusSelectEditorComponent implements OnInit {
    listen = [];

    statuses = [];
    statusPerStopPointMap = {};
    currentStopPointId;
    currentPayOnDeliveryData = {};

    constructor(
        public globals: Globals,
        public dataService: DataService,
        public translate: TranslateService,
    ) { }

    onChangeSelect() {
    }

    public agInit(params: any): void {
        this.statuses = this.dataService.payOnDeliveryStatuses;
        this.statusPerStopPointMap = this.dataService.statusPerStopPointMap;
        this.currentStopPointId = params.data.id;
        this.currentPayOnDeliveryData = params.data.payOnDelivery;
    }

    public getValue(): any {
        const currentStopPointStatus = this.statusPerStopPointMap[this.currentStopPointId];
        currentStopPointStatus['payOnDeliveryValue'] = this.currentPayOnDeliveryData['payOnDeliveryValue'];
        return currentStopPointStatus;
    }

    getTranslations() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
