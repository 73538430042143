import { LM_BAR_CHART_HEART_OPTIONS, LM_BAR_CHART_MONEY_OPTIONS, LM_BAR_CHART_RATING_OPTIONS, LM_STACKED_CHART_BORDER_RADIUS } from "@app/model/charts-config";
import { ILmChartWidgetTab } from "@app/model/widget";

export const SCORE: ILmChartWidgetTab = {
    id: 'score',
    title: 'Productivity',
    subtitle: 'Score',
    btn:{id: 'score', icon: 'lm-icon icon-star', textOnly: true, color: "#ccc", activeColor: '#ffc107', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#fac505', '#fac505', '#c7980a'],
                data: [],
            }
        ]
    }, 
    filters: [],
    chartOptions: LM_BAR_CHART_RATING_OPTIONS,
    chartType: 'bar'
};

export const VISITED_STOPS_PER_HOUR: ILmChartWidgetTab = {
    id: 'visitedPerHour',
    title: 'Productivity',
    subtitle: 'Visited stop points per hour',
    btn:{id: 'visitedPerHour', icon: 'lm-icon icon-stoppointprocessing', textOnly: true, color: "#ccc", activeColor: '#ba6ef5', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#940bff', '#ba6ef5'],
                data: [],
            }
        ]
    }, 
    chartType: 'bar'
};

export const PACKAGES: ILmChartWidgetTab = {
    id: 'packages',
    title: 'Productivity',
    subtitle: 'Packages',
    btn:{id: 'packages', icon: 'lm-icon icon-box', textOnly: true, color: "#ccc", activeColor: '#de921f', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#de921f', '#ffb84e'],
                data: [],
            }
        ]
    }, 
    chartType: 'bar'
};

export const AVERAGE_DELAY: ILmChartWidgetTab = {
    id: 'averageDelay',
    title: 'Productivity',
    subtitle: 'Average delay',
    btn:{id: 'averageDelay', icon: 'lm-icon icon-timer', textOnly: true, color: "#ccc", activeColor: '#ba6ef5', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#940bff', '#ba6ef5'],
                data: [],
            }
        ]
    }, 
    chartType: 'bar'
};

export const COST_PER_SHIPMENT: ILmChartWidgetTab = {
    id: 'costPerShipment',
    title: 'Productivity',
    subtitle: 'Cost per shipment',
    btn:{id: 'costPerShipment', icon: 'lm-icon icon-dollar-coin', textOnly: true, color: "#ccc", activeColor: '#1c90d4', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#1c90d4', '#51bfff'],
                data: [],
            }
        ]
    },
    chartOptions: LM_BAR_CHART_MONEY_OPTIONS,
    chartType: 'bar'
};

export const RECIPIENT_EXPERIENCE: ILmChartWidgetTab = {
    id: 'recipientExprerience',
    title: 'Productivity',
    subtitle: 'Recipient experience',
    btn:{id: 'recipientExprerience', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#c74c96', active: true},
    data: {
        labels: [],
        datasets: [
            {
                backgroundColor: ['#b64388', '#faa6d9'],
                data: [],
            }
        ]
    },
    chartOptions: LM_BAR_CHART_HEART_OPTIONS,
    chartType: 'bar'
};
