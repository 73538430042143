import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private openDepotModalListeners = new Subject<any>();
  private openDynamicFieldModalListeners = new Subject<any>();
  private openPackagingModalListeners = new Subject<any>();
  private updateDepotsGridListeners = new Subject<any>();
  private updateDynamicFieldsGridListeners = new Subject<any>();
  private updatePackagingGridListeners = new Subject<any>();
  private updateRulesGridListeners = new Subject<any>();
  private updateTimeWindowGridListeners = new Subject<any>();
  private updateCustomerCollaboratorsGridListeners = new Subject<any>();

  openDepotModalListen(): Observable<any> {
    return this.openDepotModalListeners.asObservable();
  }
  openDynamicFieldModalListen(): Observable<any> {
    return this.openDynamicFieldModalListeners.asObservable();
  }
  openPackagingModalListen(): Observable<any> {
    return this.openPackagingModalListeners.asObservable();
  }
  updateDepotsGridListen(): Observable<any> {
    return this.updateDepotsGridListeners.asObservable();
  }
  updateDynamicFieldsGridListen(): Observable<any> {
    return this.updateDynamicFieldsGridListeners.asObservable();
  }
  updatePackagingGridListen(): Observable<any> {
    return this.updatePackagingGridListeners.asObservable();
  }
  updateRulesGridListen(): Observable<any> {
    return this.updateRulesGridListeners.asObservable();
  }
  updateTimeWindowGridListen(): Observable<any> {
    return this.updateTimeWindowGridListeners.asObservable();
  }
  updateCustomerCollaboratorsGridListen(): Observable<any> {
    return this.updateCustomerCollaboratorsGridListeners.asObservable();
  }

  openDepotModal(data) {
    this.openDepotModalListeners.next(data);
  }
  openDynamicFieldModal() {
    this.openDynamicFieldModalListeners.next(1);
  }
  openPackagingModal() {
    this.openPackagingModalListeners.next(1);
  }
  updateDepotsGrid() {
    this.updateDepotsGridListeners.next(1);
  }
  updateDynamicFieldsGrid() {
    this.updateDynamicFieldsGridListeners.next(1);
  }
  updatePackagingGrid() {
    this.updatePackagingGridListeners.next(1);
  }
  updateRulesGrid() {
    this.updateRulesGridListeners.next(1);
  }
  updateTimeWindowGrid() {
    this.updateTimeWindowGridListeners.next(1);
  }
  updateCustomerCollaboratorsGrid() {
    this.updateCustomerCollaboratorsGridListeners.next(1);
  }
}
