
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import {LmButtonConfig, LmButtonModule} from '../button/button.component'
import { LMSwitchModule } from '@app/shared/controls/input/switch/switch.component';
import { ILmWidgetFilterConfig } from '@app/model/widget';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'lm-widget-header',
    template: `
        <div class="lm-widget-header">
            <div class="lm-widget-label">
                <div class="lm-widget-title">{{title}}</div>
                <div class="font-small lm-txt-color-grey lm-trim">{{subtitle}}</div>
            </div>
            
            <div class="lm-widget-filters" *ngIf="filters && !!filters.length && !hideFilters">
                <span *ngFor="let filter of filters" class="lm-pdr5 lm-pdl5">
                    <lm-switch class="lm-widget-filter" label="{{filter.label}}" [trueValue]="true" [falseValue]="false" [(ngModel)]="filter.on" (ngModelChange)="onFilterChange(filter)"></lm-switch>
                </span>
            </div>
            <div class="lm-widget-tabs" *ngIf="tabs && !!tabs.length && !hideTabs">
                <span *ngFor="let tab of tabs">
                    <lm-button class="lm-widget-tab" [ngStyle]="{'color': tab.active ? tab.activeColor : tab.color}" [config]="tab" (click)="tab.command(tab)"></lm-button>
                </span>
            </div>
        </div>
    `,
    styles:[`
        :host ::ng-deep button:enabled:active{background: none !important}
    `]
})

export class LmWidgetHeaderComponent implements OnInit, AfterViewInit, OnDestroy{
    @Input() title: string;
    @Input() titleIcon?: string;
    @Input() subtitle?: string;
    @Input() tabs?: LmButtonConfig[];
    @Input() hideTabs = false;
    @Input() filters?: ILmWidgetFilterConfig[];
    @Input() hideFilters = false;
    @Input() filterFn: (...args) => void;


    onFilterChange(filter){
        if(this.filterFn) this.filterFn(filter)
    }

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {}

}

@NgModule({
  imports: [CommonModule, FormsModule, LmButtonModule, LMSwitchModule],
  exports: [LmWidgetHeaderComponent],
  declarations: [LmWidgetHeaderComponent]
})
export class LmWidgetHeaderModule {}
