import { Globals } from './../../services/globals';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CustomerFormComponent } from '@app/forms/customer-form/customer-form.component';
import { ModalService } from '@app/services/modal.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.scss']
})
export class CustomerModalComponent implements OnInit, OnDestroy {

  @ViewChild(CustomerFormComponent, { static: false }) customerFormComponent: CustomerFormComponent;
  listen = [];

  customerId = null;
  dataLoading = false;
  isFormValid = false;

  modalOpen = false;
  canEditOrDelete = false;

  constructor(
    public globals: Globals,
    private http: HttpClient,
    private modalService: ModalService,
    private modalGridService: ModalGridService,
    public genericService: GenericService,
  ) {
    this.canEditOrDelete = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] || this.globals.accessRole == this.globals.teamMemberTypeConstants['ADVANCED'];
    this.listen.push(this.modalService.openCustomerModalListen().subscribe((customerId: number) => {
      this.loadAndOpenCustomerModal(customerId);
    }));
    this.listen.push(this.modalGridService.listenCloseRightModal().subscribe((response: any) => {
      this.closeModal();
    }));
  }

  loadAndOpenCustomerModal(customerId) {
    this.openModal();
    setTimeout(() => {
      this.customerId = customerId;
      this.customerFormComponent.customerId = customerId;
      if (customerId) {
        this.customerFormComponent.getCustomerData(customerId);
      } else {
        this.customerFormComponent.resetForm();
      }
    }, 100);
  }

  dataLoadingListen(event) {
    if (event === 'true') {
      this.dataLoading = true;
    } else if (event === 'false') {
      this.dataLoading = false;
      this.customerFormComponent.isFormValid = true;
    }
  }

  updateFormValidityListen(event) {
    if (event === 'true') {
      this.isFormValid = true;
    } else if (event === 'false') {
      this.isFormValid = false;
    }
  }

  test() {
    console.log(this.customerFormComponent.myForm.value)
    console.log(this.customerFormComponent.isFormValid);
  }

  deleteCustomer() {
    this.customerFormComponent.isClickedOnce = true;
    const myObserver = {
      next: (response) => {
        this.modalGridService.updateCustomerGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.customerFormComponent.isClickedOnce = false;
        this.customerFormComponent.resetForm();
        this.modalGridService.closeRightModal();
      },
    };
    this.http.delete('api/v1/customers/' + this.customerId).pipe(take(1)).subscribe(myObserver);
  }

  submitCustomer() {
    this.customerFormComponent.submitCustomerForm();
  }

  openModal() {
    this.genericService.comm100ZIndexFix();
    this.modalOpen = true;
    setTimeout(() => {
      const modal = document.querySelector('.customer-modal');
      const modalBackground = document.querySelector('.customer-modal-background');
      modal.classList.add('open');
      modal.classList.remove('closed');
      modalBackground.classList.remove('hidden');
      this.customerFormComponent.initMap();
      this.customerFormComponent.initializeSelect();
    }, 100);
  }

  closeModal() {
    const modal = document.querySelector('.customer-modal');
    const modalBackground = document.querySelector('.customer-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.customerFormComponent.resetForm();
    setTimeout(() => {
      this.modalOpen = false;
    }, 1000);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
