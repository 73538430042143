import { InjectionToken, Renderer2 } from '@angular/core';
import { hasValue, valueIsAlphaNumeric, valueIsAlphabetical, valueIsEmail, valueIsInteger, valueIsNumber } from '@app/shared/utils';
import { Observable, of } from 'rxjs';
import { take, tap, switchMap, concatMap } from 'rxjs/operators';
import { LmModelProxyService } from './model-proxy/model-proxy.service';
import { LmModelValidationService } from './model-proxy/model-validation.service';
import { LmNotificationService } from './notification.service';

export interface IFormVmValidator {
  id: string;
  model: any;
  modelSnapshot: any;
  modelProxySvc: LmModelProxyService<any>;
  modelValidationSvc: LmModelValidationService;
  msgTitle: string;
  msgs: IFormVmValidatorMessages;
  updateModel: (...args) => void;
  valueIsAlphabetical: (...args) => boolean;
  valueIsInteger: (...args) => boolean;
  valueIsNumber: (...args) => boolean;
  valueIsAlphaNumeric: (...args) => boolean;
  valueIsEmail: (...args) => boolean;
  testAlphabetical: (...args) => void;
  testInteger: (...args) => void;
  testNumeric: (...args) => void;
  testAlphaNumeric: (...args) => void;
  testEmail: (...args) => void;
  testEmptyValue: (...args) => boolean | void;
  init$: (...args) => Observable<any>;
}

export interface IFormVmValidatorMessages {
  empty: string;
  alphabetical: string;
  integer: string;
  numeric: string;
  alphaNumeric: string;
  email: string;
}

export const FORM_VM_VALIDATOR = new InjectionToken('FormVmValidator');

export const FormVmValidationFactory = (injector, renderer?) => {
  const stylize = (el, prop, val) =>  renderer.setStyle(el, prop, val);
  const stylizeInvalid = (el?) => stylize(el, 'border', '1px solid #f00000');
  const classifyInvalid = (el?) =>  el ?? renderer.addClass(el, '__invalid');
  const classifyValid = (el?) => el ?? renderer.removeClass(el, '__invalid');

  const modelProxySvc = injector.get(LmModelProxyService);
  const modelValidationSvc = injector.get(LmModelValidationService, null);
  const notificationSvc = injector.get(LmNotificationService);
  const msgTitle = 'Invalid value!'
  const msgTitleEmpty = 'Value missing!'
  const msgs = {
    empty: 'Please fill in the required fields!',
    alphabetical: 'Value must contain alphabetical characters!',
    integer: 'Value must contain integer characters!',
    numeric: 'Value must contain numeric characters!',
    alphaNumeric: 'Value must contain alhpabetical and numeric characters!',
    email: 'Value is not a valid email!'
  }
  
  const testEmptyValue = (_value, cb?, title=msgTitleEmpty, msg=msgs.empty, comp?) => {
    if(!hasValue(_value)) notificationSvc.showWarning(title,msg);
    // if(!hasValue(_value)) {
    //   notificationSvc.showWarning(title,msg);
    //   classifyInvalid(comp?.ctrl?.el.nativeElement);
    // }
    // else classifyValid(comp?.ctrl?.el.nativeElement)
    if(cb) cb(); 
    return hasValue(_value);
  }

  const testAlphabetical = (_value, cb?, title=msgTitle, msg=msgs.alphabetical, comp?) => {
    if(!valueIsAlphabetical(_value)) notificationSvc.showWarning(title,msg);
    if(cb) cb(); 
  }

  const testInteger = (_value, cb?, title=msgTitle, msg=msgs.integer, comp?) => {
    if(!valueIsInteger(_value)) notificationSvc.showWarning(title,msg);
    if(cb) cb(); 
  }

  const testNumeric = (_value, cb?, title=msgTitle, msg=msgs.numeric, comp?) => {
    if(!valueIsNumber(_value)) notificationSvc.showWarning(title,msg);
    if(cb) cb(); 
  }

  const testAlphaNumeric = (_value, cb?, title=msgTitle, msg=msgs.alphaNumeric, comp?) => {
    if(!valueIsAlphaNumeric(_value)) notificationSvc.showWarning(title,msg);

    
    if(cb) cb(); 
  }

  const testEmail = (_value, cb?, title=msgTitle, msg=msgs.email) => {
    if(!valueIsEmail(_value)) notificationSvc.showWarning(title,msg);
    if(cb) cb(); 
  }


  return new (function() {
    const self = this;

    function engage$(model) {
      return of(null).pipe(
        switchMap(() => of(model)),
        take(1)
      );
    }


    this.id = undefined;
    this.model = undefined;
    this.modelSnapshot = undefined;
    this.modelProxySvc = modelProxySvc;
    this.modelValidationSvc = modelValidationSvc;
    
    this.updateModel = (target: any, resetValidations: boolean) => {
      if (resetValidations && this.modelValidationSvc) {
        this.modelValidationSvc.removeValidations();
      }
      this.modelProxySvc.setTarget(target);
      this.model = this.modelProxySvc.proxy;
    }

  //   this.setupModelProxy = () => {
  //     this.modelProxySvc.modelChanged = this.modelChanged.bind(this);
  //     this.modelProxySvc.modelChanging = this.modelChanging.bind(this);
  //     this.modelProxySvc.detailAdded = this.detailAdded.bind(this);
  //     this.modelProxySvc.detailRemoved = this.detailRemoved.bind(this);
  // }
    
    
    this.init$ = (target) => engage$(target).pipe(tap((res) => self.updateModel(res, false)));
    this.msgTitle = msgTitle;
    this.msgs = msgs;

    this.valueIsAlphabetical = valueIsAlphabetical;
    this.valueIsInteger = valueIsInteger;
    this.valueIsNumber = valueIsNumber;
    this.valueIsAlphaNumeric = valueIsAlphaNumeric;
    this.valueIsEmail = valueIsEmail;

    this.testEmptyValue = testEmptyValue;
    this.testAlphabetical = testAlphabetical;
    this.testInteger = testInteger;
    this.testAlphaNumeric = testAlphaNumeric;
    this.testNumeric = testNumeric;
    this.testEmail = testEmail;

    // this.stylize = ()
  })();
};
