import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { LmSelect2Component } from '../controls/input/select2/select.component';
import { Observable, of } from 'rxjs';
import { hasValue } from '../utils';

@Pipe({
  name: 'lmSelectorDisplayValue'
})
export class LmSelectorDisplayValuePipe implements PipeTransform {
  constructor() {}

  transform(item: any, selectorDisplayValue: (item: any) => Observable<any>, label: string, ellipsis: boolean, host: LmSelect2Component): Observable<string> {
    if (ellipsis) {
      if (label.length > 100) {
        (host as any).elRef.nativeElement.title = label;
        label = label.substring(0, 100) + '...';
      } else {
        (host as any).elRef.nativeElement.title = '';
      }
    }

    if (!hasValue(label)) {
      return of(label);
    }

    if (!!selectorDisplayValue) {
      if (ellipsis && item.description.length > 100) {
        item.description = item.description.substring(0, 100) + '...';
      }
      return selectorDisplayValue(item);
    } else {
      if (ellipsis) {
        label = label.substring(0, 100) + '...';
      }
      return of(label);
    }
  }
}

@NgModule({
  imports: [],
  exports: [LmSelectorDisplayValuePipe],
  declarations: [LmSelectorDisplayValuePipe]
})
export class LmSelectorDisplayValueModule {}
