import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ImporterService } from '@app/services/importer.service';
import { MapService } from '@app/services/map.service';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ColourService } from '@app/services/colour.service';

declare var H: any;

@Component({
    selector: 'transportation-regions-map',
    templateUrl: './transportation-regions-map.component.html',
    styleUrls: ['./transportation-regions-map.component.scss']
})
export class TransportationRegionsMapComponent implements OnInit, AfterViewInit, OnDestroy {

    private platform: any;
    @ViewChild('map', { static: false }) public mapElement: ElementRef;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @Output() polygonChanged = new EventEmitter<string>();

    map;
    ui;
    behavior;

    listen = [];

    depots = [];
    depotsGroup = new H.map.Group();
    polygonGroup = new H.map.Group();
    polygonMarkersGroup = new H.map.Group();
    polygonLineString = new H.geo.LineString();
    polygonVerticesCoords = [];
    polygonLineStringPolyline;

    drawModeEnabled = true;

    constructor(
        public translate: TranslateService,
        private mapService: MapService,
        public globals: Globals,
        private colourService: ColourService,
    ) {
        this.platform = this.mapService.platform;
    }

    emptyMap() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonMarkersGroup.removeAll();
        this.polygonLineString = new H.geo.LineString();
        this.polygonGroup.removeAll();
    }

    emptyDrawMode() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonLineString = new H.geo.LineString();
        this.polygonMarkersGroup.removeAll();
        const polygons = this.polygonGroup.getObjects();
        polygons.forEach(mainGroup => {
            if (!mainGroup.getVisibility()) {
                mainGroup.setVisibility(true);
            }
        });
    }

    getPolygonCount() {
        return this.polygonGroup.getObjects().length;
    }

    centerToPolygon(id) {
        let bounds = this.polygonGroup.getBoundingBox();
        if (id) {
            const polygons = this.polygonGroup.getObjects();
            const mainGroups = [];
            polygons.forEach(mainGroup => {
                if (mainGroup.getData().id === id) {
                    bounds = mainGroup.getBoundingBox();
                }
            });
        }
        if (bounds) {
            if (this.map) {
                this.map.getViewModel().setLookAtData({ bounds: bounds }, true);
            } else {
                console.error('The map is not yet initialized.');
            }
        } else {
            console.error('This polygon group has no bounds.');
        }
    }

    createResizablePolygon(lineString, colour, id, isEditable = true) {
        const rgb = this.colourService.hexToRGB(colour.replace('#', ''));
        const rgbaColour = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.3)';
        const circleIcon = this.svgIconsComponent.svgCircleColour.replace('markerColour', colour);
        const polygonData = this.mapService.createPolygonMainGroup(lineString, circleIcon, id, rgbaColour, colour);
        const verticeGroup = polygonData.verticeGroup;
        const mainGroup = polygonData.mainGroup;
        const polygon = polygonData.polygon;
        this.polygonGroup.addObject(mainGroup);
        // this.polygonWKT = mainGroup.getObjects()[0].getGeometry().toString();
        // add group with polygon and it's vertices (markers) on the map
        // this.map.addObject(this.polygonGroup);
        verticeGroup.setData({ id: id });

        if (isEditable && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
            // event listener for main group to show markers if moved in with mouse (or touched on touch devices)
            mainGroup.addEventListener('pointerenter', function (evt) {
                // show vertice markers
                verticeGroup.setVisibility(true);
            }, true);

            // event listener for main group to hide vertice markers if moved out with mouse (or released finger on touch devices)
            // the vertice markers are hidden on touch devices after specific timeout
            this.polygonGroup.addEventListener('pointerleave', function (evt) {
                const timeout = (evt.currentPointer.type === 'touch') ? 1000 : 0;
                // hide vertice markers
                verticeGroup.setVisibility(false);
            }, true);

            // event listener for vertice markers group to change the cursor to pointer
            verticeGroup.addEventListener('pointerenter', function (evt) {
                document.body.style.cursor = 'pointer';
            }, true);

            // event listener for vertice markers group to change the cursor to default
            verticeGroup.addEventListener('pointerleave', function (evt) {
                document.body.style.cursor = 'default';
            }, true);
        }

        // event listener for vertice markers group to resize the geo polygon object if dragging over markers
        verticeGroup.addEventListener('drag', (evt) => {
            const pointer = evt.currentPointer,
                geoLineString = polygon.getGeometry().getExterior(),
                geoPoint = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

            // set new position for vertice marker
            evt.target.setGeometry(geoPoint);

            // set new position for polygon's vertice
            geoLineString.removePoint(evt.target.getData()['verticeIndex']);
            geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
            polygon.setGeometry(new H.geo.Polygon(geoLineString));

            // stop propagating the drag event, so the map doesn't move
            evt.stopPropagation();
        }, true);

        // verticeGroup.addEventListener('dragend', (evt) => {
        //     this.polygonChanged.emit('');
        // }, true);
    }

    removePolygon(id){
        const polygons = this.polygonGroup.getObjects();
        const mainGroups = [];
        polygons.forEach(mainGroup => {
            if (mainGroup.getData().id === id) {
                mainGroups.push(mainGroup);
            }
        });
        if (mainGroups.length) {
            mainGroups.forEach(mainGroup => {
                this.polygonGroup.removeObject(mainGroup);
            });
        }
    }

    convertToMultiPolygon(polygons):string{
        let multiPolygon = 'MULTIPOLYGON (';
        polygons.forEach(polygon => {
            const string = polygon.replace('POLYGON ', '');
            multiPolygon+=string;
        });
        multiPolygon += ')'
        return multiPolygon;
    }

    getPolygon():string{
        let polygonWKT = '';
        const polygons = this.polygonGroup.getObjects();
        polygons.forEach(mainGroup => {
                mainGroup.getObjects().forEach(polygon => {
                    if (polygon instanceof H.map.Polygon) {
                        polygonWKT = polygon.getGeometry().toString();
                    }
                });
        });
        return this.convertToMultiPolygon([polygonWKT]);
    }

    getBoundingBox():string{
        return this.polygonGroup.getBoundingBox().toString();
    }

    createPolygonMarker(lat, lon) {
        const icon = new H.map.Icon(this.svgIconsComponent.svgCircle);
        const coords = { lat: lat, lng: lon };
        const marker = new H.map.Marker(coords, { icon: icon });
        this.polygonMarkersGroup.addObject(marker);
    }

    createLineString(lat, lon) {
        if (!this.polygonLineStringPolyline) {
            const firstPointCoords = this.polygonVerticesCoords[0];
            this.polygonLineString.pushLatLngAlt(firstPointCoords.lat, firstPointCoords.lng, 0);
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline = new H.map.Polyline(this.polygonLineString, {
                style: {
                    lineWidth: 5,
                    strokeColor: '#00aeba',
                }
            });
            this.map.addObject(this.polygonLineStringPolyline);
        } else {
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline.setGeometry(this.polygonLineString);
        }
    }

    clickOnMap(event) {
        const target = event.target;
        const polygonId = 'custom';
        if (
            this.drawModeEnabled &&
            !(target instanceof H.map.DomMarker) &&
            (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')
        ) {
            const polygons = this.polygonGroup.getObjects();
            if (target instanceof H.map.Marker) {
                if (target.getParentGroup() === this.polygonMarkersGroup) {
                    const colour = this.colourService.colourCalculator(1);
                    this.createResizablePolygon(this.polygonLineString, colour, polygonId);
                    this.polygonChanged.emit('');
                    polygons.forEach(mainGroup => {
                        if (mainGroup.getData().id === polygonId) {
                            this.polygonGroup.removeObject(mainGroup);
                        }
                    });
                    this.emptyDrawMode();
                }
            } else {
                const coords = this.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
                this.polygonVerticesCoords.push(coords);
                if (this.polygonVerticesCoords.length === 1) {
                    polygons.forEach(mainGroup => {
                        if (mainGroup.getData().id === polygonId) {
                            mainGroup.setVisibility(false);
                        }
                    });
                    this.createPolygonMarker(coords.lat, coords.lng);
                } else if (this.polygonVerticesCoords.length === 2) {
                    this.createLineString(coords.lat, coords.lng);
                } else if (this.polygonVerticesCoords.length > 2) {
                    this.createLineString(coords.lat, coords.lng);
                }
            }
        }
    }


    initMap(depotLat, depotLon) {
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 12,
                center: { lat: depotLat, lng: depotLon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.map.addEventListener('tap', this.clickOnMap.bind(this));
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');
        this.map.addObject(this.polygonMarkersGroup);
        this.map.addObject(this.polygonGroup);
        // this.map.addEventListener('tap', this.clickOnMap);
        // this.map.addObject(this.stopPointsDepotsRoutesGroup);

    }

    ngOnInit() {
    }

    public ngAfterViewInit() {
        const dataRefreshIntervalId = setInterval(()=> {
            if (this.globals.depotsDataDone) {
                clearInterval(dataRefreshIntervalId);
                const depotLat = this.globals.currentLat;
                const depotLon = this.globals.currentLon;
                this.initMap(depotLat, depotLon);
            }
        }, 200);
    }

    ngOnDestroy() {
        // this.map.removeEventListener('tap', this.clickOnMap);
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
