import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { ISettings_Addon_Service_Dto } from '@app/api/models/settings-services-dto';
import { SettingsAddonServicesViewModelService } from '../settings-addon-services-viewmodel.service';


@UntilDestroy()
@Component({
  templateUrl: 'new-addon-service-modal.component.html'
})
export class AddonServiceModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: SettingsAddonServicesViewModelService;
  saveBttn: LmButtonConfig;
  item: ISettings_Addon_Service_Dto;

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService
  )
   {
    const {data:{item}} = config;

    this.VM = config.data.VM;
    this.item = item ?? this.VM.newItem()
    this.updateButtons();
    this.saveBttn = {label:'Αποθήκευση', command: () => this.ok()};
  }

  private isFormValid(): boolean {
    return Boolean(this.item.name && (this.item.value != null));
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = () => {
    if (this.isFormValid()) return this.VM.postItem({ addon_charges: [this.item] })
      .pipe(take(1), untilDestroyed(this),)
      .subscribe(_ => this.config.close())
    
  }
  cancel = () => this.config.close();
}

