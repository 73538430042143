import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, NgModule, OnChanges, OnInit } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';

export interface LmProgressCardConfig {
    amount: number;
    amountDefinition?: string;
    progress: number;
    percentage: number;
    iconClass: string; 
    iconSize?: number;
    title: string;
    mainColor: string;
    secondaryColor: string;
  }

@Component({
  selector: 'lm-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss']
})
export class LMProgressCardComponent implements OnInit, OnChanges {
    @Input() options: LmProgressCardConfig;
    progressBgColour = '#cbebe1'
    progressFontColour = '#306152'

    progressIconClass: string;
    progressString: string;

  constructor(private el: ElementRef) {}

  calculateProgressColor(): void {
    if (this.options.progress > 0) {
      this.progressBgColour = '#cbebe1';
      this.progressFontColour = '#306152';
    } else if (this.options.progress < 0) {
      this.progressBgColour = '#efc2ac';
      this.progressFontColour = '#9d3308';
    } else {
      this.progressBgColour = '#eee';
      this.progressFontColour = '#666';
    }
  }

  getProgressIconClass(): string {
    if (this.options.progress > 0) {
      return 'fas fa-arrow-up';
    } else if (this.options.progress < 0) {
      return 'fas fa-arrow-down';
    }
    return 'fas fa-arrow-right';
  }

  formatProgress(): string {
    if (this.options.progress >= 1000) {
      return (this.options.progress / 1000).toFixed(1) + 'K';
    }
    return this.options.progress.toString();
  }

  ngOnInit() {
    this.calculateProgressColor();
    this.progressIconClass = this.getProgressIconClass();
    this.progressString = this.formatProgress();
  }

  ngOnChanges() {
    this.el.nativeElement.style.setProperty('--bar-color', this.options.mainColor || '#1992D4');
  }

}

@NgModule({
  imports: [CommonModule, ProgressBarModule],
  exports: [LMProgressCardComponent],
  declarations: [LMProgressCardComponent]
})
export class LmProgressCardModule {}
