import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { GuideTourService } from '@app/services/guide-tour.service';

@Component({
    selector: 'app-drivers-grid-guided-tour',
    template: ''
})

export class DriversGridGuidedTourComponent implements OnInit, AfterViewInit, OnDestroy {

    listen = [];

    defaultButtons = [];
    firstButtons = [];
    lastButtons = [];

    nextMsg = '';
    backMsg = '';
    finishMsg = '';

    newDriverMsg = '';
    newDriverDescriptionMsg = '';

    driversListMsg = '';
    driversListDescriptionMsg = '';

    chartsMsg = '';
    chartsDescriptionMsg = '';

    searchMsg = '';
    searchDescriptionMsg = '';

    chartToolsMsg = '';
    chartToolsDescriptionMsg = '';

    constructor(
        private driversGuide: ShepherdService,
        public translate: TranslateService,
        private guideTourService: GuideTourService,
    ) { }

    startGuide() {
        const self = this;
        const steps = [];
        steps.push({
            id: 'newDriver',
            attachTo: {
                element: '#new-driver-button',
                on: 'right'
            },
            buttons: this.firstButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.newDriverMsg,
            text: this.guideTourService.generateMilyMessage(this.newDriverDescriptionMsg),
        });
        steps.push({
            id: 'driversList',
            attachTo: {
                element: '#drivers-grid-container',
                on: 'top'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.driversListMsg,
            text: this.guideTourService.generateMilyMessage(this.driversListDescriptionMsg),
        });
        if (document.getElementById('driver-chart-container').classList.contains('expanded')) {
            steps.push({
                id: 'charts',
                attachTo: {
                    element: '#drivers-chart',
                    on: 'bottom'
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.chartsMsg,
                text: this.guideTourService.generateMilyMessage(this.chartsDescriptionMsg),
            });
        }
        steps.push({
            id: 'search',
            attachTo: {
                element: '#search-container',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.searchMsg,
            text: this.guideTourService.generateMilyMessage(this.searchDescriptionMsg),
        });
        steps.push({
            id: 'chartTools',
            attachTo: {
                element: '#driver-chart-tools',
                on: 'left'
            },
            buttons: this.lastButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.chartToolsMsg,
            text: this.guideTourService.generateMilyMessage(this.chartToolsDescriptionMsg),
        });
        if (steps.length) {
            self.driversGuide.addSteps(steps);
            self.driversGuide.start();
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('DEMO.NEXT').subscribe((res: string) => {
            this.nextMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.BACK').subscribe((res: string) => {
            this.backMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.FINISH').subscribe((res: string) => {
            this.finishMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.NEW_DRIVER').subscribe((res: string) => {
            this.newDriverMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.NEW_DRIVER_DESCRIPTION').subscribe((res: string) => {
            this.newDriverDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.DRIVERS_LIST').subscribe((res: string) => {
            this.driversListMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.DRIVERS_LIST_DESCRIPTION').subscribe((res: string) => {
            this.driversListDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.CHARTS').subscribe((res: string) => {
            this.chartsMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.CHARTS_DESCRIPTION').subscribe((res: string) => {
            this.chartsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.SEARCH').subscribe((res: string) => {
            this.searchMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.SEARCH_DESCRIPTION').subscribe((res: string) => {
            this.searchDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.CHART_TOOLS').subscribe((res: string) => {
            this.chartToolsMsg = res;
        }));
        this.listen.push(this.translate.get('DRIVERS_DEMO.CHART_TOOLS_DESCRIPTION').subscribe((res: string) => {
            this.chartToolsDescriptionMsg = res;
        }));
        this.defaultButtons = [
            {
                classes: 'shepherd-button-secondary',
                text: this.backMsg,
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: this.nextMsg,
                type: 'next'
            }
        ];
        this.firstButtons = [
            {
                classes: 'shepherd-button-primary',
                text: this.nextMsg,
                type: 'next'
            }
        ];
        this.lastButtons = [
            {
                classes: 'shepherd-button-secondary',
                text: this.backMsg,
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: this.finishMsg,
                type: 'next'
            }
        ];
    }

    ngAfterViewInit() {
        const self = this;
        const defaultStepOptions = {
            classes: '',
            scrollTo: false,
            cancelIcon: {
                enabled: true
            }
        };

        this.driversGuide.defaultStepOptions = defaultStepOptions;
        this.driversGuide.modal = true;
        this.driversGuide.confirmCancel = false;
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
