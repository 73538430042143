import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { CollaboratorViewBalanceComponent } from '@app/collaborator-view/collaborator-view-balance/collaborator-view-balance.component';
import { CustomerInvoiceModalComponent } from '@app/modals/customer-invoice-modal/customer-invoice-modal.component';
import { CollaboratorService } from '@app/services/collaborator.service';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-customer-invoices-grid',
    templateUrl: './customer-invoices-grid.component.html',
    styleUrls: ['./../grids.scss']
})
export class CustomerInvoicesGridComponent implements OnInit, OnDestroy {
    @ViewChild(CollaboratorViewBalanceComponent, { static: false }) collaboratorViewBalanceComponent: CollaboratorViewBalanceComponent;
    @ViewChild(CustomerInvoiceModalComponent, { static: false }) customerInvoiceModalComponent: CustomerInvoiceModalComponent;
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('filtersContainer', { static: false }) filtersContainer;

    listen = [];

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];
    columnDefs;
    rowModelType = 'serverSide';
    cacheBlockSize = 50;
    gridApi;
    gridColumnApi;
    rowData = [];

    months = [];
    invoiceNumberTotal = [];
    invoiceNumberCompleted = [];
    invoiceNumberCancelled = [];

    pagesCount = 0;
    customerInvoicesDataArray = [];
    invoicesCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    invoiceNumberTitle;
    collaboratorTitle;
    statusTitle;
    untilTitle;
    createdDateTitle;
    numberTitle;
    amountTitle;
    currency;
    actionsTitle;
    issuedByMily;
    issuedByYou;
    sentLabel;
    approvedLabel;
    approveLabel;
    paidLabel; // used in status & action button!
    payLabel;
    viewedLabel;
    overdueLabel;
    createdLabel;
    detailsLabel;
    showSortDropdown = false;
    selectedOrder = '';
    canceledLabel = '';
    completedLabel = '';

    collaboratorId;

    selectedInvoice = null;

    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;

    noNameLabel = '';

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private collaboratorService: CollaboratorService,
    ) {
        this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
            this.updateGrid();
        }));
        this.listen.push(this.modalGridService.listenUpdateInvoicesGrid().subscribe((response) => {
            this.updateGrid();
        }));
    }

    // update grid
    updateGrid() {
        this.pagesCount = 0;
        // this.gridApi.purgeServerSideCache([]);
        this.gridApi.refreshServerSideStore({ purge: true });
        const gridContainer = document.querySelector('#invoices-grid-container .ag-body-viewport');
        gridContainer.scrollTo(0, 0);
    }

    newButtonClicked() {
        this.modalService.openInvoiceModal(null);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    emptyChartArrays() {
        this.customerNames = [];
        this.customerShortNames = [];
        this.mostVisited = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.months = [];
        for (let i = 1; i < 8; i++) {
            this.months.push(moment.utc().add(i, 'day').format('DD-MM'));
        }

        this.invoiceNumberTotal = [22, 17, 16, 16, 15, 9, 13];
        this.invoiceNumberCompleted = [17, 15, 12, 14, 14, 8, 9];

        for (let i = 1; i < 8; i++) {
            this.invoiceNumberCancelled.push(this.invoiceNumberTotal[i] - this.invoiceNumberCompleted[i]);
        }

        this.chartDisabled = true;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&ids=';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        this.displayDummyChartValues();
        this.initChart();
        // this.http.get('api/v1/stats/customer-stats' + params).subscribe(response => {
        //     this.dataLoading = false;
        //     const data = response['items'];
        //     if (Object.keys(data).length) {
        //         if (Object.keys(data).length < this.pageSize) {
        //             this.lastPage = true;
        //         } else if (Object.keys(data).length === this.pageSize) {
        //             this.lastPage = false;
        //         }
        //         Object.keys(data).forEach(id => {
        //             if (data[id]) {
        //                 this.chartData[id] = data[id];
        //                 this.customerNames.push(data[id].contact_name);
        //                 this.customerShortNames.push(this.getFirstWord(data[id].contact_name));
        //                 this.mostVisited.push(data[id].stopPointsCount);
        //             }
        //         });
        //         if (this.checkIfAllZero()) {
        //             this.displayDummyChartValues();
        //         }
        //     } else {
        //         this.lastPage = true;
        //         this.displayDummyChartValues();
        //     }
        //     this.initChart();
        // });
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('invoices-chart');
        if (this.chart) { this.chart.destroy(); }
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
        chartOptions.plugins.legend.display = true;
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.months,
                    datasets: [
                        {
                            label: this.completedLabel,
                            data: this.invoiceNumberCompleted,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: '#00aeba70',
                        },
                        {
                            label: this.canceledLabel,
                            data: this.invoiceNumberTotal,
                            fill: '-1',
                            borderColor: '#00aeba',
                            backgroundColor: '#FF000030',
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    // dropdown actions
    detailsInvoice() {
        if (this.selectedInvoice) {
            this.modalService.openInvoiceModal(this.selectedInvoice.objData);
        }
        this.closeDropdown();
    }
    editInvoice() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    sendReminderInvoice() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    sendAgainInvoice() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    exportPdfInvoice() {
        this.globals.comingSoonAlert();
        this.closeDropdown();
    }
    printInvoice() {
        if (this.selectedInvoice) {
            window.open('/invoicePrint/' + this.selectedInvoice.hash, '_blank');
        }
    }
    deleteInvoice() {
        if (this.selectedInvoice) {
            this.http.delete('api/v1/invoice/' + this.selectedInvoice.id).pipe(take(1)).subscribe(response => {
                this.modalGridService.updateCustomerGrid();
            });
        }
    }

    // set invoice as paid
    setPaid(invoiceId) {
        let paidInvoice = {
            "status": this.globals.invoiceStatusConstants['PAID']
        };

        const myObserver = {
            next: (response) => {
                this.modalGridService.updateCustomerGrid();
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {

            },
        };

        this.http.put('api/v1/invoice/' + invoiceId, JSON.stringify(paidInvoice)).pipe(take(1)).subscribe(myObserver);
    }

    // set invoice as paid
    setApproved(invoiceId) {
        let paidInvoice = {
            "status": this.globals.invoiceStatusConstants['APPROVED']
        };

        const myObserver = {
            next: (response) => {
                this.modalGridService.updateCustomerGrid();
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {

            },
        };

        this.http.put('api/v1/invoice/' + invoiceId, JSON.stringify(paidInvoice)).pipe(take(1)).subscribe(myObserver);
    }

    // show invoice details
    showDetails() { }

    getInvoiceGridData(pageSize) {
        const searchQuery = `&searchQuery=${this.searchString}`;
        let url = 'api/v1/invoice';
        url += '?pageSize=' + pageSize;
        url += '&page=' + this.pagesCount;
        // url += '?searchQuery=collaboratorId=' + 335;

        if (this.globals.isInRoute('collaboratorView')) {
            url += '&collaborator_id=' + this.collaboratorId;
        }

        url += searchQuery;

        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setCustomerViewGridData(dataArray), lastRow);

        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setCustomerViewGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getInvoiceGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
            this.invoicesCount = response['itemsMeta'].totalCount;
            this.customerInvoicesDataArray = response['items']['invoices'];
            this.displayDataToGrid(params, this.customerInvoicesDataArray, response['itemsMeta']['pagesCount']);
            this.gridApi.hideOverlay();
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }

    createServerSideDatasource() {
        const self = this;
        return {
            getRows: function (params) {
                self.gridApi.showLoadingOverlay();
                self.loadSinglePage(params);
            },
        };
    }

    setCustomerViewGridData(invoices) {
        this.customerInvoicesDataArray = [];
        let gridObject = {};
        let toGetPaidSum = 0;
        if (invoices.length) {
            invoices.forEach(invoice => {
                // Until Date
                let untilDateFormatted = moment(invoice.due_on_datetime).format('DD/MM/YYYY');

                // Created Date + Issued by
                let createdDateIssuedBy: string;
                let dateCreatedFormatted = moment(invoice.creation_datetime).format('DD/MM/YYYY');

                if (invoice.issued_by == this.globals.invoiceStatusCollaboratorConstants['COMPANY']) {
                    createdDateIssuedBy = dateCreatedFormatted + " " + this.issuedByYou;
                } else {
                    createdDateIssuedBy = dateCreatedFormatted + " " + this.issuedByMily;
                }

                // Sent Date + Sent by
                let sentDateBy: string;
                let dateSendFormatted = moment(invoice.sent_date).format('DD/MM/YYYY');

                if (invoice.sent_by == 1) {
                    sentDateBy = dateSendFormatted + " " + this.issuedByMily;
                } else {
                    sentDateBy = dateSendFormatted + " " + this.issuedByYou;
                }

                // Status Label & Correct invoice action (button)
                let statusLabel, invoiceActionLabel, invoiceActionClass, colorClass;
                switch (invoice.status) {
                    case this.globals.invoiceStatusConstants['CREATED']:
                        statusLabel = this.createdLabel;
                        invoiceActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.approveLabel : this.detailsLabel;
                        invoiceActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'approve-button' : 'details-button';
                        colorClass = 'status-created';
                        break;
                    case this.globals.invoiceStatusConstants['APPROVED']:
                        statusLabel = this.approvedLabel;
                        invoiceActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        invoiceActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-sent';
                        break;
                    case this.globals.invoiceStatusConstants['VIEWED']:
                        statusLabel = this.viewedLabel;
                        invoiceActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        invoiceActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-viewed';
                        break;
                    case this.globals.invoiceStatusConstants['OVERDUE']:
                        statusLabel = this.overdueLabel;
                        invoiceActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        invoiceActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-overdue';
                        break;
                    case this.globals.invoiceStatusConstants['SENT']:
                        statusLabel = this.sentLabel;
                        invoiceActionLabel = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? this.payLabel : this.detailsLabel;
                        invoiceActionClass = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] ? 'pay-button' : 'details-button';
                        colorClass = 'status-sent';
                        break;
                    case this.globals.invoiceStatusConstants['PAID']:
                        statusLabel = this.paidLabel;
                        invoiceActionLabel = this.detailsLabel;
                        invoiceActionClass = 'details-button';
                        colorClass = 'status-paid';
                        break;
                    default:
                        statusLabel = 'CASE ERROR';
                }

                gridObject = {
                    id: invoice.id,
                    hash: invoice.hash,
                    invoice_number: this.collaboratorService.generateIdWithZeros(invoice.id),
                    status: {
                        colorClass: colorClass,
                        label: statusLabel
                    },
                    collaborator: {
                        id: invoice.collaborator_id,
                        name: invoice.collaborator_name
                    },
                    until_date: untilDateFormatted,
                    created_date_issued_by: createdDateIssuedBy,
                    sent_date_issued_by: sentDateBy,
                    vouchers: invoice.vouchers,
                    amount: invoice.amount.toFixed(2) + this.currency,
                    actions: {
                        invoiceActionLabel: invoiceActionLabel,
                        invoiceActionClass: invoiceActionClass
                    },
                    objData: invoice
                };
                this.customerInvoicesDataArray.push(gridObject);
                toGetPaidSum += invoice.amount;
            });
        } else {
            const noData = { noDataText: 'No data' }
            this.customerInvoicesDataArray.push(noData);
        }

        return this.customerInvoicesDataArray;
    }

    rowClicked(event) {
        const target = event.event.target.closest('div');
        if (!event.data.noDataText && !target.classList.contains('dropdown-dots')
            && event.event.target.classList.contains('details-button')) {
            this.modalService.openInvoiceModal(event.data.objData);
        }
        // approve button
        else if (event.event.target.classList.contains('approve-button')) {
            this.setApproved(event.data.id);
        }
        // pay button
        else if (event.event.target.classList.contains('pay-button')) {
            this.setPaid(event.data.id);
        }
        // row click open details
        else if (!target.classList.contains('dropdown-dots') && !target.classList.contains('hyperlink')) {
            this.modalService.openInvoiceModal(event.data.objData);
        }
    }

    closeDropdown() {
        const dropdown = document.getElementById('invoices-dropdown-container');
        this.selectedInvoice = null;
        dropdown.style.display = 'none';
    }
    // custom renderer for dropdown actions
    dropdownRenderer(params) {
        const self = this;
        const dots = document.createElement('div');
        dots.classList.add('dropdown-dots');
        dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
        const dropdown = document.getElementById('invoices-dropdown-container');
        dots.addEventListener('click', function () {
            setTimeout(() => {
                if (!self.selectedInvoice) {
                    // const space = window.innerHeight - dots.offsetTop;
                    // const space2 = window.innerHeight - dots.offsetTop + dots.offsetHeight
                    // console.log(dots.offsetTop);
                    // console.log(dots.offsetHeight);
                    // console.log(window.innerHeight);
                    // console.log(dropdown.offsetHeight);
                    // console.log();

                    // dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
                    dropdown.style.top = 'min(100vh, 50vh + 55px)';
                    dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
                    dropdown.style.display = 'block';
                    self.selectedInvoice = params.data;
                } else {
                    self.closeDropdown();
                }
            }, 20);
        });
        return dots;
    }
    //

    // custom renderer for actions (button)
    actionsButtonRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="invoices-actions-button-container">';
            columnObject += '<div class="single-cell standard-width">' + '<button class="invoices-actions-button ' + params.getValue().invoiceActionClass + '">' + params.getValue().invoiceActionLabel + '</button>' + '</div>';
            columnObject += '</div>';
        }
        return columnObject;
    }

    onFirstDataRendered(params) { }

    createdDateRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    // custom renderer for status
    statusRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="invoice-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
        }

        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    initGrid(id) {
        let intervalId = setInterval(() => {
            if (this.gridApi) {
                this.collaboratorId = id;
                this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
                clearInterval(intervalId);
            }
        }, 100);
    }

    getTranslations() {
        this.listen.push(this.translate.get('STOP_POINT.COLLABORATOR').subscribe((res: string) => { this.collaboratorTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.INVOICE_NUMBER').subscribe((res: string) => { this.invoiceNumberTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.UNTIL').subscribe((res: string) => { this.untilTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED_DATE').subscribe((res: string) => { this.createdDateTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.NUMBER').subscribe((res: string) => { this.numberTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTIONS').subscribe((res: string) => { this.actionsTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_MILY').subscribe((res: string) => { this.issuedByMily = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_YOU').subscribe((res: string) => { this.issuedByYou = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT').subscribe((res: string) => { this.sentLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.APPROVED').subscribe((res: string) => { this.approvedLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.APPROVE').subscribe((res: string) => { this.approveLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.VIEWED').subscribe((res: string) => { this.viewedLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID').subscribe((res: string) => { this.paidLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAY').subscribe((res: string) => { this.payLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED').subscribe((res: string) => { this.createdLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTION_DETAILS').subscribe((res: string) => { this.detailsLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.getChartData();

        // specific collaborator grid
        if (this.globals.isInRoute('collaboratorView')) {
            this.columnDefs = [
                { headerName: this.invoiceNumberTitle, field: 'invoice_number', width: this.gridsService.widthCalculator(12.5) },
                {
                    headerName: this.statusTitle,
                    field: 'status',
                    cellRenderer: this.statusRenderer,
                    width: this.gridsService.widthCalculator(15)
                },
                { headerName: this.untilTitle, field: 'until_date', width: this.gridsService.widthCalculator(12.5) },
                { headerName: this.createdDateTitle, field: 'created_date_issued_by', width: this.gridsService.widthCalculator(27.5) },
                { headerName: this.amountTitle, field: 'amount', width: this.gridsService.widthCalculator(12.5) },
                {
                    headerName: this.actionsTitle,
                    field: 'actions',
                    cellRenderer: this.actionsButtonRenderer.bind(this),
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: '', field: '',
                    cellClass: 'dropdown-cell',
                    cellRenderer: this.dropdownRenderer.bind(this),
                    width: this.gridsService.widthCalculator(5)
                }
            ];
        }
        // general collaborators grid
        else {
            this.columnDefs = [
                {
                    headerName: this.collaboratorTitle,
                    field: 'collaborator',
                    cellRenderer: this.gridsService.collaboratorNewTabRenderer,
                    width: this.gridsService.widthCalculator(10.5)
                },
                { headerName: this.invoiceNumberTitle, field: 'invoice_number', width: this.gridsService.widthCalculator(15.5) },
                {
                    headerName: this.statusTitle,
                    field: 'status',
                    cellRenderer: this.statusRenderer,
                    width: this.gridsService.widthCalculator(15)
                },
                { headerName: this.untilTitle, field: 'until_date', width: this.gridsService.widthCalculator(12.5) },
                { headerName: this.createdDateTitle, field: 'created_date_issued_by', width: this.gridsService.widthCalculator(17) },
                { headerName: this.amountTitle, field: 'amount', width: this.gridsService.widthCalculator(9.5) },
                {
                    headerName: this.actionsTitle,
                    field: 'actions',
                    cellRenderer: this.actionsButtonRenderer.bind(this),
                    width: this.gridsService.widthCalculator(15)
                },
                {
                    headerName: '', field: '',
                    cellClass: 'dropdown-cell',
                    cellRenderer: this.dropdownRenderer.bind(this),
                    width: this.gridsService.widthCalculator(5)
                }
            ];
        }
    }

    ngOnInit(): void {
        this.charts = [
            {
                label: 'Invoices',
                value: 'invoices',
            },
        ];
        this.periods = this.chartService.periods;
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[4];

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        // init grid for all invoices
        if (!this.globals.isInRoute('collaboratorView')) {
            this.initGrid(null);
        }
        // search update
        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.pagesCount = 0;
                // this.gridApi.purgeServerSideCache([]);
                this.gridApi.refreshServerSideStore({ purge: true });
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
