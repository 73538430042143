import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { Router } from '@angular/router';
import { GridUtils } from '@app/utils/grid-utils';
import { PaymentStatusSelectEditorComponent } from '@app/grids/grid-editors/payment-status-select-editor/payment-status-select-editor.component';
import { DataService } from '@app/services/data.service';

@Component({
    selector: 'app-customer-vouchers-print-grid',
    templateUrl: './customer-vouchers-print-grid.component.html',
    styleUrls: ['./../grids.scss']
})
export class CustomerVouchersPrintGridComponent implements OnInit, OnDestroy {
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;

    listen = [];

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];

    columnDefs;
    rowModelType = 'serverSide';
    cacheBlockSize = 50;
    gridApi;
    gridColumnApi;
    rowData = [];
    isEditingActive = false;

    // columnDefs;
    // rowModelType = 'serverSide';
    // cacheBlockSize = 50;
    // gridApi;
    // gridColumnApi;
    // rowData;
    // domLayout = 'autoHeight';

    months = [];
    vouchersNumberTotal = [];
    vouchersNumberComplete = [];
    vouchersNumberCancelled = [];

    pagesCount = 0;
    customerVouchersDataArray = [];
    vouchersCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    nameCodeTitle;
    addressTitle;
    timeTitle;
    completeTitle;
    createdTitle;
    volumeTitle;
    statusTitle;
    voucherStatusValue: string;
    payOnDeliveryTitle;
    paymentStatusTitle;
    currency;
    statusOnGoing: string;
    statusCompleted: string;
    statusCanceled: string;
    statusArrived: string;
    statusDropped: string;
    statusLoading: string;
    statusNotSpecified: string;
    statusScheduled: string;
    statusUnscheduled: string;
    statusHanded: string;
    paidLabel: string;
    toPayLabel: string;
    overdueLabel: string;
    toBeInvoicedLabel: string;
    showSortDropdown = false;
    selectedOrder = '';

    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;

    noNameLabel = '';
    returnLabel = '';
    noNameConstant = '_NO_NAME';
    returnConstant = '_RETURN';

    invoiceVouchers;

    collaboratorId;
    invoiceOrPaymentQuery;
    hash;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private addressService: AddressService,
        public router: Router,
        public gridUtils: GridUtils,
        public dataService: DataService,
    ) {
        this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
            this.updateGrid();
        }));
    }

    cellValueChanged(event) {
        const stopPointsToUpdate = this.gridUtils.getStopPointsDataToSavePayOnDeliveryStatus(event, []);

        if (stopPointsToUpdate.length) {
            this.http.put('api/v1/stop-points', { stopPoints: stopPointsToUpdate }).pipe(take(1)).subscribe(response => {
                this.modalService.updatePaymentModal();
            });
        }
    }

    updateGrid() {
        this.pagesCount = 0;
        // this.gridApi.purgeServerSideCache([]);
        this.gridApi.refreshServerSideStore({ purge: true });
    }

    newButtonClicked() {
        this.modalService.openCustomerModal(null);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    initGrid() {
        let intervalId = setInterval(() => {
            if (this.gridApi) {
                this.pagesCount = 0;
                this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
                clearInterval(intervalId);
            }
        }, 100);
    }

    // get request (grid data)
    getVouchersGridData(collaboratorId, invoiceOrPaymentQuery, hash) {
        let url = '';
        if (hash) {
            url = '/api/unregistered/v1/voucher-view';
            url += '?hash=' + hash;
        } else if (this.globals.collaboratorModeEnabled) {
            // url = 'api/internal/v2/partner-stop-points';
            url = 'api/v1/partner-stop-points';
            url += '?collaborator_id=' + collaboratorId;
        } else {
            url = 'api/v1/vouchers';
            url += '?collaborator_id=' + collaboratorId;
        }
        url += '&' + invoiceOrPaymentQuery;
        url += '&pageSize=' + this.cacheBlockSize;
        url += '&page=' + this.pagesCount;
        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setCustomerVouchersGridData(dataArray), lastRow);

        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setCustomerVouchersGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getVouchersGridData(this.collaboratorId, this.invoiceOrPaymentQuery, this.hash).pipe(take(1)).subscribe(response => {
            this.vouchersCount = response['itemsMeta'].totalCount;
            this.customerVouchersDataArray = response['items'];
            this.displayDataToGrid(params, this.customerVouchersDataArray, response['itemsMeta']['pagesCount']);
            this.gridApi.hideOverlay();
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }

    createServerSideDatasource() {
        const self = this;
        return {
            getRows: function (params) {
                self.gridApi.showLoadingOverlay();
                self.loadSinglePage(params);
            },
        };
    }

    // createServerSideDatasource(collaboratorId, invoiceOrPaymentQuery, hash) {
    //     const self = this;
    //     return {
    //         getRows: function (params) {
    //             self.getVouchersGridData(collaboratorId, invoiceOrPaymentQuery, hash).pipe(take(1)).subscribe(response => {
    //                 if (response) {
    //                     if (response['items'].length) {
    //                         let lastRow = -1;
    //                         self.pagesCount++;
    //                         if (response['items'].length < self.cacheBlockSize) {
    //                             lastRow = response['items'].length + ((self.pagesCount - 1) * self.cacheBlockSize);
    //                         }
    //                         params.successCallback(self.setCustomerVouchersGridData(response['items']), lastRow);
    //                     } else {
    //                         const noData = { noDataText: 'No data' }
    //                         params.successCallback([noData], 1);
    //                     }
    //                 }
    //             },
    //                 (error) => {
    //                     console.error(error);
    //                     params.failCallback();
    //                 });
    //         },
    //     };
    // }

    setCustomerVouchersGridData(vouchers) {
        this.customerVouchersDataArray = [];
        let gridObject = {};
        this.dataService.statusPerStopPointMap = this.gridUtils.getStatusPerStopPointMap(vouchers);
        if (vouchers.length) {
            vouchers.forEach(voucher => {
                let chargeClass = '', chargeLabel = '';

                // Format 'time' property to keep only the time (done only on fulfilled stopPoint)
                let completeTimeFormatted = '-', completeDateFormatted = '';
                if (
                    voucher.stopPoint.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
                    voucher.stopPoint.solution
                ) {
                    completeTimeFormatted = moment(voucher.stopPoint.solution.atTime).format('HH:mm');
                    completeDateFormatted = moment(voucher.stopPoint.solution.atTime).format('DD/MM/YYYY');
                }

                let creationTimeFormatted = '-', creationDateFormatted = '';
                if (voucher.stopPoint.voucher.creation_datetime) {
                    creationTimeFormatted = moment(voucher.stopPoint.voucher.creation_datetime).format('HH:mm');
                    creationDateFormatted = moment(voucher.stopPoint.voucher.creation_datetime).format('DD/MM/YYYY');
                }

                let completeDatetimeMoment = null;
                if (
                    voucher.stopPoint.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
                    voucher.stopPoint.solution
                ) {
                    completeDatetimeMoment = moment(voucher.stopPoint.solution.atTime);
                }

                // Payment Status Value
                let paymentStatusValue = '0' + this.currency;
                chargeClass = 'status-unset';
                chargeLabel = this.toBeInvoicedLabel;
                if (voucher.stopPoint.voucher && voucher.stopPoint.voucher.pay_price) {
                    if (voucher.stopPoint.voucher.paid_by == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
                        if (voucher.stopPoint.voucher.consignor_type == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
                            paymentStatusValue = voucher.stopPoint.voucher.pay_price + this.currency;
                        }
                    } else {
                        paymentStatusValue = voucher.stopPoint.voucher.pay_price + this.currency;
                    }
                }

                // if (voucher.stopPoint.voucher.pay_price) {
                // if invoiced
                if (voucher.stopPoint.voucher.invoice_id) {
                    chargeClass = 'status-on-going';
                    chargeLabel = this.toPayLabel;
                    if (voucher.stopPoint.voucher.invoice) {
                        if (voucher.stopPoint.voucher.invoice.status) {
                            if (voucher.stopPoint.voucher.invoice.status === this.globals.invoiceStatusConstants['PAID']) {
                                // if paid
                                chargeClass = 'status-completed';
                                chargeLabel = this.paidLabel;
                            } else if (voucher.stopPoint.voucher.invoice.status === this.globals.invoiceStatusConstants['OVERDUE']) {
                                // if expired
                                chargeClass = 'status-canceled';
                                chargeLabel = this.overdueLabel;
                            }
                        }
                    }
                }

                let name = voucher.stopPoint.contact_name;
                if (name.includes(this.noNameConstant)) {
                    name = name.replace(this.noNameConstant, this.noNameLabel);
                }
                if (name.includes(this.returnConstant)) {
                    name = name.replace(this.returnConstant, this.returnLabel);
                }

                // Time (Time Windows)
                let timeWindowStart, timeWindowEnd;
                let timeWindowSecondStart, timeWindowSecondEnd;
                let timeWindowRange, timeWindowRangeMinutes;
                if (voucher.stopPoint.time_windows[0]) {
                    timeWindowStart = moment(voucher.stopPoint.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
                    timeWindowRange = voucher.stopPoint.time_windows[0].time_window_range;
                    timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
                    timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

                    if (voucher.stopPoint.time_windows[1]) {
                        timeWindowSecondStart = moment(voucher.stopPoint.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
                        timeWindowRange = voucher.stopPoint.time_windows[1].time_window_range;
                        timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
                        timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
                    }
                }

                // fulfillment status
                const fulfillmentObj = this.gridUtils.getFulfillmentStatus(voucher.stopPoint);

                gridObject = {
                    id: voucher.stopPoint.id,
                    projectProblemId: voucher.stopPoint.project_problem_id,
                    voucherId: voucher.stopPoint.voucher.id,
                    details: {
                        name: name,
                        code: voucher.stopPoint.barcode ? voucher.stopPoint.barcode : '-',
                        phone: voucher.stopPoint.telephone ? voucher.stopPoint.telephone : '-',
                    },
                    address: this.addressService.getAddressLabel(voucher.stopPoint.address),
                    creation_datetime: {
                        time: creationTimeFormatted,
                        date: creationDateFormatted,
                    },
                    time: {
                        date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
                        twFirstStart: timeWindowStart,
                        twFirstEnd: timeWindowEnd,
                        twSecondStart: timeWindowSecondStart,
                        twSecondEnd: timeWindowSecondEnd
                    },
                    volume: voucher.stopPoint.delivery_load,
                    status: {
                        colorClass: fulfillmentObj.colorClass,
                        label: fulfillmentObj.statusValue
                    },
                    payOnDelivery: this.gridUtils.getPayAmountStatus(voucher.stopPoint),
                    paymentStatus: {
                        colorClass: chargeClass,
                        label: chargeLabel,
                        paymentStatusValue: paymentStatusValue
                    },
                    objData: voucher
                };
                this.customerVouchersDataArray.push(gridObject);
            });
        } else {
            const noData = { noDataText: 'No data' }
            this.customerVouchersDataArray.push(noData);
        }

        return this.customerVouchersDataArray;
    }

    cellEditingStarted(event) {
        this.isEditingActive = true;
    }

    cellEditingStopped(event) {
        // row click event fires right after cell edit stop event, so we add a delay to stop row click from opening SP modal
        setTimeout(() => {
            this.isEditingActive = false;
        }, 100);
    }

    // custom cell renderer for name, code & phone
    nameCodePhoneRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-qrcode grey-letters"></i> ' + params.getValue().code + '<i class="fas fa-phone grey-letters"></i>' + params.getValue().phone + '</div>';
            }
        }
        return columnObject;
    }

    // custom renderer for status
    statusRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
        }
        return columnObject;
    }

    // custom renderer for volume + kg
    volumeRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue()) {
                columnObject += '<div class="single-cell standard-width">' + params.getValue() + ' kg' + '</div>';
            }
        }
        return columnObject;
    }

    // custom renderer for time
    timeRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().twFirstStart && !params.getValue().twSecondStart) {
                columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
                columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ')</div>'
            }
            else if (params.getValue().twFirstStart && params.getValue().twSecondStart) {
                columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
                columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ' ' + `${params.getValue().twSecondStart}-${params.getValue().twSecondEnd}` + ')</div>'
            }
            else {
                columnObject += '<div class="single-cell standard-width">' + params.getValue().date + '</div>'
            }
        }
        return columnObject;
    }

    rowClicked(event) {
        // if (!event.data.noDataText) {
        //     this.modalService.openCustomerModal(event.data.id);
        // }
    }

    onFirstDataRendered(params) { }

    createdDateRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    gridWidthFix() {
        this.gridApi.sizeColumnsToFit()
    }

    getTranslations() {
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.NAME_CODE').subscribe((res: string) => { this.nameCodeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TIME').subscribe((res: string) => { this.timeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.CREATED').subscribe((res: string) => { this.createdTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.COMPLETE').subscribe((res: string) => { this.completeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.VOLUME').subscribe((res: string) => { this.volumeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAY_ON_DELIVERY').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAYMENT_STATUS').subscribe((res: string) => { this.paymentStatusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_ON_GOING').subscribe((res: string) => { this.statusOnGoing = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_COMPLETED').subscribe((res: string) => { this.statusCompleted = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_ON_GOING').subscribe((res: string) => { this.statusOnGoing = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_CANCELED').subscribe((res: string) => { this.statusCanceled = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_ARRIVED').subscribe((res: string) => { this.statusArrived = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_DROPPED').subscribe((res: string) => { this.statusDropped = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_LOADING').subscribe((res: string) => { this.statusLoading = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_NOT_SPECIFIED').subscribe((res: string) => { this.statusNotSpecified = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_SCHEDULED').subscribe((res: string) => { this.statusScheduled = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.STATUS_UNSCHEDULED').subscribe((res: string) => { this.statusUnscheduled = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAID').subscribe((res: string) => { this.paidLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_PAY').subscribe((res: string) => { this.toPayLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_BE_INVOICED').subscribe((res: string) => { this.toBeInvoicedLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));

        if (!this.router.url.includes('Print/')) {
            this.columnDefs = [
                {
                    headerName: this.nameCodeTitle,
                    field: 'details',
                    cellRenderer: this.nameCodePhoneRenderer,
                    width: this.gridsService.widthCalculator(14)
                },
                {
                    headerName: this.addressTitle,
                    field: 'address',
                    cellRenderer: this.gridsService.addressRenderer,
                    width: this.gridsService.widthCalculator(14)
                },
                {
                    headerName: this.createdTitle,
                    field: 'creation_datetime',
                    cellRenderer: this.gridsService.dateTimeRenderer,
                    width: this.gridsService.widthCalculator(7)
                },
                {
                    headerName: this.completeTitle,
                    field: 'time',
                    cellRenderer: this.timeRenderer,
                    width: this.gridsService.widthCalculator(7)
                },
                {
                    headerName: this.volumeTitle,
                    field: 'volume',
                    cellRenderer: this.volumeRenderer,
                    width: this.gridsService.widthCalculator(7)
                },
                {
                    headerName: this.statusTitle,
                    field: 'status',
                    cellRenderer: this.statusRenderer,
                    width: this.gridsService.widthCalculator(9)
                },
                {
                    headerName: this.payOnDeliveryTitle,
                    field: 'payOnDelivery',
                    cellRenderer: this.gridsService.payOnDeliveryRenderer,
                    cellEditorFramework: PaymentStatusSelectEditorComponent,
                    editable: (params) => !this.globals.collaboratorModeEnabled && (this.gridsService.isStopPointRowWithPayAmount(params)),
                    width: this.gridsService.widthCalculator(9)
                },
                {
                    headerName: this.paymentStatusTitle,
                    field: 'paymentStatus',
                    cellRenderer: this.gridsService.paymentStatusRenderer.bind(this),
                    width: this.gridsService.widthCalculator(9)
                },
            ];
        } else {
            this.columnDefs = [
                {
                    headerName: this.nameCodeTitle,
                    field: 'details',
                    cellRenderer: this.nameCodePhoneRenderer,
                },
                {
                    headerName: this.addressTitle,
                    field: 'address',
                    cellRenderer: this.gridsService.addressRenderer,
                },
                {
                    headerName: this.createdTitle,
                    field: 'creation_datetime',
                    cellRenderer: this.gridsService.dateTimeRenderer,
                },
                {
                    headerName: this.completeTitle,
                    field: 'time',
                    cellRenderer: this.gridsService.timeRenderer,
                },
                {
                    headerName: this.volumeTitle,
                    field: 'volume',
                    cellRenderer: this.volumeRenderer,
                },
            ];
        }
    }

    ngOnInit(): void {

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.charts = [
            {
                label: 'Vouchers',
                value: 'vouchers',
            },
        ];
        this.periods = this.chartService.periods;
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[4];

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.updateGrid();
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
