// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-column {
  max-width: 29vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 2vw;
}

.settings-container {
  padding: 3vw;
  justify-content: space-between;
  height: calc(87vh - 65px - 65px);
}

.form-title {
  margin-bottom: 2vh;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndpemFyZC1zZXR0aW5ncy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGVBQUE7RUFDQSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0Esa0JBQUE7QUFDSjs7QUFFQTtFQUNJLFlBQUE7RUFDQSw4QkFBQTtFQUNBLGdDQUFBO0FBQ0o7O0FBRUE7RUFDSSxrQkFBQTtBQUNKIiwiZmlsZSI6IndpemFyZC1zZXR0aW5ncy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zZXR0aW5ncy1jb2x1bW4ge1xuICAgIG1heC13aWR0aDogMjl2dztcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xuICAgIG92ZXJmbG93LXg6IGhpZGRlbjtcbiAgICBwYWRkaW5nLXJpZ2h0OiAydnc7XG59XG5cbi5zZXR0aW5ncy1jb250YWluZXIge1xuICAgIHBhZGRpbmc6IDN2dztcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgaGVpZ2h0OiBjYWxjKDEwMHZoIC0gMTN2aCAtIDY1cHggLSA2NXB4KTtcbn1cblxuLmZvcm0tdGl0bGUge1xuICAgIG1hcmdpbi1ib3R0b206IDJ2aDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/wizard/wizard-settings/wizard-settings.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,8BAAA;EACA,gCAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AACA,wxBAAwxB","sourcesContent":[".settings-column {\n    max-width: 29vw;\n    overflow-y: auto;\n    overflow-x: hidden;\n    padding-right: 2vw;\n}\n\n.settings-container {\n    padding: 3vw;\n    justify-content: space-between;\n    height: calc(100vh - 13vh - 65px - 65px);\n}\n\n.form-title {\n    margin-bottom: 2vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
