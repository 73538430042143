import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { hasValue } from '../utils';

@Pipe({
  name: 'lmSelectorOptionValue'
})
export class lmSelectorOptionValuePipe implements PipeTransform {
  constructor() {}

  transform(item: { index: number; label: string; value: any }, selectorOptionValue: (item: any) => Observable<any>): Observable<string> {
    if (!hasValue(item.label)) {
      return of(item.label);
    }

    if (!!selectorOptionValue) {
      return selectorOptionValue(item.value);
    } else {
      return of(item.label);
    }
  }
}

@NgModule({
  imports: [],
  exports: [lmSelectorOptionValuePipe],
  declarations: [lmSelectorOptionValuePipe]
})
export class LmSelectorOptionValueModule {}
