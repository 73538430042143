import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-departure-time-select-editor',
  templateUrl: './departure-time-select-editor.component.html'
})
export class DepartureTimeSelectEditorComponent implements OnInit {

  defaultDepartureTime = '';

  constructor() { }

  public agInit(params: any): void {
    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false,
      autoClose: false
      // container: ('#drivers-grid')
    });
  }

  public getValue(): any {
    const departureTimeElement = document.querySelector('#grid-departure-timepicker');
    const departureTime = M.Timepicker.getInstance(departureTimeElement).time;
    if (departureTime) {
      return departureTime;
    } else {
      return null;
    }
  }

  ngOnInit() {
  }

}
