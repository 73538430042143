import { ColourService } from '../../../services/colour.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as turf from '@turf/turf';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { MapService } from '@app/services/map.service';
import { DriverRegionRuleService } from '@app/services/driver-region-rule.service';
import { ImporterService } from '@app/services/importer.service';

declare var H: any;

@Component({
    selector: 'app-stops-grid-map',
    templateUrl: './stops-grid-map.component.html',
    styleUrls: ['./stops-grid-map.component.scss']
})
export class StopsGridMapComponent implements OnInit, AfterViewInit {
    private platform: any;
    @ViewChild('map', { static: false }) public mapElement: ElementRef;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @Output() regionDataReceived = new EventEmitter<string>();

    map;
    ui;
    behavior;

    importerMapObjects = {};

    // clickOnMap;
    selectAddress;

    depots = [];
    depotsGroup = new H.map.Group();
    editingMarkerGroup = new H.map.Group();
    importerMarkersGroup = new H.map.Group();

    listen = [];

    constructor(
        private http: HttpClient,
        public globals: Globals,
        private mapService: MapService,
        public driverRegionRuleService: DriverRegionRuleService,
        private colourService: ColourService,
        private importerService: ImporterService,
    ) {
        this.platform = this.mapService.platform;
        this.listen.push(this.importerService.addMarkerListen().subscribe((data) => {
            this.addImporterStopPoint(data.lat, data.lon, data.index);
        }));
        this.listen.push(this.importerService.mapEmptyListen().subscribe((response) => {
            this.emptyMap();
        }));
        this.listen.push(this.importerService.mapRefillListen().subscribe((response) => {
            this.emptyMap();
        }));
        this.listen.push(this.importerService.focusOnMarkerListen().subscribe((index) => {
            const target = this.importerMapObjects[index];
            this.editingMarkerGroup.addObject(target);
            const bounds = this.editingMarkerGroup.getBoundingBox();
            this.editingMarkerGroup.removeObject(target);
            this.importerMarkersGroup.addObject(target);
            this.centerToMarker(bounds);
            // the code below centers the marker but doesn't look good with modal open
            // need to put it through the same algorithm as above
            // const data = target.getGeometry();
            // const coords = { lat: data.lat, lng: data.lng }
            // this.map.setCenter(coords);
            // this.map.setZoom(14);
            // const bounds = this.map.getViewModel().getLookAtData().bounds;
            // this.centerToMarker(bounds);
        }));
    }

    centerToMarker(bounds) {
        const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
        this.map.getViewModel().setLookAtData({ bounds: newBounds }, false);
    }

    addImporterStopPoint(lat, lon, index) {
        let icon, coords;
        icon = new H.map.Icon(this.svgIconsComponent.svgMarkup.replace('markerColour', '#00aeba'));
        coords = { lat: lat, lng: lon };
        this.importerMapObjects[index] = new H.map.Marker(coords, { icon: icon });
        this.importerMapObjects[index].setData({
            index: index
        });
        this.importerMarkersGroup.addObject(this.importerMapObjects[index]);
    }

    emptyMap() {
        this.importerMarkersGroup.removeAll();
    }

    setMapBounds() {
        this.map.getViewModel().setLookAtData({ bounds: this.depotsGroup.getBoundingBox() }, true);
    }

    resetMap() {
        this.setMapBounds();
    }

    // clickOnMap(event) {
    //     const self = this;
    //     const target = event.target;
    //     if (
    //         !(target instanceof H.map.DomMarker) &&
    //         (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')
    //     ) {
    //         if (target instanceof H.map.Marker) {

    //         } else {

    //         }
    //     }
    // }

    initMap(depotLat, depotLon) {
        const self = this;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 12,
                center: { lat: depotLat, lng: depotLon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.selectAddress = function (event) {
            const target = event.target;
            if (target instanceof H.map.Marker) {
                if (target.getData()) {
                    const clickedStopPointIndex = target.getData().index;
                    self.importerService.markerClicked(clickedStopPointIndex);
                }
            }
        };
        // this.map.addEventListener('tap', this.clickOnMap.bind(this));
        this.importerMarkersGroup.addEventListener('tap', this.selectAddress);
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');
        this.displayDepots();
        this.map.addObject(this.depotsGroup);
        this.map.addObject(this.editingMarkerGroup);
        this.map.addObject(this.importerMarkersGroup);
    }

    displayDepots() {
        const icon = new H.map.Icon(this.svgIconsComponent.svgDepotMarker.replace('markerColour', '#00aeba'));
        const iconBackground = new H.map.Icon(this.svgIconsComponent.svgMakerBackground);
        let coords, lat, lon, marker, markerBackground;
        this.depots.forEach(depot => {
            lat = depot['companyDepot']['address']['lat'];
            lon = depot['companyDepot']['address']['lon'];
            coords = { lat: lat, lng: lon };
            marker = new H.map.Marker(coords, { icon: icon });
            markerBackground = new H.map.Marker(coords, { icon: iconBackground });
            this.depotsGroup.addObject(markerBackground);
            this.depotsGroup.addObject(marker);
        });
        // });
    }

    ngOnInit() {

    }

    public ngAfterViewInit() {
        const self = this;
        const dataRefreshIntervalId = setInterval(dataChecker, 200);
        function dataChecker() {
            if (self.globals.depotsDataDone) {
                clearInterval(dataRefreshIntervalId);
                self.depots = self.globals.depotsArray;
                const depotLat = self.globals.currentLat;
                const depotLon = self.globals.currentLon;
                self.initMap(depotLat, depotLon);
            }
        }
    }

    ngOnDestroy() {
        this.importerMarkersGroup.removeEventListener('tap', this.selectAddress);
        // this.map.removeEventListener('tap', this.clickOnMap);
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
