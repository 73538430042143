import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { Observable, concat, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { RegionalMapComponent } from './regional-map/regional-map.component';
import { ModalService } from '@app/services/modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { AddressService } from '@app/services/address.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import Cropper from 'cropperjs';
import { GenericUtils } from '@app/utils/generic-utils';
import { DepotUtils } from '@app/utils/depot-utils';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';
import { ImageUtils } from '@app/utils/image-utils';

@Component({
  selector: 'app-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss']
})
export class DriverFormComponent implements OnInit {

  @ViewChild(RegionalMapComponent, { static: false }) regionalMapComponent: RegionalMapComponent;
  @ViewChild('formElement', { static: false, read: ElementRef }) formElement: ElementRef;
  @ViewChild('regionalElement', { static: false, read: ElementRef }) regionalElement: ElementRef;
  @Output() mapToggled = new EventEmitter<string>();
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild('addressStartSelect', { static: false }) addressStartSelect: NgSelectComponent;
  @ViewChild('addressEndSelect', { static: false }) addressEndSelect: NgSelectComponent;

  listen = [];

  cropper;

  vehicleTypes = [];

  currency;
  bicycleLabel = '';
  scooterLabel = '';
  carLabel = '';
  vanLabel = '';
  largeVanLabel = '';
  truckLabel = '';

  projectsIds = [];
  selectedProjectsIds = [];
  allProjects = [];
  projectsOptions = [];
  depotsOptions = [];

  vehicles = [];
  vehicleLoading = false;
  vehicleInput = new Subject<string>();
  myForm: FormGroup;
  errors;
  data;
  driverId = null;
  name: String = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  defaultVehicleId = null;
  vehiclePlateNumber = '';
  dailyWorkingHours = null;
  dailyWorkingHoursUnformatted = null;
  usualDepartureDatetimeUnformatted = '';
  usualDepartureDatetime = '';
  usualDepartureTimezone = '';
  stops = null;
  kilometers: String = '';
  status = 1;
  isClickedOnce = false;
  depots = [];
  portalAccess = false;
  portalSettingsConfirmMsg;
  demoDisableRequestMsg;

  countryCodeStart = '';
  stateStart = '';
  countyStart = '';
  cityStart = '';
  districtStart = '';
  streetStart = '';
  street2Start = '';
  street3Start = '';
  houseNumberStart = '';
  postalCodeStart = '';
  lonStart = '';
  latStart = '';
  freeformAddressStart = '';
  isPlaceStart = false;
  placeNameStart = '';

  countryCodeEnd = '';
  stateEnd = '';
  countyEnd = '';
  cityEnd = '';
  districtEnd = '';
  streetEnd = '';
  street2End = '';
  street3End = '';
  houseNumberEnd = '';
  postalCodeEnd = '';
  isPlaceEnd = false;
  placeNameEnd = '';
  lonEnd = '';
  latEnd = '';
  freeformAddressEnd = '';

  regional = false;
  repository;
  driverWorkType = false;
  callTariff = null;
  driverCostPerShipment = null;
  driverDailyWage = null;
  pendingData = false;

  selectedStartAddress: any = <any>[];
  selectedEndAddress: any = <any>[];
  addressStartInput = new Subject<string>();
  addressEndInput = new Subject<string>();
  startAddresses: Observable<any>;
  endAddresses: Observable<any>;
  startAddressesLoading = false;
  endAddressesLoading = false;

  locationStartModelName = '';
  locationStartAddress = {};
  locationStartModelId = null;
  locationEndModelName = '';
  locationEndAddress = {};
  locationEndModelId = null;
  depotId = null;
  selectedDepotItem;

  imageSliderOptions;
  imageSliderValue;
  slideValGlobal = 0.5;
  imageBase64;
  avatar = '';
  avatarSrc = 'data:image/png;base64,AVATAR_HASH'
  imageHash;

  constructor(
    private http: HttpClient,
    formBuilder: FormBuilder,
    public globals: Globals,
    private dataService: DataService,
    public modalGridService: ModalGridService,
    private projectProblemDataService: ProjectProblemDataService,
    public translate: TranslateService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private addressService: AddressService,
    private genericUtils: GenericUtils,
    private depotUtils: DepotUtils,
    private formErrorsUtils: FormErrorsUtils,
    private imageUtils: ImageUtils
  ) {
    this.myForm = formBuilder.group({
      'countryPrefix': [this.countryPrefix],
      'phoneNumber': [this.phoneNumber],
      'depot': [this.selectedDepotItem],
      // 'startDepot': [this.selectedStartDepotItem],
      // 'endDepot': [this.selectedEndDepotItem],
      'startAddress': [this.selectedStartAddress],
      'endAddress': [this.selectedEndAddress],
      'driver': formBuilder.group({
        'id': [this.driverId],
        'daily_working_hours': [this.dailyWorkingHours],
        'daily_working_hours_unformatted': [this.dailyWorkingHoursUnformatted],
        'default_vehicle_id': [this.defaultVehicleId],
        'usual_departure_datetime': [this.usualDepartureDatetime],
        'usual_departure_datetime_unformatted': [this.usualDepartureDatetimeUnformatted],
        'usual_departure_datetime_timezone': [this.usualDepartureTimezone],
        'portal_access_sw': [this.portalAccess],
        'projectsIds': [this.projectsIds],
        'selectedProjectsIds': [this.selectedProjectsIds],
        'regional': [this.regional],
        'repository': [this.repository],
        'driver_work_type': [this.driverWorkType],
        'call_tariff': [this.callTariff],
        'cost_per_shipment': [this.driverCostPerShipment],
        'daily_wage': [this.driverDailyWage]
      }),
      'userProfile': formBuilder.group({
        'name': [this.name, Validators.required],
        'telephone': [this.telephone],
      }),
      'driverLocations': formBuilder.group({
        'companyDepot': {
          'modelId': this.depotId
        },
        'locationStart': {
          'modelName': this.locationStartModelName,
          'modelId': this.locationStartModelId,
          'address': {
            'countryCode': this.countryCodeStart,
            'state': this.stateStart,
            'county': this.countyStart,
            'city': this.cityStart,
            'district': this.districtStart,
            'street': this.streetStart,
            'street2': this.street2Start,
            'street3': this.street3Start,
            'houseNumber': this.houseNumberStart,
            'postalCode': this.postalCodeStart,
            'isPlace': this.isPlaceStart,
            'placeName': this.placeNameStart,
            'value': this.freeformAddressStart,
            'lat': this.latStart,
            'lon': this.lonStart,
          },
        },
        'locationEnd': {
          'modelName': this.locationEndModelName,
          'modelId': this.locationEndModelId,
          'address': {
            'countryCode': this.countryCodeEnd,
            'state': this.stateEnd,
            'county': this.countyEnd,
            'city': this.cityEnd,
            'district': this.districtEnd,
            'street': this.streetEnd,
            'street2': this.street2End,
            'street3': this.street3End,
            'houseNumber': this.houseNumberEnd,
            'postalCode': this.postalCodeEnd,
            'isPlace': this.isPlaceEnd,
            'placeName': this.placeNameEnd,
            'value': this.freeformAddressEnd,
            'lat': this.latEnd,
            'lon': this.lonEnd,
          },
        },
        // 'start': formBuilder.group({
        //   'modelName': 'CompanyDepot',
        //   'modelId': [this.startDepotId],
        // }),
        // 'finish': formBuilder.group({
        //   'modelName': 'CompanyDepot',
        //   'modelId': [this.endDepotId],
        // }),
      }),
    });
  }

  public getFormData(id) {
    this.pendingData = true;
    this.regionalMapComponent.loadDriverRegion(id);
    this.http.get('api/internal/v2/drivers/' + id).pipe(take(1)).subscribe(
      response => {
        this.pendingData = false;
        this.data = response['item'];
        const driver = this.data.driver;
        const userProfile = this.data.userProfile;

        this.driverId = driver.id;
        this.dailyWorkingHours = driver.daily_working_hours;
        this.dailyWorkingHoursUnformatted = moment.duration(driver.daily_working_hours).asHours();
        this.usualDepartureDatetime = driver.usual_departure_datetime;
        this.usualDepartureDatetimeUnformatted = moment(this.usualDepartureDatetime).format('HH:mm');
        this.usualDepartureTimezone = driver.usual_departure_datetime_timezone;
        this.repository = Number(driver.repository);
        this.driverWorkType = driver.driver_work_type;
        this.callTariff = Number(driver.call_tariff);
        this.driverCostPerShipment = Number(driver.cost_per_shipment);
        this.driverDailyWage = Number(driver.daily_wage);
        this.name = userProfile.name;
        this.telephone = userProfile.telephone;
        this.phoneNumber = this.telephone;
        if (this.data.driverImage) {
          // fetch & load image asynchronously
          this.imageHash = this.data.driverImage.imageHash;
          if (this.imageHash) {
            this.loadAvatarAsync(this.imageHash);
          }
        }
        if (driver.projects) {
          driver.projects.forEach(project => {
            const projectData = {
              id: project.id,
              name: project.title
            };
            this.selectedProjectsIds.push(projectData);
          });
        }
        const vehicle = this.data.vehicle;
        this.defaultVehicleId = null;
        if (vehicle) {
          if (vehicle.id) {
            this.defaultVehicleId = vehicle.id;
          }
        }
        // this.startDepotId = this.data.driverDepots.start.modelId;
        // this.endDepotId = this.data.driverDepots.finish.modelId;
        this.depotId = this.data.driverLocations?.companyDepot?.modelId ?? this.globals.depotId;
        const depot = this.depotUtils.getFirstDepotWithDepotId(this.depotId);
        this.selectedDepotItem = {
          companyDepot: {
            address: depot.companyDepot.address,
            id: depot.companyDepot.id
          },
          name: this.depotUtils.getDepotName(depot.companyDepot)
        };

        if (this.data.driverLocations.locationStart) {
          if (this.data.driverLocations.locationStart.modelName === this.globals.companyDepotModelName) {
            this.setStartLocationAsSelectedDepot();
          } else {
            this.locationStartModelName = this.data.driverLocations.locationStart.modelName;
            this.setStartLocation(this.data.driverLocations.locationStart.address);
            this.setAddressStart(this.data.driverLocations.locationStart.address);
          }
        } else {
          this.setStartLocationAsSelectedDepot();
        }
        if (this.data.driverLocations.locationEnd) {
          if (this.data.driverLocations.locationEnd.modelName === this.globals.companyDepotModelName) {
            this.setEndLocationAsSelectedDepot();
          } else {
            this.locationEndModelName = this.data.driverLocations.locationEnd.modelName;
            this.setEndLocation(this.data.driverLocations.locationEnd.address);
            this.setAddressEnd(this.data.driverLocations.locationEnd.address);
          }
        } else {
          this.setEndLocationAsSelectedDepot();
        }
        this.portalAccess = this.data.driver.portal_access_sw;
        this.setForm();
        this.updateAvailableProjects();
      },
      error => {
        this.pendingData = false;
      }
    );
  }

  loadAvatarAsync(imageHash) {
    this.imageUtils.fetchImagesViaHashes(`api/internal/v1/images/drivers`, [imageHash]).then(avatars => {
      this.imageBase64 = avatars[0];
      this.avatar = this.avatarSrc.replace('AVATAR_HASH', this.imageBase64);
    });
  }

  // address must be object with lat, lon and value
  // for depots, position is null because this is just for displaying the address to the user
  setStartLocation(address) {
    this.selectedStartAddress = {
      label: this.addressService.getAddressLabel(address),
      position: [address.lat, address.lon]
    };
    this.freeformAddressStart = this.addressService.getAddressLabel(address);
    this.patchAddressesStart();
  }

  // address must be object with lat, lon and value
  // for depots, position is null because this is just for displaying the address to the user
  setEndLocation(address) {
    this.selectedEndAddress = {
      label: this.addressService.getAddressLabel(address),
      position: [address.lat, address.lon]
    };
    this.freeformAddressEnd = this.addressService.getAddressLabel(address);
    this.patchAddressesEnd();
  }

  setStartLocationAsSelectedDepot() {
    this.locationStartModelName = this.globals.companyDepotModelName;
    this.locationStartModelId = this.selectedDepotItem.companyDepot.id;
    this.setStartLocation(this.selectedDepotItem.companyDepot.address);
  }

  setEndLocationAsSelectedDepot() {
    this.locationEndModelName = this.globals.companyDepotModelName;
    this.locationEndModelId = this.selectedDepotItem.companyDepot.id;
    this.setEndLocation(this.selectedDepotItem.companyDepot.address);
  }

  patchPortalValues() {
    this.myForm.patchValue({
      'driver': {
        'portal_access_sw': this.portalAccess,
      },
    });
  }

  patchForSubmit() {
    this.myForm.patchValue({
      'driver': {
        'id': this.driverId,
        // 'default_vehicle_id': this.defaultVehicleId,
        'daily_working_hours': this.dailyWorkingHours,
        'usual_departure_datetime': this.usualDepartureDatetime,
        'usual_departure_datetime_timezone': this.usualDepartureTimezone,
        'projectsIds': this.projectsIds,
        'driver_work_type': this.driverWorkType,
        'call_tariff': this.callTariff,
        'cost_per_shipment': this.driverCostPerShipment,
        'daily_wage': this.driverDailyWage
      },
      'userProfile': {
        'telephone': this.telephone,
      },
      'driverLocations': {
        'companyDepot': {
          'modelId': this.depotId
        },
        'locationStart': {
          'modelName': this.locationStartModelName,
          'modelId': this.locationStartModelId,
          'address': {
            'countryCode': this.countryCodeStart,
            'state': this.stateStart,
            'county': this.countyStart,
            'city': this.cityStart,
            'district': this.districtStart,
            'street': this.streetStart,
            'street2': this.street2Start,
            'street3': this.street3Start,
            'houseNumber': this.houseNumberStart,
            'postalCode': this.postalCodeStart,
            'isPlace': this.isPlaceStart,
            'placeName': this.placeNameStart,
            'value': this.freeformAddressStart,
            'lat': this.latStart,
            'lon': this.lonStart,
          },
        },
        'locationEnd': {
          'modelName': this.locationEndModelName,
          'modelId': this.locationEndModelId,
          'address': {
            'countryCode': this.countryCodeEnd,
            'state': this.stateEnd,
            'county': this.countyEnd,
            'city': this.cityEnd,
            'district': this.districtEnd,
            'street': this.streetEnd,
            'street2': this.street2End,
            'street3': this.street3End,
            'houseNumber': this.houseNumberEnd,
            'postalCode': this.postalCodeEnd,
            'isPlace': this.isPlaceEnd,
            'placeName': this.isPlaceEnd,
            'value': this.freeformAddressEnd,
            'lat': this.latEnd,
            'lon': this.lonEnd,
          },
        },
      },
    });
    M.updateTextFields();
  }

  setForm() {
    this.myForm.setValue({
      'countryPrefix': this.countryPrefix,
      'phoneNumber': this.phoneNumber,
      'depot': this.selectedDepotItem,
      // 'startDepot': this.selectedStartDepotItem,
      // 'endDepot': this.selectedEndDepotItem,
      'startAddress': this.selectedStartAddress,
      'endAddress': this.selectedEndAddress,
      'driver': {
        'id': this.driverId,
        'default_vehicle_id': this.defaultVehicleId,
        'daily_working_hours': this.dailyWorkingHours,
        'daily_working_hours_unformatted': this.dailyWorkingHoursUnformatted,
        'usual_departure_datetime': this.usualDepartureDatetime,
        'usual_departure_datetime_unformatted': this.usualDepartureDatetimeUnformatted,
        'usual_departure_datetime_timezone': this.usualDepartureTimezone,
        'portal_access_sw': this.portalAccess,
        'projectsIds': this.projectsIds,
        'selectedProjectsIds': this.selectedProjectsIds,
        'regional': this.regional,
        'repository': this.repository,
        'driver_work_type': this.driverWorkType,
        'call_tariff': this.callTariff,
        'cost_per_shipment': this.driverCostPerShipment,
        'daily_wage': this.driverDailyWage
      },
      'userProfile': {
        'name': this.name,
        'telephone': this.telephone,
        // 'address': {
        //   'countryCode': this.countryCode,
        //   'state': this.state,
        //   'county': this.county,
        //   'city': this.city,
        //   'district': this.district,
        //   'street': this.street,
        //   'street2': this.street2,
        //   'houseNumber': this.houseNumber,
        //   'postalCode': this.postalCode,
        //   'isPlace': this.isPlace,
        //   'value': this.freeformAddress,
        //   'lat': this.lat,
        //   'lon': this.lon,
        // },
      },
      'driverLocations': {
        'companyDepot': {
          'modelId': this.depotId
        },
        'locationStart': {
          'modelName': this.locationStartModelName,
          'modelId': this.locationStartModelId,
          'address': {
            'countryCode': this.countryCodeStart,
            'state': this.stateStart,
            'county': this.countyStart,
            'city': this.cityStart,
            'district': this.districtStart,
            'street': this.streetStart,
            'street2': this.street2Start,
            'street3': this.street3Start,
            'houseNumber': this.houseNumberStart,
            'postalCode': this.postalCodeStart,
            'isPlace': this.isPlaceStart,
            'placeName': this.placeNameStart,
            'value': this.freeformAddressStart,
            'lat': this.latStart,
            'lon': this.lonStart,
          },
        },
        'locationEnd': {
          'modelName': this.locationEndModelName,
          'modelId': this.locationEndModelId,
          'address': {
            'countryCode': this.countryCodeEnd,
            'state': this.stateEnd,
            'county': this.countyEnd,
            'city': this.cityEnd,
            'district': this.districtEnd,
            'street': this.streetEnd,
            'street2': this.street2End,
            'street3': this.street3End,
            'houseNumber': this.houseNumberEnd,
            'postalCode': this.postalCodeEnd,
            'isPlace': this.isPlaceEnd,
            'placeName': this.placeNameEnd,
            'value': this.freeformAddressEnd,
            'lat': this.latEnd,
            'lon': this.lonEnd,
          },
        },
      },
    });
    M.updateTextFields();
  }

  resetForm() {
    this.errors = [];
    this.driverId = null;
    this.name = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.defaultVehicleId = null;
    this.vehiclePlateNumber = '';
    this.selectedProjectsIds = [];
    this.projectsIds = [];
    this.dailyWorkingHours = null;
    this.dailyWorkingHoursUnformatted = null;
    this.usualDepartureDatetime = '';
    this.usualDepartureDatetimeUnformatted = '';
    this.usualDepartureTimezone = '';
    this.stops = null;
    this.kilometers = '';
    this.status = 1;
    this.repository = null;
    this.driverWorkType = false;
    this.callTariff = null;
    this.driverCostPerShipment = null;
    this.driverDailyWage = null;
    this.selectedStartAddress = null;
    this.selectedEndAddress = null;
    this.isPlaceStart = false;
    this.isPlaceEnd = false;
    this.placeNameStart = '';
    this.placeNameEnd = '';
    this.imageBase64 = '';
    this.avatar = '';
    this.imageHash = '';
    this.cropper = '';
    this.depotId = null;
    this.regional = false;
    this.portalAccess = this.globals.defaultPortalAccess ? true : false;
    this.selectedDepotItem = {
      companyDepot: {
        id: this.globals.depotId,
        address: {
          value: this.globals.depotAddress,
        },
      },
      name: this.depotUtils.getDepotName(this.globals.depots[this.globals.depotId].companyDepot)
    };
    this.setStartLocationAsSelectedDepot();
    this.setEndLocationAsSelectedDepot();
    this.regionalMapComponent.resetMap();
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    this.regionalChanged();
    this.updateAvailableProjects();
  }

  regionalChanged() {
    if (this.myForm.value.driver.regional) {
      this.regionalElement.nativeElement.classList.add('expanded');
      this.formElement.nativeElement.classList.remove('expanded');
      this.mapToggled.emit('expand');
    } else {
      this.regionalElement.nativeElement.classList.remove('expanded');
      this.formElement.nativeElement.classList.add('expanded');
      this.mapToggled.emit('shrink');
    }
    this.regionalMapComponent.mapToggled();
  }

  regionDataReceived(event) {
    if (event === 'data') {
      this.regional = true;
      this.myForm.patchValue({
        'driver': {
          'regional': this.regional,
        },
      });
      M.updateTextFields();
      this.regionalChanged();
    }

    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (!this.pendingData) {
        clearInterval(dataRefreshIntervalId);
        this.modalService.openDriverModal();
      }
    }
  }

  addressStartInputFocusOut() {
    if (!this.myForm.value.startAddress.timeZone) {
      if (this.addressStartSelect.itemsList['_filteredItems']) {
        const firstItem = this.addressStartSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.addressStartSelect.select(firstItem);
        }
      }
    }
  }

  addressEndInputFocusOut() {
    if (!this.myForm.value.endAddress.timeZone) {
      if (this.addressEndSelect.itemsList['_filteredItems']) {
        const firstItem = this.addressEndSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.addressEndSelect.select(firstItem);
        }
      }
    }
  }

  inputStartAddress() {
    this.selectedStartAddress = '';
    this.myForm.patchValue({
      'startAddress': this.selectedStartAddress,
    });
    this.addressStartSelect.filter((<HTMLInputElement>document.getElementById('start-address-custom-input')).value);
  }

  inputEndAddress() {
    this.selectedEndAddress = '';
    this.myForm.patchValue({
      'endAddress': this.selectedEndAddress,
    });
    this.addressEndSelect.filter((<HTMLInputElement>document.getElementById('end-address-custom-input')).value);
  }

  onStartAddressChange() {
    const addressFormValue = this.myForm.value.startAddress;
    (<HTMLInputElement>document.getElementById('start-address-custom-input')).value = addressFormValue.label;
    if (addressFormValue) {
      this.latStart = addressFormValue.position[0];
      this.lonStart = addressFormValue.position[1];
      this.setStartLocation(addressFormValue);
      this.setAddressStart(addressFormValue.address);
      this.locationStartModelName = this.globals.addressModelName;
    }
  }

  onEndAddressChange() {
    const addressFormValue = this.myForm.value.endAddress;
    (<HTMLInputElement>document.getElementById('end-address-custom-input')).value = addressFormValue.label;
    if (addressFormValue) {
      this.latEnd = addressFormValue.position[0];
      this.lonEnd = addressFormValue.position[1];
      this.setEndLocation(addressFormValue.address);
      this.setAddressEnd(addressFormValue.address);
      this.locationEndModelName = this.globals.addressModelName;
    }
  }

  onDepotChange(selectedDepot) {
    if (selectedDepot) {
      this.selectedDepotItem = {
        companyDepot: {
          id: selectedDepot.companyDepot.id,
          address: {
            value: this.addressService.getAddressLabel(selectedDepot.companyDepot.address),
          },
        },
        name: this.depotUtils.getDepotName(selectedDepot.companyDepot)
      };
      this.myForm.patchValue({
        'depot': this.selectedDepotItem
      });

      this.setStartLocationAsSelectedDepot();
      this.setEndLocationAsSelectedDepot();
      this.emptySelectedProjects();
      this.updateAvailableProjects();
    }
  }

  deleteDriver() {
    this.isClickedOnce = true;
    const myObserver = {
      next: (response) => {
        this.modalGridService.updateDriverGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.isClickedOnce = false;
        this.resetForm();
        this.modalGridService.closeRightModal();
        this.modalGridService.updateDriverGrid();
      },
    };
    this.http.delete('api/v1/drivers/' + this.driverId).pipe(take(1)).subscribe(myObserver);
  }

  setAddressStart(address) {
    this.freeformAddressStart = this.addressService.getAddressLabel(address);
    this.placeNameStart = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCodeStart = address.countryCode;
    } else {
      this.countryCodeStart = '';
    }
    if (address.state) {
      this.stateStart = address.state;
    } else {
      this.stateStart = '';
    }
    if (address.county) {
      this.countyStart = address.county;
    } else {
      this.countyStart = '';
    }
    if (address.city) {
      this.cityStart = address.city;
    } else {
      this.cityStart = '';
    }
    if (address.district) {
      this.districtStart = address.district;
    } else {
      this.districtStart = '';
    }
    if (address.street) {
      this.streetStart = address.street;
    } else {
      this.streetStart = '';
    }
    if (address.street2) {
      this.street2Start = address.street2;
    } else {
      this.street2Start = '';
    }
    if (address.street3) {
      this.street3Start = address.street2;
    } else {
      this.street3Start = '';
    }
    if (address.houseNumber) {
      this.houseNumberStart = address.houseNumber;
    } else {
      this.houseNumberStart = '';
    }
    if (address.postalCode) {
      this.postalCodeStart = address.postalCode;
    } else {
      this.postalCodeStart = '';
    }
    if (address.isPlace) {
      this.isPlaceStart = address.isPlace;
    } else {
      this.isPlaceStart = false;;
    }
    if (address.lat) {
      this.latStart = address.lat;
    }
    if (address.lon) {
      this.lonStart = address.lon;
    }
    this.patchAddressesStart();
  }

  setAddressEnd(address) {
    this.freeformAddressEnd = this.addressService.getAddressLabel(address);
    this.placeNameEnd = this.addressService.getAddressPlace(address);
    if (address.isPlace) {
      this.isPlaceEnd = true;
    } else {
      this.isPlaceEnd = false;
    }
    if (address.countryCode) {
      this.countryCodeEnd = address.countryCode;
    } else {
      this.countryCodeEnd = '';
    }
    if (address.state) {
      this.stateEnd = address.state;
    } else {
      this.stateEnd = '';
    }
    if (address.county) {
      this.countyEnd = address.county;
    } else {
      this.countyEnd = '';
    }
    if (address.city) {
      this.cityEnd = address.city;
    } else {
      this.cityEnd = '';
    }
    if (address.district) {
      this.districtEnd = address.district;
    } else {
      this.districtEnd = '';
    }
    if (address.street) {
      this.streetEnd = address.street;
    } else {
      this.streetEnd = '';
    }
    if (address.street2) {
      this.street2End = address.street2;
    } else {
      this.street2End = '';
    }
    if (address.street3) {
      this.street3End = address.street2;
    } else {
      this.street3End = '';
    }
    if (address.houseNumber) {
      this.houseNumberEnd = address.houseNumber;
    } else {
      this.houseNumberEnd = '';
    }
    if (address.postalCode) {
      this.postalCodeEnd = address.postalCode;
    } else {
      this.postalCodeEnd = '';
    }
    if (address.isPlace) {
      this.isPlaceEnd = address.isPlace;
    } else {
      this.isPlaceEnd = false;
    }
    if (address.lat) {
      this.latEnd = address.lat;
    }
    if (address.lon) {
      this.lonEnd = address.lon;
    }
    this.patchAddressesEnd();
  }

  patchAddressesStart() {
    this.myForm.patchValue({
      'startAddress': this.selectedStartAddress,
      'driverLocations': {
        'locationStart': {
          'address': {
            'countryCode': this.countryCodeStart,
            'state': this.stateStart,
            'county': this.countyStart,
            'city': this.cityStart,
            'district': this.districtStart,
            'street': this.streetStart,
            'street2': this.street2Start,
            'street3': this.street3Start,
            'houseNumber': this.houseNumberStart,
            'postalCode': this.postalCodeStart,
            'isPlace': this.isPlaceStart,
            'value': this.freeformAddressStart,
            'lat': this.latStart,
            'lon': this.lonStart,
          },
        },
      },
    });
    if (this.freeformAddressStart) {
      (<HTMLInputElement>document.getElementById('start-address-custom-input')).value = this.freeformAddressStart.valueOf();
    }
  }

  patchAddressesEnd() {
    this.myForm.patchValue({
      'endAddress': this.selectedEndAddress,
      'driverLocations': {
        'locationEnd': {
          'address': {
            'countryCode': this.countryCodeEnd,
            'state': this.stateEnd,
            'county': this.countyEnd,
            'city': this.cityEnd,
            'district': this.districtEnd,
            'street': this.streetEnd,
            'street2': this.street2End,
            'street3': this.street3End,
            'houseNumber': this.houseNumberEnd,
            'postalCode': this.postalCodeEnd,
            'isPlace': this.isPlaceEnd,
            'value': this.freeformAddressEnd,
            'lat': this.latEnd,
            'lon': this.lonEnd,
          },
        },
      },
    });
    if (this.freeformAddressEnd) {
      (<HTMLInputElement>document.getElementById('end-address-custom-input')).value = this.freeformAddressEnd.valueOf();
    }
  }

  initializeSelect() {
    const select = document.querySelectorAll('select');
    const instancesSelect = M.FormSelect.init(select);
    const dateElement = document.querySelectorAll('.datepicker');
    const dateInstances = M.Datepicker.init(dateElement);
    const timePicker = document.querySelectorAll('.timepicker');
    const instancesTimePicker = M.Timepicker.init(timePicker, {
      twelveHour: false
    });
  }

  portalEnabled() {
    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
      alert(this.demoDisableRequestMsg);
    } else {
      if (!this.globals.portalAccess && this.myForm.value.driver.portal_access_sw) {
        if (confirm(this.portalSettingsConfirmMsg)) {
          const settingsRequestObserver = {
            next: (response) => {
              this.globals.getCompanyData();
              this.globals.portalAccess = true;
            },
            error: (error) => {
              console.error(error);
            },
            complete: () => { },
          };
          const data = {
            companySettings: {
              portal_access_sw: true
            }
          };
          this.http.put('api/v1/companies/' + this.globals.companyId, data).pipe(take(1)).subscribe(settingsRequestObserver);
        } else {
          this.portalAccess = false;
          this.patchPortalValues();
        }
      }
    }
  }

  portalHistoryEnabled() { }

  public submitDriverForm(saveAndNew = false) {
    this.errors = [];
    this.isClickedOnce = true;
    const formValue = this.myForm.value;
    if (formValue.depot) {
      this.depotId = formValue.depot.companyDepot.id;
    } else {
      this.depotId = this.globals.depotId;
    }
    // if (formValue.startDepot) {
    //   this.startDepotId = formValue.startDepot.companyDepot.id;
    // } else {
    //   this.startDepotId = this.globals.depotId;
    // }
    // if (formValue.endDepot) {
    //   this.endDepotId = formValue.endDepot.companyDepot.id;
    // } else {
    //   this.endDepotId = this.globals.depotId;
    // }

    if (formValue.phoneNumber) {
      this.telephone = formValue.countryPrefix + formValue.phoneNumber;
    }
    if (formValue.repository) {
      this.repository = Number(formValue.driver.repository);
    }
    this.driverWorkType = formValue.driver.driver_work_type;
    if (formValue.driver.call_tariff) {
      this.callTariff = Number(formValue.driver.call_tariff);
    }
    if (formValue.driver.cost_per_shipment) {
      this.driverCostPerShipment = Number(formValue.driver.cost_per_shipment);
    }
    if (formValue.driver.daily_wage) {
      this.driverDailyWage = Number(formValue.driver.daily_wage);
    }
    this.projectsIds = [];
    if (formValue.driver.selectedProjectsIds) {
      formValue.driver.selectedProjectsIds.forEach(project => {
        this.projectsIds.push(project.id);
      });
    }
    const departureTimeElement = document.querySelector('#departure-time-driver-form');
    const departureTime = M.Timepicker.getInstance(departureTimeElement).time;
    if (moment(departureTime, 'HH:mm', true).isValid() && departureTime) {
      this.usualDepartureDatetimeUnformatted = departureTime;
      this.usualDepartureDatetime = moment(departureTime, 'HH:mm').format();
      const today = moment().format('YYYY-MM-DD');
      const departureDatetimeUnformatted = today + ' ' + departureTime;
      this.usualDepartureDatetime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format();
      this.usualDepartureTimezone = moment.tz.guess();
    }
    this.dailyWorkingHours = moment.duration(Number(formValue.driver.daily_working_hours_unformatted), 'hours').toISOString();
    this.patchForSubmit();
    const data = this.myForm.value;
    if (this.imageBase64) {
      data['driverImage'] = {
        'base64': this.imageBase64,
      };
    }
    const myObserver = {
      next: (response) => {
        // updated regional data after successful driver submit
        if (formValue.driver.regional) {
          this.regionalMapComponent.driverId = response['item']['driver']['id'];
          this.regionalMapComponent.savePolygonChanges();
        } else {
          this.regionalMapComponent.deleteRegion();
        }
      },
      error: (error) => {
        this.formErrorsUtils.checkResponseForErrorCodes(error);
        const errorsObject = error.error.errors;
        if (errorsObject) {
          const errors = [];
          const keyify = (obj, prefix = '') =>
            Object.keys(obj).reduce((res, el) => {
              if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
                errors.push(obj[el]['0']);
              }
              if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
                if (obj[el]['0']['load']) {
                  errors.push(obj[el]['0']['load']['0']);
                }
              }
              if (Array.isArray(obj[el])) {
                return res;
              } else if (typeof obj[el] === 'object' && obj[el] !== null) {
                return [...res, ...keyify(obj[el], prefix + el + '.')];
              } else {
                return [...res, prefix + el];
              }
            }, []);
          const output = keyify(errorsObject);
          this.errors = errors;
        }
        this.isClickedOnce = false;
      },
      complete: () => {
        const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
        function dataChecker() {
          if (!this.regionalMapComponent.savingChanges) {
            clearInterval(dataRefreshIntervalId);
            this.isClickedOnce = false;
            this.resetForm();
            if (!saveAndNew) {
              this.modalGridService.closeRightModal();
            }
            this.modalGridService.updateDriverGrid();
          }
        }
      },
    };

    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
      alert(this.demoDisableRequestMsg);
      this.isClickedOnce = false;
    } else {
      if (this.driverId) {
        this.http.put('api/v1/drivers/' + this.driverId, data).pipe(take(1)).subscribe(myObserver);
      } else {
        this.http.post('api/v1/drivers', data).pipe(take(1)).subscribe(myObserver);
      }
    }
  }

  // changes driver type & clears all related fields
  changedriverWorkType(event) {
    this.driverWorkType = event.target.checked;
    this.callTariff = null;
    this.driverCostPerShipment = null;
    this.driverDailyWage = null;
    this.myForm.patchValue({
      'driver': {
        'driver_work_type': this.driverWorkType,
        'call_tariff': this.callTariff,
        'cost_per_shipment': this.driverCostPerShipment,
        'daily_wage': this.driverDailyWage
      }
    });
    M.updateTextFields();

    this.genericUtils.scrollToContainerBottom(document.getElementById('driver-basic-form-container'));
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
        typeName: this.bicycleLabel
      },
      {
        index: 1,
        image: scooter,
        typeName: this.scooterLabel
      },
      {
        index: 2,
        image: car,
        typeName: this.carLabel
      },
      {
        index: 3,
        image: van,
        typeName: this.vanLabel
      },
      {
        index: 4,
        image: largeVan,
        typeName: this.largeVanLabel
      },
      {
        index: 5,
        image: truck,
        typeName: this.truckLabel
      }
    ];
  }

  getTranslations() {
    this.listen.push(this.translate.get('ALERTS.PORTAL_SETTINGS_DISABLED').subscribe((res: string) => { this.portalSettingsConfirmMsg = res; }));
    this.listen.push(this.translate.get('ALERTS.DEMO_REQUEST_NOT_POSSIBLE').subscribe((res: string) => { this.demoDisableRequestMsg = res; }));
    // this.listen.push(this.translate.get('ALERTS.PORTAL_SETTINGS_HISTORY_DISABLED').subscribe((res: string) => { this.portalSettingsHistoryConfirmMsg = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));

    setTimeout(() => {
      this.setVehicleTypes();

      const vehiclesDataRefreshIntervalId = setInterval(vehiclesDataChecker.bind(this), 200);
      function vehiclesDataChecker() {
        if (this.globals.vehiclesDataDone) {
          clearInterval(vehiclesDataRefreshIntervalId);
          this.vehicles = this.globals.vehiclesArray;
        }
      }
    }, 300);
  }

  resetUploaderPopup() {
    // this.imageBase64 = '';
    // this.avatar = '';
    const imageElement = document.getElementById('cropper-image-container'),
      imgPreview = document.querySelector('#preview-img') as HTMLImageElement;
    imgPreview.src = '/assets/lastmilyAssets/user.png';
    // if (imageElement) {
    imageElement.innerHTML = '';
    // }
    // if (this.cropper) {
    //   this.cropper.reset();
    // }
    this.cropper = '';
  }

  openUploaderPopup() {
    this.resetUploaderPopup();
    document.getElementById('avatar-upload-popup').classList.add('open');
    document.getElementById('avatar-upload-popup-bg').classList.remove('hidden');
  }

  closeUploaderPopup() {
    document.getElementById('avatar-upload-popup').classList.remove('open');
    document.getElementById('avatar-upload-popup-bg').classList.add('hidden');
  }

  cropImgFunc(event) {
    let result = document.querySelector('#cropper-image-container'),
      imgPreview = document.querySelector('#preview-img') as HTMLImageElement;
    if (event.target.files.length) {
      // start file reader
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          // create new image
          let img = document.createElement('img');
          img.id = 'image';
          img.src = String(event.target.result);
          img.width = 544;
          img.height = 370;
          // clean result before
          result.innerHTML = '';
          // append new image
          result.appendChild(img);
          // init cropper
          const cropper = new Cropper(img, {
            viewMode: 1,
            dragMode: 'move',
            aspectRatio: 1,
            autoCropArea: 0.68,
            minContainerWidth: 544,
            minContainerHeight: 370,
            center: false,
            zoomOnWheel: false,
            zoomOnTouch: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            guides: false,
            toggleDragModeOnDblclick: false,
            ready: (event) => {
              this.cropper = cropper;
            },
            crop: (event) => {
              if (this.cropper) {
                let imgSrc = this.cropper.getCroppedCanvas({
                  width: 125,
                  height: 125
                }).toDataURL("image/png");
                imgPreview.src = imgSrc;
              }
            },
          });

        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  getAvatar() {
    if (this.cropper) {
      this.imageBase64 = (this.cropper.getCroppedCanvas({
        width: 125,
        height: 125
      }).toDataURL()).replace('data:image/png;base64,', '');
      this.avatar = this.avatarSrc.replace('AVATAR_HASH', this.imageBase64);
    }
    this.closeUploaderPopup();
  }

  sliderChange() {
    // let slideVal = ui.value;
    let zoomRatio = Math.round((this.imageSliderValue - this.slideValGlobal) * 10) / 10;
    this.slideValGlobal = this.imageSliderValue;
    this.cropper.zoom(zoomRatio);
  }

  updateAvailableProjects() {
    this.projectsOptions = [];
    this.allProjects.forEach(project => {
      if (project.depot_ids.includes(this.selectedDepotItem.companyDepot.id)) {
        this.projectsOptions.push(project);
      }
    });
  }

  emptySelectedProjects() {
    this.selectedProjectsIds = [];
    this.myForm.patchValue({
      'driver': {
        'selectedProjectsIds': this.selectedProjectsIds
      }
    });
  }

  initializeSlider() {
    this.imageSliderOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 0.1,
      start: [this.slideValGlobal],
      connect: 'lower',
      range: {
        min: 0,
        max: 1
      },
    };
  }

  ngOnInit() {

    this.initializeSelect();
    this.initializeSlider();

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    const depotsDataRefreshIntervalId = setInterval(depotsDataChecker.bind(this), 200);
    function depotsDataChecker() {
      if (this.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);

        this.depotsOptions = this.globals.depotsWithNamesArray;
        this.selectedDepotItem = {
          companyDepot: {
            id: this.globals.depotId,
            address: {
              value: this.addressService.getAddressLabel(this.globals.depots[this.globals.depotId].companyDepot.address),
            },
          },
          name: this.depotUtils.getDepotName(this.globals.depots[this.globals.depotId].companyDepot)
        };
        this.myForm.patchValue({
          'depot': this.selectedDepotItem,
        });
      }
    }


    this.startAddresses = concat(
      of([]), // default items
      this.addressStartInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.startAddressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.startAddressesLoading = false)
        ))
      )
    );
    this.endAddresses = concat(
      of([]), // default items
      this.addressEndInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.endAddressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.endAddressesLoading = false)
        ))
      )
    );
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
