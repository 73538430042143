/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LmAreasLookUpService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

    // ------------------------- ADD DTOs ---------------------
  
    static readonly apiAreasLookUpPostPath = '/api/v1/open-street-maps/look-up';

    // POST 
    apiAreasLookUpPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmAreasLookUpService.apiAreasLookUpPostPath, 'post');
      if (params) rb.body(params.body, 'application/*+json');

      return this.http
        .request(
          rb.build({
            responseType: 'json',
            accept: 'application/json'
          })
        )
        .pipe(
          filter((r: any) => r instanceof HttpResponse),
          map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<any>>;
          })
        );
    }
  

    apiAreasLookUpPost(params?: { body?: any }): Observable<Array<any>> {
      return this.apiAreasLookUpPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    

}
