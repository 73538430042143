import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import * as moment from 'moment';
import { PreloadStopPointsService } from './preload-stop-points.service';
import { take } from 'rxjs/operators';
import { ColourService } from './colour.service';
import { GenericService } from './generic.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';

@Injectable({
  providedIn: 'root'
})
export class ProjectProblemDataService {
  projectUrl = 'api/internal/v2/project/projects/PROJECT_ID';
  projectProblemUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID';
  // allStopPointsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points';
  allStopPointsUrl = 'api/internal/v2/project/problems/PROJECT_PROBLEM_ID/stop-points';
  smartPointsUrl = 'api/v1/smart-points';
  pudoPointsUrl = 'api/v1/pudo-points';
  solutionUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/solution';
  routeSettingsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/route-settings';
  manualModifiedRouteUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/manual-modified-route-items';
  loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';
  driversUrl = 'api/internal/v2/drivers';

  collaboratorRouteSettingsUrl = 'api/v1/partner-route-settings';
  collaboratorSolutionUrl = 'api/v1/partner-solution';
  collaboratorStopPointsUrl = 'api/internal/v2/partner-overview-stop-points';
  collaboratorDriversUrl = 'api/v1/partner-drivers';
  // collaboratorDriversUrl = 'api/internal/v2/partner-drivers';

  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  loadProjectProblemDone = false;
  loadSolutionDone = false;
  loadModificationsDone = false;
  loadStopPointsDone = false;
  loadSmartPointsDone = false;
  loadExtraStopPoints = false;
  loadDepotsDone = false;
  loadDriversDone = false;
  loadRouteSettingsDone = false;
  loadVehiclesDone = false;

  isStopFormModalProjectProblemIdLoaded = false;

  hasLoadedAllData = false;

  selectedDriver;

  projectId = null;
  projectName = '';
  projectData;
  projectDepotId;
  projectProblemId;
  projectProblemData = {};
  projectProblemDayOfWeek = '';
  solutionData = {};
  stopPointsArray = [];
  smartPointsArray = [];
  projectProblemChargeCategories = [];
  projectProblemBasicVoucherServices = [];
  stopPoints = {};
  stopPointClustersByLevelByClusterId = {};
  stopPointsMarkerData = {};
  routeSettingIdsWithReload = [];
  routeIndexesPercentage = {};
  stopPointsProgress = {};
  stopPointIndexInArray = {};
  mergedStops = {};
  // mergedTo = {};
  depots = {};
  drivers = {};
  driversArray = [];
  routeSettingsArray = [];
  routeSettings = {};
  routeSettingsById = {};
  vehicleData = {};
  vehicles = {};
  routeSettingIdsToDrivers = {};
  // routeIndexesToDrivers = {};
  driversToRouteSettingIds = {};
  stopPointSolutionData = {};
  sequenceArrayPerRouteSettingId = {};
  stopPointModifications = {};
  manualModifiedRouteItems = [];
  manualModifiedSequenceItems = [];
  manualModifiedDirectSequenceItems = [];
  volumesArray = [];
  loadedStops = {};
  routesWithCloseOvertime = [];
  vehiclesWithCloseCapacity = [];

  estimatedArrivalTimeDifferenceMinutesPerRouteSettingId = {};

  updatingStopPoints = false;

  timer;

  maxStopPointsInARequest = 600;
  // maxStopPointsInARequest = 600;

  constructor(
    public http: HttpClient,
    private globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    public preloadStopPointsService: PreloadStopPointsService,
    private colourService: ColourService,
    private genericService: GenericService,
    private stopPointUtils: StopPointUtils,
  ) {
    this.projectProblemDayOfWeek = this.days[moment(this.projectProblemData['departure_datetime']).day()];
  }

  loadDataAfterOptimize() {
    // console.log('Starting timer for load data after optimize...');
    this.timer = moment();
    this.manualModifiedRouteItems = [];
    this.manualModifiedSequenceItems = [];
    this.manualModifiedDirectSequenceItems = [];
    this.routeSettingIdsWithReload = [];
    this.stopPointModifications = {};
    this.loadDepotsDone = true;
    this.loadDriversDone = false;
    this.loadVehiclesDone = true;
    this.loadSmartPointsDone = true;
    this.loadRouteSettings().pipe(take(1)).subscribe(response => {
      this.routeSettingsArray = [];
      if (response['items']) {
        this.routeSettingsArray = response['items'];
        this.setRouteSettings(this.routeSettingsArray);
      }
      this.loadRouteSettingsDone = true;
    });
    this.loadProjectProblemData().pipe(take(1)).subscribe(response => {
      this.projectProblemData = response['item']['projectProblem'];
      this.loadProjectProblemDone = true;
      const dataRefreshModificationsIntervalId = setInterval(() => {
        if (this.loadRouteSettingsDone) {
          clearInterval(dataRefreshModificationsIntervalId);
          this.checkForModifications();
          this.checkForSolution();
        }
      }, 200);
      const dataRefreshStopsIntervalId = setInterval(() => {
        if (this.loadModificationsDone) {
          clearInterval(dataRefreshStopsIntervalId);
          let pageSize = this.projectProblemData['totalStopPointsCount'];
          let pages = 1;
          const done = [];
          if (this.projectProblemData['totalStopPointsCount'] > this.maxStopPointsInARequest) {
            pageSize = Math.ceil(this.projectProblemData['totalStopPointsCount'] / 2);
            pages = 2;
          }
          let stopPointsArray = [];
          for (let i = 0; i < pages; i++) {
            done.push(false);
            this.loadStopPointsAfterOptimize(pageSize, i).pipe(take(1)).subscribe(stopsResponse => {
              stopPointsArray = stopPointsArray.concat(stopsResponse['items']['stopPoints']);
              done[i] = true;
              if (done.every(Boolean)) {
                // dont update map because it is updated by project-view
                this.updateStopPointsAddData(stopPointsArray, false, false);
                this.setStopPoints(this.stopPointsArray);
                const dataRefreshIntervalId = setInterval(() => {
                  if (this.loadExtraStopPoints) {
                    clearInterval(dataRefreshIntervalId);
                    this.calculateDriversStopPointsPercentage();
                    this.loadStopPointsDone = true;
                    // console.log(moment.duration(moment().diff(this.timer)).asSeconds() + 's elapsed');
                  }
                }, 200);
              }
            });
          }
        }
      }, 200);
    });
  }

  countStopPointsInProjectProblem() {
    const activeStopPointsArray = this.stopPointsArray;
    let count = 0;
    activeStopPointsArray.forEach(stopPoint => {
      // this is to handle both cases of SP data from be, with stopPoint wrapper and without
      if (stopPoint.stopPoint) {
        stopPoint = stopPoint.stopPoint;
      }
      if (
        stopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE'] &&
        stopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']
      ) {
        count++;
      }
    });
    return count;
  }

  countFoodStopPointsInProjectProblem(newShipmentsArray = []) {
    const self = this;
    if (newShipmentsArray.length) {
      // filter incoming new shipments array & remove any stop point that is already on the dispatched
      let newShipmentsData = [];
      newShipmentsArray.forEach(newShipment => {
        let newShipmentExists = false;
        this.stopPointsArray.forEach(stopPoint => {
          if (stopPoint['stopPoint']['id'] == newShipment['stopPoint']['id']) {
            newShipmentExists = true;
          }
        });

        if (!newShipmentExists) {
          newShipmentsData.unshift(newShipment);
        }
      });
      return getStopPointsCount(newShipmentsData);
    } else {
      return getStopPointsCount();
    }

    function getStopPointsCount(extraStopPoints = []) {
      let activeStopPointsArray = self.stopPointsArray;
      if (extraStopPoints.length) {
        activeStopPointsArray = [...self.stopPointsArray.concat(extraStopPoints)];
      } else {
        activeStopPointsArray = self.stopPointsArray;
      }
      let count = 0;
      activeStopPointsArray.forEach(stopPoint => {
        // this is to handle both cases of SP data from be, with stopPoint wrapper and without
        if (stopPoint.stopPoint) {
          stopPoint = stopPoint.stopPoint;
        }
        if (
          stopPoint.entity_status === self.globals.stopPointEntityStatusConstants['ACTIVE'] &&
          stopPoint.related_to === self.globals.stopPointRelatedToConstants['SELF'] &&
          stopPoint.service_type === self.globals.stopPointServiceTypeConstants['DELIVERY']
        ) {
          count++;
        }
      });

      return count;
    }
  }

  countDriversInProjectProblem() {
    const activeStopPointsArray = this.driversArray;
    let count = 0;
    activeStopPointsArray.forEach(driver => {
      const routeSettingId = this.driversToRouteSettingIds[driver.driver.id];
      if (this.projectProblemData) {
        if (this.projectProblemData['optimization_state'] === this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']) {
          if (this.sequenceArrayPerRouteSettingId) {
            if (this.sequenceArrayPerRouteSettingId[routeSettingId]) {
              if (Object.keys(this.sequenceArrayPerRouteSettingId[routeSettingId]).length) {
                count++;
              }
            }
          }
        } else {
          if (routeSettingId) {
            count++;
          }
        }
      }
    });
    return count;
  }

  updateRouteSettings() {
    console.warn('updating route settings...')
    this.loadProjectProblemDone = false;
    this.loadSolutionDone = false;
    this.loadModificationsDone = false;
    this.loadRouteSettingsDone = false;

    this.manualModifiedRouteItems = [];
    this.manualModifiedSequenceItems = [];
    this.manualModifiedDirectSequenceItems = [];
    this.stopPointModifications = {};

    this.loadRouteSettings().pipe(take(1)).subscribe(response => {
      this.routeSettingsArray = [];
      if (response['items']) {
        this.routeSettingsArray = response['items'];
        this.setRouteSettings(this.routeSettingsArray);
      }
      this.loadRouteSettingsDone = true;
    });
    const dataRefreshIntervalId = setInterval(() => {
      if (this.loadProjectProblemDone && this.loadRouteSettingsDone) {
        clearInterval(dataRefreshIntervalId);
        this.checkForModifications();
        this.checkForSolution();
      }
    }, 200);
    this.loadProjectProblemData().pipe(take(1)).subscribe(response => {
      this.projectProblemData = response['item']['projectProblem'];
      this.loadProjectProblemDone = true;
    });
  }

  loadCollaboratorOverviewData() {
    console.log("loadCollaboratorOverviewData");
    const self = this;
    const previousStopPointsArray = [...this.stopPointsArray];

    this.manualModifiedRouteItems = [];
    this.manualModifiedSequenceItems = [];
    this.manualModifiedDirectSequenceItems = [];
    this.volumesArray = [];
    this.routeSettingIdsWithReload = [];
    this.stopPointModifications = {};
    this.mergedStops = {};
    this.loadCollaboratorRouteSettings().pipe(take(1)).subscribe(response => {
      this.routeSettingsArray = [];
      if (response['items']) {
        this.routeSettingsArray = response['items'];
        this.setRouteSettings(this.routeSettingsArray);
        this.genericService.updateStopsGridInCollaboratorOverview();
      }
      this.loadRouteSettingsDone = true;
      this.checkForCollaboratorSolution();
    });
    this.projectProblemData = null;
    this.projectId = null;
    this.loadDepotsDone = true;
    this.loadModificationsDone = true;
    this.loadProjectProblemDone = true;
    this.projectName = '';

    let stopPointsArray = [];
    this.loadCollaboratorStopPoints().pipe(take(1)).subscribe(stopsResponse => {
      if (stopsResponse) {
        if (stopsResponse['items']['stopPoints']) {
          stopPointsArray = stopPointsArray.concat(stopsResponse['items']['stopPoints']);
          this.stopPointsArray = [...stopPointsArray];
          removeOldFoodStops();
          this.genericService.updateStopsGridInCollaboratorOverview();
        }
      }
      this.stopPointsArray = stopPointsArray;
      this.stopPointsArray = [...stopPointsArray];
      removeOldFoodStops();
      this.setStopPoints(this.stopPointsArray);
      this.calculateDriversStopPointsPercentage();
      this.loadStopPointsDone = true;

      // remove stop points that no longer exist from the map
      previousStopPointsArray.forEach(previousStopPoint => {
        let removeCurrent = true;
        this.stopPointsArray.forEach(currStopPoint => {
          if (previousStopPoint['stopPoint']) {
            if (previousStopPoint['stopPoint']['id'] == currStopPoint['stopPoint']['id']) {
              removeCurrent = false;
            }
          }
        });

        if (removeCurrent) {
          if (previousStopPoint['stopPoint']) {
            this.viewProjectProblemService.removeStopPointFromMap(previousStopPoint['stopPoint']['id']);
          }
        }
      });
    });


    const vehiclesDataRefreshIntervalId = setInterval(() => {
      if (this.globals.vehiclesDataDone) {
        clearInterval(vehiclesDataRefreshIntervalId);
        this.globals.vehiclesArray.forEach(vehicle => {
          this.vehicles[vehicle.vehicle.id] = vehicle;
        });
        this.loadVehiclesDone = true;
      }
    }, 200);

    // [food mode]: remove completed/canceled sps that were fulfilled >1hr ago
    function removeOldFoodStops() {
      if (self.globals.collaboratorModeEnabled && self.globals.foodModeEnabled) {
        self.stopPointsArray.forEach((stopPoint, index) => {
          if (
            stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS_HANDED
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME
            || stopPoint['stopPoint'].fulfillment_events[0]['reason'] == self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['COMPLETED']].COMPLETED_HANDED
          ) {
            let hoursDifference = moment.duration(moment().diff(stopPoint['stopPoint'].fulfillment_events[0]['fulfillment_datetime'])).asHours();
            if (hoursDifference > 1) {
              self.stopPointsArray.splice(index, 1);
            }
          }
        });
      }
    }
  }

  loadCollaboratorOverviewStopPoints() {
    let stopPointsArray = [];
    this.loadCollaboratorStopPoints().pipe(take(1)).subscribe(stopsResponse => {
      if (stopsResponse['items']['stopPoints']) {
        stopPointsArray = stopPointsArray.concat(stopsResponse['items']['stopPoints']);
      }
      this.stopPointsArray = stopPointsArray;
      this.setStopPoints(this.stopPointsArray);
      this.calculateDriversStopPointsPercentage();
      this.loadStopPointsDone = true;
    });
  }

  loadData() {
    // console.log('Starting timer for load data...');
    this.hasLoadedAllData = false;
    this.projectDepotId = null;
    this.timer = moment();
    this.manualModifiedRouteItems = [];
    this.manualModifiedSequenceItems = [];
    this.manualModifiedDirectSequenceItems = [];
    this.volumesArray = [];
    this.routeSettingIdsWithReload = [];
    this.stopPointModifications = {};
    this.mergedStops = {};
    this.loadRouteSettings().pipe(take(1)).subscribe(response => {
      this.routeSettingsArray = [];
      if (response['items']) {
        this.routeSettingsArray = response['items'];
        this.setRouteSettings(this.routeSettingsArray);
      }
      this.loadRouteSettingsDone = true;
    });
    const dataRefreshIntervalId = setInterval(() => {
      if (this.loadProjectProblemDone && this.loadRouteSettingsDone) {
        // console.log(moment.duration(moment().diff(this.timer)).asSeconds() + 's elapsed');
        clearInterval(dataRefreshIntervalId);
        this.checkForModifications();
        this.checkForSolution();
      }
    }, 200);
    this.loadProjectProblemData().pipe(take(1)).subscribe(response => {
      this.projectProblemData = response['item']['projectProblem'];
      this.projectId = this.projectProblemData['project_id'];
      this.loadProjectData().pipe(take(1)).subscribe(projectResponse => {
        this.projectName = projectResponse['items'][0]['project']['title'];
        this.projectData = projectResponse['items'][0]['project'];
        this.projectDepotId = projectResponse['items'][0]['project']['company_depot_id'];
      });
      this.loadProjectProblemDone = true;

      let pageSize = 0;
      let pages = 1;
      const done = [];
      if (this.projectProblemData['totalStopPointsCount'] > 1000) {
        pages = 3;
        pageSize = Math.ceil(this.projectProblemData['totalStopPointsCount'] / pages);
      } else if (this.projectProblemData['totalStopPointsCount'] > this.maxStopPointsInARequest) {
        pages = 2;
        pageSize = Math.ceil(this.projectProblemData['totalStopPointsCount'] / pages);
      } else {
        pageSize = this.projectProblemData['totalStopPointsCount'];
      }

      // load smart points before loading stop points
      this.loadSmartPoints().pipe(take(1)).subscribe(smartResponse => {
        this.smartPointsArray = smartResponse['items'];
        this.loadSmartPointsDone = true;

        // load stop points
        let stopPointsArray = [];
        for (let i = 0; i < pages; i++) {
          done.push(false);
          this.loadStopPoints(pageSize, i).pipe(take(1)).subscribe(stopsResponse => {
            stopPointsArray = stopPointsArray.concat(stopsResponse['items']['stopPoints']);
            done[i] = true;
            if (done.every(Boolean)) {
              this.stopPointsArray = stopPointsArray;
              this.projectProblemChargeCategories = stopsResponse['items']['charge_categories'];
              this.projectProblemBasicVoucherServices = stopsResponse['items']['basic_voucher_services'];
              this.setStopPoints(this.stopPointsArray);
              this.calculateDriversStopPointsPercentage();
              this.loadStopPointsDone = true;

              this.hasLoadedAllData = true;
              // console.log(moment.duration(moment().diff(this.timer)).asSeconds() + 's elapsed');
            }
          });
        }
      });
    });

    const depotsDataRefreshIntervalId = setInterval(() => {
      if (this.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);
        this.setDepots(this.globals.depotsArray);
        this.loadDepotsDone = true;
      }
    }, 200);

    const vehiclesDataRefreshIntervalId = setInterval(() => {
      if (this.globals.vehiclesDataDone) {
        clearInterval(vehiclesDataRefreshIntervalId);
        this.globals.vehiclesArray.forEach(vehicle => {
          this.vehicles[vehicle.vehicle.id] = vehicle;
        });
        this.loadVehiclesDone = true;
      }
    }, 200);

    this.loadLoadedStops().pipe(take(1)).subscribe(response => {
      if (response) {
        this.loadedStops = response['items'];
      }
    });
  }

  loadDepotData() {
    this.loadDepots().pipe(take(1)).subscribe(response => {
      this.setDepots(response['items']);
      this.loadDepotsDone = true;
    });
  }

  dataReady() {
    // loadSmartPointsDone must always be true in shipper portal!
    if (this.globals.collaboratorModeEnabled) {
      this.loadSmartPointsDone = true;
    }

    if (
      this.loadProjectProblemDone
      && this.loadSolutionDone
      && this.loadModificationsDone
      && this.loadSmartPointsDone
      && this.loadStopPointsDone
      && this.loadDepotsDone
      && this.loadDriversDone
      && this.loadRouteSettingsDone
      && this.loadVehiclesDone
    ) {
      return true;
    } else {
      // console.log(
      //   'data not ready: ' +
      //   'loadSolutionDone-' + this.loadSolutionDone +
      //   ' loadModificationsDone-' + this.loadModificationsDone +
      //   ' loadStopPointsDone-' + this.loadStopPointsDone +
      //   ' loadDepotsDone-' + this.loadDepotsDone +
      //   ' loadDriversDone-' + this.loadDriversDone +
      //   ' loadRouteSettingsDone-' + this.loadRouteSettingsDone +
      //   ' loadVehiclesDone-' + this.loadVehiclesDone
      // );
      return false;
    }
  }

  resetFlags() {
    this.loadProjectProblemDone = false;
    this.loadSolutionDone = false;
    this.loadModificationsDone = false;
    this.loadStopPointsDone = false;
    this.loadSmartPointsDone = false;
    this.loadDepotsDone = false;
    this.loadDriversDone = false;
    this.loadRouteSettingsDone = false;
    this.loadVehiclesDone = false;
  }

  addStopPoint(stopPointData) {
    if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint; }
    if (this.stopPoints[stopPointData.id]) {
      this.updateSingleStopPoint(stopPointData);
    } else {
      this.stopPointsArray.push(stopPointData);
      this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
      this.stopPoints[stopPointData.id] = stopPointData;
      this.viewProjectProblemService.updateStopPointsGrid({
        stopPointsToUpdate: [],
        stopPointsToAdd: [stopPointData],
        stopPointsToRemove: []
      });
      this.viewProjectProblemService.displayStopPointsToMap([stopPointData]);
      this.calculateStopPointIndexInArray();
    }
    this.updateStopPointsModifications();
  }

  addStopPoints(stopPoints) {
    stopPoints.forEach(stopPointData => {
      if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint; }
      if (this.stopPoints[stopPointData.id]) {
        this.updateSingleStopPoint(stopPointData);
      } else {
        this.stopPointsArray.push(stopPointData);
        this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
        this.stopPoints[stopPointData.id] = stopPointData;
        this.viewProjectProblemService.updateStopPointsGrid({
          stopPointsToUpdate: [],
          stopPointsToAdd: [stopPointData],
          stopPointsToRemove: []
        });
        this.viewProjectProblemService.displayStopPointsToMap([stopPointData]);
        this.calculateStopPointIndexInArray();
      }
    });
    this.updateStopPointsModifications();
  }

  updateMultipleStopPoints(stopPointsData) {
    const updateStopPointsData = [];
    stopPointsData.forEach(stopPointData => {
      if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint; }
      if (stopPointData) {
        const id = stopPointData.id;
        this.stopPoints[id] = stopPointData;
        this.stopPointsArray[this.stopPointIndexInArray[id]] = stopPointData;
        this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
        updateStopPointsData.push(stopPointData);
        this.viewProjectProblemService.updateStopPointDataInGrid(stopPointData);
      }
    });
    this.viewProjectProblemService.displayStopPointsToMap(updateStopPointsData);
  }

  updateSingleStopPoint(stopPointData) {
    if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint; }
    if (stopPointData) {
      const id = stopPointData.id;
      this.stopPoints[id] = stopPointData;
      this.stopPointsArray[this.stopPointIndexInArray[id]] = stopPointData;
      this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
      this.viewProjectProblemService.updateStopPointDataInGrid(stopPointData);
      this.viewProjectProblemService.displayStopPointsToMap([stopPointData]);
    }
  }

  updateSingleStopPointAddData(stopPointData, addOnlyFulfillmentStatuses) {
    let changed = false;
    if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint; }
    if (stopPointData) {
      const id = stopPointData.id;
      Object.keys(stopPointData).forEach(key => {
        // if the stop point have changes, then mark it as changed to be updated
        if (this.stopPoints[id][key] !== stopPointData[key]) {
          if (key === 'fulfillment_events') {
            if (JSON.stringify(this.stopPoints[id][key]) !== JSON.stringify(stopPointData[key])) {
              this.stopPoints[id][key] = stopPointData[key];
              this.stopPointsArray[this.stopPointIndexInArray[id]][key] = stopPointData[key];
              changed = true;
            }
          } else {
            this.stopPoints[id][key] = stopPointData[key];
            this.stopPointsArray[this.stopPointIndexInArray[id]][key] = stopPointData[key];
            changed = true;
          }
        }
      });
      // if we have a general update and there is no solution in the new data,
      // remove the solution - this means that in the new optimization, this point was dropped and no longer has a solution
      // if the update is to add only the new fss don't remove the solution
      if (!addOnlyFulfillmentStatuses && !stopPointData.solution) {
        delete (this.stopPoints[id]['solution']);
        delete (this.stopPointsArray[this.stopPointIndexInArray[id]]['solution']);
        changed = true;
      }
      this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
    }
    return changed;
  }

  updateStopPointsAddData(stopPoints, addOnlyFulfillmentStatuses = false, updateMap = true) {
    const stopPointsToUpdate = [];
    const stopPointsToAdd = [];
    stopPoints.forEach(stopPoint => {
      if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
      if (this.stopPoints[stopPoint.id]) {
        let changed = this.updateSingleStopPointAddData(stopPoint, addOnlyFulfillmentStatuses);
        const stopPointFullData = { stopPoint: this.stopPoints[stopPoint.id] };
        if (changed) {
          stopPointsToUpdate.push(stopPointFullData);
        }
      } else {
        stopPointsToAdd.push(stopPoint.id);
      }
    });
    if (stopPointsToAdd.length) {
      this.loadExtraStopPoints = false;
      this.loadSpecificStopPoints(stopPointsToAdd).pipe(take(1)).subscribe(response => {
        this.addStopPoints(response['items']['stopPoints']);
        this.loadExtraStopPoints = true;
      });
    } else {
      this.loadExtraStopPoints = true;
    }

    const dataSolutionIntervalId = setInterval(() => {
      if (this.loadSolutionDone) {
        this.viewProjectProblemService.updateStopPointsGrid({
          stopPointsToUpdate: stopPointsToUpdate,
          stopPointsToAdd: [],
          stopPointsToRemove: []
        });
        clearInterval(dataSolutionIntervalId);
      }
    }, 200);

    if (updateMap) {
      this.viewProjectProblemService.displayStopPointsToMap(stopPointsToUpdate);
    }
  }

  sameDayDeliveryChecker(ids) {
    const sameDayDeliveryPairIds = [];
    ids.forEach(id => {
      const data = this.stopPoints[id];
      if (data) {
        data.deliverySources.forEach(source => {
          if (source.modelName === this.globals.stopPointModelName) {
            sameDayDeliveryPairIds.push(source.modelId);
          }
        });
        data.pickupDestinations.forEach(destination => {
          if (destination.modelName === this.globals.stopPointModelName) {
            sameDayDeliveryPairIds.push(destination.modelId);
          }
        });
      }
    });
    return sameDayDeliveryPairIds;
  }

  removeStopPoint(data) {
    if (data) {
      if (data.id) {
        const id = data.id;
        const stopPointDataToRemove = this.stopPoints[id];
        this.viewProjectProblemService.updateStopPointsGrid({
          stopPointsToUpdate: [],
          stopPointsToAdd: [],
          stopPointsToRemove: [stopPointDataToRemove]
        });
        this.viewProjectProblemService.removeStopPointFromMap(id);
        this.stopPointsArray.splice(this.stopPointIndexInArray[id], 1);
        this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
        delete (this.stopPoints[id]);
        delete (this.stopPointIndexInArray[id]);
        this.calculateStopPointIndexInArray();

        if (data.zoom_levels) {
          data.zoom_levels.forEach(levelData => {
            const level = levelData.level;
            const centerId = levelData.id;
            this.viewProjectProblemService.removeStopPointFromCluster(data.id, level, centerId);
          });
        }
      }
    }
  }

  updateStopPoints(afterImport = false) {
    if (!this.updatingStopPoints && this.projectProblemId) {
      this.updatingStopPoints = true;
      const previousStopPointsArray = [...this.stopPointsArray];
      const previousStopPoints = { ...this.stopPoints };
      this.loadProjectProblemData().pipe(take(1)).subscribe(response => {
        this.projectProblemData = response['item']['projectProblem'];
        this.loadSolutionDone = false;
        this.checkForSolution();
        this.loadProjectProblemDone = true;
        let pageSize = this.projectProblemData['totalStopPointsCount'];
        let pages = 1;
        const done = [];
        if (this.projectProblemData['totalStopPointsCount'] > this.maxStopPointsInARequest) {
          pageSize = Math.ceil(this.projectProblemData['totalStopPointsCount'] / 2);
          pages = 2;
        }
        let stopPointsArray = [];
        const dataSolutionIntervalId = setInterval(() => {
          if (this.loadSolutionDone) {
            clearInterval(dataSolutionIntervalId);
            for (let i = 0; i < pages; i++) {
              done.push(false);
              this.loadStopPoints(pageSize, i).pipe(take(1)).subscribe(stopsResponse => {
                stopPointsArray = stopPointsArray.concat(stopsResponse['items']['stopPoints']);
                done[i] = true;
                if (done.every(Boolean)) {
                  this.stopPointsArray = stopPointsArray;

                  this.setStopPoints(this.stopPointsArray);
                  if (this.stopPointsArray !== previousStopPointsArray) {
                    const stopPointsToUpdate = [];
                    const stopPointsToAdd = [];
                    const stopPointsToRemove = [];
                    this.stopPointsArray.forEach(stopPoint => {
                      if (previousStopPoints[stopPoint.id]) {
                        if (JSON.stringify(previousStopPoints[stopPoint.id]) !== JSON.stringify(stopPoint)) {
                          stopPointsToUpdate.push(stopPoint);
                        }
                      } else {
                        stopPointsToAdd.push(stopPoint);
                      }
                    });
                    previousStopPointsArray.forEach(stopPoint => {
                      if (!this.stopPoints[stopPoint.id]) {
                        stopPointsToRemove.push(stopPoint);
                      }
                    });
                    this.viewProjectProblemService.updateStopPointsGrid({
                      stopPointsToUpdate: stopPointsToUpdate,
                      stopPointsToAdd: stopPointsToAdd,
                      stopPointsToRemove: stopPointsToRemove
                    });
                    this.viewProjectProblemService.displayStopPointsToMap(stopPointsToUpdate.concat(stopPointsToAdd));
                    stopPointsToRemove.forEach(stopPoint => {
                      this.viewProjectProblemService.removeStopPointFromMap(stopPoint.id);
                    });
                    this.viewProjectProblemService.updateDriversGrid();
                  }
                  if (afterImport) {
                    this.viewProjectProblemService.updateProjectProblem();
                  } else {
                    this.viewProjectProblemService.updateProjectProblemStatus();
                  }
                  this.updatingStopPoints = false;
                  // console.log(moment.duration(moment().diff(this.timer)).asSeconds() + 's elapsed');
                }
                this.updateStopPointsModifications();
                this.preloadStopPointsService.stopPointsArrayByProjectProblemId[this.projectProblemId] = this.stopPointsArray;
              });
            }
          }
        }, 200);
      });
    } else {
      setTimeout(() => {
        this.updateStopPoints();
      }, 200);
    }
  }

  updateStopPointsModifications() {
    const previousModifiedRouteData = this.manualModifiedRouteItems;
    const previousModifiedSequenceData = this.manualModifiedSequenceItems;
    const previousModifiedDirectSequenceData = this.manualModifiedDirectSequenceItems;
    this.loadModifications().pipe(take(1)).subscribe(manualModifiedRouteData => {
      const modifiedStopPointsToUpdate = [];
      this.manualModifiedRouteItems = manualModifiedRouteData['items']['route'];
      if (JSON.stringify(this.manualModifiedRouteItems) !== JSON.stringify(previousModifiedRouteData)) {
        Object.keys(this.manualModifiedRouteItems).forEach(stopPointId => {
          if (JSON.stringify(this.manualModifiedRouteItems[stopPointId]) !== JSON.stringify(previousModifiedRouteData[stopPointId])) {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[stopPointId] });
            const routeIndexes = [];
            this.manualModifiedRouteItems[stopPointId].forEach(routeSettingId => {
              routeIndexes.push(this.routeSettingsById[routeSettingId].routeSetting.route_index);
            });
            if (!this.stopPointModifications[stopPointId]) {
              this.createEmptyModificationsForStopPoint(stopPointId);
            }
            this.stopPointModifications[stopPointId].routeIndex = routeIndexes;
          }
        });
      }
      this.manualModifiedSequenceItems = manualModifiedRouteData['items']['sequence'];
      this.manualModifiedDirectSequenceItems = manualModifiedRouteData['items']['directSequence'];
      if (JSON.stringify(this.manualModifiedSequenceItems) !== JSON.stringify(previousModifiedSequenceData)) {
        Object.keys(this.manualModifiedSequenceItems).forEach(stopPointId => {
          if (JSON.stringify(this.manualModifiedSequenceItems[stopPointId]) !== JSON.stringify(previousModifiedSequenceData[stopPointId])) {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[stopPointId] });
            if (!this.stopPointModifications[stopPointId]) {
              this.createEmptyModificationsForStopPoint(stopPointId);
            }
            this.stopPointModifications[stopPointId].sequence.after = this.manualModifiedSequenceItems[stopPointId];
            this.manualModifiedSequenceItems[stopPointId].forEach(afterStopPointId => {
              if (!this.stopPointModifications[afterStopPointId]) {
                this.createEmptyModificationsForStopPoint(afterStopPointId);
              }
              this.stopPointModifications[afterStopPointId].sequence.before.push(Number(stopPointId));
            });
          }
        });

      }
      if (JSON.stringify(this.manualModifiedDirectSequenceItems) !== JSON.stringify(previousModifiedDirectSequenceData)) {
        Object.keys(this.manualModifiedDirectSequenceItems).forEach(stopPointId => {
          if (JSON.stringify(this.manualModifiedDirectSequenceItems[stopPointId]) !== JSON.stringify(previousModifiedDirectSequenceData[stopPointId])) {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[stopPointId] });
            if (!this.stopPointModifications[stopPointId]) {
              this.createEmptyModificationsForStopPoint(stopPointId);
            }
            this.stopPointModifications[stopPointId].directSequence.after = this.manualModifiedDirectSequenceItems[stopPointId];
            this.manualModifiedDirectSequenceItems[stopPointId].forEach(afterStopPointId => {
              if (!this.stopPointModifications[afterStopPointId]) {
                this.createEmptyModificationsForStopPoint(afterStopPointId);
              }
              this.stopPointModifications[afterStopPointId].directSequence.before.push(Number(stopPointId));
            });
          }
        });

      }
      this.viewProjectProblemService.displayStopPointsToMap(modifiedStopPointsToUpdate);
    });
  }

  removeStopPointsModifications(ids, data) {
    this.manualModifiedRouteItems = data['items']['route'];
    this.manualModifiedSequenceItems = data['items']['sequence'];
    this.manualModifiedDirectSequenceItems = data['items']['directSequence'];
    const modifiedStopPointsToUpdate = [];
    ids.forEach(id => {
      if (this.stopPointModifications[id]) {
        if (this.stopPointModifications[id].sequence) {
          this.stopPointModifications[id].sequence.after.forEach(afterId => {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[afterId] });
            if (this.stopPointModifications[afterId]) {
              const index = this.stopPointModifications[afterId].sequence.before.indexOf(afterId);
              if (index) {
                this.stopPointModifications[afterId].sequence.before.splice(index, 1);
              }
            } else {
              console.error('stopPointModifications for id ' + afterId + ' not found');
              console.error(this.stopPointModifications);
            }
          });
          this.stopPointModifications[id].sequence.before.forEach(beforeId => {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[beforeId] });
            const index = this.stopPointModifications[beforeId].sequence.after.indexOf(beforeId);
            if (index) {
              this.stopPointModifications[beforeId].sequence.after.splice(index, 1);
            }
          });
        }
        if (this.stopPointModifications[id].directSequence) {
          this.stopPointModifications[id].directSequence.after.forEach(afterId => {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[afterId] });
            if (this.stopPointModifications[afterId]) {
              const index = this.stopPointModifications[afterId].directSequence.before.indexOf(afterId);
              if (index) {
                this.stopPointModifications[afterId].directSequence.before.splice(index, 1);
              }
            } else {
              console.error('stopPointModifications for id ' + afterId + ' not found');
              console.error(this.stopPointModifications);
            }
          });
          this.stopPointModifications[id].directSequence.before.forEach(beforeId => {
            modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[beforeId] });
            const index = this.stopPointModifications[beforeId].directSequence.after.indexOf(beforeId);
            if (index) {
              this.stopPointModifications[beforeId].directSequence.after.splice(index, 1);
            }
          });
        }
        delete this.stopPointModifications[id];
      }
      modifiedStopPointsToUpdate.push({ stopPoint: this.stopPoints[id] });
    });
    this.viewProjectProblemService.displayStopPointsToMap(modifiedStopPointsToUpdate);

  }

  checkForModifications() {
    this.loadModifications().pipe(take(1)).subscribe(manualModifiedRouteData => {
      if (manualModifiedRouteData) {
        this.manualModifiedRouteItems = manualModifiedRouteData['items']['route'];
        Object.keys(this.manualModifiedRouteItems).forEach(stopPointId => {
          const routeIndexes = [];
          this.manualModifiedRouteItems[stopPointId].forEach(routeSettingId => {
            routeIndexes.push(this.routeSettingsById[routeSettingId].routeSetting.route_index);
          });
          this.createEmptyModificationsForStopPoint(stopPointId);
          this.stopPointModifications[stopPointId]['routeIndex'] = routeIndexes;
        });
        this.manualModifiedSequenceItems = manualModifiedRouteData['items']['sequence'];
        this.manualModifiedDirectSequenceItems = manualModifiedRouteData['items']['directSequence'];
        Object.keys(this.manualModifiedSequenceItems).forEach(stopPointId => {
          if (!this.stopPointModifications[stopPointId]) {
            this.createEmptyModificationsForStopPoint(stopPointId);
          }
          this.stopPointModifications[stopPointId].sequence.after = this.manualModifiedSequenceItems[stopPointId];
          this.manualModifiedSequenceItems[stopPointId].forEach(afterStopPointId => {
            if (!this.stopPointModifications[afterStopPointId]) {
              this.createEmptyModificationsForStopPoint(afterStopPointId);
            }
            this.stopPointModifications[afterStopPointId].sequence.before.push(Number(stopPointId));
          });
        });
        Object.keys(this.manualModifiedDirectSequenceItems).forEach(stopPointId => {
          if (!this.stopPointModifications[stopPointId]) {
            this.createEmptyModificationsForStopPoint(stopPointId);
          }
          this.stopPointModifications[stopPointId].directSequence.after = this.manualModifiedDirectSequenceItems[stopPointId];
          this.manualModifiedDirectSequenceItems[stopPointId].forEach(afterStopPointId => {
            if (!this.stopPointModifications[afterStopPointId]) {
              this.createEmptyModificationsForStopPoint(afterStopPointId);
            }
            this.stopPointModifications[afterStopPointId].directSequence.before.push(Number(stopPointId));
          });
        });
        this.loadModificationsDone = true;
      } else {
        this.loadModificationsDone = true;
      }
      // console.log(moment.duration(moment().diff(this.timer)).asSeconds() + 's elapsed');
    });
  }

  createEmptyModificationsForStopPoint(stopPointId) {
    this.stopPointModifications[stopPointId] = {
      routeIndex: [],
      sequence: {
        before: [],
        after: []
      },
      directSequence: {
        before: [],
        after: []
      }
    };
  }

  checkForCollaboratorSolution() {
    console.log("checkForCollaboratorSolution");
    // wait for stop points to be finish loading before setting solution data
    let waitForStopPointsInterval = setInterval(() => {
      console.log("checkForCollaboratorSolution setInterval");
      if (this.loadStopPointsDone) {
        clearInterval(waitForStopPointsInterval);
        this.http.get(this.collaboratorSolutionUrl).pipe(take(1)).subscribe(response => {
          if (response) {
            if (response['items']) {
              // update solution data only if route is dispatched OR if it isn't dispatched, but the time difference
              // of (time - modification) is >= 5 min
              const routesCount = Object.keys(response['items']['projectProblemRouteSettingIdToRouteInfo']).length;
              let dispatchedRoutesCount = 0;
              Object.keys(response['items']['projectProblemRouteSettingIdToRouteInfo']).forEach(routeId => {
                const modificationTime = response['items']['projectProblemRouteSettingIdToRouteInfo'][routeId]['modification_datetime'];
                const utcDatetime = moment.utc(modificationTime, "YYYY-MM-DD HH:mm:ss");
                const minsDiff = moment().diff(utcDatetime, 'minutes');
                if ((response['items']['projectProblemRouteSettingIdToRouteInfo'][routeId]['entity_status'] == this.globals.projectProblemEntityStatusConstants['DISPATCHED']
                  || response['items']['projectProblemRouteSettingIdToRouteInfo'][routeId]['entity_status'] == this.globals.projectProblemEntityStatusConstants['RE_DISPATCH'])
                  || minsDiff >= 5) {
                  dispatchedRoutesCount += 1;
                }
              });

              // if all routes are dispatched -> refresh solution
              if (routesCount == dispatchedRoutesCount) {
                if (response['items']['sequencePerRouteSettingId']) {
                  this.solutionData = {};
                  this.stopPointSolutionData = {};
                  this.sequenceArrayPerRouteSettingId = {};

                  console.warn('RELOADING SOLUTION...');
                  this.solutionData = response['items'];
                  this.setSolutionData();
                }
              }
            }
          }
          this.loadSolutionDone = true;

          // check if new drivers are added to solution and overview
          this.loadCollaboratorDrivers().pipe(take(1)).subscribe(response => {
            if (response['items']) {
              this.setDrivers(response['items']);
            }
            this.loadDriversDone = true;
          });
        });
      }
    }, 500);
  }

  checkForSolution() {
    const optimizationState = this.projectProblemData['optimization_state'];
    const isModified = this.projectProblemData['modified'];
    const entityStatus = this.projectProblemData['entity_status'];
    const optimizationStates = this.globals.projectProblemOptimizationStateConstants;
    this.solutionData = {};
    this.stopPointSolutionData = {};
    this.sequenceArrayPerRouteSettingId = {};
    if (
      optimizationState === optimizationStates['OPTIMIZED']
      || entityStatus !== this.globals.projectProblemEntityStatusConstants['UN_DISPATCHED']
      || isModified
    ) {
      const solutionUrl = this.solutionUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      // wait for stop points to be finish loading before setting solution data
      let waitForStopPointsInterval = setInterval(() => {
        if (this.loadStopPointsDone) {
          this.http.get(solutionUrl).pipe(take(1)).subscribe(response => {
            if (response) {
              this.solutionData = response['items'];
              this.setSolutionData();
            }
            this.loadSolutionDone = true;
            this.loadDrivers().pipe(take(1)).subscribe(response => {
              if (response['items']) {
                this.setDrivers(response['items']);
              }
              this.loadDriversDone = true;
            });
          });
          clearInterval(waitForStopPointsInterval);
        }
      }, 500);

    } else {
      this.loadSolutionDone = true;
      this.loadDrivers().pipe(take(1)).subscribe(response => {
        if (response['items']) {
          this.setDrivers(response['items']);
        }
        this.loadDriversDone = true;
      });
    }
  }

  setSolutionData() {
    // update all routes if no routeId is given
    // if (!routeId) {
    const sequencePerRouteSettingId = this.solutionData['sequencePerRouteSettingId'];
    this.stopPointSolutionData = {};

    this.sequenceArrayPerRouteSettingId = {};
    Object.keys(sequencePerRouteSettingId).forEach(routeSettingId => {
      const sequenceObject = sequencePerRouteSettingId[routeSettingId];

      // is pickup/delivery
      let orderIndex = -1;
      let previousDepotId;
      let stopPointOrder = {};
      Object.keys(sequenceObject).forEach(stopPointId => {
        stopPointOrder[stopPointId] = sequenceObject[stopPointId];
      });

      stopPointOrder = Object.keys(sequenceObject).sort(function (a, b) { return sequenceObject[a] - sequenceObject[b] })

      Object.values(stopPointOrder).forEach(spId => {
        const stopPointId = Number(spId);

        // is pickup
        if (this.stopPoints[stopPointId]) {
          if (this.stopPoints[stopPointId].related_partner_company_depot_id) {
            // is previous pickup/delivery
            if (this.stopPoints[stopPointId].related_partner_company_depot_id == previousDepotId) {
              this.stopPointSolutionData[stopPointId] = {
                sequence: orderIndex,
                routeSettingId: routeSettingId,
                routeIndex: this.getRouteIndexByRouteSettingId(routeSettingId),
              };
            }
            // is new pickup/delivery
            else {
              orderIndex++;
              previousDepotId = this.stopPoints[stopPointId].related_partner_company_depot_id; // store depot
              this.stopPointSolutionData[stopPointId] = {
                sequence: orderIndex,
                routeSettingId: routeSettingId,
                routeIndex: this.getRouteIndexByRouteSettingId(routeSettingId),
              };
            }
          }

          // isn't pickup/delivery
          else {
            previousDepotId = null;
            orderIndex++;
            this.stopPointSolutionData[stopPointId] = {
              sequence: orderIndex,
              routeSettingId: routeSettingId,
              routeIndex: this.getRouteIndexByRouteSettingId(routeSettingId),
            };
          }
        } else {
          orderIndex++;
        }

      });
      // get an array of SPIDs in sequence for every route setting id
      this.sequenceArrayPerRouteSettingId[routeSettingId] = Object.keys(sequenceObject).map(Number).sort(
        function (a, b) {
          return sequenceObject[a] - sequenceObject[b];
        }
      );
    });
    // }
  }

  // ANCHOR
  addClusterData(level, center, clusterId, stopPointId) {
    let routeIndex = null;
    // check if the cluster level already exists
    if (this.stopPointClustersByLevelByClusterId[level]) {
      // check if the cluster already exists
      if (this.stopPointClustersByLevelByClusterId[level][clusterId]) {
        // add the SP to the cluster for this level
        this.stopPointClustersByLevelByClusterId[level][clusterId]['stopPointIds'].push(stopPointId);
      } else {
        if (this.stopPoints[stopPointId]) {
          if (this.stopPoints[stopPointId]['solution']) {
            routeIndex = this.stopPoints[stopPointId]['solution']['routeIndex'];
          }
        }
        // create a cluster for this level with the SP in
        this.stopPointClustersByLevelByClusterId[level][clusterId] = {
          stopPointIds: [stopPointId],
          center: center,
          routeIndex: routeIndex,
        };
      }
    } else {
      if (this.stopPoints[stopPointId]) {
        if (this.stopPoints[stopPointId]['solution']) {
          routeIndex = this.stopPoints[stopPointId]['solution']['routeIndex'];
        }
      }
      // create a level and a cluster for this level with the SP in
      this.stopPointClustersByLevelByClusterId[level] = {};
      this.stopPointClustersByLevelByClusterId[level][clusterId] = {
        stopPointIds: [stopPointId],
        center: center,
        routeIndex: routeIndex,
      };
    }
  }

  removeClusterFromStopPoints(id) {
    delete this.stopPoints[id]['zoom_levels'];
  }

  removeClustersWithOneStopPoint() {
    Object.keys(this.stopPointClustersByLevelByClusterId).forEach(zoomLevel => {
      Object.keys(this.stopPointClustersByLevelByClusterId[zoomLevel]).forEach(clusterId => {
        if (this.stopPointClustersByLevelByClusterId[zoomLevel][clusterId]['stopPointIds'].length === 1) {
          this.removeClusterFromStopPoints(this.stopPointClustersByLevelByClusterId[zoomLevel][clusterId]['stopPointIds'][0]);
          delete (this.stopPointClustersByLevelByClusterId[zoomLevel][clusterId]);
        }
      });
    });
  }

  changePartnerMergedStops(partnerId, stopPointIds) {
    this.mergedStops[partnerId] = stopPointIds;
  }

  getRouteIndexByRouteSettingId(routeSettingId) {
    return this.routeSettingsById[routeSettingId]['routeSetting']['route_index'];
  }

  setStopPoints(stopPoints) {
    this.stopPoints = {};
    this.volumesArray = [];
    this.routeSettingIdsWithReload = [];
    this.routeSettingIdsToDrivers = {};
    this.stopPointClustersByLevelByClusterId = {};
    stopPoints.forEach(stopPoint => {
      if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
      this.stopPoints[stopPoint.id] = stopPoint;

      if (this.stopPointUtils.isDelivery(stopPoint.service_type)) {
        this.volumesArray.push(Number(stopPoint.load));
      }
      if (this.stopPointUtils.isPickup(stopPoint.service_type)) {
        this.volumesArray.push(Number(stopPoint.load));
      }

      if (stopPoint.mergeData) {
        if (stopPoint.mergeData.mergedStopPointId) {
          const partnerId = stopPoint.mergeData.mergedStopPointId;
          if (this.mergedStops[partnerId]) {
            this.mergedStops[partnerId].push(stopPoint.id);
          } else {
            this.mergedStops[partnerId] = [stopPoint.id];
          }
        }
      }
      if (stopPoint.solution) {
        // const routeIndex = stopPoint.solution.routeIndex;
        const routeSettingId = stopPoint.solution.routeSettingId;
        if (!this.routeSettingIdsToDrivers[routeSettingId] && stopPoint.solution.driver) {
          this.routeSettingIdsToDrivers[routeSettingId] = {
            id: stopPoint.solution.driver.id,
            name: stopPoint.solution.driver.userProfile.name,
          };
        }
        // TODO hardcoded constant
        if (stopPoint.related_to === 6) {
          this.routeSettingIdsWithReload.push(stopPoint.solution.routeSettingId);
        }
      }

      // minZoomLevel is the minimum zoom level that the sp is visible
      // 20 is the full zoom in and 0 is full zoom out
      // so, in any level less than the max, the stop will be visible
      // if we have maxZoomLevel=9, the stop will be visible in zoom levels 8 to 0 (far zoom out)
      if (stopPoint.zoom_levels) {
        stopPoint.zoom_levels.forEach(zoomLevel => {
          if (zoomLevel) {
            // save the center
            this.addClusterData(zoomLevel.level, zoomLevel.center, zoomLevel.id, stopPoint.id);
          }
        });
      }
    });

    this.removeClustersWithOneStopPoint();
    this.calculateStopPointIndexInArray();
  }

  calculateStopPointIndexInArray() {
    this.stopPointIndexInArray = {};
    this.stopPointsArray.forEach((stopPoint, index) => {
      if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
      this.stopPointIndexInArray[stopPoint.id] = index;
    });
  }

  setDrivers(drivers) {
    this.driversArray = drivers;
    this.routesWithCloseOvertime = [];
    this.vehiclesWithCloseCapacity = [];
    this.driversArray.forEach(driver => {
      this.drivers[driver.driver.id] = driver;
      this.calculateRoutesInfoForDriver(driver);
    });
  }

  calculateRoutesInfoForDriver(driver) {
    const workDuration = driver.driver.daily_working_hours;
    const workDurationHours = moment.duration(workDuration).asHours();
    const workDurationHoursFloor = Math.floor(workDurationHours);
    const workDurationHoursMinutes = moment.duration((workDurationHours - workDurationHoursFloor), 'hours').asMinutes();
    let workingHours = workDurationHoursFloor + 'h ';
    if (Math.round(workDurationHoursMinutes)) {
      workingHours += Math.round(workDurationHoursMinutes) + 'm';
    }

    const routeSettingId = this.driversToRouteSettingIds[driver.driver.id];
    if (routeSettingId) {
      const routeIndex = this.getRouteIndexByRouteSettingId(routeSettingId);
      const currentRouteSetting = this.routeSettingsById[routeSettingId];

      const load = currentRouteSetting.routeSetting.load;
      const capacity = currentRouteSetting.vehicle.maximum_cargo_capacity;
      const departureDatetimeDuration = currentRouteSetting.routeSetting.start_offset_from_departure;
      const departureDatetimeDurationMinutes = moment.duration(departureDatetimeDuration).asMinutes();

      if (this.projectProblemData) {
        if (this.projectProblemData['departure_datetime']) {
          const departureDatetimeMoment = moment(this.projectProblemData['departure_datetime']).add(departureDatetimeDurationMinutes, 'minutes');

          const lastStopPointId = currentRouteSetting.routeSetting.finish_stop_point_id;
          if (this.solutionData['solutionInfoByRouteSettingIdByStopPointId']) {
            if (this.solutionData['solutionInfoByRouteSettingIdByStopPointId'][routeSettingId][lastStopPointId]) {
              const lastStopPointData = this.solutionData['solutionInfoByRouteSettingIdByStopPointId'][routeSettingId][lastStopPointId];
              const lastDatetime = lastStopPointData.latestEstimatedArrivalDatetime;
              const routeDurationHours = moment.duration((moment(lastDatetime).utc()).diff(departureDatetimeMoment.utc())).asHours();
              const routeDurationHoursFloor = Math.floor(routeDurationHours);
              const routeDurationMinutes = moment.duration((routeDurationHours - routeDurationHoursFloor), 'hours').asMinutes();
              let routeDuration = routeDurationHoursFloor + 'h ';
              if (Math.round(routeDurationMinutes)) {
                routeDuration += Math.round(routeDurationMinutes) + 'm';
              }
              if (workDurationHours - routeDurationHours <= .3) {
                this.routesWithCloseOvertime.push({
                  name: driver.userProfile.name,
                  info: routeDuration + '/' + workingHours,
                  colour: this.colourService.colourCalculator(routeIndex)
                });
              }
            }
          }

        }
      }

      if (load / capacity > .95) {
        this.vehiclesWithCloseCapacity.push({
          name: driver.userProfile.name,
          info: load + '/' + capacity,
          colour: this.colourService.colourCalculator(routeIndex)
        });
      }
    }
  }

  setDepots(depots) {
    this.depots = {};
    depots.forEach(depot => {
      this.depots[depot.companyDepot.id] = depot.companyDepot;
    });
  }

  setRouteSettings(routeSettings) {
    this.routeSettings = {};
    this.routeSettingsById = {};
    this.driversToRouteSettingIds = {};
    this.vehicleData = {};
    routeSettings.forEach(routeSetting => {
      this.routeSettingsById[routeSetting.routeSetting.id] = routeSetting;
      this.routeSettings[routeSetting.routeSetting.route_index] = routeSetting;
      this.vehicleData[routeSetting.vehicle.id] = {
        driverId: routeSetting.driver.id,
        routeIndex: routeSetting.routeSetting.route_index
      };
      this.driversToRouteSettingIds[routeSetting.driver.id] = routeSetting.routeSetting.id;
    });
  }

  // calculate the percentage of stops that are complete or cancelled in a route
  // store them in routeIndexesPercentage object
  calculateDriversStopPointsPercentage() {
    const dataSolutionIntervalId = setInterval(() => {
      this.loadStopPointsDone = true;
      if (this.loadSolutionDone) {
        clearInterval(dataSolutionIntervalId);
        this.routeIndexesPercentage = {};
        if (this.sequenceArrayPerRouteSettingId) {
          // let routeIndex = 0;
          let allDone = true, doneStopsCount = 0, totalStopsCount = 0;
          Object.keys(this.sequenceArrayPerRouteSettingId).forEach(routeSettingId => {
            const routeIndexSequence = this.sequenceArrayPerRouteSettingId[routeSettingId];
            if (routeIndexSequence.length) {
              const routeIndex = this.routeSettingsById[routeSettingId]['routeSetting']['route_index'];
              doneStopsCount = 0;
              totalStopsCount = 0;
              routeIndexSequence.forEach(stopPointId => {
                if (this.stopPoints[stopPointId]) {
                  if (this.stopPoints[stopPointId]['related_to'] === this.globals.stopPointRelatedToConstants['SELF']) {
                    totalStopsCount++;
                    const fulfillmentStatus = this.stopPoints[stopPointId]['fulfillment_status'];
                    if (
                      fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] ||
                      fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['CANCELED']
                    ) {
                      doneStopsCount++;
                    } else {
                      allDone = false;
                    }
                  }
                }
              });
              this.stopPointsProgress[this.routeSettingsById[routeSettingId].driver.id] = {
                doneStopsCount: doneStopsCount,
                totalStopsCount: totalStopsCount
              };
              if (totalStopsCount) {
                if (this.routeSettingsById[routeSettingId]['routeSetting']['state'] == this.globals.projectProblemRouteStates['IN_PROGRESS']) {
                  this.routeIndexesPercentage[routeIndex] = 0;
                }
                if (doneStopsCount) {
                  const routePercentage = Math.round((doneStopsCount / totalStopsCount) * 100);
                  this.routeIndexesPercentage[routeIndex] = routePercentage;
                }
              }

            }
          });
        }
      }
    }, 200);
  }

  areAllStopsDone() {
    let allDone = true;
    Object.keys(this.routeIndexesPercentage).forEach(routeIndex => {
      if (this.routeIndexesPercentage[routeIndex] !== 100) {
        allDone = false;
      }
    });
    return allDone;
  }

  loadProjectData() {
    const projectUrl = this.projectUrl.replace('PROJECT_ID', this.projectId);
    return this.http.get(projectUrl);
  }

  loadProjectProblemData() {
    const projectProblemUrl = this.projectProblemUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(projectProblemUrl);
  }

  loadCollaboratorDrivers() {
    return this.http.get(this.collaboratorDriversUrl);
  }

  loadDrivers() {
    let url = this.driversUrl;
    if (this.projectProblemId) {
      url += '?projectProblemId=' + this.projectProblemId;
    }
    return this.http.get(url);
  }

  loadRouteSettings() {
    const routeSettingsUrl = this.routeSettingsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(routeSettingsUrl);
  }

  loadCollaboratorRouteSettings() {
    return this.http.get(this.collaboratorRouteSettingsUrl);
  }

  loadStopPoints(pageSize = 0, page = 1) {
    let params = '';
    if (pageSize) {
      params = '?pageSize=' + pageSize;
      params += '&page=' + page;
    }
    const allStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(allStopPointsUrl + params);
  }

  loadSmartPoints() {
    let params = `?projectProblemId=${this.projectProblemId}`;
    const smartPointsUrl = this.globals.isSmartPointsEnabled ? this.smartPointsUrl + params : this.pudoPointsUrl + params + '&activePudoPointsWStopPoints=true';
    return this.http.get(smartPointsUrl);
  }

  loadDriverStopPoints(driverId) {
    let params = '?filter-driver-id=' + driverId;
    const allStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(allStopPointsUrl + params);
  }

  loadCollaboratorStopPoints() {
    let params = '?pageSize=-1&page=0';
    return this.http.get(this.collaboratorStopPointsUrl + params);
  }

  loadStopPointsAfterOptimize(pageSize, page) {
    let params = '';
    if (pageSize) {
      params = '&pageSize=' + pageSize;
      params += '&page=' + page;
    } else {
      params = '&pageSize=-1';
    }
    const allStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(allStopPointsUrl + '?responseDto=stopPointAfterOptimizeDto' + params);
  }

  loadSpecificStopPointsFulfillmentStatus(stopPointIds) {
    let stopPointIdsString = '';
    stopPointIds.forEach(id => {
      if (stopPointIdsString === '') {
        stopPointIdsString = id;
      } else {
        stopPointIdsString += ',' + id;
      }
    });
    let allStopPointsUrl = this.collaboratorStopPointsUrl;
    if (this.projectProblemId) {
      allStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    }
    return this.http.get(allStopPointsUrl + '?ids=' + stopPointIdsString + '&responseDto=stopPointOnlyFulfillmentDto');
  }

  loadSpecificStopPoints(stopPointIds) {
    let stopPointIdsString = '';
    const stopPointsCount = stopPointIds.length;
    stopPointIds.forEach(id => {
      if (stopPointIdsString === '') {
        stopPointIdsString = id;
      } else {
        stopPointIdsString += ',' + id;
      }
    });
    const allStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const queryParams =
      '?ids=' + stopPointIdsString +
      '&pageSize=' + stopPointsCount +
      '&responseDto=stopPointFullDto';
    return this.http.get(allStopPointsUrl + queryParams);
  }

  loadDepots() {
    return this.http.get('api/v1/company/depots');
  }

  loadVehicles() {
    return this.http.get('api/v1/vehicles');
  }

  loadModifications() {
    const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(manualModifiedRouteUrl);
  }

  loadLoadedStops() {
    const loadStopsUrl = this.loadStopsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    return this.http.get(loadStopsUrl);
  }

}
