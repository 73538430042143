import { Injector, Type } from '@angular/core';

export type LmToastPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'top-center' | 'bottom-center' | 'center';
export type LmSeverity = 'success' | 'info' | 'warn' | 'error';

export class LmNotificationContext<T> {
  constructor(public id: string) {}

  close: (result?: unknown) => void;
  data: T;
}
export interface LmNotificationInfo {
  contentType: Type<any>;
  id: string;
  life: number;
  sticky: boolean;
  closable: boolean;
  severity: LmSeverity;
  position: LmToastPosition;
  injector: Injector;
  data?: unknown;
}
