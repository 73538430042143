import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { of } from 'rxjs';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-portal-issues-grid',
  templateUrl: './portal-issues-grid.component.html',
  styleUrls: ['./portal-issues-grid.component.scss']
})
export class PortalIssuesGridComponent implements OnInit {

  listen = [];

  issuesUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/issues';

  columnDefs;
  gridApi;
  domLayout = 'autoHeight';
  gridColumnApi;

  issuesObject = {};
  issuesDataArray = [];
  rowData;

  projectProblemId;
  stopPointIds = [];

  nameTitle;
  reasonTitle;

  isLastPage = false;
  noNameLabel = '';

  addressChanged1Msg;
  addressChanged2Msg;
  timeChanged1Msg;
  timeChanged2Msg;
  timeChangedTimeWindowMsg;
  timeChangedDateMsg;
  cancel1Msg;
  cancel2Msg;
  cancelNotAccept1Msg;
  cancelNotAccept2Msg;
  deliveryMsg;
  pickupMsg;
  newMsg;
  tomorrowMsg;
  dayAfterTomorrowMsg;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    public gridsService: GridsService,
    private addressService: AddressService,
    public projectProblemDataService: ProjectProblemDataService,
  ) { }

  reasonRenderer(params) {
    let columnObject = '';
    const issue = params.getValue();
    if (issue) {
      let iconElement = '', text = '';
      const serviceTypeLabel = issue.serviceType === this.globals.stopPointServiceTypeConstants['DELIVERY'] ? this.deliveryMsg : this.pickupMsg;
      if (issue.type === this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_ADDRESS_CHANGED']) {
        const address = this.addressService.getAddressLabel(issue.address);
        iconElement = '<i class="fas fa-user-times"></i>';
        text = '<span class="bold-letters">' + this.addressChanged1Msg + '</span><span>' + this.addressChanged2Msg.replace('NEW_ADDRESS', address) + '</span>';
      } else if (issue.type === this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_TIME_WINDOW_CHANGED']) {
        let tomorrow = moment().add(1, 'days').format('DD/MM/YYYY');
        let dayAfterTomorrow = moment().add(2, 'days').format('DD/MM/YYYY');
        let date = '', timeStart = '', timeEnd = '', label = '';
        if (issue.agreedShipping) {
          if (issue.agreedShipping[0]) {
            let dateTimeLabel = '';
            if (issue.agreedShipping[0].date) {
              date = moment(issue.agreedShipping[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY');
              if (date === tomorrow) {
                date += ' (' + this.tomorrowMsg + ')';
              } else if (date === dayAfterTomorrow) {
                date += ' (' + this.dayAfterTomorrowMsg + ')';
              }
              dateTimeLabel += this.timeChangedDateMsg.replace('DATE', date);
            }
            if (issue.agreedShipping[0].start && issue.agreedShipping[0].end) {
              timeStart = moment(issue.agreedShipping[0].start, 'HH:mm:SS').format('HH:mm');
              timeEnd = moment(issue.agreedShipping[0].end, 'HH:mm:SS').format('HH:mm');
              dateTimeLabel += this.timeChangedTimeWindowMsg.replace('TW_START', timeStart).replace('TW_END', timeEnd);
            }
            label += this.timeChanged2Msg.replace('DATE_TIME', dateTimeLabel);
          }
        }
        iconElement = '<i class="far fa-calendar"></i>';
        text = '<span class="bold-letters">' + this.timeChanged1Msg.replace('SERVICE_TYPE', serviceTypeLabel) + '</span><span>' + label + '</span>';
      } else if (issue.type === this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_GENERIC_CANCEL']) {
        if (issue.reasonConstant === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['DID_NOT_ACCEPT_IT']) {
          iconElement = '<i class="fas fa-user-times"></i>';
          text = '<span class="bold-letters">' + this.cancelNotAccept1Msg + '</span><span>' + this.cancelNotAccept2Msg + '</span>';
        } else {
          iconElement = '<i class="fas fa-times"></i>';
          text = '<span class="bold-letters">' + this.cancel1Msg.replace('SERVICE_TYPE', serviceTypeLabel) + '</span><span>' + this.cancel2Msg + '</span>';
        }
      }

      columnObject += '<div class="display-flex align-center standard-width">';
      columnObject += '<div class="grid-inline-icon">';
      columnObject += iconElement;
      columnObject += '</div>';
      columnObject += '<div class="double-line-grid">';
      columnObject += text;
      columnObject += '</div>';
      if (issue.new) {
        columnObject += '<div class="grid-new-line-tag">' + this.newMsg + '</div>';
      }
      columnObject += '</div>';
    }
    return columnObject;
  }

  setDepotsGridData(issues) {
    const self = this;

    const portalIssueTypes = [
      this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_ADDRESS_CHANGED'],
      this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_GENERIC_CANCEL'],
      this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_TIME_WINDOW_CHANGED'],
    ];

    self.issuesObject = issues;
    let gridObject = {};
    self.issuesDataArray = [];
    self.stopPointIds = [];
    Object.keys(issues).forEach(stopPointId => {
      const stopPointData = this.projectProblemDataService.stopPoints[stopPointId];
      if (stopPointData) {
        let name = stopPointData.contact_name;
        if (name === '_NO_NAME') {
          name = self.noNameLabel;
        }
        let reasonConstant = null;
        if (stopPointData.fulfillment_events) {
          stopPointData.fulfillment_events.forEach(event => {
            if (event.reason > 799 && event.reason < 900) {
              reasonConstant = event.reason;
            }
          });
        }
        issues[stopPointId].forEach(issue => {
          if (portalIssueTypes.includes(issue.type)) {
            if (!self.stopPointIds.includes(stopPointId)) {
              self.stopPointIds.push(stopPointId);
            }
            gridObject = {
              id: stopPointId,
              issueId: issue['id'],
              name: {
                name: name,
                phone: stopPointData.telephone
              },
              reason: {
                type: issue['type'],
                reasonConstant: reasonConstant,
                agreedShipping: stopPointData.agreed_shipping,
                address: stopPointData.address,
                serviceType: stopPointData.service_type,
                new: issue.state === this.globals.projectProblemIssueStates['ISSUE_STATE_NEW'] ? true : false,
              },
              data: issue
            };
            self.issuesDataArray.push(gridObject);
          }
        });
      }
    });
    this.sortByNew();
    self.rowData = of(self.issuesDataArray);
  }

  // rows with new state go first
  sortByNew() {
    function compare(a, b) {
      // true values first
      return (a.reason.new === b.reason.new) ? 0 : a.reason.new ? -1 : 1;
    }
    this.issuesDataArray.sort(compare);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  // update the row in the grid
  updateIssueRow(data) {
    const issueId = data.id;
    const stopPointId = data.stop_point_id;
    const previousIssueRows = this.issuesObject[stopPointId];
    if (previousIssueRows) {
      let newIssueIndex = null;
      previousIssueRows.forEach((issue, index) => {
        if (issueId === issue.id) {
          newIssueIndex = index;
        }
      });
      this.issuesObject[stopPointId][newIssueIndex] = data;
      this.setDepotsGridData(this.issuesObject);
    }
  }

  rowClicked(event) {
    const id = event.data.id;
    const issueId = event.data.issueId;
    this.viewProjectProblemService.focusOnMarker(id);
    if (event.data.reason.new) {
      let issuesUrl = this.issuesUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      const data = {};
      data[issueId] = {
        state: this.globals.projectProblemIssueStates['ISSUE_STATE_SEEN']
        // state: this.globals.projectProblemIssueStates['ISSUE_STATE_NEW']
      };
      this.http.put(issuesUrl, data).pipe(take(1)).subscribe(response => {
        const issues = response['items'];
        if (issues) {
          if (issues[0]) {
            this.updateIssueRow(issues[0]);
          }
        }
      });
    }
  }

  markEverythingAsSeen() {
    const data = {};
    this.issuesDataArray.forEach(issue => {
      if (issue.reason.new) {
        const issueId = issue.issueId;
        data[issueId] = {
          state: this.globals.projectProblemIssueStates['ISSUE_STATE_SEEN']
          // state: this.globals.projectProblemIssueStates['ISSUE_STATE_NEW']
        };
      }
    });
    if (Object.keys(data).length) {
      let issuesUrl = this.issuesUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      this.http.put(issuesUrl, data).subscribe(response => {
        const issues = response['items'];
        if (issues) {
          issues.forEach(issue => {
            this.updateIssueRow(issue);
          });
        }
      });
    }
  }

  onFirstDataRendered(params) { }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.CANCEL_REASON').subscribe((res: string) => { this.reasonTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.ADDRESS_CHANGED_1').subscribe((res: string) => { this.addressChanged1Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.ADDRESS_CHANGED_2').subscribe((res: string) => { this.addressChanged2Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.TIME_CHANGED_1').subscribe((res: string) => { this.timeChanged1Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.TIME_CHANGED_2').subscribe((res: string) => { this.timeChanged2Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.TIME_CHANGED_TIME_WINDOW').subscribe((res: string) => { this.timeChangedTimeWindowMsg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.TIME_CHANGED_DATE').subscribe((res: string) => { this.timeChangedDateMsg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.CANCEL_1').subscribe((res: string) => { this.cancel1Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.CANCEL_2').subscribe((res: string) => { this.cancel2Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.CANCEL_NOT_ACCEPT_1').subscribe((res: string) => { this.cancelNotAccept1Msg = res; }));
    this.listen.push(this.translate.get('PORTAL_ISSUES.CANCEL_NOT_ACCEPT_2').subscribe((res: string) => { this.cancelNotAccept2Msg = res; }));
    this.listen.push(this.translate.get('GENERIC.DELIVERY_LOWERCASE').subscribe((res: string) => { this.deliveryMsg = res; }));
    this.listen.push(this.translate.get('GENERIC.PICKUP_LOWERCASE').subscribe((res: string) => { this.pickupMsg = res; }));
    this.listen.push(this.translate.get('GENERIC.NEW').subscribe((res: string) => { this.newMsg = res; }));
    this.listen.push(this.translate.get('GENERIC.TOMORROW').subscribe((res: string) => { this.tomorrowMsg = res; }));
    this.listen.push(this.translate.get('GENERIC.DAY_AFTER_TOMORROW').subscribe((res: string) => { this.dayAfterTomorrowMsg = res; }));

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        cellRenderer: this.gridsService.nameRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.reasonTitle,
        field: 'reason',
        cellRenderer: this.reasonRenderer.bind(this),
        width: this.gridsService.widthCalculator(35)
      },
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
