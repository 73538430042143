import { Component, OnInit } from '@angular/core';
// import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Observable, Subject } from 'rxjs';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';

@Component({
  selector: 'app-driver-swap-select-editor',
  templateUrl: './driver-swap-select-editor.component.html',
})
export class DriverSwapSelectEditorComponent implements OnInit {

  currentDriverId;
  private params: any;

  selectedSimpleItem;
  drivers = [];

  constructor(private projectProblemDataService: ProjectProblemDataService) { }

  onChangeSelect() { }

  public agInit(params: any): void {
    this.selectedSimpleItem = {
      driver: { id: params.value.id },
      userProfile: {
        name: params.value.name,
        telephone: params.value.telephone
      }
    };
    this.drivers = this.projectProblemDataService.driversArray;
  }

  public getValue(): any {
    const driverData = this.selectedSimpleItem;
    if (driverData) {
      return {
        id: driverData.driver.id,
        name: driverData.userProfile.name,
        telephone: driverData.userProfile.telephone
      };
    } else {
      return null;
    }
  }

  ngOnInit() {
  }

}
