import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { CollaboratorViewBalanceComponent } from '@app/collaborator-view/collaborator-view-balance/collaborator-view-balance.component';
import { GenericService } from '@app/services/generic.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';

@Component({
  selector: 'app-new-shipments-grid',
  templateUrl: './new-shipments-grid.component.html',
  styleUrls: ['./../grids.scss', 'new-shipments-grid.component.scss']
})
export class NewShipmentsGridComponent implements OnInit, OnDestroy {
  // public modules: Module[] = [
  //   ServerSideRowModelModule,
  //   // MenuModule,
  //   // ColumnsToolPanelModule,
  // ];
  hasUnsentVouchersInGrid = false;

  collaboratorId;

  columnDefs;
  public modules: Module[] = [InfiniteRowModelModule];
  rowModelType = 'infinite';
  domLayout = 'normal';
  cacheBlockSize = 50;
  gridApi;
  gridColumnApi;
  rowClassRules;
  rowBuffer = 0;
  headerHeight = 30;
  paginationPageSize = 50;
  cacheOverflowSize = 2;
  components;

  months = [];
  vouchersNumberTotal = [];
  vouchersNumberComplete = [];
  vouchersNumberCancelled = [];

  pagesCount = 0;
  shipmentsDataArray = [];
  searchTextChanged = new Subject<string>();
  searchString: String = '';
  clicked;
  recipientTitle;
  addressTitle;
  completeTitle;
  createdTitle;
  timeTitle;
  volumeTitle;
  statusTitle;
  invoiceStatusTitle: any;
  partnerTitle: any;
  voucherStatusValue: string;
  payOnDeliveryTitle;
  paymentStatusTitle;
  currency;
  status: string;
  paidLabel: string;
  toPayLabel: string;
  toBeInvoicedLabel: string;
  showSortDropdown = false;
  selectedOrder = '';
  listenUpdateCustomerGrid;

  dataLoading = false;
  pageSize = 10;
  firstPage = true;
  lastPage = false;
  noNameLabel = '';
  returnLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';

  selectedStopPoint = null;
  selectAllCheckbox;

  listen = [];

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private modalService: ModalService,
    public globals: Globals,
    private modalGridService: ModalGridService,
    private chartService: ChartService,
    public gridsService: GridsService,
    private addressService: AddressService,
    private viewProjectProblemService: ViewProjectProblemService,
    private genericService: GenericService,
    private projectProblemDataService: ProjectProblemDataService,
  ) {

  }
  setShipmentsGridData(shipments) {
    this.shipmentsDataArray = []; // empty shipments array first
    let gridObject = {};

    if (shipments?.length) {
      shipments.forEach(shipment => {
        if (shipment.voucher) {
          if (shipment.voucher.acceptance_status === this.globals.voucherAcceptanceStatus['CREATED']) {
            this.hasUnsentVouchersInGrid = true;
          }
        }

        // Date
        let creationDateFormatted = '';
        if (shipment.creation_datetime) {
          creationDateFormatted = moment(shipment.creation_datetime).format('DD/MM/YYYY');
        }

        let completeDatetimeMoment = null;
        if (
          shipment.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
          shipment.solution
        ) {
          completeDatetimeMoment = moment(shipment.solution.atTime);
        }

        // Time (Time Windows)
        let timeWindowStart, timeWindowEnd;
        let timeWindowSecondStart, timeWindowSecondEnd;
        let timeWindowRange, timeWindowRangeMinutes;
        if (shipment.time_windows[0]) {
          timeWindowStart = moment(shipment.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
          timeWindowRange = shipment.time_windows[0].time_window_range;
          timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
          timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

          if (shipment.time_windows[1]) {
            timeWindowSecondStart = moment(shipment.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
            timeWindowRange = shipment.time_windows[1].time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
          }
        }

        // partner (hyperlink) (stores partner ID for hyperlink created in the renderer)
        let partnerObj = {
          id: null,
          name: '-'
        };
        // collaborator portal new shipments
        if (shipment.company_partner_id) {
          let partnerData;
          if (this.globals.collaboratorModeEnabled) {
            partnerData = this.globals.partners[shipment.company_partner_id];

            if (partnerData) {
              partnerObj = {
                id: partnerData.collaborator_id,
                name: partnerData.name
              };
            }
          }
          // courier portal new shipments (sub menu)
          else {
            this.globals.partnersArray.forEach(partner => {
              if (partner.companyCollaborator.partnerCompany) {
                if (partner.companyCollaborator.partnerCompany.id == shipment.company_partner_id) {
                  partnerData = partner;
                }
              }
            });

            if (partnerData) {
              partnerObj = {
                id: partnerData.companyCollaborator.collaborator.collaboratorData.id,
                name: partnerData.companyCollaborator.collaborator.collaboratorData.collaborator_name
              };
            }
          }
        }

        // No Name
        let name = shipment.contact_name;
        if (name.includes(this.noNameConstant)) {
          name = name.replace(this.noNameConstant, this.noNameLabel);
        }
        if (name.includes(this.returnConstant)) {
          name = name.replace(this.returnConstant, this.returnLabel);
        }

        gridObject = {
          id: shipment.id,
          details: {
            name: name,
            code: shipment.barcode ? shipment.barcode : '-',
            phone: shipment.telephone ? shipment.telephone : '-',
          },
          address: this.addressService.getAddressLabel(shipment.address),
          time: {
            date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
            twFirstStart: timeWindowStart,
            twFirstEnd: timeWindowEnd,
            twSecondStart: timeWindowSecondStart,
            twSecondEnd: timeWindowSecondEnd
          },
          partner: partnerObj,
          errorStatusConstant: shipment.error_status,
          objData: shipment
        };

        this.shipmentsDataArray.push(gridObject);
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.shipmentsDataArray.push(noData);
    }

    // create datasource
    const self = this;
    const dataSource = {
      rowCount: null,
      getRows: function (rowsParams) {
        setTimeout(function () {
          const rowsThisPage = self.shipmentsDataArray.slice(rowsParams.startRow, rowsParams.endRow);
          if (self.shipmentsDataArray.length) {
            let lastRow = -1;
            if (self.shipmentsDataArray.length <= rowsParams.endRow) {
              lastRow = self.shipmentsDataArray.length;
            }
            rowsParams.successCallback(rowsThisPage, lastRow);
          } else {
            const noData = { noDataText: 'No data' }
            rowsParams.successCallback([noData], 1);
          }
        }, 500);
      }
    };
    self.gridApi.setDatasource(dataSource);
  }

  redLettersClass(params) {
    if (params.data) {
      if (params.data.errorStatusConstant === this.globals.stopPointErrorStatusConstants['ERROR']) {
        return true;
      }
    }
    return false;
  }

  // RENDERERS
  // custom cell renderer for name, code & phone
  nameCodePhoneRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().name) {
        columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
        columnObject += '<div class="double-cell standard-width"><i class="fas fa-phone-alt grey-letters"></i> ' + params.getValue().phone + ' <i class="fas fa-qrcode grey-letters"></i> ' + params.getValue().code + '</div>';
      }
    }
    return columnObject;
  }

  // custom renderer for time
  timeRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().twFirstStart && !params.getValue().twSecondStart) {
        columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
        columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ')</div>'
      }
      else if (params.getValue().twFirstStart && params.getValue().twSecondStart) {
        columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
        columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ' ' + `${params.getValue().twSecondStart}-${params.getValue().twSecondEnd}` + ')</div>'
      }
      else {
        columnObject += '<div class="single-cell standard-width">' + params.getValue().date + '</div>'
      }
    }


    return columnObject;
  }

  createdDateRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().name) {
        columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
        columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
      }
      if (params.getValue().customerId) {
        columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
      } else {
        columnObject += '</div>';
      }
    }
    return columnObject;
  }

  // custom cell renderer for name, code & phone
  partnerRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().id) {
        columnObject += '<div class="single-cell"><a href="../partners/collaboratorPartnerView/' + params.getValue().id + '">' + params.getValue().name + '</a></div>';
      } else {
        columnObject += '<div class="single-cell">' + params.getValue().name + '</div>';
      }
    }

    return columnObject;
  }

  // dropdown (dots) renderer
  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {
        if (!self.selectedStopPoint) {
          dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
          dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          dropdown.style.display = 'block';
          self.selectedStopPoint = params.data;
        } else {
          self.selectedStopPoint = null;
          dropdown.style.display = 'none';
        }
      }, 20);
    });
    return dots;
  }

  // Grid Row Clicked
  rowClicked(event) {
    const target = event.event.target.closest('div');

    if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
      this.viewProjectProblemService.openStopModal(
        event.data.id,
        event.data.projectProblemId,
        event.data.projectProblemDepartureDatetime,
      );
    }
  }

  selectAll() {
    if (!this.selectAllCheckbox) {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(true);
      });
    } else {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(false);
      });
    }
  }

  // delete all selected stop points & then refresh the grid
  deleteStopPointOption() {
    let selectedStopPoints = [];

    // get selected, else get current clicked row stop point
    selectedStopPoints = this.gridApi.getSelectedRows();
    if (!selectedStopPoints.length) {
      selectedStopPoints.push(this.selectedStopPoint);
    }

    let idsToDelete = [];
    selectedStopPoints.forEach(stopPoint => {
      idsToDelete.push(stopPoint.id);
    });

    let deleteObj = {
      'is_delete_mode': true,
      'ids': idsToDelete
    };

    this.http.post('api/v1/partner-new-stop-points', deleteObj).pipe(take(1)).subscribe(() => {
      idsToDelete.forEach(id => {
        this.projectProblemDataService.removeStopPoint(this.projectProblemDataService.stopPoints[id]);
      });
      this.genericService.newShipmentsGenerateGridData();
    })

    const dropdown = document.getElementById('dropdown-container');
    this.selectedStopPoint = null;
    dropdown.style.display = 'none';
  }

  onFirstDataRendered(params) { }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.RECIPIENT').subscribe((res: string) => { this.recipientTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.TIME').subscribe((res: string) => { this.timeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.CREATION_DATE').subscribe((res: string) => { this.createdTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.COMPLETE').subscribe((res: string) => { this.completeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.VOLUME').subscribe((res: string) => { this.volumeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.INVOICE_STATUS').subscribe((res: string) => { this.invoiceStatusTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.PAY_ON_DELIVERY').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.PARTNER').subscribe((res: string) => { this.partnerTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAID').subscribe((res: string) => { this.paidLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_PAY').subscribe((res: string) => { this.toPayLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_BE_INVOICED').subscribe((res: string) => { this.toBeInvoicedLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));

    const addressClassRules = {
      'medium-red-letters': this.redLettersClass.bind(this)
    };

    this.columnDefs = [
      {
        headerName: this.recipientTitle,
        field: 'details',
        checkboxSelection: true,
        cellRenderer: this.nameCodePhoneRenderer,
        width: this.gridsService.widthCalculatorContainerId(25, 'new-shipments-grid')
      },
      {
        headerName: this.addressTitle,
        field: 'address',
        cellRenderer: this.gridsService.addressRenderer,
        width: this.gridsService.widthCalculatorContainerId(25, 'new-shipments-grid'),
        cellClassRules: addressClassRules,
      },
      {
        headerName: this.timeTitle,
        field: 'time',
        cellRenderer: this.timeRenderer,
        width: this.gridsService.widthCalculatorContainerId(25, 'new-shipments-grid')
      },
      {
        headerName: this.partnerTitle,
        field: 'partner',
        cellRenderer: this.partnerRenderer,
        width: this.gridsService.widthCalculatorContainerId(20, 'new-shipments-grid')
      },
      {
        headerName: '',
        field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculator(3)
      }
    ];
  }

  ngOnInit(): void {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
        this.pagesCount = 0;
        // this.gridApi.purgeServerSideCache([]);
        this.gridApi.refreshServerSideStore({ purge: true });
      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
