import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
    selector: 'app-dashboard-productivity-dummy',
    templateUrl: './dashboard-productivity-dummy.component.html',
    styleUrls: ['./dashboard-productivity-dummy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardProductivityDummyComponent implements OnInit, AfterViewInit {

    @Input() showSampleDataText: Boolean;

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() { }
}
