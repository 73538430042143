// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host ::ng-deep button:enabled:active{background: none !important}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxtLXdpZGdldC1oZWFkZXIuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7UUFDUSxzQ0FBc0MsMkJBQTJCIiwiZmlsZSI6ImxtLXdpZGdldC1oZWFkZXIuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgICAgIDpob3N0IDo6bmctZGVlcCBidXR0b246ZW5hYmxlZDphY3RpdmV7YmFja2dyb3VuZDogbm9uZSAhaW1wb3J0YW50fVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/header/lm-widget-header.component.ts"],"names":[],"mappings":";QACQ,sCAAsC,2BAA2B;;AAEzE,4XAA4X","sourcesContent":["\n        :host ::ng-deep button:enabled:active{background: none !important}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
