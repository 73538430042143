import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmNumberComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmNumberComponent) };

@Component({
  selector: 'lm-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LmNumberComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;

  @ViewChild(NgModel) model: NgModel;
  @HostBinding() id = `lm-number-${LmNumberComponent.nextId++}`;
  name = `lm-number-${LmNumberComponent.nextId++}`;
  
  @Input() label: string;
  @Input() placeholder: string;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() locale: string;
  @Input() format: boolean;
  @Input() buttonLayout: string;
  @Input() size: number;
  @Input() title: string;
  @Input() min: number;
  @Input() max: number;
  @Input() minFractionDigits: null;
  @Input() maxFractionDigits = 2;
  @Input() step: number;
  @Input() mode: string; // decimal | currency
  @Input() currency: string;
  @Input() currencyDisplay: boolean;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() showClear;
  @Input() styles: any;
  @Input() styleClass = 'lm-number-control';
  @Input() inputStyleClass = 'lm-number-input';
  @Input() iconClass: string;
  @Input() iconColor: string;
  @Input() iconSize?: string;
  @Input() iconStyle: LmTextIconStyle;
  @Input() incrementButtonClass: string;
  @Input() decrementButtonClass: string;
  @Input() incrementButtonIcon: string;
  @Input() decrementButtonIcon: string;
  @Input() blurFn: (e) => void;


  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  onBlur(self){
    if(this.blurFn) this.blurFn(self)
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule
  ],
  exports: [LmNumberComponent],
  declarations: [LmNumberComponent]
})
export class LMNumberModule {}
