import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { TimeWindowModalComponent } from '@app/modals/time-window-modal/time-window-modal.component';
import { GridsService } from '@app/services/grids.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-time-window-grid',
  templateUrl: './time-window-grid.component.html',
  styleUrls: ['./time-window-grid.component.scss']
})
export class TimeWindowGridComponent implements OnInit, OnDestroy {

  @ViewChild(TimeWindowModalComponent, { static: false }) timeWindowModalComponent: TimeWindowModalComponent;

  listen = [];
  timeWindowsDataArray = [];
  gridApi;
  gridColumnApi;
  rowData: any;
  domLayout = 'autoHeight';
  columnDefs;
  canAddOrEdit = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private settingsService: SettingsService,
    public gridsService: GridsService,
  ) {
    this.canAddOrEdit = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] || this.globals.accessRole == this.globals.teamMemberTypeConstants['ADVANCED'];
    this.listen.push(this.settingsService.updateTimeWindowGridListen().subscribe(() => {
      this.getTimeWindowData();
    }));
  }

  getTimeWindowData() {
    const self = this;
    let timeWindowData, gridObject = {};
    self.timeWindowsDataArray = [];
    this.http.get('api/v1/companies/' + this.globals.companyId + '/company-time-window').pipe(take(1)).subscribe(response => {
      if (response['items'].length) {
        response['items'].forEach(timeWindow => {
          timeWindowData = timeWindow.CompanyTimeWindowCategory;
          gridObject = {
            name: timeWindowData.label,
            timeWindow: timeWindowData.time_windows,
            id: timeWindowData.id,
          };
          self.timeWindowsDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' }
        self.timeWindowsDataArray.push(noData);
      }
      self.rowData = of(self.timeWindowsDataArray);
    });
  }

  newTimeWindow() {
    this.timeWindowModalComponent.openTimeWindowModal();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter((<HTMLInputElement>document.getElementById('time-window-filter-text-box')).value);
  }

  rowClicked(event) {
    if (!event.data.noDataText && this.canAddOrEdit) {
      this.timeWindowModalComponent.getTimeWindowData(event.data.id);
      this.timeWindowModalComponent.openTimeWindowModal();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFirstDataRendered(params) { }

  ngOnInit() {
    this.columnDefs = [
      { headerName: '', field: 'name', width: this.gridsService.widthCalculatorSettings(100), sortable: true }
    ];
    this.getTimeWindowData();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
