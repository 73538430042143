import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class GridsService {

    constructor() { }

    widthCalculator(windowPercentage) {
        const windowWidth = window.innerWidth;
        return (windowPercentage * windowWidth) / 100;
    }

    widthCalculatorGrid(gridPercentage) {
        const gridWidth = document.getElementById('stops-grid-header').offsetWidth - 20;
        return (gridPercentage * gridWidth) / 100;
    }

    widthCalculatorSettings(windowPercentage) {
        const container = <HTMLElement>document.getElementById('settings-page-container');
        const containerWidth = container.offsetWidth;
        return (windowPercentage * containerWidth) / 100;
    }

    widthCalculatorContainerId(windowPercentage, id) {
        const container = <HTMLElement>document.getElementById(id);
        if (container) {
            const containerWidth = container.offsetWidth;
            return (windowPercentage * containerWidth) / 100;
        }
    }

    addressRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            const address = params.getValue().split(',')[0];
            const region = params.getValue().replace(address + ', ', '');
            columnObject += '<div class="double-cell bold-letters standard-width" title="' + address + '">' + address + '</div>';
            columnObject += '<div class="double-cell standard-width" title="' + region + '">' + region + '</div>';
        }
        return columnObject;
    }

    addressNoBoldRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            const address = params.getValue().split(',')[0];
            const region = params.getValue().replace(address + ', ', '');
            columnObject += '<div class="double-cell standard-width" title="' + address + '">' + address + '</div>';
            columnObject += '<div class="double-cell standard-width" title="' + region + '">' + region + '</div>';
        }
        return columnObject;
    }

    nameRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell standard-width details-container"><i class="fas fa-phone-alt grey-letters"></i> ' + params.getValue().phone;
                if (params.getValue().barcode) {
                    columnObject += ' <i class="fas fa-qrcode grey-letters barcode"></i> ' + params.getValue().barcode + '</div>'
                }
            }
        }

        return columnObject;
    }

    collaboratorRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().id) {
                columnObject += '<div class="single-cell standard-width"><a target="_blank" href="../customerCollaborators/collaboratorView/' + params.getValue().id + '">' + params.getValue().name + '</a></div>';
            } else {
                columnObject += '<div class="single-cell standard-width">' + params.getValue().name + '</div>';
            }
        }

        return columnObject;
    }

    collaboratorNewTabRenderer(params) {
        let columnObject = '';

        if (params.getValue()) {
            if (params.getValue().id) {
                const collaboratorUrl = 'customerCollaborators/collaboratorView/' + String(params.getValue().id);
                columnObject += '<div class="single-cell standard-width hyperlink">' + '<a target="_blank" href=' + collaboratorUrl + '>' + params.getValue().name + '</a></div>';
            } else if (params.getValue().name) {
                columnObject += '<div class="single-cell standard-width">' + params.getValue().name + '</div>';
            }
        }
        return columnObject;
    }

    dateTimeRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().date) {
                columnObject += '<div class="double-cell" title="' + params.getValue().date + '">' + params.getValue().date + '</div>';
                columnObject += '<div class="double-cell"> ' + params.getValue().time + '</div>';
            } else {
                columnObject += '-';
            }
        }
        return columnObject;
    }

    timeRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().twFirstStart && !params.getValue().twSecondStart) {
                columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
                columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ')</div>'
            }
            else if (params.getValue().twFirstStart && params.getValue().twSecondStart) {
                columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
                columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ' ' + `${params.getValue().twSecondStart}-${params.getValue().twSecondEnd}` + ')</div>'
            }
            else {
                columnObject += '<div class="single-cell standard-width">' + params.getValue().date + '</div>'
            }
        }
        return columnObject;
    }

    fullWidthCellRenderer(params) {
        let rowObject = '<div class="no-data-row">' + params.data.noDataText + '</div>';
        return rowObject;
    }

    // custom renderer for volume and weight with input load volume
    volumeWeightCountRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="triple-cell standard-width display-flex">' + params.getValue().icon;
            if (params.getValue().load) {
                columnObject += params.getValue().load;
            } else {
                columnObject += '-';
            }
            columnObject += '</div>';
            columnObject += '<div class="triple-cell standard-width display-flex">';
            columnObject += '<span class="triple-cell-icon"><i class="fas fa-weight-hanging"></i></span>';
            if (params.getValue().weight) {
                columnObject += params.getValue().weight;
            } else {
                columnObject += '-';
            }
            columnObject += '</div>';
            columnObject += '<div class="triple-cell standard-width display-flex">';
            columnObject += '<span class="triple-cell-icon"><i class="fas fa-box"></i></span>';
            columnObject += params.getValue().count;
            columnObject += '</div>';
        }

        return columnObject;
    }

    // custom renderer for payment status
    paymentStatusRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="payment-status-container">'
            columnObject += '<div class="payment-status-bg ' + params.getValue().colorClass + '">'
            columnObject += '<div class="double-cell standard-width payment-status-label">' + params.getValue().label + '</div>';
            columnObject += '<div class="double-cell standard-width payment-status-value">' + params.getValue().paymentStatusValue + '</div>' + '</div>';
            columnObject += '</div>';
            columnObject += '</div>';
        }
        return columnObject;
    }

    isFullWidthRow(params) {
        if (params.rowNode && params.rowNode.data) {
            if (params.rowNode.data.noDataText) {
                return 1;
            } else {
                return 0;
            }
        }
    };

    // stop point history grid
    historyFullWidthCellRenderer(params) {
        let rowObject;
        rowObject = '<div class="history-last-days-row">' + params.data.rowText + '</div>';

        return rowObject;
    }

    // custom renderer for pay on delivery
    payOnDeliveryRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="pay-on-delivery-container">'
            columnObject += '<div class="pay-on-delivery-bg ' + params.getValue().colorClass + '">'
            columnObject += '<div class="double-cell standard-width pay-on-delivery-label">' + params.getValue().label + '</div>';
            columnObject += '<div class="double-cell standard-width pay-on-delivery-value">' + params.getValue().payOnDeliveryValue + '</div>' + '</div>';
            columnObject += '</div>';
            columnObject += '</div>';
        }
        return columnObject;
    }

    historyFullWidthCell(rowNode) {
        if (rowNode.data) {
            if (rowNode.data.lastThreeDays || rowNode.data.lastFiveDays || rowNode.data.lastSevenDays) {
                return 1;
            } else {
                return 0;
            }
        }
    };

    isStopPointRowWithPayAmount(params) {
        if (Number(params.data.payOnDelivery.payOnDeliveryAmount) > 0) {
            return true;
        } else {
            return false;
        }
    }
}
