import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapService } from '@app/services/map.service';
import { ColourService } from '@app/services/colour.service';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SettingsDriversMapComponent } from './map/settings-regional-map.component';
import { DriverRegionRuleService } from '@app/services/driver-region-rule.service';
import { take } from 'rxjs/operators';

declare var H: any;

@Component({
    selector: 'app-settings-drivers-regions',
    templateUrl: './settings-drivers-regions.component.html',
    styleUrls: ['./settings-drivers-regions.component.scss']
})
export class SettingsDriversRegionsComponent implements OnInit, OnDestroy {
    @ViewChild(SettingsDriversMapComponent, { static: false }) settingsDriversMapComponent: SettingsDriversMapComponent;

    listen = [];

    driversRegions = [];
    drivers = {};
    activeDriverIndex = null;
    clickOnPageFunction;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private mapService: MapService,
        private colourService: ColourService,
        public globals: Globals,
        public driverRegionRuleService: DriverRegionRuleService,
    ) {
        this.listen.push(this.driverRegionRuleService.savePolylineListen().subscribe((response) => {
            this.savePolygonChanges(response.id, response.polyline);
        }));
        this.listen.push(this.driverRegionRuleService.updateDriverDataListen().subscribe((response) => {
            this.updateDriverData(response);
        }));
    }

    loadAllRegions() {
        this.driversRegions.forEach(driverData => {
            if (driverData.polygon) {
                const colour = this.colourService.colourCalculator(driverData.index);
                const lineString = this.mapService.calculatePolygon(driverData.polygon);
                this.settingsDriversMapComponent.createResizablePolygon(lineString, colour, driverData.driverId);
            }
        });
    }

    removeActiveDriver() {
        const activeDriverElement = document.querySelector('.drivers-list-item.active');
        if (activeDriverElement) {
            activeDriverElement.classList.remove('active');
        }
    }

    clickOnPage(event) {
        // four elements above is the map element
        // all other elements are generated by here maps without id or class
        if (event.target?.parentElement?.parentElement?.parentElement?.parentElement?.id !== 'map') {
            this.settingsDriversMapComponent.emptyDrawMode();
            if (this.activeDriverIndex !== null) {
                this.removeActiveDriver();
                this.activeDriverIndex = null;
                this.settingsDriversMapComponent.activeDriverIndex = null;
            }
        }
        if (event.target.matches('.drivers-list-item') && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
            const index = event.target.getAttribute('index');
            const driverData = this.driversRegions[index];
            if (driverData) {
                this.removeActiveDriver();
                event.target.classList.add('active');
                this.activeDriverIndex = driverData.index;
                this.settingsDriversMapComponent.activeDriverIndex = driverData.index;
                this.settingsDriversMapComponent.centerToPolygon(driverData.driverId);
            } else {
                if (!index && index !== 0) {
                    console.error('wrong index (' + event.target.getAttribute('index') + '), object clicked:');
                    console.error(event.target);
                } else if (!this.driversRegions[index]) {
                    console.error('index: ' + index + 'does not belong to any driver');
                    console.error(this.driversRegions);
                }
            }
        }
    }

    updateRule(index, rule) {
        this.driversRegions[index].rule = rule;
        const driverId = this.driversRegions[index].driverId;
        const colour = this.colourService.colourCalculator(this.driversRegions[index].index);
        const polygon = rule.rule.trigger.expression.operands[1].value;
        this.driversRegions[index].polygon = polygon;
        const lineString = this.mapService.calculatePolygon(polygon);
        this.settingsDriversMapComponent.updatePolygon(lineString, colour, driverId);
    }

    savePolygonChanges(id, polyline) {
        let driverData, index;
        this.driversRegions.forEach(tempData => {
            if (tempData.driverId === id) {
                driverData = tempData;
                index = tempData.index;
            }
        });
        const rule = driverData.rule;
        const data = {
            id: id,
            polygon: polyline,
            rule: rule
        };
        if (rule) {
            this.driverRegionRuleService.invoke('put', null, null, data).pipe(take(1)).subscribe(response => {
                this.updateRule(index, response['item']['importRule']);
            });
        } else {
            this.driverRegionRuleService.invoke('post', null, null, data).pipe(take(1)).subscribe(response => {
                this.updateRule(index, response['item']['importRule']);
            });
        }
    }

    loadRule(rule, driverId) {
        let polygon = '';
        if (rule) {
            rule.rule.trigger.expression.operands.forEach(operand => {
                if (operand.order === 1) {
                    polygon = operand.value;
                }
            });
        }
        // const driverId = rule.rule.actions[0].ruleActionXDriver.driver_id;
        const index = this.driversRegions.length;
        this.driversRegions.push({
            name: this.drivers[driverId].userProfile.name,
            driverId: driverId,
            index: index,
            colour: this.colourService.colourCalculator(index),
            polygon: polygon,
            rule: rule
        });
        this.settingsDriversMapComponent.driversRegions = this.driversRegions;
        // this.currentIndex++;
    }

    updateDriverData(data) {
        const newRule = data['item'];
        if (newRule) {
            let indexToUpdate = null;
            let polygon = '';
            this.driversRegions.forEach(driverData => {
                if (driverData.driverId === newRule.importRule.rule.actions[0].ruleActionXDriver.driver_id) {
                    driverData.rule = newRule;
                    indexToUpdate = driverData.index;
                    newRule.importRule.rule.trigger.expression.operands.forEach(operand => {
                        if (operand.order === 1) {
                            polygon = operand.value;
                        }
                    });
                }
            });
            if (indexToUpdate !== null) {
                this.driversRegions[indexToUpdate].rule = newRule;
                this.driversRegions[indexToUpdate].polygon = newRule;
            }
        }
    }

    ngOnInit() {
        this.clickOnPageFunction = this.clickOnPage.bind(this);
        document.addEventListener('click', this.clickOnPageFunction, true);

        this.driverRegionRuleService.getRulesData().pipe(take(1)).subscribe(response => {
            const rules = this.driverRegionRuleService.filterRegionRules(response['items']);

            const ruleByDriverId = {};
            rules.forEach(rule => {
                if (rule.rule.actions[0].ruleActionXDriver) {
                    const driverId = rule.rule.actions[0].ruleActionXDriver.driver_id;
                    // if this driver has somehow more than one region, show only the 1st
                    if (!ruleByDriverId[driverId]) {
                        ruleByDriverId[driverId] = rule;
                    }
                }
            });

            this.http.get('api/internal/v2/drivers').pipe(take(1)).subscribe(driversData => {
                const drivers = driversData['items'];
                drivers.forEach(driver => {
                    this.drivers[driver.driver.id] = driver;
                    let rule = null;
                    if (ruleByDriverId[driver.driver.id]) {
                        rule = ruleByDriverId[driver.driver.id];
                    }
                    this.loadRule(rule, driver.driver.id);
                });
                this.loadAllRegions();
                this.settingsDriversMapComponent.centerToPolygon(null);
            });
        });
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.clickOnPageFunction, true);
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
