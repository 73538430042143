/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LmPudoService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

  
    static readonly createPudoCompanyPath = 'api/pudoInternal/v1/company';
    static readonly retrievePudoCompaniesPath = 'api/v1/pudo-companies';
    static readonly retrievePudoCompaniesSinglePath = 'api/v1/pudo-companies/{id}';
    static readonly retrievePudoCompanyByTinPath = 'api/pudoInternal/v1/company/search/{tin}';
    static readonly searchPudoCompaniesPath = 'api/v1/pudo-companies?searchQuery={query}'

    static readonly retrievePudoPointsPath = 'api/v1/pudo-points';
    static readonly retrievePudoPointsFatFreePath = 'api/v1/pudo-points?pageSize=2000&fields=id,description';
    static readonly searchPudoPointsPath = 'api/v1/pudo-points?searchQuery={query}'

    static readonly retrievePudoShipmentsPath = 'api/v1/pudo-shipments';
    static readonly searchPudoShipmentsPath = 'api/v1/pudo-shipments?searchQuery={query}';
    static readonly pudoAppShipmentsFilterGetPath = 'api/v1/pudo-shipments?filterMode={query}';

    // /api/v1/pudo-shipments?searchQuery=EO65269886

    // FILTER PUDO SHIPMENTS
    apiPudoAppShipmentsFilter$Response(params?: { query?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.pudoAppShipmentsFilterGetPath, 'get');
        if (params) rb.path('query', params.query);

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }

    apiPudoAppShipmentsFilter(params?: { query?: string }): Observable<Array<any>> {
        return this.apiPudoAppShipmentsFilter$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // SEARCH PUDO POINTS
    apiPudoShipmentsSearch$Response(params?: { query?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.searchPudoShipmentsPath, 'get');
        if (params) rb.path('query', params.query);

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoShipmentsSearch(params?: { query?: string }): Observable<Array<any>> {
        return this.apiPudoShipmentsSearch$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


    //GET PUDO SHIPMENTS
    apiPudoShipmentsGet$Response(): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.retrievePudoShipmentsPath, 'get');

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoShipmentsGet(): Observable<Array<any>> {
        return this.apiPudoShipmentsGet$Response().pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }



    // SEARCH PUDO POINTS
    apiPudoPointsSearch$Response(params?: { query?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.searchPudoPointsPath, 'get');
        if (params) rb.path('query', params.query);

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoPointsSearch(params?: { query?: string }): Observable<Array<any>> {
        return this.apiPudoPointsSearch$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // SEARCH PUDO COMPANIES
    apiPudoCompaniesSearch$Response(params?: { query?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.searchPudoCompaniesPath, 'get');
        if (params) rb.path('query', params.query);

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoCompaniesSearch(params?: { query?: string }): Observable<Array<any>> {
        return this.apiPudoCompaniesSearch$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // GET PUDO POINTS FATFREE
    apiPudoPointsFatFreeGet$Response(depotId: number): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.retrievePudoPointsFatFreePath + '&companyDepotIdFilter=' + depotId, 'get');

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoPointsFatFreeGet(depotId: number): Observable<Array<any>> {
        return this.apiPudoPointsFatFreeGet$Response(depotId).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // GET PUDO POINTS
    apiPudoPointsGet$Response(): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.retrievePudoPointsPath, 'get');

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoPointsGet(): Observable<Array<any>> {
        return this.apiPudoPointsGet$Response().pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    
    // CREATE PUDO COMPANY
    apiCreatePudoCompanyPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.createPudoCompanyPath, 'post');
        if (params) rb.body(params.body, 'application/*+json');

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiCreatePudoCompanyPost(params?: { body?: any }): Observable<Array<any>> {
        return this.apiCreatePudoCompanyPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }



    // GET PUDO COMPANIES
    apiPudoCompaniesGet$Response(): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.retrievePudoCompaniesPath, 'get');

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoCompaniesGet(): Observable<Array<any>> {
        return this.apiPudoCompaniesGet$Response().pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // GET PUDO COMPANIY BYID
    apiPudoCompanySingleGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.retrievePudoCompaniesSinglePath, 'get');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoCompanySingleGet(params?: { id?: string }): Observable<Array<any>> {
        return this.apiPudoCompanySingleGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    //GET PUDO COMPANY BY TIN
    apiPudoCompanyByTinGet$Response(params?: { tin?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmPudoService.retrievePudoCompanyByTinPath, 'get');
        if (params) rb.path('tin', params.tin);

        return this.http.request(
            rb.build({responseType: 'json', accept: 'application/json'})).pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>
            )
        );
    }
    
    apiPudoCompanyByTinGet(params?: { tin?: string }): Observable<Array<any>> {
        return this.apiPudoCompanyByTinGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }
    
}
