import { Injectable } from '@angular/core';
import { prng_alea } from 'esm-seedrandom';

declare var H: any;

@Injectable({
    providedIn: 'root'
})
export class ColourService {

    colours = [
        '#444444',
        '#4eb4f9',
        '#9889d6',
        '#229100',
        '#bcba5e',
        '#a56429',
        '#7c40ff',
        '#ef52d1',
        '#163c63',
        '#85144b',
        '#3D9970',
        '#B10DC9',
        '#56e25d',
        '#ef4b62',
        '#ffa039',
    ];

    pieChartColours = [
        '#ff6384',
        '#36a2eb',
        '#ffcd56',
        '#9966ff',
        '#4bc0c0',
        '#c9cbcf',
        '#d7889a',
        '#659abd',
        '#d5bc81',
        '#a68cd9',
        '#6c9d9d'
    ];

    // an array with the default colours plus the used generated colours
    usedColours = [];
    coloursByRouteIndex = {};
    similarityPercentage = 0.85;
    loopTriesCap = 10;

    constructor() {
        this.usedColours = [...this.colours];
    }

    // gets a hex colour and returns an object with the rbg values
    hexToRGB(hex) {
        hex.replace('#', '');
        return {
            r: parseInt(hex.substring(0, 2), 16),
            g: parseInt(hex.substring(2, 4), 16),
            b: parseInt(hex.substring(4, 6), 16)
        };
    }

    // compares two colours for similarity
    compareColours(hex1, hex2) {
        // get red/green/blue int values of hex1
        const r1 = parseInt(hex1.substring(0, 2), 16);
        const g1 = parseInt(hex1.substring(2, 4), 16);
        const b1 = parseInt(hex1.substring(4, 6), 16);
        // get red/green/blue int values of hex2
        const r2 = parseInt(hex2.substring(0, 2), 16);
        const g2 = parseInt(hex2.substring(2, 4), 16);
        const b2 = parseInt(hex2.substring(4, 6), 16);
        // calculate differences between reds, greens and blues
        let r = 255 - Math.abs(r1 - r2);
        let g = 255 - Math.abs(g1 - g2);
        let b = 255 - Math.abs(b1 - b2);
        // limit differences between 0 and 1
        r /= 255;
        g /= 255;
        b /= 255;
        // 0 means opposit colors, 1 means same colors
        return (r + g + b) / 3;
    }

    // multiplies the r,g&b values by 2 and cuts to 2 decimals to change the colour
    changeColour(colour) {
        const rgb = this.hexToRGB(colour.replace('#', ''));
        prng_alea(rgb.r).toString(16).slice(-2);
        const r = prng_alea(rgb.r)().toString(16).slice(-2);
        const g = prng_alea(rgb.g)().toString(16).slice(-2);
        const b = prng_alea(rgb.b)().toString(16).slice(-2);
        return '#' + r + '' + g + '' + b;
    }

    // checks if the colour already exists (90% similarity) and if it does it changes it until it is different from all other
    checkColourUniqueness(colour) {
        // parse all the previously calculated colours
        // Object.keys(this.coloursByRouteIndex).forEach(index => {
        this.usedColours.forEach(usedColour => {
            // change the colour if it matches over 85% with another selected
            let compareWithGeneratedColoursTries = 0;
            while (
                this.compareColours(usedColour.replace('#', ''), colour.replace('#', '')) > this.similarityPercentage
                && compareWithGeneratedColoursTries < this.loopTriesCap
            ) {
                colour = this.changeColour(colour);
                compareWithGeneratedColoursTries++;
            }
        });
        return colour;
    }

    // shifts the hex colour
    shiftColourHex(hex, step) {
        return hex.substr(step) + hex.substr(0, step);
    }

    // get a unique colour for this route index
    colourCalculator(index) {
        // calculate the colour for this route index if it wasn't previously calculated
        if (!this.coloursByRouteIndex[index]) {
            // if the index is not within our default colours,
            // calculate a new colour by shifting the previous hex
            if (!this.colours[index]) {
                // previous hex is the mod between in index and the length
                // e.g. for index=5 and 3 colours, 5%3=2, the previous colour is the 3rd in the colours array
                const previousColour = this.colours[index % this.colours.length].replace('#', '');
                // then we shift the previous colour by the rounded (floored) division of index by the length
                // e.g. for index=5 and 3 colours, floor(5/3)=1, the colour hex is shifted by 1 character
                let newColour = '#' + this.shiftColourHex(previousColour, Math.floor(index / this.colours.length));
                // lastly we compare the new colour with all our existing ones and ensure it's over 15% different
                newColour = this.checkColourUniqueness(newColour);
                this.coloursByRouteIndex[index] = newColour;
                if (!this.usedColours.includes(newColour)) {
                    this.usedColours.push(newColour);
                }
            } else {
                this.coloursByRouteIndex[index] = this.colours[index];
            }
        }
        return this.coloursByRouteIndex[index];
    }

    // get the right colour for the route index but with 70 opacity
    oldRouteColourCalculator(index) {
        return this.colourCalculator(index) + '70';
    }

    calculatePieChartColor(index) {
        return this.pieChartColours[index % this.pieChartColours.length];
    }

}
