// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#details-form,
#card-form {
  padding: 3vw;
}

#details-form {
  width: 50vw;
}

#wizard-information-container {
  height: 100%;
  justify-content: space-between;
}

.settings-form-title {
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndpemFyZC1maW5pc2guY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7O0VBRUksWUFBQTtBQUNKOztBQUVBO0VBQ0ksV0FBQTtBQUNKOztBQUVBO0VBQ0ksWUFBQTtFQUNBLDhCQUFBO0FBQ0o7O0FBRUE7RUFDSSxnQkFBQTtFQUNBLGdCQUFBO0VBQ0EsbUJBQUE7QUFDSiIsImZpbGUiOiJ3aXphcmQtZmluaXNoLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiI2RldGFpbHMtZm9ybSxcbiNjYXJkLWZvcm0ge1xuICAgIHBhZGRpbmc6IDN2dztcbn1cblxuI2RldGFpbHMtZm9ybSB7XG4gICAgd2lkdGg6IDUwdnc7XG59XG5cbiN3aXphcmQtaW5mb3JtYXRpb24tY29udGFpbmVyIHtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xufVxuXG4uc2V0dGluZ3MtZm9ybS10aXRsZSB7XG4gICAgZm9udC1zaXplOiAxLjdlbTtcbiAgICBmb250LXdlaWdodDogNjAwO1xuICAgIG1hcmdpbi1ib3R0b206IDIwcHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/wizard/wizard-finish/wizard-finish.component.scss"],"names":[],"mappings":"AAAA;;EAEI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,8BAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACA,wwBAAwwB","sourcesContent":["#details-form,\n#card-form {\n    padding: 3vw;\n}\n\n#details-form {\n    width: 50vw;\n}\n\n#wizard-information-container {\n    height: 100%;\n    justify-content: space-between;\n}\n\n.settings-form-title {\n    font-size: 1.7em;\n    font-weight: 600;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
