import { MilyService } from '@app/services/mily.service';
import { Injectable } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ImportUtils {

    listen = [];

    errorDuplicateColumnLabel: String;
    errorTooManySheets: String;

    constructor(
        public globals: Globals,
        public milyService: MilyService,
        public translate: TranslateService
    ) { }

    handleAnalyzerErrors(errors) {
        this.getTranslations();

        if (errors) {
            // importer errors
            // duplicate column (two or more columns with the same name)
            if (errors.importer?.base?.length) {
                switch (errors.importer.base[0]) {
                    case 'There are duplicate keys in your excel input file':
                        this.milyService.alert(this.errorDuplicateColumnLabel);
                        break;
                    default:
                        console.warn('Unidentified error!')
                        break;
                }
            }

            // analyzer errors
            // duplicate column (two or more columns with the same name)
            else if (errors.analyzer?.base?.length) {
                switch (errors.analyzer.base[0]) {
                    case 'Too many sheets. Max 10 sheets per file allowed!':
                        this.milyService.alert(this.errorTooManySheets);
                        break;
                    default:
                        console.warn('Unidentified error!')
                        break;
                }
            }
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('IMPORTER.ERROR_DUPLICATE_COLUMNS').subscribe((res: string) => { this.errorDuplicateColumnLabel = res; }));
        this.listen.push(this.translate.get('IMPORTER.ERROR_TOO_MANY_SHEETS').subscribe((res: string) => { this.errorTooManySheets = res; }));
    }
}
