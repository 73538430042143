import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { GuideTourService } from '@app/services/guide-tour.service';

@Component({
    selector: 'app-project-view-guided-tour',
    template: ''
})

export class ProjectViewGuidedTourComponent implements OnInit, AfterViewInit, OnDestroy {

    listen = [];

    defaultButtons = [];
    firstButtons = [];
    goButtons = [];
    lastButtons = [];
    normalMarkerId;
    overweightMarkerId;
    disabledMarkerId;
    timeWindowMarkerId;
    priorityMarkerId;

    nextMsg = '';
    goMsg = '';
    backMsg = '';
    finishMsg = '';

    thinkMsg = '';
    thinkDescription = '';
    thinkDescriptionHtml = '';
    thinkDescription1Msg = '';
    thinkDescription2Msg = '';
    thinkDescriptionChoice1Msg = '';
    thinkDescriptionChoice2Msg = '';
    thinkDescriptionChoice3Msg = '';

    pointsMsg = '';
    pointsDescriptionMsg = '';

    driversMsg = '';
    driversDescriptionMsg = '';

    newPointMsg = '';
    newPointDescriptionMsg = '';

    multiSelectPointsMsg = '';
    multiSelectPointsDescriptionHtml = '';
    multiSelectPointsDescriptionMsg = '';
    multiSelectPointsDescriptionChoice1Msg = '';
    multiSelectPointsDescriptionChoice2Msg = '';

    allPointsMsg = '';
    allPointsDescriptionMsg = '';

    normalPointMsg = '';
    normalPointDescriptionMsg = '';

    disabledPointMsg = '';
    disabledPointDescriptionMsg = '';

    overweightPointMsg = '';
    overweightPointDescriptionMsg = '';

    timeWindowPointMsg = '';
    timeWindowPointDescriptionMsg = '';

    priorityPointMsg = '';
    priorityPointDescriptionMsg = '';

    rightClickMsg = '';
    rightClickDescriptionMsg = '';

    enableMsg = '';
    enableDescriptionMsg = '';

    moveToDriverMsg = '';
    moveToDriverDescriptionMsg = '';

    beforeMsg = '';
    beforeDescriptionMsg = '';

    removeModificationsMsg = '';
    removeModificationsDescriptionMsg = '';

    hightPriorityMsg = '';
    hightPriorityDescriptionMsg = '';

    freeTimeWindowsMsg = '';
    freeTimeWindowsDescriptionMsg = '';

    mergeMsg = '';
    mergeDescriptionMsg = '';

    deleteMsg = '';
    deleteDescriptionMsg = '';

    milyMsg = '';
    chatMsg = '';

    constructor(
        private generalButtonsGuide: ShepherdService,
        private markerIconsGuide: ShepherdService,
        private rightClickGuide: ShepherdService,
        private guideTourService: GuideTourService,
        public translate: TranslateService,
    ) {
    }

    startGeneralGuide() {
        this.startGeneralButtonsGuide(true);
    }

    startGeneralButtonsGuide(continueToMarkers = false) {
        const self = this;
        const steps = [];
        if (document.getElementById('stops-grid-button')) {
            steps.push({
                id: 'stops',
                attachTo: {
                    element: '#stops-grid-button',
                    on: 'left'
                },
                buttons: this.firstButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.pointsMsg,
                text: this.guideTourService.generateMilyMessage(this.pointsDescriptionMsg),
            });
        }
        if (document.getElementById('drivers-grid-button')) {
            steps.push({
                id: 'drivers',
                attachTo: {
                    element: '#drivers-grid-button',
                    on: 'left'
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.driversMsg,
                text: this.guideTourService.generateMilyMessage(this.driversDescriptionMsg),
            });
        }
        if (document.getElementById('create-new-stop')) {
            steps.push({
                id: 'newStop',
                attachTo: {
                    element: '#create-new-stop',
                    on: 'left'
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.newPointMsg,
                text: this.guideTourService.generateMilyMessage(this.newPointDescriptionMsg),
            });
        }
        if (document.getElementById('select-wrapper')) {
            steps.push({
                id: 'select',
                attachTo: {
                    element: '#select-wrapper',
                    on: 'right'
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.multiSelectPointsMsg,
                text: this.guideTourService.generateMilyMessage(this.multiSelectPointsDescriptionHtml),
            });
        }
        if (steps.length) {
            self.generalButtonsGuide.addSteps(steps);
            self.generalButtonsGuide.start();
            if (continueToMarkers) {
                self.generalButtonsGuide.tourObject.on('complete', (event) => {
                    self.startMarkerIconsGuide(true);
                });
                self.rightClickGuide.tourObject.on('cancel', (event) => {
                    self.endTour();
                });
            }
        }
    }

    startMarkerIconsGuide(continueToRightClick = false) {
        const self = this;
        const steps = [];
        steps.push({
            id: 'allMarkers',
            buttons: this.goButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.allPointsMsg,
            text: '<div class="stops-icon-in-tour"></div>' + this.guideTourService.generateMilyMessage(this.allPointsDescriptionMsg),
        });
        steps.push({
            id: 'normalMarker',
            attachTo: {
                element: '#normalMarkerIcon',
                on: 'left'
            },
            beforeShowPromise: function () {
                return new Promise<void>(function (resolve) {
                    self.guideTourService.focusOnMarker(self.normalMarkerId);
                    setTimeout(function () {
                        resolve();
                    }, 700);
                });
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.normalPointMsg,
            text: this.guideTourService.generateMilyMessage(this.normalPointDescriptionMsg),
        });
        if (self.disabledMarkerId) {
            steps.push({
                id: 'disabledMarker',
                attachTo: {
                    element: '#disabledMarkerIcon',
                    on: 'left'
                },
                beforeShowPromise: function () {
                    return new Promise<void>(function (resolve) {
                        self.guideTourService.focusOnMarker(self.disabledMarkerId);
                        setTimeout(function () {
                            resolve();
                        }, 700);
                    });
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.disabledPointMsg,
                text: this.guideTourService.generateMilyMessage(this.disabledPointDescriptionMsg),
            });
        }
        if (self.overweightMarkerId) {
            steps.push({
                id: 'overweightMarker',
                attachTo: {
                    element: '#overweightMarkerIcon',
                    on: 'left'
                },
                beforeShowPromise: function () {
                    return new Promise<void>(function (resolve) {
                        self.guideTourService.focusOnMarker(self.overweightMarkerId);
                        setTimeout(function () {
                            resolve();
                        }, 700);
                    });
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.overweightPointMsg,
                text: this.guideTourService.generateMilyMessage(this.overweightPointDescriptionMsg),
            });
        }
        if (self.timeWindowMarkerId) {
            steps.push({
                id: 'timeWindowMarker',
                attachTo: {
                    element: '#timeWindowMarkerIcon',
                    on: 'left'
                },
                beforeShowPromise: function () {
                    return new Promise<void>(function (resolve) {
                        self.guideTourService.focusOnMarker(self.timeWindowMarkerId);
                        setTimeout(function () {
                            resolve();
                        }, 700);
                    });
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.timeWindowPointMsg,
                text: this.guideTourService.generateMilyMessage(this.timeWindowPointDescriptionMsg),
            });
        }
        if (self.priorityMarkerId) {
            steps.push({
                id: 'priorityMarker',
                attachTo: {
                    element: '#priorityMarkerIcon',
                    on: 'left'
                },
                beforeShowPromise: function () {
                    return new Promise<void>(function (resolve) {
                        self.guideTourService.focusOnMarker(self.priorityMarkerId);
                        setTimeout(function () {
                            resolve();
                        }, 700);
                    });
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.priorityPointMsg,
                text: this.guideTourService.generateMilyMessage(this.priorityPointDescriptionMsg),
            });
        }
        if (steps.length) {
            self.markerIconsGuide.addSteps(steps);
            self.markerIconsGuide.start();
            if (continueToRightClick) {
                self.markerIconsGuide.tourObject.on('complete', (event) => {
                    self.startRightClickGuide();
                });
                self.rightClickGuide.tourObject.on('cancel', (event) => {
                    self.endTour();
                });
            }
        }
    }

    startRightClickGuide() {
        const self = this;
        const steps = [];
        steps.push({
            id: 'contextmenu',
            attachTo: {
                element: '.H_context_menu',
                on: 'left'
            },
            beforeShowPromise: function () {
                return new Promise<void>(function (resolve) {
                    self.guideTourService.focusOnMarker(self.normalMarkerId);
                    setTimeout(function () {
                        self.guideTourService.triggerRightClick(self.normalMarkerId);
                        setTimeout(function () {
                            resolve();
                        }, 200);
                    }, 500);
                });
            },
            buttons: this.firstButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.rightClickMsg,
            text: this.guideTourService.generateMilyMessage(this.rightClickDescriptionMsg),
        });
        steps.push({
            id: 'enable',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-of-type(1)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.enableMsg,
            text: this.guideTourService.generateMilyMessage(this.enableDescriptionMsg),
        });
        steps.push({
            id: 'moveToDriver',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(4)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.moveToDriverMsg,
            text: this.guideTourService.generateMilyMessage(this.moveToDriverDescriptionMsg),
        });
        steps.push({
            id: 'before',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(5)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.beforeMsg,
            text: this.guideTourService.generateMilyMessage(this.beforeDescriptionMsg),
        });
        steps.push({
            id: 'removeModifications',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(7)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.removeModificationsMsg,
            text: this.guideTourService.generateMilyMessage(this.removeModificationsDescriptionMsg),
        });
        steps.push({
            id: 'priority',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(11)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.hightPriorityMsg,
            text: this.guideTourService.generateMilyMessage(this.hightPriorityDescriptionMsg),
        });
        steps.push({
            id: 'freeTimeWindow',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(14)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.freeTimeWindowsMsg,
            text: this.guideTourService.generateMilyMessage(this.freeTimeWindowsDescriptionMsg),
        });
        steps.push({
            id: 'merge',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(17)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.mergeMsg,
            text: this.guideTourService.generateMilyMessage(this.mergeDescriptionMsg),
        });
        steps.push({
            id: 'delete',
            attachTo: {
                element: '.H_context_menu .H_context_menu_item:nth-child(21)',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: this.deleteMsg,
            text: this.guideTourService.generateMilyMessage(this.deleteDescriptionMsg),
        });
        steps.push({
            id: 'mily',
            attachTo: {
                element: '#mily-button',
                on: 'left'
            },
            buttons: this.defaultButtons,
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: false,
            title: 'Mily',
            text: this.guideTourService.generateMilyMessage(this.milyMsg),
        });
        if (document.querySelector('[id^="comm100-button"] iframe')) {
            steps.push({
                id: 'chat',
                attachTo: {
                    element: '[id^="comm100-button"] iframe',
                    on: 'left'
                },
                buttons: this.defaultButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: 'Support',
                text: this.guideTourService.generateMilyMessage(this.chatMsg),
            });
        }
        if (!document.getElementById('optimize-button').classList.contains('hidden')) {
            steps.push({
                id: 'think',
                attachTo: {
                    element: '#optimize-button',
                    on: 'top'
                },
                buttons: this.lastButtons,
                cancelIcon: {
                    enabled: true
                },
                highlightClass: 'highlight',
                scrollTo: false,
                title: this.thinkMsg,
                text: this.guideTourService.generateMilyMessage(this.thinkDescription),
            });
        }
        if (steps.length) {
            self.rightClickGuide.addSteps(steps);
            self.rightClickGuide.start();
            self.rightClickGuide.tourObject.on('complete', (event) => {
                const element = document.querySelector('.H_context_menu');
                if (element) {
                    element.remove();
                }
                self.endTour();
            });
            self.rightClickGuide.tourObject.on('cancel', (event) => {
                const element = document.querySelector('.H_context_menu');
                if (element) {
                    element.remove();
                }
                self.endTour();
            });
        }
    }

    endTour() {
        this.guideTourService.clearMapFromTour();
    }

    getTranslations() {
        this.listen.push(this.translate.get('DEMO.NEXT').subscribe((res: string) => {
            this.nextMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.GO').subscribe((res: string) => {
            this.goMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.BACK').subscribe((res: string) => {
            this.backMsg = res;
        }));
        this.listen.push(this.translate.get('DEMO.FINISH').subscribe((res: string) => {
            this.finishMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK').subscribe((res: string) => {
            this.thinkMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK_DESCRIPTION').subscribe((res: string) => {
            this.thinkDescription = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK_DESCRIPTION1').subscribe((res: string) => {
            this.thinkDescription1Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK_DESCRIPTION2').subscribe((res: string) => {
            this.thinkDescription2Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK_DESCRIPTION_CHOICE1').subscribe((res: string) => {
            this.thinkDescriptionChoice1Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK_DESCRIPTION_CHOICE2').subscribe((res: string) => {
            this.thinkDescriptionChoice2Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.THINK_DESCRIPTION_CHOICE3').subscribe((res: string) => {
            this.thinkDescriptionChoice3Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.POINTS').subscribe((res: string) => {
            this.pointsMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.POINTS_DESCRIPTION').subscribe((res: string) => {
            this.pointsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.DRIVERS').subscribe((res: string) => {
            this.driversMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.DRIVERS_DESCRIPTION').subscribe((res: string) => {
            this.driversDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.ADD_POINTS').subscribe((res: string) => {
            this.newPointMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.ADD_POINTS_DESCRIPTION').subscribe((res: string) => {
            this.newPointDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MULTIPLE_SELECT').subscribe((res: string) => {
            this.multiSelectPointsMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MULTIPLE_SELECT_DESCRIPTION').subscribe((res: string) => {
            this.multiSelectPointsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MULTIPLE_SELECT_DESCRIPTION_CHOICE1').subscribe((res: string) => {
            this.multiSelectPointsDescriptionChoice1Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MULTIPLE_SELECT_DESCRIPTION_CHOICE2').subscribe((res: string) => {
            this.multiSelectPointsDescriptionChoice2Msg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.ALL_POINTS').subscribe((res: string) => {
            this.allPointsMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.ALL_POINTS_DESCRIPTION').subscribe((res: string) => {
            this.allPointsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.NORMAL_POINT').subscribe((res: string) => {
            this.normalPointMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.NORMAL_POINT_DESCRIPTION').subscribe((res: string) => {
            this.normalPointDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.DISABLED_POINT').subscribe((res: string) => {
            this.disabledPointMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.DISABLED_POINT_DESCRIPTION').subscribe((res: string) => {
            this.disabledPointDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.OVERWEIGHT_POINT').subscribe((res: string) => {
            this.overweightPointMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.OVERWEIGHT_POINT_DESCRIPTION').subscribe((res: string) => {
            this.overweightPointDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.TIME_WINDOW_POINT').subscribe((res: string) => {
            this.timeWindowPointMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.TIME_WINDOW_POINT_DESCRIPTION').subscribe((res: string) => {
            this.timeWindowPointDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.PRIORITY_POINT').subscribe((res: string) => {
            this.priorityPointMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.PRIORITY_POINT_DESCRIPTION').subscribe((res: string) => {
            this.priorityPointDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.RIGHT_CLICK').subscribe((res: string) => {
            this.rightClickMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.RIGHT_CLICK_DESCRIPTION').subscribe((res: string) => {
            this.rightClickDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.ENABLE').subscribe((res: string) => {
            this.enableMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.ENABLE_DESCRIPTION').subscribe((res: string) => {
            this.enableDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MOVE_TO_DRIVER').subscribe((res: string) => {
            this.moveToDriverMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MOVE_TO_DRIVER_DESCRIPTION').subscribe((res: string) => {
            this.moveToDriverDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.BEFORE').subscribe((res: string) => {
            this.beforeMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.BEFORE_DESCRIPTION').subscribe((res: string) => {
            this.beforeDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.REMOVE_MODIFICATIONS').subscribe((res: string) => {
            this.removeModificationsMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.REMOVE_MODIFICATIONS_DESCRIPTION').subscribe((res: string) => {
            this.removeModificationsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.HIGH_PRIORITY').subscribe((res: string) => {
            this.hightPriorityMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.HIGH_PRIORITY_DESCRIPTION').subscribe((res: string) => {
            this.hightPriorityDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.FREE_TIME_WINDOW').subscribe((res: string) => {
            this.freeTimeWindowsMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.FREE_TIME_WINDOW_DESCRIPTION').subscribe((res: string) => {
            this.freeTimeWindowsDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MERGE').subscribe((res: string) => {
            this.mergeMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MERGE_DESCRIPTION').subscribe((res: string) => {
            this.mergeDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.DELETE').subscribe((res: string) => {
            this.deleteMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.DELETE_DESCRIPTION').subscribe((res: string) => {
            this.deleteDescriptionMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.MILY').subscribe((res: string) => {
            this.milyMsg = res;
        }));
        this.listen.push(this.translate.get('PROJECT_PROBLEM_VIEW_DEMO.CHAT').subscribe((res: string) => {
            this.chatMsg = res;
        }));

        this.thinkDescriptionHtml = '<span>' + this.thinkDescription1Msg + '</span><div>' + this.thinkDescription2Msg + '</div>';
        this.thinkDescriptionHtml += '<div class="list-container"><ul class="decimal-list">';
        this.thinkDescriptionHtml += '<li class="decimal-list">' + this.thinkDescriptionChoice1Msg + '</li>';
        this.thinkDescriptionHtml += '<li class="decimal-list">' + this.thinkDescriptionChoice2Msg + '</li>';
        this.thinkDescriptionHtml += '<li class="decimal-list">' + this.thinkDescriptionChoice3Msg + '</li></ul></div>';

        this.multiSelectPointsDescriptionHtml = '<div>' + this.multiSelectPointsDescriptionMsg + '</div>';
        this.multiSelectPointsDescriptionHtml += '<div class="list-container"><ul class="decimal-list">';
        this.multiSelectPointsDescriptionHtml += '<li class="decimal-list">' + this.multiSelectPointsDescriptionChoice1Msg + '</li>';
        this.multiSelectPointsDescriptionHtml += '<li class="decimal-list">' + this.multiSelectPointsDescriptionChoice2Msg + '</li></ul></div>';
        this.defaultButtons = [
            {
                classes: 'shepherd-button-secondary',
                text: this.backMsg,
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: this.nextMsg,
                type: 'next'
            }
        ];
        this.goButtons = [
            {
                classes: 'shepherd-button-primary',
                text: this.goMsg,
                type: 'next'
            }
        ];
        this.firstButtons = [
            {
                classes: 'shepherd-button-primary',
                text: this.nextMsg,
                type: 'next'
            }
        ];
        this.lastButtons = [
            {
                classes: 'shepherd-button-secondary',
                text: this.backMsg,
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: this.finishMsg,
                type: 'next'
            }
        ];
    }

    ngAfterViewInit() {
        const self = this;
        const defaultStepOptions = {
            classes: '',
            scrollTo: false,
            cancelIcon: {
                enabled: true
            }
        };

        this.generalButtonsGuide.defaultStepOptions = defaultStepOptions;
        this.generalButtonsGuide.modal = true;
        this.generalButtonsGuide.confirmCancel = false;

        this.markerIconsGuide.defaultStepOptions = defaultStepOptions;
        this.markerIconsGuide.modal = true;
        this.markerIconsGuide.confirmCancel = false;

        this.rightClickGuide.defaultStepOptions = defaultStepOptions;
        this.rightClickGuide.modal = true;
        this.rightClickGuide.confirmCancel = false;
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
