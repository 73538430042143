import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild } from '@angular/core';
import { PriceListsComponent } from '@app/settings/price-lists/price-lists.component';
import { SettingsSurchargesComponent } from '@app/settings/surcharges/surcharges.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { RevaluationsComponent } from '@app/settings/revaluations/revaluations.component';

@Component({
    selector: 'collaborator-view-charges',
    templateUrl: './collaborator-view-charges.component.html',
    styleUrls: ['./collaborator-view-charges.component.scss']
})
export class CollaboratorViewChargesComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(PriceListsComponent, { static: false }) PriceListsComponent: PriceListsComponent;
    @ViewChild(DiscountsComponent, { static: false }) discountsComponent: DiscountsComponent;
    @ViewChild(SettingsSurchargesComponent, { static: false }) settingsSurchargesComponent: SettingsSurchargesComponent;
    @ViewChild(RevaluationsComponent, { static: false }) revaluationsComponent: RevaluationsComponent;
    @Input() collaboratorId: number;
    activeGrid: string;

    constructor(    ){    }

    setActiveGrid (grid:string){
        this.activeGrid = grid;
    }

    ngAfterViewInit(): void {}
    ngOnInit() {
        this.setActiveGrid('price-lists');
    }
    ngOnDestroy() {}
}
