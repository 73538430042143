import { HttpUtils } from './../../utils/http-utils';
import { LoaderService } from './../../services/loader.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '@app/auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

    listen = [];

    companyName;
    usersInitial = '';
    activePageTitle;
    currentActivePage;
    currentLang;
    navVisible = false;
    isAuthenticated = false;

    soonMsg = '';

    constructor(
        public authService: AuthService,
        public globals: Globals,
        public translate: TranslateService,
        public router: Router,
        public genericService: GenericService,
        public loaderService: LoaderService,
        public httpUtils: HttpUtils
    ) {
    }

    showHoverBubble(event) {
        document.getElementById('hover-bubble').style.left = document.getElementById(event.target.id).getBoundingClientRect().left + 12 + 'px';
        if (event.target.id == 'settingsButton') {
            document.getElementById('bubble').style.right = '-84px';
        } else {
            document.getElementById('bubble').style.right = '-128px';
        }
        document.getElementById('hover-bubble').style.display = 'block';
    }

    hideHoverBubble() {
        document.getElementById('hover-bubble').style.display = 'none';
    }

    updateAuth() {
        this.isAuthenticated = this.authService.isAuthenticated();
    }

    langChanged() {
        this.translate.use(this.currentLang);
        this.globals.currentLang = this.currentLang;
        localStorage.setItem('lang', this.currentLang);
    }

    toggleAccountDropDown() {
        const dropDown = document.getElementById('account-drop-down');
        dropDown.classList.toggle('hidden');
    }

    alertNotExist() {
        alert(this.soonMsg);
    }

    navigateToMembers() {
        this.router.navigate(['members']);
    }

    calculateNameInitials(name) {
        const array = name.split(' ');
        let initials = '';
        if (array.length) {
            initials = array[0].charAt(0);
        }
        return initials;
    }

    partnerViewRoute() {
        this.genericService.openPartnerView();
    }

    getTranslations() {
        this.listen.push(this.translate.get('ALERTS.SOON').subscribe((res: string) => { this.soonMsg = res; }));
    }

    // updates page title on hover
    ngAfterViewInit() {
        const self = this;
        const navLinks = document.querySelectorAll('.nav-link');
        navLinks.forEach(link => {
            link.addEventListener('mouseover', function (event) {
                if (link.getAttribute('page-title')) {
                    self.translate.get('NAVBAR.' + link.getAttribute('page-title')).subscribe((res: string) => {
                        self.activePageTitle = res;
                    });
                }
            });
            link.addEventListener('mouseout', function (event) {
                self.activePageTitle = self.currentActivePage;
            });
        });
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
        this.companyName = this.globals.companyName;
        const elems = document.querySelectorAll('select');
        const instances = M.FormSelect.init(elems);
        this.currentLang = this.translate.currentLang;
        this.usersInitial = this.calculateNameInitials(this.globals.userName);
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
