import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-client-portal-chat-modal-dummy',
  templateUrl: './client-portal-chat-modal-dummy.component.html',
  styleUrls: ['./client-portal-chat-modal-dummy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientPortalChatModalDummyComponent implements OnInit {

  @ViewChild('modal', { static: false }) public modal: ElementRef;
  @ViewChild('modalButtons', { static: false }) public modalButtons: ElementRef;

  constructor(
    // public router: Router,
  ) { }

  message = '';
  activeChannelId;

  @Input() channels: Array<any>;
  @Input() messages: Array<any>;
  @Input() activeChannelInfo: Object;
  @Input() activeFilter: String;
  @Input() session: any;
  @Input() isOpponentTypingMessage: Boolean;
  @Input() isInAudioCall: Boolean;
  @Input() isInVideoCall: Boolean;
  @Input() isBeingCalledVideo: Boolean;
  @Input() isBeingCalledAudio: Boolean;
  @Input() isMuteVideo: Boolean;
  @Input() isMuteMicrophone: Boolean;
  @Input() isOpponentVideoMuted: Boolean;
  @Input() callOpponentId: Number;
  @Input() avatarBase64: String;
  @Input() chatMockData: ChatData;
  @Input() callerName: String;
  @Input() selectedColor: String;

  // @Input() mode: String;
  // @Input() convertToNextDayDelivery: Boolean;
  // @Input() isClickedOnce: Boolean;
  // @Input() buttonName: String;
  // @Input() title: String;

  searchString: String = '';

  @Output() searchStringChangedEvent = new EventEmitter<String>();
  @Output() sendMessageEvent = new EventEmitter<String>();
  // @Output() getAndShowActiveChannelInfoEvent = new EventEmitter<string>();
  @Output() toggleEmojiPickerEvent = new EventEmitter<string>();
  @Output() closeEmojiPickerEvent = new EventEmitter<string>();
  @Output() filterChannelsEvent = new EventEmitter<string>();
  @Output() isTypingUpdateEvent = new EventEmitter<string>();
  @Output() toggleChatModalEvent = new EventEmitter<string>();
  @Output() closeModalEvent = new EventEmitter<string>();
  @Output() acceptCallEvent = new EventEmitter<string>();
  @Output() rejectCallEvent = new EventEmitter<string>();
  @Output() endCallEvent = new EventEmitter<string>();
  @Output() muteVideoEvent = new EventEmitter<string>();
  @Output() muteMicrophoneEvent = new EventEmitter<string>();

  toggleChatModal() {
    this.toggleChatModalEvent.emit('');
  }

  closeModal() {
    this.closeModalEvent.emit('');
  }

  search($event) {
    // this.searchTextChanged.next($event);
    this.searchStringChangedEvent.emit(this.searchString);
  }

  sendMessage() {
    this.sendMessageEvent.emit();
  }

  // getAndShowActiveChannelInfo(orderIndex) {
  //   this.getAndShowActiveChannelInfoEvent.emit(orderIndex);
  // }

  toggleEmojiPicker() {
    this.toggleEmojiPickerEvent.emit();
  }

  closeEmojiPicker() {
    this.closeEmojiPickerEvent.emit();
  }

  filterChannels(filterType) {
    this.filterChannelsEvent.emit(filterType);
  }

  isTypingUpdate() {
    this.isTypingUpdateEvent.emit();
  }

  acceptCall() {
    this.acceptCallEvent.emit();
  }

  rejectCall() {
    this.rejectCallEvent.emit();
  }

  endCall() {
    this.endCallEvent.emit();
  }

  muteVideo() {
    this.muteVideoEvent.emit();
  }

  muteMicrophone() {
    this.muteMicrophoneEvent.emit();
  }

  ngOnInit() {
  }

}

interface ChatData {
  username: String,
  userId: Number,
  opponentId: Number,
  opponentName: String,
  opponentInitials: String;
  dialogId: String,
  avatarBase64: String,
}