// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lm-spinner {
  position: absolute;
  left: 50%;
  top: 45%;
  z-index: -1;
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%, 0% {
    stroke: #01a5e2;
  }
  40% {
    stroke: #1dd1a1;
  }
  66% {
    stroke: #c436d8;
  }
  80%, 90% {
    stroke: #ff9f43;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYWRpbmcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBQTtFQUNBLFNBQUE7RUFDQSxRQUFBO0VBQ0EsV0FBQTtBQUNGOztBQUVBO0VBQ0Usd0hBQUE7QUFDRjs7QUFFQTtFQUNFO0lBRUUsZUFBQTtFQUFGO0VBRUE7SUFDRSxlQUFBO0VBQUY7RUFFQTtJQUNFLGVBQUE7RUFBRjtFQUVBO0lBRUUsZUFBQTtFQURGO0FBQ0YiLCJmaWxlIjoibG9hZGluZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5sbS1zcGlubmVyIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICBsZWZ0OiA1MCU7XG4gIHRvcDogNDUlO1xuICB6LWluZGV4OiAtMTtcbn1cblxuOmhvc3QgOjpuZy1kZWVwIC5jdXN0b20tc3Bpbm5lciAucC1wcm9ncmVzcy1zcGlubmVyLWNpcmNsZSB7XG4gIGFuaW1hdGlvbjogY3VzdG9tLXByb2dyZXNzLXNwaW5uZXItZGFzaCAxLjVzIGVhc2UtaW4tb3V0IGluZmluaXRlLCBjdXN0b20tcHJvZ3Jlc3Mtc3Bpbm5lci1jb2xvciA2cyBlYXNlLWluLW91dCBpbmZpbml0ZTtcbn1cblxuQGtleWZyYW1lcyBjdXN0b20tcHJvZ3Jlc3Mtc3Bpbm5lci1jb2xvciB7XG4gIDEwMCUsXG4gIDAlIHtcbiAgICBzdHJva2U6ICMwMWE1ZTI7XG4gIH1cbiAgNDAlIHtcbiAgICBzdHJva2U6ICMxZGQxYTE7XG4gIH1cbiAgNjYlIHtcbiAgICBzdHJva2U6ICNjNDM2ZDg7XG4gIH1cbiAgODAlLFxuICA5MCUge1xuICAgIHN0cm9rZTogI2ZmOWY0MztcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/misc/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;AACF;;AAEA;EACE,wHAAA;AACF;;AAEA;EACE;IAEE,eAAA;EAAF;EAEA;IACE,eAAA;EAAF;EAEA;IACE,eAAA;EAAF;EAEA;IAEE,eAAA;EADF;AACF;AACA,gkCAAgkC","sourcesContent":[".lm-spinner {\n  position: absolute;\n  left: 50%;\n  top: 45%;\n  z-index: -1;\n}\n\n:host ::ng-deep .custom-spinner .p-progress-spinner-circle {\n  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;\n}\n\n@keyframes custom-progress-spinner-color {\n  100%,\n  0% {\n    stroke: #01a5e2;\n  }\n  40% {\n    stroke: #1dd1a1;\n  }\n  66% {\n    stroke: #c436d8;\n  }\n  80%,\n  90% {\n    stroke: #ff9f43;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
