import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { AddButtonBaseConfig as abbc } from '@app/model/buttons-model';
import { DiscountsViewModelService } from './discounts-viewmodel.service';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { DiscountModalComponent } from './discount-modal/discount-modal.component';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PriceListModalComponent } from '@app/settings/price-lists/new-price-list-modal/new-price-list-modal.component';
import { ISettings_Price_Lists_Dto } from '@app/api/models/settings-price-lists-dto';

@Component({
    selector: 'discounts',
    template: `
        <div class="grid">
            <div class="col-12 lm-pdr30">
                <lm-table-list [cols]="cols" [VM]="VM" [headerBtn]="button" [title]="title"></lm-table-list>
            </div>
        </div>
    `,
    providers: [DiscountsViewModelService, LmModelProxyService]
})
export class DiscountsComponent implements OnInit, OnDestroy {
    @Input() title: string;
    showDiscountModal = DiscountModalComponent;
    priceListModal = PriceListModalComponent;
    cols: LmTableBaseColumnDefs[];
    button: LmButtonConfig;

    translations;
    listen = [];

    constructor(
        public VM: DiscountsViewModelService,
        private notificationSvc: LmNotificationService, 
        private _injector: Injector,
        private activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        public globals: Globals
    ) {
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            if (params['id']) this.VM.collaboratorId = params['id'];
        }));
        this.button = {...abbc, command: this.showModal};
        this.cols = [{header: '', field: 'nameAndType'}];
        
        this.translate.get('DISCOUNTS').pipe(
            tap(response => {
            this.translations = response; 
            this.cols = [
                {header: response['NAME'], field: 'name', cellClass:"lm-tr-table-name-cell"},
                {header: response['PERCENTAGE'], field: 'percentage', percent: true, cellClass:"bold-letters lm-tr-table-percentage-cell"},
                {header: response['PRICE_LISTS'], field: 'priceLists', cellClass:"lm-tr-table-pricelist-cell"}
            ];
            })
        ).subscribe((res: string) => {
            this.VM.modalConfig.data['translations'] = res;
        })
    }

    private showPriceListModal = (priceList: ISettings_Price_Lists_Dto) => {
        this.notificationSvc.showDialog<boolean>({ 
            componentType: this.priceListModal,   
            injector: this._injector, 
            data: {
                VM: this.VM, 
                globals: this.globals, 
                item: priceList,
                translations: this.translations,
            } 
        })
        .subscribe();
    }

    private showModal = () => {
        this.notificationSvc.showDialog<boolean>({ 
            componentType: this.showDiscountModal,   
            injector: this._injector, 
            data: {
                VM: this.VM, 
                globals: this.globals, 
                item: {...this.VM.newItem()},
                translations: this.translations,
            } 
        })
        .subscribe();
    }

    ngOnInit() {}
    ngOnDestroy() {
      this.listen.forEach(element => {
        element.unsubscribe();
      });
    }
}
