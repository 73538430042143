import { take } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { ChartService } from '@app/services/chart.service';
import { DashboardRatingDummyComponent } from './dashboard-rating-dummy/dashboard-rating-dummy.component';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-dashboard-rating',
    templateUrl: './dashboard-rating.component.html'
})

export class DashboardRatingComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardRatingDummyComponent, { static: false }) dashboardRatingDummyComponent: DashboardRatingDummyComponent;

    

    listen = [];

    rating = 0;
    showSampleDataText = false;

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        public chartService: ChartService,
        public changeDetectorRef: ChangeDetectorRef,
    ) {
        // this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
        //     this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
        //     this.updateView();
        // }));
    }

    // CHART
    createRatingDoughnut(rating) {
        // dummy data
        let backgroundColor = '#00aeba';
        if (!rating) {
            rating = 3;
            backgroundColor = '#00aeba50';
            this.showSampleDataText = true;
        } else {
            this.showSampleDataText = false
        }
        
        const max = 5;
        if (rating) {
            if (Number.isInteger(rating)) {
                rating = rating > max ? max : rating;
            } else {
                rating = rating > max ? max : rating.toFixed(2);
            }
        } else {
            rating = 0; 
        }
        this.rating = rating;
        this.changeDetectorRef.detectChanges(); // (on-push specific) force-update dom
        let canvas = <HTMLCanvasElement>document.getElementById('rating-chart');
        let ctx = canvas.getContext('2d');
        let ratingChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [rating, max - rating],
                    backgroundColor: [
                        backgroundColor,
                        'rgba(0, 0, 0, 0)',
                    ],
                }]
            },
            options: {
                plugins:{
                    tooltip: {
                        enabled: false
                    }
                },
                // tooltips: { enabled: false },
                hover: { mode: null },
                //cutoutPercentage: 70,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                }
            }
        });
    }

    getTranslations() {
        // this.listen.push(this.translate.get('DASHBOARD.RATING').subscribe((res: string) => { this.ratingLegend = res; }));
    }

    ngOnInit() {
        // this.initRatingChart();

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        // this.listen.forEach(element => {
        //     element.unsubscribe();
        // });
    }
}
