import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { AddButtonBaseConfig as abbc } from '@app/model/buttons-model';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { SettingsFuelSurchargesViewModelService } from './settings-fuel-surcharges-viewmodel.service';
import { FuelSurchargeModalComponent } from './new-fuel-surcharge-modal/new-fuel-surcharge-modal.component';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { SettingsOtherSurchargesViewModelService } from './settings-other-surcharges-viewmodel.service';
import { OtherSurchargeModalComponent } from './new-other-surcharge-modal/new-other-surharge-modal.component';
import { SettingsBasicServicesViewModelService } from '../settings-services/settings-basic-services-viewmodel.service';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';


@Component({
    selector: 'settings-surharges',
    template: `
        <div class="grid">
            <div class="col-6 lm-pdr30">
                <lm-table-list [cols]="fuelSurchargesCols" [VM]="fuelSurchargesVM" [headerBtn]="fuelSurchargesHdrBtn" [title]="fuelSurchargesTitle"></lm-table-list>
            </div>
            <div class="col-6 lm-pdl30">
                <lm-table-list class="lm-table-other-surcharges" [cols]="otherSurchargesCols" [VM]="otherSurchargesVM" [showBttn]="false" [title]="otherSurchargesTitle"></lm-table-list>
            </div>
        </div>
    `,
    providers: [SettingsFuelSurchargesViewModelService, SettingsOtherSurchargesViewModelService, LmModelProxyService, SettingsBasicServicesViewModelService ],
    styles:[`
        :host ::ng-deep .lm-table-other-surcharges .lm-table-action-delete{display: none}
    `]
})
//
export class SettingsSurchargesComponent implements OnInit, OnDestroy {
    @Input() fuelSurchargesTitle: string;
    @Input() otherSurchargesTitle: string;
    fuelSurchargesModal = FuelSurchargeModalComponent;
    otherSurchargesModal = OtherSurchargeModalComponent
    fuelSurchargesCols: LmTableBaseColumnDefs[];
    fuelSurchargesHdrBtn: LmButtonConfig;
    listen;

    otherSurchargesCols: LmTableBaseColumnDefs[];

    constructor(
        public fuelSurchargesVM: SettingsFuelSurchargesViewModelService,
        public otherSurchargesVM: SettingsOtherSurchargesViewModelService,
        private notificationSvc: LmNotificationService, 
        private _injector: Injector,
        public translate: TranslateService,
        public globals: Globals,
        private basicServicesVM: SettingsBasicServicesViewModelService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.listen = this.activatedRoute.params.subscribe(params => {
            if (params['id']) {
                this.fuelSurchargesVM.collaboratorId = params['id'];
                this.otherSurchargesVM.collaboratorId = params['id'];
            }
        });
        this.fuelSurchargesHdrBtn = {...abbc, command: this.showFuelModal}
        this.translate.get('FUEL_SURCHARGES').pipe(
            tap(response => {
                this.fuelSurchargesCols = [
                    {header: response['SERVICE'], field: 'service_names'}, 
                    {header: response['CHARGE'], field: 'value', percent: true, plus: true}
                ];
                this.fuelSurchargesVM.modalConfig.data['translations'] = response;
            })
        ).subscribe();
        this.translate.get('OTHER_SURCHARGES').pipe(
            tap(response => {
                this.otherSurchargesCols = [
                    {header: response['SERVICE'], field: 'name'},
                    {header: response['CHARGE'], field: 'value', percent: true,  plus: true}
                ];
                this.otherSurchargesVM.modalConfig.data['translations'] = response;
            })
        ).subscribe();
        this.translate.get('WARNINGS').pipe(
            tap(response => {
                this.fuelSurchargesVM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
                this.otherSurchargesVM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
            })
        ).subscribe();
    }

    private showFuelModal = () => {
        const newItem = {...this.fuelSurchargesVM.newItem()};
        if (this.fuelSurchargesVM.collaboratorId) {
            newItem.is_collaborator_specific = true;
            newItem.collaborator_id = Number(this.fuelSurchargesVM.collaboratorId);
            newItem.is_generic = false;
        }
        this.notificationSvc.showDialog<boolean>({ 
            componentType: this.fuelSurchargesModal,   
            injector: this._injector, 
            data: {VM: this.fuelSurchargesVM, globals: this.globals, item: newItem} 
        })
        .subscribe();
    }
    

    ngOnInit() { 
        this.basicServicesVM.getRows().subscribe(response => {
            this.fuelSurchargesVM.defaultService = response.items[0];
        });
    }
    ngOnDestroy() { this.listen.unsubscribe(); }
}
