import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth.service';
import { Globals } from '@app/services/globals';
import { HttpUtils } from '@app/utils/http-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'pudo-app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PudoAppNavComponent implements OnInit, AfterViewInit {
    navigationModel;
    userMenuNavigationModel;
    viewTitle = '';
    menuOpen = false;
    menuHover = false;
    userAuthenticated: boolean;

    constructor(
        public router: Router, 
        public httpUtils: HttpUtils, 
        public globals: Globals,
        public authSvc: AuthService
    ){
        const membersLink = {icon: 'icon-person', routerLink: 'pudo-app/pudo-office-members', id:'pudo-navlink-members', viewTitle:'MEMBERS', tag: 'MEMBERS'};
        const settingsLink = {icon: 'pi-cog', routerLink: 'pudo-app/pudo-settings', id:'pudo-navlink-settings', viewTitle:'SETTINGS', tag: 'SETTINGS'};

        this.userAuthenticated = authSvc.isAuthenticated();

        this.navigationModel = [
            {icon: 'icon-dashboard', routerLink: 'pudo-app/pudo-dashboard', id:'pudo-navlink-dashboard', viewTitle:'DASHBOARD', tag: 'DASHBOARD'},
            {icon: 'icon-project', routerLink: 'pudo-app/pudo-points-map', id:'pudo-navlink-points-map', viewTitle:'PUDO PROJECTS', tag: 'PROJECTS'},
            {icon: 'icon-pudo-store', routerLink: 'pudo-app/pudo-points', id:'pudo-navlink-points', viewTitle:'PUDO POINTS', tag: 'POINTS'},
            {icon: 'icon-pin', routerLink: 'pudo-app/pudo-shipments', id:'pudo-navlink-shipments', viewTitle:'PUDO SHIPMENTS', tag: 'SHIPMENTS'},
            {icon: 'icon-money-bag', routerLink: 'pudo-app/pudo-profit', id:'pudo-navlink-profit', viewTitle:'PUDO', tag: 'PUDO'},
            {icon: 'icon-invoice', routerLink: 'pudo-app/pudo-invoices', id:'pudo-navlink-invoices', viewTitle:'PUDO', tag: 'PUDO'}
        ];
        this.userMenuNavigationModel = this.globals.isPudoAdmin ? [membersLink, settingsLink] : [settingsLink];

        // this.quickAccessModel = {

        // }
      }

    ngAfterViewInit() {}

    ngOnInit() {
        this.router.events.pipe(untilDestroyed(this)).subscribe(res => {
            const title = (<any>res).snapshot?.data?.title; 
            if(title) this.viewTitle = title; 
        })
    }

    ngOnDestroy() {}
}
