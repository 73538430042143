import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from "@angular/forms";
import { SliderModule } from "primeng/slider";
import { LmInputBase } from "../input-base";
import { LmTextIconStyle } from "@app/model/icons-model";

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LmSliderComponent),
  multi: true,
};
const LM_INPUT_BASE = {
  provide: LmInputBase,
  useExisting: forwardRef(() => LmSliderComponent),
};

@Component({
  selector: "lm-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LmSliderComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy{
  static nextId = 0;
  searchType = "string";

  @HostBinding() id = `lm-slider-${LmSliderComponent.nextId++}`;

  @ViewChild(NgModel) model: NgModel;

  @Input() tooltip?: string;
  @Input() tooltipEvent = "focus";
  @Input() tooltipPosition = "top";
  @Input() startText = "";
  @Input() endText = "";
  @Input() isPreviewVisible = false;
  @Input() iconClass = "";
  @Input() iconSvgClass = "";
  @Input() iconSvg?: string;
  @Input() iconSize?: string;
  @Input() iconColor?: string;
  @Input() inputId?: string;
  @Input() ariaLabelledBy?: string;
  @Input() ariaLabel?: string;
  @Input() style?: object;
  @Input() styleClass?: string;
  @Input() labelStyleClass?: string;
  @Input() animate = false;
  @Input() min = 0;
  @Input() max = 100;
  @Input() orientation = "horizontal";
  @Input() customIconName = "";
  @Input() step = 1;
  @Input() range = false;
  @Input() inlineRangeLabels;

  @Input() rangeDescription;
  @Input() rangeDescriptionStartText;
  @Input() rangeDescriptionStartValue;
  @Input() rangeDescriptionSeparator;
  @Input() rangeDescriptionEndText;
  @Input() rangeDescriptionEndValue;

  @Input() slideEnd: (e) => number | number[];
  @Input() slideChange: (e) => void | unknown;

  iconStyle: LmTextIconStyle;
  iconSvgPath: string;
  private svgsPath = "assets/icons/icons.svg#";

  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      "font-size": this.iconSize || "1.4rem",
      color: this.iconColor || "#d3d3d3",
    };
  }

  onSlideEnd(e){
    if(this.slideEnd) this.slideEnd(e)
  }

  onSlideChange(e){
    if(this.slideChange) this.slideChange(e)
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.iconSvg) this.iconSvgPath = this.svgsPath + this.iconSvg;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SliderModule],
  exports: [LmSliderComponent],
  declarations: [LmSliderComponent],
})
export class LMSliderModule {}
