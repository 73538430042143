/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ISettings_TR_AreaDto } from '../models/settings-transportation-regions-dto';

@Injectable({
  providedIn: 'root'
})
export class LmSettingsAddonServicesService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

    // ------------------------- ADD DTOs ---------------------
  
    static readonly settingsAddonServicesPath = '/api/v1/price-list/addon-services';
    
    // GET ALL
    apiSettingsAddonServicesAllGet$Response(): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsAddonServicesService.settingsAddonServicesPath, 'get');

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsAddonServicesAllGet(): Observable<Array<any>> {
        return this.apiSettingsAddonServicesAllGet$Response().pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // GET BY ID
    static readonly settingsBasicServicesSingleGetPath = '/api/v1/price-list/addon-services/{id}';

    apiSettingsAddonServicesSingleGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsAddonServicesService.settingsBasicServicesSingleGetPath, 'get');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsAddonServicesSingleGet(params?: { id?: string }): Observable<Array<any>> {
        return this.apiSettingsAddonServicesSingleGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


    // DELETE
    static readonly settingsBasicServicesSingleDeletePath = '/api/v1/price-list/addon-services/{id}';

    apiSettingsAddonServicesSingleDelete$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsAddonServicesService.settingsBasicServicesSingleDeletePath, 'delete');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsAddonServicesSingleDelete(params?: { id?: string }): Observable<Array<any>> {
        return this.apiSettingsAddonServicesSingleDelete$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // POST 
    apiSettingsAddonServicesPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmSettingsAddonServicesService.settingsAddonServicesPath, 'post');
      if (params) rb.body(params.body, 'application/*+json');

      return this.http
        .request(
          rb.build({
            responseType: 'json',
            accept: 'application/json'
          })
        )
        .pipe(
          filter((r: any) => r instanceof HttpResponse),
          map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<any>>;
          })
        );
    }
  

    apiSettingsAddonServicesPost(params?: { body?: any }): Observable<Array<any>> {
      return this.apiSettingsAddonServicesPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

}
