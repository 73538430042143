import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
    selector: 'app-dashboard-rating-dummy',
    templateUrl: './dashboard-rating-dummy.component.html',
    styleUrls: ['./dashboard-rating-dummy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardRatingDummyComponent implements OnInit, AfterViewInit {

    // @Output() goToPreviousPageEvent = new EventEmitter<string>();

    @Input() rating: Number;
    @Input() showSampleDataText: Boolean;

    constructor() { }

    // goToNextPage() {
    //     this.goToNextPageEvent.emit();
    // }

    ngOnInit() { }

    ngAfterViewInit() { }
}
