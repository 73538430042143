import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.scss']
})
export class PhotoModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
  @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;

  isClickedOnce = false;
  photoSrc;

  constructor(
    public globals: Globals,
  ) { }

  loadPhoto(base64) {
    this.photoSrc = base64;
    this.toggleModal();
  }

  toggleModal() {
    this.modal.nativeElement.classList.toggle('closed');
    this.modal.nativeElement.classList.toggle('open');
    this.modalBackground.nativeElement.classList.toggle('hidden');
  }

  ngOnDestroy() { }

  ngOnInit() { }

  loadProject() { }

}
