// Core
import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';

import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from "@sentry/angular";
// import { BrowserTracing } from "@sentry/tracing";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, Injector } from '@angular/core';
import { Router } from "@angular/router";
import { NgSelectModule } from '@ng-select/ng-select';
import { NouisliderModule } from 'ng2-nouislider';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxUploaderModule } from 'ngx-uploader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
// interceptors
import { AuthInterceptor } from '@app-interceptors/auth.interceptor';
import { CsrfTokenInterceptor } from '@app-interceptors/csrftoken.interceptor';
import { JsonWebTokenInterceptor } from '@app-interceptors/json-web-token.interceptor';
// directives
import { DynamicFieldDirective } from './formTemplates/dynamicField/dynamicField.directive';
// globals
import { Globals } from './services/globals';
// app components
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/routing/app-routing.module';

//VERONA LAYOUT
// import { AppLayoutModule } from './layout/app.layout.module';
// replace later with lm-dialog
import { DialogModule } from 'primeng/dialog';


import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { DashboardDummyComponent } from '@app/dashboard/dashboard-dummy/dashboard-dummy.component';
import { DashboardActivityComponent } from '@app/dashboard/dashboard-views/dashboard-activity/dashboard-activity.component';
import { DashboardActivityDummyComponent } from '@app/dashboard/dashboard-views/dashboard-activity/dashboard-activity-dummy/dashboard-activity-dummy.component';
import { DashboardShipmentsComponent } from '@app/dashboard/dashboard-views/dashboard-shipments/dashboard-shipments.component';
import { DashboardShipmentsDummyComponent } from '@app/dashboard/dashboard-views/dashboard-shipments/dashboard-shipments-dummy/dashboard-shipments-dummy.component';
import { DashboardProductivityComponent } from '@app/dashboard/dashboard-views/dashboard-productivity/dashboard-productivity.component';
import { DashboardProductivityDummyComponent } from '@app/dashboard/dashboard-views/dashboard-productivity/dashboard-productivity-dummy/dashboard-productivity-dummy.component';
import { DashboardDriversComponent } from '@app/dashboard/dashboard-views/dashboard-drivers/dashboard-drivers.component';
import { DashboardDriversDummyComponent } from '@app/dashboard/dashboard-views/dashboard-drivers/dashboard-drivers-dummy/dashboard-drivers-dummy.component';
import { DashboardRatingComponent } from '@app/dashboard/dashboard-views/dashboard-rating/dashboard-rating.component';
import { DashboardRatingDummyComponent } from '@app/dashboard/dashboard-views/dashboard-rating/dashboard-rating-dummy/dashboard-rating-dummy.component';
import { DashboardConsignorsComponent } from '@app/dashboard/dashboard-views/dashboard-consignors/dashboard-consignors.component';
import { DashboardConsignorsDummyComponent } from '@app/dashboard/dashboard-views/dashboard-consignors/dashboard-consignors-dummy/dashboard-consignors-dummy.component';
import { DashboardProjectsLiveComponent } from '@app/dashboard/dashboard-views/dashboard-projects-live/dashboard-projects-live.component';
import { DashboardProjectsLiveDummyComponent } from '@app/dashboard/dashboard-views/dashboard-projects-live/dashboard-projects-live-dummy/dashboard-projects-live-dummy.component';
import { ProgressTimelineComponent } from '@app/progress-timeline/progress-timeline.component';
import { ProgressTimelineFoodComponent } from '@app/progress-timeline-food/progress-timeline-food.component';
import { LoginComponent } from '@app/forms/login/login.component';
import { NavbarComponent } from '@app/navs/navbar/navbar.component';
import { CollaboratorNavbarComponent } from '@app/navs/collaborator-navbar/collaborator-navbar.component';
import { VoucherPrintComponent } from '@app/prints/voucher-print/voucher-print.component';
import { ImageVoucherPrintComponent } from '@app/prints/image-voucher-print/image-voucher-print.component';
import { SecureComponent } from '@app/secure/secure.component';
import { FormInputComponent } from '@app/formTemplates/formInput/formInput.component';
import { FormSelectComponent } from '@app/formTemplates/formSelect/formSelect.component';
import { FormButtonComponent } from '@app/formTemplates/formButton/formButton.component';
import { FormCheckboxComponent } from './formTemplates/formCheckbox/formCheckbox.component';
import { StopFormComponent } from '@app/forms/stop-form/stop-form.component';
import { VoucherFormComponent } from '@app/forms/voucher-form/voucher-form.component';
import { CollaboratorMapComponent } from '@app/forms/voucher-form/collaborator-map/collaborator-map.component';
import { CustomerCollaboratorMapComponent } from '@app/forms/customer-collaborators-form/customer-collaborator-map/customer-collaborator-map.component';
import { RecipientMapComponent } from '@app/forms/voucher-form/recipient-map/recipient-map.component';
import { VehicleFormComponent } from '@app/forms/vehicle-form/vehicle-form.component';
import { DriverFormComponent } from '@app/forms/driver-form/driver-form.component';
import { MemberFormComponent } from '@app/forms/member-form/member-form.component';
import { CustomerFormComponent } from '@app/forms/customer-form/customer-form.component';
import { CustomerCollaboratorsFormComponent } from '@app/forms/customer-collaborators-form/customer-collaborators-form.component';
import { PartnerFormComponent } from '@app/forms/partner-form/partner-form.component';
import { DriversModalComponent } from './drivers/drivers-modal/drivers-modal.component'
import { DriversGridComponent } from '@app/grids/drivers-grid/drivers-grid.component';
import { DriversGridGuidedTourComponent } from '@app/grids/drivers-grid/drivers-grid-guided-tour/drivers-grid-guided-tour.component';
import { VehicleGridComponent } from './grids/vehicle-grid/vehicle-grid.component';
import { CustomersGridComponent } from './grids/customers-grid/customers-grid.component';
import { CustomersGridGuidedTourComponent } from './grids/customers-grid/customers-grid-guided-tour/customers-grid-guided-tour.component';
import { CustomerInvoicesGridComponent } from './grids/customer-invoices-grid/customer-invoices-grid.component';
import { CollaboratorInvoicesGridComponent } from './grids/collaborator-invoices-grid/collaborator-invoices-grid.component';
import { CustomerPaymentsGridComponent } from './grids/customer-payments-grid/customer-payments-grid.component';
import { CollaboratorPaymentsGridComponent } from './grids/collaborator-payments-grid/collaborator-payments-grid.component';
import { PartnerSettingsComponent } from './grids/partner-settings/partner-settings.component';
import { CollaboratorVouchersGridComponent } from './grids/collaborator-vouchers-grid/collaborator-vouchers-grid.component';
import { CustomerVouchersPrintGridComponent } from './grids/customer-vouchers-print-grid/customer-vouchers-print-grid.component';
import { CustomerCollaboratorsGridComponent } from './grids/customer-collaborators-grid/customer-collaborators-grid.component';
import { PartnersGridComponent } from './grids/partners-grid/partners-grid.component';
import { TeamMemberGridComponent } from './grids/team-member-grid/team-member-grid.component';
import { CollaboratorOverviewComponent } from './collaborator-overview/collaborator-overview.component';;
import { ProjectViewComponent } from './project-view/project-view.component';
import { ProjectProblemRoutingSettingsModalComponent } from './project-view/project-problem-routing-settings-modal/project-problem-routing-settings-modal.component';
import { ProjectViewGuidedTourComponent } from './project-view/project-view-guided-tour/project-view-guided-tour.component';
import { StopsModalGridComponent } from './grids/stops-modal-grid/stops-modal-grid.component';
import { MapComponent } from './map/map.component';
import { MapDummyComponent } from './map/map-dummy/map-dummy.component';
import { Map2Component } from './map2/map.component';
import { MapBeTestingComponent } from './map-be-testing/map-be-testing.component';
import { DriversModalGridComponent } from './grids/drivers-modal-grid/drivers-modal-grid.component';
import { VehicleSelectEditorComponent } from './grids/drivers-modal-grid/vehicle-select-editor/vehicle-select-editor.component';
import { PaymentStatusSelectEditorComponent } from './grids/grid-editors/payment-status-select-editor/payment-status-select-editor.component';
import { CalendarModalComponent } from './modals/calendar-modal/calendar-modal.component';
import { SignatureModalComponent } from './modals/signature-modal/signature-modal.component';
import { PhotoModalComponent } from './modals/photo-modal/photo-modal.component';
import { RightFormModalComponent } from './modals/right-form-modal/right-form-modal.component';
import { ProjectProblemModalComponent } from './modals/project-problem-modal/project-problem-modal.component';
import { ProjectProblemModalDummyComponent } from './modals/project-problem-modal/project-problem-modal-dummy/project-problem-modal-dummy.component';
import { ProjectModalComponent } from './modals/project-modal/project-modal.component';
import { MoveShipmentModalComponent } from './modals/move-shipment-modal/move-shipment-modal.component';
import { PortalIssuesModalComponent } from './modals/portal-issues-modal/portal-issues-modal.component';
import { WizardComponent } from './wizard/wizard.component';
import { WizardWelcomeComponent } from './wizard/wizard-welcome/wizard-welcome.component';
import { ProjectsGridComponent } from './grids/projects-grid/projects-grid.component';
import { ProjectsGridGuidedTourComponent } from './grids/projects-grid/projects-grid-guided-tour/projects-grid-guided-tour.component';
import { ProjectProblemsGridComponent } from './grids/project-problems-grid/project-problems-grid.component';
import { ProjectProblemsGridGuidedTourComponent } from './grids/project-problems-grid/project-problems-grid-guided-tour/project-problems-grid-guided-tour.component';
import { WizardDepotsComponent } from './wizard/wizard-depots/wizard-depots.component';
import { WizardDriverSettingsComponent } from './wizard/wizard-driver-settings/wizard-driver-settings.component';
import { WizardStopSettingsComponent } from './wizard/wizard-stop-settings/wizard-stop-settings.component';
import { WizardRouteSettingsComponent } from './wizard/wizard-route-settings/wizard-route-settings.component';
import { WizardSettingsComponent } from './wizard/wizard-settings/wizard-settings.component';
import { WizardVehiclesComponent } from './wizard/wizard-vehicles/wizard-vehicles.component';
import { WizardDriversComponent } from './wizard/wizard-drivers/wizard-drivers.component';
import { WizardFinishComponent } from './wizard/wizard-finish/wizard-finish.component';
import { DeliveryFormComponent } from './forms/delivery-form/delivery-form.component';
import { ChatModalComponent } from './modals/chat-modal/chat-modal.component';
import { ChatModalDummyComponent } from './modals/chat-modal/chat-modal-dummy/chat-modal-dummy.component';
import { ClientPortalChatModalComponent } from './client-portal/client-portal-chat-modal/client-portal-chat-modal.component';
import { ClientPortalChatModalDummyComponent } from './client-portal/client-portal-chat-modal/client-portal-chat-modal-dummy/client-portal-chat-modal-dummy.component';
import { StopModalComponent } from './modals/stop-modal/stop-modal.component';
import { StopFormModalComponent } from './modals/stop-form-modal/stop-form-modal.component';
import { VoucherFormModalComponent } from './modals/voucher-form-modal/voucher-form-modal.component';
import { SettingsComponent } from './settings/settings.component';
import { ClientPortalComponent } from './client-portal/client-portal.component';
import { PortalAddressMapComponent } from './client-portal/portal-address-map/portal-address-map.component';
import { ClientNavbarComponent } from './navs/client-navbar/client-navbar.component';
import { DriverSwapSelectEditorComponent } from './grids/drivers-modal-grid/driver-swap-select-editor/driver-swap-select-editor.component';
import { OverviewMapComponent } from './overview-map/overview-map.component';
import { StopsGridComponent } from './grids/stops-grid/stops-grid.component';
import { PartnerShipmentsGridComponent } from './grids/partner-shipments-grid/partner-shipments-grid.component';
import { NewShipmentsGridComponent } from './grids/new-shipments-grid/new-shipments-grid.component';
import { HelperModalComponent } from './modals/helper-modal/helper-modal.component';
import { ImporterModalComponent } from './modals/importer-modal/importer-modal.component';
import { RulesModalComponent } from './modals/rules-modal/rules-modal.component';
import { MapRulesModalComponent } from './modals/rules-modal/map-rules-modal/map-rules-modal.component';
import { RulesGridComponent } from './grids/rules-grid/rules-grid.component';
import { DepotsGridComponent } from './grids/depots-grid/depots-grid.component';
import { StopPointHistoryGridComponent } from './grids/stop-point-history-grid/stop-point-history-grid.component';
import { ShipmentsHistoryGridComponent } from './grids/shipments-history-grid/shipments-history-grid.component';
import { DepotModalComponent } from './modals/depot-modal/depot-modal.component';
import { MoveStopsModalComponent } from './modals/move-stops-modal/move-stops-modal.component';
import { LoadStopsModalComponent } from './modals/load-stops-modal/load-stops-modal.component';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { TimeWindowModalComponent } from './modals/time-window-modal/time-window-modal.component';
import { TimeWindowGridComponent } from './grids/time-window-grid/time-window-grid.component';
import { AddStopModalComponent } from './modals/add-stop-modal/add-stop-modal.component';
import { AddStopModalDummyComponent } from './modals/add-stop-modal/add-stop-modal-dummy/add-stop-modal-dummy.component';
import { AddStopGridComponent } from './grids/add-stop-grid/add-stop-grid.component';
import { PortalIssuesGridComponent } from './grids/portal-issues-grid/portal-issues-grid.component';
import { DepartureTimeSelectEditorComponent } from './grids/drivers-modal-grid/departure-time-select-editor/departure-time-select-editor/departure-time-select-editor.component';
import { MoveStopsToProblemModalComponent } from './modals/move-stops-to-problem-modal/move-stops-to-problem-modal.component';
import { CustomerModalComponent } from './modals/customer-modal/customer-modal.component';
import { CustomerInvoiceModalComponent } from './modals/customer-invoice-modal/customer-invoice-modal.component';
import { CustomerPaymentModalComponent } from './modals/customer-payment-modal/customer-payment-modal.component';
import { CustomerCollaboratorsModalComponent } from './modals/customer-collaborators-modal/customer-collaborators-modal.component';
import { PartnerModalComponent } from './modals/partner-modal/partner-modal.component';
import { CustomerInvoicePrintComponent } from './prints/customer-invoice-print/customer-invoice-print.component';
import { CustomerPaymentPrintComponent } from './prints/customer-payment-invoice-print/customer-payment-invoice-print.component';
import { CustomerInvoicePrintSingleComponent } from './prints/customer-invoice-print-single/customer-invoice-print-single.component';
import { CustomerPaymentInvoicePrintSingleComponent } from './prints/customer-payment-invoice-print-single/customer-payment-invoice-print-single.component';
import { ReceiptPrintComponent } from './prints/receipt-print/receipt-print.component';
import { WarehouseGridComponent } from './grids/warehouse-grid/warehouse-grid.component';
import { RegisterComponent } from './forms/register/register.component';
import { ForgotPasswordComponent } from './forms/register/forgot-password/forgot-password.component';
import { DemoLoaderComponent } from './forms/register/demo-loader/demo-loader.component';
import { PackagingGridComponent } from './grids/packaging-grid/packaging-grid.component';
import { CancelStopPointModalComponent } from './modals/cancel-stop-point-modal/cancel-stop-point-modal.component';
import { DynamicFieldsGridComponent } from './grids/dynamic-fields-grid/dynamic-fields-grid.component';
import { DynamicFieldModalComponent } from './modals/dynamic-field-modal/dynamic-field-modal.component';
import { PackagingModalComponent } from './modals/packaging-modal/packaging-modal.component';
import { MilyComponent } from './mily/mily.component';
import { RegionalMapComponent } from './forms/driver-form/regional-map/regional-map.component';
import { StopsGridMapComponent } from './grids/stops-grid/stops-grid-map/stops-grid-map.component';
import { ShipmentsHistoryMapComponent } from './grids/shipments-history-grid/shipments-history-map/shipments-history-map.component';
import { DriverModalComponent } from './modals/driver-modal/driver-modal.component';
import { SettingsDriversMapComponent } from './settings/drivers-regions/map/settings-regional-map.component';
import { SettingsDriversRegionsComponent } from './settings/drivers-regions/settings-drivers-regions.component';
import { ClientPortalSettingsComponent } from './settings/client-portal-settings/client-portal-settings.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TimePickerModalComponent } from './modals/time-picker-modal/time-picker-modal.component';
import { FirstOptimizationModalComponent } from './project-view/first-optimization-modal/first-optimization-modal.component';
import { TermsModalComponent } from './modals/terms-modal/terms-modal.component';
import { PrivacyModalComponent } from './modals/privacy-modal/privacy-modal.component';
import { ProjectProblemReportModalComponent } from './modals/project-problem-report-modal/project-problem-report-modal.component';
import { DriverReportModalComponent } from './modals/driver-report-modal/driver-report-modal.component';
import { PaymentsOverviewComponent } from './payments-overview/payments-overview.component';
import { ShipmentsModalComponent } from './modals/shipments-modal/shipments-modal.component';
import { NoteModalComponent } from './client-portal/note-modal/note-modal.component';
import { StopsCustomDateModalComponent } from './modals/stops-custom-date-modal/stops-custom-date-modal.component';
import { DateRangePickerModalComponent } from './modals/date-range-picker-modal/date-range-picker-modal.component';
import { DatePickerModalComponent } from './modals/date-picker-modal/date-picker-modal.component';
import { InvoiceCustomDateModalComponent } from './modals/invoice-custom-date-modal/invoice-custom-date-modal.component';
import { CustomerDateModalComponent } from './modals/customer-date-modal/customer-date-modal.component';
import { CollaboratorViewComponent } from './collaborator-view/collaborator-view.component';
import { CollaboratorViewChargesComponent } from './collaborator-view/collaborator-view-charges/collaborator-view-charges.component';
import { NewShipmentsViewComponent } from './new-shipments-view/new-shipments-view.component';
import { CollaboratorPartnerViewComponent } from './collaborator-partner-view/collaborator-partner-view.component';
import { PartnerViewComponent } from './partner-view/partner-view.component';
import { CollaboratorViewBalanceComponent } from './collaborator-view/collaborator-view-balance/collaborator-view-balance.component';
import { TestComponent } from './test/test.component';
import { IndexComponent } from './index/index.component';
import { PendingChangesGuard } from '@app/pending-changes-guard/pending-changes.guard';
import { take } from 'rxjs/operators';
import { LoaderInterceptor } from '@app-interceptors/loader.interceptor';
import { BarcodesListBoxComponent } from './barcodes-list-box/barcodes-list-box.component';
import { MultipleShipmentsListBoxComponent } from './multiple-shipments-list-box/multiple-shipments-list-box.component';
import { LMCoreModule } from './core/core.module';
import { LmSharedModule } from './shared/shared.module';
import { TransportationRegionsComponent } from './settings/transportation-regions/transportation-regions.component';
import { SettingsServicesComponent } from './settings/settings-services/settings-services.component';
import { PriceListsComponent } from './settings/price-lists/price-lists.component';
import { DiscountsComponent } from './collaborator-view/collaborator-view-charges/discounts/discounts.component';
import { DiscountModalComponent } from './collaborator-view/collaborator-view-charges/discounts/discount-modal/discount-modal.component';
import { SettingsSurchargesComponent } from './settings/surcharges/surcharges.component';
import { RevaluationsComponent } from './settings/revaluations/revaluations.component';
import { TransportationRegionModalComponent } from './settings/transportation-regions/new-transportation-region-modal/new-transportation-region-modal.component';
import { TransportationRegionsMapComponent } from './settings/transportation-regions/transportation-regions-map/transportation-regions-map.component';
import { BasicServiceModalComponent } from './settings/settings-services/new-basic-service-modal/new-basic-service-modal.component';
import { AddonServiceModalComponent } from './settings/settings-services/new-addon-service-modal/new-addon-service-modal.component';
import { FuelSurchargeModalComponent } from './settings/surcharges/new-fuel-surcharge-modal/new-fuel-surcharge-modal.component';
import { PriceListModalComponent } from './settings/price-lists/new-price-list-modal/new-price-list-modal.component';
import { RevaluationModalComponent } from './settings/revaluations/revaluation-modal/revaluation-modal.component';
import { OtherSurchargeModalComponent } from './settings/surcharges/new-other-surcharge-modal/new-other-surharge-modal.component';
import { PudoAppNavComponent } from './pudo-app/nav/nav.component';
import { PudoRegistrationConfirmComponent } from './pudo-app/registration/registration-confirm.component';
import { LmBrowserUtilsService } from './core/services/browser-service';
import { PERIODS_FACTORY, PeriodsFactory } from './core/services/periods-factory';
import { SettingsDepotsModalComponent } from './settings/depots/depots-modal/settings-depots-modal.component';
import { DepotsMapComponent } from './settings/depots/settings-depots-map/settings-depots-map.component';
import { DashboardReportComponent } from './dashboard-report/dashboard-report.component';
import { LmDeliveriesWidgetComponent } from './dashboard-report/hybrid-widgets/deliveries-widget.component';

export function globalFactory(provider: Globals) {
    return () => provider.load();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        DashboardDummyComponent,
        DashboardActivityComponent,
        DashboardActivityDummyComponent,
        DashboardShipmentsComponent,
        DashboardShipmentsDummyComponent,
        DashboardProductivityComponent,
        DashboardProductivityDummyComponent,
        DashboardDriversComponent,
        DashboardDriversDummyComponent,
        DashboardRatingComponent,
        DashboardRatingDummyComponent,
        DashboardConsignorsComponent,
        DashboardConsignorsDummyComponent,
        DashboardProjectsLiveComponent,
        DashboardProjectsLiveDummyComponent,
        ProgressTimelineComponent,
        ProgressTimelineFoodComponent,
        LoginComponent,
        NavbarComponent,
        CollaboratorNavbarComponent,
        SecureComponent,
        FormInputComponent,
        FormSelectComponent,
        FormButtonComponent,
        FormCheckboxComponent,
        DynamicFieldDirective,
        StopFormComponent,
        VoucherPrintComponent,
        ImageVoucherPrintComponent,
        VoucherFormComponent,
        CollaboratorMapComponent,
        CustomerCollaboratorMapComponent,
        RecipientMapComponent,
        VehicleFormComponent,
        DriverFormComponent,
        MemberFormComponent,
        CustomerFormComponent,
        CustomerCollaboratorsFormComponent,
        PartnerFormComponent,
        DriversGridComponent,
        DriversModalComponent,
        DriversGridGuidedTourComponent,
        VehicleGridComponent,
        CustomersGridComponent,
        CustomerInvoicesGridComponent,
        CollaboratorInvoicesGridComponent,
        CustomerPaymentsGridComponent,
        CollaboratorPaymentsGridComponent,
        PartnerSettingsComponent,
        CustomerCollaboratorsGridComponent,
        PartnersGridComponent,
        CollaboratorVouchersGridComponent,
        CustomerVouchersPrintGridComponent,
        CustomersGridGuidedTourComponent,
        TeamMemberGridComponent,
        ProjectViewComponent,
        ProjectProblemRoutingSettingsModalComponent,
        CollaboratorOverviewComponent,
        ProjectViewGuidedTourComponent,
        StopsModalGridComponent,
        MapComponent,
        MapDummyComponent,
        Map2Component,
        MapBeTestingComponent,
        DriversModalGridComponent,
        VehicleSelectEditorComponent,
        PaymentStatusSelectEditorComponent,
        CalendarModalComponent,
        SignatureModalComponent,
        PhotoModalComponent,
        RightFormModalComponent,
        ProjectProblemModalComponent,
        ProjectProblemModalDummyComponent,
        ProjectModalComponent,
        MoveShipmentModalComponent,
        PortalIssuesModalComponent,
        WizardComponent,
        WizardWelcomeComponent,
        ProjectsGridComponent,
        ProjectsGridGuidedTourComponent,
        ProjectProblemsGridComponent,
        ProjectProblemsGridGuidedTourComponent,
        WizardDepotsComponent,
        WizardSettingsComponent,
        WizardRouteSettingsComponent,
        WizardStopSettingsComponent,
        WizardDriverSettingsComponent,
        WizardVehiclesComponent,
        WizardDriversComponent,
        WizardFinishComponent,
        DeliveryFormComponent,
        ChatModalComponent,
        ChatModalDummyComponent,
        ClientPortalChatModalComponent,
        ClientPortalChatModalDummyComponent,
        StopModalComponent,
        StopFormModalComponent,
        VoucherFormModalComponent,
        SettingsComponent,
        ClientPortalComponent,
        PortalAddressMapComponent,
        ClientNavbarComponent,
        DriverSwapSelectEditorComponent,
        OverviewMapComponent,
        StopsGridComponent,
        PartnerShipmentsGridComponent,
        NewShipmentsGridComponent,
        HelperModalComponent,
        ImporterModalComponent,
        RulesModalComponent,
        MapRulesModalComponent,
        RulesGridComponent,
        DepotsGridComponent,
        StopPointHistoryGridComponent,
        ShipmentsHistoryGridComponent,
        DepotModalComponent,
        MoveStopsModalComponent,
        LoadStopsModalComponent,
        SvgIconsComponent,
        TimeWindowModalComponent,
        TimeWindowGridComponent,
        AddStopModalComponent,
        AddStopModalDummyComponent,
        AddStopGridComponent,
        PortalIssuesGridComponent,
        DepartureTimeSelectEditorComponent,
        MoveStopsToProblemModalComponent,
        CustomerModalComponent,
        CustomerInvoiceModalComponent,
        CustomerPaymentModalComponent,
        CustomerCollaboratorsModalComponent,
        PartnerModalComponent,
        CustomerInvoicePrintComponent,
        CustomerPaymentPrintComponent,
        CustomerInvoicePrintSingleComponent,
        CustomerPaymentInvoicePrintSingleComponent,
        ReceiptPrintComponent,
        WarehouseGridComponent,
        RegisterComponent,
        DemoLoaderComponent,
        DynamicFieldsGridComponent,
        PackagingGridComponent,
        CancelStopPointModalComponent,
        DynamicFieldModalComponent,
        PackagingModalComponent,
        MilyComponent,
        RegionalMapComponent,
        StopsGridMapComponent,
        ShipmentsHistoryMapComponent,
        ShipmentsHistoryGridComponent,
        ForgotPasswordComponent,
        DriverModalComponent,
        SettingsDriversMapComponent,
        SettingsDriversRegionsComponent,
        ClientPortalSettingsComponent,
        TimePickerComponent,
        TimePickerModalComponent,
        FirstOptimizationModalComponent,
        TermsModalComponent,
        PrivacyModalComponent,
        ProjectProblemReportModalComponent,
        DriverReportModalComponent,
        PaymentsOverviewComponent,
        ShipmentsModalComponent,
        NoteModalComponent,
        StopsCustomDateModalComponent,
        DateRangePickerModalComponent,
        DatePickerModalComponent,
        InvoiceCustomDateModalComponent,
        CustomerDateModalComponent,
        CollaboratorViewComponent,
        CollaboratorViewChargesComponent,
        NewShipmentsViewComponent,
        CollaboratorPartnerViewComponent,
        PartnerViewComponent,
        CollaboratorViewBalanceComponent,
        TestComponent,
        IndexComponent,
        BarcodesListBoxComponent,
        MultipleShipmentsListBoxComponent,
        TransportationRegionsComponent,
        SettingsServicesComponent,
        PriceListsComponent,
        DiscountsComponent,
        DiscountModalComponent,
        SettingsSurchargesComponent,
        RevaluationsComponent,
        TransportationRegionModalComponent,
        TransportationRegionsMapComponent,
        SettingsServicesComponent,
        BasicServiceModalComponent,
        AddonServiceModalComponent,
        FuelSurchargeModalComponent,
        PriceListModalComponent,
        RevaluationsComponent,
        RevaluationModalComponent,
        OtherSurchargeModalComponent,
        PudoAppNavComponent,
        PudoRegistrationConfirmComponent,
        DashboardReportComponent,
        SettingsDepotsModalComponent,
        DepotsMapComponent,
        FormButtonComponent,
        FormInputComponent,
        FormSelectComponent,
        FormCheckboxComponent,
        PaymentStatusSelectEditorComponent,
        VehicleSelectEditorComponent,
        DriverSwapSelectEditorComponent,
        DepartureTimeSelectEditorComponent,
        LmDeliveriesWidgetComponent
    ],
    imports: [
        LMCoreModule.forRoot(),
        LmSharedModule,
        DialogModule,
        //move to corresponding controld
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule.disable(),
        FormsModule,
        RecaptchaV3Module,
        ReactiveFormsModule,
        AppRoutingModule,
        // AppLayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        NgSelectModule,
        NgxUploaderModule,
        NouisliderModule,
        AgGridModule,
        // AgGridModule.withComponents([]),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CsrfTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonWebTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        [Globals],
        CookieService,
        { provide: APP_INITIALIZER, useFactory: globalFactory, deps: [Globals], multi: true },
        PendingChangesGuard,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LefKTshAAAAACopheTu-mF0thSXNv8yBMJ8vb3F'
        },
    ],
    bootstrap: [
        AppComponent
    ],
    exports: [LoginComponent]
})

export class AppModule {
    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
    ) {
        this.http.get('api/account/csrf').pipe(take(1)).subscribe(response => {
            this.cookieService.set('csrfToken', response['data']['csrfToken'], 1, '/', 'localhost', false, 'Strict');
        });
        ModuleRegistry.registerModules([ClientSideRowModelModule, InfiniteRowModelModule, ServerSideRowModelModule, ColumnsToolPanelModule, MenuModule, RowGroupingModule, ExcelExportModule]);
    }
}
