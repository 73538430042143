import { DepotUtils } from '@app/utils/depot-utils';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { Module } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { StopModalComponent } from '@app/modals/stop-modal/stop-modal.component';
import { GridsService } from '@app/services/grids.service';
import { StopFormModalComponent } from '@app/modals/stop-form-modal/stop-form-modal.component';
import { AddressService } from '@app/services/address.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { MoveShipmentModalComponent } from '@app/modals/move-shipment-modal/move-shipment-modal.component';
import { FulfillmentUtils } from '@app/utils/fulfillment-event-utils';

@Component({
  selector: 'app-warehouse-grid',
  templateUrl: './warehouse-grid.component.html',
  styleUrls: ['./warehouse-grid.component.scss', './../grids.scss']
})
export class WarehouseGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(StopModalComponent, { static: false }) stopModalComponent: StopModalComponent;
  @ViewChild(StopFormModalComponent, { static: false }) stopFormModalComponent: StopFormModalComponent;
  @ViewChild(MoveShipmentModalComponent, { static: false }) moveShipmentModalComponent: MoveShipmentModalComponent;

  updateStopPointUrl = 'api/v1/stop-points';

  listen = [];

  public modules: Module[] = [
    ServerSideRowModelModule,
    MenuModule,
    ColumnsToolPanelModule,
  ];
  rowData = [];
  gridApi;
  gridColumnApi;
  rowModelType = 'serverSide';
  rowBuffer = 0;
  stopPointsDataArray = [];
  columnDefs;
  cacheBlockSize = 50;

  pagesCount = 0;
  searchTextChanged = new Subject<string>();
  searchString: String = '';
  filter = '';
  lastStopPointId = null;
  activeStopPoint = null;
  dropdownHover = false;

  filters = [];
  canChangeFilter;

  selectAllCheckbox = false;
  nameTitle;
  addressTitle;
  reasonTitle;
  serialTitle;
  arrivalTimeTitle;
  creationDateTitle;
  collaboratorTitle;
  volumeTitle;
  removeTitle;
  removeAndCompleteTitle;

  // filter labels
  agreedShippingLabel = '';
  agreedShippingAndOrOtherAddressLabel = '';
  wrongAddressLabel = '';
  customLabel = '';
  notThereLabel = '';
  didNotAcceptLabel = '';
  pickupFromDepotLabel = '';
  nonRoutedLabel = '';
  pendingLabel = '';
  allDepotsLabel;

  depots = [];
  selectedDepot;

  cancelledEventReasonsLabels = {};

  selectedStopPointId;
  noNameLabel = '';
  returnLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    public globals: Globals,
    public gridsService: GridsService,
    private addressService: AddressService,
    private sanitizer: DomSanitizer,
    public stopPointUtils: StopPointUtils,
    public depotUtils: DepotUtils,
    private fulfillmentUtils: FulfillmentUtils,
  ) {
    this.listen.push(this.viewProjectProblemService.updateWarehouseGridListen().subscribe((data) => {
      this.updateWarehouseGrid();
    }));
  }

  reasonRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="double-cell standard-width text-center" title="' + params.getValue().reasonText + '">' + params.getValue().reasonText + '</div>';
      columnObject += '<div class="double-cell text-center"><div class="center-content">';
      columnObject += '<span class="margin-right-5px">' + params.getValue().agreedDate + '</span>';
      columnObject += '<span>' + params.getValue().agreedTimeWindow + '</span>';
      // columnObject += '<div class="double-cell text-center"><div class="center-content"><span>' + params.getValue().agreedDate + '</span> <span>' + params.getValue().agreedTimeWindow + '`</span></div></div>';
      columnObject += '</div></div>';
    }
    return columnObject;
  }

  volumeRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().deliveryLoad) {
        columnObject += '<div class="double-cell"><i class="fas fa-arrow-down grey-letters"></i> ' + params.getValue().deliveryLoad + '</div>';
      }
      if (params.getValue().pickupLoad) {
        columnObject += '<div class="double-cell"><i class="fas fa-arrow-up grey-letters"></i> ' + params.getValue().pickupLoad + '</div>';
      }
    }
    return columnObject;
  }

  dropdownRenderer(params) {
    const self = this;
    const dots = document.createElement('div');
    dots.classList.add('dropdown-dots');
    dots.innerHTML = '<i class="fas fa-ellipsis-v"></i>';
    const dropdown = document.getElementById('dropdown-container');
    dots.addEventListener('click', function () {
      setTimeout(() => {

        if (self.dropdownHover) {
          self.dropdownHover = false;
          dropdown.style.display = 'none';
        } else {
          self.dropdownHover = true;
          dropdown.style.top = 'calc(' + dots.getBoundingClientRect().bottom + 'px - 10px)';
          dropdown.style.left = 'calc(' + dots.getBoundingClientRect().left + 'px - 9vw)';
          dropdown.style.display = 'block';
          self.activeStopPoint = params.data;
        }
      }, 20);
    });
    return dots;
  }

  onFirstDataRendered(params) {
  }

  onGridReady(params) {
    // const self = this;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setServerSideDatasource(this.createServerSideDatasource());

    // var timer = null;
    // const elem = document.querySelector('.ag-body-viewport');
    // elem.addEventListener('scroll', function () {
    //   if (timer !== null) {
    //     clearTimeout(timer);
    //   }
    //   timer = setTimeout(function () {
    //     self.gridApi.refreshCells({ force: true });
    //   }, 150);
    // }, false);
  }

  test() {

    // this.gridApi.refreshCells({ force: true });
  }

  getSelectedRows() {
    return this.gridApi.getSelectedRows();
  }

  removeStopPoints(markAsComplete) {
    if (this.getSelectedRows().length) {
      this.removeSelectedStopPoints(this.getSelectedRows(), markAsComplete);
    } else {
      this.removeSelectedStopPoints([this.activeStopPoint], markAsComplete);
    }
  }

  openMoveShipmentModal() {
    this.globals.comingSoonAlert();
    this.dropdownHover = false;
    document.getElementById('dropdown-container').style.display = 'none';
    // this.moveShipmentModalComponent.openModal();
  }

  removeSelectedStopPoints(stopPoints, markAsComplete) {
    const myObserver = {
      next: (response) => {
        this.updateWarehouseGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      },
    };
    const dropdown = document.getElementById('dropdown-container');
    this.dropdownHover = false;
    dropdown.style.display = 'none';
    const stopPointsData = {
      stopPoints: [],
      removeOriginFormWarehouse: true
    };
    stopPoints.forEach(stopPoint => {
      const stopPointData = {
        stopPoint: {
          project_problem_id: stopPoint.projectProblemId,
          stopPointId: stopPoint.id,
          in_warehouse: false,
        },
      };
      if (markAsComplete) {
        stopPointData.stopPoint['fulfillment_status'] = this.globals.stopPointFulfillmentStatusConstants['COMPLETED'];
        stopPointsData.removeOriginFormWarehouse = false;
      } else {
        stopPointData.stopPoint['in_warehouse'] = false;
      }
      stopPointsData.stopPoints.push(stopPointData);
    });

    this.http.put(this.updateStopPointUrl, stopPointsData).pipe(take(1)).subscribe(myObserver);
  }

  getDataForNotSeenRequestForStopPoint(stopPoint) {
    let data = {};
    // send pickup if it is not completed handed, else send delivery
    if (stopPoint.relatedStopPointId && stopPoint.fullData.service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']
      && !this.stopPointUtils.isCompletedHanded(stopPoint.relatedStopPoint?.fulfillment_events[0].reason)) {
      data = {
        stopPoint: {
          project_problem_id: stopPoint.relatedStopPointProjectProblemId,
          stopPointId: stopPoint.relatedStopPointId,
          warehouse_handed: {
            warehouse_handed_datetime: moment().utc().format().replace('Z', '+00:00'),
          },
        }
      };
    } else {
      data = {
        stopPoint: {
          project_problem_id: stopPoint.projectProblemId,
          stopPointId: stopPoint.id,
          warehouse_handed: {
            warehouse_handed_datetime: moment().utc().format().replace('Z', '+00:00'),
          },
        }
      };
    }
    return data;
  }

  // mark stop point(s) as seen
  markAsSeen() {
    // data to send
    let data = {
      stopPoints: [],
    };

    // mark multiple stop points
    if (this.getSelectedRows().length) {

      this.getSelectedRows().forEach(stopPoint => {
        data.stopPoints.push(this.getDataForNotSeenRequestForStopPoint(stopPoint));
      });

    }
    // mark single stop point (without selecting from checkbox)
    else {
      let projectProblemId;

      // find selected sp project problem
      this.stopPointsDataArray.forEach(stopPoint => {
        if (stopPoint.id == this.selectedStopPointId) {
          projectProblemId = stopPoint.projectProblemId;
          data.stopPoints.push(this.getDataForNotSeenRequestForStopPoint(stopPoint));
        }
      });
    }

    // close dropdown & reset selectedStopPointId
    const dropdown = document.getElementById('dropdown-container');
    this.dropdownHover = false;
    dropdown.style.display = 'none';
    this.selectedStopPointId = null;

    // perform request
    this.http.put(this.updateStopPointUrl, data).pipe(take(1)).subscribe(() => {
      this.updateWarehouseGrid();
    });
  }

  onDepotChange() {
    this.updateWarehouseGrid();
  }

  getWarehouseGridData() {
    let url = 'api/v1/company/warehouse';
    url += '?stopPointId=' + this.lastStopPointId;
    url += '&limit=' + this.cacheBlockSize;
    url += '&searchQuery=' + this.searchString;
    url += '&' + this.filter;
    if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
      url += `&depotId=${this.selectedDepot.companyDepot.id}`;
    }
    return this.http.get(url);
  }

  createServerSideDatasource() {
    const self = this;
    return {
      getRows: function (params) {
        self.getWarehouseGridData().pipe(take(1)).subscribe(response => {
          // allow filtering after 1s
          setTimeout(() => {
            self.canChangeFilter = true;
          }, 1000);

          if (!self.filters.length) { self.setFilters(); }

          if (!response['items'].stopPoints.length) {
            // check to see if it's an empty response on an empty grid (no last sp id) or an empty response on a grid that contains stop points
            if (self.lastStopPointId) {
              params.successCallback([], self.gridApi.getDisplayedRowCount() - 1); // -1 because it counts the Loading row, too
              return;
            }
            else {
              const noData = { noDataText: 'No data' }
              params.successCallback([noData], 1);
              return;
            }
          }


          self.lastStopPointId = response['items']['stopPointId'];
          if (response['items']['stopPoints'].length) {
            let lastRow = -1;
            self.pagesCount++;
            if (response['items']['stopPoints'].length < self.cacheBlockSize) {
              lastRow = response['items']['stopPoints'].length + ((self.pagesCount - 1) * self.cacheBlockSize);
            }
            params.successCallback(self.setCancelledStopsGridData(response['items']['stopPoints']), lastRow);
            self.gridApi.forEachNode((row, index) => {
              self.gridApi.getRowNode(row.id).selectThisNode(false);
            });
          } else {
            const noData = { noDataText: 'No data' }
            params.successCallback([noData], 1);
          }

        }, error => {
          params.failCallback();
        });
      },
    };
  }

  setCancelledStopsGridData(data) {
    const self = this;
    self.stopPointsDataArray = [];
    let stopPoint, duration, agreedTimeWindow, gridObject = {}, reasonText, agreedDate;
    data.forEach(
      stopPointData => {
        stopPoint = stopPointData.stopPoint;
        agreedTimeWindow = '';
        reasonText = '';
        agreedDate = '';
        duration = [moment.duration(stopPoint.duration).asMinutes()];
        if (stopPoint.fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['CANCELED']) {
          stopPoint.fulfillment_events.forEach(event => {
            if (event.reason > 799 && event.reason < 900) {
              if (this.cancelledEventReasonsLabels[event.reason] && event.reason != this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM) {
                reasonText = this.cancelledEventReasonsLabels[event.reason];
              }
              else if (event.reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM) {
                reasonText = event.description;
              }
              else {
                reasonText = event.reason;
              }
            }
          });
        }
        agreedTimeWindow = '';
        if (stopPoint.agreed_shipping) {
          const agreedShipping = stopPoint.agreed_shipping[0];
          if (agreedShipping) {
            if (agreedShipping.start && agreedShipping.end) {
              agreedTimeWindow += moment(agreedShipping.start, 'HH:mm').format('HH:mm') + ' - ' + moment(agreedShipping.end, 'HH:mm').format('HH:mm');
            }
            if (agreedShipping.date) {
              agreedDate = moment(agreedShipping.date, 'YYYY/MM/DD').format('DD/MM/YYYY');
            }
          }
        }

        let collaborator = {};
        if (stopPoint.collaborator) {
          collaborator = {
            id: stopPoint.collaborator.collaboratorData.id,
            name: stopPoint.collaborator.collaboratorData.collaborator_name
          }
        } else {
          collaborator = {
            id: null,
            name: stopPoint.collaborator_name ?? '',
          }
        }

        let completeDatetimeMoment = null;
        if (
          stopPoint.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
          stopPoint.solution
        ) {
          completeDatetimeMoment = moment(stopPoint.solution.atTime);
        }

        // Time (Time Windows)
        let timeWindowStart, timeWindowEnd;
        let timeWindowSecondStart, timeWindowSecondEnd;
        let timeWindowRange, timeWindowRangeMinutes;
        if (stopPoint.time_windows[0]) {
          timeWindowStart = moment(stopPoint.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
          timeWindowRange = stopPoint.time_windows[0].time_window_range;
          timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
          timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

          if (stopPoint.time_windows[1]) {
            timeWindowSecondStart = moment(stopPoint.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
            timeWindowRange = stopPoint.time_windows[1].time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
          }
        }

        // barcode
        let barcodeValue;
        if (stopPointData.stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP'] && stopPointData.relatedStopPoint) {
          barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(stopPointData.relatedStopPoint);
        } else {
          barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(stopPoint);
        }

        let name = stopPoint.contact_name;
        if (name.includes(this.noNameConstant)) {
          name = name.replace(this.noNameConstant, this.noNameLabel);
        }
        if (name.includes(this.returnConstant)) {
          name = name.replace(this.returnConstant, this.returnLabel);
        }
        gridObject = {
          id: stopPoint.id,
          projectProblemId: stopPoint.project_problem_id,
          // TODO change projectProblem for optimization
          projectProblemDepartureDatetime: stopPointData.projectProblem ? stopPointData.projectProblem.departure_datetime : null,
          name: {
            name: name,
            phone: stopPoint.telephone ?? '-',
            barcode: barcodeValue ?? '-'
          },
          collaborator: collaborator,
          address: self.addressService.getAddressLabel(stopPoint.address),
          reason: {
            reasonText: reasonText,
            agreedTimeWindow: agreedTimeWindow,
            agreedDate: agreedDate
          },
          serial: stopPoint.serial_number,
          date: stopPoint.issue_date ? moment(stopPoint.issue_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '',
          volume: this.stopPointUtils.getStopPointVolumeWeightColumnData(stopPoint),
          time: {
            date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
            twFirstStart: timeWindowStart,
            twFirstEnd: timeWindowEnd,
            twSecondStart: timeWindowSecondStart,
            twSecondEnd: timeWindowSecondEnd
          },
          relatedStopPoint: stopPointData.relatedStopPoint ?? null,
          relatedStopPointId: stopPointData.relatedStopPoint ? stopPointData.relatedStopPoint.id : null,
          relatedStopPointProjectProblemId: stopPointData.relatedStopPoint ? stopPointData.relatedStopPoint.project_problem_id : null,
          fullData: stopPoint,
        };
        self.stopPointsDataArray.push(gridObject);
      });
    return self.stopPointsDataArray;
  }

  updateWarehouseGrid() {
    this.canChangeFilter = false;
    this.lastStopPointId = null;
    this.pagesCount = 0;
    // this.gridApi.purgeServerSideCache([]);
    this.gridApi.refreshServerSideStore({ purge: true });
  }

  filterGrid(type) {
    if (this.canChangeFilter) {
      if (document.querySelector('.filter-box.active')) {
        document.querySelector('.filter-box.active').classList.remove('active');
      }
      if (document.querySelector('[index="' + type + '"]')) {
        document.querySelector('[index="' + type + '"]').classList.add('active');
      }
      this.gridApi.setColumnDefs(this.columnDefs);
      const filterData = this.filters[type];
      this.filter = '';
      if (filterData.fulfillmentStatus) {
        this.filter = 'fss=' + filterData.fulfillmentStatus;
        if (filterData.fulfillmentEvent) {
          if (filterData.fulfillmentEvent === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING']) {
            this.filter += '&fe-reasons=' + filterData.fulfillmentEvent + ',' + this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS'];
          } else if (filterData.fulfillmentEvent === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['DID_NOT_ACCEPT_IT']) {
            this.filter += '&fe-reasons=' + filterData.fulfillmentEvent;
            if (this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']) {
              this.filter += ',' + this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']
            }
            if (this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']) {
              this.filter += ',' + this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']
            }
          } else if (filterData.fulfillmentEvent === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['PICKUP_FROM_DEPOT']) {
            this.filter += '&fe-reasons=' + filterData.fulfillmentEvent;
            this.filter += '&shipment_type=' + this.globals.priceListShipmentEnum.DoorShop.key + ',' + this.globals.priceListShipmentEnum.ShopShop.key;
          } else {
            this.filter += '&fe-reasons=' + filterData.fulfillmentEvent;
          }
        }
      } else if (filterData.unhanded) {
        this.filter = 'unhanded=1'
      }
      this.updateWarehouseGrid();
    }
  }

  removeFilters() {
    if (this.canChangeFilter) {
      if (document.querySelector('.filter-box.active')) {
        document.querySelector('.filter-box.active').classList.remove('active');
      }
      if (document.querySelector('.filter-box.all')) {
        document.querySelector('.filter-box.all').classList.add('active');
      }
      this.filter = '';
      this.updateWarehouseGrid();
      // this.gridApi.setColumnDefs(this.columnDefs);
      // this.rowData = of(this.vehiclesDataArray);
    }
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  selectAll() {
    if (!this.selectAllCheckbox) {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(true);
      });
    } else {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(false);
      });
    }
  }

  rowClicked(event) {
    const target = event.event.target.closest('div');
    if (!event.data.noDataText && !target.classList.contains('dropdown-dots')) {
      this.viewProjectProblemService.openStopModal(
        event.data.id,
        event.data.projectProblemId,
        event.data.projectProblemDepartureDatetime,
      );
    }
    else if (target.classList.contains('dropdown-dots')) {
      this.selectedStopPointId = event.data.id;
    }
  }

  setFilters() {
    const agreedShipping = '<i class="fas fa-calendar-alt"></i>';
    const custom = '<i class="fas fa-shipping-fast"></i>';
    const didNotAccept = '<i class="fas fa-user-times"></i>';
    const wrongAddress = '<i class="fas fa-search-location"></i>';
    // const notThere = '<i class="fas fa-search-location"></i>';
    const pickupFromDepot = '<i class="fas fa-warehouse"></i>';
    const notThere = '<i class="fas fa-store-alt-slash"></i>';
    const nonRouted2 = '<i class="fas fa-map-marker-alt"></i>';
    const pending = '<i class="fas fa-exclamation-triangle"></i>';
    const nonRouted = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.markerWithBorder);
    // nonRouted mh dromologhmena - disabled / fs2 es1
    // pending ekkremoun exclamation den ksero poy e;inai / not seen

    // const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.filters = [
      {
        index: 0,
        image: wrongAddress,
        typeName: this.wrongAddressLabel,
        fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['WRONG_ADDRESS'],
      },
      {
        index: 1,
        image: agreedShipping,
        typeName: this.agreedShippingAndOrOtherAddressLabel,
        fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING'],
      },
      {
        index: 2,
        image: notThere,
        typeName: this.notThereLabel,
        fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['NOT_THERE'],
      },
      {
        index: 3,
        image: didNotAccept,
        typeName: this.didNotAcceptLabel,
        fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['DID_NOT_ACCEPT_IT'],
      },
      {
        index: 4,
        image: pickupFromDepot,
        typeName: this.pickupFromDepotLabel,
        fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['PICKUP_FROM_DEPOT'],
      },
      {
        index: 5,
        image: nonRouted,
        typeName: this.nonRoutedLabel,
        fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED'] + ',' + this.globals.stopPointFulfillmentStatusConstants['DROPPED'],
        // fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']][''],
      },
      {
        index: 6,
        image: pending,
        typeName: this.pendingLabel,
        unhanded: true,
        // fulfillmentStatus: this.globals.stopPointFulfillmentStatusConstants['CANCELED'],
        // fulfillmentEvent: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']][''],
      },
    ];
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => {
      this.nameTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => {
      this.addressTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.REASON').subscribe((res: string) => {
      this.reasonTitle = res;
    }));
    this.listen.push(this.translate.get('PROJECT.SERIAL_NO').subscribe((res: string) => {
      this.serialTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.ARRIVAL_TIME').subscribe((res: string) => {
      this.arrivalTimeTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.CREATION_DATE').subscribe((res: string) => {
      this.creationDateTitle = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.SENDER').subscribe((res: string) => {
      this.collaboratorTitle = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => {
      this.volumeTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.REMOVE').subscribe((res: string) => {
      this.removeTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.REMOVE_AND_COMPLETE').subscribe((res: string) => {
      this.removeAndCompleteTitle = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));

    this.listen.push(this.translate.get('CANCEL').subscribe((res: string) => {
      this.fulfillmentUtils.setupReasonLabels(res);
      this.cancelledEventReasonsLabels = this.fulfillmentUtils.cancelledEventReasonsLabels;
    }));

    this.listen.push(this.translate.get('CANCEL.CANCEL_OTHER_REASON_MSG').subscribe((res: string) => {
      this.customLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_NOT_THERE_MSG').subscribe((res: string) => {
      this.notThereLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_WRONG_ADDRESS_MSG').subscribe((res: string) => {
      this.wrongAddressLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_DID_NOT_ACCEPT_MSG').subscribe((res: string) => {
      this.didNotAcceptLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_PICKUP_FROM_DEPOT_MSG').subscribe((res: string) => {
      this.pickupFromDepotLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_AGREED_SHIPPING_MSG').subscribe((res: string) => {
      this.agreedShippingLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.AGREED_SHIPPING_WRONG_ADDRESS_MSG').subscribe((res: string) => {
      this.agreedShippingLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.AGREED_SHIPPING_AND_OR_WRONG_ADDRESS_MSG').subscribe((res: string) => {
      this.agreedShippingAndOrOtherAddressLabel = res;
    }));

    this.listen.push(this.translate.get('CANCEL.PENDING').subscribe((res: string) => {
      this.pendingLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.NOT_ROUTED').subscribe((res: string) => {
      this.nonRoutedLabel = res;
    }));
    this.listen.push(this.translate.get('GENERIC.STATIONS_ALL').subscribe((res: string) => { this.allDepotsLabel = res; }));

    // get depots & populate dropdown
    this.depots = this.depotUtils.getDepotsWithAllDepotsOption();
    this.selectedDepot = this.depots[0];

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        checkboxSelection: true,
        headerClass: 'header-with-checkbox',
        cellRenderer: this.gridsService.nameRenderer,
        width: this.gridsService.widthCalculator(22)
      },
      {
        headerName: this.creationDateTitle,
        field: 'date',
        headerClass: 'text-center',
        cellClass: 'text-center',
        width: this.gridsService.widthCalculator(10)
      },
      {
        headerName: this.collaboratorTitle,
        field: 'collaborator',
        sortable: true,
        cellRenderer: this.gridsService.collaboratorRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.addressTitle,
        field: 'address',
        cellRenderer: this.gridsService.addressRenderer,
        width: this.gridsService.widthCalculator(20)
      },
      {
        headerName: this.arrivalTimeTitle,
        field: 'time',
        sortable: true,
        width: this.gridsService.widthCalculator(7),
        cellRenderer: this.gridsService.timeRenderer
      },
      {
        headerName: this.volumeTitle,
        field: 'volume',
        cellRenderer: this.gridsService.volumeWeightCountRenderer,
        width: this.gridsService.widthCalculator(6)
      },
      {
        headerName: this.reasonTitle,
        field: 'reason',
        headerClass: 'text-center',
        cellRenderer: this.reasonRenderer,
        width: this.gridsService.widthCalculator(17)
      },
      // {
      //   headerName: this.serialTitle,
      //   field: 'serial',
      //   headerClass: 'text-center',
      //   cellClass: 'text-center',
      //   width: this.gridsService.widthCalculator(10)
      // },
      {
        headerName: '', field: '',
        cellClass: 'dropdown-cell',
        cellRenderer: this.dropdownRenderer.bind(this),
        width: this.gridsService.widthCalculator(3)
      },
    ];
  }

  ngAfterViewInit() { }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.projectProblemDataService.loadDepotData();

    this.searchTextChanged.pipe(
      debounceTime(700),
      distinctUntilChanged()).subscribe((text: string) => {
        this.updateWarehouseGrid();
      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
