import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  templateUrl: './formSelect.component.html',
  styleUrls: ['./formSelect.component.scss']
})
export class FormSelectComponent {
  config;
  group: FormGroup;
}
