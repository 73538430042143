// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#projects-live-grid-container {
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRhc2hib2FyZC1wcm9qZWN0cy1saXZlLWR1bW15LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0ksWUFBQTtBQURKIiwiZmlsZSI6ImRhc2hib2FyZC1wcm9qZWN0cy1saXZlLWR1bW15LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnc3R5bGVzL2Fic3RyYWN0cy92YXJpYWJsZXMnO1xuXG4jcHJvamVjdHMtbGl2ZS1ncmlkLWNvbnRhaW5lciB7XG4gICAgaGVpZ2h0OiAxMDAlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/dashboard/dashboard-views/dashboard-projects-live/dashboard-projects-live-dummy/dashboard-projects-live-dummy.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;AACA,obAAob","sourcesContent":["@import 'styles/abstracts/variables';\n\n#projects-live-grid-container {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
