/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ISettings_TR_AreaDto } from '../models/settings-transportation-regions-dto';

@Injectable({
  providedIn: 'root'
})
export class LmDepotsService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

    // ------------------------- ADD DTOs ---------------------
  
    static readonly depotsGetPath = '/api/v1/company/depots/{id}?isDepotAdministrationMultipolygonRequired=true';
    static readonly depotsPutPath = '/api/v1/company/depots/{id}';
    static readonly depotsPostPath = '/api/v1/company/depots';
    //make map api singleton
    static readonly settingsOpenStreetMapsGeoPath = '/api/v1/open-street-maps/get-geo-json';
    static readonly mapLocationFromCoordsGetPath = '/api/v1/search/reverse-locations?searchQuery={lat},{lon}';

    apiDepotPut$Response(params?: { body?: any, id?: string }): Observable<StrictHttpResponse<Array<any>>> {
      const rb = new RequestBuilder(this.rootUrl, LmDepotsService.depotsPutPath, 'put');
      if (params) rb.path('id', params.id);
      if (params) rb.body(params.body, 'application/*+json');
  
      return this.http
        .request(
          rb.build({
            responseType: 'json',
            accept: 'application/json'
          })
        )
        .pipe(
          filter((r: any) => r instanceof HttpResponse),
          map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<any>>;
          })
        );
    }
  
  
    apiDepotPut(params?: { body?: any, id?: string }): Observable<Array<any>> {
      return this.apiDepotPut$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }




    // GET BY ID
    apiDepotSingleGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmDepotsService.depotsGetPath, 'get');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({ responseType: 'json', accept: 'application/json'}))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiDepotSingleGet(params?: { id?: string }): Observable<Array<any>> {
        return this.apiDepotSingleGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


    // POST 
    apiDepotPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmDepotsService.depotsPostPath, 'post');
      if (params) rb.body(params.body, 'application/*+json');

      return this.http
        .request(
          rb.build({
            responseType: 'json',
            accept: 'application/json'
          })
        )
        .pipe(
          filter((r: any) => r instanceof HttpResponse),
          map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<any>>;
          })
        );
    }

    apiDepotPost(params?: { body?: any }): Observable<Array<any>> {
        return this.apiDepotPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    //MAP LOCATION GET
    

    apiMapLocationFromCoordsGet$Response(params?: { lat?: number, lon?: number }): Observable<StrictHttpResponse<Array<any>>> {
      const rb = new RequestBuilder(this.rootUrl, LmDepotsService.mapLocationFromCoordsGetPath, 'get');
      if (params) {
        rb.path('lat', params.lat);
        rb.path('lon', params.lon);
      };

      return this.http.request(
          rb.build({ responseType: 'json', accept: 'application/json'}))
          .pipe(
              filter((r: any) => r instanceof HttpResponse),
              map((r: HttpResponse<any>) => {
                  return r as StrictHttpResponse<Array<any>>;
              }
          )
      );
  }
  
  apiMapLocationFromCoordsGet(params?: { lat?: number, lon?: number }): Observable<Array<any>> {
      return this.apiMapLocationFromCoordsGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }
    
    // MAP POST
    apiSettingsDepotsOpenStreeMapsPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<ISettings_TR_AreaDto>> {
      const rb = new RequestBuilder(this.rootUrl, LmDepotsService.settingsOpenStreetMapsGeoPath, 'post');
        if (params) rb.body(params.body, 'application/*+json');
  
        return this.http
          .request(
            rb.build({
              responseType: 'json',
              accept: 'application/json'
            })
          )
          .pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
              return r as StrictHttpResponse<ISettings_TR_AreaDto>;
            })
          );
      }
    
  
      apiSettingsDepotsOpenStreeMapsPost(params?: { body?: any }): Observable<ISettings_TR_AreaDto> {
        return this.apiSettingsDepotsOpenStreeMapsPost$Response(params).pipe(map((r: StrictHttpResponse<ISettings_TR_AreaDto>) => r.body as ISettings_TR_AreaDto));
      }

}
