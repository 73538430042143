import { chartImageAnnotation, chartAnnotationY, chartBarThicknessOffset } from "@app/shared/utils";
import { BubbleDataPoint, Chart, ChartDataset, ChartOptions, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import { AnnotationOptions, PartialEventContext } from "chartjs-plugin-annotation";


export const LM_BAR_CHART_BASE_OPTIONS: ChartOptions = {
    datasets:{
        bar:{
            barThickness: 40
        }
    },
    elements:{
        bar:{
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false
        }
    },
    plugins:{
        legend:{
            display: false,
            labels:{
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle',
              color: '#999',
              font:{
                  size: 12
              }
          }
        },
        tooltip: {
          enabled: false
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
              color: '#ccc',
            },
            grid: {
                color: '#f6f6f6'
            },
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
              color: '#ccc',
            }
        }
    }
}

export const LM_BAR_CHART_HEART_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + ' ❤';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_BAR_CHART_RATING_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + ' ★';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_BAR_CHART_MONEY_OPTIONS: ChartOptions = {
  scales: {
      y: {
          beginAtZero: true,
          ticks: {
              stepSize: 1,
              callback: function(value) {
                  return value + '€';
              }
          }
      },
      x: {
          grid: {
              display: false
          }
      }
  }
};

export const LM_PIE_CHART_BASE_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins:{
      legend:{
          display: false,
          position: 'top'
      },
  }
}

export const LM_DOUGHNUT_CHART_BASE_OPTIONS: ChartOptions<'doughnut'> = {
  cutout: '65%',
  plugins:{
      legend:{
          display: false
      }
  }
}


export function lmVerticalLabelAnnotation(label): AnnotationOptions{
  return {
    type: 'label',
    padding: {left:1, bottom: 10},
    content: label,
    // content: ({chart:{data:{datasets, labels}}}) => `${label}:   ${datasets[0].data[labels.indexOf(label)]}%`,
    yValue: label,
    xValue: 0,
    position: { y: 'end', x: '0%' }
  }
}


export function lmAvatarAnnotation(label, img): AnnotationOptions{
  return {
    type: 'label',
    drawTime: 'afterDatasetsDraw',
    // init: true,
    padding: 5,
    // borderWidth: 4,
    // borderColor: 'rgba(255,255,255,.3)',
    borderRadius: 30,
    content: (e:PartialEventContext) => chartImageAnnotation(e, img),
    position: { x: 'center', y: 'end' },
    xValue: label,
    yValue: (e:PartialEventContext, anno) =>  chartAnnotationY(e, anno),
    // yAdjust: (e) => chartBarThicknessOffset(e, 1)
  }
}

export const LM_STACKED_CHART_BORDER_RADIUS = {
  topLeft: 0,
  topRight: 0,
  bottomLeft: 100,
  bottomRight: 100,
}





/*

export type ChartType = keyof ChartTypeRegistry;


export interface ChartTypeRegistry {
  bar: {
    chartOptions: BarControllerChartOptions;
    datasetOptions: BarControllerDatasetOptions;
    defaultDataPoint: number;
    metaExtensions: {};
    parsedDataType: BarParsedData,
    scales: keyof CartesianScaleTypeRegistry;
  };
  line: {
    chartOptions: LineControllerChartOptions;
    datasetOptions: LineControllerDatasetOptions & FillerControllerDatasetOptions;
    defaultDataPoint: ScatterDataPoint | number | null;
    metaExtensions: {};
    parsedDataType: CartesianParsedData;
    scales: keyof CartesianScaleTypeRegistry;
  };
  scatter: {
    chartOptions: ScatterControllerChartOptions;
    datasetOptions: ScatterControllerDatasetOptions;
    defaultDataPoint: ScatterDataPoint | number | null;
    metaExtensions: {};
    parsedDataType: CartesianParsedData;
    scales: keyof CartesianScaleTypeRegistry;
  };
  bubble: {
    chartOptions: unknown;
    datasetOptions: BubbleControllerDatasetOptions;
    defaultDataPoint: BubbleDataPoint;
    metaExtensions: {};
    parsedDataType: BubbleParsedData;
    scales: keyof CartesianScaleTypeRegistry;
  };
  pie: {
    chartOptions: PieControllerChartOptions;
    datasetOptions: PieControllerDatasetOptions;
    defaultDataPoint: PieDataPoint;
    metaExtensions: PieMetaExtensions;
    parsedDataType: number;
    scales: keyof CartesianScaleTypeRegistry;
  };
  doughnut: {
    chartOptions: DoughnutControllerChartOptions;
    datasetOptions: DoughnutControllerDatasetOptions;
    defaultDataPoint: DoughnutDataPoint;
    metaExtensions: DoughnutMetaExtensions;
    parsedDataType: number;
    scales: keyof CartesianScaleTypeRegistry;
  };
  polarArea: {
    chartOptions: PolarAreaControllerChartOptions;
    datasetOptions: PolarAreaControllerDatasetOptions;
    defaultDataPoint: number;
    metaExtensions: {};
    parsedDataType: RadialParsedData;
    scales: keyof RadialScaleTypeRegistry;
  };
  radar: {
    chartOptions: RadarControllerChartOptions;
    datasetOptions: RadarControllerDatasetOptions & FillerControllerDatasetOptions;
    defaultDataPoint: number | null;
    metaExtensions: {};
    parsedDataType: RadialParsedData;
    scales: keyof RadialScaleTypeRegistry;
  };
}
*/