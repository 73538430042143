import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment-timezone';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-wizard-settings',
  templateUrl: './wizard-settings.component.html',
  styleUrls: ['./wizard-settings.component.scss']
})
export class WizardSettingsComponent implements OnInit {

  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;

  listen = [];

  myForm: FormGroup;
  // companyId = null;
  // companySettingsId = null;

  defaultDepartureTime = '';
  defaultDepartureDatetime = '';
  defaultDepartureTimezone = '';

  // lateStops = false;
  reloadSystemSwitch = true;

  // solverSigma = '';
  // sigmaValue = [30];
  // sigmaOptions;

  durationUnformatted = '04:00';
  durationSeconds = [120];
  duration = '';
  durationOptions;

  defaultLoad = null;

  reloadDurationAtIntermediateDepot = '';
  reloadTimeUnformatted = '00:30';
  reloadTimeMinutes = [30];
  reloadTimeOptions;

  timeWindowSingleDefault = [480, 1200];

  portalAccess = 0;
  portalAccessDefault = 0;
  portalOn = false;
  // portalOnDefault = false;
  // portalPlus = false;
  // portalPlusDefault = false;

  defaultWorkingHoursUnformatted = '08:00';
  defaultWorkingHoursMinutes = [480];
  defaultWorkingHours = '';
  defaultWorkingHoursOptions;

  driverOvertime = false;

  minuteLabel = '';

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private dataService: DataService,
    private settingsService: SettingsService,
    public translate: TranslateService,
    public globals: Globals,
    private dateTimeCalculatorService: DateTimeCalculatorService,
  ) {
    this.initializeSlider();
    this.myForm = formBuilder.group({
      'company': formBuilder.group({
        // 'id': [this.companyId],
      }),
      'companySettings': formBuilder.group({
        'timeWindows': formBuilder.array([]),
        'recurringDays': formBuilder.array([]),
        // 'company_id': [this.companySettingsId],
        'default_departure_time': [this.defaultDepartureTime],
        'default_project_problem_departure_datetime': [this.defaultDepartureDatetime],
        'default_project_problem_departure_datetime_timezone': [this.defaultDepartureTimezone],
        'default_stop_point_duration': [this.duration],
        'durationSeconds': [this.durationSeconds],
        'default_stop_point_delivery_load': [this.defaultLoad],
        'default_stop_point_pickup_load': [this.defaultLoad],
        'default_working_hours': [this.defaultWorkingHours],
        'defaultWorkingHoursUnformatted': [this.defaultWorkingHoursUnformatted],
        'defaultWorkingHoursMinutes': [this.defaultWorkingHoursMinutes],
        'driver_overtime': [this.driverOvertime],
        'reload_system_switch': [this.reloadSystemSwitch],
        'reload_duration_at_intermediate_depot': [this.reloadDurationAtIntermediateDepot],
        'reloadTimeMinutes': [this.reloadTimeMinutes],
        // 'solver_sigma': [this.solverSigma],
        // 'sigma': [this.sigmaValue],
        // 'allow_late_stops': [this.lateStops],
        'portal_access': [this.portalAccessDefault],
        'portal_access_sw': [this.portalOn],
        // 'portalOn': [this.portalOn],
        // 'portalPlus': [this.portalPlus],
        // 'portalOnDefault': [this.portalOn],
        // 'portalPlusDefault': [this.portalPlusDefault],
      }),
    });
  }

  patchFormForLoad() {
    this.myForm.patchValue({
      'company': {},
      'companySettings': {
        'default_departure_time': this.defaultDepartureTime,
        'default_project_problem_departure_datetime': this.defaultDepartureDatetime,
        'default_project_problem_departure_datetime_timezone': this.defaultDepartureTimezone,
        'durationSeconds': this.durationSeconds,
        'default_stop_point_duration': this.duration,
        'default_working_hours': this.defaultWorkingHours,
        'default_stop_point_delivery_load': this.defaultLoad,
        'default_stop_point_pickup_load': this.defaultLoad,
        'driver_overtime': this.driverOvertime,
        'reload_system_switch': this.reloadSystemSwitch,
        'reload_duration_at_intermediate_depot': this.reloadDurationAtIntermediateDepot,
        'reloadTimeMinutes': this.reloadTimeMinutes,
        // 'solver_sigma': this.solverSigma,
        // 'sigma': this.sigmaValue,
        // 'allow_late_stops': this.lateStops,
        'portal_access': this.portalAccessDefault,
        'portal_access_sw': this.portalOn,
        'portalOnDefault': this.portalOn,
        // 'portalPlusDefault': this.portalPlusDefault,
      },
    });
    M.updateTextFields();
  }

  patchForm() {
    this.myForm.patchValue({
      'companySettings': {
        'default_departure_time': this.defaultDepartureTime,
        'default_project_problem_departure_datetime': this.defaultDepartureDatetime,
        'default_project_problem_departure_datetime_timezone': this.defaultDepartureTimezone,
        'default_stop_point_duration': this.duration,
        'default_working_hours': this.defaultWorkingHours,
        'reload_duration_at_intermediate_depot': this.reloadDurationAtIntermediateDepot,
        // 'solver_sigma': this.solverSigma,
        'portal_access': this.portalAccessDefault,
      },
    });
    M.updateTextFields();
  }

  loadData() {
    const companyUrl = 'api/v1/companies/' + this.globals.companyId;
    this.http.get(companyUrl).pipe(take(1)).subscribe(response => {
      const data = response['items'];
      // this.lateStops = data.companySettings.allow_late_stops;
      // this.reload = data.companySettings.reload;

      this.portalOn = data.companySettings.portal_access_sw;
      this.portalAccessDefault = data.companySettings.portal_access;
      // if (this.portalAccessDefault === this.globals.portalAccessConstants['NO_ACCESS']) {
      //   // this.portalOnDefault = false;
      //   // this.portalPlusDefault = false;
      // } else if (this.portalAccessDefault === this.globals.portalAccessConstants['ACCESS_NO_HISTORY']) {
      //   // this.portalOnDefault = true;
      //   // this.portalPlusDefault = false;
      // } else if (this.portalAccessDefault === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
      //   // this.portalOnDefault = true;
      //   // this.portalPlusDefault = true;
      // }

      if (data.companySettings.default_project_problem_departure_datetime) {
        this.defaultDepartureDatetime = data.companySettings.default_project_problem_departure_datetime;
        this.defaultDepartureTimezone = data.companySettings.default_project_problem_departure_datetime_timezone;
        this.defaultDepartureTime = moment(this.defaultDepartureDatetime).format('HH:mm');
      }
      this.defaultLoad = data.companySettings.default_stop_point_delivery_load;
      this.driverOvertime = data.companySettings.driver_overtime;

      if (data.companySettings.project_problem_horizon) {
        this.defaultWorkingHours = data.companySettings.default_working_hours;
        this.defaultWorkingHoursUnformatted = moment(data.companySettings.default_working_hours, 'HH:mm:SS').format('HH:mm');
        this.defaultWorkingHoursMinutes = [moment.duration(this.defaultWorkingHours).asMinutes()];
      }
      if (data.companySettings.default_stop_point_duration) {
        this.duration = data.companySettings.default_stop_point_duration;
        this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
        this.durationSeconds = [moment.duration(this.duration).asSeconds()];
      }
      if (data.companySettings.reload_duration_at_intermediate_depot) {
        this.reloadDurationAtIntermediateDepot = data.companySettings.reload_duration_at_intermediate_depot;
        this.reloadTimeUnformatted = moment.duration(this.reloadDurationAtIntermediateDepot).asMinutes() + this.minuteLabel;
        this.reloadTimeMinutes = [moment.duration(this.reloadDurationAtIntermediateDepot).asMinutes()];
      }
      // if (data.companySettings.solver_sigma) {
      //   this.solverSigma = data.companySettings.solver_sigma;
      //   this.sigmaValue = [data.companySettings.solver_sigma];
      // }

      this.patchFormForLoad();
    });

  }

  initializeSlider() {
    this.durationOptions = {
      step: 15,
      start: [120],
      connect: 'lower',
      range: {
        min: 0,
        max: 1800
      },
    };
    this.defaultWorkingHoursOptions = {
      step: 15,
      start: [480],
      connect: 'lower',
      range: {
        min: 60,
        max: 720
      },
    };
    this.reloadTimeOptions = {
      step: 5,
      start: [30],
      connect: 'lower',
      range: {
        min: 15,
        max: 90
      },
    };
    // this.sigmaOptions = {
    //   step: 0.1,
    //   start: [1.5],
    //   connect: 'lower',
    //   range: {
    //     min: 0.8,
    //     max: 1.5
    //   },
    // };
    // this.timeWindowOptions = {
    //   margin: 30, // how many minutes between start and stop
    //   step: 15,
    //   start: [480, 1200],
    //   connect: [false, true, false],
    //   range: {
    //     min: 0,
    //     max: 1439
    //   },
    // };
  }

  convertMinutesToTime() {
    let hours = 0, minutes = 0;
    hours = this.convertToHour(this.myForm.value.companySettings.defaultWorkingHoursMinutes);
    minutes = this.convertToMinute(this.myForm.value.companySettings.defaultWorkingHoursMinutes, hours);
    this.defaultWorkingHoursUnformatted = this.formatHoursAndMinutes(hours, minutes);
  }

  convertSecondsToTime() {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.myForm.value.companySettings.durationSeconds);
    seconds = this.convertToMinute(this.myForm.value.companySettings.durationSeconds, minutes);
    this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  }

  showReloadTimeMinutes() {
    this.reloadTimeUnformatted = this.myForm.value.companySettings.reloadTimeMinutes + this.minuteLabel;
  }

  convertToHour(value) {
    return Math.floor(value / 60);
  }

  convertToMinute(value, hour) {
    return value - hour * 60;
  }

  formatHoursAndMinutes(hours, minutes) {
    if (hours.toString().length === 1) {
      hours = '0' + hours;
    }
    if (minutes.toString().length === 1) {
      minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
  }

  scrollFix() {
    // window.scrollTo(0, 0);
  }

  submitWizardForm() {
    const companyFormValues = this.myForm.value.company;
    const companySettingsFormValues = this.myForm.value.companySettings;

    let defaultWorkingHoursMinutes;
    if (Array.isArray(companySettingsFormValues.defaultWorkingHoursMinutes)) {
      defaultWorkingHoursMinutes = companySettingsFormValues.defaultWorkingHoursMinutes[0];
    } else {
      defaultWorkingHoursMinutes = companySettingsFormValues.defaultWorkingHoursMinutes;
    }
    this.defaultWorkingHours = moment.duration(defaultWorkingHoursMinutes, 'minutes').toISOString();

    let durationSeconds;
    if (Array.isArray(companySettingsFormValues.durationSeconds)) {
      durationSeconds = companySettingsFormValues.durationSeconds[0];
    } else {
      durationSeconds = companySettingsFormValues.durationSeconds;
    }
    this.duration = moment.duration(durationSeconds, 'seconds').toISOString();

    let reloadTimeMinutes;
    if (Array.isArray(companySettingsFormValues.reloadTimeMinutes)) {
      reloadTimeMinutes = companySettingsFormValues.reloadTimeMinutes[0];
    } else {
      reloadTimeMinutes = companySettingsFormValues.reloadTimeMinutes;
    }
    this.reloadDurationAtIntermediateDepot = moment.duration(reloadTimeMinutes, 'minutes').toISOString();

    // let sigma;
    // if (Array.isArray(companySettingsFormValues.sigma)) {
    //   sigma = companySettingsFormValues.sigma[0];
    // } else {
    //   sigma = companySettingsFormValues.sigma;
    // }
    // this.solverSigma = sigma;

    const departureTimeElement = document.querySelector('#departure-time-settings-form');
    const departureTime = M.Timepicker.getInstance(departureTimeElement).time;
    const today = moment().format('YYYY-MM-DD');
    if (moment(departureTime, 'HH:mm', true).isValid()) {
      const departureDatetimeUnformatted = today + ' ' + departureTime;
      this.defaultDepartureDatetime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format();
      this.defaultDepartureTime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format('HH:mm');
      this.defaultDepartureTimezone = moment.tz.guess();
    }

    // TODO change to constants
    if (companySettingsFormValues.portalOn) {
      // if (companySettingsFormValues.portalPlusDefault) {
      //   this.portalAccessDefault = 2;
      //   // this.portalAccessDefault = this.globals.portalAccessConstants['ACCESS_WITH_HISTORY'];
      // } else {
      this.portalAccessDefault = 1;
      // this.portalAccessDefault = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
      // }
    } else {
      this.portalAccessDefault = 0;
      // this.portalAccessDefault = this.globals.portalAccessConstants['NO_ACCESS'];
    }

    this.patchForm();
    const myObserver = {
      next: (response) => {
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      },
    };

    const companyUrl = 'api/v1/companies/' + this.globals.companyId;
    this.http.put(companyUrl, this.myForm.value).subscribe(myObserver);
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.MINUTE_LABEL').subscribe((res: string) => {
      this.minuteLabel = res;
    }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.loadData();

    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false
    });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
