import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LMTextModule } from '../shared/controls/input/text/text.component';
import { LMRadioModule } from '../shared/controls/input/radio/radio.component';
import { LMCalendarModule } from '../shared/controls/input/calendar/calendar.component';
import { LMCheckboxModule } from '../shared/controls/input/checkbox/checkbox.component';
import { LMSwitchModule } from '../shared/controls/input/switch/switch.component';
import { LMSliderModule } from '../shared/controls/input/slider/slider.component';
import { LmTableModule } from './structure/table/table.component';
import { LmButtonModule } from './structure/button/button.component';
import { LmHeaderModule } from './structure/header/lm-header.component';
import { LMTableListModule } from './structure/table-list/table-list.component';
import { LMDialogModule } from './containers/dialog/dialog.component';
import { LMPanelModule } from './containers/panel/panel.component';
import { LMLoadingModule } from './misc/loading/loading.component';
import {LmSelect2Module} from '../shared/controls/input/select2/select.component'
import { LmSelectTypeAhead2Module } from './controls/input/select2/select-typeahead.component';
import { LmHeaderActionsModule } from './controls/header-actions/header-actions.component';
import { LMAutoCompleteModule } from './controls/input/auto-complete/auto-complete.component';
import { LMNumberModule } from './controls/input/number/number.component';
import { LmFormTableModule } from './structure/form-table/form-table.component';
import { LmMultiSelectModule } from './controls/input/multi-select/multi-select.component';
import { LmDropdownModule } from './controls/input/dropdown/dropdown.component';
import { LmDropdownDoubleModule } from './controls/input/dropdown-double/dropdown-double.component';
import { LmBarChartModule } from './charts/bar-chart.component';
import { LmPieChartModule } from './charts/pie-chart.component';
import { LmKnobModule } from './charts/knob/knob.component';
import { LMPasswordModule } from './controls/input/password/password.component';
import { LmTestIdTagGeneratorModule } from './directives/testi-id-tag-generator.directive';
import { LmStringifyPipedModule } from './pipes/stringify.pipe';
import { LmSwipeDirectiveModule } from './directives/swipe.directive';
import { LmDoughnutChartModule } from './charts/doughnut-chart.component';
import { LmBarChartWidgetModule } from './structure/widgets/barchart-widget.component';
import { LmPieChartWidgetModule } from './structure/widgets/piechart-widget.component';
import { LmDoughnutChartWidgetModule } from './structure/widgets/douhnutchart-widget.component';
import { LmSemiCircleChartModule } from './charts/semicircle-chart.component';
import { LmSemiCircleChartWidgetModule } from './structure/widgets/semicirclechart-widget.component';
import { LmProgressCardModule } from './structure/progress-card/progress-card.component';
import { PaginatorModule } from 'primeng/paginator';
import { LmWidgetHeaderModule } from './structure/header/lm-widget-header.component';
import { LmResponsifyDirectiveModule } from './directives/responsify.directive';


// LicenseManager.setLicenseKey(environment.agGridLic);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginatorModule,
    LMTextModule,
    LMRadioModule,
    LMCalendarModule,
    LMCheckboxModule,
    LMSwitchModule,
    LMSliderModule,
    LmTableModule,
    LmButtonModule,
    LmHeaderModule,
    LMTableListModule,
    LMDialogModule,
    LMPanelModule,
    LMLoadingModule,
    LmSelect2Module,
    LmSelectTypeAhead2Module,
    LmHeaderActionsModule,
    LMAutoCompleteModule,
    LMNumberModule,
    LmFormTableModule,
    LmMultiSelectModule,
    LmDropdownModule,
    LmDropdownDoubleModule,
    LmWidgetHeaderModule,
    LmBarChartModule,
    LmBarChartWidgetModule,
    LmPieChartModule,
    LmPieChartWidgetModule,
    LmDoughnutChartModule,
    LmDoughnutChartWidgetModule,
    LmKnobModule,
    LmProgressCardModule,
    LMPasswordModule,
    LmTestIdTagGeneratorModule,
    LmStringifyPipedModule,
    LmSwipeDirectiveModule,
    LmResponsifyDirectiveModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginatorModule,
    LMTextModule,
    LMRadioModule,
    LMCalendarModule,
    LMCheckboxModule,
    LMSwitchModule,
    LMSliderModule,
    LmTableModule,
    LmButtonModule,
    LmHeaderModule,
    LMTableListModule,
    LMDialogModule,
    LMPanelModule,
    LMLoadingModule,
    LmSelect2Module,
    LmSelectTypeAhead2Module,
    LmHeaderActionsModule,
    LMAutoCompleteModule,
    LMNumberModule,
    LmFormTableModule,
    LmMultiSelectModule,
    LmDropdownModule,
    LmDropdownDoubleModule,
    LmWidgetHeaderModule,
    LmBarChartModule,
    LmBarChartWidgetModule,
    LmPieChartModule,
    LmPieChartWidgetModule,
    LmDoughnutChartModule,
    LmDoughnutChartWidgetModule,
    LmKnobModule,
    LmSemiCircleChartModule,
    LmSemiCircleChartWidgetModule,
    LmProgressCardModule,
    LMPasswordModule,
    LmTestIdTagGeneratorModule,
    LmSemiCircleChartWidgetModule,
    LmStringifyPipedModule,
    LmSwipeDirectiveModule,
    LmResponsifyDirectiveModule
  ],
  declarations: []
})
export class LmSharedModule {}
