import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';

import { LmHeaderAction } from '../header-actions/header-actions.component';

@Component({
  selector: 'lm-input-actions',
  templateUrl: './input-actions.component.html',
  styleUrls: ['./input-actions.component.scss']
})
export class LmInputActionsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() actions: LmHeaderAction | LmHeaderAction[];
  @Input() disabled: boolean;

  action: LmHeaderAction;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.actions) {
      this.action = Array.isArray(changes.actions.currentValue) ? null : changes.actions.currentValue;
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, MenuModule, RippleModule],
  exports: [LmInputActionsComponent],
  declarations: [LmInputActionsComponent]
})
export class LmInputActionsModule {}
