import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StepsService } from '@app/services/steps.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
// import { TimePickerComponent } from '@app/time-picker/time-picker.component';

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss']
})
export class CalendarModalComponent implements OnInit, OnDestroy {
  // @ViewChild(TimePickerComponent, { static: false }) timePickerComponent: TimePickerComponent;

  projectTitle: String = '';
  isClickedOnce = false;
  listen = [];
  projectId = null;
  defaultDepartureTime = '';

  projects = [];
  selectedProjectId = null;

  // time;

  constructor(
    private http: HttpClient,
    private stepsService: StepsService,
    public router: Router,
    public globals: Globals,
  ) {
    this.listen.push(this.stepsService.transmitProjectProblemDataToModalsListen().subscribe((modalView) => {
      if (modalView === 'calendar') {
        this.initPickers();
        this.toggleModal();
      }
    }));
  }

  initPickers() {
    const dateElement = document.querySelectorAll('.datepicker');
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const options = {
      setDefaultDate: true,
      defaultDate: tomorrow
    };
    const dateInstances = M.Datepicker.init(dateElement, options);

    const defaultTime = moment(this.defaultDepartureTime).format('HH:mm');
    // this.timePickerComponent.setTime(defaultTime);
    const departureTimeElement = <HTMLInputElement>document.querySelector('.timepicker');
    const timeInstance = M.Timepicker.init(departureTimeElement, {
      twelveHour: false,
      defaultTime: defaultTime
    });
    departureTimeElement.value = defaultTime;

    // exclusive to dashboard
    if (this.router.url.split('/')[1] == 'dashboard') {
      departureTimeElement.value = '08:00';
    }

    M.updateTextFields();
  }

  // for dashboard
  changeProjectSelect(selectedProjectId) {
    this.selectedProjectId = selectedProjectId;
  }

  toggleModal() {
    const modal = document.querySelector('#create-project-first-step-modal');
    const modalBackground = document.querySelector('.custom-project-problem-modal-background');
    modal.classList.toggle('closed');
    modal.classList.toggle('open');
    modalBackground.classList.toggle('hidden');
    // if(modal.classList.contains('closed')){
    // this.timePickerComponent.resetTimePicker();
    // }
  }

  loadProject() { }

  createProject() {
    this.isClickedOnce = true;
    let projectProblemId = null;

    const dateElement = document.querySelector('#project-date');
    const departureTimeElement = document.querySelector('#project-problem-departure-time');
    const date = M.Datepicker.getInstance(dateElement).date;
    const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:SS').format('YYYY-MM-DD');

    let departureTime = M.Timepicker.getInstance(departureTimeElement).time;
    if (!departureTime) { departureTime = moment(this.defaultDepartureTime).format('HH:mm'); }
    const unformattedDatetime = formattedDate + ' ' + departureTime;
    // const unformattedDatetime = formattedDate + ' ' + this.timePickerComponent.selectedTime;
    const formattedDatetime = moment(unformattedDatetime, 'YYYY-MM-DD HH:mm').utc().format().replace('Z', '+00:00');
    const httpData = {
      project: {
        id: 1,
        problem: {
          departure_datetime: formattedDatetime,
          title: this.projectTitle,
        }
      }
    };
    const myObserver = {
      next: (response) => {
        const responseData = response['item'];
        projectProblemId = responseData.projectProblem.id;
      },
      error: (error) => {
        console.error(error);
        this.isClickedOnce = false;
      },
      complete: () => {
        this.toggleModal();
        this.isClickedOnce = false;
        this.router.navigateByUrl('/projects/projectView/' + projectProblemId);
      },
    };

    if (this.router.url.split('/')[1] == 'dashboard') {
      this.http.post('api/v1/project/' + this.selectedProjectId + '/problem', httpData).subscribe(myObserver);
    } else {
      this.http.post('api/v1/project/' + this.projectId + '/problem', httpData).subscribe(myObserver);
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
