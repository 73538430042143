import { Injectable } from '@angular/core';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { ColourService } from '@app/services/colour.service';
import { FulfillmentUtils } from './fulfillment-event-utils';
import { StopPointUtils } from './stop-point-utils';

@Injectable({
    providedIn: 'root'
})
export class ProjectProblemViewUtils {

    constructor(
        public projectProblemDataService: ProjectProblemDataService,
        public globals: Globals,
        private colourService: ColourService,
        private fulfillmentUtils: FulfillmentUtils,
        private stopPointUtils: StopPointUtils,
    ) { }

    calculateSolutionData(routeStartTime = null) {
        if (!routeStartTime) {

        }
        const solutionDataPerRouteSettingId = {};
        const firstPointInRouteDatetimesArray = [];
        const lastPointInRouteDatetimesArray = [];
        const polylinesArray = [];
        let currentSingleRouteDistance = 0, maxCurrentRouteDurationHours = 0, currentRoutesDistance = 0, totalRouteDistance = 0, firstDatetime, lastDatetime, firstDatetimeMoment, lastDatetimeMoment;
        if (this.projectProblemDataService.solutionData) {
            if (this.projectProblemDataService.sequenceArrayPerRouteSettingId && this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId']) {
                Object.keys(this.projectProblemDataService.sequenceArrayPerRouteSettingId).forEach(routeSettingId => {
                    const sequenceArray = this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId];

                    solutionDataPerRouteSettingId[routeSettingId] = {
                        sequence: sequenceArray
                    };
                });
                const solutionInfoByRouteSettingId = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'];
                let currentElement, firstPointInRouteId, lastPointInRouteId;
                const currentRouteDurationHours = [];
                Object.keys(solutionInfoByRouteSettingId).forEach(routeSettingId => {
                    const stopPointsData = solutionInfoByRouteSettingId[routeSettingId];
                    const routeIndex = this.projectProblemDataService.getRouteIndexByRouteSettingId(routeSettingId);
                    currentSingleRouteDistance = 0;
                    let lastCompletedStopPointId = null;
                    if (Object.keys(stopPointsData).length) {
                        solutionDataPerRouteSettingId[routeSettingId] = {
                            sequence: solutionDataPerRouteSettingId[routeSettingId]['sequence'],
                            stopPoints: stopPointsData,
                        };
                        firstPointInRouteId = solutionDataPerRouteSettingId[routeSettingId].sequence[0];
                        lastPointInRouteId = solutionDataPerRouteSettingId[routeSettingId].sequence[solutionDataPerRouteSettingId[routeSettingId].sequence.length - 1];
                        firstDatetime = solutionDataPerRouteSettingId[routeSettingId].stopPoints[firstPointInRouteId].latestEstimatedArrivalDatetime;
                        lastDatetime = solutionDataPerRouteSettingId[routeSettingId].stopPoints[lastPointInRouteId].latestEstimatedArrivalDatetime;
                        firstPointInRouteDatetimesArray.push(firstDatetime);
                        lastPointInRouteDatetimesArray.push(lastDatetime);

                        firstDatetimeMoment = moment(firstDatetime);
                        lastDatetimeMoment = moment(lastDatetime);

                        totalRouteDistance += solutionDataPerRouteSettingId[routeSettingId].stopPoints[lastPointInRouteId].cumulativeDistance;

                        this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId].forEach(stopPointId => {
                            currentElement = stopPointsData[stopPointId];
                            if (currentElement.polyline) {
                                polylinesArray.push({
                                    encodedPolyline: currentElement.polyline,
                                    colour: this.colourService.colourCalculator(routeIndex),
                                    orderIndex: currentElement.orderIndex,
                                    routeIndex: routeIndex,
                                    routeSettingId: routeSettingId
                                });
                            }
                            if (this.projectProblemDataService.stopPoints[stopPointId]) {
                                // the last point in route that is cancelled or completed and the driver has arrived at it
                                if (
                                    this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_status'] === this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] ||
                                    this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_status'] === this.globals.stopPointFulfillmentStatusConstants['CANCELED']
                                ) {
                                    if (this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_events']) {
                                        this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_events'].forEach(event => {
                                            if (event.reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]['AT_TIME']) {
                                                // the current route distance is the cumulative distance up to the last completed point on the route (with the largest sequence)
                                                currentSingleRouteDistance = solutionDataPerRouteSettingId[routeSettingId].stopPoints[stopPointId].cumulativeDistance;
                                                lastCompletedStopPointId = stopPointId;
                                            }
                                        });
                                    } else {
                                        console.warn('Stop point with id' + stopPointId + ' in route setting with id ' + routeSettingId + ' does not have fulfillment events');
                                        console.warn(this.projectProblemDataService.stopPoints[stopPointId]);
                                    }
                                }
                            }
                        });
                    }
                    currentRoutesDistance += currentSingleRouteDistance;
                    if (lastCompletedStopPointId) {
                        // calculate the route duration up until now (to the last completed sp)
                        const departureDatetimeDuration = this.projectProblemDataService.routeSettingsById[routeSettingId]['routeSetting']['start_offset_from_departure'];
                        const departureDatetimeDurationMinutes = moment.duration(departureDatetimeDuration).asMinutes();

                        const lastStopData = this.projectProblemDataService.stopPoints[lastCompletedStopPointId]
                        let lastStopPointArrivalTime = moment(lastStopData.solution.latest_estimated_arrival_datetime);

                        // if the last completed stop point is the last stop point in route
                        // count the distance and the time as if the final depot is completed
                        const indexOfLastCompletedStopPoint = solutionDataPerRouteSettingId[routeSettingId].sequence.indexOf(Number(lastCompletedStopPointId));
                        if (indexOfLastCompletedStopPoint === solutionDataPerRouteSettingId[routeSettingId].sequence.length - 2) {
                            currentSingleRouteDistance = solutionDataPerRouteSettingId[routeSettingId].stopPoints[lastPointInRouteId].cumulativeDistance;
                            const lastDepotData = this.projectProblemDataService.stopPoints[lastPointInRouteId];
                            if (lastDepotData) {
                                if (lastDepotData.solution.latest_estimated_arrival_datetime) {
                                    if (Array.isArray(lastDepotData.solution.latest_estimated_arrival_datetime)) {
                                        lastStopPointArrivalTime = moment(lastDepotData.solution.latest_estimated_arrival_datetime[routeIndex]);
                                    } else {
                                        lastStopPointArrivalTime = moment(lastDepotData.solution.latest_estimated_arrival_datetime);
                                    }
                                }
                            }
                        }
                        if (!routeStartTime) {
                            routeStartTime = moment(firstDatetime).utc();
                        }
                        currentRouteDurationHours.push(moment.duration((moment(lastStopPointArrivalTime).utc()).diff(routeStartTime.utc())).asHours());
                    }
                });
                if (currentRouteDurationHours.length) {
                    maxCurrentRouteDurationHours = Math.max(...currentRouteDurationHours);
                }
            }
        }
        const solutionData = {
            currentRoutesDistance: currentRoutesDistance,
            maxCurrentRouteDurationHours: maxCurrentRouteDurationHours,
            totalRouteDistance: totalRouteDistance,
            firstPointInRouteDatetimesArray: firstPointInRouteDatetimesArray,
            lastPointInRouteDatetimesArray: lastPointInRouteDatetimesArray,
            polylinesArray: polylinesArray,
        };
        return solutionData;
    }

    calculateStopPointsCount() {
        let stopPointsCount = 0;
        let completedStopPointsCount = 0;
        let completedAndCancelledStopPointsCount = 0;
        Object.keys(this.projectProblemDataService.stopPoints).forEach(stopPointId => {
            if (
                this.projectProblemDataService.stopPoints[stopPointId].related_to === this.globals.stopPointRelatedToConstants['SELF']
                && this.projectProblemDataService.stopPoints[stopPointId].entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE']
            ) {
                stopPointsCount++;
                if (this.projectProblemDataService.stopPoints[stopPointId].fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
                    completedStopPointsCount++;
                    completedAndCancelledStopPointsCount++;
                } else if (this.projectProblemDataService.stopPoints[stopPointId].fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['CANCELED']) {
                    completedAndCancelledStopPointsCount++;
                }
            }
        });
        return {
            stopPointsCount: stopPointsCount,
            completedStopPointsCount: completedStopPointsCount,
            completedAndCancelledStopPointsCount: completedAndCancelledStopPointsCount
        }
    }

    calculateFoodStopPointsCount() {
        let stopPointsCount = 0;
        let completedStopPointsCount = 0;
        let completedAndCancelledStopPointsCount = 0;
        Object.keys(this.projectProblemDataService.stopPoints).forEach(stopPointId => {
            if (
                this.projectProblemDataService.stopPoints[stopPointId].related_to === this.globals.stopPointRelatedToConstants['SELF']
                && this.projectProblemDataService.stopPoints[stopPointId].entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE']
                && this.projectProblemDataService.stopPoints[stopPointId].service_type === this.globals.stopPointServiceTypeConstants['DELIVERY']
            ) {
                stopPointsCount++;
                if (this.projectProblemDataService.stopPoints[stopPointId].fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
                    completedStopPointsCount++;
                    completedAndCancelledStopPointsCount++;
                } else if (this.projectProblemDataService.stopPoints[stopPointId].fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['CANCELED']) {
                    completedAndCancelledStopPointsCount++;
                }
            }
        });
        return {
            stopPointsCount: stopPointsCount,
            completedStopPointsCount: completedStopPointsCount,
            completedAndCancelledStopPointsCount: completedAndCancelledStopPointsCount
        }
    }

    calculateRouteDurationSumHours(solutionData) {
        const firstPointInRouteDatetimesArray = solutionData.firstPointInRouteDatetimesArray;
        const lastPointInRouteDatetimesArray = solutionData.lastPointInRouteDatetimesArray;
        let start, end;
        let routeDurationSumHours = 0;
        // this.currentRouteDurationHours = 0;
        firstPointInRouteDatetimesArray.forEach((element, index) => {
            start = moment(element);
            end = moment(lastPointInRouteDatetimesArray[index]);
            routeDurationSumHours += moment.duration(end.diff(start)).asHours();
        });
        return routeDurationSumHours;
    }

    getStopPointDataForMap(currentStopPoint, projectProblemDayOfWeek, maximumWeight, defaultTimeWindow, isOptimized) {
        let data = null;
        let lat, lon, id, locationIndex, markerColour, editColours = [], enabled, priority, arrivalTime, recurring;
        let complete = false, cancelled = false, dropped = false, cancelledByRecipient = false, merged = false, mergedToText = '';
        // let droppedStopsCount = 0, limitedTimeWindowStopsCount = 0;

        if (currentStopPoint.stopPoint) { currentStopPoint = currentStopPoint.stopPoint; }

        if (currentStopPoint) {
            // if sp is not an intermediate depot
            if (currentStopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {

                id = currentStopPoint.id;

                lat = currentStopPoint.address.lat;
                lon = currentStopPoint.address.lon;
                // if there is a be fix for sps in the same position overlapping, show the fixed lat-lon
                if (currentStopPoint.address.display_address) {
                    lat = currentStopPoint.address.display_address.lat;
                    lon = currentStopPoint.address.display_address.lon;
                }

                priority = currentStopPoint.priority === this.globals.stopPointPriorityConstants['HIGH'] ? true : false;


                // dropped status
                if (
                    currentStopPoint.fulfillment_events[0].reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['DROPPED']].AT_TIME &&
                    currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE']
                ) {
                    // droppedStopsCount++;
                    dropped = true;
                }
                // completed status
                // currentStopPoint.fulfillment_events[0] === this.globals.stopPointFulfillmentEventConstants[11].COMPLETED_HANDED
                else if (currentStopPoint.fulfillment_events[0].reason === this.globals.stopPointFulfillmentEventConstants[7].AT_TIME ||
                    currentStopPoint.fulfillment_events[0].reason === this.globals.stopPointFulfillmentEventConstants[7].COMPLETED_HANDED
                ) {
                    complete = true;
                }
                // canceled status
                else if (this.fulfillmentUtils.cancelReasons.includes(currentStopPoint.fulfillment_events[0].reason)) {
                    cancelled = true;
                    // if the stop was cancelled by the recipient, make the cancelled:false and cancelledByRecipient:true
                    if (currentStopPoint.fulfillment_events) {
                        currentStopPoint.fulfillment_events.forEach(event => {
                            if (event.reason > 799 && event.application_type === this.globals.applicationTypes['CLIENT_PORTAL_APP']) {
                                cancelled = false;
                                cancelledByRecipient = true;
                            }
                        });
                    }
                }

                editColours = [];
                recurring = false;
                if (currentStopPoint.customer) {
                    if (currentStopPoint.customer.reappears) {
                        currentStopPoint.customer.reappears.forEach(reappear => {
                            if (reappear.weekday === projectProblemDayOfWeek && reappear.reappear) {
                                recurring = true;
                            }
                        });
                    }
                }

                const loads = this.stopPointUtils.getPickupAndDeliveryLoad({
                    stopPoint: currentStopPoint,
                    relatedStopPoint: this.projectProblemDataService.stopPoints[currentStopPoint?.related_stop_point_id]
                });

                const weight = loads.deliveryLoad ?? loads.pickupLoad ?? 0;

                if (lat && lon) {
                    const stopPointSolutionData = this.projectProblemDataService.stopPointSolutionData ? this.projectProblemDataService.stopPointSolutionData : null;
                    enabled = true;
                    if (currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['DISABLED']) {
                        enabled = false;
                    }
                    let editSequenceStopPoints = null, editDirectSequenceStopPoints = null, edited = false;
                    let timeWindowState = this.calculateTimeWindowState(currentStopPoint.time_windows, defaultTimeWindow);
                    if (currentStopPoint.error_status === this.globals.stopPointErrorStatusConstants['ERROR']) {
                        markerColour = '#666666';
                        locationIndex = null;
                        // if (timeWindowState === 'limited' || timeWindowState === 'late') { limitedTimeWindowStopsCount++; }

                        const modifications = this.projectProblemDataService.stopPointModifications[id];
                        if (modifications) {
                            if (modifications.routeIndex) {
                                modifications.routeIndex.forEach(routeIndex => {
                                    editColours.push(this.colourService.colourCalculator(routeIndex));
                                });
                            }
                            if (modifications.sequence) {
                                if (modifications.sequence.before.length || modifications.sequence.after.length) {
                                    editSequenceStopPoints = {
                                        beforeIds: [],
                                        afterIds: []
                                    };
                                    editSequenceStopPoints.beforeIds = modifications.sequence['before'];
                                    editSequenceStopPoints.afterIds = modifications.sequence['after'];
                                }
                            }
                            if (modifications.directSequence) {
                                if (modifications.directSequence.before.length || modifications.directSequence.after.length) {
                                    editDirectSequenceStopPoints = {
                                        beforeIds: [],
                                        afterIds: []
                                    };
                                    editDirectSequenceStopPoints.beforeIds = modifications.directSequence['before'];
                                    editDirectSequenceStopPoints.afterIds = modifications.directSequence['after'];
                                }
                            }
                        }
                    } else if (
                        currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE'] ||
                        currentStopPoint.entity_status === this.globals.stopPointEntityStatusConstants['DISABLED']
                    ) {
                        arrivalTime = null;
                        locationIndex = null;
                        markerColour = '#00aeba';
                        if (stopPointSolutionData[id]) {
                            locationIndex = stopPointSolutionData[id].sequence;
                            markerColour = this.colourService.colourCalculator(stopPointSolutionData[id].routeIndex);
                        }
                        if (currentStopPoint.solution) {
                            arrivalTime = currentStopPoint.solution.latest_estimated_arrival_datetime;
                        }
                        if (currentStopPoint.fulfillment_events) {
                            currentStopPoint.fulfillment_events.forEach(event => {
                                if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']]['AT_TIME']) {
                                    arrivalTime = event.fulfillment_datetime;
                                }
                            });
                        }
                        if (currentStopPoint.mergeData) {
                            // if stop is merged in another partner stop
                            if (currentStopPoint.mergeData.mergedStopPointId) {
                                merged = true;
                                // find partner sequence
                                // if (this.optimizationState === this.optimizationStates['OPTIMIZED']) {
                                if (isOptimized) {
                                    const targetStopPointId = currentStopPoint.mergeData.mergedStopPointId;
                                    if (stopPointSolutionData[targetStopPointId]) {
                                        mergedToText = stopPointSolutionData[targetStopPointId].sequence;
                                        markerColour = this.colourService.colourCalculator(stopPointSolutionData[targetStopPointId].routeIndex);
                                    }
                                    // find if partner is recurring
                                    if (this.projectProblemDataService.stopPointIndexInArray[targetStopPointId]) {
                                        const index = this.projectProblemDataService.stopPointIndexInArray[targetStopPointId];
                                        const targetStopPointData = this.projectProblemDataService.stopPointsArray[index];
                                        if (targetStopPointData.customer) {
                                            if (targetStopPointData.customer.reappears) {
                                                targetStopPointData.customer.reappears.forEach(reappear => {
                                                    if (reappear.weekday === projectProblemDayOfWeek && reappear.reappear) {
                                                        // mergedToText = 'R';
                                                        recurring = true;
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        const modifications = this.projectProblemDataService.stopPointModifications[id];
                        if (modifications) {
                            if (modifications.routeIndex) {
                                modifications.routeIndex.forEach(routeIndex => {
                                    editColours.push(this.colourService.colourCalculator(routeIndex));
                                });
                            }
                            if (modifications.sequence) {
                                if (modifications.sequence.before.length || modifications.sequence.after.length) {
                                    editSequenceStopPoints = {
                                        beforeIds: [],
                                        afterIds: []
                                    };
                                    editSequenceStopPoints.beforeIds = modifications.sequence['before'];
                                    editSequenceStopPoints.afterIds = modifications.sequence['after'];
                                }
                            }
                            if (modifications.directSequence) {
                                if (modifications.directSequence.before.length || modifications.directSequence.after.length) {
                                    editDirectSequenceStopPoints = {
                                        beforeIds: [],
                                        afterIds: []
                                    };
                                    editDirectSequenceStopPoints.beforeIds = modifications.directSequence['before'];
                                    editDirectSequenceStopPoints.afterIds = modifications.directSequence['after'];
                                }
                            }
                        }

                        timeWindowState = this.calculateTimeWindowState(currentStopPoint.time_windows, defaultTimeWindow, arrivalTime);
                        // if (timeWindowState === 'limited' || timeWindowState === 'late') { limitedTimeWindowStopsCount++; }
                    }

                    edited = (editColours.length || editSequenceStopPoints || editDirectSequenceStopPoints) ? true : false;

                    // stopZoomLevel is the minimum zoom level that the sp is visible
                    // 20 is the full zoom in and 0 is full zoom out
                    // so, in any level less than the max, the stop will be visible
                    // if we have stopZoomLevel=9, the stop will be visible in zoom levels 8 to 0 (far zoom out)
                    let stopZoomLevel = null;
                    if (currentStopPoint.zoom_levels) {
                        currentStopPoint.zoom_levels.forEach(zoomLevel => {
                            if (stopZoomLevel < zoomLevel.level || !stopZoomLevel) {
                                stopZoomLevel = zoomLevel.level;
                            }
                        });
                    }

                    // NOTE stop point
                    data = {
                        id: id,
                        smartPoint: currentStopPoint?.smartPoint ?? currentStopPoint?.pudoPoint,
                        colour: markerColour,
                        editColours: editColours,
                        editSequenceStopPoints: editSequenceStopPoints,
                        editDirectSequenceStopPoints: editDirectSequenceStopPoints,
                        lat: lat,
                        lon: lon,
                        sequence: locationIndex,
                        enabled: enabled,
                        priority: priority,
                        overweight: this.calculateWeightState(weight, maximumWeight),
                        timeWindowState: timeWindowState,
                        edited: edited,
                        recurring: recurring,
                        complete: complete,
                        cancelled: cancelled,
                        dropped: dropped,
                        cancelledByRecipient: cancelledByRecipient,
                        merged: merged,
                        mergedToText: mergedToText,
                        maxZoomLevel: stopZoomLevel,
                    };

                    // markersToAdd.push(defaultMarker);
                    // this.projectProblemDataService.stopPointsMarkerData[id] = data;

                    // this.mapComponent.addStopPoint(data, bulk);
                }
            }
        }
        // droppedStopsCount
        return data;
    }

    calculateTimeWindowState(timeWindows, defaultTimeWindow, arrivalTime = null) {
        const timeWindowDurationMinutes = [];
        let timeWindowRange, timeWindowRangeMinutes;
        // let timeWindowStartOffsetFromDeparture, timeWindowStartOffsetFromDepartureMinutes;
        const timeWindowStartArray = [], timeWindowEndArray = [];

        timeWindows.forEach((timeWindow, index) => {
            timeWindowRange = timeWindow.time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowDurationMinutes.push(timeWindowRangeMinutes);
            timeWindowStartArray.push(moment(timeWindow.start, 'HH:mm:SS').format());
            timeWindowEndArray.push(moment(timeWindowStartArray[index]).add(timeWindowRangeMinutes, 'minutes').format());
        });

        const minimumDurationMinutes = Math.min(...timeWindowDurationMinutes);
        // const defaultTimeWindowOffsetMinutes = moment.duration(defaultTimeWindow[0].time_window_start_offset_from_departure).asMinutes();
        // const timeWindowOffsetMinutes = moment.duration(timeWindows[0].time_window_start_offset_from_departure).asMinutes();
        const defaultTimeWindowStartMoment = moment(defaultTimeWindow[0].start, 'HH:mm:SS');
        const defaultTimeWindowRangeMinutes = moment.duration(defaultTimeWindow[0].time_window_range).asMinutes();
        const firstTimeWindowRangeMinutes = moment.duration(timeWindows[0].time_window_range).asMinutes();
        const timeWindowStartMoment = moment(timeWindows[0].start, 'HH:mm:SS')
        if (
            firstTimeWindowRangeMinutes >= defaultTimeWindowRangeMinutes &&
            (moment(timeWindowStartMoment).isBefore(defaultTimeWindowStartMoment) || moment(timeWindowStartMoment).isSame(defaultTimeWindowStartMoment))
        ) {
            return 'default';
        } else {
            if (!this.globals.isLargeClockDisabled && arrivalTime) {
                let timeWindowEndToArrivalMinutes = null, timeWindowStartToArrivalMinutes = null;
                const timeWindowEndToArrivalMinutesArray = [];
                const timeWindowStartToArrivalMinutesArray = [];
                const arrivalTimeFormatted = moment(arrivalTime).format('HH:mm:SS');
                const arrivalTimeMoment = moment(arrivalTimeFormatted, 'HH:mm:SS');
                timeWindowEndArray.forEach(timeWindowEnd => {
                    const timeWindowEndMoment = moment(timeWindowEnd);
                    const timeWindowEndToArrival = moment.duration(timeWindowEndMoment.diff(arrivalTimeMoment));
                    timeWindowEndToArrivalMinutesArray.push(timeWindowEndToArrival.asMinutes());
                });
                timeWindowStartArray.forEach(timeWindowStart => {
                    const timeWindowStartMoment = moment(timeWindowStart);
                    const timeWindowArrivalToStart = moment.duration(arrivalTimeMoment.diff(timeWindowStartMoment));
                    timeWindowStartToArrivalMinutesArray.push(timeWindowArrivalToStart.asMinutes());
                });

                // check if the arrival time is between tw start and end and set the difference
                timeWindowStartToArrivalMinutesArray.forEach((currentTimeWindowStartToArrivalMinutes, index) => {
                    const currentTimeWindowEndToArrivalMinutes = timeWindowEndToArrivalMinutesArray[index];
                    if (currentTimeWindowStartToArrivalMinutes > 0 && currentTimeWindowEndToArrivalMinutes > 0) {
                        timeWindowStartToArrivalMinutes = currentTimeWindowStartToArrivalMinutes;
                        timeWindowEndToArrivalMinutes = currentTimeWindowEndToArrivalMinutes;
                    }
                });

                // if the arrival time is now between start and end, the difference is not set, so set it as the closest to zero
                if (!timeWindowStartToArrivalMinutes) {
                    timeWindowStartToArrivalMinutes = timeWindowStartToArrivalMinutesArray.reduce(function (prev, curr) {
                        // get the timeWindowEndToArrivalMinutes value closest to zero
                        return (Math.abs(curr) < Math.abs(prev) ? curr : prev);
                    });
                }
                if (!timeWindowEndToArrivalMinutes) {
                    timeWindowEndToArrivalMinutes = timeWindowEndToArrivalMinutesArray.reduce(function (prev, curr) {
                        // get the timeWindowEndToArrivalMinutes value closest to zero
                        return (Math.abs(curr) < Math.abs(prev) ? curr : prev);
                    });
                }
                if (
                    (timeWindowEndToArrivalMinutes <= 15 && timeWindowEndToArrivalMinutes >= -5) ||
                    (timeWindowStartToArrivalMinutes <= 15 && timeWindowStartToArrivalMinutes >= -5)
                ) {
                    return 'limited';
                } else if (timeWindowEndToArrivalMinutes < -5 || timeWindowStartToArrivalMinutes < -5) {
                    return 'late';
                }
            } else {
                if (minimumDurationMinutes < 3) {
                    return 'strict';
                }
            }
            return 'regular';
        }
    }

    calculateWeightState(weight, maximumWeight) {
        if (weight >= maximumWeight) {
            return true;
        } else {
            return false;
        }
    }

    calculateAverageWeight() {
        let maximumWeight = 0;
        const volumesArray = this.projectProblemDataService.volumesArray;
        const volumesArrayLength = volumesArray.length;
        volumesArray.sort(function (a, b) {
            return a - b;
        });
        if (volumesArray[0] === volumesArray[volumesArrayLength - 1]) {
            maximumWeight = volumesArray[0] + 1;
        } else {
            const maximumWeightPosition = volumesArrayLength - 1 - (Math.floor(volumesArrayLength / 10));
            maximumWeight = volumesArray[maximumWeightPosition] + 1;
        }

        return maximumWeight;
    }

    setDefaultTimeWindow() {
        // default is 8:00-20:00
        const defaultTimeWindow = [];
        let timeWindowRangeDuration;
        const defaultTimeWindowStartMoment = moment('08:00', 'HH:mm');
        const defaultTimeWindowEndMoment = moment('20:00', 'HH:mm');
        timeWindowRangeDuration = moment.duration(defaultTimeWindowEndMoment.diff(defaultTimeWindowStartMoment));
        const defaultTimeWindowRange = moment.duration(timeWindowRangeDuration.asMinutes(), 'minutes').toISOString();
        defaultTimeWindow.push({
            time_window_range: defaultTimeWindowRange,
            start: defaultTimeWindowStartMoment.format('HH:mm:SS')
        });
        return defaultTimeWindow;
    }
}
