import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class LmBrowserUtilsService {

  constructor() {
  }

  isMobileDevice(){
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) && "ontouchend" in document
  }

  isAndroidMobileDevice(){
    return (/Android/i.test(navigator.userAgent)) && "ontouchend" in document
  }

  isiOSMobileDevice(){
    return (/iPhone/i.test(navigator.userAgent)) && "ontouchend" in document
  }


  isSupportedMobileDevice(){
    return (/Android|iPhone|/i.test(navigator.userAgent)) && "ontouchend" in document
  }

}