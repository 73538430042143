import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalGridService {

  private updateVehicleGridListeners = new Subject<any>();
  private updateDriverGridListeners = new Subject<any>();
  private updateCustomerGridListeners = new Subject<any>();
  private updateInvoicesGridListeners = new Subject<any>();
  private updateCustomerCollaboratorsListeners = new Subject<any>();
  private updateMemberGridListeners = new Subject<any>();
  private closeRightModalListeners = new Subject<any>();
  private updateVouchersGridListeners = new Subject<any>();
  private openInvoicesGridListeners = new Subject<any>();

  listenUpdateVehicleGrid(): Observable<any> {
    return this.updateVehicleGridListeners.asObservable();
  }
  listenUpdateDriverGrid(): Observable<any> {
    return this.updateDriverGridListeners.asObservable();
  }
  listenUpdateCustomerGrid(): Observable<any> {
    return this.updateCustomerGridListeners.asObservable();
  }
  listenUpdateInvoicesGrid(): Observable<any> {
    return this.updateInvoicesGridListeners.asObservable();
  }
  listenUpdateCustomerCollaborators(): Observable<any> {
    return this.updateCustomerCollaboratorsListeners.asObservable();
  }
  listenUpdateMemberGrid(): Observable<any> {
    return this.updateMemberGridListeners.asObservable();
  }
  listenCloseRightModal(): Observable<any> {
    return this.closeRightModalListeners.asObservable();
  }
  listenUpdateVouchersGrid(): Observable<any> {
    return this.updateVouchersGridListeners.asObservable();
  }
  listenOpenInvoicesGrid(): Observable<any> {
    return this.openInvoicesGridListeners.asObservable();
  }
  

  updateVehicleGrid() {
    this.updateVehicleGridListeners.next(1);
  }
  updateDriverGrid() {
    this.updateDriverGridListeners.next(1);
  }
  updateInvoicesGrid() {
    this.updateInvoicesGridListeners.next(1);
  }
  updateCustomerGrid() {
    this.updateCustomerGridListeners.next(1);
  }
  updateCustomerCollaborators() {
    this.updateCustomerCollaboratorsListeners.next(1);
  }
  updateMemberGrid() {
    this.updateMemberGridListeners.next(1);
  }
  closeRightModal() {
    this.closeRightModalListeners.next(1);
  }
  updateVouchersGrid() {
    this.updateVouchersGridListeners.next(1);
  }
  openInvoicesGrid() {
    this.openInvoicesGridListeners.next(1);
  }
}
