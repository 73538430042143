import { GenericUtils } from '@app/utils/generic-utils';
import { AddressService } from '@app/services/address.service';
import { ModalService } from '@app/services/modal.service';
import { GenericService } from '@app/services/generic.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
// import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import moment from 'moment';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';

@Component({
    selector: 'app-shipments-modal',
    templateUrl: './shipments-modal.component.html',
    styleUrls: ['./shipments-modal.component.scss']
})
export class ShipmentsModalComponent implements OnInit, OnDestroy {
    // @Output() cancelChangeStopPointFulfillmentStatus = new EventEmitter<string>();

    gridApi;
    gridColumnApi;
    gridParams;
    domLayout = 'autoHeight';
    gridDataArray = [];
    rowData: any;
    columnDefs;
    canBeOpened = true;

    nameTitle;
    addressTitle;
    dateTitle;
    collaboratorTitle;
    voucherPayPriceTitle;
    noNameLabel;
    dimensionsLabel;
    payOnDeliveryLabel;
    currency;
    copyLabel;
    overviewType = 'projectReport'; // projectReport, driverReport
    driverPayments = [];

    listen = [];
    driverName;
    paymentsType;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        public gridsService: GridsService,
        public stopPointUtils: StopPointUtils,
        public modalService: ModalService,
        private addressService: AddressService,
        private genericUtils: GenericUtils,
        private viewProjectProblemService: ViewProjectProblemService,
    ) {
        this.listen.push(this.modalService.openShipmentsModalListen().subscribe((response) => {
            this.driverName = response['name'];
            this.paymentsType = response['type'];
            this.driverPayments = response['stopPoints'];
            this.overviewType = response['overviewType'];

            const stopPoints = [];
            this.driverPayments.forEach(stopPoint => {
                let stopPointMethodType;

                // get the paid by method type based on the which 'amount' property exists on the sp object
                if (stopPoint?.podAmount) {
                    stopPointMethodType = stopPoint['executed_pay_on_delivery_payment_method'];
                } else if (stopPoint?.recipientChargeAmount) {
                    stopPointMethodType = stopPoint['executed_payment_method_courier_fare'];
                }
                // (for cheque only) show cheque & cheque_day, too
                if (this.paymentsType == this.globals.paymentOptionsConstants['CHEQUE']) {
                    if (stopPointMethodType === this.globals.paymentOptionsConstants['CHEQUE'] || stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['DAY_CHEQUE']) {
                        stopPoints.push(stopPoint);
                    }
                }
                // (for every other method) show only if payment type matches the stopPoint's method
                else if (this.paymentsType == stopPointMethodType) {
                    stopPoints.push(stopPoint);
                }
            });
            this.driverPayments = [...stopPoints];

            this.setGridData(this.driverPayments);
            this.openModal();
        }));

        this.listen.push(this.modalService.updateReportShipmentsModalListen().subscribe(() => {
            this.closeModal();
        }));
    }

    openModal() {
        if (this.canBeOpened) {
            this.canBeOpened = false;
            const modal = document.querySelector('#shipments-modal');
            const modalBackground = document.querySelector('#shipments-modal-background');
            modal.classList.remove('closed');
            modal.classList.add('open');
            modalBackground.classList.remove('hidden');
        }
    }

    closeModal() {
        const modal = document.querySelector('#shipments-modal');
        const modalBackground = document.querySelector('#shipments-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');

        setTimeout(() => {
            this.emptyModal();
            this.canBeOpened = true;
        }, 1000);
    }

    emptyModal() {
        this.driverName = null;
        this.paymentsType = null;
        this.driverPayments = [];
        this.overviewType = '';
        this.emptyGridData();
    }

    setGridData(stopPoints) {
        let gridObject = {};
        this.gridDataArray = [];
        if (stopPoints.length) {
            stopPoints.forEach(stopPoint => {
                let payAmount = stopPoint.podAmount ? stopPoint.podAmount : stopPoint.recipientChargeAmount;
                gridObject = {
                    id: stopPoint.id,
                    copyLabel: this.copyLabel,
                    details: {
                        name: stopPoint.contact_name,
                        phone: stopPoint.telephone ?? '-',
                        barcode: this.stopPointUtils.getBarcodeOrVoucherHash(stopPoint)
                    },
                    collaborator: {
                        id: stopPoint.collaborator ? stopPoint.collaborator['collaboratorData']['id'] : null,
                        name: stopPoint.collaborator ? stopPoint.collaborator['collaboratorData']['collaborator_name'] : stopPoint.supplier,
                    },
                    datetime: {
                        date: moment(stopPoint.creation_datetime).format('DD MMM YYYY'),
                        time: moment(stopPoint.creation_datetime).format('HH:mm')
                    },
                    address: this.addressService.getAddressLabel(stopPoint.address),
                    dimensions: this.stopPointUtils.getStopPointVolumeWeightColumnData(stopPoint),
                    payAmount: `${payAmount} ${this.currency}`,
                    objData: stopPoint,
                };
                this.gridDataArray.push(gridObject);
            });
        } else {
            const noData = { noDataText: 'No data' }
            this.gridDataArray.push(noData);
        }
        this.rowData = of(this.gridDataArray);
    }
    emptyGridData() {
        this.gridDataArray = [{ noDataText: 'No data' }];
        this.rowData = of(this.gridDataArray);
    }

    onGridReady(params) {
        this.gridParams = params;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    rowClicked(event) {
        const target = event.event.target.closest('div');
        this.viewProjectProblemService.openStopModal(event.data.id, event.data.projectProblemId);
    }

    getContextMenuItems(params) {
        let rowData = params.node.data;
        let result = [
            {
                name: rowData.copyLabel,
                cssClasses: ['grid-context-menu-item'],
                action: () => {
                    navigator.clipboard.writeText(rowData.details.barcode);
                },
                disabled: rowData.details.barcode ? false : true
            }
        ];

        return result;
    }

    onFirstDataRendered(params) { }

    getTranslations() {
        // this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.DATE_CREATED').subscribe((res: string) => { this.dateTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.COLLABORATOR').subscribe((res: string) => { this.collaboratorTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.VOUCHER_PAY_PRICE').subscribe((res: string) => { this.voucherPayPriceTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.dimensionsLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.CASH').subscribe((res: string) => { this.payOnDeliveryLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.COPY_BARCODE').subscribe((res: string) => { this.copyLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));

        // set zones column width
        this.columnDefs = [
            {
                headerName: this.nameTitle,
                field: 'details',
                cellRenderer: this.gridsService.nameRenderer,
                width: this.gridsService.widthCalculatorContainerId(25, 'shipments-grid-container')
            },
            {
                headerName: this.collaboratorTitle,
                field: 'collaborator',
                cellRenderer: this.gridsService.collaboratorRenderer,
                width: this.gridsService.widthCalculatorContainerId(17, 'shipments-grid-container')
            },
            {
                headerName: this.dateTitle,
                field: 'datetime',
                cellRenderer: this.gridsService.dateTimeRenderer,
                width: this.gridsService.widthCalculatorContainerId(15, 'shipments-grid-container')
            },
            {
                headerName: this.addressTitle,
                field: 'address',
                cellRenderer: this.gridsService.addressRenderer,
                width: this.gridsService.widthCalculatorContainerId(20, 'shipments-grid-container')
            },
            {
                headerName: this.dimensionsLabel,
                field: 'dimensions',
                cellRenderer: this.gridsService.volumeWeightCountRenderer,
                width: this.gridsService.widthCalculatorContainerId(10, 'shipments-grid-container')
            },
            {
                headerName: this.payOnDeliveryLabel,
                field: 'payAmount',
                width: this.gridsService.widthCalculatorContainerId(13, 'shipments-grid-container')
            },
        ];
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));

        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}