import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    emptyAddressObj = {
        freeformAddress: '',
        lat: '',
        lon: '',
        countryCode: '',
        state: '',
        county: '',
        city: '',
        district: '',
        street: '',
        street2: '',
        houseNumber: '',
        postalCode: '',
        isPlace: false,
        placeName: ''
    };

    getAddressLabel(address) {
        let addressLabel = '';

        if (address.isPlace && address.placeName) {
            addressLabel += address.placeName;
        }

        if (addressLabel !== '' && address.street) { addressLabel += ', '; }

        if (address.street4) {
            addressLabel += address.street;
            addressLabel += ' & ' + address.street2;
            addressLabel += ' & ' + address.street3;
            addressLabel += ' & ' + address.street4;
        } else if (address.street3) {
            addressLabel += address.street;
            addressLabel += ' & ' + address.street2;
            addressLabel += ' & ' + address.street3;
        } else if (address.street2) {
            addressLabel += address.street;
            addressLabel += ' & ' + address.street2;
        } else if (address.street) {
            addressLabel += address.street;
        }
        if (address.houseNumber) {
            if (addressLabel !== '') { addressLabel += ' '; }
            addressLabel += address.houseNumber;
        }

        if (address.postalCode) {
            if (addressLabel !== '') { addressLabel += ', '; }
            addressLabel += address.postalCode;
        }
        if (address.district) {
            addressLabel += ' ' + address.district;
        }
        if (address.city) {
            addressLabel += ' ' + address.city;
        }
        if (address.county) {
            if (addressLabel !== '') { addressLabel += ', '; }
            addressLabel += address.county;
        }

        // if the address is in old format, use the value that was provided 
        if (addressLabel === '' && address.value) { addressLabel = address.value; }
        return addressLabel;
    }

    getAddressTitle(address) {
        let addressLabel = '';

        if (address.street) {
            addressLabel += address.street;
        }
        if (address.street2) {
            addressLabel += '& ' + address.street2;
        }
        if (address.street3) {
            addressLabel += '& ' + address.street3;
        }
        if (address.street4) {
            addressLabel += '& ' + address.street4;
        }
        if (address.houseNumber) {
            if (addressLabel !== '') { addressLabel += ' '; }
            addressLabel += address.houseNumber;
        }

        return addressLabel;
    }

    getAddressVicinity(address) {
        let addressLabel = '';

        if (address.district) {
            addressLabel += address.district;
        }
        if (address.city) {
            if (addressLabel !== '') { addressLabel += ', '; }
            addressLabel += address.city;
        }
        if (address.county) {
            if (addressLabel !== '') { addressLabel += ', '; }
            addressLabel += address.county;
        }
        if (address.postalCode) {
            if (addressLabel !== '') { addressLabel += ', '; }
            addressLabel += address.postalCode;
        }

        return addressLabel;
    }

    getAddressPlace(address) {
        let addressPlace;

        if (address.placeName) {
            addressPlace = address.placeName;
        } else {
            addressPlace = '';
        }

        return addressPlace;
    }

    // checks for equal coordinates
    isFloatNumbersEqual(num1, num2, compareFloatFix = 0.0000001) {
        if (num2 == 0) {
            if (num1 == 0) {
                return true;
            } else {
                return false;
            }
        }

        return (Math.abs((num1 - num2) / num2) < compareFloatFix);
    }

    isCoordinatesEqual(lat1, lon1, lat2, lon2, compareCoordinatesFix = 0.00000001) {
        return (
            (this.isFloatNumbersEqual(lat1, lat2, compareCoordinatesFix)) &&
            (this.isFloatNumbersEqual(lon1, lon2, compareCoordinatesFix))
        )
    }

    getPostalAddressLabel(address) {
        let addressLabel;
        if (address['city']) {
            addressLabel = address['postalCode'] + ' (' + address['city'] + ', ' + address['countryName'] + ')';
        }
        else if (address['district']) {
            addressLabel = address['postalCode'] + ' (' + address['district'] + ', ' + address['countryName'] + ')';
        }
        else if (address['county']) {
            addressLabel = address['postalCode'] + ' (' + address['county'] + ', ' + address['countryName'] + ')';
        }
        else {
            addressLabel = address['postalCode'] + ' (' + address['countryName'] + ')';
        }
        return addressLabel;
    }

}
