import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-dynamic-field-modal',
    templateUrl: './dynamic-field-modal.component.html',
    styleUrls: ['./dynamic-field-modal.component.scss']
})
export class DynamicFieldModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;
    dynamicFieldId = null;
    name = '';
    value = '';

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        formBuilder: FormBuilder,
    ) {
        this.myForm = formBuilder.group({
            'importDynamicField': formBuilder.group({
                'id': [this.dynamicFieldId],
                'label': [this.name, Validators.required],
                'default_value': [this.value, Validators.required],
                'type': [this.globals.ruleValueTypeConstants['VOLUME']],
            }),
        });
        this.listen.push(this.settingsService.openDynamicFieldModalListen().subscribe(() => {
            this.openModal();
        }));
    }

    patchForm() {
        this.myForm.patchValue({
            'importDynamicField': {
                'id': this.dynamicFieldId,
                'label': this.name,
                'default_value': this.value,
            },
        });
        setTimeout(() => {
            M.updateTextFields();
        }, 100);
    }

    loadDynamicField(id) {
        this.isClickedOnce = true;
        this.http.get('api/v1/import-dynamic-fields/' + id).pipe(take(1)).subscribe(response => {
            this.isClickedOnce = false;
            const data = response['item']['importDynamicField'];
            this.name = data.label;
            this.value = data.default_value;
            this.dynamicFieldId = data.id;
            this.patchForm();
            this.openModal();
        });
    }

    deleteDynamicField() {
        this.isClickedOnce = true;
        const myObserver = {
            next: (response) => {
                this.settingsService.updateDynamicFieldsGrid();
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {
                this.isClickedOnce = false;
                this.closeModal();
            },
        };
        this.http.delete('api/v1/import-dynamic-fields/' + this.dynamicFieldId).pipe(take(1)).subscribe(myObserver);
    }

    openModal() {
        const modal = document.querySelector('#dynamic-field-modal');
        const modalBackground = document.querySelector('#dynamic-field-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#dynamic-field-modal');
        const modalBackground = document.querySelector('#dynamic-field-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.resetDynamicFieldModal();
    }

    resetDynamicFieldModal() {
        this.name = '';
        this.value = '';
        this.dynamicFieldId = null;
        this.patchForm();
    }

    submitDynamicField() {
        this.isClickedOnce = true;
        const myObserver = {
            next: (response) => {
                this.settingsService.updateDynamicFieldsGrid();
                this.globals.updateImportFieldConfigs();
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => {
                this.isClickedOnce = false;
                this.closeModal();
            },
        };
        if (this.dynamicFieldId) {
            this.http.put('api/v1/import-dynamic-fields/' + this.dynamicFieldId, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
        } else {
            this.http.post('api/v1/import-dynamic-fields', JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
        }
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}