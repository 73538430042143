import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

declare var H: any;

@Component({
    selector: 'app-customer-collaborator-map',
    template: '<div #collaboratorMap id="collaborator-map"></div>',
    styles: ['#collaborator-map { height: 100%; }'],
})
export class CustomerCollaboratorMapComponent implements OnInit, AfterViewInit {
    @ViewChild('collaboratorMap', { static: false }) public mapElement: ElementRef;
    @Output() collaboratorAddress = new EventEmitter<string>();
    @Output() collaboratorDepotAddress = new EventEmitter<string>();
    @Output() collaboratorDrag = new EventEmitter<string>();

    listen = [];

    private platform: any;

    map;
    ui;
    behavior;
    rightClickOnMap;
    draggableAddressMarkerGroup = new H.map.Group();
    draggableAddressMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
    draggableDepotAddressMarkerGroup = new H.map.Group();
    draggableDepotAddressMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
    positionSet = false;
    lat;
    lon;
    globalIcon = '<div style="width: 1px; height: 1px; position: relative"><span  class="marker-icon" style="position: absolute; bottom: -5px; left: -17px;"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve"><g id="Stop"><path fill="white" d="M28.1,163.4c-15.8-16.3-36.6-25.3-58.5-25.3c-46.8,0-84.8,41.1-84.8,91.6c0,13.3,2.5,25.9,7.4,37.2c12.9,30.9,48.3,84.6,68,109.5c2.3,2.9,5.7,4.6,9.4,4.6c3.5,0,6.7-1.5,9.1-4.2l0.1-0.2l0.1-0.2c19.5-24.5,54.8-77.9,68-109.4c4.9-11.9,7.3-24.4,7.3-37.1C54.4,204.7,45.1,180.9,28.1,163.4z M39.7,263.8c-12.5,29.9-47,82.5-66.8,107.4c-0.9,1-2,1.6-3.3,1.6s-2.5-0.6-3.3-1.6c-19.7-24.9-54.3-77.6-66.8-107.6c-4.5-10.5-6.7-21.9-6.7-34c0-46,34.5-83.5,76.9-83.5s76.9,37.4,76.7,83.7C46.4,241.9,44.1,253.4,39.7,263.8z"/><path fill="#00aeba" d="M-30.3,146.4c-42.4,0-76.9,37.4-76.9,83.5c0,12,2.2,23.4,6.7,34c12.5,30,47.1,82.6,66.8,107.6c0.8,1,2,1.6,3.3,1.6s2.4-0.6,3.3-1.6C-7.2,346.7,27.3,294,39.8,264c4.4-10.6,6.7-22.1,6.7-34C46.6,183.7,12.1,146.4-30.3,146.4z"/></g><g></g><text fill="white" style="font-size:75px; font-weight: 500; font-family: Roboto, sans-serif; cursor: default" x="-30" y="267" text-anchor="middle" alignement-baseline="middle"></text></svg></span></div>';

    contextMenuAddressLabel;
    contextMenuDepotAddressLabel;

    constructor(
        public globals: Globals,
        public translate: TranslateService,
    ) {
        this.platform = this.globals.platform;
    }

    centerToMarker(bounds) {
        const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
        this.map.getViewModel().setLookAtData({ bounds: newBounds }, true);
    }

    showAddressDraggableMarker(lat, lng) {
        const self = this;
        this.draggableAddressMarker.setGeometry({ lat: lat, lng: lng });
        this.draggableAddressMarker.draggable = true;
        this.draggableAddressMarkerGroup.addObjects([this.draggableAddressMarker]);

        const bounds = this.draggableAddressMarkerGroup.getBoundingBox();
        this.centerToMarker(bounds);

        self.map.addEventListener('dragstart', function (ev) {
            const target = ev.target,
                pointer = ev.currentPointer;
            // if (target instanceof H.map.Marker) {
            if (target === self.draggableAddressMarker) {
                const targetPosition = self.map.geoToScreen(target.getGeometry());
                target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                self.behavior.disable();
            }
        }, false);

        // re-enable the default draggability of the underlying map
        // when dragging has completed
        this.map.addEventListener('dragend', function (ev) {
            const target = ev.target;
            if (target === self.draggableAddressMarker) {
                self.behavior.enable();
                self.positionSet = true;
                self.lat = target.getGeometry()['lat'];
                self.lon = target.getGeometry()['lng'];

                self.collaboratorAddress.emit('true');
            }
        }, false);

        // Listen to the drag event and move the position of the marker
        this.map.addEventListener('drag', function (ev) {
            const target = ev.target,
                pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                target.setGeometry(self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
            }
        }, false);
    }

    showDepotAddressDraggableMarker(lat, lng) {
        const self = this;
        this.draggableDepotAddressMarker.setGeometry({ lat: lat, lng: lng });
        this.draggableDepotAddressMarker.draggable = true;
        this.draggableDepotAddressMarkerGroup.addObjects([this.draggableDepotAddressMarker]);

        const bounds = this.draggableDepotAddressMarkerGroup.getBoundingBox();
        this.centerToMarker(bounds);

        self.map.addEventListener('dragstart', function (ev) {
            const target = ev.target,
                pointer = ev.currentPointer;
            if (target === self.draggableDepotAddressMarker) {
                const targetPosition = self.map.geoToScreen(target.getGeometry());
                target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                self.behavior.disable();
            }
        }, false);

        // re-enable the default draggability of the underlying map
        // when dragging has completed
        this.map.addEventListener('dragend', function (ev) {
            const target = ev.target;
            if (target === self.draggableDepotAddressMarker) {
                self.behavior.enable();
                self.positionSet = true;
                self.lat = target.getGeometry()['lat'];
                self.lon = target.getGeometry()['lng'];

                self.collaboratorDepotAddress.emit('true');
            }
        }, false);

        // Listen to the drag event and move the position of the marker
        this.map.addEventListener('drag', function (ev) {
            const target = ev.target,
                pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                target.setGeometry(self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
            }
        }, false);
    }

    removeDraggableMarker() {
        this.draggableDepotAddressMarkerGroup.removeAll();
    }

    initMap() {
        const self = this;
        const depotLat = self.globals.currentLat;
        const depotLon = self.globals.currentLon;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 12,
                center: { lat: depotLat, lng: depotLon }
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.rightClickOnMap = function (event) {
            const coords = self.map.screenToGeo(event.viewportX, event.viewportY);
            self.positionSet = true;
            self.lat = coords.lat;
            self.lon = coords.lng;
            event.items.push(
                new H.util.ContextItem({
                    label: self.contextMenuAddressLabel,
                    callback: function () {
                        self.collaboratorAddress.emit('true');
                        self.showAddressDraggableMarker(Number(coords.lat), Number(coords.lng));
                    }
                }),
            );
            event.items.push(
                new H.util.ContextItem({
                    label: self.contextMenuDepotAddressLabel,
                    callback: function () {
                        self.collaboratorDepotAddress.emit('true');
                        self.showDepotAddressDraggableMarker(Number(coords.lat), Number(coords.lng));
                    }
                }),
            );
        };
        this.map.addEventListener('contextmenu', this.rightClickOnMap);

        // Instantiate the default behavior, providing the mapEvents object:
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');

        this.map.addObject(this.draggableAddressMarkerGroup);
        this.map.addObject(this.draggableDepotAddressMarkerGroup);
    }

    getTranslations() {
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.CONTEXT_MENU_ADDRESS').subscribe((res: string) => {
            this.contextMenuAddressLabel = res;
        }));
        this.listen.push(this.translate.get('CUSTOMER_COLLABORATORS.CONTEXT_MENU_DEPOT_ADDRESS').subscribe((res: string) => {
            this.contextMenuDepotAddressLabel = res;
        }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    public ngAfterViewInit() {
        this.initMap();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
