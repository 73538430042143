import { Directive, OnInit, AfterViewInit, OnDestroy, Input, TemplateRef, Injector, ViewChild, InjectionToken, EventEmitter, Output } from "@angular/core";
import { ILmChartWidgetTab } from "@app/model/widget";
import { Chart, ChartOptions, Plugin } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { UIChart } from "primeng/chart";
import { mergeObjects } from "../utils";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(annotationPlugin, ChartDataLabels);
Chart.defaults.plugins.datalabels = {
    display: false,
    color: '#000'
};

export const LM_BASE_CHART_OPTIONS = new InjectionToken<ChartOptions>('LmBaseChartOptions')

@Directive()
export abstract class LmBaseChartComponent implements OnInit, AfterViewInit, OnDestroy{
    id: string;
    @ViewChild('chart') chart: UIChart;
    @Input() data?: any;
    @Input() options?: ChartOptions;
    @Input() plugins?: Plugin | Plugin[] | any;
    @Input() width = '100%';
    @Input() height = '100%';
    @Input() responsive = true;
    @Input() dataClickFn: (...args) => void | any;

    @Output() chartRefreshed = new EventEmitter();
    
    chartOptions: ChartOptions;
    chartPlugins: Plugin | Plugin[] | any;
    baseOptions: ChartOptions;
    
    constructor(_baseOptions: ChartOptions) {
        this.baseOptions = structuredClone(_baseOptions);
    }

    dataSelect(e?){
        if(this.dataClickFn) this.dataClickFn(e)
    }

    refresh(tab:ILmChartWidgetTab){
        this.chartOptions = mergeObjects(this.baseOptions, tab.chartOptions ?? {})
    }

    ngOnInit(): void {
        this.chartOptions = mergeObjects(this.baseOptions, this.options ?? {});
        this.chartPlugins = [].concat((src = this.plugins ?? []) => !Array.isArray(src) ? [src] : src);
    }

    ngAfterViewInit(): void {}
    ngOnDestroy(): void {}
}