// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#routing-settings-modal {
  padding: 20px 0;
  height: 100%;
  opacity: 1;
}

.notes-text {
  font-size: 0.8rem;
  color: #9e9e9e;
  padding-bottom: 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2plY3QtcHJvYmxlbS1yb3V0aW5nLXNldHRpbmdzLW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZUFBQTtFQUNBLFlBQUE7RUFDQSxVQUFBO0FBQ0o7O0FBRUE7RUFDSSxpQkFBQTtFQUNBLGNBQUE7RUFDQSxvQkFBQTtBQUNKIiwiZmlsZSI6InByb2plY3QtcHJvYmxlbS1yb3V0aW5nLXNldHRpbmdzLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiI3JvdXRpbmctc2V0dGluZ3MtbW9kYWwge1xuICAgIHBhZGRpbmc6IDIwcHggMDtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgb3BhY2l0eTogMTtcbn1cblxuLm5vdGVzLXRleHR7XG4gICAgZm9udC1zaXplOiAuOHJlbTtcbiAgICBjb2xvcjogIzllOWU5ZTtcbiAgICBwYWRkaW5nLWJvdHRvbTogMTVweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/project-view/project-problem-routing-settings-modal/project-problem-routing-settings-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;EACA,oBAAA;AACJ;AACA,4oBAA4oB","sourcesContent":["#routing-settings-modal {\n    padding: 20px 0;\n    height: 100%;\n    opacity: 1;\n}\n\n.notes-text{\n    font-size: .8rem;\n    color: #9e9e9e;\n    padding-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
