import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';
import {DropdownModule} from 'primeng/dropdown';
import { Observable, take } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmDropdownComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmDropdownComponent) };

@Component({
  selector: 'lm-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE]
})
export class LmDropdownComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;

  @HostBinding() id = `lm-dropdown-${LmDropdownComponent.nextId++}`;
  name = `lm-dropdown-${LmDropdownComponent.nextId++}`;

  @ViewChild(NgModel) model: NgModel;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() options: any[];
  @Input() field: string;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() filter = false;
  @Input() filterBy: string;
  @Input() dataKey: string;
  @Input() optionLabel = 'name';
  @Input() optionValue = 'id';
  @Input() forceSelection: boolean;
  @Input() style: any;
  @Input() styleClass = 'lm-dropdown-control';
  @Input() inputStyleClass = 'lm-dropdown-input';
  @Input() panelStyleClass = 'lm-dropdown-panel';
  @Input() dropdownIcon: string
  @Input() iconClass = '';
  @Input() iconColor: string;
  @Input() iconSize?: string;
  @Input() iconStyle: LmTextIconStyle;
  @Input() showClear: boolean;
  @Input() appendTo? :any;
  @Input() virtualScroll = false;
  @Input() virtualScrollItemSize? :number;
  @Input() isPhoneInput = false;
  selection:any;

  @Input() optionsFn: (...args) => any[];
  @Input() optionsFn$: (...args) => Observable<any[]>;

  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  valueChanged(value: any) {
    this.onChange(value);
    this.onTouched();
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.selection = value;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.optionsFn) this.options = this.optionsFn();
    if(this.optionsFn$) this.optionsFn$().pipe(take(1), untilDestroyed(this)).subscribe(res => this.options = res)
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule
  ],
  exports: [LmDropdownComponent, DropdownModule],
  declarations: [LmDropdownComponent]
})
export class LmDropdownModule {}
