import { DepotUtils } from '@app/utils/depot-utils';
import { Globals } from './../../services/globals';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { GridsService } from '@app/services/grids.service';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-vehicle-grid',
    templateUrl: './vehicle-grid.component.html',
    styleUrls: ['./../grids.scss']
})
export class VehicleGridComponent implements OnInit, OnDestroy {
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('vehicleTypesContainer', { static: false }) vehicleTypesContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('openChartButton', { static: false }) openChartButton;
    @ViewChild('openTypesButton', { static: false }) openTypesButton;

    listen = [];

    columnDefs;
    columnDefsCategory;
    gridApi;
    gridColumnApi;
    domLayout = 'autoHeight';
    vehiclesDataArray = [];
    rowData: any;

    searchTextChanged = new Subject<string>();
    searchString: String = '';

    vehicleTypeTitle;
    vehicleInfoTitle;
    driverTitle;
    capacityTitle;
    countTitle;

    chart;
    chartData = {};
    vehiclesNames = [];
    driversNames = [];
    totalDistance = [];
    averageDistance = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;
    selectedDepot;
    depots = [];

    chartExpanded = true;
    chartDisabled = false;

    vehicleTypes = [];

    bicycleLabel = '';
    scooterLabel = '';
    carLabel = '';
    vanLabel = '';
    largeVanLabel = '';
    truckLabel = '';
    avgDistanceLabel;
    totalDistanceLabel;
    allDepotsLabel;
    lastWeekLabel;
    lastMonthLabel;
    lastThreeMonthsLabel;
    lastSixMonthsLabel;
    lastYearLabel;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private modalService: ModalService,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private sanitizer: DomSanitizer,
        private depotUtils: DepotUtils
    ) {
        this.listen.push(this.modalGridService.listenUpdateVehicleGrid().subscribe((response) => {
            this.setVehiclesGridData();
        }));
    }

    vehicleRenderer(params) {
        let columnObject = '';
        let make = '';
        let model = '';
        if (params.getValue()) {
            if (params.getValue().make) {
                make = params.getValue().make;
            }
            if (params.getValue().model) {
                model = params.getValue().model;
            }
            const makeAndModel = make + ' - ' + model;
            columnObject += '<div class="double-cell bold-letters standard-width" title="' + makeAndModel + '">' + makeAndModel + '</div>';
            let vim = '';
            if (params.getValue().vehicle_identification_number) {
                vim = ' <small>' + params.getValue().vehicle_identification_number + '</small>';
            }
            if (params.getValue().plate_number) {
                columnObject += '<div class="double-cell">' + params.getValue().plate_number + vim + '</div>';
            }
        }
        return columnObject;
    }

    typeRenderer(params) {
        let columnObject = '';
        if (params.getValue() || params.getValue() === 0) {
            columnObject += '<div class="vehicle-icon-cell dark">' + this.vehicleTypes[params.getValue()].image + '</div>';
            columnObject += '<div class="triple-cell">' + this.vehicleTypes[params.getValue()].typeName + '</div>';
        }
        return columnObject;
    }

    driverRenderer(params) {
        let columnObject = '';
        // let count = '';
        // if (params.)
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().allNames + '">';
                columnObject += params.getValue().name + params.getValue().driversCount + '</div>';
                columnObject += '<div class="double-cell">' + params.getValue().phone + '</div>';
            }
        }
        return columnObject;
    }

    getDataForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'average-distance':
                data = this.averageDistance;
                break;
            case 'total-distance':
                data = this.totalDistance;
                break;
        }
        return data;
    }

    openChart() {
        this.chartExpanded = true;
        this.vehicleTypesContainer.nativeElement.classList.remove('expanded');
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced-types');
        this.gridContainer.nativeElement.classList.add('reduced-chart');
        this.openChartButton.nativeElement.classList.add('active');
        this.openTypesButton.nativeElement.classList.remove('active');
        // this.closeChartButton.nativeElement.classList.remove('active');
    }

    openTypes() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.vehicleTypesContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced-chart');
        this.gridContainer.nativeElement.classList.add('reduced-types');
        this.openChartButton.nativeElement.classList.remove('active');
        this.openTypesButton.nativeElement.classList.add('active');
        // this.closeChartButton.nativeElement.classList.remove('active');
    }

    closeChart() {
        // this.chartExpanded = false;
        // this.statsContainer.nativeElement.classList.remove('expanded');
        // this.gridContainer.nativeElement.classList.remove('reduced');
        // this.openChartButton.nativeElement.classList.remove('active');
        // this.closeChartButton.nativeElement.classList.add('active');
    }

    emptyChartArrays() {
        this.vehiclesNames = [];
        this.totalDistance = [];
        this.averageDistance = [];
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.vehiclesNames = [];
        for (let i = 0; i < 10; i++) {
            this.vehiclesNames.push('');
        }
        this.averageDistance = [552, 365, 678, 125, 411, 511, 136, 343, 304, 644];
        this.chartDisabled = true;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.averageDistance.forEach(element => {
            if (element !== 0) {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&ids=';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            params += `&depotId=${this.selectedDepot.companyDepot.id}`;
        }
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        this.http.get('api/v1/stats/vehicle-stats' + params).pipe(take(1)).subscribe(response => {
            this.dataLoading = false;
            const data = response['items'];
            if (Object.keys(data).length) {
                if (Object.keys(data).length < this.pageSize) {
                    this.lastPage = true;
                } else if (Object.keys(data).length === this.pageSize) {
                    this.lastPage = false;
                }
                Object.keys(data).forEach(id => {
                    if (data[id]) {
                        this.chartData[id] = data[id];
                        this.vehiclesNames.push(data[id].plate_number);
                        this.totalDistance.push(data[id].totalDistance);
                        if (data[id].driver_name) {
                            this.driversNames.push(data[id].driver_name);
                        } else {
                            this.driversNames.push('');
                        }
                        const completedRoutes = data[id].completedRoutes;
                        if (completedRoutes) {
                            this.averageDistance.push(data[id].totalDistance / data[id].completedRoutes);
                        } else {
                            this.averageDistance.push(0);
                        }
                    }
                });
                if (this.checkIfAllZero()) {
                    this.displayDummyChartValues();
                }
            } else {
                this.lastPage = true;
                this.displayDummyChartValues();
            }
            this.initChart();
        });
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    emptyGrid() {
        this.vehiclesDataArray = [{ noDataText: 'No data' }];
        this.rowData = of(this.vehiclesDataArray);
    }

    onDepotChanged() {
        this.chartPeriodChange();
        this.setVehiclesGridData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('vehicles-chart');
        const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }
        const chartOptions:ChartOptions = this.chartService.chartOptions;
        const driversNames = this.driversNames;
        chartOptions['tooltips'] = {
            callbacks: {
                title: function (tooltipItem, tooltipData) {
                    return driversNames[tooltipItem[0].index];
                }
            }
        };
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.vehiclesNames,
                    datasets: [{
                        barPercentage: 0.9,
                        barThickness: 'flex',
                        maxBarThickness: 150,
                        minBarLength: 2,
                        data: data,
                        backgroundColor: (() => {
                            const backgroundColours = [];
                            for (let i = 0; i < data.length; ++i) {
                                backgroundColours.push(this.chartService.chartColours[i % this.chartService.chartColours.length]);
                            }
                            return backgroundColours;
                        })()
                    }]
                }
                // ,
                // options: this.chartService.chartOptions
            });
        }
    }

    setVehiclesGridData() {
        this.emptyGrid();
        const self = this;
        let params = `?searchQuery=${this.searchString}`;

        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            params += '&depotId=' + this.selectedDepot.companyDepot.id;
        }

        this.http.get('api/v1/vehicles' + params).pipe(take(1)).subscribe(response => {
            this.setVehicleTypes();
            self.vehiclesDataArray = [];
            let make, model, gridObject = {};
            if (response['items'].length) {
                response['items'].forEach(vehicleData => {
                    make = '';
                    model = '';
                    const vehicle = vehicleData.vehicle;
                    let driversCountText = '';
                    if (vehicleData.drivers.length > 1) {
                        driversCountText = ' +' + (vehicleData.drivers.length - 1);
                    }
                    let allNames = '', name = '', phone = '';
                    vehicleData.drivers.forEach(driver => {
                        if (allNames) {
                            allNames += ', '
                        }
                        allNames += driver.userProfile.name;
                        if (!name) {
                            name = driver.userProfile.name
                        }
                        if (!phone) {
                            phone = driver.userProfile.telephone
                        }
                    });
                    const userProfile = vehicleData.userProfile;
                    if (vehicleData.vehicleInfoMake.name) {
                        make = vehicleData.vehicleInfoMake.name;
                    } else if (vehicleData.vehicleInfoMakeCompany.name) {
                        make = vehicleData.vehicleInfoMakeCompany.name;
                    }
                    if (vehicleData.vehicleInfoModel.name) {
                        model = vehicleData.vehicleInfoModel.name;
                    } else if (vehicleData.vehicleInfoModelCompany.name) {
                        model = vehicleData.vehicleInfoModelCompany.name;
                    }
                    gridObject = {
                        id: vehicle.id,
                        type: vehicle.vehicle_type,
                        vehicle: {
                            make: make,
                            model: model,
                            plate_number: vehicle.plate_number,
                            vehicle_identification_number: vehicle.vehicle_identification_number,
                        },
                        driver: {
                            name: name,
                            phone: phone,
                            allNames: allNames,
                            driversCount: driversCountText
                        },
                        capacity: vehicle.maximum_cargo_capacity,
                        count: 1
                    };
                    self.vehiclesDataArray.push(gridObject);
                });
            } else {
                const noData = { noDataText: 'No data' }
                self.vehiclesDataArray.push(noData);
            }
            self.rowData = of(self.vehiclesDataArray);
        },
            (error) => {
                console.error(error);
            }
        );
    }

    filterByType(type) {
        if (document.querySelector('.vehicle-type-box.active')) {
            document.querySelector('.vehicle-type-box.active').classList.remove('active');
        }
        if (document.querySelector('[index="' + type + '"]')) {
            document.querySelector('[index="' + type + '"]').classList.add('active');
        }
        this.gridApi.setColumnDefs(this.columnDefs);
        const filteredArray = [];
        this.vehiclesDataArray.forEach((row, index) => {
            if (row.type === type) {
                filteredArray.push(row);
            }
        });
        this.rowData = of(filteredArray);
    }

    groupData() {
        if (document.querySelector('.vehicle-type-box.active')) {
            document.querySelector('.vehicle-type-box.active').classList.remove('active');
        }
        if (document.querySelector('.vehicle-type-box.categories')) {
            document.querySelector('.vehicle-type-box.categories').classList.add('active');
        }
        this.http.get('api/v1/vehicles?isGrouped=true').pipe(take(1)).subscribe(response => {
            this.gridApi.setColumnDefs(this.columnDefsCategory);
            const vehiclesByCategoryDataArray = [];
            let gridObject = {};
            if (response['items'].length) {
                response['items'].forEach(vehicleData => {
                    const vehicle = vehicleData.vehicle;
                    gridObject = {
                        id: vehicle.id,
                        type: vehicle.vehicle_type,
                        capacity: vehicle.maximum_cargo_capacity,
                        count: vehicle.groupCount
                    };
                    vehiclesByCategoryDataArray.push(gridObject);
                });
            } else {
                const noData = { noDataText: 'No data' }
                vehiclesByCategoryDataArray.push(noData);
            }
            this.rowData = of(vehiclesByCategoryDataArray);
        });
    }

    removeFilters() {
        if (document.querySelector('.vehicle-type-box.active')) {
            document.querySelector('.vehicle-type-box.active').classList.remove('active');
        }
        if (document.querySelector('.vehicle-type-box.all')) {
            document.querySelector('.vehicle-type-box.all').classList.add('active');
        }
        this.gridApi.setColumnDefs(this.columnDefs);
        this.rowData = of(this.vehiclesDataArray);
    }

    newButtonClicked(): void {
        this.modalService.filter('vehicle', 0, 0);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    rowClicked(event) {
        if (!event.data.noDataText) {
            this.modalService.filter('vehicle', 1, event.data.id);
        }
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.TYPE').subscribe((res: string) => { this.vehicleTypeTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.VEHICLE_INFO').subscribe((res: string) => { this.vehicleInfoTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.DRIVER').subscribe((res: string) => { this.driverTitle = res; }));
        this.listen.push(this.translate.get('VEHICLE.CAPACITY').subscribe((res: string) => { this.capacityTitle = res; }));
        this.listen.push(this.translate.get('VEHICLE.COUNT').subscribe((res: string) => { this.countTitle = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.AVG_DISTANCE').subscribe((res: string) => { this.avgDistanceLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.TOTAL_DISTANCE').subscribe((res: string) => { this.totalDistanceLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_THREE_MONTHS').subscribe((res: string) => { this.lastThreeMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_SIX_MONTHS').subscribe((res: string) => { this.lastSixMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_YEAR').subscribe((res: string) => { this.lastYearLabel = res; }));


        this.depots = this.depotUtils.getDepotsWithAllDepotsOption();
        this.selectedDepot = this.depots[0];
        this.charts = [
            {
                label: this.avgDistanceLabel,
                value: 'average-distance',
            },
            {
                label: this.totalDistanceLabel,
                value: 'total-distance',
            },
        ];
        this.chartService.setupPeriodsTranslations(this.lastWeekLabel, this.lastMonthLabel, this.lastThreeMonthsLabel, this.lastSixMonthsLabel, this.lastYearLabel);
        this.periods = [...this.chartService.periods];
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[1];

        this.columnDefs = [
            {
                headerName: this.vehicleTypeTitle,
                cellRenderer: this.typeRenderer.bind(this),
                field: 'type',
                filter: false,
                sort: true,
                checkboxSelection: false,
                width: this.gridsService.widthCalculator(10)
            },
            {
                headerName: this.vehicleInfoTitle,
                field: 'vehicle',
                filter: true,
                checkboxSelection: false,
                cellRenderer: this.vehicleRenderer,
                width: this.gridsService.widthCalculator(35)
            },
            {
                headerName: this.capacityTitle,
                field: 'capacity',
                filter: false,
                width: this.gridsService.widthCalculator(20)
            },
            {
                headerName: this.driverTitle,
                field: 'driver',
                filter: true,
                cellRenderer: this.driverRenderer,
                width: this.gridsService.widthCalculator(35)
            },
        ];
        this.columnDefsCategory = [
            {
                headerName: this.vehicleTypeTitle,
                cellRenderer: this.typeRenderer.bind(this),
                field: 'type',
                filter: false,
                sort: true,
                checkboxSelection: false,
                width: this.gridsService.widthCalculator(33)
            },
            {
                headerName: this.capacityTitle,
                field: 'capacity',
                filter: false,
                width: this.gridsService.widthCalculator(33)
            },
            {
                headerName: this.countTitle,
                field: 'count',
                filter: true,
                width: this.gridsService.widthCalculator(33)
            },
        ];
        this.setVehiclesGridData();
    }

    setVehicleTypes() {
        const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
        const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
        const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
        const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
        const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
        const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

        this.vehicleTypes = [
            {
                index: 0,
                image: bicycle,
                typeName: this.bicycleLabel
            },
            {
                index: 1,
                image: scooter,
                typeName: this.scooterLabel
            },
            {
                index: 2,
                image: car,
                typeName: this.carLabel
            },
            {
                index: 3,
                image: van,
                typeName: this.vanLabel
            },
            {
                index: 4,
                image: largeVan,
                typeName: this.largeVanLabel
            },
            {
                index: 5,
                image: truck,
                typeName: this.truckLabel
            }
        ];
    }

    ngOnInit(): void {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.setVehiclesGridData();
            });

        this.getChartData();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}

interface EventObject {
    event: string;
    value: any;
}
