import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss']
})
export class SignatureModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
  @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;

  projectTitle: String = '';
  isClickedOnce = false;
  projectId = null;
  signedName = '';
  signature = '';
  signatureSrc = 'data:image/png;base64,SIGNATURE_HASH'

  constructor(
    private http: HttpClient,
    public globals: Globals,
  ) { }

  loadSignature(id, imageHash, name) {
    this.signedName = name;
    let url = `api/internal/v1/images/signature/${id}/${imageHash}`;
    if (this.globals.collaboratorModeEnabled) url = `api/internal/v1/images/partner-signature-images/${id}/${imageHash}`;
    this.http.get(url).pipe(take(1)).subscribe(photoRes => {
      this.signature = this.signatureSrc.replace('SIGNATURE_HASH', photoRes['base64']);
      this.toggleModal();
    });
  }

  toggleModal() {
    this.modal.nativeElement.classList.toggle('closed');
    this.modal.nativeElement.classList.toggle('open');
    this.modalBackground.nativeElement.classList.toggle('hidden');
  }

  ngOnDestroy() { }

  ngOnInit() { }

  loadProject() { }

}
