import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {
    @ViewChild('hoursClock', { static: false, read: ElementRef }) hoursClock: ElementRef;
    @ViewChild('minutesClock', { static: false, read: ElementRef }) minutesClock: ElementRef;
    @ViewChild('displayHours', { static: false, read: ElementRef }) displayHours: ElementRef;
    @ViewChild('displayMinutes', { static: false, read: ElementRef }) displayMinutes: ElementRef;

    hours = '00';
    minutes = '00';
    selectedTime = '00:00';
    handDegrees = 0;
    shortHand = false;
    activeClock = 'hours';

    constructor() { }

    updateClockHand() {
        if (this.activeClock === 'hours' && Number(this.hours) <= 12 && Number(this.hours) !== 0) {
            this.shortHand = true
        } else {
            this.shortHand = false
        }
        if (this.activeClock === 'hours') {
            this.handDegrees = (((Number(this.hours) / 12) * 360));
        } else if (this.activeClock === 'minutes') {
            this.handDegrees = (((Number(this.minutes) / 60) * 360));
        }
    }

    showMinutes() {
        this.activeClock = 'minutes';
        this.hoursClock.nativeElement.classList.add('timepicker-dial-out');
        this.minutesClock.nativeElement.classList.remove('timepicker-dial-out');
        this.displayHours.nativeElement.classList.remove('text-primary');
        this.displayMinutes.nativeElement.classList.add('text-primary');
        this.updateClockHand();
    }

    showHours() {
        this.activeClock = 'hours';
        this.hoursClock.nativeElement.classList.remove('timepicker-dial-out');
        this.minutesClock.nativeElement.classList.add('timepicker-dial-out');
        this.displayHours.nativeElement.classList.add('text-primary');
        this.displayMinutes.nativeElement.classList.remove('text-primary');
        this.updateClockHand();
    }

    minutesSelected(minutes) {
        this.minutes = minutes;
        this.updateSelectedTime();
    }

    hoursSelected(hours) {
        this.hours = hours;
        this.showMinutes();
        this.updateSelectedTime();
    }

    updateSelectedTime() {
        this.selectedTime = this.hours + ':' + this.minutes;
        this.updateClockHand();
    }

    setTime(time: String) {
        const timeArray = time.split(':');
        if (timeArray.length === 2) {
            this.hours = timeArray[0];
            this.minutes = timeArray[1];
            this.updateSelectedTime();
        }
    }

    resetTimePicker() {
        this.showHours();
        this.hours = '00';
        this.minutes = '00';
        this.selectedTime = '00:00';
        this.handDegrees = 0;
    }

}