import { DatePipe } from '@angular/common';
// import { compareAsc, parseISO } from 'date-fns';
import { defer, forkJoin, isObservable, Observable, ObservableInput, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LMApiMessage, LMApiResponse } from '../model/api-response';
import { LMValidationMessage, LMValidatorInfo, LMValidatorInfoResult } from '../model/validator';
import { ILmBaseEnum, IVeronaBaseEnum } from '@app/model/enums';
import * as libphonenumber from 'libphonenumber-js';
import { PartialEventContext } from 'chartjs-plugin-annotation';


export function getElement(selector:string): Element | HTMLElement{
  return document.querySelector(selector);
}

export function getStyle(selector:string, property:string, pseudo:string=null){
  return getComputedStyle(getElement(selector), pseudo).getPropertyValue(property);
}


export function HEXtoRGB(hexCode, opacity = 1){  
  let hex = hexCode.replace('#', '');
  if (hex.length === 3) hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  
  if (opacity > 1 && opacity <= 100) opacity = opacity / 100;   

  return `rgba(${r},${g},${b},${opacity})`;
};


export function chartImageAnnotation(e:PartialEventContext, src): HTMLCanvasElement {
  const {chart:{options:{datasets:{bar:{barThickness}}}}} = e;
  const size = <number>barThickness // 4 => padding
  const radius = size/2; 

  const img = new Image();
  img.src = src;

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  img.addEventListener('load', (e) => {
    ctx.beginPath();
    ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
    ctx.clip();
    ctx.drawImage(img, 0, 0, 45, 45)
  });
  
  return canvas;
}


export function chartAnnotationY(e:PartialEventContext, anno):number {
  const {chart:{data:{datasets, labels}}} = e;
  
  const value = <number>datasets[0].data[labels.indexOf(anno.xValue)];
  return value;
}


export function chartBarThicknessOffset(e:PartialEventContext, offset=0){
  const {chart:{options:{datasets:{bar:{barThickness}}}}} = e;
  return <number>barThickness + offset;
}


export function mergeObjects(_a:object, _b:object, strings=true){
  const deepMerge = (a, b, fn) => [...new Set([...Object.keys(a), ...Object.keys(b)])].reduce((nu, key) => ({ ...nu, [key]: fn(key, a[key], b[key]) }), {});

  const mergerFn = (key, a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) return a.concat(b);
    if (typeof a === 'object' && typeof b === 'object') return deepMerge(a, b, mergerFn);
    if (typeof a === 'string' && typeof b === 'string') return strings ? [a, b].join(' ') : b;
    return b ?? a;
  };

  return deepMerge(_a, _b, mergerFn);
}


export const shuffleArray = <T>(array: T[]) => { 
  for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]]; 
  }
  return array; 
}; 

export function telephonator(tel?){
  if(tel && tel.length > 5){
    const {countryCallingCode, nationalNumber} = libphonenumber.parsePhoneNumber(tel);
    return {countryPrefix: `+${countryCallingCode ?? '30'}`, phoneNumber: nationalNumber ?? ''};
  }
  return {countryPrefix: '+30', phoneNumber: ''};
}

export function extractInteger(value: string | number){
  return parseInt(value.toString().replace(/\D/g, ""));
}

export function extractIntegerFromString(text: string) {
  return text.match(/\d+/g);
}

export function createArrayOfNumbersBetween(start, end) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}
export function valueIsAlphabetical(_value){
  return (hasValue(_value) && (/^[a-zA-Z]+$/.test(_value)));
}

export function valueIsInteger(_value){
  return (hasValue(_value) && (/\D/.test(_value)));
}

export function valueIsAlphaNumeric(_value){
  return (hasValue(_value) && (/^[A-Za-z0-9]*$/g.test(_value)));
}

export function valueIsEmail(_value){
  return (hasValue(_value) && (/^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(_value)));
}

export function valueIsNumber(_value){
  return (hasValue(_value) && !Number.isNaN(_value));
}

export function extractPropertyPath(path: string) {
  return path
    .split('.')
    .filter((p) => isNaN(p as any))
    .join('.');
}

export function lmEnumTypeToVeronaType(lmEnum: ILmBaseEnum, label?, field?){
  return Object.values(lmEnum).map(type => type = {[label ?? 'label']: type.caption, [field ?? 'id']: type.key})
}

export const METADATA_PROP_NAME = '$$metadata$$';

export const deepClone = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  let newObj = {};
  if (Array.isArray(obj)) {
    newObj = obj.map((item) => deepClone(item));
  } else {
    Object.keys(obj).forEach((key) => {
      return (newObj[key] = deepClone(obj[key]));
    });
  }
  return newObj;
};

export function isApiResponse(obj: any): obj is LMApiResponse {
  return !!obj && typeof obj !== 'string' && 'isError' in obj;
}

export function hasValue(value): boolean {
  return !(value == null || value.length === 0);
}

export function getApiResponseMetadata(obj: any): { isError: boolean; messages?: LMApiMessage[]; metadata?: any } {
  return Reflect.get(obj, METADATA_PROP_NAME);
}
export const hasDuplicates = (arr): boolean => {
  return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
};

export function sortData(data: any[], sortField: string, sort: 'asc' | 'desc'): Observable<any[]> {
  const sortedResults = data.sort((a, b) => {
    if (Number(a[sortField]) && Number(b[sortField])) {
      if (Number.parseFloat(a[sortField]) < Number.parseFloat(b[sortField])) {
        return sort === 'asc' ? -1 : 1;
      } else if (Number.parseFloat(a[sortField]) > Number.parseFloat(b[sortField])) {
        return sort === 'asc' ? 1 : -1;
      }
      return 0;
    } else {
      if (a[sortField] < b[sortField]) {
        return sort === 'asc' ? -1 : 1;
      } else if (a[sortField] > b[sortField]) {
        return sort === 'asc' ? 1 : -1;
      }
      return 0;
    }
  });

  return of(sortedResults);
}

export function stringHasOnlyDigits(input: string) {
  for (let i = 0; i < input.length; i++) {
    const c = input.substring(i, i + 1);
    if (c < '0' || c > '9') {
      return false;
    }
  }
  return true;
}

export function iifdefer<T, F>(condition: () => boolean, trueResult: () => ObservableInput<T>, falseResult: () => Observable<T | F>) {
  return defer(() => (condition() ? trueResult() : falseResult()));
}

/**
 * Returns an observable with the validator's result
 */
export function executeValidator(validator: LMValidatorInfo, target: any): Observable<LMValidatorInfoResult> {
  const validatorResultMessages = validator.validationsFn(target, target);
  const validatorResult$ =
    !!validatorResultMessages && isObservable(validatorResultMessages) ? validatorResultMessages : of(validatorResultMessages as LMValidationMessage[]);

  return validatorResult$.pipe(map((validationMessages) => ({ propertyPath: validator.propertyPath, isNotice: validator.isNotice, validationMessages: validationMessages })));
}
  
// /**
//  * Returns an observable with the validators results
//  */
export function executeValidators(args: { validator: LMValidatorInfo; target: any }[]): Observable<LMValidatorInfoResult[]> {
  const validatorsResults$: Observable<LMValidationMessage[]>[] = [];

  args.forEach((validatorInfo) => {
    const validatorResultMessages = validatorInfo.validator.validationsFn(validatorInfo.target, validatorInfo.target);
    const validatorResult$ =
      !!validatorResultMessages && isObservable(validatorResultMessages) ? validatorResultMessages : of(validatorResultMessages as LMValidationMessage[]);

    validatorsResults$.push(validatorResult$);
  });

  return forkJoin(validatorsResults$).pipe(
    map((validatorsResults) => {
      const validatorResults: LMValidatorInfoResult[] = [];

      for (let i = 0; i < validatorsResults.length; i++) {
        validatorResults.push({
          propertyPath: args[i].validator.propertyPath,
          isNotice: args[i].validator.isNotice,
          validationMessages: validatorsResults[i]
        } as LMValidatorInfoResult);
      }

      return validatorResults;
    })
  );
}

