import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { StepsService } from '@app/services/steps.service';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import { ChartService } from '@app/services/chart.service';
import { ProjectModalComponent } from '@app/modals/project-modal/project-modal.component';
import { ProjectsGridGuidedTourComponent } from './projects-grid-guided-tour/projects-grid-guided-tour.component';
import { DepotUtils } from '@app/utils/depot-utils';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-projects-grid',
    templateUrl: './projects-grid.component.html',
    styleUrls: ['./projects-grid.component.scss', './../grids.scss']
})
export class ProjectsGridComponent implements OnInit {
    @ViewChild(ProjectsGridGuidedTourComponent, { static: false }) projectsGridGuidedTourComponent: ProjectsGridGuidedTourComponent;
    @ViewChild(ProjectModalComponent, { static: false }) projectModalComponent: ProjectModalComponent;
    @ViewChild('title', { static: false }) title: TemplateRef<any>;
    @ViewChild('departure', { static: false }) departure: TemplateRef<any>;
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('openChartButton', { static: false }) openChartButton;
    @ViewChild('closeChartButton', { static: false }) closeChartButton;

    projectsDataArray = [];
    listen = [];

    searchTextChanged = new Subject<string>();
    searchString: String = '';

    allDepotsLabel;
    stopPointsLabel;
    ratingLabel;

    lastWeekLabel;
    lastMonthLabel;
    lastThreeMonthsLabel;
    lastSixMonthsLabel;
    lastYearLabel;
    canceledLabel = '';
    completedLabel = '';

    chart;
    chartData = {};
    projectsNames = [];
    totalPoints = [];
    completedPoints = [];
    cancelledPoints = [];
    ratings = [];

    charts = [];
    selectedChart;
    periods = [];
    depots = [];
    selectedDepot;
    selectedPeriod;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    pageSize = 10;
    dataLoading = false;

    boxColours = [];
    chartExpanded = true;
    chartDisabled = false;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private router: Router,
        private stepsService: StepsService,
        private chartService: ChartService,
        private depotUtils: DepotUtils
    ) {
        this.boxColours = [
            '#85669f',
            '#4f4f4f',
            '#088c98',
            '#6a6a6a'
        ];
        this.listen.push(this.stepsService.updateProjectsGridListen().subscribe(response => {
            this.setProjectProblemGridData();
        }));
    }

    // checkIfStopsChartDataIsEmpty() {
    //     let allEmpty = true;
    //     this.totalPoints.forEach(element => {
    //         if (element !== '0') {
    //             allEmpty = false;
    //         }
    //     });
    //     return allEmpty;
    // }

    displayDummyChartValues() {
        this.projectsNames = [
            moment.utc().subtract(7, 'day').format('DD-MM'),
            moment.utc().subtract(6, 'day').format('DD-MM'),
            moment.utc().subtract(5, 'day').format('DD-MM'),
            moment.utc().subtract(4, 'day').format('DD-MM'),
            moment.utc().subtract(3, 'day').format('DD-MM'),
            moment.utc().subtract(2, 'day').format('DD-MM'),
            moment.utc().subtract(1, 'day').format('DD-MM'),
        ];
        this.totalPoints = [340, 330, 445, 605, 641, 681, 746];
        this.completedPoints = [310, 290, 400, 555, 601, 621, 712];
        this.cancelledPoints = [];
        this.totalPoints.forEach((element, index) => {
            this.cancelledPoints.push(element - this.completedPoints[index]);
        });
        this.ratings = [3, 2, 4, 4, 3, 4, 5];
        this.chartDisabled = true;
    }

    getChartData() {
        this.dataLoading = true;
        let pageSize = 0, monthlyGroup = false;
        if (this.selectedPeriod.value === 180) {
            monthlyGroup = true;
            pageSize = 6;
        } else if (this.selectedPeriod.value === 365) {
            monthlyGroup = true;
            pageSize = 12;
        } else {
            pageSize = this.selectedPeriod.value;
        }
        let params = '?pageSize=' + pageSize;
        params += '&page=' + this.currentChartPage;
        // params += '&date=2020-10-08T14:45:00Z';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (monthlyGroup) {
            params += '&monthlyGroup=true';
        }
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }

        let depotParams = '';
        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            depotParams = `&depotId=${this.selectedDepot.companyDepot.id}`;
        }

        // this.http.get('api/v1/stats/project-stats' + params).pipe(take(1)).subscribe(response => {
        let groupByValue = 'daily';
        if (this.selectedPeriod.value == 7 || this.selectedPeriod.value == 30) {
            groupByValue = 'daily';
        } else {
            groupByValue = 'monthly';
        }
        this.http.get(`api/internal/v1/statistics/productivity-statistics?daysBeforeUntilDateCount=${this.selectedPeriod.value}${depotParams}&untilDateTime=${moment().utc().format()}&groupBy=${groupByValue}`).pipe(take(1)).subscribe(response => {
            this.dataLoading = false;
            const data = response['items'];
            if (Object.keys(data).length) {
                if (Object.keys(data).length < this.pageSize) {
                    this.lastPage = true;
                } else if (Object.keys(data).length === this.pageSize) {
                    this.lastPage = false;
                }

                Object.keys(data).forEach(index => {
                    if (data[index]) {
                        if (this.totalPoints.length < pageSize) {
                            this.chartData[index] = data[index];
                            if (groupByValue == 'monthly') {
                                this.projectsNames.push(moment(index, 'YYYY-MM').format('MMM'));
                            } else {
                                this.projectsNames.push(moment(index, 'YYYY-MM-DD').format('DD-MM'));
                            }

                            // calculate shipments & rating per date

                            // Object.keys(data[index]['projectProblemIdToProjectProblemStatistics']).forEach(projectProblemId => {
                            //     if (data[index]['projectProblemIdToProjectProblemStatistics'][projectProblemId]['totalShipments'] > 0) {
                            //         totalPointsResult += data[index]['projectProblemIdToProjectProblemStatistics'][projectProblemId]['totalShipments']
                            //         completedPointsResult += data[index]['projectProblemIdToProjectProblemStatistics'][projectProblemId]['completedShipments']
                            //     }
                            // });
                            this.totalPoints.push(data[index]['totalShipments']);
                            this.completedPoints.push(data[index]['completedShipments']);
                            this.cancelledPoints.push(data[index]['totalShipments'] - data[index]['completedShipments']);
                            this.ratings.push(data[index].rating);
                        }
                    }
                });

                // this.projectsNames.reverse();
                // this.totalPoints.reverse();
                // this.completedPoints.reverse();
                // this.cancelledPoints.reverse();

                this.chartDisabled = false;
            } else {
                this.lastPage = true;
                this.displayDummyChartValues();
            }
            this.initChart();
        });
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
        // this.chart.update();
    }

    onDepotChanged() {
        this.chartPeriodChange();
        this.setProjectProblemGridData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('projects-chart');
        if (this.chart) { this.chart.destroy(); }
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
        chartOptions.plugins.legend.display = true;
        if (ctx) {
            if (this.selectedChart.value === 'points') {
                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.projectsNames,
                        datasets: [
                            {
                                label: this.completedLabel,
                                data: this.completedPoints,
                                //fill: true,
                                borderColor: '#00aeba',
                                // borderColor: '#4fbd76',
                                // backgroundColor: '#00aeba70',
                                backgroundColor: '#00aeba70',
                            },
                            {
                                label: this.canceledLabel,
                                data: this.totalPoints,
                                fill: '-1',
                                borderColor: '#00aeba',
                                backgroundColor: '#FF000030',
                            },
                            // {
                            //     label: 'cancelled',
                            //     data: this.cancelledPoints,
                            //     fill: false,
                            //     borderColor: 'red',
                            //     backgroundColor: 'red',
                            // },
                        ]
                    },
                    options: chartOptions
                });
            } else if (this.selectedChart.value === 'rating') {
                this.chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: this.projectsNames,
                        datasets: [{
                            label: this.ratingLabel,
                            barPercentage: 0.9,
                            barThickness: 'flex',
                            maxBarThickness: 150,
                            minBarLength: 2,
                            data: this.ratings,
                            backgroundColor: (() => {
                                const backgroundColours = [];
                                for (let i = 0; i < this.ratings.length; ++i) {
                                    backgroundColours.push(this.chartService.chartColours[i % this.chartService.chartColours.length]);
                                }
                                return backgroundColours;
                            })()
                        }]
                    },
                    options: chartOptions
                });
            }
        }
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced-chart');
        this.openChartButton.nativeElement.classList.add('active');
        this.closeChartButton.nativeElement.classList.remove('active');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced-chart');
        this.openChartButton.nativeElement.classList.remove('active');
        this.closeChartButton.nativeElement.classList.add('active');
    }

    emptyChartArrays() {
        this.projectsNames = [];
        this.totalPoints = [];
        this.completedPoints = [];
        this.cancelledPoints = [];
    }

    projectBoxClicked(id) {
        this.router.navigateByUrl('/projects/' + id);
    }

    getBoxColour(index) {
        const coloursCount = this.boxColours.length;
        // let colour = this.boxColours[0];
        while (index > coloursCount - 1) {
            index = index - coloursCount;
        }
        return this.boxColours[index];
    }

    getProjectsData() {
        let url = this.globals.projectsUrl + '&searchQuery=' + this.searchString;
        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            url += '&depotId=' + String(this.selectedDepot.companyDepot.id);
        }

        return this.http.get(url);
    }

    mode(arr) {
        return arr.sort((a, b) =>
            arr.filter(v => v === a).length
            - arr.filter(v => v === b).length
        ).pop();
    }

    startTour() {
        this.projectsGridGuidedTourComponent.startGuide();
    }

    setProjectProblemGridData() {
        this.getProjectsData().pipe(take(1)).subscribe(response => {
            const data = response['items'];
            const dataRefreshIntervalId = setInterval(() => {
                if (this.globals.depotsDataDone) {
                    clearInterval(dataRefreshIntervalId);
                    this.projectsDataArray = [];
                    data.forEach((projectData, index) => {
                        const project = projectData.project;
                        const gridObject = {
                            id: project.id,
                            name: project.title,
                            drivers: project.drivers_ids.length + ' Drivers',
                            location: this.depotUtils.getDepotName(this.globals.depots[project.company_depot_id].companyDepot),
                            colour: this.getBoxColour(index),
                        };
                        this.projectsDataArray.push(gridObject);
                    });
                }
            }, 200);
        });
    }

    newButtonClicked(): void {
        this.projectModalComponent.openModal();
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getTranslations() {
        this.listen.push(this.translate.get('PROJECT.STOP_POINTS').subscribe((res: string) => { this.stopPointsLabel = res; }));
        this.listen.push(this.translate.get('PROJECT.RATING').subscribe((res: string) => { this.ratingLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_THREE_MONTHS').subscribe((res: string) => { this.lastThreeMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_SIX_MONTHS').subscribe((res: string) => { this.lastSixMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_YEAR').subscribe((res: string) => { this.lastYearLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));

        this.charts = [
            {
                label: this.stopPointsLabel,
                value: 'points',
            },
            {
                label: this.ratingLabel,
                value: 'rating',
            },
        ];

        // add all depots option
        this.depots = this.depotUtils.getDepotsWithAllDepotsOption();
        this.selectedDepot = this.depots[0];
        
        this.chartService.setupPeriodsTranslations(this.lastWeekLabel, this.lastMonthLabel, this.lastThreeMonthsLabel, this.lastSixMonthsLabel, this.lastYearLabel);
        this.periods = [...this.chartService.periods];
        this.periods.splice(0, 1);
        this.selectedPeriod = this.periods[0];
        this.selectedChart = this.charts[0];

        this.getChartData();
        this.setProjectProblemGridData();
    }

    ngOnInit(): void {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.setProjectProblemGridData();
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
