import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
// import { BrowserTracing } from "@sentry/tracing";
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

// Disable Sentry in localhost
// (Would just do Sentry.init in prod mode, but beta & dev2 isn't deployed in prod mode!)
if (
    window.location.href.includes('lastmily') &&
    (window.location.href.includes('apps') || window.location.href.includes('dev2'))
) {
    Sentry.init({
        dsn: "https://938485dbeac348c4bffde6ceaf40a025@o919837.ingest.sentry.io/6507073",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],

        //https://docs.sentry.io/platforms/javascript/performance/
        tracesSampleRate: 1.0,

        // MARIA REVIEW BELOW
        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0,
    });
}

try {
    platformBrowserDynamic().bootstrapModule(AppModule);
} catch (error) {
    console.error(error);
}
