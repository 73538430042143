import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MilyService } from './mily.service';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {

  private printListeners = new Subject<any>();
  private openHelperListeners = new Subject<any>();
  private loadAndOpenHelperListeners = new Subject<any>();
  private mapEmptyListeners = new Subject<any>();
  private mapRefillListeners = new Subject<any>();
  private addMarkerListeners = new Subject<any>();
  private markerClickedListeners = new Subject<any>();
  private focusOnMarkerListeners = new Subject<any>();
  private openImporterMatcherListeners = new Subject<any>();
  private disableCreateStopPointListeners = new Subject<any>();

  printListen(): Observable<any> {
    return this.printListeners.asObservable();
  }

  openHelperListen(): Observable<any> {
    return this.openHelperListeners.asObservable();
  }

  loadAndOpenHelperListen(): Observable<any> {
    return this.loadAndOpenHelperListeners.asObservable();
  }

  mapEmptyListen(): Observable<any> {
    return this.mapEmptyListeners.asObservable();
  }

  mapRefillListen(): Observable<any> {
    return this.mapRefillListeners.asObservable();
  }

  addMarkerListen(): Observable<any> {
    return this.addMarkerListeners.asObservable();
  }

  markerClickedListen(): Observable<any> {
    return this.markerClickedListeners.asObservable();
  }

  focusOnMarkerListen(): Observable<any> {
    return this.focusOnMarkerListeners.asObservable();
  }

  openImporterMatcherListen(): Observable<any> {
    return this.openImporterMatcherListeners.asObservable();
  }

  disableCreateStopPointListen(): Observable<any> {
    return this.disableCreateStopPointListeners.asObservable();
  }

  print() {
    this.printListeners.next(1);
  }

  openHelper(stopPointsData) {
    this.openHelperListeners.next(stopPointsData);
  }

  loadAndOpenHelper(type) {
    this.loadAndOpenHelperListeners.next(type);
  }

  openImporterMatcher(matcherData) {
    this.openImporterMatcherListeners.next(matcherData);
  }

  mapEmpty() {
    this.mapEmptyListeners.next(1);
  }

  mapRefill() {
    this.mapRefillListeners.next(1);
  }

  markerClicked(index) {
    this.markerClickedListeners.next(index);
  }

  focusOnMarker(index) {
    this.focusOnMarkerListeners.next(index);
  }

  addMarker(index, lat, lon) {
    this.addMarkerListeners.next({
      index: index,
      lat: lat,
      lon: lon
    });
  }

  disableCreateStopPoint() {
    this.disableCreateStopPointListeners.next(1);
  }

}
