import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';
import { PaymentsOverviewComponent } from '@app/payments-overview/payments-overview.component';
import { StopPointUtils } from '@app/utils/stop-point-utils';

@Component({
    selector: 'app-project-problem-report-modal',
    templateUrl: './project-problem-report-modal.component.html',
    styleUrls: ['./project-problem-report-modal.component.scss']
})
export class ProjectProblemReportModalComponent implements OnInit, OnDestroy {
    @ViewChild(PaymentsOverviewComponent, { static: false }) paymentsOverviewComponent: PaymentsOverviewComponent;

    updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
    listen = [];
    isModalOpen = false;
    isClickedOnce = false;
    projectProblemId = null;
    departureTime;

    driverIds = [];

    projectName = '';
    date = '';
    totalRating = 0;
    completedPoints = 0;
    totalPoints = 0;
    pointsPercentage = 0;
    duration = '';
    distance = 0;
    owedAmount = 0;
    amounts = {};

    driversReportArray = [];
    driverPayments = [];

    columnDefs;
    gridApi;
    gridColumnApi;
    domLayout = 'autoHeight';
    driversDataArray = [];
    rowData: any;
    headerHeight = 30;
    rowHeight = 74;

    driverOfTheDayMsg = '';
    totalDelayMsg = '';
    driverTitle = '';
    resultsTitle = '';
    delayTitle = '';
    hoursShortMsg = '';
    minutesShortMsg = '';
    secondsShortMsg = '';
    kilometersShortMsg = '';

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        public gridsService: GridsService,
        private viewProjectProblemService: ViewProjectProblemService,
        private projectProblemDataService: ProjectProblemDataService,
        public router: Router,
        private genericService: GenericService,
        public stopPointUtils: StopPointUtils,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        // this.listen.push(this.settingsService.openDynamicFieldModalListen().subscribe(() => {
        //     this.openModal();
        // }));
    }

    getStats(ids, date) {
        this.driverIds = ids;
        // let params = '?pageSize=' + ids.length;
        // params += '&page=0';
        // params += '&ids=' + ids;
        // params += '&date=' + moment(date).utc().format();
        // params += '&days=1';
        // params += '&projectProblemId=' + this.projectProblemId;

        let newParams = '?daysBeforeUntilDateCount=1';
        newParams += '&untilDateTime=' + moment(date).utc().format();
        newParams += '&projectProblemId=' + this.projectProblemId;
        newParams += '&retrieve_type=on-demand';

        // this.http.get('api/v1/stats/driver-stats' + params).pipe(take(1)).subscribe(response => {
        this.http.get('api/internal/v1/statistics/driver-statistics' + newParams).pipe(take(1)).subscribe(response => {
            if (response['items']) {
                if (response['items'].length) {
                    const statsArray = response['items'];
                    const ratings = [];

                    // sort array by driver rating desc
                    statsArray.sort(function (a, b) {
                        return b.rating - a.rating;
                    });

                    let totalDurationMinutes = 0;
                    let i = 1;
                    this.date = moment(date).format('dddd DD MMMM');
                    this.completedPoints = 0;
                    this.totalPoints = 0;
                    this.distance = 0;
                    this.duration = '';

                    response['items'].forEach(driver => {
                        let rating = driver.total_rating;
                        if (rating > 5) { rating = 5; }
                        ratings.push(parseFloat((rating).toFixed(2)));
                        this.completedPoints += driver.completed_shipments;
                        this.totalPoints += driver.total_shipments;
                        this.distance += Number(driver.total_driven_distance);
                        // this.duration = moment.duration(totalTime);
                        totalDurationMinutes += moment.duration(driver.average_stop_over_duration_per_shipment, 'minutes').asMinutes();
                        // get start & stop sp ids for driver
                        const driverRouteSettingId = this.projectProblemDataService.driversToRouteSettingIds[driver.id];
                        const driverRouteSetting = this.projectProblemDataService.routeSettingsById[driverRouteSettingId];
                        const driverStartEndStopPointIds = [driverRouteSetting['routeSetting']['start_stop_point_id'], driverRouteSetting['routeSetting']['finish_stop_point_id']];

                        let gridObject = {
                            id: driver.id,
                            name: driver.name,
                            sequence: '#' + i,
                            stats: {
                                rating: parseFloat((rating).toFixed(2)),
                                completedPoints: driver.completed_shipments,
                                totalPoints: driver.total_shipments,
                                totalDistance: (Number(driver.total_driven_distance) / 1000) + (this.kilometersShortMsg)
                            },
                            totalDrivingDelay: this.calculateDelayInMinutesAndSeconds(driver.total_driving_delay),
                            maxDelays: {
                                maxDelays: this.calculateMaxDelays(driver.top_driving_delays, driver.top_driving_delays_stop_point_ids, driver.top_driving_delays_order_indices), //top_driving_delays_stop_point_ids
                                stopOverDelays: this.calculateStopOverDelays(driver.top_stop_over_delays, driver.top_stop_over_delays_order_indices)
                            }
                            // NOTE why do we do this?
                            // maxDelays: this.calculateMaxDelays(driver.max_departure_delay, driverStartEndStopPointIds), //top_driving_delays_stop_point_ids
                        };
                        this.driversReportArray.push(gridObject);
                        i++;
                    });

                    this.duration = this.calculateTotalDuration(totalDurationMinutes);
                    if (this.totalPoints) {
                        this.pointsPercentage = Math.round((this.completedPoints / this.totalPoints) * 100);
                    }
                    this.distance = Math.round(this.distance / 1000)
                    this.calculateTotalRating(ratings);
                    this.calculateAmountOwed()
                    this.rowData = of(this.driversReportArray);
                    // this.gridApi.setRowData(this.driversReportArray)
                } else {
                    const noData = { noDataText: 'No data' }
                    this.driversReportArray.push(noData);
                    this.rowData = of(this.driversReportArray);
                }


            } else {
                console.error('Report response not containing items');
            }

            this.openModal();

            this.changeDetectorRef.detectChanges(); // force on-push changes
        });
    }

    calculateAmountOwed() {
        let amount = 0;
        let cardPayAmount = 0, cashPayAmount = 0, bankPayAmount = 0, chequePayAmount = 0, chequeDayPayAmount = 0, canceledPayAmount = 0;
        let cashRecipientTotalAmount = 0, cardRecipientTotalAmount = 0, bankRecipientTotalAmount = 0, chequeRecipientTotalAmount = 0, chequeDayRecipientTotalAmount = 0;
        const collaboratorAmounts = {};

        this.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
            if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
            let validatedCollaborator = this.stopPointUtils.checkVoucherCollaboratorValidity(stopPoint);

            // get collaborator & initialize that collaborator's amounts if they don't already exist inside collaborator amounts
            let collaboratorName;
            if (validatedCollaborator.isValid) {
                collaboratorName = validatedCollaborator.collaborator.collaboratorData.collaborator_name;
                if (!collaboratorAmounts[collaboratorName]) {
                    collaboratorAmounts[collaboratorName] = {
                        cardPayAmount: 0,
                        cashPayAmount: 0,
                        bankPayAmount: 0,
                        chequePayAmount: 0,
                        canceledPayAmount: 0,
                    }
                }
            }

            if (stopPoint.solution?.driver) {
                // each stop point has 2 charges: the POD and the recipient charge
                // each will be taken into account ONLY if it has an amount > 0 & its payment method is valid (meaning it's not NO_PAYMENT)
                // for non-voucher stop points, we assume it can have only a POD amount
                let stopPointPayAmount = Number(stopPoint.pay_amount);

                // first we check if the stopPoint is completed
                if (this.stopPointUtils.isCompleted(stopPoint.fulfillment_events[0].reason)) {
                    // this.projectProblemDataService.drivers[stopPoint[]]['driver']['repository'] 
                    // if stop point has a pay_amount > 0 & valid POD payment method, categorize that pay amount based on that POD payment method
                    if (stopPointPayAmount > 0 && stopPoint['executed_pay_on_delivery_payment_method'] !== this.globals.paymentOptionsConstants['NO_PAYMENT']) {
                        console.warn('Added pay_amount of ' + String(stopPoint['pay_amount']) + ' from stop point: ' + String(stopPoint.id) + ' with payment method ' + String(stopPoint.executed_pay_on_delivery_payment_method) + ' [COMPLETED]');

                        this.driverPayments.push({ ...stopPoint, podAmount: stopPointPayAmount });
                        this.driverPayments = [...this.driverPayments];
                        if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['PAY_ON_DELIVERY']) {
                            cashPayAmount += stopPointPayAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['cashPayAmount'] += stopPointPayAmount;
                            }
                        } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['CREDIT_CARD']) {
                            cardPayAmount += stopPointPayAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['cardPayAmount'] += stopPointPayAmount;
                            }
                        } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['BANK_DEPOSIT']) {
                            bankPayAmount += stopPointPayAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['bankPayAmount'] += stopPointPayAmount;
                            }
                        } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['CHEQUE']) {
                            chequePayAmount += stopPointPayAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['chequePayAmount'] += stopPointPayAmount;
                            }
                        } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['DAY_CHEQUE']) {
                            chequeDayPayAmount += stopPointPayAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['chequePayAmount'] += stopPointPayAmount;
                            }
                        }
                    }

                    // if stop point has a voucher, a recipient charge > 0 & a valid FARE payment method, categorize its recipient charge payment based on that FARE payment method
                    // also, if the stop point is not a delivery w/o pickup that has a charge on sender!
                    const isDeliveryWithoutPickupWithSenderCharge = (this.stopPointUtils.isDelivery(stopPoint.service_type) && !stopPoint.related_stop_point_id && stopPoint?.voucher?.paid_by == this.globals.stopPointCollaboratorTypesConstants['SENDER']) ? true : false;
                    const courierFareAmount = Number(stopPoint.pay_amount_courier_fare);
                    if (stopPoint['voucher'] && courierFareAmount > 0 && stopPoint['executed_payment_method_courier_fare'] !== this.globals.paymentOptionsConstants['NO_PAYMENT'] && !isDeliveryWithoutPickupWithSenderCharge) {
                        console.warn('Added recipient charge of ' + String(courierFareAmount) + ' from stop point: ' + String(stopPoint.id) + ' with fare payment method ' + String(stopPoint.executed_payment_method_courier_fare) + ' [COMPLETED]');

                        this.driverPayments.push({ ...stopPoint, recipientChargeAmount: courierFareAmount });
                        this.driverPayments = [...this.driverPayments];
                        if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['PAY_ON_DELIVERY']) {
                            cashRecipientTotalAmount += courierFareAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['cashPayAmount'] += courierFareAmount;
                            }
                        } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['CREDIT_CARD']) {
                            cardRecipientTotalAmount += courierFareAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['cardPayAmount'] += courierFareAmount;
                            }
                        } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['BANK_DEPOSIT']) {
                            bankRecipientTotalAmount += courierFareAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['bankPayAmount'] += courierFareAmount;
                            }
                        } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['CHEQUE']) {
                            chequeRecipientTotalAmount += courierFareAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['chequePayAmount'] += courierFareAmount;
                            }
                        } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['DAY_CHEQUE']) {
                            chequeDayRecipientTotalAmount += courierFareAmount;
                            if (validatedCollaborator.isValid) {
                                collaboratorAmounts[collaboratorName]['chequePayAmount'] += courierFareAmount;
                            }
                        }
                    }
                }
            }
        });

        const collaboratorAmountsArray = [];
        Object.keys(collaboratorAmounts).forEach(collaboratorName => {
            collaboratorAmountsArray.push({
                name: collaboratorName,
                data: {
                    cashPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['cashPayAmount']).toFixed(2)),
                    cardPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['cardPayAmount']).toFixed(2)),
                    bankPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['bankPayAmount']).toFixed(2)),
                    chequePayAmount: Number(Number(collaboratorAmounts[collaboratorName]['chequePayAmount']).toFixed(2)),
                    canceledPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['canceledPayAmount']).toFixed(2))
                }
            });
        });

        // get collaborators totals
        let cashTotalAmount = 0, cardTotalAmount = 0, bankTotalAmount = 0, chequeTotalAmount = 0;
        collaboratorAmountsArray.forEach(collaborator => {
            cashTotalAmount += collaborator['data']['cashPayAmount'];
            cardTotalAmount += collaborator['data']['cardPayAmount'];
            bankTotalAmount += collaborator['data']['bankPayAmount'];
            chequeTotalAmount += collaborator['data']['chequePayAmount'];
        });

        const amounts = {
            cashPayAmount: Number(cashPayAmount).toFixed(2),
            cardPayAmount: Number(cardPayAmount).toFixed(2),
            cashRecipientAmount: Number(cashRecipientTotalAmount).toFixed(2),
            cardRecipientAmount: Number(cardRecipientTotalAmount).toFixed(2),
            cashTotal: Number(cashPayAmount + cashRecipientTotalAmount).toFixed(2),
            cardTotal: Number(cardPayAmount + cardRecipientTotalAmount).toFixed(2),
            bankTotal: Number(bankPayAmount + bankRecipientTotalAmount).toFixed(2),
            chequeTotal: Number(chequePayAmount + chequeRecipientTotalAmount + chequeDayPayAmount + chequeDayRecipientTotalAmount).toFixed(2),
            bankPayAmount: Number(bankPayAmount).toFixed(2),
            bankRecipientAmount: Number(bankRecipientTotalAmount).toFixed(2),
            chequeCombinedPayAmount: Number((chequePayAmount + chequeDayPayAmount)).toFixed(2),
            chequeCombinedRecipientAmount: Number((chequeRecipientTotalAmount + chequeDayRecipientTotalAmount).toFixed(2)),
            chequePayAmount: Number(chequePayAmount).toFixed(2),
            chequeRecipientAmount: Number(chequeRecipientTotalAmount).toFixed(2),
            chequeDayPayAmount: Number(chequeDayPayAmount).toFixed(2),
            chequeDayRecipientAmount: Number(chequeDayRecipientTotalAmount).toFixed(2),
            canceledPayAmount: Number(canceledPayAmount).toFixed(2),
            paymentsTotal: Number(cashPayAmount + cardPayAmount + bankPayAmount + chequePayAmount).toFixed(2),
            recipientTotal: Number(cashRecipientTotalAmount + cardRecipientTotalAmount + bankRecipientTotalAmount + chequeRecipientTotalAmount).toFixed(2),
            collaboratorAmounts: collaboratorAmounts,
            collaboratorAmountsArray: collaboratorAmountsArray
        };

        console.log(amounts);

        this.amounts = amounts;
        this.paymentsOverviewComponent.amounts = this.amounts;
        this.driverPayments = [...this.driverPayments];
        this.paymentsOverviewComponent.driverPayments = [...this.driverPayments];
        this.paymentsOverviewComponent.overviewType = 'projectReport';
        this.owedAmount = Number(Number(Number(amounts['cashTotal']) + Number(amounts['cardTotal']) + Number(amounts['bankTotal']) + Number(amounts['chequeTotal'])).toFixed(2));
    }

    calculateMaxDelays(maxDelays, stopPointsIdsForMaxDelays, stopPointsIndicesForMaxDelays) {
        let delays = [];
        if (maxDelays && maxDelays.length) {
            for (let i = 0; i < maxDelays.length; i++) {
                if (maxDelays[i] && stopPointsIdsForMaxDelays[i] && stopPointsIndicesForMaxDelays[i]) {
                    delays.push({
                        sequence: stopPointsIndicesForMaxDelays[i],
                        delay: this.calculateDelayInMinutesAndSeconds(maxDelays[i])
                    });
                }
            }
        }

        return delays;
    }

    calculateStopOverDelays(stopOverDelays, stopPointsIndicesForMaxDelays) {
        let delays = [];
        if (stopOverDelays && stopOverDelays.length) {
            for (let i = 0; i < stopOverDelays.length; i++) {
                if (stopOverDelays[i] && stopPointsIndicesForMaxDelays[i]) {
                    delays.push({
                        stopPoint: stopPointsIndicesForMaxDelays[i],
                        delay: this.calculateDelayInMinutesAndSeconds(stopOverDelays[i])
                    });
                }
            }
        }

        return delays;
    }

    calculateTotalDuration(durationMinutes) {
        let durationText = '';
        const durationHoursFloor = Math.floor(durationMinutes / 60)
        const durationMinutesFloor = Math.round(durationMinutes % 60);
        if (durationHoursFloor) {
            durationText += durationHoursFloor + this.hoursShortMsg + ' ';
        }
        if (durationMinutesFloor) {
            durationText += durationMinutesFloor + this.minutesShortMsg;
        }
        return durationText;
    }

    calculateDelayInMinutesAndSeconds(delay) {
        const durationMinutes = moment.duration(delay, 'minutes').asMinutes();
        let durationText = '';
        if (durationMinutes > 0) {
            const durationMinutesFloor = Math.floor(durationMinutes);
            const durationSeconds = Math.round(moment.duration((durationMinutes - durationMinutesFloor), 'minutes').asSeconds());
            const durationSecondsFloor = Math.floor(durationSeconds);
            if (durationMinutesFloor > 0) {
                durationText += durationMinutesFloor + this.minutesShortMsg + ' ';
            }
            if (durationSecondsFloor > 0) {
                durationText += durationSecondsFloor + this.secondsShortMsg;
            }
        }

        return durationText;
    }

    calculateTotalRating(ratings) {
        const a = ratings.reduce((a, b) => a + b, 0);
        const b = a / ratings.length;
        const totalRating = (ratings.reduce((a, b) => a + b, 0)) / ratings.length;
        this.totalRating = Number(totalRating.toFixed(2));
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    rowClicked(event) {
        if (event.data.id) {
            if (this.router.url.split('/')[1] == 'dashboard') {
                const driverData = {
                    projectProblemId: this.projectProblemId,
                    departureTime: this.departureTime,
                    driverId: event.data.id,
                };

                this.projectProblemDataService.projectProblemId = this.projectProblemId;
                this.projectProblemDataService.loadRouteSettings().subscribe(response => {
                    this.projectProblemDataService.setRouteSettings(response['items']);

                    // we need solution data to create sequence for driver report...
                    const solutionUrl = this.projectProblemDataService.solutionUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
                    const allStopPointsUrl = this.projectProblemDataService.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
                    this.http.get(solutionUrl).pipe(take(1)).subscribe(solutionRes => {
                        this.http.get(allStopPointsUrl).pipe(take(1)).subscribe(spsRes => {
                            this.projectProblemDataService.stopPointsArray = spsRes['items']['stopPoints'];
                            spsRes['items']['stopPoints'].forEach((stopPoint, index) => {
                                this.projectProblemDataService.stopPoints[stopPoint['id']] = stopPoint;
                                this.projectProblemDataService.stopPointIndexInArray[stopPoint['id']] = index;
                            });
                            this.projectProblemDataService.solutionData = solutionRes['items'];
                            this.projectProblemDataService.setSolutionData();
                            this.genericService.openDriverReportModal(driverData);
                        });
                    });
                });
            } else {
                this.viewProjectProblemService.openDriverReports(event.data.id);
            }
        }
    }

    loadAndOpen(ids, date, projectProblemId, projectName = "") {
        this.projectProblemId = projectProblemId;
        this.projectProblemDataService.projectProblemId = projectProblemId;
        this.departureTime = date;
        this.projectName = projectName;
        if (projectName === "") {
            this.projectName = this.projectProblemDataService.projectName;
        }
        this.projectProblemDataService.loadRouteSettings().subscribe(response => {
            this.projectProblemDataService.setRouteSettings(response['items']);
            // we need solution data to create sequence for driver report...
            const solutionUrl = this.projectProblemDataService.solutionUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
            const allStopPointsUrl = this.projectProblemDataService.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId) + '?pageSize=-1';
            this.http.get(solutionUrl).pipe(take(1)).subscribe(solutionRes => {
                this.http.get(allStopPointsUrl).pipe(take(1)).subscribe(spsRes => {
                    this.projectProblemDataService.stopPointsArray = spsRes['items']['stopPoints'];
                    spsRes['items']['stopPoints'].forEach((stopPoint, index) => {
                        this.projectProblemDataService.stopPoints[stopPoint['id']] = stopPoint;
                        this.projectProblemDataService.stopPointIndexInArray[stopPoint['id']] = index;
                    });
                    this.projectProblemDataService.solutionData = solutionRes['items'];
                    this.projectProblemDataService.setSolutionData();
                    this.getStats(ids, date);
                });
            });
        });
        // this.openModal();
    }

    openModal() {
        this.isModalOpen = true;
        setTimeout(() => {
            const modal = document.querySelector('#project-problem-report-modal');
            const modalBackground = document.querySelector('#project-problem-report-modal-background');
            modal.classList.remove('closed');
            modal.classList.add('open');
            modalBackground.classList.remove('hidden');
            this.paymentsOverviewComponent.amounts = this.amounts;
            this.paymentsOverviewComponent.overviewType = 'projectReport';
        }, 50);
    }

    closeModal() {
        const modal = document.querySelector('#project-problem-report-modal');
        const modalBackground = document.querySelector('#project-problem-report-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.emptyModal();

        setTimeout(() => {
            this.isModalOpen = false;
        }, 1000);
    }

    emptyModal() {
        this.driverIds = [];
        this.driversReportArray = [];
        this.driverPayments = [];
        this.date = '';
        this.totalRating = 0;
        this.completedPoints = 0;
        this.totalPoints = 0;
        this.pointsPercentage = 0;
        this.duration = '';
        this.distance = 0;
        this.owedAmount = 0;
        this.amounts = {};
    }

    // setPaymentsBox(open) {
    //     const paymentsHoverBox = document.getElementById('payments-project-hover-box');
    //     if (paymentsHoverBox) {
    //         if (!this.isPaymentsBoxMarkedOpen) {
    //             if (open) {
    //                 paymentsHoverBox.style.display = 'block';
    //             } else {
    //                 paymentsHoverBox.style.display = 'none';
    //             }
    //         }
    //     }
    // }

    // markOpenPaymentsBox() {
    //     const paymentsHoverBox = document.getElementById('payments-project-hover-box');
    //     if (paymentsHoverBox) {
    //         if (this.isPaymentsBoxMarkedOpen) {
    //             this.isPaymentsBoxMarkedOpen = false;
    //             paymentsHoverBox.style.display = 'none';
    //         } else {
    //             this.isPaymentsBoxMarkedOpen = true;
    //             paymentsHoverBox.style.display = 'block';
    //         }
    //     }
    // }
    openPaymentsModal() {
        const modal = document.querySelector('#payments-modal');
        const modalBackground = document.querySelector('#payments-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closePaymentsModal() {
        const modal = document.querySelector('#payments-modal');
        const modalBackground = document.querySelector('#payments-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        // this.emptyModal();
    }

    statsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="triple-cell-70 margin-top-5px">';
            columnObject += '<span class="bold-letters font-size-1-1em">' + params.getValue().rating + '</span>';
            columnObject += '<span>/5</span> ';
            columnObject += '<span class="light-grey-letters"><i class="fas fa-star"></i></span>';
            columnObject += '</div>';

            columnObject += '<div class="triple-cell-70">';
            columnObject += '<span>' + params.getValue().completedPoints + '/ ' + params.getValue().totalPoints + '</span> ';
            columnObject += '<span class="light-grey-letters"><i class="fas fa-map-marker-alt"></i></span>';
            columnObject += '</div>';

            columnObject += '<div class="triple-cell-70">';
            columnObject += '<span>' + params.getValue().totalDistance + '</span> ';
            columnObject += '<span class="light-grey-letters"><i class="fas fa-road"></i></span>';
            columnObject += '</div>';
        }
        return columnObject;
    }

    delayRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="double-cell line-height-30 text-center">' + this.totalDelayMsg + '</div>';
            columnObject += '<div class="double-cell text-center bold-letters red-letters">' + params.getValue() + '</div>';
        }
        return columnObject;
    }

    stopsDelayRenderer(params) {
        let columnObject = '';
        if (params.data.sequence === '#1') {
            columnObject += '<div class="driver-of-the-day bold-letters standard-width">' + this.driverOfTheDayMsg + '</div>';
        } else if (params.data['maxDelays']) {
            let delaysCount = 0;

            // max delay
            if (params.data['maxDelays']['maxDelays']) {
                if (params.data['maxDelays']['maxDelays'].length) {
                    params.data['maxDelays']['maxDelays'].forEach(stopDelay => {
                        if (delaysCount < 5) {
                            delaysCount += 1;
                            columnObject += '<div class="lines-in-cell-5">';
                            columnObject += '<div>';
                            columnObject += stopDelay.sequence;
                            columnObject += ' <span class="light-grey-letters margin-5px"><i class="fas fa-arrow-right"></i></span> ';
                            columnObject += Number(Number(stopDelay.sequence) + 1);
                            columnObject += '</div>';
                            columnObject += '<div>';
                            columnObject += stopDelay.delay;
                            columnObject += '</div>';
                            columnObject += '</div>';
                        }
                    });
                }
            }

            // stop over delay
            if (params.data['maxDelays']['stopOverDelays']) {
                if (params.data['maxDelays']['stopOverDelays'].length) {
                    params.data['maxDelays']['stopOverDelays'].forEach(stopOverDelay => {
                        if (delaysCount < 5) {
                            delaysCount += 1;
                            columnObject += '<div class="lines-in-cell-5">';
                            columnObject += '<div>';
                            columnObject += stopOverDelay.stopPoint;
                            columnObject += '</div>';
                            columnObject += '<div>';
                            columnObject += stopOverDelay.delay;
                            columnObject += '</div>';
                            columnObject += '</div>';
                        }
                    });
                }
            }
        }

        return columnObject;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.DRIVER').subscribe((res: string) => { this.driverTitle = res; }));
        this.listen.push(this.translate.get('REPORTS.RESULTS').subscribe((res: string) => { this.resultsTitle = res; }));
        this.listen.push(this.translate.get('REPORTS.DELAY').subscribe((res: string) => { this.delayTitle = res; }));
        this.listen.push(this.translate.get('REPORTS.TOTAL_DELAY').subscribe((res: string) => { this.totalDelayMsg = res; }));
        this.listen.push(this.translate.get('REPORTS.DRIVER_OF_THE_DAY').subscribe((res: string) => { this.driverOfTheDayMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.HOURS_SHORT').subscribe((res: string) => { this.hoursShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.MINUTES_SHORT').subscribe((res: string) => { this.minutesShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.SECONDS_SHORT').subscribe((res: string) => { this.secondsShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.KM').subscribe((res: string) => { this.kilometersShortMsg = res; }));
        this.columnDefs = [
            {
                headerName: '',
                field: 'sequence',
                // cellRenderer: this.gridsService.nameRenderer,
                width: this.gridsService.widthCalculator(5)
            },
            {
                headerName: this.driverTitle,
                field: 'name',
                // cellRenderer: this.gridsService.nameRenderer,
                width: this.gridsService.widthCalculator(20)
            },
            {
                headerName: this.resultsTitle,
                field: 'stats',
                sortable: false,
                cellRenderer: this.statsRenderer,
                width: this.gridsService.widthCalculator(15)
            },
            {
                headerName: '',
                field: 'totalDrivingDelay',
                cellRenderer: this.delayRenderer.bind(this),
                width: this.gridsService.widthCalculator(15)
            },
            {
                headerName: this.delayTitle,
                field: 'maxDelays',
                cellRenderer: this.stopsDelayRenderer.bind(this),
                width: this.gridsService.widthCalculator(15)
            },
        ];
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
