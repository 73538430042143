import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  canRequestForPickup = false;
  isInDriverReportModal = false;

  private updatePartnerBalanceListeners = new Subject<any>();
  private newShipmentsGenerateGridDataListeners = new Subject<any>();
  private openShipmentsHistoryChartListeners = new Subject<any>();
  private openShipmentsHistoryFiltersListeners = new Subject<any>();
  private submitPartnerListeners = new Subject<any>();
  private openPartnerViewListeners = new Subject<any>();
  private storeLatestFromSearchInVoucherListeners = new Subject<any>();
  private newShipmentsPickupButtonToggleListeners = new Subject<any>();
  private openProjectProblemReportModalListeners = new Subject<any>();
  private openDriverReportModalListeners = new Subject<any>();
  private updateStopsGridInCollaboratorOverviewListeners = new Subject<any>();
  private comm100ZIndexFixListeners = new Subject<any>();
  private balanceAmountsUpdateListeners = new Subject<any>();
  private stopPointsHistoryUpdateListeners = new Subject<any>();

  listenUpdatePartnerBalance(): Observable<any> {
    return this.updatePartnerBalanceListeners.asObservable();
  }
  listenNewShipmentsGenerateGridData(): Observable<any> {
    return this.newShipmentsGenerateGridDataListeners.asObservable();
  }
  listenOpenShipmentsHistoryChart(): Observable<any> {
    return this.openShipmentsHistoryChartListeners.asObservable();
  }
  listenOpenShipmentsHistoryFilters(): Observable<any> {
    return this.openShipmentsHistoryFiltersListeners.asObservable();
  }
  listenSubmitPartner(): Observable<any> {
    return this.submitPartnerListeners.asObservable();
  }
  listenOpenPartnerView(): Observable<any> {
    return this.openPartnerViewListeners.asObservable();
  }
  listenStoreLatestFromSearchInVoucher(): Observable<any> {
    return this.storeLatestFromSearchInVoucherListeners.asObservable();
  }
  listenNewShipmentsPickupButtonToggle(): Observable<any> {
    return this.newShipmentsPickupButtonToggleListeners.asObservable();
  }
  listenOpenProjectProblemReportModal(): Observable<any> {
    return this.openProjectProblemReportModalListeners.asObservable();
  }
  listenOpenDriverReportModal(): Observable<any> {
    return this.openDriverReportModalListeners.asObservable();
  }
  listenUpdateStopsGridInCollaboratorOverview(): Observable<any> {
    return this.updateStopsGridInCollaboratorOverviewListeners.asObservable();
  }
  listenComm100ZIndexFix(): Observable<any> {
    return this.comm100ZIndexFixListeners.asObservable();
  }
  listenBalanceAmountsUpdate(): Observable<any> {
    return this.balanceAmountsUpdateListeners.asObservable();
  }
  listenStopPointsHistoryUpdate(): Observable<any> {
    return this.stopPointsHistoryUpdateListeners.asObservable();
  }

  updatePartnerBalance(data) {
    this.updatePartnerBalanceListeners.next(data);
  }
  newShipmentsGenerateGridData() {
    this.newShipmentsGenerateGridDataListeners.next(1);
  }
  openShipmentsHistoryChart() {
    this.openShipmentsHistoryChartListeners.next(1);
  }
  openShipmentsHistoryFilters() {
    this.openShipmentsHistoryFiltersListeners.next(1);
  }
  submitPartner() {
    this.submitPartnerListeners.next(1);
  }
  openPartnerView() {
    this.openPartnerViewListeners.next(1);
  }
  storeLatestFromSearchInVoucher(data) {
    this.storeLatestFromSearchInVoucherListeners.next(data);
  }
  newShipmentsPickupButtonToggle(makeDisabled) {
    this.newShipmentsPickupButtonToggleListeners.next(makeDisabled);
  }
  openProjectProblemReportModal(reportData) {
    this.openProjectProblemReportModalListeners.next(reportData);
  }
  openDriverReportModal(driverId) {
    this.openDriverReportModalListeners.next(driverId);
  }
  updateStopsGridInCollaboratorOverview() {
    this.updateStopsGridInCollaboratorOverviewListeners.next(1);
  }
  comm100ZIndexFix() {
    this.comm100ZIndexFixListeners.next(1);
  }
  balanceAmountsUpdate(dates) {
    this.balanceAmountsUpdateListeners.next(dates);
  }
  stopPointsHistoryUpdate(searchText) {
    this.stopPointsHistoryUpdateListeners.next(searchText);
  }
}
