import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { LmTableViewModelService } from '@app/shared/structure/table/table-viewmodel.service';
import { LmBaseEntity } from '@app/model/base-entity';
import { LmButtonConfig, LmButtonModule } from '@app/shared/structure/button/button.component';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { PrimeIcons } from 'primeng/api';

export const ROW_ACTIONS = {
  delete: 'delete',
  edit: 'edit',
  duplicate: 'duplicate'
};


@Component({
  selector: 'lm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class LmTableComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() VM: LmTableViewModelService<LmBaseEntity>;
    @Input() gridlines: boolean;
    @Input() rowHeight: string;
    @Input() striped = true;
    @Input() reorderable: boolean;
    @Input() paginator: boolean;
    @Input() total = false;
    @Input() showCurrentPageReport: boolean;
    @Input() rowsPerPageOptions: number[]; //eg [10,25,50]
    @Input() scrollable: boolean;
    @Input() scrollHeight: string; //eg 400px
    @Input() rows = 5;
    @Input() cols: LmTableBaseColumnDefs[]
    @Input() rowActions: LmButtonConfig[];
    @Input() hideActions = false;
    @Input() styles: object;
    @Input() frozenColumns: string[];
    @Input() rowClick: (...args) => void;
    
    protected provideRowActions(){
      this.rowActions = [
        { 
          id: ROW_ACTIONS.delete, 
          icon: PrimeIcons.TRASH, 
          textOnly: true, 
          hostClass:'lm-table-action-delete',
          command: (row) => this.delete(row)
        },
        { 
          id: ROW_ACTIONS.edit, 
          icon: PrimeIcons.PENCIL, 
          textOnly: true, 
          hostClass:'lm-table-action-edit-modal',
          command: (row) => this.editInModal(row)
        }
    ];
    }
    
    constructor(){}

    onRowClick(row) {
      if (this.rowClick) this.rowClick(row);
    }
    delete(row) {
      if (this.VM.confirmBeforeDeleteText) {
        if (confirm(this.VM.confirmBeforeDeleteText)) {
          this.VM.deleteItem(row.id)
        }
      } else this.VM.deleteItem(row.id)
    }
    editInModal(row){
      const {data} = this.VM.modalConfig;
      this.VM.modalConfig.data = {...data, item: {...row}};
      this.VM.editItemInModal()
    }
    ngOnInit(): void {
      if(this.VM && this.VM.ngOnInit) this.VM.ngOnInit();
      this.provideRowActions();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
      if(this.VM && this.VM.ngOnDestroy) this.VM.ngOnDestroy()
    }

}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TableModule, LmButtonModule],
  exports: [LmTableComponent, TableModule],
  declarations: [LmTableComponent]
})
export class LmTableModule {}

//LmTableBaseColumnDef