import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { Globals } from '@app/services/globals';
import { ISettings_Basic_Services_Dto } from '@app/api/models/settings-services-dto';
import { LmSettingsBasicServicesService } from '@app/api/services/settings-basic-services.service';
import { IPriceListRevaluationApplicablePriceListType, IPriceListRevaluationCategories, IPriceListRevaluationValueType, IPriceListServices } from '@app/model/price-list';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LmSettingsRevaluationsService } from '@app/api/services/settings-revaluations.service';
import { ISettingsRevaluationDto, ISettingsRevaluationsDto } from '@app/api/models/settings-revaluations-dto';
import { RevaluationModalComponent } from './revaluation-modal/revaluation-modal.component';


@Injectable()
export class SettingsRevaluationsViewModelService extends LmTableListViewModelService<ISettingsRevaluationsDto> implements OnInit, OnDestroy {
    categoriesEnum: IPriceListRevaluationCategories;
    applicablePriceListEnum: IPriceListRevaluationApplicablePriceListType;
    valueTypeEnum: IPriceListRevaluationValueType;
    modalConfig: any;
    revaluationModal = RevaluationModalComponent;
    collaboratorId: number;
    count = 0;

    constructor(
        injector: Injector, 
        public globals: Globals,
        @Inject(forwardRef(() => LmSettingsRevaluationsService)) public apiSvc: LmSettingsRevaluationsService
    ){
        super(injector);
        const {priceListRevaluationApplicablePriceListTypeEnum, priceListRevaluationCategoriesEnum, priceListRevaluationValueTypeEnum} = globals;
        this.categoriesEnum = priceListRevaluationCategoriesEnum;
        this.applicablePriceListEnum = priceListRevaluationApplicablePriceListTypeEnum;
        this.valueTypeEnum = priceListRevaluationValueTypeEnum;

        this.modalConfig = { componentType: this.revaluationModal, injector: injector,data: {VM: this, globals: this.globals} }
    }
    


    private transformRows = (res) => {
        this.count = res.items.length;
        res.items.forEach(item => {
            const {operator, value, value_type, applicable_price_list_type, categories} = item;

            const valueType = Object.values(this.valueTypeEnum).find(val => val.key===value_type).caption
            const priceListCaption = Object.values(this.applicablePriceListEnum).find(val => val.key === applicable_price_list_type).caption
            const categoriesCaption = categories.map(cat => Object.values(this.categoriesEnum).find(val => val.key===cat)?.caption).join(', ')
            item.revaluationType = {operator: operator, selection: value_type}
            item.valueCaption = `${operator}${value}${valueType==='value'?'€':'%'}`;
            item.priceListCaption = priceListCaption;
            item.categoriesCaption = categoriesCaption;
        })
        return res;
    }
    
    getRows(){
        return this.apiSvc.apiSettingsRevaluationsAllGet(this.collaboratorId).pipe(switchMap(res => of(this.transformRows(res))));
    }
    getById(id){

    }
    post(item: any) {
        return this.apiSvc.apiSettingsRevaluationsPost({body: item})
    }
    put(id) {
        
    }
    delete(id) {
        return this.apiSvc.apiSettingsRevaluationsSingleDelete({id:id})
    }
    newItem() {
        const newItem = {...INITIAL_DATA.newRevaluationItem};
        newItem.applicable_price_list_type = this.globals.priceListRevaluationApplicablePriceListTypeEnum.Collaborator.key;
        return newItem;
    }
    search(query: string) {
        
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
