import { Component, OnInit, ViewChild, OnDestroy, HostBinding } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ColourService } from '@app/services/colour.service';
import { GenericService } from '@app/services/generic.service';

@Component({
    selector: 'app-load-stops-modal',
    templateUrl: './load-stops-modal.component.html',
    styleUrls: ['./load-stops-modal.component.scss']
})
export class LoadStopsModalComponent implements OnInit, OnDestroy {
    @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
    listen = [];
    isClickedOnce = false;

    manualModifiedRouteUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/manual-modified-route-items';

    projectProblemId;
    routeSettings = [];
    selectedStopPointIds = [];
    updateStopPointIds = [];
    selectedRouteSettingId = null;
    sequenceAlert;

    constructor(
        public translate: TranslateService,
        private viewProjectProblemService: ViewProjectProblemService,
        private projectProblemDataService: ProjectProblemDataService,
        private colourService: ColourService,
        public genericService: GenericService,
    ) {
        this.listen.push(this.viewProjectProblemService.loadStopPointsListen().subscribe((selectedStopPointIds) => {
            this.selectedStopPointIds = [];
            this.selectedStopPointIds = [...selectedStopPointIds];
            this.loadStopsData();
            this.openLoadStopsModal();
        }));
    }

    loadStopsData() {
        const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
        const self = this;
        const dataRefreshIntervalId = setInterval(dataChecker, 200);
        function dataChecker() {
            if (self.projectProblemDataService.dataReady()) {
                clearInterval(dataRefreshIntervalId);
                self.calculateRoutesStats();
            }
        }
    }

    calculateRoutesStats() {
        this.routeSettings = [];
        const routeSettings = this.projectProblemDataService.routeSettingsArray;
        routeSettings.forEach(routeSetting => {
            const routeIndex = routeSetting.routeSetting.route_index;
            const routeSettingId = routeSetting.routeSetting.id;
            let colour = this.colourService.colourCalculator(routeIndex);
            if (this.projectProblemDataService.sequenceArrayPerRouteSettingId) {
                if (this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId]) {
                    if (this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId].length) {
                        // colour += '40';
                        routeSetting['colour'] = colour;
                        routeSetting['class'] = 'route-index-' + (routeIndex + 1);
                        routeSetting['stats'] = {
                            load: routeSetting.routeSetting.load,
                            capacity: routeSetting.vehicle.maximum_cargo_capacity,
                            duration: '',
                            horizon: routeSetting.driver.daily_working_hours
                        };
                        this.routeSettings.push(routeSetting);
                    }
                }
            }
        });
    }

    routeBoxClicked(routeSetting) {
        this.routeSettings.forEach(function (routeSetting, index) {
            routeSetting.selected = false;
        });
        this.selectedRouteSettingId = routeSetting.routeSetting.id;
        routeSetting.selected = true;
    }

    submitStops() {
        if (this.selectedRouteSettingId) {
            const object = {};
            this.selectedStopPointIds.forEach(selectedStopPointId => {
                object[selectedStopPointId] = {
                    project_problem_route_setting_id: this.selectedRouteSettingId
                };
            });
            this.viewProjectProblemService.loadStops(object);
        }
    }

    updateStopPoints() {
        const self = this;
        self.viewProjectProblemService.updateProjectProblemStatus();
        self.closeLoadStopsModal();
        this.updateStopPointIds = [];
    }

    emptyLoadStopsModal() {
        const selectedBox = document.querySelector('.modal-box.selected');
        if (selectedBox) {
            selectedBox.classList.remove('selected');
        }
        this.routeSettings.forEach(function (routeSetting, index) {
            routeSetting.selected = false;
        });
        this.routeSettings = [];
        this.selectedRouteSettingId = null;
        this.selectedStopPointIds = [];
    }

    openLoadStopsModal() {
        this.genericService.comm100ZIndexFix();
        const modal = document.querySelector('.load-stops-modal');
        const modalBackground = document.querySelector('.load-stops-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeLoadStopsModal() {
        const modal = document.querySelector('.load-stops-modal');
        const modalBackground = document.querySelector('.load-stops-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.emptyLoadStopsModal();
        this.viewProjectProblemService.disableMapMultipleSelect();
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => { this.sequenceAlert = res; }));
        }));
        this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => { this.sequenceAlert = res; }));
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
