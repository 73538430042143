import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { SettingsFuelSurchargesViewModelService } from '../settings-fuel-surcharges-viewmodel.service';
import { ISettings_Surcharge_Dto } from '@app/api/models/settings-surcharges-dto';
import { LmNotificationService } from '@app/core/services/notification.service';


@UntilDestroy()
@Component({
  templateUrl: 'new-fuel-surcharge-modal.component.html'
})
export class FuelSurchargeModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: SettingsFuelSurchargesViewModelService;
  saveBttn: LmButtonConfig;
  // newServiceBttn: LmButtonConfig;
  item: ISettings_Surcharge_Dto;
  basicServicesArrayForSelect: Array<object>;

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService,
    private _notificationSvc: LmNotificationService,
  )
  {
    const {data:{item}} = config;
    this.VM = config.data.VM;
    this.item = item ?? this.VM.newItem();
    if (item.services) {
      item.service_ids = item.services.map(item => item.service_id);
    }
    this.updateButtons();

    this.saveBttn = {
      label: this.VM.modalConfig.data.translations['SAVE'], 
      command: ()=> this.ok(),
      styleClass:'lm-bg-color-mily'
    };

    const basicServicesArrayForSelect = [];
    const basicServicesIdsInFuelSurcharges = this.VM.items.flatMap(item => item.services.map(service => service.service_id));
    this.VM.globals.basicServices.forEach((basicService) => {
      if (!basicServicesIdsInFuelSurcharges.includes(basicService.id) || this.item.service_ids.includes(basicService.id)) {
        basicServicesArrayForSelect.push({
          key: basicService.id,
          value: basicService.id,
          caption: basicService.name,
        });
      }
    });
    this.basicServicesArrayForSelect = basicServicesArrayForSelect;
    if (!this.basicServicesArrayForSelect.length) {
      this._notificationSvc.showWarning('', this.VM.modalConfig.data['translations']['NO_SERVICES']);
    }
  }

  private isFormValid(): boolean {
    return Boolean(this.item.value && this.item.service_ids.length);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  addNewService = () => {

  };

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = () => {
    if(!this.item.value) this.item.value = 0;
    if (this.isFormValid()) return this.VM.postItem({ surcharges: [this.item] })
      .pipe(take(1), untilDestroyed(this),)
      .subscribe(_ => this.config.close())
  }
  cancel = () => this.config.close();
}

