import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { PortalIssuesGridComponent } from '@app/grids/portal-issues-grid/portal-issues-grid.component';

@Component({
  selector: 'app-portal-issues-modal',
  templateUrl: './portal-issues-modal.component.html',
  styleUrls: ['./portal-issues-modal.component.scss']
})
export class PortalIssuesModalComponent implements OnInit, OnDestroy {
  @ViewChild(PortalIssuesGridComponent, { static: false }) portalIssuesGridComponent: PortalIssuesGridComponent;

  listen = [];
  isModalOpen = false;
  projectProblemId = null;
  issues = {};

  constructor(
    public translate: TranslateService,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
  ) {
    this.listen.push(this.viewProjectProblemService.openPortalIssuesModalListen().subscribe((data) => {
      this.openModal();
    }));
  }

  openModal() {
    this.isModalOpen = true;
    setTimeout(() => {
      this.portalIssuesGridComponent.projectProblemId = this.projectProblemId;
      this.portalIssuesGridComponent.setDepotsGridData(this.issues);
      const modal = document.querySelector('.portal-issues-modal');
      const modalBackground = document.querySelector('.portal-issues-modal-background');
      modal.classList.remove('closed');
      modal.classList.add('open');
      modalBackground.classList.remove('hidden');
      if (this.portalIssuesGridComponent.stopPointIds.length) {
        this.viewProjectProblemService.focusOnMarkers(this.portalIssuesGridComponent.stopPointIds);
      }
    }, 50);
  }

  closeModal() {
    const modal = document.querySelector('.portal-issues-modal');
    const modalBackground = document.querySelector('.portal-issues-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.portalIssuesGridComponent.markEverythingAsSeen();

    setTimeout(() => {
      this.isModalOpen = false;
    }, 1000);
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
