import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import flatpickr from 'flatpickr';
import moment from 'moment';

@Component({
    selector: 'app-date-picker-modal',
    templateUrl: './date-picker-modal.component.html',
    styleUrls: ['./date-picker-modal.component.scss']
})
export class DatePickerModalComponent implements OnInit, OnDestroy {

    listen = [];
    isClickedOnce = false;
    datetime;

    flatPickr;

    // this is used to identify which field to fill if more than 1 date pickers are needed somewhere
    customMode = 'none'; // none (default), podChequeDay, fareChequeDay

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        private modalService: ModalService,
        public router: Router,
    ) {
        this.listen.push(this.modalService.openDateModalListen().subscribe((type) => {
            this.openModal(type);
        }));
    }

    openModal(customMode = 'none') {
        this.isClickedOnce = false;
        const modal = document.querySelector('#date-picker-modal');
        const modalBackground = document.querySelector('#stops-custom-date-single-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');

        this.customMode = customMode;
    }

    closeModal() {
        const modal = document.querySelector('#date-picker-modal');
        const modalBackground = document.querySelector('#stops-custom-date-single-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');

        this.customMode = 'none';
        this.flatPickr.clear(); // reset datepicker
    }

    submitDate() {
        if (this.flatPickr.selectedDates.length == 1) {
            this.isClickedOnce = true;
            this.modalService.submitDate({
                date: this.flatPickr.selectedDates[0],
                customMode: this.customMode
            });
        }
        this.closeModal();
    }

    ngOnInit() {
        this.flatPickr = flatpickr('#date-single', {
            enableTime: false,
            mode: "single",
            dateFormat: "d-m-Y",
            inline: true,
            monthSelectorType: "static"
        });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}