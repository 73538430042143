// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-container {
  position: absolute;
  left: 10px;
  top: calc(25vh + 140px);
  width: 50px;
  height: 50px;
  z-index: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBvcnRhbC1pc3N1ZXMtZ3JpZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGtCQUFBO0VBQ0EsVUFBQTtFQUNBLHVCQUFBO0VBQ0EsV0FBQTtFQUNBLFlBQUE7RUFDQSxVQUFBO0FBQ0oiLCJmaWxlIjoicG9ydGFsLWlzc3Vlcy1ncmlkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNoZWNrYm94LWNvbnRhaW5lciB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIGxlZnQ6IDEwcHg7XG4gICAgdG9wOiBjYWxjKDI1dmggKyAxNDBweCk7XG4gICAgd2lkdGg6IDUwcHg7XG4gICAgaGVpZ2h0OiA1MHB4O1xuICAgIHotaW5kZXg6IDE7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/grids/portal-issues-grid/portal-issues-grid.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;AACA,oiBAAoiB","sourcesContent":[".checkbox-container {\n    position: absolute;\n    left: 10px;\n    top: calc(25vh + 140px);\n    width: 50px;\n    height: 50px;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
