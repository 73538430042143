import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { CollaboratorViewBalanceComponent } from '@app/collaborator-view/collaborator-view-balance/collaborator-view-balance.component';
import { Router } from '@angular/router';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { GridUtils } from '@app/utils/grid-utils';
import { PaymentStatusSelectEditorComponent } from '@app/grids/grid-editors/payment-status-select-editor/payment-status-select-editor.component';
import { DataService } from '@app/services/data.service';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartOptions
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-partner-shipments-grid',
  templateUrl: './partner-shipments-grid.component.html',
  styleUrls: ['./../grids.scss', './partner-shipments-grid.component.scss']
})
export class PartnerShipmentsGridComponent implements OnInit, OnDestroy {
  @ViewChild('statsContainer', { static: false }) statsContainer;
  @ViewChild('gridContainer', { static: false }) gridContainer;
  @ViewChild('filtersContainer', { static: false }) filtersContainer;
  @ViewChild('filterAllContainer', { static: false }) filterAllContainer;
  @ViewChild('filterHistoryContainer', { static: false }) filterHistoryContainer;
  @ViewChild('filterInProgressContainer', { static: false }) filterInProgressContainer;
  @ViewChild('filterPayOnDeliveryContainer', { static: false }) filterPayOnDeliveryContainer;
  @ViewChild('filterToBeInvoicedContainer', { static: false }) filterToBeInvoicedContainer;
  @ViewChild(CollaboratorViewBalanceComponent, { static: false }) collaboratorViewBalanceComponent: CollaboratorViewBalanceComponent;

  listen = [];

  public modules: Module[] = [
    ServerSideRowModelModule,
    // MenuModule,
    // ColumnsToolPanelModule,
  ];

  collaboratorId;

  columnDefs;
  rowModelType = 'serverSide';
  cacheBlockSize = 50;
  gridApi;
  gridColumnApi;
  rowData = [];
  isEditingActive = false;

  months = [];
  vouchersNumberTotal = [];
  vouchersNumberComplete = [];
  vouchersNumberCancelled = [];

  pagesCount = 0;
  shipmentsDataArray = [];
  shipmentsCount = 0;
  searchTextChanged = new Subject<string>();
  searchString: String = '';
  clicked;
  nameCodeTitle;
  addressTitle;
  completeTitle;
  createdTitle;
  timeTitle;
  volumeTitle;
  statusTitle;
  voucherStatusValue: string;
  payOnDeliveryTitle;
  paymentStatusTitle;
  currency;
  status: string;
  paidLabel: string;
  toPayLabel: string;
  toBeInvoicedLabel: string;
  unpaidLabel: string;
  showSortDropdown = false;
  selectedOrder = '';
  canceledLabel = '';
  completedLabel = '';

  chart;
  chartData = {};
  customerNames = [];
  customerShortNames = [];
  mostVisited = [];

  dataLoading = false;
  pageSize = 10;
  currentChartPage = 0;
  firstPage = true;
  lastPage = false;
  charts = [];
  selectedChart;
  periods = [];
  selectedPeriod;

  chartExpanded = true;
  chartDisabled = false;

  noNameLabel = '';
  returnLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';

  filterType = '';
  invoiceStatusTitle;

  usersRating;
  totalRating;

  stopPointsCount;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private modalService: ModalService,
    public globals: Globals,
    private modalGridService: ModalGridService,
    private chartService: ChartService,
    public gridsService: GridsService,
    private addressService: AddressService,
    private viewProjectProblemService: ViewProjectProblemService,
    public router: Router,
    public stopPointUtils: StopPointUtils,
    public gridUtils: GridUtils,
    public dataService: DataService,
  ) {
    this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
      this.initGrid();
    }));
    this.listen.push(this.modalGridService.listenUpdateVouchersGrid().subscribe(() => {
      this.updateGrid();
    }));
  }

  cellValueChanged(event) {
    const stopPointsToUpdate = this.gridUtils.getStopPointsDataToSavePayOnDeliveryStatus(event, []);

    if (stopPointsToUpdate.length) {
      this.http.put('api/v1/stop-points', { stopPoints: stopPointsToUpdate }).pipe(take(1)).subscribe(response => {
        this.updateGrid();
      });
    }
  }

  createTotalRatingDoughnut() {
    let max = 5;
    let extraCurve = 0.6;
    let quarter = max / 5 + extraCurve;

    let canvas = <HTMLCanvasElement>document.getElementById('totalRatingChart');
    let ctx = canvas.getContext('2d');
    let totalRatingChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [quarter, this.totalRating, max - this.totalRating],
          backgroundColor: [
            'rgba(0, 0, 0, 0)',
            'rgb(0, 174, 186)',
            'rgb(217, 243, 245)',
          ],
        }]
      },
      options: {
        //tooltips: { enabled: false },
        hover: { mode: null },
        //cutoutPercentage: 70,
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      }
    });
  }

  createUsersRatingDoughnut() {
    let max = 5;
    let extraCurve = 0.6;
    let quarter = max / 5 + extraCurve;

    let canvas = <HTMLCanvasElement>document.getElementById('usersRatingChart');
    let ctx = canvas.getContext('2d');
    let usersRatingChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [quarter, this.usersRating, max - this.usersRating],
          backgroundColor: [
            'rgba(0, 0, 0, 0)',
            'rgb(0, 174, 186)',
            'rgb(217, 243, 245)',
          ],
        }]
      },
      options: {
        //tooltips: { enabled: false },
        hover: { mode: null },
        //cutoutPercentage: 70,
        elements: {
          arc: {
            borderWidth: 0
          }
        },
      }
    });
  }

  createDummyRatings() {
    // ratings
    this.totalRating = 3.2;
    this.usersRating = 4;

    // rating charts
    this.createTotalRatingDoughnut();
    this.createUsersRatingDoughnut();
  }

  // update grid
  updateGrid() {
    this.pagesCount = 0;
    // this.gridApi.purgeServerSideCache([]);
    this.gridApi.refreshServerSideStore({ purge: true });
    const gridContainer = document.querySelector('#vouchers-grid-container .ag-body-viewport');
    gridContainer.scrollTo(0, 0);
  }

  newButtonClicked() {
    this.modalService.openCustomerModal(null);
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  getDataForChart() {
    let data = [];
    switch (this.selectedChart.value) {
      case 'most-visited':
        data = this.mostVisited;
        break;
    }
    return data;
  }

  openChart() {
    this.chartExpanded = true;
    this.statsContainer.nativeElement.classList.add('expanded');
    this.gridContainer.nativeElement.classList.add('reduced');
  }

  closeChart() {
    this.chartExpanded = false;
    this.statsContainer.nativeElement.classList.remove('expanded');
    this.gridContainer.nativeElement.classList.remove('reduced');
  }

  closeFilters() {
    this.filtersContainer.nativeElement.classList.add('hidden');
  }

  openFilters() {
    this.filtersContainer.nativeElement.classList.remove('hidden');
  }

  emptyChartArrays() {
    this.customerNames = [];
    this.customerShortNames = [];
    this.mostVisited = [];
  }

  getFirstWord(str) {
    return str.split(' ')[0];
  }

  loadNextChartPage() {
    if (!this.lastPage && !this.dataLoading) {
      this.dataLoading = true;
      this.currentChartPage++;
      this.emptyChartArrays();
      this.getChartData();
    }
  }

  loadPreviousChartPage() {
    if (!this.firstPage && !this.dataLoading) {
      this.dataLoading = true;
      this.currentChartPage--;
      this.emptyChartArrays();
      this.getChartData();
    }
  }

  displayDummyChartValues() {
    this.chartDisabled = true;

    this.months = [];
    for (let i = 1; i < 8; i++) {
      this.months.push(moment.utc().add(i, 'day').format('DD-MM'));
    }

    this.vouchersNumberTotal = [22, 17, 16, 16, 15, 9, 13];
    this.vouchersNumberComplete = [17, 15, 12, 14, 14, 8, 9];

    for (let i = 1; i < 8; i++) {
      this.vouchersNumberCancelled.push(this.vouchersNumberTotal[i] - this.vouchersNumberComplete[i]);
    }
  }

  checkIfAllZero(): Boolean {
    let allZero = true;
    this.mostVisited.forEach(element => {
      if (element !== '0') {
        allZero = false;
      }
    });
    return allZero;
  }

  getChartData() {
    this.dataLoading = true;
    let params = '?pageSize=' + this.pageSize;
    params += '&page=' + this.currentChartPage;
    params += '&date=' + moment().utc().format();
    params += '&days=' + this.selectedPeriod.value;
    if (this.currentChartPage === 0) {
      this.firstPage = true;
    } else {
      this.firstPage = false;
    }
    this.displayDummyChartValues();
    this.initChart();
  }

  chartTypeChange() {
    this.initChart();
  }

  chartPeriodChange() {
    this.currentChartPage = 0;
    this.firstPage = true;
    this.lastPage = false;
    this.emptyChartArrays();
    this.getChartData();
  }

  initChart() {
    const ctx = <HTMLCanvasElement>document.getElementById('vouchers-chart');
    const data = this.getDataForChart();
    if (this.chart) { this.chart.destroy(); }
    const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
    chartOptions.plugins.legend.display = true;
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.months,
          datasets: [
            {
              label: this.completedLabel,
              data: this.vouchersNumberComplete,
              //fill: true,
              borderColor: '#00aeba',
              // borderColor: '#4fbd76',
              // backgroundColor: '#00aeba70',
              backgroundColor: '#00aeba70',
            },
            {
              label: this.canceledLabel,
              data: this.vouchersNumberTotal,
              fill: '-1',
              borderColor: '#00aeba',
              // borderColor: '#4fbd76',
              // backgroundColor: '#00aeba70',
              backgroundColor: '#FF000030',
            },
          ]
        },
        options: chartOptions
      });
    }
  }

  // get request (grid data)
  getVouchersGridData(pageSize) {
    let url;
    // partner stop points
    if (this.globals.isInRoute('collaboratorPartnerView')) {
      // url = 'api/internal/v2/partner-stop-points?collaboratorId=' + this.collaboratorId;
      url = 'api/v1/partner-stop-points?collaboratorId=' + this.collaboratorId;
      url += '&pageSize=' + pageSize;
      url += '&page=' + this.pagesCount;
      url += '&searchQuery=' + this.searchString;
      url += '&' + this.filterType;
    }
    else {
      // url = 'api/internal/v2/partner-stop-points';
      url = 'api/v1/partner-stop-points';
      url += '?pageSize=' + pageSize;
      url += '&page=' + this.pagesCount;
      url += '&searchQuery=' + this.searchString;
      url += '&' + this.filterType;
    }

    return this.http.get(url);
  }

  displayDataToGrid(params, dataArray, pagesCount) {
    if (dataArray.length) {
      let lastRow = -1;
      this.pagesCount++;
      if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
        lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
      }
      params.successCallback(this.setCustomerVouchersGridData(dataArray), lastRow);

    } else {
      if (this.pagesCount) {
        const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
        params.successCallback(this.setCustomerVouchersGridData(dataArray), lastRow);
      } else {
        const noData = { noDataText: 'No data' }
        params.successCallback([noData], 1);
      }
    }
  }

  // request and pass grid data page by page
  loadSinglePage(params) {
    this.getVouchersGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
      this.shipmentsCount = response['itemsMeta'].totalCount;
      this.shipmentsDataArray = response['items'];
      this.displayDataToGrid(params, this.shipmentsDataArray, response['itemsMeta']['pagesCount']);
      this.gridApi.hideOverlay();
    },
      (error) => {
        console.error(error);
        params.failCallback();
        this.gridApi.hideOverlay();
      }
    );
  }

  createServerSideDatasource() {
    const self = this;
    return {
      getRows: function (params) {
        self.gridApi.showLoadingOverlay();
        self.loadSinglePage(params);
      },
    };
  }

  setCustomerVouchersGridData(shipments) {
    this.shipmentsDataArray = []; // empty shipments array first
    let gridObject = {};
    this.dataService.statusPerStopPointMap = this.gridUtils.getStatusPerStopPointMap(shipments);

    if (shipments.length) {
      // vouchers.forEach(voucher => {
      shipments.forEach(shipmentData => {
        // Date
        let creationDateFormatted = '';
        const shipment = shipmentData.stopPoint;
        if (shipment.creation_datetime) {
          creationDateFormatted = moment(shipment.creation_datetime).format('DD/MM/YYYY');
        }

        let completeDatetimeMoment = null;
        if (
          shipment.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
          shipment.solution
        ) {
          completeDatetimeMoment = moment(shipment.solution.atTime);
        }

        // Time (Time Windows)
        let timeWindowStart, timeWindowEnd;
        let timeWindowSecondStart, timeWindowSecondEnd;
        let timeWindowRange, timeWindowRangeMinutes;
        if (shipment.time_windows[0]) {
          timeWindowStart = moment(shipment.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
          timeWindowRange = shipment.time_windows[0].time_window_range;
          timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
          timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

          if (shipment.time_windows[1]) {
            timeWindowSecondStart = moment(shipment.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
            timeWindowRange = shipment.time_windows[1].time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
          }
        }

        // invoice status
        let invoiceStatusClass, invoiceStatusLabel = '-';
        if (shipment.voucher) {
          if (shipment.voucher.invoice_id) {
            invoiceStatusClass = 'status-on-going';
            invoiceStatusLabel = this.toPayLabel;
            if (shipment.voucher.invoice) {
              if (shipment.voucher.invoice.status) {
                if (shipment.voucher.invoice.status === this.globals.invoiceStatusConstants['PAID']) {
                  // if paid
                  invoiceStatusClass = 'status-completed';
                  invoiceStatusLabel = this.paidLabel;
                } else if (shipment.voucher.invoice.status === this.globals.invoiceStatusConstants['OVERDUE']) {
                  // if expired
                  invoiceStatusClass = 'status-canceled';
                  invoiceStatusLabel = this.unpaidLabel;
                }
              }
            }
          }
        }
        if (!this.globals.vouchersEnabled) {
          invoiceStatusLabel = '-';
          invoiceStatusClass = '';
        }

        // volume
        let volumeObj = this.stopPointUtils.getStopPointVolumeWeightColumnData(shipment);

        // name / no name
        let name = shipment.contact_name;
        if (name.includes(this.noNameConstant)) {
          name = name.replace(this.noNameConstant, this.noNameLabel);
        }
        if (name.includes(this.returnConstant)) {
          name = name.replace(this.returnConstant, this.returnLabel);
        }

        // fulfillment status
        const fulfillmentObj = this.gridUtils.getFulfillmentStatus(shipment);

        gridObject = {
          id: shipment.id,
          details: {
            name: name,
            code: shipment.barcode ? shipment.barcode : '-',
            phone: shipment.telephone ? shipment.telephone : '-',
          },
          address: this.addressService.getAddressLabel(shipment.address),
          time: {
            date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
            twFirstStart: timeWindowStart,
            twFirstEnd: timeWindowEnd,
            twSecondStart: timeWindowSecondStart,
            twSecondEnd: timeWindowSecondEnd
          },
          creation_datetime: creationDateFormatted,
          volume: volumeObj,
          status: {
            colorClass: fulfillmentObj.colorClass,
            label: fulfillmentObj.statusValue
          },
          payOnDelivery: this.gridUtils.getPayAmountStatus(shipment),
          invoice_status: {
            colorClass: invoiceStatusClass,
            label: invoiceStatusLabel
          },
          objData: shipment
        };
        this.shipmentsDataArray.push(gridObject);
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.shipmentsDataArray.push(noData);
    }

    return this.shipmentsDataArray;
  }

  cellEditingStarted(event) {
    this.isEditingActive = true;
  }

  cellEditingStopped(event) {
    // row click event fires right after cell edit stop event, so we add a delay to stop row click from opening SP modal
    setTimeout(() => {
      this.isEditingActive = false;
    }, 100);
  }

  // custom cell renderer for name, code & phone
  nameCodePhoneRenderer(params) {
    let columnObject = '';

    if (params.getValue()) {
      if (params.getValue().name) {
        columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
        columnObject += '<div class="double-cell standard-width"><i class="fas fa-qrcode grey-letters"></i> ' + params.getValue().code + ' <i class="fas fa-phone grey-letters"></i>' + params.getValue().phone + '</div>';
        columnObject += '<i class="fas fa-qrcode grey-letters"></i> ' + params.getValue().code;
        columnObject += '<i class="fas fa-phone grey-letters"></i>' + params.getValue().phone;
        columnObject += '</div>';
      }
    }
    return columnObject;
  }

  // custom renderer for status
  statusRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
    }
    return columnObject;
  }

  // custom renderer for invoice status
  invoiceStatusRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
    }
    return columnObject;
  }

  // custom renderer for time
  timeRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().twFirstStart && !params.getValue().twSecondStart) {
        columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
        columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ')</div>'
      }
      else if (params.getValue().twFirstStart && params.getValue().twSecondStart) {
        columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
        columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ' ' + `${params.getValue().twSecondStart}-${params.getValue().twSecondEnd}` + ')</div>'
      }
      else {
        columnObject += '<div class="single-cell standard-width">' + params.getValue().date + '</div>'
      }
    }

    return columnObject;
  }

  // FILTERS
  removeActiveClass() {
    document.querySelector('.filter-type-box.active').classList.remove('active');
  }

  filterAll() {
    this.removeActiveClass();
    this.filterAllContainer.nativeElement.classList.add('active');

    this.filterType = '';
    this.updateGrid();
  }

  filterHistory() {
    this.removeActiveClass();
    this.filterHistoryContainer.nativeElement.classList.add('active');

    this.filterType = 'history=1';
    this.updateGrid();
  }

  filterInProgress() {
    this.removeActiveClass();
    this.filterInProgressContainer.nativeElement.classList.add('active');

    this.filterType = 'inProgress=1';
    this.updateGrid();
  }

  filterPayOnDelivery() {
    this.removeActiveClass();
    this.filterPayOnDeliveryContainer.nativeElement.classList.add('active');

    this.filterType = 'payOnDelivery=1';
    this.updateGrid();
  }

  filterToBeInvoiced() {
    this.removeActiveClass();
    this.filterToBeInvoicedContainer.nativeElement.classList.add('active');

    this.filterType = 'toBeInvoiced=1';
    this.updateGrid();
  }

  rowClicked(event) {
    if (event.column.colId !== "payOnDelivery" && !this.isEditingActive) {
      if (!event.data['noDataText']) {
        this.viewProjectProblemService.openStopModal(
          event.data.id,
          event.data.projectProblemId,
          event.data.projectProblemDepartureDatetime,
        );
      }
    }
  }

  onFirstDataRendered(params) { }

  createdDateRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().name) {
        columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
        columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
      }
      if (params.getValue().customerId) {
        columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
      } else {
        columnObject += '</div>';
      }
    }
    return columnObject;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  initGrid(id = null) {
    this.collaboratorId = id;
    this.pagesCount = 0;

    let intervalId = setInterval(() => {
      if (this.gridApi) {
        this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
        clearInterval(intervalId);
      }
    }, 100);
  }

  getTranslations() {
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.NAME').subscribe((res: string) => { this.nameCodeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.TIME').subscribe((res: string) => { this.timeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.CREATION_DATE').subscribe((res: string) => { this.createdTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.COMPLETE').subscribe((res: string) => { this.completeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.volumeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.INVOICE_STATUS').subscribe((res: string) => { this.invoiceStatusTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.PAY_ON_DELIVERY').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAID').subscribe((res: string) => { this.paidLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_PAY').subscribe((res: string) => { this.toPayLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_BE_INVOICED').subscribe((res: string) => { this.toBeInvoicedLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.UNPAID').subscribe((res: string) => { this.unpaidLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));

    this.getChartData();

    this.columnDefs = [
      {
        headerName: this.nameCodeTitle,
        field: 'details',
        cellRenderer: this.nameCodePhoneRenderer,
        // selectable: true,
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.createdTitle,
        field: 'creation_datetime',
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.addressTitle,
        field: 'address',
        cellRenderer: this.gridsService.addressRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.timeTitle,
        field: 'time',
        cellRenderer: this.timeRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.volumeTitle,
        field: 'volume',
        cellRenderer: this.gridsService.volumeWeightCountRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.payOnDeliveryTitle,
        field: 'payOnDelivery',
        cellRenderer: this.gridsService.payOnDeliveryRenderer,
        cellEditorFramework: PaymentStatusSelectEditorComponent,
        editable: (params) => this.gridsService.isStopPointRowWithPayAmount(params),
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.invoiceStatusTitle,
        field: 'invoice_status',
        cellRenderer: this.invoiceStatusRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: this.statusTitle,
        field: 'status',
        cellRenderer: this.statusRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
    ];
  }

  ngOnInit(): void {
    this.createDummyRatings();

    this.charts = [
      {
        label: 'Vouchers',
        value: 'vouchers',
      },
    ];
    this.periods = this.chartService.periods;
    this.selectedChart = this.charts[0];
    this.selectedPeriod = this.periods[4];

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
        this.pagesCount = 0;
        // this.gridApi.purgeServerSideCache([]);
        this.gridApi.refreshServerSideStore({ purge: true });
      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
