import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import flatpickr from 'flatpickr';
import moment from 'moment';

@Component({
    selector: 'app-date-range-picker-modal',
    templateUrl: './date-range-picker-modal.component.html',
    styleUrls: ['./date-range-picker-modal.component.scss']
})
export class DateRangePickerModalComponent implements OnInit, OnDestroy {

    listen = [];
    isClickedOnce = false;
    datetime;

    flatPickr;

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        private modalService: ModalService,
        public router: Router,
    ) { }

    openModal() {
        this.isClickedOnce = false;
        const modal = document.querySelector('#date-range-picker-modal');
        const modalBackground = document.querySelector('#stops-custom-date-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#date-range-picker-modal');
        const modalBackground = document.querySelector('#stops-custom-date-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');

        this.flatPickr.clear(); // reset datepicker
    }

    submitCustomDateRange() {
        if (this.flatPickr.selectedDates.length == 2) {
            this.isClickedOnce = true;
            this.modalService.submitDateRange(this.flatPickr.selectedDates);
        }
        this.closeModal();
    }

    ngOnInit() {
        this.flatPickr = flatpickr('#date-range', {
            enableTime: false,
            mode: "range",
            dateFormat: "d-m-Y",
            inline: true,
            monthSelectorType: "static",
            maxDate: "today"
        });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}