import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import flatpickr from 'flatpickr';

@Component({
    selector: 'app-customer-date-modal',
    templateUrl: './customer-date-modal.component.html',
    styleUrls: ['./customer-date-modal.component.scss']
})
export class CustomerDateModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;
    datetime;

    calendar;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        private modalService: ModalService,
        public router: Router,
        formBuilder: FormBuilder,
    ) {

    }

    openModal() {
        this.isClickedOnce = false;
        const modal = document.querySelector('#date-modal');
        const modalBackground = document.querySelector('#date-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#date-modal');
        const modalBackground = document.querySelector('#date-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
    }

    submitDate() {
        this.isClickedOnce = true;
        this.closeModal();

        this.modalService.updateCustomerDate(this.datetime);
    }

    ngOnInit() {
        this.calendar = flatpickr('#date', {
            enableTime: false,
            mode: "single",
            dateFormat: "d-m-Y",
            inline: true,
            monthSelectorType: "static"
        });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}