import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { Globals } from '@app/services/globals';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FulfillmentUtils } from '@app/utils/fulfillment-event-utils';


@Component({
  selector: 'app-progress-timeline',
  templateUrl: './progress-timeline.component.html',
  styleUrls: ['./progress-timeline.component.scss']
})
export class ProgressTimelineComponent implements OnInit, OnDestroy {

  listen = [];
  stopPointData;
  timelineStatusTimes = [];
  timelineCompletedParts = [false, false, false];
  routePercentage = 0;
  isTimelineCanceled;
  departureDelay = 25;
  arrivalDelay = 0;
  completedDelay = 0;
  driverName;
  cancelledLabel;
  cancelledTime;
  cancelledEventReasonsLabels = {};

  startReloadLabel;
  arrivedLabel;
  completedLabel;
  estimatedArrivalLabel;
  noDriverName;
  isNavigatorDisabled;

  // arrow offset
  initialArrowOffset = 15.7;
  completedArrowOffset = 76;
  arrivedArrowOffset = 46;
  canceledArrowOffset = 46.7;
  routePercentageArrowOffest = 30;
  extraArrowOffset = 3;

  // stages of the timeline
  fulfillmentEvents = [];

  constructor(
    public translate: TranslateService,
    public globals: Globals,
    private projectProblemDataService: ProjectProblemDataService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private stopPointUtils: StopPointUtils,
    private fulfillmentUtils: FulfillmentUtils,
  ) {
    // this.listen.push(this.viewProjectProblemService.setModalDataListen().subscribe((data) => {
    //   this.setModalData(data);
    // }
    // ));
  }

  resetTimeline() {
    this.stopPointData = null;
    this.timelineStatusTimes = [];
    this.timelineCompletedParts = [false, false, false];
    this.routePercentage = 0;
    this.isTimelineCanceled = false;
    this.departureDelay = 0;
    this.arrivalDelay = 0;
    this.completedDelay = 0;
    this.cancelledLabel = '';
    this.driverName = this.noDriverName;
    this.isNavigatorDisabled = false;

    setTimeout(() => {
      if ((<HTMLElement>document.getElementsByClassName('progressive-arrived')[0]) && (<HTMLElement>document.getElementsByClassName('progressive')[0])) {
        (<HTMLElement>document.getElementsByClassName('progressive-arrived')[0]).style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
        (<HTMLElement>document.getElementsByClassName('progressive')[0]).style.background = `linear-gradient(45deg, #00aeba 0%, #bbb 1%)`;
      }
    }, 500);
  }

  timelineCalculate(stopPointData, projectProblem) {
    this.resetTimeline();
    this.stopPointData = stopPointData;
    let isCompleted = false;

    if (!this.globals.foodModeEnabled) {
      // driver name
      if (stopPointData.solution) {
        this.driverName = stopPointData.solution.driver.userProfile.name;

        // DELAYS
        // departure delay
        this.stopPointData['fulfillment_events'].forEach(event => {
          if (this.stopPointUtils.isInProgress(event.reason) && this.globals.isInRoute('projectView')) {
            const departureDatetime = this.projectProblemDataService.projectProblemData['departure_datetime'];
            const departureStartOffset = this.projectProblemDataService.routeSettingsById[stopPointData.solution.routeSettingId].routeSetting.start_offset_from_departure;
            const departureDatetimeDurationMinutes = moment.duration(departureStartOffset).asMinutes();
            const departureDatetimeFinal = moment(departureDatetime).add(departureDatetimeDurationMinutes, 'minutes');
            const inProgressTime = moment(event.fulfillment_datetime);
            this.departureDelay = moment(inProgressTime).diff(departureDatetimeFinal, 'minutes');
          }
        });

        // arrived delay
        this.stopPointData['fulfillment_events'].forEach(event => {
          if (this.stopPointUtils.isArrived(event.reason)) {
            const estimatedArrivalDatetime = moment(stopPointData.solution.latest_estimated_arrival_datetime);
            const arrivalDatetime = moment(event.fulfillment_datetime);
            this.arrivalDelay = this.dateTimeCalculatorService.calculateTimeDifferenceInMinutes(estimatedArrivalDatetime, arrivalDatetime);
          }
        });

        // completed delay
        this.stopPointData['fulfillment_events'].forEach(event => {
          if (this.stopPointUtils.isCompleted(event.reason)) {
            const estimatedArrivalDatetime = moment(stopPointData.solution.latest_estimated_arrival_datetime);
            const durationMinutes = moment.duration(stopPointData.duration, 'minutes').asMinutes();
            const completedDatetime = moment(event.fulfillment_datetime);
            const estimatedCompleteTime = moment(estimatedArrivalDatetime).add(durationMinutes, 'minutes').format();
            this.completedDelay = this.dateTimeCalculatorService.calculateTimeDifferenceInMinutes(estimatedCompleteTime, completedDatetime);
          }
        });
      } else {
        this.driverName = this.noDriverName;
      }

      // stages of the timeline
      this.fulfillmentEvents = [this.startReloadLabel, this.arrivedLabel, this.completedLabel];
      if (this.stopPointUtils.isInProgress(this.stopPointData['fulfillment_events'][0].reason)) {
        this.fulfillmentEvents[1] = this.estimatedArrivalLabel;
      }

      this.timelineStatusTimes = [];
      this.timelineCompletedParts = [false, false, false];
      let arrowOffsetPercentage = this.initialArrowOffset;
      let linePercentage = 0;

      // arrow position
      // COMPLETED
      if (this.stopPointUtils.isCompleted(this.stopPointData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, true, true];
        arrowOffsetPercentage = this.completedArrowOffset;
        linePercentage = 100;
        isCompleted = true
      }
      else if (this.stopPointUtils.isArrived(this.stopPointData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, true, false];
        arrowOffsetPercentage = this.arrivedArrowOffset;
        linePercentage = 100;
      }
      // IN-PROGRESS
      else if (this.stopPointUtils.isInProgress(this.stopPointData['fulfillment_events'][0].reason)) {
        this.timelineCompletedParts = [true, false, false];

        // calculate percentage (get mins difference between in_progress datetime & estimated arrival time & convert to percentage in order to get a decimal -> e.g 0.2 = 20%)
        const start = this.stopPointData['fulfillment_events'][0]['fulfillment_datetime'];

        if (this.stopPointData['solution']) {
          // const end = this.stopPointData['solution']['latest_estimated_arrival_datetime'];
          const end = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(stopPointData, stopPointData['id'], stopPointData['solution']['routeSettingId'], projectProblem).estimatedArrivalTimeTimestamp;
          
          const maxDiff = Math.abs(moment(end).diff(start, 'minutes')); // 0%
          const currentDiff = Math.abs(moment(end).diff(moment(), 'minutes')); // compares end time w/ current time
          const isAfterEnd = moment().isAfter(moment(end));

          let routePercentageValue = 1 - (currentDiff / maxDiff);
          if (routePercentageValue > 1 || isAfterEnd) { routePercentageValue = 1; } // clamp max route percentage to 1
          this.routePercentage = routePercentageValue;

          // start showing progress of navigator after 5% (0.05 because routePercentage is stored as a decimal value)
          if (this.routePercentage > 0.05) {
            arrowOffsetPercentage = this.initialArrowOffset + (this.routePercentageArrowOffest * this.routePercentage) + this.extraArrowOffset;
            linePercentage = 100 * this.routePercentage;
          } else {
            arrowOffsetPercentage = this.initialArrowOffset;
            linePercentage = 0;
          }
        }
      }
      // CANCELED
      else if (this.stopPointUtils.isCanceled(this.stopPointData['fulfillment_events'][0].reason)) {
        // find the type of canceled
        for (let nonCanceledReasonIndex = 0; nonCanceledReasonIndex < this.stopPointData['fulfillment_events'].length; nonCanceledReasonIndex++) {
          if (!this.stopPointUtils.isCanceled(this.stopPointData['fulfillment_events'][nonCanceledReasonIndex].reason)) {
            // canceled + arrived
            if (this.stopPointUtils.isArrived(this.stopPointData['fulfillment_events'][nonCanceledReasonIndex].reason)) {
              this.timelineCompletedParts = [true, true, false];
              this.isTimelineCanceled = true;
              this.routePercentage = 0.5;
              arrowOffsetPercentage = this.canceledArrowOffset + (this.routePercentageArrowOffest * this.routePercentage) + this.extraArrowOffset;
              linePercentage = 100 * this.routePercentage;
              setTimeout(() => {
                (<HTMLElement>document.getElementsByClassName('progressive-arrived')[0]).style.background = `linear-gradient(45deg, #00aeba ${linePercentage}%, #bbb 1%)`;
              }, 550);
            }
            // canceled + anything else before arrived
            else {
              this.timelineCompletedParts = [true, false, false];
              this.isTimelineCanceled = true;
              this.routePercentage = 0.5;
              arrowOffsetPercentage = this.initialArrowOffset + (this.routePercentageArrowOffest * this.routePercentage) + this.extraArrowOffset;
              linePercentage = 100 * this.routePercentage;
            }

            this.cancelledTime = moment(this.stopPointData['fulfillment_events'][0].fulfillment_datetime).format('HH:mm');
            this.cancelledLabel = this.cancelledEventReasonsLabels[this.stopPointData['fulfillment_events'][0].reason];

            if (this.stopPointData['fulfillment_events'][0].description) {
              this.cancelledLabel += '\n\n' + this.stopPointData['fulfillment_events'][0].description;
            } else if (this.stopPointData['fulfillment_events'][1].description) {
              this.cancelledLabel += '\n\n' + this.stopPointData['fulfillment_events'][1].description;
            }

            break;
          }
        }
      }
      // default
      else {
        this.timelineCompletedParts = [false, false, false];
        arrowOffsetPercentage = this.initialArrowOffset;
        linePercentage = 0;

        if (this.stopPointUtils.isNotStarted(this.stopPointData['fulfillment_events'][0].reason)) {
          this.isNavigatorDisabled = true;
        }
      }

      // set line percentage
      setTimeout(() => {
        (<HTMLElement>document.getElementsByClassName('progressive')[0]).style.background = `linear-gradient(45deg, #00aeba ${linePercentage}%, #bbb 1%)`;
      }, 550);

      // set arrow position
      document.getElementById('timeline-navigator').style.left = `${arrowOffsetPercentage}%`;

      // get times of events //
      // delivery times
      this.stopPointData['fulfillment_events'].forEach(event => {
        if (event.reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME) {
          this.timelineStatusTimes[2] = moment(event.fulfillment_datetime).format('HH:mm');
        }
        if (event.reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME) {
          this.timelineStatusTimes[1] = moment(event.fulfillment_datetime).format('HH:mm');
        }
        if (event.reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].AT_TIME) {
          this.timelineStatusTimes[0] = moment(event.fulfillment_datetime).format('HH:mm');
        }
      });

      // if estimated arrival doesn't exist, then get the estimation from the solution
      if (!this.timelineStatusTimes[1] && !isCompleted) {
        if (this.stopPointData['solution']) {
          this.timelineStatusTimes[1] = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(stopPointData, stopPointData['id'], stopPointData['solution']['routeSettingId'], projectProblem).estimatedArrivalTime;
        }
      }
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('STATUS.START').subscribe((res: string) => { this.startReloadLabel = res; }));
    this.listen.push(this.translate.get('STATUS.ARRIVED').subscribe((res: string) => { this.arrivedLabel = res; }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
    this.listen.push(this.translate.get('STATUS.ESTIMATED_ARRIVAL').subscribe((res: string) => { this.estimatedArrivalLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NO_DRIVER_NAME').subscribe((res: string) => { this.noDriverName = res; }));

    this.listen.push(this.translate.get('CANCEL').subscribe((res: string) => {
      this.fulfillmentUtils.setupReasonLabels(res);
      this.cancelledEventReasonsLabels = this.fulfillmentUtils.cancelledEventReasonsLabels;
    }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
