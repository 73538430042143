import { Map2Component } from './../map2/map.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { OverviewMapComponent } from '@app/overview-map/overview-map.component';
import { CustomersGridComponent } from '@app/grids/customers-grid/customers-grid.component';
import { CustomerInvoicesGridComponent } from '@app/grids/customer-invoices-grid/customer-invoices-grid.component';
import { CustomerCollaboratorsGridComponent } from '@app/grids/customer-collaborators-grid/customer-collaborators-grid.component';
import { CollaboratorVouchersGridComponent } from '@app/grids/collaborator-vouchers-grid/collaborator-vouchers-grid.component';
import { DriversGridComponent } from '@app/grids/drivers-grid/drivers-grid.component';
import { VehicleGridComponent } from '@app/grids/vehicle-grid/vehicle-grid.component';
import { StopsGridComponent } from '@app/grids/stops-grid/stops-grid.component';
import { TeamMemberGridComponent } from '@app/grids/team-member-grid/team-member-grid.component';
import { LoginComponent } from '@app/forms/login/login.component';
import { RegisterComponent } from '@app/forms/register/register.component';
import { SecureComponent } from '@app/secure/secure.component';
import { ProjectViewComponent } from '@app/project-view/project-view.component';
import { ProjectProblemsGridComponent } from '@app/grids/project-problems-grid/project-problems-grid.component';
import { ProjectsGridComponent } from '@app/grids/projects-grid/projects-grid.component';
import { MapBeTestingComponent } from '@app/map-be-testing/map-be-testing.component';
import { WizardComponent } from '@app/wizard/wizard.component';
import { SettingsComponent } from '@app/settings/settings.component';
import { ClientPortalComponent } from '@app/client-portal/client-portal.component';
import { WarehouseGridComponent } from '@app/grids/warehouse-grid/warehouse-grid.component';
import { ForgotPasswordComponent } from '@app/forms/register/forgot-password/forgot-password.component';
import { DemoLoaderComponent } from './../forms/register/demo-loader/demo-loader.component';
import { CollaboratorViewComponent } from './../collaborator-view/collaborator-view.component';
import { CustomerInvoicePrintSingleComponent } from '@app/prints/customer-invoice-print-single/customer-invoice-print-single.component';
import { CustomerPaymentInvoicePrintSingleComponent } from '@app/prints/customer-payment-invoice-print-single/customer-payment-invoice-print-single.component';
import { TestComponent } from '@app/test/test.component';

import { AuthGuardService } from '@app/auth-guard.service';
import { VoucherPrintComponent } from '@app/prints/voucher-print/voucher-print.component';
import { ImageVoucherPrintComponent } from '@app/prints/image-voucher-print/image-voucher-print.component';
import { CollaboratorInvoicesGridComponent } from '@app/grids/collaborator-invoices-grid/collaborator-invoices-grid.component';
import { CollaboratorPaymentsGridComponent } from '@app/grids/collaborator-payments-grid/collaborator-payments-grid.component';
import { ShipmentsHistoryGridComponent } from '@app/grids/shipments-history-grid/shipments-history-grid.component';
import { CollaboratorPartnerViewComponent } from '@app/collaborator-partner-view/collaborator-partner-view.component';
import { PartnerViewComponent } from '@app/partner-view/partner-view.component';
import { PartnersGridComponent } from '@app/grids/partners-grid/partners-grid.component';
import { NewShipmentsViewComponent } from '@app/new-shipments-view/new-shipments-view.component';
import { IndexComponent } from '@app/index/index.component';
import { CollaboratorOverviewComponent } from '@app/collaborator-overview/collaborator-overview.component';
import { ReceiptPrintComponent } from '@app/prints/receipt-print/receipt-print.component';
import { PartnerSettingsComponent } from '@app/grids/partner-settings/partner-settings.component';
import { PendingChangesGuard } from '@app/pending-changes-guard/pending-changes.guard';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { PudoPartnersComponent } from '@app/pudo-courier/pudo-partners/pudo-partners.component';
import { PudoPointsComponent } from '@app/pudo-courier/pudo-points/pudo-points.component';
import { PudoRegistrationConfirmComponent } from '@app/pudo-app/registration/registration-confirm.component';
import { DashboardReportComponent } from '@app/dashboard-report/dashboard-report.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
};

const routes: Routes = [
    {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full'
    },
    {
        path: 'dashboards',
        component: DashboardReportComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: { title: 'DASHBOARD' }
    },
    {
        path: 'index',
        component: IndexComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PROJECTS' }
    },
    {
        path: 'overview',
        component: OverviewMapComponent,
        canActivate: [AuthGuardService],
        data: { title: 'OVERVIEW' }
    },
    {
        path: 'customers',
        component: CustomersGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'CUSTOMERS' }
    },
    {
        path: 'customerCollaborators',
        component: CustomerCollaboratorsGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'COLLABORATORS' }
    },
    {
        path: 'partners',
        component: PartnersGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PARTNERS' }
    },
    {
        path: 'customerInvoices',
        component: CustomerInvoicesGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'CUSTOMERS' }
    },
    {
        path: 'collaboratorInvoices',
        component: CollaboratorInvoicesGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'INVOICES' }
    },
    {
        path: 'collaboratorPayments',
        component: CollaboratorPaymentsGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PAYMENTS' }
    },
    {
        path: 'shipmentsHistory',
        component: ShipmentsHistoryGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'SHIPMENTS' }
    },
    {
        path: 'partnerView',
        component: PartnerViewComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PARTNERS' },
        children: [
            { path: '', redirectTo: 'collaboratorOverview', pathMatch: 'full' },
            { path: 'collaboratorOverview', component: CollaboratorOverviewComponent },
            { path: 'partners', component: PartnersGridComponent },
            { path: 'shipmentsHistory', component: ShipmentsHistoryGridComponent },
            { path: 'newShipmentsView', component: NewShipmentsViewComponent },
            { path: 'collaboratorInvoices', component: CollaboratorInvoicesGridComponent },
            { path: 'collaboratorPayments', component: CollaboratorPaymentsGridComponent },
            { path: 'partnerSettings', component: PartnerSettingsComponent },
        ]
    },
    {
        path: 'newShipmentsView',
        component: NewShipmentsViewComponent,
        canActivate: [AuthGuardService],
        canDeactivate: [PendingChangesGuard],
        data: { title: 'NEW_SHIPMENTS' }
    },
    {
        path: 'collaboratorVouchers',
        component: CollaboratorVouchersGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'COLLABORATOR_VOUCHERS' }
    },
    {
        path: 'collaboratorView',
        component: CollaboratorViewComponent,
        canActivate: [AuthGuardService],
        data: { title: 'COLLABORATORS' }
    },
    {
        path: 'invoicePrint/:hash',
        component: CustomerInvoicePrintSingleComponent,
        // canActivate: [AuthGuardService],
        data: { title: 'CUSTOMERS' }
    },
    {
        path: 'paymentPrint/:hash',
        component: CustomerPaymentInvoicePrintSingleComponent,
        // canActivate: [AuthGuardService],
        data: { title: 'CUSTOMERS' }
    },
    {
        path: 'drivers',
        component: DriversGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'DRIVERS' }
    },
    {
        path: 'vehicles',
        component: VehicleGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'VEHICLES' }
    },
    {
        path: 'pudo',
        loadChildren: () => import('../pudo-courier/pudo.module').then((m) => m.PudoCourierModule)
    },
    {
        path: 'pudo-app',
        loadChildren: () => import('../pudo-app/pudo-app.module').then((m) => m.PudoAppModule)
    },
    {
        path: 'members',
        component: TeamMemberGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'OFFICE_MEMBERS' }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'LOGIN' }
    },
    {
        path: 'login/collaborator/:partnerToken',
        component: LoginComponent,
        data: { title: 'LOGIN' }
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'REGISTER' }
    },
    {
        path: 'register/:state',
        component: RegisterComponent,
        data: { title: 'REGISTER' }
    },
    {
        path: 'register/collaborator/:partnerToken',
        component: RegisterComponent,
        data: { title: 'REGISTER' }
    },
    {
        path: 'register/confirm/:token',
        component: RegisterComponent,
        data: { title: 'REGISTER' }
    },
    {
        path: 'register/forgot-password/:token',
        component: ForgotPasswordComponent,
        data: { title: 'FORGOT_PASSWORD' }
    },
    {
        path: 'confirm-registration/:token', // ------------------ PUDO
        component: PudoRegistrationConfirmComponent,
        data: { title: 'REGISTER' }
    },
    {
        path: 'demo',
        component: DemoLoaderComponent,
        data: { title: 'DEMO' }
    },
    {
        path: 'stops',
        component: StopsGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'STOP_POINTS' }
    },
    {
        path: 'printVouchers',
        component: VoucherPrintComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PRINT_VOUCHERS' }
    },
    {
        path: 'printExtraVouchers',
        component: ImageVoucherPrintComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PRINT_VOUCHERS' }
    },
    {
        path: 'receiptPrint',
        component: ReceiptPrintComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PRINT_RECEIPT' }
    },
    {
        path: 'warehouse',
        component: WarehouseGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'WAREHOUSE' }
    },
    {
        path: 'projects',
        component: ProjectsGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PROJECTS' }
    },
    {
        path: 'projects/:id',
        component: ProjectProblemsGridComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PROJECT_PROBLEMS' }
    },
    // {
    //     path: 'projectProblems',
    //     component: ProjectProblemsGridComponent,
    //     canActivate: [AuthGuardService],
    //     data: { title: 'PROJECT_PROBLEMS' }
    // },
    {
        path: 'projects/projectView/:id',
        component: ProjectViewComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PROJECT' }
    },
    {
        path: 'collaboratorOverview',
        component: CollaboratorOverviewComponent,
        canActivate: [AuthGuardService],
        data: { title: 'OVERVIEW' }
    },
    {
        path: 'customerCollaborators/collaboratorView/:id',
        component: CollaboratorViewComponent,
        canActivate: [AuthGuardService],
        data: { title: 'COLLABORATORS' }
    },
    {
        path: 'partners/collaboratorPartnerView/:id',
        component: CollaboratorPartnerViewComponent,
        canActivate: [AuthGuardService],
        data: { title: 'PARTNERS' }
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuardService],
        data: { title: 'SETTINGS' }
    },
    {
        path: 'wizard',
        component: WizardComponent,
        data: { title: 'WIZARD' }
    },
    {
        path: 'client/:hash',
        component: ClientPortalComponent,
        data: { title: 'PORTAL' }
    },
    {
        path: 'c/:hash',
        component: ClientPortalComponent,
        data: { title: 'PORTAL' }
    },
    {
        path: 'testMap',
        component: Map2Component,
        data: { title: 'MAP' }
    },
    {
        path: 'test',
        component: TestComponent,
        data: { title: 'TEST' }
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, routerOptions)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
