import { Injectable, OnInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class GenericUtils {
    listen = [];

    polylineOverlapText: String;

    constructor(
        public globals: Globals,
        public translate: TranslateService,
    ) {

    }

    isElementInViewport(element) {
        if (
            element.getBoundingClientRect().top >= 0 &&
            element.getBoundingClientRect().left >= 0 &&
            element.getBoundingClientRect().bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            element.getBoundingClientRect().right <= (window.innerWidth || document.documentElement.clientWidth)
        ) {
            return true;
        } else {
            return false;
        }
    }

    scrollToContainerBottom(elementToScroll) {
        setTimeout(() => {
            elementToScroll.scrollTo({ top: elementToScroll.scrollHeight, behavior: 'smooth' });
        }, 100);
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    removeDotsFromString(str: string) {
        return str.replace(/\./g, "");;
    }

    areObjectKeysEqual(obj1, obj2) {
        if (Object.keys(obj1).length == Object.keys(obj2).length) {
            return true;
        }
        return false;
    }

    getTranslations() {
        this.listen.push(this.translate.get('WARNINGS.REGION_POLYLINE_OVERLAP').subscribe((res: string) => { this.polylineOverlapText = res; }));
    }
}
