import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ChatModalDummyComponent } from './chat-modal-dummy/chat-modal-dummy.component';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { createPicker } from 'picmo';
import ConnectyCube from 'connectycube';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html'
})
export class ChatModalComponent implements OnInit, OnDestroy {
  @ViewChild(ChatModalDummyComponent, { static: false }) chatModalDummyComponent: ChatModalDummyComponent;

  url = 'api/v1/';

  listen = [];
  myForm: FormGroup;
  isInMapView;

  searchTextChanged = new Subject<string>();
  activeFilter = 'all';
  channelCategories = ['drivers', 'recipients', 'consignors', 'partners', 'team'];
  chats = [];
  channels = [];
  allChannels = [];
  messages = [];
  activeChannelInfo = {};
  userToDialogMap = {};

  isOpponentTypingMessage = false;
  opponentsTypingMessage = [];
  hasLoadedChats = false;
  isBeingCalledVideo = false;
  isBeingCalledAudio = false;
  isInVideoCall = false;
  isInAudioCall = false;
  isMuteVideo = false;
  isMuteMicrophone = false;
  isOpponentVideoMuted = false;
  callOpponentId;

  callAttemptAudio;

  searchString = '';

  callWarningAlertText;
  callStartedFeedbackMsg;
  callEndedFeedbackMsg;
  callAcceptedFeedbackMsg;
  callRejectedFeedbackMsg;

  emojiPicker;
  session;

  username;
  userId;
  fullName;
  avatarBase64;
  opponentId;
  callerName;

  selectedUsers = [];
  groupChatName: String;

  users = [];
  usersForGroup = [];

  fetchedUsersData = [];
  usersData = [];

  usernameToFullName = {};
  userIdToFullName = {};
  userIdToAvatar = {};

  // async avatars
  ccUserNameToAvatarMap = {};
  ccIdToAvatarMap = {};
  avatarHashes = [];

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    formBuilder: FormBuilder,
  ) {
    // check for router changes to update chat position
    this.listen.push(router.events.subscribe(response => {
      if (globals.isInRoute('projectView') || globals.isInRoute('senderOverview')) {
        this.isInMapView = true;
      } else {
        this.isInMapView = false;
      }
    }));

    var https = require('http');

    this.myForm = formBuilder.group({
      "groupChatName": [{ value: this.groupChatName, disabled: true }],
      "selectedUsers": [this.selectedUsers],
    });
  }

  resetForm() {
    this.groupChatName = null;
    this.selectedUsers = [];
    this.myForm.patchValue({
      "groupChatName": this.groupChatName,
      "selectedUsers": this.selectedUsers
    });

    this.myForm.controls['groupChatName'].disable();
  }

  toggleChatModal() {
    console.log(this.globals.isInRoute('projectView'));
    // if modal is closed then disable activeChannelInfo
    if (this.chatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
      this.activeChannelInfo = {};
    }

    // toggle chat (allow only after all chats have initially loaded)
    if (this.hasLoadedChats) {
      this.chatModalDummyComponent.modal.nativeElement.classList.toggle('open');
      this.chatModalDummyComponent.modalButtons.nativeElement.classList.toggle('open');

      // if modal was closed, then set the first chat as the active one
      if (this.chatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
        this.goToFirstChat();
      }
    }
  }

  openModal() {
    if (!this.chatModalDummyComponent.modal.nativeElement.classList.contains('open') &&
      !this.chatModalDummyComponent.modalButtons.nativeElement.classList.contains('open')) {
      this.chatModalDummyComponent.modal.nativeElement.classList.add('open');
      this.chatModalDummyComponent.modalButtons.nativeElement.classList.add('open');
    }
  }

  toggleNewMessageModal() {
    if (this.chatModalDummyComponent.messageModal.nativeElement.classList.contains('closed')) {
      this.chatModalDummyComponent.messageModal.nativeElement.classList.remove('closed');
      this.chatModalDummyComponent.messageModal.nativeElement.classList.add('open');
      this.chatModalDummyComponent.messageModalBackground.nativeElement.classList.remove('hidden');
      this.updateUsersData();
    } else {
      this.chatModalDummyComponent.messageModal.nativeElement.classList.add('closed');
      this.chatModalDummyComponent.messageModal.nativeElement.classList.remove('open');
      this.chatModalDummyComponent.messageModalBackground.nativeElement.classList.add('hidden');
    }

    this.resetForm();
  }

  updateUsersData(refreshChats = false) {
    // get user's users data
    this.http.get('api/internal/v1/chat/chat-user-profiles').subscribe(userProfiles => {
      this.fetchedUsersData = userProfiles['items'];
      this.fetchedUsersData.forEach(user => {
        // determine (user) channel category
        let channelCategoryValue = 'team'; // use team as default
        if (user.driver_id && user.roles.includes(this.globals.userProfileRolesConstants['LASTMILY_ANDROID_DRIVER'])) {
          channelCategoryValue = 'drivers';
        } else if (user.roles.includes(this.globals.userProfileRolesConstants['LASTMILY_WEB_OPERATOR'])) {
          channelCategoryValue = 'team';
        } else {
          if (user.company_sender_partner_type == this.globals.companyModes['SENDER']) {
            channelCategoryValue = 'consignors';
          } else if (user.company_sender_partner_type == this.globals.companyModes['NORMAL']) {
            channelCategoryValue = 'partners';
          }
        }

        this.usernameToFullName['user_' + user.id] = user.name;
        const searchParams = { login: 'user_' + user.id };

        this.usersData = [];
        ConnectyCube.users
          .get(searchParams)
          .then((result) => {
            this.usersData.push(
              {
                "userId": result['user'].id,
                "name": user.name,
                "channel_category": channelCategoryValue,
                "avatar": user.avatar
              }
            );

            this.userIdToFullName[result['user'].id] = user['name'];
            this.userIdToAvatar[result['user'].id] = user['avatar'];

            // if this is the last user to be fetched, then proceed with the login & init logic, otherwise just get the user's data
            // if (this.usersData.length == this.fetchedUsersData.length) {
            if (this.usersData.length == this.fetchedUsersData.length) {
              // (new conversation) dropdown users
              this.usersForGroup = [];
              this.usersData.forEach(user => {
                if (user.userId != this.userId) {
                  this.usersForGroup.push(user);
                }
              });
              this.usersForGroup = [...this.usersForGroup];

              // find the logged in user's name & avatar from our data
              this.usersData.forEach(user => {
                if (this.userId == user.userId) {
                  this.fullName = user.name;
                  this.avatarBase64 = user.avatar;
                }
              });
            }

            if (refreshChats) {
              this.CCGetChats();
            }
          })
          .catch((error) => { });
      });
    });
  }

  goToFirstChat() {
    if (this.channels.length) {
      this.openChat(this.channels[0]['dialogId']);
    }
  }

  searchStringChanged(event) {
    this.searchTextChanged.next(event);
  }

  sendMessage(messageTextManual = null) {
    let messageText;
    if (messageTextManual) {
      messageText = messageTextManual;
    } else {
      messageText = (<HTMLInputElement>document.getElementById('chat-message-input')).value;
    }

    const stringSpacesLength = messageText.trim().length;

    if (stringSpacesLength !== 0 && messageText !== '') {
      const message: Message = {
        messageId: null,
        senderName: this.fullName,
        initials: this.calculateNameInitials(this.fullName),
        messageText: messageText,
        isSystemMessage: false,
        time: moment().format('HH:mm'),
        messageStatus: 'sent',
        sentByMe: true,
        avatarBase64: this.avatarBase64 ?? null
      };

      this.CCSendMessage(this.activeChannelInfo['opponentId'], this.activeChannelInfo['dialogId'], messageText, this.activeChannelInfo['chatType']);

      if (!messageTextManual) {
        this.messages.push(message);
        this.messages = [...this.messages];
      }

      // clear message text area & scroll to bottom & send isTyping of opponent to false
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('chat-message-input')).value = '';
        document.getElementById('chat-channel-body').scrollTop = document.getElementById('chat-channel-body').scrollHeight;

        // remove 'is typing' status (only for peer-to-peer chat)
        if (this.activeChannelInfo['chatType'] == 3) {
          ConnectyCube.chat.sendIsStopTypingStatus(this.activeChannelInfo['opponentId']);
        }
      }, 50);
    }
  }

  // new message (which is actually new chat)
  sendNewMessage() {
    if (this.myForm.value.selectedUsers.length) {
      this.selectedUsers = this.myForm.value.selectedUsers;

      // if only 1 user is selected, then create a normal chat (no need for group name)
      if (this.selectedUsers.length === 1) {
        this.CCCreateChat(this.myForm.value.selectedUsers[0]);
        this.toggleNewMessageModal();
      }
      // group/public chat (requires group name!)
      else if (this.myForm.value.groupChatName) {
        // group chat
        if (this.selectedUsers.length <= 10) {
          this.CCCreateGroupChat(this.selectedUsers);
        }
        // public chat (invited users) (only way to use this is to send an invite to each user selected via a normal message)
        else {
          this.CCCreatePublicChat();
        }

        this.toggleNewMessageModal();
      }
    }
  }

  // used to enable/disable group name based on users selected
  newMessageUsersUpdated() {
    this.selectedUsers = this.myForm.value.selectedUsers;
    if (this.selectedUsers.length > 1) {
      this.myForm.controls['groupChatName'].enable();
    } else {
      this.myForm.controls['groupChatName'].disable();
      this.myForm.patchValue({
        'groupChatName': null
      });
      M.updateTextFields();
    }
  }

  getAndShowChannels() {
    this.channels = [];
    if (this.chats.length) {
      this.chats.forEach((chat, chatIndex) => {
        // find the opponent's id for this channel
        this.opponentId = null;
        chat.occupants_ids.forEach(occupantId => {
          if (occupantId != this.userId) {
            this.opponentId = occupantId;
          }
        });

        // replace call codes in last message of channel w/ the correct label
        let messageTextValue = chat.last_message;
        switch (chat.last_message) {
          case 'CONNECTYCUBE_START_CALL':
            messageTextValue = this.callStartedFeedbackMsg;
            break;
          case 'CONNECTYCUBE_END_CALL':
            messageTextValue = this.callEndedFeedbackMsg;
            break;
          case 'CONNECTYCUBE_ACCEPT_CALL':
            messageTextValue = this.callAcceptedFeedbackMsg;
            break;
          case 'CONNECTYCUBE_REJECT_CALL':
            messageTextValue = this.callRejectedFeedbackMsg;
            break;
        }

        // initials for peer-to-peer & group chats
        let initialsValue = '';
        if (chat.type == 3) {
          initialsValue = this.calculateNameInitials(this.userIdToFullName[this.opponentId]);
        } else {
          initialsValue = this.calculateNameInitials(chat.name);
        }

        // user role icon & avatar in chats list
        let userRole;
        if (chat.type == 3) {
          chat.occupants_ids.forEach(userId => {
            if (userId != this.userId) {
              this.usersData.forEach(userProfileData => {
                if (userProfileData.userId == userId) {
                  userRole = userProfileData.channel_category;
                }
              });
            }
          });
        } else {
          userRole = this.channelCategories[4]; // team for group/public chats
        }

        const channel: Channel = {
          id: null,
          chatType: chat.type,
          userId: this.opponentId,
          userName: chat.name,
          dialogId: chat._id,
          description: 'Description goes here but chat does not return that property',
          // name: chat.name,
          name: chat.type != 2 ? this.userIdToFullName[this.opponentId] : chat.name,
          // initials: this.calculateNameInitials(chat.name),
          initials: initialsValue,
          lastMessagePreview: messageTextValue,
          time: String(moment.unix(chat.last_message_date_sent).format('HH:mm')),
          lastUpdatedDatetime: chat.updated_at,
          channelCategory: userRole,
          unreadMessagesCount: chat.unread_messages_count,
          lastMessageStatus: chat.last_message_status,
          lastMessageUserId: chat.last_message_user_id,
          avatarBase64: this.ccUserNameToAvatarMap[chat.name] ? this.ccUserNameToAvatarMap[chat.name] : null
        };

        this.channels.push(channel);
      });

      this.allChannels = this.channels; // preserve all channels

      // create a map object with key: userId -> value: dialogId
      this.allChannels.forEach(channel => {
        if (channel.chaType != 2) {
          this.userToDialogMap[channel.userId] = channel.dialogId;
        }
      });

      // start fetching avatars asynchronously
      this.loadAvatarsAsync();
    }
  }

  getAndShowActiveChannelInfo(channelId) {
    const activeChannel = this.channels.find(channel => {
      return channel.dialogId == channelId;
    });

    const activeChannelInfo: ActiveChannelInfo = {
      id: activeChannel.id,
      chatType: activeChannel.chatType,
      orderIndex: activeChannel.orderIndex,
      opponentId: activeChannel.userId,
      dialogId: activeChannel.dialogId,
      name: activeChannel.name,
      userName: activeChannel.userName,
      initials: this.calculateNameInitials(activeChannel.name),
      description: activeChannel.description,
      avatarBase64: activeChannel.avatarBase64 ?? null,
      unreadMessagesCount: activeChannel.unreadMessagesCount,
      lastMessageStatus: activeChannel.lastMessageStatus,
      lastMessageUserId: activeChannel.lastMessageUserId
    };

    this.activeChannelInfo = { ...activeChannelInfo };
  }

  getAndShowMessages(dialogId) {
    if (dialogId != this.activeChannelInfo['dialogId']) {
      this.messages = [];
    }

    const newMessages = [];

    const params = {
      chat_dialog_id: dialogId,
      sort_desc: 'date_sent',
      limit: 60,
      skip: 0,
    };

    // set all messages as read if user opening chat is NOT the user of the last message sent
    ConnectyCube.chat.message
      .list(params)
      .then((messages) => {
        messages['items'].forEach(currMessage => {
          if (currMessage.chat_dialog_id == dialogId) {
            // let messageChannelIndex = this.channels.findIndex(channel => {
            //   return channel.dialogId == currMessage.chat_dialog_id;
            // });

            let sentByMeValue, senderNameValue = '-', avatarValue;
            if (currMessage.sender_id != this.userId) {
              this.usersData.forEach(user => {
                if (user.userId == currMessage.sender_id) {
                  senderNameValue = user.name;
                  sentByMeValue = false;
                }
              });
            } else {
              senderNameValue = this.fullName;
              sentByMeValue = true;
              avatarValue = this.avatarBase64;
            }

            let messageStatusValue = 'sent';
            if (currMessage.delivered_ids.includes(this.activeChannelInfo['opponentId'])) {
              messageStatusValue = 'delivered';
            }
            if (currMessage.read_ids.includes(this.activeChannelInfo['opponentId'])) {
              messageStatusValue = 'read';
            }

            // replace call codes in messages w/ the correct label
            let messageTextValue = currMessage.message ?? "";
            let isSystemMessageValue = false;
            switch (currMessage.message) {
              case 'CONNECTYCUBE_START_CALL':
                messageTextValue = this.callStartedFeedbackMsg;
                isSystemMessageValue = true;
                break;
              case 'CONNECTYCUBE_END_CALL':
                messageTextValue = this.callEndedFeedbackMsg;
                isSystemMessageValue = true;
                break;
              case 'CONNECTYCUBE_ACCEPT_CALL':
                messageTextValue = this.callAcceptedFeedbackMsg;
                isSystemMessageValue = true;
                break;
              case 'CONNECTYCUBE_REJECT_CALL':
                messageTextValue = this.callRejectedFeedbackMsg;
                isSystemMessageValue = true;
                break;
            }

            // check for hyperlink (if link is found keep only that)
            let sentByHyperlinkClass = sentByMeValue ? 'link-sent-by-user' : 'link-sent-by-opponent';
            messageTextValue = messageTextValue.replace(
              /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
              '<a href="$1" target="blank_" class="' + sentByHyperlinkClass + '">$1</a>'
            );

            if (messageTextValue.search('<a href') !== -1) {
              messageTextValue = messageTextValue.substring(messageTextValue.indexOf('<a href'));
            }

            const newMessage: Message = {
              messageId: currMessage._id,
              senderName: senderNameValue,
              initials: this.calculateNameInitials(senderNameValue),
              messageText: messageTextValue,
              isSystemMessage: isSystemMessageValue,
              time: moment.unix(currMessage.date_sent).format('HH:mm'),
              messageStatus: messageStatusValue,
              sentByMe: sentByMeValue,
              avatarBase64: this.ccIdToAvatarMap[currMessage.sender_id]
            };

            newMessages.unshift(newMessage);
          }
        });

        this.messages = [...newMessages];

        // initial chat scroll to bottom on chat open
        setTimeout(() => {
          if (document.getElementById('chat-channel-body')) {
            document.getElementById('chat-channel-body').scrollTop = document.getElementById('chat-channel-body').scrollHeight;
          }
        }, 600);
      })
      .catch((error) => { console.error(error); });
  }

  calculateNameInitials(name) {
    if (name) {
      const array = name.split(' ');
      let initials = '';
      if (array.length) {
        if (array[0]) {
          initials = array[0].charAt(0);
        }
        if (array[1]) {
          initials = initials + array[1].charAt(0);
        }
      }
      return initials;
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('CHAT.CALL_WARNING').subscribe((res: string) => {
      this.callWarningAlertText = res;
    }));
    this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_STARTED').subscribe((res: string) => {
      this.callStartedFeedbackMsg = res;
    }));
    this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_ENDED').subscribe((res: string) => {
      this.callEndedFeedbackMsg = res;
    }));
    this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_ACCEPTED').subscribe((res: string) => {
      this.callAcceptedFeedbackMsg = res;
    }));
    this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_REJECTED').subscribe((res: string) => {
      this.callRejectedFeedbackMsg = res;
    }));
  }

  toggleEmojiPicker() {
    if (document.getElementById('emojis-container').style.display != 'block') {
      // emojis load & select event
      this.emojiPicker = createPicker({
        rootElement: document.getElementById('emojis-container'),
        showCategoryTabs: false,
        showRecents: false,
        showSearch: false,
        showPreview: false,
        initialCategory: 'smileys-emotion'
      });
      this.emojiPicker.addEventListener('emoji:select', selection => {
        (<HTMLInputElement>document.getElementById('chat-message-input')).value += selection.emoji;
      });

      document.getElementById('emojis-container').style.display = 'block';
      document.getElementById('emojis-container').style.top = document.getElementById('chat-emoji-select-button').getBoundingClientRect().top - 270 + 'px';
      document.getElementById('emojis-container').style.left = document.getElementById('chat-emoji-select-button').getBoundingClientRect().left + 'px';
    } else {
      this.closeEmojiPicker();
    }
  }

  closeEmojiPicker() {
    document.getElementById('emojis-container').style.display = 'none';
    this.emojiPicker.destroy();
  }

  createVideoSession() {
    if (!this.isInAudioCall && !this.isInVideoCall) {
      const calleesIds = [this.activeChannelInfo['opponentId']]; // User's ids
      const sessionType = ConnectyCube.videochat.CallType.VIDEO; // AUDIO is also possible
      const additionalOptions = {};
      this.session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);

      // play audio
      this.callAttemptAudio = new Audio('/assets/lastmilyAssets/audio/chat_phone_calling.mp3');
      this.callAttemptAudio.play();

      const mediaParams = {
        audio: true,
        video: true
      };

      this.sendMessage(this.globals.chatCallStatusCodes['START_CALL'])
      this.session
        .getUserMedia(mediaParams)
        .then((localStream) => {
          this.session.call(extension, (error) => {
            this.callOpponentId = this.activeChannelInfo['opponentId'];
          });
        })
        .catch((error) => { console.log(error); });

      const extension = {};
    }
    // if in call then alert the user that the current call is going to end & if they confirm then end the call and perform the next call
    else {
      const confirmCall = confirm(this.callWarningAlertText);
      if (confirmCall) {
        this.endCall();
        this.createVideoSession();
      }
    }
  }

  createAudioSession() {
    if (!this.isInAudioCall && !this.isInVideoCall) {
      const calleesIds = [this.activeChannelInfo['opponentId']]; // User's ids
      const sessionType = ConnectyCube.videochat.CallType.AUDIO;
      const additionalOptions = {};
      this.session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);

      // play audio
      this.callAttemptAudio = new Audio('/assets/lastmilyAssets/audio/chat_phone_calling.mp3');
      this.callAttemptAudio.play();

      const mediaParams = {
        audio: true,
        video: false
      };

      this.sendMessage(this.globals.chatCallStatusCodes['START_CALL']);
      this.session
        .getUserMedia(mediaParams)
        .then((localStream) => {
          this.session.call(extension, (error) => {
            this.callOpponentId = this.activeChannelInfo['opponentId'];
            this.isOpponentVideoMuted = true;
          });
        })
        .catch((error) => { console.log(error); });

      const extension = {};
    }
    // if in call then alert the user that the current call is going to end & if they confirm then end the call and perform the next call
    else {
      const confirmCall = confirm(this.callWarningAlertText);
      if (confirmCall) {
        this.endCall();
        this.createAudioSession();
      }
    }
  }

  acceptCall() {
    if (!this.isInAudioCall && !this.isInVideoCall) {
      const mediaParams = {
        audio: true,
        video: true
      };
      this.session
        .getUserMedia(mediaParams)
        .then((localStream) => {
          const extension = {};
          this.session.accept(extension);
          this.isBeingCalledVideo = false;
          this.isBeingCalledAudio = false;

          // 1: video, 2: audio
          if (this.session.callType == 1) {
            this.isInVideoCall = true;
          } else {
            this.isInAudioCall = true;
            this.isOpponentVideoMuted = true;
          }

          this.callOpponentId = this.session.initiatorID;
          this.sendMessage(this.globals.chatCallStatusCodes['ACCEPT_CALL']);

          // find dialog id via call initiator id & set as active
          this.allChannels.forEach(channel => {
            if (channel.userId == this.callOpponentId && channel.chatType == 3) {
              this.openChat(channel.dialogId);
            }
          });
        })
        .catch((error) => { console.log(error); });
    }
  }

  rejectCall() {
    const extension = {};
    this.session.reject(extension);
    this.isBeingCalledVideo = false;
    this.isBeingCalledAudio = false;
    this.sendMessage(this.globals.chatCallStatusCodes['REJECT_CALL']);
  }

  endCall() {
    const extension = {};
    this.session.stop(extension);
    this.callOpponentId = null;
    this.isInVideoCall = false;
    this.isInAudioCall = false;
    this.isMuteMicrophone = false;
    this.isMuteVideo = false;
    this.isOpponentVideoMuted = false;
    this.sendMessage(this.globals.chatCallStatusCodes['END_CALL']);
  }

  muteVideo() {
    if (this.isMuteVideo) {
      this.isMuteVideo = false;
      // this.session.localStream.getVideoTracks()[0].enabled = true;
      this.session.unmute("video");
      ConnectyCube.chat.sendSystemMessage(this.callOpponentId, { body: "VIDEO_UNMUTED", extension: {} });
    } else {
      this.isMuteVideo = true;
      // this.session.localStream.getVideoTracks()[0].enabled = false
      this.session.mute("video");
      ConnectyCube.chat.sendSystemMessage(this.callOpponentId, { body: "VIDEO_MUTED", extension: {} });
    }
  }

  muteMicrophone() {
    if (this.isMuteMicrophone) {
      this.isMuteMicrophone = false;
      this.session.localStream.getAudioTracks()[0].enabled = true;
    } else {
      this.isMuteMicrophone = true;
      this.session.localStream.getAudioTracks()[0].enabled = false;
    }
  }

  CCInit() {
    this.http.get('api/internal/v1/chat/chat-auth').subscribe(res => {
      const CREDENTIALS = {
        appId: res['items']['app_id'],
        // authKey: "test",
        // authSecret: "test",
        token: res['items']['token']
      };
      const CONFIG = {
        debug: { mode: 0 }, // enable DEBUG mode (mode 0 is logs off, mode 1 -> console.log())
        chat: {
          streamManagement: {
            enable: true
          }
        }
      };

      console.log(CREDENTIALS)
      ConnectyCube.init(CREDENTIALS, CONFIG);

      setTimeout(() => {
        this.CCLogin(res['items']['userName'], res['items']['userPassword']);
      }, 1000);

      // ConnectyCube.setSession()
      //   .then((session) => {
      //     // login after creation of session
      //     setTimeout(() => {
      //       this.CCLogin(res['items']['userName'], res['items']['userPassword']);
      //     }, 1000);
      //   })
      //   .catch((error) => { console.log(error); });
    });
  }

  loadAvatarsAsync() {
    this.fetchedUsersData.forEach(user => {
      this.avatarHashes.forEach(imageHash => {
        if (user.imageHash === imageHash) {
          this.http.get(`api/internal/v1/images/user-profile/${imageHash}`).subscribe(avatar => {
            this.ccUserNameToAvatarMap[`user_${user.id}`] = avatar['base64'];
            this.ccIdToAvatarMap[user.connecty_cube_id] = avatar['base64'];
          });
        }
      });
    });

    const hasLoadedAvatarsAndChannelsInterval = setInterval(() => {
      if (this.avatarHashes.length == Object.keys(this.ccUserNameToAvatarMap).length && this.allChannels.length && this.channels.length) {
        // mutate channels avatars with the fetched ones
        let shouldUpdateAvatars = false;
        this.allChannels.forEach((channel, index, arr) => {
          if (this.ccUserNameToAvatarMap[channel.userName] && !channel['avatarBase64']) {
            arr[index]['avatarBase64'] = this.ccUserNameToAvatarMap[channel.userName];
            shouldUpdateAvatars = true;
          }
        });
        this.channels.forEach((channel, index, arr) => {
          if (this.ccUserNameToAvatarMap[channel.userName] && !channel['avatarBase64']) {
            arr[index]['avatarBase64'] = this.ccUserNameToAvatarMap[channel.userName];
            shouldUpdateAvatars = true;
          }
        });

        if (shouldUpdateAvatars) {
          this.channels = [...this.channels];
          this.allChannels = [...this.allChannels];
        }

        clearInterval(hasLoadedAvatarsAndChannelsInterval);
      }
    }, 200);
  }

  CCLogin(username, password) {
    this.username = username;
    const pass = password;
    // this.username = 'user2';
    // const pass = '12345678';
    // this.username = 'user3';
    // const pass = '12345678';
    const userCredentials = { login: this.username, password: pass };
    ConnectyCube.login(userCredentials)
      .then((user) => {
        this.userId = user.id;
        this.username = user.login;

        // get user's users data
        this.http.get('api/internal/v1/chat/chat-user-profiles').subscribe(userProfiles => {
          this.fetchedUsersData = userProfiles['items'];
          this.fetchedUsersData.forEach(user => {
            // determine (user) channel category
            let channelCategoryValue = 'team'; // use team as default
            if (user.driver_id && user.roles.includes(this.globals.userProfileRolesConstants['LASTMILY_ANDROID_DRIVER'])) {
              channelCategoryValue = 'drivers';
            } else if (user.roles.includes(this.globals.userProfileRolesConstants['LASTMILY_WEB_OPERATOR'])) {
              channelCategoryValue = 'team';
            } else {
              if (user.company_sender_partner_type == this.globals.companyModes['SENDER']) {
                channelCategoryValue = 'consignors';
              } else if (user.company_sender_partner_type == this.globals.companyModes['NORMAL']) {
                channelCategoryValue = 'partners';
              }
            }

            this.usernameToFullName['user_' + user.id] = user.name;
            const searchParams = { login: 'user_' + user.id };

            if (user.imageHash) {
              this.avatarHashes.push(user.imageHash);
            }

            ConnectyCube.users
              .get(searchParams)
              .then((result) => {
                this.usersData.push(
                  {
                    "userId": result['user'].id,
                    "ccId": result['user'].connecty_cube_id,
                    "name": user.name,
                    "channel_category": channelCategoryValue,
                    "avatar": user.avatar
                  }
                );

                this.userIdToFullName[result['user'].id] = user['name'];
                this.userIdToAvatar[result['user'].id] = user['avatar'];

                // if this is the last user to be fetched, then proceed with the login & init logic, otherwise just get the user's data
                // if (this.usersData.length == this.fetchedUsersData.length) {
                if (this.usersData.length == this.fetchedUsersData.length) { // test
                  // const userCredentials = { userId: user.id, password: pass };
                  const userCredentials = { userId: this.userId, password: pass };
                  const self = this;

                  ConnectyCube.chat
                    .connect(userCredentials)
                    .then(() => {
                      // inital load of chats
                      self.CCGetChats();

                      // ConnectyCube Listeners //
                      ConnectyCube.chat.onSystemMessageListener = function (msg) {
                        switch (msg.body) {
                          case 'UPDATE_CHATS':
                            self.CCGetChats();
                            break;
                          case 'VIDEO_MUTED':
                            self.isOpponentVideoMuted = true;
                            break;
                          case 'VIDEO_UNMUTED':
                            self.isOpponentVideoMuted = false;
                            break;
                          // normal 'read' event won't work from Live Tracking
                          case 'MESSAGE_READ_CLIENT_PORTAL':
                            if (msg.userId == self.activeChannelInfo['opponentId']) {
                              self.getAndShowMessages(self.activeChannelInfo['dialogId']);
                            }
                            break;
                        }
                      };

                      ConnectyCube.chat.onMessageListener = function onMessage(userId, message) {
                        console.log('*** MESSAGE RECEIVED ***')

                        // play audio
                        const notificationAudio = new Audio('/assets/lastmilyAssets/audio/chat_message.mp3');
                        notificationAudio.play();

                        // refresh messages only if the message received belongs to the currently active chat
                        if (self.userId != userId && message.dialog_id == self.activeChannelInfo['dialogId']) {
                          if (self.messages.length) {
                            self.markMessagesAsRead();
                          }
                          self.CCGetChats();
                          self.getAndShowMessages(message.dialog_id);
                        } else {
                          // always refresh chats on any message received
                          self.CCGetChats();
                        }

                        // if the received message is from a user that we haven't ever chatted with, get chat user profiles, too
                        let hasAlreadyChattedWithUser = false;
                        for (let channel of self.allChannels) {
                          if (userId == channel.userId) {
                            hasAlreadyChattedWithUser = true;
                            break;
                          }
                        }
                        if (!hasAlreadyChattedWithUser) {
                          self.updateUsersData(true);
                        }
                      };

                      // the opponent must be logged in for the message to be marked as 'Delivered'
                      // since markable is 1, delivered status is set automatically by ConnectyCube's BE when they receive the message
                      ConnectyCube.chat.onDeliveredStatusListener = function (messageId, dialogId, userId) {
                        console.log('DELIVERED!');
                        if (dialogId == self.activeChannelInfo['dialogId']) {
                          self.getAndShowMessages(dialogId);
                        }
                      };

                      ConnectyCube.chat.onReadStatusListener = function (messageId, dialogId, userId) {
                        console.warn('OPPONENT HAS READ THE MESSAGES!');
                        if (dialogId == self.activeChannelInfo['dialogId']) {
                          self.getAndShowMessages(dialogId);
                        }

                        // update chats (for unread messages mostly)
                        setTimeout(() => {
                          self.CCGetChats();
                        }, 2500);
                      };

                      ConnectyCube.chat.onSentMessageCallback = function (messageLost, messageSent) { };

                      ConnectyCube.chat.onMessageTypingListener = function (isTyping, userId, dialogId) {
                        console.log('getting "is typing" update...')
                        console.log(isTyping)
                        if (isTyping && !self.opponentsTypingMessage.includes(userId)) {
                          self.opponentsTypingMessage.push(userId);
                        } else {
                          const index = self.opponentsTypingMessage.indexOf(userId);
                          if (index !== -1) {
                            self.opponentsTypingMessage.splice(index, 1);
                          }
                        }

                        self.isOpponentTypingMessage = isTyping;

                        setTimeout(() => {
                          if (document.getElementById('chat-channel-body')) {
                            document.getElementById('chat-channel-body').scrollTop = document.getElementById('chat-channel-body').scrollHeight;
                          }
                        }, 100);
                      };

                      ConnectyCube.videochat.onCallListener = (session, extension) => {
                        if (!this.isInAudioCall && !this.isInVideoCall) {
                          self.session = session;
                          self.usersData.forEach(user => {
                            if (user.userId == self.session.initiatorID) {
                              self.callerName = user.name;
                            }
                          });

                          // 1: video, 2: audio
                          if (self.session.callType == 1) {
                            self.isBeingCalledVideo = true;
                          } else {
                            self.isBeingCalledAudio = true;
                          }

                          // play audio
                          self.callAttemptAudio = new Audio('/assets/lastmilyAssets/audio/ringtone.mp3');
                          self.callAttemptAudio.play();

                          // Stop call if callee hasn't picked up in 15 seconds
                          setTimeout(() => {
                            if (self.isBeingCalledVideo || self.isBeingCalledAudio) {
                              self.rejectCall();
                            }
                          }, 15000);
                        }
                      };

                      ConnectyCube.videochat.onAcceptCallListener = function (session, userId, extension) {
                        // 1: video, 2: audio
                        console.log(session)
                        if (session.callType == 1) {
                          self.isInAudioCall = false;
                          self.isInVideoCall = true;
                        } else {
                          self.isInAudioCall = true;
                          self.isInVideoCall = false;
                        }

                        self.callOpponentId = userId;
                        self.callAttemptAudio.src = '';
                      };

                      ConnectyCube.videochat.onStopCallListener = (session, userId, extension) => {
                        self.callOpponentId = null;
                        self.isInVideoCall = false;
                        self.isInAudioCall = false;
                        self.isBeingCalledVideo = false;
                        self.isBeingCalledAudio = false;
                        self.isMuteMicrophone = false;
                        self.isMuteVideo = false;
                        self.isOpponentVideoMuted = false;
                      };

                      ConnectyCube.videochat.onUserNotAnswerListener = function (session, userId) {
                        self.isBeingCalledVideo = false;
                        self.isBeingCalledAudio = false;
                        self.callAttemptAudio.src = '';
                      };

                      ConnectyCube.videochat.onRejectCallListener = function (session, userId, extension) {
                        self.isBeingCalledVideo = false;
                        self.isBeingCalledAudio = false;
                        self.callAttemptAudio.src = '';
                      };

                      ConnectyCube.videochat.onRemoteStreamListener = function (session, userID, remoteStream) {
                        // attach the remote stream to DOM element
                        session.attachMediaStream('video-call', remoteStream);
                        self.callOpponentId = self.session.opponentIDs[0];
                      };
                    })
                    .catch((error) => { console.log(error); });


                  // (new conversation) dropdown users
                  // (NOT INCLUDING SELF)
                  // this.usersMockData.forEach(user => {
                  this.usersForGroup = [];
                  this.usersData.forEach(user => {
                    if (user.userId != this.userId) {
                      this.usersForGroup.push(user);
                    }
                  });
                  this.usersForGroup = [...this.usersForGroup];

                  // find the logged in user's name & avatar from our data
                  this.usersData.forEach(user => {
                    if (this.userId == user.userId) {
                      this.fullName = user.name;
                      this.avatarBase64 = user.avatar;
                    }
                  });
                }
              })
              .catch((error) => { });
          });
        });
      })
      .catch((error) => { console.log(error); });
  }

  CCGetChats() {
    const filters = {};
    ConnectyCube.chat.dialog
      .list(filters)
      .then((result) => {
        console.warn('got chats')
        this.hasLoadedChats = true;
        this.chats = result['items'];
        this.getAndShowChannels();
      })
      .catch((error) => { console.log(error); });
  }

  CCCreateChat(userId) {
    // 6056874 user2
    // 6057303 user3
    const params = {
      type: 3,
      occupants_ids: [userId],
    };

    ConnectyCube.chat.dialog
      .create(params)
      .then((dialog) => {
        this.CCGetChats();

        // send message to the user that the chat was established with, in order for them to update their chats due to the creation of this new chat
        // we use 'sendSystemMessage' to create our own events & use 'onSystemMessageListener''s response's 'body' property to decide what should be done
        const msg = {
          body: "UPDATE_CHATS",
          extension: {},
        };

        ConnectyCube.chat.sendSystemMessage(userId, msg);
      })
      .catch((error) => { });
  }

  CCCreateGroupChat(occupantsIds) {
    const params = {
      type: 2,
      name: this.myForm.value.groupChatName,
      occupants_ids: [...occupantsIds],
      description: "",
      photo: null,
    };

    ConnectyCube.chat.dialog
      .create(params)
      .then((dialog) => {
        this.CCGetChats();

        // send message to EACH user that the chat was established with, in order for them to update their chats due to the creation of this new chat
        // we use 'sendSystemMessage' to create our own events & use 'onSystemMessageListener''s response's 'body' property to decide what should be done
        const msg = {
          body: "UPDATE_CHATS",
          extension: {},
        };

        // send an update_chats message to every group participant
        occupantsIds.forEach(id => {
          ConnectyCube.chat.sendSystemMessage(id, msg);
        })
      })
      .catch((error) => { console.log(error); });
  }

  CCCreatePublicChat() {
    const params = {
      type: 4,
      name: this.myForm.value.groupChatName,
    };

    ConnectyCube.chat.dialog
      .create(params)
      .then((dialog) => {
        this.CCGetChats();

        const msg = {
          body: "UPDATE_CHATS",
          extension: {},
        };

        this.myForm.value.selectedUsers.forEach(userId => {
          // A REQUEST TO OUR BE MUST BE PERFORMED HERE SO THAT ALL SELECTED USERS SUBSCRIBE AUTOMATICALLY TO THE PUBLIC CHAT.
          // v v v THESE 2 THINGS MUST RUN FOR EACH USER v v v

          // ConnectyCube.chat.sendSystemMessage(userId, msg);

          // ConnectyCube.chat.dialog
          //   .subscribe(dialog._id)
          //   .then((dialog) => { })
          //   .catch((error) => { });
        });
      })
      .catch((error) => { });
  }

  CCSendMessage(opponentId, dialogId, messageText, chatType) {
    let chatTypeValue;
    if (chatType === 3) {
      chatTypeValue = 'chat';
    } else {
      chatTypeValue = 'groupchat';
    }

    const message = {
      type: chatTypeValue,
      body: messageText,
      extension: {
        save_to_history: 1,
        dialog_id: dialogId
      },
      markable: 1
    };

    // send message & update channels' list
    if (chatType === 3) {
      ConnectyCube.chat.send(opponentId, message);
    } else {
      ConnectyCube.chat.send(dialogId, message);
    }

    // update chats (for unread messages mostly)
    setTimeout(() => {
      this.CCGetChats();
    }, 2500);
  }

  openChat(dialogId) {
    if (dialogId != this.activeChannelInfo['dialogId']) {
      this.openModal();

      this.getAndShowActiveChannelInfo(dialogId);
      if (this.messages.length) {
        this.markMessagesAsRead();
      }
      this.CCGetChats();
      this.getAndShowMessages(dialogId);

      // message status images require a tiny delay to unload when moving through different chat types
      // this takes care of the visual bug that results from that (just a slight on/off of the chat body)
      if (document.getElementById('chat-channel-body') && document.getElementById('chat-channel-typing-area')) {
        document.getElementById('chat-channel-body').style.display = 'none';
        document.getElementById('chat-channel-typing-area').style.display = 'none';
        setTimeout(() => {
          document.getElementById('chat-channel-body').style.display = 'block';
          document.getElementById('chat-channel-typing-area').style.display = 'flex';
        }, 500);
      }
    }
  }

  isTypingUpdate() {
    if (this.activeChannelInfo['chatType'] == 3) {
      if ((<HTMLInputElement>document.getElementById('chat-message-input')).value == '') {
        console.log('sending -STOP TYPING- event!');
        console.log(this.activeChannelInfo['opponentId']);
        ConnectyCube.chat.sendIsStopTypingStatus(this.activeChannelInfo['opponentId']);
      } else {
        console.log('sending -IS TYPING- event!');
        console.log(this.activeChannelInfo['opponentId']);
        ConnectyCube.chat.sendIsTypingStatus(this.activeChannelInfo['opponentId']);
      }
    }
  }

  // performs various checks to the message box
  checkMessageBox(event) {
    // if message input contains more than 1 link, empty it
    const messageBox = (<HTMLInputElement>document.getElementById('chat-message-input'));
    let linksInString = (messageBox.value.match(/https?:\/\//g) || []).length;
    if (linksInString >= 2) {
      messageBox.value = '';
    }

    // on input change, update "Is typing" status
    this.isTypingUpdate();
  }

  markMessagesAsRead() {
    const params = {
      messageId: this.messages[this.messages.length - 1].messageId,
      userId: this.activeChannelInfo['opponentId'],
      dialogId: this.activeChannelInfo['dialogId'],
    };

    ConnectyCube.chat.sendReadStatus(params);

    // update chats (for unread messages mostly)
    setTimeout(() => {
      this.CCGetChats();
    }, 2500);
  }

  filterChannels(filterType) {
    this.searchString = ''; // reset search
    (<HTMLInputElement>document.getElementById('chat-user-search-input')).value = '';
    this.activeFilter = filterType;

    const filterChannelsResult = [];
    if (filterType != 'all') {
      this.allChannels.forEach(channel => {
        if (channel.channelCategory == filterType) {
          filterChannelsResult.push(channel);
        }
      });

      this.channels = [...filterChannelsResult];
    } else {
      this.channels = [...this.allChannels];
    }
  }

  CCLogout() {
    console.log('session destroyed')
    ConnectyCube.logout().catch((error) => { });
  }

  ngOnInit() {
    // init chat
    this.CCInit();

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();

      // refresh messages when language changes in order to re-translate call feedback codes
      if (this.activeChannelInfo['dialogId']) {
        this.getAndShowMessages(this.activeChannelInfo['dialogId']);
      }
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(700),
      distinctUntilChanged()).subscribe((text: string) => {
        // filter by users' full names
        if (text && text !== '') {
          const searchChannelsResult = [];
          const searchTextRegex = new RegExp(text, 'i');
          this.allChannels.forEach(channel => {
            if (searchTextRegex.test(channel.name) && (this.activeFilter == channel.channelCategory || this.activeFilter == 'all')) {
              console.log('found something')
              searchChannelsResult.push(channel);
            }
          });

          this.channels = [...searchChannelsResult];
        }
        else {
          this.channels = [...this.allChannels];
        }

      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}

interface Channel {
  id: Number;
  chatType: Number;
  userId: String;
  userName: String;
  dialogId: String;
  description: String;
  name: String;
  avatarBase64?: String;
  initials: String;
  lastMessagePreview: String;
  time: String;
  lastUpdatedDatetime: String;
  channelCategory: String;
  unreadMessagesCount: Number;
  lastMessageUserId: Number;
  lastMessageStatus: String
}

interface ActiveChannelInfo {
  id: Number;
  chatType: Number;
  orderIndex: Number;
  opponentId: String;
  dialogId: String;
  name: String;
  userName: String;
  avatarBase64?: String;
  initials: String;
  description: String;
  unreadMessagesCount: Number;
  lastMessageUserId: Number;
  lastMessageStatus: String
}

interface Message {
  messageId: String;
  senderName: String;
  avatarBase64?: String;
  initials: String;
  messageText: String;
  isSystemMessage: Boolean;
  time: String;
  messageStatus: String;
  sentByMe: Boolean;
}