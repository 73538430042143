import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { MilyService } from '@app/services/mily.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';
import { take } from 'rxjs/operators';
import { AddStopModalDummyComponent } from './add-stop-modal-dummy/add-stop-modal-dummy.component';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-add-stop-modal',
  templateUrl: './add-stop-modal.component.html',
  styleUrls: ['./add-stop-modal.component.scss']
})
export class AddStopModalComponent implements OnInit, OnDestroy {
  @ViewChild(AddStopModalDummyComponent, { static: false }) addStopGridDummyComponent: AddStopModalDummyComponent;

  listen = [];

  projectProblemId;
  projectProblemDepartureDatetime;
  isClickedOnce = false;
  title = '';
  buttonName = ''
  mode = 'recurring';
  maxPointsLimitReachedAlert = '';
  isModalOpen = false;

  convertToNextDayDelivery = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    private milyService: MilyService,
    private projectProblemDataService: ProjectProblemDataService,
    public router: Router,
    private genericService: GenericService,
    private modalService: ModalService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.listen.push(this.viewProjectProblemService.openAddStopsModalListen().subscribe((mode) => {
      this.mode = mode;
      this.setAddStopsModalMode();
    }));
    this.listen.push(this.viewProjectProblemService.updateCancelledGridListen().subscribe((data) => {
      this.addStopGridDummyComponent.addStopGridComponent.updateGrid();
    }));

    this.listen.push(this.modalService.closeAddStopModalListen().subscribe(() => {
      this.closeAddStopsModal();
      this.isClickedOnce = false;
    }));
    this.listen.push(this.modalService.openAddStopModalListen().subscribe(() => {
      this.openAddStopsModal();
      this.isClickedOnce = false;
    }));
  }

  nextDayDeliverySwitchChanged() {
  }

  setProjectProblem(id, datetime, initialize) {
    this.projectProblemId = id;
    this.projectProblemDepartureDatetime = datetime;

    // if (this.addStopGridComponent) {
    //   this.addStopGridComponent.projectProblemId = id;
    // }

    if (initialize) {
      this.mode = 'recurring';
      this.checkForDataToImport();
    }
  }

  checkForDataToImport() {
    this.checkForCancelledStops();
    this.checkForRecurringStops();
  }

  checkForCancelledStops() {
    let url = 'api/v1/company/warehouse';
    url += '?stopPointId=null';
    url += '&limit=1';
    url += '&projectProblemId=' + this.projectProblemId;

    this.http.get(url).pipe(take(1)).subscribe(response => {
      if (response['items']['stopPoints']) {
        if (response['items']['stopPoints'].length) {
          this.milyService.loadCancelledPoints();
        }
      }
    });
  }

  checkForRecurringStops() {
    if (!this.globals.isInRoute('collaboratorOverview')) {
      let url = 'api/v1/project/problems/' + this.projectProblemId + '/reappear-stop-points';
      url += '?pageSize=1';
      url += '&page=0';
      this.http.get(url).pipe(take(1)).subscribe(response => {
        if (response['items']) {
          if (response['items'].length) {
            this.milyService.loadRecurringPoints();
          }
        }
      });
    }
  }

  getPartnerId() {
    let partnerId;
    if (this.addStopGridDummyComponent.addStopGridComponent.selectedPartnerItem) {
      partnerId = this.addStopGridDummyComponent.addStopGridComponent.selectedPartnerItem.id
    } else {
      partnerId = this.globals.partnersArray[0]['id'];
    }
    return partnerId;
  }

  submitStops() {
    this.isClickedOnce = true;
    const myObserver = {
      next: (response) => {
        this.isClickedOnce = false;
        this.viewProjectProblemService.resetMilyOpenedForCanceledErrorStops();
        this.projectProblemDataService.updateStopPoints(true);
        this.genericService.newShipmentsGenerateGridData();
        if (this.addStopGridDummyComponent.addStopGridComponent.isLastPage && this.addStopGridDummyComponent.addStopGridComponent.selectAllCheckbox) {
          this.closeAddStopsModal();
        } else {
          // scroll to top so the grid does not ask for the next batch 
          const gridContainer = document.querySelector('#add-stop-points-grid .ag-body-viewport');
          gridContainer.scrollTo(0, 0);
          this.addStopGridDummyComponent.addStopGridComponent.updateGrid();
        }
      },
      error: (error) => {
        this.isClickedOnce = false;
        console.error(error);
        this.milyService.alert(error);
        if (error.error) {
          if (error.error.errors) {
            if (error.error.errors.maxPointsLimitReached === true) {
              this.milyService.alert(this.maxPointsLimitReachedAlert);
            }
          }
        }
      },
      complete: () => { },
    };
    const selectedRows = this.addStopGridDummyComponent.addStopGridComponent.getSelectedRows();
    if (this.mode === 'recurring' || this.mode === 'recipients') {
      if (selectedRows.length) {
        const data = [];
        let url = '/api/v1/project/problems/' + this.projectProblemId + '/stop-points';
        if (this.globals.isInRoute('newShipmentsView') || (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && this.globals.isInRoute('collaboratorOverview'))) {
          url = 'api/v1/partner-new-stop-points';
        }
        selectedRows.forEach(customer => {
          const stopPointData = customer.fullData;
          stopPointData['relatedCustomerId'] = stopPointData['id'];
          stopPointData['id'] = null;
          stopPointData['service_type'] = stopPointData['stop_point_default_service_type'];
          stopPointData['entity_status'] = this.globals.stopPointEntityStatusConstants['ACTIVE'];
          stopPointData['duration'] = stopPointData['stop_point_default_duration'];
          const weekday = stopPointData['weekday'] ?? this.projectProblemDataService.projectProblemDayOfWeek;
          stopPointData['time_windows'] = stopPointData['time_windows'][weekday];
          const projectProblemTimeStart = moment(this.projectProblemDepartureDatetime).format('HH:mm');
          const projectProblemTimeStartMoment = moment(projectProblemTimeStart, 'HH:mm');
          stopPointData['time_windows'].forEach((timeWindow, i) => {
            const timeWindowStartTime = moment(timeWindow['time_window_start_datetime']).format('HH:mm');
            const timeWindowStartMoment = moment(timeWindowStartTime, 'HH:mm');
            const timeWindowStartOffsetFromDepartureDuration = moment.duration(timeWindowStartMoment.diff(projectProblemTimeStartMoment));
            const timeWindowStartOffsetFromDepartureMinutes = timeWindowStartOffsetFromDepartureDuration.asMinutes();
            stopPointData['time_windows'][i]['time_window_start_offset_from_departure'] = moment.duration(timeWindowStartOffsetFromDepartureMinutes, 'minutes').toISOString();
            stopPointData['time_windows'][i]['start'] = timeWindowStartMoment.format('HH:mm:SS');
          });
          stopPointData['final'] = false;
          stopPointData['in_warehouse'] = false;
          stopPointData['customerEntityType'] = null;
          const customerData = {
            email: stopPointData['email'],
            timeZone: stopPointData['address_timezone'],
            portal_access: stopPointData['portal_access'],
            stop_point_default_service_type: stopPointData['stop_point_default_service_type']
          };
          stopPointData['address'] = stopPointData['address'];
          stopPointData['notes'] = stopPointData['note'];
          if (
            stopPointData['service_type'] === this.globals.stopPointServiceTypeConstants['DELIVERY'] ||
            stopPointData['service_type'] === this.globals.stopPointServiceTypeConstants['BOTH']
          ) {
            stopPointData['deliverySources'] = {
              modelName: this.globals.companyDepotModelName,
              modelId: this.globals.depotId,
              load: stopPointData['stop_point_default_delivery_load']
            };
          }
          if (
            stopPointData['service_type'] === this.globals.stopPointServiceTypeConstants['PICKUP'] ||
            stopPointData['service_type'] === this.globals.stopPointServiceTypeConstants['BOTH']
          ) {
            stopPointData['pickupDestinations'] = {
              modelName: this.globals.companyDepotModelName,
              modelId: this.globals.depotId,
              load: stopPointData['stop_point_default_pickup_load']
            };
          }
          // BE requires acceptance_status and company_services to create a voucher for a SP
          const emptyVoucher = {
            options: {
              acceptance_status: this.globals.voucherAcceptanceStatus['ACCEPTED'],
              company_services: []
            }
          }
          if (this.globals.isInRoute('newShipmentsView') || this.globals.isInRoute('collaboratorOverview')) {
            stopPointData['voucher'] = emptyVoucher;
            stopPointData['voucher']['options']['acceptance_status'] = this.globals.voucherAcceptanceStatus['CREATED'];
            // BE requires empty mass_and_dimensions array to create a voucher
            stopPointData['mass_and_dimensions'] = [];
            stopPointData['collaborator'] = {
              collaboratorData: {}
            };
            stopPointData['company_partner'] = { id: this.getPartnerId() };
          } else if (this.globals.isInRoute('/projectView/')) {
            const selectedCollaborator = this.addStopGridDummyComponent.addStopGridComponent.selectedCollaborator;
            if (selectedCollaborator) {
              const selectedCollaboratorData = selectedCollaborator.companyCollaborator.collaborator.collaboratorData;
              stopPointData['voucher'] = emptyVoucher;
              // BE requires empty mass_and_dimensions array to create a voucher
              stopPointData['mass_and_dimensions'] = [];
              stopPointData['collaborator'] = {
                collaboratorData: { id: selectedCollaboratorData.id }
              };
            }
          }
          const object = {
            stopPoint: stopPointData,
            customer: customerData
          };
          data.push(object);
        });
        const stopPoints = {
          stopPoints: data
        };
        if (this.router.url.includes('/newShipmentsView') || this.globals.isInRoute('collaboratorOverview')) {
          stopPoints['company_partner'] = { id: this.getPartnerId() };
        }
        this.http.post(url, JSON.stringify(stopPoints)).pipe(take(1)).subscribe(myObserver);
      } else {
        this.isClickedOnce = false;
        this.closeAddStopsModal();
      }
    } else {
      if (selectedRows.length) {
        const stopPointIds = [];
        let url = 'api/v1/project/problems/' + this.projectProblemId + '/warehouse';
        selectedRows.forEach(row => {
          stopPointIds.push(row.id);
        });
        const object = {
          canceledStopPoints: { stopPointsIds: stopPointIds }
        };
        if (this.mode === 'newShipments') {
          object['acceptance_status'] = this.globals.voucherAcceptanceStatus['ACCEPTED'];
          object['convert_to_next_day'] = this.convertToNextDayDelivery;
        } else if (this.mode === 'newStopPointsFromDrivers') {

        }
        if (this.router.url.includes('partnerView/newShipmentsView')) {
          url = 'api/v1/partner/partner-warehouse';
          object['company_partner'] = { id: this.getPartnerId() };
        }
        this.http.post(url, JSON.stringify(object)).pipe(take(1)).subscribe(myObserver);
      } else {
        this.isClickedOnce = false;
        this.closeAddStopsModal();
      }
    }
  }
  
  setAddStopsModalMode() {
    if (this.mode === 'recurring') {
      this.listen.push(this.translate.get('STOP_POINT.ADD_RECURRING_POINTS').subscribe((res: string) => { this.title = res; this.buttonName = res; }));
    } else if (this.mode === 'recipients') {
      this.listen.push(this.translate.get('STOP_POINT.ADD_SAVED_POINTS').subscribe((res: string) => { this.title = res; this.buttonName = res; }));
    } else if (this.mode === 'cancelled') {
      this.listen.push(this.translate.get('STOP_POINT.ADD_CANCELLED_POINTS').subscribe((res: string) => { this.title = res; this.buttonName = res; }));
    } else if (this.mode === 'newShipments') {
      this.listen.push(this.translate.get('STOP_POINT.ADD_NEW_POINTS').subscribe((res: string) => { this.title = res; }));
      this.listen.push(this.translate.get('STOP_POINT.ADD_NEW_POINTS_BTN').subscribe((res: string) => { this.buttonName = res; }));
    } else {
      this.listen.push(this.translate.get('STOP_POINT.ADD_NEW_POINTS').subscribe((res: string) => { this.title = res; }));
      this.listen.push(this.translate.get('STOP_POINT.ADD_NEW_POINTS_BTN').subscribe((res: string) => { this.buttonName = res; }));
    }
    this.isClickedOnce = true;

    this.openAddStopsModal();
    setTimeout(() => {
      this.addStopGridDummyComponent.addStopGridComponent.projectProblemId = this.projectProblemId;
      this.addStopGridDummyComponent.addStopGridComponent.getStopsGridData(this.mode);
    }, 50);
  }

  emptyAddStopsModal() {
    this.title = '';
    this.mode = 'recurring';
    this.isClickedOnce = false;
    this.addStopGridDummyComponent.addStopGridComponent.stopPointsDataArray = [];
    this.addStopGridDummyComponent.addStopGridComponent.resetGrid();
  }

  openAddStopsModal() {
    this.genericService.comm100ZIndexFix();
    this.isModalOpen = true;
    setTimeout(() => {
      const modal = document.querySelector('.add-stops-modal');
      const modalBackground = document.querySelector('.add-stops-modal-background');
      modal.classList.remove('closed');
      modal.classList.add('open');
      modalBackground.classList.remove('hidden');
    }, 50);

  }

  closeButtonPressed() {
    if (!this.isClickedOnce) {
      this.closeAddStopsModal();
    }
  }

  closeAddStopsModal() {
    const modal = document.querySelector('.add-stops-modal');
    const modalBackground = document.querySelector('.add-stops-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyAddStopsModal();

    setTimeout(() => {
      this.isModalOpen = false;
    }, 1000);
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT.ADD_RECURRING_POINTS').subscribe((res: string) => { this.title = res; }));
    this.listen.push(this.translate.get('IMPORTER.MAX_POINTS_ALERT').subscribe((res: string) => { this.maxPointsLimitReachedAlert = res; }));
  }

  ngOnInit() {
    this.listen.push(this.translate.get('STOP_POINT.ADD_RECURRING_POINTS').subscribe((res: string) => { this.title = res; }));
    this.listen.push(this.translate.get('IMPORTER.MAX_POINTS_ALERT').subscribe((res: string) => { this.maxPointsLimitReachedAlert = res; }));
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
