import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { StopModalComponent } from '@app/modals/stop-modal/stop-modal.component';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import polyline from '@mapbox/polyline';
import { take } from 'rxjs/operators';
import { StopPointUtils } from '@app/utils/stop-point-utils';

declare var H: any;

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss']
})
export class OverviewMapComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(StopModalComponent, { static: false }) stopModalComponent: StopModalComponent;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  listen = [];

  private platform: any;
  @ViewChild('map', { static: false })
  public mapElement: ElementRef;
  vehicleTitle;
  plateNumberTitle;
  driverTitle;
  latTitle;
  lonTitle;
  lastUpdateTitle;
  map;
  ui;
  behavior;
  clickOnMap;
  displayInfoBubble;
  hideInfoBubble;
  infoBubble;
  displayOldRouteInfoBubble;
  oldRouteInfoBubble;
  showOldRoute = false;
  oldRouteSwitchVisible = false;

  departureDateTimes = {};
  volumesArrays = {};
  defaultTimeWindow = [];
  maximumWeights = {};
  projectProblems;
  selectedProjectProblem;
  projectProblemsArray = [];
  projectProblemData = {};
  trackVehiclesInterval;
  trackVehiclesOldRouteInterval = [];
  lastId = 0;
  lastIdOldRoute = 0;
  vehicles = {};
  vehicleData = {};
  vehicleIds = [];
  vehicleSetting = {};
  vehicleIdPlateNumber = {};
  trackRequestDone = true;
  trackOldRouteRequestDone = {};
  wasHereMsg;
  stopPointsDepotsRoutesGroup = new H.map.Group();
  stopPointsGroup = new H.map.Group();
  routesGroup = new H.map.Group();
  oldRouteGroup = new H.map.Group();
  vehiclesGroup = new H.map.Group();
  editingMarkerGroup = new H.map.Group();
  stopPointMapObjects = {};
  routeMapObjects = {};
  vehiclesMapObjects = {};
  vehiclesMapObjectBackground = {};

  // white bar vars
  dateLabel;
  date;
  progress;
  minProjectProblemDepartureDatetimeMoment;
  totalStopPointsCount = 0;
  completedStopPointsCount = 0;
  totalDuration;
  currentDuration;
  totalDistance = 0;
  totalDistanceKm = 0;
  currentDistance = 0;
  currentDistanceKm = 0;

  colours = [
    // '#45f0f4',
    '#4eb4f9',
    // '#aef72f',
    '#bcba5e',
    '#a56429',
    // '#fcdf35',
    '#7c40ff',
    '#ef52d1',
    '#ba8aff',
    '#ef4b62', // red/orange route
    '#ffa039',
  ];
  oldRouteColours = [
    // '#45f0f470',
    '#4eb4f970',
    // '#aef72f70',
    '#bcba5e70',
    '#a5642970',
    // '#fcdf3570',
    '#7c40ff70',
    '#ef52d170',
    '#ba8aff70',
    '#ef4b6270', // red/orange route
    '#ffa03970',
  ];

  constructor(
    private http: HttpClient,
    private draggableMarkerService: DraggableMarkerService,
    private viewProjectProblemService: ViewProjectProblemService,
    public globals: Globals,
    public translate: TranslateService,
    public stopPointUtils: StopPointUtils,
  ) {
    this.platform = this.globals.platform;
  }

  displayDepots() {
    const icon = new H.map.Icon(this.svgIconsComponent.svgDepotMarker.replace('markerColour', '#00aeba'));
    const iconBackground = new H.map.Icon(this.svgIconsComponent.svgMakerBackground);
    let coords, lat, lon, marker, markerBackground;
    this.http.get('api/v1/company/depots').pipe(take(1)).subscribe(response => {
      const depots = response['items'];
      depots.forEach(depot => {
        lat = depot['companyDepot']['address']['lat'];
        lon = depot['companyDepot']['address']['lon'];
        coords = { lat: lat, lng: lon };
        marker = new H.map.Marker(coords, { icon: icon });
        markerBackground = new H.map.Marker(coords, { icon: iconBackground });
        this.stopPointsDepotsRoutesGroup.addObject(markerBackground);
        this.stopPointsDepotsRoutesGroup.addObject(marker);
      });
    });
  }

  createMarkerIcon(data, selected = false) {
    let icon;
    const sequence = (data.sequence && !data.overweight && data.timeWindowState !== 'limited' && data.timeWindowState !== 'late') ? data.sequence.toString() : '';
    const red = '#FF0000';
    const colour = data.timeWindowState === 'late' ? red : data.colour;
    const markerColour = data.enabled ? colour : colour + '70';
    const redColour = data.enabled ? red : red + '70';
    icon = this.svgIconsComponent.markerSvg;
    icon = icon.replace('markerColour', markerColour);
    if (selected) {
      icon = icon.replace('checkboxSvg', this.svgIconsComponent.checkbox);
      icon = icon.replace('editColour', data.editColour ? data.editColour : markerColour);
      icon = icon.replace('checkColour', '#FFFFFF');
    } else if (data.editColour !== null) {
      data.editColour = data.editColour ? data.editColour : markerColour;
      icon = icon.replace('checkboxSvg', this.svgIconsComponent.checkbox);
      icon = icon.replace('editColour', data.editColour);
      icon = icon.replace('checkColour', data.editColour);
      icon = icon.replace('markerNumber', sequence);
    } else {
      icon = icon.replace('markerNumber', sequence);
    }
    if (data.priority) {
      icon = icon.replace('asteriskSvg', this.svgIconsComponent.asterisk);
      icon = icon.replace('asteriskColour', markerColour);
    }
    if (data.timeWindowState) {
      switch (data.timeWindowState) {
        case 'regular':
          icon = icon.replace('smallClockSvg', this.svgIconsComponent.smallClock);
          icon = icon.replace('clockColour', markerColour);
          break;
        case 'strict':
          icon = icon.replace('smallClockSvg', this.svgIconsComponent.smallClock);
          icon = icon.replace('clockColour', redColour);
          break;
        case 'limited':
          icon = icon.replace('largeClockSvg', this.svgIconsComponent.largeClock);
          icon = icon.replace('clockColour', markerColour);
          break;
        case 'late':
          icon = icon.replace('largeClockSvg', this.svgIconsComponent.largeClock);
          icon = icon.replace('clockColour', redColour);
          break;
        default:
          break;
      }
    }
    if (data.overweight && data.timeWindowState !== 'late' && data.timeWindowState !== 'limited') {
      icon = icon.replace('weightSvg', this.svgIconsComponent.weight);
    }
    icon = icon.replace('weightSvg', '');
    icon = icon.replace('checkboxSvg', '');
    icon = icon.replace('smallClockSvg', '');
    icon = icon.replace('asteriskSvg', '');
    return icon;
  }

  stopPointChanged(newData) {
    const id = newData.id;
    const oldData = this.stopPointMapObjects[id].getData();
    if (newData === oldData) { return false; } else { return true; }
  }

  addStopPoint(data) {
    const id = data.id;
    if (!this.stopPointMapObjects[id]) {
      let coords;
      coords = { lat: data.lat, lng: data.lon };
      const icon = new H.map.DomIcon(this.createMarkerIcon(data));
      this.stopPointMapObjects[id] = new H.map.DomMarker(coords, { icon: icon });
      this.stopPointMapObjects[id].setData(data);
      this.stopPointsGroup.addObject(this.stopPointMapObjects[id]);
    } else if (this.stopPointChanged(data)) {
      this.updateStopPoint(data);
    }
  }

  updateStopPoint(data) {
    if (this.stopPointMapObjects[data.id]) {
      const icon = new H.map.DomIcon(this.createMarkerIcon(data));
      this.stopPointMapObjects[data.id].setIcon(icon);
      this.stopPointMapObjects[data.id].setData(data);
    }
  }

  setStopPointIcon(id, colour, sequence) {
    if (this.stopPointMapObjects[id]) {
      const markerData = this.stopPointMapObjects[id].getData();
      markerData.sequence = sequence;
      markerData.markerColour = colour;
      this.updateStopPoint(markerData);
    }
  }

  addEncodedElements(encodedPolyline, colour, index) {
    const decodedPolyline = polyline.decode(encodedPolyline);
    const linestring = new H.geo.LineString();
    let linePoint;
    decodedPolyline.forEach(function (point) {
      linePoint = {
        lat: point[0],
        lng: point[1]
      };
      linestring.pushPoint(linePoint);
    });
    this.routeMapObjects[index] = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 7,
        strokeColor: colour,
      }
    });
    this.routesGroup.addObject(this.routeMapObjects[index]);
  }

  trackVehicle(vehicleId, colour, coords, dateTime, plateNumber, driverName) {
    const iconBackground = new H.map.Icon(this.svgIconsComponent.svgMakerBackground);
    const icon = new H.map.Icon(this.svgIconsComponent.svgVehicleMaker.replace('markerColour', colour));
    const vehicleCoords = { lat: coords.lat, lng: coords.lon };
    let bubbleText = '<div>' + this.vehicleTitle + ': ' + vehicleId + '</div>';
    bubbleText += '<div>' + this.plateNumberTitle + ': ' + plateNumber + '</div>';
    bubbleText += '<div>' + this.driverTitle + ': ' + driverName + '</div>';
    bubbleText += '<div>' + this.latTitle + ': ' + coords.lat + '</div>';
    bubbleText += '<div>' + this.lonTitle + ': ' + coords.lon + '</div>';
    bubbleText += '<div>' + this.lastUpdateTitle + ': ' + dateTime + '</div>';
    const dataObject = {
      bubbleText: bubbleText,
      vehicleId: vehicleId,
      plateNumber: plateNumber,
      driverName: driverName,
      coords: coords,
      dateTime: dateTime
    };
    if (this.vehiclesMapObjects[vehicleId]) {
      this.vehiclesMapObjects[vehicleId].setData(dataObject);
      this.vehiclesMapObjects[vehicleId].setGeometry(vehicleCoords);
      this.vehiclesMapObjectBackground[vehicleId].setGeometry(vehicleCoords);
    } else {
      this.vehiclesMapObjectBackground[vehicleId] = new H.map.Marker(vehicleCoords, { icon: iconBackground });
      this.vehiclesMapObjects[vehicleId] = new H.map.Marker(vehicleCoords, { icon: icon });
      this.vehiclesMapObjects[vehicleId].setData(dataObject);
      this.vehiclesMapObjects[vehicleId].addEventListener('tap', this.displayInfoBubble);
      this.vehiclesGroup.addObject(this.vehiclesMapObjectBackground[vehicleId]);
      this.vehiclesGroup.addObject(this.vehiclesMapObjects[vehicleId]);
    }
  }

  updateVehicleBubbles() {
    const polylines = this.vehiclesGroup.getObjects();
    polylines.forEach(polyline => {
      const data = polyline.getData();
      if (data) {
        let bubbleText = '<div>' + this.vehicleTitle + ': ' + data.vehicleId + '</div>';
        bubbleText += '<div>' + this.plateNumberTitle + ': ' + data.plateNumber + '</div>';
        bubbleText += '<div>' + this.driverTitle + ': ' + data.driverName + '</div>';
        bubbleText += '<div>' + this.latTitle + ': ' + data.coords.lat + '</div>';
        bubbleText += '<div>' + this.lonTitle + ': ' + data.coords.lon + '</div>';
        bubbleText += '<div>' + this.lastUpdateTitle + ': ' + data.dateTime + '</div>';
        data.bubbleText = bubbleText;
        polyline.setData(data);
      }
    });
  }

  emptyMap() {
    this.stopPointMapObjects = {};
    this.routeMapObjects = {};
    this.vehiclesMapObjects = {};
    this.vehiclesMapObjectBackground = {};
    this.stopPointsGroup.removeAll();
    this.routesGroup.removeAll();
    this.vehiclesGroup.removeAll();
  }

  filterDataByProjectProblem() {
    this.emptyMap();
    if (this.selectedProjectProblem) {
      this.loadProjectProblemData(this.selectedProjectProblem.projectProblem);
    } else {
      this.projectProblemsArray.forEach(projectProblem => {
        this.loadProjectProblemData(projectProblem.projectProblem);
      });
    }
  }

  loadOverviewData() {
    const self = this;
    const dateParams = '?projectProblemDepartureDate=' + moment().format('YYYY-MM-DD');
    // const dateParams = '?projectProblemDepartureDate=2019-11-28';

    self.http.get('api/v1/vehicles').pipe(take(1)).subscribe(vehicles => {
      vehicles['items'].forEach(vehicle => {
        self.vehicles[vehicle.vehicle.id] = vehicle;
      });
      // get project problems
      this.http.get('api/v1/project/problems' + dateParams).pipe(take(1)).subscribe(projectProblems => {
        self.projectProblems = projectProblems['items'];
        self.projectProblems.forEach(projectProblem => {
          if (
            projectProblem.projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
            projectProblem.projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['RE_DISPATCH']
          ) {
            // add pp to the dropdown
            self.projectProblemsArray.push(projectProblem);
            self.projectProblemData[projectProblem.projectProblem.id] = projectProblem.projectProblem;
            self.loadProjectProblemData(projectProblem.projectProblem);
          }
        });
      });
    });
  }

  public getVehicleLocationsData(params: any): Observable<any> {
    this.trackRequestDone = false;
    return this.http.get('api/v1/vehicle-locations' + params);
  }

  public getVehicleOldRouteLocationsData(params: any, id): Observable<any> {
    this.trackOldRouteRequestDone[id] = false;
    return this.http.get('api/v1/vehicle-old-routes' + params);
  }

  addPreviousRoutes(encodedPolyline, colour, time) {
    const self = this;
    const arrivalTime = moment(time).format('HH:mm');
    const bubbleText = '<div>' + this.wasHereMsg + ': ' + arrivalTime + '</div>';
    const decodedPolyline = polyline.decode(encodedPolyline);
    const linestring = new H.geo.LineString();
    let linePoint;
    decodedPolyline.forEach(function (point, i) {
      linePoint = {
        lat: point[0],
        lng: point[1]
      };
      linestring.pushPoint(linePoint);
    });
    const routeMapObject = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 6,
        strokeColor: colour,
      },
    });
    const invisiblePolyline = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 15,
        strokeColor: 'rgba(255, 255, 255, 0)',
      },
      data: { bubbleText: bubbleText, arrivalTime: arrivalTime }
    });
    invisiblePolyline.addEventListener('tap', this.displayOldRouteInfoBubble);
    this.oldRouteGroup.addObjects([routeMapObject, invisiblePolyline]);
  }

  updatePreviousRoutes() {
    const polylines = this.oldRouteGroup.getObjects();
    polylines.forEach(polyline => {
      const data = polyline.getData();
      if (data) {
        data.bubbleText = '<div>' + this.wasHereMsg + ': ' + data.arrivalTime + '</div>';
        polyline.setData(data);
      }
    });
  }

  removePreviousRoutes() {
    this.oldRouteGroup.removeAll();
  }

  switchChanged() {
    if (this.showOldRoute) {
      this.enableOldRoute();
    } else {
      this.trackVehiclesOldRouteInterval.forEach(trackVehiclesOldRouteInterval => {
        clearInterval(trackVehiclesOldRouteInterval);
      });
      this.lastIdOldRoute = 0;
      this.trackVehiclesOldRouteInterval = [];
      this.removePreviousRoutes();
    }
  }

  enableOldRoute() {
    const self = this;
    self.projectProblems.forEach(projectProblemData => {
      const projectProblem = projectProblemData.projectProblem;
      if (
        projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
        projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['RE_DISPATCH'] ||
        projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['COMPLETED']
      ) {
        this.vehicleIds.forEach(id => {
          self.vehiclesOldRouteTracker(id, projectProblem);
          this.trackVehiclesOldRouteInterval.push(setInterval(function () {
            self.vehiclesOldRouteTracker(id, projectProblem);
          }, 1000));
        });
      }
    });
  }

  vehiclesOldRouteTracker(id, projectProblem) {
    const self = this;
    if (self.trackOldRouteRequestDone[id]) {
      const oldRouteParams = `?lastLocationId=${self.lastIdOldRoute}&projectProblemId=${projectProblem.id}&vehicleId=${id}`;
      self.getVehicleOldRouteLocationsData(oldRouteParams, id).pipe(take(1)).subscribe(
        trackOldRouteResponse => {
          self.trackOldRouteRequestDone[id] = true;
          const vehicleLocationData = trackOldRouteResponse['item'];
          self.lastIdOldRoute = vehicleLocationData.oldRoute.lastLocationId;
          let oldRouteColour;
          vehicleLocationData.oldRoute.lines.forEach(function (line, index) {
            oldRouteColour = self.oldRouteColours[self.vehicleData[id]['routeIndex']];
            self.addPreviousRoutes(line.line, oldRouteColour, line.time);
          });
        },
        error => {
          self.trackRequestDone = true;
        }
      );
    }
  }

  calculateTimeWindowState(timeWindows, arrivalTime = null, projectProblemId) {
    const self = this;
    const timeWindowDurationMinutes = [];
    let timeWindowRange, timeWindowRangeMinutes;
    let timeWindowStartOffsetFromDeparture, timeWindowStartOffsetFromDepartureMinutes;
    const timeWindowStartArray = [], timeWindowEndArray = [];

    timeWindows.forEach(function (timeWindow, index) {
      timeWindowRange = timeWindow.time_window_range;
      timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
      timeWindowDurationMinutes.push(timeWindowRangeMinutes);
      timeWindowStartOffsetFromDeparture = timeWindow.time_window_start_offset_from_departure;
      timeWindowStartOffsetFromDepartureMinutes = moment.duration(timeWindowStartOffsetFromDeparture).asMinutes();
      timeWindowStartArray.push(moment(self.departureDateTimes[projectProblemId]).add(timeWindowStartOffsetFromDepartureMinutes, 'minutes').format());
      timeWindowEndArray.push(moment(timeWindowStartArray[index]).add(timeWindowRangeMinutes, 'minutes').format());
    });

    const minimumDurationMinutes = Math.min(...timeWindowDurationMinutes);
    const defaultTimeWindowOffsetMinutes = moment.duration(this.defaultTimeWindow[0].time_window_start_offset_from_departure).asMinutes();
    const timeWindowOffsetMinutes = moment.duration(timeWindows[0].time_window_start_offset_from_departure).asMinutes();
    const defaultTimeWindowRangeMinutes = moment.duration(this.defaultTimeWindow[0].time_window_range).asMinutes();
    const firstTimeWindowRangeMinutes = moment.duration(timeWindows[0].time_window_range).asMinutes();
    if (
      firstTimeWindowRangeMinutes >= defaultTimeWindowRangeMinutes &&
      timeWindowOffsetMinutes <= defaultTimeWindowOffsetMinutes
    ) {
      return 'default';
    } else {
      if (!this.globals.isLargeClockDisabled && arrivalTime) {
        let timeWindowEndToArrivalMinutes, timeWindowStartToArrivalMinutes;
        const timeWindowEndToArrivalMinutesArray = [];
        const timeWindowStartToArrivalMinutesArray = [];
        const arrivalTimeMoment = moment(arrivalTime);
        timeWindowEndArray.forEach(timeWindowEnd => {
          const timeWindowEndMoment = moment(timeWindowEnd);
          const timeWindowEndToArrival = moment.duration(timeWindowEndMoment.diff(arrivalTimeMoment));
          timeWindowEndToArrivalMinutesArray.push(timeWindowEndToArrival.asMinutes());
        });
        timeWindowStartArray.forEach(timeWindowStart => {
          const timeWindowStartMoment = moment(timeWindowStart);
          const timeWindowArrivalToStart = moment.duration(arrivalTimeMoment.diff(timeWindowStartMoment));
          timeWindowStartToArrivalMinutesArray.push(timeWindowArrivalToStart.asMinutes());
        });
        timeWindowStartToArrivalMinutes = timeWindowStartToArrivalMinutesArray.reduce(function (prev, curr) {
          // get the timeWindowEndToArrivalMinutes value closest to zero
          return (Math.abs(curr) > Math.abs(prev) ? curr : prev);
        });
        timeWindowEndToArrivalMinutes = timeWindowEndToArrivalMinutesArray.reduce(function (prev, curr) {
          // get the timeWindowEndToArrivalMinutes value closest to zero
          return (Math.abs(curr) < Math.abs(prev) ? curr : prev);
        });
        if (
          (timeWindowEndToArrivalMinutes <= 15 && timeWindowEndToArrivalMinutes >= -5) ||
          (timeWindowStartToArrivalMinutes <= 15 && timeWindowStartToArrivalMinutes >= -5)
        ) {
          return 'limited';
        } else if (timeWindowEndToArrivalMinutes < -5 || timeWindowStartToArrivalMinutes < -5) {
          return 'late';
        }
      } else {
        if (minimumDurationMinutes < 3) {
          return 'strict';
        }
      }
      return 'regular';
    }
  }

  calculateWeightState(weight, id) {
    if (weight >= this.maximumWeights[id]) { return true; } else { return false; }
    // if (weight > this.averageWeight * 5) { return true; } else { return false; }
  }

  loadProjectProblemData(projectProblem) {
    const self = this;
    const stopPointsObject = {};
    const routeSettingsByProjectProblemId = {};
    const routeIndexSolutionData = {};
    const firstPointInRouteDatetimesArray = [], currentPointInRouteDatetimesArray = [],
      lastPointInRouteDatetimesArray = [];
    let firstPointInRouteId, lastPointInRouteId, firstDatetime, currentDatetime, lastDatetime, currentStopPointId;
    this.volumesArrays[projectProblem.id] = [];
    this.departureDateTimes[projectProblem.id] = projectProblem.departure_datetime;
    this.http.get('api/v1/project/problems/' + projectProblem.id + '/route-settings').pipe(take(1)).subscribe(routeSettings => {
      routeSettingsByProjectProblemId[projectProblem.id] = routeSettings['items'];
      routeSettings['items'].forEach(route => {
        this.vehicleIds.push(route.vehicle.id);
        this.vehicleSetting[route.vehicle.id] = route.routeSetting.id;
        this.vehicleIdPlateNumber[route.vehicle.id] = route.vehicle.plate_number;
        this.vehicleData[route.vehicle.id] = {
          driverId: route.driver.id,
          routeIndex: route.routeSetting.route_index
        };
      });

      // get and show all stop points with same colour
      this.http.get('api/v1/project/problems/' + projectProblem.id + '/stop-points').pipe(take(1)).subscribe(stopPoints => {
        const stopPointsWeights = {};
        stopPoints['items'].forEach(stopPoint => {
          if (stopPoint.stopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {
            let markerColour = '#00aeba';
            const locationIndex = null;
            let lat = stopPoint.stopPoint.address.lat;
            let lon = stopPoint.stopPoint.address.lon;
            if (stopPoint.stopPoint.address.display_address) {
              lat = stopPoint.stopPoint.address.display_address.lat;
              lon = stopPoint.stopPoint.address.display_address.lon;
            }
            const id = stopPoint.stopPoint.id;
            stopPointsObject[id] = stopPoint.stopPoint;
            let enabled = true;
            let arrivalTime = null;
            stopPointsWeights[id] = 0;

            const loads = this.stopPointUtils.getPickupAndDeliveryLoad({
              stopPoint: stopPoint.stopPoint,
              relatedStopPoint: stopPoint?.relatedStopPoint
            });

            if (loads.deliveryLoad) {
              stopPointsWeights[id] += Number(loads.deliveryLoad);
              this.volumesArrays[projectProblem.id].push(loads.deliveryLoad);
            }
            if (loads.pickupLoad) {
              stopPointsWeights[id] += Number(loads.pickupLoad);
              this.volumesArrays[projectProblem.id].push(loads.pickupLoad);
            }

            if (stopPoint.stopPoint.solution) { arrivalTime = stopPoint.stopPoint.solution.latest_estimated_arrival_datetime; }
            if (stopPoint.stopPoint.error_status === this.globals.stopPointErrorStatusConstants['ERROR']) {
              markerColour = '#666666';
            } else if (stopPoint.stopPoint.entity_status === this.globals.stopPointEntityStatusConstants['DISABLED']) {
              enabled = false;
              markerColour = '#00aeba70';
            }
            const data = {
              id: id,
              colour: markerColour,
              editColour: null,
              lat: lat,
              lon: lon,
              sequence: locationIndex,
              enabled: enabled,
              priority: stopPoint.stopPoint.priority === this.globals.stopPointPriorityConstants['HIGH'] ? true : false,
              overweight: false,
              timeWindowState: this.calculateTimeWindowState(stopPoint.stopPoint.time_windows, arrivalTime, projectProblem.id),
              edited: false,
              projectProblemId: projectProblem.id
            };
            self.addStopPoint(data);
            if (stopPoint.stopPoint.fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
              this.completedStopPointsCount++;
            }
            this.totalStopPointsCount++;
          }
        });
        this.calculateAverageWeight();
        Object.keys(stopPointsWeights).forEach(key => {
          if (this.stopPointMapObjects[key]) {
            const data = this.stopPointMapObjects[key].getData();
            data.overweight = this.calculateWeightState(stopPointsWeights[key], projectProblem.id);
          }
        });
        this.progress = Math.round((this.completedStopPointsCount / this.totalStopPointsCount) * 100);

        if (projectProblem.optimization_state === self.globals.projectProblemOptimizationStateConstants['OPTIMIZED']) {
          this.http.get('api/v1/project/problems/' + projectProblem.id + '/solution').pipe(take(1)).subscribe(solution => {
            solution['items'].sequencePerRouteIndex.forEach(function (array, routeIndex) {
              routeIndexSolutionData[routeIndex] = {
                sequence: array,
              };
              array.forEach(function (stopPointId, sequence) {
                self.setStopPointIcon(stopPointId, self.colours[routeIndex], sequence);
              });
            });
            solution['items'].solutionInfoByRouteIndexByStopPointId.forEach(function (object, routeIndex) {
              routeIndexSolutionData[routeIndex] = {
                sequence: routeIndexSolutionData[routeIndex].sequence,
                stopPoints: object,
              };

              firstPointInRouteId = routeIndexSolutionData[routeIndex].sequence[0];
              lastPointInRouteId = routeIndexSolutionData[routeIndex].sequence[routeIndexSolutionData[routeIndex].sequence.length - 1];
              firstDatetime = routeIndexSolutionData[routeIndex].stopPoints[firstPointInRouteId].latestEstimatedArrivalDatetime;
              firstDatetime = routeIndexSolutionData[routeIndex].stopPoints[firstPointInRouteId].latestEstimatedArrivalDatetime;
              currentDatetime = moment().utc().format();
              lastDatetime = routeIndexSolutionData[routeIndex].stopPoints[lastPointInRouteId].latestEstimatedArrivalDatetime;
              firstPointInRouteDatetimesArray.push(firstDatetime);
              lastPointInRouteDatetimesArray.push(lastDatetime);
              self.totalDistance += routeIndexSolutionData[routeIndex].stopPoints[lastPointInRouteId].cumulativeDistance;
              self.totalDistanceKm = self.totalDistance / 1000;

              Object.keys(object).forEach(stopPointId => {
                const currentElement = object[stopPointId];
                const lineColour = self.colours[routeIndex];
                if (currentElement.polyline) {
                  self.addEncodedElements(currentElement.polyline, lineColour, currentElement.orderIndex);
                }
                if (currentElement.fulfillment_status === self.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
                  currentStopPointId = stopPointId;
                }
              });
              if (currentStopPointId) {
                self.currentDistance += routeIndexSolutionData[routeIndex].stopPoints[currentStopPointId].cumulativeDistance;
                self.currentDistanceKm = self.currentDistance / 1000;
              }

              let start, end, durationSumHours = 0;
              firstPointInRouteDatetimesArray.forEach(function (element, index) {
                start = moment(element);
                end = moment(lastPointInRouteDatetimesArray[index]);
                durationSumHours += moment.duration(end.diff(start)).asHours();
              });
              if (lastPointInRouteDatetimesArray.length) {
                const routeEndTimes = lastPointInRouteDatetimesArray.map(function (routeEndTime) {
                  return moment(routeEndTime);
                });
                const projectProblemDepartureDatetimeMoment = moment(projectProblem.departure_datetime);
                if (
                  !moment(self.minProjectProblemDepartureDatetimeMoment).isValid() ||
                  projectProblemDepartureDatetimeMoment.isBefore(self.minProjectProblemDepartureDatetimeMoment)
                ) {
                  self.minProjectProblemDepartureDatetimeMoment = projectProblemDepartureDatetimeMoment;
                  const minRouteStartTime = projectProblemDepartureDatetimeMoment.utc();
                  const currentTime = moment().utc();
                  const maxRouteEndTime = moment.max(routeEndTimes).utc();
                  const currentDurationHours = moment.duration(currentTime.diff(minRouteStartTime)).asHours();
                  const durationHours = moment.duration(maxRouteEndTime.diff(minRouteStartTime)).asHours();
                  const currentDurationHoursFloor = Math.floor(currentDurationHours);
                  const durationHoursFloor = Math.floor(durationHours);
                  const currentDurationMinutes = moment.duration((currentDurationHours - currentDurationHoursFloor), 'hours').asMinutes();
                  const durationMinutes = moment.duration((durationHours - durationHoursFloor), 'hours').asMinutes();
                  self.currentDuration = currentDurationHoursFloor + 'h ' + Math.round(currentDurationMinutes) + 'm';
                  self.totalDuration = durationHoursFloor + 'h ' + Math.round(durationMinutes) + 'm';
                }
              }

            });
          });
        }
      });
      if (
        projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
        projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['RE_DISPATCH'] ||
        projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['COMPLETED']
      ) {
        let vehicleIdsString = '';
        this.oldRouteSwitchVisible = true;

        this.vehicleIds.forEach(id => {
          self.trackOldRouteRequestDone[id] = true;
          if (vehicleIdsString === '') {
            vehicleIdsString = id;
          } else {
            vehicleIdsString = vehicleIdsString + ',' + id;
          }
          if (this.showOldRoute) {
            self.vehiclesOldRouteTracker(id, projectProblem);
            this.trackVehiclesOldRouteInterval.push(setInterval(function () {
              self.vehiclesOldRouteTracker(id, projectProblem);
            }, 1000));
          }
        });
        if (
          projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
          projectProblem.entity_status === self.globals.projectProblemEntityStatusConstants['RE_DISPATCH']
        ) {
          let params, colour, coords, dateTime, plateNumber, driverId, driverName;
          vehiclesTracker();
          this.trackVehiclesInterval = setInterval(vehiclesTracker, 1000);

          function vehiclesTracker() {
            if (self.trackRequestDone) {
              params = `?last_id=${self.lastId}&vehicle_ids=${vehicleIdsString}&project_problem_id=${projectProblem.id}`;
              self.getVehicleLocationsData(params).pipe(take(1)).subscribe(
                trackResponse => {
                  self.trackRequestDone = true;
                  self.lastId = trackResponse['last_id'];
                  let colourIndex = 0;
                  const vehicleLocationData = trackResponse['items'];
                  let vehicle;
                  Object.keys(vehicleLocationData).forEach(function (vehicleId, index) {
                    colourIndex = self.vehicleData[vehicleId]['routeIndex'];
                    vehicle = vehicleLocationData[vehicleId];
                    colour = self.colours[colourIndex];
                    if (vehicle[vehicle.length - 1]) {
                      coords = {
                        lat: vehicle[vehicle.length - 1].lat,
                        lon: vehicle[vehicle.length - 1].lon
                      };
                      dateTime = vehicle[vehicle.length - 1].datetime;
                      plateNumber = self.vehicleIdPlateNumber[vehicleId];
                      driverId = self.vehicles[vehicleId]['driverId'];
                      driverName = self.vehicles[vehicleId].userProfile.name;
                    } else {
                      dateTime = null;
                    }
                    if (coords && vehicleId && dateTime) {
                      self.trackVehicle(vehicleId, colour, coords, dateTime, plateNumber, driverName);
                    }
                  });
                },
                error => {
                  self.trackRequestDone = true;
                }
              );
            }
          }
        }

      }
    });
  }

  calculateAverageWeight() {
    Object.keys(this.volumesArrays).forEach(key => {
      const volumesArrayLength = this.volumesArrays[key].length;
      this.volumesArrays[key].sort(function (a, b) { return a - b; });
      const maximumWeightPosition = volumesArrayLength - (Math.floor(volumesArrayLength / 5));
      this.maximumWeights[key] = this.volumesArrays[key][maximumWeightPosition];
    });
  }

  initMap(depotLat, depotLon) {
    const self = this;
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 12,
        center: { lat: depotLat, lng: depotLon }
      }
    );
    const map = this.map;
    const viewProjectProblemService = this.viewProjectProblemService;
    const mapEvents = new H.mapevents.MapEvents(this.map);
    this.clickOnMap = function (event) {
      const target = event.target;
      if (target instanceof H.map.DomMarker) {
        if (target.getData()) {
          self.editingMarkerGroup.addObject(target);
          // const icon = new H.map.DomIcon(this.svgMarkup.replace('markerColour', '#00aeba'));
          // target.setIcon();
          const bounds = self.editingMarkerGroup.getBoundingBox();
          self.editingMarkerGroup.removeObject(target);
          self.stopPointsGroup.addObject(target);
          const clickedStopPointId = target.getData().id;
          viewProjectProblemService.openStopModal(clickedStopPointId, target.getData().projectProblemId);
          // self.centerToMarker(bounds);
        }
      }
    };
    this.stopPointsGroup.addEventListener('tap', this.clickOnMap);
    // Instantiate the default behavior, providing the mapEvents object:
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const mapSettings = this.ui.getControl('mapsettings');
    mapSettings.setAlignment('top-left');
    this.displayInfoBubble = function (evt) {
      self.infoBubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content: evt.target.getData().bubbleText
      });
      self.ui.addBubble(self.infoBubble);
    };
    this.displayOldRouteInfoBubble = function (evt) {
      if (self.oldRouteInfoBubble && self.ui.getBubbles()) {
        self.ui.removeBubble(self.oldRouteInfoBubble);
        self.oldRouteInfoBubble.close();
      }
      self.oldRouteInfoBubble = new H.ui.InfoBubble(evt.target.getGeometry().extractPoint(0), {
        content: evt.target.getData().bubbleText
      });
      self.ui.addBubble(self.oldRouteInfoBubble);
    };
    this.displayDepots();
    this.stopPointsDepotsRoutesGroup.addObject(this.stopPointsGroup);
    this.stopPointsDepotsRoutesGroup.addObject(this.routesGroup);
    this.map.addObject(this.stopPointsDepotsRoutesGroup);
    this.map.addObject(this.vehiclesGroup);
    this.map.addObject(this.editingMarkerGroup);
    this.map.addObject(this.oldRouteGroup);
  }

  getTranslations() {
    this.listen.push(this.translate.get('OVERVIEW.DATE').subscribe((res: string) => {
      this.dateLabel = res;
    }));
    this.listen.push(this.translate.get('GENERIC.HERE_AT').subscribe((res: string) => {
      this.wasHereMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.VEHICLE').subscribe((res: string) => {
      this.vehicleTitle = res;
    }));
    this.listen.push(this.translate.get('VEHICLE.PLATE_NUMBER').subscribe((res: string) => {
      this.plateNumberTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.DRIVER').subscribe((res: string) => {
      this.driverTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.LAT').subscribe((res: string) => {
      this.latTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.LON').subscribe((res: string) => {
      this.lonTitle = res;
    }));
    this.updateVehicleBubbles();
    this.updatePreviousRoutes();
  }

  ngOnInit() {
    this.loadOverviewData();
    this.date = moment().format('DD MMM YYYY');

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  public ngAfterViewInit() {
    const self = this;
    this.http.get('api/v1/company/depots').subscribe(depots => {
      const depotLat = depots['items'][0]['companyDepot']['address']['lat'];
      const depotLon = depots['items'][0]['companyDepot']['address']['lon'];
      self.initMap(depotLat, depotLon);
    });
  }

  ngOnDestroy() {
    this.stopPointsGroup.removeEventListener('tap', this.clickOnMap);
    if (this.trackVehiclesInterval) {
      clearInterval(this.trackVehiclesInterval);
    }
    this.trackVehiclesOldRouteInterval.forEach(trackVehiclesOldRouteInterval => {
      clearInterval(trackVehiclesOldRouteInterval);
    });
    this.lastIdOldRoute = 0;
    this.trackVehiclesOldRouteInterval = [];
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
