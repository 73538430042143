import { Injector, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

export class LmDialogContext<T> {
  close: (result?: unknown) => void;
  data: T;
}

export interface LmDialogConfig {
  componentType: Type<any>;
  // componentType: any;
  data?: unknown;
  injector: Injector;
  vcr?: ViewContainerRef
}
