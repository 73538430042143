import { Injectable } from '@angular/core';
import { Globals } from '@app/services/globals';

declare var H: any;

@Injectable({
    providedIn: 'root'
})
export class MapUtils {

    constructor(
        public globals: Globals,
    ) { }

    createMarkerIcon(data, markerId = null, isDomIcon = false, selectedStopPointIds, svgIconsComponent) {
        let icon, sequence;

        // when sequence exists show sequence except if: sp is complete, cancelled, cancelledByRecipient, recurring, overweight or tw limited
        if (
            data.sequence && !data.complete && !data.cancelled && !data.cancelledByRecipient && !data.recurring && !data.overweight &&
            data.timeWindowState !== 'limited' && (data.timeWindowState !== 'late' || this.globals.foodModeEnabled)) {
            sequence = data.sequence.toString();
        } else if (data.merged && !data.complete && !data.cancelled) {
            if (data.mergedToText) {
                sequence = data.mergedToText;
            } else {
                sequence = 'M';
            }
        } else if (data.recurring && !data.complete && !data.cancelled) {
            sequence = 'R';
        } else {
            sequence = '';
        }
        const red = '#FF0000';
        const colour = data.timeWindowState === 'late' ? red : data.colour;
        const defaultColour = data.enabled && !data.merged ? '#00aeba' : '#00aeba' + '70';
        const markerColour = data.enabled && !data.merged ? colour : colour + '70';
        const redColour = data.enabled ? red : red + '70';
        const editColours = data.editColours.length ? data.editColours : [defaultColour];

        icon = svgIconsComponent.markerSvg;
        if (isDomIcon) { icon = svgIconsComponent.domMarkerSvg; }

        if (markerId) {
            icon = icon = svgIconsComponent.domMarkerSvg;
            icon = icon.replace('idPlaceholder', 'id="' + markerId + '"');
        } else {
            icon = icon.replace('idPlaceholder', '');
        }

        icon = icon.replace('markerColour', markerColour);
        icon = icon.replace('borderColour', 'white');
        if ((data.selected || selectedStopPointIds.includes(data.id)) && isDomIcon) {
            // if sp is selected
            icon = icon.replace('checkboxSvg', svgIconsComponent.checkbox);
            icon = this.replaceEditColours(editColours, icon);
            icon = icon.replace('checkSvg', svgIconsComponent.check);
        } else if (data.editColours.length || data.editSequenceStopPoints) {
            // if sp is not selected and edited
            icon = icon.replace('checkboxSvg', svgIconsComponent.checkbox);
            icon = this.replaceEditColours(editColours, icon);
            icon = icon.replace('checkSvg', '');
            if (data.editSequenceStopPoints) {
                icon = icon.replace('beforeAfterSvg', svgIconsComponent.beforeAfter);
            }
        }
        icon = icon.replace('markerNumber', sequence);
        if (data.priority) {
            icon = icon.replace('asteriskSvg', svgIconsComponent.asterisk);
            icon = icon.replace('asteriskColour', markerColour);
        }
        if (data.complete) {
            icon = icon.replace('completedSvg', svgIconsComponent.completedSvg);
            icon = icon.replace('cancelledSvg', '');
        } else if (data.cancelled) {
            icon = icon.replace('completedSvg', '');
            icon = icon.replace('cancelledSvg', svgIconsComponent.cancelledSvg);
        } else {
            icon = icon.replace('completedSvg', '');
            icon = icon.replace('cancelledSvg', '');
        }
        if (data.cancelledByRecipient) {
            icon = icon.replace('cancelledPortalSvg', svgIconsComponent.cancelledPortalSvg).replace('markerColour', markerColour);
            icon = icon.replace('userSvg', svgIconsComponent.userSvg);
        } else {
            icon = icon.replace('cancelledPortalSvg', '');

            if (data.timeWindowState) {
                switch (data.timeWindowState) {
                    case 'regular':
                        if (!data.cancelledByRecipient) {
                            icon = icon.replace('smallClockSvg', svgIconsComponent.smallClock);
                            icon = icon.replace('clockColour', markerColour);
                        }
                        break;
                    case 'strict':
                        if (!data.cancelledByRecipient) {
                            icon = icon.replace('smallClockSvg', svgIconsComponent.smallClock);
                            icon = icon.replace('clockColour', redColour);
                        }
                        break;
                    case 'limited':
                        // don't show if sp is cancelled or complete
                        if (!data.complete && !data.cancelled) {
                            if (!this.globals.foodModeEnabled) {
                                icon = icon.replace('largeClockSvg', svgIconsComponent.largeClock);
                            }
                            icon = icon.replace('clockColour', markerColour);
                        }
                        break;
                    case 'late':
                        // don't show if sp is cancelled or complete
                        if (!data.complete && !data.cancelled) {
                            if (!this.globals.foodModeEnabled) {
                                icon = icon.replace('largeClockSvg', svgIconsComponent.largeClock);
                            }
                            icon = icon.replace('clockColour', redColour);
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        // if sp is overweight
        // don't show if sp is cancelled or complete
        // don't show if sp is recurring or merged
        // don't show if a clock icon already exists
        if (
            data.overweight &&
            !data.complete && !data.cancelled && !data.recurring && !data.merged &&
            data.timeWindowState !== 'late' && data.timeWindowState !== 'limited') {
            icon = icon.replace('weightSvg', svgIconsComponent.weight);
        }
        icon = icon.replace('userSvg', '');
        icon = icon.replace('weightSvg', '');
        icon = icon.replace('checkboxSvg', '');
        icon = icon.replace('smallClockSvg', '');
        icon = icon.replace('asteriskSvg', '');
        icon = icon.replace('largeClockSvg', '');
        icon = icon.replace('beforeAfterSvg', '');

        const circle = '<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="0.5" cy="0.5" r="0.5"/>  </svg>'

        return icon;
        // return '/assets/lastmilyAssets/marker.png';
    }

    getMainMarkerIcon(data, basicMarkerSvg, stopPointMarkerIconsByColour) {
        let icon;
        const markerColour = this.getMarkerColour(data);

        if (stopPointMarkerIconsByColour[markerColour]) {
            icon = stopPointMarkerIconsByColour[markerColour];
        } else {
            let iconSvg = basicMarkerSvg.replace('markerColour', markerColour);
            iconSvg = iconSvg.replace('borderColour', 'white');
            icon = new H.map.Icon(iconSvg);
        }

        return icon;
    }

    getMarkerColour(data) {
        const red = '#FF0000';
        const colour = data.timeWindowState === 'late' ? red : data.colour;
        const markerColour = data.enabled && !data.merged ? colour : colour + '70';
        return markerColour;
    }

    replaceEditColours(colours = [], icon) {
        const editColours = [];
        for (let i = 0; i < 4; i++) {
            if (colours[i]) {
                editColours.push(colours[i]);
            } else {
                if (colours.length === 2) {
                    editColours.push(colours[i % 2]);
                } else {
                    editColours.push(colours[colours.length - 1]);
                }
            }
        }
        editColours.forEach(colour => {
            icon = icon.replace('editColour', colour);
        });
        return icon;
    }

    createClusterIcon(stopPointIds, clusterText, colour, svgIconsComponent, selected = false) {
        let circleSize;
        if (stopPointIds.length < 10) {
            circleSize = '30';
        } else if (stopPointIds.length < 50) {
            circleSize = '35';
        } else if (stopPointIds.length < 100) {
            circleSize = '40';
        } else {
            circleSize = '45';
        }
        let iconSvg = svgIconsComponent.clusterMarker
            .replace('markerColour', colour)
            .replace('markerBorderColour', colour + '70')
            .replace('circleSize', circleSize)
            // .replace('textInput', clusterText);
            .replace('textInput', stopPointIds.length);
        if (selected) {
            iconSvg = iconSvg.replace('checkboxSvg', svgIconsComponent.clusterCheckbox);
        } else {
            iconSvg = iconSvg.replace('checkboxSvg', '');
        }
        const icon = new H.map.DomIcon(iconSvg);
        return icon;
    }

    calculateDriverInitials(name) {
        const array = name.split(' ');
        let initials = '';
        if (array.length) {
            // initials were 2 letters, then we changed it to 1
            // initials = array[1] ? array[0].charAt(0) + array[1].charAt(0) : array[0].charAt(0);
            initials = array[0].charAt(0);
        }
        return initials;
    }

    stopPointChanged(newData, oldData) {
        delete newData['selected'];
        delete oldData['selected'];
        if (JSON.stringify(newData) === JSON.stringify(oldData)) {
            return false;
        } else {
            return true;
        }
    }

    stopPointColourChanged(newData, oldData) {
        const newMarkerColour = this.getMarkerColour(newData);
        const oldMarkerColour = this.getMarkerColour(oldData);
        if (newMarkerColour === oldMarkerColour) {
            return false;
        } else {
            return true;
        }
    }

    // checks if the given array's  int contents are consecutive or not
    checkIfArrayIsConsecutive(array) {
        if (array.length > 1) {
            var i = 2, d;
            while (i < array.length) {
                d = array[i - 1] - array[i - 2];
                if (Math.abs(d) === 1 && d === array[i] - array[i - 1]) {
                    return false;
                }
                i++;
            }
            return true;
        }
    }

    // if the sps in the cluster are in sequence, show the first and last sequence on icon
    calculateClusterText(stopPointSequences) {
        let text = '';
        // sort the array asc
        stopPointSequences.sort((a, b) => a - b);
        // check if array's contents are consecutive or not
        if (this.checkIfArrayIsConsecutive(stopPointSequences)) {
            text = stopPointSequences[0] + '-' + stopPointSequences[stopPointSequences.length - 1];
        }
        return text;
    }

    polylineChanged(polyline, linestring) {
        if (linestring.equals(polyline.getGeometry())) {
            return false;
        } else {
            return true;
        }
    }

    hideBeforeAfterStopPoints() {
        document.querySelectorAll('.before-after-hover-box').forEach(element => {
            element.remove();
        });
    }

    showBeforeAfterStopPoints(editSequenceStopPoints, stopPointMapObjects, map) {        
        if (editSequenceStopPoints.afterIds) {
            this.showAllBeforeAfterStopPoints(editSequenceStopPoints.afterIds, 'After', stopPointMapObjects, map);
        }
        if (editSequenceStopPoints.beforeIds) {
            this.showAllBeforeAfterStopPoints(editSequenceStopPoints.beforeIds, 'Before', stopPointMapObjects, map);
        }
    }

    showAllBeforeAfterStopPoints(ids, label, stopPointMapObjects, map) {
        ids.forEach(id => {
            const target = stopPointMapObjects[id];
            if (target) {
                const targetPosition = map.geoToScreen(target.getGeometry());
                const afterBox = document.createElement('div');
                afterBox.classList.add('before-after-hover-box');
                afterBox.style.left = (Number(targetPosition.x) + 10) + 'px';
                afterBox.style.top = targetPosition.y + 'px';
                afterBox.style.display = 'block';
                afterBox.innerHTML = label;
                document.getElementById('before-after-hover-boxes').appendChild(afterBox);
            }
        });
    }
}