// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#move-shipment-modal {
  height: 50vh;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vdmUtc2hpcG1lbnQtbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSSxZQUFBO0FBREoiLCJmaWxlIjoibW92ZS1zaGlwbWVudC1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJzdHlsZXMvYWJzdHJhY3RzL3ZhcmlhYmxlc1wiO1xuXG4jbW92ZS1zaGlwbWVudC1tb2RhbCB7XG4gICAgaGVpZ2h0OiA1MHZoO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/modals/move-shipment-modal/move-shipment-modal.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;AACA,gZAAgZ","sourcesContent":["@import \"styles/abstracts/variables\";\n\n#move-shipment-modal {\n    height: 50vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
