import { GenericService } from '@app/services/generic.service';
import { Module } from '@ag-grid-community/core';
import { Component, ViewChild, OnInit, EventEmitter, OnDestroy, Output, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import * as moment from 'moment';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ColourService } from '@app/services/colour.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { take } from 'rxjs/operators';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { StepsService } from '@app/services/steps.service';
import { GridUtils } from '@app/utils/grid-utils';
import { GenericUtils } from '@app/utils/generic-utils';

@Component({
  selector: 'app-stops-modal-grid',
  templateUrl: './stops-modal-grid.component.html',
  styleUrls: ['./../grids.scss', './stops-modal-grid.component.scss'],
})
export class StopsModalGridComponent implements OnInit, OnDestroy {
  @ViewChild('stopPointsBeforeOptimize', { static: false }) stopPointsBeforeOptimize: AgGridAngular;
  @ViewChild('stopPointsAfterOptimize', { static: false }) stopPointsAfterOptimize: AgGridAngular;
  @Output() openStopPointFormListen = new EventEmitter<string>();
  @Output() stopsCount = new EventEmitter<string>();
  stopPointsUrlOld = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points';
  stopPointsUrl = 'api/internal/v2/project/problems/PROJECT_PROBLEM_ID/stop-points';
  updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
  manualModifiedRouteUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/manual-modified-route-items';

  public modules: Module[] = [InfiniteRowModelModule];
  projectProblemId;
  listen = [];
  projectProblemDepartureDatetime;
  activeGrid;
  filterActive = false;
  manageActive = false;
  sortActive = false;
  searchString: String = '';
  currentData;

  foodModeShipmentsCount = 0;

  selectedDriverItem;
  drivers = [];
  totalStopPointsCount;

  gridApi;
  gridApi2;
  gridColumnApi;
  gridColumnApi2;
  domLayout = 'normal';
  rowModelType = 'infinite';
  rowBuffer = 0;
  paginationPageSize = 50;
  cacheOverflowSize = 2;
  cacheBlockSize = 20;
  components;
  rowClassRules;
  headerHeight = 30;

  stopPointIndexInArray = {};
  stopPointsDataArray = [];
  stopPointsDataArray2 = [];
  stopPointsDataArray2WithoutFilters = [];
  stopPointsDataArray2WithInactive = [];
  // rowData = [];
  // rowData2 = [];
  // rowData2WithInactive: any;
  barcodeTitle;
  alternativeBarcodeTitle;
  serialTitle;
  partnerTitle;
  pickupTitle;
  payOnDeliveryTitle;
  collaboratorTitle;
  driverTitle;
  sequenceTitle;
  routingTimeTitle;
  nameTitle;
  recipientTitle;
  phoneTitle;
  addressTitle;
  dateTitle;
  timeTitle;
  typeTitle;
  statusTitle;
  windowTitle;
  currencyTitle;
  cashPayAmountTitle;
  cardPayAmountTitle;
  canceledPayAmountTitle;
  cashPayAmountCollaboratorTitle;
  cardPayAmountCollaboratorTitle;
  canceledPayAmountCollaboratorTitle;
  customerIdTitle;
  notesTitle;
  loadTitle;
  cancelReasonTitle;
  descriptionTitle;
  inProgressPickupLabel;
  arrivedLabel;
  completedLabel;
  completedPickupFoodLabel;
  newLabel;
  pendingLabel;

  columnDefs;
  columnDefs2;
  noNameLabel = '';

  amounts = {};

  // cancel labels
  notThereLabel;
  wrongAddressLabel;
  didNotAcceptLabel;
  pickupFromDepotLabel;
  agreedShippingLabel;
  agreedShippingAndOrOtherAddressLabel;
  otherReasonLabel;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    private colourService: ColourService,
    public gridsService: GridsService,
    private addressService: AddressService,
    private stopPointUtils: StopPointUtils,
    private stepsService: StepsService,
    public gridUtils: GridUtils,
    public genericUtils: GenericUtils,
    public genericService: GenericService
  ) {
    this.components = {
      loadingRenderer: function (params) {
        if (params.value !== undefined) {
          return params.value;
        } else {
          return '<img src="https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/images/loading.gif">';
        }
      },
    };
    this.listen.push(this.viewProjectProblemService.openStopModalListen().subscribe((data) => {
      this.hideCheckBoxes();
    }));
    this.listen.push(this.viewProjectProblemService.setStopPointsGridListen().subscribe((data) => {
      this.setStopPointsModalGridData();
    }));
    this.listen.push(this.viewProjectProblemService.updateStopPointsGridListen().subscribe((data) => {
      if (data.stopPointsToUpdate.length) {
        this.updateStopPointsGridData(data.stopPointsToUpdate);
      }
      if (data.stopPointsToAdd.length) {
        this.addStopPointsToGrid(data.stopPointsToAdd);
      }
      if (data.stopPointsToRemove) {
        this.removeStopPointsFromGrid(data.stopPointsToRemove);
      }
    }));
    this.listen.push(this.viewProjectProblemService.setActiveStopPointsGridListen().subscribe((state) => {
      this.setActiveGrid(state);
      this.setStopPointsModalGridData();
    }));
    this.listen.push(this.genericService.listenUpdateStopsGridInCollaboratorOverview().subscribe(() => {
      this.setStopPointsModalGridData();
    }));
    this.listen.push(this.viewProjectProblemService.updateStopPointDataInGridListen().subscribe((data) => {
      this.updateStopPointDataInGrid(data);
    }));
    this.listen.push(this.viewProjectProblemService.updateSelectDriversListen().subscribe(() => {
      this.updateSelectDrivers();
    }));
    this.listen.push(this.stepsService.transmitProjectProblemDataListen().subscribe((data) => {
      this.setStopPointsGridData(this.projectProblemId, this.projectProblemDepartureDatetime, data.projectProblemState);
    }));
  }

  cellStyling(params: any) {
    if (params.data) {
      if (params.data.fullData) {
        let colour = '#ffffff';
        if (params.data.routeIndex || params.data.routeIndex === 0) {
          colour = this.colourService.colourCalculator(params.data.routeIndex);
        } else {
          colour = '#ffffff';
        }
        return { 'background': colour };
      }
    }
  }

  greyLettersClass(params) {
    if (params.data) {
      if (params.data.entityStatusConstant !== this.globals.stopPointEntityStatusConstants['ACTIVE']) {
        return true;
      }
    }
    return false;
  }

  redLettersClass(params) {
    if (params.data) {
      if (params.data.errorStatusConstant === this.globals.stopPointErrorStatusConstants['ERROR']) {
        return true;
      }
    }
    return false;
  }

  toggleCheckBoxes() {
    let activeColumnDefs, columnDefsCellIndex, gridApi;
    if (this.activeGrid === 1) {
      activeColumnDefs = this.columnDefs;
      columnDefsCellIndex = 4;
      gridApi = this.gridApi;
    } else {
      activeColumnDefs = this.columnDefs2;
      columnDefsCellIndex = 6;
      gridApi = this.gridApi2;
    }
    // if manage is enabled, disable it and remove checkboxes
    if (activeColumnDefs[columnDefsCellIndex]['checkboxSelection']) {
      this.manageActive = false;
      activeColumnDefs[columnDefsCellIndex]['checkboxSelection'] = false;
      // empty columnDefs before updating them for changes to be detected by grid
      gridApi.setColumnDefs([]);
      gridApi.setColumnDefs(activeColumnDefs);
    } else {
      // if manage is disabled, enable it and add checkboxes
      this.manageActive = true;
      activeColumnDefs[columnDefsCellIndex]['checkboxSelection'] = true;
      // empty columnDefs before updating them for changes to be detected by grid
      gridApi.setColumnDefs([]);
      gridApi.setColumnDefs(activeColumnDefs);
    }
    this.setCheckboxesAsSelected();
  }

  setCheckboxesAsSelected() {
    let gridApi;
    if (this.activeGrid === 1) {
      gridApi = this.gridApi;
    } else {
      gridApi = this.gridApi2;
    }
    // this.rowData2 = of(this.stopPointsDataArray2);
    // this.rowData2 = this.stopPointsDataArray2;
    gridApi.forEachNode((node, index) => {
      if (node.data) {
        if (node.data.entityStatusConstant === this.globals.stopPointEntityStatusConstants['ACTIVE']) {
          node.setSelected(true);
        }
      }
    });
  }

  hideCheckBoxes() {
    if (this.manageActive) {
      this.manageActive = false;
      const checkbox = document.getElementById('stop-points-checkbox') as HTMLInputElement;
      checkbox.checked = false;
      let activeColumnDefs, gridApi;
      if (this.activeGrid === 1) {
        activeColumnDefs = this.columnDefs;
        gridApi = this.gridApi;
      } else {
        activeColumnDefs = this.columnDefs2;
        gridApi = this.gridApi2;
      }
      activeColumnDefs[0]['checkboxSelection'] = false;
      gridApi.setColumnDefs(activeColumnDefs);
    }
  }

  timeRenderer(params) {
    if (params.getValue()) {
      let columnObject = '';
      const time = params.getValue().time;
      const difference = params.getValue().difference;
      if (difference !== null) {
        columnObject += '<div class="double-cell">' + time + '</div>';
        if (difference > 0) {
          columnObject += '<div class="double-cell time-difference">+' + difference + '` <i class="fas fa-clock red-letters"></i></div>';
        } else {
          columnObject += '<div class="double-cell time-difference">' + difference + '`</div>';
        }
      } else {
        columnObject += '<div>' + time + '</div>';
      }
      return columnObject;
    } else {
      return '';
    }
  }

  statusRenderer(params) {
    if (params.getValue()) {
      let columnObject = '';
      if (params.data.status.fulfillmentStatus) {
        // split canceled description and use it on the second row
        let reasonLabels = params.getValue().fulfillmentStatus.split('(');
        if (reasonLabels.length > 1) {
          columnObject += '<div class="triple-cell">' + reasonLabels[0] + '</div>';
          columnObject += '<div class="triple-cell">' + '(' + reasonLabels[1] + '</div>';

          if (params.data.status.type) {
            columnObject += '<div class="triple-cell">' + params.getValue().type + '</div>';
          }
        }
        else {
          columnObject += '<div class="double-cell">' + params.getValue().fulfillmentStatus + '</div>';
          if (params.data.status.type) {
            columnObject += '<div class="double-cell">' + params.getValue().type + '</div>';
          }
        }
      }
      return columnObject;
    } else {
      return '';
    }
  }

  statusTimeRenderer(params) {
    if (params.getValue()) {
      let columnObject = '';
      if (params.getValue().isNew) {
        columnObject += '<div class="single-cell" style="color:#00aeba; font-size: 16px; font-weight: bold ">' + params.getValue().fulfillmentStatus + '</div>';
      } else if (params.getValue().isPending) {
        columnObject += '<div class="single-cell" style="color:#f58b00; font-size: 16px; font-weight: bold ">' + params.getValue().fulfillmentStatus + '</div>';
      }
      else {
        if (params.data.time) {
          if (params.data.time.time) {
            columnObject += '<div class="double-cell">' + params.data.time.time + '</div>';
          }
        }
        if (params.data.status.fulfillmentStatus) {
          columnObject += '<div class="double-cell">' + params.getValue().fulfillmentStatus + '</div>';
        }
      }
      return columnObject;
    } else {
      return '';
    }
  }

  windowRenderer(params) {
    if (params.value) {
      let columnObject = '';
      const timeWindows = params.value.split('&');
      timeWindows.forEach(timeWindow => {
        if (params.data.status.fulfillmentStatus) {
          columnObject += '<div class="double-cell">' + timeWindow + '</div>';
        }
      });
      return columnObject;
    } else {
      return '';
    }
  }

  driverRenderer(params) {
    let columnObject = '';
    // let count = '';
    // if (params.)
    if (params.getValue().name) {
      columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().allNames + '">';
      columnObject += params.getValue().name + params.getValue().driversCount + '</div>';
      columnObject += '<div class="double-cell">' + params.getValue().phone + '</div>';
    }
    return columnObject;
  }

  rowClicked(event) {
    if (!event.data.noDataText) {
      this.viewProjectProblemService.openStopModal(event.data.id, this.projectProblemId);
    }
  }

  getSelectedRows() {
    let selectedNodes;
    if (this.activeGrid === 1) {
      selectedNodes = this.stopPointsBeforeOptimize.api.getSelectedNodes();
    } else {
      selectedNodes = this.stopPointsAfterOptimize.api.getSelectedNodes();
    }
    const selectedData = selectedNodes.map(node => node.data);
  }

  rowCheckboxToggled(event) {
    const node = event.node;
    let updateStopPointUrl = this.updateStopPointUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    updateStopPointUrl = updateStopPointUrl.replace('STOP_POINT_ID', event.data.id);
    const stopPointEntityStatus = event.data.entityStatusConstant;
    const stopPointErrorStatus = event.data.errorStatusConstant;
    if ( // if the sp is active and the checkbox was unchecked, disable it
      stopPointEntityStatus === this.globals.stopPointEntityStatusConstants['ACTIVE'] &&
      !node.isSelected()
    ) {
      this.gridApi.showLoadingOverlay();
      node.data.entityStatusConstant = this.globals.stopPointEntityStatusConstants['DISABLED'];
      this.viewProjectProblemService.changeStopPointEntityStatus(event.data.id, true);
    } else if ( // if the sp is inactive and the checkbox was checked, enable it
      stopPointEntityStatus === this.globals.stopPointEntityStatusConstants['DISABLED'] &&
      node.isSelected()
    ) {
      this.gridApi.showLoadingOverlay();
      node.data.entityStatusConstant = this.globals.stopPointEntityStatusConstants['ACTIVE'];
      this.viewProjectProblemService.changeStopPointEntityStatus(event.data.id, false);
    } else if (// if the sp has error and the checkbox was checked, do nothing and uncheck
      // we do not uncheck error stop points as they are active now (the error status is different from entity status) MAY 2022
      stopPointErrorStatus === this.globals.stopPointErrorStatusConstants['ERROR'] &&
      node.isSelected()
    ) {
      // node.setSelected(false);
    }
  }

  loadManualRoutesAndPostSequence(sequence, outOfSequence) {
    let currentRouteSet = false;
    const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const data = { routes: [] };
    const currentRouteSettingId = this.projectProblemDataService.driversToRouteSettingIds[this.selectedDriverItem.driver.id];
    const myObserver = {
      next: (response) => {
        this.viewProjectProblemService.updateProjectProblem();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    this.http.get(manualModifiedRouteUrl).pipe(take(1)).subscribe(manualModifiedRouteData => {
      if (manualModifiedRouteData) {
        // loop all routes for manual changes
        manualModifiedRouteData['items'].forEach(route => {
          let routesObject = {
            project_problem_route_setting_id: null,
            inSequence: [],
            outOfSequence: []
          };
          // if the selected route has saved changes
          if (route.project_problem_route_setting_id === currentRouteSettingId) {
            routesObject.project_problem_route_setting_id = currentRouteSettingId;
            routesObject.inSequence = sequence;
            routesObject.outOfSequence = outOfSequence;
            currentRouteSet = true;
          } else {
            const tempRoute = route;
            // check all selected sps and remove them from other routes (in and out of seq)
            sequence.forEach(id => {
              if (tempRoute.inSequence.includes(id)) {
                tempRoute.inSequence.splice(tempRoute.inSequence.indexOf(id), 1);
              }
              if (tempRoute.outOfSequence.includes(id)) {
                tempRoute.outOfSequence.splice(tempRoute.outOfSequence.indexOf(id), 1);
              }
            });
            outOfSequence.forEach(id => {
              if (tempRoute.inSequence.includes(id)) {
                tempRoute.inSequence.splice(tempRoute.inSequence.indexOf(id), 1);
              }
              if (tempRoute.outOfSequence.includes(id)) {
                tempRoute.outOfSequence.splice(tempRoute.outOfSequence.indexOf(id), 1);
              }
            });
            routesObject = tempRoute;
          }
          data.routes.push(routesObject);
        });
        // if the route wasn't in the saved changes
        if (!currentRouteSet) {
          const routesObject = {
            project_problem_route_setting_id: currentRouteSettingId,
            inSequence: sequence,
            outOfSequence: outOfSequence
          };
          data.routes.push(routesObject);
        }
      }
      this.http.post(manualModifiedRouteUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    });
  }

  changeStopPointsSequence() {
    const sequence = [];
    const outOfSequence = [];
    let sequenceNumber = 1;
    this.stopPointsDataArray2.forEach((stopPoint, index) => {
      if (stopPoint.time === '-') {
        outOfSequence.push(stopPoint.id);
      } else {
        this.stopPointsDataArray2[index]['sequence'] = sequenceNumber;
        sequenceNumber++;
        sequence.push(stopPoint.id);
      }
    });
    // this.rowData2 = of(this.stopPointsDataArray2);
    // this.rowData2 = this.stopPointsDataArray2;
    const refreshParams = { force: true };
    this.gridApi2.refreshCells(refreshParams);
    if (sequence.length || outOfSequence.length) {
      this.loadManualRoutesAndPostSequence(sequence, outOfSequence);
    }
  }

  onFirstDataRendered(params) { }
  onFirstDataRendered2(params) { }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady2(params) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }

  uploadButtonClicked() {
    document.getElementById('general-csv-uploader').click();
  }

  setStopPointsGridData(projectProblemId, projectProblemDepartureDatetime, projectProblemState) {
    this.projectProblemId = projectProblemId;
    this.projectProblemDepartureDatetime = projectProblemDepartureDatetime;
    const dataRefreshIntervalId = setInterval(() => {
      if (this.projectProblemDataService.dataReady()) {
        this.setActiveGrid(projectProblemState);
        clearInterval(dataRefreshIntervalId);
        // this.setStopPointsModalGridData();
      }
    }, 200);
  }

  // if there is a filter, request data from the server
  // if there is no filter, use the data from the data service
  public searchStopPoints(driverId = null) {
    let stopPointsUrl = 'api/internal/v2/partner-overview-stop-points';
    if (this.projectProblemId) {
      stopPointsUrl = this.stopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    }
    const searchQuery = this.searchString ? 'searchQuery=' + this.searchString : '';
    let driverFilter = this.searchString && driverId ? '&' : '';
    driverFilter += driverId ? 'filter-driver-id=' + driverId : '';
    const params = '?' + searchQuery + driverFilter + '&pageSize=-1';
    this.gridApi.showLoadingOverlay();
    this.gridApi2.showLoadingOverlay();
    if (searchQuery || driverFilter) {
      this.http.get(stopPointsUrl + params).pipe(take(1)).subscribe(response => {
        if (response['items']['stopPoints']) {
          this.setStopPointsModalGridData(response['items']['stopPoints']);
        } else {
          this.setStopPointsModalGridData(response['items']);
        }
        this.gridApi.hideOverlay();
        this.gridApi2.hideOverlay();
      });
    } else {
      // use a timeout so the grid can render the rows
      setTimeout(() => {
        this.setStopPointsModalGridData(this.projectProblemDataService.stopPointsArray);
        this.gridApi.hideOverlay();
        this.gridApi2.hideOverlay();
      }, 100);
    }
  }

  reloadStopPoints() {
    this.projectProblemDataService.loadStopPoints().pipe(take(1)).subscribe(stopPoints => {
      this.projectProblemDataService.setStopPoints(stopPoints['items']);
      this.setStopPointsModalGridData();
    });
  }

  countStopPoints() {
    const activeStopPointsArray = this.stopPointsDataArray.length ? this.stopPointsDataArray : this.stopPointsDataArray2;
    // const count = this.stopPointsDataArray.length ? this.stopPointsDataArray.length : this.stopPointsDataArray2.length;
    let count = 0, completedCount = 0, completedAndCancelledCount = 0;
    // [food mode]: use new shipments total items as sps count
    if (!this.foodModeShipmentsCount) {
      activeStopPointsArray.forEach(stopPoint => {
        if (stopPoint.entityStatusConstant === this.globals.stopPointEntityStatusConstants['ACTIVE']) {
          count++;
          if (stopPoint.fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
            completedCount++;
            completedAndCancelledCount++;
          } else if (stopPoint.fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['CANCELED']) {
            completedAndCancelledCount++;
          }
        }
      });
      const counts = {
        total: count,
        completed: completedCount,
        completedAndCancelled: completedAndCancelledCount
      };
      this.stopsCount.emit(JSON.stringify(counts));

    } else {
      this.totalStopPointsCount = this.foodModeShipmentsCount;
    }
  }

  calculateStopPointIndexInArray() {
    let activeGridArray = [];
    if (this.activeGrid === 1) {
      activeGridArray = this.stopPointsDataArray;
    } else {
      activeGridArray = this.stopPointsDataArray2;
    }
    activeGridArray.forEach((stopPoint, index) => {
      this.stopPointIndexInArray[stopPoint.id] = index;
    });
  }

  removeStopPointsFromGrid(stopPointsToRemove) {
    stopPointsToRemove.forEach(stopPoint => {
      if (this.activeGrid === 1) {
        this.stopPointsDataArray.forEach((stopPointData, index) => {
          if (stopPointData.id === stopPoint.id) {
            this.stopPointsDataArray.splice(index, 1);
          }
        });
      } else {
        this.stopPointsDataArray2.forEach((stopPointData, index) => {
          if (stopPointData.id === stopPoint.id) {
            this.stopPointsDataArray2.splice(index, 1);
          }
        });
      }
    });
    if (this.activeGrid === 1) {
      // this.rowData = this.stopPointsDataArray;
      this.gridApi.refreshInfiniteCache();
    } else {
      // this.rowData2 = this.stopPointsDataArray2;
      this.gridApi2.refreshInfiniteCache();
    }
    this.calculateStopPointIndexInArray();
    this.countStopPoints();
  }

  addStopPointsToGrid(stopPoints) {
    const newItems = this.createGridDataArray(stopPoints);
    if (this.activeGrid === 1) {
      this.stopPointsDataArray = this.stopPointsDataArray.concat(newItems);
      // this.rowData = this.stopPointsDataArray;
      this.gridApi.refreshInfiniteCache();
    } else {
      this.stopPointsDataArray2 = this.stopPointsDataArray2.concat(newItems);
      // this.rowData2 = this.stopPointsDataArray2;
      this.gridApi2.refreshInfiniteCache();
    }
    this.calculateStopPointIndexInArray();
    this.countStopPoints();
  }

  updateStopPointDataInGrid(data) {
    if (data.stopPoint) { data = data.stopPoint; }
    const index = this.stopPointIndexInArray[data.id];
    if (index || index === 0) {
      const stopPointData = this.createGridDataArray([data]);
      if (this.activeGrid === 1) {
        this.stopPointsDataArray[index] = stopPointData[0];
        // this.rowData = this.stopPointsDataArray;
        this.gridApi.refreshInfiniteCache();
      } else {
        this.stopPointsDataArray2[index] = stopPointData[0];
        // this.rowData2 = this.stopPointsDataArray2;
        this.gridApi2.refreshInfiniteCache();
      }
      this.calculateStopPointIndexInArray();
      this.countStopPoints();
      this.gridApi.hideOverlay();
      this.gridApi2.hideOverlay();
    }
  }

  sortChanged(event) {
    // const sortData = event.api.getSortModel();
    const sortData = event.api.getColumnState();
    sortData.forEach(item => {
      if (item.colId === 'status') {
        function compareAsc(a, b) {
          if (a.fulfillmentStatus < b.fulfillmentStatus) { return -1; }
          if (a.fulfillmentStatus > b.fulfillmentStatus) { return 1; }
          return 0;
        }
        function compareDesc(a, b) {
          if (a.fulfillmentStatus < b.fulfillmentStatus) { return 1; }
          if (a.fulfillmentStatus > b.fulfillmentStatus) { return -1; }
          return 0;
        }
        if (item.sort === 'asc') {
          this.stopPointsDataArray2.sort(compareAsc);
        } else if (item.sort === 'desc') {
          this.stopPointsDataArray2.sort(compareDesc);
        }
        // this.rowData = this.stopPointsDataArray;
        this.gridApi.refreshInfiniteCache();
      } else if (item.colId === 'time') {
        this.compareTime(item.sort);
        // this.rowData = this.stopPointsDataArray;
        this.gridApi.refreshInfiniteCache();
      }
    });
  }

  sortByStatusAsc() {
    this.gridColumnApi2.applyColumnState({
      state: [{ colId: 'status', sort: 'desc' }],
      defaultState: { sort: null },
    });
  }

  compareTime(sort) {
    function compareAsc(a, b) {
      // check if time is '-' and treat them as larger
      const time1 = a.time.time;
      const time2 = b.time.time;
      if (time1 === time2) {
        return 0;
      }
      if (time1 === '-') {
        return 1;
      }
      if (time2 === '-') {
        return -1;
      }

      const momentTime1 = moment(time1, 'HH:mm');
      const momentTime2 = moment(time2, 'HH:mm');

      if (momentTime1.isBefore(momentTime2)) {
        return -1;
      }
      if (momentTime2.isBefore(momentTime1)) {
        return 1;
      }
      return 0;
    }
    function compareDesc(a, b) {
      // check if time is '-' and treat them as smaller
      const time1 = a.time.time;
      const time2 = b.time.time;
      if (time1 === time2) {
        return 0;
      }
      if (time1 === '-') {
        return -1;
      }
      if (time2 === '-') {
        return 1;
      }

      const momentTime1 = moment(time1, 'HH:mm');
      const momentTime2 = moment(time2, 'HH:mm');
      if (momentTime1.isBefore(momentTime2)) {
        return 1;
      }
      if (momentTime2.isBefore(momentTime1)) {
        return -1;
      }
      return 0;
    }
    if (sort === 'asc') {
      this.stopPointsDataArray2.sort(compareAsc);
    } else if (sort === 'desc') {
      this.stopPointsDataArray2.sort(compareDesc);
    }
  }

  updateStopPointsGridData(rowsToUpdate) {
    const itemsToUpdate = this.createGridDataArray(rowsToUpdate);
    if (this.activeGrid === 1) {
      itemsToUpdate.forEach(stopPointData => {
        this.stopPointsDataArray.forEach((stopPointInGrid, index) => {
          if (stopPointInGrid['id'] === stopPointData['id']) {
            this.stopPointsDataArray[index] = stopPointData;
          }
        });
      });
      // this.rowData = this.stopPointsDataArray;
      this.gridApi.refreshInfiniteCache();
    } else {
      itemsToUpdate.forEach(stopPointData => {
        this.stopPointsDataArray2.forEach((stopPointInGrid, index) => {
          if (stopPointInGrid['id'] === stopPointData['id']) {
            this.stopPointsDataArray2[index] = stopPointData;
          }
        });
      });
      this.compareTime('asc');
      // this.rowData2 = this.stopPointsDataArray2;
      this.gridApi2.refreshInfiniteCache();
    }
    this.calculateStopPointIndexInArray();
    this.countStopPoints();
  }

  public setStopPointsModalGridData(filteredData = null, fullData = false): void {
    const self = this;
    let gridData = filteredData ? filteredData : this.projectProblemDataService.stopPointsArray;

    // [food mode]: get new shipments, too in food mode
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      let newShipmentsUrl = 'api/internal/v2/partner-new-stop-points?pageSize=0';
      newShipmentsUrl += '&searchQuery=' + this.searchString;
      this.http.get(newShipmentsUrl).subscribe(metaRes => {
        newShipmentsUrl = 'api/internal/v2/partner-new-stop-points?pageSize=' + metaRes['itemsMeta']['totalCount'];
        newShipmentsUrl += '&searchQuery=' + this.searchString;
        this.http.get(newShipmentsUrl).subscribe(newShipmentsRes => {
          // remove new shipments that are already active
          let newShipmentsData = [];
          newShipmentsRes['items']['stopPoints'].forEach(newShipment => {
            let newShipmentExists = false;
            if (newShipment['voucher']) {
              gridData.forEach(stopPoint => {
                if (stopPoint['stopPoint']['id'] == newShipment['id']) {
                  newShipmentExists = true;
                } else if (newShipment['voucher']) {
                  if (newShipment['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['ACCEPTED']
                    || newShipment['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['DECLINED']) {
                    newShipmentExists = true;
                  }
                } else {
                  newShipmentExists = true;
                }
              });
            } else {
              newShipmentExists = true;
            }

            if (!newShipmentExists) {
              newShipmentsData.unshift(newShipment);
            }

            // if at least one non-sent stop point is found, then allow the user to request for pickup
            newShipmentsRes['items']['stopPoints'].forEach(stopPoint => {
              if (stopPoint['voucher']) {
                if (stopPoint['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['CREATED']) {
                  this.genericService.canRequestForPickup = true;
                }
              }
            });
          });

          // make new shipments appear on top (on-going sps should keep their order)
          let gridDataFirst = [...newShipmentsData.concat(gridData)];
          gridData = JSON.parse(JSON.stringify(gridDataFirst));

          addDataToGrid();
        });
      });
    } else {
      this.foodModeShipmentsCount = 0;
      addDataToGrid();
    }

    function addDataToGrid() {
      if (fullData) {
        if (self.currentData) {
          gridData = self.currentData;
        }
      };
      //   this.gridApi.setDatasource(dataSource);
      // } else {
      self.stopPointsDataArray2 = self.createGridDataArray(gridData, filteredData ? true : false);
      self.compareTime('asc');

      if (self.selectedDriverItem || fullData) {
        self.gridApi2.paginationSetPageSize(self.stopPointsDataArray2.length);
        self.gridApi2.gridOptionsWrapper.setProperty('cacheBlockSize', self.stopPointsDataArray2.length);
      } else {
        self.currentData = filteredData;
      }
      self.stopPointsDataArray = [];
      self.stopPointsDataArray2 = [];
      // this.rowData = null;
      if (self.activeGrid === 1) {
        self.stopPointsDataArray = self.createGridDataArray(gridData, filteredData ? true : false);
        if (fullData) {
          self.gridApi.paginationSetPageSize(self.stopPointsDataArray.length);
          self.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', self.stopPointsDataArray.length);
        } else {
          self.gridApi.paginationSetPageSize(self.paginationPageSize);
          self.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', self.cacheBlockSize);
        }
        const dataSource = {
          rowCount: null,
          getRows: (rowsParams) => {
            setTimeout(() => {
              const rowsThisPage = self.stopPointsDataArray.slice(rowsParams.startRow, rowsParams.endRow);
              if (self.stopPointsDataArray.length) {
                let lastRow = -1;
                if (self.stopPointsDataArray.length <= rowsParams.endRow) {
                  lastRow = self.stopPointsDataArray.length;
                }
                rowsParams.successCallback(rowsThisPage, lastRow);
              } else {
                const noData = { noDataText: 'No data' }
                rowsParams.successCallback([noData], 1);
              }
            }, 500);
          }
        };
        self.gridApi.setDatasource(dataSource);
      } else {
        self.stopPointsDataArray2 = self.createGridDataArray(gridData, filteredData ? true : false);
        self.compareTime('asc');

        // [food mode]: set correct array order between new shipments & actual stop points
        if (self.globals.collaboratorModeEnabled && self.globals.foodModeEnabled) {
          self.stopPointsDataArray2 = [...self.stopPointsDataArray2.reverse()];
        } else {
          self.stopPointsDataArray2 = [...self.stopPointsDataArray2];
        }

        if (self.selectedDriverItem || fullData) {
          self.gridApi2.paginationSetPageSize(self.stopPointsDataArray2.length);
          self.gridApi2.gridOptionsWrapper.setProperty('cacheBlockSize', self.stopPointsDataArray2.length);
        } else {
          self.gridApi2.paginationSetPageSize(self.paginationPageSize);
          self.gridApi2.gridOptionsWrapper.setProperty('cacheBlockSize', self.cacheBlockSize);
        }

        const dataSource = {
          rowCount: null,
          getRows: (rowsParams) => {
            setTimeout(() => {
              const rowsThisPage = self.stopPointsDataArray2.slice(rowsParams.startRow, rowsParams.endRow);
              if (self.stopPointsDataArray2.length) {
                let lastRow = -1;
                if (self.stopPointsDataArray2.length <= rowsParams.endRow) {
                  lastRow = self.stopPointsDataArray2.length;
                }
                rowsParams.successCallback(rowsThisPage, lastRow);
                self.setCheckboxesAsSelected();
              } else {
                const noData = { noDataText: 'No data' }
                rowsParams.successCallback([noData], 1);
              }
            }, 500);
          }
        };
        self.gridApi2.setDatasource(dataSource);
      }
      self.calculateStopPointIndexInArray();
      self.countStopPoints();
    }
  }

  calculatePayAmounts(data) {
    let cardPayAmount = 0, cashPayAmount = 0, canceledPayAmount = 0;
    const collaboratorAmounts = {};

    data.forEach(stopPoint => {

      if (stopPoint.collaborator && stopPoint.collaborator.collaboratorData) {
        const collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
        if (!collaboratorAmounts[collaboratorName]) {
          collaboratorAmounts[collaboratorName] = {
            cardPayAmount: 0,
            cashPayAmount: 0,
            canceledPayAmount: 0,
          }
        }
      }

      if (stopPoint.solution) {
        if (stopPoint.solution.driver) {
          if (stopPoint.fulfillment_status === this.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
            if (stopPoint.pay_amount) {
              if (stopPoint.voucher) {
                if (stopPoint.voucher.payment_method === this.globals.paymentOptionsConstants['CREDIT_CARD']) {
                  // console.log(stopPoint['pay_amount'] + ' added to credit card for stop point id ' + stopPoint.id);
                  cardPayAmount += Number(stopPoint.pay_amount);
                  if (stopPoint.collaborator) {
                    const collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
                    // console.log(stopPoint['pay_amount'] + ' added to credit card for stop point id ' + stopPoint.id + ' for collaborator ' + collaboratorName);
                    collaboratorAmounts[collaboratorName]['cardPayAmount'] += Number(stopPoint.pay_amount);
                  }
                } else {
                  // console.log(stopPoint['pay_amount'] + ' added to cash for stop point id ' + stopPoint.id);
                  cashPayAmount += Number(stopPoint.pay_amount);
                  if (stopPoint.collaborator) {
                    const collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
                    // console.log(stopPoint['pay_amount'] + ' added to cash for stop point id ' + stopPoint.id + ' for collaborator ' + collaboratorName);
                    collaboratorAmounts[collaboratorName]['cashPayAmount'] += Number(stopPoint.pay_amount);
                  }
                }
              } else {
                // console.log(stopPoint['pay_amount'] + ' added to cash for stop point id ' + stopPoint.id);
                cashPayAmount += Number(stopPoint.pay_amount);
                if (stopPoint.collaborator) {
                  const collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
                  // console.log(stopPoint['pay_amount'] + ' added to cash for stop point id ' + stopPoint.id + ' for collaborator ' + collaboratorName);
                  collaboratorAmounts[collaboratorName]['cashPayAmount'] += Number(stopPoint.pay_amount);
                }
              }
            }
          } else {
            if (stopPoint.pay_amount) {
              // console.log(stopPoint['pay_amount'] + ' added to canceled for stop point id ' + stopPoint.id);
              canceledPayAmount += Number(stopPoint.pay_amount);
              if (stopPoint.collaborator && stopPoint.collaborator.collaboratorData) {
                const collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
                // console.log(stopPoint['pay_amount'] + ' added to canceled for stop point id ' + stopPoint.id + ' for collaborator ' + collaboratorName);
                collaboratorAmounts[collaboratorName]['canceledPayAmount'] += Number(stopPoint.pay_amount);
              }
            }
          }
        }
      } else {
        if (stopPoint.pay_amount) {
          // console.log(stopPoint['pay_amount'] + ' added to canceled for stop point id ' + stopPoint.id);
          canceledPayAmount += Number(stopPoint.pay_amount);
          if (stopPoint.collaborator && stopPoint.collaborator.collaboratorData) {
            const collaboratorName = stopPoint.collaborator.collaboratorData.collaborator_name;
            // console.log(stopPoint['pay_amount'] + ' added to canceled for stop point id ' + stopPoint.id + ' for collaborator ' + collaboratorName);
            collaboratorAmounts[collaboratorName]['canceledPayAmount'] += Number(stopPoint.pay_amount);
          }
        }
      }

    });

    const amounts = {
      cardPayAmount: cardPayAmount,
      cashPayAmount: cashPayAmount,
      canceledPayAmount: canceledPayAmount,
      collaboratorAmounts: collaboratorAmounts
    };

    this.amounts = amounts;
  }

  createGridDataArray(data, mapFilters = false) {
    const dataArray = [];
    let id, stopPointName, stopPointPhone, timeWindow, serviceType, entityStatus, serviceTypeLabel, fulfillmentStatus, time, sequence, driverName, payAmount, pickupSpot, customerId;
    const filteredRouteSettingIds = [];
    const filteredStopPoints = [];
    this.calculatePayAmounts(data);

    // [food mode]: mutate data on courier food to have the same data structure as collaborator food mode
    // if (!this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
    //   let newData = [];
    //   data.forEach(stopPoint => {
    //     data.forEach(otherStopPoint => {
    //       if (stopPoint['related_stop_point_id'] == otherStopPoint['id']) {
    //         newData.push({
    //           stopPoint: stopPoint,
    //           relatedStopPoint: otherStopPoint
    //         });
    //       }
    //     });
    //   });

    JSON.parse(JSON.stringify(data)).forEach((row, index) => {
      const bothStopPoints = row;
      // [food mode]: get food depot name (for courier & collaborator sides)
      let foodDepotNameValue;
      if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
        if (row['relatedStopPoint']) {
          if (row['relatedStopPoint']['service_type'] == this.globals.stopPointServiceTypeConstants['PICKUP']) {
            foodDepotNameValue = row['relatedStopPoint']['contact_name'];
          }
        }
      } else if (this.globals.foodModeEnabled) {
        if (row['service_type'] != this.globals.stopPointServiceTypeConstants['PICKUP']) {
          data.forEach(currStopPoint => {
            if (currStopPoint['related_stop_point_id'] == row['id']) {
              foodDepotNameValue = currStopPoint['contact_name'];
            }
          });
        }
      }

      // this is to handle both cases of SP data from be, with stopPoint wrapper and without
      if (row.stopPoint) {
        row = row.stopPoint;
      }

      if (!this.globals.foodModeEnabled ||
        (this.globals.foodModeEnabled && row['service_type'] != this.globals.stopPointServiceTypeConstants['PICKUP'])
      ) {
        if (row.related_to === this.globals.stopPointRelatedToConstants['SELF']) {
          id = row.id;
          stopPointName = row.contact_name;
          if (stopPointName === '_NO_NAME') {
            stopPointName = this.noNameLabel;
          }
          stopPointPhone = row.telephone ? String(row.telephone) : '';
          timeWindow = this.calculateTimeWindow(row.time_windows);
          time = this.calculateTime(row);
          driverName = '-';

          let routeIndex = null;
          if (this.projectProblemDataService.stopPointSolutionData[id]) {
            routeIndex = this.projectProblemDataService.stopPointSolutionData[id].routeIndex;
          }
          if (row.solution) {
            if (!filteredRouteSettingIds.includes(row.solution.routeSettingId)) {
              filteredRouteSettingIds.push(row.solution.routeSettingId);
            }
            driverName = row.solution.driver.userProfile.name;
          }

          if (this.projectProblemDataService.stopPointSolutionData[id]) {
            sequence = this.projectProblemDataService.stopPointSolutionData[id].sequence;
          } else {
            sequence = '';
          }

          payAmount = '';
          if (row.pay_amount) {
            payAmount = row.pay_amount + this.currencyTitle;
          }
          pickupSpot = '';
          if (row.related_company_depot_id) {
            const pickupSpotId = row.related_company_depot_id;
            const pickupDepot = this.globals.depots[pickupSpotId];
            if (pickupDepot) {
              if (pickupDepot['companyDepot']['address']['label']) {
                pickupSpot = pickupDepot['companyDepot']['address']['label'];
              } else {
                pickupSpot = this.addressService.getAddressLabel(pickupDepot['companyDepot']['address']);
              }
            }
          }

          serviceType = this.globals.stopPointServiceTypeLabels[row.service_type];
          entityStatus = this.globals.stopPointEntityStatusLabels[row.entity_status];
          fulfillmentStatus = this.globals.stopPointFulfillmentStatusLabels[row.fulfillment_status];
          switch (row.service_type) {
            case this.globals.stopPointServiceTypeConstants['DELIVERY']:
              this.listen.push(this.translate.get('STOP_POINT.DELIVERY_INITIAL').subscribe((res: string) => { serviceTypeLabel = '(' + res + ')'; }));
              break;
            case this.globals.stopPointServiceTypeConstants['PICKUP']:
              this.listen.push(this.translate.get('STOP_POINT.PICKUP_INITIAL').subscribe((res: string) => { serviceTypeLabel = '(' + res + ')'; }));
              break;
            case this.globals.stopPointServiceTypeConstants['BOTH']:
              this.listen.push(this.translate.get('STOP_POINT.BOTH_INITIAL').subscribe((res: string) => { serviceTypeLabel = '(' + res + ')'; }));
              break;
          }

          customerId = null;
          if (row.customer) {
            customerId = row.customer.customer_identification_number;
          } else if (row.stop_point_identification_number) {
            customerId = row.stop_point_identification_number;
          }

          let collaboratorName = '-';
          if (row.collaborator) {
            if (row.collaborator && row.collaborator.collaboratorData) {
              collaboratorName = row.collaborator.collaboratorData.collaborator_name;
            }
          } else if (row.collaborator_name) {
            collaboratorName = row.collaborator_name;
          } else if (row.related_stop_point_id && row.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
            // find related stop point contact name which we'll use as collaborator name
            this.projectProblemDataService.stopPointsArray.forEach(relatedStopPoint => {
              if (relatedStopPoint.id == row.related_stop_point_id) {
                collaboratorName = relatedStopPoint.contact_name;
              }
            });
          } else if (row.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
            collaboratorName = row.contact_name;
          } else if (row.supplier) {
            collaboratorName = row.supplier;
          }

          // [food mode]: courier pickup data (collaborator w/ hyperlink + depot)
          let foodPickupData = {};
          if (this.globals.foodModeEnabled) {
            if (row.collaborator) {
              foodPickupData = {
                collaboratorId: row.collaborator.collaboratorData.id,
                collaboratorName: row.collaborator.collaboratorData.collaborator_name,
                depotName: foodDepotNameValue ? foodDepotNameValue : '-',
                collaboratorLink: '/customerCollaborators/collaboratorView/' + row.collaborator.collaboratorData.id
              };
            } else {
              foodPickupData = {
                collaboratorId: null,
                collaboratorName: '',
                depotName: foodDepotNameValue ? foodDepotNameValue : '-',
                collaboratorLink: ''
              };
            }
          } else {
            foodPickupData = {
              depotName: foodDepotNameValue ? foodDepotNameValue : '-',
            };
          }

          // cancel reason
          const canceledConstant = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];
          let cancelReasonValue;
          let descriptionValue;
          if (row.fulfillment_status == canceledConstant) {
            row.fulfillment_events.forEach(fulfillmentEvent => {
              if (Number(fulfillmentEvent.reason.toString().charAt(0)) == canceledConstant) {
                switch (fulfillmentEvent.reason) {
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].AGREED_SHIPPING:
                    cancelReasonValue = this.agreedShippingLabel;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].AGREED_SHIPPING_WRONG_ADDRESS:
                    cancelReasonValue = this.agreedShippingAndOrOtherAddressLabel;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].DID_NOT_ACCEPT_IT:
                    cancelReasonValue = this.didNotAcceptLabel;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].NOT_THERE:
                    cancelReasonValue = this.notThereLabel;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].PICKUP_FROM_DEPOT:
                    cancelReasonValue = this.pickupFromDepotLabel;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].WRONG_ADDRESS:
                    cancelReasonValue = this.wrongAddressLabel;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[canceledConstant].CUSTOM:
                    cancelReasonValue = this.otherReasonLabel;
                    break;
                  default:
                    cancelReasonValue = '-';
                }

                // description
                fulfillmentEvent.description ? descriptionValue = fulfillmentEvent.description : descriptionValue = '-';
              }
            })
          }

          // barcode
          let barcodeValue;
          if (row.service_type == this.globals.stopPointServiceTypeConstants['PICKUP'] && row.relatedStopPoint) {
            barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(row.relatedStopPoint);
          } else {
            barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(row);;
          }

          let alternativeBarcodeValue;
          if (row.alternative_barcode) {
            alternativeBarcodeValue = row.alternative_barcode;
          } else if (row.relatedStopPoint?.alternative_barcode) {
            alternativeBarcodeValue = row.relatedStopPoint.alternative_barcode;
          }

          // override fulfillment event w/ newer logic
          const fulfillmentValue = this.gridUtils.getFulfillmentStatus(row);

          // [food mode]: override fulfillment statuses in collaborator food w/ collaborator food fulfillments
          let isNewValue, isPendingValue;
          if (this.globals.foodModeEnabled && bothStopPoints['relatedStopPoint']) {
            if (this.globals.foodModeEnabled && this.globals.collaboratorModeEnabled) {
              // show NEW!/Pending status only in collaborator food
              if (bothStopPoints['stopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']].AT_TIME
                && bothStopPoints['stopPoint']['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['CREATED']) {
                fulfillmentValue['statusValue'] = this.newLabel;
                isNewValue = true;
              } else if (bothStopPoints['stopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']].AT_TIME
                && bothStopPoints['stopPoint']['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['CONFIRMATION_SENDED']) {
                fulfillmentValue['statusValue'] = this.pendingLabel;
                isPendingValue = true;
              }
            }
            else {
              if (bothStopPoints['stopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME) {
                fulfillmentValue['statusValue'] = this.completedLabel;
              }
              else if (bothStopPoints['stopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME) {
                fulfillmentValue['statusValue'] = this.arrivedLabel;
              }
              else if (bothStopPoints['relatedStopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME) {
                fulfillmentValue['statusValue'] = this.completedPickupFoodLabel;
              }
              else if (bothStopPoints['relatedStopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME) {
                fulfillmentValue['statusValue'] = this.arrivedLabel;
              }
              else if (bothStopPoints['relatedStopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_PICKUP
                || bothStopPoints['relatedStopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].AT_TIME) {
                fulfillmentValue['statusValue'] = this.inProgressPickupLabel;
              } else {
                fulfillmentValue['statusValue'] = this.inProgressPickupLabel;
              }
            }
          }

          // const driverName = driver.userProfile.name
          filteredStopPoints.push(id);
          const stopPointData = {
            id: id,
            name: {
              name: stopPointName,
              phone: stopPointPhone
            },
            pickup: pickupSpot,
            phone: String(stopPointPhone),
            address: this.addressService.getAddressLabel(row.address),
            time: time,
            estimatedTime: time.estimatedTime ?? '-',
            window: timeWindow,
            state: entityStatus,
            type: serviceTypeLabel,
            entityStatusConstant: row.entity_status,
            errorStatusConstant: row.error_status,
            payOnDelivery: payAmount,
            statusLabel: fulfillmentStatus,
            status: {
              // fulfillmentStatus: fulfillmentStatus,
              fulfillmentStatus: fulfillmentValue.statusValue,
              type: serviceTypeLabel,
              isNew: isNewValue,
              isPending: isPendingValue
            },
            driver: driverName,
            routeIndex: routeIndex,
            // vehicleType: vehicle.vehicle_type,
            barcode: barcodeValue ?? '-',
            alternativeBarcode: alternativeBarcodeValue ?? '-',
            serial: row.serial_number ? row.serial_number : '',
            partner: this.globals.partners[row.company_partner_id] ? this.globals.partners[row.company_partner_id].name : '-',
            collaborator: collaboratorName,
            fulfillmentStatus: row.fulfillment_status,
            sequence: sequence,
            customerId: customerId ? customerId : '',
            load: row.load ? row.load : '',
            notes: row.note ? row.note : '',
            cashPayAmount: '',
            cardPayAmount: '',
            canceledPayAmount: '',
            cashPayAmountCollaborator: '',
            cardPayAmountCollaborator: '',
            canceledPayAmountCollaborator: '',
            cancelReason: cancelReasonValue,
            description: descriptionValue,
            foodPickupData: foodPickupData,
            fullData: row
          };
          dataArray.push(stopPointData);
        }
      }
    });
    if (mapFilters) {
      this.viewProjectProblemService.filterStopPoints(filteredStopPoints, filteredRouteSettingIds);
    }

    return dataArray;
  }

  calculateTime(row) {
    const time = {
      time: '-',
      estimatedTime: '-',
      difference: null,
      arrivedTime: '-'
    };
    if (row.solution) {
      time.time = moment(row.solution.atTime).format('HH:mm');
      time.estimatedTime = moment(row.solution.latest_estimated_arrival_datetime).format('HH:mm');
      if (row.fulfillment_events) {
        const solutionTimeMoment = moment(row.solution.latest_estimated_arrival_datetime);
        row.fulfillment_events.forEach(event => {
          if (event.reason === 600) {
            const arrivedTimeMoment = moment(event.fulfillment_datetime);
            time.difference = Math.round(moment.duration(arrivedTimeMoment.diff(solutionTimeMoment)).asMinutes());
            time.arrivedTime = arrivedTimeMoment.format('HH:mm');
          }
        });
      }
    }
    return time;
  }

  calculateTimeWindow(timeWindows) {
    const timeWindowUnformatted = [];
    const timeWindowDouble = [];
    let timeWindowRange, timeWindowRangeMinutes;
    let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
    let timeWindowStartUnformatted, timeWindowEndUnformatted;
    timeWindows.forEach(currentTimeWindow => {
      timeWindowRange = currentTimeWindow.time_window_range;
      timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
      timeWindowStart = moment(currentTimeWindow.start, 'HH:mm:SS').format();
      timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
      timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
      timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
      timeWindowUnformatted.push(timeWindowStartUnformatted);
      timeWindowUnformatted.push(timeWindowEndUnformatted);
      timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
      timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();
    });
    let timeWindow = timeWindowUnformatted[0] + ' - ' + timeWindowUnformatted[1];
    if (timeWindowUnformatted[2]) { timeWindow += ' & ' + timeWindowUnformatted[2] + ' - ' + timeWindowUnformatted[3]; }
    return timeWindow;
  }

  removeGridFilters() {
    this.stopPointsDataArray2 = this.stopPointsDataArray2WithoutFilters;
    // this.rowData = this.stopPointsDataArray;
    // this.rowData2 = of(this.stopPointsDataArray2);
    // this.rowData2 = this.stopPointsDataArray2;
  }

  setActiveGrid(state) {
    const gridBeforeOptimize = document.getElementById('stop-points-before-optimize');
    const gridAfterOptimize = document.getElementById('stop-points-after-optimize');
    if (gridAfterOptimize && gridAfterOptimize) {
      if (state === this.globals.projectProblemOptimizationStateConstants['INITIALIZATION']) {
        this.activeGrid = 1;
        gridAfterOptimize.classList.add('hidden');
        gridBeforeOptimize.classList.remove('hidden');
      } else {
        this.activeGrid = 2;
        gridAfterOptimize.classList.remove('hidden');
        gridBeforeOptimize.classList.add('hidden');
      }
    }
  }

  filterDataByDriver() {
    const newDataArray = [];
    if (this.selectedDriverItem) {
      this.projectProblemDataService.selectedDriver = this.selectedDriverItem.driver;
      this.searchStopPoints(this.selectedDriverItem.driver.id);
    } else {
      this.projectProblemDataService.selectedDriver = null;
      this.searchStopPoints(null);
      this.filterActive = false;
      this.removeGridFilters();
    }
  }

  updateSelectDrivers() {
    let driverId;
    // empty drivers array because otherwise the options are not assigned to the dropdown for some twisted reason
    this.drivers = [];
    Object.keys(this.projectProblemDataService.routeSettingIdsToDrivers).forEach(routeSettingId => {
      driverId = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId].id;
      this.drivers.push(this.projectProblemDataService.drivers[driverId]);
    });
  }

  openRecurringGrid() {
    this.viewProjectProblemService.openAddStopsModal('recurring');
  }

  openWarehouseGrid() {
    this.viewProjectProblemService.openAddStopsModal('cancelled');
  }

  openRecipientsGrid() {
    this.viewProjectProblemService.openAddStopsModal('recipients');
  }

  openStopPointForm() {
    this.openStopPointFormListen.emit('open');
  }

  printVouchers(forUps = false) {
    const data = [], deliveryData = [];
    const stopPointsToPrint = [], stopPointIdsToUpdate = [];
    const deliveryStopPointIdsToUpdate = [];
    this.projectProblemDataService.stopPointsArray.forEach((stopPoint, i) => {
      if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
      if (stopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {
        if (stopPoint.voucher && !stopPointIdsToUpdate.includes(stopPoint.id)) {
          if (!stopPoint.voucher.is_printed) {
            if (stopPoint['service_type'] == this.globals.stopPointServiceTypeConstants['PICKUP']) {
              stopPoint['collaborator_name'] = this.stopPointUtils.getCollaboratorName(stopPoint);
          }
            stopPointsToPrint.push({ stopPoint: stopPoint });
            stopPointIdsToUpdate.push(stopPoint.id);
            const stopPointData = {
              stopPoint: {
                project_problem_id: stopPoint.project_problem_id ? stopPoint.project_problem_id : null,
                stopPointId: stopPoint['id'],
                voucher: {
                  options: {
                    is_printed: true
                  }
                }
              }
            };
            data.push(stopPointData);
          }
        } else if (stopPoint.relatedStopPoint) {
          if (stopPoint.relatedStopPoint.voucher) {
            if (!stopPoint.relatedStopPoint.voucher.is_printed) {
              stopPointsToPrint.push({
                'stopPoint': stopPoint.relatedStopPoint,
                'relatedStopPoint': stopPoint
              });
              deliveryStopPointIdsToUpdate.push(stopPoint.relatedStopPoint.id);
              const stopPointData = {
                stopPoint: {
                  stopPointId: stopPoint.relatedStopPoint['id'],
                  voucher: {
                    options: {
                      is_printed: true
                    }
                  }
                }
              };
              data.push(stopPointData);
            }
          }
        }
      }
    });
    if (stopPointsToPrint.length) {
      if (forUps) {
        this.stopPointUtils.printImageVouchers(stopPointsToPrint);
      } else {
        this.stopPointUtils.printVouchers(stopPointsToPrint);
      }
      this.http.put('api/v1/stop-points', { stopPoints: data }).pipe(take(1)).subscribe(response => {
        this.projectProblemDataService.updateStopPointsAddData(response['items'], true);
      });
    }
  }

  onBtExport() {
    // show all stop points in grid (if not driver is chosen)
    if (!this.selectedDriverItem) {
      this.setStopPointsModalGridData(null, true);
    }

    if (this.projectProblemDataService.projectProblemData['entity_status'] === this.globals.projectProblemEntityStatusConstants['COMPLETED']) {
      // sort by status
      this.sortByStatusAsc();
    }
    let gridApi;
    if (this.activeGrid === 1) {
      gridApi = this.gridApi;
    } else {
      gridApi = this.gridApi2;
    }

    // wait until grid is ready to show data after sorting
    const dataRefreshIntervalId = setInterval(() => {
      const rowNode0 = gridApi.getDisplayedRowAtIndex(0);
      const nodeData = rowNode0.data;
      if (nodeData) {
        clearInterval(dataRefreshIntervalId);

        // add amounts to first
        const amounts = this.amounts;
        nodeData['cardPayAmount'] = amounts['cardPayAmount'];
        nodeData['cashPayAmount'] = amounts['cashPayAmount'];
        nodeData['canceledPayAmount'] = amounts['canceledPayAmount'];
        if (amounts['collaboratorAmounts']) {
          let cardPayAmount = '', cashPayAmount = '', canceledPayAmount = '';
          Object.keys(amounts['collaboratorAmounts']).forEach(collaboratorName => {
            cardPayAmount += collaboratorName + ': ' + amounts['collaboratorAmounts'][collaboratorName]['cardPayAmount'] + ', ';
            cashPayAmount += collaboratorName + ': ' + amounts['collaboratorAmounts'][collaboratorName]['cashPayAmount'] + ', ';
            canceledPayAmount += collaboratorName + ': ' + amounts['collaboratorAmounts'][collaboratorName]['canceledPayAmount'] + ', ';
          });
          cardPayAmount = cardPayAmount.substring(0, cardPayAmount.length - 2);
          cashPayAmount = cashPayAmount.substring(0, cashPayAmount.length - 2);
          canceledPayAmount = canceledPayAmount.substring(0, canceledPayAmount.length - 2);
          nodeData['cardPayAmountCollaborator'] = cardPayAmount;
          nodeData['cashPayAmountCollaborator'] = cashPayAmount;
          nodeData['canceledPayAmountCollaborator'] = canceledPayAmount;
        }

        rowNode0.setData(nodeData);

        this.exportToXls();

        // show only first batch in grid
        this.setStopPointsModalGridData(this.currentData);
      }
    }, 200);
  }

  exportToXls() {
    const excelParams = {
      processCellCallback: formattingFunction,
      allColumns: true,
      fileName: this.genericUtils.removeDotsFromString('lastmily_' + moment(this.projectProblemDepartureDatetime).format('YYYY-MM-DD'))
    };
    let gridApi;
    if (this.activeGrid === 1) {
      gridApi = this.gridApi;
    } else {
      gridApi = this.gridApi2;
    }
    function formattingFunction(params) {
      if (params.value) {
        if (params.value.name) {
          return params.value.name;
        } else if (params.value.fulfillmentStatus && params.value.type) {
          return params.value.fulfillmentStatus + ' ' + params.value.type;
        } else if (params.value.time) {
          let timeString = params.value.time;
          if (params.value.difference !== null) {
            timeString += ' ' + params.value.difference;
          }
          return timeString;
        } else {
          return params.value;
        }
      }
    }
    gridApi.exportDataAsExcel(excelParams);
  }

  foodPickupRenderer(params) {
    let columnObject = '';
    const value = params.value ? params.value : params.getValue();
    if (value) {
      // collaborator food
      if (this.globals.collaboratorModeEnabled) {
        columnObject += '<div class="double-cell standard-width">' + value.depotName + '</div>'
        columnObject += '<div class="double-cell standard-width">' + '<a style="text-decoration: underline;" target="blank_" href="' + value.collaboratorLink + '">' + value.collaboratorName + '</a>' + '</div>';
      }
      // courier food
      else {
        columnObject += '<div class="double-cell standard-width">' + value.depotName + '</div>';
        columnObject += '<div class="double-cell standard-width">' + '<a style="text-decoration: underline;" target="blank_" href="' + value.collaboratorLink + '">' + value.collaboratorName + '</a>' + '</div>';
      }
    }
    return columnObject;
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.RECIPIENT').subscribe((res: string) => { this.recipientTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.PHONE').subscribe((res: string) => { this.phoneTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DATE').subscribe((res: string) => { this.dateTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.TYPE').subscribe((res: string) => { this.typeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.COMPLETION_TIME').subscribe((res: string) => { this.timeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.WINDOW').subscribe((res: string) => { this.windowTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.BARCODE').subscribe((res: string) => { this.barcodeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ALTERNATIVE_BARCODE').subscribe((res: string) => { this.alternativeBarcodeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.SERIAL').subscribe((res: string) => { this.serialTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.PARTNER').subscribe((res: string) => { this.partnerTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.PICKUP').subscribe((res: string) => { this.pickupTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.LOAD').subscribe((res: string) => { this.loadTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.NOTES').subscribe((res: string) => { this.notesTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.CANCEL_REASON').subscribe((res: string) => { this.cancelReasonTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DESCRIPTION').subscribe((res: string) => { this.descriptionTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.CUSTOMER_ID').subscribe((res: string) => { this.customerIdTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_CASH').subscribe((res: string) => { this.cashPayAmountTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_CARD').subscribe((res: string) => { this.cardPayAmountTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_CANCELED').subscribe((res: string) => { this.canceledPayAmountTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_CASH_COLLABORATOR').subscribe((res: string) => { this.cashPayAmountCollaboratorTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_CARD_COLLABORATOR').subscribe((res: string) => { this.cardPayAmountCollaboratorTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_CANCELED_COLLABORATOR').subscribe((res: string) => { this.canceledPayAmountCollaboratorTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.COLLABORATOR').subscribe((res: string) => { this.collaboratorTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SEQUENCE').subscribe((res: string) => { this.sequenceTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.DRIVER').subscribe((res: string) => { this.driverTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ROUTING_TIME').subscribe((res: string) => { this.routingTimeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currencyTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));

    this.listen.push(this.translate.get('CANCEL.CANCEL_NOT_THERE_MSG').subscribe((res: string) => {
      this.notThereLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_WRONG_ADDRESS_MSG').subscribe((res: string) => {
      this.wrongAddressLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_DID_NOT_ACCEPT_MSG').subscribe((res: string) => {
      this.didNotAcceptLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_PICKUP_FROM_DEPOT_MSG').subscribe((res: string) => {
      this.pickupFromDepotLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_AGREED_SHIPPING_MSG').subscribe((res: string) => {
      this.agreedShippingLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.AGREED_SHIPPING_AND_OR_WRONG_ADDRESS_MSG').subscribe((res: string) => {
      this.agreedShippingAndOrOtherAddressLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_OTHER_REASON').subscribe((res: string) => {
      this.otherReasonLabel = res;
    }));
    // this.translate.get('CANCEL.PENDING').subscribe((res: string) => {
    //   this.pendingLabel = res;
    // });
    // this.translate.get('CANCEL.NOT_ROUTED').subscribe((res: string) => {
    //   this.nonRoutedLabel = res;
    // });

    // [food mode]: override pickup labels
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_PICKUP').subscribe((res: string) => {
      this.inProgressPickupLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.ARRIVED').subscribe((res: string) => {
      this.arrivedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.completedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED_PICKUP_FOOD').subscribe((res: string) => {
      this.completedPickupFoodLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.NEW').subscribe((res: string) => {
      this.newLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.PENDING').subscribe((res: string) => {
      this.pendingLabel = res;
    }));

    const addressClassRules = {
      'medium-red-letters': this.redLettersClass.bind(this)
    };

    if (this.globals.foodModeEnabled) {
      this.columnDefs2 = [
        {
          headerName: this.sequenceTitle,
          field: 'sequence',
          sortable: false,
          cellStyle: this.cellStyling.bind(this),
          cellClass: 'sequence-cell',
          width: this.gridsService.widthCalculatorGrid(0.5)
        },
        {
          headerName: this.barcodeTitle,
          field: 'barcode',
          hide: true,
        },
        {
          headerName: this.alternativeBarcodeTitle,
          field: 'alternativeBarcode',
          hide: true,
        },
        {
          headerName: this.serialTitle,
          field: 'serial',
          hide: true,
        },
        {
          headerName: this.customerIdTitle,
          field: 'customerId',
          hide: true,
        },
        {
          headerName: this.collaboratorTitle,
          field: 'collaborator',
          hide: true,
        },
        {
          headerName: this.recipientTitle,
          field: 'name',
          sortable: false,
          filter: true,
          checkboxSelection: false,
          cellRenderer: this.gridsService.nameRenderer,
          width: this.gridsService.widthCalculatorGrid(20)
        },
        {
          headerName: this.addressTitle,
          field: 'address',
          sortable: false,
          filter: true,
          cellRenderer: this.gridsService.addressRenderer,
          cellClassRules: addressClassRules,
          width: this.gridsService.widthCalculatorGrid(30.5)
        },
        {
          headerName: this.pickupTitle,
          field: 'foodPickupData',
          sortable: false,
          filter: true,
          checkboxSelection: false,
          cellRenderer: this.foodPickupRenderer.bind(this),
          width: this.gridsService.widthCalculatorGrid(20),
        },
        {
          headerName: this.driverTitle,
          field: 'driver',
          sortable: false,
          filter: true,
          checkboxSelection: false,
          // cellRenderer: this.driverRenderer,
          width: this.gridsService.widthCalculatorGrid(12),
          hide: this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled // hide in collaborator food mode
        },
        {
          headerName: this.partnerTitle,
          field: 'partner',
          sortable: false,
          filter: true,
          checkboxSelection: false,
          // cellRenderer: this.driverRenderer,
          width: this.gridsService.widthCalculatorGrid(12),
          hide: !(this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) // show in collaborator food mode
        },
        {
          headerName: this.phoneTitle,
          field: 'phone',
          hide: true
        },
        {
          headerName: this.payOnDeliveryTitle,
          field: 'payOnDelivery',
          hide: true,
        },
        {
          headerName: this.loadTitle,
          field: 'load',
          hide: true,
        },
        {
          headerName: this.routingTimeTitle,
          field: 'completionTime',
          hide: true
        },
        {
          headerName: this.statusTitle,
          field: 'status',
          sortable: true,
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRenderer: this.statusTimeRenderer,
          width: this.gridsService.widthCalculatorGrid(17)
        },
        {
          headerName: this.notesTitle,
          field: 'notes',
          hide: true,
        },
        {
          headerName: this.cashPayAmountTitle,
          field: 'cashPayAmount',
          hide: true,
        },
        {
          headerName: this.cardPayAmountTitle,
          field: 'cardPayAmount',
          hide: true,
        },
        {
          headerName: this.canceledPayAmountTitle,
          field: 'canceledPayAmount',
          hide: true,
        },
        {
          headerName: this.cashPayAmountCollaboratorTitle,
          field: 'cashPayAmountCollaborator',
          hide: true,
        },
        {
          headerName: this.cardPayAmountCollaboratorTitle,
          field: 'cardPayAmountCollaborator',
          hide: true,
        },
        {
          headerName: this.canceledPayAmountCollaboratorTitle,
          field: 'canceledPayAmountCollaborator',
          hide: true,
        },
      ];
      this.columnDefs = this.columnDefs2;
    } else {
      this.columnDefs = [
        {
          headerName: this.barcodeTitle,
          field: 'barcode',
          hide: true,
        },
        {
          headerName: this.alternativeBarcodeTitle,
          field: 'alternativeBarcode',
          hide: true,
        },
        {
          headerName: this.serialTitle,
          field: 'serial',
          hide: true,
        },
        {
          headerName: this.customerIdTitle,
          field: 'customerId',
          hide: true,
        },
        {
          headerName: this.collaboratorTitle,
          field: 'collaborator',
          hide: true,
        },
        {
          headerName: this.recipientTitle,
          field: 'name',
          sortable: false,
          filter: true,
          checkboxSelection: false,
          cellRenderer: this.gridsService.nameRenderer,
          width: this.gridsService.widthCalculatorGrid(37)
        },
        {
          headerName: this.addressTitle,
          field: 'address',
          sortable: false,
          filter: true,
          cellRenderer: this.gridsService.addressRenderer,
          cellClassRules: addressClassRules,
          width: this.gridsService.widthCalculatorGrid(39)
        },
        {
          headerName: this.phoneTitle,
          field: 'phone',
          hide: true,
        },
        {
          headerName: this.payOnDeliveryTitle,
          field: 'payOnDelivery',
          hide: true,
        },
        {
          headerName: this.loadTitle,
          field: 'load',
          hide: true,
        },
        {
          headerName: this.windowTitle,
          field: 'window',
          sortable: false,
          cellRenderer: this.windowRenderer,
          width: this.gridsService.widthCalculatorGrid(14)
        },
        {
          headerName: this.typeTitle,
          field: 'type',
          sortable: false,
          width: this.gridsService.widthCalculatorGrid(10)
        },
        {
          headerName: this.statusTitle,
          field: 'statusLabel',
          hide: true,
        },
        {
          headerName: this.notesTitle,
          field: 'notes',
          hide: true,
        },
        {
          headerName: this.cancelReasonTitle,
          field: 'cancelReason',
          hide: true,
        },
        {
          headerName: this.descriptionTitle,
          field: 'description',
          hide: true,
        },
        {
          headerName: this.cashPayAmountTitle,
          field: 'cashPayAmount',
          hide: true,
        },
        {
          headerName: this.cardPayAmountTitle,
          field: 'cardPayAmount',
          hide: true,
        },
        {
          headerName: this.canceledPayAmountTitle,
          field: 'canceledPayAmount',
          hide: true,
        },
        {
          headerName: this.cashPayAmountCollaboratorTitle,
          field: 'cashPayAmountCollaborator',
          hide: true,
        },
        {
          headerName: this.cardPayAmountCollaboratorTitle,
          field: 'cardPayAmountCollaborator',
          hide: true,
        },
        {
          headerName: this.canceledPayAmountCollaboratorTitle,
          field: 'canceledPayAmountCollaborator',
          hide: true,
        },
      ];
      this.columnDefs2 = [
        {
          headerName: this.sequenceTitle,
          field: 'sequence',
          sortable: false,
          cellStyle: this.cellStyling.bind(this),
          cellClass: 'sequence-cell',
          width: this.gridsService.widthCalculatorGrid(0.5)
        },
        {
          headerName: this.driverTitle,
          field: 'driver',
          hide: true,
        },
        {
          headerName: this.barcodeTitle,
          field: 'barcode',
          hide: true,
        },
        {
          headerName: this.alternativeBarcodeTitle,
          field: 'alternativeBarcode',
          hide: true,
        },
        {
          headerName: this.serialTitle,
          field: 'serial',
          hide: true,
        },
        {
          headerName: this.customerIdTitle,
          field: 'customerId',
          hide: true,
        },
        {
          headerName: this.collaboratorTitle,
          field: 'collaborator',
          hide: true,
        },
        {
          headerName: this.nameTitle,
          field: 'name',
          sortable: false,
          filter: true,
          checkboxSelection: false,
          cellRenderer: this.gridsService.nameRenderer,
          width: this.gridsService.widthCalculatorGrid(36)
        },
        {
          headerName: this.addressTitle,
          field: 'address',
          sortable: false,
          filter: true,
          cellRenderer: this.gridsService.addressRenderer,
          cellClassRules: addressClassRules,
          width: this.gridsService.widthCalculatorGrid(37.5)
        },
        {
          headerName: this.phoneTitle,
          field: 'phone',
          hide: true
        },
        {
          headerName: this.payOnDeliveryTitle,
          field: 'payOnDelivery',
          hide: true,
        },
        {
          headerName: this.loadTitle,
          field: 'load',
          hide: true,
        },
        {
          headerName: this.timeTitle,
          field: 'time',
          sortable: true,
          width: this.gridsService.widthCalculatorGrid(11),
          headerClass: 'text-center',
          cellClass: ' text-center',
          cellRenderer: this.timeRenderer
        },
        {
          headerName: this.routingTimeTitle,
          field: 'estimatedTime',
          hide: true
        },
        {
          headerName: this.statusTitle,
          field: 'status',
          sortable: true,
          headerClass: 'text-center',
          cellClass: ' text-center',
          cellRenderer: this.statusRenderer,
          width: this.gridsService.widthCalculatorGrid(15)
        },
        {
          headerName: this.notesTitle,
          field: 'notes',
          hide: true,
        },
        {
          headerName: this.cancelReasonTitle,
          field: 'cancelReason',
          hide: true,
        },
        {
          headerName: this.descriptionTitle,
          field: 'description',
          hide: true,
        },
        {
          headerName: this.cashPayAmountTitle,
          field: 'cashPayAmount',
          hide: true,
        },
        {
          headerName: this.cardPayAmountTitle,
          field: 'cardPayAmount',
          hide: true,
        },
        {
          headerName: this.canceledPayAmountTitle,
          field: 'canceledPayAmount',
          hide: true,
        },
        {
          headerName: this.cashPayAmountCollaboratorTitle,
          field: 'cashPayAmountCollaborator',
          hide: true,
        },
        {
          headerName: this.cardPayAmountCollaboratorTitle,
          field: 'cardPayAmountCollaborator',
          hide: true,
        },
        {
          headerName: this.canceledPayAmountCollaboratorTitle,
          field: 'canceledPayAmountCollaborator',
          hide: true,
        },
      ];
    }
    this.rowClassRules = {
      'grey-letters': this.greyLettersClass.bind(this),
    };
  }

  ngOnInit(): void {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    const dataRefreshIntervalId = setInterval(() => {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        this.updateSelectDrivers();
      }
    }, 200);
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
