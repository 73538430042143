import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import { DynamicFieldService } from '@app/services/dynamic-field.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-packaging-modal',
    templateUrl: './packaging-modal.component.html',
    styleUrls: ['./packaging-modal.component.scss']
})
export class PackagingModalComponent implements OnInit, OnDestroy {

    listen = [];
    isClickedOnce = false;

    name = '';
    defaultDynamicFields = [];
    dynamicFields = [];
    extraDynamicFields = [];
    rule = null;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        private dynamicFieldService: DynamicFieldService,
    ) {
        this.listen.push(this.settingsService.openPackagingModalListen().subscribe(() => {
            this.openModal();
        }));
    }

    addNewField() {
        this.extraDynamicFields.push({
            label: '',
            value: ''
        });
    }

    loadDynamicFields() {
        this.http.get('api/v1/import-dynamic-fields').subscribe(response => {
            response['items'].forEach(field => {
                this.dynamicFields.push({
                    id: field['importDynamicField']['id'],
                    name: field['importDynamicField']['identifier'],
                    label: field['importDynamicField']['label'],
                    value: ''
                });
            });
            this.defaultDynamicFields = this.dynamicFields;
        });
    }

    loadDynamicField(data) {
        this.rule = data.importRule;
        data.importRule.rule.trigger.expression.operands.forEach(operand => {
            if (operand.order === 0) {
                this.name = operand.value;
            }
        });
        const dynamicFieldNameToValue = {};
        this.dynamicFields = [];
        data.importRule.rule.actions[0].expression.operands.forEach(actionOperand => {
            let name = '', value = '', id = null;
            actionOperand.expression.operands.forEach(operand => {
                if (operand.order === 0) {
                    value = operand.value;
                } else {
                    name = operand.value;
                    id = operand.import_dynamic_field_id;
                }
            });
            dynamicFieldNameToValue[name] = value;
            this.dynamicFields.push({
                id: id,
                name: name,
                label: this.globals.stopPointImportFieldConfigs?.name?.label,
                value: value
            });
        });
        if (this.dynamicFields.length !== this.defaultDynamicFields.length) {
            this.defaultDynamicFields.forEach(defaultField => {
                let found = false;
                this.dynamicFields.forEach(field => {
                    if (field.id === defaultField.id) {
                        found = true;
                    }
                });
                if (!found) {
                    this.dynamicFields.push(defaultField);
                }
            });
        }
        setTimeout(() => {
            M.updateTextFields();
        }, 100);
        this.openModal();
    }

    openModal() {
        const modal = document.querySelector('#packaging-modal');
        const modalBackground = document.querySelector('#packaging-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#packaging-modal');
        const modalBackground = document.querySelector('#packaging-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.resetPackagingModal();
    }

    resetPackagingModal() {
        this.name = '';
        this.rule = null;
        this.dynamicFields = this.defaultDynamicFields;
        this.extraDynamicFields = [];
        this.isClickedOnce = false;
    }

    submitDynamicField(label) {
        const data = {
            importDynamicField: {
                label: label,
                default_value: 0,
                type: this.globals.ruleValueTypeConstants['VOLUME']
            }
        };
        return this.http.post('api/v1/import-dynamic-fields', data);
    }

    submitPackaging() {
        this.isClickedOnce = true;
        const self = this;
        if (this.extraDynamicFields.length) {
            const pendingDone = [];
            this.extraDynamicFields.forEach(function (field, index) {
                pendingDone.push(false);
                self.submitDynamicField(field.label).pipe(take(1)).subscribe(
                    response => {
                        pendingDone[index] = true;
                        self.dynamicFields.push(
                            {
                                id: response['item']['importDynamicField']['id'],
                                name: response['item']['importDynamicField']['identifier'],
                                label: field.label,
                                value: field.value
                            }
                        );
                        if (pendingDone.every(Boolean)) {
                            self.settingsService.updateDynamicFieldsGrid();
                            self.savePackaging();
                        }
                    },
                    error => {
                        self.isClickedOnce = false;
                    }
                );

            });
        } else {
            this.savePackaging();
        }
    }

    savePackaging() {
        const myObserver = {
            next: (response) => {
                this.settingsService.updateDynamicFieldsGrid();
            },
            error: (error) => {
                console.error(error);
                this.isClickedOnce = false;
            },
            complete: () => {
                this.isClickedOnce = false;
                this.closeModal();
            },
        };
        this.dynamicFieldService.submitPackagingRule(this.name, this.dynamicFields, this.rule).subscribe(myObserver);
    }

    ngOnInit() {
        this.loadDynamicFields();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
