import { Component, AfterContentInit, ElementRef, OnInit, ViewChild, OnDestroy, EventEmitter, Injector, ViewContainerRef, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StepsService } from '@app/services/steps.service';
import { PreloadStopPointsService } from '@app/services/preload-stop-points.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ActivatedRoute } from '@angular/router';
// import { Map2Component } from '@app/map2/map.component';
import { MapComponent } from '@app/map/map.component';
import { StopModalComponent } from '@app/modals/stop-modal/stop-modal.component';
import { StopFormModalComponent } from '@app/modals/stop-form-modal/stop-form-modal.component';
import { HelperModalComponent } from '@app/modals/helper-modal/helper-modal.component';
import { ImporterModalComponent } from '@app/modals/importer-modal/importer-modal.component';
import { MoveStopsModalComponent } from '@app/modals/move-stops-modal/move-stops-modal.component';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AddStopModalComponent } from '@app/modals/add-stop-modal/add-stop-modal.component';
import { ProjectProblemModalComponent } from '@app/modals/project-problem-modal/project-problem-modal.component';
import { MilyService } from '@app/services/mily.service';
import { MilyComponent } from '@app/mily/mily.component';
import { ColourService } from '@app/services/colour.service';
import { ProjectViewGuidedTourComponent } from './project-view-guided-tour/project-view-guided-tour.component';
import { FirstOptimizationModalComponent } from './first-optimization-modal/first-optimization-modal.component';
import { Router } from '@angular/router';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { LoadStopsModalComponent } from '@app/modals/load-stops-modal/load-stops-modal.component';
import { DriverReportModalComponent } from '@app/modals/driver-report-modal/driver-report-modal.component';
import { ProjectProblemReportModalComponent } from '@app/modals/project-problem-report-modal/project-problem-report-modal.component';
import { PortalIssuesModalComponent } from '@app/modals/portal-issues-modal/portal-issues-modal.component';
import { VoucherFormModalComponent } from '@app/modals/voucher-form-modal/voucher-form-modal.component';
import { ProjectProblemViewUtils } from '@app/utils/project-problem-view-utils';
import { take, tap } from 'rxjs/operators';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { CookieService } from 'ngx-cookie-service';
import { ImporterService } from '@app/services/importer.service';
import * as Sentry from "@sentry/angular";
import { ImportUtils } from '@app/utils/import-utils';
import { ProjectProblemRoutingSettingsModalComponent } from './project-problem-routing-settings-modal/project-problem-routing-settings-modal.component';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmProjectProblemRoutingSettingsService } from "@app/api/services/project-problem-routing-settings.service";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent implements AfterContentInit, OnInit, OnDestroy {
  routingSettingsModal = ProjectProblemRoutingSettingsModalComponent;

  @ViewChild('fileInput', { read: ElementRef, static: false }) fileInput: ElementRef;
  @ViewChild('routingSettingsButton') routingSettingsButton: ElementRef;
  @ViewChild(AddStopModalComponent, { static: false }) addStopModalComponent: AddStopModalComponent;
  // @ViewChild(Map2Component, { static: false }) mapComponent: Map2Component;
  @ViewChild(MapComponent, { static: false }) mapComponent: MapComponent;
  @ViewChild(StopModalComponent, { static: false }) stopModalComponent: StopModalComponent;
  @ViewChild(StopFormModalComponent, { static: false }) stopFormModalComponent: StopFormModalComponent;
  @ViewChild(VoucherFormModalComponent, { static: false }) voucherFormModalComponent: VoucherFormModalComponent;
  @ViewChild(HelperModalComponent, { static: false }) helperModalComponent: HelperModalComponent;
  @ViewChild(ImporterModalComponent, { static: false }) importerModalComponent: ImporterModalComponent;
  @ViewChild(MoveStopsModalComponent, { static: false }) moveStopsModalComponent: MoveStopsModalComponent;
  @ViewChild(LoadStopsModalComponent, { static: false }) loadStopsModalComponent: LoadStopsModalComponent;
  @ViewChild(MilyComponent, { static: false }) milyComponent: MilyComponent;
  @ViewChild(ProjectViewGuidedTourComponent, { static: false }) projectViewGuidedTourComponent: ProjectViewGuidedTourComponent;
  @ViewChild(FirstOptimizationModalComponent, { static: false }) firstOptimizationModalComponent: FirstOptimizationModalComponent;
  @ViewChild(ProjectProblemReportModalComponent, { static: false }) projectProblemReportModalComponent: ProjectProblemReportModalComponent;
  @ViewChild(DriverReportModalComponent, { static: false }) driverReportModalComponent: DriverReportModalComponent;
  @ViewChild(PortalIssuesModalComponent, { static: false }) portalIssuesModalComponent: PortalIssuesModalComponent;
  @ViewChild(ProjectProblemModalComponent, { static: false }) projectProblemModalComponent: ProjectProblemModalComponent;

  modal;
  listen = [];
  intervals = [];
  showOldRoute = false;
  oldRouteSwitchVisible = false;
  inDemo = false;
  uploadMessageShown = false;
  tourMessageShown = false;
  isAutoGenerated = false;

  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  projectId = null;
  projectProblemId = null;
  projectDepotId;
  projectProblemDepartureDatetime;
  projectProblemDayOfWeek;
  optimizationMode = 'without';
  keepModifications = false;
  lockModifications = false;
  departureTimeEdit = false;
  departureDateEdit = false;

  problemDepartureDate;
  problemDepartureTime;
  defaultTimeWindow = [];
  averageWeight = 0;
  maximumWeight = 0;
  problemTitle = '';
  defaultProblemTitle = '';
  returnTitle: string;
  optimizationState = null;
  optimizationStateModified = '';
  isModified = false;
  entityStatus;
  optimizationStateLabel = '';
  stopPointsCount;
  completedStopPointsCount;
  completedAndCancelledStopPointsCount = 0;
  limitedTimeWindowStopsCount = 0;
  droppedStopsCount = 0;
  routeEndTime = '';
  currentRouteDurationHours;
  currentRouteDuration;
  routeDuration;
  routeDurationHours;
  routeDurationSum;
  routeDurationSumHours;
  totalRouteDistance;
  currentRoutesDistance;
  optimizationLogExtraInfo = '';
  lastLoadedOptimization = null;
  allStopPointsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points';
  updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
  issuesUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/issues';
  optimizeUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/optimize';
  dispatchUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/dispatch';
  solutionUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/solution';
  routeSettingsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/route-settings';
  loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';
  manualModifiedRouteUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/manual-modified-route-items';
  directManualModifiedRouteUrl = 'api/internal/v1/project/problems/PROJECT_PROBLEM_ID/manual-modified-exact-sequence-items';
  updateStopPointsUrl = 'api/v1/stop-points';
  warehouseUrl = 'api/v1/company/warehouse';
  optimizeButtonVisible = false;
  reOptimizeButtonVisible = false;
  dispatchButtonVisible = false;
  optimizationStates = this.globals.projectProblemOptimizationStateConstants;
  // lastId = 0;
  lastIdOldRoute = 0;
  vehicleIds = [];
  vehicleSetting = {};
  vehicleIdPlateNumber = {};
  vehicleData = {};
  trackRequestDone = true;
  stopPointsToUnModify = [];
  updateStopPointIds = [];
  trackOldRouteRequestDone = {};
  dispatchStopPointsCondition = false;
  milyOpenedForImporterErrorStops = false;
  milyOpenedForCanceledErrorStops = false;

  mergeAlert;
  sequenceAlert;
  deleteAlert;
  noDemoAlert;
  stopsChangedInPortal;
  optimizeAlerts = {};
  optimizeLabel;
  reOptimizeLabel;
  reDispatchLabel;
  dispatchLabel;
  optimizeButtonName;
  dispatchButtonName;
  inProgressLabel = '';
  projectProblemInProgressLabel = '';
  completedLabel = '';
  errorLabel = '';
  cancelReasonUnableToDeliverLabel = '';
  thinkButtonFindMsg = '';
  loadingStopsMsg = '';
  hoursShortMsg = '';
  minutesShortMsg = '';
  newStopsFormDriversMsg = '';
  newStopsFoodMsg = '';
  alreadyOptimizingAlertMessage = '';
  routingSettingsTranslations;

  deleteAlertSubscription = null;
  deleteSameDayAlertSubscription = null;
  mergeAlertSubscription = null;
  cancelAlertSubscription = null;
  cancelMultipleStopPointsAlertSubscription = null;

  stopPointIdsWithIssuesUpdated = [];
  relatedPartnerCompanyDepotsAddressesInMapById = {};
  routingSettings;

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;

  // intervals
  getIssuesInterval;
  getStopPointsFromDriversInterval;
  getNewShipmentsInterval;
  autoGetNewOptimizationsInterval;
  autoUpdateStopsFulfillmentStatusInterval;
  autoRefreshInterval;
  trackVehiclesInterval;
  trackVehiclesOldRouteInterval = [];
  checkForFinishedOptimizationInterval;

  constructor(
    public translate: TranslateService,
    public http: HttpClient,
    private elRef: ElementRef,
    private stepsService: StepsService,
    public preloadStopPointsService: PreloadStopPointsService,
    public projectProblemDataService: ProjectProblemDataService,
    private viewProjectProblemService: ViewProjectProblemService,
    private activatedRoute: ActivatedRoute,
    public globals: Globals,
    private milyService: MilyService,
    private colourService: ColourService,
    public router: Router,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private projectProblemViewUtils: ProjectProblemViewUtils,
    private cookieService: CookieService,
    private importerService: ImporterService,
    private importUtils: ImportUtils,
    private notificationSvc: LmNotificationService, 
    private _injector: Injector,
    private _vcr: ViewContainerRef,
    private routingSettingsService: LmProjectProblemRoutingSettingsService,
    private renderer: Renderer2
  ) {
    
    this.options = { concurrency: 1 };
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.files = []; // local uploading files array
    // unused for now
    this.listen.push(this.viewProjectProblemService.displayStopPointsToMapListen().subscribe((response) => {
      if (Array.isArray(response)) {
        this.relatedPartnerCompanyDepotsAddressesInMapById = {};
        if (response.length > 1) {
          response.forEach(item => {
            this.loadStopToMap(item, true);
          });
          // this.mapComponent.zoomOutGroup.setVisibility(false);
          this.mapComponent.addAllStopsToZoomLevels();
          this.mapComponent.showLevelsStopPoints();
          // this.mapComponent.checkWhenDoneRendering();
          if (!this.mapComponent.drawModeEnabled && !this.mapComponent.selectModeEnabled) {
            this.mapComponent.checkWhenDoneRendering();
          }
        } else {
          response.forEach(item => {
            this.loadStopToMap(item, false);
          });
        }
        if (this.mapComponent.disableSelectModeAfterUpdate) {
          this.mapComponent.disableSelectMode();
          this.mapComponent.disableSelectModeAfterUpdate = false;
        }
        this.calculateAndDisplayCollaboratorAndPartnerDepotsOnMap();
      }
    }));
    this.listen.push(this.viewProjectProblemService.updateProjectProblemListen().subscribe(response => {
      this.updateProjectProblem();
    }));
    this.listen.push(this.viewProjectProblemService.updateProjectProblemStatusListen().subscribe(response => {
      this.updateProjectProblemStatus();
    }));
    this.listen.push(this.viewProjectProblemService.updateProjectProblemRouteSettingsListen().subscribe(response => {
      this.updateProjectProblemRouteSettings();
    }));
    // unused for now
    // this.listen.push(this.viewProjectProblemService.addStopPointToMapListen().subscribe(response => {
    //   this.mapComponent.addStopPoint(response);
    // }));
    this.listen.push(this.viewProjectProblemService.removeStopPointFromMapListen().subscribe(id => {
      // TODO remove the collaborator/partner depot icon if there are no other SPs in the depot
      this.mapComponent.removeStopPoint(id);
    }));
    this.listen.push(this.viewProjectProblemService.removeStopPointFromClusterListen().subscribe(data => {
      this.mapComponent.removeStopPointFromClusterCenter(data.stopPointId, data.zoomLevel, data.centerId);
    }));
    this.listen.push(this.viewProjectProblemService.changeStopPointEntityStatusListen().subscribe(response => {
      this.changeStopPointEntityStatus(response.id, response.enabled);
    }));
    this.listen.push(this.viewProjectProblemService.returnStopPointsListen().subscribe(ids => {
      this.returnStopPoints(ids);
    }));
    this.listen.push(this.viewProjectProblemService.changeMultipleStopPointsEntityStatusListen().subscribe(response => {
      this.changeMultipleStopPointsEntityStatus(response.ids, response.enabled);
    }));
    this.listen.push(this.viewProjectProblemService.freeStopPointTimeWindowListen().subscribe(id => {
      this.freeStopPointTimeWindow(id);
    }));
    this.listen.push(this.viewProjectProblemService.freeMultipleStopPointsTimeWindowListen().subscribe(ids => {
      this.freeMultipleStopPointsTimeWindow(ids);
    }));
    this.listen.push(this.viewProjectProblemService.unModifyStopPointsListen().subscribe(stopPointIds => {
      this.stopPointsToUnModify = stopPointIds;
      this.unModifyStopPoints();
    }));
    this.listen.push(this.viewProjectProblemService.cancelStopPointsListen().subscribe(stopPointIds => {
      this.cancelStopPoints(stopPointIds);
    }));
    this.listen.push(this.viewProjectProblemService.deleteStopPointsListen().subscribe(stopPointIds => {
      this.deleteStopPoints(stopPointIds);
    }));
    this.listen.push(this.viewProjectProblemService.mergeStopPointsListen().subscribe(data => {
      this.mergeStopPoints(data.stopPointIds, data.targetStopPointId);
    }));
    this.listen.push(this.viewProjectProblemService.sequenceStopPointsListen().subscribe(data => {
      this.sequenceStopPoints(data.stopPointIds, data.targetStopPointId, data.mode);
    }));
    this.listen.push(this.viewProjectProblemService.createVehicleLocationListen().subscribe(coords => {
      this.createVehicleLocation(coords);
    }));
    this.listen.push(this.viewProjectProblemService.changeStopPointPriorityListen().subscribe(data => {
      this.changeStopPointPriority(data.ids, data.priority);
    }));
    this.listen.push(this.viewProjectProblemService.loadStopsListen().subscribe(data => {
      this.loadStops(data);
    }));
    this.listen.push(this.viewProjectProblemService.moveStopsListen().subscribe(data => {
      this.moveStops(data);
    }));
    this.listen.push(this.viewProjectProblemService.enableDriverPortalListen().subscribe(id => {
      this.enableDriverPortal(id);
    }));
    this.listen.push(this.viewProjectProblemService.enableSettingsPortalListen().subscribe(data => {
      this.enableSettingsPortal();
    }));
    this.listen.push(this.viewProjectProblemService.unMergeStopPointsListen().subscribe(ids => {
      this.unMergeStopPoints(ids);
    }));
    // this.listen.push(this.viewProjectProblemService.loadAndOpenHelperListen().subscribe(type => {
    //   this.openHelper(type);
    // }));
    this.listen.push(this.viewProjectProblemService.resetMilyOpenedForCanceledErrorStopsListen().subscribe(data => {
      this.milyOpenedForCanceledErrorStops = false;
    }));
    this.listen.push(this.viewProjectProblemService.startGeneralTourListen().subscribe(ids => {
      this.startTour();
    }));
    this.listen.push(this.viewProjectProblemService.throwCancelConfirmListen().subscribe(data => {
      this.throwCancelConfirm();
    }));
    this.listen.push(this.viewProjectProblemService.openProjectProblemReportsListen().subscribe(data => {
      this.showProjectProblemReport();
    }));
    this.listen.push(this.viewProjectProblemService.openDriverReportsListen().subscribe(id => {
      this.showDriverReport(id);
    }));
    this.listen.push(this.milyService.openMilyListen().subscribe((data) => {
      this.mapComponent.mapDummyComponent.selectStopsButton.nativeElement.classList.add('open');
    }));
    this.listen.push(this.milyService.closeMilyListen().subscribe((data) => {
      this.mapComponent.mapDummyComponent.selectStopsButton.nativeElement.classList.remove('open');
    }));
  }

  openRoutingSettingsModal() {
    const dialog = this.notificationSvc.getActiveDialog();
    if (dialog) return dialog.ctx.close();
    this.renderer.addClass(document.body, 'lm-routing-settings-modal')
    if(this.routingSettings){
      this.notificationSvc
      .showDialog<boolean>({ componentType: this.routingSettingsModal, injector: this._injector, vcr: this._vcr, data: {globals: this.globals, values: this.routingSettings, button:this.routingSettingsButton, id: this.projectProblemId, translations:this.routingSettingsTranslations} })
      .subscribe();
    }
  }

  dispatchMessagesToMily() {
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (!this.driversLoading) {
        clearInterval(dataRefreshIntervalId);
        if (this.projectProblemDataService.routesWithCloseOvertime.length) {
          this.milyService.driversWorkingHours(this.projectProblemDataService.routesWithCloseOvertime);
        }
        if (this.projectProblemDataService.vehiclesWithCloseCapacity.length) {
          this.milyService.vehicleCapacity(this.projectProblemDataService.vehiclesWithCloseCapacity);
        }
      }
    }
  }

  onUploadOutput(output: UploadOutput): void {
    const jwt = this.cookieService.get('jwt');
    switch (output.type) {
      case 'allAddedToQueue':
        const event: UploadInput = {
          type: 'uploadAll',
          url: '/api/v1/project/problems/' + this.projectProblemId + '/import-analyzer',
          fieldName: 'companyOrders',
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + jwt,
            'language': this.globals.currentLang
          }
        };
        this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'done':
        // handle import analyzer errors
        this.importUtils.handleAnalyzerErrors(output['file']['response']['errors']);

        // The file is uploaded
        if (this.files) {
          const file = this.files[this.files.length - 1];
          const data = file.response.items;
          if (data) {
            this.importerService.openImporterMatcher(data);
          }
        }
        break;
    }
  }

  getDriverIdsWithRoute() {
    const driverIds = [];
    if (this.projectProblemDataService.sequenceArrayPerRouteSettingId) {
      Object.keys(this.projectProblemDataService.sequenceArrayPerRouteSettingId).forEach(routeSettingId => {
        if (this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId].length) {
          if (this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId]) {
            driverIds.push(this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId]['id']);
          }
        }
      });
    }
    return driverIds;
  }

  showProjectProblemReport() {
    const driverIds = this.getDriverIdsWithRoute();
    this.projectProblemReportModalComponent.loadAndOpen(driverIds, this.projectProblemDepartureDatetime, this.projectProblemId);
  }

  showDriverReport(driverId) {
    this.driverReportModalComponent.loadAndOpen(driverId, this.projectProblemDepartureDatetime, this.projectProblemId);
  }

  editDepartureDate() {
    if (!this.isAutoGenerated && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
      this.departureDateEdit = true;
    }
  }

  editDepartureTime() {
    if (!this.isAutoGenerated && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
      this.departureTimeEdit = true;
    }
  }

  timeSelected(event) { }

  throwCancelConfirm() {
    if (this.cancelAlertSubscription) {
      this.cancelAlertSubscription.unsubscribe();
    }
    this.cancelAlertSubscription = this.milyComponent.cancelObservable.pipe(take(1)).subscribe(res => {
      if (res === 'ok') {
        this.stopFormModalComponent.stopFormComponent.submitButtonPressed('yes');
      } else {
        this.stopFormModalComponent.stopFormComponent.submitButtonPressed('no');
      }
    });
  }

  enableSettingsPortal() {
    const component = this.stopFormModalComponent.stopFormComponent;
    const portalSubscription = this.milyComponent.portalSettingsObservable.pipe(take(1)).subscribe(res => {
      if (res === 'ok') {
        const settingsRequestObserver = {
          next: (response) => {
            this.globals.getCompanyData();
            this.globals.portalAccess = true;
            component.setPortalSwitches();
            portalSubscription.unsubscribe();
          },
          error: (error) => {
            console.error(error);
            portalSubscription.unsubscribe();
          },
          complete: () => { },
        };
        const data = {
          companySettings: {
            portal_access_sw: true
          }
        };
        this.http.put('api/v1/companies/' + this.globals.companyId, data).pipe(take(1)).subscribe(settingsRequestObserver);
      } else {
        component.portalOn = false;
        component.setForm();
        portalSubscription.unsubscribe();
      }
    });
  }

  enableDriverPortal(driverId) {
    const component = this.stopFormModalComponent.stopFormComponent;
    const portalSubscription = this.milyComponent.portalDriverObservable.pipe(take(1)).subscribe(res => {
      if (res === 'ok') {
        const driversRequestObserver = {
          next: (response) => {
            this.projectProblemDataService.loadDrivers().pipe(take(1)).subscribe(drivers => {
              this.projectProblemDataService.setDrivers(drivers['items']);
              this.projectProblemDataService.loadDriversDone = true;
            });
            portalSubscription.unsubscribe();
          },
          error: (error) => {
            console.error(error);
            portalSubscription.unsubscribe();
          },
          complete: () => { },
        };
        const data = {
          driver: {
            portal_access_sw: true
          }
        };
        this.http.put('api/v1/drivers/' + driverId, data).pipe(take(1)).subscribe(driversRequestObserver);
      } else {
        component.portalOn = false;
        component.setForm();
        portalSubscription.unsubscribe();
      }
    });
  }

  setStopPointsGridCount(event) {
    if (event) {
      const object = JSON.parse(event);
      if (object) {
        this.stopPointsCount = object.total;
        this.completedStopPointsCount = object.completed;
        this.completedAndCancelledStopPointsCount = object.completedAndCancelled;
      }
    }
  }

  openHelper(type) {
    this.helperModalComponent.loadHelperDataAndOpen(type);
  }

  reloadOptimizations() {
    this.updateProjectProblem(true);
  }

  changeStopPointPriority(ids, priority) {
    const myObserver = {
      next: (response) => {
        if (response['items']) {
          this.projectProblemDataService.updateMultipleStopPoints(response['items']);
        }
        this.viewProjectProblemService.updateProjectProblemStatus();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => { },
    };

    let priorityConstant;
    if (priority) {
      priorityConstant = this.globals.stopPointPriorityConstants['HIGH'];
    } else {
      priorityConstant = this.globals.stopPointPriorityConstants['NORMAL'];
    }
    const stopPointsData = {
      stopPoints: []
    };
    ids.forEach(id => {
      const stopPointData = {
        stopPoint: {
          project_problem_id: this.projectProblemId,
          stopPointId: id
        },
      };
      if (priority) {
        stopPointData.stopPoint['priority'] = this.globals.stopPointPriorityConstants['HIGH'];
      } else {
        stopPointData.stopPoint['priority'] = this.globals.stopPointPriorityConstants['NORMAL'];
      }
      stopPointsData.stopPoints.push(stopPointData);
    });
    this.http.put(this.updateStopPointsUrl, JSON.stringify(stopPointsData)).pipe(take(1)).subscribe(myObserver);
  }

  cancelStopPoints(stopPointIds) {
    const myObserver = {
      next: (response) => {
        if (response['items']) {
          this.projectProblemDataService.updateMultipleStopPoints(response['items']);
        }
        this.viewProjectProblemService.updateProjectProblemStatus();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => { },
    };

    const canceledStatus = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];
    const stopPointsDataForRequest = {
      stopPoints: [],
    };
    stopPointIds.forEach(stopPointId => {
      const stopPointData = {
        stopPoint: {
          project_problem_id: this.projectProblemId,
          stopPointId: stopPointId,
          fulfillment_status: canceledStatus,
          fulfillment_event: {
            reason: this.globals.stopPointFulfillmentEventConstants[canceledStatus]['CUSTOM'],
            description: this.cancelReasonUnableToDeliverLabel,
          }
        },
      };
      stopPointsDataForRequest.stopPoints.push(stopPointData);
    });

    if (this.cancelMultipleStopPointsAlertSubscription) {
      this.cancelMultipleStopPointsAlertSubscription.unsubscribe();
    }
    this.cancelMultipleStopPointsAlertSubscription = this.milyComponent.cancelMultipleStopPointsObservable.pipe(take(1)).subscribe(res => {
      if (res === 'ok') {
        this.http.put(this.updateStopPointsUrl, stopPointsDataForRequest).pipe(take(1)).subscribe(myObserver);
      }
    });


  }

  deleteStopPoints(stopPointIds = []) {
    let ids = [...stopPointIds];
    const myObserver = {
      next: (response) => { },
      error: (error) => {
        console.error(error);
        if (error['error']['errors']) {
          if (error['error']['errors']['project']['problem']) {
            if (error['error']['errors']['project']['problem'][this.projectProblemId]) {
              if (error['error']['errors']['project']['problem'][this.projectProblemId]['base'][0] == "Already optimizing") {
                this.milyService.alreadyOptimizing();
              }
            }
          }
        }
      },
      complete: () => {
        this.projectProblemDataService.updateStopPoints();
        ids.forEach(id => {
          this.projectProblemDataService.removeStopPoint(this.projectProblemDataService.stopPoints[id]);
        });
        if (this.deleteAlertSubscription) {
          this.deleteAlertSubscription.unsubscribe();
        }
        if (this.deleteSameDayAlertSubscription) {
          this.deleteSameDayAlertSubscription.unsubscribe();
        }
      },
    };
    // check if any of the stops is involved in a same day delivery situation and have an array with their partner SPs
    const extraStops = this.projectProblemDataService.sameDayDeliveryChecker(ids);
    if (extraStops.length) {
      if (this.deleteSameDayAlertSubscription) {
        this.deleteSameDayAlertSubscription.unsubscribe();
      }
      // throw a mily confirm saying there are same day delivery stops
      // add the partner SPs to the other ids to be deleted and removed from the map
      this.deleteSameDayAlertSubscription = this.milyComponent.deleteSameDayObservable.pipe(take(1)).subscribe(res => {
        if (res === 'ok') {
          ids = ids.concat(extraStops);
          const deleteStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
          this.http.delete(deleteStopPointsUrl + '?ids=' + ids).pipe(take(1)).subscribe(myObserver);
        }
      });
    } else {
      if (this.deleteAlertSubscription) {
        this.deleteAlertSubscription.unsubscribe();
      }
      this.deleteAlertSubscription = this.milyComponent.deleteObservable.pipe(take(1)).subscribe(res => {
        if (res === 'ok') {
          const deleteStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
          this.http.delete(deleteStopPointsUrl + '?ids=' + ids).pipe(take(1)).subscribe(myObserver);
        }
      });
    }
  }

  createVehicleLocation(coords) {
    const myObserver = {
      next: (response) => {
      },
      error: (error) => {
      },
      complete: () => {
      },
    };
    const data = {
      projectProblem: { id: this.projectProblemId },
      vehicleLocations: [{
        lat: coords.lat,
        lon: coords.lon,
        timestamp: moment().unix()
      }]
    };
    this.http.post('api/v1/vehicle-locations', JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
  }

  loadStops(data) {
    this.loadStopsModalComponent.isClickedOnce = true;
    const loadStopsUrl = this.loadStopsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const myObserver = {
      next: (response) => {
        this.loadStopsModalComponent.isClickedOnce = false;
        this.loadStopsModalComponent.updateStopPoints();
        if (response) {
          this.projectProblemDataService.loadedStops = response['items'];
        }
      },
      error: (error) => {
        this.loadStopsModalComponent.isClickedOnce = false;
        this.loadStopsModalComponent.updateStopPointIds = [];
      },
      complete: () => { },
    };

    this.http.put(loadStopsUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    this.viewProjectProblemService.disableMapMultipleSelect();
  }

  moveStops(data) {
    this.moveStopsModalComponent.isClickedOnce = true;
    const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const myObserver = {
      next: (response) => {
        this.moveStopsModalComponent.isClickedOnce = false;
        if (this.stopFormModalComponent.stopFormComponent) {
          this.stopFormModalComponent.stopFormComponent.loadAssignedDrivers(data);
        }
        this.moveStopsModalComponent.updateStopPoints();
        if (this.mergeAlertSubscription) {
          this.mergeAlertSubscription.unsubscribe();
        }
      },
      error: (error) => {
        this.moveStopsModalComponent.isClickedOnce = false;
        this.moveStopsModalComponent.updateStopPointIds = [];
        if (error.status === 300) {

          if (this.mergeAlertSubscription) {
            this.mergeAlertSubscription.unsubscribe();
          }
          this.mergeAlertSubscription = this.milyComponent.mergeObservable.pipe(take(1)).subscribe(res => {
            if (res === 'ok') {
              data.ignoreKeepRoutes = true;
              this.http.post(manualModifiedRouteUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
            }
          });
        } else {
          let alertMsg = 'Error';
          if (error.error.errors) {
            if (error.error.errors.project) {
              if (error.error.errors.project.problem) {
                if (error.error.errors.project.problem.manualModifiedSequenceItem[0]) {
                  alertMsg = error.error.errors.project.problem.manualModifiedSequenceItem[0];
                }
              }
            }
          }
          this.milyService.alert(alertMsg);
          console.error(error);
        }
      },
      complete: () => { },
    };
    this.http.post(manualModifiedRouteUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    // this.viewProjectProblemService.disableMapMultipleSelect();
  }

  sequenceStopPoints(stopPointIds, targetStopPointId, mode) {
    const savedStopPointIds = [];
    const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const directManualModifiedRouteUrl = this.directManualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const data = {
      sequence: {},
      ignoreKeepRoutes: false
    };
    const editSequenceStopPointsPerStopPointId = {};
    if (mode === 'before') {
      stopPointIds.forEach(id => {
        savedStopPointIds.push(id);
        if (!data.sequence[id]) {
          data.sequence[id] = [];
        }
        data.sequence[id].push(targetStopPointId);
        editSequenceStopPointsPerStopPointId[id] = {
          beforeIds: [],
          afterIds: [targetStopPointId]
        };
      });
      editSequenceStopPointsPerStopPointId[targetStopPointId] = {
        beforeIds: savedStopPointIds,
        afterIds: []
      };
    } else if (mode === 'after') {
      data.sequence[targetStopPointId] = [];
      stopPointIds.forEach(id => {
        savedStopPointIds.push(id);
        data.sequence[targetStopPointId].push(id);
        editSequenceStopPointsPerStopPointId[id] = {
          beforeIds: [targetStopPointId],
          afterIds: []
        };
      });
      editSequenceStopPointsPerStopPointId[targetStopPointId] = {
        beforeIds: [],
        afterIds: savedStopPointIds
      };
    } else if (mode === 'directlyBefore') {
      stopPointIds.forEach(id => {
        savedStopPointIds.push(id);
        if (!data.sequence[id]) {
          data.sequence[id] = null;
        }
        data.sequence[id] = targetStopPointId;
        editSequenceStopPointsPerStopPointId[id] = {
          beforeIds: [],
          afterIds: [targetStopPointId]
        };
      });
      editSequenceStopPointsPerStopPointId[targetStopPointId] = {
        beforeIds: savedStopPointIds,
        afterIds: []
      };
    } else if (mode === 'directlyAfter') {
      data.sequence[targetStopPointId] = null;
      stopPointIds.forEach(id => {
        savedStopPointIds.push(id);
        data.sequence[targetStopPointId] = id;
        editSequenceStopPointsPerStopPointId[id] = {
          beforeIds: [targetStopPointId],
          afterIds: []
        };
      });
      editSequenceStopPointsPerStopPointId[targetStopPointId] = {
        beforeIds: [],
        afterIds: savedStopPointIds
      };
    }
    const myObserver = {
      next: (response) => {
        if (mode === 'before' || mode === 'after') {
          this.updateProjectProblemStatus();
          this.projectProblemDataService.updateStopPointsModifications();
        } else {
          this.milyComponent.displayOptimizationStatus(this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']);
          setTimeout(() => {
            this.milyService.closeMily();
          }, 2000);
          this.updateProjectProblem(true);
        }
        savedStopPointIds.forEach(id => {
          const stopPointData = this.mapComponent.stopPointMapObjects[id].getData();
          if (mode === 'before' || mode === 'after') {
            stopPointData.editSequenceStopPoints = editSequenceStopPointsPerStopPointId[id];
          } else {
            stopPointData.editDirectSequenceStopPoints = editSequenceStopPointsPerStopPointId[id];
          }
          stopPointData.edited = true;
          this.mapComponent.updateStopPoint(stopPointData);
        });
        const targetStopPointData = this.mapComponent.stopPointMapObjects[targetStopPointId].getData();
        if (mode === 'before' || mode === 'after') {
          targetStopPointData.editSequenceStopPoints = editSequenceStopPointsPerStopPointId[targetStopPointId];
        } else {
          targetStopPointData.editDirectSequenceStopPoints = editSequenceStopPointsPerStopPointId[targetStopPointId];
        }
        targetStopPointData.edited = true;
        this.mapComponent.updateStopPoint(targetStopPointData);
        if (this.mergeAlertSubscription) {
          this.mergeAlertSubscription.unsubscribe();
        }
      },
      error: (error) => {
        if (mode === 'directlyBefore' || mode === 'directlyAfter') {
          this.milyComponent.displayOptimizationStatus(this.globals.projectProblemOptimizationStateConstants['ERROR']);
          this.milyService.openMily();
        }
        if (error.status === 300) {
          if (this.mergeAlertSubscription) {
            this.mergeAlertSubscription.unsubscribe();
          }
          this.mergeAlertSubscription = this.milyComponent.mergeObservable.pipe(take(1)).subscribe(res => {
            if (res === 'ok') {
              data.ignoreKeepRoutes = true;
              this.http.post(manualModifiedRouteUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
            }
          });
        } else {
          this.handleManualModifiedRouteError(error);
        }
      },
      complete: () => { },
    };
    if (mode === 'before' || mode === 'after') {
      this.http.post(manualModifiedRouteUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    } else {
      this.milyService.openMily();
      let option = 'updatingRoutes';
      this.milyService.optimization(option);
      this.http.put(directManualModifiedRouteUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    }
  }

  handleManualModifiedRouteError(error) {
    let alertMsg = 'Error';
    if (error.error.errors) {
      if (error.error.errors.project) {
        if (error.error.errors.project.problem) {
          const sentryMessageForManualModifiedRouteError = 'Manual modified route error stringified is: ';
          Sentry.captureMessage(sentryMessageForManualModifiedRouteError + JSON.stringify(error.error.errors.project.problem));
          if (error.error.errors.project.problem.manualModifiedSequenceItem[0]) {
            alertMsg = error.error.errors.project.problem.manualModifiedSequenceItem[0];
          }
        }
      }
    }
    this.milyService.alert(alertMsg);
    console.error(error);
  }

  unMergeStopPoints(ids) {
    const stopPointIds = [...ids];
    const myObserver = {
      next: (response) => {
        this.viewProjectProblemService.updateProjectProblemStatus();
        stopPointIds.forEach(id => {
          if (this.projectProblemDataService.stopPointIndexInArray[id]) {
            const index = this.projectProblemDataService.stopPointIndexInArray[id];
            const stopPointData = this.projectProblemDataService.stopPointsArray[index];
            if (stopPointData['stopPoint']) {
              stopPointData['stopPoint']['mergeData'] = null;
            } else if (stopPointData['id']) {
              stopPointData['mergeData'] = null;
            }
            this.projectProblemDataService.updateSingleStopPoint(stopPointData);
          }
        });
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    const stopPointsData = {
      stopPoints: []
    };
    const targetsToUpdate = {};
    // check every stop point if they are merged
    const mergedStopPoints = JSON.parse(JSON.stringify(this.projectProblemDataService.mergedStops));
    let targetsToStopsIds = {};
    stopPointIds.forEach(id => {
      if (this.projectProblemDataService.stopPoints[id].mergeData) {
        if (this.projectProblemDataService.stopPoints[id].mergeData.mergedStopPointId) {
          // find and remove them from their target point's array of merged stops
          // const targetStopPointId = this.projectProblemDataService.stopPoints[id].mergeData.mergedStopPointId;
          // const index = this.projectProblemDataService.mergedStops[targetStopPointId].indexOf(id);
          // if (index || index === 0) {
          //   this.projectProblemDataService.mergedStops[targetStopPointId].splice(index, 1);
          //   targetsToUpdate[targetStopPointId] = this.projectProblemDataService.mergedStops[targetStopPointId];
          const targetStopPointId = this.projectProblemDataService.stopPoints[id].mergeData.mergedStopPointId;
          Object.keys(mergedStopPoints).forEach(targetId => {
            if (mergedStopPoints[targetId].includes(id)) {
              if (targetsToStopsIds[targetStopPointId]) {
                if (targetsToStopsIds[targetStopPointId].length) {
                  targetsToStopsIds[targetStopPointId].push(id);
                }
              } else {
                targetsToStopsIds[targetStopPointId] = [id];
              }
            }
          });
          // const stopPointData = {
          //   stopPoint: {
          //     project_problem_id: this.projectProblemId,
          //     stopPointId: targetStopPointId,
          //     mergeData: {
          //       // separateStopPointIds: this.projectProblemDataService.mergedStops[targetStopPointId]
          //       separateStopPointIds: stopPointIds
          //     },
          //   },
          // };
          // stopPointsData.stopPoints.push(stopPointData);
          // }
        }
      }
    });
    Object.keys(targetsToStopsIds).forEach(targetStopPointId => {
      const stopPointData = {
        stopPoint: {
          project_problem_id: this.projectProblemId,
          stopPointId: Number(targetStopPointId),
          mergeData: {
            unMergeFlag: true,
            separateStopPointIds: targetsToStopsIds[targetStopPointId]
          },
        },
      };

      this.http.put(this.updateStopPointsUrl, JSON.stringify(stopPointData)).pipe(take(1)).subscribe(myObserver);
    });
  }

  mergeStopPoints(ids, targetStopPointId) {
    const stopPointIds = [...ids];
    // if any of the sp were merged to another stop, remove them from that list
    stopPointIds.forEach(id => {
      if (this.projectProblemDataService.stopPoints[id].mergeData) {
        if (this.projectProblemDataService.stopPoints[id].mergeData.mergedStopPointId) {
          const target = this.projectProblemDataService.stopPoints[id].mergeData.mergedStopPointId;
          const index = this.projectProblemDataService.mergedStops[target].indexOf(id);
          if (index) {
            this.projectProblemDataService.mergedStops[target].splice(index, 1);
          }
        }
      }
    });
    if (this.projectProblemDataService.mergedStops[targetStopPointId]) {
      this.projectProblemDataService.mergedStops[targetStopPointId].forEach(id => {
        if (!stopPointIds.includes(id)) {
          stopPointIds.push(id);
        }
      });
    }
    const myObserver = {
      next: (response) => {
        const weight = Number(response['items'][0]['stopPoint']['pickup_load']) + Number(response['items'][0]['stopPoint']['delivery_load']);
        const data = this.mapComponent.stopPointMapObjects[targetStopPointId].getData();
        data.overweight = this.projectProblemViewUtils.calculateWeightState(weight, this.maximumWeight);
        this.mapComponent.updateStopPoint(data);
        this.projectProblemDataService.changePartnerMergedStops(targetStopPointId, stopPointIds);

        stopPointIds.forEach(id => {
          if (this.projectProblemDataService.stopPointIndexInArray[id]) {
            const index = this.projectProblemDataService.stopPointIndexInArray[id];
            const stopPointData = this.projectProblemDataService.stopPointsArray[index];
            if (stopPointData['stopPoint']) {
              stopPointData['stopPoint']['mergeData'] = {
                mergedStopPointId: targetStopPointId
              };
            } else if (stopPointData['id']) {
              stopPointData['mergeData'] = {
                mergedStopPointId: targetStopPointId
              };
            }

            this.projectProblemDataService.updateSingleStopPoint(stopPointData);
          }
        });
        this.updateProjectProblemStatus();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => { },
    };
    const targetStopPointUpdateData = {
      stopPoint: {
        mergeData: {
          separateStopPointIds: stopPointIds
        },
      }
    };
    let updateTargetStopPointUrl = this.updateStopPointUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    updateTargetStopPointUrl = updateTargetStopPointUrl.replace('STOP_POINT_ID', targetStopPointId);
    this.http.put(updateTargetStopPointUrl, targetStopPointUpdateData).pipe(take(1)).subscribe(myObserver);
  }

  unModifyAllStopPoints() {
    const myObserver = {
      next: (response) => {
        this.updateProjectProblemStatus();
        this.projectProblemDataService.updateStopPoints();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => {
      },
    };
    const manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    this.http.delete(manualModifiedRouteUrl).pipe(take(1)).subscribe(myObserver);
  }

  unModifyStopPoints() {
    const stopPointIds = [...this.stopPointsToUnModify];
    const myObserver = {
      next: (response) => {
        this.projectProblemDataService.removeStopPointsModifications(stopPointIds, response);
        this.updateStopPointIds = [];
        this.updateProjectProblemStatus();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
        this.updateStopPointIds = [];
      },
      complete: () => { },
    };
    let manualModifiedRouteUrl = this.manualModifiedRouteUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    manualModifiedRouteUrl = manualModifiedRouteUrl + '?ids=' + stopPointIds;
    this.http.delete(manualModifiedRouteUrl).pipe(take(1)).subscribe(myObserver);
  }

  changeStopPointEntityStatus(stopPointId, enabled) {
    const myObserver = {
      next: (response) => {
        if (response['items']) {
          this.projectProblemDataService.updateMultipleStopPoints(response['items']);
        }
        this.updateProjectProblemStatus();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => {
      },
    };
    let updateStopPointUrl = this.updateStopPointUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    updateStopPointUrl = updateStopPointUrl.replace('STOP_POINT_ID', stopPointId);
    const entityStatusConstant = enabled ? this.globals.stopPointEntityStatusConstants['DISABLED'] : this.globals.stopPointEntityStatusConstants['ACTIVE'];
    const clickedStopPointData = { stopPoint: { entity_status: entityStatusConstant } };
    this.http.put(updateStopPointUrl, clickedStopPointData).pipe(take(1)).subscribe(myObserver);
  }

  returnStopPoints(stopPointIds) {
    const statusObj = {
      label: this.returnTitle
    };

    this.stopModalComponent.cancelStopPointModalComponent.openModal(stopPointIds, this.projectProblemId, statusObj, this.globals.stopPointServiceTypeConstants['PICKUP'], null, '');
  }

  changeMultipleStopPointsEntityStatus(stopPointIds, enabled) {
    const myObserver = {
      next: (response) => {
        if (response['items']) {
          this.projectProblemDataService.updateMultipleStopPoints(response['items']);
        }
        this.updateProjectProblemStatus();
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => {
      },
    };
    const entityStatusConstant = enabled ? this.globals.stopPointEntityStatusConstants['DISABLED'] : this.globals.stopPointEntityStatusConstants['ACTIVE'];
    const data = [];
    stopPointIds.forEach(stopPointId => {
      const stopPointData = {
        stopPoint: {
          project_problem_id: this.projectProblemId,
          stopPointId: stopPointId,
          entity_status: entityStatusConstant
        }
      };
      data.push(stopPointData);
    });
    this.http.put(this.updateStopPointsUrl, { stopPoints: data }).pipe(take(1)).subscribe(myObserver);
  }

  freeStopPointTimeWindow(stopPointId) {
    const projectProblemTimeStart = moment(this.projectProblemDepartureDatetime).format('HH:mm');
    const projectProblemTimeStartMoment = moment(projectProblemTimeStart, 'HH:mm');
    let timeWindowStartOffsetFromDepartureMinutes;
    const timeWindowStartMoment = moment('08:00', 'HH:mm');
    const timeWindowStartOffsetFromDepartureDuration = moment.duration(timeWindowStartMoment.diff(projectProblemTimeStartMoment));
    timeWindowStartOffsetFromDepartureMinutes = timeWindowStartOffsetFromDepartureDuration.asMinutes();
    const timeWindowStartOffsetFromDeparture = moment.duration(timeWindowStartOffsetFromDepartureMinutes, 'minutes').toISOString();
    const clickedStopPointData = {
      stopPoint: {
        time_windows: [
          {
            time_window_range: 'PT12H',
            time_window_start_offset_from_departure: timeWindowStartOffsetFromDeparture
          }
        ]
      }
    };
    const myObserver = {
      next: (response) => {
        this.updateProjectProblemStatus();
        const data = this.mapComponent.stopPointMapObjects[stopPointId].getData();
        data.timeWindowState = 'default';
        this.mapComponent.updateStopPoint(data);
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      },
    };
    let updateStopPointUrl = this.updateStopPointUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    updateStopPointUrl = updateStopPointUrl.replace('STOP_POINT_ID', stopPointId);
    this.http.put(updateStopPointUrl, JSON.stringify(clickedStopPointData)).pipe(take(1)).subscribe(myObserver);
  }

  freeMultipleStopPointsTimeWindow(stopPointIds) {
    const projectProblemTimeStart = moment(this.projectProblemDepartureDatetime).format('HH:mm');
    const projectProblemTimeStartMoment = moment(projectProblemTimeStart, 'HH:mm');
    let timeWindowStartOffsetFromDepartureMinutes;
    const timeWindowStartMoment = moment('08:00', 'HH:mm');
    const timeWindowStartOffsetFromDepartureDuration = moment.duration(timeWindowStartMoment.diff(projectProblemTimeStartMoment));
    timeWindowStartOffsetFromDepartureMinutes = timeWindowStartOffsetFromDepartureDuration.asMinutes();
    const timeWindowStartOffsetFromDeparture = moment.duration(timeWindowStartOffsetFromDepartureMinutes, 'minutes').toISOString();
    const data = [];
    stopPointIds.forEach(stopPointId => {
      const stopPointData = {
        stopPoint: {
          project_problem_id: this.projectProblemId,
          stopPointId: stopPointId,
          time_windows: [
            {
              time_window_range: 'PT12H',
              time_window_start_offset_from_departure: timeWindowStartOffsetFromDeparture
            }
          ]
        }
      };
      data.push(stopPointData);
    });
    const myObserver = {
      next: (response) => {
        this.updateProjectProblemStatus();
        if (response['items']) {
          this.projectProblemDataService.updateMultipleStopPoints(response['items']);
          // response['items'].forEach(stopPointResponseData => {
          //   this.projectProblemDataService.updateSingleStopPoint(stopPointResponseData);
          // });
        }
      },
      error: (error) => {
        console.error(error);
        this.checkForAlreadyOptimizingError(error);
      },
      complete: () => {
      },
    };
    this.http.put(this.updateStopPointsUrl, { stopPoints: data }).pipe(take(1)).subscribe(myObserver);
  }

  updateProjectProblemDate() {
    const dateElement = document.querySelector('#departure-date-edit');
    const date = M.Datepicker.getInstance(dateElement).date;
    const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:SS').format('YYYY-MM-DD');
    const projectProblemDatetimeUnformatted = formattedDate + ' ' + this.problemDepartureTime;
    const projectProblemDatetime = moment(projectProblemDatetimeUnformatted, 'YYYY-MM-DD HH:mm').utc().format().replace('Z', '+00:00');
    const myObserver = {
      next: (response) => {
        if (this.projectProblemDepartureDatetime !== projectProblemDatetime) { console.warn('Project problem departure dateTime was changed to ' + projectProblemDatetime); }
        this.projectProblemDepartureDatetime = projectProblemDatetime;
        this.projectProblemDayOfWeek = this.days[moment(this.projectProblemDepartureDatetime).day()];
        this.problemDepartureDate = moment(this.projectProblemDepartureDatetime).format('DD MMM YYYY');
        this.updateProjectProblemStatus();
        this.setProjectProblem();
      },
      error: (error) => {
        console.error(error);
        this.departureDateEdit = false;
        if (error.error.errors.project.problem) {
          if (error.error.errors.project.problem.routeSetting) {
            if (error.error.errors.project.problem.routeSetting.start_offset_from_departure) {
              this.milyService.alert(error.error.errors.project.problem.routeSetting.start_offset_from_departure[0])
            }
          }
        }
      },
      complete: () => {
        this.departureDateEdit = false;
      },
    };
    const httpData = {
      project: {
        problem: {
          departure_datetime: projectProblemDatetime,
          title: this.problemTitle,
        }
      }
    };
    this.http.put('api/v1/project/problems/' + this.projectProblemId, httpData).pipe(take(1)).subscribe(myObserver);
  }

  updateProjectProblemTime() {
    const time = (<HTMLInputElement>document.getElementById('departure-time-edit')).value;
    const projectProblemDatetimeUnformatted = this.problemDepartureDate + ' ' + time;
    const projectProblemDatetime = moment(projectProblemDatetimeUnformatted, 'DD MMM YYYY HH:mm').utc().format().replace('Z', '+00:00');
    const myObserver = {
      next: (response) => {
        if (this.projectProblemDepartureDatetime !== projectProblemDatetime) { console.warn('Project problem departure dateTime was changed to ' + projectProblemDatetime); }
        this.projectProblemDepartureDatetime = projectProblemDatetime;
        this.projectProblemDayOfWeek = this.days[moment(this.projectProblemDepartureDatetime).day()];
        this.problemDepartureTime = moment(this.projectProblemDepartureDatetime).format('HH:mm');
        this.updateProjectProblemStatus();
        this.setProjectProblem();
      },
      error: (error) => {
        console.error(error);
        this.departureTimeEdit = false;
        if (error.error.errors.project.problem) {
          if (error.error.errors.project.problem.routeSetting) {
            if (error.error.errors.project.problem.routeSetting.start_offset_from_departure) {
              this.milyService.alert(error.error.errors.project.problem.routeSetting.start_offset_from_departure[0])
            }
          }
        }
      },
      complete: () => {
        this.departureTimeEdit = false;
      },
    };
    const httpData = {
      project: {
        problem: {
          departure_datetime: projectProblemDatetime,
          title: this.problemTitle,
        }
      }
    };
    this.http.put('api/v1/project/problems/' + this.projectProblemId, httpData).pipe(take(1)).subscribe(myObserver);
  }

  openModal(step): void {
    this.stepsService.transmitProjectProblemDataToModals(step);
  }

  checkForSolution() {
    if (
      this.optimizationState === this.optimizationStates['OPTIMIZED']
      || this.entityStatus !== this.globals.projectProblemEntityStatusConstants['UN_DISPATCHED']
      || this.isModified
    ) {
      if (this.projectProblemDataService.solutionData) {
        this.loadStopsToMap();
        const routeStartTime = moment(this.projectProblemDepartureDatetime).utc();
        const solutionData = this.projectProblemViewUtils.calculateSolutionData(routeStartTime);
        const polylinesArray = solutionData.polylinesArray;

        //  NOTE polylines
        this.mapComponent.addEncodedElements(polylinesArray);
        this.updateWhiteBar(solutionData);
        if (this.entityStatus !== this.globals.projectProblemEntityStatusConstants['UN_DISPATCHED']) {
          this.trackVehicles();
          this.autoUpdateStopIcons();
          this.autoGetIssues();
          if (this.globals.foodModeEnabled) {
            // this.autoRefresh();
          }
        }
        if (this.problemTitle.includes('demo') && !localStorage.getItem('demoOptimized') && solutionData?.polylinesArray?.length) {
          this.populateFirstOptimizationModal();
          this.firstOptimizationModalComponent.openModal();
        }
      }
    } else {
      this.loadStopsToMap();
      this.updateWhiteBar();
    }
    if (this.projectProblemDataService.routeSettingIdsWithReload.length) {
      this.sendReloadingRoutesToMily();
    }
    this.dispatchMessagesToMily();
  }

  populateFirstOptimizationModal() {
    this.firstOptimizationModalComponent.routes = [];
    if (this.projectProblemDataService.sequenceArrayPerRouteSettingId) {
      Object.keys(this.projectProblemDataService.sequenceArrayPerRouteSettingId).forEach(routeSettingId => {
        const sequenceArray = this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId];
        const routeIndex = this.projectProblemDataService.getRouteIndexByRouteSettingId(routeSettingId);
        if (sequenceArray.length) {
          const colour = this.colourService.colourCalculator(routeIndex);
          const driverName = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId]['name'];
          const driverId = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId]['id'];
          const vehicleId = '';
          const load = this.projectProblemDataService.routeSettingsById[routeSettingId].routeSetting.load;
          const capacity = this.projectProblemDataService.routeSettingsById[routeSettingId].vehicle.maximum_cargo_capacity;
          const stopPointsCount = sequenceArray.length - 2;
          const loadPercentage = Math.round((100 * load) / capacity);

          const departureDatetimeDuration = this.projectProblemDataService.routeSettingsById[routeSettingId].routeSetting.start_offset_from_departure;
          const departureDatetimeDurationMinutes = moment.duration(departureDatetimeDuration).asMinutes();
          const departureDatetimeMoment = moment(this.projectProblemDepartureDatetime).add(departureDatetimeDurationMinutes, 'minutes');
          const lastStopPointId = this.projectProblemDataService.routeSettingsById[routeSettingId].routeSetting.finish_stop_point_id;
          const lastStopPointData = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'][routeSettingId][lastStopPointId];
          const lastDatetime = lastStopPointData.latestEstimatedArrivalDatetime;
          const routeDurationHours = moment.duration((moment(lastDatetime).utc()).diff(departureDatetimeMoment.utc())).asHours();
          const durationHours = Math.floor(routeDurationHours);
          const durationMinutes = moment.duration((routeDurationHours - durationHours), 'hours').asMinutes();
          let duration = durationHours + this.hoursShortMsg;
          if (Math.round(durationMinutes)) {
            duration += ' ' + Math.round(durationMinutes) + this.minutesShortMsg;
          }

          this.firstOptimizationModalComponent.addRoute(driverName, duration, stopPointsCount, loadPercentage, colour);
        }
      });
    }
  }

  sendReloadingRoutesToMily() {
    const routes = [];
    this.projectProblemDataService.routeSettingIdsWithReload.forEach(routeSettingId => {
      const driver = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId];
      if (driver) {
        if (driver.id) {
          const driverId = driver.id;
          const driverData = this.projectProblemDataService.drivers[driverId];
          const routeIndex = this.projectProblemDataService.getRouteIndexByRouteSettingId(routeSettingId);
          const routeInfo = {
            name: driverData.userProfile.name,
            info: '',
            colour: this.colourService.colourCalculator(routeIndex)
          };
          routes.push(routeInfo);
        }
      }
    });
    this.milyService.reloadsDetected(routes);
  }

  updateWhiteBar(solutionData = null) {
    if (!solutionData) {
      const routeStartTime = moment(this.projectProblemDepartureDatetime).utc();
      solutionData = this.projectProblemViewUtils.calculateSolutionData(routeStartTime);
    }
    const stopPointsCountData = this.projectProblemViewUtils.calculateStopPointsCount();
    this.stopPointsCount = stopPointsCountData.stopPointsCount;
    this.completedStopPointsCount = stopPointsCountData.completedStopPointsCount;
    this.completedAndCancelledStopPointsCount = stopPointsCountData.completedAndCancelledStopPointsCount;

    const totalRouteDistance = solutionData.totalRouteDistance;
    const currentRoutesDistance = solutionData.currentRoutesDistance;
    const firstPointInRouteDatetimesArray = solutionData.firstPointInRouteDatetimesArray;
    const lastPointInRouteDatetimesArray = solutionData.lastPointInRouteDatetimesArray;
    const maxCurrentRouteDurationHours = solutionData.maxCurrentRouteDurationHours;

    if (totalRouteDistance) {
      this.totalRouteDistance = (totalRouteDistance / 1000).toFixed(2);
    }
    if (currentRoutesDistance) {
      this.currentRoutesDistance = (currentRoutesDistance / 1000).toFixed(2);
    }

    this.routeDurationSumHours = this.projectProblemViewUtils.calculateRouteDurationSumHours(solutionData);

    this.currentRouteDurationHours = 0;
    const durationH = Math.floor(this.routeDurationSumHours);
    const durationM = moment.duration((this.routeDurationSumHours - durationH), 'hours').asMinutes();
    if (lastPointInRouteDatetimesArray.length) {
      const routeEndTimes = lastPointInRouteDatetimesArray.map(function (routeEndTime) {
        return moment(routeEndTime);
      });
      const minRouteStartTime = moment(this.projectProblemDepartureDatetime).utc();
      const maxRouteEndTime = moment.max(routeEndTimes).utc();
      this.routeEndTime = maxRouteEndTime.format('HH:mm');
      this.routeDurationHours = moment.duration(maxRouteEndTime.diff(minRouteStartTime)).asHours();
      this.currentRouteDurationHours = maxCurrentRouteDurationHours;
      const durationHoursFloor = Math.floor(this.routeDurationHours);
      const durationMinutes = moment.duration((this.routeDurationHours - durationHoursFloor), 'hours').asMinutes();
      this.routeDuration = durationHoursFloor + 'h ' + Math.round(durationMinutes) + 'm';
      this.routeDurationSum = durationH + 'h ' + Math.round(durationM) + 'm';
      if (this.currentRouteDurationHours > this.routeDurationHours) {
        this.currentRouteDurationHours = this.routeDurationHours;
      }
      if (this.currentRouteDurationHours < 0) {
        this.currentRouteDurationHours = 0;
      }

      const currentDurationHoursFloor = Math.floor(this.currentRouteDurationHours);
      const currentDurationMinutes = moment.duration((this.currentRouteDurationHours - currentDurationHoursFloor), 'hours').asMinutes();
      this.currentRouteDuration = currentDurationHoursFloor + 'h ' + Math.round(currentDurationMinutes) + 'm';
    }
  }

  calculateAndDisplayCollaboratorAndPartnerDepotsOnMap() {
    const depots = [];
    Object.keys(this.relatedPartnerCompanyDepotsAddressesInMapById).forEach(depotId => {
      const depotAddress = this.relatedPartnerCompanyDepotsAddressesInMapById[depotId];
      depots.push({ address: depotAddress })
    });
    this.mapComponent.displayCollaboratorAndPartnerDepots(depots);
  }

  // load a stop point to map with all it's data
  loadStopToMap(currentStopPoint, bulk) {
    const isOptimized = (this.optimizationState === this.optimizationStates['OPTIMIZED']) ? true : false;
    const stopPointData = this.projectProblemViewUtils.getStopPointDataForMap(
      currentStopPoint, this.projectProblemDayOfWeek, this.maximumWeight, this.defaultTimeWindow, isOptimized
    );
    if (currentStopPoint.stopPoint) { currentStopPoint = currentStopPoint.stopPoint; }
    if (stopPointData && currentStopPoint.project_problem_id == this.projectProblemId) {
      const id = stopPointData.id;
      this.projectProblemDataService.stopPointsMarkerData[id] = stopPointData;
      this.mapComponent.addStopPoint(stopPointData, bulk);
      if (stopPointData.dropped) { this.droppedStopsCount++; }
      if (stopPointData.timeWindowState === 'limited' || stopPointData.timeWindowState === 'late') { this.limitedTimeWindowStopsCount++; }
      if (currentStopPoint.related_partner_company_depot_id) {
        this.relatedPartnerCompanyDepotsAddressesInMapById[currentStopPoint.related_partner_company_depot_id] = currentStopPoint.address;
      }
    }
  }

  startMarkerIconsGuide() {
    let backupNormalMarkerId = null, normalMarkerId = null, disabledMarkerId = null, overweightMarkerId = null, timeWindowMarkerId = null, priorityMarkerId = null;
    this.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
      const id = stopPoint.id;
      const markerData = this.projectProblemDataService.stopPointsMarkerData[id];
      if (markerData) {
        if (markerData.colour !== '#666666') {
          if (!backupNormalMarkerId) {
            backupNormalMarkerId = id;
          } else if (!disabledMarkerId && !markerData.enabled) {
            disabledMarkerId = id;
          } else if (!overweightMarkerId && markerData.overweight
            && !markerData.complete && !markerData.cancelled && !markerData.recurring && !markerData.merged &&
            markerData.timeWindowState !== 'late' && markerData.timeWindowState !== 'limited'
          ) {
            overweightMarkerId = id;
          } else if (!timeWindowMarkerId && markerData.timeWindowState !== 'default' && !markerData.complete && !markerData.cancelled) {
            timeWindowMarkerId = id;
          } else if (!priorityMarkerId && markerData.priority) {
            priorityMarkerId = id;
          } else if (!normalMarkerId && markerData.enabled && !markerData.overweight && !markerData.priority && markerData.timeWindowState === 'default') {
            normalMarkerId = id;
          }
        }
      }
    });
    if (!normalMarkerId) {
      normalMarkerId = backupNormalMarkerId;
    }
    this.mapComponent.addMarkerIdsForGuide(normalMarkerId, disabledMarkerId, overweightMarkerId, timeWindowMarkerId, priorityMarkerId);
    this.mapComponent.setMapBoundsForStopPoints();
    this.projectViewGuidedTourComponent.normalMarkerId = normalMarkerId;
    this.projectViewGuidedTourComponent.disabledMarkerId = disabledMarkerId;
    this.projectViewGuidedTourComponent.overweightMarkerId = overweightMarkerId;
    this.projectViewGuidedTourComponent.timeWindowMarkerId = timeWindowMarkerId;
    this.projectViewGuidedTourComponent.priorityMarkerId = priorityMarkerId;
  }

  loadStopsToMap() {
    let levels = this.projectProblemDataService.stopPointClustersByLevelByClusterId;
    const zoomLevels = [];
    let initialZoomLevels = [];
    Object.keys(levels).forEach(zoomLevel => {
      initialZoomLevels.push(Number(zoomLevel));
    });

    // sort the zoom levels to find the next larger zoom if needed
    initialZoomLevels.sort(function (a, b) {
      return a - b;
    });

    const minZoomLevel = initialZoomLevels[0];
    const maxZoomLevel = initialZoomLevels[initialZoomLevels.length - 1];


    // if there is a zoom level in the middle of others that doesn't exist, use the one above it
    if (maxZoomLevel && minZoomLevel) {
      for (let i = minZoomLevel; i < maxZoomLevel; i++) {
        if (!initialZoomLevels.includes(i)) {

          // find the next bigger existing zoom level to use
          let nextInitialLevel = null;
          initialZoomLevels.forEach(level => {
            if (!nextInitialLevel && level > i) {
              nextInitialLevel = level;
            }
          });
          if (nextInitialLevel && this.projectProblemDataService.stopPointClustersByLevelByClusterId[nextInitialLevel]) {
            this.projectProblemDataService.stopPointClustersByLevelByClusterId[i] = this.projectProblemDataService.stopPointClustersByLevelByClusterId[nextInitialLevel];
          } else {
            console.error('Something went wrong:');
            console.error('nextInitialLevel: ' + nextInitialLevel);
            console.error(this.projectProblemDataService.stopPointClustersByLevelByClusterId);
          }

        }
      }
    }
    levels = this.projectProblemDataService.stopPointClustersByLevelByClusterId;

    // add the cluster centers to the map
    Object.keys(levels).forEach(zoomLevel => {
      if (Number(zoomLevel) > 0 && Number(zoomLevel) < 21) {
        zoomLevels.push(Number(zoomLevel));
        Object.keys(levels[zoomLevel]).forEach(centerId => {
          const data = levels[zoomLevel][centerId];
          const coords = { lat: data.center.lat, lng: data.center.lon };
          const stopPointSequences = [];
          data.stopPointIds.forEach(stopPointId => {
            if (this.projectProblemDataService.stopPointSolutionData[stopPointId]) {
              stopPointSequences.push(this.projectProblemDataService.stopPointSolutionData[stopPointId]['sequence']);
            }
          });
          let colour = '#666666';
          if (data.routeIndex !== null) { colour = this.colourService.colourCalculator(data.routeIndex); }
          this.mapComponent.addClusterCenter(zoomLevel, coords, centerId, data.stopPointIds, stopPointSequences, colour);
        });
      }
    });
    this.mapComponent.clusterZoomLevels = zoomLevels;
    if (maxZoomLevel) {
      this.mapComponent.startClusterZoomLevel = maxZoomLevel;
    }

    this.droppedStopsCount = 0;
    this.limitedTimeWindowStopsCount = 0;
    let stopPoints = this.projectProblemDataService.stopPointsArray;
    this.relatedPartnerCompanyDepotsAddressesInMapById = {};
    stopPoints.forEach(currentStopPoint => {
      this.loadStopToMap(currentStopPoint, true);
    });
    this.calculateAndDisplayCollaboratorAndPartnerDepotsOnMap();
    this.mapComponent.addAllStopsToZoomLevels();
    this.mapComponent.showLevelsStopPoints();
    this.mapComponent.checkWhenDoneRendering();
  }

  checkForAlreadyOptimizingError(error) {
    if (error['error']['errors']) {
      if (error['error']['errors']['project']['problem']) {
        if (error['error']['errors']['project']['problem'][this.projectProblemId]) {
          if (error['error']['errors']['project']['problem'][this.projectProblemId]['base'][0] == "Already optimizing") {
            this.milyService.alreadyOptimizing();
          }
        } else {
          if (error['error']['errors']['project']['problem']['base'][0] == "Already optimizing") {
            this.milyService.alreadyOptimizing();
          }
        }
      }
    }
  }

  setProjectProblem() {
    this.stepsService.transmitProjectProblemData(this.projectProblemDepartureDatetime, this.problemTitle, this.projectProblemId, this.optimizationState);
    this.importerModalComponent.projectProblemId = this.projectProblemId;
    this.moveStopsModalComponent.projectProblemId = this.projectProblemId;
    this.stopModalComponent.projectProblemId = this.projectProblemId;
    this.helperModalComponent.projectProblemId = this.projectProblemId;
    this.portalIssuesModalComponent.projectProblemId = this.projectProblemId;
    this.stopFormModalComponent.setStopFormData(this.projectProblemId, this.projectProblemDepartureDatetime, this.entityStatus);
    this.voucherFormModalComponent.setStopFormData(this.projectProblemId, this.projectProblemDepartureDatetime);
    this.stopModalComponent.projectProblemDepartureDatetime = this.projectProblemDepartureDatetime;
    this.addStopModalComponent.setProjectProblem(this.projectProblemId, this.projectProblemDepartureDatetime, this.optimizationState === this.optimizationStates['INITIALIZATION'] ? true : false);
    this.projectProblemDataService.isStopFormModalProjectProblemIdLoaded = true;
    this.milyComponent.optimizationState = this.optimizationState;
  }

  loadProjectProblem(checkForSolution = false, setProjectProblem = false, updateOnlyStatuses = false) {
    const projectProblemData = this.projectProblemDataService.projectProblemData;
    this.projectId = projectProblemData['project_id'];
    this.projectProblemId = projectProblemData['id'];
    if (this.projectProblemDepartureDatetime !== projectProblemData['departure_datetime']) { console.warn('Project problem departure dateTime was changed to ' + projectProblemData['departure_datetime']); }
    this.projectProblemDepartureDatetime = projectProblemData['departure_datetime'];
    this.projectProblemDayOfWeek = this.days[moment(this.projectProblemDepartureDatetime).day()];
    this.problemDepartureDate = moment(this.projectProblemDepartureDatetime).format('DD MMM YYYY');
    this.problemDepartureTime = moment(this.projectProblemDepartureDatetime).format('HH:mm');
    this.problemTitle = projectProblemData['title'];
    if (this.problemTitle.includes('demo')) {
      this.inDemo = true;
      this.milyComponent.inDemo = true;
    }

    this.defaultTimeWindow = this.projectProblemViewUtils.setDefaultTimeWindow();

    this.optimizationState = projectProblemData['optimization_state'];
    this.isModified = projectProblemData['modified'];
    this.entityStatus = projectProblemData['entity_status'];

    this.isAutoGenerated = projectProblemData['is_auto_generated'] ?? false;

    this.mapComponent.entityStatus = this.entityStatus;
    this.stopModalComponent.projectProblemEntityStatus = this.entityStatus;

    this.dispatchButtonVisible = false;
    this.optimizeButtonVisible = false;
    this.reOptimizeButtonVisible = false;

    if (this.inDemo && localStorage.getItem('tourDone') !== 'true' && !this.tourMessageShown) {
      this.tourMessageShown = true;
      this.milyService.tour();
    }

    this.mapComponent.emptyDriverStartEndPoints();

    // display drivers' start and stop points if they are not depots
    Object.keys(this.projectProblemDataService.routeSettings).forEach(routeIndex => {
      let colour = '#666';
      const driver = this.projectProblemDataService.routeSettings[routeIndex].driver;
      const driverData = this.projectProblemDataService.drivers[driver.id];
      const routeSettingId = this.projectProblemDataService.routeSettings[routeIndex]['routeSetting']['id'];

      // if the driver has an active route, select the route's colour for the icon
      if (this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId]) {
        colour = this.colourService.colourCalculator(routeIndex);
      }

      if (driverData.driverLocations.locationStart) {
        if (driverData.driverLocations.locationStart.modelName === this.globals.addressModelName) {
          const lat = driverData.driverLocations.locationStart.address.lat;
          const lon = driverData.driverLocations.locationStart.address.lon;
          this.mapComponent.displayDriverStartEndPoint(lat, lon, colour)
        }
      }
      if (driverData.driverLocations.locationEnd) {
        if (driverData.driverLocations.locationEnd.modelName === this.globals.addressModelName) {
          const lat = driverData.driverLocations.locationEnd.address.lat;
          const lon = driverData.driverLocations.locationEnd.address.lon;
          this.mapComponent.displayDriverStartEndPoint(lat, lon, colour)
        }
      }
    });

    if (this.entityStatus !== this.globals.projectProblemEntityStatusConstants['COMPLETED']) {
      if (projectProblemData['confirm_needed'] !== this.globals.projectProblemConfirmRunThinkConstants['CONFIRM_RUN_THINK_NO_CONFIRM_NEEDED']) {
        const data = {
          keep_routes_modifications: this.keepModifications,
          lock_routes_solutions: this.lockModifications,
          cancel_confirm_and_abort_think: true
        };
        const optimizeUrl = this.optimizeUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
        const myObserver = {
          next: (response) => {
            this.updateProjectProblemStatus();
          },
          error: (error) => {
            console.error(error);
          },
          complete: () => {
          },
        };
        this.http.post(optimizeUrl, data).pipe(take(1)).subscribe(myObserver);
      } else {
        this.optimizationStateLabel = this.projectProblemInProgressLabel;
        switch (this.optimizationState) {
          case this.optimizationStates['OPTIMIZATION_ERROR']:
            this.optimizeButtonName = this.reOptimizeLabel;
            this.optimizeButtonVisible = true;
            this.reOptimizeButtonVisible = true;
            this.optimizationStateLabel = this.errorLabel;
            // this.optimizationStateLabel = this.globals.projectProblemOptimizationStateLabels[this.optimizationState];
            break;
          case this.optimizationStates['INITIALIZATION']:
            if (this.entityStatus === this.globals.projectProblemEntityStatusConstants['UN_DISPATCHED']) {
              this.optimizeButtonName = this.optimizeLabel;
              this.optimizeButtonVisible = true;
              if (!this.inDemo && !this.uploadMessageShown) {
                this.milyService.uploadPoints();
                this.uploadMessageShown = true;
              }
              // this.optimizationStateLabel = this.globals.projectProblemOptimizationStateLabels[this.optimizationState];
            }
            break;
          case this.optimizationStates['OPTIMIZED']:
            if (this.entityStatus === this.globals.projectProblemEntityStatusConstants['UN_DISPATCHED']) {
              if (this.isModified) {
                this.optimizeButtonName = this.reOptimizeLabel;
                this.optimizeButtonVisible = true;
                this.reOptimizeButtonVisible = true;
              } else {
                this.dispatchButtonName = this.dispatchLabel;
                this.dispatchButtonVisible = true;
              }
              // this.optimizationStateLabel = this.globals.projectProblemOptimizationStateLabels[this.optimizationState];
            } else if (this.entityStatus === this.globals.projectProblemEntityStatusConstants['DISPATCHED']) {
              // this.oldRouteSwitchVisible = true;
              // this.optimizationStateLabel = this.globals.projectProblemEntityStatusLabels[this.entityStatus];
            } else if (this.entityStatus === this.globals.projectProblemEntityStatusConstants['RE_DISPATCH']) {
              if (this.isModified) {
                this.optimizeButtonName = this.optimizeLabel;
                this.optimizeButtonVisible = true;
                this.reOptimizeButtonVisible = true;
              } else {
                this.dispatchButtonName = this.reDispatchLabel;
                this.dispatchButtonVisible = true;
              }
              // this.oldRouteSwitchVisible = true;
              // this.optimizationStateLabel = this.globals.projectProblemEntityStatusLabels[this.entityStatus];
            }
            break;
          default:
            // this.optimizationStateLabel = this.globals.projectProblemOptimizationStateLabels[this.optimizationState];
            // loading
            break;
        }
      }
    } else {
      this.optimizationStateLabel = this.completedLabel;
      // this.oldRouteSwitchVisible = true;
      // this.optimizationStateLabel = this.globals.projectProblemEntityStatusLabels[this.entityStatus];

    }
    if (!updateOnlyStatuses) {
      if (checkForSolution) {
        this.checkForSolution();
      } else {
        this.updateWhiteBar();
      }
      if (this.dispatchStopPointsCondition) {
        if (this.limitedTimeWindowStopsCount > 0 || this.droppedStopsCount > 0) {
          this.milyService.stopPointsCondition(this.limitedTimeWindowStopsCount, this.droppedStopsCount);
          this.dispatchStopPointsCondition = false;
        }
      }
      if (setProjectProblem) {
        this.setProjectProblem();
        if (this.optimizationState !== this.globals.projectProblemOptimizationStateConstants['INITIALIZATION']) {
          this.milyService.optimization('none');
          this.milyComponent.displayOptimizationStatus(this.optimizationState);
        }
        if (this.limitedTimeWindowStopsCount > 0 || this.droppedStopsCount > 0) {
          this.milyService.stopPointsCondition(this.limitedTimeWindowStopsCount, this.droppedStopsCount);
        }
        if (this.entityStatus === this.globals.projectProblemEntityStatusConstants['COMPLETED']) {
          // this.milyService.complete();
          // this.displayReportsMessage();
          this.milyService.projectProblemReport();
        } else if (
          this.entityStatus === this.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
          this.entityStatus === this.globals.projectProblemEntityStatusConstants['RE_DISPATCH']
        ) {
          this.milyService.dispatch();
        }
      }
    } else {
      this.updateWhiteBar();
    }

    this.helperModalComponent.dataLoading = true;
    this.helperModalComponent.getHelperItems().pipe(take(1)).subscribe(response => {
      if (
        !this.importerModalComponent.milyOpenedForErrorStops &&
        !this.milyOpenedForImporterErrorStops
      ) {
        // this.helperModalComponent.stopPointsDataArray = [];
        this.helperModalComponent.setHelperDataFromRequest(response['items']);
        if (this.helperModalComponent.importerStopPointsDataArray.length) {
          this.milyOpenedForImporterErrorStops = true;
          this.importerModalComponent.milyOpenedForErrorStops = true;
          this.milyService.helper('importer');
        }
      }
      if (!this.milyOpenedForCanceledErrorStops) {
        // this.helperModalComponent.stopPointsDataArray = [];
        this.helperModalComponent.setHelperDataFromRequest(response['items']);
        if (this.helperModalComponent.canceledStopPointsDataArray.length) {
          this.milyOpenedForCanceledErrorStops = true;
          this.milyService.helper('canceled');
        }
      }
    });
  }

  closeOptimizationOptions() {
    document.getElementById('optimize-options-container').classList.remove('visible');
  }

  optimizeButtonClick() {
    if (!this.checkForFinishedOptimizationInterval) {
      if (
        Object.keys(this.projectProblemDataService.stopPointModifications).length &&
        this.entityStatus !== this.globals.projectProblemEntityStatusConstants['DISPATCHED']
      ) {
        const optionsContainer = document.getElementById('optimize-options-container');
        if (optionsContainer.classList.contains('visible')) {
          if (this.optimizationMode === 'without') {
            this.keepModifications = false;
            this.lockModifications = false;
          } else if (this.optimizationMode === 'keep') {
            this.keepModifications = true;
            this.lockModifications = false;
          } else if (this.optimizationMode === 'lock') {
            this.keepModifications = true;
            this.lockModifications = true;
          }
          this.optimizeProblem();
          optionsContainer.classList.remove('visible');
        } else {
          if (this.optimizationMode === 'without') {
            this.optimizationMode = 'keep';
          }
          optionsContainer.classList.add('visible');
        }
      } else {
        this.keepModifications = false;
        this.lockModifications = false;
        this.optimizeProblem();
      }
    }
  }

  optimizeProblem() {
    this.optimizeButtonVisible = false;
    this.reOptimizeButtonVisible = false;
    this.dispatchButtonVisible = false;
    const data = {
      keep_routes_modifications: this.keepModifications,
      lock_routes_solutions: this.lockModifications,
    };
    let option = 'optimize';
    if (this.keepModifications) {
      option = 'optimizeWithModifications';
    }
    if (this.lockModifications) {
      option = 'optimizeWithModificationsKeepRoutes';
    }
    this.milyService.optimization(option);
    this.milyService.openMily();

    let optimizeAfterAlreadyOptimizingInterval, refreshIntervalId;

    function optimizingChecker() {
      this.http.get('api/v1/project/problems/' + this.projectProblemId).pipe(take(1)).subscribe(problemResponse => {
        this.lastLoadedOptimization = problemResponse['item']['projectProblem']['latest_optimization_datetime'];
        this.milyComponent.displayOptimizationStatus(problemResponse['item']['projectProblem']['optimization_state']);
        this.optimizationLogExtraInfo = problemResponse['item']['projectProblem']['optimizationLogExtraInfo'];
        if (problemResponse['item']['projectProblem']['confirm_needed'] !== this.globals.projectProblemConfirmRunThinkConstants['CONFIRM_RUN_THINK_NO_CONFIRM_NEEDED']) {
          this.milyComponent.optimizeAlert = this.optimizeAlerts[problemResponse['item']['projectProblem']['confirm_needed']];
          clearInterval(refreshIntervalId);
          if (this.mergeAlertSubscription) {
            this.mergeAlertSubscription.unsubscribe();
          }
          this.mergeAlertSubscription = this.milyComponent.optimizeObservable.pipe(take(1)).subscribe(res => {
            if (res === 'ok') {
              data['confirm_run_think_with'] = problemResponse['item']['projectProblem']['confirm_needed'];
            } else {
              data['cancel_confirm_and_abort_think'] = true;
            }

            this.http.post(optimizeUrl, data).pipe(take(1)).subscribe(myObserver);
          });
        } else {
          this.optimizationStateLabel = this.projectProblemInProgressLabel;
          if (problemResponse['item']['projectProblem']['optimization_state'] === Number(this.optimizationStates['OPTIMIZED'])) {
            this.optimizationState = problemResponse['item']['projectProblem']['optimization_state'];
            this.milyComponent.optimizationState = this.optimizationState;
            this.viewProjectProblemService.setActiveStopPointsGrid(this.optimizationState);
            this.dispatchStopPointsCondition = true;
            this.updateProjectProblem(true);
            this.viewProjectProblemService.updateDriversModalGrid();
            this.milyService.openMily();
            clearInterval(refreshIntervalId);
            this.mapComponent.milyMapMessage = this.thinkButtonFindMsg;
            setTimeout(() => {
              this.mapComponent.milyMapMessage = '';
            }, 5000);
          } else if (problemResponse['item']['projectProblem']['optimization_state'] === Number(this.optimizationStates['OPTIMIZATION_ERROR'])) {
            this.optimizationStateLabel = this.errorLabel;
            this.optimizationState = problemResponse['item']['projectProblem']['optimization_state'];
            this.optimizeButtonVisible = true;
            this.reOptimizeButtonVisible = true;
            this.optimizeButtonName = this.reOptimizeLabel;
            this.updateProjectProblemStatus();
            this.milyService.openMily();
            clearInterval(refreshIntervalId);
          } else if (problemResponse['item']['projectProblem']['optimization_state'] === Number(this.optimizationStates['INITIALIZATION'])) {
            this.optimizationState = problemResponse['item']['projectProblem']['optimization_state'];
            this.optimizeButtonVisible = true;
            this.optimizeButtonName = this.optimizeLabel;
            this.updateProjectProblemStatus();
            clearInterval(refreshIntervalId);
          }
        }
      });
    }

    const optimizeUrl = this.optimizeUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    const myObserver = {
      next: (response) => {
        clearInterval(optimizeAfterAlreadyOptimizingInterval);
        optimizeAfterAlreadyOptimizingInterval = null;

        this.keepModifications = false;
        this.lockModifications = false;
        if (this.mergeAlertSubscription) {
          this.mergeAlertSubscription.unsubscribe();
        }
        optimizingChecker.bind(this)();

        // This causes PP to stop tracking when user re-optimizes a dispatched PP and gets an optimization error
        // if (this.trackVehiclesInterval) {
        //   clearInterval(this.trackVehiclesInterval);
        // }

        refreshIntervalId = setInterval(optimizingChecker.bind(this), 5000);
        this.intervals.push(refreshIntervalId);
      },
      error: (error) => {
        console.error(error);

        // alert if pp is already being optimized & start performing checks to see if the pp has been optimized
        if (error.status == 409 && !this.checkForFinishedOptimizationInterval) {
          this.milyComponent.generateAlertMessage(this.alreadyOptimizingAlertMessage);
          optimizingChecker.bind(this)();
          if (this.trackVehiclesInterval) {
            clearInterval(this.trackVehiclesInterval);
          }

          refreshIntervalId = setInterval(optimizingChecker.bind(this), 5000);
          this.intervals.push(refreshIntervalId);
        }
      },
      complete: () => {
      },
    };
    this.http.post(optimizeUrl, data).pipe(take(1)).subscribe(myObserver);
  }

  dispatchProblem() {
    if (this.globals.demoState !== this.globals.companyDemoStateConstants['COMPLETED']) {
      this.milyService.alert(this.noDemoAlert);
    } else {
      this.optimizeButtonVisible = false;
      this.dispatchButtonVisible = false;
      const dispatchUrl = this.dispatchUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      const myObserver = {
        next: (response) => {
        },
        error: (error) => {
          console.error(error);
          this.dispatchButtonVisible = true;
        },
        complete: () => {
          this.entityStatus = this.globals.projectProblemEntityStatusConstants['DISPATCHED'];

          this.mapComponent.entityStatus = this.entityStatus;
          this.stopModalComponent.projectProblemEntityStatus = this.entityStatus;

          this.milyService.dispatch();
          this.dispatchButtonVisible = false;
          this.updateProjectProblemStatus();
          this.trackVehicles();
          this.autoUpdateStopIcons();
          this.autoGetIssues();
          if (this.globals.foodModeEnabled) {
            // this.autoRefresh();
          }
        },
      };
      this.http.post(dispatchUrl, {}).pipe(take(1)).subscribe(myObserver);
    }
  }

  switchChanged() {
    if (this.showOldRoute) {
      this.enableOldRoute();
    } else {
      this.trackVehiclesOldRouteInterval.forEach(trackVehiclesOldRouteInterval => {
        clearInterval(trackVehiclesOldRouteInterval);
      });
      this.lastIdOldRoute = 0;
      this.trackVehiclesOldRouteInterval = [];
      this.mapComponent.removePreviousRoutes();
    }
  }

  enableOldRoute() {
    this.vehicleIds.forEach(id => {
      this.vehiclesOldRouteTracker(id);
      this.trackVehiclesOldRouteInterval.push(setInterval(() => {
        this.vehiclesOldRouteTracker(id);
      }, 1000));
    });
  }

  vehiclesOldRouteTracker(id) {
    if (this.trackOldRouteRequestDone[id]) {
      const oldRouteParams = `?lastLocationId=${this.lastIdOldRoute}&projectProblemId=${this.projectProblemId}&vehicleId=${id}`;
      this.getVehicleOldRouteLocationsData(oldRouteParams, id).pipe(take(1)).subscribe(
        trackOldRouteResponse => {
          this.trackOldRouteRequestDone[id] = true;
          const vehicleLocationData = trackOldRouteResponse['item'];
          this.lastIdOldRoute = vehicleLocationData.oldRoute.lastLocationId;
          let oldRouteColour;
          vehicleLocationData.oldRoute.lines.forEach((line, index) => {
            oldRouteColour = this.colourService.oldRouteColourCalculator(this.vehicleData[id]['routeIndex']);
            this.mapComponent.addPreviousRoutes(line.line, oldRouteColour, line.time);
          });
        },
        error => {
          this.trackRequestDone = true;
        }
      );
    }
  }

  autoGetNewOptimizations() {
    if (this.autoGetNewOptimizationsInterval) {
      clearInterval(this.autoGetNewOptimizationsInterval);
    }

    // search for new shipments if the pp is not completed
    if (this.entityStatus !== this.globals.projectProblemEntityStatusConstants['COMPLETED']) {
      // search for new shipments every 10 minutes
      let refreshIntervalMinutes = 10;
      if (this.globals.foodModeEnabled) {
        refreshIntervalMinutes = 1;
        // refreshIntervalMinutes = 0.5;
      }
      const refreshIntervalMilliseconds = refreshIntervalMinutes * 60 * 1000;
      this.autoGetNewOptimizationsInterval = setInterval(getLastOptimization.bind(this), refreshIntervalMilliseconds);
      this.intervals.push(this.autoGetNewOptimizationsInterval);
      getLastOptimization.bind(this)();
    }

    function getLastOptimization() {
      let url = 'api/v1/project/problems/' + this.projectProblemId;

      this.http.get(url).pipe(take(1)).subscribe(response => {
        if (response['item'] && response['item']['projectProblem']) {
          const lastOptimization = response['item']['projectProblem']['latest_optimization_datetime'];
          if (lastOptimization && lastOptimization !== this.lastLoadedOptimization) {
            if (this.globals.foodModeEnabled) {
              this.mapComponent.milyMapMessage = this.newStopsFoodMsg;
              this.milyService.alert(this.newStopsFoodMsg);
            } else {
              this.mapComponent.milyMapMessage = this.newStopsFormDriversMsg;
            }
            this.reloadOptimizations();
            this.lastLoadedOptimization = lastOptimization;
          }
        }
      });
    }

  }

  getStopPointsFromDrivers() {
    if (this.getStopPointsFromDriversInterval) {
      clearInterval(this.getStopPointsFromDriversInterval);
    }

    // search for new shipments if the pp is not completed
    if (this.entityStatus !== this.globals.projectProblemEntityStatusConstants['COMPLETED']) {
      // search for new shipments every 10 minutes
      const refreshIntervalMinutes = 10;
      // const refreshIntervalMinutes = 0.5;
      const refreshIntervalMilliseconds = refreshIntervalMinutes * 60 * 1000;
      this.getStopPointsFromDriversInterval = setInterval(requestAndLoadStopPointsFromDrivers.bind(this), refreshIntervalMilliseconds);
      this.intervals.push(this.getStopPointsFromDriversInterval);
      requestAndLoadStopPointsFromDrivers.bind(this)();
    }

    function requestAndLoadStopPointsFromDrivers() {
      let url = this.warehouseUrl;
      url += '?stopPointId=null';
      url += '&limit=1';
      url += '&isSentFromDriver=true';
      url += `&depotId=${this.projectProblemDataService.projectDepotId}`;

      this.http.get(url).pipe(take(1)).subscribe(response => {
        if (response['items']) {
          if (response['items']['stopPoints']) {
            if (response['items']['stopPoints'].length) {
              this.milyService.loadNewStopPointsFromDrivers();
            }
          }
        }
      });
    }

  }

  getCollaboratorAffectedStopPoints() {
    this.http.get('api/v1/collaborator-affected-stop-points?projectProblemId=' + this.projectProblemId).subscribe(res => {
      if (res['items'].length) {
        this.milyService.cancelCollaboratorStopPoints(res['items']);
      }
    });
  }

  autoGetNewShipments() {if (this.getNewShipmentsInterval) {
      clearInterval(this.getNewShipmentsInterval);
    }

    // search for new shipments if the pp is not completed (keep looking for new shipments even in pp is completed only if date is >= today)
    if (this.entityStatus !== this.globals.projectProblemEntityStatusConstants['COMPLETED']
      || (this.entityStatus === this.globals.projectProblemEntityStatusConstants['COMPLETED']
        && moment(this.projectProblemDepartureDatetime).isSameOrAfter(moment().utc(), 'day'))) {
      // search for new shipments every 10 minutes
      let refreshIntervalMinutes = 10;

      // [food mode courier]: polling time -> 1 min
      if (this.globals.foodModeEnabled) {
        refreshIntervalMinutes = 1;
      }
      // const refreshIntervalMinutes = 0.1;
      const refreshIntervalMilliseconds = refreshIntervalMinutes * 60 * 1000;
      this.getNewShipmentsInterval = setInterval(getNewShipments.bind(this), refreshIntervalMilliseconds);
      this.intervals.push(this.getNewShipmentsInterval);
      getNewShipments.bind(this)();
    }

    function getNewShipments() {
      let url = this.warehouseUrl;
      url += '?stopPointId=null';
      url += '&limit=1';
      url += '&isFromCollaborator=true';
      url += `&depotId=${this.projectProblemDataService.projectDepotId}`;

      this.http.get(url).pipe(take(1)).subscribe(response => {
        if (response['items']) {
          if (response['items']['stopPoints']) {
            if (response['items']['stopPoints'].length) {
              this.milyService.loadNewShipments();
            }
          }
        }
      });
    }

  }

  autoGetIssues() {
    let issuesUrl = this.issuesUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);

    const portalIssueTypes = [
      this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_ADDRESS_CHANGED'],
      this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_GENERIC_CANCEL'],
      this.globals.projectProblemIssueTypes['ISSUE_TYPE_PORTAL_TIME_WINDOW_CHANGED'],
    ];

    if (this.getIssuesInterval) {
      clearInterval(this.getIssuesInterval);
    }

    if (
      this.entityStatus === this.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
      this.entityStatus === this.globals.projectProblemEntityStatusConstants['RE_DISPATCH']
    ) {
      this.stopPointIdsWithIssuesUpdated = [];
      // get issues every 10 minutes
      this.getIssuesInterval = setInterval(getIssues.bind(this), 600000);
      this.intervals.push(this.getIssuesInterval);
      getIssues.bind(this)();
    }

    function getIssues() {
      this.http.get(issuesUrl).pipe(take(1)).subscribe(response => {
        const issues = response['items'];
        if (issues) {
          if (Object.keys(issues).length) {
            let stopsCount = 0, newStopsCount = 0;
            const idsToUpdate = [];

            Object.keys(issues).forEach(stopPointId => {
              if (issues[stopPointId]) {
                let stopHasPortalIssue = false, newIssueFound = false;

                // check if this stop has portal issues, and if it's new
                issues[stopPointId].forEach(issue => {
                  if (portalIssueTypes.includes(issue.type)) {
                    stopHasPortalIssue = true;
                    if (issue.state === this.globals.projectProblemIssueStates['ISSUE_STATE_NEW']) {
                      newIssueFound = true;
                    }
                  }
                });

                if (stopHasPortalIssue) {
                  stopsCount++;
                  const stopPointData = this.projectProblemDataService.stopPoints[stopPointId];
                  if (stopPointData) {
                    if (!this.stopPointIdsWithIssuesUpdated.includes(stopPointId)) {
                      idsToUpdate.push(stopPointId);
                      this.stopPointIdsWithIssuesUpdated.push(stopPointId);
                    }
                  }
                  if (newIssueFound) {
                    newStopsCount++;
                  }
                }
              }
            });

            // if there are stops that are not updated in the map (so new for FE)
            // update the stops and throw mily message
            if (idsToUpdate.length) {
              this.projectProblemDataService.loadSpecificStopPoints(idsToUpdate).pipe(take(1)).subscribe(response => {
                this.projectProblemDataService.updateStopPointsAddData(response['items']['stopPoints'], true);
                this.portalIssuesModalComponent.issues = issues;
                this.milyService.loadPortalPoints(stopsCount, newStopsCount);
                this.mapComponent.milyMapMessage = this.stopsChangedInPortal;
                setTimeout(() => {
                  this.mapComponent.milyMapMessage = '';
                }, 5000);
              });
            }
          }
        }
      });
    }
  }

  autoRefresh() {
    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval);
    }

    const refreshIntervalMinutes = 1;
    const refreshIntervalMilliseconds = refreshIntervalMinutes * 60 * 1000;

    this.autoRefreshInterval = setInterval(refresh.bind(this), refreshIntervalMilliseconds);
    this.intervals.push(this.autoRefreshInterval);

    function refresh() {
      this.reloadOptimizations();
    }
  }

  autoUpdateStopIcons() {
    if (this.autoUpdateStopsFulfillmentStatusInterval) {
      clearInterval(this.autoUpdateStopsFulfillmentStatusInterval);
    }

    if (
      this.entityStatus === this.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
      this.entityStatus === this.globals.projectProblemEntityStatusConstants['RE_DISPATCH']
    ) {
      const refreshIntervalMinutes = 2;
      const refreshIntervalMilliseconds = refreshIntervalMinutes * 60 * 1000;
      this.autoUpdateStopsFulfillmentStatusInterval = setInterval(updateStopsFulfillmentStatus.bind(this), refreshIntervalMilliseconds);
      this.intervals.push(this.autoUpdateStopsFulfillmentStatusInterval);
    }

    function updateStopsFulfillmentStatus() {
      const solutionInfoByRouteSetting = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'];
      const sequenceArrayPerRouteSettingId = this.projectProblemDataService.sequenceArrayPerRouteSettingId;
      // for each route, get the last visited sp
      let stopsToUpdate = [];
      Object.keys(solutionInfoByRouteSetting).forEach(routeSettingId => {
        // const stopPointsData = solutionInfoByRouteSetting[routeSettingId];
        const sequenceForThisRouteSettingId = sequenceArrayPerRouteSettingId[routeSettingId];
        if (sequenceForThisRouteSettingId.length) {
          const lastArrivedPointIdInRoute = this.dateTimeCalculatorService.getDriversLastArrivedStopPoint(routeSettingId);
          if (lastArrivedPointIdInRoute) {
            // get the next 5 stops after the last visited sp
            const lastArrivedPointIndexInRoute = sequenceForThisRouteSettingId.indexOf(lastArrivedPointIdInRoute);
            if (lastArrivedPointIndexInRoute) {
              if (!isNaN(lastArrivedPointIndexInRoute)) {
                const newStopsToUpdate = this.dateTimeCalculatorService.findStopsToUpdate(sequenceForThisRouteSettingId, lastArrivedPointIndexInRoute);
                stopsToUpdate = stopsToUpdate.concat(newStopsToUpdate);
              }
            }
          } else {
            const newStopsToUpdate = this.dateTimeCalculatorService.findStopsToUpdate(sequenceForThisRouteSettingId, 0);
            stopsToUpdate = stopsToUpdate.concat(newStopsToUpdate);
          }
        }
      });

      if (stopsToUpdate.length) {
        this.projectProblemDataService.loadSpecificStopPointsFulfillmentStatus(stopsToUpdate).pipe(take(1)).subscribe(response => {
          this.projectProblemDataService.updateStopPointsAddData(response['items']['stopPoints'], true);
          this.projectProblemDataService.calculateDriversStopPointsPercentage();
          this.viewProjectProblemService.updateDriversGrid();
          this.updateWhiteBar();
        });
      }
    }
  }

  trackVehicles() {
    const routeSettingsUrl = this.routeSettingsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
    this.http.get(routeSettingsUrl).pipe(take(1)).subscribe(routeSettings => {
      this.vehicleIds = [];
      routeSettings['items'].forEach(route => {
        this.vehicleIds.push(route.vehicle.id);
        this.vehicleSetting[route.vehicle.id] = route.routeSetting.id;
        this.vehicleIdPlateNumber[route.vehicle.id] = route.vehicle.plate_number;
        this.vehicleData[route.vehicle.id] = {
          driverId: route.driver.id,
          routeIndex: route.routeSetting.route_index
        };
      });
      let vehicleIdsString = '';
      this.vehicleIds.forEach(id => {
        this.trackOldRouteRequestDone[id] = true;
        if (vehicleIdsString === '') {
          vehicleIdsString = id;
        } else {
          vehicleIdsString = vehicleIdsString + ',' + id;
        }
        if (this.showOldRoute) {
          this.vehiclesOldRouteTracker(id);
          this.trackVehiclesOldRouteInterval.push(setInterval(() => {
            this.vehiclesOldRouteTracker(id);
          }, 1000));
        }
      });
      let params, colour, coords, dateTime, plateNumber, driverId, driverName, driverImage, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour;

      // if (this.trackVehiclesInterval) {
      //   clearInterval(this.trackVehiclesInterval);
      // }
      // ANCHOR vehicles tracker
      vehiclesTracker.bind(this)();
      if (this.entityStatus !== this.globals.projectProblemEntityStatusConstants['COMPLETED']) {
        if (!localStorage.getItem('stopVehicleTracking')) {
          if (!this.trackVehiclesInterval) {
            this.trackVehiclesInterval = setInterval(vehiclesTracker.bind(this), 10000);
            this.intervals.push(this.trackVehiclesInterval);
          }
        } else {
          console.warn('vehicle tracking is disabled with localStorage option')
        }
      }

      function vehiclesTracker() {
        if (this.trackRequestDone) {
          params = `?isLast=true&vehicle_ids=${vehicleIdsString}&project_problem_id=${this.projectProblemId}`;
          this.getVehicleLocationsData(params).pipe(take(1)).subscribe(
            trackResponse => {
              this.trackRequestDone = true;
              // this.lastId = trackResponse['isLast'];
              let colourIndex = 0;
              const vehicleLocationData = trackResponse['items'];
              let vehicle;
              Object.keys(vehicleLocationData).forEach(vehicleId => {
                colourIndex = this.projectProblemDataService.vehicleData[vehicleId]['routeIndex'];
                vehicle = vehicleLocationData[vehicleId];
                colour = this.colourService.colourCalculator(colourIndex);
                if (vehicle[vehicle.length - 1]) {
                  coords = {
                    lat: vehicle[vehicle.length - 1].lat,
                    lon: vehicle[vehicle.length - 1].lon
                  };
                  dateTime = vehicle[vehicle.length - 1].datetime;
                  plateNumber = this.vehicleIdPlateNumber[vehicleId];
                  driverId = this.projectProblemDataService.vehicleData[vehicleId]['driverId'];
                  driverName = this.projectProblemDataService.drivers[driverId].userProfile.name;
                  driverImage = this.projectProblemDataService.drivers[driverId].driverImage;
                  vehicleType = this.projectProblemDataService.drivers[driverId].vehicle.vehicle_type;
                  if (this.projectProblemDataService.stopPointsProgress[driverId]) {
                    driverFinishedStops = this.projectProblemDataService.stopPointsProgress[driverId].doneStopsCount;
                    driverTotalStops = this.projectProblemDataService.stopPointsProgress[driverId].totalStopsCount;
                  }
                  driverTotalProgressDegrees = driverFinishedStops / driverTotalStops * 180;
                  driverColour = colour;
                } else {
                  dateTime = null;
                }
                if (coords && vehicleId && dateTime) {
                  this.viewProjectProblemService.trackVehicles(vehicleId, colour, coords, dateTime, plateNumber, driverName, driverImage, null, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour);
                }

                if (vehicle[0]) {
                  // set appropriate icon & and angle based on vehicle static location
                  const vehicleData = {
                    id: vehicleId,
                    driverName: driverName,
                    colourIndex: colourIndex,
                    coords: {
                      lat: vehicle[0].lat,
                      lon: vehicle[0].lon
                    },
                    angle: vehicle[0].angle,
                    isStatic: vehicle[0].is_static,
                  };
                  this.viewProjectProblemService.updateVehicleIcon(vehicleData);
                }
              });
            },
            error => {
              this.trackRequestDone = true;
            }
          );
        }
      }
    });
  }

  updateProjectProblemStatus() {
    this.projectProblemDataService.loadProjectProblemData().pipe(take(1)).subscribe(response => {
      this.projectProblemDataService.projectProblemData = response['item']['projectProblem'];
      this.loadProjectProblem(false, false, true);
    });
  }

  updateProjectProblem(afterOptimize = false) {
    this.getRoutingSettings();
    this.projectProblemDataService.resetFlags();
    this.projectProblemDataService.projectProblemId = this.projectProblemId;
    if (afterOptimize) {
      this.mapComponent.emptyClusters();
      this.projectProblemDataService.loadDataAfterOptimize();
    } else {
      this.projectProblemDataService.loadData();
    }
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    this.intervals.push(dataRefreshIntervalId);

    function dataChecker() {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        this.lastLoadedOptimization = this.projectProblemDataService.projectProblemData['latest_optimization_datetime'];
        this.maximumWeight = this.projectProblemViewUtils.calculateAverageWeight();
        this.loadProjectProblem(true);
        // this.projectProblemModalComponent.projectProblemModalDummyComponent.stopsModalGridComponent.updateSelectDrivers();
        // this.projectProblemModalComponent.projectProblemModalDummyComponent.driversModalGridComponent.loadRouteSettings();
        // this.viewProjectProblemService.updateSelectDrivers();
        // this.viewProjectProblemService.loadRouteSettings();
      }
    }
  }

  updateProjectProblemRouteSettings() {
    this.projectProblemDataService.updateRouteSettings();
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    this.intervals.push(dataRefreshIntervalId);

    function dataChecker() {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        this.mapComponent.milyMapMessage = this.newStopsFormDriversMsg;
        this.loadProjectProblem(true);
        this.projectProblemModalComponent.projectProblemModalDummyComponent.stopsModalGridComponent.updateSelectDrivers();
        this.projectProblemModalComponent.projectProblemModalDummyComponent.driversModalGridComponent.loadRouteSettings();
        // this.viewProjectProblemService.updateSelectDrivers();
        // this.viewProjectProblemService.loadRouteSettings();
      }
    }
  }

  getRoutingSettings(){
    this.routingSettingsService
      .apiProjectProblemRoutingSettingsGet({ id: this.projectProblemId })
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this.routingSettings = res["items"];
      });
  }

  public getVehicleLocationsData(params: any): Observable<any> {
    this.trackRequestDone = false;
    return this.http.get('api/v1/vehicle-locations' + params);
  }

  public getVehicleOldRouteLocationsData(params: any, id): Observable<any> {
    this.trackOldRouteRequestDone[id] = false;
    return this.http.get('api/v1/vehicle-old-routes' + params);
  }

  startTour() {
    this.startMarkerIconsGuide();
    this.projectViewGuidedTourComponent.startGeneralGuide();
  }

  backToProjectDays() {
    if (this.projectId) {
      this.router.navigate(['projects/' + this.projectId]);
    }
  }

  ngAfterContentInit() {
    this.modal = this.elRef.nativeElement.querySelector('.top-right-modal');
  }

  getTranslations() {
    const globalsOptimizeConfirmConstants = this.globals.projectProblemConfirmRunThinkConstants;
    this.listen.push(this.translate.get('ROUTING_SETTINGS').subscribe((res: string) => {
      this.routingSettingsTranslations = res;
    }));
    this.listen.push(this.translate.get('GENERIC.DEFAULT_PROJECT_DAY_TITLE').subscribe((res: string) => {
      this.defaultProblemTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.RETURN').subscribe((res: string) => {
      this.returnTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.HOURS_SHORT').subscribe((res: string) => {
      this.hoursShortMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.MINUTES_SHORT').subscribe((res: string) => {
      this.minutesShortMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.THINK_BUTTON_FIND').subscribe((res: string) => {
      this.thinkButtonFindMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.LOADING_STOPS').subscribe((res: string) => {
      this.loadingStopsMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.ADDING_STOPS_FROM_DRIVERS').subscribe((res: string) => {
      this.newStopsFormDriversMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.ADDING_STOPS_FOOD').subscribe((res: string) => {
      this.newStopsFoodMsg = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.OPTIMIZE').subscribe((res: string) => {
      this.optimizeLabel = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.DISPATCH').subscribe((res: string) => {
      this.dispatchLabel = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.RE_OPTIMIZE').subscribe((res: string) => {
      this.reOptimizeLabel = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.RE_DISPATCH').subscribe((res: string) => {
      this.reDispatchLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS').subscribe((res: string) => {
      this.inProgressLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.PP_IN_PROGRESS').subscribe((res: string) => {
      this.projectProblemInProgressLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.completedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.ERROR').subscribe((res: string) => {
      this.errorLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.CANCEL_REASON_UNABLE_TO_DELIVER').subscribe((res: string) => {
      this.cancelReasonUnableToDeliverLabel = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.MERGE_ALERT').subscribe((res: string) => {
      this.mergeAlert = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => {
      this.sequenceAlert = res;
    }));
    this.listen.push(this.translate.get('ALERTS.DELETE_ALERT').subscribe((res: string) => {
      this.deleteAlert = res;
    }));
    this.listen.push(this.translate.get('ALERTS.NO_DISPATCH').subscribe((res: string) => {
      this.noDemoAlert = res;
    }));
    this.listen.push(this.translate.get('ALERTS.STOPS_CHANGED_IN_PORTAL').subscribe((res: string) => {
      this.stopsChangedInPortal = res;
    }));
    this.listen.push(this.translate.get('ALERTS.DROP_MODIFICATIONS_BECAUSE_OF_SIMPLE_THINK_WITH_MODIFICATIONS').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_DROP_MODIFICATIONS_BECAUSE_OF_SIMPLE_THINK_WITH_MODIFICATIONS']] = res;
    }));
    this.listen.push(this.translate.get('ALERTS.KEEP_ROUTE_MODIFICATIONS_BECAUSE_OF_THINK_AFTER_DISPATCH_WITH_UN_ASSIGNED_NODES').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_KEEP_ROUTE_MODIFICATIONS_BECAUSE_OF_THINK_AFTER_DISPATCH_WITH_UN_ASSIGNED_NODES']] = res;
    }));
    this.listen.push(this.translate.get('ALERTS.SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITHOUT_MODIFIED_ROUTES').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITHOUT_MODIFIED_ROUTES']] = res;
    }));
    this.listen.push(this.translate.get('ALERTS.SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITH_UN_ASSIGNED_NODES').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITH_UN_ASSIGNED_NODES']] = res;
    }));
    this.listen.push(this.translate.get('MILY.ALREADY_OPTIMIZING').subscribe((res: string) => {
      this.alreadyOptimizingAlertMessage = res;
    }));
    this.optimizeButtonName = this.optimizeLabel;
    this.dispatchButtonName = this.dispatchLabel;
    // this.viewProjectProblemService.showMapMessage(this.loadingStopsMsg);
  }

  ngAfterViewInit() {
    if (this.fileInput) {
      this.fileInput.nativeElement.onclick = (event) => { event.target.value = null; };
    }
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    const dataChecker = () => {
      if (this.projectProblemDataService.dataReady()) {
        this.lastLoadedOptimization = this.projectProblemDataService.projectProblemData['latest_optimization_datetime'];
        clearInterval(dataRefreshIntervalId);
        this.trackVehicles();
        this.maximumWeight = this.projectProblemViewUtils.calculateAverageWeight();
        this.loadProjectProblem(true, true);
        if (this.globals.vouchersEnabled && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
          this.autoGetNewShipments();
        }
        this.getCollaboratorAffectedStopPoints();
        this.getStopPointsFromDrivers();
        this.autoGetNewOptimizations();
        this.mapComponent.loadSmartPointsOnMap(); // load smart points on map

        if (!Number(this.projectProblemDataService.projectProblemData['selfStopPointsCount'])) {
          this.milyService.openMily();
        }
      }
    }

    const dateElement = document.querySelectorAll('.datepicker');
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const options = {
      setDefaultDate: true,
      defaultDate: tomorrow
    };
    const dateInstances = M.Datepicker.init(dateElement, options);

    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false
    });

    this.listen.push(this.activatedRoute.params.subscribe(params => {
      this.projectProblemId = params['id'];
    }));

    this.projectProblemDataService.isStopFormModalProjectProblemIdLoaded = false;
    this.viewProjectProblemService.showProjectProblemButtons(this.projectProblemId);
    this.projectProblemDataService.projectProblemId = this.projectProblemId;
    this.projectProblemDataService.resetFlags();
    this.projectProblemDataService.loadData();
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    this.intervals.push(dataRefreshIntervalId);
    this.getRoutingSettings();

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.helperModalComponent.projectProblemId = null;
    setTimeout(() => {
      const preChatElements = document.querySelector('body > div > iframe');
      const chatElements = document.querySelectorAll('body #comm100-container iframe');
      if (chatElements) {
        chatElements.forEach(element => {
          element.classList.remove('high');
        });
      }
      if (preChatElements) {
        preChatElements.classList.remove('high');
      }
    }, 100);

    this.projectProblemDataService.resetFlags();
    this.trackVehiclesOldRouteInterval.forEach(trackVehiclesOldRouteInterval => {
      clearInterval(trackVehiclesOldRouteInterval);
    });
    this.trackVehiclesOldRouteInterval = [];
    this.intervals.forEach(interval => {
      clearInterval(interval);
    });
    clearInterval(this.checkForFinishedOptimizationInterval);
    this.checkForFinishedOptimizationInterval = null;

    this.lastIdOldRoute = 0;
    this.listen.forEach(element => {
      element.unsubscribe();
    });
    this.viewProjectProblemService.showProjectProblemButtons(this.projectProblemId);
  }

}
