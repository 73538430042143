import { GenericService } from '@app/services/generic.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import flatpickr from 'flatpickr';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-stops-custom-date-modal',
    templateUrl: './stops-custom-date-modal.component.html',
    styleUrls: ['./stops-custom-date-modal.component.scss']
})
export class StopsCustomDateModalComponent implements OnInit, OnDestroy {

    listen = [];
    isClickedOnce = false;
    datetime;

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        private modalService: ModalService,
        public router: Router,
        public genericService: GenericService,
    ) { }

    openModal() {
        this.isClickedOnce = false;
        const modal = document.querySelector('#stops-custom-date-modal');
        const modalBackground = document.querySelector('#stops-custom-date-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#stops-custom-date-modal');
        const modalBackground = document.querySelector('#stops-custom-date-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
    }

    submitServicesCharge() {
        this.isClickedOnce = true;
        this.closeModal();

        let dates = {
            start: this.datetime.split(" ")[0],
            end: this.datetime.split(" ")[2]
        };

        // set single-day date
        if (!dates['end']) {
            dates['end'] = dates['start'];
        }

        this.modalService.updateStopPointsDate(dates);

        // balance update
        const dateObj = {
            startDate: moment(dates['start'], 'DD-MM-YYYY').format('YYYY-MM-DD'),
            endDate: moment(dates['end'], 'DD-MM-YYYY').format('YYYY-MM-DD'),
            timezone: moment.tz.guess()
        };
        this.genericService.balanceAmountsUpdate(dateObj);
    }

    ngOnInit() {
        flatpickr('#date-range', {
            enableTime: false,
            mode: "range",
            dateFormat: "d/m/Y",
            inline: true,
            monthSelectorType: "static"
        });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}