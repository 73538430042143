import { UploaderOptions, UploadFile, UploadInput, UploadOutput, humanizeBytes } from 'ngx-uploader';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { StepsService } from '@app/services/steps.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { ProjectProblemModalDummyComponent } from './project-problem-modal-dummy/project-problem-modal-dummy.component';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ImporterService } from '@app/services/importer.service';
import { MilyService } from '@app/services/mily.service';
import { GenericService } from '@app/services/generic.service';
import { ImportUtils } from '@app/utils/import-utils';

@Component({
  selector: 'app-project-problem-modal',
  templateUrl: './project-problem-modal.component.html',
  styleUrls: ['./project-problem-modal.component.scss']
})
export class ProjectProblemModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ProjectProblemModalDummyComponent, { static: false }) projectProblemModalDummyComponent: ProjectProblemModalDummyComponent;
  @Input() projectProblemData: ProjectProblemData;
  @Output() stopsCount = new EventEmitter<string>();

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  modalOpen = false;
  afterViewInitExecuted = false;

  searchTextChanged = new Subject<string>();
  searchString: String = '';
  buttonName = '';
  foodPickupRequestsMsg;
  projectProblemId;
  projectProblemDepartureDatetime;
  submit = '';
  listen = [];

  stopPointsGridCount = 0;
  driversGridCount = 0;

  constructor(
    public http: HttpClient,
    private cookieService: CookieService,
    public translate: TranslateService,
    private stepsService: StepsService,
    private viewProjectProblemService: ViewProjectProblemService,
    private milyService: MilyService,
    public globals: Globals,
    public router: Router,
    public projectProblemDataService: ProjectProblemDataService,
    private importerService: ImporterService,
    public genericService: GenericService,
    public importUtils: ImportUtils
  ) {
    // file options
    this.options = { concurrency: 1 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

    this.listen.push(this.stepsService.transmitProjectProblemDataToModalsListen().subscribe((modalView) => {
      if (modalView === 'stops') {
        this.submit = 'stops';
        this.toggleModal();
        this.selectStops();
        this.toggleGrids('stops-grid');
      } else if (modalView === 'drivers') {
        this.submit = 'drivers';
        this.openModalFromButtons('drivers');
        // this.toggleGrids('drivers-grid');
        // this.toggleModal();
      }
    }));
    // load data to project modal
    this.listen.push(this.stepsService.transmitProjectProblemDataListen().subscribe((data) => {
      this.projectProblemId = data.projectProblemId;
      this.projectProblemDepartureDatetime = data.datetime;
      this.pushDataToDom();

      // update driver data if modal is open
      if (this.modalOpen) {
        this.projectProblemModalDummyComponent.driversModalGridComponent.setRouteSettingsData(this.projectProblemId, this.projectProblemDepartureDatetime);
      }
    }));
    this.listen.push(this.viewProjectProblemService.showProjectProblemButtonsListen().subscribe((projectProblemId) => {
      const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
      function dataChecker() {
        if (this.afterViewInitExecuted) {
          clearInterval(dataRefreshIntervalId);
          this.projectProblemId = projectProblemId;
          this.projectProblemModalDummyComponent.showModalButtons();
          this.pushDataToDom();
        }
      }
    }));
    // update drivers grid if optimization finishes while it's open
    this.listen.push(this.viewProjectProblemService.updateDriversModalGridListen().subscribe((data) => {
      if (this.projectProblemModalDummyComponent.driversGrid) {
        if (this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.contains('open')) {
          this.projectProblemModalDummyComponent.driversModalGridComponent.setRouteSettingsData(this.projectProblemId, this.projectProblemDepartureDatetime);
        }
      }
    }));
    this.listen.push(this.viewProjectProblemService.updateStopPointDataInGridListen().subscribe((data) => {
      this.updateButtonsCounters();
    }));
    this.listen.push(this.viewProjectProblemService.setStopPointsGridListen().subscribe((data) => {
      this.updateButtonsCounters();
    }));
    this.listen.push(this.viewProjectProblemService.updateStopPointsGridListen().subscribe((data) => {
      this.updateButtonsCounters();
    }));
    this.listen.push(this.viewProjectProblemService.openDriversGridListen().subscribe((data) => {
      this.openModalFromButtons('drivers');
    }));
    this.listen.push(this.viewProjectProblemService.updateStopPointsCounterListen().subscribe((data) => {
      this.updateButtonsCounters();
    }));
  }

  pushDataToDom() {
    this.projectProblemData = {
      projectProblemId: this.projectProblemId,
      stopPointsGridCount: this.stopPointsGridCount,
      driversGridCount: this.driversGridCount,
      modalOpen: this.modalOpen,
      searchString: this.searchString,
      buttonName: this.buttonName
    }
  }

  selectStops() {
    this.submit = 'stops';
  }

  selectDrivers() {
    this.submit = 'drivers';
  }

  openStopPointFormListen(event) {
    if (event === 'open') {
      this.closeModal();
      this.viewProjectProblemService.openStopFormModal();
    }
  }

  openStopFormButtonPressed(event) {
    event.stopPropagation();
    if (this.projectProblemDataService.isStopFormModalProjectProblemIdLoaded) {
      this.viewProjectProblemService.openStopFormModal();
    }
  }

  openModalFromButtons(step) {
    this.genericService.comm100ZIndexFix();

    // if the modal is closed, open it
    this.modalOpen = true;
    this.pushDataToDom();
    setTimeout(() => {
      if (this.projectProblemModalDummyComponent.modal.nativeElement.classList.contains('closed')) {
        this.submit = step;
        this.toggleGrids(step + '-grid');
        if (step === 'stops') {
          // if a driver was selected (before closing the modal), then load data for that driver only, else load all of the pp's sps
          if (this.projectProblemModalDummyComponent.stopsModalGridComponent.selectedDriverItem) {
            this.projectProblemModalDummyComponent.stopsModalGridComponent.searchStopPoints(this.projectProblemModalDummyComponent.stopsModalGridComponent.selectedDriverItem['driver']['id']);
          } else {
            this.projectProblemModalDummyComponent.stopsModalGridComponent.setStopPointsModalGridData();
          }
          this.projectProblemModalDummyComponent.stopsModalGridComponent.setStopPointsGridData(this.projectProblemId, this.projectProblemDepartureDatetime, null);
        } else if (step === 'drivers') {
          this.projectProblemModalDummyComponent.driversModalGridComponent.setRouteSettingsData(this.projectProblemId, this.projectProblemDepartureDatetime);
        }
        this.toggleModal();
      } else { // if modal is open
        if (step === 'stops') {
          if (this.projectProblemModalDummyComponent.stopsGrid.nativeElement.classList.contains('open')) {
            this.closeModal();
          } else {
            this.submit = step;
            this.toggleGrids(step + '-grid');
            this.projectProblemModalDummyComponent.stopsModalGridComponent.setStopPointsModalGridData();
            this.projectProblemModalDummyComponent.stopsModalGridComponent.setStopPointsGridData(this.projectProblemId, this.projectProblemDepartureDatetime, null);
          }
        } else if (step === 'drivers') {
          if (this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.contains('open')) {
            this.closeModal();
          } else {
            this.submit = step;
            this.toggleGrids(step + '-grid');
            this.projectProblemModalDummyComponent.driversModalGridComponent.setRouteSettingsData(this.projectProblemId, this.projectProblemDepartureDatetime);
          }
        }
      }
    }, 100);
  }

  togglePlusButtonOptions() {
    if (!document.getElementById('plus-button-menu').classList.contains('open')) {
      document.getElementById('plus-button-menu').classList.add('open');
    } else {
      document.getElementById('plus-button-menu').classList.remove('open');
    }
  }

  plusNewShipment() {
    this.viewProjectProblemService.openStopFormModal();
    this.togglePlusButtonOptions();
  }

  plusFromCustomers() {
    this.viewProjectProblemService.openAddStopsModal('recipients');
    this.togglePlusButtonOptions();
  }

  plusImport() {
    document.getElementById('general-csv-uploader').click();
    this.togglePlusButtonOptions();
  }

  onUploadOutput(output: UploadOutput): void {
    const jwt = this.cookieService.get('jwt');
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        const event: UploadInput = {
          type: 'uploadAll',
          // url: '/api/v1/import-analyzer',
          url: '/api/v1/project/problems/' + 1 + '/import-analyzer',
          fieldName: 'companyOrders',
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + jwt,
            'language': this.globals.currentLang
          }
        };
        this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
          // this.gridApi.showLoadingOverlay();
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // handle import analyzer errors
        this.importUtils.handleAnalyzerErrors(output['file']['response']['errors']);

        // The file is uploaded
        // this.gridApi.hideOverlay();
        if (this.files) {
          const file = this.files[this.files.length - 1];
          const data = file.response.items;
          if (data) {
            const fileHash = data.csvSheets[0].csvFile;
            const constantFieldsArray = [];
            this.importerService.openImporterMatcher(data);
            // this.updateGrid();
          }
        }

        break;
    }
  }

  // pickup button
  requestForPickup() {
    // mily message on pickup (only if not in food mode)
    // if (!(this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled)) {
    // this.milyService.newShipmentsPickup();
    // this.milyService.openMily();
    // }
    // food mode mily map feedback
    // else {
    // this.viewProjectProblemService.showMapMessage(this.foodPickupRequestsMsg);
    // }

    this.http.get('api/internal/v2/partner-new-stop-points').subscribe(metaRes => {
      this.http.get(`api/internal/v2/partner-new-stop-points?pageSize=` + metaRes['itemsMeta']['totalCount']).subscribe(newShipmentsRes => {
        const selectedStopPoints = newShipmentsRes['items']['stopPoints'];
        let selectedStopPointsIds = [];
        if (selectedStopPoints.length) {
          // request all created acceptance status stop point ids for pickup
          selectedStopPoints.forEach(stopPoint => {
            if (stopPoint['voucher']) {
              if (stopPoint['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['CREATED']) {
                selectedStopPointsIds.push(stopPoint['id']);
              }
            }
          });
        }
        const pickupObj = {
          pickup_stop_point_ids: selectedStopPointsIds
        };

        this.http.post('api/v1/partner-pickup-stop-points', pickupObj).pipe(take(1)).subscribe(() => {
          if (!this.projectProblemModalDummyComponent.modal.nativeElement.classList.contains('closed')) {
            this.closeModal();
          }
          this.genericService.canRequestForPickup = false;
          this.viewProjectProblemService.showMapMessage(this.foodPickupRequestsMsg);
          setTimeout(() => {
            this.viewProjectProblemService.showMapMessage('');
          }, 5000);
        });
      });
    });


    // const sentryStringForPickupRequest = 'Requesting pickup as ' + this.globals.userProfileId + ' from company ' + this.globals.companyId + ' for stop points: ' + selectedStopPointsIds;
    // Sentry.captureMessage(sentryStringForPickupRequest);
  }

  openModal() {
    if (this.projectProblemModalDummyComponent.modal.nativeElement.classList.contains('closed')) {
      this.projectProblemModalDummyComponent.stopsGridButton.nativeElement.classList.add('expanded');
      this.projectProblemModalDummyComponent.driversGridButton.nativeElement.classList.add('expanded');
      if (this.projectProblemModalDummyComponent.addNewShipmentButton) {
        this.projectProblemModalDummyComponent.addNewShipmentButton.nativeElement.classList.add('expanded');
      }
      this.projectProblemModalDummyComponent.generalSearch.nativeElement.classList.add('expanded');
      this.projectProblemModalDummyComponent.modal.nativeElement.classList.remove('closed');
      this.projectProblemModalDummyComponent.modal.nativeElement.classList.add('open');
      this.projectProblemModalDummyComponent.modalBackground.nativeElement.classList.remove('hidden');
      this.modalOpen = true;
      this.pushDataToDom();
    }
  }

  toggleModal() {
    if (this.projectProblemModalDummyComponent.addNewShipmentButton) {
      this.projectProblemModalDummyComponent.addNewShipmentButton.nativeElement.classList.toggle('expanded');
    }
    this.projectProblemModalDummyComponent.stopsGridButton.nativeElement.classList.toggle('expanded');
    this.projectProblemModalDummyComponent.driversGridButton.nativeElement.classList.toggle('expanded');
    this.projectProblemModalDummyComponent.generalSearch.nativeElement.classList.toggle('expanded');
    this.projectProblemModalDummyComponent.modal.nativeElement.classList.toggle('closed');
    this.projectProblemModalDummyComponent.modal.nativeElement.classList.toggle('open');
    this.projectProblemModalDummyComponent.modalBackground.nativeElement.classList.toggle('hidden');
    if (this.projectProblemModalDummyComponent.modal.nativeElement.classList.contains('closed')) {
      this.resetModal();
    }
  }

  closeModal() {
    if (this.projectProblemModalDummyComponent.addNewShipmentButton) {
      this.projectProblemModalDummyComponent.addNewShipmentButton.nativeElement.classList.remove('expanded');
    }
    this.projectProblemModalDummyComponent.stopsGridButton.nativeElement.classList.remove('expanded');
    this.projectProblemModalDummyComponent.driversGridButton.nativeElement.classList.remove('expanded');
    this.projectProblemModalDummyComponent.generalSearch.nativeElement.classList.remove('expanded');
    this.projectProblemModalDummyComponent.modal.nativeElement.classList.add('closed');
    this.projectProblemModalDummyComponent.modal.nativeElement.classList.remove('open');
    this.projectProblemModalDummyComponent.modalBackground.nativeElement.classList.add('hidden');
    this.resetModal();
    this.removeSearchFilters();
    setTimeout(() => {
      this.modalOpen = false;
    }, 1000);
  }

  toggleGrids(gridId) {
    if (this.modalOpen) {
      this.removeSearchFilters();
      if (gridId === 'drivers-grid') {
        if (!this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.contains('open')) {
          // open drivers grid
          this.projectProblemModalDummyComponent.stopsGrid.nativeElement.classList.remove('open');
          this.projectProblemModalDummyComponent.stopsGrid.nativeElement.classList.add('closed');
          this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.remove('closed');
          this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.add('open');
          this.selectDrivers();
        }
      } else if (gridId === 'stops-grid') {
        if (!this.projectProblemModalDummyComponent.stopsGrid.nativeElement.classList.contains('open')) {
          this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.remove('open');
          this.projectProblemModalDummyComponent.driversGrid.nativeElement.classList.add('closed');
          this.projectProblemModalDummyComponent.stopsGrid.nativeElement.classList.remove('closed');
          this.projectProblemModalDummyComponent.stopsGrid.nativeElement.classList.add('open');
          this.selectStops();
        }
      }
    }
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  setStopPointsGridCount(event) {
    if (event) {
      const object = JSON.parse(event);
      if (object) {
        this.stopPointsGridCount = object.total;
        this.stopsCount.emit(String(event));
      }
    }
    this.pushDataToDom();
  }

  setDriversGridCount(event) {
    if (event) {
      this.driversGridCount = event;
    }
    this.pushDataToDom();
  }

  removeSearchFilters() {
    if (this.searchString !== '') {
      this.searchString = '';
      this.projectProblemModalDummyComponent.stopsModalGridComponent.searchString = this.searchString;
      this.projectProblemModalDummyComponent.driversModalGridComponent.searchString = this.searchString;
      this.projectProblemModalDummyComponent.stopsModalGridComponent.setStopPointsModalGridData();
      this.viewProjectProblemService.removeFiltersFromMap();
    }
    this.pushDataToDom();
  }

  resetModal() {
    if (this.submit === 'stops') {
      this.projectProblemModalDummyComponent.stopsModalGridComponent.hideCheckBoxes();
    } else {
      this.toggleGrids('stops-grid');
      this.projectProblemModalDummyComponent.driversModalGridComponent.hideCheckBoxes();
    }
    this.removeSearchFilters();
  }

  updateButtonsCounters() {
    const dataRefreshIntervalId = setInterval(() => {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        // [food mode]: filtering for sps count
        if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
          let newShipmentsUrl = 'api/internal/v2/partner-new-stop-points?pageSize=0';
          this.http.get(newShipmentsUrl).subscribe(metaRes => {
            newShipmentsUrl = 'api/internal/v2/partner-new-stop-points?pageSize=' + metaRes['itemsMeta']['totalCount'];
            this.http.get(newShipmentsUrl).subscribe(newShipmentsRes => {
              this.stopPointsGridCount = 0;
              newShipmentsRes['items']['stopPoints'].forEach(newShipment => {
                let newShipmentExists = false;
                if (newShipment['voucher']) {
                  this.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
                    if (stopPoint) {
                      if (stopPoint['id'] == newShipment['id']) {
                        newShipmentExists = true;
                      } else if (newShipment['voucher']) {
                        if (newShipment['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['ACCEPTED']
                          || newShipment['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['DECLINED']) {
                          newShipmentExists = true;
                        }
                      } else {
                        newShipmentExists = true;
                      }
                    }
                  });
                  if (newShipment['voucher']['acceptance_status'] == this.globals.voucherAcceptanceStatus['CREATED']) {
                    this.genericService.canRequestForPickup = true;
                  }
                } else {
                  newShipmentExists = true;
                }

                if (!newShipmentExists) {
                  this.stopPointsGridCount += 1;
                }
              });
              this.stopPointsGridCount += Math.ceil(this.projectProblemDataService.stopPointsArray.length / 2);

              this.pushDataToDom();
            });
          });
        } else {
          // if driver is selected, then take only that drivers' sps into account (otherwise, use all sps of pp)
          if (this.projectProblemDataService.selectedDriver) {
            if (this.projectProblemDataService.selectedDriver['id']) {
              this.projectProblemDataService.loadDriverStopPoints(this.projectProblemDataService.selectedDriver['id']).subscribe(res => {
                this.stopPointsGridCount = 0;
                const stopPoints = res['items']['stopPoints'];
                stopPoints.forEach(stopPoint => {
                  if (stopPoint['service_type'] != this.globals.stopPointServiceTypeConstants['NOT_SPECIFIED']
                    && stopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE']
                    && stopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {
                    this.stopPointsGridCount++;
                  }
                });
                this.pushDataToDom();
              });
            }
          }
          else {
            this.stopPointsGridCount = this.projectProblemDataService.countStopPointsInProjectProblem();
          }
        }
        this.driversGridCount = this.projectProblemDataService.countDriversInProjectProblem();
        this.pushDataToDom();
      }
    }, 200);
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.OK').subscribe((res: string) => {
      this.buttonName = res;
    }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.FOOD_REQUEST_PICKUP_MSG').subscribe((res: string) => { this.foodPickupRequestsMsg = res; }));
  }

  ngAfterViewInit() {
    this.afterViewInitExecuted = true;
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
    this.pushDataToDom();
    this.updateButtonsCounters();

    // ANCHOR
    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged()).subscribe((text: string) => {
        this.searchString = text;
        this.modalOpen = true;
        this.pushDataToDom();
        setTimeout(() => {
          this.projectProblemModalDummyComponent.stopsModalGridComponent.setStopPointsGridData(this.projectProblemId, this.projectProblemDepartureDatetime, null);
          setTimeout(() => {
            this.projectProblemModalDummyComponent.stopsModalGridComponent.searchString = this.searchString;
            this.projectProblemModalDummyComponent.driversModalGridComponent.searchString = this.searchString;
            if (this.projectProblemModalDummyComponent.modal.nativeElement.classList.contains('closed')) {
              this.openModal();
              this.projectProblemModalDummyComponent.stopsModalGridComponent.searchStopPoints();
            } else {
              if (this.submit === 'drivers') {
                this.projectProblemModalDummyComponent.driversModalGridComponent.loadDriversData();
              } else {
                if (this.projectProblemModalDummyComponent.stopsModalGridComponent.selectedDriverItem) {
                  this.projectProblemModalDummyComponent.stopsModalGridComponent.searchStopPoints(this.projectProblemModalDummyComponent.stopsModalGridComponent.selectedDriverItem.driver.id);
                } else {
                  this.projectProblemModalDummyComponent.stopsModalGridComponent.searchStopPoints();
                }
              }
            }
          }, 100);
        }, 100);
      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}

// interface
export interface ProjectProblemData {
  projectProblemId: Number;
  stopPointsGridCount: Number,
  driversGridCount: Number,
  modalOpen: Boolean,
  // afterViewInitExecuted: Boolean,
  searchString: String,
  buttonName: String,
}