import { CommonModule } from '@angular/common';
import { Directive, NgModule, ElementRef, Input, Optional, Host, Output, EventEmitter, HostBinding, Renderer2 } from '@angular/core';
import { LmTextComponent } from '../controls/input/text/text.component';
import { LmNumberComponent } from '../controls/input/number/number.component';


@Directive({
  selector: 'lm-text, lm-number, lm-switch, lm-radio, lm-button, lm-radio, lm-switch, lm-slider, lm-multi-select, lm-dropdown, lm-checkbox, lm-auto-complete, lm-calendar'
})
export class LmTestIdTagGeneratorDirective {

  constructor(private el: ElementRef, private renderer: Renderer2){}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const _el:HTMLElement = this.el.nativeElement;
      this.renderer.setAttribute(_el, 'testing-id', _el.getAttribute('name'));
  })

}}

@NgModule({
  imports: [CommonModule],
  exports: [LmTestIdTagGeneratorDirective],
  declarations: [LmTestIdTagGeneratorDirective]
})
export class LmTestIdTagGeneratorModule {}
