import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { LmTableComponent, LmTableModule } from '@app/shared/structure/table/table.component';
import { LmHeaderModule } from '../header/lm-header.component';
import { LmButtonConfig } from '../button/button.component';
import { LmTableListViewModelService } from './table-list-viewmodel.service';
import { LmBaseEntity } from '@app/model/base-entity';


@Component({
  selector: 'lm-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss']
})
export class LMTableListComponent extends LmTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(LmTableComponent) table:LmTableComponent;
    @Input() VM: LmTableListViewModelService<LmBaseEntity>;
    @Input() cols: LmTableBaseColumnDefs[];
    @Input() headerBtn: LmButtonConfig;
    @Input() title: string;
    @Input() showBttn: boolean = true;
    @Input() hideActions;
    @Input() showCount = true;
    @Input() headerIcon: string;


    
    constructor(){
      super();
    }

    ngOnInit(): void {
      if(this.VM) this.VM.ngOnInit();
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
      this.VM.ngOnDestroy()
    }

}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LmHeaderModule, LmTableModule],
  exports: [LMTableListComponent],
  declarations: [LMTableListComponent]
})
export class LMTableListModule {}
