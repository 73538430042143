import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { of } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import { Globals } from '@app/services/globals';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { GridsService } from '@app/services/grids.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-wizard-vehicles',
  templateUrl: './wizard-vehicles.component.html',
  styleUrls: ['./wizard-vehicles.component.scss']
})
export class WizardVehiclesComponent implements OnInit {
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  listen = [];

  myForm: FormGroup;
  errors;
  data;

  depotId = null;
  vehicleType = 0;
  vehicleCargoCapacity = 50;
  count = null;

  columnDefs;
  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  vehiclesDataArray = [];
  rowData: any;

  listenUpdateVehicleGrid;
  vehicleTypeTitle;
  vehicleInfonfoTitle;
  driverTitle;
  capacityTitle;
  countTitle;

  vehicleTypes = [];

  bicycleLabel = '';
  scooterLabel = '';
  carLabel = '';
  vanLabel = '';
  largeVanLabel = '';
  truckLabel = '';

  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    formBuilder: FormBuilder,
    private dataService: DataService,
    private modalGridService: ModalGridService,
    private projectProblemDataService: ProjectProblemDataService,
    public globals: Globals,
    public gridsService: GridsService,
    private sanitizer: DomSanitizer,
  ) {
    this.myForm = formBuilder.group({
      'vehicle': formBuilder.group({
        'vehicle_type': [this.vehicleType],
        'maximum_cargo_capacity': [this.vehicleCargoCapacity],
        'default_company_depot_id': [this.depotId],
      }),
      'count': [this.count]
    });
  }

  typeRenderer(params) {
    let columnObject = '';
    if (params.getValue() || params.getValue() === 0) {
      if (this.vehicleTypes[params.getValue()]) {
        columnObject += '<div class="vehicle-icon-cell dark">' + this.vehicleTypes[params.getValue()].image + '</div>';
        columnObject += '<div class="triple-cell">' + this.vehicleTypes[params.getValue()].typeName + '</div>';
      }
    }
    return columnObject;
  }

  cancelRenderer(params) {
    return '<i class="fas fa-times"></i>';
  }

  updateGrid() {
    this.setVehiclesGridData();
  }

  selectVehicleType(type) {
    if (document.querySelector('.vehicle-type-box.active')) {
      document.querySelector('.vehicle-type-box.active').classList.remove('active');
    }
    if (document.querySelector('[index="' + type + '"]')) {
      document.querySelector('[index="' + type + '"]').classList.add('active');
    }
    if (this.vehicleTypes[type].capacity) { this.vehicleCargoCapacity = this.vehicleTypes[type].capacity }
    this.vehicleType = type;
    this.patchForm();
  }

  setVehiclesGridData() {
    const self = this;
    this.http.get('api/v1/vehicles?isGrouped=true').pipe(take(1)).subscribe(response => {
      self.vehiclesDataArray = [];
      let gridObject = {};
      if (response['items'].length) {
        response['items'].forEach(vehicleData => {
          const vehicle = vehicleData.vehicle;
          gridObject = {
            id: vehicle.id,
            type: vehicle.vehicle_type,
            capacity: vehicle.maximum_cargo_capacity,
            count: vehicle.groupCount
          };
          self.vehiclesDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' }
        self.vehiclesDataArray.push(noData);
      }
      self.rowData = of(self.vehiclesDataArray);
    },
      (error) => {
        console.error(error);
      }
    );
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
        typeName: this.bicycleLabel,
        capacity: 50,
      },
      {
        index: 1,
        image: scooter,
        typeName: this.scooterLabel,
        capacity: 100,
      },
      {
        index: 2,
        image: car,
        typeName: this.carLabel,
        capacity: 2000,
      },
      {
        index: 3,
        image: van,
        typeName: this.vanLabel,
        capacity: 5000,
      },
      {
        index: 4,
        image: largeVan,
        typeName: this.largeVanLabel,
        capacity: 9000,
      },
      {
        index: 5,
        image: truck,
        typeName: this.truckLabel,
        capacity: 16000,
      }
    ];
    setTimeout(() => {
      if (document.querySelector('[index="' + this.vehicleType + '"]')) {
        document.querySelector('[index="' + this.vehicleType + '"]').classList.add('active');
      }
    }, 300);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  cellClicked(event) {
    if (event.column.colId.includes('delete')) {
      this.deleteVehicle(event.data.id);
    } else {
      if (!event.data.noDataText) {
        this.getFormData(event.data);
      }
    }
  }

  deleteVehicle(id) {
    const myObserver = {
      next: (response) => {
        this.updateGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    this.http.delete('api/v1/vehicles/' + id).pipe(take(1)).subscribe(myObserver);
  }

  public getFormData(data) {
    this.selectVehicleType(data.type)
    this.count = data.count;
    this.vehicleCargoCapacity = data.capacity;
    this.setForm();
  }

  patchForm() {
    this.myForm.patchValue({
      'vehicle': {
        'vehicle_type': this.vehicleType,
        'default_company_depot_id': this.depotId,
        'maximum_cargo_capacity': this.vehicleCargoCapacity,
      },
    });
    M.updateTextFields();
  }

  setForm() {
    this.myForm.setValue({
      'vehicle': {
        'vehicle_type': this.vehicleType,
        'maximum_cargo_capacity': this.vehicleCargoCapacity,
        'default_company_depot_id': this.depotId,
      },
      'count': this.count,
    });
    M.updateTextFields();
  }

  resetForm() {
    this.errors = [];
    this.vehicleType = 0;
    this.selectVehicleType(0);
    this.count = null;
    this.vehicleCargoCapacity = 50;
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
  }

  initializeSelect() {
    const elems = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elems);
  }

  public submitWizardForm(saveAndClone = false) {
    const formValues = this.myForm.value;
    const formVehicleValues = this.myForm.value.vehicle;
    this.depotId = this.globals.depotId;
    this.vehicleCargoCapacity = formVehicleValues.maximum_cargo_capacity;
    this.patchForm();
    const myObserver = {
      next: (response) => {
        this.setVehiclesGridData();
      },
      error: (error) => {
        const errorsObject = error.error.errors;
        const errors = [];
        const keyify = (obj, prefix = '') =>
          Object.keys(obj).reduce((res, el) => {
            if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
              errors.push(obj[el]['0']);
            }
            if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
              if (obj[el]['0']['load']) {
                errors.push(obj[el]['0']['load']['0']);
              }
            }
            if (Array.isArray(obj[el])) {
              return res;
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
              return [...res, ...keyify(obj[el], prefix + el + '.')];
            } else {
              return [...res, prefix + el];
            }
          }, []);
        const output = keyify(errorsObject);
        this.errors = errors;
      },
      complete: () => {
        if (!saveAndClone) { this.resetForm(); }
      },
    };

    const formData = this.myForm.value.vehicle;
    let vehiclesToCreate = this.myForm.value.count;
    this.vehiclesDataArray.forEach(row => {
      if (formData.vehicle_type === row.type && formData.maximum_cargo_capacity === row.capacity) {
        vehiclesToCreate = vehiclesToCreate - row.count;
      }
    });

    if (Number(vehiclesToCreate)) {
      this.http.post('api/v1/vehicles?modelsToCreate=' + vehiclesToCreate, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.TYPE').subscribe((res: string) => { this.vehicleTypeTitle = res; }));
    this.listen.push(this.translate.get('VEHICLE.CAPACITY').subscribe((res: string) => { this.capacityTitle = res; }));
    this.listen.push(this.translate.get('VEHICLE.COUNT').subscribe((res: string) => { this.countTitle = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));

    this.columnDefs = [
      {
        headerName: this.vehicleTypeTitle,
        cellRenderer: this.typeRenderer.bind(this),
        field: 'type',
        filter: false,
        sort: true,
        checkboxSelection: false,
        width: this.gridsService.widthCalculator(16)
      },
      {
        headerName: this.capacityTitle,
        field: 'capacity',
        filter: false,
        width: this.gridsService.widthCalculator(16)
      },
      {
        headerName: this.countTitle,
        field: 'count',
        filter: true,
        width: this.gridsService.widthCalculator(16)
      },
      // {
      //   headerName: '',
      //   field: 'delete',
      //   width: this.gridsService.widthCalculatorContainerId(10, 'vehicles-grid-container'),
      //   cellRenderer: this.cancelRenderer
      // },
    ];
    this.setVehiclesGridData();
    setTimeout(() => {
      this.setVehicleTypes();
    }, 300);
  }

  ngOnInit() {
    const self = this;
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    const depotsDataRefreshIntervalId = setInterval(depotsDataChecker, 200);
    function depotsDataChecker() {
      if (self.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);
        self.depotId = self.globals.depotId;
      }
    }
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
