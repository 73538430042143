import { Injectable } from '@angular/core';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

declare var H: any;

@Injectable({
    providedIn: 'root'
})
export class DateTimeCalculatorService {

    in = null;
    hoursShortMsg = 'HOURS_MSG';
    minutesShortMsg = 'MINUTES_MSG';
    secondsShortMsg = 'SECONDS_MSG';

    standardPeriods = [];
    selectedPeriod = {};

    constructor(
        public projectProblemDataService: ProjectProblemDataService,
        public globals: Globals,
        public translate: TranslateService,
    ) { }

    getDriversLastArrivedStopPoint(routeSettingId) {
        // const self = this;
        let lastCompletedStopPointId = null;
        if (this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId]) {
            this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId].forEach(stopPointId => {
                if (this.projectProblemDataService.stopPoints[stopPointId]) {
                    // the last point in route that is canceled or completed and the driver has arrived at it
                    if (
                        this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_status'] === this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] ||
                        this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_status'] === this.globals.stopPointFulfillmentStatusConstants['CANCELED']
                    ) {
                        if (this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_events']) {
                            this.projectProblemDataService.stopPoints[stopPointId]['fulfillment_events'].forEach(event => {
                                if (event.reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]['AT_TIME']) {
                                    // the current route distance is the cumulative distance up to the last completed point on the route (with the largest sequence)
                                    lastCompletedStopPointId = stopPointId;
                                }
                            });
                        } else {
                            console.error('Stop point with id' + stopPointId + ' in route setting ' + routeSettingId + ' does not have fulfillment events');
                            console.error(this.projectProblemDataService.stopPoints[stopPointId]);
                        }
                    }
                }
            });
        }
        return lastCompletedStopPointId;
    }

    findStopsToUpdate(sequence, index = 0) {
        const stopsToUpdate = [];
        while (index < sequence.length) {
            const id = sequence[index];
            if (id) {
                const stopData = this.projectProblemDataService.stopPoints[id];
                if (stopData) {
                    if (
                        stopData['fulfillment_status'] !== this.globals.stopPointFulfillmentStatusConstants['CANCELED'] &&
                        stopData['fulfillment_status'] !== this.globals.stopPointFulfillmentStatusConstants['COMPLETED']
                    ) {
                        stopsToUpdate.push(id);
                    }
                }
            }
            index++;
        }
        return stopsToUpdate;
    }

    calculateTimeDifferenceInMinutes(dateTime1, dateTime2) {
        const dateTime1Moment = moment(dateTime1);
        const dateTime2Moment = moment(dateTime2);
        return Math.round(moment.duration(dateTime2Moment.diff(dateTime1Moment)).asMinutes());
    }

    getStopPointArrivalTime(stopPointId) {
        let arrivalTime = '';
        const stopPointData = this.projectProblemDataService.stopPoints[stopPointId];
        if (stopPointData) {
            stopPointData.fulfillment_events.forEach(event => {
                if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]['AT_TIME']) {
                    arrivalTime = event.fulfillment_datetime;
                }
            });
        } else {
            if (!this.globals.restrictedUser) {
                console.error('Could not find stop with id ' + stopPointId)
            }
        }
        return arrivalTime;
    }

    getCanceledAndCompletedStopsAfterStopPoint(afterStopPointId, beforeStopPointId, routeSettingId) {
        const self = this;
        const stopPointIds = [];
        let isAfterStopPoint = false;
        const sequence = this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId];
        const sequenceLength = sequence.length;
        for (let i = 0; i < sequenceLength; i++) {
            const stopPointId = sequence[i];

            // don't count after we reached the sp in the sequence
            if (stopPointId === beforeStopPointId) {
                break;
            }

            // if we are passed the stop point we are looking for, check for canceled or completed
            if (isAfterStopPoint) {
                if (self.projectProblemDataService.stopPoints[stopPointId]) {
                    if (
                        self.projectProblemDataService.stopPoints[stopPointId]['fulfillment_status'] === self.globals.stopPointFulfillmentStatusConstants['COMPLETED'] ||
                        self.projectProblemDataService.stopPoints[stopPointId]['fulfillment_status'] === self.globals.stopPointFulfillmentStatusConstants['CANCELED']
                    ) {
                        stopPointIds.push(stopPointId);
                    }
                }
            }

            // wait until we reach the sp in the sequence
            if (stopPointId === afterStopPointId) {
                isAfterStopPoint = true;
            }
        }
        return stopPointIds;
    }

    calculateTotalDuration(durationMinutes) {
        let durationText = '';
        const durationHoursFloor = Math.floor(durationMinutes / 60)
        const durationMinutesFloor = Math.round(durationMinutes % 60);
        if (durationHoursFloor) {
            durationText += Math.abs(durationHoursFloor) + this.hoursShortMsg + ' ';
        }
        if (durationMinutesFloor) {
            durationText += Math.abs(durationMinutesFloor) + this.minutesShortMsg;
        }
        return durationText;
    }

    calculateDelayInMinutesAndSeconds(delay, timeFormat = false) {
        const durationMinutes = moment.duration(delay).asMinutes();
        const durationMinutesFloor = Math.trunc(durationMinutes);
        const durationSeconds = Math.round(moment.duration((durationMinutes - durationMinutesFloor), 'minutes').asSeconds());
        const durationSecondsFloor = Math.trunc(durationSeconds);
        if (timeFormat) {
            return ('0' + durationMinutesFloor).slice(-2) + ':' + ('0' + durationSecondsFloor).slice(-2);
        } else {
            let durationText = '';
            if (durationMinutesFloor) {
                durationText += durationMinutesFloor + this.minutesShortMsg + ' ';
            }
            if (durationSecondsFloor) {
                durationText += durationSecondsFloor + this.secondsShortMsg;
            }
            return durationText;
        }
    }

    calculateDelayInHoursAndMinutes(delay) {
        const durationHours = moment.duration(delay).asHours();
        const durationHoursFloor = Math.floor(durationHours);
        const durationMinutes = moment.duration((durationHours - durationHoursFloor), 'hours').asMinutes();
        const durationMinutesFloor = Math.floor(durationMinutes);
        let durationText = '';
        if (durationHoursFloor) {
            durationText += durationHoursFloor + this.hoursShortMsg + ' ';
        }
        if (durationMinutesFloor) {
            durationText += durationMinutesFloor + this.minutesShortMsg;
        }
        return durationText;
    }

    getTimeWindowLabel(timeWindows) {
        const timeWindowUnformatted = [];
        let timeWindowRange, timeWindowRangeMinutes;
        let timeWindowStart, timeWindowEnd;
        let timeWindowStartUnformatted, timeWindowEndUnformatted;
        timeWindows.forEach(timeWindow => {
            timeWindowRange = timeWindow.time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowStart = moment(timeWindow.start, 'HH:mm:SS').format();
            timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
            timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
            timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
            timeWindowUnformatted.push(timeWindowStartUnformatted);
            timeWindowUnformatted.push(timeWindowEndUnformatted);
        });
        let label = timeWindowUnformatted[0] + '-' + timeWindowUnformatted[1];
        if (timeWindowUnformatted[2]) {
            label += ' & ' + timeWindowUnformatted[2] + '-' + timeWindowUnformatted[3];
        }
        return label;
    }

    getStopPointFulfillmentTime(stopPointData) {
        let arrivalTime = '', completeTime = '';
        if (stopPointData.fulfillment_events) {
            stopPointData.fulfillment_events.forEach(event => {
                if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]['AT_TIME']) {
                    arrivalTime = moment(event.fulfillment_datetime).format('HH:mm');
                } else if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']]['AT_TIME']) {
                    completeTime = moment(event.fulfillment_datetime).format('HH:mm');
                }
            });
        }
        if (!arrivalTime && completeTime) {
            return completeTime;
        }
        return arrivalTime;
    }

    calculateEstimatedArrivalTime(stopPointData, stopPointId, routeSettingId, projectProblemData) {
        // make estimated arrival time be the time that was given by the solution
        const fulfillmentTimeMoment = moment(stopPointData.solution.latest_estimated_arrival_datetime);
        let estimatedArrivalTimeMoment = fulfillmentTimeMoment;
        let estimatedArrivalTime = fulfillmentTimeMoment.format('HH:mm');
        // console.log('estimatedArrivalTime latest_estimated_arrival_datetime ' + estimatedArrivalTime);

        const estimatedArrivalTimeDifferenceMinutes = this.projectProblemDataService.estimatedArrivalTimeDifferenceMinutesPerRouteSettingId[routeSettingId];
        if (
            this.globals.collaboratorModeEnabled &&
            estimatedArrivalTimeDifferenceMinutes
        ) {
            estimatedArrivalTimeMoment = estimatedArrivalTimeMoment.add(estimatedArrivalTimeDifferenceMinutes, 'minutes');
            estimatedArrivalTime = estimatedArrivalTimeMoment.format('HH:mm');
            // console.log('estimatedArrivalTime ' + estimatedArrivalTime);
        } else {
            // find the last point the driver has arrived to
            const lastArrivedStopPointId = this.getDriversLastArrivedStopPoint(routeSettingId);
            // find the last stop's difference from estimated to actual time
            // console.log('lastArrivedStopPointId ' + lastArrivedStopPointId);
            if (lastArrivedStopPointId) {
                const lastArrivedStopPointData = this.projectProblemDataService.stopPoints[lastArrivedStopPointId];
                if (lastArrivedStopPointData) {
                    if (lastArrivedStopPointData.solution) {
                        const lastStopEstimatedArrivalTime = lastArrivedStopPointData.solution.latest_estimated_arrival_datetime;
                        // console.log('lastStopEstimatedArrivalTime ' + lastStopEstimatedArrivalTime);
                        const lastStopActualArrivalTime = this.getStopPointArrivalTime(lastArrivedStopPointId);
                        // console.log('lastStopActualArrivalTime ' + lastStopActualArrivalTime);
                        if (lastStopActualArrivalTime && lastStopEstimatedArrivalTime) {
                            const timeDifferenceInMinutes = this.calculateTimeDifferenceInMinutes(lastStopEstimatedArrivalTime, lastStopActualArrivalTime);
                            // console.log('timeDifferenceInMinutes ' + timeDifferenceInMinutes);

                            // add the time difference to the estimated arrival time
                            if (timeDifferenceInMinutes) {
                                estimatedArrivalTimeMoment = moment(estimatedArrivalTime, 'HH:mm').add(timeDifferenceInMinutes, 'minutes');
                                estimatedArrivalTime = moment(estimatedArrivalTime, 'HH:mm').add(timeDifferenceInMinutes, 'minutes').format('HH:mm');
                                // console.log('estimatedArrivalTime ' + estimatedArrivalTime);

                                // check for completed or cancelled stops after the last arrived stop
                                const canceledAndCompleteStopPointIds = this.getCanceledAndCompletedStopsAfterStopPoint(lastArrivedStopPointId, stopPointId, routeSettingId);
                                // if we have completed or cancelled stops, add their duration to the estimated arrival time
                                let durationMinutesSum = 0;
                                canceledAndCompleteStopPointIds.forEach(id => {
                                    const data = this.projectProblemDataService.stopPoints[id];
                                    if (data) {
                                        const duration = data.duration;
                                        const durationMinutes = moment.duration(duration).asMinutes();
                                        if (durationMinutes) {
                                            durationMinutesSum += durationMinutes;
                                        }
                                    }
                                });
                                // console.log('durationMinutesSum ' + durationMinutesSum);
                                estimatedArrivalTimeMoment = moment(estimatedArrivalTime, 'HH:mm').subtract(durationMinutesSum, 'minutes');
                                estimatedArrivalTime = moment(estimatedArrivalTime, 'HH:mm').subtract(durationMinutesSum, 'minutes').format('HH:mm');
                                // console.log('estimatedArrivalTime ' + estimatedArrivalTime);
                            } else {
                                console.error('Time difference is ' + timeDifferenceInMinutes);
                            }
                        }
                    } else {
                        console.error('stop point id ' + lastArrivedStopPointId + ' with not visible solution');
                        console.error(lastArrivedStopPointData);
                    }
                } else {
                    console.error('Could not find stop with id ' + lastArrivedStopPointId);
                }
            } else {
                // console.warn('Could not find last arrived stop for route index ' + routeIndex + ', searching for start delay...');

                if (!this.globals.foodModeEnabled) {
                    stopPointData.fulfillment_events.forEach(event => {
                        // if (!isNaN(reasonsToLabels[event.reason])) {
                        if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']]['AT_TIME']) {
                            if (this.projectProblemDataService.routeSettingsById[routeSettingId]) {
                                // console.log('IN_PROGRESS event found');
                                let projectDepartureTime;
                                if (projectProblemData) {
                                    projectDepartureTime = projectProblemData['departure_datetime'];
                                }
                                // console.log('projectDepartureTime ' + projectDepartureTime);
                                const driverDepartureOffset = this.projectProblemDataService.routeSettingsById[routeSettingId]['routeSetting']['start_offset_from_departure'];
                                // console.log('driverDepartureOffset ' + driverDepartureOffset);
                                const driverDepartureOffsetMinutes = moment.duration(driverDepartureOffset).asMinutes();
                                // console.log('driverDepartureOffsetMinutes ' + driverDepartureOffsetMinutes);
                                const driverDepartureTime = moment(projectDepartureTime).add(driverDepartureOffsetMinutes, 'minutes').format();
                                // console.log('driverDepartureTime ' + driverDepartureTime);
                                // console.log('actualDepartureTime ' + event.fulfillment_datetime);
                                const startTimeDiff = this.calculateTimeDifferenceInMinutes(driverDepartureTime, event.fulfillment_datetime);
                                // console.log('departureTimeDiff ' + startTimeDiff);

                                estimatedArrivalTimeMoment = moment(estimatedArrivalTime, 'HH:mm').add(startTimeDiff, 'minutes');
                                estimatedArrivalTime = moment(estimatedArrivalTime, 'HH:mm').add(startTimeDiff, 'minutes').format('HH:mm');
                                // console.log('estimatedArrivalTime ' + estimatedArrivalTime);
                            }
                        }
                        // }
                    });
                }

            }
        }

        return {
            estimatedArrivalTime: estimatedArrivalTime,
            estimatedArrivalTimeTimestamp: moment(estimatedArrivalTimeMoment).toISOString()
        };
    }

    getDateSelectPeriods(labels){
        return [
            {
                label: labels['RECENT'],
                value: 1
            },
            {
                label: labels['LAST_WEEK'],
                value: 6
            },
            {
                label: labels['LAST_MONTH'],
                value: 29
            },
            {
                label: labels['CUSTOM'],
                value: 'custom'
            },
        ];
    }

}
