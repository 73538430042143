import { LM_BAR_CHART_HEART_OPTIONS, LM_BAR_CHART_MONEY_OPTIONS, LM_BAR_CHART_RATING_OPTIONS, LM_STACKED_CHART_BORDER_RADIUS, lmAvatarAnnotation } from "@app/model/charts-config";
import { ILmChartWidgetTab } from "@app/model/widget";
import { chartAvatar1,  chartAvatar2, chartAvatar3, chartAvatar4, chartAvatar5 } from '@app/dashboard-report/dashboard-report-dummy-imgs';
import { ChartOptions } from "chart.js";

const mainPalette = ['#741b47', '#b45f06', '#274e13', '#351c75', '#0b5393', '#06aeba'];
const annotations = {
    'Nikos': lmAvatarAnnotation('Nikos', chartAvatar1),
    'Alexis': lmAvatarAnnotation('Alexis', chartAvatar2),
    'Dimitris': lmAvatarAnnotation('Dimitris', chartAvatar3),
    'Thomas': lmAvatarAnnotation('Thomas', chartAvatar4),
    'Kleanthis': lmAvatarAnnotation('Kleanthis', chartAvatar5),
    'Darth Vader': lmAvatarAnnotation('Darth Vader', chartAvatar1),
    'Super Batman': lmAvatarAnnotation('Super Batman', chartAvatar2),
    'Ovelix': lmAvatarAnnotation('Ovelix', chartAvatar3),
    'Stamatis Kokotas': lmAvatarAnnotation('Stamatis Kokotas', chartAvatar4),
    'Mpampis Flou': lmAvatarAnnotation('Mpampis Flou', chartAvatar5),
};
const mainData = {
    labels: [],
    datasets: [
        {
            backgroundColor: mainPalette,
            data: []
        }
    ]
};
const mainChartOptions:ChartOptions= {
    // ...LM_BAR_CHART_RATING_OPTIONS,
    plugins:{
        annotation:{
            annotations: annotations
        }
    }
};

export const DRIVER_PRODUCTIVITY: ILmChartWidgetTab = {
    id: 'productivity',
    title: 'Drivers',
    subtitle: 'Productivity',
    btn:{id: 'productivity', icon: 'lm-icon icon-star', textOnly: true, color: "#ccc", activeColor: '#ffc107', active: true},
    data: mainData,
    chartType: 'bar',
    chartOptions: {...mainChartOptions, scales:{
        y:{
            grace:'60%',
            ticks:{
                callback: (tickValue, index, ticks) =>{
                    return index < mainPalette.length ? tickValue + '★' : '';
                },
            }
        }
    }},
};

export const DRIVER_VISITED_STOPS_PER_HOUR: ILmChartWidgetTab = {
    id: 'visited',
    title: 'Drivers',
    subtitle: 'Visited per hour',
    btn:{id: 'visited', icon: 'lm-icon icon-stoppointprocessing', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    chartOptions: mainChartOptions,
};

export const DRIVER_PACKAGES: ILmChartWidgetTab = {
    id: 'packages',
    title: 'Drivers',
    subtitle: 'Packages',
    btn:{id: 'packages', icon: 'lm-icon icon-box', textOnly: true, color: "#ccc", activeColor: '#de921f'},
    data: mainData,
    chartType: 'bar',
    chartOptions: mainChartOptions,
};

export const DRIVER_AVERAGE_DELAY: ILmChartWidgetTab = {
    id: 'delay',
    title: 'Drivers',
    subtitle: 'Average delay',
    btn:{id: 'delay', icon: 'lm-icon icon-timer', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    chartOptions: mainChartOptions,
};

export const DRIVER_COST_PER_SHIPMENT: ILmChartWidgetTab = {
    id: 'cost',
    title: 'Drivers',
    subtitle: 'Cost per shipment',
    btn:{id: 'cost', icon: 'lm-icon icon-dollar-coin', textOnly: true, color: "#ccc", activeColor: '#1c90d4'},
    data: mainData,
    chartType: 'bar',
    chartOptions: mainChartOptions,
};

export const DRIVER_RECIPIENT_EXPERIENCE: ILmChartWidgetTab = {
    id: 'recipient',
    title: 'Drivers',
    subtitle: 'Recipient experience',
    btn:{id: 'recipient', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ba6ef5'},
    data: mainData,
    chartType: 'bar',
    chartOptions: mainChartOptions,
};
