// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#print-window {
  max-height: 100vh;
  overflow-y: auto;
}

#receipt-print-container {
  position: relative;
  padding: 30px;
  width: 210mm;
  margin: auto;
}

#receipt-details-container {
  width: 100%;
  height: 100%;
  page-break-after: always;
  margin-bottom: 50px;
}

#qr-container {
  position: absolute;
  bottom: 0;
  width: 200px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlY2VpcHQtcHJpbnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSSxpQkFBQTtFQUNBLGdCQUFBO0FBREo7O0FBSUE7RUFDSSxrQkFBQTtFQUNBLGFBQUE7RUFDQSxZQUFBO0VBQ0EsWUFBQTtBQURKOztBQUlBO0VBQ0ksV0FBQTtFQUNBLFlBQUE7RUFDQSx3QkFBQTtFQUNBLG1CQUFBO0FBREo7O0FBSUE7RUFDSSxrQkFBQTtFQUNBLFNBQUE7RUFDQSxZQUFBO0FBREoiLCJmaWxlIjoicmVjZWlwdC1wcmludC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ3N0eWxlcy9hYnN0cmFjdHMvdmFyaWFibGVzJztcblxuI3ByaW50LXdpbmRvdyB7XG4gICAgbWF4LWhlaWdodDogMTAwdmg7XG4gICAgb3ZlcmZsb3cteTogYXV0bztcbn1cblxuI3JlY2VpcHQtcHJpbnQtY29udGFpbmVyIHtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgcGFkZGluZzogMzBweDtcbiAgICB3aWR0aDogMjEwbW07XG4gICAgbWFyZ2luOiBhdXRvO1xufVxuXG4jcmVjZWlwdC1kZXRhaWxzLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIHBhZ2UtYnJlYWstYWZ0ZXI6IGFsd2F5cztcbiAgICBtYXJnaW4tYm90dG9tOiA1MHB4O1xufVxuXG4jcXItY29udGFpbmVyIHtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgYm90dG9tOiAwO1xuICAgIHdpZHRoOiAyMDBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/prints/receipt-print/receipt-print.component.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;AADJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,wBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,SAAA;EACA,YAAA;AADJ;AACA,oiCAAoiC","sourcesContent":["@import 'styles/abstracts/variables';\n\n#print-window {\n    max-height: 100vh;\n    overflow-y: auto;\n}\n\n#receipt-print-container {\n    position: relative;\n    padding: 30px;\n    width: 210mm;\n    margin: auto;\n}\n\n#receipt-details-container {\n    width: 100%;\n    height: 100%;\n    page-break-after: always;\n    margin-bottom: 50px;\n}\n\n#qr-container {\n    position: absolute;\n    bottom: 0;\n    width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
