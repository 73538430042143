import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '@app/services/globals';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';

@Injectable()
export class JsonWebTokenInterceptor implements HttpInterceptor {

    constructor(
        public globals: Globals,
        private cookieService: CookieService,
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const jwt = this.cookieService.get('jwt');
        const items = { ...localStorage };
        let authReq = request.clone({
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-app': 'w',
                'language': this.globals.currentLang,
                'Access-Control-Allow-Origin': '*',
            })
        });
        if (jwt) {
            authReq = authReq.clone({
                headers: authReq.headers.set('Authorization', 'Bearer ' + jwt)
            });
        }
        const location = window.location.href;
        let hash = '';
        if (location.includes('/c/')) {
            hash = location.split('c/')[1];
        } else if (location.includes('/client/')) {
            hash = location.split('client/')[1];
        }
        if (hash) {
            authReq = authReq.clone({
                headers: authReq.headers.set('Authorization', hash)
            });
        }
        if (localStorage.getItem('devMode') === 'true') {
            Object.keys(items).forEach(key => {
                if (key.includes('debug')) {
                    const keyName = 'x-' + key;
                    authReq = authReq.clone({
                        headers: authReq.headers.set(keyName, items[key])
                    });
                }
            });
        }

        // send uuid in stop-points on all requests' headers
        authReq = authReq.clone({
            headers: authReq.headers.set('Request-Uuid', generateUniqueIdFromTimestamp(moment().format('x')))
        });
        
        function generateUniqueIdFromTimestamp(timestampMs) {
            // "2022-10-20T09:02:14Z"
            const uuid = timestampMs.toString();
            const finalUuid = uuid + Math.floor(Math.random() * 500 + 1).toString() + Math.floor(Math.random() * 500 + 1).toString() + Math.floor(Math.random() * 500 + 1).toString();
            return finalUuid;
        }

        return next.handle(authReq);

    }

}
