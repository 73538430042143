import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
    selector: 'app-note-modal',
    templateUrl: './note-modal.component.html',
    styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    listen = [];
    isClickedOnce = false;
    
    note;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        private modalService: ModalService,
        public router: Router,
        formBuilder: FormBuilder,
    ) {
        this.myForm = formBuilder.group({
            "note": [this.note]
        });
    }

    patchForm() {
        this.myForm.patchValue({
            "note": this.note
        });
        setTimeout(() => {
            M.updateTextFields();
        }, 100);
    }

    openModal() {
        const modal = document.querySelector('#note-modal');
        const modalBackground = document.querySelector('#note-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');

        document.getElementById('note-textarea').focus();
    }

    closeModal() {
        const modal = document.querySelector('#note-modal');
        const modalBackground = document.querySelector('#note-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
    }

    submitNote() {
        this.note = (<HTMLInputElement>document.getElementById('note-textarea')).value;

        this.isClickedOnce = false;
        this.closeModal();
    }

    getTranslations() {
        this.listen.push(this.translate.get('SETTINGS').subscribe((res: string) => {
        }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}