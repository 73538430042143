// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host button{border:none; color: inherit !important}
    :host ::ng-deep span {color: inherit !important}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1dHRvbi5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJLGFBQWEsV0FBVyxFQUFFLHlCQUF5QjtJQUNuRCxzQkFBc0IseUJBQXlCIiwiZmlsZSI6ImJ1dHRvbi5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICA6aG9zdCBidXR0b257Ym9yZGVyOm5vbmU7IGNvbG9yOiBpbmhlcml0ICFpbXBvcnRhbnR9XG4gICAgOmhvc3QgOjpuZy1kZWVwIHNwYW4ge2NvbG9yOiBpbmhlcml0ICFpbXBvcnRhbnR9XG4gICAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/button/button.component.ts"],"names":[],"mappings":";IACI,aAAa,WAAW,EAAE,yBAAyB;IACnD,sBAAsB,yBAAyB;;AAEnD,obAAob","sourcesContent":["\n    :host button{border:none; color: inherit !important}\n    :host ::ng-deep span {color: inherit !important}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
