import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { LmTableViewModelService } from '@app/shared/structure/table/table-viewmodel.service';
import { LmBaseEntity } from '@app/model/base-entity';

@Injectable()
export abstract class LmTableListViewModelService<TModel extends LmBaseEntity> extends LmTableViewModelService<TModel> implements OnInit, OnDestroy {
    protected getRowsCb = this.getRows.bind(this);
    protected getByIdCb = this.getById.bind(this);
    protected postCb = this.post.bind(this);
    protected putCb = this.put.bind(this);
    protected deleteCb = this.delete.bind(this);
    protected editInlineCb = this.editInline.bind(this);
    protected editInModalCb = this.editInModal.bind(this);
    protected newEntryCb = this.newItem.bind(this);
    protected searchCb = this.search.bind(this);
    protected filterCb = this.filter.bind(this)

  constructor(injector: Injector) {
    super(injector);
  }

  getRows(){}
  getById(id){}
  post(item){}
  put(id, item){}
  delete(id){}
  editInline(id){}
  editInModal(){}
  newItem(item){}
  search(query){}
  filter(query){}

  ngOnInit(): void {
    super.ngOnInit()
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }



}
