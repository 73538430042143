import { Inject, Injectable } from '@angular/core';

import { LMLoggingLevel } from '../../model/enums';
import { LM_ENVIRONMENT, LMEnvironment } from '../../model/environment';

@Injectable()
export class LmLoggerService {
  private _level: LMLoggingLevel;

  // constructor(@Inject(LM_ENVIRONMENT) environment: LMEnvironment) {
  //   this._level = environment.loggerLevel ?? LMLoggingLevel.Verbose;
  // }
  constructor() {
    // this._level = environment.loggerLevel ?? LMLoggingLevel.Verbose;
  }

  logError(message: any, ...optionalParams: any[]) {
    this.log(message, LMLoggingLevel.Error, ...optionalParams);
  }

  logWarning(message: any, ...optionalParams: any[]) {
    this.log(message, LMLoggingLevel.Warning, ...optionalParams);
  }

  logInfo(message: any, ...optionalParams: any[]) {
    this.log(message, LMLoggingLevel.Info, ...optionalParams);
  }

  logVerbose(message: any, ...optionalParams: any[]) {
    this.log(message, LMLoggingLevel.Verbose, ...optionalParams);
  }

  log(message: any, level = LMLoggingLevel.Warning, ...optionalParams: any[]) {
    if (this._level !== LMLoggingLevel.None) {
      if (level >= this._level) {
        switch (level) {
          case LMLoggingLevel.Error:
            console.error(message, ...optionalParams);
            break;
          case LMLoggingLevel.Warning:
            console.warn(message, ...optionalParams);
            break;
          case LMLoggingLevel.Info:
            console.info(message, ...optionalParams);
            break;
          default:
            console.log(message, ...optionalParams);
        }
      }
    }
  }
}
