// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNoaXBtZW50cy1oaXN0b3J5LW1hcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFVQTtFQUNJLFdBQUE7RUFDQSxZQUFBO0FBVEoiLCJmaWxlIjoic2hpcG1lbnRzLWhpc3RvcnktbWFwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnc3R5bGVzL2Fic3RyYWN0cy92YXJpYWJsZXMnO1xuXG4vLyAubWFwLWRlc2NyaXB0aW9ue1xuLy8gICAgIGhlaWdodDogODVweDtcbi8vICAgICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuLy8gICAgIGJvcmRlci1sZWZ0OiAxcHggc29saWQgJGxpZ2h0LWdyZXk7XG4vLyAgICAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICRsaWdodC1ncmV5O1xuLy8gICAgIHBhZGRpbmc6IDIwcHg7XG4vLyB9XG5cbiNtYXAge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgICAvLyBoZWlnaHQ6IGNhbGMoMTAwJSAtIDg1cHgpO1xuICAgIC8vIGJvcmRlci1sZWZ0OiAxcHggc29saWQgJGxpZ2h0LWdyZXk7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/grids/shipments-history-grid/shipments-history-map/shipments-history-map.component.scss"],"names":[],"mappings":"AAUA;EACI,WAAA;EACA,YAAA;AATJ;AACA,oxBAAoxB","sourcesContent":["@import 'styles/abstracts/variables';\n\n// .map-description{\n//     height: 85px;\n//     box-sizing: border-box;\n//     border-left: 1px solid $light-grey;\n//     border-bottom: 1px solid $light-grey;\n//     padding: 20px;\n// }\n\n#map {\n    width: 100%;\n    height: 100%;\n    // height: calc(100% - 85px);\n    // border-left: 1px solid $light-grey;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
