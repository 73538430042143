import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';
import {MultiSelectModule} from 'primeng/multiselect';
import { Observable, take } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmMultiSelectComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmMultiSelectComponent) };

@Component({
  selector: 'lm-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE]
})
export class LmMultiSelectComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;

  @HostBinding() id = `lm-multi-select-${LmMultiSelectComponent.nextId++}`;
  name = `lm-multi-select-${LmMultiSelectComponent.nextId++}`;

  @ViewChild(NgModel) model: NgModel;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() options: any[] = [];
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() dataKey: string;
  @Input() optionLabel = 'name';
  @Input() optionValue = 'id';
  @Input() style: any;
  @Input() styleClass = 'lm-multi-select-control';
  @Input() inputStyleClass = 'lm-multi-select-input';
  @Input() panelStyleClass = 'lm-multi-select-panel';
  @Input() dropdownIcon: string
  @Input() iconClass = '';
  @Input() iconColor: string;
  @Input() iconSize?: string;
  @Input() iconStyle: LmTextIconStyle;
  @Input() showClear: boolean;
  @Input() showHeader = false;
  @Input() filter = false;
  @Input() customIconName = "";
  @Input() appendTo? :any;
  @Input() showToggleAll = false;
  @Input() virtualScroll = false;
  @Input() virtualScrollItemSize = 43;
  @Input() modelOptions: any
  // @Input() selectAll = false; not available in 14.* version
  selections: number[];
  
  @Input() optionsFn: (...args) => any[];
  @Input() optionsFn$: (...args) => Observable<any[]>;
  @Input() selectAllFn: (...args) => any[];

  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  onChange = (_: any) => {};
  onClear = () => {};
  onTouched = () => {};

  onSelectAllChange(e){
    if(this.selectAllFn) this.selectAllFn(e);
  }

  valueChanged(value: any) {
    this.onChange(value);
    this.onTouched();
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.selections = value;
  }

  updateValues() {
    if(this.optionsFn) this.options = this.optionsFn();
    if(this.optionsFn$) this.optionsFn$().pipe(take(1), untilDestroyed(this)).subscribe(res => this.options = res)
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.optionsFn) this.options = this.optionsFn();
    if(this.optionsFn$) this.optionsFn$().pipe(take(1), untilDestroyed(this)).subscribe(res => this.options = res)
  }


  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule
  ],
  exports: [LmMultiSelectComponent, MultiSelectModule],
  declarations: [LmMultiSelectComponent]
})
export class LmMultiSelectModule {}
