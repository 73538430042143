import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/services/globals';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})

export class HttpUtils {
    constructor(
        public globals: Globals,
        public cookieService: CookieService,
        public router: Router
    ) { }

    removeJwt() {
        const domain = window.location.hostname;
        this.cookieService.delete('jwt', '/', domain, true, 'Strict');
    }

    logout() {
        this.removeJwt();
        this.router.navigate(['login']);
    }
}
