import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, take, tap } from 'rxjs/operators';
import { I18N } from '../../../core/i18n/lm.el';
import { LmBaseEntity } from '../../../model/base-entity';
import { LmModelChangeArgs } from '../../../model/model-change-args';
import { LmModelChangingArgs } from '../../../model/model-changing-args';
import { isApiResponse } from '../../utils';
import { LmModelProxyService } from '../../../core/services/model-proxy/model-proxy.service';
import { LmModelValidationService } from '../../../core/services/model-proxy/model-validation.service';
import { LmNotificationService } from '../../../core/services/notification.service';
import { StrictHttpResponse } from '@app/api/strict-http-response';
import { LMApiResponse } from '@app/model/api-response';
import { LmDialogContext } from '@app/model/dialog';


@UntilDestroy()
@Directive()
export abstract class LmTableViewModelService<TModel extends LmBaseEntity> implements OnInit, OnDestroy {
    model: TModel[];
    response: any;
    validationsHeader = I18N.common.crud.alerts;
    modalConfig: any
    confirmBeforeDeleteText: string

    get isBusy$(): Observable<boolean> {
      return this._isBusy$.asObservable();
    }
  
    get isDirty$(): Observable<boolean> {
      return this.modelProxySvc.isDirty$;
    }
  
    protected abstract getRowsCb(): Observable<TModel[]>;
    protected abstract getByIdCb: (id: string) => Observable<TModel>;
    protected abstract postCb: (item: TModel) => Observable<TModel>;
    protected abstract putCb: (id: number, item: TModel) => Observable<void>;
    protected abstract deleteCb: (id: number) => Observable<any>;
    protected abstract editInlineCb:(id: any) => any | void;
    protected abstract newEntryCb: (entry?: TModel) => Observable<TModel>;
    protected abstract searchCb(query:string): Observable<TModel[]>;
    protected abstract filterCb(query:string): Observable<TModel[]>;

  
    notificationSvc: LmNotificationService;
    modelProxySvc: LmModelProxyService<TModel[]>;

    protected _newEntry$ = new Subject<TModel>();
    protected _isBusy$ = new Subject<boolean>();
    protected _isDirty$ = new Subject<boolean>();
  
    constructor(injector: Injector) {
      this.modelProxySvc = injector.get<LmModelProxyService<any>>(LmModelProxyService);
      this.notificationSvc = injector.get<LmNotificationService>(LmNotificationService);
     }
  
    // abstract deleteResponse(id: string): Observable<StrictHttpResponse<void>>;
    ngOnInit(): void {
      this.init().subscribe(_=> this.setupModelProxy());
    }

    ngOnDestroy(): void {
      this._newEntry$.complete();
      this._isBusy$.complete();
      this._isDirty$.complete();
    }

    setupModelProxy() {
      this.modelProxySvc.modelChanged = this.modelChanged.bind(this);
      this.modelProxySvc.modelChanging = this.modelChanging.bind(this);
    }

    modelChanged(change: LmModelChangeArgs<TModel>): void {}
    modelChanging(change: LmModelChangingArgs<TModel>): boolean | Observable<boolean> {
      return true;
    }


    init(){
      return of(null)
        .pipe(
          tap(() => this.emitIsBusy(true)),
          switchMap(() => this.getRowsCb()),
          tap((res) => {
            this.model = (<any>res).items
          }),
          finalize(() => {
            this.emitIsDirty(false);
            this.emitIsBusy(false);
          }),
          take(1),
          untilDestroyed(this)
        )
    }

    searchItems(query:string){
      return of(null)
        .pipe(
          tap(() => this.emitIsBusy(true)),
          switchMap(() => this.searchCb(query)),
          tap((res) => {
            this.model = (<any>res).items
          }),
          finalize(() => {
            this.emitIsDirty(false);
            this.emitIsBusy(false);
          }),
          take(1),
          untilDestroyed(this)
        )
    }

    filterItems(query:string){
      return of(null)
        .pipe(
          tap(() => this.emitIsBusy(true)),
          switchMap(() => this.filterCb(query)),
          tap((res) => {
            this.model = (<any>res).items
          }),
          finalize(() => {
            this.emitIsDirty(false);
            this.emitIsBusy(false);
          }),
          take(1),
          untilDestroyed(this)
        )
    }

    postItem(item:TModel){
      return of(null)
        .pipe(
          tap(() => this.emitIsBusy(true)),
          switchMap(() => this.postCb(item)),
          switchMap(() => this.getRowsCb()),
          tap((res) => {
            this.model = (<any>res).items
          }),
          finalize(() => {
            this.emitIsDirty(false);
            this.emitIsBusy(false);
          }),
          take(1),
          untilDestroyed(this)
        )
    }

    putItem(item: TModel, id: number) {
      return of(null)
        .pipe(
            tap(() => this.emitIsBusy(true)),
            switchMap(() => this.putCb(id, item)),
            switchMap(() => this.getRowsCb()),
            tap((res) => {
                this.model = (<any>res).items;
            }),
            finalize(() => {
                this.emitIsDirty(false);
                this.emitIsBusy(false);
            }),
            take(1),
            untilDestroyed(this)
        );
  }

    newEntry(){
      return this.newEntryCb();
    }

    getItem(id:string){}
  
    deleteItem(id: number) {
      // replace of(null) with showQuestion form notification service
      return of(null).pipe(
        tap(() => this.emitIsBusy(true)),
        switchMap(() => this.deleteCb(id)),
        switchMap(() => this.getRowsCb()),
        tap((res) => {
          this.model = (<any>res).items
        }),
        finalize(() => {
          this.emitIsDirty(false);
          this.emitIsBusy(false);
        }),
        take(1),
        untilDestroyed(this)
      )
      .subscribe()
    }

    editItemInModal() {
      this.notificationSvc.showDialog<void>(this.modalConfig).subscribe();
    }

    editItemInline(id?: any) {
      // replace of(null) with showQuestion form notification service
      return of(null).pipe(
        tap(() => this.emitIsBusy(true)),
        switchMap(() => this.editInlineCb(id)),
        tap(() => this.modelProxySvc.acceptChanges()),
        finalize(() => this.emitIsBusy(false))
      )
    }
  
  
    protected emitIsBusy(isBusy: boolean): void {
      this._isBusy$.next(isBusy);
    }
  
    protected emitIsDirty(isDirty: boolean): void {
      this._isDirty$.next(isDirty);
    }
  
  
    showError(err: HttpErrorResponse): void {
      // let message: string;
  
      // if (isApiResponse(err.error)) {
      //   message = err.error.messages.map((msg) => msg.message).join('\n');
      // } else {
      //   message = err.error.Message || err.error.title;
      // }
  
      this.notificationSvc.showError(I18N.common.unhandledError, 'ERROR');
    }
  
   
  }
  