import { RulesModalComponent } from '@app/modals/rules-modal/rules-modal.component';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-rules-grid',
  templateUrl: './rules-grid.component.html',
  styleUrls: ['./rules-grid.component.scss']
})
export class RulesGridComponent implements OnInit, OnDestroy {

  @ViewChild(RulesModalComponent, { static: false }) rulesModalComponent: RulesModalComponent;

  normalMsg;
  highMsg;
  listen = [];
  rulesDataArray = [];
  subRuleLabels = {
    word: '',
    volume: '',
    payAmount: '',
    region: '',
    dimension: '',
    timeWindow: '',
    duration: '',
    volumes: '',
    priority: '',
    vehicles: '',
    drivers: '',
    merge: '',
    vehicleTypes: '',
  };
  operatorLabels = {};
  volumeTypesList = [];
  gridApi;
  minutesLabel;
  secondsLabel;
  andLabel;
  gridColumnApi;
  timeWindows = [];
  rowData: any;
  domLayout = 'autoHeight';
  columnDefs;
  canAddOrEdit = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private settingsService: SettingsService,
    public gridsService: GridsService,
  ) {
    this.canAddOrEdit = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] || this.globals.accessRole == this.globals.teamMemberTypeConstants['ADVANCED'];
    this.listen.push(this.settingsService.updateRulesGridListen().subscribe(() => {
      this.setRulesData();
    }));
    this.listen.push(this.settingsService.updateTimeWindowGridListen().subscribe(() => {
      this.http.get('api/v1/companies/' + this.globals.companyId + '/company-time-window').subscribe(response => {
        this.timeWindows = response['items'];
      });
    }));
  }

  labelRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      params.getValue().forEach(element => {
        columnObject += '<b>' + element.condition + '</b> ' + element.operator + ' ';
      });
    }
    return columnObject;
  }

  loadSubRule(operatorType, operands) {
    let firstOperand, secondOperand;
    let selectedActionType, selectedConstant, selectedColumn;
    operands.forEach(operand => {
      if (operand['order'] === 0) {
        firstOperand = operand;
      } else {
        secondOperand = operand;
      }
    });
    if (operatorType === this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE']) {
      selectedActionType = 'word';
      selectedConstant = firstOperand['value'];
      selectedColumn = secondOperand['value'];
    } else if (this.volumeTypesList.includes(firstOperand['value'])) {
      selectedActionType = 'volume';
      selectedConstant = secondOperand['value'];
    } else if (firstOperand['value'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_PAY_AMOUNT']['constant_name']) {
      selectedActionType = 'payAmount';
      selectedConstant = secondOperand['value'];
    } else if (firstOperand['value'] === this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT']['constant_name']) {
      selectedActionType = 'region';
      selectedConstant = secondOperand['value'];
    } else if (
      firstOperand['value'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_HEIGHT']['constant_name'] ||
      firstOperand['value'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_LENGTH']['constant_name'] ||
      firstOperand['value'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_WIDTH']['constant_name']
    ) {
      selectedActionType = 'dimension';
      selectedConstant = secondOperand['value'];
    }
    let str = 'general rule ' + operatorType + ': ' + firstOperand['value'] + ' - ' + secondOperand['value'];
    if (this.subRuleLabels[selectedActionType]) {
      str = this.subRuleLabels[selectedActionType];
      str = str.replace('selectedConstant', selectedConstant);
      if (this.operatorLabels[operatorType]) { str = str.replace('OPERATOR', this.operatorLabels[operatorType]); }
      if (selectedColumn) {
        if (this.globals.stopPointImportFieldConfigsConstantNamesToData[selectedColumn]) {
          str = str.replace('selectedColumn', this.globals.stopPointImportFieldConfigsConstantNamesToData[selectedColumn]['label']);
        } else {
          console.error('Could not find label for column.');
          console.error('selectedColumn: ' + selectedColumn);
          console.error(this.globals.stopPointImportFieldConfigsConstantNamesToData);
        }
      }
    } else {
      console.error('Could not find label for rule.');
      console.error('operatorType: ' + operatorType);
      console.error('selectedActionType: ' + selectedActionType);
      console.error(this.subRuleLabels);
      console.error(operands);
    }
    return str;
  }

  ruleIsPackaging(rule) {
    let operandIsCollaborator = false;
    rule['trigger']['expression']['operands'].forEach(operand => {
      if (operand.value === 'SP_SUP') {
        operandIsCollaborator = true;
      }
    });
    // if the trigger is collaborator and there is only one action
    if (
      rule['trigger']['expression']['operator_type'] === this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE'] &&
      operandIsCollaborator &&
      rule['actions'].length === 1
    ) {
      if (rule['actions'][0]['expression']) {
        if (rule['actions'][0]['expression']['operator_type'] === this.globals.ruleExpressionOperatorTypeConstants['ADDITION']) {
          let allOperandsAreOfTypeMul = true;
          if (rule['actions'][0]['expression']['operands']) {
            rule['actions'][0]['expression']['operands'].forEach(operand => {
              if (operand['expression']) {
                if (operand['expression']['operator_type'] !== this.globals.ruleExpressionOperatorTypeConstants['MULTIPLY']) {
                  allOperandsAreOfTypeMul = false;
                }
              } else {
                allOperandsAreOfTypeMul = false;
              }
            });
          }
          if (allOperandsAreOfTypeMul) {
            return true;
          }
        }
      }

    }
    return false;
  }

  setRulesData() {
    this.http.get('api/v1/company/import-rules?returnRulesWithoutCustomAreaFlag=true').pipe(take(1)).subscribe(response => {
      let condition;
      this.rulesDataArray = [];
      if (response['items'].length) {
        response['items'].forEach(element => {
          const ruleForGrid = {
            operators: [],
            id: null
          };
          const rule = element['importRule']['rule'];
          const actions = rule['actions'];
          const triggerOperatorType = rule['trigger']['expression']['operator_type'];
          // if rule is not driver's region OR packaging rule
          if (
            !(triggerOperatorType === this.globals.ruleExpressionOperatorTypeConstants['WITHIN_AREA'] && actions[0]['ruleActionXDriver']) &&
            !this.ruleIsPackaging(rule)
          ) {
            this.listen.push(this.translate.get('RULES.WHEN_CONDITION').subscribe((res: string) => { condition = res; }));
            if (triggerOperatorType === this.globals.ruleExpressionOperatorTypeConstants['AND']) {
              rule['trigger']['expression']['operands'].forEach(operand => {
                const expression = operand.expression;
                const operator = {
                  operator: this.loadSubRule(expression['operator_type'], expression['operands']),
                  condition: condition,
                };
                ruleForGrid.operators.push(operator);
                this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { condition = res; }));
              });
            } else {
              const operator = {
                operator: this.loadSubRule(triggerOperatorType, rule['trigger']['expression']['operands']),
                condition: condition,
              };
              ruleForGrid.operators.push(operator);
            }
            this.listen.push(this.translate.get('RULES.THEN_CONDITION').subscribe((res: string) => { condition = res; }));
            let selectedActionType, durationSeconds, timeWindowLabel, priority;
            actions.forEach(action => {
              const actionOperatorType = action['type'];
              if (
                action['result_attribute'] === this.globals.stopPointImportFieldConfigs['MATCHER_ONLY_ATTRIBUTE_LOAD']['constant_name'] ||
                action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CALCULATED_LOAD']['constant_name']
              ) {
                selectedActionType = 'volumes';
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_DURATION']['constant_name']) {
                selectedActionType = 'duration';
                const duration = action['actionValue']['value'];
                durationSeconds = [moment.duration(duration).asSeconds()];
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_COMPANY_TIME_WINDOW_CATEGORY_ID']['constant_name']) {
                selectedActionType = 'timeWindow';
                const selectedTimeWindowId = action['companyTimeWindowCategoryId'];
                this.timeWindows.forEach(timeWindow => {
                  if (timeWindow['CompanyTimeWindowCategory']['id'] === selectedTimeWindowId) {
                    timeWindowLabel = timeWindow['CompanyTimeWindowCategory']['label'];
                  }
                });
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_PRIORITY']['constant_name']) {
                selectedActionType = 'priority';
                priority = action['actionValue']['value'];
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name']) {
                selectedActionType = 'drivers';
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_VEHICLE_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name']) {
                selectedActionType = 'vehicles';
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CUSTOMER_ID_FOR_MERGING_STOP_POINT_TO']['constant_name']) {
                if (action['ruleActionXCustomer']) {
                  selectedActionType = 'merge';
                }
              } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_VEHICLE_TYPE']['constant_name']) {
                if (action['actionValue']) {
                  selectedActionType = 'vehicleTypes';
                }
              } else {
                console.error('Unexpected error');
              }
            });

            let str = this.subRuleLabels[selectedActionType];
            if (timeWindowLabel) { str = str.replace('timeWindow', timeWindowLabel); }
            if (durationSeconds) {
              const durationMinutesFloor = Math.floor(Number(durationSeconds) / 60);
              const durationSecondsFloor = durationSeconds - durationMinutesFloor * 60;
              let durationString: String = durationMinutesFloor + ' ' + this.minutesLabel;
              if (durationSecondsFloor) { durationString += ' ' + this.andLabel + ' ' + durationSecondsFloor + ' ' + this.secondsLabel; }
              str = str.replace('durationString', durationString);
            }
            if (priority === this.globals.stopPointPriorityConstants['HIGH']) {
              str = str.replace('priorityState', this.highMsg);
            } else if (priority === this.globals.stopPointPriorityConstants['NORMAL']) {
              str = str.replace('priorityState', this.normalMsg);
            }
            const actionOperator = {
              operator: str,
              condition: condition,
            };
            ruleForGrid.id = element['importRule']['id'];
            ruleForGrid.operators.push(actionOperator);
            this.rulesDataArray.push(ruleForGrid);
          }
        });
      } else {
        const noData = { noDataText: 'No data' }
        this.rulesDataArray.push(noData);
      }
      this.rowData = of(this.rulesDataArray);
    });
  }

  newRule() {
    this.rulesModalComponent.openRulesModal();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter((<HTMLInputElement>document.getElementById('filter-text-box')).value);
  }

  rowClicked(event) {
    if (!event.data.noDataText && this.canAddOrEdit) {
      this.rulesModalComponent.getRuleData(event.data.id);
      this.rulesModalComponent.openRulesModal();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFirstDataRendered(params) { }

  getTranslations() {
    const lessThanConstant = this.globals.ruleExpressionOperatorTypeConstants['LESS_THAN'];
    const equalToConstant = this.globals.ruleExpressionOperatorTypeConstants['EQUAL_TO'];
    const graterThanConstant = this.globals.ruleExpressionOperatorTypeConstants['GREATER_THAN'];
    this.listen.push(this.translate.get('RULES.LESS_THAN_OPERAND').subscribe((res: string) => { this.operatorLabels[lessThanConstant] = res; }));
    this.listen.push(this.translate.get('RULES.EQUAL_TO_OPERAND').subscribe((res: string) => { this.operatorLabels[equalToConstant] = res; }));
    this.listen.push(this.translate.get('RULES.GRATER_THAN_OPERAND').subscribe((res: string) => { this.operatorLabels[graterThanConstant] = res; }));
    this.listen.push(this.translate.get('RULES.WORD_MSG').subscribe((res: string) => { this.subRuleLabels.word = res; }));
    this.listen.push(this.translate.get('RULES.VOLUME_MSG').subscribe((res: string) => { this.subRuleLabels.volume = res; }));
    this.listen.push(this.translate.get('RULES.PAY_AMOUNT_MSG').subscribe((res: string) => { this.subRuleLabels.payAmount = res; }));
    this.listen.push(this.translate.get('RULES.REGION_MSG').subscribe((res: string) => { this.subRuleLabels.region = res; }));
    this.listen.push(this.translate.get('RULES.DIMENSION_MSG').subscribe((res: string) => { this.subRuleLabels.dimension = res; }));
    this.listen.push(this.translate.get('RULES.TIME_WINDOW_MSG').subscribe((res: string) => { this.subRuleLabels.timeWindow = res; }));
    this.listen.push(this.translate.get('RULES.DURATION_MSG').subscribe((res: string) => { this.subRuleLabels.duration = res; }));
    this.listen.push(this.translate.get('RULES.VOLUMES_MSG').subscribe((res: string) => { this.subRuleLabels.volumes = res; }));
    this.listen.push(this.translate.get('RULES.PRIORITY_MSG').subscribe((res: string) => { this.subRuleLabels.priority = res; }));
    this.listen.push(this.translate.get('RULES.VEHICLES_MSG').subscribe((res: string) => { this.subRuleLabels.vehicles = res; }));
    this.listen.push(this.translate.get('RULES.DRIVERS_MSG').subscribe((res: string) => { this.subRuleLabels.drivers = res; }));
    this.listen.push(this.translate.get('RULES.MERGE_MSG').subscribe((res: string) => { this.subRuleLabels.merge = res; }));
    this.listen.push(this.translate.get('RULES.VEHICLE_TYPES_MSG').subscribe((res: string) => { this.subRuleLabels.vehicleTypes = res; }));
    this.listen.push(this.translate.get('RULES.HIGH').subscribe((res: string) => { this.highMsg = res; }));
    this.listen.push(this.translate.get('RULES.NORMAL').subscribe((res: string) => { this.normalMsg = res; }));
    this.listen.push(this.translate.get('TIME.MINUTES').subscribe((res: string) => { this.minutesLabel = res; }));
    this.listen.push(this.translate.get('TIME.SECONDS').subscribe((res: string) => { this.secondsLabel = res; }));
    this.listen.push(this.translate.get('TIME.AND').subscribe((res: string) => { this.andLabel = res; }));
    this.setRulesData();
  }

  ngOnInit() {
    this.columnDefs = [
      { headerName: '', field: 'operators', cellRenderer: this.labelRenderer, width: this.gridsService.widthCalculatorSettings(100) }
    ];

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.http.get('api/v1/companies/' + this.globals.companyId + '/company-time-window').pipe(take(1)).subscribe(response => {
      this.timeWindows = response['items'];
    });

    const matchedColumnsObject = this.globals.stopPointImportFieldConfigs;
    Object.keys(matchedColumnsObject).forEach(key => {
      if (!matchedColumnsObject[key]['hidden']) {
        if (
          matchedColumnsObject[key]['valueType'] === this.globals.ruleValueTypeConstants['VOLUME'] &&
          matchedColumnsObject[key]['operand_attribute_type'] === this.globals.ruleAttributeTypeConstants['IMPORT_DYNAMIC_FIELD']
        ) {
          this.volumeTypesList.push(matchedColumnsObject[key]['constant_name']);
        }
      }
    });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
