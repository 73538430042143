import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { Globals } from '@app/services/globals';
import { LmDiscountsService } from '@app/api/services/discounts.service';
import { IPriceListServices } from '@app/model/price-list';
import { DiscountModalComponent } from './discount-modal/discount-modal.component';
import { INITIAL_DATA } from '@app/model/initial-data';
import { IDiscount_Dto, IDiscounts_Dto } from '@app/api/models/discounts-dto';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable()
export class DiscountsViewModelService extends LmTableListViewModelService<IDiscounts_Dto> implements OnInit, OnDestroy {
    basicServicesEnum: IPriceListServices;
    modalConfig: any;
    showBasicServiceModal = DiscountModalComponent;
    basicServicesEnumKeyToTranslationsKeyMap = {};
    collaboratorId: number;
    count = 0;

    constructor(
        injector: Injector, 
        public globals: Globals,
        @Inject(forwardRef(() => LmDiscountsService)) public apiSvc: LmDiscountsService,
        private doms : DomSanitizer
    ){
        super(injector);
        const {priceListServicesEnum} = globals;
        this.basicServicesEnum = priceListServicesEnum;
        this.modalConfig = { componentType: this.showBasicServiceModal, injector: injector,data: {VM: this, globals: this.globals} }
        this.apiSvc.globals = this.globals;
        this.basicServicesEnumKeyToTranslationsKeyMap[this.basicServicesEnum.NextDay.key] = 'NEXT_DAY';
        this.basicServicesEnumKeyToTranslationsKeyMap[this.basicServicesEnum.Express.key] = 'SAME_DAY';
        this.basicServicesEnumKeyToTranslationsKeyMap[this.basicServicesEnum.ThroughHub.key] = 'THROUGH_HUB';
    }

    private doSomething() {
        console.log(1);
    }
    
    private transformRows = (res) => {
        this.count = res.items.length;
        function doSm() { console.log(111); }
        res.items.forEach(item => {
            let priceLists = '';
            item.price_lists.forEach(priceList => {
                if (priceLists!='') {
                    priceLists += ', '
                }
                priceLists += '<span class="price-list-link" (click)="this.doSomething()">' + priceList.name + '</span>'
                const test = this.doms.bypassSecurityTrustHtml(
                    '<span class="price-list-link" onclick="doSm">' + priceList.name + '</span>'
                );
                item.priceLists = test;
            });
            // item.priceLists = priceLists;
        });
        return res;
    }
    
    getRows(){
        return this.apiSvc.apiDiscountsAllGet(this.collaboratorId).pipe(switchMap(res => of(this.transformRows(res))));
    }
    getById(id){

    }
    post(item: any) {
        return this.apiSvc.apiDiscountsPost({body: item})
    }
    put(id) {
        
    }
    delete(id) {
        return this.apiSvc.apiDiscountsSingleDelete({id:id})
    }
    newItem() {
        const newItem = {...INITIAL_DATA.newDiscountItem};
        newItem.collaborator_id = Number(this.collaboratorId);
        // newItem.company_id = this.globals.companyId;
        return newItem;
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
