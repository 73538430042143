import { LM_STACKED_CHART_BORDER_RADIUS, lmAvatarAnnotation, lmVerticalLabelAnnotation} from "@app/model/charts-config";
import { ILmCSemiCircleChartWidgetTab, ILmChartWidgetTab } from "@app/model/widget";
import { ChartData, ChartOptions } from "chart.js";
import { chartAvatar1,  chartAvatar2, chartAvatar3, chartAvatar4, chartAvatar5 } from '@app/dashboard-report/dashboard-report-dummy-imgs';
import { chartImageAnnotation, chartAnnotationY } from "@app/shared/utils";
import { PartialEventContext } from "chartjs-plugin-annotation";
import { CO2_EMISSSIONS, DELIVERIES_AND_PICKUPS, FIRST_ATTEMPT_SUCCESS_RATE, IN_TOTAL, ON_TIME, TOTAL_DISTANCE } from "@app/shared/structure/widgets/configs/dashboard-shipments-config";
import { AVERAGE_DELAY, COST_PER_SHIPMENT, PACKAGES, RECIPIENT_EXPERIENCE, SCORE, VISITED_STOPS_PER_HOUR } from "@app/shared/structure/widgets/configs/dashboard-productivity-config";
import { DRIVER_AVERAGE_DELAY, DRIVER_COST_PER_SHIPMENT, DRIVER_PACKAGES, DRIVER_RECIPIENT_EXPERIENCE, DRIVER_VISITED_STOPS_PER_HOUR, DRIVER_PRODUCTIVITY } from "@app/shared/structure/widgets/configs/dashboard-drivers-config";
import { BRANCHES_AVERAGE_DELAY, BRANCHES_COST_PER_SHIPMENT, BRANCHES_PACKAGES, BRANCHES_RECIPIENT_EXPERIENCE, BRANCHES_VISITED_STOPS_PER_HOUR, BRANCHES_PRODUCTIVITY, BRANCHES_DELIVERIES_AND_PICKUPS, BRANCHES_ON_TIME, BRANCHES_SHIPMENTS } from "@app/shared/structure/widgets/configs/dashboard-branches-config";

export const LM_WIDGET_PERIODS = [
    {name: 'Week', id: 1}, 
    {name: 'Month', id: 2}, 
    {name: 'Three months', id: 3}, 
    {name: 'Six months', id: 4}, 
    {name: 'Year', id: 6}, 
    {name: 'Custom', id: 7}
]


const chartImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=';
const chartDataAvatars = [chartImg, null, chartImg, chartImg, chartImg, chartImg, chartImg, chartImg];

export const LM_WIDGET_BAR_CHART_2  = {
    labels: ['picanha ', 'fatback ', 'round ', 'landjaeger', 'shankle ', 'bacon ', 'Biltong ', 'shank'],
    datasets: [
        { 
            backgroundColor: ['#741b47'],
            data: [1, 2, 3, 5, 2, 0, 3, 4],
            avatars: chartDataAvatars
        }
    ]
};
export const LM_WIDGET_BAR_CHART_3: ChartData  = {
    labels: ['Blah 1', 'Blah 2', 'Blah 3', 'Blah 4', 'Blah 5', 'Blah 6', 'Blah 7', 'Blah 8'],
    datasets: [
        { 
            backgroundColor: ['#146540'],
            data: [10, 20, 30, 40, 50, 60, 70, 80],
        }
    ]
};
export const LM_WIDGET_BAR_CHART_4: ChartData  = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',],
    datasets: [
        { 
            backgroundColor: ['#'],
            data: [60, 20, 60, 30, 90, 70, 330, 20],
        }
    ]
};


export const LM_WIDGET_BAR_CHART_MULTI  = {
    labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis'],
    datasets: [
        {
            backgroundColor: ['#00aeba', '#741b47', '#134f5c', '#b45f06', '#0b5393'],
            data: [45, 40, 30, 50, 60]
        }
    ]
};

export const LM_WIDGET_BAR_CHART_MULTI_2  = {
    labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis'],
    datasets: [
        {
            backgroundColor: ['#146540', '#dbbc00', '#134f5c', '#b45f06', '#0b5393'],
            data: [60, 70, 50, 90, 80]
        }
    ]
};

export const MONTHS_1: ChartData  = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Jan'],
    datasets: [
        {
            label: 'Value 7',
            backgroundColor: ['#f9c406'],
            data: [50, 90, 70, 120, 50],

            order: 1,
            stack: 'Stack 6'
        },
        {
            label: 'Limit 7',
            backgroundColor: ['#fef1c3'],
            data: [50, 90, 60, 150, 50],
            order: 1,
            stack: 'Stack 6'
        },
        {
            label: 'Value 8',
            backgroundColor: ['#ad1919'],
            data: [60, 70, 80, 90, 60,],
            order: 1,
            stack: 'Stack 7'
        },
        {
            label: 'Limit 8',
            backgroundColor: ['#ffafaf'],
            data: [120, 70, 40, 100, 120],

            order: 1,
            stack: 'Stack 7'
        },
        {
            label: 'Value 9',
            backgroundColor: ['#0b5393'],
            data: [100, 110, 120, 130, 130],
            order: 1,
            stack: 'Stack 8'
        },
        {
            label: 'Limit 9',
            backgroundColor: ['#acd8ff'],
            data: [130, 90, 160, 150, 130],
            order: 1,
            stack: 'Stack 8'
        }
    ]
};

export const MONTHS_2: ChartData  = {
    labels: ['May', 'Jun', 'Jul', 'Aug', 'May'],
    datasets: [
        {
            label: 'Value 4',
            backgroundColor: ['#f9c406'],
            data: [190, 220, 40, 100, 120],
            order: 1,
            stack: 'Stack 3'
        },
        {
            label: 'Limit 4',
            backgroundColor: ['#fef1c3'],
            data: [200, 190, 60, 150, 50],
            order: 1,
            stack: 'Stack 3'
        },
        {
            label: 'Value 5',
            backgroundColor: ['#ad1919'],
            data: [130, 90, 160, 150, 130],
            order: 1,
            stack: 'Stack 4'
        },
        {
            label: 'Limit 5',
            backgroundColor: ['#ffafaf'],
            data: [190, 1100, 1800, 170, 150],
            order: 1,
            stack: 'Stack 4'
        },
        {
            label: 'Value 6',
            backgroundColor: ['#0b5393'],
            data: [100, 110, 120, 130, 130, 90, 160, 150],
            order: 1,
            stack: 'Stack 5'
        },
        {
            label: 'Limit 6',
            backgroundColor: ['#acd8ff'],
            data: [120, 170, 180, 190, 160, 110, 180, 190],
            order: 1,
            stack: 'Stack 5'
        }
    ]
};

export const MONTHS_3: ChartData  = {
    labels: ['Sep', 'Oct', 'Nov', 'Dec', 'Sep'],
    datasets: [
        {
            label: 'Value 7',
            backgroundColor: ['#f9c406'],
            data: [50, 90, 70, 120, 50],

            order: 1,
            stack: 'Stack 6'
        },
        {
            label: 'Limit 7',
            backgroundColor: ['#fef1c3'],
            data: [50, 90, 60, 150, 50],
            order: 1,
            stack: 'Stack 6'
        },
        {
            label: 'Value 8',
            backgroundColor: ['#ad1919'],
            data: [60, 70, 80, 90, 60,],
            order: 1,
            stack: 'Stack 7'
        },
        {
            label: 'Limit 8',
            backgroundColor: ['#ffafaf'],
            data: [120, 70, 40, 100, 120],

            order: 1,
            stack: 'Stack 7'
        },
        {
            label: 'Value 9',
            backgroundColor: ['#0b5393'],
            data: [100, 110, 120, 130, 130],
            order: 1,
            stack: 'Stack 8'
        },
        {
            label: 'Limit 9',
            backgroundColor: ['#acd8ff'],
            data: [130, 90, 160, 150, 130],
            order: 1,
            stack: 'Stack 8'
        }
    ]
};


export const SEMICIRCLE: ILmCSemiCircleChartWidgetTab[] = [
    {
        id: 'experience',
        title: 'General',
        subtitle: 'Experience',
        value: 40,
        btn:{id: 'experience', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ad1919', active: true},
        chartOptions:{
            iconClass: 'icon-heart',
            valuecolor: '#ad1919'
        }
    },
    {
        id: 'punctuality',
        title: 'General',
        subtitle: 'Punctuality',
        value: 88,
        btn:{id: 'punctuality', icon: 'lm-icon icon-timewindowprocessing', textOnly: true, color: "#ccc", activeColor: '#0B5393'},
        chartOptions:{
            iconClass: 'icon-timewindowprocessing',
            valuecolor: '#0B5393',
            min: 0,
            max: 100,
            percent: true
        }
    },
    {
        id: 'productivity',
        title: 'General',
        subtitle: 'Productivity score',
        value: 20,
        btn:{id: 'productivity', icon: 'lm-icon icon-box-sent', textOnly: true, color: "#ccc", activeColor: '#f0b200'},
        chartOptions:{
            iconClass: 'icon-box-sent',
            valuecolor: '#f0b200',
            min: 0,
            max: 100
        }
    }
]

export const PRODUCTIVITY: ILmChartWidgetTab[] = [
    {
        ...SCORE,
        data: {
            ...SCORE.data,
            labels: ['21/09', '22/09', '23/09', '24/09', '25/09', '26/09', '27/09',],
            datasets: [
              {
                ...SCORE.data.datasets[0],
                data: [4, 5, 4, 4.5, 5, 2, 3.4],
              },
            ],
        },
    },
    {
        ...VISITED_STOPS_PER_HOUR,
        data: {
            ...VISITED_STOPS_PER_HOUR.data,
            labels: ['21/09', '22/09', '23/09', '24/09', '25/09', '26/09', '27/09',],
            datasets: [
              {
                ...VISITED_STOPS_PER_HOUR.data.datasets[0],
                data: [40, 50, 40, 45, 50, 20, 34],
              },
            ],
        },
    },
    {
        ...PACKAGES,
        data: {
            ...PACKAGES.data,
            labels: ['21/09', '22/09', '23/09', '24/09', '25/09', '26/09', '27/09',],
            datasets: [
              {
                ...PACKAGES.data.datasets[0],
                data: [6000, 7400, 5400, 6000, 7500, 2000, 4300],
              },
            ],
        },
    },
    {
        ...AVERAGE_DELAY,
        data: {
            ...AVERAGE_DELAY.data,
            labels: ['21/09', '22/09', '23/09', '24/09', '25/09', '26/09', '27/09',],
            datasets: [
              {
                ...AVERAGE_DELAY.data.datasets[0],
                data: [25, 20, 40, 1, 30, 20, 34],
              },
            ],
        },
    },
    {
        ...COST_PER_SHIPMENT,
        data: {
            ...COST_PER_SHIPMENT.data,
            labels: ['21/09', '22/09', '23/09', '24/09', '25/09', '26/09', '27/09',],
            datasets: [
              {
                ...COST_PER_SHIPMENT.data.datasets[0],
                data: [2, 2, 4, 1, 3, 2, 3],
              },
            ],
        },
    },
    {
        ...RECIPIENT_EXPERIENCE,
        data: {
            ...RECIPIENT_EXPERIENCE.data,
            labels: ['21/09', '22/09', '23/09', '24/09', '25/09', '26/09', '27/09',],
            datasets: [
              {
                ...RECIPIENT_EXPERIENCE.data.datasets[0],
                data: [4, 5, 4, 4.5, 5, 2, 3.4],
              },
            ],
        },
    },
    // {
    //     id: 'score',
    //     title: 'Productivity',
    //     subtitle: 'Score',
    //     btn:{id: 'score', icon: 'lm-icon icon-company', textOnly: true, color: "#ccc", activeColor: '#dbbc00', active: true},
    //     data: LM_WIDGET_BAR_CHART_4,
    //     pages: [MONTHS_1, MONTHS_2, MONTHS_3],
    //     chartType: 'bar',
    //     chartOptions:{
    //         scales: {
    //             x: {
    //                 stacked: true
    //             }
    //         },
    //         datasets:{
    //             bar:{
    //                 barThickness: 22,
    //                 borderRadius: Number.MAX_VALUE, 
    //                 borderColor: "rgba(0,0,0,0)",
    //                 borderWidth: {
    //                     bottom:0,
    //                     top:0,
    //                     left:2,
    //                     right:2
    //                 },
    //                 borderSkipped: "bottom"
    //             }
    //         },
    //         plugins:{
    //             legend:{
    //                 display: true,
    //                 position: 'bottom',
    //                 align: 'start',
    //                 labels:{
    //                     padding: 20,
    //                     usePointStyle: true,
    //                     pointStyle: 'circle',
    //                     color: '#999',
    //                     font:{
    //                         size: 12
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // },
]

export const DELIVERIES_FILTERS = [
    { 
        id: 'percentage', 
        label: 'Percentage', 
        chartType: 'line',
        on: false,
        data:{
            labels: [],
            datasets: [
                {
                    label: 'deliveries',
                    type: 'line',
                    data: [], // Align with left bars
                    borderColor: '#b45f06',
                    borderWidth: 1,
                    pointBackgroundColor: '#b45f06',
                    fill: false,
                    spanGaps: false,
                },
                {
                    label: 'pickups',
                    type: 'line',
                    data: [], // Align with left bars
                    borderColor: '#1992D4',
                    borderWidth: 1,
                    pointBackgroundColor: '#1992D4',
                    fill: false,
                    spanGaps: false
                }
            ]
        }
    }
];

export const SHIPMENTS: ILmChartWidgetTab[] = [
    {
        ...IN_TOTAL,
        data: IN_TOTAL.data
    },
    {
        ...DELIVERIES_AND_PICKUPS,
        data: DELIVERIES_AND_PICKUPS.data,
        filters: DELIVERIES_FILTERS,
    },
    {
        ...ON_TIME,
        data: ON_TIME.data
    },
    {
        ...FIRST_ATTEMPT_SUCCESS_RATE,
        data: FIRST_ATTEMPT_SUCCESS_RATE.data
    },
    {
        ...TOTAL_DISTANCE,
        data: TOTAL_DISTANCE.data
    },
    {
        ...CO2_EMISSSIONS,
        data: CO2_EMISSSIONS.data
    },
];

export const BRANCHES: ILmChartWidgetTab[] = [
    {
        ...BRANCHES_PRODUCTIVITY,
        data: {
            ...BRANCHES_PRODUCTIVITY.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_PRODUCTIVITY.data.datasets[0],
                data: [5, 4.8, 4.77, 4.5, 4.4, 4.3, 4.2, 4, 3.4, 3.2]
              },
            ],
        },
    },
    {
        ...BRANCHES_SHIPMENTS,
        data: {
            ...BRANCHES_SHIPMENTS.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_SHIPMENTS.data.datasets[0],
                data: [55, 48, 47, 45, 44, 43, 42, 40, 34, 32]
              },
            ],
        },
    },
    {
        ...BRANCHES_DELIVERIES_AND_PICKUPS,
        data: {
            ...BRANCHES_DELIVERIES_AND_PICKUPS.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_DELIVERIES_AND_PICKUPS.data.datasets[0],
                data: [110, 90, 80, 60, 50, 43, 42, 40, 34, 32]
              },
            ],
        },
    },
    {
        ...BRANCHES_ON_TIME,
        data: {
            ...BRANCHES_ON_TIME.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_ON_TIME.data.datasets[0],
                data: [110, 90, 80, 60, 50, 43, 42, 40, 34, 32]
              },
            ],
        },
    },
    {
        ...BRANCHES_VISITED_STOPS_PER_HOUR,
        data: {
            ...BRANCHES_VISITED_STOPS_PER_HOUR.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_VISITED_STOPS_PER_HOUR.data.datasets[0],
                data: [55, 48, 47, 45, 44, 43, 42, 40, 34, 32]
              },
            ],
        },
    },
    {
        ...BRANCHES_PACKAGES,
        data: {
            ...BRANCHES_PACKAGES.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_PACKAGES.data.datasets[0],
                data: [520, 480, 470, 450, 440, 430, 420, 400, 340, 320]
              },
            ],
        },
    },
    {
        ...BRANCHES_AVERAGE_DELAY,
        data: {
            ...BRANCHES_AVERAGE_DELAY.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_AVERAGE_DELAY.data.datasets[0],
                data: [25, 20, 20, 15, 13, 12, 10, 9, 5, 1]
              },
            ],
        },
    },
    {
        ...BRANCHES_COST_PER_SHIPMENT,
        data: {
            ...BRANCHES_COST_PER_SHIPMENT.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_COST_PER_SHIPMENT.data.datasets[0],
                data: [5.3, 4.6, 4.5, 4.2, 4.1, 4.1, 4, 4, 3.4, 3.2]
              },
            ],
        },
    },
    {
        ...BRANCHES_RECIPIENT_EXPERIENCE,
        data: {
            ...BRANCHES_RECIPIENT_EXPERIENCE.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...BRANCHES_RECIPIENT_EXPERIENCE.data.datasets[0],
                data: [5, 4.8, 4.77, 4.5, 4.4, 4.3, 4.2, 4, 3.4, 3.2]
              },
            ],
        },
    },
]

export const DRIVERS: ILmChartWidgetTab[] = [
    {
        ...DRIVER_PRODUCTIVITY,
        data: {
            ...DRIVER_PRODUCTIVITY.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...DRIVER_PRODUCTIVITY.data.datasets[0],
                data: [5, 4.8, 4.77, 4.5, 4.4, 4.3, 4.2, 4, 3.4, 3.2]
              },
            ],
        },
    },
    {
        ...DRIVER_VISITED_STOPS_PER_HOUR,
        data: {
            ...DRIVER_VISITED_STOPS_PER_HOUR.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...DRIVER_VISITED_STOPS_PER_HOUR.data.datasets[0],
                data: [55, 48, 47, 45, 44, 43, 42, 40, 34, 32]
              },
            ],
        },
    },
    {
        ...DRIVER_PACKAGES,
        data: {
            ...DRIVER_PACKAGES.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...DRIVER_PACKAGES.data.datasets[0],
                data: [520, 480, 470, 450, 440, 430, 420, 400, 340, 320]
              },
            ],
        },
    },
    {
        ...DRIVER_AVERAGE_DELAY,
        data: {
            ...DRIVER_AVERAGE_DELAY.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...DRIVER_AVERAGE_DELAY.data.datasets[0],
                data: [25, 20, 20, 15, 13, 12, 10, 9, 5, 1]
              },
            ],
        },
    },
    {
        ...DRIVER_COST_PER_SHIPMENT,
        data: {
            ...DRIVER_COST_PER_SHIPMENT.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...DRIVER_COST_PER_SHIPMENT.data.datasets[0],
                data: [5.3, 4.6, 4.5, 4.2, 4.1, 4.1, 4, 4, 3.4, 3.2]
              },
            ],
        },
    },
    {
        ...DRIVER_RECIPIENT_EXPERIENCE,
        data: {
            ...DRIVER_RECIPIENT_EXPERIENCE.data,
            labels: ['Nikos', 'Alexis', 'Dimitris', 'Thomas', 'Kleanthis', 'Darth Vader', 'Super Batman', 'Ovelix', 'Stamatis Kokotas', 'Mpampis Flou'],
            datasets: [
              {
                ...DRIVER_RECIPIENT_EXPERIENCE.data.datasets[0],
                data: [5, 4.8, 4.77, 4.5, 4.4, 4.3, 4.2, 4, 3.4, 3.2]
              },
            ],
        },
    },
]

export const LM_WIDGET_PIE_CHART_1  = {
    labels: ['PLAISIO', 'IKEA', 'Public', 'Other'],
    datasets: [
        { 
            backgroundColor: ['#ff6384', '#ff9f40', '#ffcd56', '#4bc0c0'],
            data: [40, 30, 20, 10],
            borderWidth: 1,
        }
    ]
};

export const LM_WIDGET_PIE_TABS: ILmChartWidgetTab[] = [
    {
        id: 'deliveries',
        title: 'Deliveries',
        subtitle: 'Shipments share',
        btn:{id: 'shipmentsShare', icon: 'lm-icon icon-cargo-truck', textOnly: true, color: "#ccc", activeColor: '#00aeba', active: true},
        data: LM_WIDGET_PIE_CHART_1,
        chartType: 'pie'
    },
]

export const VERTICAL: ChartData  = {
    labels: ['Reason 1', 'Reason 2', 'Reason 3', 'Reason 4', 'Reason 5'],
    datasets: [
        {
            backgroundColor: ['#00aeba', '#741b47', '#134f5c', '#b45f06', '#0b5393', '#274e13', '#7f6000'],
            data: [15, 30, 70, 50, 60, 80, 40],
            datalabels: {
                align: 'start',
                anchor: 'end',
                display: true,
                color: '#fff',
                // font:{
                //     size: 13
                // },
                formatter: (value, context) => {
                    return value+'%'
                }
              }
        },
        {
            backgroundColor: ['#eee'],
            data: [100, 100, 100, 100, 100, 100, 100]
        }
    ]
};

export const VERTICAL_OPTIONS: ChartOptions<'bar'> = {
    indexAxis: 'y',
    datasets: { 
        bar:{ barThickness: 17}
    },
    scales: { 
        x: {
            grid:{ 
                borderWidth: 0,
                display: false
            },
            ticks:{ display: false }
        },
        y: {
            stacked: true,
            ticks:{ display: false } ,
            grid: {
                borderWidth: 0,
                display: false
            }, 
        }
    },
    plugins:{
        annotation:{
            annotations:{
                'Reason 1': lmVerticalLabelAnnotation('Reason 1'),
                'Reason 2': lmVerticalLabelAnnotation('Reason 2'),
                'Reason 3': lmVerticalLabelAnnotation('Reason 3'),
                'Reason 4': lmVerticalLabelAnnotation('Reason 4'),
                'Reason 5': lmVerticalLabelAnnotation('Reason 5'),
            }
        }
    }
};

// export const VERTICAL  = {
//     labels: ['Reason 1', 'Reason 2', 'Reason 3', 'Reason 4', 'Reason 5'],
//     datasets: [
//         {
//             backgroundColor: ['#00aeba', '#741b47', '#134f5c', '#b45f06', '#0b5393'],
//             data: [15, 30, 70, 50, 60],
//             // datalabels: {
//             //     display: true,
//             //     align: 'top',
//             //     anchor: 'start',
//             //     clip: false,
//             //     formatter: (value, context) => {
//             //         const label = context.chart.data.labels[context.dataIndex];
//             //         return `${label}`;
//             //     },
//             // }
//         },
//         {
//             backgroundColor: ['#eee'],
//             data: [80, 80, 80, 80, 80],
//             // datalabels: {
//             //     display: true,
//             //     align: 'top',
//             //     anchor: 'end',
//             //     clip: false,
//             //     formatter: (value) => {
//             //         return `${value}%`;
//             //     },
//             //     // offset: 20,
//             // }
//         }
//     ]
// };

// export const VERTICAL_OPTIONS: ChartOptions<'bar'> = {
//     indexAxis: 'y',
//     plugins: {
//         legend: {
//             display: false
//         },
//     },
//     scales: { 
//         x: {
//             display: false,
//         },
//         y: {
//             display: false,
//         }
//     },
//     datasets: {
//         bar: {
//             barThickness: 5
//         }
//     }
// };

export const DOUGHNUT  = {
    labels: ['Reason 1', 'Reason 2', 'Reason 3'],
    datasets: [
        {
            backgroundColor: ['#4c63b6', '#00ae99', '#ba6ff4'],
            data: [15, 30, 70]
        }
    ]
};

export const DELIVERIES = [
    {
        id: 'shipmentsShare',
        title: 'Deliveries',
        subtitle: 'Shipments share',
        btn:{id: 'shipmentsShare', icon: 'lm-icon icon-cargo-truck', textOnly: true, color: "#ccc", activeColor: '#00aeba', active: true},
        data: DOUGHNUT,
        chartType: 'pie',
        viewType: 'chart'
    },
    {
        id: 'experienceImpact',
        title: 'Deliveries',
        subtitle: 'Recepient experience impact on new purchases',
        value: 40,
        btn:{id: 'experienceImpact', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ad1919'},
        chartOptions:{
            iconClass: 'icon-heart',
            valuecolor: '#ad1919'
        },
        viewType: 'semicircle'
    }
]

export const GENERAL_PERFORMANCE = [
    {
        id: 'productivity',
        title: 'General',
        subtitle: 'Productivity',
        btn:{id: 'productivity', icon: 'lm-icon icon-cargo-truck', textOnly: true, color: "#ccc", activeColor: '#00aeba', active: true},
      viewType: 'html'
    },
    {
        id: 'experience',
        title: 'General',
        subtitle: 'Experience',
        value: 40,
        btn:{id: 'experience', icon: 'lm-icon icon-heart', textOnly: true, color: "#ccc", activeColor: '#ad1919'},
        viewType: 'html'
    }
]


export const SHIPMENTS_OVERVIEW = {
    title: "Shipments",
    amount: 532.123,
    progress: 551,
    percentage: 34,
    iconClass: 'fas fa-map-marker-alt',
    mainColor: "#06aeba",
    secondaryColor: "#c8edf0"
  };

  export const PRODUCTIVITY_OVERVIEW  = {
    title: "Productivity",
    amount: 4.4,
    amountDefinition: "/5",
    progress: -254, 
    percentage: 80,
    iconClass: 'far fa-star',
    mainColor: "#f9c406",
    secondaryColor: "#fef1c3"
  };

  export const EXPERIENCE_OVERVIEW = {
    title: "Experience",
    amount: 4.82,
    amountDefinition: "/5",
    progress: 2.7,
    percentage: 90,
    iconClass: 'icon-heart',
    iconSize: 1,
    mainColor: "#c74c96",
    secondaryColor: "#ead1dc"
  };

  export const DELIVERIES_OVERVIEW = {
    title: "Delivered on Time",
    amount: 96.2,
    amountDefinition: "%",
    progress: 0,
    percentage: 96.2,
    iconClass: 'icon-clock',
    mainColor: "#ba6ef5",
    secondaryColor: "#eedcfc"
  };