import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { MilyService } from '@app/services/mily.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { StepsService } from '@app/services/steps.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ImporterService } from '@app/services/importer.service';

declare var H: any;

@Component({
    selector: 'app-mily',
    templateUrl: './mily.component.html',
    styleUrls: ['./mily.component.scss']
})
export class MilyComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('milyButton', { static: false, read: ElementRef }) milyButton: ElementRef;
    @ViewChild('milyContainer', { static: false, read: ElementRef }) milyContainer: ElementRef;
    @ViewChild('milyMessagesContainer', { static: false, read: ElementRef }) milyMessagesContainer: ElementRef;

    listen = [];
    inDemo = false;

    messages = [];
    messagesCount = 0;
    lastOptimizationMessageIndex = null;
    lastUploadingMessageIndex = null;
    lastWizardMessageIndex = null;

    optimizationState = null;

    messageTitle = 'Mily';
    recurringText = '';
    cancelledText = '';
    newShipmentsText = '';
    newStopPointsFromDriversText = '';
    newShipmentsPickupText = '';
    portalText = '';
    uploadStopsText = 'Upload?';
    fileLineErrorsText = '';
    successfulFileStopPointsUploaded = '';
    driversText = '';
    vehiclesText = '';
    reloadText = '';
    tourText = '';
    wizardText = '';
    stopsDroppedText = '';
    stopsTimeWindowText = '';
    seeMoreOnMapText = '';
    loadText = '';
    seeStopsText = '';
    uploadText = 'Upload';
    seeMoreText = '';
    dispatchText = '';
    completeText = '';
    importerHelperPrintText = '';
    importerHelperText = '';
    canceledHelperText = '';
    helperImportantText = '';
    letsGoText = '';
    okText = '';
    ok2Text = '';
    yesText = '';
    printText = '';
    setUpCompanyText = '';
    deleteAlert = '';
    deleteSameDayAlert = '';
    cancelAlert = '';
    cancelMultipleStopPointsAlert = '';
    mergeAlert = '';
    optimizeAlert = '';
    portalSettingsAlert = '';
    deleteImporterGroupAlert = '';
    portalDriverAlert = '';
    reportButtonText = '';
    reportText = '';
    removedFromCollaboratorText = '';
    alreadyOptimizingErrorLabel = '';

    optimizationText = '';
    optimizingOptionsTexts = {};

    uploadingText = '';
    addressSearchText = '';

    calculatingPolylinesText = '';
    computingDistanceMatricesText = '';
    initializationText = '';
    optimizationErrorText = '';
    errorText = '';
    optimizedText = '';
    optimizingText = '';
    preparingResultsForSave = '';
    preparingVrp = '';
    preparingVrp2 = '';
    savingResults = '';

    openMilyHref = '';
    aTag = '';

    deleteObservable: Observable<any>;
    cancelMultipleStopPointsObservable: Observable<any>;
    cancelObservable: Observable<any>;
    deleteSameDayObservable: Observable<any>;
    mergeObservable: Observable<any>;
    optimizeObservable: Observable<any>;
    portalDriverObservable: Observable<any>;
    portalSettingsObservable: Observable<any>;
    deleteImporterTemplateGroupObservable: Observable<any>;
    private deleteSubject = new Subject();
    private deleteSameDaySubject = new Subject();
    private cancelSubject = new Subject();
    private cancelMultipleStopPointsSubject = new Subject();
    private mergeSubject = new Subject();
    private optimizeSubject = new Subject();
    private portalDriverSubject = new Subject();
    private portalSettingsSubject = new Subject();
    private deleteImporterTemplateGroupSubject = new Subject();

    constructor(
        private milyService: MilyService,
        public translate: TranslateService,
        private viewProjectProblemService: ViewProjectProblemService,
        public globals: Globals,
        private stepsService: StepsService,
        public router: Router,
        private importerService: ImporterService,
    ) {
        this.optimizingOptionsTexts = {
            none: '',
            optimize: '',
            optimizeWithModifications: '',
            optimizeWithModificationsKeepRoutes: '',
            updatingRoutes: '',
        };
        this.listen.push(this.milyService.openMilyListen().subscribe((data) => {
            this.openMily();
        }));
        this.listen.push(this.milyService.closeMilyListen().subscribe((data) => {
            this.closeMily();
        }));
        this.listen.push(this.milyService.loadCancelledPointsListen().subscribe((data) => {
            this.generateCancelledPointsMessage();
        }));
        this.listen.push(this.milyService.loadNewStopPointsFromDriversListen().subscribe((data) => {
            this.generateNewStopPointsFromDriversMessage();
        }));
        this.listen.push(this.milyService.loadNewShipmentsListen().subscribe((data) => {
            this.generateNewShipmentsMessage();

            // play audio
            this.openMily();
            setTimeout(() => {
                const notificationAudio = new Audio('/assets/lastmilyAssets/audio/new_shipments.mp3');
                notificationAudio.play();
            }, 100);
        }));
        this.listen.push(this.milyService.newShipmentsPickupListen().subscribe((data) => {
            this.generateNewShipmentsPickupMessage();
        }));
        this.listen.push(this.milyService.loadPortalPointsListen().subscribe((data) => {
            this.generatePortalPointsMessage(data.stopsCount, data.newStopsCount);
        }));
        this.listen.push(this.milyService.uploadPointsListen().subscribe((data) => {
            this.generateUploadPointsMessage();
        }));
        this.listen.push(this.milyService.loadRecurringPointsListen().subscribe((data) => {
            this.generateRecurringPointsMessage();
        }));
        this.listen.push(this.milyService.reloadsDetectedListen().subscribe((routes) => {
            this.generateReloadsMessage(routes);
        }));
        this.listen.push(this.milyService.driversWorkingHoursListen().subscribe((routes) => {
            this.generateDriverHoursMessage(routes);
        }));
        this.listen.push(this.milyService.vehicleCapacityListen().subscribe((routes) => {
            this.generateVehicleLoadMessage(routes);
        }));
        this.listen.push(this.milyService.stopPointsConditionListen().subscribe((data) => {
            this.generateStopsConditionMessage(data.limitedTimeWindowStopsCount, data.droppedStopsCount);
        }));
        this.listen.push(this.milyService.optimizationListen().subscribe((optimizingOption) => {
            this.generateOptimizingMessage(optimizingOption);
        }));
        this.listen.push(this.milyService.dispatchListen().subscribe((data) => {
            this.generateDispatchMessage();
        }));
        this.listen.push(this.milyService.completeListen().subscribe((data) => {
            this.generateCompleteMessage();
        }));
        this.listen.push(this.milyService.uploadingPointsListen().subscribe((data) => {
            this.generateUploadingMessage();
            this.milyService.openMily();
        }));
        this.listen.push(this.milyService.tourListen().subscribe((data) => {
            this.generateTourMessage();
        }));
        this.listen.push(this.milyService.wizardListen().subscribe((data) => {
            this.generateWizardMessage();
        }));
        this.listen.push(this.milyService.projectProblemReportListen().subscribe((data) => {
            this.generateProjectProblemReportMessage();
        }));
        this.listen.push(this.milyService.uploadPointsDoneListen().subscribe((data) => {
            this.uploadDone();
        }));
        this.listen.push(this.milyService.importFileLineErrorsListen().subscribe((uploadReport) => {
            this.generateFileUploadReport(uploadReport);
        }));
        this.listen.push(this.milyService.cancelCollaboratorStopPointsListen().subscribe((stopPoints) => {
            this.generateCollaboratorRemovedStopPointsMessage(stopPoints);
        }));
        this.listen.push(this.milyService.alertListen().subscribe((text) => {
            if (typeof text === 'string') {
                this.generateAlertMessage(text);
                this.milyService.openMily();
            } else {
                console.error('The following error is not a string:');
                console.error(text);
            }
        }));
        this.listen.push(this.milyService.helperListen().subscribe((type) => {
            if (type === 'importer') {
                this.generateHelperImporterMessage();
            } else {
                this.generateHelperCanceledMessage();
            }
        }));
        this.listen.push(this.milyService.helperPrintListen().subscribe((type) => {
            this.generateHelperImporterPrintMessage();
        }));
        this.listen.push(this.milyService.alreadyOptimizingListen().subscribe(() => {
            this.openMily();
            this.generateAlertMessage(this.alreadyOptimizingErrorLabel);
        }));

        // generate a confirm message from anywhere (type should match a type from the generateConfirmMessage observables being watched in ngOnInit)
        this.listen.push(this.milyService.setConfirmMessageTypeListen().subscribe(type => {
            switch (type) {
                case 'deleteImporterGroup':
                    this.milyService.confirmMessageObservable = this.deleteImporterTemplateGroupObservable;
                    break;
            }
        }));
    }

    findWizardMessageIndex() {
        this.messages.forEach((message, index) => {
            if (message.mode === 'wizard') {
                this.lastWizardMessageIndex = index;
            }
        });
    }

    findUploadingMessageIndex() {
        this.messages.forEach((message, index) => {
            if (message.mode === 'uploading') {
                this.lastUploadingMessageIndex = index;
            }
        });
    }

    findOptimizationMessageIndex() {
        this.messages.forEach((message, index) => {
            if (message.mode === 'optimization') {
                this.lastOptimizationMessageIndex = index;
            }
        });
    }

    displayOptimizationStatus(statusConstant) {
        this.findOptimizationMessageIndex();
        if (this.messages[this.lastOptimizationMessageIndex]) {
            this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel2 = '';
            switch (statusConstant) {
                case this.globals.projectProblemOptimizationStateConstants['OPTIMIZATION_ERROR']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.optimizationErrorText;
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = false;
                    this.messages[this.lastOptimizationMessageIndex].isOptimizationError = true;
                    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
                        this.generateWizardMessage();
                    }
                    break;
                case this.globals.projectProblemOptimizationStateConstants['ERROR']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.errorText;
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = false;
                    this.messages[this.lastOptimizationMessageIndex].isError = true;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['INITIALIZATION']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = false;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.initializationText;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['COMPUTING_DISTANCE_MATRICES']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = true;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.computingDistanceMatricesText;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['PREPARING_VRP']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = true;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.preparingVrp;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel2 = this.preparingVrp2;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['OPTIMIZING']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = true;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.optimizingText;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['SAVING_RESULTS']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = true;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.savingResults;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['CALCULATING_POLYLINES']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = true;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.calculatingPolylinesText;
                    break;
                case this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = false;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.optimizedText;
                    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
                        this.generateWizardMessage();
                    }
                    break;
                case this.globals.projectProblemOptimizationStateConstants['PREPARING_RESULTS_FOR_SAVE']:
                    this.messages[this.lastOptimizationMessageIndex].optimization.loading = true;
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = this.preparingResultsForSave;
                    break;
                default:
                    this.messages[this.lastOptimizationMessageIndex].optimization.stateLabel = '';
            }
        } else {
            console.warn(this.lastOptimizationMessageIndex);
        }
        setTimeout(() => {
            if (this.milyMessagesContainer) {
                this.milyMessagesContainer.nativeElement.scrollTo(0, this.milyMessagesContainer.nativeElement.scrollHeight);
            }
        }, 200);
    }

    uploadDone() {
        this.findUploadingMessageIndex();
        this.messages[this.lastUploadingMessageIndex].optimization.loading = false;
    }

    generateUploadingMessage() {
        this.lastUploadingMessageIndex = this.messages.length;
        this.addMessage({
            mode: 'uploading',
            title: this.messageTitle,
            text: this.addressSearchText,
            time: moment().format('HH:mm'),
            important: false,
            optimization: {
                loading: true,
                stateLabel: this.addressSearchText,
                stateLabel2: '',
            }
        });
    }

    generateOptimizingMessage(optimizingOption) {
        this.lastOptimizationMessageIndex = this.messages.length;
        this.addMessage({
            mode: 'optimization',
            title: this.messageTitle,
            text: this.optimizationText + ' ' + this.optimizingOptionsTexts[optimizingOption],
            time: moment().format('HH:mm'),
            important: false,
            optimization: {
                loading: true,
                stateLabel: '',
                stateLabel2: '',
            }
        }, false, false);
    }

    generateDispatchMessage() {
        this.addMessage({
            mode: 'dispatch',
            title: this.messageTitle,
            text: this.dispatchText,
            time: moment().format('HH:mm'),
            important: false,
        });
    }

    generateCompleteMessage() {
        this.addMessage({
            mode: 'complete',
            title: this.messageTitle,
            text: this.completeText,
            time: moment().format('HH:mm'),
            important: false,
        });
    }

    generateRecurringPointsMessage() {
        this.addMessage({
            mode: 'recurring',
            title: this.messageTitle,
            text: this.recurringText,
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'recurring',
                text: this.loadText,
            }
        }, true);
    }

    generateCancelledPointsMessage() {
        this.addMessage({
            mode: 'cancelled',
            title: this.messageTitle,
            text: this.cancelledText,
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'cancelled',
                text: this.loadText,
            }
        }, true);
    }

    generateNewStopPointsFromDriversMessage() {
        this.addMessage({
            mode: 'newStopPointsFromDrivers',
            title: this.messageTitle,
            text: this.newStopPointsFromDriversText,
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'newStopPointsFromDrivers',
                text: this.loadText,
            }
        });
    }

    generateNewShipmentsMessage() {
        this.addMessage({
            mode: 'newShipments',
            title: this.messageTitle,
            text: this.newShipmentsText,
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'newShipments',
                text: this.loadText,
            }
        });
    }

    generateNewShipmentsPickupMessage() {
        this.addMessage({
            mode: 'newShipments',
            title: this.messageTitle,
            text: this.newShipmentsPickupText,
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'redirectToLiveMap',
                text: this.letsGoText,
            }
        });
    }

    generatePortalPointsMessage(stopsCount, newStopsCount) {
        this.addMessage({
            mode: 'portal',
            title: this.messageTitle,
            text: this.portalText.replace('STOPS_COUNT', stopsCount).replace('NEW_STOPS_COUNT', newStopsCount),
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'portal',
                text: this.seeStopsText,
            }
        });
    }

    generateUploadPointsMessage() {
        this.addMessage({
            mode: 'upload',
            title: this.messageTitle,
            text: this.uploadStopsText,
            time: moment().format('HH:mm'),
            important: false,
            button: {
                type: 'upload',
                text: this.uploadText,
                reverse: true
            }
        });
    }

    generateFileUploadReport(uploadReport) {
        let text = '';
        if (uploadReport.hasErrors) {
            let successMessage = this.successfulFileStopPointsUploaded.replace('STOPS_COUNT', uploadReport.newStopPointsCount);
            successMessage = successMessage.replace('LINES_COUNT', uploadReport.totalLines);
            text = successMessage + '<br>' + '<br>' + '<i>' + this.fileLineErrorsText + uploadReport.linesMessage + '</i>';
        } else {
            let successMessage = this.successfulFileStopPointsUploaded.replace('STOPS_COUNT', uploadReport.newStopPointsCount);
            successMessage = successMessage.replace('LINES_COUNT', uploadReport.totalLines);
            text = successMessage;
        }
        this.addMessage({
            mode: 'upload',
            title: this.messageTitle,
            text: text,
            time: moment().format('HH:mm'),
            important: false
        });
    }

    generateDriverHoursMessage(routes) {
        this.addMessage({
            mode: 'driverHours',
            title: this.messageTitle,
            important: true,
            text: this.driversText,
            time: moment().format('HH:mm'),
            button: {
                type: 'drivers',
                text: this.seeMoreText
            },
            routes: routes
        });
    }

    generateVehicleLoadMessage(routes) {
        this.addMessage({
            mode: 'vehicleLoad',
            title: this.messageTitle,
            important: true,
            text: this.vehiclesText,
            time: moment().format('HH:mm'),
            button: {
                type: 'drivers',
                text: this.seeMoreText
            },
            routes: routes
        });
    }

    generateReloadsMessage(routes) {
        this.addMessage({
            mode: 'reloads',
            title: this.messageTitle,
            important: true,
            text: this.reloadText,
            time: moment().format('HH:mm'),
            routes: routes
        });
    }

    generateHelperImporterPrintMessage() {
        const text = '<div>' + this.importerHelperPrintText + '</div>' + ' <div class="bold-letters">' + this.helperImportantText + '</div>';
        this.addMessage({
            mode: 'helper',
            title: this.messageTitle,
            important: true,
            text: text,
            time: moment().format('HH:mm'),
            button: {
                type: 'helperImporter',
                text: this.ok2Text,
            },
            button2: {
                type: 'print',
                text: this.printText,
            }
        });
        this.milyService.openMily();
    }

    generateHelperImporterMessage() {
        const text = '<div>' + this.importerHelperText + '</div>' + ' <div class="bold-letters">' + this.helperImportantText + '</div>';
        this.addMessage({
            mode: 'helper',
            title: this.messageTitle,
            important: true,
            text: text,
            time: moment().format('HH:mm'),
            button: {
                type: 'helperImporter',
                text: this.ok2Text,
            }
        });
        this.milyService.openMily();
    }

    generateHelperCanceledMessage() {
        const text = '<div>' + this.canceledHelperText + '</div>' + ' <div class="bold-letters">' + this.helperImportantText + '</div>';
        this.addMessage({
            mode: 'helper',
            title: this.messageTitle,
            important: true,
            text: text,
            time: moment().format('HH:mm'),
            button: {
                type: 'helperCanceled',
                text: this.ok2Text,
            }
        });
        this.milyService.openMily();
    }

    generateTourMessage() {
        this.addMessage({
            mode: 'tour',
            title: this.messageTitle,
            important: false,
            text: this.tourText,
            time: moment().format('HH:mm'),
            button: {
                type: 'tour',
                text: this.yesText,
                reverse: true,
            }
        });
        this.milyService.openMily();
    }

    generateWizardMessage() {
        this.findWizardMessageIndex();
        if (this.lastWizardMessageIndex) {
            this.removeMessage(this.lastWizardMessageIndex);
        }
        this.lastWizardMessageIndex = this.messages.length;
        this.addMessage({
            mode: 'wizard',
            title: this.messageTitle,
            important: false,
            text: this.wizardText,
            time: moment().format('HH:mm'),
            button: {
                type: 'wizard',
                text: this.setUpCompanyText,
                reverse: true,
            }
        }, false, false);
    }

    generateProjectProblemReportMessage() {
        this.addMessage({
            mode: 'report',
            title: this.messageTitle,
            important: false,
            text: this.reportText,
            time: moment().format('HH:mm'),
            button: {
                type: 'report',
                text: this.okText,
            }
        }, false, false);
    }

    generateAlertMessage(text) {
        this.addMessage({
            mode: 'alert',
            title: this.messageTitle,
            important: true,
            text: text,
            time: moment().format('HH:mm'),
        });
    }

    generateConfirmMessage(text, type) {
        this.milyService.openMily();
        document.getElementById('background-disable').classList.remove('hidden');
        document.getElementById('mily-container').classList.add('confirm');
        this.addMessage({
            mode: 'confirm',
            title: this.messageTitle,
            important: true,
            text: text,
            time: moment().format('HH:mm'),
            confirmData: {
                type: type,
            }
        }, false, false);
    }

    generateStopsConditionMessage(limitedTimeWindowStopsCount, droppedStopsCount) {
        let stopsText = '';
        if (limitedTimeWindowStopsCount > 0) {
            stopsText += this.stopsTimeWindowText.replace('STOPS_COUNT', limitedTimeWindowStopsCount) + ' ';
        }
        if (droppedStopsCount > 0) {
            stopsText += this.stopsDroppedText.replace('STOPS_COUNT', droppedStopsCount) + ' ';
        }
        stopsText += this.seeMoreOnMapText;
        this.addMessage({
            mode: 'stopsCondition',
            title: this.messageTitle,
            important: true,
            text: stopsText,
            time: moment().format('HH:mm'),
        });
    }

    generateCollaboratorRemovedStopPointsMessage(stopPoints) {
        this.milyService.openMily();
        let stopsText = '';
        if (stopPoints.length) {
            stopsText += '<div>' + this.removedFromCollaboratorText + '</div>';
            
            stopPoints.forEach(stopPoint => {
                stopsText += '<div class="list-item">' + stopPoint['stopPoint']['contact_name'] + '</div>';
            });
        }

        this.addMessage({
            mode: 'report',
            title: this.messageTitle,
            important: true,
            text: stopsText,
            time: moment().format('HH:mm'),
        });
    }

    addMessage(message: Message, toTop = false, addWizardMessage = true) {
        if (toTop) {
            this.messages.unshift(message);
        } else {
            this.messages.push(message);
        }
        this.messagesCount++;
        setTimeout(() => {
            if (this.milyMessagesContainer) {
                this.milyMessagesContainer.nativeElement.scrollTo(0, this.milyMessagesContainer.nativeElement.scrollHeight);
            }
        }, 200);
        if (
            addWizardMessage && this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS'] &&
            this.optimizationState === this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']
        ) {
            this.generateWizardMessage();
        }
    }

    removeMessage(index) {
        this.messages.splice(index, 1);
        this.messagesCount--;
        setTimeout(() => {
            if (this.milyMessagesContainer) {
                this.milyMessagesContainer.nativeElement.scrollTo(0, this.milyMessagesContainer.nativeElement.scrollHeight);
            }
        }, 200);
    }

    buttonClicked(buttonName) {
        switch (buttonName) {
            case 'cancelled':
                this.viewProjectProblemService.openAddStopsModal('cancelled');
                break;
            case 'recurring':
                this.viewProjectProblemService.openAddStopsModal('recurring');
                break;
            case 'newShipments':
                this.viewProjectProblemService.openAddStopsModal('newShipments');
                break;
            case 'redirectToLiveMap':
                this.router.navigateByUrl('/collaboratorOverview');
                break;
            case 'newStopPointsFromDrivers':
                this.viewProjectProblemService.openAddStopsModal('newStopPointsFromDrivers');
                break;
            case 'drivers':
                this.stepsService.transmitProjectProblemDataToModals('drivers');
                break;
            case 'upload':
                document.getElementById('general-csv-uploader').click();
                break;
            case 'print':
                this.importerService.print();
                this.milyService.closeMily();
                break;
            case 'helperImporter':
                this.importerService.loadAndOpenHelper('importer');
                this.milyService.closeMily();
                break;
            case 'helperCanceled':
                this.importerService.loadAndOpenHelper('canceled');
                this.milyService.closeMily();
                break;
            case 'tour':
                this.startTour();
                localStorage.setItem('tourDone', 'true');
                break;
            case 'wizard':
                this.router.navigate(['wizard']);
                break;
            case 'report':
                this.viewProjectProblemService.openProjectProblemReports();
                break;
            case 'portal':
                this.viewProjectProblemService.openPortalIssuesModal();
                break;
        }
    }

    confirmButtonPressed(index, answer) {
        let closeAfterButtonPress = true;
        switch (this.messages[index]['confirmData']['type']) {
            case 'delete':
                this.deleteSubject.next(answer);
                break;
            case 'deleteSameDay':
                this.deleteSameDaySubject.next(answer);
                break;
            case 'merge':
                this.mergeSubject.next(answer);
                break;
            case 'optimize':
                closeAfterButtonPress = false;
                this.optimizeSubject.next(answer);
                break;
            case 'settingsPortal':
                this.portalSettingsSubject.next(answer);
                break;
            case 'driverPortal':
                this.portalDriverSubject.next(answer);
                break;
            case 'cancel':
                this.cancelSubject.next(answer);
                break;
            case 'cancelMultipleStopPoints':
                this.cancelMultipleStopPointsSubject.next(answer);
                break;
            case 'deleteImporterGroup':
                this.deleteImporterTemplateGroupSubject.next(answer);
                break;
        }
        this.removeMessage(index);
        document.getElementById('background-disable').classList.add('hidden');
        document.getElementById('mily-container').classList.remove('confirm');
        if (closeAfterButtonPress) {
            this.milyService.closeMily();
        }
    }

    openUploader() {
        document.getElementById('general-csv-uploader').click();
    }

    startTour() {
        if (this.globals.foodModeEnabled) {
            this.globals.comingSoonAlert();
        } else {
            this.viewProjectProblemService.startGeneralTour();
            this.milyService.closeMily();
        }
    }

    toggleMily() {
        if (this.milyContainer.nativeElement.classList.contains('open')) {
            this.milyService.closeMily();
        } else {
            this.milyService.openMily();
        }
    }

    openMily() {
        // do not call this function directly
        // instead use milyService.openMily() to expand the select multiple points button as well
        if (!this.milyContainer.nativeElement.classList.contains('open')) {
            this.milyButton.nativeElement.classList.add('open');
            this.milyContainer.nativeElement.classList.add('open');
        }
    }

    closeMily() {
        if (this.milyContainer.nativeElement.classList.contains('open')) {
            this.milyButton.nativeElement.classList.remove('open');
            this.milyContainer.nativeElement.classList.remove('open');
            this.messagesCount = 0;
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.OK').subscribe((res: string) => { this.okText = res; }));
        this.listen.push(this.translate.get('GENERIC.OK2').subscribe((res: string) => { this.ok2Text = res; }));
        this.listen.push(this.translate.get('MILY.LIVE_MAP').subscribe((res: string) => { this.letsGoText = res; }));
        this.listen.push(this.translate.get('MILY.YES').subscribe((res: string) => { this.yesText = res; }));
        this.listen.push(this.translate.get('MILY.PRINT').subscribe((res: string) => { this.printText = res; }));
        this.listen.push(this.translate.get('MILY.SET_UP_COMPANY').subscribe((res: string) => { this.setUpCompanyText = res; }));
        this.listen.push(this.translate.get('MILY.DRIVER_HOURS').subscribe((res: string) => { this.driversText = res; }));
        this.listen.push(this.translate.get('MILY.VEHICLE_CAPACITY').subscribe((res: string) => { this.vehiclesText = res; }));
        this.listen.push(this.translate.get('MILY.RELOAD').subscribe((res: string) => { this.reloadText = res; }));
        this.listen.push(this.translate.get('MILY.TOUR').subscribe((res: string) => { this.tourText = res; }));
        this.listen.push(this.translate.get('MILY.WIZARD').subscribe((res: string) => { this.wizardText = res; }));
        this.listen.push(this.translate.get('MILY.TIME_WINDOW').subscribe((res: string) => { this.stopsTimeWindowText = res; }));
        this.listen.push(this.translate.get('MILY.POINTS_DROPPED').subscribe((res: string) => { this.stopsDroppedText = res; }));
        this.listen.push(this.translate.get('MILY.SEE_MORE_ON_MAP').subscribe((res: string) => { this.seeMoreOnMapText = res; }));
        this.listen.push(this.translate.get('MILY.RECURRING').subscribe((res: string) => { this.recurringText = res; }));
        this.listen.push(this.translate.get('MILY.CANCELLED').subscribe((res: string) => { this.cancelledText = res; }));
        this.listen.push(this.translate.get('MILY.NEW_SHIPMENTS').subscribe((res: string) => { this.newShipmentsText = res; }));
        this.listen.push(this.translate.get('MILY.NEW_STOP_POINTS_FROM_DRIVERS').subscribe((res: string) => { this.newStopPointsFromDriversText = res; }));
        this.listen.push(this.translate.get('MILY.NEW_SHIPMENTS_PICKUP').subscribe((res: string) => { this.newShipmentsPickupText = res; }));
        this.listen.push(this.translate.get('MILY.PORTAL').subscribe((res: string) => { this.portalText = res; }));
        this.listen.push(this.translate.get('MILY.UPLOAD_STOPS').subscribe((res: string) => { this.uploadStopsText = res; }));
        this.listen.push(this.translate.get('MILY.FILE_LINE_ERRORS').subscribe((res: string) => { this.fileLineErrorsText = res; }));
        this.listen.push(this.translate.get('MILY.SUCCESSFUL_STOP_POINTS_UPLOADED').subscribe((res: string) => { this.successfulFileStopPointsUploaded = res; }));
        this.listen.push(this.translate.get('MILY.LOAD_THEM').subscribe((res: string) => { this.loadText = res; }));
        this.listen.push(this.translate.get('MILY.SEE_STOPS').subscribe((res: string) => { this.seeStopsText = res; }));
        this.listen.push(this.translate.get('MILY.UPLOAD').subscribe((res: string) => { this.uploadText = res; }));
        this.listen.push(this.translate.get('MILY.SEE_MORE').subscribe((res: string) => { this.seeMoreText = res; }));
        this.listen.push(this.translate.get('MILY.LET_ME_THINK').subscribe((res: string) => { this.optimizationText = res; }));
        this.listen.push(this.translate.get('MILY.OPTIMIZE_FROM_SCRATCH').subscribe((res: string) => { this.optimizingOptionsTexts['optimize'] = res; }));
        this.listen.push(this.translate.get('MILY.OPTIMIZE_WITH_MODIFICATIONS').subscribe((res: string) => { this.optimizingOptionsTexts['optimizeWithModifications'] = res; }));
        this.listen.push(this.translate.get('MILY.OPTIMIZE_WITH_MODIFICATIONS_KEEP_ROUTES').subscribe((res: string) => { this.optimizingOptionsTexts['optimizeWithModificationsKeepRoutes'] = res; }));
        this.listen.push(this.translate.get('MILY.UPDATING_ROUTES').subscribe((res: string) => { this.optimizingOptionsTexts['updatingRoutes'] = res; }));
        this.listen.push(this.translate.get('MILY.DISPATCH').subscribe((res: string) => { this.dispatchText = res; }));
        this.listen.push(this.translate.get('MILY.COMPLETE').subscribe((res: string) => { this.completeText = res; }));
        this.listen.push(this.translate.get('MILY.HELPER_PRINT').subscribe((res: string) => { this.importerHelperPrintText = res; }));
        this.listen.push(this.translate.get('MILY.HELPER').subscribe((res: string) => { this.importerHelperText = res; }));
        this.listen.push(this.translate.get('MILY.HELPER_CANCELED').subscribe((res: string) => { this.canceledHelperText = res; }));
        this.listen.push(this.translate.get('MILY.HELPER_IMPORTANT').subscribe((res: string) => { this.helperImportantText = res; }));
        this.listen.push(this.translate.get('MILY.UPLOADING').subscribe((res: string) => { this.uploadingText = res; }));
        this.listen.push(this.translate.get('MILY.ADDRESS_SEARCH').subscribe((res: string) => { this.addressSearchText = res; }));
        this.listen.push(this.translate.get('MILY.REPORT').subscribe((res: string) => { this.reportText = res; }));
        this.listen.push(this.translate.get('MILY.REPORT_BUTTON').subscribe((res: string) => { this.reportButtonText = res; }));
        this.listen.push(this.translate.get('MILY.REMOVED_FROM_COLLABORATOR').subscribe((res: string) => { this.removedFromCollaboratorText = res; }));
        this.listen.push(this.translate.get('MILY.ALREADY_OPTIMIZING_ERROR').subscribe((res: string) => { this.alreadyOptimizingErrorLabel = res; }));

        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.CALCULATING_POLYLINES').subscribe((res: string) => { this.calculatingPolylinesText = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.COMPUTING_DISTANCE_MATRICES').subscribe((res: string) => { this.computingDistanceMatricesText = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.INITIALIZATION').subscribe((res: string) => { this.initializationText = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.OPTIMIZATION_ERROR').subscribe((res: string) => { this.optimizationErrorText = res; }));
        this.listen.push(this.translate.get('GENERIC.ERROR').subscribe((res: string) => { this.errorText = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.OPTIMIZED').subscribe((res: string) => { this.optimizedText = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.OPTIMIZING').subscribe((res: string) => { this.optimizingText = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.PREPARING_RESULTS_FOR_SAVE').subscribe((res: string) => { this.preparingResultsForSave = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.PREPARING_VRP').subscribe((res: string) => { this.preparingVrp = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.PREPARING_VRP2').subscribe((res: string) => { this.preparingVrp2 = res; }));
        this.listen.push(this.translate.get('OPTIMIZATION_STEPS.SAVING_RESULTS').subscribe((res: string) => { this.savingResults = res; }));

        this.listen.push(this.translate.get('ALERTS.DELETE_ALERT').subscribe((res: string) => { this.deleteAlert = res; }));
        this.listen.push(this.translate.get('ALERTS.DELETE_SAME_DAY_ALERT').subscribe((res: string) => { this.deleteSameDayAlert = res; }));
        this.listen.push(this.translate.get('ALERTS.CANCEL_ALERT').subscribe((res: string) => { this.cancelAlert = res; }));
        this.listen.push(this.translate.get('ALERTS.CANCEL_MULTIPLE_ALERT').subscribe((res: string) => { this.cancelMultipleStopPointsAlert = res; }));
        this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => { this.mergeAlert = res; }));
        this.listen.push(this.translate.get('STOP_POINT.OPTIMIZE_ALERT').subscribe((res: string) => { this.optimizeAlert = res; }));
        this.listen.push(this.translate.get('ALERTS.PORTAL_DRIVER_SETTINGS_DISABLED').subscribe((res: string) => { this.portalDriverAlert = res; }));
        this.listen.push(this.translate.get('ALERTS.PORTAL_DRIVER_SETTINGS_DISABLED').subscribe((res: string) => { this.portalSettingsAlert = res; }));
        this.listen.push(this.translate.get('ALERTS.DELETE_IMPORTER_GROUP').subscribe((res: string) => { this.deleteImporterGroupAlert = res; }));
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        });
        this.getTranslations();

        this.deleteObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.deleteAlert, 'delete');
            this.listen.push(this.deleteSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.deleteSameDayObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.deleteSameDayAlert, 'deleteSameDay');
            this.listen.push(this.deleteSameDaySubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.cancelMultipleStopPointsObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.cancelMultipleStopPointsAlert, 'cancelMultipleStopPoints');
            this.listen.push(this.cancelMultipleStopPointsSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.cancelObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.cancelAlert, 'cancel');
            this.listen.push(this.cancelSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.mergeObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.mergeAlert, 'merge');
            this.listen.push(this.mergeSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.optimizeObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.optimizeAlert, 'optimize');
            this.listen.push(this.optimizeSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.portalDriverObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.portalDriverAlert, 'driverPortal');
            this.listen.push(this.portalDriverSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.portalSettingsObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.portalSettingsAlert, 'settingsPortal');
            this.listen.push(this.portalSettingsSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });

        this.deleteImporterTemplateGroupObservable = new Observable<any>(ob => {
            this.generateConfirmMessage(this.deleteImporterGroupAlert, 'deleteImporterGroup');
            this.listen.push(this.deleteImporterTemplateGroupSubject.subscribe(res => {
                if (res === 'ok') {
                    ob.next('ok');
                } else {
                    ob.next('cancel');
                }
            }));
        });
    }

    populateChat() {
        const name = this.globals.userName;
        const email = this.globals.userEmail;
        const chatIframe = document.getElementById('reve-chat-widget-holder') as HTMLIFrameElement;
        // chatIframe.contentWindow.document.getElementById('txt5').style.zIndex = '2147483645';
        // document.getElementById('reve-chat-container-div').style.zIndex = '2147483645';
        // chatIframe.contentWindow.document.getElementById('txt5').style.visibility = 'visible';
        // chatIframe.classList.add('enabled');
        setTimeout(() => {
            const chatNameOfflineInput = chatIframe.contentWindow.document.getElementById('chat-name-offline');
            if (chatNameOfflineInput) {
                (<HTMLInputElement>chatNameOfflineInput).value = name;
            }
            const chatEmailOfflineInput = chatIframe.contentWindow.document.getElementById('chat-email-offline');
            if (chatEmailOfflineInput) {
                (<HTMLInputElement>chatEmailOfflineInput).value = email;
            }
            const chatNameOnlineInput = chatIframe.contentWindow.document.getElementById('chat-start-name');
            if (chatNameOnlineInput) {
                (<HTMLInputElement>chatNameOnlineInput).value = name;
            }
            const chatEmailOnlineInput = chatIframe.contentWindow.document.getElementById('chat-start-email');
            if (chatEmailOnlineInput) {
                (<HTMLInputElement>chatEmailOnlineInput).value = email;
            }
            // chatIframe.contentWindow.document.getElementById('txt5').style.zIndex = '2147483645';
            // document.getElementById('reve-chat-container-div').style.zIndex = '2147483645';
            // chatIframe.contentWindow.document.getElementById('txt5').style.visibility = 'visible';
            // chatIframe.contentWindow.document.getElementById('txt5').style.width = '100%';
            // chatIframe.contentWindow.document.getElementById('txt5').style.height = '100%';
            const a = chatIframe.contentWindow.document.querySelector('#reve-chat-widget-body .footer-text') as HTMLIFrameElement;
            a.style.display = 'none';
        }, 1000);

    }

    public ngAfterViewInit() {
        this.milyMessagesContainer.nativeElement.scrollTo(0, this.milyMessagesContainer.nativeElement.scrollHeight);
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}

interface Message {
    mode: String;
    title: String;
    time: String;
    text: String;
    button?: {
        type: String,
        text: String,
        reverse?: Boolean
    };
    button2?: {
        type: String,
        text: String,
        reverse?: Boolean
    };
    routes?: Array<object>;
    important: Boolean;
    isError?: Boolean;
    isOptimizationError?: Boolean;
    optimization?: {
        loading: Boolean,
        stateLabel: String,
        stateLabel2: String,
    };
    confirmData?: {
        type: String,
    };
}
