import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { ISettings_Basic_Service_Dto } from '@app/api/models/settings-services-dto';
import { SettingsFuelSurchargesViewModelService } from '../settings-fuel-surcharges-viewmodel.service';
import { ISettings_Surcharge_Dto } from '@app/api/models/settings-surcharges-dto';
import { SettingsOtherSurchargesViewModelService } from '../settings-other-surcharges-viewmodel.service';


@UntilDestroy()
@Component({
  templateUrl: 'new-other-surcharge-modal.component.html'
})
export class OtherSurchargeModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: SettingsOtherSurchargesViewModelService;
  saveBttn: LmButtonConfig;
  item: ISettings_Surcharge_Dto;

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService
  )
   {
    const {data:{item}} = config;
    this.VM = config.data.VM;
    this.item = item ?? this.VM.newItem();
    this.updateButtons();

    this.saveBttn = {
      label:'Αποθήκευση', 
      command: ()=> this.ok()
    };

  }

  private isFormValid(): boolean {
    return Boolean(this.item.value);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  addNewService = () => {

  };

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = () => {
    if(!this.item.value) this.item.value = 0;
    if (this.isFormValid()) return this.VM.postItem({ surcharges: [this.item] })
      .pipe(take(1), untilDestroyed(this),)
      .subscribe(_ => this.config.close())
  }
  cancel = () => this.config.close();
}

