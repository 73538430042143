import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DynamicFieldModalComponent } from '@app/modals/dynamic-field-modal/dynamic-field-modal.component';
import { GridsService } from '@app/services/grids.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-fields-grid',
  templateUrl: './dynamic-fields-grid.component.html',
  styleUrls: ['./dynamic-fields-grid.component.scss']
})
export class DynamicFieldsGridComponent implements OnInit, OnDestroy {

  @ViewChild(DynamicFieldModalComponent, { static: false }) dynamicFieldModalComponent: DynamicFieldModalComponent;

  listen = [];
  searchString: String = '';
  gridApi;
  nameHeader;
  defaultValueHeader;
  phoneHeader;
  gridColumnApi;
  domLayout = 'autoHeight';
  dynamicFieldsDataArray = [];
  rowData: any;
  columnDefs;


  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private settingsService: SettingsService,
    public gridsService: GridsService,
  ) {
    this.listen.push(this.settingsService.updateDynamicFieldsGridListen().subscribe(() => {
      this.setDynamicFieldsGridData();
    }));
  }

  setDynamicFieldsGridData() {
    this.http.get('api/v1/import-dynamic-fields').pipe(take(1)).subscribe(response => {
      const self = this;
      let dynamicField, gridObject = {};
      self.dynamicFieldsDataArray = [];
      if (response['items'].length) {
        response['items'].forEach(data => {
          dynamicField = data.importDynamicField;
          gridObject = {
            id: dynamicField.id,
            name: dynamicField.label,
            defaultValue: dynamicField.default_value,
          };
          self.dynamicFieldsDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' }
        self.dynamicFieldsDataArray.push(noData);
      }
      self.rowData = of(self.dynamicFieldsDataArray);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    if (!event.data.noDataText && this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN']) {
      this.dynamicFieldModalComponent.loadDynamicField(event.data.id);
    }
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameHeader = res; }));
    this.listen.push(this.translate.get('SETTINGS.DEFAULT_VALUE').subscribe((res: string) => { this.defaultValueHeader = res; }));

    this.columnDefs = [
      { headerName: this.nameHeader, field: 'name', width: this.gridsService.widthCalculatorSettings(50) },
      { headerName: this.defaultValueHeader, field: 'defaultValue', width: this.gridsService.widthCalculatorSettings(50) },
    ];
    this.setDynamicFieldsGridData();
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}