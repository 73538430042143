// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .lm-tr-table .lm-tr-table-name-cell {
  width: 25%;
}
:host ::ng-deep .lm-tr-table .lm-tr-table-areas-cell {
  width: 40%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRyYW5zcG9ydGF0aW9uLXJlZ2lvbnMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRVE7RUFDSSxVQUFBO0FBRFo7QUFHUTtFQUNJLFVBQUE7QUFEWiIsImZpbGUiOiJ0cmFuc3BvcnRhdGlvbi1yZWdpb25zLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3QgOjpuZy1kZWVwe1xuICAgIC5sbS10ci10YWJsZXtcbiAgICAgICAgLmxtLXRyLXRhYmxlLW5hbWUtY2VsbHtcbiAgICAgICAgICAgIHdpZHRoOiAyNSU7XG4gICAgICAgIH1cbiAgICAgICAgLmxtLXRyLXRhYmxlLWFyZWFzLWNlbGx7XG4gICAgICAgICAgICB3aWR0aDogNDAlO1xuICAgICAgICB9XG4gICAgICAgIC5sbS10ci10YWJsZS10eXBlLWNlbGx7XG4gICAgICAgIH1cbiAgICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/settings/transportation-regions/transportation-regions.component.scss"],"names":[],"mappings":"AAEQ;EACI,UAAA;AADZ;AAGQ;EACI,UAAA;AADZ;AACA,4mBAA4mB","sourcesContent":[":host ::ng-deep{\n    .lm-tr-table{\n        .lm-tr-table-name-cell{\n            width: 25%;\n        }\n        .lm-tr-table-areas-cell{\n            width: 40%;\n        }\n        .lm-tr-table-type-cell{\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
