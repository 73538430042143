import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ImporterService } from '@app/services/importer.service';
import { MapService } from '@app/services/map.service';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ColourService } from '@app/services/colour.service';
import { BehaviorSubject, Subject } from 'rxjs';

declare var H: any;

@Component({
    selector: 'depots-map',
    templateUrl: './settings-depots-map.component.html',
    styleUrls: ['./settings-depots-map.component.scss']
})
export class DepotsMapComponent implements OnInit, AfterViewInit, OnDestroy {

    private platform: any;
    @ViewChild('map', { static: false }) public mapElement: ElementRef;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
    draggableMarkerGroup = new H.map.Group();
    pinDropped = new Subject();
    polygonComplete = new BehaviorSubject(false); 
    mapDragged = new BehaviorSubject(false);
    map;
    ui;
    behavior;

    listen = [];

    depots = [];
    depotsGroup = new H.map.Group();
    polygonGroup = new H.map.Group();
    polygonMarkersGroup = new H.map.Group();
    polygonLineString = new H.geo.LineString();
    polygonVerticesCoords = [];
    polygonLineStringPolyline;
    lat = '';
    lon = '';
    positionSet = false;
    drawModeEnabled = true;
    

    
    markerAndPolygonGroup = new H.map.Group();

    constructor(
        public translate: TranslateService,
        private mapService: MapService,
        public globals: Globals,
        private colourService: ColourService,
    ) {
        this.platform = this.mapService.platform;
    }

    emptyMap() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonMarkersGroup.removeAll();
        this.polygonLineString = new H.geo.LineString();
        this.polygonGroup.removeAll();
    }

    emptyDrawMode() {
        if (this.polygonLineStringPolyline) {
            this.map.removeObject(this.polygonLineStringPolyline);
        }
        this.polygonLineStringPolyline = null;
        this.polygonVerticesCoords = [];
        this.polygonLineString = new H.geo.LineString();
        this.polygonMarkersGroup.removeAll();
        const polygons = this.polygonGroup.getObjects();
        polygons.forEach(mainGroup => {
            if (!mainGroup.getVisibility()) {
                mainGroup.setVisibility(true);
            }
        });
    }

    getPolygonCount() {
        return this.polygonGroup.getObjects().length;
    }

    centerToPolygon(id) {
        let bounds = this.polygonGroup.getBoundingBox();
        if (id) {
            const polygons = this.polygonGroup.getObjects();
            const mainGroups = [];
            polygons.forEach(mainGroup => {
                if (mainGroup.getData().id === id) {
                    bounds = mainGroup.getBoundingBox();
                }
            });
        }
        if (bounds) {
            if (this.map) {
                this.map.getViewModel().setLookAtData({ bounds: bounds }, true);
            } else {
                console.error('The map is not yet initialized.');
            }
        } else {
            console.error('This polygon group has no bounds.');
        }
    }


    createResizablePolygon(lineString, colour, id, isEditable = true) {
        const rgb = this.colourService.hexToRGB(colour.replace('#', ''));
        const rgbaColour = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.3)';
        const circleIcon = this.svgIconsComponent.svgCircleColour.replace('markerColour', colour);
        const polygonData = this.mapService.createPolygonMainGroup(lineString, circleIcon, id, rgbaColour, colour);
        const verticeGroup = polygonData.verticeGroup;
        const mainGroup = polygonData.mainGroup;
        const polygon = polygonData.polygon;
        this.polygonGroup.addObject(mainGroup);
        this.polygonComplete.next(true);
        // this.polygonWKT = mainGroup.getObjects()[0].getGeometry().toString();
        // add group with polygon and it's vertices (markers) on the map
        // this.map.addObject(this.polygonGroup);
        verticeGroup.setData({ id: id });

        if (isEditable && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
            // event listener for main group to show markers if moved in with mouse (or touched on touch devices)
            mainGroup.addEventListener('pointerenter', function (evt) {
                // show vertice markers
                verticeGroup.setVisibility(true);
            }, true);

            // event listener for main group to hide vertice markers if moved out with mouse (or released finger on touch devices)
            // the vertice markers are hidden on touch devices after specific timeout
            this.polygonGroup.addEventListener('pointerleave', function (evt) {
                const timeout = (evt.currentPointer.type === 'touch') ? 1000 : 0;
                // hide vertice markers
                verticeGroup.setVisibility(false);
            }, true);

            // event listener for vertice markers group to change the cursor to pointer
            verticeGroup.addEventListener('pointerenter', function (evt) {
                document.body.style.cursor = 'pointer';
                if(this.draggableMarker) this.draggableMarker.draggable = false;
            }, true);

            // event listener for vertice markers group to change the cursor to default
            verticeGroup.addEventListener('pointerleave', function (evt) {
                document.body.style.cursor = 'default';
                if(this.draggableMarker) this.draggableMarker.draggable = true;
            }, true);
        }

        // event listener for vertice markers group to resize the geo polygon object if dragging over markers
        verticeGroup.addEventListener('drag', (evt) => {
            
            const pointer = evt.currentPointer,
                geoLineString = polygon.getGeometry().getExterior(),
                geoPoint = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

            // set new position for vertice marker
            evt.target.setGeometry(geoPoint);

            // set new position for polygon's vertice
            geoLineString.removePoint(evt.target.getData()['verticeIndex']);
            geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
            polygon.setGeometry(new H.geo.Polygon(geoLineString));

            // stop propagating the drag event, so the map doesn't move
            evt.stopPropagation();
        }, true);

        // verticeGroup.addEventListener('dragend', (evt) => {
        //     this.polygonChanged.emit('');
        // }, true);
    }

    removePolygon(id){
        const polygons = this.polygonGroup.getObjects();
        const mainGroups = [];
        polygons.forEach(mainGroup => {
            if (mainGroup.getData().id === id) {
                mainGroups.push(mainGroup);
            }
        });
        if (mainGroups.length) {
            mainGroups.forEach(mainGroup => {
                this.polygonGroup.removeObject(mainGroup);
            });
        }
    }

    convertToMultiPolygon(polygons):string{
        let multiPolygon = 'MULTIPOLYGON (';
        polygons.forEach(polygon => {
            const string = polygon.replace('POLYGON ', '');
            multiPolygon+=string;
        });
        multiPolygon += ')'
        return multiPolygon;
    }

    getPolygon():string{
        let polygonWKT = '';
        const polygons = this.polygonGroup.getObjects();
        polygons.forEach(mainGroup => {
                mainGroup.getObjects().forEach(polygon => {
                    if (polygon instanceof H.map.Polygon) {
                        polygonWKT = polygon.getGeometry().toString();
                    }
                });
        });
        return this.convertToMultiPolygon([polygonWKT]);
    }

    getBoundingBox():string{
        return this.polygonGroup.getBoundingBox().toString();
    }

    createPolygonMarker(lat, lon) {
        const icon = new H.map.Icon(this.svgIconsComponent.svgCircle);
        const coords = { lat: lat, lng: lon };
        const marker = new H.map.Marker(coords, { icon: icon });
        this.polygonMarkersGroup.addObject(marker);
    }

    createLineString(lat, lon) {
        if (!this.polygonLineStringPolyline) {
            const firstPointCoords = this.polygonVerticesCoords[0];
            this.polygonLineString.pushLatLngAlt(firstPointCoords.lat, firstPointCoords.lng, 0);
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline = new H.map.Polyline(this.polygonLineString, {
                style: {
                    lineWidth: 5,
                    strokeColor: '#00aeba',
                }
            });
            this.map.addObject(this.polygonLineStringPolyline);
        } else {
            this.polygonLineString.pushLatLngAlt(lat, lon, 0);
            this.polygonLineStringPolyline.setGeometry(this.polygonLineString);
        }
    }

    clickOnMap(event) {
        if(event.originalEvent.button === 2) return event.preventDefault()

        const target = event.target;
        const polygonId = 'custom';
        if (this.drawModeEnabled && !(target instanceof H.map.DomMarker) && (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')) {
            const polygons = this.polygonGroup.getObjects();
            if (target instanceof H.map.Marker) {
                if (target.getParentGroup() === this.polygonMarkersGroup) {
                    const colour = this.colourService.colourCalculator(1);
                    this.createResizablePolygon(this.polygonLineString, colour, polygonId);
                    polygons.forEach(mainGroup => {
                        if (mainGroup.getData().id === polygonId) this.polygonGroup.removeObject(mainGroup);
                    });
                    this.emptyDrawMode();
                }
            } 
            else {
                const coords = this.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
                this.polygonVerticesCoords.push(coords);
                if (this.polygonVerticesCoords.length === 1) {
                    polygons.forEach(mainGroup => {
                        if (mainGroup.getData().id === polygonId) mainGroup.setVisibility(false);
                    });
                    this.createPolygonMarker(coords.lat, coords.lng);
                } 
                else if (this.polygonVerticesCoords.length === 2) this.createLineString(coords.lat, coords.lng);
                else if (this.polygonVerticesCoords.length > 2) this.createLineString(coords.lat, coords.lng);
            }
        }
    }

    showDraggableMarker(lat, lng) {
        const self = this;
        this.draggableMarker.setGeometry({ lat: lat, lng: lng });
        this.draggableMarker.draggable = true;
        this.draggableMarkerGroup.addObjects([this.draggableMarker]);
    
      }
    
      removeDraggableMarker() {
        if (this.draggableMarkerGroup.contains(this.draggableMarker)) {
          this.draggableMarkerGroup.removeObject(this.draggableMarker);
        }
      }


      initMap(depotLat, depotLon) {
        const self = this;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map, 
            {zoom: 10, center: { lat: depotLat, lng: depotLon }}
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.map.addEventListener('tap', this.clickOnMap.bind(this));

        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');


        this.map.addEventListener('dragstart', function (ev) {
            const target = ev.target, pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
              const targetPosition = self.map.geoToScreen(target.getGeometry());
              target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
              self.behavior.disable();
            }
          }, false);
      
  
          this.map.addEventListener('dragend', function (ev) {
            const target = ev.target;
            const pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
              self.behavior.enable();
              self.positionSet = true;
              self.lat = target.getGeometry()['lat'];
              self.lon = target.getGeometry()['lng'];
              const coords = self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y)
            }
          }, false);
      
          this.map.addEventListener('drag', function (ev) {
            const target = ev.target, pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
              const coords = self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y)
              target.setGeometry(coords);
              self.pinDropped.next(coords)
            }
            self.mapDragged.next(true)
            
          }, false);
        
        this.markerAndPolygonGroup.addObject(this.polygonMarkersGroup);
        this.markerAndPolygonGroup.addObject(this.polygonGroup);
        this.markerAndPolygonGroup.addObject(this.draggableMarkerGroup);
        this.map.addObject(this.markerAndPolygonGroup);

    }

    centerToMapElements(){
        const box = this.markerAndPolygonGroup.getBoundingBox();
        if(box) this.map.getViewModel().setLookAtData({ bounds: box }, true);
    }



    ngOnInit() {
    }

    public ngAfterViewInit() {
        const dataRefreshIntervalId = setInterval(()=> {
            if (this.globals.depotsDataDone) {
                clearInterval(dataRefreshIntervalId);
                const depotLat = this.globals.currentLat;
                const depotLon = this.globals.currentLon;
                this.initMap(depotLat, depotLon);
            }
        }, 200);
    }

    ngOnDestroy() {
        // this.map.removeEventListener('tap', this.clickOnMap);
        this.listen.forEach(element => {
            element.unsubscribe();
        });

        this.pinDropped.complete();
        this.polygonComplete.complete();
        this.mapDragged.complete();
    }
}
