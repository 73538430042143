import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { ISettings_TR_AreaDto, ISettings_TR_AreaGroupDto, ISettings_TR_AreaGroupInfoDto, ISettings_TR_ProviderDto } from '@app/api/models/settings-transportation-regions-dto';
import { TransportationViewModelService } from '../transportation-regions-viewmodel.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap, take, tap } from 'rxjs/operators';
import { MapService } from '@app/services/map.service';
import { LmSelectTypeAhead2Component } from '@app/shared/controls/input/select2/select-typeahead.component';
import { IPriceListAreaGroupAdministrativeLevel } from '@app/model/price-list';
import { Observable, of } from 'rxjs';
import { TransportationRegionsMapComponent } from '../transportation-regions-map/transportation-regions-map.component';
import { ColourService } from '@app/services/colour.service';

@UntilDestroy()
@Component({
  templateUrl: 'new-transportation-region-modal.component.html'
})
export class TransportationRegionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TransportationRegionsMapComponent, { static: false }) transportationRegionsMapComponent: TransportationRegionsMapComponent;
  // @ViewChild('map') public map: ElementRef;
  VM: TransportationViewModelService;
  label: string;
  saveBttn: LmButtonConfig;
  item: ISettings_TR_AreaGroupInfoDto;
  
  selectedAreas;
  selectedPostalCodes;
  areaControlLabel:string;
  
  administrativeLevels: IPriceListAreaGroupAdministrativeLevel;
  areaProvider: number;
  areaSelectSetup : any;

  areasLookUp: (e) => Observable<any>;
  postalCodesLookUp: (e) => Observable<any>;
  onAreaSelect: (e) => Observable<ISettings_TR_AreaDto>;
  onAreaUnselect: (e, field) => void;
  onRegionTypeSelect = (e) => {
    if (this.item.administrative_level == this.administrativeLevels.Polygon.key) {
      if (this.item.areas.length && this.item.areas[0]['name'] != 'custom') {
        this.item.areas = [];
      }
    }
    this.configureAdministrativeLevel(e);
    this.updateButtons();
  }

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService,
    private mapService: MapService,
    private colourService: ColourService,
  )
  {
    const {data} = config;
    const {globals:{priceListAreaGroupsAdministrativeLevelEnum, companyId}, item} = data;
    
    this.VM = config.data.VM;
    this.administrativeLevels = priceListAreaGroupsAdministrativeLevelEnum; 
    this.item = item ?? this.VM.newItem();
    this.item.company_id = companyId;
    this.item.areas.forEach(area=> {
      if (area['geometry']) {
        area.multipolygon = area['geometry']['wkt'];
        area.bounding_box = area['geometry']['bounding_box'];
      }
    });
    setTimeout(() => {
      this.loadAreasOnMap();
    }, 500);
    if(!this.item.administrative_level) this.item.administrative_level = this.administrativeLevels.Polygon.key;
    this.updateButtons();
    this.saveBttn = {label: this.VM.modalConfig.data.translations['SAVE'], command: () => this.ok()};
    
    this.onAreaUnselect = ({name}) => {
      this.transportationRegionsMapComponent.removePolygon(name);
      if(!!item.areas.length) item.areas = item.areas.filter((area) => area.name !== name);
      this.updateButtons();
    }
    
    this.onAreaSelect = (e) => {
      return this.VM.openStreetMapsPost(e.id).pipe(
        tap(area => {
          const normalizedArea = this.normalizeArea(<any>area)
          this.item.areas.push(normalizedArea);
          this.loadSingleAreaOnMap(normalizedArea);
          this.transportationRegionsMapComponent.centerToPolygon(null);
          this.updateButtons();
        }), take(1)
      )
    }

    this.configureAdministrativeLevel(this.item.administrative_level);
  }

  private isFormValid(): boolean {
    let hasSetRegion = false, hasCustomPolygon = false, hasSelectedArea = false, polygonsCount;
    polygonsCount = this.transportationRegionsMapComponent.getPolygonCount();
    hasCustomPolygon = Boolean(this.item.administrative_level == this.administrativeLevels.Polygon.key && polygonsCount > 0);
    hasSelectedArea = Boolean(this.item.administrative_level != this.administrativeLevels.Polygon.key && this.item.areas.length);
    if (hasCustomPolygon || hasSelectedArea) {
      hasSetRegion = true;
    }
    return Boolean(this.item.name && hasSetRegion);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  polygonChanged(event) {
    this.updateButtons();
  }

  private loadSingleAreaOnMap(area) {
    const index = this.item.areas.indexOf(area);
    const colour = this.colourService.colourCalculator(index);
    let polygon = area.multipolygon;
    if(!polygon) polygon = area.geometry.wkt
    const lineStrings = this.mapService.calculateMultiPolygons(polygon);
    lineStrings.forEach(lineString => {
      this.transportationRegionsMapComponent.createResizablePolygon(lineString, colour, area.name, false);
    });
  }

  private loadAreasOnMap(){
    this.transportationRegionsMapComponent.emptyMap();
    this.item.areas.forEach(area=> {
      this.loadSingleAreaOnMap(area);
    });
    this.transportationRegionsMapComponent.centerToPolygon(null);
  }

  private normalizeArea = 
    ({result:{localname, name, geometry:{bounding_box, wkt}}}) => 
    ({ name: localname ?? name, identifier: 'Th', bounding_box: bounding_box, multipolygon: wkt, provider: this.areaProvider })

  private configureAdministrativeLevel(e){
    if(e){
      this.setupMapDrawMode();
      const setup = {
        [this.administrativeLevels.PostalCode.key]: {label: this.VM.modalConfig.data.translations['POSTAL_CODES'], provider: 2}, //2 is not valid. not handled by BE yet},
        [this.administrativeLevels.Region.key]: {label: this.VM.modalConfig.data.translations['REGIONS'], provider: 2},
        [this.administrativeLevels.City.key]: {label: this.VM.modalConfig.data.translations['CITIES'], provider: 2},
        [this.administrativeLevels.Country.key]: {label: this.VM.modalConfig.data.translations['COUNTRIES'], provider: 2},
        [this.administrativeLevels.Polygon.key]: {label: this.VM.modalConfig.data.translations['POLYGON'], provider:1}
      }
  
      this.areaProvider = setup[e].provider ?? 2;
      return this.areaControlLabel =  setup[e].label ?? ''
    }
  }

  private setupMapDrawMode(){
    setTimeout(() => {
      this.transportationRegionsMapComponent.emptyDrawMode();
      this.transportationRegionsMapComponent.drawModeEnabled = false;
      if (this.item.administrative_level == this.administrativeLevels.Polygon.key) {
        this.transportationRegionsMapComponent.emptyMap();
        this.transportationRegionsMapComponent.drawModeEnabled = true;
      }
    }, 100);
  }

  private getCustomArea(){
    if (this.item.administrative_level == this.administrativeLevels.Polygon.key){
      const area: ISettings_TR_AreaDto = {
        bounding_box: this.transportationRegionsMapComponent.getBoundingBox(),
        multipolygon: this.transportationRegionsMapComponent.getPolygon(),
        name: 'custom',
        provider: 1
      }
    this.item.areas = [area];
    }
  }


  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = () => {
    this.getCustomArea();
    if (this.isFormValid()) return this.VM.postItem({area_group: this.item})
      .pipe(take(1), untilDestroyed(this),)
      // this was this.config.close(true) I don't know what the true is
      .subscribe(_ => this.config.close(true));
  }
  cancel = () => this.config.close();
}
