import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import countriesList from '@app/../assets/lastmilyAssets/countries.json';
import { CookieService } from 'ngx-cookie-service';
import { TermsModalComponent } from '@app/modals/terms-modal/terms-modal.component';
import { PrivacyModalComponent } from '@app/modals/privacy-modal/privacy-modal.component';
import { take } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild(PrivacyModalComponent, { static: false }) privacyModalComponent: PrivacyModalComponent;
  @ViewChild(TermsModalComponent, { static: false }) termsModalComponent: TermsModalComponent;

  listen = [];

  myForm: FormGroup;
  username: String = '';
  password: String = '';
  country: String = '';
  isClickedOnce = false;
  applicationType = '';
  rememberMe = false;
  activeViewName = '';

  email = '';
  name = '';
  companyName = '';
  countries = [];
  countryPrefix = '+30';
  phoneNumber = '';
  telephone = '';
  partnerToken = null;

  exitRegisterConfirmation = '';
  countDownSeconds;
  resendMailIntervalSeconds = 30;
  resendEnabled = true;
  counterIntervalId;
  timeZone = '';

  hasApiError = false;

  apiResponseNameError = '';
  apiResponseCompanyNameError = '';
  apiResponseEmailError = '';
  apiResponsePasswordError = '';
  apiResponsePhoneError = '';

  isInMobile = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    formBuilder: FormBuilder,
    public globals: Globals,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.countries = countriesList;
    this.myForm = formBuilder.group({
      'userAccount': formBuilder.group({
        'username': [this.username, [
          Validators.required,
          Validators.email,
          this.emailResponseValidator.bind(this)
        ]],
        'password': [this.password],
        'passwordPlaintextNew': this.password,
        'passwordPlaintextNewConfirm': this.password,
        'country': [this.country],
      }),
      'company': formBuilder.group({
        'name': [this.companyName, [
          Validators.required,
          this.companyNameResponseValidator.bind(this)
        ]],
        'email': this.username,
        'telephone': [this.telephone],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber, [
          Validators.required,
          this.phoneResponseValidator.bind(this)
        ]],
        'partner_token': this.partnerToken
      }),
      'companySettings': {
        'default_project_problem_departure_datetime_timezone': [this.timeZone],
      },
      'userProfile': formBuilder.group({
        'name': this.name,
        'email': this.username
      }),
      'applicationType': ['web-app'],
      'provider': [0],
    });
  }

  resetEmailValidator() {
    this.apiResponseEmailError = '';
    this.myForm.get('userAccount').get('username').updateValueAndValidity();
  }

  resetCompanyNameValidator() {
    this.apiResponseCompanyNameError = '';
    this.myForm.get('company').get('name').updateValueAndValidity();
  }

  resetPhoneValidator() {
    this.apiResponsePhoneError = '';
    this.myForm.get('company').get('phoneNumber').updateValueAndValidity();
  }

  emailResponseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.apiResponseEmailError === '') {
      return null;
    }
    return { 'responseValidator': true }
  }

  companyNameResponseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.apiResponseCompanyNameError === '') {
      return null;
    }
    return { 'responseValidator': true }
  }

  phoneResponseValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.apiResponsePhoneError === '') {
      return null;
    }
    return { 'responseValidator': true }
  }

  patchForSubmit() {
    this.myForm.patchValue({
      'userAccount': {
        'passwordPlaintextNew': this.password,
        'passwordPlaintextNewConfirm': this.password,
      },
      'company': {
        // 'name': this.username,
        'email': this.username,
        'telephone': this.telephone,
      },
      'userProfile': {
        // 'name': this.username,
        'email': this.username
      },
    });
  }

  underDevelopmentAlert() {
    alert('Its under development, it will soon be available, thank you!');
  }

  startWizard() {
    this.router.navigate(['wizard']);
  }

  startDemo() {
    this.router.navigate(['demo']);
  }

  closeButtonPressed() {
    if (this.activeViewName === 'registration-choices') {
      if (confirm(this.exitRegisterConfirmation)) {
        document.location.href = 'http://lastmily.com';
      } else { }
    } else {
      document.location.href = 'http://lastmily.com';
    }
  }

  loadChoices() {
    this.globals.load().then(() => {
      this.showRegistrationView('registration-choices');
    });
  }

  updateValuesAndValidities() {
    this.apiResponseEmailError = '';
    this.apiResponseCompanyNameError = '';
    this.apiResponsePhoneError = '';
    this.myForm.get('userAccount').get('username').updateValueAndValidity();
    this.myForm.get('company').get('name').updateValueAndValidity();
    this.myForm.get('company').get('phoneNumber').updateValueAndValidity();
  }

  public registerToApi() {
    this.isClickedOnce = true;
    this.updateValuesAndValidities();
    if (this.myForm.value.company.phoneNumber) {
      this.telephone = this.myForm.value.company.countryPrefix + this.myForm.value.company.phoneNumber;
    }
    this.username = this.myForm.value.userAccount.username;
    this.password = this.myForm.value.userAccount.password;
    this.patchForSubmit();
    const myObserver = {
      next: (response) => {
        this.email = this.myForm.value.userAccount.username;
        this.showRegistrationView('mail-confirmation');
        document.getElementById('resend-email').classList.add('disabled');
        this.enableResendMail();
        this.updateValuesAndValidities();
      },
      error: (error) => {
        const registerError = error.error.errors;
        console.error(error);
        this.isClickedOnce = false;
        if (registerError.userProfile) {
          if (registerError.userProfile.email) {
            this.apiResponseEmailError = registerError.userProfile.email[0];
            this.myForm.get('userAccount').get('username').updateValueAndValidity();
          }
        }
        if (registerError.company) {
          if (registerError.company.base) {
            if (registerError.company.base.name) {
              this.apiResponseCompanyNameError = registerError.company.base.name[0];
              this.myForm.get('company').get('name').updateValueAndValidity();
            }
            if (registerError.company.base.telephone) {
              this.apiResponsePhoneError = registerError.company.base.telephone[0];
              this.myForm.get('company').get('phoneNumber').updateValueAndValidity();
            }
          }
        }
      },
      complete: () => {
        this.isClickedOnce = false;
      },
    };
    this.listen.push(this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.http.post('api/v1/account/register', this.myForm.value).pipe(take(1)).subscribe(myObserver);
    }));
  }

  enableResendMail() {
    const self = this;
    self.resendEnabled = false;
    let seconds = this.resendMailIntervalSeconds;
    self.counterIntervalId = setInterval(function () {
      self.countDownSeconds = seconds;
      if (--seconds < 0) {
        clearInterval(self.counterIntervalId);
        if (document.getElementById('resend-email')) {
          document.getElementById('resend-email').classList.remove('disabled');
        }
        self.resendEnabled = true;
      }
    }, 1000);
  }

  resendConfirmationEmail() {
    const resendButton = document.getElementById('resend-email');
    const myObserver = {
      next: (response) => { },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    if (this.resendEnabled) {
      this.http.post('api/v1/account/register/resend-confirm-mail', { mail: this.email }).pipe(take(1)).subscribe(myObserver);
      resendButton.classList.add('disabled');
      this.enableResendMail();
    }
  }

  showRegistrationView(id) {
    const activeView = document.querySelector('.register-views.active');
    if (activeView) {
      activeView.classList.remove('active');
    }
    const viewElement = document.getElementById(id);
    if (viewElement) {
      this.activeViewName = id;
      viewElement.classList.add('active');
    }
  }

  openPrivacyModal() {
    this.privacyModalComponent.openPrivacyModal();
  }

  openTermsModal() {
    this.termsModalComponent.openTermsModal();
  }

  getTranslations() {
    this.listen.push(this.translate.get('ALERTS.EXIT_REGISTRATION').subscribe((res: string) => { this.exitRegisterConfirmation = res; }));
  }

  ngOnInit() {
    let isInMobile = false;
    if (window.screen.width < 500 || window.screen.height < 500) { // catch portrait and landscape
      isInMobile = true;
    }

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.showRegistrationView('register-form');
    // this.showRegistrationView('mail-confirmation');
    // this.showRegistrationView('registration-choices');

    this.timeZone = moment.tz.guess();

    this.listen.push(this.activatedRoute.params.subscribe(params => {
      if (params['token']) {

        const myObserver = {
          next: (response) => {
            const domain = window.location.hostname;
            if (this.rememberMe) {
              this.cookieService.set('jwt', response['items']['accessToken'], 365, '/', domain, true, 'Strict');
              this.cookieService.set('csrfToken', response['items']['csrfToken'], 365, '/', domain, true, 'Strict');
            } else {
              this.cookieService.delete('jwt', '/', domain, true, 'Strict');
              this.cookieService.delete('csrfToken', '/', domain, true, 'Strict');
              this.cookieService.set('jwt', response['items']['accessToken'], 0, '/', domain, true, 'Strict');
              this.cookieService.set('csrfToken', response['items']['csrfToken'], 0, '/', domain, true, 'Strict');
            }
          },
          error: (error) => {
            console.error(error);
            this.isClickedOnce = false;
          },
          complete: () => {
            this.globals.load().then(() => {
              setTimeout(() => {
                if (this.globals.collaboratorModeEnabled) {
                  this.router.navigate(['index']);
                } else {
                  this.showRegistrationView('registration-choices');
                }
              }, 100);
            });
            this.isClickedOnce = false;
          },
        };

        if (isInMobile) {
          this.isInMobile = true;
        } else {
          this.http.get('api/v1/account/register?token=' + params['token']).pipe(take(1)).subscribe(myObserver);
        }
      }
      if (params['state']) {
        if (isInMobile) {
          this.isInMobile = true;
        }
        this.showRegistrationView('registration-choices');
      }
      if (params['partnerToken']) {
        if (isInMobile) {
          this.isInMobile = true;
        }
        this.partnerToken = params['partnerToken'];
        this.myForm.patchValue({
          'company': {
            'partner_token': this.partnerToken
          }
        });
      }

    }));
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
    if (this.counterIntervalId) {
      clearInterval(this.counterIntervalId);
    }
  }

}
