import { Observable, of } from 'rxjs';
import { differenceInDays, startOfYesterday, subDays, subMonths, subWeeks, subYears } from 'date-fns';


export interface IPeriodFormatter {
  UIdate?: string | Date;
  APIdate?: string | Date;
  dateSetup: object;
  formatDateForUI: (...args) => IPeriodFormatter;
  convertDateForAPI: (...args) => IPeriodFormatter;
  countDaysBetween: (from, to) => number; 
  offset: number;
}

export interface IPeriodProcessor {
  start: Date | string;
  end: Date | string;
  day: Date | string;
  week: Date | string;
  month: Date | string;
  trimester: Date | string;
  semester: Date | string;
  year: Date | string;
  formatter: IPeriodFormatter;
  uidate: (_date) => Date | string;
  apidate: (_date) => Date | string;
  setStart: (...args) => void;
  setEnd: (...args) => void;
  startingAt$: () => Observable<Date | string>
  endingAt$: () => Observable<Date | string>
}

export const Period = (_start?, _end?): IPeriodProcessor => {


  const Formatter:IPeriodFormatter = {
    UIdate: undefined,
    APIdate: undefined,
    dateSetup: { year: 'numeric', month: 'numeric', day: 'numeric' },
    formatDateForUI: (date, setup?) =>{
      const _setup = setup ?? Formatter.dateSetup;
      Formatter.UIdate = new Intl.DateTimeFormat('el-GR', <any>_setup).format(date);
      return Formatter;
    },
    convertDateForAPI: (date?) => {
      const _date = date ?? Formatter.UIdate;
      if(_date) Formatter.APIdate = _date.split('/').reverse().join('-');
      return Formatter;
    },
    countDaysBetween: (start, end) =>{
      return Math.abs(differenceInDays(new Date(start), new Date(end)));
    },
    offset: Math.floor(new Date().getTimezoneOffset() / 60)
  } 

  const uidate = (_date) => Formatter.formatDateForUI(_date).UIdate;
  const apidate = (_date) => Formatter.convertDateForAPI(uidate(_date)).APIdate;

  const yesterday = apidate(startOfYesterday()); 

  const day = apidate(subDays(new Date(yesterday), 1));
  const week = apidate(subWeeks(new Date(yesterday), 1));
  const month = apidate(subMonths(new Date(yesterday), 1));
  const trimester = apidate(subMonths(new Date(yesterday), 3));
  const semester = apidate(subMonths(new Date(yesterday), 6));
  const year = apidate(subYears(new Date(yesterday), 1));

  const start = (_start && apidate(_start)) ?? week;
  const end = (_end && apidate(_end)) ?? yesterday;


  return new (function() {
    const self = this;

    this.formatter = Formatter;
    this.start = start;
    this.end = end;
    this.day = day;
    this.week = week;
    this.month = month;
    this.trimester = trimester;
    this.semester = semester;
    this.year = year;
    this.uidate = (_date) => uidate(_date);
    this.apidate = (_date) => apidate(_date);
    this.setStart = (decision) => this.start = decision;
    this.setEnd = (decision) => this.end = decision;
    this.startingAt$ = () => {
      return of(this.start);
    }
    this.endingAt$ = () => {
      return of(this.end);
    }
  })();
}