import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
    selector: 'app-dashboard-drivers-dummy',
    templateUrl: './dashboard-drivers-dummy.component.html',
    styleUrls: ['./dashboard-drivers-dummy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardDriversDummyComponent implements OnInit, AfterViewInit {

    @Input() showSampleDataText: Boolean;
    
    @Output() goToNextPageEvent = new EventEmitter<string>();
    @Output() goToPreviousPageEvent = new EventEmitter<string>();

    constructor() { }

    goToNextPage() {
        this.goToNextPageEvent.emit();
    }

    goToPreviousPage() {
        this.goToPreviousPageEvent.emit();
    }

    ngOnInit() { }

    ngAfterViewInit() { }
}
