export const I18N = {
  common: {
    ok: 'ΟΚ',
    cancel: 'Ακύρωση',
    next: 'Επόμενο',
    previous: 'Προηγούμενο',
    submit: 'Ολοκλήρωση',
    close: 'Κλείσιμο',
    unhandledSuccess: 'Ειδοποίηση',
    unhandledError: 'Προέκυψε σφάλμα',
    unhandledWarning: 'Προειδοποίηση',
    confirmNavigateAwayTitle: 'Επιβεβαίωση πλοήγησης',
    confirmNavigateAwayMessage: 'Υπάρχουν μη αποθηκευμένες αλλαγές οι οποίες θα χαθούν.<br /> Θέλετε να συνεχίσετε;',
    confirmDeleteTitle: 'Επιβεβαίωση διαγραφής',
    confirmDeleteOneMessage: 'Θέλετε να γίνει διαγραφή της επιλεγμένης εγγραφής;',
    confirmDeleteManyMessage: 'Θέλετε να γίνει διαγραφή των επιλεγμένων εγγραφών;',
    actions: {
      new: 'Νέο',
      edit: 'Τροποποίηση',
      view: 'Προβολή',
      save: 'Αποθήκευση',
      reload: 'Ανανέωση',
      delete: 'Διαγραφή',
      actions: 'Ενέργειες'
    },
    crud: {
      alerts: 'Ειδοποιήσεις',
      deleteError: 'Σφάλμα κατά τη διαγραφή',
      saveSuccessTitle: 'Επιτυχής αποθήκευση',
      saveSuccessOneMessage: 'Η εγγραφή αποθηκεύτηκε επιτυχώς',
      saveSuccessManyMessage: 'Οι εγγραφές αποθηκεύτηκαν επιτυχώς',
      editSuccessTitle: 'Επιτυχής ενημέρωση',
      editSuccessOneMessage: 'Η εγγραφή ενημερώθηκε επιτυχώς',
      editSuccessManyMessage: 'Οι εγγραφές ενημερώθηκαν επιτυχώς',
      deleteSuccess: 'Επιτυχής διαγραφή',
      deleteSuccessOneMessage: 'Η εγγραφή διαγράφηκε επιτυχώς',
      deleteSuccessManyMessage: 'Οι εγγραφές διαγράφηκαν επιτυχώς'
    }
  }
};
