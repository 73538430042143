import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LmBrowserUtilsService } from '@app/core/services/browser-service';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: 'registration-confirm.component.html'
})
export class PudoRegistrationConfirmComponent implements OnInit, AfterViewInit, OnDestroy {
  password: string;
  token: string;
  submitBttn: LmButtonConfig;

  constructor(
    private _router: Router,  
    private _route: ActivatedRoute,
    private _http: HttpClient,
    private _browser: LmBrowserUtilsService
  )
   {

    this.submitBttn = {
      label:'Submit', 
      disabled: true, 
      icon: 'lm-icon icon-check', 
      rounded: true, 
      command: () => this.submit(),  
      styleClass: 'lm-bg-color-mily'
    };
    this.token = this._route.snapshot.params.token
   }

   handlePassword(e){
    this.submitBttn.disabled = e && e.length > 3 ?  false : true;
   }

   submit(){
      this._http.post(`api/pudoInternal/v1/account/confirm/${this.token}`, { userAccount: {passwordPlaintextNew : this.password} })
      .pipe(
        take(1), untilDestroyed(this)
      ).subscribe(_=> {
        if(this._browser.isAndroidMobileDevice()) window.location.href = 'https://play.google.com/store/apps/details?id=com.thinkSocialLtd.lastmilyDriver';
        else if(this._browser.isiOSMobileDevice() ) window.location.href = 'https://www.apple.com/app-store/';
        else this._router.navigate(['login'])
      });

   }


 
  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
}
