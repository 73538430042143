import { Component, OnInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import * as libphonenumber from 'libphonenumber-js';

@Component({
  selector: 'app-wizard-finish',
  templateUrl: './wizard-finish.component.html',
  styleUrls: ['./wizard-finish.component.scss']
})
export class WizardFinishComponent implements OnInit {
  myForm: FormGroup;

  name = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  tin = '';
  addressText = '';

  constructor(
    public globals: Globals,
    private formBuilder: FormBuilder,
    private http: HttpClient,
  ) {
    this.myForm = formBuilder.group({
      'company': formBuilder.group({
        'name': [this.name],
        'telephone': [this.telephone],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'tin': [this.tin],
        'address_text': [this.addressText],
        'demo_state': 2
      }),
    });
  }

  patchForLoad() {
    this.myForm.patchValue({
      'company': {
        'name': this.name,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'tin': this.tin,
        'address_text': this.addressText,
      },
    });
    M.updateTextFields();
  }

  patchForm() {
    this.myForm.patchValue({
      'company': {
        'telephone': this.telephone,
      },
    });
    M.updateTextFields();
  }

  submitWizardForm() {
    const formValues = this.myForm.value.company;
    if (formValues.phoneNumber) {
      this.telephone = formValues.countryPrefix + formValues.phoneNumber;
    }
    this.patchForm();
    const myObserver = {
      next: (response) => { },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    };
    const companyUrl = 'api/v1/companies/' + this.globals.companyId;
    this.http.put(companyUrl, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
  }

  ngOnInit() {
    this.name = this.globals.companyData['name'];
    this.telephone = this.globals.companyData['telephone'];
    
    if (this.telephone === 'n/a') { this.telephone = ''; }
    if (this.telephone) {
      this.phoneNumber = this.telephone;
      if (this.telephone.length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
        this.phoneNumber = phoneObj.nationalNumber;
        this.countryPrefix = '+' + phoneObj.countryCallingCode;
      }
    }
    this.tin = this.globals.companyData['tin'];
    this.addressText = this.globals.companyData['address_text'];
    this.patchForLoad();
  }

}
