// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider-vertical {
  margin: 0 16px;
  width: 1px;
  height: 38px;
  border-left: 1px solid #999999;
}

.white-bar-right-button {
  padding: 0 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbWVyLWNvbGxhYm9yYXRvcnMtZ3JpZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNJLGNBQUE7RUFDQSxVQUFBO0VBQ0EsWUFBQTtFQUNBLDhCQUFBO0FBREo7O0FBSUE7RUFDSSxlQUFBO0FBREoiLCJmaWxlIjoiY3VzdG9tZXItY29sbGFib3JhdG9ycy1ncmlkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcInN0eWxlcy9hYnN0cmFjdHMvdmFyaWFibGVzXCI7XG5cbi5kaXZpZGVyLXZlcnRpY2FsIHtcbiAgICBtYXJnaW46IDAgMTZweDtcbiAgICB3aWR0aDogMXB4O1xuICAgIGhlaWdodDogMzhweDtcbiAgICBib3JkZXItbGVmdDogMXB4IHNvbGlkICM5OTk5OTk7XG59XG5cbi53aGl0ZS1iYXItcmlnaHQtYnV0dG9uIHtcbiAgICBwYWRkaW5nOiAwIDE0cHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/grids/customer-collaborators-grid/customer-collaborators-grid.component.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,UAAA;EACA,YAAA;EACA,8BAAA;AADJ;;AAIA;EACI,eAAA;AADJ;AACA,gpBAAgpB","sourcesContent":["@import \"styles/abstracts/variables\";\n\n.divider-vertical {\n    margin: 0 16px;\n    width: 1px;\n    height: 38px;\n    border-left: 1px solid #999999;\n}\n\n.white-bar-right-button {\n    padding: 0 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
