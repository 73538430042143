import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { PartnerFormComponent } from '@app/forms/partner-form/partner-form.component';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrls: ['./partner-modal.component.scss']
})
export class PartnerModalComponent implements OnInit, OnDestroy {

  @ViewChild(PartnerFormComponent, { static: false }) partnerFormComponent: PartnerFormComponent;
  listen = [];

  dataLoading = false;

  collaboratorId;

  constructor(
    private modalService: ModalService,
    private modalGridService: ModalGridService,
    public genericService: GenericService,
  ) {
    this.listen.push(this.modalService.openPartnerModalListen().subscribe((data: number) => {
      // Check if the collaborator being opened is a new collaborator or an existing one
      if (data) {
        this.loadAndOpenCustomerCollaboratorsModal(data);
      } else {
        this.partnerFormComponent.resetForm();
        this.loadAndOpenCustomerCollaboratorsModal(null);
      }

    }));
    this.listen.push(this.modalGridService.listenCloseRightModal().subscribe((response: any) => {
      this.closeModal();
    }));
  }

  loadAndOpenCustomerCollaboratorsModal(data) {
    this.openModal();
  }

  dataLoadingListen(event) {
    if (event === 'true') {
      this.dataLoading = true;
    } else if (event === 'false') {
      this.dataLoading = false;
    }
  }

  deleteCustomer() {
    this.partnerFormComponent.isClickedOnce = true;
    const myObserver = {
      next: (response) => {
        this.modalGridService.updateCustomerGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.partnerFormComponent.isClickedOnce = false;
        this.partnerFormComponent.resetForm();
        this.modalGridService.closeRightModal();
      },
    };
    // this.http.delete('api/v1/customers/' + this.customerId).subscribe(myObserver);
  }

  submitCustomer() {
    // this.customerCollaboratorsFormComponent.submitCustomerForm();
  }

  openModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.customer-collaborators-modal');
    const modalBackground = document.querySelector('.customer-collaborators-modal-background');
    modal.classList.add('open');
    modal.classList.remove('closed');
    modalBackground.classList.remove('hidden');

    // this.customerCollaboratorsFormComponent.initializeSelect();
  }

  closeModal() {
    const modal = document.querySelector('.customer-collaborators-modal');
    const modalBackground = document.querySelector('.customer-collaborators-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.partnerFormComponent.resetForm();
  }

  // custom event for modal open/close toggling
  toggleModalListen(event) {
    if (event === 'open') {
      this.openModal();
    } else if (event === 'close') {
      this.closeModal();
    }
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
