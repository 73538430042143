/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LmSettingsRevaluationsService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

    // ------------------------- ADD DTOs ---------------------
  
    static readonly settingsRevaluationsPath = '/api/v1/price-list/revaluation';
    
    // GET ALL
    apiSettingsRevaluationsAllGet$Response(collaboratorId: number): Observable<StrictHttpResponse<Array<any>>> {
        let path = LmSettingsRevaluationsService.settingsRevaluationsPath;
        if (collaboratorId) path += '?collaborator=' + collaboratorId;
        const rb = new RequestBuilder(this.rootUrl, path, 'get');

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsRevaluationsAllGet(collaboratorId: number): Observable<Array<any>> {
        return this.apiSettingsRevaluationsAllGet$Response(collaboratorId).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // GET BY ID
    static readonly settingsRevaluationsSingleGetPath = '/api/v1/price-list/revaluation{id}';

    apiSettingsRevaluationsSingleGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsRevaluationsService.settingsRevaluationsSingleGetPath, 'get');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsRevaluationsSingleGet(params?: { id?: string }): Observable<Array<any>> {
        return this.apiSettingsRevaluationsSingleGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


    // DELETE
    static readonly settingsRevaluationsSingleDeletePath = '/api/v1/price-list/revaluation/{id}';

    apiSettingsRevaluationsSingleDelete$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsRevaluationsService.settingsRevaluationsSingleDeletePath, 'delete');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsRevaluationsSingleDelete(params?: { id?: string }): Observable<Array<any>> {
        return this.apiSettingsRevaluationsSingleDelete$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // POST 
    apiSettingsRevaluationsPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmSettingsRevaluationsService.settingsRevaluationsPath, 'post');
      if (params) rb.body(params.body, 'application/*+json');

      return this.http
        .request(
          rb.build({
            responseType: 'json',
            accept: 'application/json'
          })
        )
        .pipe(
          filter((r: any) => r instanceof HttpResponse),
          map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<any>>;
          })
        );
    }
  

    apiSettingsRevaluationsPost(params?: { body?: any }): Observable<Array<any>> {
      return this.apiSettingsRevaluationsPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

}
