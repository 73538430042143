// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .p-toast-message{box-shadow:none}
    .p-toast-icon.pi{font-weight:700;font-size:1.6rem}
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvYXN0LWRlZmF1bHQtY29udGVudC5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJLGlCQUFpQixlQUFlO0lBQ2hDLGlCQUFpQixlQUFlLENBQUMsZ0JBQWdCIiwiZmlsZSI6InRvYXN0LWRlZmF1bHQtY29udGVudC5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAucC10b2FzdC1tZXNzYWdle2JveC1zaGFkb3c6bm9uZX1cbiAgICAucC10b2FzdC1pY29uLnBpe2ZvbnQtd2VpZ2h0OjcwMDtmb250LXNpemU6MS42cmVtfVxuICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/containers/toast/toast-default-content.component.ts"],"names":[],"mappings":";IACI,iBAAiB,eAAe;IAChC,iBAAiB,eAAe,CAAC,gBAAgB;;AAErD,ocAAoc","sourcesContent":["\n    .p-toast-message{box-shadow:none}\n    .p-toast-icon.pi{font-weight:700;font-size:1.6rem}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
