import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import InfoWindow = google.maps.InfoWindow;
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-map-be-testing',
    templateUrl: './map-be-testing.component.html',
    styleUrls: ['./map-be-testing.component.scss']
})
export class MapBeTestingComponent implements OnInit {

    map;
    script;
    googleMapsBounds;
    googleMapsZoomLevel;
    listenerEvent;

    TILE_SIZE = 256;

    // @HostListener('document:keyup', ['$event'])
    // onKeyUp(ev: KeyboardEvent) {
    //     // do something meaningful with it
    //     console.log(`The user just pressed ${ev.key}!`);
    // }

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        this.initMap();
        const elems = document.querySelectorAll('select');
        const instances = M.FormSelect.init(elems);
    }

    // The mapping between latitude, longitude and pixels is defined by the web
    // mercator projection.
    project(latLng) {
        let siny = Math.sin(latLng.lat() * Math.PI / 180);

        // Truncating to 0.9999 effectively limits latitude to 89.189. This is
        // about a third of a tile past the edge of the world tile.
        siny = Math.min(Math.max(siny, -0.9999), 0.9999);

        return new google.maps.Point(
            this.TILE_SIZE * (0.5 + latLng.lng() / 360),
            this.TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
        );
    }

    createInfoWindowContent(latLng, zoom) {
        let scale = 1 << zoom;

        let worldCoordinate = this.project(latLng);

        let pixelCoordinate = new google.maps.Point(
            Math.floor(worldCoordinate.x * scale),
            Math.floor(worldCoordinate.y * scale));

        let tileCoordinate = new google.maps.Point(
            Math.floor(worldCoordinate.x * scale / this.TILE_SIZE),
            Math.floor(worldCoordinate.y * scale / this.TILE_SIZE));

        return [
            'LatLng: ' + latLng,
            'Zoom level: ' + zoom,
            'World Coordinate: ' + worldCoordinate,
            'Pixel Coordinate: ' + pixelCoordinate,
            'Tile Coordinate: ' + tileCoordinate
        ].join('<br>');
    }

    initMap() {
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 17,
            // center: {lat: 40.634365, lng: 22.956412}
            // center: {lat: 40.6520378106329, lng: 22.8297529220581}
            // center: {lat: 40.6680284, lng: 22.962699}
            center: {lat: 40.677236, lng: 22.938904}
            // center: {lat: 40.634076, lng: 22.947865}
        });

        // Set google maps idle event
        this.map.addListener('idle', () => {
            this.googleMapsBounds = this.map.getBoundingBox();
            this.googleMapsZoomLevel = this.map.getZoom();
            this.getTraffic();
        });

        var coordInfoWindow = new google.maps.InfoWindow({
            content: 'tasloum'
        });

        // it is called here. If it called every time new data going to map multiple events are set it.
        this.map.data.addListener('click', (event) => {
            const feature = event.feature;
            const type = feature.getProperty('type');
            const featureType = feature.getGeometry().getType();
            let msg = '';
            if (type == 'rtp') {
                msg += 'RTP > ';
                msg += 'oid: ' + feature.getId();
                msg += ' | status: ' + feature.getProperty('status');
                if (feature.getProperty('tfic')) {
                    msg += ' | tfic: ' + feature.getProperty('tfic');
                }
                if (feature.getProperty('label')) {
                    msg += ' | label: ' + feature.getProperty('label');
                }
                if (feature.getProperty('relatedOsmWayId')) {
                    msg += ' | relatedOsmWayId: ' + feature.getProperty('relatedOsmWayId');
                }
                if (feature.getProperty('betweenOsmNodes')) {
                    msg += ' | betweenOsmNodes: ' + feature.getProperty('betweenOsmNodes').join('<->');
                }
            } else if (type == 'osm-node') {
                msg += 'OSM-NODE > ';
                msg += 'oid: ' + feature.getId();
                msg += ' | elemId: ' + feature.getProperty('elemId');
            } else if (type == 'osm-way') {
                msg += 'OSM-WAY > ';
                msg += 'oid: ' + feature.getId();
                msg += ' | elemId: ' + feature.getProperty('elemId');
            } else if (type == 'ttts') {
                let segmentMetrics = feature.getProperty('metrics');
                msg += 'ttts > ';
                msg += 'oid: ' + feature.getId();
                msg += ' | dow: ' + feature.getProperty('dow');
                msg += ' | todRange: ' + feature.getProperty('todRange');
                msg += ' | streetName: ' + feature.getProperty('streetName');
                msg += ' | distance: ' + feature.getProperty('distance');
                msg += ' | speed: ' + segmentMetrics['speed']['average'];
            }

            if (featureType == 'Point') {
                msg += ' | coords: ' + feature.getGeometry().get().toString();
            }

            // Display the message
            console.log(msg);

            let infoWindowCoordinates = null;
            if (featureType == 'Point') {
                infoWindowCoordinates = feature.getGeometry().get();
            } else if (featureType == 'LineString') {
                infoWindowCoordinates = feature.getGeometry().getAt(0);
            }

            // Open an info window
            coordInfoWindow.setContent(this.createInfoWindowContent(infoWindowCoordinates, 18));
            coordInfoWindow.setPosition(event.latLng);
            coordInfoWindow.open(this.map);

        });

        // // Add the circle for this city to the map.
        // var cityCircle = new google.maps.Circle({
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: '#FF0000',
        //     fillOpacity: 0.35,
        //     map: this.map,
        //     center: new google.maps.LatLng(40.634139, 22.95613030000004),
        //     radius: 50
        // });
    }

    getTraffic() {

        let tomTomTrafficSegmentCounter = 0;
        let tomTomTrafficSegmentColors = [
            '0000FF',
            'FF00FF',
            '00FFFF',
            '00FF00',
            'FF0000',
            'FF1300',
            '007F16',
        ];
        let tomtomTrafficLevelColors = [
            '000000',
            '811f1f',
            'f23c32',
            'ff974d',
            '64d568',
        ];

        // console.log(this.googleMapsBounds.toJSON());
        // console.log(this.googleMapsZoomLevel);
        // console.log(this.map.getCenter().toJSON());
        // console.log(this.map.getHeading());
        // console.log(this.map.getProjection());
        // console.log(this.map.getMapTypeId());
        // console.log(this.map.getTilt());

        let tomtomDow = (document.getElementById('dow')) as HTMLSelectElement;
        let tomtomTodRange = (document.getElementById('tod-range')) as HTMLSelectElement;

        let p = {
            googleMapsLatLngBoundsLiteral: JSON.stringify(this.googleMapsBounds.toJSON()),
            googleMapsCurrentZoomLevel: this.googleMapsZoomLevel,
            dummy: 'false',
            tomtomDow: tomtomDow.options[tomtomDow.selectedIndex].value,
            tomtomTodRange: tomtomTodRange.options[tomtomTodRange.selectedIndex].value
        };

        this.http.get(
            'api/traffic/be-testing',
            {
                params: p
            }
        ).pipe(take(1)).subscribe(response => {

            if (response['status'] === 'ok') {
                // Remove existing
                this.map.data.forEach((feature) => {
                    this.map.data.remove(feature);
                });

                if ('polylinesFeatureCollection' in response['data']) {
                    this.map.data.addGeoJson(
                        response['data']['polylinesFeatureCollection']
                    );
                }

                if ('osmNodesFeatureCollection' in response['data']) {
                    this.map.data.addGeoJson(
                        response['data']['osmNodesFeatureCollection'],
                        {
                            idPropertyName: 'oid'
                        }
                    );
                }

                if ('rawTrafficPointsFeatureCollection' in response['data']) {
                    this.map.data.addGeoJson(
                        response['data']['rawTrafficPointsFeatureCollection'],
                        {
                            idPropertyName: 'oid'
                        }
                    );
                }

                if ('tomTomTrafficSegmentsFeatureCollection' in response['data']) {
                    this.map.data.addGeoJson(
                        response['data']['tomTomTrafficSegmentsFeatureCollection'],
                        {
                            idPropertyName: 'oid'
                        }
                    );
                }

                if ('stopPointsFeatureCollection' in response['data']) {
                    this.map.data.addGeoJson(
                        response['data']['stopPointsFeatureCollection'],
                        {
                            idPropertyName: 'id'
                        }
                    );
                }


            } else {
                console.warn(response['msg']);
            }
        });

        this.map.data.setStyle(function (feature) {
            const status = feature.getProperty('status');
            const label = feature.getProperty('label');
            const type = feature.getProperty('type');
            let color = '';
            if (type === 'rtp') {
                if (status === 0) {
                    color = 'cyan';
                } else if (status === 1) {
                    color = '#403789';
                } else if (status === 2) {
                    color = '#7f7f7f';
                } else if (status === 3) {
                    color = 'black';
                } else if (status === 4) {
                    if (label) {
                        if ((label.split(':')).length === 2) {
                            if (label.split(':')[0] === 'r') {
                                color = 'blue';
                            } else {
                                color = 'yellow';
                            }
                        } else {
                            color = 'magenta';
                        }
                    }
                }
                return {
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 3,
                        fillColor: color,
                        fillOpacity: 1,
                        strokeWeight: 0.4
                    }
                };
            } else if (type === 'osm-node') {
                return {
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 6,
                        fillColor: 'brown',
                        fillOpacity: 1,
                        strokeWeight: 0.4
                    }
                };
            } else if (type === 'ttts') {
                tomTomTrafficSegmentCounter = tomTomTrafficSegmentCounter + 1;

                let segmentMetrics = feature.getProperty('metrics');
                let segmentSpeed = segmentMetrics['speed']['average'];
                let strokeColorForSegment = null;
                if (segmentSpeed == 0) {
                    strokeColorForSegment = '#' + tomtomTrafficLevelColors[0];
                } else if (segmentSpeed < 4) {
                    strokeColorForSegment = '#' + tomtomTrafficLevelColors[1];
                    // let strokeWeight = Math.floor((Math.random() * 30) + 10);
                } else if (segmentSpeed < 9) {
                    strokeColorForSegment = '#' + tomtomTrafficLevelColors[2];
                    // let strokeWeight = Math.floor((Math.random() * 30) + 10);
                } else if (segmentSpeed < 15) {
                    strokeColorForSegment = '#' + tomtomTrafficLevelColors[3];
                    // let strokeWeight = Math.floor((Math.random() * 30) + 10);
                } else if (segmentSpeed < 80) {
                    strokeColorForSegment = '#' + tomtomTrafficLevelColors[4];
                    // let strokeWeight = Math.floor((Math.random() * 30) + 10);
                }
                return {
                    strokeColor: strokeColorForSegment,
                    strokeWeight: 5
                };
            } else if (type === 'stop-point') {
                return {
                    label: label
                }
            }
        });
    }

    centerToFeature(event: any) {
        let rawTrafficPointOid = event.target.value;

        let rawTrafficPointFeature = this.map.data.getFeatureById(rawTrafficPointOid);
        if (rawTrafficPointFeature) {
            rawTrafficPointFeature.getGeometry().forEachLatLng((latLng) => {
                this.map.setCenter(latLng);
                this.map.setZoom(22);
            });
        } else {
            console.warn('Feature not found');
        }
    }

}
