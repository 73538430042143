import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';

@Component({
    selector: 'app-payments-overview',
    templateUrl: './payments-overview.component.html',
    styleUrls: ['./payments-overview.component.scss']
})
export class PaymentsOverviewComponent implements OnInit, OnDestroy {

    driverName = '';
    driverPayments = [];
    overviewType = 'projectReport'; // projectReport, driverReport
    currentPayerType = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    amounts = {};
    listen = [];

    isChequeHovered = false;

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        public gridsService: GridsService,
        public modalService: ModalService,
        public router: Router
    ) {
        // this.listen.push(this.settingsService.openDynamicFieldModalListen().subscribe(() => {
        //     this.openModal();
        // }));
    }

    openShipmentsModal(type, driverName, stopPoints, overviewType) {
        this.currentPayerType = type;
        this.modalService.openShipmentsModal({ type: type, name: driverName, stopPoints: stopPoints, overviewType: overviewType });
    }

    getTranslations() {
        // this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    }

    toggleChequeAnalysis() {
        this.isChequeHovered = !this.isChequeHovered;
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}