import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@app/services/modal.service';
import flatpickr from 'flatpickr';

@Component({
    selector: 'app-invoice-custom-date-modal',
    templateUrl: './invoice-custom-date-modal.component.html',
    styleUrls: ['./invoice-custom-date-modal.component.scss']
})
export class InvoiceCustomDateModalComponent implements OnInit, OnDestroy {

    listen = [];
    isClickedOnce = false;
    datetime;

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        private modalService: ModalService,
    ) { }

    openModal() {
        this.isClickedOnce = false;
        const modal = document.querySelector('#invoice-custom-date-modal');
        const modalBackground = document.querySelector('#invoice-custom-date-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#invoice-custom-date-modal');
        const modalBackground = document.querySelector('#invoice-custom-date-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
    }

    submitServicesCharge() {
        this.isClickedOnce = true;
        this.closeModal();

        let date = this.datetime;

        this.modalService.updateInvoiceDate(date);
    }

    ngOnInit() {
        flatpickr('#invoice-date-range', {
            enableTime: false,
            mode: "single",
            dateFormat: "d-m-Y",
            inline: true,
            monthSelectorType: "static"
        });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}