import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ColourService } from '@app/services/colour.service';

@Component({
    selector: 'app-partner-settings',
    templateUrl: './partner-settings.component.html',
    styleUrls: ['./partner-settings.component.scss']
})
export class PartnerSettingsComponent implements OnInit, OnDestroy {

    listen = [];

    rules = [];

    constructor(
        private colourService: ColourService,
    ) {
        // this.listen.push(this.genericService.listenSubmitPartner().subscribe(() => {
        //     this.updateGrid();
        // }));
    }

    editRule() {

    }

    ngOnInit(): void {
        this.rules = [
            {
                name: 'Test',
                index: 1,
                colour: this.colourService.colourCalculator(1),
            },
            {
                name: 'Test2',
                index: 2,
                colour: this.colourService.colourCalculator(2),
            }
        ];
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
