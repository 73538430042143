import { Globals } from '@app/services/globals';
import { WizardWelcomeComponent } from './wizard-welcome/wizard-welcome.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WizardDepotsComponent } from './wizard-depots/wizard-depots.component';
import { WizardVehiclesComponent } from './wizard-vehicles/wizard-vehicles.component';
import { WizardDriversComponent } from './wizard-drivers/wizard-drivers.component';
import { WizardFinishComponent } from './wizard-finish/wizard-finish.component';
import { Router } from '@angular/router';
import { WizardStopSettingsComponent } from './wizard-stop-settings/wizard-stop-settings.component';
import { WizardDriverSettingsComponent } from './wizard-driver-settings/wizard-driver-settings.component';
import { WizardRouteSettingsComponent } from './wizard-route-settings/wizard-route-settings.component';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @ViewChild(WizardWelcomeComponent, { static: false }) welcomeComponent: WizardWelcomeComponent;
  @ViewChild(WizardDepotsComponent, { static: false }) depotsComponent: WizardDepotsComponent;
  @ViewChild(WizardStopSettingsComponent, { static: false }) stopSettingsComponent: WizardStopSettingsComponent;
  @ViewChild(WizardDriverSettingsComponent, { static: false }) driverSettingsComponent: WizardDriverSettingsComponent;
  @ViewChild(WizardRouteSettingsComponent, { static: false }) routeSettingsComponent: WizardRouteSettingsComponent;
  @ViewChild(WizardVehiclesComponent, { static: false }) vehiclesComponent: WizardVehiclesComponent;
  @ViewChild(WizardDriversComponent, { static: false }) driversComponent: WizardDriversComponent;
  @ViewChild(WizardFinishComponent, { static: false }) finishComponent: WizardFinishComponent;

  listen = [];

  // myForm: FormGroup;
  depotName: String = '';
  depotTelephone: String = '';
  address: String = '';
  addressTerm: String = '';
  addressType: String = '';
  freeformAddress: String = '';
  country: String = '';
  lat: String = '';
  lon: String = '';
  isClickedOnce = false;

  wizardTitle: String = 'Start';

  currentStepIndex = 0;
  // currentStep = '';
  wizardSteps = [];
  companyId = null;
  title = '';
  noDepotAlert = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    public globals: Globals,
    public translate: TranslateService,
  ) {
    this.wizardSteps = [
      {
        name: 'start',
        buttonStyle: 'start',
      },
      {
        name: 'depots',
        buttonStyle: 'double',
      },
      {
        name: 'route-settings',
        buttonStyle: 'double',
      },
      {
        name: 'stop-settings',
        buttonStyle: 'double',
      },
      {
        name: 'vehicles',
        buttonStyle: 'double',
      },
      {
        name: 'driver-settings',
        buttonStyle: 'double',
      },
      {
        name: 'drivers',
        buttonStyle: 'end',
      },
      // {
      //   name: 'information',
      //   buttonStyle: 'end',
      // },
    ];
  }

  saveCurrentData() {
    switch (this.wizardSteps[this.currentStepIndex].name) {
      case 'start':
        this.welcomeComponent.submitWizardForm();
        break;
      case 'depots':
        this.depotsComponent.submitWizardForm();
        break;
      case 'stop-settings':
        this.stopSettingsComponent.submitWizardForm();
        break;
      case 'route-settings':
        this.routeSettingsComponent.submitWizardForm();
        break;
      case 'driver-settings':
        this.driverSettingsComponent.submitWizardForm();
        this.driversComponent.resetForm();
        break;
      case 'vehicles':
        this.vehiclesComponent.submitWizardForm();
        break;
      case 'drivers':
        this.driversComponent.submitWizardForm();
        break;
      case 'information':
        this.finishComponent.submitWizardForm();
        break;
    }
  }

  loadData() {
    document.querySelector('.wizard-screen.active').classList.remove('active');
    const targetScreenElement = document.getElementById(this.wizardSteps[this.currentStepIndex].name);
    targetScreenElement.classList.add('active');
    this.reloadGrid();
  }

  reloadGrid() {
    switch (this.wizardSteps[this.currentStepIndex].name) {
      case 'depots':
        this.depotsComponent.updateGrid();
        break;
      case 'vehicles':
        this.vehiclesComponent.updateGrid();
        break;
      case 'drivers':
        this.driversComponent.updateGrid();
        break;
    }
  }

  updateGrid() {
    switch (this.wizardSteps[this.currentStepIndex].name) {
      case 'depots':
        this.depotsComponent.updateGrid();
        break;
      case 'vehicles':
        this.vehiclesComponent.updateGrid();
        break;
      case 'drivers':
        this.driversComponent.updateGrid();
        break;
    }
  }

  deleteDemoAndNext() {
    this.http.delete('api/v1/demos').pipe(take(1)).subscribe(response => {
      if (this.currentStepIndex < this.wizardSteps.length - 1) { this.currentStepIndex++; }
      this.loadData();
      const companyUrl = 'api/v1/companies/' + this.globals.companyId;
      this.http.get(companyUrl).pipe(take(1)).subscribe(response => { });
      this.globals.getCompanyData();
    });
  }

  saveAndNext() {
    if (this.wizardSteps[this.currentStepIndex].name === 'depots') {
      if (this.depotsComponent.checkIfDepotExists()) {
        this.saveCurrentData();
        if (this.currentStepIndex < this.wizardSteps.length - 1) { this.currentStepIndex++; }
        this.loadData();
      } else {
        alert(this.noDepotAlert);
      }
    } else {
      this.saveCurrentData();
      if (this.currentStepIndex < this.wizardSteps.length - 1) { this.currentStepIndex++; }
      this.loadData();
    }
  }

  saveAndFinish() {
    this.saveCurrentData();
    this.http.get('api/internal/v2/drivers').pipe(take(1)).subscribe(response => {
        // empty drivers array because otherwise the options are not assigned to the dropdown for some twisted reason
        const driversIds = [];
        response['items'].forEach(driver => {
          driversIds.push(driver.driver.id);
        });
        const data = {
          project: {
            title: this.title,
            driversIds: driversIds,
            company_depot_id: this.globals.depotsArray[0].companyDepot.id,
            default_project_departure_datetime: this.globals.defaultDepartureDateTime,
            default_project_departure_datetime_timezone: moment.tz.guess()
          },
        };

        this.http.post('api/v1/projects', data).pipe(take(1)).subscribe(responsePP => {
          this.globals.getCompanyData();
          this.globals.getVehicles();
          this.router.navigate(['']);
        });
    });
  }

  saveForm(clone = false) {
    switch (this.wizardSteps[this.currentStepIndex].name) {
      case 'vehicles':
        this.vehiclesComponent.submitWizardForm(clone);
        break;
      case 'drivers':
        this.driversComponent.submitWizardForm(clone);
        break;
    }
  }

  saveAndPrevious() {
    this.saveCurrentData();
    if (this.currentStepIndex > 0) { this.currentStepIndex--; }
    this.loadData();
  }

  getTranslations() {
    this.listen.push(this.translate.get('PROJECT.FIRST_PROJECT').subscribe((res: string) => {
      this.title = res;
    }));
    this.listen.push(this.translate.get('WIZARD.NO_DEPOT_ALERT').subscribe((res: string) => {
      this.noDepotAlert = res;
    }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // this.currentStepIndex = 4;
    this.loadData();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
