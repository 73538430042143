import { CommonModule } from '@angular/common';
import { Directive, NgModule, TemplateRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Directive({ selector: '[lm-label-tpl]' })
export class LmLabelTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[lm-header-tpl]' })
export class LmHeaderTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[lm-option-tpl]' })
export class LmOptionTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [LmLabelTemplateDirective, LmHeaderTemplateDirective, LmOptionTemplateDirective],
  exports: [LmLabelTemplateDirective, LmHeaderTemplateDirective, LmOptionTemplateDirective]
})
export class LmSelectorTemplatesModule {}
