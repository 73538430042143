import { AddressService } from './../../services/address.service';
import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { take } from 'rxjs/operators';
import { ModalService } from '@app/services/modal.service';
import { Router } from '@angular/router';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';

declare var H: any;

@Component({
    selector: 'app-cancel-stop-point-modal',
    templateUrl: './cancel-stop-point-modal.component.html',
    styleUrls: ['./cancel-stop-point-modal.component.scss']
})
export class CancelStopPointModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() cancelChangeStopPointFulfillmentStatus = new EventEmitter<string>();
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @ViewChild('mapSmartPoints', { static: false }) public mapElement: ElementRef;

    updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
    updateGeneralStopPointUrl = 'api/v1/stop-points/STOP_POINT_ID';
    listen = [];
    isClickedOnce = false;
    projectProblemId = null; // is set in project view component
    stopPointId = null;
    recipientName;
    serviceType;
    stopPointCoords
    cancelStatusLabel;
    selectedFulfillmentStatus = {};
    fulfillmentStatusDescription = '';
    agreedShippingDate = '';
    agreedShippingValuesVisible = false;
    // inWarehouse = false;

    timeWindow = [480, 1200];
    timeWindowOptions;
    timeWindowUnformattedDefault = ['08:00', '20:00'];
    timeWindowUnformatted = ['08:00', '20:00'];

    // map (for smart points)
    map;
    behavior;
    infoBubble;
    ui;
    smartPointsGroup = new H.map.Group();
    smartPointsMapObjects = {};

    selectedSmartPoint;
    showInfoBox;
    infoBoxName;
    infoBoxAddress;

    allSmartPoints = [];
    smartPointsSearchResult = [];
    isSearchActive = false;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private viewProjectProblemService: ViewProjectProblemService,
        private projectProblemDataService: ProjectProblemDataService,
        public router: Router,
        private modalService: ModalService,
        private addressService: AddressService,
    ) {
        this.initializeSlider();
        // this.listen.push(this.settingsService.openDynamicFieldModalListen().subscribe(() => {
        //     this.openModal();
        // }));
    }

    initializeSlider() {
        this.timeWindowOptions = {
            margin: 30, // how many minutes between start and stop
            step: 15,
            start: [480, 1200],
            connect: [false, true, false],
            range: {
                min: 0,
                max: 1439
            },
        };
    }

    convertValuesToTime() {
        const self = this;
        let hours = 0, minutes = 0;
        this.timeWindow.forEach(function (timeWindow, i) {
            hours = self.convertToHour(timeWindow);
            minutes = self.convertToMinute(timeWindow, hours);
            self.timeWindowUnformatted[i] = self.formatHoursAndMinutes(hours, minutes);
        });
    }

    convertToHour(value) {
        return Math.floor(value / 60);
    }

    convertToMinute(value, hour) {
        return value - hour * 60;
    }

    formatHoursAndMinutes(hours, minutes) {
        if (hours.toString().length === 1) {
            hours = '0' + hours;
        }
        if (minutes.toString().length === 1) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }

    openModal(stopPointId, projectProblemId, selectedFulfillmentStatus, serviceType, coords, recipientName) {
        this.serviceType = serviceType;
        this.cancelStatusLabel = selectedFulfillmentStatus.label;
        this.recipientName = recipientName;
        this.stopPointId = stopPointId;
        this.projectProblemId = projectProblemId;
        this.stopPointCoords = coords;
        this.selectedFulfillmentStatus = selectedFulfillmentStatus;
        if (
            this.selectedFulfillmentStatus['reason'] === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING'] ||
            this.selectedFulfillmentStatus['reason'] === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS']
        ) {
            this.agreedShippingValuesVisible = true;
            this.initPickers();
        }

        this.updateSmartPointsContainerVisibility();
        const modal = document.querySelector('#cancel-stop-point-modal');
        const modalBackground = document.querySelector('#cancel-stop-point-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    cancelAndCloseModal() {
        this.cancelChangeStopPointFulfillmentStatus.emit('cancel');
        this.closeModal();
    }

    closeModal() {
        const modal = document.querySelector('#cancel-stop-point-modal');
        const modalBackground = document.querySelector('#cancel-stop-point-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.resetCancelStopPointModal();
        this.resetSmartPoints();
    }

    resetCancelStopPointModal() {
        this.stopPointId = null;
        this.selectedFulfillmentStatus = {};
        this.fulfillmentStatusDescription = '';
        this.agreedShippingValuesVisible = false;
        this.agreedShippingDate = null;
        this.isClickedOnce = false;
        this.stopPointCoords = null;
    }

    submitCancelStopPoint() {
        this.isClickedOnce = true;

        const myObserver = {
            next: (response) => {
                if (response['items']) {
                    response['items'].forEach(stopPointResponseData => {
                        this.projectProblemDataService.updateSingleStopPoint(stopPointResponseData);
                        if (stopPointResponseData.stopPoint.id === this.stopPointId) {
                            this.viewProjectProblemService.setModalData(stopPointResponseData);
                        }
                    });
                    this.viewProjectProblemService.updateProjectProblemStatus();
                    this.viewProjectProblemService.updateWarehouseGrid();
                    this.modalService.updateStopPointsGrid();

                    // refresh the smart points in map
                    this.projectProblemDataService.loadSmartPoints().subscribe(smartResponse => {
                        this.projectProblemDataService.smartPointsArray = smartResponse['items'];
                        this.projectProblemDataService.loadSmartPointsDone = true;
                        this.viewProjectProblemService.updateSmartPoints();
                    });
                }

                // refresh stops grid if in /stops
                if (this.router.url.split('/')[1] == 'stops') {
                    this.modalService.updateStopPointsGrid();
                }

                this.closeModal();
            },
            error: (error) => {
                console.error(error);
                this.isClickedOnce = false;
            },
            complete: () => {
                this.isClickedOnce = false;
            },
        };

        if (this.selectedFulfillmentStatus === 'return' && Array.isArray(this.stopPointId) && this.projectProblemId) {
            const data = {
                stopPoints: []
            };
            this.stopPointId.forEach(id => {
                data.stopPoints.push({
                    stopPoint: {
                        id: id,
                        fulfillment_event: {
                            description: this.fulfillmentStatusDescription
                        }
                    }
                });
            });
            this.http.post('api/v1/project/problems/' + this.projectProblemId + '/return-and-or-switch', data).pipe(take(1)).subscribe(myObserver);
        } else {
            const stopPointData = {
                stopPoint: {
                    fulfillment_status: this.selectedFulfillmentStatus['constant'],
                    fulfillment_event: {
                        reason: this.selectedFulfillmentStatus['reason'],
                        description: this.fulfillmentStatusDescription
                    }
                }
            };

            if (this.selectedFulfillmentStatus['reason'] === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING']
                || this.selectedFulfillmentStatus['reason'] === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['AGREED_SHIPPING_WRONG_ADDRESS']) {
                let date = '', start = '', end = '';
                const dateElement = document.querySelector('#agreed-shipping-date-cancel-stop-modal');
                const rawDate = M.Datepicker.getInstance(dateElement).date;
                if (rawDate) {
                    date = moment(rawDate, 'ddd MMM DD YYYY HH:mm:SS').format('YYYY-MM-DD');
                }

                // if agreed shipping date is not assigned, use today's date
                if (!date) {
                    date = moment().format('YYYY-MM-DD');
                }

                start = this.timeWindowUnformatted[0];
                end = this.timeWindowUnformatted[1];

                stopPointData['stopPoint']['agreed_shipping'] = [
                    {
                        date: date,
                        start: start,
                        end: end
                    }
                ]
            } else if (this.selectedFulfillmentStatus['reason'] === this.globals.smartPointFulfillmentConstant) {
                stopPointData['stopPoint']['smartPoint'] = {
                    prefix: this.selectedSmartPoint.prefix,
                    code: this.selectedSmartPoint.code,
                    is_in_project_problem: this.globals.isInRoute('projectView') ? true : false
                }
                // remove the fulfillment status altogether
                delete stopPointData['stopPoint']['fulfillment_status'];
            } else if (this.selectedFulfillmentStatus['reason'] === this.globals.pudoFulfillmentConstant) {
                stopPointData['stopPoint']['pudoPoint'] = {
                    pudo_point_id: this.selectedSmartPoint.id,
                    pudoShipmentStopPoint: {
                        type: 1,
                        order: 1
                    },
                    pudoFulfillment: {
                        fulfillment_event: {
                            reason: this.globals.pudoFulfillmentEvents['PICKUP_AWAITING'],
                            description: ""
                        }
                    }
                }
                // remove the fulfillment event object & fulfillment status altogether
                delete stopPointData['stopPoint']['fulfillment_status'];
            }

            if (this.projectProblemId) {
                let updateStopPointUrl = this.updateStopPointUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
                updateStopPointUrl = updateStopPointUrl.replace('STOP_POINT_ID', this.stopPointId);
                this.http.put(updateStopPointUrl, JSON.stringify(stopPointData)).pipe(take(1)).subscribe(myObserver);
            }
        }
    }

    initPickers() {
        const dateElement = document.getElementById('agreed-shipping-date-cancel-stop-modal');
        const dateInstances = M.Datepicker.init(dateElement);
    }

    // smart points container visibility should be toggled from here, so that the initial width & height are applied and the map doesn't render blank
    updateSmartPointsContainerVisibility() {
        setTimeout(() => {
            // for smart point cancelation fulfillment reason
            if (this.selectedFulfillmentStatus['reason'] == this.globals.smartPointFulfillmentConstant ||
                this.selectedFulfillmentStatus['reason'] == this.globals.pudoFulfillmentConstant) {
                document.getElementById('cancelation-modal-body').classList.add('no-padding');
                document.getElementById('smart-points-container').classList.remove('hidden');
                // load smart points
                this.loadSmartPointsOnMap();
            } else { // for every other fulfillment reason                
                document.getElementById('cancelation-modal-body').classList.remove('no-padding');
                document.getElementById('smart-points-container').classList.add('hidden');
            }
        }, 200);
    }

    loadSmartPointsOnMap(searchString = "") {
        const distanceKmToFetch = 15;
        const smartPointsParams = `?lat=${this.stopPointCoords.lat}&lon=${this.stopPointCoords.lon}&distanceKm=${distanceKmToFetch}&stopPointId=${this.stopPointId}`;
        const pudoPointsParams = `?searchQuery=${searchString}&projectProblemId=${this.projectProblemId}&stopPointId=${this.stopPointId}`;
        let url = this.globals.isSmartPointsEnabled ? 'api/v1/smart-points' + smartPointsParams : 'api/v1/pudo-points' + pudoPointsParams;

        this.http.get(url).subscribe(res => {
            this.smartPointsGroup.removeAll();
            this.allSmartPoints = res['items'];
            const iconSmartPoint = new H.map.DomIcon(this.svgIconsComponent.smartPointMarker);

            res['items'].forEach(smartPoint => {
                const smartPointMarker = new H.map.DomMarker({ lat: Number(smartPoint.address.lat), lng: Number(smartPoint.address.lon) }, { icon: iconSmartPoint });
                smartPointMarker.setData({
                    smartPoint: smartPoint
                });

                // hover
                smartPointMarker.addEventListener('pointerenter', (event) => {
                    this.smartPointInfoShow(event);
                });

                // leave
                smartPointMarker.addEventListener('pointerleave', (event) => {
                    if (!this.selectedSmartPoint) {
                        this.showInfoBox = false;
                    }
                });

                // click
                smartPointMarker.addEventListener('pointerdown', (event) => {
                    this.smartPointClick(event);
                });

                this.smartPointsGroup.addObject(smartPointMarker);
                this.map.addObject(this.smartPointsGroup);

            });

            // zoom at the center of the smart points' group
            this.map.getViewModel().setLookAtData({ bounds: this.smartPointsGroup.getBoundingBox() }, false);
        });
    }

    smartPointClick(event) {
        const smartPoint = event.target.getData();
        this.selectedSmartPoint = smartPoint.smartPoint;
        const name = smartPoint.smartPoint.name ?? smartPoint.smartPoint.description;
        (<HTMLInputElement>document.getElementById('smart-point-search-input')).value = name;
    }

    selectSmartPointFromDropdown(selectedSmartPoint) {
        this.selectedSmartPoint = selectedSmartPoint;
        (<HTMLInputElement>document.getElementById('smart-point-search-input')).value = selectedSmartPoint?.name ?? selectedSmartPoint?.description;
        this.infoBoxName = selectedSmartPoint['name'] ?? selectedSmartPoint['description'];
        this.infoBoxAddress = this.addressService.getAddressLabel(selectedSmartPoint['address']);

        const infoBoxElem = document.getElementById('smart-points-info-box');
        infoBoxElem.style.left = (62 + '%');
        infoBoxElem.style.top = (40 + '%');
        infoBoxElem.style.display = 'flex';
        this.showInfoBox = true;

        this.map.setCenter({ lat: selectedSmartPoint['address']['lat'], lng: selectedSmartPoint['address']['lon'] });
        this.map.setZoom(15);
    }

    smartPointInfoShow(event) {
        if (!this.selectedSmartPoint) {
            // smart point info
            const data = event.target.getData();
            this.infoBoxName = data.smartPoint.name ?? data.smartPoint.description;
            this.infoBoxAddress = this.addressService.getAddressLabel(data.smartPoint.address);

            const targetPosition = this.map.geoToScreen(event.target.getGeometry());
            const infoBoxElem = document.getElementById('smart-points-info-box');
            infoBoxElem.style.left = (Number(targetPosition.x) + 50) + 'px';
            infoBoxElem.style.top = targetPosition.y + 'px';
            infoBoxElem.style.display = 'flex';
            this.showInfoBox = true;
        }
    }

    closeSmartPointInfoBox() {
        this.selectedSmartPoint = null;
        this.showInfoBox = false;
        (<HTMLInputElement>document.getElementById('smart-point-search-input')).value = null;
    }

    performSmartPointsSearch(event) {
        const searchString = event.target.value;
        const regex = new RegExp(searchString, 'i');

        let foundSmartPoints = [];
        if (this.globals.isSmartPointsEnabled) {
            if (searchString) {
                this.isSearchActive = true;
                this.smartPointsSearchResult = [];
                this.allSmartPoints.forEach(smartPoint => {
                    if (smartPoint.name.search(regex) !== -1) {
                        foundSmartPoints.push({ ...smartPoint, addressLabel: this.addressService.getAddressLabel(smartPoint.address) });
                    }
                });
                this.smartPointsSearchResult = [...foundSmartPoints];
            } else {
                this.isSearchActive = false;
            } 
        } else {
            this.loadSmartPointsOnMap(searchString);
        }
    }

    showAllSmartPoints() {
        let foundSmartPoints = [];
        this.smartPointsSearchResult = [];
        this.isSearchActive = true;
        this.allSmartPoints.forEach(smartPoint => {
            foundSmartPoints.push({ ...smartPoint, addressLabel: this.addressService.getAddressLabel(smartPoint.address) });
        });
        this.smartPointsSearchResult = [...foundSmartPoints];
    }

    resetSmartPoints() {
        this.closeSmartPointInfoBox();
        this.selectedSmartPoint = null;
        this.smartPointsGroup.removeAll();
        this.allSmartPoints = [];
        this.smartPointsSearchResult = [];
        this.isSearchActive = false;

        this.map.setCenter({ lat: this.globals.greeceLat, lng: this.globals.greeceLon });
        this.map.setZoom(6);
    }

    toggleSearch(active = false) {
        setTimeout(() => {
            this.isSearchActive = active;
            this.smartPointsSearchResult = [];
        }, 200);
    }

    ngOnInit() { }

    ngAfterViewInit() {
        const defaultLayers = this.globals.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 6,
                center: { lat: this.globals.greeceLat, lng: this.globals.greeceLon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        const provider = this.map.getBaseLayer().getProvider();
        const style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);

        // Instantiate the default behavior, providing the mapEvents object:
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        const scalebar = this.ui.getControl('scalebar');
        mapSettings.setAlignment('top-left');
        scalebar.setAlignment('top-left');
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}