import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { ModalService } from './../../../services/modal.service';
import { GenericService } from '@app/services/generic.service';
import { take } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { DashboardActivityDummyComponent } from './dashboard-activity-dummy/dashboard-activity-dummy.component';
import { HttpClient } from '@angular/common/http';
import { ColourService } from '@app/services/colour.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard-activity',
    templateUrl: './dashboard-activity.component.html'
})

export class DashboardActivityComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardActivityDummyComponent, { static: false }) dashboardActivityDummyComponent: DashboardActivityDummyComponent;

    listen = [];

    activities = [];
    hoverActivityTime;

    hoverActivityAppearTimer;


    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        public colourService: ColourService,
        public router: Router,
        public genericService: GenericService,
        public modalService: ModalService,
        public projectProblemDataService: ProjectProblemDataService,
        public changeDetectorRef: ChangeDetectorRef,
    ) {
        // this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
        //     this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
        //     this.updateView();
        // }));
    }

    hoverActivityItem(activity) {
        this.hoverActivityTime = activity.hoverTimeLabel;

        this.hoverActivityAppearTimer = setTimeout(() => {
            const target = document.getElementById('activity-' + activity.id);
            if (target) {
                document.getElementById('activity-time-hover').style.left = 'calc(' + target.getBoundingClientRect().left + 'px' + ' - 100px' + ')';
                document.getElementById('activity-time-hover').style.top = 'calc(' + target.getBoundingClientRect().top + 'px' + ' + ' + target.getBoundingClientRect().height / 2 + 'px' + ')';
                document.getElementById('activity-time-hover').style.opacity = '1';
            }
        }, 1000);
    }

    unhoverActivityItem() {
        clearTimeout(this.hoverActivityAppearTimer);
        this.hoverActivityAppearTimer = null;
        document.getElementById('activity-time-hover').style.opacity = '0';
    }

    clickOnActivity(activityData) {
        // pp
        if (activityData['projectProblemStatus'] == this.globals.dashboardActivityProjectProblemConstants['THINK'] || activityData['projectProblemStatus'] == this.globals.dashboardActivityProjectProblemConstants['THINK_AUTO']) {
            if (!this.globals.collaboratorModeEnabled) {
                this.router.navigateByUrl('/projects/projectView/' + activityData['projectProblemId']);
            }
        }
        // driver report
        else if (activityData['driverId']) {
            // get required pp data to generate report & open driver report modal
            this.projectProblemDataService.projectProblemId = activityData['projectProblemId'];
            this.projectProblemDataService.loadProjectProblemData().subscribe(projectProblemData => {
                this.projectProblemDataService.projectProblemData = projectProblemData;

                // Debug
                // console.log(this.projectProblemDataService.stopPointsArray);
                console.log("Driver (%d) Report for PP '%d'", activityData['driverId'], this.projectProblemDataService.projectProblemId);

                this.genericService.openDriverReportModal({
                    driverId: activityData['driverId'],
                    departureTime: activityData['projectProblemDepartureDatetime'],
                    projectProblemId: activityData['projectProblemId']
                });
            });
        }
        // project report
        else if (activityData['projectProblemStatus'] == this.globals.dashboardActivityProjectProblemConstants['COMPLETED']) {
            if (!this.globals.collaboratorModeEnabled) {
                this.genericService.openProjectProblemReportModal({
                    projectProblemId: activityData['projectProblemId'],
                    projectName: activityData['projectName'],
                    departureTime: activityData['projectProblemDatetime']
                });
            }
        }
        // invoice / pod (+ go to collaborator in courier)
        else if (activityData['collaboratorId']) {
            if (!this.globals.collaboratorModeEnabled) {
                this.router.navigateByUrl('/customerCollaborators/collaboratorView/' + activityData['collaboratorId']);
            } else {
                if (activityData['type'] == 'invoice') {
                    this.http.get('api/v1/partner-invoices').subscribe(res => {
                        res['items']['invoices'].forEach(invoice => {
                            if (invoice['id'] == activityData['invoiceId']) {
                                this.modalService.openInvoiceModal(invoice);
                            }
                        });
                    });
                } else if (activityData['type'] == 'payment') {
                    this.http.get('api/v1/partner-pay-on-delivery').subscribe(res => {
                        res['items']['pay_on_delivery_payments'].forEach(payment => {
                            if (payment['id'] == activityData['paymentId']) {
                                this.modalService.openPaymentModal(payment);
                            }
                        });
                    });
                    // this.modalService.openPaymentModal(activityData['paymentId']);
                }
            }
        }
        // shipments
        else if (activityData['type'] == 'shipments' && this.globals.collaboratorModeEnabled) {
            switch (activityData['status']) {
                case this.globals.dashboardActivityCollaboratorShipmentsConstants['THINK']:
                    this.router.navigateByUrl('collaboratorOverview');
                    break;
                case this.globals.dashboardActivityCollaboratorShipmentsConstants['ACCEPTED']:
                    if (this.globals.foodModeEnabled) {
                        this.router.navigateByUrl('collaboratorOverview');
                    } else {
                        this.router.navigateByUrl('newShipmentsView');
                    }
                    break;
            }
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
