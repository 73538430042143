// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-field {
  margin-bottom: 0;
}
.custom-input-field .ng-select-container {
  visibility: hidden;
  position: absolute !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZlaGljbGUtZm9ybS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGdCQUFBO0FBQ0o7QUFDSTtFQUNJLGtCQUFBO0VBQ0EsNkJBQUE7QUFDUiIsImZpbGUiOiJ2ZWhpY2xlLWZvcm0uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY3VzdG9tLWlucHV0LWZpZWxkIHtcbiAgICBtYXJnaW4tYm90dG9tOiAwO1xuXG4gICAgLm5nLXNlbGVjdC1jb250YWluZXIge1xuICAgICAgICB2aXNpYmlsaXR5OiBoaWRkZW47XG4gICAgICAgIHBvc2l0aW9uOiBhYnNvbHV0ZSAhaW1wb3J0YW50O1xuICAgIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/forms/vehicle-form/vehicle-form.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,kBAAA;EACA,6BAAA;AACR;AACA,ggBAAggB","sourcesContent":[".custom-input-field {\n    margin-bottom: 0;\n\n    .ng-select-container {\n        visibility: hidden;\n        position: absolute !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
