import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PackagingModalComponent } from '@app/modals/packaging-modal/packaging-modal.component';
import { GridsService } from '@app/services/grids.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-packaging-grid',
    templateUrl: './packaging-grid.component.html',
    styleUrls: ['./packaging-grid.component.scss']
})
export class PackagingGridComponent implements OnInit, OnDestroy {

    @ViewChild(PackagingModalComponent, { static: false }) packagingModalComponent: PackagingModalComponent;

    listen = [];
    searchString: String = '';
    gridApi;
    nameHeader;
    defaultValueHeader;
    phoneHeader;
    gridColumnApi;
    domLayout = 'autoHeight';
    packagingGridDataArray = [];
    rowData: any;
    columnDefs;

    dynamicFieldsNamesToLabels = {};


    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private settingsService: SettingsService,
        public gridsService: GridsService,
    ) {
        this.listen.push(this.settingsService.updateDynamicFieldsGridListen().subscribe(() => {
            this.getDynamicFields().pipe(take(1)).subscribe(response => {
                this.dynamicFieldsNamesToLabels = {};
                response['items'].forEach(field => {
                    this.dynamicFieldsNamesToLabels[field['importDynamicField']['identifier']] = field['importDynamicField']['label'];
                });
                this.setDynamicFieldsGridData();
            });
        }));
    }

    getDynamicFields() {
        return this.http.get('api/v1/import-dynamic-fields');
    }

    loadDynamicFields() {
        this.getDynamicFields().pipe(take(1)).subscribe(response => {
            this.dynamicFieldsNamesToLabels = {};
            response['items'].forEach(field => {
                this.dynamicFieldsNamesToLabels[field['importDynamicField']['identifier']] = field['importDynamicField']['label'];
            });
            this.setDynamicFieldsGridData();
        });
    }

    setDynamicFieldsGridData() {
        this.http.get('api/v1/company/import-rules').pipe(take(1)).subscribe(response => {
            const self = this;
            let gridObject = {};
            self.packagingGridDataArray = [];
            if (response['items'].length) {
                response['items'].forEach(data => {
                    if (data.importRule.rule.trigger.expression.operator_type === this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE']) {
                        let first, second;
                        data.importRule.rule.trigger.expression.operands.forEach(operand => {
                            if (operand.order === 0) {
                                first = operand;
                            } else {
                                second = operand;
                            }
                        });
                        if (
                            first.value_type === this.globals.ruleValueTypeConstants['STRING'] &&
                            second.value === this.globals.stopPointImportFieldConfigs['STOP_POINT_SUPPLIER']['constant_name'] &&
                            data.importRule.rule.actions[0].expression
                        ) {
                            if (data.importRule.rule.actions[0].expression.operator_type === this.globals.ruleExpressionOperatorTypeConstants['ADDITION']) {
                                let string = '';
                                data.importRule.rule.actions[0].expression.operands.forEach(actionOperand => {
                                    if (actionOperand.expression.operator_type === this.globals.ruleExpressionOperatorTypeConstants['MULTIPLY']) {
                                        let label = '', value = '';
                                        actionOperand.expression.operands.forEach(operand => {
                                            if (operand.order === 0) {
                                                value = operand.value;
                                            } else {
                                                label = self.dynamicFieldsNamesToLabels[operand.value];
                                            }
                                        });
                                        if (label && value) {
                                            if (string !== '') { string += ', '; }
                                            string += label + ': ' + value;
                                        }
                                    }
                                });
                                gridObject = {
                                    rule: data,
                                    name: first.value,
                                    defaultValue: string,
                                };
                                self.packagingGridDataArray.push(gridObject);
                            }
                        }
                    }
                });
            }

            if (!self.packagingGridDataArray.length) {
                const noData = { noDataText: 'No data' }
                self.packagingGridDataArray.push(noData);
            }

            self.rowData = of(self.packagingGridDataArray);
        });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    rowClicked(event) {
        if (!event.data.noDataText && this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN']) {
            this.packagingModalComponent.loadDynamicField(event.data.rule);
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameHeader = res; }));
        this.listen.push(this.translate.get('SETTINGS.DEFAULT_VALUE').subscribe((res: string) => { this.defaultValueHeader = res; }));

        this.columnDefs = [
            { headerName: this.nameHeader, field: 'name', width: this.gridsService.widthCalculatorSettings(30) },
            { headerName: this.defaultValueHeader, field: 'defaultValue', width: this.gridsService.widthCalculatorSettings(70) },
        ];
        this.loadDynamicFields();
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
