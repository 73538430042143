import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { GridsService } from '@app/services/grids.service';

@Component({
  selector: 'app-team-member-grid',
  templateUrl: './team-member-grid.component.html',
  styleUrls: ['./../grids.scss']
})
export class TeamMemberGridComponent implements OnInit, OnDestroy {

  listen = [];

  columnDefs;
  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  membersDataArray = [];
  rowData: any;

  searchTextChanged = new Subject<string>();
  searchString: String = '';

  nameTitle;
  roleTitle;
  groupTitle;
  emailTitle;
  adminLabel;
  advancedLabel;
  viewerLabel;
  standardLabel;


  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private modalService: ModalService,
    public globals: Globals,
    private modalGridService: ModalGridService,
    public gridsService: GridsService,
  ) {
    this.listen.push(this.modalGridService.listenUpdateMemberGrid().subscribe((response) => {
      this.setMembersGridData();
    }));
  }

  setMembersGridData() {
    const self = this;
    const searchQuery = `?searchQuery=${this.searchString}`;
    this.http.get('api/v1/office-members' + searchQuery).pipe(take(1)).subscribe(response => {
      self.membersDataArray = [];
      let roles = '', gridObject = {};
      if (response['items'].length) {
        response['items'].forEach(memberData => {
          const userProfile = memberData.userProfile;
          roles = '';
          userProfile.roles.forEach(roleConstant => {
            if (roles === '') {
              roles = self.globals.userProfileRolesLabels[roleConstant];
            } else {
              roles = ', ' + self.globals.userProfileRolesLabels[roleConstant];
            }
          });

          // access group
          let accessGroupLabel;
          if (userProfile.access_group) {
            switch (userProfile.access_group) {
              case this.globals.teamMemberTypeConstants['ADMIN']:
                accessGroupLabel = this.adminLabel;
                break;
              case this.globals.teamMemberTypeConstants['ADVANCED']:
                accessGroupLabel = this.advancedLabel;
                break;
              case this.globals.teamMemberTypeConstants['VIEWER']:
                accessGroupLabel = this.viewerLabel;
                break;
              case this.globals.teamMemberTypeConstants['STANDARD']:
                accessGroupLabel = this.standardLabel;
                break;
            }
          }

          gridObject = {
            id: userProfile.id,
            name: userProfile.name,
            role: roles,
            accessGroup: accessGroupLabel,
            email: userProfile.email
          };
          self.membersDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' }
        self.membersDataArray.push(noData);
      }
      self.rowData = of(self.membersDataArray);
    },
      (error) => {
        console.error(error);
      }
    );
  }

  newButtonClicked(): void {
    this.modalService.filter('member', 0, 0);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) {
    if (!event.data.noDataText && this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN']) {
      this.modalService.filter('member', 1, event.data.id);
    }
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ROLE').subscribe((res: string) => { this.roleTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.PRIVILEGES').subscribe((res: string) => { this.groupTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.EMAIL').subscribe((res: string) => { this.emailTitle = res; }));
    this.listen.push(this.translate.get('MEMBERS.ADMIN').subscribe((res: string) => { this.adminLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.ADVANCED').subscribe((res: string) => { this.advancedLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.VIEWER').subscribe((res: string) => { this.viewerLabel = res; }));
    this.listen.push(this.translate.get('MEMBERS.STANDARD').subscribe((res: string) => { this.standardLabel = res; }));

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        checkboxSelection: false,
        width: this.gridsService.widthCalculator(35)
      },
      {
        headerName: this.groupTitle,
        field: 'accessGroup',
        width: this.gridsService.widthCalculator(30)
      },
      {
        headerName: this.emailTitle,
        field: 'email',
        width: this.gridsService.widthCalculator(35)
      },
    ];

    this.setMembersGridData();
  }

  ngOnInit(): void {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
        this.setMembersGridData();
      });

  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}

interface EventObject {
  event: string;
  value: any;
}
