import { Component, ViewChild, OnInit, OnDestroy, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@app/services/settings.service';
import { of } from 'rxjs';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { map, take, tap } from 'rxjs/operators';
import { Globals } from '@app/services/globals';
import { LmDepotsService } from '@app/api/services/depots-services.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LmNotificationService } from '@app/core/services/notification.service';
import { SettingsDepotsModalComponent } from '@app/settings/depots/depots-modal/settings-depots-modal.component';
import { GridApi } from '@ag-grid-community/core';

@UntilDestroy()
@Component({
  selector: 'app-depots-grid',
  templateUrl: './depots-grid.component.html',
  styleUrls: ['./depots-grid.component.scss']
})
export class DepotsGridComponent implements OnInit, OnDestroy {
  @ViewChild('depotsGrid', { static: false }) depotsGrid: AgGridAngular;
  listen = [];
  searchString: String = '';
  gridApi : GridApi;
  titleHeader;
  addressHeader;
  phoneHeader;
  gridColumnApi;
  depotsDataArray = [];
  rowData: any;
  columnDefs;
  googlePlaceHeader = "Google Place ID"

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private settingsService: SettingsService,
    public gridsService: GridsService,
    private addressService: AddressService,
    private globals: Globals,
    private _depotsSvc: LmDepotsService,
    private _notificationSvc: LmNotificationService,
    private _injector: Injector
  ) {
    this.listen.push(this.settingsService.updateDepotsGridListen().subscribe(() => {
      this.setDepotsGridData();
    }));
  }

  setDepotsGridData() {
    this.http.get('api/v1/company/depots').pipe(take(1)).subscribe(response => {
      let depot, gridObject = {};
      this.depotsDataArray = [];
      if (response['items'].length) {
        this.globals.updateDepotsExternally(response['items']);
        response['items'].forEach(depotData => {
          depot = depotData.companyDepot;
          gridObject = {
            title: depot.address.label,
            telephone: depot.telephone,
            address: this.addressService.getAddressLabel(depot.address),
            google_place_id: depot.google_place_id,
            data: depot
          };
          this.depotsDataArray.push(gridObject);
        });
      } else {
        const noData = { noDataText: 'No data' };
        this.depotsDataArray.push(noData);
      }
      this.rowData = of(this.depotsDataArray);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  showDepotModal(_item?) {
    this._notificationSvc.showDialog<boolean>({ 
        componentType: SettingsDepotsModalComponent,   
        injector: this._injector, 
        data: {trigger: this, globals: this.globals, item: _item} 
    }).subscribe(_=> this.setDepotsGridData())
}

  rowClicked(event) {
    if (!event.data.noDataText && this.globals.userCanEditDepots) {
      const {data:{data:{id}}} = event
      
      return this._depotsSvc.apiDepotSingleGet({id:id})
        .pipe(
          map(res => this.showDepotModal((<any>res).item?.companyDepot)),
          untilDestroyed(this)
        ).subscribe();
    }
  }

  onFirstDataRendered(params) { }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.TITLE').subscribe((res: string) => { this.titleHeader = res; }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressHeader = res; }));
    this.listen.push(this.translate.get('GENERIC.PHONE').subscribe((res: string) => { this.phoneHeader = res; }));
    this.columnDefs = [
      { headerName: this.titleHeader, field: 'title', sortable: true, width: this.gridsService.widthCalculatorSettings(25) },
      { headerName: this.addressHeader, field: 'address', sortable: true, width: this.gridsService.widthCalculatorSettings(30) },
      { headerName: this.phoneHeader, field: 'telephone', sortable: true, width: this.gridsService.widthCalculatorSettings(20) },
      { headerName: this.googlePlaceHeader, field: 'google_place_id', sortable: true, width: this.gridsService.widthCalculatorSettings(25) }
    ];
    this.setDepotsGridData();
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
