import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-image-voucher-print',
    templateUrl: './image-voucher-print.component.html',
    styleUrls: ['./image-voucher-print.component.scss']
})
export class ImageVoucherPrintComponent implements OnInit, AfterViewInit {

    listen = [];

    vouchers = [];
    logoSrc = 'data:image/png;base64,LOGO_HASH';
    testImageBase64 = '';
    requestLimit = 12;

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        private http: HttpClient,
    ) { }

    loadVouchers(vouchers) {
        let testImageBase64 = '';
        vouchers.forEach(voucherImage => {
            if (voucherImage.voucher_blob && voucherImage.items_count) {
                for (let i = 0; i < Number(voucherImage.items_count); i++) {
                    const base64 = this.logoSrc.replace('LOGO_HASH', voucherImage.voucher_blob);
                    this.vouchers.push({ base64: base64 });
                    if (!testImageBase64) {
                        testImageBase64 = voucherImage.voucher_blob;
                    }
                }
            }
        });
        this.testImageBase64 = this.logoSrc.replace('LOGO_HASH', testImageBase64);
    }

    onImageLoad() {
        var comm100 = document.querySelector('[id^="comm100-button"]');
        comm100.classList.add('hidden');
        var comm1002 = document.querySelector('[id^="comm100-container"]');
        comm1002.classList.add('hidden');
        setTimeout(() => {
            var comm100 = document.querySelector('[id^="comm100-button"]');
            comm100.classList.add('hidden');
            var comm1002 = document.querySelector('[id^="comm100-container"]');
            comm1002.classList.add('hidden');
            window.print();
        }, 100);
    }

    openPdfTab(pdfBase64) {
        // let pdfWindow = window.open("");
        // pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + pdfBase64 + "'></iframe>")
        // pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfBase64) + "'></iframe>");
        const linkSource = `data:application/pdf;base64,${pdfBase64}`;
        const downloadLink = document.createElement("a");
        const fileName = "acs_shipment_list.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    getAllVoucherImages(stopPointIds) {
        let vouchers = [];
        this.getVoucherImages(stopPointIds).pipe(take(1)).subscribe(firstResponse => {
            if (firstResponse['items'] && firstResponse['itemsMeta']) {
                if (firstResponse['items']['company_stop_point_systemic_vouchers']) {
                    vouchers = vouchers.concat(firstResponse['items']['company_stop_point_systemic_vouchers']);
                    let totalPages = firstResponse['itemsMeta'].pagesCount;
                    if (totalPages) {
                        let endRequestCounter = 1;
                        if (totalPages > 1) {
                            for (let page = 1; page < totalPages; page++) {
                                if (page <= this.requestLimit) {
                                    this.getVoucherImages(stopPointIds).pipe(take(1)).subscribe(response => {
                                        if (endRequestCounter <= totalPages) {
                                            vouchers = vouchers.concat(response['items']['company_stop_point_systemic_vouchers']);
                                            endRequestCounter++;
                                        }
                                        if (endRequestCounter >= totalPages || endRequestCounter === this.requestLimit) {
                                            this.loadVouchers(vouchers);
                                        }
                                    });
                                }
                            }
                        } else {
                            this.loadVouchers(vouchers);
                        }
                    } else {
                        this.loadVouchers(vouchers);
                    }
                }
                if (firstResponse['items']['systemic_courier_lists']) {
                    firstResponse['items']['systemic_courier_lists'].forEach(list => {
                        this.openPdfTab(list);
                    });
                }
            }
        });
    }

    getVoucherImages(voucherIds) {
        let url = 'api/v1/voucher/print-systemic-courier-voucher-index';
        if (this.globals.collaboratorModeEnabled) {
            url = 'api/v1/partner/voucher/partner-print-systemic-courier-voucher-index';
        }
        const data = {
            voucher_ids: voucherIds
        };
        return this.http.post(url, data);
    }

    ngAfterViewInit() { }

    ngOnInit() {
        var comm100 = document.querySelector('[id^="comm100-button"]');
        if (comm100) {
            comm100.classList.add('hidden');
        }
        var comm1002 = document.querySelector('[id^="comm100-container"]');
        if (comm1002) {
            comm1002.classList.add('hidden');
        }

        const stopPointIdsWithImageVoucherString = localStorage.getItem('stopPointIdsWithImageVoucher');
        if (stopPointIdsWithImageVoucherString) {
            const stopPointIdsWithImageVoucher = JSON.parse(stopPointIdsWithImageVoucherString);
            if (stopPointIdsWithImageVoucher.length) {
                this.getAllVoucherImages(stopPointIdsWithImageVoucher);
            }
        }
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
