import { Component, Inject } from '@angular/core';
import { LmNotificationContext, LmSeverity } from '../../../model/toast';

@Component({
  template: `
    <div class="p-toast-message">
      <span
        class="p-toast-icon pi"
        [ngClass]="{
          'pi-info-circle': config.data.severity == 'info',
          'pi-exclamation-triangle': config.data.severity == 'warn',
          'pi-times-circle': config.data.severity == 'error',
          'pi-check': config.data.severity == 'success'
        }"
      ></span>

      <div class="p-toast-message-text-content">
        <h5>{{ config.data.summary }}</h5>
        <p class=" lm-txt-color-body">{{ config.data.detail }}</p>
      </div>
    </div>
  `,
  styles:[`
    .p-toast-message{box-shadow:none}
    .p-toast-icon.pi{font-weight:700;font-size:1.6rem}
  `]
})
export class LmToastDefaultContentComponent {
  constructor(public config: LmNotificationContext<{ summary: string; detail: string; severity: LmSeverity }>) {
  }
}
