import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal.service';
import { ClientPortalChatModalDummyComponent } from './client-portal-chat-modal-dummy/client-portal-chat-modal-dummy.component';
import { createPicker } from 'picmo';
import ConnectyCube from 'connectycube';
import moment from 'moment';

@Component({
    selector: 'app-client-portal-chat-modal',
    templateUrl: './client-portal-chat-modal.component.html',
    styleUrls: ['./client-portal-chat-modal.component.scss']
})
export class ClientPortalChatModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(ClientPortalChatModalDummyComponent, { static: false }) clientPortalChatModalDummyComponent: ClientPortalChatModalDummyComponent;

    listen = [];

    messages = [];
    userId;
    username;
    fullName;
    avatarBase64;
    isOpponentTypingMessage;
    opponentId;
    callerName;

    hasLoggedIn; // used to enable/disable ability to open the chat modal

    activeChannelInfo = {};
    isBeingCalledVideo = false;
    isBeingCalledAudio = false;
    isInVideoCall = false;
    isInAudioCall = false;
    isMuteVideo = false;
    isMuteMicrophone = false;
    isOpponentVideoMuted = false;
    callOpponentId;

    callWarningAlertText;
    callStartedFeedbackMsg;
    callEndedFeedbackMsg;
    callAcceptedFeedbackMsg;
    callRejectedFeedbackMsg;

    emojiPicker;
    session;
    selectedColor = '#00aeba';

    chatMockData = {
        username: 'user1',
        userId: 6056869, // e.g. user1's id (the client viewing Live Tracking)
        opponentId: 6056874, // e.g. user2's id
        opponentName: 'Lorem Ipsumakis', // user2's name
        opponentInitials: 'LI', // these will be calculated when chat data is loaded
        dialogId: '62da6f1c9439050032919355', // the two users' dialog id
        avatarBase64: null, // the avatar (base64) of the opponent
    };

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        public router: Router,
        public http: HttpClient,
    ) { }

    toggleChatModal() {
        if (this.hasLoggedIn) {
            this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.toggle('open');
            this.clientPortalChatModalDummyComponent.modalButtons.nativeElement.classList.toggle('open');

            // if modal was closed, then set the first chat as the active one
            if (this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
                // get messages on modal open (& mark them as read, too)
                this.markMessagesAsRead();
                this.getAndShowMessages();
            }
        } else if (!this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
            this.CCInit();
            this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.add('open');
        } else {
            this.clientPortalChatModalDummyComponent.modalButtons.nativeElement.classList.toggle('open');
        }
    }

    openModal() {
        if (!this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
            this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.add('open');
        }
    }

    closeModal() {
        if (this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
            this.clientPortalChatModalDummyComponent.modal.nativeElement.classList.remove('open');
        }
    }

    sendMessage(messageTextManual = null) {
        // remove 'is typing' status
        ConnectyCube.chat.sendIsStopTypingStatus(this.chatMockData.opponentId);

        let messageText;
        if (messageTextManual) {
            messageText = messageTextManual;
        } else {
            messageText = (<HTMLInputElement>document.getElementById('chat-message-input')).value;
        }

        const stringSpacesLength = messageText.trim().length;

        if (stringSpacesLength !== 0 && messageText !== '') {
            const message: Message = {
                messageId: null,
                senderName: this.fullName,
                initials: this.calculateNameInitials(this.fullName),
                messageText: messageText,
                isSystemMessage: false,
                time: moment().format('HH:mm'),
                messageStatus: 'sent',
                sentByMe: true,
                avatarBase64: this.avatarBase64 ?? null
            };

            this.CCSendMessage(this.chatMockData.opponentId, this.chatMockData.dialogId, messageText);

            if (!messageTextManual) {
                this.messages.push(message);
                this.messages = [...this.messages];
            }

            // clear message text area & scroll to bottom
            setTimeout(() => {
                (<HTMLInputElement>document.getElementById('chat-message-input')).value = '';
                document.getElementById('chat-channel-body').scrollTop = document.getElementById('chat-channel-body').scrollHeight;
            }, 50);
        }
    }

    CCSendMessage(opponentId, dialogId, messageText) {
        const message = {
            type: 'chat', // 'chat' is peer-to-peer chat type
            body: messageText,
            extension: {
                save_to_history: 1,
                dialog_id: dialogId
            },
            markable: 1
        };

        // send message to connectycube's be
        ConnectyCube.chat.send(opponentId, message);
    }

    toggleEmojiPicker() {
        if (document.getElementById('emojis-container').style.display != 'block') {
            // emojis load & select event
            this.emojiPicker = createPicker({
                rootElement: document.getElementById('emojis-container'),
                showCategoryTabs: false,
                showRecents: false,
                showSearch: false,
                showPreview: false,
                initialCategory: 'smileys-emotion'
            });
            this.emojiPicker.addEventListener('emoji:select', selection => {
                (<HTMLInputElement>document.getElementById('chat-message-input')).value += selection.emoji;
            });

            document.getElementById('emojis-container').style.display = 'block';
            document.getElementById('emojis-container').style.top = document.getElementById('chat-emoji-select-button').getBoundingClientRect().top - 270 + 'px';
            document.getElementById('emojis-container').style.left = document.getElementById('chat-emoji-select-button').getBoundingClientRect().left - 300 + 'px';
        } else {
            this.closeEmojiPicker();
        }
    }

    closeEmojiPicker() {
        document.getElementById('emojis-container').style.display = 'none';
        this.emojiPicker.destroy();
    }

    isTypingUpdate() {
        if ((<HTMLInputElement>document.getElementById('chat-message-input')).value == '') {
            ConnectyCube.chat.sendIsStopTypingStatus(this.chatMockData.opponentId);
        } else {
            ConnectyCube.chat.sendIsTypingStatus(this.chatMockData.opponentId);
        }
    }

    markMessagesAsRead() {
        // const params = {
        //     messageId: this.messages[this.messages.length - 1].messageId,
        //     userId: this.chatMockData.userId,
        //     dialogId: this.chatMockData.dialogId,
        // };

        // ConnectyCube.chat.sendReadStatus(params);
        ConnectyCube.chat.sendSystemMessage(this.chatMockData.opponentId, { body: "MESSAGE_READ_CLIENT_PORTAL", extension: {} });
    }

    getAndShowMessages() {
        console.warn('Getting messages....');
        const newMessages = [];

        const params = {
            chat_dialog_id: this.chatMockData.dialogId,
            sort_desc: 'date_sent',
            limit: 60,
            skip: 0,
        };

        ConnectyCube.chat.message
            .list(params)
            .then((messages) => {
                console.log(messages['items'])
                messages['items'].forEach(currMessage => {
                    let sentByMeValue, senderNameValue = '-', avatarValue;
                    // msg sent by user
                    if (this.chatMockData.userId == currMessage.sender_id) {
                        senderNameValue = null; // doesn't have a name in Live Tracking
                        sentByMeValue = true;
                    }
                    // msg sent by opponent
                    else {
                        senderNameValue = this.chatMockData.opponentName;
                        sentByMeValue = false;
                    }

                    let messageStatusValue = 'sent';
                    if (currMessage.delivered_ids.includes(this.chatMockData.opponentId)) {
                        messageStatusValue = 'delivered';
                    }
                    if (currMessage.read_ids.includes(this.chatMockData.opponentId)) {
                        messageStatusValue = 'read';
                    }

                    // replace call codes in messages w/ the correct label
                    let messageTextValue = currMessage.message;
                    let isSystemMessageValue = false;
                    switch (currMessage.message) {
                        case 'CONNECTYCUBE_START_CALL':
                            messageTextValue = this.callStartedFeedbackMsg;
                            isSystemMessageValue = true;
                            break;
                        case 'CONNECTYCUBE_END_CALL':
                            messageTextValue = this.callEndedFeedbackMsg;
                            isSystemMessageValue = true;
                            break;
                        case 'CONNECTYCUBE_ACCEPT_CALL':
                            messageTextValue = this.callAcceptedFeedbackMsg;
                            isSystemMessageValue = true;
                            break;
                        case 'CONNECTYCUBE_REJECT_CALL':
                            messageTextValue = this.callRejectedFeedbackMsg;
                            isSystemMessageValue = true;
                            break;
                    }

                    // check for hyperlink (if link is found keep only that)
                    let sentByHyperlinkClass = sentByMeValue ? 'link-sent-by-user' : 'link-sent-by-opponent';
                    messageTextValue = messageTextValue.replace(
                        /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
                        '<a href="$1" target="blank_" class="' + sentByHyperlinkClass + '">$1</a>'
                    );

                    if (messageTextValue.search('<a href') !== -1) {
                        messageTextValue = messageTextValue.substring(messageTextValue.indexOf('<a href'));
                    }

                    const newMessage: Message = {
                        messageId: currMessage._id,
                        senderName: senderNameValue,
                        initials: this.calculateNameInitials(senderNameValue),
                        messageText: messageTextValue,
                        isSystemMessage: isSystemMessageValue,
                        time: moment.unix(currMessage.date_sent).format('HH:mm'),
                        messageStatus: messageStatusValue,
                        sentByMe: sentByMeValue,
                        avatarBase64: avatarValue
                    };

                    newMessages.unshift(newMessage);
                });

                this.messages = [...newMessages];
                console.log(this.messages)

                // initial chat scroll to bottom on chat open
                setTimeout(() => {
                    if (document.getElementById('chat-channel-body')) {
                        document.getElementById('chat-channel-body').scrollTop = document.getElementById('chat-channel-body').scrollHeight;
                    }
                }, 600);
            })
            .catch((error) => { });
    }

    // createVideoSession() {
    //     if (!this.isInAudioCall && !this.isInVideoCall) {
    //         const calleesIds = [this.activeChannelInfo['opponentId']]; // User's ids
    //         const sessionType = ConnectyCube.videochat.CallType.VIDEO; // AUDIO is also possible
    //         const additionalOptions = {};
    //         this.session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);

    //         const mediaParams = {
    //             audio: true,
    //             video: true
    //         };

    //         this.sendMessage(this.globals.chatCallStatusCodes['START_CALL'])
    //         this.session
    //             .getUserMedia(mediaParams)
    //             .then((localStream) => {
    //                 this.session.call(extension, (error) => {
    //                     this.callOpponentId = this.activeChannelInfo['opponentId'];
    //                     console.log(this.callStartedFeedbackMsg)
    //                 });
    //             })
    //             .catch((error) => { console.log(error); });

    //         const extension = {};
    //     }
    //     // if in call then alert the user that the current call is going to end & if they confirm then end the call and perform the next call
    //     else {
    //         const confirmCall = confirm(this.callWarningAlertText);
    //         if (confirmCall) {
    //             this.endCall();
    //             this.createVideoSession();
    //         }
    //     }
    // }

    // createAudioSession() {
    //     if (!this.isInAudioCall && !this.isInVideoCall) {
    //         const calleesIds = [this.activeChannelInfo['opponentId']]; // User's ids
    //         const sessionType = ConnectyCube.videochat.CallType.AUDIO;
    //         const additionalOptions = {};
    //         this.session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);

    //         const mediaParams = {
    //             audio: true,
    //             video: false
    //         };

    //         this.sendMessage(this.globals.chatCallStatusCodes['START_CALL']);
    //         this.session
    //             .getUserMedia(mediaParams)
    //             .then((localStream) => {
    //                 this.session.call(extension, (error) => {
    //                     this.callOpponentId = this.activeChannelInfo['opponentId'];
    //                     this.isOpponentVideoMuted = true;
    //                     console.log(this.callStartedFeedbackMsg)
    //                 });
    //             })
    //             .catch((error) => { console.log(error); });

    //         const extension = {};
    //     }
    //     // if in call then alert the user that the current call is going to end & if they confirm then end the call and perform the next call
    //     else {
    //         const confirmCall = confirm(this.callWarningAlertText);
    //         if (confirmCall) {
    //             this.endCall();
    //             this.createAudioSession();
    //         }
    //     }
    // }

    acceptCall() {
        if (!this.isInAudioCall && !this.isInVideoCall) {
            const mediaParams = {
                audio: true,
                video: true
            };
            this.session
                .getUserMedia(mediaParams)
                .then((localStream) => {
                    const extension = {};
                    this.session.accept(extension);
                    this.isBeingCalledVideo = false;
                    this.isBeingCalledAudio = false;

                    // 1: video, 2: audio
                    if (this.session.callType == 1) {
                        this.isInVideoCall = true;
                    } else {
                        this.isInAudioCall = true;
                        this.isOpponentVideoMuted = true;
                    }

                    this.callOpponentId = this.session.initiatorID;
                    this.sendMessage('CONNECTYCUBE_ACCEPT_CALL');

                    // // find dialog id via call initiator id & set as active
                    // this.allChannels.forEach(channel => {
                    //     if (channel.userId == this.callOpponentId && channel.chatType == 3) {
                    //         this.openChat(channel.dialogId);
                    //     }
                    // });
                })
                .catch((error) => { console.log(error); });
        }
    }

    rejectCall() {
        const extension = {};
        this.session.reject(extension);
        this.isBeingCalledVideo = false;
        this.isBeingCalledAudio = false;
        this.sendMessage('CONNECTYCUBE_REJECT_CALL');
    }

    endCall() {
        const extension = {};
        this.session.stop(extension);
        this.callOpponentId = null;
        this.isInVideoCall = false;
        this.isInAudioCall = false;
        this.isMuteMicrophone = false;
        this.isMuteVideo = false;
        this.isOpponentVideoMuted = false;
        this.sendMessage('CONNECTYCUBE_END_CALL');
    }

    muteVideo() {
        if (this.isMuteVideo) {
            this.isMuteVideo = false;
            // this.session.localStream.getVideoTracks()[0].enabled = true;
            this.session.unmute("video");
            ConnectyCube.chat.sendSystemMessage(this.callOpponentId, { body: "VIDEO_UNMUTED", extension: {} });
        } else {
            this.isMuteVideo = true;
            // this.session.localStream.getVideoTracks()[0].enabled = false
            this.session.mute("video");
            ConnectyCube.chat.sendSystemMessage(this.callOpponentId, { body: "VIDEO_MUTED", extension: {} });
        }
    }

    muteMicrophone() {
        if (this.isMuteMicrophone) {
            this.isMuteMicrophone = false;
            this.session.localStream.getAudioTracks()[0].enabled = true;
        } else {
            this.isMuteMicrophone = true;
            this.session.localStream.getAudioTracks()[0].enabled = false;
        }
    }

    CCInit() {
        const CREDENTIALS = {
            appId: 6397,
            authKey: "76atLgmkfWjC3nh",
            authSecret: "DyRHym6VfAwgk7M",
        };
        const CONFIG = {
            debug: { mode: 0 }, // enable DEBUG mode (mode 0 is logs off, mode 1 -> console.log())
            chat: {
                streamManagement: {
                    enable: true
                }
            }
        };

        ConnectyCube.init(CREDENTIALS, CONFIG);

        ConnectyCube.createSession()
            .then((session) => {
                // login after creation of session
                setTimeout(() => {
                    this.CCLogin();
                }, 1000);
            })
            .catch((error) => { console.log(error); });
    }

    CCLogin() {
        this.username = this.chatMockData.username;
        const pass = '12345678';
        // this.username = 'user2';
        // const pass = '12345678';
        // this.username = 'user3';
        // const pass = '12345678';

        this.http.get('api/unregistered/v1/chat/chat-auth').subscribe(res => {
            console.log(res)
            const userCredentials = { login: this.username, password: pass };

            ConnectyCube.login(userCredentials)
                .then((user) => {
                    this.userId = user.id;
                    this.username = user.login;
                    this.fullName = user.full_name;
                    this.avatarBase64 = user.avatar;

                    const userCredentials = { userId: user.id, password: pass };
                    const self = this;

                    ConnectyCube.chat
                        .connect(userCredentials)
                        .then(() => {
                            // allow the user to open the chat, only after successful login
                            self.hasLoggedIn = true;

                            // ConnectyCube Listeners //
                            ConnectyCube.chat.onSystemMessageListener = function (msg) {
                                switch (msg.body) {
                                    case 'VIDEO_MUTED':
                                        self.isOpponentVideoMuted = true;
                                        break;
                                    case 'VIDEO_UNMUTED':
                                        self.isOpponentVideoMuted = false;
                                        break;
                                }
                            };

                            ConnectyCube.chat.onMessageListener = function onMessage(userId, message) {
                                console.log('*** MESSAGE RECEIVED ***')

                                // get new messages only if modal is open
                                if (self.clientPortalChatModalDummyComponent.modal.nativeElement.classList.contains('open')) {
                                    self.getAndShowMessages();
                                }
                            };

                            // the opponent must be logged in for the message to be marked as 'Delivered'
                            // since markable is 1, delivered status is set automatically by ConnectyCube's BE when they receive the message
                            ConnectyCube.chat.onDeliveredStatusListener = function (messageId, dialogId, userId) {
                                console.log('DELIVERED!');
                                self.getAndShowMessages();
                            };

                            ConnectyCube.chat.onReadStatusListener = function (messageId, dialogId, userId) {
                                console.warn('OPPONENT HAS READ THE MESSAGES!');
                                self.getAndShowMessages();
                            };

                            ConnectyCube.chat.onSentMessageCallback = function (messageLost, messageSent) { };

                            ConnectyCube.chat.onMessageTypingListener = function (isTyping, userId, dialogId) {
                                self.isOpponentTypingMessage = isTyping;

                                setTimeout(() => {
                                    if (document.getElementById('chat-channel-body')) {
                                        document.getElementById('chat-channel-body').scrollTop = document.getElementById('chat-channel-body').scrollHeight;
                                    }
                                }, 100);
                            };

                            ConnectyCube.videochat.onCallListener = (session, extension) => {
                                if (!this.isInAudioCall && !this.isInVideoCall && session.initiatorID == self.chatMockData.opponentId) {
                                    self.session = session;
                                    self.callerName = this.chatMockData.opponentName;

                                    // 1: video, 2: audio
                                    if (self.session.callType == 1) {
                                        self.isBeingCalledVideo = true;
                                    } else {
                                        self.isBeingCalledAudio = true;
                                    }

                                    // Stop call if callee hasn't picked up in 15 seconds
                                    setTimeout(() => {
                                        if (self.isBeingCalledVideo || self.isBeingCalledAudio) {
                                            self.rejectCall();
                                        }
                                    }, 15000);
                                }
                            };

                            ConnectyCube.videochat.onAcceptCallListener = function (session, userId, extension) {
                                // 1: video, 2: audio
                                console.log(session)
                                if (session.callType == 1) {
                                    self.isInAudioCall = false;
                                    self.isInVideoCall = true;
                                } else {
                                    self.isInAudioCall = true;
                                    self.isInVideoCall = false;
                                }

                                self.callOpponentId = userId;
                            };

                            ConnectyCube.videochat.onStopCallListener = (session, userId, extension) => {
                                self.callOpponentId = null;
                                self.isInVideoCall = false;
                                self.isInAudioCall = false;
                                self.isBeingCalledVideo = false;
                                self.isBeingCalledAudio = false;
                                self.isMuteMicrophone = false;
                                self.isMuteVideo = false;
                                self.isOpponentVideoMuted = false;
                            };

                            ConnectyCube.videochat.onUserNotAnswerListener = function (session, userId) {
                                self.isBeingCalledVideo = false;
                                self.isBeingCalledAudio = false;
                            };

                            ConnectyCube.videochat.onRejectCallListener = function (session, userId, extension) {
                                self.isBeingCalledVideo = false;
                                self.isBeingCalledAudio = false;
                            };

                            ConnectyCube.videochat.onRemoteStreamListener = function (session, userID, remoteStream) {
                                // attach the remote stream to DOM element
                                session.attachMediaStream('video-call', remoteStream);
                                self.callOpponentId = self.chatMockData.opponentId;
                            };
                        })
                        .catch((error) => { console.log(error); });
                })
                .catch((error) => { console.log(error); });
        });
    }

    calculateNameInitials(name) {
        if (name) {
            const array = name.split(' ');
            let initials = '';
            if (array.length) {
                if (array[0]) {
                    initials = array[0].charAt(0);
                }
                if (array[1]) {
                    initials = initials + array[1].charAt(0);
                }
            }
            return initials;
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('CHAT.CALL_WARNING').subscribe((res: string) => {
            this.callWarningAlertText = res;
        }));
        this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_STARTED').subscribe((res: string) => {
            this.callStartedFeedbackMsg = res;
        }));
        this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_ENDED').subscribe((res: string) => {
            this.callEndedFeedbackMsg = res;
        }));
        this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_ACCEPTED').subscribe((res: string) => {
            this.callAcceptedFeedbackMsg = res;
        }));
        this.listen.push(this.translate.get('CHAT.CALL_FEEDBACK_REJECTED').subscribe((res: string) => {
            this.callRejectedFeedbackMsg = res;
        }));
    }

    // make message text area enter work as expected
    ngAfterViewInit() {
        let enterButtonInterval = setInterval(() => {
            if (document.getElementById('chat-message-input')) {
                document.getElementById('chat-message-input').addEventListener('keypress', (event) => {
                    if (event.key == 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        this.sendMessage();
                    }
                });

                clearInterval(enterButtonInterval);
                enterButtonInterval = null;
            }
        }, 1000);
    }

    ngOnInit() {
        // init chat
        // this.CCInit();

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}

interface Message {
    messageId: String;
    senderName: String;
    avatarBase64?: String;
    initials: String;
    messageText: String;
    isSystemMessage: Boolean;
    time: String;
    messageStatus: String;
    sentByMe: Boolean;
}