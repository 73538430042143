import { ColourService } from '../../../services/colour.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as turf from '@turf/turf';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { MapService } from '@app/services/map.service';
import { DriverRegionRuleService } from '@app/services/driver-region-rule.service';

declare var H: any;

@Component({
    selector: 'app-shipments-history-map',
    templateUrl: './shipments-history-map.component.html',
    styleUrls: ['./shipments-history-map.component.scss']
})
export class ShipmentsHistoryMapComponent implements OnInit, AfterViewInit {
    private platform: any;
    @ViewChild('map', { static: false }) public mapElement: ElementRef;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @Output() regionDataReceived = new EventEmitter<string>();

    map;
    ui;
    behavior;

    // clickOnMap;

    depots = [];
    depotsGroup = new H.map.Group();

    constructor(
        private http: HttpClient,
        public globals: Globals,
        private mapService: MapService,
        public driverRegionRuleService: DriverRegionRuleService,
        private colourService: ColourService
    ) {
        this.platform = this.mapService.platform;
    }

    setMapBounds() {
        this.map.getViewModel().setLookAtData({ bounds: this.depotsGroup.getBoundingBox() }, true);
    }

    resetMap() {
        this.setMapBounds();
    }

    clickOnMap(event) {
        const self = this;
        const target = event.target;
        if (
            !(target instanceof H.map.DomMarker) &&
            (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')
        ) {
            if (target instanceof H.map.Marker) {
                
            } else {
                
            }
        }
    }

    initMap(depotLat, depotLon) {
        const self = this;
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 12,
                center: { lat: depotLat, lng: depotLon },
                // pixelRatio: window.devicePixelRatio || 1
            }
        );
        var provider = this.map.getBaseLayer().getProvider();
        var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
        provider.setStyle(style);
        const mapEvents = new H.mapevents.MapEvents(this.map);
        this.map.addEventListener('tap', this.clickOnMap.bind(this));
        this.behavior = new H.mapevents.Behavior(mapEvents);
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
        const mapSettings = this.ui.getControl('mapsettings');
        mapSettings.setAlignment('top-left');
        this.displayDepots();
        this.map.addObject(this.depotsGroup);
    }

    displayDepots() {
        const icon = new H.map.Icon(this.svgIconsComponent.svgDepotMarker.replace('markerColour', '#00aeba'));
        const iconBackground = new H.map.Icon(this.svgIconsComponent.svgMakerBackground);
        let coords, lat, lon, marker, markerBackground;
        this.depots.forEach(depot => {
            lat = depot['companyDepot']['address']['lat'];
            lon = depot['companyDepot']['address']['lon'];
            coords = { lat: lat, lng: lon };
            marker = new H.map.Marker(coords, { icon: icon });
            markerBackground = new H.map.Marker(coords, { icon: iconBackground });
            this.depotsGroup.addObject(markerBackground);
            this.depotsGroup.addObject(marker);
        });
        // });
    }

    ngOnInit() {

    }

    public ngAfterViewInit() {
        const self = this;
        const dataRefreshIntervalId = setInterval(dataChecker, 200);
        function dataChecker() {
            if (self.globals.depotsDataDone) {
                clearInterval(dataRefreshIntervalId);
                self.depots = self.globals.depotsArray;
                const depotLat = self.globals.currentLat;
                const depotLon = self.globals.currentLon;
                self.initMap(depotLat, depotLon);
            }
        }
    }

    ngOnDestroy() {
        this.map.removeEventListener('tap', this.clickOnMap);
        //   this.listen.forEach(element => {
        //     element.unsubscribe();
        //   });
    }
}
