import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, isObservable, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, finalize, first, take, tap } from 'rxjs/operators';

import { LM_VALIDATOR_PROVIDERS, LMValidationMessage, LmValidationMessageType, LMValidatorInfo, LMValidatorInfoResult, LMValidatorProvider } from '../../../model/validator';
import { extractPropertyPath, hasValue } from '../../../shared/utils';
import { LmLoggerService } from '../logger.service';

@UntilDestroy()
@Injectable()
export class LmModelValidationService implements OnDestroy {
  get isValid$(): Observable<boolean> {
    return this._isValid$.asObservable();
  }

  get validation$(): Observable<LMValidatorInfoResult> {
    return this._validation$.asObservable();
  }

  get resetValidation$(): Observable<LMValidatorInfoResult> {
    return this._resetValidation$.asObservable();
  }

  get resetValidations$(): Observable<void> {
    return this._resetValidations$.asObservable();
  }

  protected _availableValidators: LMValidatorInfo[] = [];
  protected _requiredFields: string[] = [];
  protected _validatorsResults: LMValidatorInfoResult[] = [];
  protected _isValid$ = new BehaviorSubject<boolean>(true);
  protected _validation$ = new Subject<LMValidatorInfoResult>();
  protected _resetValidation$ = new Subject<LMValidatorInfoResult>();
  protected _resetValidations$ = new Subject<void>();
  protected _shouldExecValidators = true;

  constructor(@Inject(LM_VALIDATOR_PROVIDERS) @Optional() protected _validatorProviders: LMValidatorProvider[], protected _loggerSvc: LmLoggerService) {}

  ngOnDestroy(): void {
    this._requiredFields = [];
    this._availableValidators = [];
    this._validatorsResults = [];
    this._isValid$.complete();
    this._validation$.complete();
  }

  suspendValidatorsExecution() {
    this._shouldExecValidators = false;
  }

  resumeValidatorsExecution() {
    this._shouldExecValidators = true;
  }

  setValid(valid: boolean): void {
    this._isValid$.next(valid);
  }

  initValidators(): void {
    if (this._validatorProviders) {
      this._validatorProviders.forEach((validatorProvider) => validatorProvider.getValidators().forEach((validator) => this.addValidator(validator)));
    }
  }

  addValidator(validator: LMValidatorInfo): void {
    const validatorIdx = this._availableValidators.findIndex((p) => p.propertyPath === validator.propertyPath);
    if (validatorIdx !== -1) return;

    this._availableValidators.push(validator);
    this.logValidator('add', validator, this._availableValidators);
  }

  execValidator(model: any, targetPath: string, dryRun = false): void {
    const target = this.getTarget(model, targetPath);
    this.execValidatorWithTarget(model, target, extractPropertyPath(targetPath), dryRun);
  }

  execValidatorWithTarget(model: any, target: any, propertyPath: string, dryRun = false): void {
    if (!this._shouldExecValidators) return;

    const validator = this._availableValidators.find((p) => p.propertyPath === propertyPath);

    if (validator) {
      this.logValidator('exec', validator);
    } else {
      this.logValidator('exec n/a', propertyPath);
      if (!dryRun) {
        this.setValid(!this.hasValidationErrors(model));
      }
      return;
    }

    /* Reset validatorResults for validator */
    const resetValidatorResults$ = of(null).pipe(
      first(),
      tap(() => {
        if (!dryRun) {
          const existingValidatorResultIdx = this._validatorsResults.findIndex((p) => p.propertyPath === propertyPath);
          if (existingValidatorResultIdx !== -1) {
            this._validatorsResults.splice(existingValidatorResultIdx, 1);
          }
        }
      })
    );

    /* Execute validator */
    const validatorResultMessages = validator.validationsFn(model, target);
    const validatorResult$ =
      !!validatorResultMessages && isObservable(validatorResultMessages) ? validatorResultMessages : of(validatorResultMessages as LMValidationMessage[]);

    resetValidatorResults$
      .pipe(
        take(1),
        untilDestroyed(this),
        concatMap(() => validatorResult$),
        tap((validationMessages) => {
          const validatorResult: LMValidatorInfoResult = { propertyPath: validator.propertyPath, isNotice: validator.isNotice, validationMessages: validationMessages };
          if (!dryRun) {
            if (validatorResult.validationMessages && validatorResult.validationMessages.length) {
              this._validatorsResults.push(validatorResult);
            }
          }
          this.logValidation('emit', validatorResult);
          this._validation$.next(validatorResult);
        }),
        catchError((err) => {
          this.logValidator('exception', err);
          return of(null);
        }),
        finalize(() => {
          if (!dryRun) {
            this.setValid(!this.hasValidationErrors(model));
          }
        })
      )
      .subscribe();
  }

  removeValidator(id: string): void {
    this.logValidator('remove', id);
    const validatorIdx = this._availableValidators.findIndex((p) => p.propertyPath === id);
    if (validatorIdx !== -1) {
      this._availableValidators.splice(validatorIdx, 1);
      this.logValidator('availableValidators', this._availableValidators);
    }
  }

  removeValidators(): void {
    this.logValidator('remove all');
    this._availableValidators = [];
  }

  addRequiredField(fieldName: string): void {
    const fieldIdx = this._requiredFields.findIndex((field) => field === fieldName);
    if (fieldIdx !== -1) return;

    this._requiredFields.push(fieldName);
    this.logRequiredField('add', fieldName, this._requiredFields);
  }

  removeRequiredField(fieldName: string): void {
    this.logRequiredField('remove', fieldName);
    const fieldIdx = this._requiredFields.findIndex((p) => p === fieldName);
    if (fieldIdx !== -1) {
      this._requiredFields.splice(fieldIdx, 1);
      this.logRequiredField('requiredFields', this._requiredFields);
    }
  }

  getValidations(): LMValidatorInfoResult[] {
    return [...this._validatorsResults];
  }

  removeValidation(id: string): void {
    this.logValidation('remove', id);

    const validator = this._availableValidators.find((p) => p.propertyPath === id);
    if (!!validator) {
      this._resetValidation$.next({ propertyPath: id, validationMessages: null, isNotice: validator.isNotice });
    }

    const validatorResultsIdx = this._validatorsResults.findIndex((p) => p.propertyPath === id);
    if (validatorResultsIdx !== -1) {
      this._validatorsResults.splice(validatorResultsIdx, 1);
      this.logValidation('validationMessages', this._validatorsResults);
    }
  }

  removeValidations(): void {
    this.logValidation('remove all');
    this._resetValidations$.next();
    this._validatorsResults = [];
  }

  hasValidationErrors(model: any): boolean {
    const hasValidationErrors = this._validatorsResults.some((p) => !!p.validationMessages && p.validationMessages.some((p) => p.messageType === LmValidationMessageType.Error));
    this.logValidation('hasErrors', hasValidationErrors);

    const requiredFieldsWithoutValue = this.getRequiredFieldsWithoutValue(model);
    this.logRequiredField('hasErrors', requiredFieldsWithoutValue);

    return hasValidationErrors || requiredFieldsWithoutValue.length > 0;
  }

  protected getTarget(model: any, targetPath: string): any {
    const properties = targetPath.split('.');

    if (properties.length > 1) {
      properties.splice(properties.length - 1, 1);
    }

    return properties.reduce((prev, curr) => prev && prev[curr], model);
  }

  protected getRequiredFieldsWithoutValue(model: any): string[] {
    /* TODO: How are we going to handle required properties for nested array paths?
     * Since after every property change, we validate all required fields validity,
     * to avoid checking each and every detail item's required props,
     * just ignore nested property if it's part of array.
     */
    var rootModelRequiredFields = this._requiredFields.filter((field) => !Array.isArray(model[field.split('.')[0]]));
    const fieldsWithoutValue = [];

    const getNestedValue = (nestedObj: any, pathArr: string[]) => {
      return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
    };

    for (let i = 0; i < rootModelRequiredFields.length; i++) {
      const field = rootModelRequiredFields[i];

      let fieldValue: any;
      if (field.split('.').length > 1) {
        fieldValue = getNestedValue(model, field.split('.'));
      } else {
        fieldValue = model[field];
      }

      if (!hasValue(fieldValue)) {
        fieldsWithoutValue.push(field);
      }
    }

    return fieldsWithoutValue;
  }

  protected logValidator(header: string, ...optionalParams: any[]): void {
    this.log(`%c Validators: ${header} `, 'background: black; color: #00b100', ...optionalParams);
  }

  protected logValidation(header: string, ...optionalParams: any[]): void {
    this.log(`%c Validations: ${header} `, 'background: black; color: orange', ...optionalParams);
  }

  protected logRequiredField(header: string, ...optionalParams: any[]): void {
    this.log(`%c Required fields: ${header} `, 'background: black; color: #8f9dff', ...optionalParams);
  }

  protected log(message: string, ...optionalParams: any[]): void {
    this._loggerSvc.logVerbose(message, ...optionalParams);
  }
}
