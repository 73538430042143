import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AddStopGridComponent } from '@app/grids/add-stop-grid/add-stop-grid.component';

@Component({
  selector: 'app-add-stop-modal-dummy',
  templateUrl: './add-stop-modal-dummy.component.html',
  styleUrls: ['./add-stop-modal-dummy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddStopModalDummyComponent implements OnInit {

  @ViewChild(AddStopGridComponent, { static: false }) addStopGridComponent: AddStopGridComponent;
  
  constructor(
    public router: Router,
  ) { }

  @Input() isModalOpen: Boolean;
  @Input() mode: String;
  @Input() convertToNextDayDelivery: Boolean;
  @Input() isClickedOnce: Boolean;
  @Input() buttonName: String;
  @Input() title: String;

  @Output() closeButtonPressedEvent = new EventEmitter<string>();
  @Output() nextDayDeliverySwitchChangedEvent = new EventEmitter<string>();
  @Output() submitStopsEvent = new EventEmitter<string>();

  closeButtonPressed() {
    this.closeButtonPressedEvent.emit('');
  }

  nextDayDeliverySwitchChanged() {
    this.nextDayDeliverySwitchChangedEvent.emit('');
  }

  submitStops() {
    this.submitStopsEvent.emit('');
  }

  ngOnInit() {
  }

}
