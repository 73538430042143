import { Globals } from '@app/services/globals';
import { FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-chat-modal-dummy',
  templateUrl: './chat-modal-dummy.component.html',
  styleUrls: ['./chat-modal-dummy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatModalDummyComponent implements OnInit {

  @ViewChild('modal', { static: false }) public modal: ElementRef;
  @ViewChild('modalButtons', { static: false }) public modalButtons: ElementRef;
  @ViewChild('messageModal', { static: false }) public messageModal: ElementRef;
  @ViewChild('messageModalBackground', { static: false }) public messageModalBackground: ElementRef;

  constructor(
    public globals: Globals
  ) { }

  message = '';
  activeChannelId;

  @Input() channels: Array<any>;
  @Input() messages: Array<any>;
  @Input() activeChannelInfo: Object;
  @Input() activeFilter: String;
  @Input() session: any;
  @Input() isOpponentTypingMessage: Boolean;
  @Input() opponentsTypingMessage: Array<any>;
  @Input() hasLoadedChats: Boolean;
  @Input() isInVideoCall: Boolean;
  @Input() isInAudioCall: Boolean;
  @Input() isBeingCalledVideo: Boolean;
  @Input() isBeingCalledAudio: Boolean;
  @Input() isMuteVideo: Boolean;
  @Input() isMuteMicrophone: Boolean;
  @Input() isOpponentVideoMuted: Boolean;
  @Input() callOpponentId: Number;
  @Input() callerName: String;
  @Input() selectedUsers: Array<any>;
  @Input() groupChatName: String;
  @Input() usersForGroup: Array<any>;
  @Input() myForm: FormGroup;
  @Input() userToDialogMap: Object;
  @Input() searchString: String;
  @Input() isInMapView: String;

  @Output() toggleChatModalEvent = new EventEmitter<string>();
  @Output() searchStringChangedEvent = new EventEmitter<String>();
  @Output() sendMessageEvent = new EventEmitter<String>();
  @Output() sendNewMessageEvent = new EventEmitter<String>();
  @Output() toggleNewMessageModalEvent = new EventEmitter<String>();
  @Output() getAndShowActiveChannelInfoEvent = new EventEmitter<string>();
  @Output() toggleEmojiPickerEvent = new EventEmitter<string>();
  @Output() closeEmojiPickerEvent = new EventEmitter<string>();
  @Output() filterChannelsEvent = new EventEmitter<string>();
  @Output() createVideoSessionEvent = new EventEmitter<string>();
  @Output() createAudioSessionEvent = new EventEmitter<string>();
  @Output() acceptCallEvent = new EventEmitter<string>();
  @Output() rejectCallEvent = new EventEmitter<string>();
  @Output() endCallEvent = new EventEmitter<string>();
  @Output() muteVideoEvent = new EventEmitter<string>();
  @Output() muteMicrophoneEvent = new EventEmitter<string>();
  @Output() isTypingUpdateEvent = new EventEmitter<string>();
  @Output() checkMessageBoxEvent = new EventEmitter<string>();
  @Output() markMessagesAsReadEvent = new EventEmitter<string>();
  @Output() openChatEvent = new EventEmitter<string>();
  @Output() newMessageUsersUpdatedEvent = new EventEmitter<string>();

  toggleChatModal() {
    this.toggleChatModalEvent.emit('');
  }

  search($event) {
    this.searchStringChangedEvent.emit(this.searchString);
  }

  sendMessage() {
    this.sendMessageEvent.emit();
  }

  toggleNewMessageModal() {
    this.toggleNewMessageModalEvent.emit();
  }

  sendNewMessage() {
    this.sendNewMessageEvent.emit();
  }

  getAndShowActiveChannelInfo(orderIndex) {
    this.getAndShowActiveChannelInfoEvent.emit(orderIndex);
  }

  toggleEmojiPicker() {
    this.toggleEmojiPickerEvent.emit();
  }

  closeEmojiPicker() {
    this.closeEmojiPickerEvent.emit();
  }

  filterChannels(filterType) {
    this.filterChannelsEvent.emit(filterType);
  }

  createVideoSession() {
    this.createVideoSessionEvent.emit();
  }

  createAudioSession() {
    this.createAudioSessionEvent.emit();
  }

  acceptCall() {
    this.acceptCallEvent.emit();
  }

  rejectCall() {
    this.rejectCallEvent.emit();
  }

  endCall() {
    this.endCallEvent.emit();
  }

  muteVideo() {
    this.muteVideoEvent.emit();
  }

  muteMicrophone() {
    this.muteMicrophoneEvent.emit();
  }

  isTypingUpdate() {
    this.isTypingUpdateEvent.emit();
  }

  checkMessageBox() {
    this.checkMessageBoxEvent.emit();
  }

  markMessagesAsRead() {
    this.markMessagesAsReadEvent.emit();
  }

  openChat(dialogId) {
    this.openChatEvent.emit(dialogId);
  }

  newMessageUsersUpdated() {
    this.newMessageUsersUpdatedEvent.emit();
  }

  ngOnInit() {
  }

}
