import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { Globals } from '@app/services/globals';
import { ISettings_Basic_Services_Dto } from '@app/api/models/settings-services-dto';
import { IPriceListServices } from '@app/model/price-list';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LmSettingsFuelSurchargesService } from '@app/api/services/settings-fuel-surcharges.service';
import { FuelSurchargeModalComponent } from './new-fuel-surcharge-modal/new-fuel-surcharge-modal.component';
import { ISettings_Surcharges_Dto } from '@app/api/models/settings-surcharges-dto';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class SettingsFuelSurchargesViewModelService extends LmTableListViewModelService<ISettings_Surcharges_Dto> implements OnInit, OnDestroy {
    surchargesEnum: IPriceListServices;
    modalConfig: any;
    defaultService;
    collaboratorId: number;
    count = 0;
    items = [];

    constructor(
        injector: Injector, 
        public globals: Globals,
        @Inject(forwardRef(() => LmSettingsFuelSurchargesService)) public apiSvc: LmSettingsFuelSurchargesService
    ){
        super(injector);
        const {priceListServicesEnum} = globals;
        this.surchargesEnum = priceListServicesEnum;
        this.modalConfig = { componentType: FuelSurchargeModalComponent, injector: injector, data: {VM: this, globals: this.globals} }
    }
    
    private transformRows = (res) => {
        this.count = res.items.length;
        this.items = res.items;
        res.items.forEach(item => {
            item.service_names = '';
            item.services.forEach(service => {
                if (item.service_names != '') item.service_names += ', '
                item.service_names += service.service_name;
            });
            item.valueCaption = `<b>${item.operator} ${item.value}</b>`;
        })
        return res;
    }
    
    getRows(){
        return this.apiSvc.apiSettingsFuelSurchargesAllGet(this.collaboratorId).pipe(switchMap(res => of(this.transformRows(res))));
    }
    getById(id){

    }
    post(item: any) {
        return this.apiSvc.apiSettingsFuelSurchargesPost({body: item})
    }
    put(id) {
        
    }
    delete(id) {
        return this.apiSvc.apiSettingsFuelSurchargesSingleDelete({id:id})
    }
    newItem() {
        return {
            ...INITIAL_DATA.newFuelSurchargeItem, 
            type: this.globals.priceListSurchargesEnum.FuelSurcharge.key,
            // service_id: this.defaultService.id
        };
    }
    search(query: string) {
        
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
