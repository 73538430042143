import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Injector, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { LmSelectorTemplatesModule } from '../../../directives/selector-templates.directive';
import { LmColumnMetadataToGridColSizeModule } from '../../../pipes/column-metadata-to-grid-col-size.pipe';
import { LmSelectorOptionValueModule } from '../../../pipes/selector-option.pipe';
import { LmSelectorCacheService } from '../../../../core/services/cache/selector-cache.service';
import { hasValue } from '../../../utils';
import { LmFilterOperatorModule } from '../../filter-operator/filter-operator.component';
import { LmInputBase } from '../input-base';
import { LmSelect2Component } from './select.component';
import { LmInputActionsModule } from '../../input-actions/input-actions.component';
import { LmSelectorDisplayValueModule } from '@app/shared/pipes/selector-label.pipe';

export type SelectTypeAheadOptionsKeys = Exclude<
  keyof LmSelectTypeAhead2Component,
  'selectOptions' | 'id' | 'ngSelect' | 'value' | 'disabled' | 'dataSource$' | 'isLoading' | Function
>;
export type SelectTypeAheadOptions = Partial<{ [key in SelectTypeAheadOptionsKeys]: LmSelectTypeAhead2Component[key] }>;

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmSelectTypeAhead2Component), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmSelectTypeAhead2Component) };

@Component({
  selector: 'lm-select-typeahead2',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE]
})
export class LmSelectTypeAhead2Component extends LmSelect2Component implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, ControlValueAccessor {
  @Input() searchDelay = 500;
  @Input() autoFetchNumberOfData = 0;
  results: any;

  constructor(injector: Injector, cacheSvc: LmSelectorCacheService) {
    super(injector, cacheSvc);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.__typeahead$ = new Subject<string>();
    this.__typeahead$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => {
          this.ngSelectCtrl.loading = true;
          this.refreshDataSource([]);
        }),
        // debounceTime(this.searchDelay),
        switchMap((inputTerm) => (this.data$ as (term: string) => Observable<any[]>)(inputTerm).pipe(map((data) => ({ data, term: inputTerm })))),
        switchMap((response) => {
          const datas = (<any>response.data)?.result ?? []
          this.results = {data: datas, term: response.term}
          
          if (!hasValue(this.results.term)) {
            return of(this.results.data);
          }

          return this.selectorSearchValue(this.results.data, this.results.term);
        }),
        tap(() => (this.ngSelectCtrl.loading = false))
      )
      .subscribe((res) => {
        console.log('__typeahead res:', res)
        console.log('__typeahead results.data:', this.results.data)
        this.refreshDataSource(this.results.data);
        // this.refreshDataSource(res);
      });
  }

  ngAfterContentInit(): void {}

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.ngSelectCtrl) this.ngSelectCtrl.minTermLength = 3;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  skipResolveDisplayValue(): boolean {
    return this.autoFetchNumberOfData > 0;
  }

  protected getData(): Observable<any[]> {
    if (this.autoFetchNumberOfData > 0) {
      const data$ = (this.data$ as (term: string) => Observable<any[]>)(null).pipe(map((res) => res?.slice(0, Math.min(res.length, this.autoFetchNumberOfData))));
      if (this.useCacheForData) {
        return this.getCacheKey().pipe(
          switchMap((cacheKey) => {
            if (!cacheKey) throw new Error(`Invalid cache key for ${this.id}`);
            return this.cacheSvc.getOrAdd(cacheKey, () => data$);
          })
        );
      } // 
      else return data$;
    } // 
    else {
      if (!hasValue(this.value)) return of([]);
      return this.__dataSource$;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MessageModule,
    CheckboxModule,
    DropdownModule,
    TooltipModule,
    LmInputActionsModule,
    LmSelectorDisplayValueModule,
    LmSelectorOptionValueModule,
    LmColumnMetadataToGridColSizeModule,
    LmSelectorTemplatesModule,
    LmFilterOperatorModule
  ],
  exports: [LmSelectTypeAhead2Component, LmSelectorTemplatesModule],
  declarations: [LmSelectTypeAhead2Component]
})
export class LmSelectTypeAhead2Module {}
