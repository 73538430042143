export const OPERATOR_SYMBOLS = {
  equalsCaseInsensitive: '==*',
  equalsCaseSensitive: '==',
  notEqualsCaseInsensitive: '!=*',
  notEqualsCaseSensitive: '!=',
  biggerThan: '>',
  smallerThan: '<',
  biggerThanOrEqual: '>=',
  smallerThanOrEqual: '<=',
  containSCaseInsensitive: '@=*',
  doesNotContainSCaseInsensitive: '!@=*',
  beginsWithCaseInsensitive: '_=*'
};

export type LmFilterOperatorId =
  | 'equalsCaseInsensitive'
  | 'equalsCaseSensitive'
  | 'notEqualsCaseInsensitive'
  | 'notEqualsCaseSensitive'
  | 'biggerThan'
  | 'smallerThan'
  | 'biggerThanOrEqual'
  | 'smallerThanOrEqual'
  | 'containsCaseInsensitive'
  | 'doesNotContainsCaseInsensitive'
  | 'beginsWithCaseInsensitive';

export interface LmFilterOperatorInfo {
  id: LmFilterOperatorId;
  caption: string;
  symbol: string;
}

export const FILTER_OPERATORS: LmFilterOperatorInfo[] = [
  { id: 'equalsCaseInsensitive', caption: 'Ίσο με', symbol: OPERATOR_SYMBOLS.equalsCaseInsensitive },
  { id: 'equalsCaseSensitive', caption: 'Ίσο με', symbol: OPERATOR_SYMBOLS.equalsCaseSensitive },
  { id: 'notEqualsCaseInsensitive', caption: 'Όχι ίσο με', symbol: OPERATOR_SYMBOLS.notEqualsCaseInsensitive },
  { id: 'notEqualsCaseSensitive', caption: 'Όχι ίσο με', symbol: OPERATOR_SYMBOLS.notEqualsCaseSensitive },
  { id: 'biggerThan', caption: 'Μεγαλύτερο από', symbol: OPERATOR_SYMBOLS.biggerThan },
  { id: 'smallerThan', caption: 'Μικρότερο από', symbol: OPERATOR_SYMBOLS.smallerThan },
  { id: 'biggerThanOrEqual', caption: 'Μεγαλύτερο ή ίσο', symbol: OPERATOR_SYMBOLS.biggerThanOrEqual },
  { id: 'smallerThanOrEqual', caption: 'Μικρότερο ή ίσο', symbol: OPERATOR_SYMBOLS.smallerThanOrEqual },
  { id: 'containsCaseInsensitive', caption: 'Περιέχει', symbol: OPERATOR_SYMBOLS.containSCaseInsensitive },
  { id: 'doesNotContainsCaseInsensitive', caption: 'Δεν περιέχει', symbol: OPERATOR_SYMBOLS.doesNotContainSCaseInsensitive },
  { id: 'beginsWithCaseInsensitive', caption: 'Ξεκινάει με', symbol: OPERATOR_SYMBOLS.beginsWithCaseInsensitive }
];

//provide translations
export const FILTER_OPERATORS_EN: LmFilterOperatorInfo[] = [
  { id: 'equalsCaseInsensitive', caption: 'Ίσο με', symbol: OPERATOR_SYMBOLS.equalsCaseInsensitive },
  { id: 'equalsCaseSensitive', caption: 'Ίσο με', symbol: OPERATOR_SYMBOLS.equalsCaseSensitive },
  { id: 'notEqualsCaseInsensitive', caption: 'Όχι ίσο με', symbol: OPERATOR_SYMBOLS.notEqualsCaseInsensitive },
  { id: 'notEqualsCaseSensitive', caption: 'Όχι ίσο με', symbol: OPERATOR_SYMBOLS.notEqualsCaseSensitive },
  { id: 'biggerThan', caption: 'Μεγαλύτερο από', symbol: OPERATOR_SYMBOLS.biggerThan },
  { id: 'smallerThan', caption: 'Μικρότερο από', symbol: OPERATOR_SYMBOLS.smallerThan },
  { id: 'biggerThanOrEqual', caption: 'Μεγαλύτερο ή ίσο', symbol: OPERATOR_SYMBOLS.biggerThanOrEqual },
  { id: 'smallerThanOrEqual', caption: 'Μικρότερο ή ίσο', symbol: OPERATOR_SYMBOLS.smallerThanOrEqual },
  { id: 'containsCaseInsensitive', caption: 'Περιέχει', symbol: OPERATOR_SYMBOLS.containSCaseInsensitive },
  { id: 'doesNotContainsCaseInsensitive', caption: 'Δεν περιέχει', symbol: OPERATOR_SYMBOLS.doesNotContainSCaseInsensitive },
  { id: 'beginsWithCaseInsensitive', caption: 'Ξεκινάει με', symbol: OPERATOR_SYMBOLS.beginsWithCaseInsensitive }
];

export const FILTER_OPERATORS_DE: LmFilterOperatorInfo[] = [
  { id: 'equalsCaseInsensitive', caption: 'Ίσο με', symbol: OPERATOR_SYMBOLS.equalsCaseInsensitive },
  { id: 'equalsCaseSensitive', caption: 'Ίσο με', symbol: OPERATOR_SYMBOLS.equalsCaseSensitive },
  { id: 'notEqualsCaseInsensitive', caption: 'Όχι ίσο με', symbol: OPERATOR_SYMBOLS.notEqualsCaseInsensitive },
  { id: 'notEqualsCaseSensitive', caption: 'Όχι ίσο με', symbol: OPERATOR_SYMBOLS.notEqualsCaseSensitive },
  { id: 'biggerThan', caption: 'Μεγαλύτερο από', symbol: OPERATOR_SYMBOLS.biggerThan },
  { id: 'smallerThan', caption: 'Μικρότερο από', symbol: OPERATOR_SYMBOLS.smallerThan },
  { id: 'biggerThanOrEqual', caption: 'Μεγαλύτερο ή ίσο', symbol: OPERATOR_SYMBOLS.biggerThanOrEqual },
  { id: 'smallerThanOrEqual', caption: 'Μικρότερο ή ίσο', symbol: OPERATOR_SYMBOLS.smallerThanOrEqual },
  { id: 'containsCaseInsensitive', caption: 'Περιέχει', symbol: OPERATOR_SYMBOLS.containSCaseInsensitive },
  { id: 'doesNotContainsCaseInsensitive', caption: 'Δεν περιέχει', symbol: OPERATOR_SYMBOLS.doesNotContainSCaseInsensitive },
  { id: 'beginsWithCaseInsensitive', caption: 'Ξεκινάει με', symbol: OPERATOR_SYMBOLS.beginsWithCaseInsensitive }
];