import { ProjectProblemDataService } from './../services/project-problem-data.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ImporterService } from '@app/services/importer.service';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import { GuideTourService } from '@app/services/guide-tour.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { Router } from '@angular/router';
import polyline from '@mapbox/polyline';
import { MapDummyComponent } from './map-dummy/map-dummy.component';
import { MapUtils } from '@app/utils/map-utils';
import { ColourService } from '@app/services/colour.service';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { ImageUtils } from '@app/utils/image-utils';
import { DepotUtils } from '@app/utils/depot-utils';

declare var H: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MapDummyComponent, { static: false }) mapDummyComponent: MapDummyComponent;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  count = 0;
  timer;

  // map modes
  mapEmptyForImporter = false;
  drawModeEnabled = false;
  selectModeEnabled = false;
  mergeModeEnabled = false;
  disableSelectModeAfterUpdate = false;
  disableRightClickOnStopPoint = false;
  disableCreateStopPoint = false;
  disableClickOnMap = false;
  disableRightClickOnMap = false;
  sameDayDeliveryMode = false;
  draggableForSameDayDelivery = false;
  sequenceModeEnabled = '';

  private platform: any;
  // @ViewChild('map', { static: false }) public mapElement: ElementRef;
  // @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  // @ViewChild('selectStopsButton', { static: false, read: ElementRef }) selectStopsButton: ElementRef;
  wasHereMsg;
  vehicleTitle;
  plateNumberTitle;
  driverTitle;
  latTitle;
  lonTitle;
  lastUpdateTitle;
  moveAnotherDriverMSG;
  moveAnotherProblemMSG;
  freeTimeWindowMSG;
  loadStopsMSG;
  returnLabel;

  disableLabel;
  enableLabel;
  removeModificationsMSG;
  mergeToMSG;
  unMergeMSG;
  cancelMSG;
  deleteMSG;
  beforeMsg;
  afterMsg;
  directlyBeforeMsg;
  directlyAfterMsg;
  visitBeforeMsg;
  visitAfterMsg;
  visitDirectlyBeforeMsg;
  visitDirectlyAfterMsg;
  beforeWordMsg;
  afterWordMsg;
  beforeInitialMsg;
  afterInitialMsg;
  editStopMSG;
  highPriorityMSG;
  normalPriorityMSG;
  selectOneByOneMsg;
  selectPolygonMsg;
  selectFinalMsg;
  beforeAfterMsg;
  mergeMsg;
  addressLocationMsg;
  deliveryLocationMsg;
  pickupLocationMsg;
  loadingStopsMsg;

  goingToPickupLabel;

  map;
  ui;
  behavior;

  clickOnMap;
  selectAddress;
  rightClickOnMap;
  contextMenuItems;
  // mapViewChange;

  // event bind refs
  hoverIconRef;
  unHoverIconRef;
  checkWhenDoneRenderingRef;
  checkWhenDoneShowingOriginalStopsRef;
  onRenderChangeRef;

  startClusterZoomLevel = 11;
  clusterZoomLevels = [];
  currentZoomLevel = 0;

  displayInfoBubble;
  hideInfoBubble;
  infoBubble;

  selectedStopPointIds = [];
  polygonVerticesCoords = [];

  displayOldRouteInfoBubble;
  oldRouteInfoBubble;
  stopPointsDepotsRoutesGroup = new H.map.Group();
  partnerAndCollaboratorDepotsGroup = new H.map.Group();
  driverStartEndPointsGroup = new H.map.Group();
  smartPointsGroup = new H.map.Group();
  stopPointsGroup = new H.map.Group();
  stopPointsTempGroup = new H.map.Group();
  routesGroup = new H.map.Group();
  driverPickupRoutesGroup = new H.map.Group();
  routesTempGroup = new H.map.Group();
  vehiclesGroup = new H.map.Group();
  editingMarkerGroup = new H.map.Group();
  draggableMarkerGroup = new H.map.Group();
  oldRouteGroup = new H.map.Group();
  importerMarkersGroup = new H.map.Group();
  polygonMarkersGroup = new H.map.Group();
  polygonLineString = new H.geo.LineString();
  polygonLineStringPolyline;
  polygonGroup = new H.map.Group();
  boundsGroup = new H.map.Group();
  tourMarkersGroup = new H.map.Group();
  smartPointsHoverBoxes = [];

  domMarkersGroup = new H.map.Group();
  zoomOutGroup = new H.map.Group();
  zoomInCentersGroup = new H.map.Group();
  zoomLevelsGroups = {};
  zoomLevelsCentersParentGroup = new H.map.Group();
  zoomLevelsCentersGroups = {};


  // general icons
  blueDepotIcon;
  greyDepotIcon;
  depotIconBackground;
  debugDepotIcon;

  polygonVerticeCircleIcon;
  basicCheckIcon;
  basicCancelIcon;
  basicUserIcon;
  basicWeightIcon;
  basicLargeClockIcon;
  smallClockIcon;
  smallRedClockIcon;
  smallSmartPointIcon;
  asteriskIcon;
  cancelledPortalIcon;
  basicSmallCheckIcon;
  basicBeforeAfterIcon;

  letterMIcon;
  letterRIcon;

  stopPointMarkerIconsByColour = {};
  checkboxIconsPerColourCombination = {};
  numberIconsPerDigitLength = {};
  staticVehicleIconsPerColourPerInitials = {};
  movingVehicleIconsPerColourPerInitialsPerAngle = {};

  secondaryNumbersIcons = {};

  counter = 0;

  vehiclesMapObjects = {};
  stopPointMarkerGroups = {};
  stopPointMapObjects = {};
  importerMapObjects = {};
  routeMapObjects = {};
  domMarkers = {};

  stopPointMarkersToAdd = [];

  listen = [];
  sequenceStopPointIdsSelected = [];

  clickOnStopPoint;
  markerOnClickListener;

  milyMapMessage = '';
  entityStatus;

  newShipmentsDrivers = [];

  globalNormalIcon;
  invisibleIcon;

  a = 0;

  stopPointDataForHover = {};
  tappedDriverData = {};

  hoverStopPointLogo = '';
  hoverStopPointName = '';
  hoverStopPointCollaborator = '';
  hoverStopPointTimeWindow = '';
  hoverStopPointBarcode = '';
  hoverStopPointDriver = '';
  hoverStopPointRating: number;
  hoverStopPointSequence = '';
  hoverStopPointFulfillmentTime = '';
  hoverStopPointDeliveryLoad = 0;
  hoverStopPointPickupLoad = 0;

  hoverDepotName
  hoverDepotAddress
  hoverDepotPhone

  driverDefaultBase64 = this.globals.driverDefaultBase64;

  vehicleTypesIcons = {};

  driverImage = '';
  driverName = '';
  driverLastSeen = '';
  driverFinishedStops;
  driverTotalStops;
  vehicleId;
  vehicleType;
  vehicleTypes = [];
  driverTotalProgressDegrees;
  driverColour;
  driverHoverBoxTapped = false;
  driverMarkerInterval; // used to keep hover box location next to the tapped driver
  comingForPickupLabel;
  driversIntervals = [];

  noNameLabel = '';
  returnNameLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public router: Router,
    private draggableMarkerService: DraggableMarkerService,
    private viewProjectProblemService: ViewProjectProblemService,
    private importerService: ImporterService,
    public globals: Globals,
    private guideTourService: GuideTourService,
    private projectProblemDataService: ProjectProblemDataService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private mapUtils: MapUtils,
    private colourService: ColourService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private stopPointUtils: StopPointUtils,
    private imageUtils: ImageUtils,
    private depotUtils: DepotUtils,
  ) {
    this.hoverIconRef = this.hoverIcon.bind(this);
    this.unHoverIconRef = this.unHoverIcon.bind(this);
    this.checkWhenDoneRenderingRef = this.checkWhenDoneRenderingEvent.bind(this);
    this.checkWhenDoneShowingOriginalStopsRef = this.checkWhenDoneShowingOriginalStopsEvent.bind(this);
    this.onRenderChangeRef = this.onRenderChange.bind(this);

    this.clickOnStopPoint = (event) => {
      const target = event.target;
      if ((target instanceof H.map.Marker || target instanceof H.map.DomMarker) && (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')) {
        if (target.getData()) {
          if (this.mergeModeEnabled) {
            this.mergeModeEnabled = false;
            this.milyMapMessage = '';
            this.viewProjectProblemService.mergeStopPoints(this.selectedStopPointIds, target.getData().id);
            this.disableSelectMode();
          } else if (this.sequenceModeEnabled) {
            if (this.selectModeEnabled || this.drawModeEnabled) {
              this.viewProjectProblemService.sequenceStopPoints(this.sequenceStopPointIdsSelected, target.getData().id, this.sequenceModeEnabled);
              this.disableSelectMode();
            } else {
              this.viewProjectProblemService.sequenceStopPoints(this.sequenceStopPointIdsSelected, target.getData().id, this.sequenceModeEnabled);
            }
            this.sequenceModeEnabled = '';
            this.milyMapMessage = '';
          } else if (this.selectModeEnabled || this.drawModeEnabled) {
            // TODO check if sp has error without using its colour
            // if (target.getData().colour !== '#666666') {
            if (target.getData().selected || this.selectedStopPointIds.includes(target.getData().id)) {
              this.removeStopPointFromSelectedGroup(target);
            } else {
              this.addStopPointToSelectedGroup(target);
            }
            // }
          } else {
            const clickedStopPointId = target.getData().id;
            const clickedStopPointProjectProblem = this.projectProblemDataService.projectProblemId;
            this.viewProjectProblemService.openStopModal(clickedStopPointId, clickedStopPointProjectProblem);
          }
        }
      }
      // }
    };
    if (!this.markerOnClickListener) {
      this.markerOnClickListener = this.clickOnStopPoint.bind(this);
    }
    this.platform = this.globals.platform;
    this.listen.push(this.draggableMarkerService.sameDayDeliveryModeListen().subscribe((enabled) => {
      // sameDayDeliveryModeListen(enabled);
      this.sameDayDeliveryMode = enabled;
    }));
    this.listen.push(this.draggableMarkerService.addDraggableMarkerListen().subscribe((response) => {
      this.draggableForSameDayDelivery = response.forSameDayDelivery;
      this.showDraggableMarker(response.lat, response.lng);
    }));
    this.listen.push(this.draggableMarkerService.removeDraggableMarkerListen().subscribe((response) => {
      this.removeDraggableMarker();
    }));
    this.listen.push(this.viewProjectProblemService.updateVehicleIconListen().subscribe(vehicleData => {
      const colour = this.colourService.colourCalculator(vehicleData.colourIndex);
      if (vehicleData.isStatic) {
        let iconStaticSetup = this.mapDummyComponent.svgIconsComponent.svgVehicleMaker.replace('initials', this.mapUtils.calculateDriverInitials(vehicleData.driverName)).replace('markerColour', this.colourService.colourCalculator(vehicleData.colourIndex));
        // const iconStatic = new H.map.DomIcon(iconStaticSetup);
        const iconStatic = this.getStaticVehicleIconWithColoursAndInitials(colour, vehicleData.driverName)
        this.vehiclesMapObjects[vehicleData.id].setIcon(iconStatic);
      } else {
        let iconMovingSetup = this.mapDummyComponent.svgIconsComponent.svgVehicleMarkerMoving.replace('rotationAngle', vehicleData.angle).replace('markerColour', this.colourService.colourCalculator(vehicleData.colourIndex));
        // const iconMoving = new H.map.DomIcon(iconMovingSetup);
        const iconMoving = this.getMovingVehicleIconWithColoursInitialsAndAngle(colour, vehicleData.driverName, vehicleData.angle)
        this.vehiclesMapObjects[vehicleData.id].setIcon(iconMoving);
      }
    }));
    this.listen.push(this.viewProjectProblemService.showOnlyActiveVehiclesOnMapListen().subscribe((activeVehicleIdsArray) => {
      Object.keys(this.vehiclesMapObjects).forEach(vehicleId => {
        if (activeVehicleIdsArray && !activeVehicleIdsArray.includes(vehicleId)) {
          if (this.vehiclesMapObjects[vehicleId]) {
            if (this.vehiclesGroup.contains(this.vehiclesMapObjects[vehicleId])) {
              this.vehiclesGroup.removeObject(this.vehiclesMapObjects[vehicleId]);
            }
            delete this.vehiclesMapObjects[vehicleId];
          }
        }
      });
    }));
    this.listen.push(this.viewProjectProblemService.trackVehiclesListen().subscribe((response) => {
      this.trackVehicle(response.vehicleId, response.colour, response.coords, response.dateTime, response.plateNumber, response.driverName, response.driverImage, response.companyImage, response.isStatic, response.angle, response.vehicleType, response.driverFinishedStops, response.driverTotalStops, response.driverTotalProgressDegrees, response.driverColour, response.showPickupBox, response.estimatedArrival);
    }));
    this.listen.push(this.viewProjectProblemService.updateSmartPointsListen().subscribe(() => {
      this.loadSmartPointsOnMap();
    }));
    this.listen.push(this.viewProjectProblemService.openStopModalListen().subscribe((data) => {
      const target = this.stopPointMapObjects[data.stopPointId];
      if (target) {
        // this.editingMarkerGroup.addObject(target);
        // const icon = new H.map.Icon(this.svgMarkup.replace('markerColour', '#00aeba'));
        // target.setIcon();
        // const bounds = this.editingMarkerGroup.getBoundingBox();
        // this.editingMarkerGroup.removeObject(target);
        // this.stopPointsGroup.addObject(target);
        // this.centerToMarker(bounds);
      }
    }));
    this.listen.push(this.viewProjectProblemService.disableMapMultipleSelectListen().subscribe((data) => {
      this.disableSelectMode();
    }));
    this.listen.push(this.viewProjectProblemService.disableMapMultipleSelectAfterUpdateListen().subscribe((data) => {
      this.disableSelectModeAfterUpdate = true;
    }));
    this.listen.push(this.viewProjectProblemService.changeStopPointPriorityListen().subscribe((data) => {
      data.ids.forEach(id => {
        if (this.stopPointMapObjects[id]) {
          const markerData = this.stopPointMapObjects[id].getData();
          markerData.priority = data.priority;
          this.updateStopPoint(markerData);
        }
      });
    }));
    this.listen.push(this.viewProjectProblemService.filterStopPointsListen().subscribe((data) => {
      if (data.shownStopPoints && data.shownRoutes) {
        this.filterRoutesAndStops(data.shownStopPoints, data.shownRoutes);
      }
    }));
    this.listen.push(this.viewProjectProblemService.removeFiltersFromMapListen().subscribe((data) => {
      this.removeMapFilters();
    }));
    this.listen.push(this.viewProjectProblemService.showMapMessageListen().subscribe((data) => {
      this.milyMapMessage = data;

      // [food mode]: remove message after 5s in new shipments
      if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && this.router.url.split('/')[1] == 'newShipmentsView') {
        setTimeout(() => {
          this.milyMapMessage = null;
        }, 5000);
      }
    }));
    this.listen.push(this.viewProjectProblemService.focusOnMarkerListen().subscribe((id) => {
      this.focusOnMarker(id);
    }));
    this.listen.push(this.viewProjectProblemService.focusOnMarkersListen().subscribe((ids) => {
      this.focusOnMarkers(ids);
    }));
    this.listen.push(this.importerService.mapEmptyListen().subscribe((response) => {
      this.temporaryEmptyMap();
    }));
    this.listen.push(this.importerService.mapRefillListen().subscribe((response) => {
      this.refillMap();
      this.setMapBoundsForStopPoints();
    }));
    this.listen.push(this.importerService.addMarkerListen().subscribe((data) => {
      this.addImporterStopPoint(data.lat, data.lon, data.index);
    }));
    this.listen.push(this.importerService.disableCreateStopPointListen().subscribe((data) => {
      this.disableCreateStopPoint = true;
    }));
    this.listen.push(this.importerService.focusOnMarkerListen().subscribe((index) => {
      const target = this.importerMapObjects[index];
      this.editingMarkerGroup.addObject(target);
      const bounds = this.editingMarkerGroup.getBoundingBox();
      this.editingMarkerGroup.removeObject(target);
      this.importerMarkersGroup.addObject(target);
      this.centerToMarker(bounds);
      // the code below centers the marker but doesn't look good with modal open
      // need to put it through the same algorithm as above
      // const data = target.getGeometry();
      // const coords = { lat: data.lat, lng: data.lng }
      // this.map.setCenter(coords);
      // this.map.setZoom(14);
      // const bounds = this.map.getViewModel().getLookAtData().bounds;
      // this.centerToMarker(bounds);
    }));
    this.listen.push(this.guideTourService.triggerRightClickListen().subscribe((id) => {
      this.stopPointMapObjects[id].dispatchEvent('contextmenu');
    }));
    this.listen.push(this.guideTourService.focusOnMarkerListen().subscribe((id) => {
      this.focusOnMarker(id);
    }));
    this.listen.push(this.guideTourService.clearMapFromTourListen().subscribe((data) => {
      this.tourMarkersGroup.removeAll();
    }));
  }

  // passes data to dummy component
  pushHoverDataToDom() {
    this.stopPointDataForHover = {
      hoverStopPointLogo: this.hoverStopPointLogo,
      hoverStopPointName: this.hoverStopPointName,
      hoverStopPointCollaborator: this.hoverStopPointCollaborator,
      hoverStopPointTimeWindow: this.hoverStopPointTimeWindow,
      hoverStopPointBarcode: this.hoverStopPointBarcode,
      hoverStopPointDriver: this.hoverStopPointDriver,
      hoverStopPointRating: this.hoverStopPointRating,
      hoverStopPointSequence: this.hoverStopPointSequence,
      hoverStopPointFulfillmentTime: this.hoverStopPointFulfillmentTime,
      hoverStopPointDeliveryLoad: this.hoverStopPointDeliveryLoad,
      hoverStopPointPickupLoad: this.hoverStopPointPickupLoad,
      hoverDepotName: this.hoverDepotName,
      hoverDepotAddress: this.hoverDepotAddress,
      hoverDepotPhone: this.hoverDepotPhone,
    }
  }

  pushTapDriverDataToDom() {
    this.tappedDriverData = {
      vehicleId: this.vehicleId,
      driverImage: this.driverImage,
      driverName: this.driverName,
      driverLastSeen: this.driverLastSeen,
      driverFinishedStops: this.driverFinishedStops,
      driverTotalStops: this.driverTotalStops,
      vehicleType: this.vehicleType,
      driverTotalProgressDegrees: this.driverTotalProgressDegrees,
      driverColour: this.driverColour,
      driverDefaultBase64: this.globals.driverDefaultBase64
    };
  }

  sendSelectedStopsToDom() {
    this.selectedStopPointIds = [...this.selectedStopPointIds];
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  closeVehicleBox() {
    document.getElementById('vehicle-hover-box').style.display = 'none';
    if (this.driverMarkerInterval) {
      clearInterval(this.driverMarkerInterval);
    }
    if (this.driverHoverBoxTapped) {
      this.driverHoverBoxTapped = false;
    }
  }

  openDriversGrid() {
    this.viewProjectProblemService.openDriversGrid();
    setTimeout(() => {
      this.closeVehicleBox();
    }, 1000);
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
      },
      {
        index: 1,
        image: scooter,
      },
      {
        index: 2,
        image: car,
      },
      {
        index: 3,
        image: van,
      },
      {
        index: 4,
        image: largeVan,
      },
      {
        index: 5,
        image: truck,
      }
    ];
  }

  // generate box with SP data for hover
  hoverIcon(event) {
    const data = event.target.getData();

    // stop point
    if (!data['isDepot']) {
      const targetPosition = this.map.geoToScreen(event.target.getGeometry());
      let stopPointData = this.projectProblemDataService.stopPoints[data.id];
      if (stopPointData.stopPoint) { stopPointData = stopPointData.stopPoint; }
      const stopsData = {
        stopPoint: stopPointData,
        relatedStopPoint: this.projectProblemDataService.stopPoints[stopPointData?.related_stop_point_id]
      }

      let name = stopPointData.contact_name;

      if (name.includes(this.noNameConstant)) {
        name = name.replace(this.noNameConstant, this.noNameLabel);
      }
      if (name.includes(this.returnConstant)) {
        name = name.replace(this.returnConstant, this.returnNameLabel);
      }

      this.hoverStopPointName = name;
      this.hoverStopPointSequence = '';
      if (data.sequence) {
        this.hoverStopPointSequence = data.sequence;
      }

      this.hoverStopPointCollaborator = '-';
      this.hoverStopPointLogo = '';
      if (this.globals.collaboratorModeEnabled) {
        const partnerCompanyId = stopPointData.company_partner_id;
        if (this.globals.partners[partnerCompanyId]) {
          // partner logo
          if (this.globals.partners[partnerCompanyId]['logo']) {
            if (this.globals.partners[partnerCompanyId]['logo']['base64']) {
              this.hoverStopPointLogo = this.globals.partners[partnerCompanyId]['logo']['base64'];
            }
          }
          this.hoverStopPointCollaborator = this.globals.partners[partnerCompanyId]['name'];
        }
      } else {
        if (stopPointData.collaborator) {
          this.hoverStopPointCollaborator = stopPointData.collaborator.collaboratorData.collaborator_name;
        } else if (stopPointData.collaborator_name) {
          this.hoverStopPointCollaborator = stopPointData.collaborator_name;
        } else if (stopPointData.related_stop_point_id && stopPointData.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
          // find related stop point contact name which we'll use as collaborator name
          this.projectProblemDataService.stopPointsArray.forEach(relatedStopPoint => {
            if (relatedStopPoint.id == stopPointData.related_stop_point_id) {
              this.hoverStopPointCollaborator = relatedStopPoint.contact_name;
            }
          });
        } else {
          this.hoverStopPointCollaborator = stopPointData.contact_name;
        }
      }

      this.hoverStopPointTimeWindow = this.dateTimeCalculatorService.getTimeWindowLabel(stopPointData.time_windows);

      this.hoverStopPointBarcode = '-';
      this.hoverStopPointBarcode = this.stopPointUtils.getBarcodeOrVoucherHash(stopPointData);


      this.hoverStopPointDriver = '';
      if (stopPointData.portal_access != this.globals.portalAccessConstants['NO_ACCESS']) {
        this.hoverStopPointRating = stopPointData.driver_rating / 2;
      }

      this.hoverStopPointFulfillmentTime = '';
      // document.getElementById('vehicle-type-icon').innerHTML = '';
      document.getElementById('route-colour').style.backgroundColor = 'transparent';
      if (stopPointData.solution) {
        this.hoverStopPointDriver = stopPointData.solution.driver.userProfile.name;
        // document.getElementById('vehicle-type-icon').innerHTML = '';
        const routeSetting = this.projectProblemDataService.routeSettingsById[stopPointData.solution.routeSettingId];
        if (routeSetting) {
          const vehicleType = routeSetting.vehicle.vehicle_type;
          if (this.vehicleTypesIcons[vehicleType]) {
            setTimeout(() => {
              const vehicleIconElement = document.getElementById('vehicle-type-icon');
              if (vehicleIconElement) {
                vehicleIconElement.innerHTML = this.vehicleTypesIcons[vehicleType];
              }
            }, 100);
          }
        }
        document.getElementById('route-colour').style.backgroundColor = data.colour;
        this.hoverStopPointFulfillmentTime = this.dateTimeCalculatorService.getStopPointFulfillmentTime(stopPointData);
        if (!this.hoverStopPointFulfillmentTime) {
          // TODO change projectProblem for optimization
          const projectProblemData = this.projectProblemDataService.stopPointsArray[this.projectProblemDataService.stopPointIndexInArray[data.id]]['projectProblem'];
          this.hoverStopPointFulfillmentTime = this.dateTimeCalculatorService.calculateEstimatedArrivalTime(stopPointData, data.id, stopPointData.solution.routeSettingId, projectProblemData).estimatedArrivalTime;
        }
      }


      this.hoverStopPointDeliveryLoad = 0;
      this.hoverStopPointPickupLoad = 0;

      const loads = this.stopPointUtils.getPickupAndDeliveryLoad(stopsData);
      if (loads.deliveryLoad) {
        this.hoverStopPointDeliveryLoad += Number(loads.deliveryLoad);
      }
      if (loads.pickupLoad) {
        this.hoverStopPointPickupLoad += Number(loads.pickupLoad);
      }

      var markerHoverBox = document.getElementById('marker-hover-box');
      markerHoverBox.style.left = (Number(targetPosition.x) + 10) + 'px';
      markerHoverBox.style.top = targetPosition.y + 'px';
      markerHoverBox.style.display = 'block';

      if (data.editSequenceStopPoints) {
        this.mapUtils.showBeforeAfterStopPoints(data.editSequenceStopPoints, this.stopPointMapObjects, this.map);
      }

      if (data.editDirectSequenceStopPoints) {
        this.showDirectlyBeforeAfterStopPoints(data.editDirectSequenceStopPoints);
      }

      // show smart point box over the smart point that this point stop point relates to (if there is one)
      if (stopPointData.smartPoint) {
        const hoverIdentifier = stopPointData.smartPoint.identifier ?? stopPointData.smartPoint.id;
        this.smartPointsHoverBoxes = [];
        this.smartPointsGroup.getObjects().forEach(smartPoint => {
          const smartPointId = smartPoint.getData()?.smartPoint?.identifier ?? smartPoint.getData()?.smartPoint?.id;
          if (smartPointId === hoverIdentifier) {
            const coords = this.map.geoToScreen(smartPoint.getGeometry());
            this.smartPointsHoverBoxes.push({ ...coords });
          }
        });
        this.smartPointsHoverBoxes = [...this.smartPointsHoverBoxes];
      }
    }
    // depot
    else {
      const targetPosition = this.map.geoToScreen(event.target.getGeometry());
      document.getElementById('depot-hover-box').style.left = (Number(targetPosition.x) + 10) + 'px';
      document.getElementById('depot-hover-box').style.top = targetPosition.y + 'px';
      document.getElementById('depot-hover-box').style.display = 'block';

      this.hoverDepotName = data['depotName'];
      this.hoverDepotAddress = data['depotAddress'];
      this.hoverDepotPhone = data['depotPhone'];
    }

    this.pushHoverDataToDom();
  }

  unHoverIcon() {
    document.getElementById('marker-hover-box').style.display = 'none';
    document.getElementById('depot-hover-box').style.display = 'none';
    this.mapUtils.hideBeforeAfterStopPoints();
    this.smartPointsHoverBoxes = []; // hide all smart point hover boxes
    // this.hideBeforeAfterStopPoints();
  }

  // generate smart point boxes on stop points on hover
  hoverSmartPointIcon(event) {
    const data = event.target.getData();
    const hoverIdentifier = data.smartPoint.identifier ?? data.smartPoint.id;

    this.smartPointsHoverBoxes = [];
    Object.keys(this.stopPointMarkerGroups).forEach(stopPointId => {
      const stopPointMarker = this.stopPointMarkerGroups[stopPointId];
      const stopPointSmartPointId = stopPointMarker.getData().smartPoint?.identifier ?? stopPointMarker.getData().smartPoint?.id;
      if (stopPointSmartPointId === hoverIdentifier) {
        const markerCoords = this.stopPointMarkerGroups[stopPointId].getObjects()[0].getGeometry();
        const coords = this.map.geoToScreen(markerCoords);
        this.smartPointsHoverBoxes.push({ ...coords });
      }
    });
    this.smartPointsHoverBoxes = [...this.smartPointsHoverBoxes];
  }

  hideSmartPointBoxes() {
    this.smartPointsHoverBoxes = [];
  }

  showBeforeAfterStopPoints(editSequenceStopPoints) {
    if (editSequenceStopPoints.afterIds) {
      editSequenceStopPoints.afterIds.forEach(id => {
        this.showBeforeAfterBubble(id, this.afterMsg);
      });
    }
    if (editSequenceStopPoints.beforeIds) {
      editSequenceStopPoints.beforeIds.forEach(id => {
        this.showBeforeAfterBubble(id, this.beforeMsg);
      });
    }
  }

  showDirectlyBeforeAfterStopPoints(editDirectSequenceStopPoints) {
    if (editDirectSequenceStopPoints.afterIds) {
      editDirectSequenceStopPoints.afterIds.forEach(id => {
        this.showBeforeAfterBubble(id, this.directlyAfterMsg);
      });
    }
    if (editDirectSequenceStopPoints.beforeIds) {
      editDirectSequenceStopPoints.beforeIds.forEach(id => {
        this.showBeforeAfterBubble(id, this.directlyBeforeMsg);
      });
    }
  }

  showBeforeAfterBubble(stopPointId, label) {
    const target = this.stopPointMapObjects[stopPointId];
    if (target) {
      const targetPosition = this.map.geoToScreen(target.getGeometry());
      const beforeAfterBox = document.createElement('div');
      beforeAfterBox.classList.add('before-after-hover-box');
      beforeAfterBox.style.left = (Number(targetPosition.x) + 10) + 'px';
      beforeAfterBox.style.top = targetPosition.y + 'px';
      beforeAfterBox.style.display = 'block';
      beforeAfterBox.innerHTML = label;
      document.getElementById('before-after-hover-boxes').appendChild(beforeAfterBox);
    }
  }

  hideBeforeAfterStopPoints() {
    document.querySelectorAll('.before-after-hover-box').forEach(element => {
      element.remove();
    });
  }

  // move map bounds to show one marker
  focusOnMarker(id) {
    if (this.stopPointMapObjects[id]) {
      const data = this.stopPointMapObjects[id].getData();
      const coords = { lat: data.lat, lng: data.lon }
      const icon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgMakerBackground);
      const marker = new H.map.Marker(coords, { icon: icon });
      this.boundsGroup.removeAll();
      this.boundsGroup.addObject(marker);
      const bounds = this.boundsGroup.getBoundingBox();
      this.centerToMarker(bounds);
      this.boundsGroup.removeAll();
    }
  }

  // move map bounds to show some marker
  focusOnMarkers(ids) {
    this.boundsGroup.removeAll();
    ids.forEach(id => {
      if (this.stopPointMapObjects[id]) {
        const data = this.stopPointMapObjects[id].getData();
        const coords = { lat: data.lat, lng: data.lon }
        const icon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgMakerBackground);
        const marker = new H.map.Marker(coords, { icon: icon });
        this.boundsGroup.addObject(marker);
      }
    });
    const bounds = this.boundsGroup.getBoundingBox();
    this.centerToMarker(bounds);
    this.boundsGroup.removeAll();
  }

  // hide all SPs and routes except from some
  filterRoutesAndStops(shownStopPoints, shownRoutes) {
    Object.keys(this.stopPointMarkerGroups).forEach(id => {
      if (shownStopPoints.includes(Number(id))) {
        this.stopPointMarkerGroups[id].setVisibility(true);
      } else {
        this.stopPointMarkerGroups[id].setVisibility(false);
      }
    });
    Object.keys(this.routeMapObjects).forEach(routeSettingId => {
      Object.keys(this.routeMapObjects[routeSettingId]).forEach(orderIndex => {
        if (shownRoutes.includes(Number(routeSettingId))) {
          this.routeMapObjects[routeSettingId][orderIndex].setVisibility(true);
        } else {
          this.routeMapObjects[routeSettingId][orderIndex].setVisibility(false);
        }
      });
    });
  }

  // show all routes and SPs without hiding some
  removeMapFilters() {
    Object.keys(this.stopPointMarkerGroups).forEach(id => {
      this.stopPointMarkerGroups[id].setVisibility(true);
    });
    Object.keys(this.routeMapObjects).forEach(routeSettingId => {
      Object.keys(this.routeMapObjects[routeSettingId]).forEach(orderIndex => {
        if (this.routeMapObjects[routeSettingId][orderIndex]) {
          this.routeMapObjects[routeSettingId][orderIndex].setVisibility(true);
        }
      });
    });
  }

  emptyDriverStartEndPoints() {
    this.driverStartEndPointsGroup.removeAll();
  }

  // show company depots
  displayDepots() {
    let coords, lat, lon, marker, markerBackground;
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (this.globals.depotsDataDone) {
        clearInterval(dataRefreshIntervalId);
        const depots = this.globals.depotsArray;
        depots.forEach(depot => {
          lat = depot['companyDepot']['address']['lat'];
          lon = depot['companyDepot']['address']['lon'];
          coords = { lat: lat, lng: lon };
          marker = new H.map.Marker(coords, { icon: this.blueDepotIcon });
          markerBackground = new H.map.Marker(coords, { icon: this.depotIconBackground });
          // NOTE canvas warning here
          this.stopPointsDepotsRoutesGroup.addObject(markerBackground);
          this.stopPointsDepotsRoutesGroup.addObject(marker);
        });
      }
    }
  }

  // [food mode]: show specific company depots
  displaySpecificDepots(depots) {
    let coords, lat, lon, marker, markerBackground;
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (this.globals.depotsDataDone) {
        clearInterval(dataRefreshIntervalId);
        this.partnerAndCollaboratorDepotsGroup.removeAll();
        depots.forEach(depot => {
          lat = depot['companyDepot']['address']['lat'];
          lon = depot['companyDepot']['address']['lon'];
          coords = { lat: lat, lng: lon };
          marker = new H.map.Marker(coords, { icon: this.blueDepotIcon });
          marker.addEventListener('pointerenter', this.hoverIconRef);
          marker.addEventListener('pointerleave', this.unHoverIconRef);

          const dataForMarker = {
            isDepot: true,
            depotName: depot['companyDepot']['address']['label'],
            depotAddress: depot['companyDepot']['address']['value'],
            depotPhone: depot['companyDepot']['telephone']
          };
          marker.setData(dataForMarker);

          markerBackground = new H.map.Marker(coords, { icon: this.depotIconBackground });
          this.partnerAndCollaboratorDepotsGroup.addObject(markerBackground);
          this.partnerAndCollaboratorDepotsGroup.addObject(marker);
        });
      }
    }
  }

  // show collaborator and partner depots
  displayCollaboratorAndPartnerDepots(depots) {
    this.partnerAndCollaboratorDepotsGroup.removeAll();
    let coords, lat, lon, marker, markerBackground;
    depots.forEach(depot => {
      lat = depot['address']['lat'];
      lon = depot['address']['lon'];
      coords = { lat: lat, lng: lon };
      marker = new H.map.Marker(coords, { icon: this.blueDepotIcon });
      markerBackground = new H.map.Marker(coords, { icon: this.depotIconBackground });
      this.partnerAndCollaboratorDepotsGroup.addObject(markerBackground);
      this.partnerAndCollaboratorDepotsGroup.addObject(marker);
    });
  }

  // show driver start/end points
  displayDriverStartEndPoint(lat, lon, colour) {
    // Production icon
    let icon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgDepotMarker.replace('markerColour', colour));

    // Dev override for driver icon to differentiate
    if (localStorage.getItem('devMode') === 'true') {
      icon = this.debugDepotIcon;
    }

    let coords, marker, markerBackground;
    coords = { lat: lat, lng: lon };
    marker = new H.map.Marker(coords, { icon: icon });
    markerBackground = new H.map.Marker(coords, { icon: this.depotIconBackground });
    this.driverStartEndPointsGroup.addObject(markerBackground);
    this.driverStartEndPointsGroup.addObject(marker);
  }

  testCreateMarkerIcon() {
    const icon = this.mapDummyComponent.svgIconsComponent.markerSvg;
  }

  // called from project-view to show a cluster
  addClusterCenter(zoomLevel, coords, centerId, stopPointIds, stopPointSequences, colour) {
    const clusterText = this.mapUtils.calculateClusterText(stopPointSequences);
    const icon = this.mapUtils.createClusterIcon(stopPointIds, clusterText, colour, this.mapDummyComponent.svgIconsComponent);
    const marker = new H.map.DomMarker(coords, { icon: icon });
    marker.setData({ id: centerId, stopPointIds: stopPointIds, clusterText: clusterText, colour: colour, selected: false });
    if (!this.zoomLevelsCentersGroups[zoomLevel]) {
      this.zoomLevelsCentersGroups[zoomLevel] = new H.map.Group();
      // add centers to map when loading the PP
      if (Number(zoomLevel) === this.currentZoomLevel) {
        this.zoomLevelsCentersParentGroup.addObject(this.zoomLevelsCentersGroups[zoomLevel]);
        if (this.zoomLevelsGroups[zoomLevel]) {
          this.stopPointsGroup.addObject(this.zoomLevelsGroups[zoomLevel]);
        }
      }
    }
    this.zoomLevelsCentersGroups[zoomLevel].addObject(marker);
  }

  editClusterCenter(zoomLevel, centerId, stopPointIds, stopPointSequences) {

  }

  emptyClusters() {
    this.zoomLevelsCentersParentGroup.removeAll();
    // Object.keys(this.zoomLevelsCentersGroups).forEach(zoomLevel => {
    //   const zoomLevelsCentersGroup = this.zoomLevelsCentersGroups[zoomLevel];
    // });
    this.zoomLevelsCentersGroups = {};
  }

  addAllStopsToZoomLevels() {
    this.zoomOutGroup.addObjects(this.stopPointMarkersToAdd);
    this.stopPointMarkersToAdd = [];
  }

  // ANCHOR add marker to the correct group to be displayed on map
  addStopPointToZoomLevel(id, maxZoomLevel, bulk, marker = null) {
    // maxZoomLevel is the minimum zoom level that the sp is visible
    // 20 is the full zoom in and 0 is full zoom out
    // so, in any level less than the max, the stop will be visible
    // if we have maxZoomLevel=9, the stop will be visible in zoom levels 8 to 0 (far zoom out)

    if (maxZoomLevel) {
      if (!this.zoomLevelsGroups[maxZoomLevel]) {
        this.zoomLevelsGroups[maxZoomLevel] = new H.map.Group();
        // add stops to map when loading the PP
        this.showLevelsStopPoints();
      }
      this.zoomLevelsGroups[maxZoomLevel].addObject(this.stopPointMarkerGroups[id]);
      // this.zoomLevelsGroups[maxZoomLevel].addObject(this.stopPointMapObjects[id]);
    } else {
      if (bulk) {
        this.stopPointMarkersToAdd.push(this.stopPointMarkerGroups[id]);
        // this.stopPointMarkersToAdd.push(this.stopPointMapObjects[id]);
      } else {
        this.zoomOutGroup.addObject(this.stopPointMarkerGroups[id]);
      }
    }
  }

  removeSecondaryMarkerFromStopPointMarkerGroup(id, iconType) {
    const markerGroup = this.stopPointMarkerGroups[id];
    if (markerGroup) {
      let markersToRemove = [];
      markerGroup.getObjects().forEach(marker => {
        const data = marker.getData();
        if (data.iconType === iconType) {
          markersToRemove.push(marker);
        }
      });
      markersToRemove.forEach(markerToRemove => {
        markerGroup.removeObject(markerToRemove);
      });
    }
  }

  // test add mini icons on a basic marker
  addSecondaryMarkersToStopPointMarkerGroup(data, icon, iconType) {
    const id = data.id;
    const coords = { lat: data.lat, lng: data.lon };
    const marker = new H.map.Marker(coords, { icon: icon });
    const dataForMarker = { ...data }
    dataForMarker.iconType = iconType;
    marker.setData(dataForMarker);
    this.stopPointMarkerGroups[id].addObject(marker);
  }

  // add or remove center sequence number or letter
  calculateNumberOrLetterToMarker(data, checkToRemove = true) {
    const sequence = data.sequence;
    let icons = [];
    if (sequence) {
      icons = this.getNumberIconWithSequence(sequence);
    } else if (data.merged && !data.complete && !data.cancelled) {
      if (data.mergedToText) {
        icons = this.getNumberIconWithSequence(data.mergedToText);
      } else {
        icons = [this.letterMIcon];
      }
    } else if (data.recurring && !data.complete && !data.cancelled) {
      icons = [this.letterRIcon];
    }
    if (icons.length) {
      this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'numberIcon');
      icons.forEach(icon => {
        this.addSecondaryMarkersToStopPointMarkerGroup(data, icon, 'numberIcon');
      });
    } else if (checkToRemove) {
      this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'numberIcon');
    }
  }

  // returns icon with the sequence number
  getNumberIconWithSequence(sequence) {
    const sequenceString = String(sequence);
    const numberOfDigits = sequenceString.length;
    const icons = [];
    if (numberOfDigits) {
      for (let i = 1; i <= numberOfDigits; i++) {
        if (this.numberIconsPerDigitLength[numberOfDigits][i][sequenceString[i - 1]]) {
          icons.push(this.numberIconsPerDigitLength[numberOfDigits][i][sequenceString[i - 1]]);
        } else {
          console.error('Could not find icon for sequence ' + sequence);
        }
      }
      return icons;
    } else {
      return null;
    }
  }

  // add or remove central large icon in marker
  calculateLargeSecondaryIcon(data, checkToRemove = true) {
    let icon = null;
    if (data.complete) {
      icon = this.basicCheckIcon;
    } else if (data.cancelledByRecipient) {
      icon = this.basicUserIcon;
    } else if (data.cancelled) {
      icon = this.basicCancelIcon;
    } else if ((data.timeWindowState === 'limited' || data.timeWindowState === 'late') && !this.globals.foodModeEnabled) {
      icon = this.basicLargeClockIcon;
    } else if (data.overweight && !data.recurring && !data.merged) {
      icon = this.basicWeightIcon;
    }
    if (icon) {
      if (checkToRemove) {
        this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'numberIcon');
        this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'largeIcon');
      }
      this.addSecondaryMarkersToStopPointMarkerGroup(data, icon, 'largeIcon');
    } else {
      this.calculateNumberOrLetterToMarker(data, checkToRemove);
      if (checkToRemove) {
        this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'largeIcon');
      }
    }
  }

  // add or remove top left icon in marker
  calculateTopLeftIcon(data, checkToRemove = true) {
    let icon = null;
    if (data.priority) {
      icon = this.asteriskIcon;
    }
    if (icon) {
      this.addSecondaryMarkersToStopPointMarkerGroup(data, icon, 'topLeftIcon');
    } else if (checkToRemove) {
      this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'topLeftIcon');
    }
  }

  // add or remove top right icon in marker
  calculateTopRightIcon(data, checkToRemove = true) {
    let icon = null;
    const stopPointData = this.projectProblemDataService.stopPoints[data.id];
    // show smart point icon (lock) on top right of any stop point that has a smart point object
    if (stopPointData.smartPoint || stopPointData.pudoPoint) {
      icon = this.smallSmartPointIcon;
    } else if (data.cancelledByRecipient) {
      icon = this.cancelledPortalIcon;
    } else {
      if (data.timeWindowState) {
        switch (data.timeWindowState) {
          case 'regular':
            icon = this.smallClockIcon;
            break;
          case 'strict':
            icon = this.smallRedClockIcon;
            break;
          case 'limited':
            icon = this.smallClockIcon;
            break;
          default:
            break;
        }
      }
    }
    if (icon) {
      this.addSecondaryMarkersToStopPointMarkerGroup(data, icon, 'topRightIcon');
    } else if (checkToRemove) {
      this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'topRightIcon');
    }
  }

  // add or remove checkbox with bottom right icon for marker
  calculateCheckboxIcon(data, checkToRemove = true) {
    let icon = null, checkboxIcon = null;
    if (data.selected || this.selectedStopPointIds.includes(data.id)) {
      // if sp is selected
      checkboxIcon = this.getCheckboxIconWithColours(data);
      icon = this.basicSmallCheckIcon;
    } else if (data.editColours.length || data.editSequenceStopPoints || data.editDirectSequenceStopPoints) {
      // if sp is not selected and edited
      checkboxIcon = this.getCheckboxIconWithColours(data);
      if (data.editSequenceStopPoints || data.editDirectSequenceStopPoints) {
        icon = this.basicBeforeAfterIcon;
      }
    }
    this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'checkboxIcon');
    if (checkboxIcon) {
      this.addSecondaryMarkersToStopPointMarkerGroup(data, checkboxIcon, 'checkboxIcon');
    }
    if (icon) {
      this.addSecondaryMarkersToStopPointMarkerGroup(data, icon, 'bottomRightIcon');
    } else if (checkToRemove) {
      this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'bottomRightIcon');
    }

  }

  // returns the checkbox icon with correct colours
  getCheckboxIconWithColours(data) {
    const defaultColour = data.enabled && !data.merged ? '#00aeba' : '#00aeba' + '70';
    const editColours = data.editColours.length ? data.editColours : [defaultColour];
    const editColoursString = String(editColours);
    if (!this.checkboxIconsPerColourCombination[editColoursString]) {
      let basicCheckboxSvg = this.mapDummyComponent.svgIconsComponent.basicCheckboxSvg;
      basicCheckboxSvg = this.mapUtils.replaceEditColours(editColours, basicCheckboxSvg);
      this.checkboxIconsPerColourCombination[editColoursString] = new H.map.Icon(basicCheckboxSvg);
    }
    return this.checkboxIconsPerColourCombination[editColoursString];
  }

  addHoverToMarker(data) {
    let icon = null;
    icon = this.invisibleIcon;
    const coords = { lat: data.lat, lng: data.lon };
    const marker = new H.map.Marker(coords, { icon: icon });
    const dataForMarker = { ...data }
    dataForMarker.iconType = 'hoverIcon';
    marker.setData(dataForMarker);
    marker.addEventListener('pointerenter', this.hoverIconRef);
    marker.addEventListener('pointerleave', this.unHoverIconRef);
    this.removeSecondaryMarkerFromStopPointMarkerGroup(data.id, 'hoverIcon');
    this.stopPointMarkerGroups[data.id].addObject(marker);
  }

  // create a group per SP for multi icon markers
  createStopPointGroup(data) {
    const id = data.id;
    const coords = { lat: data.lat, lng: data.lon };

    const icon = this.mapUtils.getMainMarkerIcon(data, this.mapDummyComponent.svgIconsComponent.basicMarkerSvg, this.stopPointMarkerIconsByColour);
    this.stopPointMarkerIconsByColour[this.mapUtils.getMarkerColour(data)] = icon;
    this.stopPointMapObjects[id] = new H.map.Marker(coords, { icon: icon });
    this.stopPointMapObjects[id].setData(data);

    if (this.stopPointMarkerGroups[id]) {
      this.stopPointMarkerGroups[id].removeAll();
    } else {
      this.stopPointMarkerGroups[id] = new H.map.Group();
      // make sure map has loaded
      this.map.addObject(this.stopPointMarkerGroups[id]);
      this.stopPointMarkerGroups[id].setData(data);
    }

    this.stopPointMarkerGroups[id].addObject(this.stopPointMapObjects[id]);

    this.calculateTopLeftIcon(data, false);
    this.calculateTopRightIcon(data, false);
    this.calculateLargeSecondaryIcon(data, false);
    this.calculateCheckboxIcon(data, false);
    this.addHoverToMarker(data);
  }


  // add a stop point to the map
  // stopPointMapObjects has all the sps that are in the map currently
  addStopPoint(data, bulk = false) {

    const id = data.id;

    if (!this.stopPointMapObjects[id]) {

      this.createStopPointGroup(data);
      this.addStopPointToZoomLevel(id, data.maxZoomLevel, bulk);

      // if (bulk) {
      //   this.addStopPointToZoomLevel(id, data.maxZoomLevel, bulk);

      //   // for (let i = 0; i < 10; i++) {
      //   //   // data[id] = (data[id]) + ((i + 1) + (1000 * (i + 1)))
      //   //   const newId = (id) + (10000 * (i + 1));
      //   //   const dataX = { ...data };
      //   //   dataX['id'] = newId;
      //   //   this.createStopPointGroup(dataX);
      //   //   this.addStopPointToZoomLevel(newId, data.maxZoomLevel, bulk);
      //   // }

      // } else {
      //   this.addStopPointToZoomLevel(id, data.maxZoomLevel, bulk);
      // }

      // this.zoomOutGroup.addObject(stopPointMapObject);
    } else if (this.mapUtils.stopPointChanged(data, this.stopPointMapObjects[id].getData())) {
      this.updateStopPoint(data, bulk);
    }
  }

  // update SP with new data
  updateStopPoint(data, bulk = false) {

    const id = data.id;
    if (this.stopPointMapObjects[id]) {
      if (!this.selectedStopPointIds.includes(data.id)) {
        data.selected = false;
      }

      this.stopPointMarkerGroups[id].setData(data);

      this.updateStopPointGeometry(data);
      if (this.mapUtils.stopPointColourChanged(data, this.stopPointMapObjects[id].getData())) {
        const icon = this.mapUtils.getMainMarkerIcon(data, this.mapDummyComponent.svgIconsComponent.basicMarkerSvg, this.stopPointMarkerIconsByColour);
        this.stopPointMarkerIconsByColour[this.mapUtils.getMarkerColour(data)] = icon;
        this.stopPointMapObjects[data.id].setIcon(icon);
      }
      this.stopPointMapObjects[id].setData(data);

      this.calculateTopLeftIcon(data);
      this.calculateTopRightIcon(data);
      this.calculateLargeSecondaryIcon(data);
      this.calculateCheckboxIcon(data);
      this.addHoverToMarker(data);

      // update selected (dom) icons
      // if (this.domMarkers[data.id]) {
      //   const domIconData = { ...data };
      //   domIconData.selected = true;
      //   this.domMarkers[data.id].setIcon(domIcon);
      //   this.domMarkers[data.id].setData(domIconData);
      //   this.domMarkers[data.id].setGeometry(coords);
      // }
    }
  }

  updateStopPointGeometry(data) {
    if (this.stopPointMarkerGroups[data.id]) {
      const coords = { lat: data.lat, lng: data.lon };
      this.stopPointMarkerGroups[data.id].getObjects().forEach(marker => {
        marker.setGeometry(coords);
      });
    }

  }

  // add DOM icons with dom id on top of normal ones to display for guide tour
  addMarkerIdsForGuide(normalMarkerId, disabledMarkerId, overweightMarkerId, timeWindowMarkerId, priorityMarkerId) {
    this.tourMarkersGroup.removeAll();
    if (this.stopPointMapObjects[normalMarkerId]) {
      const data = this.stopPointMapObjects[normalMarkerId].getData();
      const coords = { lat: data.lat, lng: data.lon };
      const icon = new H.map.DomIcon(this.mapUtils.createMarkerIcon(data, 'normalMarkerIcon', false, this.selectedStopPointIds, this.mapDummyComponent.svgIconsComponent));
      const marker = new H.map.DomMarker(coords, { icon: icon });
      this.tourMarkersGroup.addObject(marker);
    }
    if (this.stopPointMapObjects[disabledMarkerId]) {
      const data = this.stopPointMapObjects[disabledMarkerId].getData();
      const coords = { lat: data.lat, lng: data.lon };
      const icon = new H.map.DomIcon(this.mapUtils.createMarkerIcon(data, 'disabledMarkerIcon', false, this.selectedStopPointIds, this.mapDummyComponent.svgIconsComponent));
      const marker = new H.map.DomMarker(coords, { icon: icon });
      this.tourMarkersGroup.addObject(marker);
    }
    if (this.stopPointMapObjects[overweightMarkerId]) {
      const data = this.stopPointMapObjects[overweightMarkerId].getData();
      const coords = { lat: data.lat, lng: data.lon };
      const icon = new H.map.DomIcon(this.mapUtils.createMarkerIcon(data, 'overweightMarkerIcon', false, this.selectedStopPointIds, this.mapDummyComponent.svgIconsComponent));
      const marker = new H.map.DomMarker(coords, { icon: icon });
      this.tourMarkersGroup.addObject(marker);
    }
    if (this.stopPointMapObjects[timeWindowMarkerId]) {
      const data = this.stopPointMapObjects[timeWindowMarkerId].getData();
      const coords = { lat: data.lat, lng: data.lon };
      const icon = new H.map.DomIcon(this.mapUtils.createMarkerIcon(data, 'timeWindowMarkerIcon', false, this.selectedStopPointIds, this.mapDummyComponent.svgIconsComponent));
      const marker = new H.map.DomMarker(coords, { icon: icon });
      this.tourMarkersGroup.addObject(marker);
    }
    if (this.stopPointMapObjects[priorityMarkerId]) {
      const data = this.stopPointMapObjects[priorityMarkerId].getData();
      const coords = { lat: data.lat, lng: data.lon };
      const icon = new H.map.DomIcon(this.mapUtils.createMarkerIcon(data, 'priorityMarkerIcon', false, this.selectedStopPointIds, this.mapDummyComponent.svgIconsComponent));
      const marker = new H.map.DomMarker(coords, { icon: icon });
      this.tourMarkersGroup.addObject(marker);
    }
  }

  // add importer icons on map
  addImporterStopPoint(lat, lon, index) {
    let coords;
    coords = { lat: lat, lng: lon };
    this.importerMapObjects[index] = new H.map.Marker(coords, { icon: this.globalNormalIcon });
    this.importerMapObjects[index].setData({
      index: index
    });
    this.importerMarkersGroup.addObject(this.importerMapObjects[index]);
  }

  setMapBoundsForStopPoints() {
    this.map.getViewModel().setLookAtData({ bounds: this.stopPointsDepotsRoutesGroup.getBoundingBox() }, true);
  }

  // remove a SP from the map
  removeStopPoint(id) {
    if (this.stopPointMapObjects[id]) {
      this.removeStopPointFromSelectedGroup(this.stopPointMapObjects[id]);
      if (this.zoomOutGroup.contains(this.stopPointMarkerGroups[id])) {
        this.zoomOutGroup.removeObject(this.stopPointMarkerGroups[id]);
      }
      delete this.stopPointMapObjects[id];
    }
  }

  // used after removing a sp from pp
  // if cluster has more sps, remove sp from cluster and update cluster text label
  // if cluster has one sp left, remove cluster center and make the remaining sp visible at all levels
  removeStopPointFromClusterCenter(stopPointId, zoomLevel, centerId) {
    const zoomLevelMarkers = this.zoomLevelsCentersGroups[zoomLevel].getObjects();
    zoomLevelMarkers.forEach(marker => {
      if (marker.getData().id === centerId) {
        const stopPointIds = marker.getData().stopPointIds;
      }
    });

    // if there is only one sp in cluster
    if (this.zoomLevelsCentersGroups[zoomLevel].getObjects().length === 1) {
      this.zoomLevelsCentersParentGroup.removeObject(this.zoomLevelsCentersGroups[zoomLevel]);
    }
  }

  temporaryEmptyMap() {
    if (!this.mapEmptyForImporter) {
      this.stopPointsDepotsRoutesGroup.removeObject(this.stopPointsGroup);
      this.stopPointsDepotsRoutesGroup.removeObject(this.routesGroup);
    }
    this.importerMarkersGroup.removeAll();
    this.mapEmptyForImporter = true;
  }

  // display all SPs and routes normally
  refillMap() {
    this.importerMarkersGroup.removeAll();
    this.stopPointsDepotsRoutesGroup.addObject(this.stopPointsGroup);
    this.stopPointsDepotsRoutesGroup.addObject(this.routesGroup);
    this.mapEmptyForImporter = false;
  }

  // not used, if we need to use this we should empty all the cluster groups as well?
  emptyStopPoints() {
    this.stopPointMapObjects = {};
    this.stopPointsGroup.removeAll();
  }

  polylineChanged(polyline, linestring) {
    if (linestring.equals(polyline.getGeometry())) {
      return false;
    } else {
      return true;
    }
  }

  polylineStyleChanged(polyline, style) {
    if (JSON.stringify(style) === JSON.stringify(polyline.getStyle())) {
      return false;
    } else {
      return true;
    }
  }

  emptyDriverPickupRoutesGroup() {
    this.driverPickupRoutesGroup.removeAll();
  }

  // display a route on map
  addDriverRoute(encodedPolyline, data) {
    const colour = data.colour;
    const vehicleId = data.vehicleId;
    const dateTime = data.dateTime;
    const plateNumber = data.plateNumber;
    const driverName = data.driverName;
    const driverImage = data.driverImage;
    const companyImage = data.companyImage;
    const isStatic = data.isStatic;
    const angle = data.angle;
    const vehicleType = data.vehicleType;
    const driverFinishedStops = data.driverFinishedStops;
    const driverTotalStops = data.driverTotalStops;
    const driverTotalProgressDegrees = data.driverTotalProgressDegrees;
    const driverColour = data.driverColour;
    const showPickupBox = data.showPickupBox;
    const estimatedArrival = data.estimatedArrival;
    const polyline = require('@mapbox/polyline');
    const decodedPolyline = polyline.decode(encodedPolyline);
    const linestring = new H.geo.LineString();
    let linePoint;
    decodedPolyline.forEach((point, i) => {
      linePoint = {
        lat: point[0],
        lng: point[1]
      };
      linestring.pushPoint(linePoint);

      if (i === 0) {
        const coords = { lat: linePoint.lat, lon: linePoint.lng };
        this.trackVehicle(vehicleId, colour, coords, dateTime, plateNumber, driverName, driverImage, companyImage, isStatic, angle, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour, showPickupBox, estimatedArrival);
      }
    });
    const routeMapObject = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 7,
        strokeColor: colour,
      }
    });
    this.driverPickupRoutesGroup.addObject(routeMapObject);
  }

  // add polylines on map
  addEncodedElements(polylines) {
    const activeRouteSettingIds = [];
    const activeOrderIndexesByRouteSettingId = {};
    polylines.forEach(polylineObject => {
      if (!activeRouteSettingIds.includes(Number(polylineObject.routeSettingId))) { activeRouteSettingIds.push(Number(polylineObject.routeSettingId)); }
      if (!activeOrderIndexesByRouteSettingId[polylineObject.routeSettingId]) { activeOrderIndexesByRouteSettingId[polylineObject.routeSettingId] = []; }
      if (!activeOrderIndexesByRouteSettingId[polylineObject.routeSettingId].includes(Number(polylineObject.orderIndex))) {
        activeOrderIndexesByRouteSettingId[polylineObject.routeSettingId].push(Number(polylineObject.orderIndex));
      }
      const decodedPolyline = polyline.decode(polylineObject.encodedPolyline);
      const linestring = new H.geo.LineString();
      let linePoint;
      decodedPolyline.forEach((point) => {
        linePoint = {
          lat: point[0],
          lng: point[1]
        };
        linestring.pushPoint(linePoint);
      });

      if (!this.routeMapObjects[polylineObject.routeSettingId]) {
        this.routeMapObjects[polylineObject.routeSettingId] = {};
      }
      const style = {
        lineWidth: 7,
        strokeColor: polylineObject.colour,
      }
      if (!this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex]) {
        this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex] = new H.map.Polyline(linestring, {
          style: style
        });
        this.routesGroup.addObject(this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex]);
      } else if (this.mapUtils.polylineChanged(this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex], linestring)) {
        this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex].setGeometry(linestring);
      }
      if (this.polylineStyleChanged(this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex], style)) {
        this.routeMapObjects[polylineObject.routeSettingId][polylineObject.orderIndex].setStyle(style);
      }
    });

    const mapObjectsToDelete = {};
    Object.keys(this.routeMapObjects).forEach(routeSettingId => {
      Object.keys(this.routeMapObjects[routeSettingId]).forEach(orderIndex => {
        if (
          !activeRouteSettingIds.includes(Number(routeSettingId)) ||
          !activeOrderIndexesByRouteSettingId[routeSettingId].includes(Number(orderIndex))
        ) {
          if (this.routesGroup.contains(this.routeMapObjects[routeSettingId][orderIndex])) {
            this.routesGroup.removeObject(this.routeMapObjects[routeSettingId][orderIndex]);
            if (!mapObjectsToDelete[routeSettingId]) { mapObjectsToDelete[routeSettingId] = []; }
            mapObjectsToDelete[routeSettingId].push(orderIndex);
          }
        }
      });
    });
    Object.keys(mapObjectsToDelete).forEach(routeSettingId => {
      mapObjectsToDelete[routeSettingId].forEach(orderIndex => {
        delete this.routeMapObjects[routeSettingId][orderIndex];
      });
      if (this.routeMapObjects[routeSettingId].length === 0) {
        delete this.routeMapObjects[routeSettingId];
      }
    });
  }

  addPreviousRoutes(encodedPolyline, colour, time) {
    const arrivalTime = moment(time).format('HH:mm');
    const bubbleText = '<div>' + this.wasHereMsg + ': ' + arrivalTime + '</div>';
    const decodedPolyline = polyline.decode(encodedPolyline);
    const linestring = new H.geo.LineString();
    let linePoint;
    decodedPolyline.forEach((point, i) => {
      linePoint = {
        lat: point[0],
        lng: point[1]
      };
      linestring.pushPoint(linePoint);
    });
    const routeMapObject = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 6,
        strokeColor: colour,
      },
    });
    const invisiblePolyline = new H.map.Polyline(linestring, {
      style: {
        lineWidth: 15,
        strokeColor: 'rgba(255, 255, 255, 0)',
      },
      data: { bubbleText: bubbleText, arrivalTime: arrivalTime }
    });
    invisiblePolyline.addEventListener('tap', this.displayOldRouteInfoBubble);
    this.oldRouteGroup.addObjects([routeMapObject, invisiblePolyline]);
  }

  updatePreviousRoutes() {
    const polylines = this.oldRouteGroup.getObjects();
    polylines.forEach(polyline => {
      const data = polyline.getData();
      if (data) {
        data.bubbleText = '<div>' + this.wasHereMsg + ': ' + data.arrivalTime + '</div>';
        polyline.setData(data);
      }
    });
  }

  removePreviousRoutes() {
    this.oldRouteGroup.removeAll();
  }

  // add a draggable marker on map with drag events
  showDraggableMarker(lat, lng) {
    this.removeDraggableMarker();
    const draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
    draggableMarker.setGeometry({ lat: lat, lng: lng });
    draggableMarker.draggable = true;
    this.draggableMarkerGroup.addObjects([draggableMarker]);

    const bounds = this.draggableMarkerGroup.getBoundingBox();
    this.centerToMarker(bounds);

    // disable the default draggability of the underlying map
    // when starting to drag a marker object:
    this.map.addEventListener('dragstart', (ev) => {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        const targetPosition = this.map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        this.behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    const draggableMarkerService = this.draggableMarkerService;
    this.map.addEventListener('dragend', (ev) => {
      const target = ev.target;
      if (target instanceof H.map.Marker) {
        this.behavior.enable();
        draggableMarkerService.markerDrag(target.getGeometry()['lat'], target.getGeometry()['lng'], this.draggableForSameDayDelivery);
      }
    }, false);

    this.map.addEventListener('drag', (ev) => {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(this.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
      }
    }, false);
  }

  centerToMarker(bounds) {
    const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
    this.map.getViewModel().setLookAtData({ bounds: newBounds }, false);
  }

  removeDraggableMarker() {
    this.draggableMarkerGroup.removeAll();
  }

  // returns the driver icon with correct colours and initial
  getStaticVehicleIconWithColoursAndInitials(colour, driverName) {
    const initials = this.mapUtils.calculateDriverInitials(driverName);
    if (!this.staticVehicleIconsPerColourPerInitials[colour]) {
      this.staticVehicleIconsPerColourPerInitials[colour] = {};
    }
    if (!this.staticVehicleIconsPerColourPerInitials[colour][initials]) {
      const icon = this.mapDummyComponent.svgIconsComponent.svgVehicleMaker.replace('markerColour', colour).replace('initials', initials);
      this.staticVehicleIconsPerColourPerInitials[colour][initials] = new H.map.DomIcon(icon)
    }
    return this.staticVehicleIconsPerColourPerInitials[colour][initials];
  }

  // returns the driver icon with correct colours and initial
  getMovingVehicleIconWithColoursInitialsAndAngle(colour, driverName, angle) {
    const initials = this.mapUtils.calculateDriverInitials(driverName);
    if (!this.movingVehicleIconsPerColourPerInitialsPerAngle[colour]) {
      this.movingVehicleIconsPerColourPerInitialsPerAngle[colour] = {};
    }
    if (!this.movingVehicleIconsPerColourPerInitialsPerAngle[colour][initials]) {
      this.movingVehicleIconsPerColourPerInitialsPerAngle[colour][initials] = {};
    }
    if (!this.movingVehicleIconsPerColourPerInitialsPerAngle[colour][initials][angle]) {
      const icon = this.mapDummyComponent.svgIconsComponent.svgVehicleMarkerMoving
        .replace('markerColour', colour)
        .replace('initials', initials)
        .replace('rotationAngle', angle);
      this.movingVehicleIconsPerColourPerInitialsPerAngle[colour][initials][angle] = new H.map.DomIcon(icon)
    }
    return this.movingVehicleIconsPerColourPerInitialsPerAngle[colour][initials][angle];
  }

  // display driver position on map
  trackVehicle(vehicleId, colour, coords, dateTime, plateNumber, driverName, driverImage, companyImage, isStatic, angle, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour, showPickupBox, estimatedArrival) {
    // update vehicle info (sps & last seen time) on refresh if active hover box matches
    if (this.tappedDriverData['vehicleId'] == vehicleId) {
      this.driverLastSeen = moment(dateTime).format('HH:mm');
      this.driverFinishedStops = driverFinishedStops;
      this.driverTotalStops = driverTotalStops;
      this.driverTotalProgressDegrees = driverTotalProgressDegrees;
      this.pushTapDriverDataToDom();
    }

    // if driver image is empty, then replace with default driver image
    if (!driverImage) {
      driverImage = this.globals.driverDefaultBase64;
    }

    const icon = this.getStaticVehicleIconWithColoursAndInitials(colour, driverName);

    // New shipments view navigator icon behaviour
    if (this.router.url.split('/')[1] == 'newShipmentsView') {
      if (this.vehiclesMapObjects[vehicleId]) {
        if (isStatic) {
          const iconStatic = this.getStaticVehicleIconWithColoursAndInitials(colour, driverName)
          this.vehiclesMapObjects[vehicleId].setIcon(iconStatic);
        } else {
          const iconMoving = this.getMovingVehicleIconWithColoursInitialsAndAngle(colour, driverName, angle)
          this.vehiclesMapObjects[vehicleId].setIcon(iconMoving);
        }
      }
    }

    const vehicleCoords = { lat: coords.lat, lng: coords.lon };
    let shipmentDriverBox;

    if (showPickupBox || this.router.url.split('/')[1] == 'newShipmentsView') {
      shipmentDriverBox = `<div id="collaborator-driver-box-${vehicleId}" class="collaborator-driver-box">`;

      if (companyImage) {
        // different image class in food mode
        if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
          shipmentDriverBox += `<div class="food-company-image-container">`;
        } else {
          shipmentDriverBox += `<div class="company-image-container">`;
        }
        shipmentDriverBox += `<div class="company-image" style="background-image: url('data:image/png;base64,${companyImage}')">`;
        shipmentDriverBox += `</div>`;
        shipmentDriverBox += `</div>`;
      }

      // disable some elements in food mode
      if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && this.router.url.split('/')[1] == 'collaboratorOverview') {
        shipmentDriverBox += `<div class="food-pickup-label">${this.goingToPickupLabel}</div>`;
      }
      // else {
      //   shipmentDriverBox += `<div class="driver-info-container">`;
      //   shipmentDriverBox += `<div class="driver-image-container">`;
      //   shipmentDriverBox += `<div class="driver-image" style="background-image: url('data:image/png;base64,${driverImage['base64']}')"></div>`;
      //   shipmentDriverBox += `</div>`;
      //   shipmentDriverBox += `<div class="driver-info">`;
      //   shipmentDriverBox += `<div class="driver-name">${driverName}</div>`;
      //   // shipmentDriverBox += '<div class="driver-vehicle"><span *ngIf="' + this.vehicleTypes[vehicleType] + '" [innerHTML]="' + this.vehicleTypes[vehicleType].image + '"></span></div>';
      //   shipmentDriverBox += '<div class="driver-vehicle"><span>' + this.vehicleTypes[vehicleType].image + '</span></div>';
      //   shipmentDriverBox += `</div>`;
      //   shipmentDriverBox += `</div>`;
      //   shipmentDriverBox += `<div class="driver-arrival">`;
      //   shipmentDriverBox += `<div class="label">${this.comingForPickupLabel}:</div>`;
      //   shipmentDriverBox += `<div class="arrival">${estimatedArrival}</div>`;
      //   shipmentDriverBox += `</div>`;
      //   shipmentDriverBox += `</div>`;
      // }
      this.mapDummyComponent.collaboratorDriverBoxes.nativeElement.insertAdjacentHTML('afterbegin', shipmentDriverBox);
    }

    const dataObject = {
      shipmentDriverBox: shipmentDriverBox,
      vehicleId: vehicleId,
      plateNumber: plateNumber,
      driverName: driverName,
      coords: coords,
      dateTime: dateTime,
      driverImage: driverImage,
      vehicleType: vehicleType,
      driverFinishedStops: driverFinishedStops,
      driverTotalStops: driverTotalStops,
      driverTotalProgressDegrees: driverTotalProgressDegrees,
      driverColour: driverColour
    };

    const hoverBoxElement = document.getElementById('vehicle-hover-box');

    // Update vehicle & the values of its events
    if (this.vehiclesMapObjects[vehicleId]) {
      this.vehiclesMapObjects[vehicleId].setData(dataObject);
      // this.vehiclesMapObjects[vehicleId].setGeometry(vehicleCoords);

      let easeVehicleInterval;
      clearInterval(easeVehicleInterval);
      easeVehicleInterval = null;
      let currentCoords = this.vehiclesMapObjects[vehicleId].getGeometry();
      easeVehicleInterval = setInterval(() => {

        if (currentCoords.lat == vehicleCoords.lat && currentCoords.lng == vehicleCoords.lng) {
          clearInterval(easeVehicleInterval);
        } else {
          this.vehiclesMapObjects[vehicleId].setGeometry(this.easeVehicleMarker(vehicleCoords, currentCoords));
        }

      }, 100);

      // stop animation after 10s by default
      setTimeout(() => {
        clearInterval(easeVehicleInterval);
        easeVehicleInterval = null;
        this.vehiclesMapObjects[vehicleId].setGeometry(vehicleCoords); // re-position to final position for positioning fixes
        currentCoords = vehicleCoords;
      }, 10000);

      // show vehicle box (tap)
      // setVehicleMarker()
    }
    // Create vehicle if it doesn't exist & assign it hover & tap events
    else {
      this.vehiclesMapObjects[vehicleId] = new H.map.DomMarker(vehicleCoords, { icon: icon });
      this.vehiclesMapObjects[vehicleId].setData(dataObject);
      // this.vehiclesMapObjects[vehicleId].addEventListener('tap', this.displayInfoBubble);

      // New shipments view navigator icon behaviour
      if (this.router.url.split('/')[1] == 'newShipmentsView') {
        if (this.vehiclesMapObjects[vehicleId]) {
          if (isStatic) {
            let iconStaticSetup = this.mapDummyComponent.svgIconsComponent.svgVehicleMaker.replace('initials', this.mapUtils.calculateDriverInitials(driverName)).replace('markerColour', colour);
            const iconStatic = this.getStaticVehicleIconWithColoursAndInitials(colour, driverName)
            this.vehiclesMapObjects[vehicleId].setIcon(iconStatic);
          } else {
            let iconMovingSetup = this.mapDummyComponent.svgIconsComponent.svgVehicleMarkerMoving.replace('rotationAngle', angle).replace('markerColour', colour);
            const iconMoving = this.getMovingVehicleIconWithColoursInitialsAndAngle(colour, driverName, angle)
            this.vehiclesMapObjects[vehicleId].setIcon(iconMoving);
          }
        }
      }

      // Update vehicle in new shipments view && collaborator overview
      if (this.router.url.split('/')[1] == 'newShipmentsView' || this.router.url.split('/')[1] == 'collaboratorOverview') {
        setTimeout(() => {
          let targetPosition = this.map.geoToScreen(this.vehiclesMapObjects[vehicleId].getGeometry());
          this.driversIntervals.push(
            setInterval(() => {
              targetPosition = this.map.geoToScreen(this.vehiclesMapObjects[vehicleId].getGeometry());
              if (document.getElementById('collaborator-driver-box-' + vehicleId)) {
                document.getElementById('collaborator-driver-box-' + vehicleId).style.left = (Number(targetPosition.x) + 10) + 'px';

                if (companyImage) {
                  // [food mode]: place box closer to the navigator (because hidden elements make the box smaller)
                  if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
                    document.getElementById('collaborator-driver-box-' + vehicleId).style.top = (Number(targetPosition.y) - 35) + 'px';
                    document.getElementById('collaborator-driver-box-' + vehicleId).classList.add('smaller-box');
                  } else {
                    document.getElementById('collaborator-driver-box-' + vehicleId).style.top = (Number(targetPosition.y) - 130) + 'px';
                  }
                } else {
                  document.getElementById('collaborator-driver-box-' + vehicleId).style.top = (Number(targetPosition.y) - 70) + 'px';
                }
              }
            }, 10)
          );
        }, 500);
      }

      // show vehicle box (tap)
      this.setVehicleMarker(showPickupBox, vehicleId, driverImage, driverName, vehicleType, driverColour, hoverBoxElement);

      this.vehiclesGroup.addObject(this.vehiclesMapObjects[vehicleId]);
    }
  }

  private easeVehicleMarker(finalCoords, currentCoords) {
    const newCoords = {
      lat: this.lerp(currentCoords.lat, finalCoords.lat, 0.1),
      lng: this.lerp(currentCoords.lng, finalCoords.lng, 0.1)
    };
    currentCoords = newCoords;

    return newCoords;
  }

  private lerp(start, end, amount) {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return start + (end - start) * amount;
  }

  private setVehicleMarker(showPickupBox: any, vehicleId: any, driverImage: any, driverName: any, vehicleType: any, driverColour: any, hoverBoxElement: HTMLElement) {
    if (!showPickupBox) {
      this.vehiclesMapObjects[vehicleId].addEventListener('tap', (event) => {
        this.closeVehicleBox();
        this.driverHoverBoxTapped = true;
        const data = event.target.getData();

        this.vehicleId = vehicleId;
        this.driverName = driverName;
        this.driverLastSeen = moment(data.dateTime).format('HH:mm');
        this.driverFinishedStops = data.driverFinishedStops;
        this.driverTotalStops = data.driverTotalStops;
        this.vehicleType = vehicleType;
        this.driverTotalProgressDegrees = data.driverTotalProgressDegrees;
        this.driverColour = driverColour;
        if (driverImage['imageHash']) {
          this.loadDriverAvatarAsync(driverImage['imageHash']);
        } else if (driverImage['base64']) {
          this.driverImage = driverImage['base64'];
        }
        this.pushTapDriverDataToDom();

        let targetPosition = this.map.geoToScreen(event.target.getGeometry());
        hoverBoxElement.style.display = 'flex';
        this.driverMarkerInterval = setInterval(() => {
          targetPosition = this.map.geoToScreen(event.target.getGeometry());
          hoverBoxElement.style.left = (Number(targetPosition.x) + 10) + 'px';
          hoverBoxElement.style.top = targetPosition.y + 'px';
        }, 100);
      });

      //   // show vehicle box (hover)
      this.vehiclesMapObjects[vehicleId].addEventListener('pointerenter', (event) => {
        if (!this.driverHoverBoxTapped) {
          this.closeVehicleBox();
          const data = event.target.getData();

          this.vehicleId = vehicleId;
          this.driverName = driverName;
          this.driverLastSeen = moment(data.dateTime).format('HH:mm');
          this.driverFinishedStops = data.driverFinishedStops;
          this.driverTotalStops = data.driverTotalStops;
          this.vehicleType = vehicleType;
          this.driverTotalProgressDegrees = data.driverTotalProgressDegrees;
          this.driverColour = driverColour;
          if (driverImage['imageHash']) {
            this.loadDriverAvatarAsync(driverImage['imageHash']);
          } else if (driverImage['base64']) {
            this.driverImage = driverImage['base64'];
            console.log(this.driverImage);
          }
          this.pushTapDriverDataToDom();

          const targetPosition = this.map.geoToScreen(event.target.getGeometry());
          hoverBoxElement.style.left = (Number(targetPosition.x) + 10) + 'px';
          hoverBoxElement.style.top = targetPosition.y + 'px';
          hoverBoxElement.style.display = 'flex';
        }
      });

      // hide vehicle box (hover leave)
      this.vehiclesMapObjects[vehicleId].addEventListener('pointerleave', () => {
        if (!this.driverHoverBoxTapped) {
          hoverBoxElement.style.display = 'none';
        }
      });
    }
  }

  loadDriverAvatarAsync(imageHash) {
    this.imageUtils.fetchImagesViaHashes(`api/internal/v1/images/partner-drivers`, [imageHash]).then(avatars => {
      this.driverImage = avatars[0];
      this.pushTapDriverDataToDom();
    });
  }

  updateVehicleIcons(vehicleIdsToColours) {
    let icon, colour;
    Object.keys(this.vehiclesMapObjects).forEach(vehicleId => {
      colour = vehicleIdsToColours.vehicleId;
      icon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgVehicleMaker.replace('markerColour', colour));
      this.vehiclesMapObjects[vehicleId].setIcon(icon);
    });
  }

  disableSelectMode() {
    const selectButton = document.getElementById('select');
    if (this.selectModeEnabled || this.drawModeEnabled) {
      this.toggleSelectButtons();
    }
    this.selectModeEnabled = false;
    this.drawModeEnabled = false;
    this.emptySelectedGroup();
    selectButton.classList.remove('active');
  }

  toggleSelectButtons() {
    const singleSelectButton = document.getElementById('single-select');
    const drawSelectButton = document.getElementById('draw-select');
    if (this.selectModeEnabled && singleSelectButton.classList.contains('hidden')) {
      this.toggleSelectMode();
    } else if (this.drawModeEnabled && drawSelectButton.classList.contains('hidden')) {
      this.toggleDrawMode();
    } else {
      singleSelectButton.classList.toggle('hidden');
      drawSelectButton.classList.toggle('hidden');
    }
  }

  toggleSelectMode() {
    const selectButton = document.getElementById('select');
    if (this.selectModeEnabled) {
      this.selectModeEnabled = false;
      this.emptySelectedGroup();
      selectButton.classList.remove('active');
      this.milyMapMessage = '';
    } else {
      this.selectModeEnabled = true;
      selectButton.classList.add('active');
      this.toggleSelectButtons();
      this.milyMapMessage = this.selectOneByOneMsg;
    }
  }

  toggleDrawMode() {
    const selectButton = document.getElementById('select');
    if (this.drawModeEnabled) {
      this.drawModeEnabled = false;
      this.emptySelectedGroup();
      selectButton.classList.remove('active');
      this.emptyDrawMode();
      this.polygonGroup.removeAll();
      this.milyMapMessage = '';
    } else {
      this.drawModeEnabled = true;
      selectButton.classList.add('active');
      this.toggleSelectButtons();
      this.milyMapMessage = this.selectPolygonMsg;
    }
  }

  emptyDrawMode() {
    if (this.polygonLineStringPolyline) {
      this.map.removeObject(this.polygonLineStringPolyline);
    }
    this.polygonLineStringPolyline = null;
    this.polygonVerticesCoords = [];
    this.polygonLineString = new H.geo.LineString();
    this.polygonMarkersGroup.removeAll();
  }

  selectCluster(target) {
    // to select a cluster, make its data.selected = true and update its icon
    const markerData = target.getData();
    const icon = this.mapUtils.createClusterIcon(markerData.stopPointIds, markerData.colour, this.mapDummyComponent.svgIconsComponent, true);
    target.setIcon(icon);
    markerData.selected = true;
    target.setData(markerData);
    markerData.stopPointIds.forEach(stopPointId => {
      if (!this.selectedStopPointIds.includes(stopPointId)) {
        this.selectedStopPointIds.push(stopPointId);
        this.sendSelectedStopsToDom();
      }
    });
    if (this.selectedStopPointIds.length === 1) {
      this.milyMapMessage = this.selectFinalMsg;
    }
    markerData.stopPointIds.forEach(selectedStopPointId => {
      if (this.stopPointMapObjects[selectedStopPointId]) {
        this.changeStopPointIconToSelected(this.stopPointMapObjects[selectedStopPointId]);
      }
    });
  }

  changeClusterIconToUnSelected(cluster) {
    const markerData = cluster.getData();
    markerData.selected = false;
    const icon = this.mapUtils.createClusterIcon(markerData.stopPointIds, markerData.clusterText, markerData.colour, this.mapDummyComponent.svgIconsComponent);
    cluster.setIcon(icon);
    cluster.setData(markerData);
  }

  unSelectCluster(target) {
    // to un-select a cluster, make its data.selected = false and update its icon
    const markerData = target.getData();
    if (markerData.selected) {
      markerData.stopPointIds.forEach(id => {
        const index = this.selectedStopPointIds.indexOf(id);
        if (index > -1) {
          this.selectedStopPointIds.splice(index, 1);
          this.sendSelectedStopsToDom();
        }
        if (this.selectedStopPointIds.length === 0) {
          this.milyMapMessage = this.selectOneByOneMsg;
        }
      });
      this.changeClusterIconToUnSelected(target);
      markerData.stopPointIds.forEach(selectedStopPointId => {
        if (this.stopPointMapObjects[selectedStopPointId]) {
          this.changeStopPointIconToUnSelected(this.stopPointMapObjects[selectedStopPointId]);
        }
      });
    }
  }

  changeStopPointIconToSelected(target) {
    const markerData = target.getData();
    const id = markerData.id;
    markerData.selected = true;
    const icon = new H.map.Icon(this.mapUtils.createMarkerIcon(markerData, null, false, this.selectedStopPointIds, this.mapDummyComponent.svgIconsComponent));
    this.stopPointMapObjects[id].setData(markerData);
    this.stopPointMapObjects[id].setIcon(icon);
  }

  // ANCHOR
  changeStopPointsIconsToSelected(ids) {
    ids.forEach(id => {
      if (this.stopPointMapObjects[id]) {
        if (this.zoomOutGroup.contains(this.stopPointMapObjects[id])) {
          const data = this.stopPointMapObjects[id].getData();
          this.calculateCheckboxIcon(data);
        }
      }
    });
  }

  changeStopPointIconToUnSelected(target) {
    const markerData = target.getData();
    const id = markerData.id;
    this.changeStopPointsIconsToUnSelected([id]);
  }

  changeStopPointsIconsToUnSelected(ids) {
    ids.forEach(id => {
      if (this.stopPointMapObjects[id]) {
        const data = this.stopPointMapObjects[id].getData();
        data.selected = false;
        // this.addStopPoint(data, false);
        this.calculateCheckboxIcon(data);
        if (this.zoomOutGroup.contains(this.stopPointMapObjects[id])) {
          // this.stopPointMapObjects[id].setVisibility(true);
          // this.stopPointMapObjects[id].setData(data);
          // this.checkWhenDoneUnselectingStops(ids);
        }
      }
    });
  }

  checkWhenDoneUnselectingStops(ids) {
    // this.map.getEngine().addEventListener('render', this.onRenderChangeRef);
    // pass ids to event as evt.currentTarget.ids
    this.map.getEngine().ids = ids;
  }

  onRenderChange(evt) {
    if (this.map.getEngine() === evt.target) {
      console.count('rendered unselected');
      this.map.getEngine().removeEventListener('render', this.onRenderChangeRef);
      // this.zoomOutGroup.setVisibility(true);
      evt.currentTarget.ids.forEach(id => {
        if (this.domMarkers[id]) {
          // if (this.domMarkersGroup.contains(this.domMarkers[id])) {
          //   this.domMarkersGroup.removeObject(this.domMarkers[id]);
          // }
          delete this.domMarkers[id];
        }
      });

    }
  }

  addStopPointToSelectedGroup(target, changeIcon = true) {
    if (!this.selectedStopPointIds.includes(target.getData().id)) {
      this.selectedStopPointIds.push(target.getData().id);
      this.sendSelectedStopsToDom();
      if (changeIcon) {
        // this.changeStopPointIconToSelected(target);
        this.changeStopPointsIconsToSelected([target.getData().id]);
      }
      if (this.selectedStopPointIds.length === 1) {
        this.milyMapMessage = this.selectFinalMsg;
      }
    }
  }

  removeStopPointFromSelectedGroup(target, changeIcon = true) {
    if (target) {
      const markerData = target.getData();
      if (markerData.selected || this.selectedStopPointIds.includes(markerData.id)) {
        const index = this.selectedStopPointIds.indexOf(markerData.id);
        if (index > -1) {
          this.selectedStopPointIds.splice(index, 1);
          this.sendSelectedStopsToDom();
        }
        if (this.selectedStopPointIds.length === 0) {
          this.milyMapMessage = this.selectOneByOneMsg;
        }
        if (changeIcon) {
          this.changeStopPointIconToUnSelected(target);
        }
      }
    }
  }

  emptySelectedGroup() {
    if (this.selectedStopPointIds.length) {
      // first we empty the selectedStopPointIds array, because "createIcon" function checks if the id is selected
      const stopPointsToUnSelect = this.selectedStopPointIds;
      this.selectedStopPointIds = [];
      this.changeStopPointsIconsToUnSelected(stopPointsToUnSelect);
      Object.keys(this.zoomLevelsCentersGroups).forEach(zoomLevel => {
        if (this.zoomLevelsCentersGroups[zoomLevel]) {
          const clusters = this.zoomLevelsCentersGroups[zoomLevel].getObjects();
          clusters.forEach(cluster => {
            if (cluster.getData().selected) {
              this.unSelectCluster(cluster);
            }
          });
        }
      });
    }
  }

  createPolygonMarker(lat, lon) {
    const coords = { lat: lat, lng: lon };
    const marker = new H.map.Marker(coords, { icon: this.polygonVerticeCircleIcon });
    this.polygonMarkersGroup.addObject(marker);
    this.mergeModeEnabled = false;
    this.milyMapMessage = '';
  }

  createLineString(lat, lon) {
    if (!this.polygonLineStringPolyline) {
      const firstPointCoords = this.polygonVerticesCoords[0];
      this.polygonLineString.pushLatLngAlt(firstPointCoords.lat, firstPointCoords.lng, 0);
      this.polygonLineString.pushLatLngAlt(lat, lon, 0);
      this.polygonLineStringPolyline = new H.map.Polyline(this.polygonLineString, {
        style: {
          lineWidth: 5,
          strokeColor: '#00aeba',
        }
      });
      this.map.addObject(this.polygonLineStringPolyline);
    } else {
      this.polygonLineString.pushLatLngAlt(lat, lon, 0);
      this.polygonLineStringPolyline.setGeometry(this.polygonLineString);
    }
  }

  // for clustering
  showLevelsStopPoints() {
    let selectedUpdate = false;
    // remove all cluster groups lower than the current zoom level
    // add all cluster groups larger or equal than current zoom level
    this.clusterZoomLevels.forEach(level => {
      // if zoomed out hide stops
      if (level < this.currentZoomLevel) {
        if (this.zoomLevelsGroups[level]) {
          if (!this.stopPointsGroup.contains(this.zoomLevelsGroups[level])) {
            this.stopPointsGroup.addObject(this.zoomLevelsGroups[level]);
            if (this.drawModeEnabled) {
              selectedUpdate = true;
            }
          }
        }
      } else {
        if (this.zoomLevelsGroups[level]) {
          if (this.stopPointsGroup.contains(this.zoomLevelsGroups[level])) {
            this.stopPointsGroup.removeObject(this.zoomLevelsGroups[level]);
          }
        }
      }
    });

    // if we are closer than the min zoom with cluster
    // as we zoom far out, we need to see the clusters
    if (this.currentZoomLevel >= Math.min(...this.clusterZoomLevels)) {
      // remove previous cluster centers and add current level cluster centers
      this.zoomLevelsCentersParentGroup.removeAll();
      if (this.zoomLevelsCentersGroups[this.currentZoomLevel]) {
        this.zoomLevelsCentersParentGroup.addObject(this.zoomLevelsCentersGroups[this.currentZoomLevel]);
        if (this.drawModeEnabled) {
          selectedUpdate = true;
        }
      }
    }

    // if we go to a zoom level smaller than the min clustering level (we are far out),
    // show  min cluster centers level
    const minZoomLevel = Math.min(...this.clusterZoomLevels);
    if (this.currentZoomLevel < minZoomLevel) {
      // remove previous cluster centers and add most far out level cluster centers
      this.zoomLevelsCentersParentGroup.removeAll();
      if (this.zoomLevelsCentersGroups[minZoomLevel]) {
        this.zoomLevelsCentersParentGroup.addObject(this.zoomLevelsCentersGroups[minZoomLevel]);
        if (this.drawModeEnabled) {
          selectedUpdate = true;
        }
      }
    }

    if (selectedUpdate) {
      this.selectMarkersInPolygon();
    }

    if (this.selectModeEnabled) {
      if (this.zoomLevelsCentersGroups[this.currentZoomLevel]) {
        const visibleClusters = this.zoomLevelsCentersGroups[this.currentZoomLevel].getObjects();
        if (visibleClusters) {
          visibleClusters.forEach(cluster => {
            let allPointsSelected = true;
            cluster.getData().stopPointIds.forEach(stopPointId => {
              if (!this.selectedStopPointIds.includes(stopPointId)) {
                allPointsSelected = false;
              }
            });
            if (allPointsSelected) {
              this.selectCluster(cluster);
            } else {
              this.changeClusterIconToUnSelected(cluster);
            }
          });
        }
      }
    }

  }

  loadSmartPointsOnMap() {
    const smartPoints = this.projectProblemDataService.smartPointsArray;

    this.smartPointsGroup.removeAll();
    smartPoints.forEach(smartPoint => {
      // check if at least 1 stop point that is related to this smart point isn't completed (to show the appropriate icon)
      let areAllStopsCompleted = true;
      this.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
        let isCompleted = false;
        if (stopPoint.fulfillment_events[0]) {
          isCompleted = this.stopPointUtils.isCompleted(stopPoint.fulfillment_events[0].reason);
        }
        const hasSmartPoint = stopPoint.smartPoint;
        const isAssignedToThisSmartPoint = hasSmartPoint && stopPoint.smartPoint?.identifier == smartPoint.identifier;
        const hasPudoPoint = stopPoint.pudoPoint;
        const isAssignedToThisPudoPoint = hasPudoPoint && stopPoint.pudoPoint?.id == smartPoint.id
        const isAssignedToThis = isAssignedToThisSmartPoint || isAssignedToThisPudoPoint;
        
        if (isAssignedToThisSmartPoint && !isCompleted) {
          areAllStopsCompleted = false
        }
      });

      this.addSmartPointIconOnMap(smartPoint, areAllStopsCompleted);
    });
  }

  addSmartPointIconOnMap(smartPoint, isCompleted) {
    const iconSmartPoint = new H.map.DomIcon(this.svgIconsComponent.smartPointMarker);
    const iconSmartPointComplete = new H.map.DomIcon(this.svgIconsComponent.smartPointCompleteMarker);
    const smartPointDomIcon = isCompleted ? iconSmartPointComplete : iconSmartPoint;
    const smartPointMarker = new H.map.DomMarker({ lat: Number(smartPoint.address.lat), lng: Number(smartPoint.address.lon) }, { icon: smartPointDomIcon });
    smartPointMarker.setData({
      smartPoint: smartPoint
    });

    // hover
    smartPointMarker.addEventListener('pointerenter', (event) => {
      this.hoverSmartPointIcon(event);
    });

    // leave
    smartPointMarker.addEventListener('pointerleave', (event) => {
      this.hideSmartPointBoxes();
    });

    this.smartPointsGroup.addObject(smartPointMarker);
    this.map.addObject(this.smartPointsGroup);
  }

  setBasicMarkerIcons() {
    // const basicMarkerData = {
    //   id: 1,
    //   colour: '#00aeba',
    //   editColours: [],
    //   editSequenceStopPoints: null,
    //   lat: 40.64202,
    //   lon: 22.95652,
    //   sequence: '',
    //   enabled: true,
    //   priority: false,
    //   overweight: false,
    //   timeWindowState: 'default',
    //   edited: false,
    //   recurring: false,
    //   complete: false,
    //   cancelled: false,
    //   merged: false,
    //   mergedToText: '',
    //   maxZoomLevel: null,
    // };

    const basicGreyColour = '#999999';
    const darkGreyColour = '#666666';
    const basicRedColour = '#ff0000';
    const lastmilyBlue = '#00aeba';
    const invisible = '#00000000';

    this.globalNormalIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgMarkup.replace('markerColour', lastmilyBlue));
    this.invisibleIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgMarkup.replace('markerColour', invisible).replace('white', invisible));
    this.polygonVerticeCircleIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgCircle);

    if (this.globals.foodModeEnabled) {
      this.blueDepotIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgDepotMarker);
      this.greyDepotIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgDepotMarker);
    } else {
      this.blueDepotIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgDepotMarker.replace('markerColour', lastmilyBlue));
      this.greyDepotIcon = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgDepotMarker.replace('markerColour', darkGreyColour));
    }
    this.depotIconBackground = new H.map.Icon(this.mapDummyComponent.svgIconsComponent.svgMakerBackground);
    this.debugDepotIcon = new H.map.Icon('/assets/lastmilyAssets/driver-debug-icon.png');

    // small side icons
    let smallClockSvg = this.mapDummyComponent.svgIconsComponent.basicSmallClockSvg.replace('clockColour', basicGreyColour);
    let smallRedClockSvg = this.mapDummyComponent.svgIconsComponent.basicSmallClockSvg.replace('clockColour', basicRedColour);
    let smallSmartPointSvg = this.mapDummyComponent.svgIconsComponent.basicSmartPointSvg;
    let asteriskSvg = this.mapDummyComponent.svgIconsComponent.basicAsteriskSvg.replace('asteriskColour', basicGreyColour);
    let cancelledPortalSvg = this.mapDummyComponent.svgIconsComponent.basicCancelledPortalSvg.replace('markerColour', basicGreyColour);
    this.smallClockIcon = new H.map.Icon(smallClockSvg);
    this.smallRedClockIcon = new H.map.Icon(smallRedClockSvg);
    this.smallSmartPointIcon = new H.map.Icon(smallSmartPointSvg);
    this.asteriskIcon = new H.map.Icon(asteriskSvg);
    this.cancelledPortalIcon = new H.map.Icon(cancelledPortalSvg);

    // large middle icons
    let basicCheckSvg = this.mapDummyComponent.svgIconsComponent.basicCheckSvg;
    let basicCancelSvg = this.mapDummyComponent.svgIconsComponent.basicCancelSvg;
    let basicUserSvg = this.mapDummyComponent.svgIconsComponent.basicUserSvg;
    let basicWeightSvg = this.mapDummyComponent.svgIconsComponent.basicWeightSvg;
    let basicLargeClockSvg = this.mapDummyComponent.svgIconsComponent.basicLargeClockSvg;
    this.basicCheckIcon = new H.map.Icon(basicCheckSvg);
    this.basicCancelIcon = new H.map.Icon(basicCancelSvg);
    this.basicUserIcon = new H.map.Icon(basicUserSvg);
    this.basicWeightIcon = new H.map.Icon(basicWeightSvg);
    this.basicLargeClockIcon = new H.map.Icon(basicLargeClockSvg);

    // bottom right icons
    let basicSmallCheckSvg = this.mapDummyComponent.svgIconsComponent.basicSmallCheckSvg;
    let basicBeforeAfterSvg = this.mapDummyComponent.svgIconsComponent.basicBeforeAfterSvg;
    this.basicSmallCheckIcon = new H.map.Icon(basicSmallCheckSvg);
    this.basicBeforeAfterIcon = new H.map.Icon(basicBeforeAfterSvg);

    const textXPositionPerDigitNumberPerPosition = {
      1: {
        1: '-30',
      },
      2: {
        1: '-50',
        2: '-10',
      },
      3: {
        1: '-70',
        2: '-30',
        3: '10',
      }
    };

    const letterMSvg = this.mapDummyComponent.svgIconsComponent.numberMarkerSvg.replace('markerNumber', 'M').replace('XPosition', textXPositionPerDigitNumberPerPosition[1][1]);
    const letterRSvg = this.mapDummyComponent.svgIconsComponent.numberMarkerSvg.replace('markerNumber', 'R').replace('XPosition', textXPositionPerDigitNumberPerPosition[1][1]);
    this.letterMIcon = new H.map.Icon(letterMSvg);;
    this.letterRIcon = new H.map.Icon(letterRSvg);;

    for (let digitLength = 1; digitLength < 4; digitLength++) {
      if (!this.numberIconsPerDigitLength[digitLength]) {
        this.numberIconsPerDigitLength[digitLength] = {};;
      }
      for (let digitPosition = 1; digitPosition <= digitLength; digitPosition++) {
        if (!this.numberIconsPerDigitLength[digitLength][digitPosition]) {
          this.numberIconsPerDigitLength[digitLength][digitPosition] = {};;
        }
        for (let i = 0; i < 10; i++) {
          const iconSvg = this.mapDummyComponent.svgIconsComponent.numberMarkerSvg.replace('markerNumber', String(i)).replace('XPosition', textXPositionPerDigitNumberPerPosition[digitLength][digitPosition]);
          this.numberIconsPerDigitLength[digitLength][digitPosition][i] = new H.map.Icon(iconSvg);
        }
      }
    }

    for (let i = 0; i < 100; i++) {
      const iconSvg = this.mapDummyComponent.svgIconsComponent.numberMarkerSvg.replace('markerNumber', String(i + 1));
      this.secondaryNumbersIcons[i] = new H.map.Icon(iconSvg);
    }
  }

  initMap(depotLat, depotLon) {
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapDummyComponent.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 12,
        center: { lat: depotLat, lng: depotLon },
        pixelRatio: window.devicePixelRatio || 1
      }
    );
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    const viewProjectProblemService = this.viewProjectProblemService;
    const mapEvents = new H.mapevents.MapEvents(this.map);
    this.rightClickOnMap = (event) => {
      const target = event.target;

      // right click on SP or on map
      if (!this.disableRightClickOnMap && this.globals.accessRole != this.globals.teamMemberTypeConstants['VIEWER']) {
        if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
          if (target.getData() && !this.mergeModeEnabled && !this.sequenceModeEnabled && !this.disableRightClickOnStopPoint) {
            let allEnabled = true, allDisabled = true, allHighPriority = true, allNormalPriority = true;
            this.unHoverIcon();
            if (!this.selectModeEnabled && !this.drawModeEnabled) {
              if (!event.items) {
                event.items = [];
                const xy = this.map.geoToScreen(target.getGeometry());
                event.viewportX = xy.x;
                event.viewportY = xy.y;
              }
              let ids = [];
              if (target.getData().stopPointIds) {
                ids = target.getData().stopPointIds;
              } else if (target.getData().id) {
                ids = [target.getData().id];
              }
              ids.forEach(id => {
                if (!this.stopPointMapObjects[id].getData().enabled) {
                  allEnabled = false;
                } else {
                  allDisabled = false;
                }
                if (this.stopPointMapObjects[id].getData().priority) {
                  allNormalPriority = false;
                } else {
                  allHighPriority = false;
                }
              });
              this.contextMenuItems(event, ids, allEnabled, allDisabled, allHighPriority, allNormalPriority);
            } else {
              this.selectedStopPointIds.forEach(id => {
                if (!this.stopPointMapObjects[id].getData().enabled) {
                  allEnabled = false;
                } else {
                  allDisabled = false;
                }
                if (this.stopPointMapObjects[id].getData().priority) {
                  allNormalPriority = false;
                } else {
                  allHighPriority = false;
                }
              });
              this.contextMenuItems(event, this.selectedStopPointIds, allEnabled, allDisabled, allHighPriority, allNormalPriority);
            }
          }
        } else {
          let coords;
          if (event.viewportX && event.viewportY) {
            coords = this.map.screenToGeo(event.viewportX, event.viewportY);
          }
          if (this.sameDayDeliveryMode) {
            event.items.push(
              new H.util.ContextItem({
                label: this.pickupLocationMsg,
                callback: () => {
                  viewProjectProblemService.createStopFromMapClick(coords.lat, coords.lng, this.mapEmptyForImporter);
                }
              }),
            );
            event.items.push(
              new H.util.ContextItem({
                label: this.deliveryLocationMsg,
                callback: () => {
                  viewProjectProblemService.createStopFromMapClick(coords.lat, coords.lng, this.mapEmptyForImporter, true);
                }
              }),
            );
          } else if ((this.disableCreateStopPoint && this.mapEmptyForImporter) || !this.disableCreateStopPoint) {
            // disable right click unless we are in helper mode
            event.items.push(
              new H.util.ContextItem({
                label: this.addressLocationMsg,
                callback: () => {
                  viewProjectProblemService.createStopFromMapClick(coords.lat, coords.lng, this.mapEmptyForImporter);
                }
              }),
            );
          }
          if (this.globals.devMode) {
            event.items.push(
              new H.util.ContextItem({
                label: 'Driver location',
                callback: () => {
                  this.viewProjectProblemService.createVehicleLocation({ lat: coords.lat, lon: coords.lng });
                }
              }),
            );
          }
        }
      }
    };

    // generate context menu
    this.contextMenuItems = (event, stopPointIds, allEnabled, allDisabled, allHighPriority, allNormalPriority) => {
      if (this.globals.foodModeEnabled) {
        event.items.push(
          new H.util.ContextItem({
            label: this.returnLabel,
            callback: () => {
              this.viewProjectProblemService.returnStopPoints(stopPointIds);
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      }
      if (allEnabled) {
        event.items.push(
          new H.util.ContextItem({
            label: this.enableLabel
          }),
          new H.util.ContextItem({
            label: this.disableLabel,
            callback: () => {
              this.viewProjectProblemService.changeMultipleStopPointsEntityStatus(stopPointIds, true);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      } else if (allDisabled) {
        event.items.push(
          new H.util.ContextItem({
            label: this.enableLabel,
            callback: () => {
              this.viewProjectProblemService.changeMultipleStopPointsEntityStatus(stopPointIds, false);
            }
          }),
          new H.util.ContextItem({
            label: this.disableLabel
          }),
        );
      } else {
        event.items.push(
          new H.util.ContextItem({
            label: this.enableLabel,
            callback: () => {
              this.viewProjectProblemService.changeMultipleStopPointsEntityStatus(stopPointIds, false);
            }
          }),
          new H.util.ContextItem({
            label: this.disableLabel,
            callback: () => {
              this.viewProjectProblemService.changeMultipleStopPointsEntityStatus(stopPointIds, true);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      }
      event.items.push(H.util.ContextItem.SEPARATOR);
      event.items.push(
        new H.util.ContextItem({
          label: this.moveAnotherDriverMSG,
          callback: () => {
            this.viewProjectProblemService.moveStopPoints(stopPointIds);
          }
        }),
      );
      event.items.push(
        new H.util.ContextItem({
          label: this.visitBeforeMsg,
          callback: () => {
            this.sequenceModeEnabled = 'before';
            this.milyMapMessage = this.beforeAfterMsg.replace('BEFORE_AFTER', '<span class="bold-letters">' + this.beforeWordMsg + '</span>');
            this.sequenceStopPointIdsSelected = stopPointIds;
          }
        }),
        new H.util.ContextItem({
          label: this.visitAfterMsg,
          callback: () => {
            this.sequenceModeEnabled = 'after';
            this.milyMapMessage = this.beforeAfterMsg.replace('BEFORE_AFTER', '<span class="bold-letters">' + this.afterWordMsg + '</span>');
            this.sequenceStopPointIdsSelected = stopPointIds;
          }
        }),
      );
      if (this.globals.directlyBeforeAfterModificationsEnabled) {
        event.items.push(
          new H.util.ContextItem({
            label: this.visitDirectlyBeforeMsg,
            callback: () => {
              this.sequenceModeEnabled = 'directlyBefore';
              this.mapDummyComponent.milyMapMessage = this.beforeAfterMsg.replace('BEFORE_AFTER', '<span class="bold-letters">' + this.beforeWordMsg + '</span>');
              this.sequenceStopPointIdsSelected = stopPointIds;
            }
          }),
          new H.util.ContextItem({
            label: this.visitDirectlyAfterMsg,
            callback: () => {
              this.sequenceModeEnabled = 'directlyAfter';
              this.mapDummyComponent.milyMapMessage = this.beforeAfterMsg.replace('BEFORE_AFTER', '<span class="bold-letters">' + this.afterWordMsg + '</span>');
              this.sequenceStopPointIdsSelected = stopPointIds;
            }
          }),
        );
      }
      event.items.push(
        new H.util.ContextItem({
          label: this.removeModificationsMSG,
          callback: () => {
            this.viewProjectProblemService.unModifyStopPoints(stopPointIds);
            // this.disableSelectMode();
            this.disableSelectModeAfterUpdate = true;
          }
        }),
      );
      event.items.push(H.util.ContextItem.SEPARATOR);
      event.items.push(
        new H.util.ContextItem({
          label: this.moveAnotherProblemMSG,
          callback: () => {
            this.viewProjectProblemService.moveStopPointsToProblem(stopPointIds);
          }
        }),
      );
      event.items.push(H.util.ContextItem.SEPARATOR);
      if (allHighPriority) {
        event.items.push(
          new H.util.ContextItem({
            label: this.highPriorityMSG,
          }),
          new H.util.ContextItem({
            label: this.normalPriorityMSG,
            callback: () => {
              this.viewProjectProblemService.changeStopPointPriority(stopPointIds, false);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      }
      if (allNormalPriority) {
        event.items.push(
          new H.util.ContextItem({
            label: this.highPriorityMSG,
            callback: () => {
              this.viewProjectProblemService.changeStopPointPriority(stopPointIds, true);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
          new H.util.ContextItem({
            label: this.normalPriorityMSG,
          }),
        );
      }
      if (!allHighPriority && !allNormalPriority) {
        event.items.push(
          new H.util.ContextItem({
            label: this.normalPriorityMSG,
            callback: () => {
              this.viewProjectProblemService.changeStopPointPriority(stopPointIds, false);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
          new H.util.ContextItem({
            label: this.highPriorityMSG,
            callback: () => {
              this.viewProjectProblemService.changeStopPointPriority(stopPointIds, true);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      }
      event.items.push(H.util.ContextItem.SEPARATOR);
      event.items.push(
        new H.util.ContextItem({
          label: this.freeTimeWindowMSG,
          callback: () => {
            this.viewProjectProblemService.freeMultipleStopPointsTimeWindow(stopPointIds);
            // this.disableSelectMode();
            this.disableSelectModeAfterUpdate = true;
          }
        }),
      );
      if (
        this.entityStatus === this.globals.projectProblemEntityStatusConstants['DISPATCHED'] ||
        this.entityStatus === this.globals.projectProblemEntityStatusConstants['RE_DISPATCH']
      ) {
        event.items.push(
          new H.util.ContextItem({
            label: this.loadStopsMSG,
            callback: () => {
              this.viewProjectProblemService.loadStopPoints(stopPointIds);
              // this.disableSelectMode();
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      } else {
        event.items.push(
          new H.util.ContextItem({
            label: this.loadStopsMSG,
          }),
        );
      }
      event.items.push(H.util.ContextItem.SEPARATOR);
      event.items.push(
        new H.util.ContextItem({
          label: this.mergeToMSG,
          callback: () => {
            if (!this.selectedStopPointIds.length) { this.selectedStopPointIds = stopPointIds; }
            this.mergeModeEnabled = true;
            this.milyMapMessage = this.selectOneByOneMsg;
          }
        }),
      );
      event.items.push(
        new H.util.ContextItem({
          label: this.unMergeMSG,
          callback: () => {
            this.viewProjectProblemService.unMergeStopPoints(stopPointIds);
          }
        }),
      );
      event.items.push(H.util.ContextItem.SEPARATOR);
      event.items.push(
        new H.util.ContextItem({
          label: this.cancelMSG,
          callback: () => {
            this.viewProjectProblemService.cancelStopPoints(stopPointIds);
            this.disableSelectModeAfterUpdate = true;
          }
        }),
      );
      if (this.globals.canUserDeleteStopPoint) {
        event.items.push(
          new H.util.ContextItem({
            label: this.deleteMSG,
            callback: () => {
              this.viewProjectProblemService.deleteStopPoints(stopPointIds);
              this.disableSelectModeAfterUpdate = true;
            }
          }),
        );
      }
    }

    // click on map to select or view SP
    this.clickOnMap = (event) => {
      const target = event.target;
      if (
        this.drawModeEnabled &&
        // !(target instanceof H.map.DomMarker) &&
        (event.originalEvent.button === 0 || event.originalEvent.type === 'touchend')
      ) {
        if (target instanceof H.map.Marker) {
          if (target.getParentGroup() === this.polygonMarkersGroup) {
            this.createResizablePolygon(this.polygonLineString);
            this.emptyDrawMode();
            this.milyMapMessage = this.selectFinalMsg;
          }
        } else {
          const coords = this.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
          this.polygonVerticesCoords.push(coords);
          if (this.polygonVerticesCoords.length === 1) {
            this.polygonGroup.removeAll();
            this.createPolygonMarker(coords.lat, coords.lng);
            this.milyMapMessage = this.selectPolygonMsg;
          } else if (this.polygonVerticesCoords.length === 2) {
            this.createLineString(coords.lat, coords.lng);
          } else if (this.polygonVerticesCoords.length > 2) {
            this.createLineString(coords.lat, coords.lng);
          }
        }
      } else if (target instanceof H.map.Marker) {
        if (target.getParentGroup() === this.zoomLevelsCentersGroups[this.currentZoomLevel]) {
          if (this.selectModeEnabled) {
            if (target.getData().selected) {
              this.unSelectCluster(target);
            } else {
              this.selectCluster(target);
            }
          }
        }
      }
    };
    this.selectAddress = (event) => {
      const target = event.target;
      if (target instanceof H.map.Marker) {
        if (target.getData()) {
          const clickedStopPointIndex = target.getData().index;
          this.importerService.markerClicked(clickedStopPointIndex);
        }
      }
    };
    // ANCHOR
    // this.mapViewChange = (event) => {
    //   // this.currentZoomLevel = this.map.getZoom();
    //   // this.showLevelsStopPoints();
    // }
    this.map.addEventListener('tap', this.clickOnMap);
    this.stopPointsGroup.addEventListener('tap', this.markerOnClickListener, true);
    // this.domMarkersGroup.addEventListener('tap', this.markerOnClickListener, true);
    this.importerMarkersGroup.addEventListener('tap', this.selectAddress);
    this.map.addEventListener('contextmenu', this.rightClickOnMap);
    // TODO remove event listener check bind
    // this.map.addEventListener('mapviewchangeend', this.mapViewChange.bind(this));
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);

    const mapSettings = this.ui.getControl('mapsettings');
    mapSettings.setAlignment('top-left');
    const zoom = this.ui.getControl('zoom');
    zoom.setAlignment('right-middle');
    const scalebar = this.ui.getControl('scalebar');
    scalebar.setAlignment('bottom-center');

    this.displayInfoBubble = (evt) => {
      this.infoBubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content: evt.target.getData().bubbleText
      });
      this.ui.addBubble(this.infoBubble);
    };
    this.displayOldRouteInfoBubble = (evt) => {
      if (this.oldRouteInfoBubble && this.ui.getBubbles()) {
        this.ui.removeBubble(this.oldRouteInfoBubble);
        this.oldRouteInfoBubble.close();
      }
      this.oldRouteInfoBubble = new H.ui.InfoBubble(evt.target.getGeometry().extractPoint(0), {
        content: evt.target.getData().bubbleText
      });
      this.ui.addBubble(this.oldRouteInfoBubble);
    };

    if (!this.globals.collaboratorModeEnabled && !this.globals.foodModeEnabled && this.router.url.split('/')[1] != 'collaboratorOverview') {
      this.displayDepots();
    }

    this.stopPointsGroup.addObject(this.zoomOutGroup);
    this.stopPointsDepotsRoutesGroup.addObject(this.partnerAndCollaboratorDepotsGroup);
    // NOTE canvas warning here
    this.stopPointsDepotsRoutesGroup.addObject(this.stopPointsGroup);
    this.stopPointsDepotsRoutesGroup.addObject(this.routesGroup);

    this.stopPointsDepotsRoutesGroup.addObject(this.driverStartEndPointsGroup);
    this.stopPointsDepotsRoutesGroup.addObject(this.driverPickupRoutesGroup);
    this.map.addObject(this.zoomLevelsCentersParentGroup);

    // this.map.addObject(this.domMarkersGroup);
    this.map.addObject(this.stopPointsDepotsRoutesGroup);

    this.map.addObject(this.editingMarkerGroup);
    this.map.addObject(this.vehiclesGroup);
    this.map.addObject(this.oldRouteGroup);
    this.map.addObject(this.importerMarkersGroup);
    this.map.addObject(this.draggableMarkerGroup);
    this.map.addObject(this.polygonMarkersGroup);
    this.map.addObject(this.boundsGroup);
    this.map.addObject(this.tourMarkersGroup);
    // this.map.addObject(this.zoomOutGroup);
    this.boundsGroup.setVisibility(false);
    // this.stopPointsDepotsRoutesGroup.setVisibility(false);
    this.tourMarkersGroup.setZIndex(5);
    this.zoomLevelsCentersParentGroup.setZIndex(10);
    this.vehiclesGroup.setZIndex(20);
    this.currentZoomLevel = this.map.getZoom();
    this.showLevelsStopPoints();
    // let s = Object.prototype.toString;
    // const icon = new H.map.Icon(this.globalDomIconString);
    // this.draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 }, { icon: icon });
    // this.map.setBaseLayer(defaultLayers.vector.normal.map);
  }

  test() {
    // console.log(this.domMarkersGroup.getVisibility());
    // console.log(this.domMarkersGroup.getObjects().length);
    // this.zoomOutGroup.setVisibility(false);
  }

  test2() {
    // this.zoomOutGroup.setVisibility(true);
  }

  checkWhenDoneShowingOriginalStops() {
    // this.zoomOutGroup.setVisibility(false);
    // this.map.getEngine().addEventListener('render', this.checkWhenDoneShowingOriginalStopsRef);
  }

  checkWhenDoneShowingOriginalStopsEvent(evt) {
    if (this.map.getEngine() === evt.target) {
      // console.count('rendered orig');
      // this.map.getEngine().removeEventListener('render', this.checkWhenDoneShowingOriginalStopsRef);
      // this.zoomOutGroup.setVisibility(true);

    }
  }

  checkWhenDoneRendering() {
    // if there is no other loading msg, show the generic loading stops msg
    if (!this.milyMapMessage) {
      if (!this.globals.collaboratorModeEnabled && !this.globals.foodModeEnabled) {
        this.milyMapMessage = this.loadingStopsMsg;
      }
    }
    this.timer = moment();
    this.map.getEngine().removeEventListener('render', this.checkWhenDoneRenderingRef);
    this.map.getEngine().addEventListener('render', this.checkWhenDoneRenderingRef);
    // this.zoomOutGroup.setVisibility(false);

  }

  checkWhenDoneRenderingEvent(evt) {
    if (this.map.getEngine() === evt.target) {
      setTimeout(() => {
        this.milyMapMessage = '';
        // console.log(moment.duration(moment().diff(this.timer)).asSeconds() + 's for rendering');
        this.map.getEngine().removeEventListener('render', this.checkWhenDoneRenderingRef);
        this.milyMapMessage = '';
        this.stopPointMarkersToAdd = [];
      }, 500);
    }

  }

  // ANCHOR find what SPs are in the drawn polygon and select them
  selectMarkersInPolygon() {
    if (this.polygonGroup) {
      if (this.polygonGroup.getObjects().length) {
        const polygon = this.polygonGroup.getObjects()[0];
        // get the array with the coords of the lineString of the geo.polygon of the map.polygon
        const polygonCoords = polygon.getGeometry().getExterior().getLatLngAltArray();
        const polygonCoordsArray = [];
        // make the coords array from the lineString
        // lineString array is [lat, lon, altitude]
        for (let i = 0; i < polygonCoords.length - 1; i = i + 3) {
          polygonCoordsArray.push([polygonCoords[i], polygonCoords[i + 1]]);
        }
        if (polygonCoordsArray.length > 2) {
          // the last coords must me the same as the first
          polygonCoordsArray.push([polygonCoords[0], polygonCoords[1]]);
          const turfPolygon = turf.polygon([polygonCoordsArray]);
          // this.emptySelectedGroup();
          const stopPointIdsInArea = [], stopPointIdsToSelect = [], stopPointIdsToUnSelect = [];
          this.zoomOutGroup.getObjects().forEach(group => {
            if (group instanceof H.map.Group) {
              const data = group.getData();
              const pointCoords = { lat: data.lat, lng: data.lon };
              const point = turf.point([pointCoords.lat, pointCoords.lng]);
              const id = data.id;
              // if the marker is inside the polygon and is visible
              if (turf.booleanPointInPolygon(point, turfPolygon) && group.getVisibility()) {
                stopPointIdsInArea.push(id);
                if (!this.selectedStopPointIds.includes(id)) {
                  stopPointIdsToSelect.push(id);
                }
              }

            }
          });
          this.selectedStopPointIds.forEach(id => {
            if (!stopPointIdsInArea.includes(id)) {
              stopPointIdsToUnSelect.push(id);
            }
          });
          stopPointIdsToSelect.forEach(id => {
            this.addStopPointToSelectedGroup(this.stopPointMapObjects[id], false);
          });
          this.changeStopPointsIconsToSelected(stopPointIdsToSelect);
          stopPointIdsToUnSelect.forEach(id => {
            this.removeStopPointFromSelectedGroup(this.stopPointMapObjects[id]);
          });
          this.changeStopPointsIconsToUnSelected(stopPointIdsToUnSelect);
          this.selectedStopPointIds = stopPointIdsInArea;
          if (this.zoomLevelsCentersGroups[this.currentZoomLevel]) {
            this.zoomLevelsCentersGroups[this.currentZoomLevel].getObjects().forEach(center => {
              const pointCoords = center.getGeometry();
              const point = turf.point([pointCoords.lat, pointCoords.lng]);
              // if the marker is inside the polygon and is visible
              if (turf.booleanPointInPolygon(point, turfPolygon)) {
                const stopPointIds = center.getData().stopPointIds;
                if (stopPointIds) {
                  this.selectCluster(center);
                }
              }
            });
          }
        }
      }
    }
  }

  createResizablePolygon(lineString) {
    const svgCircle = this.mapDummyComponent.svgIconsComponent.svgCircle,
      polygon = new H.map.Polygon(
        new H.geo.Polygon(lineString),
        {
          style: { fillColor: 'rgba(0, 174, 186, 0.3)', lineWidth: 0 }
          // style: { fillColor: '#00aeba', lineWidth: 0 }
        }
      ),
      verticeGroup = new H.map.Group({
        // visibility: false
      }),
      mainGroup = new H.map.Group({
        volatility: true, // mark the group as volatile for smooth dragging of all it's objects
        objects: [polygon, verticeGroup]
      });

    // ensure that the polygon can receive drag events
    polygon.draggable = true;

    // create markers for each polygon's vertice which will be used for dragging
    polygon.getGeometry().getExterior().eachLatLngAlt((lat, lng, alt, index) => {
      const vertice = new H.map.Marker(
        { lat, lng },
        {
          icon: new H.map.Icon(svgCircle, { anchor: { x: 10, y: 10 } })
        }
      );
      vertice.draggable = true;
      vertice.setData({ 'verticeIndex': index });
      verticeGroup.addObject(vertice);
    });

    this.polygonGroup = mainGroup;
    // add group with polygon and it's vertices (markers) on the map
    this.map.addObject(this.polygonGroup);
    this.selectMarkersInPolygon();

    // event listener for vertice markers group to change the cursor to pointer
    verticeGroup.addEventListener('pointerenter', (evt) => {
      document.body.style.cursor = 'pointer';
    }, true);

    // event listener for vertice markers group to change the cursor to default
    verticeGroup.addEventListener('pointerleave', (evt) => {
      document.body.style.cursor = 'default';
    }, true);

    // event listener for vertice markers group to resize the geo polygon object if dragging over markers
    verticeGroup.addEventListener('drag', (evt) => {
      const pointer = evt.currentPointer,
        geoLineString = polygon.getGeometry().getExterior(),
        geoPoint = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

      // set new position for vertice marker
      evt.target.setGeometry(geoPoint);

      // set new position for polygon's vertice
      geoLineString.removePoint(evt.target.getData()['verticeIndex']);
      geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
      polygon.setGeometry(new H.geo.Polygon(geoLineString));

      // stop propagating the drag event, so the map doesn't move
      evt.stopPropagation();
    }, true);

    verticeGroup.addEventListener('dragend', (evt) => {
      this.selectMarkersInPolygon();
    }, true);
  }


  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.HERE_AT').subscribe((res: string) => {
      this.wasHereMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.VEHICLE').subscribe((res: string) => {
      this.vehicleTitle = res;
    }));
    this.listen.push(this.translate.get('VEHICLE.PLATE_NUMBER').subscribe((res: string) => {
      this.plateNumberTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.DRIVER').subscribe((res: string) => {
      this.driverTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.LAT').subscribe((res: string) => {
      this.latTitle = res;
    }));
    this.listen.push(this.translate.get('GENERIC.LON').subscribe((res: string) => {
      this.lonTitle = res;
    }));
    this.listen.push(this.translate.get('MAP.LAST_UPDATE_TITLE').subscribe((res: string) => {
      this.lastUpdateTitle = res;
    }));
    this.listen.push(this.translate.get('MAP.MOVE_ANOTHER_DRIVER_MSG').subscribe((res: string) => {
      this.moveAnotherDriverMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.MOVE_ANOTHER_PROBLEM_MSG').subscribe((res: string) => {
      this.moveAnotherProblemMSG = res;
    }));
    this.listen.push(this.translate.get('GENERIC.RETURN').subscribe((res: string) => {
      this.returnLabel = res;
    }));
    this.listen.push(this.translate.get('GENERIC.DISABLE').subscribe((res: string) => {
      this.disableLabel = res;
    }));
    this.listen.push(this.translate.get('GENERIC.ENABLE').subscribe((res: string) => {
      this.enableLabel = res;
    }));
    this.listen.push(this.translate.get('MAP.REMOVE_MODIFICATIONS_MSG').subscribe((res: string) => {
      this.removeModificationsMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.MERGE_TO').subscribe((res: string) => {
      this.mergeToMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.UN_MERGE').subscribe((res: string) => {
      this.unMergeMSG = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.CANCEL').subscribe((res: string) => {
      this.cancelMSG = res;
    }));
    this.listen.push(this.translate.get('GENERIC.DELETE').subscribe((res: string) => {
      this.deleteMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.BEFORE').subscribe((res: string) => {
      this.beforeMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.AFTER').subscribe((res: string) => {
      this.afterMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.DIRECTLY_BEFORE').subscribe((res: string) => {
      this.directlyBeforeMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.DIRECTLY_AFTER').subscribe((res: string) => {
      this.directlyAfterMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.VISIT_BEFORE').subscribe((res: string) => {
      this.visitBeforeMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.VISIT_AFTER').subscribe((res: string) => {
      this.visitAfterMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.VISIT_DIRECTLY_BEFORE').subscribe((res: string) => {
      this.visitDirectlyBeforeMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.VISIT_DIRECTLY_AFTER').subscribe((res: string) => {
      this.visitDirectlyAfterMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.BEFORE').subscribe((res: string) => {
      this.beforeWordMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.AFTER').subscribe((res: string) => {
      this.afterWordMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.BEFORE_INITIAL').subscribe((res: string) => {
      this.beforeInitialMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.AFTER_INITIAL').subscribe((res: string) => {
      this.afterInitialMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.EDIT_STOP_MSG').subscribe((res: string) => {
      this.editStopMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.FREE_TIME_WINDOW_MSG').subscribe((res: string) => {
      this.freeTimeWindowMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.LOAD_STOPS').subscribe((res: string) => {
      this.loadStopsMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.HIGH_PRIORITY_MSG').subscribe((res: string) => {
      this.highPriorityMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.NORMAL_PRIORITY_MSG').subscribe((res: string) => {
      this.normalPriorityMSG = res;
    }));
    this.listen.push(this.translate.get('MAP.SELECT_ONE_DESCRIPTION').subscribe((res: string) => {
      this.selectOneByOneMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.SELECT_POLYGON_DESCRIPTION').subscribe((res: string) => {
      this.selectPolygonMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.SELECT_FINAL_DESCRIPTION').subscribe((res: string) => {
      this.selectFinalMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.BEFORE_AFTER_DESCRIPTION').subscribe((res: string) => {
      this.beforeAfterMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.MERGE_DESCRIPTION').subscribe((res: string) => {
      this.mergeMsg = res;
    }));
    this.listen.push(this.translate.get('MAP.COMING_FOR_PICKUP').subscribe((res: string) => {
      this.comingForPickupLabel = res;
    }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS_LOCATION').subscribe((res: string) => {
      this.addressLocationMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.PICKUP_LOCATION').subscribe((res: string) => {
      this.pickupLocationMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.DELIVERY_LOCATION').subscribe((res: string) => {
      this.deliveryLocationMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.LOADING_STOPS').subscribe((res: string) => {
      this.loadingStopsMsg = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => {
      this.noNameLabel = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => {
      this.returnNameLabel = res;
    }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.GOING_TO_PICK_UP').subscribe((res: string) => {
      this.goingToPickupLabel = res;
    }));
    this.updatePreviousRoutes();
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    if (localStorage.getItem('devMode') === 'true') {
      this.globals.devMode = true;
      console.log('Dev mode enabled');
    }
  }

  public ngAfterViewInit() {
    this.setVehicleTypes();
    this.changeDetector.detectChanges(); // required because vehicle data is changed after change detection is ran

    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (this.globals.depotsDataDone) {
        let depotLat = this.globals.currentLat;
        let depotLon = this.globals.currentLon;

        if (this.globals.isInRoute('projectView')) {
          if (this.projectProblemDataService.projectDepotId) {
            clearInterval(dataRefreshIntervalId);
            const depot = this.depotUtils.getFirstDepotWithDepotId(this.projectProblemDataService.projectDepotId);
            if (depot) {
              depotLat = depot.companyDepot.address.lat;
              depotLon = depot.companyDepot.address.lon;
            }
            this.initMap(depotLat, depotLon);
          }
        } else {
          clearInterval(dataRefreshIntervalId);
          this.initMap(depotLat, depotLon);
        }
      }
    }

    this.setBasicMarkerIcons();

    this.vehicleTypesIcons = {
      0: this.mapDummyComponent.svgIconsComponent.vehicle0,
      1: this.mapDummyComponent.svgIconsComponent.vehicle1,
      2: this.mapDummyComponent.svgIconsComponent.vehicle2,
      3: this.mapDummyComponent.svgIconsComponent.vehicle3,
      4: this.mapDummyComponent.svgIconsComponent.vehicle4,
      5: this.mapDummyComponent.svgIconsComponent.vehicle5,
    }
  }

  ngOnDestroy() {
    this.stopPointsGroup.removeAll();
    this.stopPointsGroup.removeEventListener('tap', this.markerOnClickListener, true);
    // this.domMarkersGroup.removeEventListener('tap', this.markerOnClickListener, true);
    this.importerMarkersGroup.removeEventListener('tap', this.selectAddress);
    this.map.removeEventListener('contextmenu', this.rightClickOnMap);
    // this.map.removeEventListener('mapviewchangeend', this.mapViewChange);
    this.map.removeEventListener('tap', this.clickOnMap);
    if (this.driverMarkerInterval) {
      clearInterval(this.driverMarkerInterval);
    }
    if (this.driversIntervals.length) {
      this.driversIntervals.forEach(interval => {
        clearInterval(interval);
      })
    }
    this.listen.forEach(element => {
      element.unsubscribe();
    });
    for (const key in this.vehiclesMapObjects) {
      if (!this.vehiclesMapObjects.hasOwnProperty(key)) {
        continue;
      }
      // this.vehiclesMapObjects[key].removeEventListener('tap', this.displayInfoBubble);
    }
  }
}
