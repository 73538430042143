// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        lm-semicircle-chart{width: 100%}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbWljaXJjbGVjaGFydC13aWRnZXQuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7UUFDUSxvQkFBb0IsV0FBVyIsImZpbGUiOiJzZW1pY2lyY2xlY2hhcnQtd2lkZ2V0LmNvbXBvbmVudC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgICBsbS1zZW1pY2lyY2xlLWNoYXJ0e3dpZHRoOiAxMDAlfVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/widgets/semicirclechart-widget.component.ts"],"names":[],"mappings":";QACQ,oBAAoB,WAAW;;AAEvC,4VAA4V","sourcesContent":["\n        lm-semicircle-chart{width: 100%}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
