import { Injectable } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FormErrorsUtils {
    listen = [];
    genericFormError;
    
    constructor(
        private globals: Globals,
        public translate: TranslateService,
    ) { }

    // generic alert for 500 & 400 errors 
    checkResponseForErrorCodes(response) {
        if (response.status == 500) {
            this.getTranslations();
            alert(this.genericFormError);
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.FORM_ERROR').subscribe((res: string) => { this.genericFormError = res; }));
    }
}
