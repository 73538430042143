import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { DiscountsViewModelService } from '../discounts-viewmodel.service';
import { IDiscount_Dto } from '@app/api/models/discounts-dto';


@UntilDestroy()
@Component({
  templateUrl: 'discount-modal.component.html'
})
export class DiscountModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: DiscountsViewModelService;
  globals: any;
  saveBttn: LmButtonConfig;
  item: IDiscount_Dto;

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService
  )
  {
    const {data:{item}} = config;
    console.log(config);

    this.globals = config.data.globals;
    this.VM = config.data.VM;
    this.item = item ?? this.VM.newItem();
    this.item.price_lists = this.item.price_lists.map(item => item.id);
    console.log(this.item);
    this.updateButtons();
    this.saveBttn = {label: config.data.translations['SAVE'], command: () => this.ok()};
  }

  private isFormValid(): boolean {
    return Boolean((this.item.price_lists.length > 0) && this.item.percentage && this.item.name);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = () => {
    return this.VM.postItem({ discount: [this.item] })
      .pipe(take(1), untilDestroyed(this),)
      .subscribe(_ => {
        this.config.close();
        this.globals.getBasicServices();
      })
  }
  cancel = () => this.config.close();
}
