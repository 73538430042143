import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { SettingsBasicServicesViewModelService } from '../settings-basic-services-viewmodel.service';
import { ISettings_Basic_Service_Dto } from '@app/api/models/settings-services-dto';


@UntilDestroy()
@Component({
  templateUrl: 'new-basic-service-modal.component.html'
})
export class BasicServiceModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: SettingsBasicServicesViewModelService;
  globals: any;
  saveBttn: LmButtonConfig;
  item: ISettings_Basic_Service_Dto;

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService
  )
   {
    const {data:{item}} = config;

    this.globals = config.data.globals;
    this.VM = config.data.VM;
    this.item = item ?? this.VM.newItem()
    this.updateButtons();
    this.saveBttn = {label: config.data.translations['SAVE'], command: () => this.ok()};
  }

  private isFormValid(): boolean {
    return Boolean(this.item.name && this.item.type);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = () => {
    if (this.isFormValid()) return this.VM.postItem({ services: [this.item] })
      .pipe(take(1), untilDestroyed(this),)
      .subscribe(_ => {
        this.config.close();
        this.globals.getBasicServices();
      })
  }
  cancel = () => this.config.close();
}
