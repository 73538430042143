import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Globals } from '@app/services/globals';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export class PendingRequest {
    method: string;
    options: any;
    subscription: Subject<any>;
    data: any;

    constructor(method: string, options: any, subscription: Subject<any>, data: any) {
        this.method = method;
        this.options = options;
        this.subscription = subscription;
        this.data = data;
    }
}

@Injectable({
    providedIn: 'root'
})
export class DriverRegionRuleService {
    private savePolylineListeners = new Subject<any>();
    private updateDriverDataListeners = new Subject<any>();
    private requests$ = new Subject<any>();
    private queue: PendingRequest[] = [];

    // importRuleId = null;
    // ruleId = null;
    // triggerId = null;

    constructor(
        private http: HttpClient,
        public globals: Globals,
    ) { this.requests$.subscribe(request => this.execute(request)); }

    savePolylineListen(): Observable<any> {
        return this.savePolylineListeners.asObservable();
    }

    updateDriverDataListen(): Observable<any> {
        return this.updateDriverDataListeners.asObservable();
    }

    savePolyline(data) {
        this.savePolylineListeners.next(data);
    }

    updateDriverData(data) {
        this.updateDriverDataListeners.next(data);
    }

    getRulesData() {
        return this.http.get('api/v1/company/import-rules?returnRulesOnlyWithCustomAreaFlag=true');
    }

    getDriversRuleData(driverId) {
        return this.http.get('api/v1/company/import-rules?returnRulesOnlyWithCustomAreaFlag=true&ruleActionDriverIds=' + driverId);
    }

    filterRegionRules(rules) {
        const regionRules = [];
        rules.forEach(ruleData => {
            const rule = ruleData['importRule'];
            // if the trigger operation is of type INSIDE
            if (rule.rule.trigger.expression.operator_type === 'INS') {
                regionRules.push(rule);
            }
        });
        return regionRules;
    }

    polygonCorrectorForSubmit(polygon) {
        let polygonPoints = polygon.replace('POLYGON ((', '');
        polygonPoints = polygonPoints.replace('))', '');
        const points = polygonPoints.split(',');
        if (points[0] !== points[points.length - 1]) {
            polygon = polygon.replace('))', ',' + points[0] + '))');
        }
        return polygon;
    }

    formRule(polygon, oldRule, driverId) {
        polygon = this.polygonCorrectorForSubmit(polygon);
        if (!driverId && oldRule) {
            oldRule.rule.actions.forEach(oldAction => {
                if (oldAction.ruleActionXDriver) {
                    driverId = oldAction.ruleActionXDriver.driver_id;
                }
            });
        }
        const polygonConstantOperand: Operand = {
            id: oldRule ? oldRule?.rule?.trigger?.expression?.operands[0]?.id : null,
            value: this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT']['constant_name'],
            order: 0
        };
        const polygonValueOperand: Operand = {
            id: oldRule ? oldRule?.rule?.trigger?.expression?.operands[1]?.id : null,
            value: polygon,
            value_type: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['valueType'],
            order: 1
        };
        const triggerExpression: Expression = {
            id: oldRule ? oldRule?.rule?.trigger?.expression?.id : null,
            operator_type: this.globals.ruleExpressionOperatorTypeConstants['WITHIN_AREA'],
            operands: [polygonConstantOperand, polygonValueOperand]
        };
        const trigger: Trigger = {
            id: oldRule ? oldRule?.rule?.trigger?.id : null,
            expression: triggerExpression
        };
        const action: Action = {
            id: oldRule ? oldRule?.rule?.actions[0]?.id : null,
            action_type: this.globals.ruleActionTypeConstants['SET'],
            result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name'],
            ruleActionXDriver: {
                driver_id: driverId
            }
        };
        const rule: Rule = {
            id: oldRule ? oldRule?.rule?.id : null,
            name: '',
            trigger: trigger,
            actions: [action]
        };
        const finalRule: ImportRule = {
            id: oldRule ? oldRule?.id : null,
            rule: rule
        };
        return finalRule;
    }

    handleResponseError(error) {
        if (error.errors) {
            if (error.errors.ruleExpressionOperandCustomArea) {
                if (error.errors.ruleExpressionOperandCustomArea.value) {
                    error.errors.ruleExpressionOperandCustomArea.value.forEach(errorText => {
                        alert('Invalid Geometry!');
                        console.error(errorText);
                    });
                }
            }
        }

        this.queue.shift();
    }

    /** Call this method to add your http request to queue */
    invoke(method, params, options, data) {
        return this.addRequestToQueue(method, params, options, data);
    }

    private execute(requestData) {
        // somehow .finally is not working for me
        const data = { importRule: this.formRule(requestData.data.polygon, requestData.data.rule, requestData.data.id) };
        if (requestData.method === 'put') {
            const importRuleId = requestData.data.rule.id;
            const req = this.http.put('api/v1/company/import-rules/' + importRuleId, data).pipe(take(1)).subscribe(
                res => {
                    const sub = requestData.subscription;
                    sub.next(res);
                    this.queue.shift();
                    this.startNextRequest();
                },
                error => {
                    this.handleResponseError(error.error);
                }
            );
        } else {
            const req = this.http.post('api/v1/company/import-rules', data).pipe(take(1)).subscribe(
                res => {
                    const sub = requestData.subscription;
                    sub.next(res);
                    this.queue.shift();
                    this.startNextRequest();
                    this.updateDriverData(res);
                },
                error => {
                    this.handleResponseError(error.error);
                }
            );
        }
    }

    private addRequestToQueue(method, params, options, data) {
        const sub = new Subject<any>();
        const request = new PendingRequest(method, options, sub, data);
        this.queue.push(request);
        if (this.queue.length === 1) {
            this.startNextRequest();
        }
        return sub;
    }

    private startNextRequest() {
        // get next request, if any.
        if (this.queue.length > 0) {
            this.execute(this.queue[0]);
        }
    }

}

interface ImportRule {
    id: Number;
    rule: Object;
}
interface Rule {
    id: Number;
    name: String;
    trigger: Object;
    actions: Array<Object>;
}
interface Trigger {
    id: Number;
    expression: Object;
}
interface Action {
    id: Number;
    action_type: String;
    result_attribute: String;
    expression?: Object;
    actionValue?: Object;
    ruleActionXDriver?: Object;
    // result_model_attribute_type: String;
}
interface Expression {
    id: Number;
    operator_type: String;
    operands: Array<Object>;
}
interface Operand {
    id: Number;
    value_type?: String;
    value: String;
    order: Number;
}
interface ActionValue {
    id: Number;
    value_type: String;
    value: String;
}
