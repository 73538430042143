import { Router } from '@angular/router';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ProjectProblemModalComponent } from '@app/modals/project-problem-modal/project-problem-modal.component';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';

@Component({
  selector: 'app-map-dummy',
  templateUrl: './map-dummy.component.html',
  styleUrls: ['./map-dummy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapDummyComponent implements OnInit, AfterViewInit, OnDestroy {
  // /home/leuteris/lastmily/lastmily-stack/lastmily_pkgs/DESKT_lm_dev/lastmily-fe/src/styles/layout/grids.scss
  @ViewChild('map', { static: false }) public mapElement: ElementRef;
  @ViewChild('selectStopsButton', { static: false, read: ElementRef }) selectStopsButton: ElementRef;
  @ViewChild('collaboratorDriverBoxes', { static: false, read: ElementRef }) collaboratorDriverBoxes: ElementRef;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(ProjectProblemModalComponent, { static: false }) projectProblemModalComponent: ProjectProblemModalComponent;

  @Input() selectedStopPointIds: Array<Number>;
  @Input() milyMapMessage: String;
  @Input() stopPointDataForHover: StopPointDataForHover;
  @Input() tappedDriverData: TappedDriverData;
  @Input() vehicleTypes: Array<any>;
  @Input() smartPointsHoverBoxes: Array<any>;

  @Output() toggleSelectButtonsEvent = new EventEmitter<string>();
  @Output() toggleDrawModeEvent = new EventEmitter<string>();
  @Output() toggleSelectModeEvent = new EventEmitter<string>();
  @Output() closeVehicleBoxEvent = new EventEmitter<string>();
  @Output() openDriversGridEvent = new EventEmitter<string>();
  @Output() setVehicleTypesEvent = new EventEmitter<string>();

  constructor(
    public globals: Globals,
    public viewProjectProblemService: ViewProjectProblemService,
    public router: Router,
  ) { }

  toggleSelectButtons() {
    this.toggleSelectButtonsEvent.emit('');
  }

  toggleDrawMode() {
    this.toggleDrawModeEvent.emit('');
  }

  toggleSelectMode() {
    this.toggleSelectModeEvent.emit('');
  }

  closeVehicleBox() {
    this.closeVehicleBoxEvent.emit('');
  }

  openDriversGrid() {
    this.openDriversGridEvent.emit('');
  }

  setVehicleTypes() {
    this.setVehicleTypesEvent.emit('');
  }

  ngOnDestroy() { }
  ngAfterViewInit() { }
  ngOnInit() { }
}

export interface StopPointDataForHover {
  hoverStopPointLogo: String,
  hoverStopPointName: String,
  hoverStopPointCollaborator: String,
  hoverStopPointTimeWindow: String,
  hoverStopPointDeliveryLoad: String,
  hoverStopPointPickupLoad: String,
  hoverStopPointBarcode: String,
  hoverStopPointDriver: String,
  hoverStopPointRating: Number,
  hoverStopPointSequence: String,
  hoverStopPointFulfillmentTime: String,
  hoverDepotName: String,
  hoverDepotAddress: String,
  hoverDepotPhone: String,
}

export interface TappedDriverData {
  driverImage: String,
  driverName: String,
  driverLastSeen: String,
  driverFinishedStops: Number,
  driverTotalStops: Number,
  vehicleType: number,
  driverTotalProgressDegrees: Number,
  driverColour: String,
  driverDefaultBase64: String
}