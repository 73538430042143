import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DraggableMarkerService {

  private addDraggableMarkerListeners = new Subject<any>();
  private markerDragListeners = new Subject<any>();
  private removeDraggableMarkerListeners = new Subject<any>();
  private sameDayDeliveryModeListeners = new Subject<any>();

  addDraggableMarkerListen(): Observable<any> {
    return this.addDraggableMarkerListeners.asObservable();
  }

  markerDragListen(): Observable<any> {
    return this.markerDragListeners.asObservable();
  }

  removeDraggableMarkerListen(): Observable<any> {
    return this.removeDraggableMarkerListeners.asObservable();
  }

  sameDayDeliveryModeListen(): Observable<any> {
    return this.sameDayDeliveryModeListeners.asObservable();
  }

  addDraggableMarker(lat, lng, forSameDayDelivery = false) {
    this.addDraggableMarkerListeners.next({
      lat: lat,
      lng: lng,
      forSameDayDelivery: forSameDayDelivery
    });
  }

  markerDrag(lat, lng, forSameDayDelivery) {
    this.markerDragListeners.next({
      lat: lat,
      lng: lng,
      forSameDayDelivery: forSameDayDelivery
    });
  }

  removeDraggableMarker() {
    this.removeDraggableMarkerListeners.next(1);
  }

  sameDayDeliveryMode(enabled) {
    this.sameDayDeliveryModeListeners.next(enabled);
  }

}
