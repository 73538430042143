import { DepotUtils } from '@app/utils/depot-utils';
import { GenericService } from '../services/generic.service';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomerCollaboratorsModalComponent } from '@app/modals/customer-collaborators-modal/customer-collaborators-modal.component';
import { PartnerShipmentsGridComponent } from '@app/grids/partner-shipments-grid/partner-shipments-grid.component';
import { CollaboratorPaymentsGridComponent } from '@app/grids/collaborator-payments-grid/collaborator-payments-grid.component';
import { CollaboratorInvoicesGridComponent } from '@app/grids/collaborator-invoices-grid/collaborator-invoices-grid.component';
import { ModalService } from '@app/services/modal.service';
import * as moment from 'moment-timezone';
import { StopsCustomDateModalComponent } from '@app/modals/stops-custom-date-modal/stops-custom-date-modal.component';

@Component({
    selector: 'app-partner-view',
    templateUrl: './partner-view.component.html',
    styleUrls: ['./partner-view.component.scss']
})
export class PartnerViewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('container', { static: false }) container;

    @ViewChild('openPartnersButton', { static: false }) openPartnersButton;
    @ViewChild('openOverviewButton', { static: false }) openOverviewButton;
    @ViewChild('openShipmentsHistoryChartButton', { static: false }) openShipmentsHistoryChartButton;
    @ViewChild('openShipmentsHistoryFiltersButton', { static: false }) openShipmentsHistoryFiltersButton;
    @ViewChild('openMapButton', { static: false }) openMapButton;
    @ViewChild('openNewShipmentsButton', { static: false }) openNewShipmentsButton;
    @ViewChild('openPaymentsButton', { static: false }) openPaymentsButton;
    @ViewChild('openInvoicesButton', { static: false }) openInvoicesButton;
    @ViewChild('openSettingsButton', { static: false }) openSettingsButton;

    @ViewChild(StopsCustomDateModalComponent, { static: false }) stopsCustomDateModalComponent: StopsCustomDateModalComponent;
    @ViewChild(CustomerCollaboratorsModalComponent, { static: false }) customerCollaboratorsModalComponent: CustomerCollaboratorsModalComponent;
    @ViewChild(PartnerShipmentsGridComponent, { static: false }) partnerShipmentsGridComponent: PartnerShipmentsGridComponent;
    @ViewChild(CollaboratorInvoicesGridComponent, { static: false }) collaboratorInvoicesGridComponent: CollaboratorInvoicesGridComponent;
    @ViewChild(CollaboratorPaymentsGridComponent, { static: false }) collaboratorPaymentsGridComponent: CollaboratorPaymentsGridComponent;

    currentActiveRouterComponent;

    searchTextChanged = new Subject<string>();
    searchString: String = '';

    newShipmentsMode = 'chart'; // chart, filters

    stopsPeriods = [];
    selectedStopsPeriod;

    recentLabel;
    lastWeekLabel;
    lastMonthLabel;
    customLabel;
    allDepotsLabel;
    selectedDatesQuery = '';
    depots = [];

    // collaboratorId;
    collaboratorData;
    partnerCompanyId = null;
    partnerName;
    partnerLogo;

    partners = [];

    listen = [];

    constructor(
        private router: Router,
        public globals: Globals,
        public genericService: GenericService,
        public translate: TranslateService,
        private modalService: ModalService,
        private depotUtils: DepotUtils,
    ) {
        this.listen.push(this.genericService.listenOpenPartnerView().subscribe(() => {
            this.getPage();
        }));
    }

    onRouterOutletActivate(event) {
        this.currentActiveRouterComponent = event;
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    newButtonClicked() {
        this.modalService.openPartnerModal(null);
    }

    setActiveButton(buttonSelector) {
        if (document.querySelector('.white-bar-right-button.active')) {
            document.querySelector('.white-bar-right-button.active').classList.remove('active');
        }

        if (buttonSelector) {
            buttonSelector.nativeElement.classList.add('active');
        }
    }

    openMap() {
        this.globals.comingSoonAlert();
    }

    // openShipmentsHistoryChart() {
    //     this.mode = 'chart';
    // }

    // openShipmentsHistoryFilters() {
    //     this.mode = 'filters';
    // }

    getPage() {
        setTimeout(() => {
            const urlTitle = this.router.url.split('/')[2];

            switch (urlTitle) {
                case 'partners':
                    this.setActiveButton(this.openPartnersButton);
                    break;
                case 'collaboratorOverview':
                    this.setActiveButton(this.openOverviewButton);
                    break;
                case 'shipmentsHistory':
                    setTimeout(() => {
                        if (this.newShipmentsMode == 'chart') {
                            this.genericService.openShipmentsHistoryChart();
                            this.setActiveButton(this.openShipmentsHistoryChartButton);
                        } else if (this.newShipmentsMode == 'filters') {
                            this.genericService.openShipmentsHistoryFilters();
                            this.setActiveButton(this.openShipmentsHistoryFiltersButton);
                        }
                    }, 50);
                case 'newShipmentsView':
                    this.setActiveButton(this.openNewShipmentsButton);
                    break;
                case 'collaboratorInvoices':
                    this.setActiveButton(this.openInvoicesButton);
                    break;
                case 'collaboratorPayments':
                    this.setActiveButton(this.openPaymentsButton);
                    break;
                default:
                    this.setActiveButton(null);
            }
        }, 200);
    }

    stopsPeriodChange() {
        let startDate = '', endDate = '', timezone = '';
        timezone = moment.tz.guess();
        if (this.selectedStopsPeriod.value === 'custom') {
            this.stopsCustomDateModalComponent.openModal();
        } else {
            let recentParam = '';
            if (this.selectedStopsPeriod.value === 1) {
                recentParam = '&isRecent=1';
            }
            startDate = moment().subtract(this.selectedStopsPeriod.value, 'days').format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
            this.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone + recentParam;

            // set balance for selected period
            const dateObj = {
                start: startDate,
                end: endDate,
                timezone: timezone
            };
            this.modalService.updateStopPointsDate(dateObj);
        }
    }

    // for partners grid only
    onDepotChanged() {
        this.currentActiveRouterComponent.chartPeriodChange();
        this.currentActiveRouterComponent['pagesCount'] = 0;
        this.currentActiveRouterComponent.gridApi.setServerSideDatasource(this.currentActiveRouterComponent.createServerSideDatasource());
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.RECENT').subscribe((res: string) => { this.recentLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.CUSTOM').subscribe((res: string) => { this.customLabel = res; }));

        // add all depots option
        this.depots = this.depotUtils.getDepotsWithAllDepotsOption();

        this.stopsPeriods = [
            {
                label: this.recentLabel,
                value: 1
            },
            {
                label: this.lastWeekLabel,
                value: 6
            },
            {
                label: this.lastMonthLabel,
                value: 29
            },
            {
                label: this.customLabel,
                value: 'custom'
            },
        ];
        this.selectedStopsPeriod = {
            label: this.recentLabel,
            value: 1
        };
    }

    ngOnDestroy() { }

    ngAfterViewInit() {
        // get initial page's active button
        this.getPage();
    }

    ngOnInit() {
        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.genericService.stopPointsHistoryUpdate(text);
            });

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }
}
