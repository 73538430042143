import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
// import { Globals } from '@app/services/globals';
import { TimePickerComponent } from '@app/time-picker/time-picker.component';

@Component({
    selector: 'app-time-picker-modal',
    templateUrl: './time-picker-modal.component.html',
    styleUrls: ['./time-picker-modal.component.scss']
})
export class TimePickerModalComponent implements OnInit, OnDestroy {
    @Output() timeSelected = new EventEmitter<string>();
    @ViewChild(TimePickerComponent, { static: false }) timePickerComponent: TimePickerComponent;
    @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
    @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;

    // listen;

    constructor(
        // public globals: Globals,
    ) { }

    setTime(time) {
        this.timePickerComponent.setTime(time);
    }

    ngOnDestroy() {    }

    ngOnInit() {
    }

    toggleModal() {
        this.modal.nativeElement.classList.toggle('closed');
        this.modal.nativeElement.classList.toggle('open');
        this.modalBackground.nativeElement.classList.toggle('hidden');
        if (this.modal.nativeElement.classList.contains('closed')) {
            this.timePickerComponent.resetTimePicker();
        }
    }

    loadProject() { }

    okButtonClick() {
        this.timeSelected.emit(this.timePickerComponent.selectedTime);
        this.toggleModal();
    }

}
