// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  -webkit-user-select: none;
          user-select: none;
}

#date-range-picker-modal {
  width: 50vw;
  left: 25vw;
  top: 0;
}

.modal-header {
  text-align: center;
  height: auto;
  padding: 24px;
}

.modal-body-service-charges {
  overflow: visible;
  background-color: white;
  padding: 30px;
}

#calendar-container {
  display: flex;
  justify-content: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRhdGUtcmFuZ2UtcGlja2VyLW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0kseUJBQUE7VUFBQSxpQkFBQTtBQUNKOztBQUVBO0VBQ0ksV0FBQTtFQUNBLFVBQUE7RUFDQSxNQUFBO0FBQ0o7O0FBRUE7RUFDSSxrQkFBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0FBQ0o7O0FBRUE7RUFDSSxpQkFBQTtFQUNBLHVCQUFBO0VBQ0EsYUFBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLHVCQUFBO0FBQ0oiLCJmaWxlIjoiZGF0ZS1yYW5nZS1waWNrZXItbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIqIHtcbiAgICB1c2VyLXNlbGVjdDogbm9uZTtcbn1cblxuI2RhdGUtcmFuZ2UtcGlja2VyLW1vZGFsIHtcbiAgICB3aWR0aDogNTB2dztcbiAgICBsZWZ0OiAyNXZ3O1xuICAgIHRvcDogMDtcbn1cblxuLm1vZGFsLWhlYWRlciB7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIGhlaWdodDogYXV0bztcbiAgICBwYWRkaW5nOiAyNHB4O1xufVxuXG4ubW9kYWwtYm9keS1zZXJ2aWNlLWNoYXJnZXMge1xuICAgIG92ZXJmbG93OiB2aXNpYmxlO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xuICAgIHBhZGRpbmc6IDMwcHg7XG59XG5cbiNjYWxlbmRhci1jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cbiAgICAuZmxhdHBpY2tyLWNhbGVuZGFyIHt9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/modals/date-range-picker-modal/date-range-picker-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;UAAA,iBAAA;AACJ;;AAEA;EACI,WAAA;EACA,UAAA;EACA,MAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,iBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;AACJ;AAEA,gjCAAgjC","sourcesContent":["* {\n    user-select: none;\n}\n\n#date-range-picker-modal {\n    width: 50vw;\n    left: 25vw;\n    top: 0;\n}\n\n.modal-header {\n    text-align: center;\n    height: auto;\n    padding: 24px;\n}\n\n.modal-body-service-charges {\n    overflow: visible;\n    background-color: white;\n    padding: 30px;\n}\n\n#calendar-container {\n    display: flex;\n    justify-content: center;\n\n    .flatpickr-calendar {}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
