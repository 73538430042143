import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, concat, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { MapRulesModalComponent } from './map-rules-modal/map-rules-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-rules-modal',
  templateUrl: './rules-modal.component.html',
  styleUrls: ['./rules-modal.component.scss']
})

export class RulesModalComponent implements OnInit, OnDestroy {

  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @ViewChild(MapRulesModalComponent, { static: false }) mapRulesModalComponent: MapRulesModalComponent;
  @ViewChild('customerSelect', { static: false }) ngSelect: NgSelectComponent;

  vehicleTypes = [];
  availableVehicleTypes = [];
  listen = [];
  andButtonHidden = false;
  thenButtonHidden = false;
  doneButtonHidden = true;
  editMode = false;
  isClickedOnce = false;

  importRuleId = null;
  ruleId = null;
  triggerId = null;
  multipleTriggerId = null;

  finalRule = {};
  triggerLabels = [];
  actionLabels = [];
  triggers = [];
  currentCondition;
  ifStatementsLabels = [];
  ifStatements = [];
  thenStatements = [];
  thenStatementsLabels = [];
  subRuleLabels = {
    word: '',
    volume: '',
    payAmount: '',
    region: '',
    dimension: '',
    timeWindow: '',
    duration: '',
    volumes: '',
    priority: '',
    vehicles: '',
    drivers: '',
    merge: '',
    vehicleTypes: '',
  };
  operatorLabels = {};
  normalMsg;
  highMsg;
  lessThanMsg = '';
  equalToMsg = '';
  graterThanMsg = '';
  minutesLabel;
  secondsLabel;
  andLabel;
  multipleDriversLabel;
  matchedColumnsObject = {};
  matchedColumns = [];
  packageTypeColumns = [];
  volumeTypesList = [];
  dimensionsTypesList = [];
  dimensionsColumns = [];
  operatorsDropdownList = [];
  selectedDropdownOperator;
  selectedColumn;
  selectedString;
  selectedVolume;
  selectedPayAmount;
  polygon = '';
  selectedConstant;
  selectedOperator;
  selectedActionType;
  selectedVolumes = {};
  activeStatement = '';
  activeTriggerIndex = null;
  activeActionIndex = null;

  openFormElement;
  selectedSubRule;

  priorityOn = true;

  selectedTimeWindow;
  timeWindows = [];
  durationUnformatted = '02:00';
  durationSeconds = [120];
  durationOptions;

  driversObject = {};
  drivers = [];
  vehicles = [];
  selectedDriver: any = <any>[];
  // selectedVehicles: any = <any>[];
  selectedVehicleIds = [];
  customers: Observable<any>;
  customersLoading = false;
  selectedCustomer;
  customersInput = new Subject<string>();
  noNameLabel = '';
  selectedWidth = null;
  selectedLength = null;
  selectedHeight = null;
  selectedDimensionSize = null;
  selectedVehicleTypes = [];

  bicycleLabel = '';
  scooterLabel = '';
  carLabel = '';
  vanLabel = '';
  largeVanLabel = '';
  truckLabel = '';

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private settingsService: SettingsService,
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    public genericService: GenericService,
  ) {
    this.initializeSlider();
    this.listen.push(this.settingsService.updateTimeWindowGridListen().subscribe(() => {
      this.http.get('api/v1/companies/' + this.globals.companyId + '/company-time-window').subscribe(response => {
        this.timeWindows = response['items'];
      });
    }));
  }

  customerSelectFocusOut() {
    if (!this.selectedCustomer) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  getRuleData(id) {
    this.http.get('api/v1/company/import-rules/' + id).pipe(take(1)).subscribe(response => {
      const openGroup = document.querySelector('.statements-group.open');
      if (openGroup) {
        openGroup.classList.remove('open');
      }
      this.andButtonHidden = false;
      this.thenButtonHidden = true;
      this.doneButtonHidden = false;
      this.loadRule(response['item']['importRule']);
    });
  }

  loadRule(ruleObject: ImportRule) {
    this.editMode = true;
    this.importRuleId = ruleObject['id'];
    this.ruleId = ruleObject['rule']['id'];
    this.triggerId = ruleObject['rule']['trigger']['id'];
    const triggerOperatorType = ruleObject.rule['trigger']['expression']['operator_type'];
    const actions = ruleObject.rule['actions'];
    this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
    if (triggerOperatorType === this.globals.ruleExpressionOperatorTypeConstants['AND']) {
      this.multipleTriggerId = ruleObject.rule['trigger']['expression']['id'];
      ruleObject.rule['trigger']['expression']['operands'].forEach(operand => {
        const expression = operand.expression;
        this.loadSubRule(expression['operator_type'], expression['operands']);
        this.saveTrigger(operand);
        this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
      });
    } else {
      const trigger = ruleObject.rule['trigger'];
      this.loadSubRule(triggerOperatorType, trigger['expression']['operands']);
      this.saveTrigger(trigger);
    }
    this.listen.push(this.translate.get('RULES.THEN_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
    const selectedVehicleIds = [], selectedVehicleTypes = [];
    actions.forEach(action => {
      if (
        action['result_attribute'] === this.globals.stopPointImportFieldConfigs['MATCHER_ONLY_ATTRIBUTE_LOAD']['constant_name'] ||
        action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CALCULATED_LOAD']['constant_name']
      ) {
        this.selectedActionType = 'volumes';
        action['expression']['operands'].forEach(volumeAction => {
          this.selectedVolumes[volumeAction['expression']['operands'][1]['value']] = volumeAction['expression']['operands'][0]['value'];
        });
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_DURATION']['constant_name']) {
        this.selectedActionType = 'duration';
        const duration = action['actionValue']['value'];
        this.durationSeconds = [moment.duration(duration).asSeconds()];
        this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(duration, true);
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_COMPANY_TIME_WINDOW_CATEGORY_ID']['constant_name']) {
        this.selectedActionType = 'timeWindow';
        const selectedTimeWindowId = action['companyTimeWindowCategoryId'];
        this.timeWindows.forEach(timeWindow => {
          if (timeWindow['CompanyTimeWindowCategory']['id'] === selectedTimeWindowId) {
            this.selectedTimeWindow = timeWindow;
          }
        });
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['STOP_POINT_PRIORITY']['constant_name']) {
        this.selectedActionType = 'priority';
        if (action['actionValue']['value'] === this.globals.stopPointPriorityConstants['HIGH']) {
          this.priorityOn = true;
        } else if (action['actionValue']['value'] === this.globals.stopPointPriorityConstants['NORMAL']) {
          this.priorityOn = false;
        }
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name']) {
        this.selectedActionType = 'drivers';
        this.selectedDriver = this.driversObject[action.ruleActionXDriver.driver_id];
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_VEHICLE_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name']) {
        this.selectedActionType = 'vehicles';
        // let customLabel = action['vehicle']['plate_number'];
        // if (action['drivers']) {
        //   if (action['drivers'].length) {
        //     if (action['drivers'].length > 1) {
        //       customLabel += ' - ' + this.multipleDriversLabel;
        //     } else {
        //       customLabel += ' - ' + action['drivers'][0].userProfile.name;
        //     }
        //   }
        // }
        // const vehicle = {
        //   vehicle: {
        //     id: action['ruleActionXVehicle']['vehicle_id'],
        //     plate_number: action['vehicle']['plate_number']
        //   },
        //   customLabel: customLabel
        // };
        selectedVehicleIds.push(action['ruleActionXVehicle']['vehicle_id']);
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CUSTOMER_ID_FOR_MERGING_STOP_POINT_TO']['constant_name']) {
        this.selectedActionType = 'merge';
        if (action['ruleActionXCustomer']) {
          let name = action['customer']['contact_name'];
          if (name === '_NO_NAME') {
            name = this.noNameLabel;
          }
          this.selectedCustomer = {
            customer: {
              id: action.ruleActionXCustomer.customer_id,
              contact_name: name
            }
          };
        }
      } else if (action['result_attribute'] === this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_VEHICLE_TYPE']['constant_name']) {
        this.selectedActionType = 'vehicleTypes';
        selectedVehicleTypes.push(Number(action['actionValue']['value']));
      } else {
        console.error('Unexpected error');
      }
    });
    this.selectedVehicleIds = selectedVehicleIds;
    this.selectedVehicleTypes = selectedVehicleTypes;
    this.saveActionLabel(this.createSubRuleLabel(), this.selectedActionType);
    this.finalRule = ruleObject;
  }

  showStatementsGroup(className) {
    const openGroup = document.querySelector('.statements-group.open');
    if (openGroup) {
      openGroup.classList.remove('open');
    }
    const selectedGroup = document.querySelector('.' + className);
    if (selectedGroup) {
      selectedGroup.classList.add('open');
    }
  }

  // ANCHOR
  hideStatement(formName) {
    const statement: HTMLElement = document.querySelector('[connected-form-name="' + formName + '"]');
    if (statement) {
      statement.classList.add('hidden');
    }
  }

  showAllStatement() {
    const statement: HTMLElement = document.querySelector('.statements.hidden');
    if (statement) {
      statement.classList.remove('hidden');
    }
  }

  showRuleForm(condition, clearDropDown = true) {
    if (clearDropDown) {
      this.selectedColumn = '';
    }
    const selectedStatement = document.querySelector('.statements.statement-selected');
    if (selectedStatement) {
      selectedStatement.classList.remove('statement-selected');
    }
    const selectedForm = document.querySelector('.condition-form.open');
    if (selectedForm) {
      selectedForm.classList.remove('open');
    }
    if (condition) {
      if (condition.operator) {
        this.selectedOperator = condition.operator.value ? condition.operator.value : condition.operator;
      } else {
        this.selectedOperator = null;
      }
      this.selectedActionType = condition.actionType;
      const selected = document.querySelector('[connected-form-name="' + condition.formName + '"]');
      selected.classList.add('statement-selected');
      const targetForm: HTMLElement = document.querySelector('[form-name="' + condition.formName + '"]');
      targetForm.classList.add('open');
    }
    if (this.selectedSubRule && this.openFormElement) {
      this.selectedSubRule.style.margin = '0 0 ' + (this.openFormElement.offsetHeight + 50) + 'px 0';
      this.selectedSubRule.classList.add('open');
    }
    if (this.selectedActionType === 'region') {
      this.mapRulesModalComponent.mapToggled();
    }
  }

  andButtonPressed() {
    if (this.getSelectedConstant() || (this.importRuleId && this.activeStatement === '')) {
      this.resetFormCss();
      this.openFormElement = null;
      this.selectedSubRule = null;
      if (this.editMode) {
        if (this.activeStatement === 'if') {
          this.updateTriggers();
          this.emptyIfStatementForms();
        } else if (this.activeStatement === 'then') {
          this.setAction();
        }
      } else {
        this.saveTrigger();
      }
      this.editMode = false;
      this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
      this.showStatementsGroup('if-statements');
      this.activeStatement = 'if';
      this.activeActionIndex = null;
      this.activeTriggerIndex = null;
    }
    this.emptyIfStatementForms();
  }

  thenButtonPressed() {
    if (this.getSelectedConstant()) {
      this.resetFormCss();
      this.openFormElement = null;
      this.selectedSubRule = null;
      if (this.editMode) {
        this.updateTriggers();
        this.emptyIfStatementForms();
      } else {
        this.saveTrigger();
        this.formTriggers();
      }
      this.listen.push(this.translate.get('RULES.THEN_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
      this.showStatementsGroup('then-statements');
      this.activeStatement = 'then';
      this.activeActionIndex = null;
      this.activeTriggerIndex = null;
      this.andButtonHidden = true;
      this.thenButtonHidden = true;
      this.doneButtonHidden = false;
    }
    this.emptyIfStatementForms();
  }

  submitRule() {
    this.resetFormCss();
    if (this.editMode) {
      if (this.activeStatement === 'if') {
        this.updateTriggers();
      } else if (this.activeStatement === 'then') {
        this.setAction();
      }
    } else {
      if (this.activeStatement === 'if') {
        this.saveTrigger();
        this.formTriggers();
      } else if (this.activeStatement === 'then') {
        this.setAction();
      }
    }
    const myObserver = {
      next: (response) => { },
      error: (error) => {
        console.error(error);
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.closeRulesModal();
        this.settingsService.updateRulesGrid();
        this.activeTriggerIndex = null;
        this.activeActionIndex = null;
      },
    };
    const data = { importRule: this.finalRule };
    if (this.importRuleId) {
      this.http.put('api/v1/company/import-rules/' + this.importRuleId, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    } else {
      this.http.post('api/v1/company/import-rules', JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    }
  }

  deleteRule() {
    if (this.importRuleId) {
      this.http.delete('api/v1/company/import-rules/' + this.importRuleId).pipe(take(1)).subscribe(response => {
        this.closeRulesModal();
        this.settingsService.updateRulesGrid();
      });
    }
  }

  emptyIfStatementForms() {
    this.selectedString = '';
    this.selectedVolume = '';
    this.selectedPayAmount = '';
    this.selectedColumn = '';
    this.selectedDropdownOperator = this.operatorsDropdownList[0];
    this.polygon = '';
    this.mapRulesModalComponent.resetMap();
  }

  resetRulesModal() {
    this.activeTriggerIndex = null;
    this.activeActionIndex = null;
    this.activeStatement = '';
    this.showStatementsGroup('if-statements');
    this.showRuleForm(null);
    this.emptySelectedVolumes();
    this.editMode = false;
    this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
    this.importRuleId = null;
    this.ruleId = null;
    this.triggerId = null;
    this.multipleTriggerId = null;
    this.triggers = [];
    this.triggerLabels = [];
    this.actionLabels = [];
    this.selectedColumn = '';
    this.selectedConstant = '';
    this.selectedString = '';
    this.selectedVolume = '';
    this.selectedPayAmount = '';
    this.polygon = '';
    this.selectedDriver = null;
    this.selectedCustomer = null;
    this.selectedDropdownOperator = this.operatorsDropdownList[0];
    this.selectedVehicleIds = [];
    this.selectedActionType = '';
    this.selectedOperator = '';
    this.andButtonHidden = false;
    this.thenButtonHidden = false;
    this.doneButtonHidden = true;
    this.openFormElement = null;
    this.selectedSubRule = null;
    this.selectedWidth = null;
    this.selectedLength = null;
    this.selectedHeight = null;
    this.selectedDimensionSize = null;
    this.selectedVehicleTypes = [];
    this.finalRule = {};
    this.showAllStatement();
    this.resetFormCss();
    this.mapRulesModalComponent.resetMap();
  }

  getSelectedConstant() {
    if (this.selectedActionType === 'word') {
      return this.selectedString;
    } else if (this.selectedActionType === 'volume') {
      return this.selectedVolume;
    } else if (this.selectedActionType === 'payAmount') {
      return this.selectedPayAmount;
    } else if (this.selectedActionType === 'region') {
      return this.mapRulesModalComponent.currentPolygon;
    } else if (this.selectedActionType === 'dimension') {
      return this.selectedDimensionSize;
    }
  }

  createSubRuleLabel() {
    let str = this.subRuleLabels[this.selectedActionType];
    str = str.replace('selectedConstant', this.getSelectedConstant());
    if (this.operatorLabels[this.selectedDropdownOperator['value']]) {
      str = str.replace('OPERATOR', this.operatorLabels[this.selectedDropdownOperator['value']]);
    }
    if (this.selectedColumn) {
      if (this.selectedColumn['label']) {
        str = str.replace('selectedColumn', this.selectedColumn['label']);
      } else {
        str = str.replace('selectedColumn', this.globals.stopPointImportFieldConfigsConstantNamesToData[this.selectedColumn]['label']);
      }
    }
    if (this.selectedTimeWindow) {
      str = str.replace('timeWindow', this.selectedTimeWindow['CompanyTimeWindowCategory']['label']);
    }
    if (this.durationSeconds) {
      const durationMinutes = Math.floor(Number(this.durationSeconds) / 60);
      const durationSeconds = Number(this.durationSeconds) - durationMinutes * 60;
      let durationString: String = durationMinutes + ' ' + this.minutesLabel;
      if (durationSeconds) { durationString += ' ' + this.andLabel + ' ' + durationSeconds + ' ' + this.secondsLabel; }
      str = str.replace('durationString', durationString);
    }
    if (this.selectedActionType === 'priority') {
      if (this.priorityOn) {
        str = str.replace('priorityState', this.highMsg);
      } else {
        str = str.replace('priorityState', this.normalMsg);
      }
    }
    if (this.selectedActionType === 'vehicles') {

    }
    return str;
  }

  saveTriggerLabel(label, actionType, trigger) {
    const data = {
      trigger: trigger,
      column: this.selectedColumn
    };
    this.triggerLabels.push({
      condition: this.currentCondition,
      label: label,
      actionType: actionType,
      data: data
    });
    if (actionType === 'region') {
      this.hideStatement('drivers-set-form');
    }
  }

  changeSubRuleLabel(label, actionType, trigger, index) {
    this.triggerLabels.splice(index, 1);
    const data = {
      trigger: trigger,
      column: this.selectedColumn
    };
    this.triggerLabels.splice(index, 0, {
      condition: this.currentCondition,
      label: label,
      actionType: actionType,
      data: data
    });
  }

  saveActionLabel(label, actionType) {
    this.actionLabels = [];
    this.actionLabels.push({
      condition: 'THEN',
      label: label,
      actionType: actionType,
    });
    if (actionType === 'drivers') {
      this.hideStatement('region-match-form');
    }
  }

  removeSubRule(index) {
    const openGroup = document.querySelector('.statements-group.open');
    if (openGroup) {
      openGroup.classList.remove('open');
    }
    const regionConstant = this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT']['constant_name'];
    if (
      this.triggers[index]['expression']['operands'][0]['value'] === regionConstant ||
      this.triggers[index]['expression']['operands'][1]['value'] === regionConstant
    ) {
      this.showAllStatement();
    }
    this.triggerLabels.splice(index, 1);
    this.triggers.splice(index, 1);
    this.activeTriggerIndex = null;
    this.updateTriggers();
    if (!this.triggers.length) {
      this.showStatementsGroup('if-statements');
      this.activeStatement = 'if';
    }
  }

  setAction() {
    let flag = false;
    if (this.selectedOperator === this.globals.ruleExpressionOperatorTypeConstants['MULTIPLY']) {
      this.setVolumesActions();
      flag = true;
    } else {
      if (this.selectedActionType === 'timeWindow' && this.selectedTimeWindow) {
        this.setTimeWindowActions();
        flag = true;
      } else if (this.selectedActionType === 'duration') {
        this.setDurationActions();
        flag = true;
      } else if (this.selectedActionType === 'priority') {
        this.setPriorityActions();
        flag = true;
      } else if (this.selectedActionType === 'vehicles') {
        this.setVehicleActions();
        flag = true;
      } else if (this.selectedActionType === 'drivers') {
        this.setDriverActions();
        flag = true;
      } else if (this.selectedActionType === 'merge') {
        this.setMergeActions();
        flag = true;
      } else if (this.selectedActionType === 'vehicleTypes') {
        this.setVehicleTypesActions();
        flag = true;
      } else {
        console.error('Unexpected error');
      }
    }
    if (flag) { this.saveActionLabel(this.createSubRuleLabel(), this.selectedActionType); }
  }

  populateTriggerForm(trigger) {
    const operatorType = trigger['expression']['operator_type'];
    let firstOperandValue, secondOperandValue, matchedColumnsObjectConstant;
    trigger['expression']['operands'].forEach(operand => {
      if (operand['order'] === 0) {
        firstOperandValue = operand['value'];
      } else {
        secondOperandValue = operand['value'];
      }
    });
    if (operatorType === this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE']) {
      this.selectedActionType = 'word';
      this.selectedString = firstOperandValue;
      matchedColumnsObjectConstant = this.globals.stopPointImportFieldConfigsConstantNamesToData[secondOperandValue];
    } else if (this.volumeTypesList.includes(firstOperandValue)) {
      this.selectedActionType = 'volume';
      this.selectedVolume = secondOperandValue;
      matchedColumnsObjectConstant = this.globals.stopPointImportFieldConfigsConstantNamesToData[firstOperandValue];
    } else if (firstOperandValue === this.globals.stopPointImportFieldConfigs['STOP_POINT_PAY_AMOUNT']['constant_name']) {
      this.selectedActionType = 'payAmount';
      this.selectedPayAmount = secondOperandValue;
      matchedColumnsObjectConstant = this.globals.stopPointImportFieldConfigsConstantNamesToData[firstOperandValue];
    } else if (firstOperandValue === this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT']['constant_name']) {
      this.selectedActionType = 'region';
      this.mapRulesModalComponent.loadDriverRegion(secondOperandValue);
      matchedColumnsObjectConstant = this.globals.stopPointImportFieldConfigsConstantNamesToData[firstOperandValue];
    } else if (this.dimensionsTypesList.includes(firstOperandValue)) {
      this.selectedActionType = 'dimension';
      this.selectedDimensionSize = secondOperandValue;
      matchedColumnsObjectConstant = this.globals.stopPointImportFieldConfigsConstantNamesToData[firstOperandValue];
    } else {
      console.error('Unexpected error');
    }
    this.selectedColumn = this.matchedColumnsObject[matchedColumnsObjectConstant['originalKey']];
  }

  resetFormCss() {
    document.querySelector('.if-statements').removeAttribute('style');
    document.querySelector('.then-statements').removeAttribute('style');
    const selected: HTMLElement = document.querySelector('.rule-text.open');
    if (selected) { selected.style.margin = '0 0 0 0'; }
    const openGroup = document.querySelector('.statements-group.open');
  }

  openForm(subrule, triggerIndex = null, actionIndex = null) {
    if (triggerIndex !== this.activeTriggerIndex || actionIndex !== this.activeActionIndex) {
      if (this.activeStatement === 'then') {
        this.formTriggers();
        this.setAction();
      } else {
        if (this.getSelectedConstant()) {
          if (this.editMode) {
            this.updateTriggers();
          } else {
            this.saveTrigger();
            this.emptyIfStatementForms();
          }
        }
      }
      this.activeTriggerIndex = triggerIndex;
      this.activeActionIndex = actionIndex;
      this.editMode = true;
      if (subrule.condition === 'THEN') {
        this.listen.push(this.translate.get('RULES.THEN_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
        this.showStatementsGroup('then-statements');
        this.activeStatement = 'then';
        this.selectedSubRule = document.querySelector('[action-index="' + actionIndex + '"]');
        this.openFormElement = document.querySelector('.then-statements');
      } else {
        this.selectedSubRule = document.querySelector('[trigger-index="' + triggerIndex + '"]');
        this.openFormElement = document.querySelector('.if-statements');
        if (subrule.condition === 'AND') {
          this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
        } else {
          this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));
        }
        this.showStatementsGroup('if-statements');
        this.activeStatement = 'if';
        this.populateTriggerForm(subrule['data']['trigger']);
        if (!this.actionLabels.length) { this.thenButtonHidden = false; }
      }
      let condition;
      this.ifStatements.forEach(statement => {
        if (statement.actionType === subrule.actionType) {
          condition = statement;
        }
      });
      this.thenStatements.forEach(statement => {
        if (statement.actionType === subrule.actionType) {
          condition = statement;
        }
      });
      this.resetFormCss();
      const ifStatements: HTMLElement = document.querySelector('.if-statements');
      const thenStatements: HTMLElement = document.querySelector('.then-statements');
      ifStatements.style.display = 'none';
      thenStatements.style.display = 'none';
      setTimeout(() => {
        ifStatements.removeAttribute('style');
        thenStatements.removeAttribute('style');
        if (condition) { this.showRuleForm(condition, false); }
        this.selectedSubRule.style.margin = '0 0 ' + (this.openFormElement.offsetHeight + 50) + 'px 0';
        this.selectedSubRule.classList.add('open');
        const rect = this.selectedSubRule.getBoundingClientRect();
        this.openFormElement.style.top = rect.top - 30 + 'px';
        this.openFormElement.style.position = 'absolute';
        this.openFormElement.style.margin = '0 0 25px';
      }, 300);
    }
  }

  loadSubRule(triggerOperatorType, operands) {
    this.operatorsDropdownList.forEach(element => {
      if (element.value === triggerOperatorType) {
        this.selectedDropdownOperator = element;
      }
    });
    let firstOperandValue, secondOperandValue;
    operands.forEach(operand => {
      if (operand['order'] === 0) {
        firstOperandValue = operand['value'];
      } else {
        secondOperandValue = operand['value'];
      }
    });
    if (triggerOperatorType === this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE']) {
      this.selectedActionType = 'word';
      this.selectedString = firstOperandValue;
      this.selectedColumn = secondOperandValue;
    } else if (this.volumeTypesList.includes(firstOperandValue)) {
      this.selectedActionType = 'volume';
      this.selectedVolume = secondOperandValue;
    } else if (firstOperandValue === this.globals.stopPointImportFieldConfigs['STOP_POINT_PAY_AMOUNT']['constant_name']) {
      this.selectedActionType = 'payAmount';
      this.selectedPayAmount = secondOperandValue;
      this.selectedColumn = this.globals.stopPointImportFieldConfigs['STOP_POINT_PAY_AMOUNT'];
    } else if (firstOperandValue === this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT']['constant_name']) {
      this.selectedActionType = 'region';
      this.mapRulesModalComponent.loadDriverRegion(secondOperandValue);
      this.selectedColumn = this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT'];
    } else if (this.dimensionsTypesList.includes(firstOperandValue)) {
      this.selectedActionType = 'dimension';
      this.selectedDimensionSize = secondOperandValue;
    } else {
      console.error('Unexpected error');
    }
  }

  saveTrigger(loadedTrigger = {}) {
    if (loadedTrigger['expression']) {
      this.saveTriggerLabel(this.createSubRuleLabel(), this.selectedActionType, loadedTrigger);
      this.triggers.push(loadedTrigger);
    } else {
      let type = 'NUMBER';
      if (this.selectedActionType === 'word') {
        type = 'STRING';
      } else if (this.selectedActionType === 'region') {
        type = this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['valueType'];
      }
      let modelAttributeOrder, constantOrder;
      if (this.selectedActionType === 'word') {
        modelAttributeOrder = 1;
        constantOrder = 0;
      } else {
        modelAttributeOrder = 0;
        constantOrder = 1;
      }
      if (this.selectedActionType === 'volume' || this.selectedActionType === 'payAmount' || this.selectedActionType === 'dimension') {
        this.selectedOperator = this.selectedDropdownOperator['value'];
      }
      if (this.selectedActionType === 'payAmount') {
        this.selectedColumn = this.globals.stopPointImportFieldConfigs['STOP_POINT_PAY_AMOUNT'];
        this.selectedColumn['constant'] = this.selectedColumn['constant_name'];
      } else if (this.selectedActionType === 'region') {
        this.selectedColumn = this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT'];
        this.selectedColumn['constant'] = this.selectedColumn['constant_name'];
      }
      const column = this.selectedColumn['constant'] ? this.selectedColumn['constant'] : this.selectedColumn;
      const expression: Expression = {
        id: null,
        operator_type: this.selectedOperator,
        operands: [
          this.createOperand('CONSTANT', type, this.getSelectedConstant(), constantOrder),
          this.createOperand('MODEL_ATTRIBUTE', type, column, modelAttributeOrder),
        ]
      };
      const trigger: Trigger = {
        id: this.triggerId,
        expression: expression
      };
      this.saveTriggerLabel(this.createSubRuleLabel(), this.selectedActionType, trigger);
      this.triggers.push(trigger);
    }
  }

  updateTriggers() {
    this.formTriggers();
    this.finalRule['rule']['trigger'] = {};
    if (this.activeTriggerIndex !== null) {
      const trigger = this.triggers[this.activeTriggerIndex];
      let modelAttributeOrder, constantOrder;
      if (this.selectedActionType === 'word') {
        modelAttributeOrder = 1;
        constantOrder = 0;
      } else {
        modelAttributeOrder = 0;
        constantOrder = 1;
      }
      let type = 'NUMBER';
      if (this.selectedActionType === 'word') {
        type = 'STRING';
      } else if (this.selectedActionType === 'region') {
        this.selectedColumn = this.globals.stopPointImportFieldConfigs['SPECIAL_ATTRIBUTE_STOP_POINT_ADDRESS_GEO_POINT'];
        this.selectedColumn['constant'] = this.selectedColumn['constant_name'];
        type = this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['valueType'];
      } else if (this.selectedActionType === 'payAmount') {
        this.selectedColumn = this.globals.stopPointImportFieldConfigs['STOP_POINT_PAY_AMOUNT'];
        this.selectedColumn['constant'] = this.selectedColumn['constant_name'];
      }
      const column = this.selectedColumn['constant'] ? this.selectedColumn['constant'] : this.selectedColumn;
      const operand0 = this.createOperand('CONSTANT', type, this.getSelectedConstant(), constantOrder);
      const operand1 = this.createOperand('MODEL_ATTRIBUTE', type, column, modelAttributeOrder);

      if (this.triggers[this.activeTriggerIndex]['expression']['operands'][0]['value'] !== operand0['value']) {
        this.triggers[this.activeTriggerIndex]['expression']['operands'][0] = operand0;
      }
      if (this.triggers[this.activeTriggerIndex]['expression']['operands'][1]['value'] !== operand1['value']) {
        this.triggers[this.activeTriggerIndex]['expression']['operands'][1] = operand1;
      }

      this.triggers[this.activeTriggerIndex]['expression']['operator_type'] = this.selectedOperator;
      if (this.selectedActionType === 'volume' || this.selectedActionType === 'payAmount' || this.selectedActionType === 'dimension') {
        this.triggers[this.activeTriggerIndex]['expression']['operator_type'] = this.selectedDropdownOperator['value'];
      }
      this.changeSubRuleLabel(this.createSubRuleLabel(), this.selectedActionType, trigger, this.activeTriggerIndex);
    }
    this.formTriggers();
  }

  changeActiveTriggerOperandValue(valueForOrder0, valueForOrder1) {
    const self = this;
    this.triggers[this.activeTriggerIndex]['expression']['operands'].forEach(function (operand, index) {
      if (operand['order'] === 0) {
        self.triggers[self.activeTriggerIndex]['expression']['operands'][index]['value'] = valueForOrder0;
      } else {
        self.triggers[self.activeTriggerIndex]['expression']['operands'][index]['value'] = valueForOrder1;
      }
    });
  }

  formTriggers() {
    const actions = this.finalRule['rule'] ? this.finalRule['rule']['actions'] : [];
    const rule: Rule = {
      id: this.ruleId,
      name: '',
      trigger: {},
      actions: actions
    };
    if (this.triggers.length > 1) {
      const expression: Expression = {
        id: this.multipleTriggerId,
        operator_type: this.globals.ruleExpressionOperatorTypeConstants['AND'],
        operands: []
      };

      this.triggers.forEach(trigger => {
        if (this.multipleTriggerId === null && this.importRuleId) {
          trigger['id'] = null;
          trigger['expression']['id'] = null;
        }
        expression.operands.push(trigger);
      });
      const templateTrigger: Trigger = {
        id: this.triggerId,
        expression: expression
      };
      rule.trigger = templateTrigger;
    } else {
      if (this.multipleTriggerId) {
        this.triggers[0]['expression']['id'] = null;
      }
      rule.trigger = this.triggers[0];
    }
    const finalRule: ImportRule = {
      id: this.importRuleId,
      rule: rule
    };
    this.finalRule = finalRule;
  }

  setVolumesActions() {
    const actions = [];
    this.packageTypeColumns.forEach(volumeType => {
      const expression: Expression = {
        id: null,
        operator_type: this.selectedOperator,
        operands: [
          this.createOperand('CONSTANT', 'VOLUME', this.selectedVolumes[volumeType['constant']], 0),
          this.createOperand('IMPORT_DYNAMIC_FIELD', 'VOLUME', volumeType['constant'], 1),
        ]
      };
      const action: Action = {
        id: null,
        action_type: this.globals.ruleActionTypeConstants['SET'],
        result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CALCULATED_LOAD']['constant_name'],
        expression: expression,
        actionValue: ''
      };
      actions.push(action);
    });
    if (actions.length > 1) {
      const andExpression: Expression = {
        id: null,
        operator_type: this.globals.ruleExpressionOperatorTypeConstants['ADDITION'],
        operands: actions
      };
      const andAction: Action = {
        id: null,
        action_type: this.globals.ruleActionTypeConstants['SET'],
        result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CALCULATED_LOAD']['constant_name'],
        expression: andExpression,
        actionValue: ''
      };
      this.finalRule['rule']['actions'] = [andAction];
    } else {
      this.finalRule['rule']['actions'] = actions;
    }
  }

  setTimeWindowActions() {
    const actions = [];
    const action = {
      id: null,
      action_type: this.globals.ruleActionTypeConstants['SET'],
      result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_COMPANY_TIME_WINDOW_CATEGORY_ID']['constant_name'],
      result_attribute_type: this.globals.ruleValueTypeConstants['TIME_WINDOW_ARRAY_RELATION'],
      companyTimeWindowCategoryId: this.selectedTimeWindow['CompanyTimeWindowCategory']['id'],
    };
    actions.push(action);
    this.finalRule['rule']['actions'] = actions;
  }

  setDurationActions() {
    let durationSeconds;
    if (Array.isArray(this.durationSeconds)) {
      durationSeconds = this.durationSeconds[0];
    } else {
      durationSeconds = this.durationSeconds;
    }
    const duration = moment.duration(durationSeconds, 'seconds').toISOString();
    const actionValue: ActionValue = {
      id: null,
      value_type: this.globals.ruleValueTypeConstants['ISO8601_DURATION'],
      value: duration
    };
    const action: Action = {
      id: null,
      action_type: this.globals.ruleActionTypeConstants['SET'],
      result_attribute: this.globals.stopPointImportFieldConfigs['STOP_POINT_DURATION']['constant_name'],
      expression: null,
      actionValue: actionValue
    };
    this.finalRule['rule']['actions'] = [action];
  }

  setPriorityActions() {
    const priority = this.priorityOn ? this.globals.stopPointPriorityConstants['HIGH'] : this.globals.stopPointPriorityConstants['NORMAL'];
    const actionValue: ActionValue = {
      id: null,
      value_type: this.globals.ruleValueTypeConstants['STRING'],
      value: priority
    };
    const action: Action = {
      id: null,
      action_type: this.globals.ruleActionTypeConstants['SET'],
      result_attribute: this.globals.stopPointImportFieldConfigs['STOP_POINT_PRIORITY']['constant_name'],
      expression: null,
      actionValue: actionValue
    };
    this.finalRule['rule']['actions'] = [action];
  }

  setVehicleTypesActions() {
    const actions = [];
    this.selectedVehicleTypes.forEach(type => {
      const action = {
        id: null,
        action_type: this.globals.ruleActionTypeConstants['SET'],
        result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_VEHICLE_TYPE']['constant_name'],
        expression: null,
        actionValue: {
          value_type: this.globals.ruleValueTypeConstants['STRING'],
          value: type
        }
      };
      actions.push(action);
    });
    this.finalRule['rule']['actions'] = actions;
  }

  setVehicleActions() {
    const actions = [];
    this.selectedVehicleIds.forEach(id => {
      const action = {
        id: null,
        action_type: this.globals.ruleActionTypeConstants['SET'],
        result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_VEHICLE_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name'],
        expression: null,
        ruleActionXVehicle: { vehicle_id: id }
      };
      actions.push(action);
    });
    this.finalRule['rule']['actions'] = actions;
  }

  setDriverActions() {
    const actions = [];
    if (this.selectedDriver) {
      const action = {
        id: null,
        action_type: this.globals.ruleActionTypeConstants['SET'],
        result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_DRIVER_ID_FOR_MANUAL_MODIFIED_ROUTE_ITEM']['constant_name'],
        expression: null,
        ruleActionXDriver: { driver_id: this.selectedDriver.driver.id }
      };
      actions.push(action);
    }
    this.finalRule['rule']['actions'] = actions;
  }

  setMergeActions() {
    const actions = [];
    if (this.selectedCustomer) {
      const action = {
        id: null,
        action_type: this.globals.ruleActionTypeConstants['SET'],
        result_attribute: this.globals.stopPointImportFieldConfigs['RULES_SYSTEM_CUSTOMER_ID_FOR_MERGING_STOP_POINT_TO']['constant_name'],
        expression: null,
        ruleActionXCustomer: { customer_id: this.selectedCustomer.customer.id }
      };
      actions.push(action);
    }
    this.finalRule['rule']['actions'] = actions;
  }

  createOperand(attributeType, valueType, value, order) {
    const operand: Operand = {
      id: null,
      attribute_type: this.globals.ruleAttributeTypeConstants[attributeType],
      value_type: this.globals.ruleValueTypeConstants[valueType],
      value: value,
      order: order
    };
    return operand;
  }

  openRulesModal() {
    this.genericService.comm100ZIndexFix();
    this.loadSystemColumnsData();
    const modal = document.querySelector('.rules-modal');
    const modalBackground = document.querySelector('.rules-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
  }

  loadSystemColumnsData() {
    const self = this;
    this.matchedColumnsObject = this.globals.stopPointImportFieldConfigs;
    this.packageTypeColumns = [];
    this.volumeTypesList = [];
    Object.keys(this.matchedColumnsObject).forEach(key => {
      if (!self.matchedColumnsObject[key]['hidden'] && !self.matchedColumnsObject[key]['hide_from_rules']) {
        this.matchedColumnsObject[key]['constant'] = this.matchedColumnsObject[key]['constant_name'];
        this.matchedColumns.push(this.matchedColumnsObject[key]);
        if (
          this.matchedColumnsObject[key]['valueType'] === this.globals.ruleValueTypeConstants['VOLUME'] &&
          this.matchedColumnsObject[key]['operand_attribute_type'] === this.globals.ruleAttributeTypeConstants['IMPORT_DYNAMIC_FIELD']
        ) {
          this.packageTypeColumns.push(this.matchedColumnsObject[key]);
          this.volumeTypesList.push(this.matchedColumnsObject[key]['constant_name']);
        }
      }
    });
    this.emptySelectedVolumes();
    // if we don't have any package types, don't show this option
    if (!this.packageTypeColumns.length) {
      this.hideStatement('volumes-set-form');
    }
  }

  closeRulesModal() {
    const modal = document.querySelector('.rules-modal');
    const modalBackground = document.querySelector('.rules-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.resetRulesModal();
  }

  formatHoursAndMinutes(hours, minutes) {
    if (hours.toString().length === 1) { hours = '0' + hours; }
    if (minutes.toString().length === 1) { minutes = '0' + minutes; }
    return hours + ':' + minutes;
  }

  convertSecondsToTime() {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.durationSeconds);
    seconds = this.convertToMinute(this.durationSeconds, minutes);
    this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  }

  convertToHour(value) {
    return Math.floor(value / 60);
  }

  convertToMinute(value, hour) {
    return value - hour * 60;
  }

  initializeSlider() {
    this.durationOptions = {
      step: 15,
      start: [120],
      connect: 'lower',
      range: {
        min: 0,
        max: 1800
      },
    };
  }

  emptySelectedVolumes() {
    this.packageTypeColumns.forEach(type => {
      this.selectedVolumes[type] = '';
    });
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
        typeName: this.bicycleLabel
      },
      {
        index: 1,
        image: scooter,
        typeName: this.scooterLabel
      },
      {
        index: 2,
        image: car,
        typeName: this.carLabel
      },
      {
        index: 3,
        image: van,
        typeName: this.vanLabel
      },
      {
        index: 4,
        image: largeVan,
        typeName: this.largeVanLabel
      },
      {
        index: 5,
        image: truck,
        typeName: this.truckLabel
      }
    ];
    // this.vehicleTypes.forEach(type => {
    //   this.availableVehicleTypes.push()
    // });
  }

  getTranslations() {
    const self = this;

    const lessThanConstant = this.globals.ruleExpressionOperatorTypeConstants['LESS_THAN'];
    const equalToConstant = this.globals.ruleExpressionOperatorTypeConstants['EQUAL_TO'];
    const graterThanConstant = this.globals.ruleExpressionOperatorTypeConstants['GREATER_THAN'];
    this.listen.push(this.translate.get('RULES.LESS_THAN_OPERAND').subscribe((res: string) => { this.operatorLabels[lessThanConstant] = res; }));
    this.listen.push(this.translate.get('RULES.EQUAL_TO_OPERAND').subscribe((res: string) => { this.operatorLabels[equalToConstant] = res; }));
    this.listen.push(this.translate.get('RULES.GRATER_THAN_OPERAND').subscribe((res: string) => { this.operatorLabels[graterThanConstant] = res; }));
    this.listen.push(this.translate.get('RULES.WORD_MSG').subscribe((res: string) => { this.subRuleLabels.word = res; }));
    this.listen.push(this.translate.get('RULES.VOLUME_MSG').subscribe((res: string) => { this.subRuleLabels.volume = res; }));
    this.listen.push(this.translate.get('RULES.PAY_AMOUNT_MSG').subscribe((res: string) => { this.subRuleLabels.payAmount = res; }));
    this.listen.push(this.translate.get('RULES.REGION_MSG').subscribe((res: string) => { this.subRuleLabels.region = res; }));
    this.listen.push(this.translate.get('RULES.DIMENSION_MSG').subscribe((res: string) => { this.subRuleLabels.dimension = res; }));
    this.listen.push(this.translate.get('RULES.TIME_WINDOW_MSG').subscribe((res: string) => { this.subRuleLabels.timeWindow = res; }));
    this.listen.push(this.translate.get('RULES.DURATION_MSG').subscribe((res: string) => { this.subRuleLabels.duration = res; }));
    this.listen.push(this.translate.get('RULES.VOLUMES_MSG').subscribe((res: string) => { this.subRuleLabels.volumes = res; }));
    this.listen.push(this.translate.get('RULES.PRIORITY_MSG').subscribe((res: string) => { this.subRuleLabels.priority = res; }));
    this.listen.push(this.translate.get('RULES.VEHICLES_MSG').subscribe((res: string) => { this.subRuleLabels.vehicles = res; }));
    this.listen.push(this.translate.get('RULES.DRIVERS_MSG').subscribe((res: string) => { this.subRuleLabels.drivers = res; }));
    this.listen.push(this.translate.get('RULES.MERGE_MSG').subscribe((res: string) => { this.subRuleLabels.merge = res; }));
    this.listen.push(this.translate.get('RULES.VEHICLE_TYPES_MSG').subscribe((res: string) => { this.subRuleLabels.vehicleTypes = res; }));
    this.listen.push(this.translate.get('RULES.HIGH').subscribe((res: string) => { this.highMsg = res; }));
    this.listen.push(this.translate.get('RULES.NORMAL').subscribe((res: string) => { this.normalMsg = res; }));

    this.listen.push(this.translate.get('RULES.LESS_THAN').subscribe((res: string) => { this.lessThanMsg = res; }));
    this.listen.push(this.translate.get('RULES.EQUAL_TO').subscribe((res: string) => { this.equalToMsg = res; }));
    this.listen.push(this.translate.get('RULES.GREATER_THAN').subscribe((res: string) => { this.graterThanMsg = res; }));
    this.listen.push(this.translate.get('RULES.IF_STATEMENT_MSG_PART_1').subscribe((res: string) => { this.ifStatementsLabels[0] = res; }));
    this.listen.push(this.translate.get('RULES.IF_STATEMENT_MSG_PART_2').subscribe((res: string) => { this.ifStatementsLabels[1] = res; }));
    this.listen.push(this.translate.get('RULES.IF_STATEMENT_MSG_PART_3').subscribe((res: string) => { this.ifStatementsLabels[2] = res; }));
    this.listen.push(this.translate.get('RULES.IF_STATEMENT_MSG_PART_4').subscribe((res: string) => { this.ifStatementsLabels[3] = res; }));
    this.listen.push(this.translate.get('RULES.IF_STATEMENT_MSG_PART_5').subscribe((res: string) => { this.ifStatementsLabels[4] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_1').subscribe((res: string) => { this.thenStatementsLabels[0] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_2').subscribe((res: string) => { this.thenStatementsLabels[1] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_3').subscribe((res: string) => { this.thenStatementsLabels[2] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_4').subscribe((res: string) => { this.thenStatementsLabels[3] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_5').subscribe((res: string) => { this.thenStatementsLabels[4] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_6').subscribe((res: string) => { this.thenStatementsLabels[5] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_7').subscribe((res: string) => { this.thenStatementsLabels[6] = res; }));
    this.listen.push(this.translate.get('RULES.THEN_STATEMENT_MSG_PART_8').subscribe((res: string) => { this.thenStatementsLabels[7] = res; }));
    this.listen.push(this.translate.get('TIME.MINUTES').subscribe((res: string) => { this.minutesLabel = res; }));
    this.listen.push(this.translate.get('TIME.SECONDS').subscribe((res: string) => { this.secondsLabel = res; }));
    this.listen.push(this.translate.get('TIME.AND').subscribe((res: string) => { this.andLabel = res; }));
    this.listen.push(this.translate.get('DRIVER.MULTIPLE').subscribe((res: string) => { this.multipleDriversLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
    this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));

    this.operatorsDropdownList = [
      {
        label: this.lessThanMsg,
        value: this.globals.ruleExpressionOperatorTypeConstants['LESS_THAN'],
      },
      {
        label: this.equalToMsg,
        value: this.globals.ruleExpressionOperatorTypeConstants['EQUAL_TO'],
      },
      {
        label: this.graterThanMsg,
        value: this.globals.ruleExpressionOperatorTypeConstants['GREATER_THAN'],
      },
    ];
    this.selectedDropdownOperator = this.operatorsDropdownList[0];

    // ANCHOR
    this.ifStatements = [
      {
        label: this.ifStatementsLabels[0],
        formName: 'word-match-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['FOUND_IN_CASE_INSENSITIVE'],
        actionType: 'word'
      },
      {
        label: this.ifStatementsLabels[1],
        formName: 'volume-match-form',
        operator: this.selectedDropdownOperator,
        actionType: 'volume'
      },
      {
        label: this.ifStatementsLabels[2],
        formName: 'pay-amount-match-form',
        operator: this.selectedDropdownOperator,
        actionType: 'payAmount'
      },
      {
        label: this.ifStatementsLabels[3],
        formName: 'region-match-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['WITHIN_AREA'],
        actionType: 'region'
      },
      {
        label: this.ifStatementsLabels[4],
        formName: 'dimension-match-form',
        operator: this.selectedDropdownOperator,
        actionType: 'dimension'
      },
    ];

    this.thenStatements = [
      {
        label: this.thenStatementsLabels[0],
        formName: 'time-window-set-form',
        operator: null,
        actionType: 'timeWindow'
      },
      {
        label: this.thenStatementsLabels[1],
        formName: 'duration-set-form',
        operator: null,
        actionType: 'duration'
      },
      {
        label: this.thenStatementsLabels[2],
        formName: 'volumes-set-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['MULTIPLY'],
        actionType: 'volumes'
      },
      {
        label: this.thenStatementsLabels[3],
        formName: 'priority-set-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['SET'],
        actionType: 'priority'
      },
      {
        label: this.thenStatementsLabels[4],
        formName: 'vehicles-set-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['SET'],
        actionType: 'vehicles'
      },
      {
        label: this.thenStatementsLabels[5],
        formName: 'drivers-set-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['SET'],
        actionType: 'drivers'
      },
      {
        label: this.thenStatementsLabels[6],
        formName: 'merge-set-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['SET'],
        actionType: 'merge'
      },
      {
        label: this.thenStatementsLabels[7],
        formName: 'vehicle-type-set-form',
        operator: this.globals.ruleExpressionOperatorTypeConstants['SET'],
        actionType: 'vehicleTypes'
      },
    ];

    setTimeout(() => {
      this.setVehicleTypes();

      const vehiclesDataRefreshIntervalId = setInterval(vehiclesDataChecker, 200);
      function vehiclesDataChecker() {
        if (self.globals.vehiclesDataDone) {
          clearInterval(vehiclesDataRefreshIntervalId);
          self.vehicles = self.globals.vehiclesArray;
        }
      }
    }, 300);
  }


  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
    this.listen.push(this.translate.get('RULES.AND_CONDITION').subscribe((res: string) => { this.currentCondition = res; }));

    this.http.get('api/v1/companies/' + this.globals.companyId + '/company-time-window').subscribe(response => {
      this.timeWindows = response['items'];
    });

    this.loadSystemColumnsData();

    // make the dimensions list for the dropdown
    this.dimensionsTypesList = [
      this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_HEIGHT']['constant_name'],
      this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_LENGTH']['constant_name'],
      this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_WIDTH']['constant_name'],
    ];
    this.dimensionsColumns = [
      this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_HEIGHT'],
      this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_LENGTH'],
      this.globals.stopPointImportFieldConfigs['STOP_POINT_PACKET_WIDTH'],
    ];

    this.customers = concat(
      of([]), // default items
      this.customersInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.customersLoading = true),
        switchMap(term => this.dataService.getCustomers(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.customersLoading = false)
        ))
      )
    );

    this.http.get('api/internal/v2/drivers').pipe(take(1)).subscribe(response => {
      // empty drivers array because otherwise the options are not assigned to the dropdown for some twisted reason
      this.drivers = [];
      response['items'].forEach(driver => {
        this.drivers.push(driver);
        this.driversObject[driver.driver.id] = driver;
      });
    });

  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}

interface ImportRule {
  id: Number;
  rule: Object;
}
interface Rule {
  id: Number;
  name: String;
  trigger: Object;
  actions: Array<Object>;
}
interface Trigger {
  id: Number;
  expression: Object;
}
interface Action {
  id: Number;
  action_type: String;
  result_attribute: String;
  expression: Object;
  actionValue: Object;
}
interface Expression {
  id: Number;
  operator_type: String;
  operands: Array<Object>;
}
interface Operand {
  id: Number;
  attribute_type: String;
  value_type: String;
  value: String;
  order: Number;
}
interface ActionValue {
  id: Number;
  value_type: String;
  value: String;
}
