import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StepsService } from '@app/services/steps.service';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { DepotUtils } from '@app/utils/depot-utils';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';
import { SelectMapperService } from '@app/core/services/select-mapper.service';

@Component({
    selector: 'app-project-modal',
    templateUrl: './project-modal.component.html',
    styleUrls: ['./project-modal.component.scss']
})
export class ProjectModalComponent implements OnInit, OnDestroy {
    @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
    @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;

    searchTextChanged = new Subject<string>();
    searchString: String = '';
    isClickedOnce = false;
    canBeOpened = true;

    listen = [];
    myForm: FormGroup;
    allDrivers = [];
    driversOptions = [];
    driversObject = {};
    driversIds = [];
    projectId = null;
    name = '';
    selectedDrivers: any = <any>[];
    companyDepotId = null;

    completionTime = '';
    completionTimeSelected = '';
    defaultCompletionTime = '23:00';
    completionTimeEnabled = false;

    departureDateTime = '';
    departureTime = '';
    timeZone = '';

    avoidTolls = false;
    afterDispatchRespectOrderStrictness = 5;
    routingOptimizeMode = this.globals.projectRoutingOptimizeModeConstants['TIME'] ?? 0;
    respectSliderOptions;
    respectNoneLabel;
    respectFullLabel;
    deleteProjectWarningLabel;

    pudoSelectOptions$:Observable<[]>;
    pudoPointsCount: number;
    pudoPointIds:any[];

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        public router: Router,
        formBuilder: FormBuilder,
        private stepsService: StepsService,
        public depotUtils: DepotUtils,
        public formErrorsUtils: FormErrorsUtils,
        public selectMapperSvc: SelectMapperService
    ) {
        this.updatePudoOptions();
        this.completionTimeSelected = this.defaultCompletionTime;
        this.myForm = formBuilder.group({
            'project': formBuilder.group({
                'id': [this.projectId],
                'title': [this.name, Validators.required],
                'drivers': [this.selectedDrivers],
                'departure_time': [this.departureTime],
                'completion_time': [this.completionTime],
                'completion_time_selected': [this.completionTimeSelected],
                'completion_time_enabled': [this.completionTimeEnabled],
                'driversIds': [this.driversIds],
                'company_depot_id': [this.companyDepotId],
                'default_project_departure_datetime': [this.departureDateTime],
                'default_project_departure_datetime_timezone': [this.timeZone],
                'avoid_tolls': [this.avoidTolls],
                'after_dispatch_respect_order_strictness': [this.afterDispatchRespectOrderStrictness],
                'routing_optimize_mode': [this.routingOptimizeMode],
                'pudo_point_ids': [this.pudoPointIds]
            }),
        });
    }

    updatePudoOptions() {
        this.pudoSelectOptions$ = this.selectMapperSvc.pudoPointsFatFree(this.companyDepotId).pipe(
            tap(res => this.pudoPointsCount = res?.length ?? 0)
        );
    }

    fixRespectOrderStrictness() {
        this.afterDispatchRespectOrderStrictness = 5 - this.afterDispatchRespectOrderStrictness;
    }

    loadProject(id) {
        this.projectId = id;
        this.http.get('api/internal/v2/project/projects/' + this.projectId).pipe(take(1)).subscribe(response => {
            if (response['items'] && response['items'][0]) {
                this.openModal();
                const project = response['items'][0]['project'];
                this.name = project.title;
                this.companyDepotId = project.company_depot_id;
                this.avoidTolls = project.avoid_tolls;

                this.timeZone = project.default_project_departure_datetime_timezone;
                this.departureDateTime = project.default_project_departure_datetime;
                this.departureTime = moment(this.departureDateTime).format('HH:mm');

                this.completionTime = project.completion_time;
                this.completionTimeSelected = this.completionTime ? moment(this.completionTime, 'HH:mm:SS').format('HH:mm') : this.defaultCompletionTime;
                this.completionTimeEnabled = this.completionTime ? true : false;

                this.selectedDrivers = [];
                this.afterDispatchRespectOrderStrictness = project.after_dispatch_respect_order_strictness ?? 5;
                this.routingOptimizeMode = project.routing_optimize_mode ?? this.globals.projectRoutingOptimizeModeConstants['TIME'] ?? 0;
                this.pudoPointIds = project.pudo_point_ids ?? [];
                this.fixRespectOrderStrictness();
                this.driversIds = project.drivers_ids ?? [];
                this.driversIds.forEach(driverId => {
                    this.selectedDrivers.push({
                        id: driverId,
                        name: this.driversObject[driverId].userProfile.name
                    });
                });
                this.updatePudoOptions();
                this.updateAvailableDrivers(project.company_depot_id);
                this.setForm();
            }
        });
    }

    deleteProject() {
        if (confirm(this.deleteProjectWarningLabel)) {
            this.http.delete('api/v1/projects/' + this.projectId).pipe(take(1)).subscribe(response => {
                this.router.navigate(['projects']);
            });
        }
    }

    setForm() {
        this.myForm.setValue({
            'project': {
                'id': this.projectId,
                'title': this.name,
                'drivers': this.selectedDrivers,
                'departure_time': this.departureTime,
                'completion_time': this.completionTime,
                'completion_time_selected': this.completionTimeSelected,
                'completion_time_enabled': this.completionTimeEnabled,
                'driversIds': this.driversIds,
                'default_project_departure_datetime': this.departureDateTime,
                'company_depot_id': this.companyDepotId,
                'default_project_departure_datetime_timezone': this.timeZone,
                'avoid_tolls': this.avoidTolls,
                'after_dispatch_respect_order_strictness': this.afterDispatchRespectOrderStrictness,
                'routing_optimize_mode': this.routingOptimizeMode,
                'pudo_point_ids': this.pudoPointIds
            },
        });
        M.updateTextFields();
    }

    patchForSubmit() {
        this.myForm.patchValue({
            'project': {
                'id': this.projectId,
                'driversIds': this.driversIds,
                'company_depot_id': this.companyDepotId,
                'completion_time': this.completionTime,
                'default_project_departure_datetime': this.departureDateTime,
                'default_project_departure_datetime_timezone': this.timeZone,
                'after_dispatch_respect_order_strictness': this.afterDispatchRespectOrderStrictness,
                'routing_optimize_mode': this.routingOptimizeMode,
                'pudo_point_ids': this.pudoPointIds
            },
        });
        M.updateTextFields();
    }

    openModal() {
        if (this.canBeOpened) {
            this.canBeOpened = false;

            if (this.modal.nativeElement.classList.contains('closed')) {
                this.modal.nativeElement.classList.remove('closed');
                this.modal.nativeElement.classList.add('open');
                this.modalBackground.nativeElement.classList.remove('hidden');

                this.departureDateTime = this.globals.defaultDepartureDateTime;
                this.departureTime = moment(this.departureDateTime).format('HH:mm');
                const departureTimeElement = <HTMLInputElement>document.querySelector('#project-departure-time');
                M.Timepicker.init(departureTimeElement, {
                    twelveHour: false,
                    defaultTime: this.departureTime
                });
                departureTimeElement.value = this.departureTime;

                const completionTimeElement = <HTMLInputElement>document.querySelector('#project-completion-time');
                M.Timepicker.init(completionTimeElement, {
                    twelveHour: false,
                    defaultTime: this.completionTimeSelected
                });
                completionTimeElement.value = this.completionTimeSelected;

                M.updateTextFields();
            }
        }
    }

    closeModal() {
        this.modal.nativeElement.classList.add('closed');
        this.modal.nativeElement.classList.remove('open');
        this.modalBackground.nativeElement.classList.add('hidden');

        setTimeout(() => {
            this.resetModal();
            this.canBeOpened = true;
        }, 500);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    removeSearchFilters() {
    }

    respectOrderStrictnessChange(newValue) {
        this.afterDispatchRespectOrderStrictness = newValue;
        this.myForm.patchValue({
            'project': {
                'after_dispatch_respect_order_strictness': this.afterDispatchRespectOrderStrictness
            }
        });
    }

    changeRoutingOptimizeMode() {
        this.routingOptimizeMode = Number(this.myForm.value.project.routing_optimize_mode) ?? 0;
    }

    handlePudoPoints(e) {
        const {value:points} = e;
        this.pudoPointIds = points.length === this.pudoPointsCount ? [0] : points;
    }

    clearPudoPoints() {
        this.pudoPointIds = [];
    }

    updateAvailableDrivers(depotId) {
        this.driversOptions = this.depotUtils.getDriversOfDepot(this.allDrivers, depotId);
    }

    onDepotChange(selectedDepot) {
        this.updateAvailableDrivers(selectedDepot.companyDepot.id);
        this.selectedDrivers = [];
        this.myForm.patchValue({
            'project': {
                'drivers': this.selectedDrivers
            }
        });
        
        this.companyDepotId = selectedDepot.companyDepot.id;
        this.updatePudoOptions();
    }

    resetModal() {
        this.projectId = null;
        this.name = '';
        this.selectedDrivers = null;
        this.departureTime = '';
        this.departureDateTime = '';
        this.driversIds = [];
        this.companyDepotId = null;
        this.selectedDrivers = [];
        this.driversOptions = [];
        this.avoidTolls = this.globals.companyDataSettings['avoid_tolls'];
        this.completionTime = null;
        this.completionTimeSelected = this.defaultCompletionTime;
        this.completionTimeEnabled = false;
        this.afterDispatchRespectOrderStrictness = 5;
        this.routingOptimizeMode = this.globals.projectRoutingOptimizeModeConstants['TIME'] ?? 0;
        this.pudoPointIds = [];
        this.updatePudoOptions();
        this.fixRespectOrderStrictness();
        this.setForm();
    }

    submitProject() {
        this.isClickedOnce = true;
        const projectData = this.myForm.value.project;

        const departureTimeElement = document.querySelector('#project-departure-time');
        if (M.Timepicker.getInstance(departureTimeElement).time) {
            this.departureTime = M.Timepicker.getInstance(departureTimeElement).time;
        }
        const today = moment().format('YYYY-MM-DD');
        const departureDatetimeUnformatted = today + ' ' + this.departureTime;
        this.departureDateTime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format().replace('Z', '+00:00');

        const completionTimeElement = document.querySelector('#project-completion-time');
        if (M.Timepicker.getInstance(completionTimeElement).time) {
            this.completionTimeSelected = M.Timepicker.getInstance(completionTimeElement).time;
        }
        this.completionTime = moment(this.completionTimeSelected, 'HH:mm').format('HH:mm:SS');
        if (!projectData.completion_time_enabled) this.completionTime = null;

        this.driversIds = [];
        this.companyDepotId = projectData.company_depot_id;
        projectData.drivers.forEach(driver => {
            this.driversIds.push(driver.id);
        });

        this.fixRespectOrderStrictness();
        this.patchForSubmit();
        const myObserver = {
            next: (response) => {
                this.stepsService.updateProjectsGrid(this.departureDateTime);
            },
            error: (error) => {
                this.formErrorsUtils.checkResponseForErrorCodes(error);
                console.error(error);
                this.isClickedOnce = false;
            },
            complete: () => {
                this.isClickedOnce = false;
                this.closeModal();
            },
        };

        if (this.projectId) {
            this.http.put('api/v1/projects/' + this.projectId, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
        } else {
            this.http.post('api/v1/projects', JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('PROJECT.RESPECT_NONE').subscribe((res: string) => { this.respectNoneLabel = res; }));
        this.listen.push(this.translate.get('PROJECT.RESPECT_FULL').subscribe((res: string) => { this.respectFullLabel = res; }));
        this.listen.push(this.translate.get('WARNINGS.PROJECT_DELETE').subscribe((res: string) => { this.deleteProjectWarningLabel = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        // init respect existing order slider
        setTimeout(() => {
            const pipFormats = { '0': this.respectNoneLabel, '5': this.respectFullLabel };
            this.respectSliderOptions = {
                step: 1,
                start: [0],
                connect: 'lower',
                range: {
                    'min': [0, 1],
                    'max': [5, 6]
                },
                pips: {
                    mode: 'range',
                    density: 100,
                    format: {
                        to: function (a) {
                            return pipFormats[a];
                        }
                    }
                }
            };
        }, 500);

        this.timeZone = moment.tz.guess();

        this.departureDateTime = this.globals.defaultDepartureDateTime;
        this.departureTime = moment(this.departureDateTime).format('HH:mm');
        this.avoidTolls = this.globals.companyDataSettings['avoid_tolls'];
        this.myForm.patchValue({
            'project': {
                'id': this.projectId,
                'driversIds': this.driversIds,
                'company_depot_id': this.companyDepotId,
                'default_project_departure_datetime': this.departureDateTime,
                'default_project_departure_datetime_timezone': this.timeZone,
                'avoid_tolls': this.avoidTolls,
                'after_dispatch_respect_order_strictness': this.afterDispatchRespectOrderStrictness,
                'routing_optimize_mode': this.routingOptimizeMode,
                'pudo_point_ids': this.pudoPointIds
            },
        });

        M.updateTextFields();

        this.http.get('api/internal/v2/drivers').pipe(take(1)).subscribe(response => {
            this.allDrivers = [];
            response['items'].forEach(driver => {
                this.allDrivers.push(driver);
                this.driversObject[driver.driver.id] = driver;
            });
        });
    }

    ngOnDestroy() {
    }

}
