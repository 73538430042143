import { take } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
// import { Chart } from 'chart.js';
import { ChartService } from '@app/services/chart.service';
import { DashboardProductivityDummyComponent } from './dashboard-productivity-dummy/dashboard-productivity-dummy.component';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-dashboard-productivity',
    templateUrl: './dashboard-productivity.component.html'
})

export class DashboardProductivityComponent implements OnInit, AfterViewInit {

    @ViewChild(DashboardProductivityDummyComponent, { static: false }) dashboardProductivityDummyComponent: DashboardProductivityDummyComponent;

    listen = [];

    chartExpanded = true;
    chartDisabled = false;

    chart;
    chartData = {};

    timeAxisEntries = [];
    rating = [];
    recipientsRating = [];
    durationAvg = [];
    driverShipmentsAvg = [];
    distanceAvg = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;
    showSampleDataText = false;

    // chart legend labels
    ratingLegend;
    recipientsRatingLegend;
    durationAvgLegend;
    driverShipmentsAvgLegend;
    distanceAvgLegend;

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public translate: TranslateService,
        public chartService: ChartService,
    ) {
        // this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
        //     this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
        //     this.updateView();
        // }));
    }

    initRatingChart() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardBars));
        let backgroundColor = '#00aeba';
        let chartData = [];
        // dummy data if no data is found
        if (!this.rating.length) {
            backgroundColor = '#00aeba50';
            chartOptions.plugins.tooltip = {enabled: false};
            chartOptions.hover = {mode: null};
            this.timeAxisEntries.forEach(date => {
                chartData.push(Math.floor(Math.random() * 5 + 1));
            });
            this.showSampleDataText = true;
        } else {
            chartData = this.rating;
            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('productivity-chart');
        if (this.chart) { this.chart.destroy(); }
        // chartOptions.scales.y.ticks. .ticks['stepSize'] = 5;
        chartOptions.scales.y.min = 0;
        chartOptions.scales.y.max = 5;
        // ticks.maxTicksLimi ['min'] = 0;
        // chartOptions.scales.yAxes[0].ticks['max'] = 5;

        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.timeAxisEntries,
                    datasets: [
                        {
                            label: this.ratingLegend,
                            data: chartData,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: backgroundColor,
                            barPercentage: 0.5
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    initRecipientsRatingChart() {
        const chartOptions: ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardBars));
        console.log('chartOptions:', chartOptions)
        let backgroundColor = '#00aeba';
        let chartData = [];
        // dummy data if no data is found
        if (!this.recipientsRating.length) {
            backgroundColor = '#00aeba50';
            chartOptions.plugins.tooltip = {enabled: false};
            chartOptions.hover = {mode: null};
            this.timeAxisEntries.forEach(date => {
                chartData.push(Math.floor(Math.random() * 5 + 1));
            });
            this.showSampleDataText = true;
        } else {
            chartData = this.recipientsRating;
            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('productivity-chart');
        if (this.chart) { this.chart.destroy(); }
        chartOptions.plugins.legend.display = true;
        // chartOptions.scales.
        // chartOptions.scales.yAxes[0].ticks['stepSize'] = 5;
        // chartOptions.scales.yAxes[0].ticks['min'] = 0;
        // chartOptions.scales.yAxes[0].ticks['max'] = 5;
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.timeAxisEntries,
                    datasets: [
                        {
                            label: this.recipientsRatingLegend,
                            data: chartData,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: backgroundColor,
                            barPercentage: 0.5
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    initAvgDurationStopPointChart() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardBars));
        let backgroundColor = '#00aeba';
        let chartData = [];
        // dummy data if no data is found
        if (!this.durationAvg.length) {
            backgroundColor = '#00aeba50';
            chartOptions.plugins.tooltip = {enabled: false};
            chartOptions.hover = {mode: null};
            this.timeAxisEntries.forEach(date => {
                chartData.push(Math.floor(Math.random() * 20 + 1));
            });
            this.showSampleDataText = true;
        } else {
            chartData = this.durationAvg;
            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('productivity-chart');
        // const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }

        chartOptions.scales.y.ticks.maxTicksLimit = 4;

        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.timeAxisEntries,
                    datasets: [
                        {
                            label: this.durationAvgLegend,
                            data: chartData,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: backgroundColor,
                            barPercentage: 0.5
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    initAvgDeliveriesDriver() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardBars));
        let backgroundColor = '#00aeba';
        let chartData = [];
        // dummy data if no data is found
        if (!this.driverShipmentsAvg.length) {
            backgroundColor = '#00aeba50';
            chartOptions.plugins.tooltip = {enabled: false};
            chartOptions.hover = {mode: null};
            this.timeAxisEntries.forEach(date => {
                chartData.push(Math.floor(Math.random() * 15 + 1));
            });
            this.showSampleDataText = true;
        } else {
            chartData = this.driverShipmentsAvg;
            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('productivity-chart');
        if (this.chart) { this.chart.destroy(); }

        chartOptions.scales.y.ticks.maxTicksLimit = 4;

        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.timeAxisEntries,
                    datasets: [
                        {
                            label: this.driverShipmentsAvgLegend,
                            data: chartData,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: backgroundColor,
                            barPercentage: 0.5
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    initAvgStopPointsDistance() {
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptionsDashboardBars));
        
        let backgroundColor = '#00aeba';
        let chartData = [];
        // dummy data if no data is found
        if (!this.distanceAvg.length) {
            backgroundColor = '#00aeba50';
            chartOptions.plugins.tooltip = {enabled: false};
            chartOptions.hover = {mode: null};
            this.timeAxisEntries.forEach(date => {
                chartData.push(Math.floor(Math.random() * 45 + 1));
            });
            this.showSampleDataText = true;
        } else {
            chartData = this.distanceAvg;
            this.showSampleDataText = false;
        }

        const ctx = <HTMLCanvasElement>document.getElementById('productivity-chart');
        if (this.chart) { this.chart.destroy(); }

        chartOptions.scales.y.ticks.maxTicksLimit = 4;

        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.timeAxisEntries,
                    datasets: [
                        {
                            label: this.distanceAvgLegend,
                            data: chartData,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: backgroundColor,
                            barPercentage: 0.5
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    getTranslations() {
        this.listen.push(this.translate.get('DASHBOARD.RATING').subscribe((res: string) => { this.ratingLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.RECIPIENTS_RATING').subscribe((res: string) => { this.recipientsRatingLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DURATION_STOP_POINT_LEGEND').subscribe((res: string) => { this.durationAvgLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DELIVERIES_DRIVER').subscribe((res: string) => { this.driverShipmentsAvgLegend = res; }));
        this.listen.push(this.translate.get('DASHBOARD.AVG_DISTANCE_BETWEEN_STOP_POINTS_LEGEND').subscribe((res: string) => { this.distanceAvgLegend = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        // this.listen.forEach(element => {
        //     element.unsubscribe();
        // });
    }
}
