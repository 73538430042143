import { Injectable } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Injectable({
    providedIn: 'root'
})
export class ChartService {
    listen = [];

    chartColours = [
        '#00aeba',
        '#e88846',
        '#84629d',
        '#ffc62b'
    ];

    periods = [
        {
            label: 'Last week',
            value: 7
        },
        {
            label: 'Last month',
            value: 30
        },
        {
            label: 'Last three months',
            value: 90
        },
        {
            label: 'Last six months',
            value: 180
        },
        {
            label: 'Last year',
            value: 365
        },
    ];

    chartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
            legend: {
                display: false
            }
        },
        scales: {
            x:{
                beginAtZero: true,
                offset: true,
                ticks:{
                    autoSkip: false
                }
            },
            y:{
                type: 'linear',
                beginAtZero: true,
                offset: true,
                ticks:{}
                // grid:{
                //     offset:
                // }
                // ticks:{
                    // autoSkip: false
                // }
            }
            // offset: true,
            // xAxes: [{
            //     ticks: {
            //         autoSkip: false,
            //         // padding: -110
            //     }
            // }],
            // yAxes: [{
            //     type: 'linear',
            //     ticks: {
            //         beginAtZero: true,
            //         userCallback: function (label, index, labels) {
            //             // when the floored value is the same as the value we have a whole number
            //             if (Math.floor(label) === label) {
            //                 return label;
            //             }
            //         },
            //     },
            //     gridLines: {
            //         offsetGridLines: false
            //     },
            //     display: true
            // }],
        },
        animations: {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: false
            }
        }
    };

    // for dashboard bar charts
    chartOptionsDashboardBars:ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
            legend: {
                display: false
            }
        },
        scales: {
            x:{
                beginAtZero: true,
                offset: true,
                ticks:{
                    autoSkip: false
                }
            },
            y:{
                type: 'linear',
                beginAtZero: true,
                offset: true,
                ticks:{
                    callback: (val) => val
                }
            }
        },
        // legend: {
        //     display: false
        // },
        // scales: {
        //     offset: true,
        //     xAxes: [{
        //         ticks: {
        //             autoSkip: false,
        //             // padding: -110
        //         },
        //         gridLines: {
        //             offsetGridLines: false,
        //             color: "rgba(0, 0, 0, 0.04)",
        //             zeroLineColor: "rgba(0, 0, 0, 0.04)"
        //         },
        //         stacked: true
        //     }],
        //     yAxes: [{
        //         type: 'linear',
        //         ticks: {
        //             beginAtZero: true,
        //             userCallback: function (label, index, labels) {
        //                 // when the floored value is the same as the value we have a whole number
        //                 if (Math.floor(label) === label) {
        //                     return label;
        //                 }
        //             },
        //         },
        //         gridLines: {
        //             offsetGridLines: false,
        //             color: "rgba(0, 0, 0, 0.04)",
        //             zeroLineColor: "rgba(0, 0, 0, 0.04)"
        //         },
        //         stacked: true,
        //         display: true
        //     }],
        // },
        animations: {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: false
            }
        }
    };

    // for dashboard drivers bar charts
    chartOptionsDashboardDriversBars:ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
            legend: {
                display: false
            }
        },
        scales: {
            x:{
                beginAtZero: true,
                offset: true,
                ticks:{
                    autoSkip: false
                }
            },
            y:{
                type: 'linear',
                beginAtZero: true,
                offset: true,
                ticks:{}
            }
        },
        // legend: {
        //     display: true
        // },
        // scales: {
        //     offset: true,
        //     xAxes: [{
        //         ticks: {
        //             autoSkip: false,
        //             // padding: -110
        //         },
        //         gridLines: {
        //             offsetGridLines: false,
        //             color: "rgba(0, 0, 0, 0.04)",
        //             zeroLineColor: "rgba(0, 0, 0, 0.04)"
        //         },
        //         stacked: true
        //     }],
        //     yAxes: [{
        //         type: 'linear',
        //         grace: '5%',
        //         ticks: {
        //             beginAtZero: true,
        //             userCallback: function (label, index, labels) {
        //                 // when the floored value is the same as the value we have a whole number
        //                 if (Math.floor(label) === label) {
        //                     return label;
        //                 }
        //             },
        //         },
        //         gridLines: {
        //             offsetGridLines: false,
        //             color: "rgba(0, 0, 0, 0.04)",
        //             zeroLineColor: "rgba(0, 0, 0, 0.04)"
        //         },
        //         stacked: true,
        //         display: true
        //     }],
        // },
        animations: {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: false
            }
        }
    };

    constructor() {     }

    setupPeriodsTranslations(lastWeekLabel, lastMonthLabel, lastThreeMonthsLabel, lastSixMonthsLabel, lastYearLabel) {
        this.periods[0].label = lastWeekLabel;
        this.periods[1].label = lastMonthLabel;
        this.periods[2].label = lastThreeMonthsLabel;
        this.periods[3].label = lastSixMonthsLabel;
        this.periods[4].label = lastYearLabel;
    }
}