import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take, tap } from 'rxjs/operators';
import { SettingsPriceListsViewModelService } from '../settings-price-lists-viewmodel.service';
import { ISettings_Price_List_Charge_Dto, ISettings_Price_List_Dto, ISettings_Price_List_Service_Charge_Dto } from '@app/api/models/settings-price-lists-dto';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { INITIAL_DATA } from '@app/model/initial-data';
import { priceListModalSetup } from './new-price-list-modal-utils';
import { Observable, from } from 'rxjs';
import {MultiSelectModule} from 'primeng/multiselect';
import { ILmBEBaseTypeEnum, ILmBaseEnum, IVeronaBaseEnum } from '@app/model/enums';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';


@UntilDestroy()
@Component({
  templateUrl: 'new-price-list-modal.component.html',
  styleUrls: ['./new-price-list-modal.component.scss']
})
export class PriceListModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: SettingsPriceListsViewModelService;
  saveBttn: LmButtonConfig;
  duplicateBttn: LmButtonConfig;
  newRowBtn: LmButtonConfig;
  newServiceRowBtn: LmButtonConfig;
  addRowBtn: LmButtonConfig;
  addServiceRowBtn: LmButtonConfig;
  cancelRowBtn: LmButtonConfig;
  newPieceRowBtn: LmButtonConfig;
  weightTableCols: LmTableBaseColumnDefs[];
  piecesTableCols: LmTableBaseColumnDefs[];
  distanceTableCols: LmTableBaseColumnDefs[];
  serviceTableCols: LmTableBaseColumnDefs[];
  tableItemDropDownOptions: IVeronaBaseEnum[];

  distanceOptions: Array<ILmBaseEnum>
  distanceOptionsAll: Array<ILmBaseEnum>
  distanceOptionsNoSameCity: Array<ILmBaseEnum>

  item: ISettings_Price_List_Dto;
  options: any;

  weightRows: ISettings_Price_List_Charge_Dto[];
  piecesRows: ISettings_Price_List_Charge_Dto[];
  newServiceRow: ISettings_Price_List_Service_Charge_Dto;
  newRow: ISettings_Price_List_Charge_Dto;
  newRowType:string;

  canAddAdditionalItem = false;
  globals;

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService,
    private formErrorsUtils: FormErrorsUtils,
  )
  {
    const {data:{item}} = config;
    this.VM = config.data.VM;
    const {tableItemDropDownOptions, weightTableCols, piecesTableCols, distanceTableCols, serviceTableCols, saveBttn, duplicateBttn, newRowBtn, newServiceRowBtn, addRowBtn, addServiceRowBtn, cancelRowBtn} = priceListModalSetup(this, this.VM.modalConfig.data.translations)
    this.globals = config.data.globals;
    const distanceOptionsAll = [];
    const distanceOptionsNoSameCity = [];
    Object.keys(this.globals.priceListDistanceEnum).forEach(key => {
      const option = {
        key: this.globals.priceListDistanceEnum[key].key,
        value: this.globals.priceListDistanceEnum[key].key,
        caption: this.globals.priceListDistanceEnum[key].caption,
      }
      distanceOptionsAll.push(option);
      if (key!=='SameCity') distanceOptionsNoSameCity.push(option);
    });
    this.distanceOptionsAll = distanceOptionsAll;
    this.distanceOptionsNoSameCity = distanceOptionsNoSameCity;
    this.distanceOptions = this.distanceOptionsAll;
    
    this.item = item ?? {...this.VM.newItem()};
    if(this.item.id) {
      this.fromToChanged();
      this.VM.getById(this.item.id).subscribe(response=>{
        this.item = response.items[0];
        this.item.services = this.item.services.map(s => (<any>s).id);
        this.item.per_item_charges.forEach(item=> {
          item['type'] = this.tableItemDropDownOptions.find(row => row['is_additional'] == item['is_additional'])['value'];
        });
        this.item.weight_charges.forEach(item=> {
          item['type'] = this.tableItemDropDownOptions.find(row => row['is_additional'] == item['is_additional'])['value'];
        });
        this.item.distance_charges.forEach(item=> {
          item['type'] = this.tableItemDropDownOptions.find(row => row['is_additional'] == item['is_additional'])['value'];
        });
        // this.item.addon_charges = this.item.addon_services;
        this.item.addon_services.forEach(charge => {
          charge['hideDelete'] = charge['is_generic'];
        });
      });
    }
    this.item.gov_service_id = this.globals.govServiceId;
    this.item.services = this.item.services.map(s => (<any>s).id)
    this.tableItemDropDownOptions = tableItemDropDownOptions;
    this.updateButtons();
    this.weightTableCols = weightTableCols;
    this.piecesTableCols = piecesTableCols;
    this.distanceTableCols = distanceTableCols;
    this.serviceTableCols = serviceTableCols;
    this.saveBttn = saveBttn;
    this.duplicateBttn = duplicateBttn;
    this.newRowBtn = newRowBtn;
    this.newServiceRowBtn = newServiceRowBtn;
    this.addRowBtn = addRowBtn;
    this.addServiceRowBtn = addServiceRowBtn;
    this.cancelRowBtn = cancelRowBtn;
  }

  private isFormValid(): boolean {
    return Boolean(this.item.name && this.item.type.length && this.item.distance_type.length && this.item.services.length);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
      this.duplicateBttn.disabled = !this.isFormValid();
    }, 100);
  }

  areaGroupFromChanged() {
    this.item.area_groups.to = {...this.item.area_groups.from};
    this.fromToChanged();
  }

  areaGroupToChanged() {
    this.fromToChanged();
  }

  fromToChanged() {
    // this functionallity is disabled
    // if(this.item.area_groups.from != this.item.area_groups.to){
    //   this.item.distance_type = this.item.distance_type.filter(type => type !== this.globals.priceListDistanceEnum['SameCity'].key);
    //   this.distanceOptions = this.distanceOptionsNoSameCity;
    // } else {
    //   this.distanceOptions = this.distanceOptionsAll;
    // }
  }

  private promptNewRow(tableRows) {
    this.newRowType = tableRows;
    this.canAddAdditionalItem = this.item[tableRows]?.find(row => row.is_additional) ? false : true;
    this.newRow = {...INITIAL_DATA.newChargeTableRowItem};
  }

  private promptNewServiceRow() {
    this.newServiceRow = {...INITIAL_DATA.newServiceChargeTableRowItem};
  }

  addNewRow(tableRows) {
    if(!this.item[tableRows]) {
      this.item[tableRows] = [];
    }
    if(!this.newRow['type']) {
      this.newRow['type'] = this.tableItemDropDownOptions.find(row => row['is_additional'] == this.newRow['is_additional'])['value'];
    }
    const selectedOption = this.tableItemDropDownOptions.find(row => row.value == this.newRow['type']);
    this.newRow['is_additional'] = selectedOption? selectedOption['is_additional']:false;
    if(this.newRow.price_value && this.newRow.charge_value){
      this.item[tableRows] = [...this.item[tableRows], this.newRow];
    }
    this.newRow = null;
    this.newRowType = null;
  }

  addNewServiceRow() {
    if (!this.item['addon_services']) {
      this.item['addon_services'] = [];
    }
    if (this.newServiceRow.value && this.newServiceRow.name) {
      this.item['addon_services'] = [...this.item['addon_services'], this.newServiceRow];
    }
    this.newServiceRow = null;
  }

  cancelNewRow() {
    this.newRow = null;
  }

  private duplicateItem() {
    this.item = {...this.item, name: undefined, id: undefined}
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  
  ok = (duplicate?) => {
    if (this.isFormValid()) return this.VM.postItem({ price_lists: [this.item] })
      .pipe(take(1), untilDestroyed(this),)
      .subscribe(_ => {
        if(duplicate) return this.duplicateItem();
        this.item = this.VM.newItem()
        this.config.close()
      }, error => {
        this.formErrorsUtils.checkResponseForErrorCodes(error);
      })
  }
  cancel = () => this.config.close();
}
