import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { CustomersGridGuidedTourComponent } from './customers-grid-guided-tour/customers-grid-guided-tour.component';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { CustomerDateModalComponent } from '@app/modals/customer-date-modal/customer-date-modal.component';
import { CustomerModalComponent } from '@app/modals/customer-modal/customer-modal.component';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-customers-grid',
    templateUrl: './customers-grid.component.html',
    styleUrls: ['./../grids.scss']
})
export class CustomersGridComponent implements OnInit, OnDestroy {
    @ViewChild(CustomersGridGuidedTourComponent, { static: false }) customersGridGuidedTourComponent: CustomersGridGuidedTourComponent;
    @ViewChild(CustomerModalComponent, { static: false }) customerModalComponent: CustomerModalComponent;
    @ViewChild(CustomerDateModalComponent, { static: false }) customerDateModalComponent: CustomerDateModalComponent;
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('openChartButton', { static: false }) openChartButton;
    @ViewChild('closeChartButton', { static: false }) closeChartButton;

    listen = [];

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];
    columnDefs;
    rowModelType = 'serverSide';
    cacheBlockSize = 50;
    gridApi;
    gridColumnApi;
    rowData = [];

    pagesCount = 0;
    customersDataArray = [];
    customersCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    nameTitle;
    addressTitle;
    emailTitle;
    typeTitle;
    statusTitle;
    mostVisitedLabel;
    showSortDropdown = false;
    selectedOrder = '';

    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;
    canCreate = false;

    noNameLabel = '';

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private addressService: AddressService,
    ) {
        this.canCreate = this.globals.accessRole == this.globals.teamMemberTypeConstants['ADMIN'] || this.globals.accessRole == this.globals.teamMemberTypeConstants['ADVANCED'];
        this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }));
        this.listen.push(this.modalService.openCustomerDateModalListen().subscribe(() => {
            this.customerDateModalComponent.openModal();
        }));
    }

    newButtonClicked() {
        if (!this.customerModalComponent.modalOpen) {
            this.modalService.openCustomerModal(null);
        }
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getDataForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'most-visited':
                data = this.mostVisited;
                break;
        }
        return data;
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
        this.openChartButton.nativeElement.classList.add('active');
        this.closeChartButton.nativeElement.classList.remove('active');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
        this.openChartButton.nativeElement.classList.remove('active');
        this.closeChartButton.nativeElement.classList.add('active');
    }

    emptyChartArrays() {
        this.customerNames = [];
        this.customerShortNames = [];
        this.mostVisited = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.customerShortNames = [];
        for (let i = 0; i < 10; i++) {
            this.customerShortNames.push('');
        }
        this.mostVisited = [25, 24, 20, 16, 16, 15, 12, 10, 8, 8];
        this.chartDisabled = true;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&ids=';
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        this.http.get('api/v1/stats/customer-stats' + params).pipe(take(1)).subscribe(response => {
            this.dataLoading = false;
            const data = response['items'];
            if (Object.keys(data).length) {
                if (Object.keys(data).length < this.pageSize) {
                    this.lastPage = true;
                } else if (Object.keys(data).length === this.pageSize) {
                    this.lastPage = false;
                }
                Object.keys(data).forEach(id => {
                    if (data[id]) {
                        this.chartData[id] = data[id];
                        this.customerNames.push(data[id].contact_name);
                        this.customerShortNames.push(this.getFirstWord(data[id].contact_name));
                        this.mostVisited.push(data[id].stopPointsCount);
                    }
                });
                if (this.checkIfAllZero()) {
                    this.displayDummyChartValues();
                }
            } else {
                this.lastPage = true;
                this.displayDummyChartValues();
            }
            this.initChart();
        });
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('customers-chart');
        const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }
        // const chartOptions:ChartOptions = this.chartService.chartOptions;
        const customerNames = this.customerNames;
        // chartOptions['tooltips'] = {
        //     callbacks: {
        //         title: (tooltipItem, tooltipData) => {
        //             return customerNames[tooltipItem[0].index];
        //         }
        //     }
        // };
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.customerShortNames,
                    datasets: [{
                        barPercentage: 0.9,
                        barThickness: 'flex',
                        maxBarThickness: 150,
                        minBarLength: 2,
                        data: data,
                        backgroundColor: (() => {
                            const backgroundColours = [];
                            for (let i = 0; i < data.length; ++i) {
                                backgroundColours.push(this.chartService.chartColours[i % this.chartService.chartColours.length]);
                            }
                            return backgroundColours;
                        })()
                    }]
                }
                // ,
                // options: this.chartService.chartOptions
            });
        }
    }

    getCustomersGridData(pageSize) {
        let url = 'api/v1/customers';
        url += '?pageSize=' + pageSize;
        url += '&page=' + this.pagesCount;
        url += '&searchQuery=' + this.searchString;
        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setCustomersGridData(dataArray), lastRow);

        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setCustomersGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getCustomersGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
            this.customersCount = response['itemsMeta'].totalCount;
            this.customersDataArray = response['items'];
            this.displayDataToGrid(params, this.customersDataArray, response['itemsMeta']['pagesCount']);
            this.gridApi.hideOverlay();
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }

    createServerSideDatasource() {
        const self = this;
        return {
            getRows: function (params) {
                self.gridApi.showLoadingOverlay();
                self.loadSinglePage(params);
            },
        };
    }

    setCustomersGridData(customers) {
        this.customersDataArray = [];
        let customer, gridObject = {};
        customers.forEach(customerData => {
            customer = customerData.customer;
            let name = customer.contact_name;
            if (name === '_NO_NAME') {
                name = this.noNameLabel;
            }
            gridObject = {
                id: customer.id,
                name: {
                    name: name,
                    phone: customer.telephone,
                    customerId: customer.customer_identification_number
                },
                address: this.addressService.getAddressLabel(customer.address),
                email: customer.email,
                entity_type: this.globals.customerEntityTypeLabels[customer.entity_type],
                entity_status: this.globals.customerEntityStatusLabels[customer.entity_status],
                fullData: customer
            };
            this.customersDataArray.push(gridObject);
        });
        return this.customersDataArray;
    }

    rowClicked(event) {
        if (!event.data.noDataText && !this.customerModalComponent.modalOpen) {
            this.modalService.openCustomerModal(event.data.id);
        }
    }

    startTour() {
        this.customersGridGuidedTourComponent.startGuide();
    }

    onFirstDataRendered(params) { }

    nameRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.setServerSideDatasource(this.createServerSideDatasource());
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.TITLE').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.EMAIL').subscribe((res: string) => { this.emailTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.TYPE').subscribe((res: string) => { this.typeTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.MOST_VISITED').subscribe((res: string) => { this.mostVisitedLabel = res; }));

        this.charts = [
            {
                label: this.mostVisitedLabel,
                value: 'most-visited',
            },
        ];
        this.selectedChart = this.charts[0];

        this.columnDefs = [
            {
                headerName: this.nameTitle, field: 'name',
                cellRenderer: this.nameRenderer, width: this.gridsService.widthCalculator(30)
            },
            {
                headerName: this.addressTitle, field: 'address',
                cellRenderer: this.gridsService.addressRenderer, width: this.gridsService.widthCalculator(30)
            },
            {
                headerName: this.emailTitle, field: 'email', width: this.gridsService.widthCalculator(20)
            },
            {
                headerName: this.typeTitle, field: 'entity_type', width: this.gridsService.widthCalculator(10)
            },
            {
                headerName: this.statusTitle, field: 'entity_status', width: this.gridsService.widthCalculator(10)
            },
        ];
    }

    ngOnInit(): void {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.periods = this.chartService.periods;
        this.selectedPeriod = this.periods[4];

        this.getChartData();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.pagesCount = 0;
                // this.gridApi.purgeServerSideCache([]);
                this.gridApi.refreshServerSideStore({ purge: true });
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
