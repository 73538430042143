import { DepotUtils } from '@app/utils/depot-utils';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { Router } from '@angular/router';

@Component({
    selector: 'app-move-shipment-modal',
    templateUrl: './move-shipment-modal.component.html',
    styleUrls: ['./move-shipment-modal.component.scss']
})
export class MoveShipmentModalComponent implements OnInit, OnDestroy {
    @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;
    @ViewChild('modalBackground', { static: false, read: ElementRef }) modalBackground: ElementRef;

    isClickedOnce = false;
    canBeOpened = true;

    depots = [];
    selectedDepot = null;

    listen = [];

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        public router: Router,
        public depotUtils: DepotUtils,
    ) {

    }

    onDepotChange(depot) {
        this.selectedDepot = depot;
    }

    openModal() {
        if (this.canBeOpened) {
            this.canBeOpened = false;

            if (this.modal.nativeElement.classList.contains('closed')) {
                this.modal.nativeElement.classList.remove('closed');
                this.modal.nativeElement.classList.add('open');
                this.modalBackground.nativeElement.classList.remove('hidden');
            }
        }
    }

    closeModal() {
        this.modal.nativeElement.classList.add('closed');
        this.modal.nativeElement.classList.remove('open');
        this.modalBackground.nativeElement.classList.add('hidden');

        this.selectedDepot = null;

        setTimeout(() => {
            this.canBeOpened = true;
        }, 500);
    }

    submitShipmentTransport() {
        console.log(this.selectedDepot);
        this.closeModal();
    }

    getTranslations() {
        // this.listen.push(this.translate.get('PROJECT.RESPECT_NONE').subscribe((res: string) => { this.respectNoneLabel = res; }));
        // this.listen.push(this.translate.get('PROJECT.RESPECT_FULL').subscribe((res: string) => { this.respectFullLabel = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
    }

}
