import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { AgGridAngular } from '@ag-grid-community/angular';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { FulfillmentUtils } from '@app/utils/fulfillment-event-utils';

@Component({
  selector: 'stop-point-history-grid',
  templateUrl: './stop-point-history-grid.component.html',
  styleUrls: ['./stop-point-history-grid.component.scss']
})
export class StopPointHistoryGridComponent implements OnInit {
  @ViewChild('stopPointHistoryGrid', { static: false }) stopPointHistoryGrid: AgGridAngular;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  searchTextChanged = new Subject<string>();
  searchString: String = '';
  userQuestionUpdate = new Subject<string>();
  domLayout = 'autoHeight';
  stopPointEventsDataArray = [];
  stopPointEventsCount = 0;
  nameTitle;
  addressTitle;
  dateTitle;
  typeTitle;
  statusTitle;
  arrivalTimeTitle;
  cancelOtherReasonMsg;
  cancelNotThereMSG;
  cancelWrongAddressMSG;
  cancelDidNotAcceptMSG;

  stopPointId;
  stopPointName;
  stopPointAddress;

  dividerThreeDaysLabel;
  dividerFiveDaysLabel;
  dividerSevenDaysLabel;
  byDriver;
  byMily;
  byClient;
  byAdministrator;
  statusNotSpecifiedLabel;
  statusUnscheduledLabel;
  statusScheduledDeliveryLabel;
  statusSmartPointLabel;
  statusSmartPointAbsentLabel;
  statusScheduledPickupLabel;
  statusScheduledLabel;
  statusLoadingLabel;
  statusInProgressLabel;
  statusInProgressDeliveryLabel;
  statusInProgressPickupLabel;
  statusInProgressDeliverySoonLabel;
  statusInProgressPickupSoonLabel;
  statusArrivedLabel;
  statusCompletedLabel;
  statusCanceledLabel;
  statusDroppedLabel;
  statusHandedCanceled;
  statusHandedCompleted;
  statusPickupCompleted;
  descAgreedShipping;
  descAgreedShippingWrongAddress;
  descDidNotAcceptIt;
  descDriverDidNotAcceptIt;
  descDriverDidNotAcceptCharge;
  descDriverDidNotAcceptPOD;
  descNotThere;
  descPickUpFromDepot;
  descWrongAddress;
  descNotAware;
  descNothingToSend;
  descUnknownReceiverRecipient;
  descParcelsNotReady;

  columnDefs;
  rowModelType = 'serverSide';
  cacheBlockSize = 50;
  gridApi;
  gridColumnApi;
  rowData = [];
  pagesCount = 0;
  noNameLabel = '';
  listen = [];
  projectProblemId: number;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    public gridsService: GridsService,
    private fulfillmentUtils: FulfillmentUtils,
  ) {  }

  getHistoryGridData(pageSize) {
    let historyUrl = '/stop-point-fulfillment-event'
    if (this.globals.collaboratorModeEnabled ||
      (!this.globals.collaboratorModeEnabled && this.globals.isInRoute('newShipmentsView')) ||
      (!this.globals.collaboratorModeEnabled && this.globals.isInRoute('shipmentsHistory'))
    ) {
      historyUrl = '/partner-stop-point-fulfillment-event';
    }

    let url = 'api/v1/stop-point/' + this.stopPointId + historyUrl;
    url += '?pageSize=' + pageSize;
    url += '&page=' + this.pagesCount;
    if(this.projectProblemId) url += `&projectProblemId=${this.projectProblemId}`;

    return this.http.get(url);
  }

  displayDataToGrid(params, dataArray, pagesCount) {
    if (dataArray.length) {
      let lastRow = -1;
      this.pagesCount++;
      if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
        lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
      }
      params.successCallback(this.setStopsEventsGridData(dataArray), lastRow);

    } else {
      if (this.pagesCount) {
        const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
        params.successCallback(this.setStopsEventsGridData(dataArray), lastRow);
      } else {
        const noData = { noDataText: 'No data' }
        params.successCallback([noData], 1);
      }
    }
  }

  // request and pass grid data page by page
  loadSinglePage(params) {
    this.getHistoryGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
      this.stopPointEventsCount = response['fulfillment_events']['itemsMeta'].totalCount;
      this.stopPointEventsDataArray = response['fulfillment_events']['items'];

      this.displayDataToGrid(params, this.stopPointEventsDataArray, response['fulfillment_events']['itemsMeta']['pagesCount']);
      this.gridApi.hideOverlay();
    },
      (error) => {
        console.error(error);
        params.failCallback();
        this.gridApi.hideOverlay();
      }
    );
  }

  createServerSideDatasource() {
    const self = this;
    return {
      getRows: function (params) {
        self.gridApi.showLoadingOverlay();
        self.loadSinglePage(params);
      },
    };
  }

  setStopsEventsGridData(data) {
    const self = this;

    let gridObject = {};
    self.stopPointEventsDataArray = [];
    let history = [];
    if (data.length) {
      data.forEach((stopPoint, currEventIndex) => {
        // date time value
        let fulfillmentDate = moment(stopPoint.fulfillment_datetime).format('DD/MM/YYYY');
        let fulfillmentTime = moment(stopPoint.fulfillment_datetime).format('HH:mm');

        // by value
        let byValue;
        if (stopPoint.application_type == this.globals.applicationTypes['WEB_APP']) {
          byValue = this.byAdministrator;
        } else if (stopPoint.application_type == this.globals.applicationTypes['SOLVER_APP']) {
          byValue = this.byMily;
        } else if (stopPoint.application_type == this.globals.applicationTypes['MOBILE_APP']) {
          byValue = this.byDriver;
        } else if (stopPoint.application_type == this.globals.applicationTypes['CLIENT_PORTAL_APP']) {
          byValue = this.byClient;
        } else {
          byValue = '-';
        }

        // status/reason values (doesn't use grid-utils because it has the unique property 'description')
        let statusObj, statusValue = '';
        switch (stopPoint.reason) {
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['NOT_SPECIFIED']].AT_TIME:
            statusObj = {
              status: this.statusNotSpecifiedLabel,
              description: stopPoint.description,
              colorClass: 'status-normal'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']].AT_TIME:
            statusObj = {
              status: this.statusUnscheduledLabel,
              description: stopPoint.description,
              colorClass: 'status-red'
            };
            break;
          case this.globals.smartPointFulfillmentReasonsStopPoint['FULFILLMENT_REASON_INSERT_TO_PUDO_POINT_RECEIVER_ABSENT']:
            statusObj = {
              status: this.statusSmartPointAbsentLabel,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          case this.globals.pudoFulfillmentConstant:
            statusObj = {
              status: this.statusSmartPointLabel,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          case this.globals.smartPointFulfillmentConstant:
            statusObj = {
              status: this.statusSmartPointLabel,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          // legacy
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME:
            statusObj = {
              status: this.statusScheduledDeliveryLabel,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_PICKUP:
            statusObj = {
              status: this.statusScheduledPickupLabel,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_DELIVERY:
            statusObj = {
              status: this.statusScheduledDeliveryLabel,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['LOADING']].AT_TIME:
            statusObj = {
              status: this.statusLoadingLabel,
              description: stopPoint.description,
              colorClass: 'status-normal'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].AT_TIME:
            if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
              statusValue = this.statusInProgressDeliveryLabel;
            } else {
              statusValue = this.statusInProgressPickupLabel;
            }

            statusObj = {
              status: statusValue,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].PENDING:
            if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
              statusValue = this.statusInProgressDeliverySoonLabel;
            } else {
              statusValue = this.statusInProgressPickupSoonLabel;
            }

            statusObj = {
              status: statusValue,
              description: stopPoint.description,
              colorClass: 'status-orange'
            };

            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME:
            statusObj = {
              status: this.statusArrivedLabel,
              description: stopPoint.description,
              colorClass: 'status-blue'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME:
            statusObj = {
              status: this.statusCompletedLabel,
              description: stopPoint.description,
              colorClass: 'status-blue'
            };

            if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
              statusObj['status'] = this.statusPickupCompleted;
            } else {
              statusObj['status'] = this.statusCompletedLabel;
            }
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].COMPLETED_HANDED:
            statusObj = {
              description: stopPoint.description,
              colorClass: 'status-blue'
            };

            if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
              statusObj['status'] = this.statusHandedCompleted;
            } else {
              statusObj['status'] = this.statusCompletedLabel;
            }
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM:
            statusObj = {
              status: this.statusCanceledLabel,
              description: stopPoint.description,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descNotThere,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descWrongAddress,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descDidNotAcceptIt,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descDriverDidNotAcceptIt,
              colorClass: 'status-red'
            };
            break;
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descDriverDidNotAcceptPOD,
              colorClass: 'status-red'
            };
            break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descDriverDidNotAcceptCharge,
              colorClass: 'status-red'
            };
            break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY']:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descParcelsNotReady,
              colorClass: 'status-red'
            };
            break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE']:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descNotAware,
              colorClass: 'status-red'
            };
            break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND']:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descNothingToSend,
              colorClass: 'status-red'
            };
            break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT']:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descUnknownReceiverRecipient,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descPickUpFromDepot,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descAgreedShipping,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS:
            statusObj = {
              status: this.statusCanceledLabel,
              description: this.descAgreedShipping,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CANCELED_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS_HANDED: // cancel handed
          case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT_HANDED: // cancel handed
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY_HANDED']: // cancel handed
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE_HANDED']: // cancel handed
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY_HANDED']: // cancel handed
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE_HANDED']: // cancel handed
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND_HANDED']: // cancel handed
          case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT_HANDED']: // cancel handed
            // find the cancel reason
            let isCanceledReasonFound, descriptionValue;
            for (let index = currEventIndex; index < data.length; index++) {
              if (String(data[index].reason).charAt(0) == String(this.globals.stopPointFulfillmentStatusConstants['CANCELED']) && !isCanceledReasonFound) {
                isCanceledReasonFound = true;
                switch (data[index].reason) {
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM:
                    descriptionValue = stopPoint.description
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING:
                    descriptionValue = this.descAgreedShipping;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS:
                    descriptionValue = this.descAgreedShippingWrongAddress;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT:
                    descriptionValue = this.descDidNotAcceptIt;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT:
                    descriptionValue = this.descDriverDidNotAcceptIt;
                    break;
                  case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']:
                    descriptionValue = this.descDriverDidNotAcceptPOD;
                    break;
                  case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']:
                    descriptionValue = this.descDriverDidNotAcceptCharge;
                    break;
                  case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY']:
                    descriptionValue = this.descParcelsNotReady;
                    break;
                  case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE']:
                    descriptionValue = this.descNotAware;
                    break;
                  case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND']:
                    descriptionValue = this.descNothingToSend;
                    break;
                  case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT']:
                    descriptionValue = this.descUnknownReceiverRecipient;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE:
                    descriptionValue = this.descNotThere;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT:
                    descriptionValue = this.descPickUpFromDepot;
                    break;
                  case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS:
                    descriptionValue = this.descWrongAddress;
                    break;
                  default:
                    descriptionValue = 'CANCEL CASE ERROR';
                    break;
                }

                break;
              }
            }

            statusObj = {
              status: this.statusHandedCanceled,
              description: descriptionValue,
              colorClass: 'status-red'
            };
            break;
          case this.globals.stopPointFulfillmentEventConstants[9].AT_TIME:
            statusObj = {
              status: this.statusDroppedLabel,
              description: stopPoint.description,
              colorClass: 'status-red',
              cellType: 'single-cell'
            };
            break;
          default:
            statusObj = {
              status: 'ERROR',
              description: 'Error',
              colorClass: 'status-red',
              cellType: 'single-cell'
            };
        }

        gridObject = {
          fulfillment_datetime: {
            datetime: stopPoint.fulfillment_datetime,
            date: fulfillmentDate,
            time: fulfillmentTime
          },
          application_type: byValue,
          description: stopPoint.description,
          reason: statusObj,
          address: this.stopPointAddress,
          rowHeight: '25px'
        };

        self.stopPointEventsDataArray.push(gridObject);
      });

      // create history array of objects
      let threeDone;
      let fiveDone;
      let sevenDone;
      let threeDaysArr = [];
      let fiveDaysArr = [];
      let sevenDaysArr = [];
      let restDaysArr = [];
      let currentDate = moment();

      self.stopPointEventsDataArray.forEach(stopPoint => {
        let rowDate = moment(stopPoint.fulfillment_datetime.datetime);
        let dayDiff = currentDate.diff(rowDate, 'days');

        if (!threeDone) {
          if (dayDiff >= 0 && dayDiff <= 3) {
            threeDaysArr.push(stopPoint);
          } else if (threeDaysArr.length) {
            threeDone = true;
            threeDaysArr.push({
              lastThreeDays: true,
              rowText: this.dividerThreeDaysLabel,
              rowHeight: '16px'
            });
          } else {
            threeDone = true;
          }
        }

        if (threeDone && !fiveDone) {
          if (dayDiff > 3 && dayDiff <= 5) {
            fiveDaysArr.push(stopPoint);
          } else if (fiveDaysArr.length) {
            fiveDone = true;
            fiveDaysArr.push({
              lastFiveDays: true,
              rowText: this.dividerFiveDaysLabel,
              rowHeight: '16px'
            });
          } else {
            fiveDone = true;
          }
        }

        if (threeDone && fiveDone && !sevenDone) {
          if (dayDiff > 5 && dayDiff <= 7) {
            sevenDaysArr.push(stopPoint);
          } else if (sevenDaysArr.length) {
            sevenDone = true;
            sevenDaysArr.push({
              lastSevenDays: true,
              rowText: this.dividerSevenDaysLabel,
              rowHeight: '16px'
            });
          }
          else {
            sevenDone = true;
          }
        }

        if (threeDone && fiveDone && sevenDone) {
          restDaysArr.push(stopPoint);
        }
      });

      // combine all arrays into final history array
      history = history.concat(threeDaysArr);
      history = history.concat(fiveDaysArr);
      history = history.concat(sevenDaysArr);
      history = history.concat(restDaysArr);
    } else {
      const noData = { noDataText: 'No data' }
      history.push(noData);
    }

    return history;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  rowClicked(event) { }

  search($event) {
    this.searchTextChanged.next($event);
  }

  // custom cell renderer for status
  statusRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().description) {
        columnObject += '<div class="double-cell standard-width ' + params.getValue().colorClass + '" title="' + params.getValue().status + '">' + params.getValue().status + '</div>';
        columnObject += '<div class="double-cell standard-width">' + params.getValue().description + '</div>';
      }
      else {
        columnObject += '<div class="single-cell standard-width ' + params.getValue().colorClass + '" title="' + params.getValue().status + '">' + params.getValue().status + '</div>';
      }
    }
    else if (params.data.rowText) {
      columnObject += '<div class="single-cell standard-width">' + params.data.rowText + '</div>';
    }
    return columnObject;
  }

  // toggle history visibility
  toggleHistory() {
    document.getElementById('stop-point-history-grid').classList.toggle('hidden');
  }

  closeHistory() {
    document.getElementById('stop-point-history-grid').classList.add('hidden');
  }

  loadStopPointHistory(id, name, address, projectProblemId?) {
    if(projectProblemId) this.projectProblemId = projectProblemId;
    this.pagesCount = 0;
    this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
    this.stopPointId = id;
    this.stopPointName = name;
    this.stopPointAddress = address;

    this.toggleHistory();
  }

  onFirstDataRendered(params) { }

  getTranslations() {
    let colDateTime, colBy, colStatus, colAddress;

    this.listen.push(this.translate.get('STOP_POINT_HISTORY.COL_DATE_TIME').subscribe((res: string) => { colDateTime = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.COL_BY').subscribe((res: string) => { colBy = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.COL_STATUS').subscribe((res: string) => { colStatus = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.COL_ADDRESS').subscribe((res: string) => { colAddress = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DIV_3_DAYS').subscribe((res: string) => { this.dividerThreeDaysLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DIV_5_DAYS').subscribe((res: string) => { this.dividerFiveDaysLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DIV_7_DAYS').subscribe((res: string) => { this.dividerSevenDaysLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_NOT_SPECIFIED').subscribe((res: string) => { this.statusNotSpecifiedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_UNSCHEDULED').subscribe((res: string) => { this.statusUnscheduledLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_SCHEDULED').subscribe((res: string) => { this.statusScheduledLabel = res; }));
    this.listen.push(this.translate.get('STATUS.SCHEDULED_DELIVERY').subscribe((res: string) => { this.statusScheduledDeliveryLabel = res; }));
    this.listen.push(this.translate.get('CANCEL.SMART_POINT_ACCEPT_IT').subscribe((res: string) => { this.statusSmartPointLabel = res }));
    this.listen.push(this.translate.get('CANCEL.SMART_POINT_ABSENT').subscribe((res: string) => { this.statusSmartPointAbsentLabel = res }));
    this.listen.push(this.translate.get('STATUS.SCHEDULED_PICKUP').subscribe((res: string) => { this.statusScheduledPickupLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_LOADING').subscribe((res: string) => { this.statusLoadingLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_IN_PROGRESS').subscribe((res: string) => { this.statusInProgressLabel = res; }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_SOON_DELIVERY').subscribe((res: string) => { this.statusInProgressDeliverySoonLabel = res; }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_SOON_PICKUP').subscribe((res: string) => { this.statusInProgressPickupSoonLabel = res; }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_DELIVERY').subscribe((res: string) => { this.statusInProgressDeliveryLabel = res; }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_PICKUP').subscribe((res: string) => { this.statusInProgressPickupLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_ARRIVED').subscribe((res: string) => { this.statusArrivedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_ARRIVED').subscribe((res: string) => { this.statusArrivedLabel = res; }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => { this.statusCompletedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_CANCELED').subscribe((res: string) => { this.statusCanceledLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.STATUS_DROPPED').subscribe((res: string) => { this.statusDroppedLabel = res; }));
    this.listen.push(this.translate.get('STATUS.HANDED_COMPLETED').subscribe((res: string) => { this.statusHandedCompleted = res; }));
    this.listen.push(this.translate.get('STATUS.COMPLETED_PICKUP').subscribe((res: string) => { this.statusPickupCompleted = res; }));
    this.listen.push(this.translate.get('STATUS.HANDED_CANCELED').subscribe((res: string) => { this.statusHandedCanceled = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.BY_CLIENT').subscribe((res: string) => { this.byClient = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.BY_DRIVER').subscribe((res: string) => { this.byDriver = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.BY_MILY').subscribe((res: string) => { this.byMily = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.BY_ADMIN').subscribe((res: string) => { this.byAdministrator = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.AGREED_SHIPPING').subscribe((res: string) => { this.descAgreedShipping = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.AGREED_SHIPPING_WRONG_ADDRESS').subscribe((res: string) => { this.descAgreedShippingWrongAddress = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DID_NOT_ACCEPT_IT').subscribe((res: string) => { this.descDidNotAcceptIt = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DRIVER_NOT_ACCEPT').subscribe((res: string) => { this.descDriverDidNotAcceptIt = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DRIVER_NOT_ACCEPT_CHARGE').subscribe((res: string) => { this.descDriverDidNotAcceptCharge = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.DRIVER_NOT_ACCEPT_POD').subscribe((res: string) => { this.descDriverDidNotAcceptPOD = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.NOT_THERE').subscribe((res: string) => { this.descNotThere = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.PICKUP_FROM_DEPOT').subscribe((res: string) => { this.descPickUpFromDepot = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.WRONG_ADDRESS').subscribe((res: string) => { this.descWrongAddress = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.NOT_AWARE').subscribe((res: string) => { this.descNotAware = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.NOTHING_TO_SEND').subscribe((res: string) => { this.descNothingToSend = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.UNKNOWN_SENDER_RECIPIENT').subscribe((res: string) => { this.descUnknownReceiverRecipient = res; }));
    this.listen.push(this.translate.get('STOP_POINT_HISTORY.PARCELS_NOT_READY').subscribe((res: string) => { this.descParcelsNotReady = res; }));

    this.columnDefs = [
      {
        headerName: colDateTime,
        field: 'fulfillment_datetime',
        cellRenderer: this.gridsService.dateTimeRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
      {
        headerName: colBy,
        field: 'application_type',
        sortable: false,
        width: this.gridsService.widthCalculator(7)
      },
      {
        headerName: colStatus,
        field: 'reason',
        cellRenderer: this.statusRenderer,
        width: this.gridsService.widthCalculator(18)
      },
      {
        headerName: colAddress,
        field: 'address',
        sortable: false,
        cellRenderer: this.gridsService.addressNoBoldRenderer,
        width: this.gridsService.widthCalculator(12.5)
      },
    ];
  }

  ngOnInit() {

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
      });
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
