// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.print-container {
  position: relative;
}

#customer-invoices-print-container {
  height: 100%;
}

#customer-invoices-alternative-print-container {
  height: 100%;
  margin-bottom: 32px;
}

#qr-invoice-container {
  position: absolute;
  top: 78%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbWVyLWludm9pY2UtcHJpbnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSSxzQkFBQTtFQUNBLDJCQUFBO0FBREo7O0FBSUE7RUFDSSxrQkFBQTtBQURKOztBQUlBO0VBQ0ksWUFBQTtBQURKOztBQUlBO0VBQ0ksWUFBQTtFQUNBLG1CQUFBO0FBREo7O0FBSUE7RUFDSSxrQkFBQTtFQUNBLFFBQUE7QUFESiIsImZpbGUiOiJjdXN0b21lci1pbnZvaWNlLXByaW50LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnc3R5bGVzL2Fic3RyYWN0cy92YXJpYWJsZXMnO1xuXG4qIHtcbiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICAgIC1tb3otYm94LXNpemluZzogYm9yZGVyLWJveDtcbn1cblxuLnByaW50LWNvbnRhaW5lciB7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xufVxuXG4jY3VzdG9tZXItaW52b2ljZXMtcHJpbnQtY29udGFpbmVyIHtcbiAgICBoZWlnaHQ6IDEwMCU7XG59XG5cbiNjdXN0b21lci1pbnZvaWNlcy1hbHRlcm5hdGl2ZS1wcmludC1jb250YWluZXIge1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBtYXJnaW4tYm90dG9tOiAzMnB4O1xufVxuXG4jcXItaW52b2ljZS1jb250YWluZXIge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICB0b3A6IDc4JTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/prints/customer-invoice-print/customer-invoice-print.component.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;EACA,2BAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,YAAA;EACA,mBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,QAAA;AADJ;AACA,g9BAAg9B","sourcesContent":["@import 'styles/abstracts/variables';\n\n* {\n    box-sizing: border-box;\n    -moz-box-sizing: border-box;\n}\n\n.print-container {\n    position: relative;\n}\n\n#customer-invoices-print-container {\n    height: 100%;\n}\n\n#customer-invoices-alternative-print-container {\n    height: 100%;\n    margin-bottom: 32px;\n}\n\n#qr-invoice-container {\n    position: absolute;\n    top: 78%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
