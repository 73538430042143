import { GenericService } from '../../services/generic.service';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-collaborator-view-balance',
    templateUrl: './collaborator-view-balance.component.html',
    styleUrls: ['./collaborator-view-balance.component.scss']
})
export class CollaboratorViewBalanceComponent implements OnInit, OnDestroy {
    @ViewChild('container', { static: false }) container;

    listen = [];

    collaboratorId = null;
    toPay = 0;
    toGetPaid = 0;
    total = 0;
    url;
    startDate = '';
    endDate = '';
    timezone = '';

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private modalGridService: ModalGridService,
        private genericService: GenericService,
        public globals: Globals,
        private router: Router,
    ) {
        this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
            if (this.collaboratorId) {
                this.getAmounts(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'), moment.tz.guess());
            }
        }));
        
        this.listen.push(this.genericService.listenBalanceAmountsUpdate().subscribe((response) => {
            if (this.collaboratorId) {
                this.getAmounts(response['startDate'], response['endDate'], response['timezone']);
            }
        }));

        // balance of single partner (partner view)
        this.listen.push(this.genericService.listenUpdatePartnerBalance().subscribe((balanceObj) => {
            this.getBalanceAmounts(balanceObj.toPay, balanceObj.toGetPaid);
        }));
    }

    getAmounts(startDate, endDate, timezone) {
        const dateParams = `startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`;
        this.toGetPaid = 0;
        this.toPay = 0;
        this.total = 0;

        // balance inside partners grid (in company mode)
        if (this.router.url.split('/')[2] == 'partners') {
            this.url = 'api/v1/invoice-and-payment-stats?partnerType=' + this.globals.companyModes['NORMAL'] + '&' + dateParams;

            this.http.get(this.url).pipe(take(1)).subscribe(response => {
                if (response['items']) {
                    if (response['items']['collaborator']) {
                        const collaboratorsArray = Object.values(response['items']['collaborator'])
                        if (collaboratorsArray.length) {
                            collaboratorsArray.forEach(collaborator => {
                                this.toPay += Number(collaborator['pay_on_deliveries']['total_left_amount'].toFixed(2));
                                this.toGetPaid += Number(collaborator['invoices']['total_left_amount'].toFixed(2));
                            });
                        }

                        const diff = Number(this.toGetPaid) - Number(this.toPay);
                        this.total = Number(diff.toFixed(2));

                        const container = this.container.nativeElement;
                        if (container) {
                            if (this.total === 0) {
                                container.classList.remove('positive');
                                container.classList.remove('negative');
                                container.classList.add('zero');
                            } else if (this.total > 0) {
                                container.classList.remove('negative');
                                container.classList.remove('zero');
                                container.classList.add('positive');
                            } else {
                                container.classList.remove('positive');
                                container.classList.remove('zero');
                                container.classList.add('negative');
                            }
                        }
                    }
                }
            });
        }
        // balance inside a single collaborator
        else {
            this.url = 'api/v1/invoice-and-payment-stats/' + this.collaboratorId + '?' + dateParams;

            this.http.get(this.url).pipe(take(1)).subscribe(response => {
                if (response['items']) {
                    if (response['items']['collaborator']) {
                        if (response['items']['collaborator'][this.collaboratorId]) {
                            const data = response['items']['collaborator'][this.collaboratorId];
                            this.toPay = Number(data.pay_on_deliveries.total_left_amount.toFixed(2));
                            this.toGetPaid = Number(data.invoices.total_left_amount.toFixed(2));
                            const diff = Number(this.toGetPaid) - Number(this.toPay);
                            this.total = Number(diff.toFixed(2));
                            const container = this.container.nativeElement;
                            if (container) {
                                if (this.total === 0) {
                                    container.classList.remove('positive');
                                    container.classList.remove('negative');
                                    container.classList.add('zero');
                                } else if (this.total > 0) {
                                    container.classList.remove('negative');
                                    container.classList.remove('zero');
                                    container.classList.add('positive');
                                } else {
                                    container.classList.remove('positive');
                                    container.classList.remove('zero');
                                    container.classList.add('negative');
                                }
                            }
                        }
                    }
                }
            });
        }
    }

    // for usage without a request (via service, viewchild etc.)
    getBalanceAmounts(pay, getPaid) {
        this.toPay = Number(pay.toFixed(2));
        this.toGetPaid = Number(getPaid.toFixed(2));
        const diff = Number(this.toGetPaid) - Number(this.toPay);
        this.total = Number(diff.toFixed(2));
        const container = this.container.nativeElement;
        if (container) {
            if (this.total === 0) {
                container.classList.remove('positive');
                container.classList.remove('negative');
                container.classList.add('zero');
            } else if (this.total > 0) {
                container.classList.remove('negative');
                container.classList.remove('zero');
                container.classList.add('positive');
            } else {
                container.classList.remove('positive');
                container.classList.remove('zero');
                container.classList.add('negative');
            }
        }
    }

    ngOnInit() {
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            this.collaboratorId = params['id'];

            if (this.collaboratorId) {
                this.getAmounts(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'), moment.tz.guess());
            }
        }));

        // get amounts in partnerView -> partners
        if (this.router.url.split('/')[2] == 'partners') {
            this.getAmounts(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'), moment.tz.guess());
        }
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
