import { Globals } from './../../../../services/globals';
import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CustomerPaymentModalComponent } from '@app/modals/customer-payment-modal/customer-payment-modal.component';
import { CustomerInvoiceModalComponent } from '@app/modals/customer-invoice-modal/customer-invoice-modal.component';

@Component({
    selector: 'app-dashboard-activity-dummy',
    templateUrl: './dashboard-activity-dummy.component.html',
    styleUrls: ['./dashboard-activity-dummy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardActivityDummyComponent implements OnInit, AfterViewInit {

    @Input() activities: Array<any>;
    @Input() hoverActivityTime: String;
    @Input() colourService;

    @Output() clickOnActivityEvent = new EventEmitter<string>();
    @Output() hoverActivityItemEvent = new EventEmitter<string>();
    @Output() unhoverActivityItemEvent = new EventEmitter<string>();

    constructor(
        public globals: Globals
    ) { }

    clickOnActivity(activityId) {
        this.clickOnActivityEvent.emit(activityId);
    }

    hoverActivityItem(activity) {
        this.hoverActivityItemEvent.emit(activity);
    }

    unhoverActivityItem() {
        this.unhoverActivityItemEvent.emit();
    }

    ngOnInit() { }

    ngAfterViewInit() { }
}
