import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { GridsService } from '@app/services/grids.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { CollaboratorService } from '@app/services/collaborator.service';
import { Globals } from '@app/services/globals';

@Component({
    selector: 'app-customer-payment-invoice-print',
    templateUrl: './customer-payment-invoice-print.component.html',
    styleUrls: ['./customer-payment-invoice-print.component.scss']
})
export class CustomerPaymentPrintComponent implements OnInit, OnDestroy {

    listen = [];

    companyName;
    companyAddress;
    companyWebsite;
    companyTaxId;
    companyTaxOffice;
    companyEmail;
    logoBase64 = '';
    logoSrc = 'data:image/png;base64,LOGO_HASH';

    // tax = 0.2;
    taxCharge;
    total = 0;
    // subtotal;
    // taxTotal;

    // titles
    itemsTitle = 'Items';
    quantityTitle = 'Quantity';
    priceTitle = 'Price';
    amountTitle = 'Amount';
    paymentTitle = 'Pay on delivery';
    currency;
    totalLabel;

    // grid settings
    gridApi;
    gridColumnApi;
    domLayout = 'autoHeight';
    itemsDataArray = [];
    rowData: any;
    columnDefs;

    // print values
    transactionNumber;
    createdDate;
    dueOnDate;
    leftAmount;

    invoiceData;
    collaboratorData;

    // collaborator data/info
    collaboratorName;
    collaboratorAddress;
    collaboratorTaxId;
    collaboratorTaxOffice;
    collaboratorEmail;

    // partner details
    partnerName;
    partnerAddress;
    partnerTaxId;
    partnerTaxOffice;
    partnerWebsite;

    methodCard;
    methodCash;
    methodCheque;
    methodBankDeposit;

    constructor(
        public gridsService: GridsService,
        public translate: TranslateService,
        public router: Router,
        private collaboratorService: CollaboratorService,
        public globals: Globals
    ) {
        // this.listenUpdateCustomerGrid = this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
        //     // this.pagesCount = 0;
        //     // this.gridApi.purgeServerSideCache([]);
        // });
    }

    setCompanyData(companyData) {
        if (companyData) {
            this.companyName = companyData.name;
            this.companyAddress = companyData.address_text;
            this.companyWebsite = companyData.website;
            this.companyTaxId = companyData.tin;
            this.companyTaxOffice = companyData.tax_office;
            this.companyEmail = companyData.email;
            if (companyData.companyLogo) {
                if (companyData.companyLogo['base64']) {
                    this.logoBase64 = this.logoSrc.replace('LOGO_HASH', companyData.companyLogo['base64']);
                }
            }
            // in partners request, the logo is logo['base64'] instead of companyLogo['base64']
            if (companyData.logo) {
                if (companyData.logo['base64']) {
                    this.logoBase64 = this.logoSrc.replace('LOGO_HASH', companyData.logo['base64']);
                }
            }
        }
    }

    setCollaboratorData(company) {
        if (company.collaboratorData) {
            this.collaboratorName = company.collaboratorData.collaborator_name;
            this.collaboratorAddress = company.address.value;
            this.collaboratorTaxId = company.collaboratorData.tin;
            this.collaboratorTaxOffice = company.collaboratorData.tax_office;
            this.collaboratorEmail = company.collaboratorData.mail;
        } else {
            this.collaboratorName = company.name;
            this.collaboratorAddress = company.address_text;
            this.collaboratorTaxId = company.tin;
            this.collaboratorTaxOffice = company.tax_office;
            this.collaboratorEmail = company.email;
        }
    }

    // sets print component values
    setPrintData(data) {
        this.transactionNumber = this.collaboratorService.generateIdWithZeros(data.id);
        this.createdDate = moment(data.creation_datetime).format('MMMM DD, YYYY');
        this.dueOnDate = moment(data.due_on_datetime).format('MMMM DD, YYYY');
        this.leftAmount = data.pay_amount.toFixed(2);
    }

    // calculate total
    setPaymentsGridData(data) {
        let gridObject = {};
        this.itemsDataArray = [];

        // payment methods & amounts
        let paymentMethodsWithAmounts = {
            bank_deposit: {},
            credit_card: {},
            sum_cheque: {},
            pay_on_delivery: {},
            cellsCount: 0
        };
        if (data.pay_on_delivery_pay_payment_amount['bank_deposit']) {
            paymentMethodsWithAmounts['bank_deposit']['amount_raw'] = data.pay_on_delivery_pay_payment_amount['bank_deposit']['amount'];
            paymentMethodsWithAmounts['bank_deposit']['amount'] = String(data.pay_on_delivery_pay_payment_amount['bank_deposit']['amount']) + this.currency;
            paymentMethodsWithAmounts['bank_deposit']['count'] = String(data.pay_on_delivery_pay_payment_amount['bank_deposit']['count']);
            paymentMethodsWithAmounts['cellsCount'] += 1;
        }
        if (data.pay_on_delivery_pay_payment_amount['credit_card']) {
            paymentMethodsWithAmounts['credit_card']['amount_raw'] = data.pay_on_delivery_pay_payment_amount['credit_card']['amount'];
            paymentMethodsWithAmounts['credit_card']['amount'] = String(data.pay_on_delivery_pay_payment_amount['credit_card']['amount']) + this.currency;
            paymentMethodsWithAmounts['credit_card']['count'] = String(data.pay_on_delivery_pay_payment_amount['credit_card']['count']);
            paymentMethodsWithAmounts['cellsCount'] += 1;
        }
        if (data.pay_on_delivery_pay_payment_amount['sum_cheque']) {
            paymentMethodsWithAmounts['sum_cheque']['amount_raw'] = data.pay_on_delivery_pay_payment_amount['sum_cheque']['amount'];
            paymentMethodsWithAmounts['sum_cheque']['amount'] = String(data.pay_on_delivery_pay_payment_amount['sum_cheque']['amount']) + this.currency;
            paymentMethodsWithAmounts['sum_cheque']['count'] = String(data.pay_on_delivery_pay_payment_amount['sum_cheque']['count']);
            paymentMethodsWithAmounts['cellsCount'] += 1;
        }
        if (data.pay_on_delivery_pay_payment_amount['pay_on_delivery']) {
            paymentMethodsWithAmounts['pay_on_delivery']['amount_raw'] = data.pay_on_delivery_pay_payment_amount['pay_on_delivery']['amount'];
            paymentMethodsWithAmounts['pay_on_delivery']['amount'] = String(data.pay_on_delivery_pay_payment_amount['pay_on_delivery']['amount']) + this.currency;
            paymentMethodsWithAmounts['pay_on_delivery']['count'] = String(data.pay_on_delivery_pay_payment_amount['pay_on_delivery']['count']);
            paymentMethodsWithAmounts['cellsCount'] += 1;
        }

        // labels
        paymentMethodsWithAmounts['labels'] = {
            cardLabel: this.methodCard,
            cashLabel: this.methodCash,
            bankDepositLabel: this.methodBankDeposit,
            chequeLabel: this.methodCheque,
            totalLabel: this.totalLabel,
            currency: this.currency
        }

        gridObject = {
            name: this.paymentTitle,
            paymentMethodsWithAmounts: paymentMethodsWithAmounts,
            quantity: 1,
            price: data.pay_amount.toFixed(2),
            amount: data.pay_amount.toFixed(2),
        };

        this.calculateSum(data);

        this.itemsDataArray.push(gridObject);
        this.rowData = of(this.itemsDataArray);
    }

    calculateSum(data) {
        this.total = data.pay_amount.toFixed(2);
        // this.taxCharge = (this.total * this.tax).toFixed(2);
        // this.subtotal = (this.total - this.taxCharge).toFixed(2);
    }
    // - -

    paymentMethodsLabelsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            // determine how many cells are required
            let cellClass = 'single-cell';
            switch (params.getValue().cellsCount) {
                case 2:
                    cellClass = 'double-cell';
                    break;
                case 3:
                    cellClass = 'triple-cell';
                    break;
                case 4:
                    cellClass = 'quad-cell';
                    break;
            }


            if (params.getValue().credit_card['amount']) {
                columnObject += '<div class="' + cellClass + ' text-right-align' + '">' + params.getValue().labels['cardLabel'] + '</div>';
            }
            if (params.getValue().pay_on_delivery['amount']) {
                columnObject += '<div class="' + cellClass + ' text-right-align' + '">' + params.getValue().labels['cashLabel'] + '</div>';
            }
            if (params.getValue().sum_cheque['amount']) {
                columnObject += '<div class="' + cellClass + ' text-right-align' + '">' + params.getValue().labels['chequeLabel'] + '</div>';
            }
            if (params.getValue().bank_deposit['amount']) {
                columnObject += '<div class="' + cellClass + ' text-right-align' + '">' + params.getValue().labels['bankDepositLabel'] + '</div>';
            }

            // separator line & total
            columnObject += '<div class="separator-line-quarter-cell"></div>';
            columnObject += '<div class="single-cell bold-letters text-right-align">' + params.getValue().labels['totalLabel'] + ':</div>';
        }
        return columnObject;
    }

    paymentMethodsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            // determine how many cells are required
            let cellClass = 'single-cell';
            switch (params.getValue().cellsCount) {
                case 2:
                    cellClass = 'double-cell';
                    break;
                case 3:
                    cellClass = 'triple-cell';
                    break;
                case 4:
                    cellClass = 'quad-cell';
                    break;
            }

            let countTotal = 0;
            if (params.getValue().credit_card['count']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().credit_card['count'] + '</div>';
                countTotal += Number(params.getValue().credit_card['count']);
            }
            if (params.getValue().pay_on_delivery['count']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().pay_on_delivery['count'] + '</div>';
                countTotal += Number(params.getValue().pay_on_delivery['count']);
            }
            if (params.getValue().sum_cheque['count']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().sum_cheque['count'] + '</div>';
                countTotal += Number(params.getValue().sum_cheque['count']);
            }
            if (params.getValue().bank_deposit['count']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().bank_deposit['count'] + '</div>';
                countTotal += Number(params.getValue().bank_deposit['count']);
            }

            // separator line & total
            columnObject += '<div class="separator-line-full-cell"></div>';
            columnObject += '<div class="single-cell left-margin-big">' + countTotal + '</div>';
        }
        return columnObject;
    }

    priceRenderer(params) {
        const self = this;
        let columnObject = '';
        if (params.getValue()) {
            // determine how many cells are required
            let cellClass = 'single-cell';
            switch (params.getValue().cellsCount) {
                case 2:
                    cellClass = 'double-cell';
                    break;
                case 3:
                    cellClass = 'triple-cell';
                    break;
                case 4:
                    cellClass = 'quad-cell';
                    break;
            }

            let priceTotal = 0;
            if (params.getValue().credit_card['amount']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().credit_card['amount'] + '</div>';
                priceTotal += params.getValue().credit_card['amount_raw'];
            }
            if (params.getValue().pay_on_delivery['amount']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().pay_on_delivery['amount'] + '</div>';
                priceTotal += params.getValue().pay_on_delivery['amount_raw'];
            }
            if (params.getValue().sum_cheque['amount']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().sum_cheque['amount'] + '</div>';
                priceTotal += params.getValue().sum_cheque['amount_raw'];
            }
            if (params.getValue().bank_deposit['amount']) {
                columnObject += '<div class="' + cellClass + ' left-margin-big' + '">' + params.getValue().bank_deposit['amount'] + '</div>';
                priceTotal += params.getValue().bank_deposit['amount_raw'];
            }

            // separator line
            columnObject += '<div class="separator-line-full-cell"></div>';
            columnObject += '<div class="single-cell bold-letters left-margin-big">' + String(priceTotal) + params.getValue().labels['currency'] + '</div>';
        }
        return columnObject;
    }

    onFirstDataRendered(params) { }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    getTranslations() {
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.SERVICE').subscribe((res: string) => { this.itemsTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.SHIPMENTS_AMOUNT').subscribe((res: string) => { this.quantityTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PRICE').subscribe((res: string) => { this.priceTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.AMOUNT').subscribe((res: string) => { this.amountTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.PRINT_TOTAL').subscribe((res: string) => { this.totalLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.PAY_ON_DELIVERY').subscribe((res: string) => { this.paymentTitle = res; }));

        // payment methods
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.METHOD_CARD').subscribe((res: string) => { this.methodCard = res; }));
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.METHOD_CASH').subscribe((res: string) => { this.methodCash = res; }));
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.METHOD_CHEQUE').subscribe((res: string) => { this.methodCheque = res; }));
        this.listen.push(this.translate.get('CUSTOMER_PAYMENTS.METHOD_BANK_DEPOSIT').subscribe((res: string) => { this.methodBankDeposit = res; }));


        // create grid columns

        // modal view
        if (!this.router.url.includes('Print/')) {
            this.columnDefs = [
                { headerName: this.itemsTitle, field: 'name', width: this.gridsService.widthCalculator(15) },
                {
                    headerName: '',
                    field: 'paymentMethodsWithAmounts',
                    width: this.gridsService.widthCalculator(15),
                    cellRenderer: this.paymentMethodsLabelsRenderer,
                    cellStyle: { 'padding': 0 }
                },
                {
                    headerName: this.quantityTitle,
                    field: 'paymentMethodsWithAmounts',
                    width: this.gridsService.widthCalculator(15),
                    cellRenderer: this.paymentMethodsRenderer,
                    cellStyle: { 'padding': 0 }
                },
                {
                    headerName: this.priceTitle,
                    field: 'paymentMethodsWithAmounts',
                    width: this.gridsService.widthCalculator(25),
                    cellRenderer: this.priceRenderer,
                    cellStyle: { 'padding': 0 }
                }
            ];
        }
        // print view
        else {
            this.columnDefs = [
                { headerName: this.itemsTitle, field: 'name', width: this.gridsService.widthCalculatorContainerId(25, 'customer-payment-invoices-print-container') },
                {
                    headerName: '',
                    field: 'paymentMethodsWithAmounts',
                    width: this.gridsService.widthCalculatorContainerId(37, 'customer-payment-invoices-print-container'),
                    cellRenderer: this.paymentMethodsLabelsRenderer,
                    cellStyle: { 'padding': 0 }
                },
                {
                    headerName: this.quantityTitle,
                    field: 'paymentMethodsWithAmounts',
                    width: this.gridsService.widthCalculatorContainerId(28, 'customer-payment-invoices-print-container'),
                    cellRenderer: this.paymentMethodsRenderer,
                    cellStyle: { 'padding': 0 }
                },
                {
                    headerName: this.priceTitle,
                    field: 'paymentMethodsWithAmounts',
                    width: this.gridsService.widthCalculatorContainerId(10, 'customer-payment-invoices-print-container'),
                    cellRenderer: this.priceRenderer,
                    cellStyle: { 'padding': 0 }
                }
            ];
        }


    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
