import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { ISettings_TR_AreaGroupDto } from '@app/api/models/settings-transportation-regions-dto';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { LmSettingsTransportationRegionsService } from '@app/api/services/settings-transportation-regions.service';
import { Globals } from '@app/services/globals';
import { IPriceListAreaGroupAdministrativeLevel } from '@app/model/price-list';
import { TransportationRegionModalComponent } from './new-transportation-region-modal/new-transportation-region-modal.component';
import { LmAreasLookUpService } from '@app/api/services/areas-lookup.service';
import { INITIAL_DATA } from '@app/model/initial-data';


@Injectable()
export class TransportationViewModelService extends LmTableListViewModelService<ISettings_TR_AreaGroupDto> implements OnInit, OnDestroy {
    levelsEnum: IPriceListAreaGroupAdministrativeLevel;
    transportationRegionModal = TransportationRegionModalComponent;
    modalConfig: any;

    constructor(
        injector: Injector, 
        @Inject(forwardRef(() => LmSettingsTransportationRegionsService)) public apiSvc: LmSettingsTransportationRegionsService,
        @Inject(forwardRef(() => LmAreasLookUpService)) public areaSvc: LmAreasLookUpService,
        public globals: Globals
    ) {
        super(injector);
        const {priceListAreaGroupsAdministrativeLevelEnum} = globals;
        this.levelsEnum = priceListAreaGroupsAdministrativeLevelEnum;
        this.modalConfig = { componentType: this.transportationRegionModal, injector: injector, data: {VM: this, globals: this.globals} }
    }

    transformRows = (res) => {
        res.items.forEach(item => {
            const type = Object.values(this.levelsEnum).find(level => level.key===item.administrative_level).caption;
            const areaNames = item.area_names.map(str => str === 'custom' ?  this.modalConfig.data['translations']['POLYGON_GRID'] : str);
            item.areasCaption = areaNames.join(', ');
            item.nameCaption = `${item.name}</br><small class="lm-txt-color-grey lm-font-weight-normal">${type}</small>`;
        })
        return res;
    }

    getRows() {
        return this.apiSvc.apiSettingsTransportationRegionsAllGet().pipe(switchMap(res => of(this.transformRows(res))))
    }

    getById(id) {

    }

    post(item: any) {
        return this.apiSvc.apiSettingsTransportationRegionsPost({body: item})
    }

    put(id) {
        
    }

    delete(id) {
        return this.apiSvc.apiSettingsTransportationRegionSingleDelete({id:id})
    }

    search(query: string) {
        
    }

    newItem() {
        // areas were somehow not emptying 
        INITIAL_DATA.newTransportationRegionsItem.areas = [];
        return INITIAL_DATA.newTransportationRegionsItem;
    }

    openStreetMapsPost(id){
        const _id = Array.isArray(id) ? id[0] : id;
        return this.apiSvc.apiSettingsTransportationRegionsOpenStreeMapsPost({body:{area_uuid: _id}})
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
