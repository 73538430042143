import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Observable, finalize, mergeWith, of, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardReportViewModelService } from './dashboard-report-viewmodel.service';
import { ILmCSemiCircleChartWidgetTab, ILmChartWidgetTab } from '@app/model/widget';
import { SHIPMENTS as sc, PRODUCTIVITY as pc, VERTICAL as vc, VERTICAL_OPTIONS as vco, DOUGHNUT as dc, DRIVERS as drc, SEMICIRCLE as smc} from './dashboard-report-dummy-data';
import { DELIVERIES as deliveriesTabs} from './dashboard-report-dummy-data';
import { LmProgressCardConfig } from '@app/shared/structure/progress-card/progress-card.component';

@UntilDestroy()
@Component({
    selector: 'dashboard-report',
    templateUrl: './dashboard-report.component.html',
    providers: [DashboardReportViewModelService] 
})
export class DashboardReportComponent implements OnInit, OnDestroy, AfterViewInit {
  shipments: ILmChartWidgetTab[];
  productivity: ILmChartWidgetTab[];
  drivers: ILmChartWidgetTab[];
  cancelation: ChartData | any;
  doughnut: ChartData | any;
  deliveries: any;
  semicircle: ILmCSemiCircleChartWidgetTab[];

  verticalOptions: ChartOptions;
  shipmentProgressOptions: LmProgressCardConfig;
  productivityProgressOptions: LmProgressCardConfig;
  experienceProgressOptions: LmProgressCardConfig;
  deliveriesProgressOptions: LmProgressCardConfig;


  constructor(public VM: DashboardReportViewModelService, private _injector: Injector){

    this.shipments = [...sc];
    this.productivity = [...pc];
    this.drivers = [...drc];
    this.cancelation = {...vc};
    this.doughnut = {...dc}
    this.semicircle = [...smc];
    this.deliveries = deliveriesTabs;
    
    this.verticalOptions = {...vco};



    // pls move following configuration to dummy data file.  
    // consider converting to obj[] and bind in tpl with ngfor repeater
    // ty :)

    this.shipmentProgressOptions = {
      title: "Shipments",
      amount: 532.123,
      progress: 551,
      percentage: 34,
      iconClass: 'fas fa-map-marker-alt',
      mainColor: "#06aeba",
      secondaryColor: "#c8edf0"
    };

    this.productivityProgressOptions = {
      title: "Productivity",
      amount: 4.4,
      amountDefinition: "/5",
      progress: -254,
      percentage: 80,
      iconClass: 'far fa-star',
      mainColor: "#f9c406",
      secondaryColor: "#fef1c3"
    };

    this.experienceProgressOptions = {
      title: "Experience",
      amount: 4.82,
      amountDefinition: "/5",
      progress: 2.7,
      percentage: 90,
      iconClass: 'icon-heart',
      mainColor: "#c74c96",
      secondaryColor: "#ead1dc"
    };

    this.deliveriesProgressOptions = {
      title: "Delivered on Time",
      amount: 96.2,
      amountDefinition: "%",
      progress: 0,
      percentage: 96.2,
      iconClass: 'icon-clock',
      mainColor: "#ba6ef5",
      secondaryColor: "#eedcfc"
    };

  }


  ngAfterViewInit(): void {}
  ngOnInit() {}
  ngOnDestroy() {}
}
