import { CommonModule } from '@angular/common';
import { Directive, Input, NgModule, TemplateRef } from '@angular/core';

export type lmTemplateType = 'header' | 'body' | 'footer';

@Directive({ selector: '[lmTemplate]' })
export class LmTemplateDirective {
  @Input('lmTemplate') name: lmTemplateType;
  constructor(public template: TemplateRef<unknown>) {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [LmTemplateDirective],
  exports: [LmTemplateDirective]
})
export class LmTemplateModule {}
