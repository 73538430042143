import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-first-optimization-modal',
    templateUrl: './first-optimization-modal.component.html',
    styleUrls: ['./first-optimization-modal.component.scss']
})
export class FirstOptimizationModalComponent implements OnInit {

    listen = [];

    routes = [];
    routeText = '';
    routeLoadText = '';

    constructor(
        public translate: TranslateService,
    ) { }

    addRoute(driverName, duration, stopsCount, loadPercentage, colour) {
        const driverNameUpdated = driverName.replace('demoDriver: ', '');
        const text = this.routeText.replace('DRIVER_NAME', driverNameUpdated).replace('DURATION', duration).replace('STOPS_COUNT', stopsCount);
        const loadText = this.routeLoadText.replace('PERCENTAGE', loadPercentage);
        this.routes.push({
            text: text,
            load_text: loadText,
            colour: colour,
        });
    }

    openModal() {
        localStorage.setItem('demoOptimized', 'true');
        const modal = document.querySelector('#first-optimization-modal');
        const modalBackground = document.querySelector('#first-optimization-modal-background');
        modal.classList.toggle('closed');
        modal.classList.toggle('open');
        modalBackground.classList.toggle('hidden');
    }

    closeModal() {
        const modal = document.querySelector('#first-optimization-modal');
        const modalBackground = document.querySelector('#first-optimization-modal-background');
        modal.classList.toggle('closed');
        modal.classList.toggle('open');
        modalBackground.classList.toggle('hidden');
    }

    getTranslations() {
        this.listen.push(this.translate.get('FIRST_OPTIMIZATION_MODAL.ROUTE_TEXT').subscribe((res: string) => {
            this.routeText = res;
        }));
        this.listen.push(this.translate.get('FIRST_OPTIMIZATION_MODAL.ROUTE_LOAD').subscribe((res: string) => {
            this.routeLoadText = res;
        }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
