/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ISettings_TR_AreaDto } from '../models/settings-transportation-regions-dto';

@Injectable({
  providedIn: 'root'
})
export class LmSettingsTransportationRegionsService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

    // ------------------------- ADD DTOs ---------------------
  
    static readonly settingsTransportationRegionsPath = '/api/v1/area-groups';
    
    // GET ALL
    apiSettingsTransportationRegionsAllGet$Response(): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsTransportationRegionsService.settingsTransportationRegionsPath, 'get');

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsTransportationRegionsAllGet(): Observable<Array<any>> {
        return this.apiSettingsTransportationRegionsAllGet$Response().pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // GET BY ID
    static readonly settingsTransportationRegionSingleGetPath = '/api/v1/area-groups/{id}';

    apiSettingsTransportationRegionSingleGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsTransportationRegionsService.settingsTransportationRegionSingleGetPath, 'get');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsTransportationRegionSingleGet(params?: { id?: string }): Observable<Array<any>> {
        return this.apiSettingsTransportationRegionSingleGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


    // DELETE
    static readonly settingsTransportationRegionSingleDeletePath = '/api/v1/area-groups/{id}';

    apiSettingsTransportationRegionSingleDelete$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
        const rb = new RequestBuilder(this.rootUrl, LmSettingsTransportationRegionsService.settingsTransportationRegionSingleDeletePath, 'delete');
        if (params) rb.path('id', params.id);

        return this.http.request(
            rb.build({
                responseType: 'json',
                accept: 'application/json'
            }))
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<any>>;
                }
            )
        );
    }
    
    apiSettingsTransportationRegionSingleDelete(params?: { id?: string }): Observable<Array<any>> {
        return this.apiSettingsTransportationRegionSingleDelete$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }

    // POST 
    apiSettingsTransportationRegionsPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmSettingsTransportationRegionsService.settingsTransportationRegionsPath, 'post');
      if (params) rb.body(params.body, 'application/*+json');

      return this.http
        .request(
          rb.build({
            responseType: 'json',
            accept: 'application/json'
          })
        )
        .pipe(
          filter((r: any) => r instanceof HttpResponse),
          map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<any>>;
          })
        );
    }
  

    apiSettingsTransportationRegionsPost(params?: { body?: any }): Observable<Array<any>> {
      return this.apiSettingsTransportationRegionsPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }



    // AREA DETAILS POST
    static readonly settingsOpenStreetMapsGeoPath = '/api/v1/open-street-maps/get-geo-json';

    apiSettingsTransportationRegionsOpenStreeMapsPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<ISettings_TR_AreaDto>> {
      const rb = new RequestBuilder(this.rootUrl, LmSettingsTransportationRegionsService.settingsOpenStreetMapsGeoPath, 'post');
        if (params) rb.body(params.body, 'application/*+json');
  
        return this.http
          .request(
            rb.build({
              responseType: 'json',
              accept: 'application/json'
            })
          )
          .pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
              return r as StrictHttpResponse<ISettings_TR_AreaDto>;
            })
          );
      }
    
  
      apiSettingsTransportationRegionsOpenStreeMapsPost(params?: { body?: any }): Observable<ISettings_TR_AreaDto> {
        return this.apiSettingsTransportationRegionsOpenStreeMapsPost$Response(params).pipe(map((r: StrictHttpResponse<ISettings_TR_AreaDto>) => r.body as ISettings_TR_AreaDto));
      }

}
