import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  private transmitProjectProblemDataToModalsListeners = new Subject<any>();
  private transmitProjectProblemDataListeners = new Subject<any>();
  private updateProjectsGridListeners = new Subject<any>();

  transmitProjectProblemDataToModalsListen(): Observable<any> {
    return this.transmitProjectProblemDataToModalsListeners.asObservable();
  }
  transmitProjectProblemDataListen(): Observable<any> {
    return this.transmitProjectProblemDataListeners.asObservable();
  }
  updateProjectsGridListen(): Observable<any> {
    return this.updateProjectsGridListeners.asObservable();
  }

  updateProjectsGrid(defaultDepartureTime) {
    this.updateProjectsGridListeners.next(defaultDepartureTime);
  }

  transmitProjectProblemDataToModals(modalView) {
    this.transmitProjectProblemDataToModalsListeners.next(modalView);
  }

  // data to send to the project modal to load the project problem
  transmitProjectProblemData(datetime, title: String, projectProblemId, projectProblemState = null) {
    this.transmitProjectProblemDataListeners.next({
      datetime: datetime,
      title: title,
      projectProblemId: projectProblemId,
      projectProblemState: projectProblemState
    });
  }

}
