import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { Globals } from '@app/services/globals';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LmFormViewModelService } from '@app/shared/viewmodel/form-viewmodel.service';
import { IDepotDto } from '@app/model/depot-model';
import { LmDepotsService } from '@app/api/services/depots-services.service';


@Injectable()
export class LmSettingsDepotsViewModelService extends LmFormViewModelService<IDepotDto> implements OnInit, OnDestroy {
    lat:number;
    lon:number;
    constructor(
        injector: Injector, 
        @Inject(forwardRef(() => LmDepotsService)) public apiSvc: LmDepotsService,
        public globals: Globals
    ){
        super(injector);
    }

    getById(id){
        return this.apiSvc.apiDepotSingleGet({id: id})
    }

    post(item: any) {
        return this.apiSvc.apiDepotPost({body: item})
    }

    put(item, id) {
        return this.apiSvc.apiDepotPut({body: item, id: id})
    }

    delete(id) {
        // return this.apiSvc.apiSettingsTransportationRegionSingleDelete({id:id})
        return of(null);
    }

    newItem() {
        return {...INITIAL_DATA.newDepotItem};
    }

    openStreetMapsPost(id){
        const _id = Array.isArray(id) ? id[0] : id;
        return this.apiSvc.apiSettingsDepotsOpenStreeMapsPost({body:{area_uuid: _id}})
    }

    getLocationFromMapCoords(lat, lon){
        this.lat = lat;
        this.lon = lon;
        return this.apiSvc.apiMapLocationFromCoordsGet({ lat: lat, lon: lon})
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
