import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { SettingsService } from '@app/services/settings.service';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-time-window-modal',
  templateUrl: './time-window-modal.component.html',
  styleUrls: ['./time-window-modal.component.scss']
})
export class TimeWindowModalComponent implements OnInit {

  errors = [];
  isClickedOnce = false;

  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  timeWindowName = '';
  timeWindowId = null;
  doubleTimeWindow = [];
  timeWindowSingleDefault = [480, 1200];
  timeWindowDoubleDefault = [480, 840, 1020, 1200];
  timeWindowSingle = [];
  timeWindowDouble = [];
  timeWindowDailyValues = {};
  timeWindowUnformattedDefault = ['08:00', '20:00'];
  timeWindowUnformatted = [];
  timeWindowOptions;
  timeWindowOptionsDouble;
  defaultTimezone = moment.tz.guess();

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private settingsService: SettingsService,
    public genericService: GenericService,
  ) {
    this.initializeSlider();
  }

  initializeSlider() {
    this.timeWindowOptions = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 1200],
      connect: [false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.timeWindowOptionsDouble = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 840, 1020, 1200],
      connect: [false, true, false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
  }

  convertValuesToTime(index) {
    const self = this;
    let hours = 0, minutes = 0;
    if (self.doubleTimeWindow[index]) {
      self.timeWindowDouble[index].forEach(function (timeWindow, i) {
        hours = self.convertToHour(timeWindow);
        minutes = self.convertToMinute(timeWindow, hours);
        self.timeWindowUnformatted[index][i] = self.formatHoursAndMinutes(hours, minutes);
      });
    } else {
      self.timeWindowSingle[index].forEach(function (timeWindow, i) {
        hours = self.convertToHour(timeWindow);
        minutes = self.convertToMinute(timeWindow, hours);
        self.timeWindowUnformatted[index][i] = self.formatHoursAndMinutes(hours, minutes);
      });
    }
  }

  convertToHour(value) {
    return Math.floor(value / 60);
  }

  convertToMinute(value, hour) {
    return value - hour * 60;
  }

  formatHoursAndMinutes(hours, minutes) {
    if (hours.toString().length === 1) { hours = '0' + hours; }
    if (minutes.toString().length === 1) { minutes = '0' + minutes; }
    return hours + ':' + minutes;
  }

  timeWindowCheckboxClicked(i) {
    if (this.doubleTimeWindow[i]) {
      this.timeWindowUnformatted[i] = ['08:00', '20:00'];
    } else {
      this.timeWindowUnformatted[i] = ['08:00', '14:00', '17:00', '20:00'];
    }
  }


  getTimeWindowData(id) {
    const self = this;
    let timeWindowData;
    self.http.get('api/v1/companies/' + self.globals.companyId + '/company-time-window/' + id).pipe(take(1)).subscribe(response => {

      timeWindowData = response['item']['CompanyTimeWindowCategory'];
      self.timeWindowId = timeWindowData.id;
      self.timeWindowName = timeWindowData.label;
      const timeWindows = timeWindowData.time_windows;

      self.days.forEach(function (day, index) {

        if (timeWindows[day].length === 1) {
          self.doubleTimeWindow[index] = false;
        } else {
          self.doubleTimeWindow[index] = true;
        }

        let timeWindowDouble = [];
        let timeWindowRange, timeWindowRangeMinutes, timeWindowStartDatetime;
        let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
        let timeWindowStartUnformatted, timeWindowEndUnformatted;
        self.timeWindowUnformatted[index] = [];

        timeWindows[day].forEach(timeWindow => {
          timeWindowRange = timeWindow.time_window_range;
          timeWindowStartDatetime = timeWindow.time_window_start_datetime;
          timeWindowRange = timeWindow.time_window_range;
          timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
          timeWindowStart = moment(timeWindowStartDatetime).format();
          timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
          timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
          timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
          self.timeWindowUnformatted[index].push(timeWindowStartUnformatted);
          self.timeWindowUnformatted[index].push(timeWindowEndUnformatted);
          timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
          timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();

          if (timeWindows[day].length === 1) {
            self.timeWindowSingle[index] = [timeWindowStartMinutes, timeWindowEndMinutes];
            timeWindowDouble = self.timeWindowDoubleDefault;
          } else {
            self.timeWindowSingle[index] = self.timeWindowSingleDefault;
            timeWindowDouble.push(timeWindowStartMinutes);
            timeWindowDouble.push(timeWindowEndMinutes);
          }

        });

        self.timeWindowDouble[index] = timeWindowDouble;

      });

    });
  }

  submitTimeWindowForm() {
    const self = this;
    this.isClickedOnce = true;
    this.errors = [];

    this.days.forEach(function (day, index) {
      const timeWindowDay = [];
      for (let i = 0; i < self.timeWindowUnformatted[index].length; i = i + 2) {
        const timeWindowStartMoment = moment(self.timeWindowUnformatted[index][i], 'HH:mm');
        const timeWindowEndMoment = moment(self.timeWindowUnformatted[index][i + 1], 'HH:mm');
        const timeWindowRangeDuration = moment.duration(timeWindowEndMoment.diff(timeWindowStartMoment));
        const timeWindowStartDatetimeUnformatted = moment().format('YYYY-DD-MM ') + self.timeWindowUnformatted[index][i];
        const timeWindowStartDatetime = moment(timeWindowStartDatetimeUnformatted, 'YYYY-DD-MM HH:mm').format();
        const timeWindowRangeMinutes = timeWindowRangeDuration.asMinutes();
        const timeWindowRange = moment.duration(timeWindowRangeMinutes, 'minutes').toISOString();
        const timeWindowObject = {
          time_window_start_datetime_timezone: self.defaultTimezone,
          time_window_start_datetime: timeWindowStartDatetime,
          time_window_range: timeWindowRange
        };
        timeWindowDay.push(timeWindowObject);
      }
      self.timeWindowDailyValues[day] = timeWindowDay;
    });

    const myObserver = {
      next: (response) => {
        this.settingsService.updateTimeWindowGrid();
        this.closeTimeWindowModal();
      },
      error: (error) => {
        const errorsObject = error.error.errors;
        const errors = [];
        const keyify = (obj, prefix = '') =>
          Object.keys(obj).reduce((res, el) => {
            if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
              errors.push(obj[el]['0']);
            }
            if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
              if (obj[el]['0']['load']) {
                errors.push(obj[el]['0']['load']['0']);
              }
            }
            if (Array.isArray(obj[el])) {
              return res;
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
              return [...res, ...keyify(obj[el], prefix + el + '.')];
            } else {
              return [...res, prefix + el];
            }
          }, []);
        const output = keyify(errorsObject);
        this.errors = errors;
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.resetTimeWindowModal();
        this.settingsService.updateDepotsGrid();
      },
    };
    const depotsUrl = 'api/v1/companies/' + this.globals.companyId + '/company-time-window';
    const editUrl = depotsUrl + '/' + this.timeWindowId;
    const data = {
      companyTimeWindowCategory: {
        label: this.timeWindowName,
        time_windows: this.timeWindowDailyValues
      }
    };
    if (this.timeWindowId) {
      this.http.put(editUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    } else {
      this.http.post(depotsUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    }
  }

  resetTimeWindowModal() {
    const self = this;
    self.timeWindowName = '';
    self.timeWindowId = null;
    self.timeWindowSingle = [];
    self.timeWindowDouble = [];
    self.timeWindowUnformatted = [];
    self.doubleTimeWindow = [];
    self.days.forEach(day => {
      self.timeWindowDailyValues[day] = [];
      self.timeWindowSingle.push(self.timeWindowSingleDefault);
      self.timeWindowDouble.push(self.timeWindowDoubleDefault);
      self.timeWindowUnformatted.push(self.timeWindowUnformattedDefault.slice(0));
      self.doubleTimeWindow.push(false);
    });
  }

  openTimeWindowModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.time-window-modal');
    const modalBackground = document.querySelector('.time-window-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
  }

  closeTimeWindowModal() {
    const modal = document.querySelector('.time-window-modal');
    const modalBackground = document.querySelector('.time-window-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.resetTimeWindowModal();
  }

  ngOnInit() {
    const self = this;
    this.days.forEach(day => {
      self.timeWindowDailyValues[day] = [];
      self.timeWindowSingle.push(self.timeWindowSingleDefault);
      self.timeWindowDouble.push(self.timeWindowDoubleDefault);
      self.timeWindowUnformatted.push(self.timeWindowUnformattedDefault.slice(0));
      self.doubleTimeWindow.push(false);
    });
  }

}
