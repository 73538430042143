import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

//
// make sure to include canDeactivate: [PendingChangesGuard] inside app-routing.module when using this guard!
//
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {
  listen = [];
  confirmMessage = '';

  leaveNewShipmentsWarningMessage;

  constructor(
    public translate: TranslateService,
    public router: Router,
  ) {

  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    this.getTranslations();

    // decide message to show (mostly based on route)
    if (this.router.url.split('/')[1] == 'newShipmentsView') {
      this.confirmMessage = this.leaveNewShipmentsWarningMessage;
    }

    // if there are no pending changes, just allow deactivation - else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app
      // when navigating away from your angular app (eg. refresh), the browser will show a generic warning message
      confirm(this.confirmMessage);
  }

  getTranslations() {
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.NEW_SHIPMENTS_LEAVE_WARNING').subscribe((res: string) => { this.leaveNewShipmentsWarningMessage = res; }));
  }
}