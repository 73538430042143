import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, AfterViewInit, NgModule, ViewChild, HostBinding, Inject, forwardRef } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { Chart, ChartOptions } from 'chart.js';
import { LM_BAR_CHART_BASE_OPTIONS} from '@app/model/charts-config';
import { LM_BASE_CHART_OPTIONS, LmBaseChartComponent } from './chart-base';


@Component({
    selector: 'lm-bar-chart',
    providers:[
        { provide: LmBaseChartComponent, useExisting: forwardRef(() => LmBarChartComponent) },
        { provide: LM_BASE_CHART_OPTIONS, useValue: LM_BAR_CHART_BASE_OPTIONS }
    ],
    template: `
        <div class="grid lm-height lm-pdt10">
            <div class="col-12 lm-height">
                <p-chart 
                    #chart
                    type="bar" 
                    [data]="data" 
                    [options]="chartOptions"
                    [plugins]="chartPlugins"
                    [responsive]="responsive"
                    [width]="width"
                    [height]="height"
                    [responsive]="responsive"
                    (onDataSelect)="dataSelect($event)"
                >
                </p-chart>
            </div>
        </div>
    `
})
export class LmBarChartComponent extends LmBaseChartComponent implements OnInit, OnDestroy, AfterViewInit {
    static nextId = 0;
    @HostBinding() id = `lm-bar-chart-${LmBarChartComponent.nextId++}`;    


    constructor(@Inject(LM_BASE_CHART_OPTIONS) _baseOptions: ChartOptions) {
        super(_baseOptions)
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngAfterViewInit();
    }
}

@NgModule({
    imports: [CommonModule, ChartModule],
    exports: [LmBarChartComponent],
    declarations: [LmBarChartComponent]
  })
  export class LmBarChartModule {}