import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private cookieService: CookieService,
    ) { }

    public isAuthenticated(): boolean {
        const helper = new JwtHelperService();
        const jwt = this.cookieService.get('jwt');
        if (jwt) {
            const decodedToken = helper.decodeToken(jwt);
            return true;
        }
        return false;
    }

}
