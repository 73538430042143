import { Injectable } from '@angular/core';
import { LmIdGenerator } from './id-generator';

@Injectable()
export class LMUlidGeneratorService implements LmIdGenerator {
  private BASE32 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z'];
  private last = -1;
  /* Pre-allocate work buffers / views */
  private ulid = new Uint8Array(16);
  private time = new DataView(this.ulid.buffer, 0, 6);
  private rand = new Uint8Array(this.ulid.buffer, 6, 10);
  private dest = new Array(26);

  constructor() {}

  newId(): string {
    let now = Date.now();
    if (now === this.last) {
      /* 80-bit overflow is so incredibly unlikely that it's not
       * considered as a possibility here.
       */
      for (let i = 9; i >= 0; i--) if (this.rand[i]++ < 255) break;
    } else {
      this.last = now;
      this.time.setUint16(0, (now / 4294967296.0) | 0);
      this.time.setUint32(2, now | 0);
      window.crypto.getRandomValues(this.rand);
    }
    return this.format(this.ulid);
  }

  private encode(ulid) {
    this.dest[0] = this.BASE32[ulid[0] >> 5];
    this.dest[1] = this.BASE32[(ulid[0] >> 0) & 0x1f];
    for (let i = 0; i < 3; i++) {
      this.dest[i * 8 + 2] = this.BASE32[ulid[i * 5 + 1] >> 3];
      this.dest[i * 8 + 3] = this.BASE32[((ulid[i * 5 + 1] << 2) | (ulid[i * 5 + 2] >> 6)) & 0x1f];
      this.dest[i * 8 + 4] = this.BASE32[(ulid[i * 5 + 2] >> 1) & 0x1f];
      this.dest[i * 8 + 5] = this.BASE32[((ulid[i * 5 + 2] << 4) | (ulid[i * 5 + 3] >> 4)) & 0x1f];
      this.dest[i * 8 + 6] = this.BASE32[((ulid[i * 5 + 3] << 1) | (ulid[i * 5 + 4] >> 7)) & 0x1f];
      this.dest[i * 8 + 7] = this.BASE32[(ulid[i * 5 + 4] >> 2) & 0x1f];
      this.dest[i * 8 + 8] = this.BASE32[((ulid[i * 5 + 4] << 3) | (ulid[i * 5 + 5] >> 5)) & 0x1f];
      this.dest[i * 8 + 9] = this.BASE32[(ulid[i * 5 + 5] >> 0) & 0x1f];
    }
    return this.dest.join('');
  }

  private a2hs = function(bytes, begin, end, uppercase, str, pos) {
    var mkNum = function(num, uppercase) {
      var base16 = num.toString(16);
      if (base16.length < 2) base16 = '0' + base16;
      if (uppercase) base16 = base16.toUpperCase();
      return base16;
    };
    for (var i = begin; i <= end; i++) str[pos++] = mkNum(bytes[i], uppercase);
    return str;
  };

  private format = function(ulid) {
    const arr = new Array(36);
    this.a2hs(ulid, 0, 3, false, arr, 0);
    arr[8] = '-';
    this.a2hs(ulid, 4, 5, false, arr, 9);
    arr[13] = '-';
    this.a2hs(ulid, 6, 7, false, arr, 14);
    arr[18] = '-';
    this.a2hs(ulid, 8, 9, false, arr, 19);
    arr[23] = '-';
    this.a2hs(ulid, 10, 15, false, arr, 24);
    return arr.join('');
  };
}
