import { GenericService } from '@app/services/generic.service';
import { CustomerDateModalComponent } from '@app/modals/customer-date-modal/customer-date-modal.component';
import { AddressService } from './../services/address.service';
import { Component, AfterContentInit, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StepsService } from '@app/services/steps.service';
import { PreloadStopPointsService } from '@app/services/preload-stop-points.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { MapComponent } from '@app/map/map.component';
import { StopModalComponent } from '@app/modals/stop-modal/stop-modal.component';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';

import { Observable } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ProjectProblemModalComponent } from '@app/modals/project-problem-modal/project-problem-modal.component';
import { MilyService } from '@app/services/mily.service';
import { MilyComponent } from '@app/mily/mily.component';
import { ColourService } from '@app/services/colour.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { ProjectProblemViewUtils } from '@app/utils/project-problem-view-utils'
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-collaborator-overview',
  templateUrl: './collaborator-overview.component.html',
  styleUrls: ['./collaborator-overview.component.scss']
})
export class CollaboratorOverviewComponent implements AfterContentInit, OnInit, OnDestroy {

  @ViewChild(ProjectProblemModalComponent, { static: false }) projectProblemModalComponent: ProjectProblemModalComponent;
  @ViewChild(MapComponent, { static: false }) mapComponent: MapComponent;
  @ViewChild(StopModalComponent, { static: false }) stopModalComponent: StopModalComponent;
  @ViewChild(MilyComponent, { static: false }) milyComponent: MilyComponent;

  modal;
  listen = [];

  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  dayOfWeek;

  departureDate;
  departureTime;
  defaultTimeWindow = [];
  averageWeight = 0;
  maximumWeight = 0;
  stopPointsCount;
  completedStopPointsCount;
  completedAndCancelledStopPointsCount = 0;
  limitedTimeWindowStopsCount = 0;
  routeEndTime = '';
  currentRouteDurationHours;
  currentRouteDuration;
  routeDuration;
  routeDurationHours;
  routeDurationSumHours;
  routeSettingsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/route-settings';
  optimizeInterval;
  autoUpdateStopsFulfillmentStatusInterval;
  autoRefreshInterval;
  trackVehiclesInterval;
  trackVehiclesOldRouteInterval = [];
  lastIdOldRoute = 0;
  vehicleIds = [];
  vehicleSetting = {};
  vehicleIdPlateNumber = {};
  vehicleData = {};
  trackRequestDone = true;
  dispatchStopPointsCondition = false;
  milyOpenedForCanceledErrorStops = false;
  refreshStopPointsInterval;
  autoRefreshSolutionInterval;

  mergeAlert;
  sequenceAlert;
  deleteAlert;
  noDemoAlert;
  stopsChangedInPortal;
  optimizeAlerts = {};
  optimizeLabel;
  reOptimizeLabel;
  reDispatchLabel;
  dispatchLabel;
  inProgressLabel = '';
  completedLabel = '';
  errorLabel = '';
  thinkButtonFindMsg = '';
  loadingStopsMsg = '';
  hoursShortMsg = ''
  minutesShortMsg = ''

  deleteAlertSubscription = null;
  deleteSameDayAlertSubscription = null;
  mergeAlertSubscription = null;
  cancelAlertSubscription = null;

  stopPointIdsWithIssuesUpdated = [];

  constructor(
    public translate: TranslateService,
    public http: HttpClient,
    private elRef: ElementRef,
    private stepsService: StepsService,
    public preloadStopPointsService: PreloadStopPointsService,
    public projectProblemDataService: ProjectProblemDataService,
    private viewProjectProblemService: ViewProjectProblemService,
    public globals: Globals,
    private milyService: MilyService,
    private colourService: ColourService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private projectProblemViewUtils: ProjectProblemViewUtils,
    private addressService: AddressService,
    public genericService: GenericService
  ) {
    // unused for now
    this.listen.push(this.viewProjectProblemService.displayStopPointsToMapListen().subscribe((response) => {
      if (Array.isArray(response)) {
        if (response.length > 1) {
          response.forEach(item => {
            this.loadStopToMap(item, true);
          });
          this.mapComponent.addAllStopsToZoomLevels();
          this.mapComponent.showLevelsStopPoints();
          if (!this.mapComponent.drawModeEnabled && !this.mapComponent.selectModeEnabled) {
            this.mapComponent.checkWhenDoneRendering();
          }
        } else {
          response.forEach(item => {
            this.loadStopToMap(item, false);
          });
        }
        if (this.mapComponent.disableSelectModeAfterUpdate) {
          this.mapComponent.disableSelectMode();
          this.mapComponent.disableSelectModeAfterUpdate = false;
        }
      }
    }));
    this.listen.push(this.viewProjectProblemService.removeStopPointFromMapListen().subscribe(id => {
      this.mapComponent.removeStopPoint(id);
    }));
    this.listen.push(this.viewProjectProblemService.removeStopPointFromClusterListen().subscribe(data => {
      this.mapComponent.removeStopPointFromClusterCenter(data.stopPointId, data.zoomLevel, data.centerId);
    }));
    this.listen.push(this.viewProjectProblemService.resetMilyOpenedForCanceledErrorStopsListen().subscribe(data => {
      this.milyOpenedForCanceledErrorStops = false;
    }));
    this.listen.push(this.viewProjectProblemService.startGeneralTourListen().subscribe(ids => {
      this.startTour();
    }));
  }

  setStopPointsGridCount(event) {
    if (event) {
      const object = JSON.parse(event);
      if (object) {
        this.stopPointsCount = object.total;
        this.completedStopPointsCount = object.completed;
        this.completedAndCancelledStopPointsCount = object.completedAndCancelled;
      }
    }
  }

  openModal(step): void {
    this.stepsService.transmitProjectProblemDataToModals(step);
  }

  // refresh solution (for polylines polling)
  refreshSolution() {
    // this.projectProblemDataService.checkForCollaboratorSolution();

    // setTimeout(() => {
    //   this.projectProblemViewUtils.calculateSolutionData();
    //   this.checkForSolution();
    // }, 500);
  }

  // refresh every 1 min
  checkForSolution() {
    if (this.projectProblemDataService.solutionData) {
      this.loadStopsToMap();
      const solutionData = this.projectProblemViewUtils.calculateSolutionData();
      const polylinesArray = solutionData.polylinesArray;
      //  NOTE polylines
      // this.mapComponent.addEncodedElements(polylinesArray);
      this.updateWhiteBar(solutionData);
      if (this.globals.foodModeEnabled) {
        this.autoRefresh();
      } else {
        this.autoUpdateStopIcons();
      }
    }
  }

  sendReloadingRoutesToMily() {
    const routes = [];
    this.projectProblemDataService.routeSettingIdsWithReload.forEach(routeSettingId => {
      const driver = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId];
      if (driver) {
        if (driver.id) {
          const driverId = driver.id;
          const driverData = this.projectProblemDataService.drivers[driverId];
          const routeIndex = this.projectProblemDataService.getRouteIndexByRouteSettingId(routeSettingId);
          const routeInfo = {
            name: driverData.userProfile.name,
            info: '',
            colour: this.colourService.colourCalculator(routeIndex)
          };
          routes.push(routeInfo);
        }
      }
    });
    this.milyService.reloadsDetected(routes);
  }

  updateWhiteBar(solutionData = null) {
    if (!solutionData) {
      solutionData = this.projectProblemViewUtils.calculateSolutionData();
    }
    const stopPointsCountData = this.projectProblemViewUtils.calculateFoodStopPointsCount();
    this.stopPointsCount = stopPointsCountData.stopPointsCount;
    this.completedStopPointsCount = stopPointsCountData.completedStopPointsCount;
    this.completedAndCancelledStopPointsCount = stopPointsCountData.completedAndCancelledStopPointsCount;

    const firstPointInRouteDatetimesArray = solutionData.firstPointInRouteDatetimesArray;
    const lastPointInRouteDatetimesArray = solutionData.lastPointInRouteDatetimesArray;
    const maxCurrentRouteDurationHours = solutionData.maxCurrentRouteDurationHours;

    this.routeDurationSumHours = this.projectProblemViewUtils.calculateRouteDurationSumHours(solutionData);

    if (lastPointInRouteDatetimesArray.length) {
      const routeEndTimes = lastPointInRouteDatetimesArray.map(function (routeEndTime) {
        return moment(routeEndTime);
      });
      const routeStartTimes = firstPointInRouteDatetimesArray.map(function (routeStartTime) {
        return moment(routeStartTime);
      });
      const minRouteStartTime = moment.min(routeStartTimes).utc();
      const maxRouteEndTime = moment.max(routeEndTimes).utc();
      this.routeEndTime = maxRouteEndTime.format('HH:mm');
      this.routeDurationHours = moment.duration(maxRouteEndTime.diff(minRouteStartTime)).asHours();

      this.currentRouteDurationHours = maxCurrentRouteDurationHours;
      const durationHoursFloor = Math.floor(this.routeDurationHours);
      const durationMinutes = moment.duration((this.routeDurationHours - durationHoursFloor), 'hours').asMinutes();
      this.routeDuration = durationHoursFloor + 'h ' + Math.round(durationMinutes) + 'm';
      // this.routeDurationSum = durationH + 'h ' + Math.round(durationM) + 'm';
      if (this.currentRouteDurationHours > this.routeDurationHours) {
        this.currentRouteDurationHours = this.routeDurationHours;
      }
      if (this.currentRouteDurationHours < 0) {
        this.currentRouteDurationHours = 0;
      }

      const currentDurationHoursFloor = Math.floor(this.currentRouteDurationHours);
      const currentDurationMinutes = moment.duration((this.currentRouteDurationHours - currentDurationHoursFloor), 'hours').asMinutes();

      this.currentRouteDuration = currentDurationHoursFloor + 'h ' + Math.round(currentDurationMinutes) + 'm';
    }
  }

  // load a stop point to map with all it's data
  loadStopToMap(currentStopPoint, bulk) {
    const isOptimized = false;
    const stopPointData = this.projectProblemViewUtils.getStopPointDataForMap(
      currentStopPoint, null, this.maximumWeight, this.defaultTimeWindow, isOptimized
    );
    if (currentStopPoint['stopPoint']) {
      currentStopPoint = currentStopPoint['stopPoint'];
    }
    // [food mode]: don't show pickups in food mode
    if (stopPointData && !(this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && currentStopPoint['service_type'] == this.globals.stopPointServiceTypeConstants['PICKUP'])) {
      const id = stopPointData.id;
      this.projectProblemDataService.stopPointsMarkerData[id] = stopPointData;
      this.mapComponent.addStopPoint(stopPointData, bulk);
    }
  }

  loadStopsToMap() {
    let levels = this.projectProblemDataService.stopPointClustersByLevelByClusterId;
    const zoomLevels = [];
    let initialZoomLevels = [];
    Object.keys(levels).forEach(zoomLevel => {
      initialZoomLevels.push(Number(zoomLevel));
    });

    // sort the zoom levels to find the next larger zoom if needed
    initialZoomLevels.sort(function (a, b) {
      return a - b;
    });

    const minZoomLevel = initialZoomLevels[0];
    const maxZoomLevel = initialZoomLevels[initialZoomLevels.length - 1];


    // if there is a zoom level in the middle of others that doesn't exist, use the one above it
    if (maxZoomLevel && minZoomLevel) {
      for (let i = minZoomLevel; i < maxZoomLevel; i++) {
        if (!initialZoomLevels.includes(i)) {

          // find the next bigger existing zoom level to use
          let nextInitialLevel = null;
          initialZoomLevels.forEach(level => {
            if (!nextInitialLevel && level > i) {
              nextInitialLevel = level;
            }
          });
          if (nextInitialLevel && this.projectProblemDataService.stopPointClustersByLevelByClusterId[nextInitialLevel]) {
            this.projectProblemDataService.stopPointClustersByLevelByClusterId[i] = this.projectProblemDataService.stopPointClustersByLevelByClusterId[nextInitialLevel];
          } else {
            console.error('Something went wrong:');
            console.error('nextInitialLevel: ' + nextInitialLevel);
            console.error(this.projectProblemDataService.stopPointClustersByLevelByClusterId);
          }

        }
      }
    }
    levels = this.projectProblemDataService.stopPointClustersByLevelByClusterId;

    // add the cluster centers to the map
    Object.keys(levels).forEach(zoomLevel => {
      if (Number(zoomLevel) > 0 && Number(zoomLevel) < 21) {
        zoomLevels.push(Number(zoomLevel));
        Object.keys(levels[zoomLevel]).forEach(centerId => {
          const data = levels[zoomLevel][centerId];
          const coords = { lat: data.center.lat, lng: data.center.lon };
          const stopPointSequences = [];
          data.stopPointIds.forEach(stopPointId => {
            if (this.projectProblemDataService.stopPointSolutionData[stopPointId]) {
              stopPointSequences.push(this.projectProblemDataService.stopPointSolutionData[stopPointId]['sequence']);
            }
          });
          let colour = '#666666';
          if (data.routeIndex !== null) { colour = this.colourService.colourCalculator(data.routeIndex); }
          this.mapComponent.addClusterCenter(zoomLevel, coords, centerId, data.stopPointIds, stopPointSequences, colour);
        });
      }
    });
    this.mapComponent.clusterZoomLevels = zoomLevels;
    if (maxZoomLevel) {
      this.mapComponent.startClusterZoomLevel = maxZoomLevel;
    }

    // this.droppedStopsCount = 0;
    this.limitedTimeWindowStopsCount = 0;
    let stopPoints = this.projectProblemDataService.stopPointsArray;
    this.loadDepotsToMap(stopPoints);
    stopPoints.forEach(currentStopPoint => {
      this.loadStopToMap(currentStopPoint, true);
    });
    this.mapComponent.addAllStopsToZoomLevels();
    this.mapComponent.showLevelsStopPoints();
    this.mapComponent.checkWhenDoneRendering();
  }

  loadDepotsToMap(stopPoints) {
    const depots = [];
    stopPoints.forEach(currentStopPoint => {
      if (currentStopPoint.stopPoint) {
        // if sp is an intermediate depot
        if (currentStopPoint.stopPoint.related_to !== this.globals.stopPointRelatedToConstants['SELF']) {
          depots.push(currentStopPoint.stopPoint);
        }
      }
      this.loadStopToMap(currentStopPoint, true);
    });
    this.mapComponent.displayCollaboratorAndPartnerDepots(depots);
  }

  setCollaboratorOverview() {
    this.stepsService.transmitProjectProblemData(null, '', null, this.globals.projectProblemOptimizationStateConstants['OPTIMIZED']);
    this.stopModalComponent.projectProblemId = null;
    this.stopModalComponent.projectProblemDepartureDatetime = null;
    // this.milyComponent.optimizationState = this.optimizationState;
  }

  loadCollaboratorOverview(checkForSolution = false, setCollaboratorOverview = false) {
    this.dayOfWeek = this.days[moment().day()];
    this.departureDate = moment().format('DD MMM YYYY');
    this.departureTime = moment().format('HH:mm');

    this.defaultTimeWindow = this.projectProblemViewUtils.setDefaultTimeWindow();

    this.mapComponent.entityStatus = this.globals.projectProblemEntityStatusConstants['DISPATCHED'];
    this.stopModalComponent.projectProblemEntityStatus = this.globals.projectProblemEntityStatusConstants['DISPATCHED'];

    this.mapComponent.emptyDriverStartEndPoints();

    // display drivers' start and stop points if they are not depots
    Object.keys(this.projectProblemDataService.routeSettings).forEach(routeIndex => {
      let colour = '#666';
      const driver = this.projectProblemDataService.routeSettings[routeIndex].driver;
      const driverData = this.projectProblemDataService.drivers[driver.id];
      const routeSettingId = this.projectProblemDataService.routeSettings[routeIndex]['routeSetting']['id'];

      // if the driver has an active route, select the route's colour for the icon
      if (this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId]) {
        colour = this.colourService.colourCalculator(routeIndex);
      }

      if (driverData.driverLocations.locationStart) {
        if (driverData.driverLocations.locationStart.modelName === this.globals.addressModelName) {
          const lat = driverData.driverLocations.locationStart.address.lat;
          const lon = driverData.driverLocations.locationStart.address.lon;
          this.mapComponent.displayDriverStartEndPoint(lat, lon, colour)
        }
      }
      if (driverData.driverLocations.locationEnd) {
        if (driverData.driverLocations.locationEnd.modelName === this.globals.addressModelName) {
          const lat = driverData.driverLocations.locationEnd.address.lat;
          const lon = driverData.driverLocations.locationEnd.address.lon;
          this.mapComponent.displayDriverStartEndPoint(lat, lon, colour)
        }
      }
    });

    if (checkForSolution) {
      this.checkForSolution();
    } else {
      this.updateWhiteBar();
    }
    if (this.dispatchStopPointsCondition) {
      if (this.limitedTimeWindowStopsCount > 0) {
        this.milyService.stopPointsCondition(this.limitedTimeWindowStopsCount, 0);
        this.dispatchStopPointsCondition = false;
      }
    }
    if (setCollaboratorOverview) {
      this.setCollaboratorOverview();
      if (this.limitedTimeWindowStopsCount > 0) {
        this.milyService.stopPointsCondition(this.limitedTimeWindowStopsCount, 0);
      }
    }
  }

  autoRefresh() {
    this.autoUpdateStopIcons();
  }

  autoUpdateStopIcons() {
    if (this.autoUpdateStopsFulfillmentStatusInterval) {
      clearInterval(this.autoUpdateStopsFulfillmentStatusInterval);
    }

    const refreshIntervalMinutes = 1;
    const refreshIntervalMilliseconds = refreshIntervalMinutes * 60 * 1000;
    this.autoUpdateStopsFulfillmentStatusInterval = setInterval(() => {
      const solutionInfoByRouteSetting = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'];
      const sequenceArrayPerRouteSettingId = this.projectProblemDataService.sequenceArrayPerRouteSettingId;
      if (solutionInfoByRouteSetting && sequenceArrayPerRouteSettingId) {
        let stopsToUpdate = [];
        // for each route, get the last visited sp
        Object.keys(solutionInfoByRouteSetting).forEach(routeSettingId => {
          // const stopPointsData = solutionInfoByRouteSetting[routeSettingId];
          const sequenceForThisRouteSettingId = sequenceArrayPerRouteSettingId[routeSettingId];
          if (sequenceForThisRouteSettingId.length) {
            const lastArrivedPointIdInRoute = this.dateTimeCalculatorService.getDriversLastArrivedStopPoint(routeSettingId);
            if (lastArrivedPointIdInRoute) {
              // get the next 5 stops after the last visited sp
              const lastArrivedPointIndexInRoute = sequenceForThisRouteSettingId.indexOf(lastArrivedPointIdInRoute);
              if (lastArrivedPointIndexInRoute) {
                if (!isNaN(lastArrivedPointIndexInRoute)) {
                  const newStopsToUpdate = this.dateTimeCalculatorService.findStopsToUpdate(sequenceForThisRouteSettingId, lastArrivedPointIndexInRoute);
                  stopsToUpdate = stopsToUpdate.concat(newStopsToUpdate);
                }
              }
            } else {
              const newStopsToUpdate = this.dateTimeCalculatorService.findStopsToUpdate(sequenceForThisRouteSettingId, 0);
              stopsToUpdate = stopsToUpdate.concat(newStopsToUpdate);
            }

          }
        });

        if (stopsToUpdate.length) {
          this.projectProblemDataService.loadSpecificStopPointsFulfillmentStatus(stopsToUpdate).pipe(take(1)).subscribe(response => {
            this.projectProblemDataService.calculateDriversStopPointsPercentage();
            this.projectProblemDataService.updateStopPointsAddData(response['items']['stopPoints'], true);
            this.updateWhiteBar();
          });
        }
      }
    }, refreshIntervalMilliseconds);
  }

  trackVehicles() {
    const self = this;
    this.vehicleIds = [];
    this.projectProblemDataService.routeSettingsArray.forEach(route => {
      this.vehicleIds.push(route.vehicle.id);
      this.vehicleSetting[route.vehicle.id] = route.routeSetting.id;
      this.vehicleIdPlateNumber[route.vehicle.id] = route.vehicle.plate_number;
      this.vehicleData[route.vehicle.id] = {
        driverId: route.driver.id,
        routeIndex: route.routeSetting.route_index
      };
    });
    let vehicleIdsString = '';
    this.vehicleIds.forEach(id => {
      if (vehicleIdsString === '') {
        vehicleIdsString = id;
      } else {
        vehicleIdsString = vehicleIdsString + ',' + id;
      }
    });
    let params, colour, coords, dateTime, plateNumber, driverId, driverName, driverImage, companyImage, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour, showPickupBox, estimatedArrival;

    // if (this.trackVehiclesInterval) {
    //   clearInterval(this.trackVehiclesInterval);
    // }
    // ANCHOR vehicles tracker
    vehiclesTracker();
    if (!localStorage.getItem('stopVehicleTracking') && !this.trackVehiclesInterval) {
      this.trackVehiclesInterval = setInterval(vehiclesTracker, 10000);
    } else {
      console.warn('vehicle tracking is disabled with localStorage option')
    }

    function vehiclesTracker() {
      if (self.trackRequestDone) {
        params = `?isLast=true&vehicle_ids=${vehicleIdsString}`;
        self.getVehicleLocationsData(params).pipe(take(1)).subscribe(
          trackResponse => {
            self.mapComponent.mapDummyComponent.collaboratorDriverBoxes.nativeElement.innerHTML = ''; // remove boxes before re-inserting them in the dom
            self.projectProblemDataService.calculateDriversStopPointsPercentage();
            self.trackRequestDone = true;
            let colourIndex = 0;
            const vehicleLocationData = trackResponse['items'];
            let vehicle;
            if (vehicleLocationData) {
              const activeVehicleIdsArray = [];
              Object.keys(vehicleLocationData).forEach(function (vehicleId, index) {
                if (self.projectProblemDataService.vehicleData[vehicleId]) {
                  colourIndex = self.projectProblemDataService.vehicleData[vehicleId]['routeIndex'];
                  vehicle = vehicleLocationData[vehicleId];
                  colour = self.colourService.colourCalculator(colourIndex);
                  if (vehicle[vehicle.length - 1]) {
                    coords = {
                      lat: vehicle[vehicle.length - 1].lat,
                      lon: vehicle[vehicle.length - 1].lon
                    };
                    dateTime = vehicle[vehicle.length - 1].datetime;
                    // dateTime = moment(vehicle[vehicle.length - 1].datetime).format('HH:mm');
                    plateNumber = self.vehicleIdPlateNumber[vehicleId];
                    if (self.projectProblemDataService) {
                      driverId = self.projectProblemDataService.vehicleData[vehicleId]['driverId'];

                      const defaultDriverImage = {
                        base64: self.globals.driverDefaultBase64
                      };

                      driverName = '';
                      const driver = self.projectProblemDataService.drivers[driverId];
                      driverImage = defaultDriverImage;
                      if (driver) {
                        driverName = driver.userProfile.name;
                        if (driver.driverImage.base64 || driver.driverImage.imageHash) {
                          driverImage = driver.driverImage;
                        }
                      }

                      let driverCompanyId;
                      self.projectProblemDataService.driversArray.forEach(driver => {
                        if (driver.driver.id == driverId) {
                          driverCompanyId = driver.userProfile.company_id;
                        }
                      });
                      self.globals.partnersArray.forEach(partner => {
                        if (partner.id == driverCompanyId) {
                          companyImage = partner.logo.base64;
                        }
                      });

                      // determine if this driver has at least one pickup matching their company's depot address and only then show the pickup driver box (old)
                      showPickupBox = false;
                      const driverRouteId = self.projectProblemDataService.driversToRouteSettingIds[driverId];
                      let driverRouteStopPoints = [];
                      if (self.projectProblemDataService['solutionData']['solutionInfoByRouteSettingIdByStopPointId']) {
                        driverRouteStopPoints = Object.keys(self.projectProblemDataService['solutionData']['solutionInfoByRouteSettingIdByStopPointId'][driverRouteId]);
                      }
                      let isCollaboratorDepotInRoute;
                      let earliestEstimatedArrivalDatetime;
                      // self.projectProblemDataService.loadCollaboratorOverviewStopPoints();

                      let shouldShowPickupBox = false;

                      // [food mode]
                      // first, search the route's stop points sequentially & check if the latest non-completed && non-canceled sp is a pickup
                      let latestStopPointServiceType;
                      if (self.globals.collaboratorModeEnabled && self.globals.foodModeEnabled) {
                        if (self.projectProblemDataService['sequenceArrayPerRouteSettingId'][driverRouteId]) {
                          self.projectProblemDataService['sequenceArrayPerRouteSettingId'][driverRouteId].forEach(currStopPoint => {
                            self.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
                              if (stopPoint.id == Number(currStopPoint) && !latestStopPointServiceType) {
                                if (stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT &&
                                  stopPoint.fulfillment_events[0].reason != self.globals.stopPointFulfillmentEventConstants[self.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS
                                ) {
                                  console.warn('moving towards sp: ' + stopPoint.id + ' and service type is ' + stopPoint.service_type);
                                  latestStopPointServiceType = stopPoint.service_type;
                                }
                              }
                            });
                          });
                        }
                      }

                      self.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
                        driverRouteStopPoints.forEach(routeStopPointId => {
                          if (stopPoint.id == Number(routeStopPointId)) {
                            // check if collaborator depot in route first
                            for (let depot of self.globals.depotsArray) {
                              if (depot['companyDepot']['address']['lat'] && depot['companyDepot']['address']['lon']) {
                                isCollaboratorDepotInRoute = self.addressService.isCoordinatesEqual(stopPoint.address.lat, stopPoint.address.lon, depot['companyDepot']['address']['lat'], depot['companyDepot']['address']['lon']);
                                break;
                              }
                            };

                            // decide if pickup box should show (conditions vary based on food mode on/off)
                            // non-food mode check
                            if (!self.globals.foodModeEnabled && !self.globals.collaboratorModeEnabled) {
                              if (
                                stopPoint.service_type == self.globals.stopPointServiceTypeConstants['PICKUP'] &&
                                isCollaboratorDepotInRoute &&
                                (stopPoint.fulfillment_status != self.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
                                  stopPoint.fulfillment_status != self.globals.stopPointFulfillmentStatusConstants['CANCELED'])
                              ) {
                                shouldShowPickupBox = true;
                              }
                            }
                            else if (self.globals.foodModeEnabled && self.globals.collaboratorModeEnabled) {
                              if (latestStopPointServiceType == self.globals.stopPointServiceTypeConstants['PICKUP']) {
                                shouldShowPickupBox = true;
                              }
                            }

                            if (shouldShowPickupBox) {
                              showPickupBox = true;

                              // find the earliest arrival datetime
                              const currentStopPointEstimatedDatetime = moment(stopPoint.solution.latest_estimated_arrival_datetime);
                              let estimationsDiff;
                              if (!earliestEstimatedArrivalDatetime) {
                                earliestEstimatedArrivalDatetime = currentStopPointEstimatedDatetime;
                              } else {
                                estimationsDiff = earliestEstimatedArrivalDatetime.diff(currentStopPointEstimatedDatetime, 'minutes');

                                if (estimationsDiff < 0) {
                                  earliestEstimatedArrivalDatetime = currentStopPointEstimatedDatetime;
                                }
                              }

                              estimatedArrival = moment(earliestEstimatedArrivalDatetime).format('HH:mm');
                            }
                          }
                        });
                      });

                      // estimatedArrival = earliestEstimatedArrivalDatetime;

                      driverTotalProgressDegrees = 0;
                      driverColour = colour;
                      if (self.projectProblemDataService.drivers[driverId]) {
                        vehicleType = self.projectProblemDataService.drivers[driverId].vehicle.vehicle_type;
                        driverFinishedStops = self.projectProblemDataService.stopPointsProgress[driverId].doneStopsCount;
                        driverTotalStops = self.projectProblemDataService.stopPointsProgress[driverId].totalStopsCount;
                        if (driverTotalStops) {
                          driverTotalProgressDegrees = driverFinishedStops / driverTotalStops * 180;
                        }
                      }
                    }
                  } else {
                    dateTime = null;
                  }
                  if (coords && vehicleId && dateTime) {
                    self.viewProjectProblemService.trackVehicles(vehicleId, colour, coords, dateTime, plateNumber, driverName, driverImage, companyImage, vehicleType, driverFinishedStops, driverTotalStops, driverTotalProgressDegrees, driverColour, showPickupBox, estimatedArrival);
                  }

                  if (vehicle[0]) {
                    activeVehicleIdsArray.push(vehicleId);
                    // set appropriate icon & and angle based on vehicle static location
                    const vehicleData = {
                      id: vehicleId,
                      driverName: driverName,
                      colourIndex: colourIndex,
                      coords: {
                        lat: vehicle[0].lat,
                        lon: vehicle[0].lon
                      },
                      angle: vehicle[0].angle,
                      isStatic: vehicle[0].is_static,
                    };
                    self.viewProjectProblemService.updateVehicleIcon(vehicleData);
                  }
                }
              });
              self.viewProjectProblemService.showOnlyActiveVehiclesOnMap(activeVehicleIdsArray);
            }

            self.projectProblemDataService.estimatedArrivalTimeDifferenceMinutesPerRouteSettingId = {};
            const vehiclesInfo = trackResponse['extra_items']['vehicles'];
            if (vehiclesInfo) {
              vehiclesInfo.forEach(vehicleData => {
                const vehicleId = vehicleData.vehicle.id;
                self.projectProblemDataService.routeSettingsArray.forEach(routeSetting => {
                  if (routeSetting.vehicle.id === vehicleId) {
                    const routeSettingId = routeSetting.routeSetting.id;
                    const estimatedArrivalTimeDifferenceMinutes = moment.duration(vehicleData.vehicle.offset).asMinutes();
                    self.projectProblemDataService.estimatedArrivalTimeDifferenceMinutesPerRouteSettingId[routeSettingId] = estimatedArrivalTimeDifferenceMinutes;
                  }
                });
              });
            }
          },
          error => {
            self.trackRequestDone = true;
          }
        );
      }
    }
  }

  public getVehicleLocationsData(params: any): Observable<any> {
    this.trackRequestDone = false;
    return this.http.get('api/v1/partner-vehicle-location' + params);
  }

  startTour() {
    this.globals.comingSoonAlert();
  }

  loadAllStopsToCollaboratorLiveMap() {
    const self = this;
    this.viewProjectProblemService.showProjectProblemButtons(null);
    this.projectProblemDataService.projectProblemId = null;
    this.projectProblemDataService.resetFlags();
    this.projectProblemDataService.loadCollaboratorOverviewData();
    const dataRefreshIntervalId = setInterval(dataChecker, 200);

    function dataChecker() {
      if (self.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        self.maximumWeight = self.projectProblemViewUtils.calculateAverageWeight();
        self.loadCollaboratorOverview(true, true);

        // [food mode]: add only depots that exist in active sps (if no sps then show all depots)
        if (self.globals.foodModeEnabled && self.globals.collaboratorModeEnabled) {
          self.viewProjectProblemService.updateStopPointsCounter();
          if (self.projectProblemDataService.stopPointsArray.length) {
            let foundDepotNames = [];
            let depotsToShow = [];
            self.projectProblemDataService.stopPointsArray.forEach(stopPointData => {
              self.globals.depotsArray.forEach(depot => {
                if (stopPointData['stopPoint']['contact_name'] == depot['companyDepot']['address']['label'] && !foundDepotNames.includes(depot['companyDepot']['address']['label'])) {
                  foundDepotNames.push(depot['companyDepot']['address']['label']);
                  depotsToShow.push(depot);
                }
              });
            });
            self.mapComponent.displaySpecificDepots(depotsToShow);
          } else {
            self.mapComponent.displaySpecificDepots(self.globals.depotsArray);
          }
        } else {
          self.mapComponent.displayDepots();
        }

        // map click disable
        self.mapComponent.disableClickOnMap = true;
        self.mapComponent.disableRightClickOnMap = true;
      }
    }
  }

  ngAfterContentInit() {
    this.modal = this.elRef.nativeElement.querySelector('.top-right-modal');
  }

  getTranslations() {
    const globalsOptimizeConfirmConstants = this.globals.projectProblemConfirmRunThinkConstants;
    this.listen.push(this.translate.get('GENERIC.HOURS_SHORT').subscribe((res: string) => {
      this.hoursShortMsg = res;
    }));
    this.listen.push(this.translate.get('GENERIC.MINUTES_SHORT').subscribe((res: string) => {
      this.minutesShortMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.THINK_BUTTON_FIND').subscribe((res: string) => {
      this.thinkButtonFindMsg = res;
    }));
    this.listen.push(this.translate.get('PROJECT.LOADING_STOPS').subscribe((res: string) => {
      this.loadingStopsMsg = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.OPTIMIZE').subscribe((res: string) => {
      this.optimizeLabel = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.DISPATCH').subscribe((res: string) => {
      this.dispatchLabel = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.RE_OPTIMIZE').subscribe((res: string) => {
      this.reOptimizeLabel = res;
    }));
    this.listen.push(this.translate.get('OPTIMIZE_MSG.RE_DISPATCH').subscribe((res: string) => {
      this.reDispatchLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS').subscribe((res: string) => {
      this.inProgressLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.completedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.ERROR').subscribe((res: string) => {
      this.errorLabel = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.MERGE_ALERT').subscribe((res: string) => {
      this.mergeAlert = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.SEQUENCE_ALERT').subscribe((res: string) => {
      this.sequenceAlert = res;
    }));
    this.listen.push(this.translate.get('ALERTS.DELETE_ALERT').subscribe((res: string) => {
      this.deleteAlert = res;
    }));
    this.listen.push(this.translate.get('ALERTS.NO_DISPATCH').subscribe((res: string) => {
      this.noDemoAlert = res;
    }));
    this.listen.push(this.translate.get('ALERTS.STOPS_CHANGED_IN_PORTAL').subscribe((res: string) => {
      this.stopsChangedInPortal = res;
    }));
    this.listen.push(this.translate.get('ALERTS.DROP_MODIFICATIONS_BECAUSE_OF_SIMPLE_THINK_WITH_MODIFICATIONS').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_DROP_MODIFICATIONS_BECAUSE_OF_SIMPLE_THINK_WITH_MODIFICATIONS']] = res;
    }));
    this.listen.push(this.translate.get('ALERTS.KEEP_ROUTE_MODIFICATIONS_BECAUSE_OF_THINK_AFTER_DISPATCH_WITH_UN_ASSIGNED_NODES').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_KEEP_ROUTE_MODIFICATIONS_BECAUSE_OF_THINK_AFTER_DISPATCH_WITH_UN_ASSIGNED_NODES']] = res;
    }));
    this.listen.push(this.translate.get('ALERTS.SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITHOUT_MODIFIED_ROUTES').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITHOUT_MODIFIED_ROUTES']] = res;
    }));
    this.listen.push(this.translate.get('ALERTS.SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITH_UN_ASSIGNED_NODES').subscribe((res: string) => {
      this.optimizeAlerts[globalsOptimizeConfirmConstants['CONFIRM_RUN_THINK_WITH_SEND_ALL_ROUTES_BECAUSE_OF_LOCK_ROUTES_WITH_UN_ASSIGNED_NODES']] = res;
    }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
      this.loadAllStopsToCollaboratorLiveMap();

    // start tracking vehicle locations
    const dataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);

    function dataChecker() {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(dataRefreshIntervalId);
        this.trackVehicles();
      }
    }

    window.onbeforeunload = () => this.ngOnDestroy();
  }


  ngOnDestroy() {
    setTimeout(() => {
      const preChatElements = document.querySelector('body > div > iframe');
      const chatElements = document.querySelectorAll('body #comm100-container iframe');
      if (chatElements) {
        chatElements.forEach(element => {
          element.classList.remove('high');
        });
      }
      if (preChatElements) {
        preChatElements.classList.remove('high');
      }
    }, 100);

    this.projectProblemDataService.resetFlags();
    if (this.optimizeInterval) {
      clearInterval(this.optimizeInterval);
    }
    if (this.autoUpdateStopsFulfillmentStatusInterval) {
      clearInterval(this.autoUpdateStopsFulfillmentStatusInterval);
    }
    if (this.trackVehiclesInterval) {
      clearInterval(this.trackVehiclesInterval);
    }
    if (this.refreshStopPointsInterval) {
      clearInterval(this.refreshStopPointsInterval);
    }
    if (this.autoRefreshSolutionInterval) {
      clearInterval(this.autoRefreshSolutionInterval);
    }
    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval);
    }
    this.trackVehiclesOldRouteInterval.forEach(trackVehiclesOldRouteInterval => {
      clearInterval(trackVehiclesOldRouteInterval);
    });
    this.trackVehiclesOldRouteInterval = [];
    this.lastIdOldRoute = 0;
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
