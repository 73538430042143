// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  height: calc(100vh - 65px);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNldHRpbmdzLXJlZ2lvbmFsLW1hcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNJLDBCQUFBO0FBREoiLCJmaWxlIjoic2V0dGluZ3MtcmVnaW9uYWwtbWFwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnc3R5bGVzL2Fic3RyYWN0cy92YXJpYWJsZXMnO1xuXG4jbWFwIHtcbiAgICBoZWlnaHQ6IGNhbGMoMTAwdmggLSA2NXB4KTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/settings/drivers-regions/map/settings-regional-map.component.scss"],"names":[],"mappings":"AAEA;EACI,0BAAA;AADJ;AACA,oZAAoZ","sourcesContent":["@import 'styles/abstracts/variables';\n\n#map {\n    height: calc(100vh - 65px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
