// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#checkbox-all p {
  margin: 0 0 0 10px;
}

#new-shipments-grid-container {
  height: calc(100vh - 162px);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5ldy1zaGlwbWVudHMtZ3JpZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGtCQUFBO0FBQVI7O0FBSUE7RUFDSSwyQkFBQTtBQURKIiwiZmlsZSI6Im5ldy1zaGlwbWVudHMtZ3JpZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIiNjaGVja2JveC1hbGwge1xuICAgIHAge1xuICAgICAgICBtYXJnaW46IDAgMCAwIDEwcHg7XG4gICAgfVxufVxuXG4jbmV3LXNoaXBtZW50cy1ncmlkLWNvbnRhaW5lciB7XG4gICAgaGVpZ2h0OiBjYWxjKDEwMHZoIC0gMTYycHgpO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/grids/new-shipments-grid/new-shipments-grid.component.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;;AAIA;EACI,2BAAA;AADJ;AACA,4eAA4e","sourcesContent":["#checkbox-all {\n    p {\n        margin: 0 0 0 10px;\n    }\n}\n\n#new-shipments-grid-container {\n    height: calc(100vh - 162px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
