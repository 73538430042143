import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, AfterViewInit, NgModule, forwardRef, HostBinding, ViewChild, Injector } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { LmInputBase } from '@app/shared/controls/input/input-base';
import {KnobModule} from 'primeng/knob';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmKnobComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmKnobComponent) };

@Component({
    selector: 'lm-knob',
    template: `
    <div class="grid lm-height lm-pdt10">
        <div class="col-12 lm-height lm-rel">
            <div class="lm-knob-icon" *ngIf="iconClass">
                <i [ngClass]="{'lm-icon': true}" [attr.class]="iconClass"></i>
            </div>
            <p-knob  
                [(ngModel)]="value" 
                [name]="name"
                id="knob"
                [min]="min"
                [max]="max"
                [disabled]="disabled"
                [readonly]="readonly"
                [step]="step"
                [valueColor]="valueColor"
                [showValue]="showValue"
                [rangeColor]="rangeColor"
                [style]="style"
                [styleClass]="styleClass"
                [textColor]="textColor"
                [size]="size"
            >
            </p-knob>
            <div class="lm-knob-limit lm-knob-min">{{min}}</div>
            <div class="lm-knob-line"></div>
            <div class="lm-knob-limit lm-knob-max">{{max}}</div>
        </div>
    </div>
    `,
    styles: [`
        .lm-knob-limit{color:#999;position:absolute;width:40px;top:auto;left:0;right:auto;bottom:20px;}
        .lm-knob-icon{position:absolute; left:0;right:0;top:0;bottom:auto;padding-top:25%;text-align:center}
        .lm-knob-icon i{font-size:2.4rem!important}
        .lm-knob-min{text-align:right}
        .lm-knob-max{left:auto;right:0}
        .lm-knob-line{height: 50px;border-top: solid 1px #eee;position:absolute;left:50%;width:20%;margin-left:-10%;right:auto;top:auto;bottom:0}
        :host ::ng-deep .p-knob-text{font-weight:bold; font-size:1rem;}
    `],
    providers: [VALUE_ACCESSOR, LM_INPUT_BASE]
})
export class LmKnobComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    static nextId = 0;

    @HostBinding() id = `lm-knob-${LmKnobComponent.nextId++}`;
    name = `lm-knob-${LmKnobComponent.nextId++}`;

    @ViewChild(NgModel) model: NgModel;
    
    @Input() disabled = true;
    @Input() readonly?: boolean;
    @Input() step?: number;
    @Input() min = 0;
    @Input() max?: number;
    @Input() valueColor = '#00aeba';
    @Input() rangeColor = '#e5e5e5';
    @Input() textColor= '#495057';
    @Input() strokeWidth?: number;
    @Input() showValue = true;
    @Input() valueTemplate?: string;
    @Input() style?: any;
    @Input() styleClass?: string;
    @Input() size?: number;
    @Input() iconClass: string;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit(): void {
       super.ngAfterViewInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, KnobModule],
    exports: [LmKnobComponent, KnobModule],
    declarations: [LmKnobComponent]
  })
  export class LmKnobModule {}