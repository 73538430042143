/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { IProjectProblemRoutingSettingsDto } from '../models/project-problem-routing-settings-dto';

@Injectable({
  providedIn: 'root'
})
export class LmProjectProblemRoutingSettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  // ------------------------- ADD DTOs ---------------------
  // GET BY ID
  static readonly projectProblemRoutingSettingsGetPath = 'api/v1/project/problems/{id}/project-problem-route-settings';

  apiProjectProblemRoutingSettingsGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmProjectProblemRoutingSettingsService.projectProblemRoutingSettingsGetPath, 'get');
    if (params) rb.path('id', params.id);

    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'application/json'
      }))
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        }
        )
      );
  }

  apiProjectProblemRoutingSettingsGet(params?: { id?: string }): Observable<Array<any>> {
    return this.apiProjectProblemRoutingSettingsGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

  // POST 
  apiProjectProblemRoutingSettingsPost$Response(params?: { body?: any, id?: string }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmProjectProblemRoutingSettingsService.projectProblemRoutingSettingsGetPath, 'post');
    if (params) rb.path('id', params.id);
    if (params) rb.body(params.body, 'application/*+json');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        })
      );
  }


  apiProjectProblemRoutingSettingsPost(params?: { body?: any, id?: string }): Observable<Array<any>> {
    return this.apiProjectProblemRoutingSettingsPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

}
