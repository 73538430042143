import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { Globals } from '@app/services/globals';
import { ISettings_Basic_Services_Dto } from '@app/api/models/settings-services-dto';
import { LmSettingsBasicServicesService } from '@app/api/services/settings-basic-services.service';
import { IPriceListServices } from '@app/model/price-list';
import { BasicServiceModalComponent } from './new-basic-service-modal/new-basic-service-modal.component';
import { INITIAL_DATA } from '@app/model/initial-data';


@Injectable()
export class SettingsBasicServicesViewModelService extends LmTableListViewModelService<ISettings_Basic_Services_Dto> implements OnInit, OnDestroy {
    basicServicesEnum: IPriceListServices;
    modalConfig: any;
    showBasicServiceModal = BasicServiceModalComponent;
    basicServicesEnumKeyToTranslationsKeyMap = {};


    constructor(
        injector: Injector, 
        public globals: Globals,
        @Inject(forwardRef(() => LmSettingsBasicServicesService)) public apiSvc: LmSettingsBasicServicesService
    ){
        super(injector);
        const {priceListServicesEnum} = globals;
        this.basicServicesEnum = priceListServicesEnum;
        this.modalConfig = { componentType: this.showBasicServiceModal, injector: injector,data: {VM: this, globals: this.globals} }
        this.apiSvc.globals = this.globals;
        this.basicServicesEnumKeyToTranslationsKeyMap[this.basicServicesEnum.NextDay.key] = 'NEXT_DAY';
        this.basicServicesEnumKeyToTranslationsKeyMap[this.basicServicesEnum.Express.key] = 'SAME_DAY';
        this.basicServicesEnumKeyToTranslationsKeyMap[this.basicServicesEnum.ThroughHub.key] = 'THROUGH_HUB';
    }
    
    private transformRows = (res) => {
        res.items.forEach(item => {
            const basicServiceEnum = Object.values(this.basicServicesEnum).find(level => level.key === item.type);
            let serviceTypeLabel = basicServiceEnum.value;
            if (this.modalConfig.data['translations']) {
                serviceTypeLabel = this.modalConfig.data['translations'][this.basicServicesEnumKeyToTranslationsKeyMap[basicServiceEnum.key]];
            }
            item.nameAndType = `${item.name}</br><small class="lm-txt-color-grey lm-font-weight-normal">${serviceTypeLabel}</small>`;
            item.hideDelete = item.is_default;
        });
        return res;
    }
    
    getRows(){
        return this.apiSvc.apiSettingsBasicServicesAllGet().pipe(switchMap(res => of(this.transformRows(res))));
    }
    getById(id){

    }
    post(item: any) {
        return this.apiSvc.apiSettingsBasicServicesPost({body: item})
    }
    put(id) {
        
    }
    delete(id) {
        return this.apiSvc.apiSettingsBasicServicesSingleDelete({id:id})
    }
    newItem() {
        return INITIAL_DATA.newBasicServiceItem;
    }
    search(query: string) {
        
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
