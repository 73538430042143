import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { MapUtils } from '@app/utils/map-utils';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';

declare var H: any;

@Component({
  selector: 'app-map2',
  template: '<div id="map" #map></div><button (click)="test()">test</button><button (click)="rem()">rem</button><app-svg-icons></app-svg-icons>',
  styleUrls: ['./map.component.scss']
})
export class Map2Component implements OnInit, AfterViewInit, OnDestroy {
  private platform: any;
  @ViewChild('map', { static: false }) public mapElement: ElementRef;
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

  map;
  ui;
  behavior;

  count = 0;
  timer = 0;

  markersToAdd;
  staticVehicleIconsPerColourPerInitials = {};

  markersGroup;
  globalIcon;
  markerSvg = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" height="45px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'white' + '" d="M28.1,163.4c-15.8-16.3-36.6-25.3-58.5-25.3c-46.8,0-84.8,41.1-84.8,91.6c0,13.3,2.5,25.9,7.4,37.2c12.9,30.9,48.3,84.6,68,109.5c2.3,2.9,5.7,4.6,9.4,4.6c3.5,0,6.7-1.5,9.1-4.2l0.1-0.2l0.1-0.2c19.5-24.5,54.8-77.9,68-109.4c4.9-11.9,7.3-24.4,7.3-37.1C54.4,204.7,45.1,180.9,28.1,163.4z M39.7,263.8c-12.5,29.9-47,82.5-66.8,107.4c-0.9,1-2,1.6-3.3,1.6s-2.5-0.6-3.3-1.6c-19.7-24.9-54.3-77.6-66.8-107.6c-4.5-10.5-6.7-21.9-6.7-34c0-46,34.5-83.5,76.9-83.5s76.9,37.4,76.7,83.7C46.4,241.9,44.1,253.4,39.7,263.8z"/>' +
    '<path fill="' + '#00aeba' + '" d="M-30.3,146.4c-42.4,0-76.9,37.4-76.9,83.5c0,12,2.2,23.4,6.7,34c12.5,30,47.1,82.6,66.8,107.6c0.8,1,2,1.6,3.3,1.6s2.4-0.6,3.3-1.6C-7.2,346.7,27.3,294,39.8,264c4.4-10.6,6.7-22.1,6.7-34C46.6,183.7,12.1,146.4-30.3,146.4z"/>' +
    '</g>' +
    '<text fill="white" style="font-size:75px; font-weight: 500; font-family: Roboto, sans-serif; cursor: default" x="-30" y="267" text-anchor="middle" alignement-baseline="middle">' + 'numberText' + '</text>' +
    '</svg>';

  constructor(
    public globals: Globals,
    private mapUtils: MapUtils,
  ) {
    this.platform = this.globals.platform;
  }

  createMarkerIcon(data) {
    const color = this.getRandomColor();
    const icon = this.markerSvg.replace('numberText', data.numberText).replace('#00aeba', color);
    // const icon = this.markerSvg.replace('numberText', data.numberText);
    return icon;
  }

  rem() {
    this.markersGroup.removeAll();

  }

  test() {
    // this.markersGroup.addObjects(this.markersToAdd);
    // console.log(this.markersGroup.getObjects().length);
  }

  getRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  // returns the driver icon with correct colours and initial
  getStaticVehicleIconWithColoursAndInitials(colour, driverName) {
    const initials = this.mapUtils.calculateDriverInitials(driverName);
    if (!this.staticVehicleIconsPerColourPerInitials[colour]) {
      this.staticVehicleIconsPerColourPerInitials[colour] = {};
    }
    if (!this.staticVehicleIconsPerColourPerInitials[colour][initials]) {
      const icon = this.svgIconsComponent.svgVehicleMaker.replace('markerColour', colour).replace('initials', initials);
      this.staticVehicleIconsPerColourPerInitials[colour][initials] = new H.map.DomIcon(icon)
      console.log("creating!");
    }
    return this.staticVehicleIconsPerColourPerInitials[colour][initials];
  }

  initMap(depotLat, depotLon) {
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 12,
        center: { lat: depotLat, lng: depotLon },
        // pixelRatio: window.devicePixelRatio || 1
      }
    );
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);

    const mapEvents = new H.mapevents.MapEvents(this.map);
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);

    this.markersGroup = new H.map.Group();
    this.map.addObject(this.markersGroup);
    this.markersToAdd = [];

    this.globalIcon = new H.map.Icon(this.markerSvg);

    this.count = 0;
    // const icon = new H.map.Icon('/assets/lastmilyAssets/driver-debug-icon.png');

    var lineString = new H.geo.LineString();
    const styleP = {
      lineWidth: 7,
      strokeColor: this.getRandomColor(),
    }

    for (let index = 0; index < 4; index++) {
      // var newLatitude1 = index * 0.0001 + depotLat;
      var newLatitude1 = depotLat + index * 0.01;
      var newLongitude1 = depotLon - index * 0.01;

      // var newLatitude2 = index * 0.0001 - depotLat;
      var newLatitude2 = depotLat - index * 0.01;
      var newLongitude2 = depotLon - index * 0.01;

      var newLatitude3 = depotLat - index * 0.01;
      var newLongitude3 = depotLon + index * 0.01;

      var newLatitude4 = depotLat + index * 0.01;
      var newLongitude4 = depotLon + index * 0.01;

      lineString.pushPoint({ lat: newLatitude1, lng: newLongitude1 });
      lineString.pushPoint({ lat: newLatitude2, lng: newLongitude2 });
      lineString.pushPoint({ lat: newLatitude3, lng: newLongitude3 });
      lineString.pushPoint({ lat: newLatitude4, lng: newLongitude4 });

      // const icon = this.globalIcon;
      // const icon = new H.map.Icon(this.createMarkerIcon({ numberText: newLatitude1.toString().slice(-1) }));
      const icon = this.getStaticVehicleIconWithColoursAndInitials(this.getRandomColor(), newLatitude1.toString().slice(-1));
      // const icon = new H.map.Icon('/assets/lastmilyAssets/driver-debug-icon.png');

      for (let i = 0; i < 1; i++) {
        // const icon = new H.map.Icon('/assets/lastmilyAssets/driver-debug-icon.png');
        // const icon = new H.map.Icon(this.createMarkerIcon({ numberText: newLatitude1.toString().slice(-1) }));

        var defaultMarker1 = new H.map.DomMarker(
          { lat: newLatitude1, lng: newLongitude1 },
          { icon: icon }
        );
        var defaultMarker2 = new H.map.DomMarker(
          { lat: newLatitude2, lng: newLongitude2 },
          { icon: icon }
        );
        var defaultMarker3 = new H.map.DomMarker(
          { lat: newLatitude3, lng: newLongitude3 },
          { icon: icon }
        );
        var defaultMarker4 = new H.map.DomMarker(
          { lat: newLatitude4, lng: newLongitude4 },
          { icon: icon }
        );

        this.markersToAdd.push(defaultMarker1);
        this.markersToAdd.push(defaultMarker2);
        this.markersToAdd.push(defaultMarker3);
        this.markersToAdd.push(defaultMarker4);
        this.count = this.count + 4;
      }
    }

    this.map.addObject(new H.map.Polyline(
      lineString, { style: style }
    ));
    // console.log(this.markersToAdd.length);
    this.markersGroup.addObjects(this.markersToAdd);
    console.log(this.count + ' stops in map');
    this.checkWhenDoneRendering();


  }

  checkWhenDoneRendering() {
    let timer = moment();
    const self = this;
    let timeOut;
    self.map.getEngine().removeEventListener('render', onRenderChange);
    self.map.getEngine().addEventListener('render', onRenderChange);

    function onRenderChange(evt) {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      if (self.map.getEngine() === evt.target) {
        timeOut = setTimeout(() => {
          self.timer = moment.duration(moment().diff(timer)).asSeconds();
          // console.log(moment.duration(moment().diff(timer)).asSeconds() + 's for rendering');
          self.map.getEngine().removeEventListener('render', onRenderChange);
        }, 500);
      }
    }

  }

  ngOnInit() { }

  public ngAfterViewInit() {
    this.initMap(40.70217122, 22.9304716);
  }

  ngOnDestroy() {
  }
}
