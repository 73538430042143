import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { DriverFormComponent } from '@app/forms/driver-form/driver-form.component';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GenericService } from '@app/services/generic.service';

@Component({
    selector: 'app-driver-modal',
    templateUrl: './driver-modal.component.html',
    styleUrls: ['./driver-modal.component.scss']
})
export class DriverModalComponent implements OnInit, OnDestroy {

    listen = [];

    driverId = null;
    actionType: String = '';
    formName: String = '';
    newLabel;
    editLabel;
    @ViewChild(DriverFormComponent, { static: false }) driverFormComponent: DriverFormComponent;
    @ViewChild('modal', { static: false, read: ElementRef }) modal: ElementRef;

    listenCloseRightModal;
    isModalOpen;

    constructor(
        public translate: TranslateService,
        private modalService: ModalService,
        private modalGridService: ModalGridService,
        public genericService: GenericService,
    ) {
        this.listen.push(this.modalService.listen().subscribe((response: any) => {
            this.toggleModal(response.form, response.type, response.id);
        }));
        this.listen.push(this.modalService.openDriverModalListen().subscribe((response: any) => {
            this.openModal();
        }));
        this.listenCloseRightModal = this.modalGridService.listenCloseRightModal().subscribe((response: any) => {
            this.closeModal();
        });
    }

    mapToggled(event) {
        if (event === 'expand') {
            this.modal.nativeElement.classList.add('expanded');
            this.modal.nativeElement.classList.remove('hide-map');
        } else {
            this.modal.nativeElement.classList.remove('expanded');
            this.modal.nativeElement.classList.add('hide-map');
        }
    }

    closeModal() {
        const modal = document.querySelector('.driver-modal');
        if (this.isModalOpen) {
            this.isModalOpen = false;
            const modalBackground = document.querySelector('.driver-modal-background');
            modal.classList.add('closed');
            modal.classList.remove('open');
            modalBackground.classList.add('hidden');
            this.modal.nativeElement.classList.remove('expanded');
            this.modal.nativeElement.classList.add('hide-map');
            this.driverFormComponent.resetForm();
        }
        this.driverId = null;
    }

    openModal() {
        this.genericService.comm100ZIndexFix();
        setTimeout(() => {
            this.isModalOpen = true;
        }, 1000);
        const modal = document.querySelector('.driver-modal');
        const modalBackground = document.querySelector('.driver-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
    }

    toggleModal(form, type, id) {
        if (form === 'driver') {
            // if modal is for edit
            if (type === 1) {
                this.driverId = id;
                this.driverFormComponent.getFormData(id);
                this.actionType = this.editLabel;
            } else if (type === 0 && !this.isModalOpen) {
                this.driverId = null;
                this.actionType = this.newLabel;
                this.openModal();
            }
            const modalBackground = document.querySelector('.driver-modal-background');
            modalBackground.classList.remove('hidden');
        }
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.listen.push(this.translate.get('GENERIC.EDIT').subscribe((res: string) => { this.editLabel = res; }));
            this.listen.push(this.translate.get('GENERIC.NEW').subscribe((res: string) => { this.newLabel = res; }));
        }));
        this.listen.push(this.translate.get('GENERIC.EDIT').subscribe((res: string) => { this.editLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.NEW').subscribe((res: string) => { this.newLabel = res; }));
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
