import { Component, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  template: `
    <ng-container #vcr></ng-container>
  `
})
export class LmToastHostComponent {
  @ViewChild('vcr', { static: true, read: ViewContainerRef }) vcr: ViewContainerRef;
}
