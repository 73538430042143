import { LmButtonConfig } from "@app/shared/structure/button/button.component";
import { PrimeIcons } from "primeng/api";

export const AddButtonBaseConfig: LmButtonConfig = {
    rounded: true,
    icon: PrimeIcons.PLUS,
    iconPos: 'left',
    label: 'New',
    small: true
}

export const BackButtonBaseConfig: LmButtonConfig = {
    rounded: true,
    icon: PrimeIcons.ARROW_LEFT,
    iconPos: 'left',
    label: 'Back',
    small: true
}