import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { LmBaseEntity } from '@app/model/base-entity';
import { BaseViewModelService } from '@app/core/services/viewmodel/base-viewmodel.service';
import { Observable } from 'rxjs';

@Injectable()
export abstract class LmFormViewModelService<TModel extends LmBaseEntity> extends BaseViewModelService<TModel> implements OnInit, OnDestroy {
    protected initializeCb = this.initialize.bind(this);
    protected getByIdCb = this.getById.bind(this);
    protected postCb = this.post.bind(this);
    protected putCb = this.put.bind(this);
    protected deleteCb = this.delete.bind(this);
    protected newEntryCb = this.newItem.bind(this);


  constructor(injector: Injector) {
    super(injector);
  }

  initialize(){};
  getById(id){}
  post(item){}
  put(id, item){}
  delete(id){}
  editInModal(){}
  newItem(item){}

  ngOnInit(): void {
    // super.ngOnInit()
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
