/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { IDriverDataDto } from '../models/drivers-dto';

@Injectable({
  providedIn: 'root'
})
export class LmDriversService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  // ------------------------- ADD DTOs ---------------------
  // GET BY ID
  static readonly driversGetPath = 'api/v1/drivers/{id}';
  static readonly driversPostPath = 'api/v1/drivers';
  static readonly driversPutPath = 'api/v1/drivers/{id}';
  static readonly driversDeletePath = 'api/v1/drivers/{id}';

  apiDriversGet$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmDriversService.driversGetPath, 'get');
    if (params) rb.path('id', params.id);

    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'application/json'
      }))
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        }
        )
      );
  }

  apiDriversGet(params?: { id?: string }): Observable<Array<any>> {
    return this.apiDriversGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

  // POST 
  apiDriversPost$Response(params?: { body?: any }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmDriversService.driversPostPath, 'post');
    if (params) rb.body(params.body, 'application/*+json');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        })
      );
  }


  apiDriversPost(params?: { body?: any, id?: string }): Observable<Array<any>> {
    return this.apiDriversPost$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

  // POST 
  apiDriversPut$Response(params?: { body?: any, id?: string }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmDriversService.driversPutPath, 'put');
    if (params) rb.path('id', params.id);
    if (params) rb.body(params.body, 'application/*+json');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        })
      );
  }


  apiDriversPut(params?: { body?: any, id?: string }): Observable<Array<any>> {
    return this.apiDriversPut$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

  // POST 
  apiDriversDelete$Response(params?: { id?: string }): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmDriversService.driversDeletePath, 'delete');
    if (params) rb.path('id', params.id);

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<any>>;
        })
      );
  }


  apiDriversDelete(params?: { body?: any, id?: string }): Observable<Array<any>> {
    return this.apiDriversDelete$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

}
