import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-wizard-welcome',
    templateUrl: './wizard-welcome.component.html',
    styleUrls: ['./wizard-welcome.component.scss']
})
export class WizardWelcomeComponent implements OnInit {

    constructor(
        private http: HttpClient,
        public globals: Globals,
    ) { }

    resetDemoState() {
        // const data = {
        //     company: {
        //         demo_state: 0,
        //     }
        // };
        // const myObserver = {
        //     next: (response) => { },
        //     error: (error) => {
        //         console.error(error);
        //     },
        //     complete: () => { },
        // };
        // const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        // this.http.put(companyUrl, data).pipe(take(1)).subscribe(myObserver);
    }

    submitWizardForm() { }

    ngOnInit() { }

}
