import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmCalendarComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmCalendarComponent) };

@Component({
  selector: 'lm-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LmCalendarComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  searchType = 'string';

  @HostBinding() id = `lm-calendar-${LmCalendarComponent.nextId++}`;

  @ViewChild(NgModel) model: NgModel;

  @Input() defaultDate?: Date;
  @Input() dateFormat = 'mm/dd/yy';
  @Input() selectionMode = 'single';
  @Input() style?: string;
  @Input() inline = false;
  @Input() showTime = false;
  @Input() timeOnly = false;
  @Input() showSeconds = false;
  @Input() stepHour = 1;
  @Input() stepMinute = 1;
  @Input() stepSecond = 1;
  @Input() hourFormat = '24';
  @Input() timeSeparator = ':';
  @Input() dataType = 'date';
  @Input() showOtherMonths = true;
  @Input() selectOtherMonths = true;
  @Input() showIcon = false;
  @Input() showOnFocus = true;
  @Input() showWeek = false;
  @Input() autoZIndex = true;
  @Input() appendTo?: any;
  @Input() readonlyInput?: boolean;
  @Input() shortYearCutoff = '+10';
  @Input() firstDayOfWeek = 0;
  @Input() baseZIndex = 0;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() maxDateCount?: number;
  @Input() disabledDates?: Array<Date>;
  @Input() disabledDays?: Array<number>;
  @Input() iconClass = '';
  @Input() iconSvgClass = '';
  @Input() iconSvg?: string;
  @Input() iconSize?: string;
  @Input() iconColor?: string;
  iconStyle: LmTextIconStyle;
  iconSvgPath: string;
  private svgsPath = 'assets/icons/icons.svg#'

  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.iconSvg) this.iconSvgPath = this.svgsPath + this.iconSvg;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule
  ],
  exports: [LmCalendarComponent],
  declarations: [LmCalendarComponent]
})
export class LMCalendarModule {}
