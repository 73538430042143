import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
    selector: 'app-dashboard-shipments-dummy',
    templateUrl: './dashboard-shipments-dummy.component.html',
    styleUrls: ['./dashboard-shipments-dummy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardShipmentsDummyComponent implements OnInit, AfterViewInit {

    @Input() showSampleDataText: Boolean;

    @Output() clickOnActivityEvent = new EventEmitter<string>();

    constructor() { }

    clickOnActivity(activityId) {
        this.clickOnActivityEvent.emit(activityId);
    }

    ngOnInit() { }

    ngAfterViewInit() { }
}
