import { CommonModule } from '@angular/common';
import { Component, NgModule, Type } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { LmNotificationInfo, LmToastPosition } from '../../../model/toast';
import { LmToastDefaultContentComponent } from './toast-default-content.component';
import { LmToastHostComponent } from './toast-host.component';
import { LmToastMultipleContentComponent } from './toast-multiple-content.component';

@Component({
  // selector: 'lm-toast',
  template: `
    <p-toast class="p-toast shadow-2 lm-toast-z-index" #toast [style]="style" [styleClass]="styleClass" [position]="position" [key]="id" (onClose)="closeFn()">
      <ng-template pTemplate="message" let-msg>
        <ng-container *ngComponentOutlet="toastContent; injector: msg.data"></ng-container>
      </ng-template>
    </p-toast>
  `,
  styles:[`
    :host ::ng-deep .p-toast-icon-close{position: absolute;left: auto; right: 10px; bottom: auto; top: 3px;color: #999;}
  `]
})
export class LmToastComponent {
  style: any = { 'white-space': 'pre-line', width: '360px', 'margin-top': ' 70px'};
  styleClass: string;
  position: LmToastPosition;
  id: string;
  toastContent: Type<unknown>;
  closeFn: (res?: unknown) => void;

  constructor(private _messageSvc: MessageService) {
  }

  showMessage(msg: LmNotificationInfo): void {
    this.id = msg.id;
    this.position = msg.position;
    this.toastContent = msg.contentType;

    setTimeout(() => {
      this._messageSvc.add({
        key: msg.id,
        life: msg.life,
        closable: msg.closable,
        sticky: msg.sticky,
        severity: msg.severity,
        data: msg.injector
      });
    });
  }

  close(res?: unknown): void {
    this._messageSvc.clear(this.id);
    this.closeFn(res);
  }

  setClose(close: (res?: unknown) => void): void {
    this.closeFn = close;
  }
}

@NgModule({
  imports: [CommonModule, ToastModule],
  declarations: [LmToastComponent, LmToastHostComponent, LmToastDefaultContentComponent, LmToastMultipleContentComponent],
  exports: [LmToastComponent, LmToastHostComponent, LmToastDefaultContentComponent, LmToastMultipleContentComponent]
})
export class LmToastModule {}
