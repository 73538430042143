import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { Globals } from '@app/services/globals';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';

@Component({
    selector: 'app-select-cell-editor',
    templateUrl: './vehicle-select-editor.component.html'
})
export class VehicleSelectEditorComponent implements OnInit {
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;

    listen = [];

    vehicleTypes = [];

    bicycleLabel = '';
    scooterLabel = '';
    carLabel = '';
    vanLabel = '';
    largeVanLabel = '';
    truckLabel = '';

    vehicles = [];
    driversVehicle = [];
    currentDriverId;

    constructor(
        private dataService: DataService,
        private http: HttpClient,
        public globals: Globals,
        private sanitizer: DomSanitizer,
        public translate: TranslateService,
        private projectProblemDataService: ProjectProblemDataService,
    ) { }

    onChangeSelect() { }

    public agInit(params: any): void {
        setTimeout(() => {
            this.setVehicleTypes();
            this.currentDriverId = params.data.driverId;
            this.getAvailableVehicles();
            this.driversVehicle = this.dataService.driversVehicle;

            this.vehicles.push(this.driversVehicle[this.currentDriverId]);
        }, 100);
    }

    getMake(vehicleData) {
        let make;
        if (vehicleData.vehicleInfoMake || vehicleData.vehicleInfoMakeCompany) {
            if (vehicleData.vehicleInfoMake) {
                make = vehicleData.vehicleInfoMake.name;
            } else if (vehicleData.vehicleInfoMakeCompany) {
                make = vehicleData.vehicleInfoMakeCompany.name;
            }
        } else {
            make = vehicleData.vehicle.make;
        }
        return make;
    }

    getModel(vehicleData) {
        let model;
        if (vehicleData.vehicleInfoMake || vehicleData.vehicleInfoMakeCompany) {
            if (vehicleData.vehicleInfoModel) {
                model = vehicleData.vehicleInfoModel.name;
            } else if (vehicleData.vehicleInfoModelCompany) {
                model = vehicleData.vehicleInfoModelCompany.name;
            }
        } else {
            model = vehicleData.vehicle.model;
        }
        return model;
    }

    public getValue(): any {
        const currentVehicleData = this.driversVehicle[this.currentDriverId];

        let vehicle = {};
        if (currentVehicleData) {
            const vehicleId = currentVehicleData.vehicle.id;
            const vehicleData = this.projectProblemDataService.vehicles[vehicleId];
            if (vehicleData) {
                if (vehicleData.vehicleInfoMake || vehicleData.vehicleInfoMakeCompany) {
                    let make, model;
                    if (vehicleData.vehicleInfoMake) {
                        make = vehicleData.vehicleInfoMake.name;
                    } else if (vehicleData.vehicleInfoMakeCompany) {
                        make = vehicleData.vehicleInfoMakeCompany.name;
                    }
                    if (vehicleData.vehicleInfoModel) {
                        model = vehicleData.vehicleInfoModel.name;
                    } else if (vehicleData.vehicleInfoModelCompany) {
                        model = vehicleData.vehicleInfoModelCompany.name;
                    }
                    vehicle = {
                        make: make,
                        model: model,
                        plate_number: vehicleData.vehicle.plate_number,
                        id: vehicleData.vehicle.id,
                        vehicle_type: vehicleData.vehicle.vehicle_type,
                        maximum_cargo_capacity: vehicleData.vehicle.maximum_cargo_capacity,
                    };
                } else {
                    vehicle = {
                        make: vehicleData.vehicle.make,
                        model: vehicleData.vehicle.model,
                        plate_number: vehicleData.vehicle.plate_number,
                        id: vehicleData.vehicle.id,
                        vehicle_type: vehicleData.vehicle.vehicle_type,
                        maximum_cargo_capacity: vehicleData.vehicle.maximum_cargo_capacity,
                    };
                }
            }
        }
        return vehicle;
    }

    getAvailableVehicles() {
        this.http.get('api/v1/vehicles').pipe(take(1)).subscribe(response => {
            this.vehicles = [];
            const vehicles = response['items'];
            vehicles.forEach(vehicle => {
                const id = vehicle.vehicle.id;
                const data = {
                    vehicle: {
                        id: id,
                        make: this.getMake(vehicle),
                        model: this.getModel(vehicle),
                        plate_number: vehicle.vehicle.plate_number,
                        vehicle_type: vehicle.vehicle.vehicle_type,
                        maximum_cargo_capacity: vehicle.vehicle.maximum_cargo_capacity,
                    },
                    driver: {
                        id: vehicle.driver.id,
                    }
                }
                this.vehicles.push(data);
            });
        });
    }

    setVehicleTypes() {
        const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
        const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
        const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
        const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
        const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
        const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

        this.vehicleTypes = [
            {
                index: 0,
                image: bicycle,
                typeName: this.bicycleLabel
            },
            {
                index: 1,
                image: scooter,
                typeName: this.scooterLabel
            },
            {
                index: 2,
                image: car,
                typeName: this.carLabel
            },
            {
                index: 3,
                image: van,
                typeName: this.vanLabel
            },
            {
                index: 4,
                image: largeVan,
                typeName: this.largeVanLabel
            },
            {
                index: 5,
                image: truck,
                typeName: this.truckLabel
            }
        ];
    }

    getTranslations() {
        this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));
    }

    ngOnInit(): void {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
