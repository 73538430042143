import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringify'
})
export class LmStringifyPipe implements PipeTransform {
  transform = (value: number | string | any): string => {
    if(!value) return null;
    const str = (t=(typeof value), fn={'number': _=>value.toString(), 'string': _=>value.toString(), 'object': _=>JSON.stringify(JSON.parse(value))}) => (fn[t]());
    return str() 
  }
}

@NgModule({
    imports: [],
    exports: [LmStringifyPipe],
    declarations: [LmStringifyPipe]
  })
  export class LmStringifyPipedModule {}