import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-button',
  templateUrl: './formButton.component.html',
  styleUrls: ['./formButton.component.scss']
})
export class FormButtonComponent {
  config;
  group: FormGroup;
}
