import { HttpErrorResponse } from '@angular/common/http';
import { Directive, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LmBaseEntity } from '@app/model/base-entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif, Observable, of, Subject } from 'rxjs';
import { filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { LmModelProxyService } from '../model-proxy/model-proxy.service';
import { I18N } from '@app/core/i18n/lm.el';
import { LmModelChangeArgs } from '@app/model/model-change-args';
import { LmModelChangingArgs } from '@app/model/model-changing-args';
import { SERVER_RESPONSE_VALIDATOR_NAME } from '@app/model/validator';
import { isApiResponse } from '@app/shared/utils';
import { LmIdGenerator, ID_GENERATOR } from '../id-generator/id-generator';
import { LmModelValidationService } from '../model-proxy/model-validation.service';
import { LmNotificationService } from '../notification.service';

const ID = 'id';
const NEW_URL = 'new';
const EDIT_URL = 'edit';

@UntilDestroy()
@Directive()
export abstract class BaseViewModelService<TModel extends LmBaseEntity> implements OnDestroy {
  model: TModel;
  isNew: boolean;
  validationsHeader = I18N.common.crud.alerts;
  modelProxySvc: LmModelProxyService<TModel>;
  modalConfig: any;

  get isBusy$(): Observable<boolean> {
    return this._isBusy$.asObservable();
  }

  get isDirty$(): Observable<boolean> {
    return this.modelProxySvc.isDirty$;
  }

  // abstract entityRoute: string;

  protected abstract initializeCb: () => Observable<null>;
  protected abstract newEntryCb: (entry: TModel) => Observable<TModel>;
  protected abstract getByIdCb: (id: string | number) => Observable<TModel>;
  protected abstract postCb: (item: TModel) => Observable<TModel>;
  protected abstract putCb: (id: string | number, item: TModel) => Observable<void>;
  protected abstract deleteCb: (id: string | number) => Observable<void>;
  

  protected router: Router;
  notificationSvc: LmNotificationService;
  protected route: ActivatedRoute;
  protected idGeneratorSvc: LmIdGenerator;
  protected modelValidationSvc: LmModelValidationService;
  protected handleSuccessInsert: () => Observable<null>;
  protected handleSuccessUpdate: () => Observable<null>;
  protected handleSuccessDelete: () => Observable<null>;

  protected _entryId: string;
  protected _isBusy$ = new Subject<boolean>();

  constructor(protected injector: Injector) {
    this.modelProxySvc = injector.get<LmModelProxyService<any>>(LmModelProxyService);
    this.router = injector.get<Router>(Router);
    this.notificationSvc = injector.get<LmNotificationService>(LmNotificationService);
    this.route = injector.get<ActivatedRoute>(ActivatedRoute);
    this.idGeneratorSvc = injector.get<LmIdGenerator>(ID_GENERATOR);
    this.modelValidationSvc = injector.get<LmModelValidationService>(LmModelValidationService, null);
  }

  init() {
    this.setupModelProxy();

    this.route.params
      .pipe(
        switchMap((params) => this.initializeCb().pipe(map(() => params))),
        untilDestroyed(this),
        tap((params) => {
          this._entryId = params[ID];
          this.isNew = this._entryId.toLowerCase() === NEW_URL;
        })
      )
      .subscribe({
        next: () => this.performReload()
      });
  }

  boot(){
    
  }

  ngOnDestroy() {
    this._isBusy$.complete();
  }

  performNew(_state?: {}) {
    // if (!this.isNew) {
    //   if (_state) this.router.navigate([`/${this.entityRoute}/${EDIT_URL}`, NEW_URL], { state: _state });
    //   else this.router.navigate([`/${this.entityRoute}/${EDIT_URL}`, NEW_URL]);
    // } else {
      of(null)
        .pipe(
          tap(() => this.emitIsBusy(true)),
          switchMap(() => this.newEntryInternal()),
          tap((entry) => {
            this.updateModel(entry, true);
          }),
          finalize(() => this.emitIsBusy(false)),
          first()
        )
        .subscribe({
          error: (err: HttpErrorResponse) => this.handleErrorInternal(err)
        });
    // }
  }

  performReload() {
    of(null)
      .pipe(
        tap(() => this.emitIsBusy(true)),
        switchMap(() => iif(() => this.isNew, this.newEntryInternal(), this.getByIdCb(this._entryId))),
        tap((res) => this.updateModel(res, true)),
        finalize(() => {
          this.emitIsBusy(false);
        }),
        first()
      )
      .subscribe({
        error: (err: HttpErrorResponse) => this.handleErrorInternal(err)
      });
  }

  performSave() {
    const isNew$ = of(null).pipe(
      tap(() => this.emitIsBusy(true)),
      switchMap(() => this.postCb(this.modelProxySvc.target)),
      tap(() => this.modelProxySvc.acceptChanges()),
      finalize(() => this.emitIsBusy(false)),
      switchMap(() => this.handleSuccessInsertInternal()),
      first()
    );

    const isExisting$ = of(null).pipe(
      tap(() => this.emitIsBusy(true)),
      switchMap(() => this.putCb(this.model.id, this.modelProxySvc.target)),
      tap(() => this.modelProxySvc.acceptChanges()),
      finalize(() => this.emitIsBusy(false)),
      switchMap(() => this.handleSuccessUpdateInternal()),
      first()
    );

    iif(() => this.isNew, isNew$, isExisting$).subscribe({
      error: (err: HttpErrorResponse) => this.handleErrorInternal(err)
    });
  }

  performDelete() {
    if (this.isNew) {
      return;
    }

    this.notificationSvc
      .showQuestion(I18N.common.confirmDeleteTitle, I18N.common.confirmDeleteOneMessage)
      .pipe(
        filter((res) => !!res),
        tap(() => this.emitIsBusy(true)),
        switchMap(() => this.deleteCb(this.model.id)),
        tap(() => this.modelProxySvc.acceptChanges()),
        switchMap(() => this.handleSuccessDeleteInternal()),
        finalize(() => this.emitIsBusy(false))
      )
      .subscribe({
        error: (err: HttpErrorResponse) => this.handleErrorInternal(err)
      });
  }

  editItemInModal() {
    this.notificationSvc.showDialog<void>(this.modalConfig).subscribe();
  }

  createModelSnapshot(): TModel {
    return this.modelProxySvc.target;
  }

  restoreModelSnapshot(snapshot: TModel): void {
    this.updateModel(snapshot, false);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.modelProxySvc.hasChanges()) {
      return true;
    }
    // EDO
    return this.notificationSvc.showQuestion(I18N.common.confirmNavigateAwayTitle, I18N.common.confirmNavigateAwayMessage, I18N.common.ok, I18N.common.ok);
  }

  protected modelChanging(change: LmModelChangingArgs<TModel>): boolean | Observable<boolean> {
    return true;
  }

  protected modelChanged(change: LmModelChangeArgs<TModel>): void {}

  protected detailAdded(change: LmModelChangeArgs<TModel>): void {}

  protected detailRemoved(change: LmModelChangeArgs<TModel>): void {}

  protected newEntryInternal(): Observable<TModel> {
    return of({
      id: this.idGeneratorSvc.newId()
    } as any).pipe(switchMap((entry) => this.newEntryCb(entry)));
  }

  protected handleErrorInternal(err: HttpErrorResponse): Observable<null> {
    if (this.modelValidationSvc) {
      this.modelValidationSvc.execValidatorWithTarget(this.model, err.error, SERVER_RESPONSE_VALIDATOR_NAME, true);
      this.modelValidationSvc.setValid(true);
    } else {
      let message: string;

      if (isApiResponse(err.error)) {
        message = err.error.messages.map((msg) => msg.message).join('\n');
      } else {
        message = err.error.Message || err.error.title;
      }

      // EDO
      this.notificationSvc.showError(I18N.common.unhandledError, 'ERROR');
    }

    return of(null);
  }

  protected handleSuccessInsertInternal(): Observable<null> {
    // EDO
    
    // const handleSuccessInsert$ = of(null).pipe(tap(() => this.router.navigate([`/${this.entityRoute}/${EDIT_URL}`, this.modelProxySvc.target.id])));
    this.notificationSvc.showSuccess(I18N.common.crud.saveSuccessTitle, I18N.common.crud.saveSuccessOneMessage);
    return this.handleSuccessInsert ? this.handleSuccessInsert() : of(null);
  }

  protected handleSuccessUpdateInternal(): Observable<any> {
    // EDO
    // this.isNew = false;
    // const handleSuccessUpdate$ = of(null).pipe(tap(() => this.performReload()));
    // return this.handleSuccessUpdate ? this.handleSuccessUpdate() : handleSuccessUpdate$;
    this.isNew = false;
    this.notificationSvc.showSuccess(I18N.common.crud.editSuccessTitle, I18N.common.crud.editSuccessOneMessage);
    return this.handleSuccessUpdate ? this.handleSuccessUpdate() : of(null)
  }

  protected handleSuccessDeleteInternal(): Observable<any> {
    // EDO
    // const handleSuccessDelete$ = of(null).pipe(tap(() => this.router.navigate([`/${this.entityRoute}/${EDIT_URL}`, NEW_URL])));
    // return this.handleSuccessDelete ? this.handleSuccessDelete() : handleSuccessDelete$;

    this.notificationSvc.showSuccess(I18N.common.crud.deleteSuccess, I18N.common.crud.deleteSuccessOneMessage);
    return this.handleSuccessDelete ? this.handleSuccessDelete() : of(null)
  }

  protected setupModelProxy() {
    this.modelProxySvc.modelChanged = this.modelChanged.bind(this);
    this.modelProxySvc.modelChanging = this.modelChanging.bind(this);
    this.modelProxySvc.detailAdded = this.detailAdded.bind(this);
    this.modelProxySvc.detailRemoved = this.detailRemoved.bind(this);
  }

  protected updateModel(target: TModel, resetValidations: boolean) {
    if (resetValidations && this.modelValidationSvc) {
      this.modelValidationSvc.removeValidations();
    }
    this.modelProxySvc.setTarget(target);
    this.model = this.modelProxySvc.proxy;
  }

  protected emitIsBusy(isBusy: boolean): void {
    this._isBusy$.next(isBusy);
  }
}
