import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment-timezone';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-wizard-stop-settings',
    templateUrl: './wizard-stop-settings.component.html',
    styleUrls: ['./wizard-stop-settings.component.scss']
})
export class WizardStopSettingsComponent implements OnInit {

    @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;

    myForm: FormGroup;

    durationUnformatted = '04:00';
    durationSeconds = [120];
    duration = '';
    durationOptions;

    defaultLoad = null;

    portalAccess = 0;
    portalAccessDefault = 0;
    portalOn = false;

    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private dataService: DataService,
        private settingsService: SettingsService,
        public translate: TranslateService,
        public globals: Globals,
        private dateTimeCalculatorService: DateTimeCalculatorService,
    ) {
        this.initializeSlider();
        this.myForm = formBuilder.group({
            'company': formBuilder.group({
                // 'id': [this.companyId],
            }),
            'companySettings': formBuilder.group({
                'default_stop_point_duration': [this.duration],
                'durationSeconds': [this.durationSeconds],
                'default_stop_point_delivery_load': [this.defaultLoad],
                'default_stop_point_pickup_load': [this.defaultLoad],
                'portal_access': [this.portalAccessDefault],
                'portal_access_sw': [this.portalOn],
            }),
        });
    }

    patchFormForLoad() {
        this.myForm.patchValue({
            'company': {
            },
            'companySettings': {
                'durationSeconds': this.durationSeconds,
                'default_stop_point_duration': this.duration,
                'default_stop_point_delivery_load': this.defaultLoad,
                'default_stop_point_pickup_load': this.defaultLoad,
                'portal_access': this.portalAccessDefault,
                'portal_access_sw': this.portalOn,
                'portalOnDefault': this.portalOn,
            },
        });
        M.updateTextFields();
    }

    patchForm() {
        this.myForm.patchValue({
            'companySettings': {
                'default_stop_point_duration': this.duration,
                'portal_access': this.portalAccessDefault,
            },
        });
        M.updateTextFields();
    }

    loadData() {
        const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        this.http.get(companyUrl).pipe(take(1)).subscribe(response => {
            const data = response['items'];

            this.portalOn = data.companySettings.portal_access_sw;
            this.portalAccessDefault = data.companySettings.portal_access;

            this.defaultLoad = data.companySettings.default_stop_point_delivery_load;

            if (data.companySettings.default_stop_point_duration) {
                this.duration = data.companySettings.default_stop_point_duration;
                this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
                this.durationSeconds = [moment.duration(this.duration).asSeconds()];
            }

            this.patchFormForLoad();
        });

    }

    initializeSlider() {
        this.durationOptions = {
            step: 15,
            start: [120],
            connect: 'lower',
            range: {
                min: 0,
                max: 1800
            },
        };
    }

    convertSecondsToTime() {
        let minutes = 0, seconds = 0;
        minutes = this.convertToHour(this.myForm.value.companySettings.durationSeconds);
        seconds = this.convertToMinute(this.myForm.value.companySettings.durationSeconds, minutes);
        this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
    }

    convertToHour(value) {
        return Math.floor(value / 60);
    }

    convertToMinute(value, hour) {
        return value - hour * 60;
    }

    formatHoursAndMinutes(hours, minutes) {
        if (hours.toString().length === 1) { hours = '0' + hours; }
        if (minutes.toString().length === 1) { minutes = '0' + minutes; }
        return hours + ':' + minutes;
    }

    scrollFix() {
        // window.scrollTo(0, 0);
    }

    submitWizardForm() {
        const companyFormValues = this.myForm.value.company;
        const companySettingsFormValues = this.myForm.value.companySettings;

        if (!this.myForm.value.companySettings.default_stop_point_delivery_load) {
            this.defaultLoad = 0;
            this.myForm.patchValue({
                'companySettings': {
                    'default_stop_point_delivery_load': this.defaultLoad,
                    'default_stop_point_pickup_load': this.defaultLoad,
                },
            });
        }

        let durationSeconds;
        if (Array.isArray(companySettingsFormValues.durationSeconds)) {
            durationSeconds = companySettingsFormValues.durationSeconds[0];
        } else {
            durationSeconds = companySettingsFormValues.durationSeconds;
        }
        this.duration = moment.duration(durationSeconds, 'seconds').toISOString();

        if (companySettingsFormValues.portal_access_sw) {
            this.portalAccessDefault = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
        } else {
            this.portalAccessDefault = this.globals.portalAccessConstants['NO_ACCESS'];
        }

        this.patchForm();
        const myObserver = {
            next: (response) => { },
            error: (error) => {
                console.error(error);
            },
            complete: () => { },
        };

        const companyUrl = 'api/v1/companies/' + this.globals.companyId;
        this.http.put(companyUrl, this.myForm.value).pipe(take(1)).subscribe(myObserver);
    }

    ngOnInit() {
        this.loadData();

        const departureTimeElement = document.querySelectorAll('.timepicker');
        const timeInstances = M.Timepicker.init(departureTimeElement, {
            twelveHour: false
        });
    }

}
