// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        lm-doughnut-chart{width: 100%}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvdWhudXRjaGFydC13aWRnZXQuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7UUFDUSxrQkFBa0IsV0FBVyIsImZpbGUiOiJkb3VobnV0Y2hhcnQtd2lkZ2V0LmNvbXBvbmVudC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgICBsbS1kb3VnaG51dC1jaGFydHt3aWR0aDogMTAwJX1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/structure/widgets/douhnutchart-widget.component.ts"],"names":[],"mappings":";QACQ,kBAAkB,WAAW;;AAErC,gVAAgV","sourcesContent":["\n        lm-doughnut-chart{width: 100%}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
