import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { BehaviorSubject, mergeWith, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Globals } from '@app/services/globals';
import { INITIAL_DATA } from '@app/model/initial-data';
import { ColourService } from '@app/services/colour.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LmReportsApiService } from '@app/api/services/reports-services.service';
import { IPeriodFormatter, IPeriodProcessor, Period } from '@app/core/services/period-processor';
import { ImageUtils } from '@app/utils/image-utils';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmCalendarComponent } from '@app/shared/containers/calendar-dialog/calendar-dialog.component';
import { ILmChartWidgetTab } from '@app/model/widget';
import { SHIPMENTS as sc, PRODUCTIVITY as pc, VERTICAL as vc, VERTICAL_OPTIONS as vco, DOUGHNUT as dc, DRIVERS as drc, BRANCHES as brc, SEMICIRCLE as smc, GENERAL_PERFORMANCE, DELIVERIES_OVERVIEW, EXPERIENCE_OVERVIEW, PRODUCTIVITY_OVERVIEW, SHIPMENTS_OVERVIEW} from './dashboard-report-dummy-data';
import { ChartData, ChartDataset } from 'chart.js';


export type LmReportsQueryFragmentType = Date | string | number[];

export type LmDashboardReportQueryType = {
    untilDateTime: Date | string;
    daysBeforeUntilDateCount: number;
    projectId?: number[];
    shipment_type?: number[];
}
@UntilDestroy()
@Injectable()
export class DashboardReportViewModelService implements OnInit, OnDestroy {
    Period: IPeriodProcessor;
    Formatter: IPeriodFormatter;
    query: LmDashboardReportQueryType;

    shipments = new BehaviorSubject<ILmChartWidgetTab[]>([...sc]);
    drivers: ILmChartWidgetTab[];
    cancelation: ChartData | any;

    logoBase64 = '';
    // calendarComponent = LmCalendarComponent;
    
    constructor(
        private injector: Injector, 
        @Inject(forwardRef(() => LmReportsApiService)) public apiSvc: LmReportsApiService,
        public globals: Globals
    ){
        
        this.drivers = [...drc];
        this.cancelation = {...vc};

        this.Period = Period();
        this.Formatter = this.Period.formatter;

        const {start, end} = this.Period;
        const days = this.Formatter.countDaysBetween(start, end)

        this.query = {
            untilDateTime: end,
            daysBeforeUntilDateCount: days,
            projectId: [],
            shipment_type: [1,2,3]
        }
    }


    getOverview(_query): Observable<any>{
        return this.apiSvc.apiReportsOverviewGet(_query)
            .pipe(
                tap(res => {
                    // console.log('overview:', res)
                })
            );
    }

    getShipments(_query): Observable<any>{
        return this.apiSvc.apiReportsShipmentsGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                const shipments = [...sc];
                const labels = Object.keys(res);
                const values = Object.values(res);
                const ids = shipments.map(({id}) => id)

                
                const sets = values
                    .map((v:any) => Object.keys(v).map(key => ({[key]: [v[key]]})))
                    .reduce((nu, v, i) => [...nu, { [ids[i]]: (values.map((_v:any) => _v[ids[i]])) }], []);


                shipments.map(ship => {
                    ship.data.labels = labels;
                    const {id,data:{datasets:ds}, filters:fl} = ship;
                    const set = ((sets.find(set => Object.keys(set)[0] === id) ?? {})[id]) ?? [];
                            
                    if(['on_time_deliveries', 'first_attempt_success_rate', 'total_miles','total_co2_commissions'].includes(<string>id)) ship.data.datasets[0].data = set;
                   
                    if(id === 'shipments_in_total') ship.data.datasets = ds.map(dts => ({...dts, data:[...set.map(s => s[dts.label])]})) ;
                   
                    if(id === 'deliveries_and_pickups') {
                        const deliveries = set.map(s => s['deliveries']);
                        const pickups = set.map(s => s['pickups']);
                        const tracking = set.map(s => s['live_tracking']);
                        const filters = set.map(s => s['percentages'])
                        console.log('filters:', filters)

                        ship.data.datasets = ds.map(dts => {
                            const {stack, label} = dts;
                            if(stack === 'deliveries'){
                                if(label === 'success') dts.data = deliveries.map(d => d['success']);
                                if(label === 'total') dts.data = deliveries.map(d => d['total']);
                            }
                            if(stack === 'pickups'){
                                if(label === 'success') dts.data = pickups.map(d => d['success']);
                                if(label === 'total') dts.data = pickups.map(d => d['total']);
                            }
                            if(label === 'live_tracking') dts.data = tracking
                            return dts;
                        });

                        ship.filters[0].data.datasets.map(fl =>{
                            console.log('fl:', fl)

                            if(fl.label === 'deliveries') fl.data = filters.map(f => f['deliveries']);
                            if(fl.label === 'pickups') fl.data = filters.map(f => f['pickups']);
                            return fl
                        })
                    }
                    
                    })
                    
                    this.shipments.next(shipments)
            })
        );
    }


    // getProductivity(query): Observable<any>{
    //     return of(null)
    // }

    getDrivers(_query): Observable<any>{
        return this.apiSvc.apiReportsDriversGet(_query).pipe(
            switchMap(res => of((<any>res).items)),
            tap(res => {
                // console.log('drivers:', res)
            })
        );
    }
    getCancelled(_query): Observable<any>{
        return this.apiSvc.apiCancelationGet(_query).pipe(
            tap(res => {
                // console.log('cancelled:', res)
            })
        );
    }
    // getBranches(): Observable<any>{
    //     return of(null)
    // }
    // getExperience(): Observable<any>{
    //     return of(null)
    // }
    // getLive(): Observable<any>{
    //     return of(null)
    // }
    // getActivity(): Observable<any>{
    //     return of(null)
    // }
    ngOnInit(): void {
    }

    ngOnDestroy() {
    }

    fragmentRequest$ = ({label}) => {
        return of(null).pipe(
            switchMap(_=> {
                this.query = { 
                    ...this.query,
                    daysBeforeUntilDateCount: this.Formatter.countDaysBetween(this.Period[label], this.Period.end)
                }

                return this.getAll$(this.query)
            })
        ) 
    }

    getAll$(query){
        return of(null).pipe(
            mergeWith(
            //   this.getOverview(query),
              this.getShipments(query),
            //   this.getDrivers(query),
            //   this.getCancelled(query)
            )
        ) 
    }


    getLogo(imageHash) {
        const _src = 'data:image/png;base64,LOGO_HASH';
        // if (this.globals.collaboratorModeEnabled) {
        //     const hash = this.globals.portalSettings['live_tracking_image_hash'];
        //     this.http.get('api/v1/image-cloud?imageHashKey=' + hash).pipe(take(1)).subscribe(response => {
        //         this.logoBase64 = this._src.replace('LOGO_HASH', response['items']);
        //     });
        // } else {
        //     this._imageUtils.fetchImagesViaHashes(`api/internal/v1/images/company-logo`, [imageHash]).then(images => {
        //         this.logoBase64 = this._src.replace('LOGO_HASH', images[0]);
        //     });
        // }
    }


}
