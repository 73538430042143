import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { SettingsService } from '@app/services/settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Globals } from '@app/services/globals';
import { AddressService } from '@app/services/address.service';
import { GenericService } from '@app/services/generic.service';
import * as libphonenumber from 'libphonenumber-js';

declare var H: any;

@Component({
  selector: 'app-depot-modal',
  templateUrl: './depot-modal.component.html',
  styleUrls: ['./depot-modal.component.scss']
})
export class DepotModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  private platform: any;
  @ViewChild('map', { static: false }) public mapElement: ElementRef;

  map;
  ui;
  behavior;
  rightClickOnMap;
  draggableMarkerGroup = new H.map.Group();
  draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
  positionSet = false;

  errors = [];
  isClickedOnce = false;
  myForm: FormGroup;
  listen = [];

  depotId = null;
  title = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  companyDepotIdentificationNumber = '';
  addressType = 7;
  freeformAddress = '';
  country = 'GR';
  lat = '';
  lon = '';

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();
  selectedAddress: any = <any>[];

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    formBuilder: FormBuilder,
    private dataService: DataService,
    public globals: Globals,
    private addressService: AddressService,
    public genericService: GenericService,
  ) {
    this.platform = this.globals.platform;
    this.listen.push(this.settingsService.openDepotModalListen().subscribe((depotData) => {
      if (depotData) {
        this.loadDepotData(depotData);
      }
      this.openDepotModal();
    }));
    this.myForm = formBuilder.group({ 
      'companyDepot': formBuilder.group({
        'id': [this.depotId],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'telephone': [this.telephone],
        'company_depot_identification_number': [this.companyDepotIdentificationNumber],
        'address': formBuilder.group({
          'countryCode': [this.countryCode],
          'state': [this.state],
          'county': [this.county],
          'city': [this.city],
          'district': [this.district],
          'street': [this.street],
          'street2': [this.street2],
          'houseNumber': [this.houseNumber],
          'postalCode': [this.postalCode],
          'isPlace': [this.isPlace],
          'placeName': [this.placeName],
          'value': [this.freeformAddress],
          'lat': [this.lat],
          'lon': [this.lon],
          'label': [this.title],
          'term': [this.selectedAddress],
        }),
      }),
    });
  }

  inputFocusOut() {
    if (!this.myForm.value.companyDepot.address.term.timeZone) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }``
      }
    }
  }

  inputAddress() {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'companyDepot': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });
    this.ngSelect.filter((<HTMLInputElement>document.getElementById('depot-custom-input')).value);
  }

  onAddressChange() {
    const addressFormValue = this.myForm.value.companyDepot.address.term;
    (<HTMLInputElement>document.getElementById('depot-custom-input')).value = addressFormValue.label;
    if (addressFormValue) {
      this.lat = addressFormValue.position[0];
      this.lon = addressFormValue.position[1];
      this.freeformAddress = addressFormValue.label;
      this.showDraggableMarker(Number(this.lat), Number(this.lon));
      this.setAddress(addressFormValue.address);
    }
  }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    this.patchAddresses();
  }

  patchAddresses() {
    this.myForm.patchValue({
      'companyDepot': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'lat': this.lat,
          'lon': this.lon,
          'value': this.freeformAddress,
        },
      },
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'companyDepot': {
        'telephone': this.telephone,
        'company_depot_identification_number': this.companyDepotIdentificationNumber,
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('depot-custom-input')).value = this.freeformAddress.valueOf();
    }
  }

  setForm() {
    this.myForm.setValue({
      'companyDepot': {
        'id': this.depotId,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'telephone': this.telephone,
        'company_depot_identification_number': this.companyDepotIdentificationNumber,
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'label': this.title,
          'term': this.selectedAddress,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('depot-custom-input')).value = this.freeformAddress.valueOf();
    }
  }

  loadDepotData(data) {
    this.positionSet = true;
    this.depotId = data.id;
    this.telephone = data.telephone;
    this.companyDepotIdentificationNumber = data.company_depot_identification_number;
    
    if (data.telephone === 'n/a') { data.telephone = ''; }
    if (data.telephone) {
      this.phoneNumber = this.telephone;
      if (data.telephone.length > 5) {
        const phoneObj = libphonenumber.parsePhoneNumber(data.telephone);
        this.phoneNumber = phoneObj.nationalNumber;
        this.countryPrefix = '+' + phoneObj.countryCallingCode;
      }
    }
    this.title = data.address.label;
    this.setAddress(data.address);
    this.showDraggableMarker(this.lat, this.lon);
    this.setForm();
  }

  public submitDepotForm() {
    const formValue = this.myForm.value.companyDepot;
    const addressFormValue = this.myForm.value.companyDepot.address.term;
    this.isClickedOnce = true;
    this.errors = [];
    if (formValue.phoneNumber) {
      this.telephone = formValue.countryPrefix + formValue.phoneNumber;
    }
    if (formValue.company_depot_identification_number) {
      this.companyDepotIdentificationNumber = formValue.company_depot_identification_number;
    }
    if (!this.positionSet) {
      if (addressFormValue.customer) {
        this.lat = addressFormValue.customer.address.lat;
        this.lon = addressFormValue.customer.address.lon;
        this.freeformAddress = this.addressService.getAddressLabel(addressFormValue.customer.address);
      } else {
        this.lat = addressFormValue.position[0];
        this.lon = addressFormValue.position[1];
        this.freeformAddress = addressFormValue.label;
      }
    }

    this.patchForm();
    const myObserver = {
      next: (response) => {
        this.closeDepotModal();
      },
      error: (error) => {
        const errorsObject = error.error.errors;
        const errors = [];
        const keyify = (obj, prefix = '') =>
          Object.keys(obj).reduce((res, el) => {
            if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
              errors.push(obj[el]['0']);
            }
            if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
              if (obj[el]['0']['load']) {
                errors.push(obj[el]['0']['load']['0']);
              }
            }
            if (Array.isArray(obj[el])) {
              return res;
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
              return [...res, ...keyify(obj[el], prefix + el + '.')];
            } else {
              return [...res, prefix + el];
            }
          }, []);
        const output = keyify(errorsObject);
        this.errors = errors;
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.emptyDepotModal();
        this.settingsService.updateDepotsGrid();
      },
    };
    const depotsUrl = 'api/v1/company/depots';
    const editUrl = depotsUrl + '/' + this.depotId;

    if (this.depotId) {
      this.http.put(editUrl, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
    } else {
      this.http.post(depotsUrl, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
    }
  }

  emptyDepotModal() {
    this.positionSet = false;
    this.errors = [];
    this.depotId = null;
    this.title = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.companyDepotIdentificationNumber = '';
    this.selectedAddress = null;
    this.freeformAddress = '';
    this.lat = '';
    this.lon = '';
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    (<HTMLInputElement>document.getElementById('depot-custom-input')).value = '';
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    this.removeDraggableMarker();
  }

  openDepotModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.depot-modal');
    const modalBackground = document.querySelector('.depot-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
  }

  closeDepotModal() {
    const modal = document.querySelector('.depot-modal');
    const modalBackground = document.querySelector('.depot-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyDepotModal();
  }

  centerToMarker(bounds) {
    const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
    this.map.getViewModel().setLookAtData({ bounds: newBounds }, true);
  }

  showDraggableMarker(lat, lng) {
    const self = this;
    this.draggableMarker.setGeometry({ lat: lat, lng: lng });
    this.draggableMarker.draggable = true;
    this.draggableMarkerGroup.addObjects([this.draggableMarker]);

    const bounds = this.draggableMarkerGroup.getBoundingBox();
    this.centerToMarker(bounds);

    // disable the default draggability of the underlying map
    // when starting to drag a marker object:
    // this.map.addEventListener('dragstart', function (ev) {
    //     const target = ev.target;
    //     if (target instanceof H.map.Marker) {
    //         self.behavior.disable();
    //     }
    // }, false);
    self.map.addEventListener('dragstart', function (ev) {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        const targetPosition = self.map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        self.behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    this.map.addEventListener('dragend', function (ev) {
      const target = ev.target;
      if (target instanceof H.map.Marker) {
        self.behavior.enable();
        self.positionSet = true;
        self.freeformAddress = self.selectedAddress.label;
        self.lat = target.getGeometry()['lat'];
        self.lon = target.getGeometry()['lng'];
      }
    }, false);

    // Listen to the drag event and move the position of the marker
    // as necessary
    // this.map.addEventListener('drag', function (ev) {
    //     const target = ev.target,
    //         pointer = ev.currentPointer;
    //     if (target instanceof H.map.Marker) {
    //         target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY));
    //     }
    // }, false);
    this.map.addEventListener('drag', function (ev) {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(self.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
      }
    }, false);
  }

  removeDraggableMarker() {
    if (this.draggableMarkerGroup.contains(this.draggableMarker)) {
      this.draggableMarkerGroup.removeObject(this.draggableMarker);
    }
  }

  initMap(depotLat, depotLon) {
    const self = this;
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 12,
        center: { lat: depotLat, lng: depotLon }
      }
    );
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    const mapEvents = new H.mapevents.MapEvents(this.map);

    this.rightClickOnMap = function (event) {
      const coord = self.map.screenToGeo(event.viewportX, event.viewportY);
      self.positionSet = true;
      self.lat = coord.lat;
      self.lon = coord.lng;
      self.isClickedOnce = true;
      self.http.get(`api/v1/search/reverse-locations?searchQuery=${self.lat},${self.lon}`).pipe(take(1)).subscribe(location => {
        self.isClickedOnce = false;
        if (location['data']['addresses']['items'].length) {
          self.setAddress(location['data']['addresses']['items'][0]['address']);
        } else {
          self.freeformAddress = 'Address';
          self.country = 'GR';
        }
        (<HTMLInputElement>document.getElementById('depot-custom-input')).value = self.freeformAddress;
      });
      self.showDraggableMarker(Number(coord.lat), Number(coord.lng));
    };
    
    this.map.addEventListener('contextmenu', this.rightClickOnMap);
    // Instantiate the default behavior, providing the mapEvents object:
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const mapSettings = this.ui.getControl('mapsettings');
    mapSettings.setAlignment('top-left');
    this.map.addObject(this.draggableMarkerGroup);
  }

  ngOnInit() {
    this.countryPrefix = this.globals.defaultCountryCode;
    this.myForm.patchValue({
      'companyDepot': {
        'countryPrefix': this.countryPrefix,
      }
    });

    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddresses(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );
  }

  public ngAfterViewInit() {
    const self = this;
    const dataRefreshIntervalId = setInterval(dataChecker, 200);
    function dataChecker() {
      if (self.globals.depotsDataDone) {
        clearInterval(dataRefreshIntervalId);
        const depotLat = self.globals.currentLat;
        const depotLon = self.globals.currentLon;
        self.initMap(depotLat, depotLon);
      }
    }
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
