import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { CustomerInvoicePrintComponent } from '@app/prints/customer-invoice-print/customer-invoice-print.component';
import { Globals } from '@app/services/globals';
import { CustomerVouchersPrintGridComponent } from '@app/grids/customer-vouchers-print-grid/customer-vouchers-print-grid.component';
import { Router } from '@angular/router';
import { CollaboratorService } from '@app/services/collaborator.service';
import { take } from 'rxjs/operators';
import { InvoiceCustomDateModalComponent } from '../invoice-custom-date-modal/invoice-custom-date-modal.component';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-customer-invoice-modal',
  templateUrl: './customer-invoice-modal.component.html',
  styleUrls: ['./customer-invoice-modal.component.scss']
})
export class CustomerInvoiceModalComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerInvoicePrintComponent, { static: false }) customerInvoicePrintComponent: CustomerInvoicePrintComponent;
  @ViewChild(CustomerVouchersPrintGridComponent, { static: false }) customerVouchersPrintGridComponent: CustomerVouchersPrintGridComponent;
  @ViewChild(InvoiceCustomDateModalComponent, { static: false }) invoiceCustomDateModalComponent: InvoiceCustomDateModalComponent;

  listen = [];

  dataLoading = false;

  // values / labels
  invoiceId;
  invoiceHash;
  isPartnerInvoice;
  invoiceName;
  invoiceStatus;
  invoiceStatusLabel;
  invoiceAmountDue;
  invoiceDueDate;
  invoiceCreatedDate;
  invoiceSentDate
  invoiceIssuedByDate;
  invoiceSentByDate;
  invoiceReminderType;
  invoiceReminderNumberOfDays;
  isReminderCustomEnabled;
  isReminderThreeDaysEnabled;
  isReminderSevenDaysEnabled;
  isReminderFourteenDaysEnabled;
  isReminderDueDateEnabled;
  reminderCustomId = null;
  reminderThreeDaysId = null;
  reminderSevenDaysId = null;
  reminderFourteenDaysId = null;
  reminderDueDateId = null;
  reminderCustomDate;
  reminderDueDate;

  collaboratorId;
  sentLabel;
  viewedLabel;
  paidLabel;
  overdueLabel;
  createdLabel;
  approvedLabel;
  currency;
  issuedByMily;
  issuedByYou;
  createdBy;
  createdByOnDate;
  sentBy;
  sentDateBy;
  paidAmount;
  notSentYetLabel;
  invoiceSendWarningMessage;
  linkCopiedLabel = "";

  invoiceData;

  paymentsReceived;
  paymentDates = [];

  setPaymentsData(data) {
    this.paymentsReceived = data.payments;
    this.paymentDates.push(moment(data.creation_datetime).format('DD/MM/YYYY'));
  }

  // titles
  amountDueTitle;
  dueDateTitle;
  scheduleRemindersTitle;
  sendInvoiceTitle;
  getPaidTitle;
  paymentsReceivedTitle;
  paidOnTitle;
  paidTitle;
  sendReceiptLinkTitle;
  editLinkTitle;
  cloneLinkTitle;
  removeLinkTitle;
  sendActionLabel;
  resendActionLabel;
  sendResendActionLabel;
  sendDateValue;

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    public translate: TranslateService,
    private modalGridService: ModalGridService,
    public globals: Globals,
    public router: Router,
    private collaboratorService: CollaboratorService,
    public genericService: GenericService,
  ) {
    this.listen.push(this.modalService.updateInvoiceDateListen().subscribe(date => {
      this.reminderCustomDate = date;
      this.updateRemindersAndReloadModal('custom date');
    }));
    this.listen.push(this.modalService.openInvoiceModalListen().subscribe((data: number) => {
      this.loadInvoiceModal(data);
      this.openModal();
    }));
    this.listen.push(this.modalGridService.listenCloseRightModal().subscribe((response: any) => {
      this.closeModal();
    }));
  }

  loadInvoiceModal(data) {
    let hasBeenSent;
    let hasBeenPaid;

    this.invoiceData = data;
    this.invoiceId = data.id;
    this.isPartnerInvoice = data.is_partner_invoice;
    this.invoiceHash = data.hash;
    this.collaboratorId = data.collaborator_id;
    this.invoiceName = this.collaboratorService.generateIdWithZeros(data.id);
    this.invoiceAmountDue = data.left_payment_amount + this.currency;
    this.invoiceDueDate = moment(data.due_on_datetime).format('DD/MM/YYYY');
    this.invoiceStatus = data.status;
    this.invoiceCreatedDate = moment(data.creation_datetime).format('DD/MM/YYYY @ hh:mm');
    this.paidAmount = data.paid;
    this.invoiceSentDate = '';
    let invoiceIssuedBy = data.issued_by;
    let invoiceSentBy = data.sent_by;

    if (data.sent_datetime && data.sent_by) {
      this.invoiceSentDate = moment(data.sent_datetime).format('DD/MM/YYYY @ hh:mm');
      hasBeenSent = true;
    }

    if (data.left_payment_amount <= 0) {
      hasBeenPaid = true;
    }

    // Created Date + Issued by
    if (invoiceIssuedBy == this.globals.invoiceStatusCollaboratorConstants['MILY']) {
      this.invoiceIssuedByDate = this.createdBy + ' ' + this.issuedByMily + ' ' + this.createdByOnDate + ' ' + this.invoiceCreatedDate;
    } else {
      this.invoiceIssuedByDate = this.createdBy + ' ' + this.issuedByYou + ' ' + this.createdByOnDate + ' ' + this.invoiceCreatedDate;
    }

    // Sent Date + Sent by
    if (invoiceSentBy == this.globals.invoiceStatusCollaboratorConstants['MILY']) {
      this.invoiceSentByDate = this.sentBy + ' ' + this.issuedByMily + ' ' + this.createdByOnDate + ' ' + this.invoiceSentDate;
    } else if (invoiceSentBy == invoiceSentBy == this.globals.invoiceStatusCollaboratorConstants['COMPANY']) {
      this.invoiceSentByDate = this.sentBy + ' ' + this.issuedByYou + ' ' + this.createdByOnDate + ' ' + this.invoiceSentDate;
    } else {
      this.invoiceSentByDate = this.notSentYetLabel;
    }

    // Reminder Settings reset & load
    this.isReminderCustomEnabled = false;
    this.isReminderThreeDaysEnabled = false;
    this.isReminderSevenDaysEnabled = false;
    this.isReminderFourteenDaysEnabled = false;
    this.isReminderDueDateEnabled = false;
    this.reminderCustomId = null;
    this.reminderThreeDaysId = null;
    this.reminderSevenDaysId = null;
    this.reminderFourteenDaysId = null;
    this.reminderDueDateId = null;
    this.reminderCustomDate = null;
    this.reminderDueDate = null;
    data.reminder_settings.forEach(reminder => {
      switch (reminder.type) {
        case this.globals.invoiceRemindersTypeConstants['CUSTOM_DATETIME']:
          this.isReminderCustomEnabled = true;
          this.reminderCustomId = reminder.id;
          this.reminderCustomDate = reminder.custom_datetime;
          break;
        case this.globals.invoiceRemindersTypeConstants['AFTER_DAYS']:
          if (reminder.number_of_days == 3) {
            this.isReminderThreeDaysEnabled = true;
            this.reminderThreeDaysId = reminder.id;
          }
          else if (reminder.number_of_days == 7) {
            this.isReminderSevenDaysEnabled = true;
            this.reminderSevenDaysId = reminder.id;
          }
          else if (reminder.number_of_days == 14) {
            this.isReminderFourteenDaysEnabled = true;
            this.reminderFourteenDaysId = reminder.id;
          }
          break;
        case this.globals.invoiceRemindersTypeConstants['ON_DUE_DATE']:
          this.isReminderDueDateEnabled = true;
          this.reminderDueDateId = reminder.id;
          this.reminderDueDate = data.due_on_datetime;
          break;
      }
    });

    // set data on print invoice component
    this.customerInvoicePrintComponent.setPrintData(data);
    this.customerInvoicePrintComponent.setInvoicesGridData(data);
    const companyData = this.globals.companyData;
    companyData['companyLogo'] = this.globals.companyLogo;
    companyData['invoice_notes'] = this.globals.companyDataSettings['invoice_notes'];


    // get partner data only when logged in as a collaborator (else get collaborator data for 'bill to' information)
    if (this.globals.collaboratorModeEnabled) {
      const partnerCompanyId = data.company_partner_id;
      this.customerInvoicePrintComponent.setCompanyData(this.globals.partners[partnerCompanyId]);
      this.customerInvoicePrintComponent.setCollaboratorData(companyData);
    } else {
      // 'company' refers to the logged in user in this case
      this.customerInvoicePrintComponent.setCompanyData(companyData);
      this.customerInvoicePrintComponent.setCollaboratorData(this.collaboratorService.collaboratorData);
    }

    // send data to vouchers grid (inside modal)
    this.customerVouchersPrintGridComponent.collaboratorId = this.collaboratorId;
    this.customerVouchersPrintGridComponent.invoiceOrPaymentQuery = 'invoice_id=' + this.invoiceId;
    this.customerVouchersPrintGridComponent.initGrid();

    // set modal payments
    this.setPaymentsData(data);

    // Status Label (Toggle things based on status)
    switch (this.invoiceStatus) {
      case this.globals.invoiceStatusConstants['CREATED']:
        this.invoiceStatusLabel = this.createdLabel;
        document.querySelector('.value-status').classList.add('status-created');
        document.querySelector('.value-status').classList.remove('status-viewed');
        document.querySelector('.value-status').classList.remove('status-paid');
        document.querySelector('.value-status').classList.remove('status-overdue');
        document.querySelector('.value-status').classList.remove('status-sent');
        break;
      case this.globals.invoiceStatusConstants['APPROVED']:
        this.invoiceStatusLabel = this.approvedLabel;
        document.querySelector('.value-status').classList.add('status-created');
        document.querySelector('.value-status').classList.remove('status-viewed');
        document.querySelector('.value-status').classList.remove('status-paid');
        document.querySelector('.value-status').classList.remove('status-overdue');
        document.querySelector('.value-status').classList.remove('status-sent');
        break;
      case this.globals.invoiceStatusConstants['VIEWED']:
        this.invoiceStatusLabel = this.viewedLabel;
        document.querySelector('.value-status').classList.remove('status-created');
        document.querySelector('.value-status').classList.add('status-viewed');
        document.querySelector('.value-status').classList.remove('status-paid');
        document.querySelector('.value-status').classList.remove('status-overdue');
        document.querySelector('.value-status').classList.remove('status-sent');
        break;
      case this.globals.invoiceStatusConstants['PAID']:
        this.invoiceStatusLabel = this.paidLabel;
        document.querySelector('.value-status').classList.remove('status-created');
        document.querySelector('.value-status').classList.remove('status-viewed');
        document.querySelector('.value-status').classList.add('status-paid');
        document.querySelector('.value-status').classList.remove('status-overdue');
        document.querySelector('.value-status').classList.remove('status-sent');
        break;
      case this.globals.invoiceStatusConstants['OVERDUE']:
        this.invoiceStatusLabel = this.overdueLabel;
        document.querySelector('.value-status').classList.remove('status-created');
        document.querySelector('.value-status').classList.remove('status-viewed');
        document.querySelector('.value-status').classList.remove('status-paid');
        document.querySelector('.value-status').classList.add('status-overdue');
        document.querySelector('.value-status').classList.remove('status-sent');
        break;
      case this.globals.invoiceStatusConstants['SENT']:
        this.invoiceStatusLabel = this.sentLabel;
        document.querySelector('.value-status').classList.remove('status-created');
        document.querySelector('.value-status').classList.remove('status-viewed');
        document.querySelector('.value-status').classList.remove('status-paid');
        document.querySelector('.value-status').classList.remove('status-overdue');
        document.querySelector('.value-status').classList.add('status-sent');
        break;
      default:
        this.invoiceStatusLabel = 'CASE ERROR';
    }

    // if (hasBeenSent) {
    //   this.sendResendActionLabel = this.resendActionLabel;
    //   document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
    //   document.querySelector('.send-invoice path').classList.remove('grey-icon');
    //   document.querySelector('.send-invoice path').classList.add('blue-icon');
    // } else {
    //   this.sendResendActionLabel = this.sendActionLabel;
    //   document.querySelector('.resend-button-invoice .normal-button').classList.add('primary-button');
    //   document.querySelector('.send-invoice path').classList.remove('blue-icon');
    //   document.querySelector('.send-invoice path').classList.add('grey-icon');
    // }

    // if (hasBeenPaid) {
    //   document.querySelector('.get-paid-invoice path').classList.remove('grey-icon');
    //   document.querySelector('.get-paid-invoice path').classList.add('blue-icon');
    //   document.querySelector('.record-payment-invoice-button').classList.add('hidden');
    // } else if (!hasBeenPaid && hasBeenSent) {
    //   document.querySelector('.get-paid-invoice path').classList.add('grey-icon');
    //   document.querySelector('.get-paid-invoice path').classList.remove('blue-icon');
    //   document.querySelector('.record-payment-invoice-button .normal-button').classList.add('primary-button');
    //   document.querySelector('.record-payment-invoice-button').classList.remove('hidden');
    // } else if (!hasBeenPaid && !hasBeenSent) {
    //   document.querySelector('.get-paid-invoice path').classList.add('grey-icon');
    //   document.querySelector('.get-paid-invoice path').classList.remove('blue-icon');
    //   document.querySelector('.record-payment-invoice-button .normal-button').classList.remove('primary-button');
    //   document.querySelector('.record-payment-invoice-button').classList.remove('hidden');
    // }

    // focused (primary) button per invoice status (200ms delay for dom (*ngIf) elements to load)
    setTimeout(() => {
      this.sendResendActionLabel = this.resendActionLabel;
      switch (this.invoiceStatus) {
        case this.globals.invoiceStatusConstants['CREATED']: // focus: approved
          this.sendResendActionLabel = this.sendActionLabel;
          document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.add('primary-button');
            document.querySelector('.approve-action-button button').classList.remove('normal-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.remove('primary-button');

          document.querySelector('.create-invoice path').classList.add('blue-icon');
          document.querySelector('.create-invoice path').classList.remove('grey-icon');
          document.querySelector('.send-invoice path').classList.remove('blue-icon');
          document.querySelector('.send-invoice path').classList.add('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.add('grey-icon');
          break;
        case this.globals.invoiceStatusConstants['APPROVED']: // focus: send
          this.sendResendActionLabel = this.sendActionLabel;
          document.querySelector('.resend-button-invoice .normal-button').classList.add('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.remove('primary-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.remove('primary-button');

          document.querySelector('.create-invoice path').classList.add('blue-icon');
          document.querySelector('.create-invoice path').classList.remove('grey-icon');
          document.querySelector('.send-invoice path').classList.add('blue-icon');
          document.querySelector('.send-invoice path').classList.remove('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.add('grey-icon');
          break;
        case this.globals.invoiceStatusConstants['SENT']: // focus: pay
          this.sendResendActionLabel = this.resendActionLabel;
          document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.remove('primary-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.add('primary-button');

          document.querySelector('.create-invoice path').classList.add('blue-icon');
          document.querySelector('.create-invoice path').classList.remove('grey-icon');
          document.querySelector('.send-invoice path').classList.add('blue-icon');
          document.querySelector('.send-invoice path').classList.remove('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.add('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('grey-icon');
          break;
        case this.globals.invoiceStatusConstants['OVERDUE']: // focus: pay
          this.sendResendActionLabel = this.resendActionLabel;
          document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.remove('primary-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.add('primary-button');

          document.querySelector('.create-invoice path').classList.add('blue-icon');
          document.querySelector('.create-invoice path').classList.remove('grey-icon');
          document.querySelector('.send-invoice path').classList.add('blue-icon');
          document.querySelector('.send-invoice path').classList.remove('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.add('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('grey-icon');
          break;
        case this.globals.invoiceStatusConstants['VIEWED']: // focus: pay
          this.sendResendActionLabel = this.resendActionLabel;
          document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.remove('primary-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.add('primary-button');

          document.querySelector('.create-invoice path').classList.add('blue-icon');
          document.querySelector('.create-invoice path').classList.remove('grey-icon');
          document.querySelector('.send-invoice path').classList.add('blue-icon');
          document.querySelector('.send-invoice path').classList.remove('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.add('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('grey-icon');
          break;
        case this.globals.invoiceStatusConstants['PAID']: // focus: -
          this.sendResendActionLabel = this.resendActionLabel;
          document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.remove('primary-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.remove('primary-button');

          document.querySelector('.create-invoice path').classList.add('blue-icon');
          document.querySelector('.create-invoice path').classList.remove('grey-icon');
          document.querySelector('.send-invoice path').classList.add('blue-icon');
          document.querySelector('.send-invoice path').classList.remove('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.add('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('grey-icon');
          break;
        // default disables everything
        default:
          document.querySelector('.resend-button-invoice .normal-button').classList.remove('primary-button');
          document.querySelector('.mark-as-sent-button .normal-button').classList.remove('primary-button');
          if (document.querySelector('.approve-action-button button')) {
            document.querySelector('.approve-action-button button').classList.remove('primary-button');
          }
          document.querySelector('.record-payment-invoice-button .normal-button').classList.remove('primary-button');

          document.querySelector('.create-invoice path').classList.remove('blue-icon');
          document.querySelector('.send-invoice path').classList.remove('blue-icon');
          document.querySelector('.get-paid-invoice path').classList.remove('blue-icon');
          document.querySelector('.create-invoice path').classList.add('grey-icon');
          document.querySelector('.send-invoice path').classList.add('grey-icon');
          document.querySelector('.get-paid-invoice path').classList.add('grey-icon');
      }
    }, 200);
  }

  setCustomDate(event) {
    // disable if checked, otherwise open calendar modal to set date before checking checkbox
    if (this.isReminderCustomEnabled) {
      this.updateRemindersAndReloadModal('custom date');
    } else {
      event.target.checked = false; // force checkbox off
      this.invoiceCustomDateModalComponent.openModal();
    }
  }

  updateRemindersAndReloadModal(checkboxType) {
    const remindersArray = [];
    switch (checkboxType) {
      case 'due date':
        this.isReminderDueDateEnabled ? this.isReminderDueDateEnabled = false : this.isReminderDueDateEnabled = true;
        break;
      case 'three days':
        this.isReminderThreeDaysEnabled ? this.isReminderThreeDaysEnabled = false : this.isReminderThreeDaysEnabled = true;
        break;
      case 'seven days':
        this.isReminderSevenDaysEnabled ? this.isReminderSevenDaysEnabled = false : this.isReminderSevenDaysEnabled = true;
        break;
      case 'fourteen days':
        this.isReminderFourteenDaysEnabled ? this.isReminderFourteenDaysEnabled = false : this.isReminderFourteenDaysEnabled = true;
        break;
      case 'custom date':
        this.isReminderCustomEnabled ? this.isReminderCustomEnabled = false : this.isReminderCustomEnabled = true;
        break;
    }

    // create object for update
    if (this.isReminderCustomEnabled) {
      remindersArray.push(
        {
          id: this.reminderCustomId,
          type: this.globals.invoiceRemindersTypeConstants['CUSTOM_DATETIME'],
          custom_datetime: this.reminderCustomDate
        }
      );
    }
    if (this.isReminderDueDateEnabled) {
      this.reminderDueDate = moment().add(30, 'days').format('DD-MM-YYYY'); // 30 days ahead from now
      remindersArray.push(
        {
          id: this.reminderDueDateId,
          type: this.globals.invoiceRemindersTypeConstants['ON_DUE_DATE']
        }
      );
    }
    if (this.isReminderThreeDaysEnabled) {
      remindersArray.push(
        {
          id: this.reminderThreeDaysId,
          type: this.globals.invoiceRemindersTypeConstants['AFTER_DAYS'],
          number_of_days: 3
        }
      );
    }
    if (this.isReminderSevenDaysEnabled) {
      remindersArray.push(
        {
          id: this.reminderSevenDaysId,
          type: this.globals.invoiceRemindersTypeConstants['AFTER_DAYS'],
          number_of_days: 7
        }
      );
    }
    if (this.isReminderFourteenDaysEnabled) {
      remindersArray.push(
        {
          id: this.reminderFourteenDaysId,
          type: this.globals.invoiceRemindersTypeConstants['AFTER_DAYS'],
          number_of_days: 14
        }
      );
    }

    const updatedReminderObj = {
      invoices: [
        {
          due_on_datetime: this.reminderDueDate, // 30 days ahead from now if due date reminder exists
          reminder_settings: remindersArray,
          status: this.invoiceStatus
        }
      ]
    };

    // update invoice & update modal
    this.http.put('api/v1/invoice/' + this.invoiceId, updatedReminderObj).pipe(take(1)).subscribe(response => {
      this.loadInvoiceModal(response['items'].invoices[0]);
      this.modalGridService.updateInvoicesGrid();
    });
  }

  dataLoadingListen(event) {
    if (event === 'true') {
      this.dataLoading = true;
    } else if (event === 'false') {
      this.dataLoading = false;
    }
  }

  openModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.customer-invoices-modal');
    const modalBackground = document.querySelector('.customer-invoices-modal-background');
    modal.classList.add('open');
    modal.classList.remove('closed');
    modalBackground.classList.remove('hidden');
  }

  closeModal() {
    const modal = document.querySelector('.customer-invoices-modal');
    const modalBackground = document.querySelector('.customer-invoices-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
  }

  // mark as read
  markAsSent() {
    this.globals.comingSoonAlert();
  }

  // re-send invoice
  resendInvoice() {
    let self = this;
    if (this.invoiceStatus == this.globals.invoiceStatusConstants['CREATED']) {
      let confirmCreatedMessage = confirm(this.invoiceSendWarningMessage);
      if (confirmCreatedMessage) {
        sendInvoice();
      }
    } else {
      sendInvoice();
    }

    // send invoice action
    function sendInvoice() {
      const sendInvoiceObj = {
        invoice: {
          id: self.invoiceId
        }
      };

      // send invoice & update grid and modal
      self.http.post('api/v1/invoice-reminder', sendInvoiceObj).subscribe(response => {
        self.modalGridService.updateCustomerGrid();
        self.loadInvoiceModal(response['items']['invoices']['0']);
      });
    }
  }

  // send receipt
  sendReceipt() {
    this.globals.comingSoonAlert();
  }

  // edit invoice
  editInvoice() {
    this.globals.comingSoonAlert();
  }

  // clone invoice
  cloneInvoice() {
    this.globals.comingSoonAlert();
  }

  removeInvoice() {
    this.globals.comingSoonAlert();
  }

  getLink() {
    navigator.clipboard.writeText(window.location.hostname + '/invoicePrint/' + this.invoiceData.hash);
    alert(this.linkCopiedLabel);
  }

  exportAsPdf() {
    this.globals.comingSoonAlert();
  }

  routerPrint() {
    window.open('/invoicePrint/' + this.invoiceHash, '_blank');
  }

  // set invoice as paid
  setPaid() {
    let paidInvoice = {
      "status": this.globals.invoiceStatusConstants['PAID']
    };

    const myObserver = {
      next: (response) => {
        // problematic back-end -> returns all objects in array instead of only returning the object matching the put request
        this.loadInvoiceModal(response['items']['invoices']['0']);
        // this.invoiceStatusLabel = this.paidLabel;
        // this.sendResendActionLabel = this.resendActionLabel;
        // document.querySelector('.value-status').classList.remove('status-created');
        // document.querySelector('.value-status').classList.add('status-paid');
        // this.invoiceStatus = this.globals.invoiceStatusConstants['PAID'];
        this.modalGridService.updateCustomerGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {

      },
    };

    if (this.globals.collaboratorModeEnabled) {
      this.http.put('api/v1/partner-invoices/' + this.invoiceId, JSON.stringify(paidInvoice)).pipe(take(1)).subscribe(myObserver);
    } else {
      this.http.put('api/v1/invoice/' + this.invoiceId, JSON.stringify(paidInvoice)).pipe(take(1)).subscribe(myObserver);
    }
  }

  // set invoice as approved
  setApproved() {
    let paidInvoice = {
      "status": this.globals.invoiceStatusConstants['APPROVED']
    };

    const myObserver = {
      next: (response) => {
        // problematic back-end -> returns all objects in array instead of only returning the object matching the put request
        this.loadInvoiceModal(response['items']['invoices']['0']);
        // this.invoiceStatusLabel = this.paidLabel;
        // this.sendResendActionLabel = this.resendActionLabel;
        // document.querySelector('.value-status').classList.remove('status-created');
        // document.querySelector('.value-status').classList.add('status-paid');
        // this.invoiceStatus = this.globals.invoiceStatusConstants['PAID'];
        this.modalGridService.updateCustomerGrid();
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {

      },
    };

    if (this.globals.collaboratorModeEnabled) {
      this.http.put('api/v1/partner-invoices/' + this.invoiceId, JSON.stringify(paidInvoice)).pipe(take(1)).subscribe(myObserver);
    } else {
      this.http.put('api/v1/invoice/' + this.invoiceId, JSON.stringify(paidInvoice)).pipe(take(1)).subscribe(myObserver);
    }
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT').subscribe((res: string) => { this.sentLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.VIEWED').subscribe((res: string) => { this.viewedLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID').subscribe((res: string) => { this.paidLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED').subscribe((res: string) => { this.createdLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.APPROVED').subscribe((res: string) => { this.approvedLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SCHEDULE_REMINDERS').subscribe((res: string) => { this.scheduleRemindersTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_MILY').subscribe((res: string) => { this.issuedByMily = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.BY_YOU').subscribe((res: string) => { this.issuedByYou = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED_BY').subscribe((res: string) => { this.createdBy = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT_BY').subscribe((res: string) => { this.sentBy = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.NOT_SENT_YET').subscribe((res: string) => { this.notSentYetLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.ON_DATE').subscribe((res: string) => { this.createdByOnDate = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAYMENTS_RECEIVED').subscribe((res: string) => { this.paymentsReceivedTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTION_SEND').subscribe((res: string) => { this.sendActionLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.ACTION_SEND_AGAIN').subscribe((res: string) => { this.resendActionLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID_ON').subscribe((res: string) => { this.paidOnTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID_AMOUNT').subscribe((res: string) => { this.paidTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SEND_RECEIPT_RESEND').subscribe((res: string) => { this.sendReceiptLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.EDIT').subscribe((res: string) => { this.editLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.CLONE').subscribe((res: string) => { this.cloneLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.REMOVE').subscribe((res: string) => { this.removeLinkTitle = res; }));
    this.listen.push(this.translate.get('CUSTOMER_INVOICES.SEND_WARNING').subscribe((res: string) => { this.invoiceSendWarningMessage = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('GENERIC.LINK_COPIED').subscribe((res: string) => { this.linkCopiedLabel = res; }));
  }
}
