import { Globals as globals} from "@app/services/globals";

export enum LMLoggingLevel {
  None,
  Verbose,
  Info,
  Warning,
  Error
}

export interface ILmBaseEnum {
  key: number;
  value: string;
  caption: string;
}

export interface ILmBEBaseTypeEnum{
  id: number;
  name: string;
}

export interface IVeronaBaseEnum{
  value?: number | string;
  name?: string
}



