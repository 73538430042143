import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-navbar',
  templateUrl: './client-navbar.component.html',
  styleUrls: ['./client-navbar.component.scss']
})
export class ClientNavbarComponent implements OnInit {

  companyName = '';
  contactName = '';
  driverName = '';
  estimatedTime = '';
  estimatedTimeEnd = '';
  estimatedDeliveryDate = '';
  address = '';
  addressMobile = '';
  estimatedTimeLabel = '';
  payAmount = '';
  avatarUrl = 'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/366-mj-7703-fon-jj.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=d144b28b5ebf828b7d2a1bb5b31efdb6';
  logoUrl = 'https://images-platform.99static.com//DaTIx2162nmq5AJpEwQWugAXp00=/303x295:1702x1694/fit-in/500x500/99designs-contests-attachments/103/103961/attachment_103961129';
  logoBase64 = '';
  avatarBase64 = '';
  logoSrc = 'data:image/png;base64,LOGO_HASH'
  avatarSrc = 'data:image/png;base64,AVATAR_HASH'
  sampleAvatarVisible = false;

  constructor() { }

  ngOnInit() {
  }

}
