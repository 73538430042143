import { Globals } from '@app/services/globals';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
declare var H: any;

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

    listen = [];

    myForm: FormGroup;
    token = '';
    isClickedOnce = false;
    password;
    passwordConfirm;

    constructor(
        // public translate: TranslateService,
        public globals: Globals,
        formBuilder: FormBuilder,
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.myForm = formBuilder.group({
            'userAccount': formBuilder.group({
                'passwordPlaintextNew': [this.password],
                'passwordPlaintextNewConfirm': [this.passwordConfirm],
            }),
            'token': this.token
        }, { validators: this.checkPasswords });
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        const password = group.get('userAccount.passwordPlaintextNew').value;
        const confirmPassword = group.get('userAccount.passwordPlaintextNewConfirm').value;

        return password === confirmPassword ? null : { notSame: true };
    }

    submitNewPassword() {
        const myObserver = {
            next: (response) => {
                this.router.navigate(['login']);
            },
            error: (error) => {
                console.error(error);
            },
            complete: () => { },
        };
        this.http.post('api/v1/account/register/user-change-password', this.myForm.value).pipe(take(1)).subscribe(myObserver);
    }

    ngOnInit() {
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            this.token = params['token'];
            this.myForm.patchValue({
                'token': this.token,
            });
        }));
    }

    public ngAfterViewInit() { }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
