import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';
import { Password, PasswordModule } from 'primeng/password';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmPasswordComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmPasswordComponent) };

@Component({
  selector: 'lm-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LmPasswordComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild(Password) passCtrl: Password;
  @HostBinding() id = `lm-password-${LmPasswordComponent.nextId++}`;
  name = `lm-password-${LmPasswordComponent.nextId++}`;
  
  @Input() label: string;
  @Input() promptLabel: string
  @Input() placeholder: string;
  @Input() showClear: boolean;
  @Input() mediumRegex: RegExp; // bind only if provide a regexp
  @Input() strongRegex: RegExp; // bind only if provide a regexp
  @Input() weakLabel: string;
  @Input() mediumLabel: string;
  @Input() strongLabel: string;
  @Input() feedback = true;
  @Input() toggleMask = true;
  @Input() appendTo: string | HTMLElement;
  @Input() disabled: boolean; 
  @Input() inputStyle: string;
  @Input() styleClass = 'lm-password-control';
  @Input() inputStyleClass = 'lm-password-input';
  @Input() iconClass: string;
  @Input() iconColor: string;
  @Input() iconSize?: string;
  @Input() iconStyle: LmTextIconStyle;
  @Input() styles: any;
  @Input() autocomplete = false;


  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.passCtrl.input.nativeElement.autocomplete = 'new-password';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule
  ],
  exports: [LmPasswordComponent],
  declarations: [LmPasswordComponent]
})
export class LMPasswordModule {}
