import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    requestsCount = 0;

    constructor() { }
}
