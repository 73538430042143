import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridsService } from '@app/services/grids.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerInvoicePrintComponent } from '@app/prints/customer-invoice-print/customer-invoice-print.component';
import { CustomerVouchersPrintGridComponent } from '@app/grids/customer-vouchers-print-grid/customer-vouchers-print-grid.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-customer-invoice-print-single',
    templateUrl: './customer-invoice-print-single.component.html',
    styleUrls: ['./customer-invoice-print-single.component.scss']
})
export class CustomerInvoicePrintSingleComponent implements OnInit, OnDestroy {
    @ViewChild(CustomerInvoicePrintComponent, { static: false }) customerInvoicePrintComponent: CustomerInvoicePrintComponent;
    @ViewChild(CustomerVouchersPrintGridComponent, { static: false }) customerVouchersPrintGridComponent: CustomerVouchersPrintGridComponent;

    listen = [];

    constructor(
        public gridsService: GridsService,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
    ) {
        // this.listenUpdateCustomerGrid = this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
        //     // this.pagesCount = 0;
        //     // this.gridApi.purgeServerSideCache([]);
        // });
    }

    loadInvoice(hash) {
        this.http.get('api/unregistered/v1/invoice-view?hash=' + hash).pipe(take(1)).subscribe(response => {
            if (response) {
                const data = response['items'];
                // set data on print invoice component
                this.customerInvoicePrintComponent.setPrintData(data);
                this.customerInvoicePrintComponent.setInvoicesGridData(data);
                this.customerInvoicePrintComponent.setCompanyData(data.company);
                this.customerInvoicePrintComponent.collaboratorData = {};
                this.customerInvoicePrintComponent.setCollaboratorData(data.collaborator);
                // this.customerVouchersPrintGridComponent.setCustomerVouchersGridData(data.vouchers);

                this.customerVouchersPrintGridComponent.collaboratorId = data.collaborator.collaboratorData.id;
                this.customerVouchersPrintGridComponent.invoiceOrPaymentQuery = 'is_invoice=1';
                this.customerVouchersPrintGridComponent.hash = hash;
                this.customerVouchersPrintGridComponent.initGrid();
            }
        });
    }

    ngOnInit() {
        var comm100 = document.querySelector('[id^="comm100-button"]');
        if (comm100) {
            comm100.classList.add('hidden');
        }
        var comm1002 = document.querySelector('[id^="comm100-container"]');
        if (comm1002) {
            comm1002.classList.add('hidden');
        }
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            const hash = params['hash'];
            this.loadInvoice(hash);

            setTimeout(() => {
                this.customerVouchersPrintGridComponent.gridWidthFix();
                window.print();
            }, 1200);

        }));
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
