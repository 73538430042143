import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';
import { SplitButton, SplitButtonModule } from 'primeng/splitbutton';
import { LmButtonModule, LmButtonSeverity } from '../../structure/button/button.component';

export interface LmHeaderAction {
  id?: string;
  icon?: string;
  label?: string;
  severity?: LmButtonSeverity;
  class?: string;
  disabled?: () => boolean;
  visible?: () => boolean;
  command?: () => void;
  subActions?: LmHeaderSubAction[];
}

export type LmHeaderSubAction = Pick<LmHeaderAction, Exclude<keyof LmHeaderAction, 'visible'>> & { visible?: boolean };

@Component({
  selector: 'lm-header-actions',
  templateUrl: './header-actions.component.html'
})
export class LmHeaderActionsComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() actions: LmHeaderAction[];

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {}

  ngOnDestroy(): void {}

  splitButtonClick(splitButton: SplitButton, action: LmHeaderAction): void {
    if (action.command) {
      action.command();
    } else {
      splitButton.onDropdownButtonClick(null);
    }
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LmButtonModule, SplitButtonModule, RippleModule],
  exports: [LmHeaderActionsComponent],
  declarations: [LmHeaderActionsComponent]
})
export class LmHeaderActionsModule {}
