import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { LmTableListViewModelService } from '@app/shared/structure/table-list/table-list-viewmodel.service';
import { Globals } from '@app/services/globals';
import { IPriceListDistance, IPriceListServices, IPriceListShipment } from '@app/model/price-list';
import { INITIAL_DATA } from '@app/model/initial-data';
import { LmSettingsPriceListsServicesService } from '@app/api/services/settings-price-lists-services.service';
import { ISettings_Price_Lists_Dto } from '@app/api/models/settings-price-lists-dto';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PriceListModalComponent } from './new-price-list-modal/new-price-list-modal.component';


@Injectable()
export class SettingsPriceListsViewModelService extends LmTableListViewModelService<ISettings_Price_Lists_Dto> implements OnInit, OnDestroy {
    priceListServicesEnum: IPriceListServices;
    priceListShipmentEnum: IPriceListShipment;
    priceListDistanceEnum: IPriceListDistance;
    modalConfig: any;
    defaultNextDayService;
    collaboratorId: number;
    count = 0;

    constructor(
        injector: Injector, 
        public globals: Globals,
        @Inject(forwardRef(() => LmSettingsPriceListsServicesService)) public apiSvc: LmSettingsPriceListsServicesService
    ){
        super(injector);
        const {priceListServicesEnum, priceListShipmentEnum, priceListDistanceEnum} = globals;
        this.priceListServicesEnum = priceListServicesEnum;
        this.priceListShipmentEnum = priceListShipmentEnum;
        this.priceListDistanceEnum = priceListDistanceEnum;
        this.modalConfig = { componentType: PriceListModalComponent, injector: injector, data: {VM: this, globals: this.globals} }
    }
    
    private transformRows = (res) => {
        this.count = res.items.length;
        res.items.forEach(item => {
            const {name, services, area_groups, type} = item;
            const nameServices = services.map(({name})=>name).join(', ');
            let distanceTypesText = '';
            item.distance_type.forEach(distanceType => {
                Object.keys(this.globals.priceListDistanceEnum).forEach(constant=> {
                    if (this.globals.priceListDistanceEnum[constant].key == distanceType) {
                        if (distanceTypesText!='') distanceTypesText += ', ';
                        distanceTypesText += constant;
                    }
                });
            });
            let typesText = '';
            item.type.forEach(type => {
                Object.keys(this.globals.priceListShipmentEnum).forEach(constant=>{
                    if (this.globals.priceListShipmentEnum[constant].key == type) {
                        if (typesText!='') typesText += ', ';
                        typesText += constant;
                    }
                });
            });

            item.nameCaption = `${name}</br><small class="lm-txt-color-grey lm-font-weight-normal">${nameServices}</small>`;
            item.areasCaption = '';
            if (item.area_groups.from.name == item.area_groups.to.name) {
                item.areasCaption = this.modalConfig.data.translations['IN'] + ' ' + item.area_groups.from.name;
            } else {
                item.areasCaption = item.area_groups.from.name + ' ' + this.modalConfig.data.translations['TO'] + ' ' + item.area_groups.to.name;
            }
            item.distanceΤypeCaption = distanceTypesText;
            item.typeCaption = typesText;
        });
        return res;
    }
    
    getRows(){
        return this.apiSvc.apiSettingsPriceListsServicesAllGet(this.collaboratorId).pipe(switchMap(res => of(this.transformRows(res))));
    }
    getById(id) {
        return this.apiSvc.apiSettingsPriceListsServicesSingleGet({id:id}).pipe(switchMap(res => of(this.transformRows(res))));
    }
    post(item: any) {
        return this.apiSvc.apiSettingsPriceListsServicesPost({body: item})
    }
    put(id) {
        
    }
    delete(id) {
        return this.apiSvc.apiSettingsPriceListsServicesSingleDelete({id:id})
    }
    newItem() {
        // are groups were somehow not emptying 
        INITIAL_DATA.newPriceListItem.area_groups = {from: {id: undefined, name: undefined}, to: {id: undefined, name: undefined}};
        const nextDayService = {id:this.defaultNextDayService.id}
        INITIAL_DATA.newPriceListItem.services = [nextDayService];
        return {...INITIAL_DATA.newPriceListItem};
    }
    search(query: string) {
        
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }



}
