import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Component, ComponentRef, Inject, Injectable, Injector, NgModuleRef, OnDestroy, Optional, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LmConfirmDialogComponent } from '../../shared/containers/confirm-dialog/confirm-dialog.component';
import { LmToastDefaultContentComponent } from '../../shared/containers/toast/toast-default-content.component';
import { LmToastHostComponent } from '../../shared/containers/toast/toast-host.component';
import { LmToastMultipleContentComponent } from '../../shared/containers/toast/toast-multiple-content.component';
import { LmToastComponent } from '../../shared/containers/toast/toast.component';
import { LmConfirmationInfo } from '../../model/confirm';
import { LmNotificationContext, LmNotificationInfo, LmSeverity } from '../../model/toast';
import { LmDialogComponent } from '../../shared/containers/dialog/dialog.component';
import { LmDialogConfig, LmDialogContext } from '../../model/dialog';
import { AppComponent } from '@app/app.component';

@UntilDestroy()
@Injectable(
  {
    providedIn: "root"
  }
)

export class LmNotificationService implements OnDestroy {
  protected activeDialog: LmDialogComponent;
  protected toastHostCompRef: ComponentRef<LmToastHostComponent>;
  protected toastCompRefsMap: Map<string, { close$: Subject<unknown>; compRef: ComponentRef<LmToastComponent> }> = new Map();
  protected vcr: ViewContainerRef;

  constructor(
    protected injector: Injector, 
    protected appRef: ApplicationRef, 
    @Inject(DOCUMENT) protected document: Document
  ) {
    this.vcr = (this.appRef.components[0].instance as AppComponent).appVCR;
  }

  showDialog(args: LmDialogConfig): void;
  showDialog<TRes>(args: LmDialogConfig): Observable<TRes>;
  showDialog(args: LmDialogConfig): Observable<unknown> {
    const close$ = new Subject<unknown>();
    const ngModuleRef = args.injector.get(NgModuleRef) as any;
    const dialogCtx = new LmDialogContext();
    
    dialogCtx.data = args.data;
    dialogCtx.close = (result: unknown): void => {
      close$.next(result);
      close$.complete();
    };

    let dialogInjector = Injector.create({
      providers: [{ provide: LmDialogContext, useValue: dialogCtx }],
      parent: args.injector
    });

    const dialogCompRef = this.vcr.createComponent(args.componentType, {injector: dialogInjector, ngModuleRef: ngModuleRef} );

    this.document.body.appendChild(dialogCompRef.location.nativeElement);

    close$.pipe(take(1)).subscribe(() => {
      dialogCompRef.destroy();
    });

    return close$.asObservable();
  }

  setActiveDialog(dialog: LmDialogComponent): void {
    this.activeDialog = dialog;
  }

  getActiveDialog(): LmDialogComponent {
    return this.activeDialog;
  }

  showSuccess(title: string, body: string): void;
  showSuccess(title: string, body: string, life?: number): void;
  showSuccess(title: string, body: string, life?: number, closable?: boolean): void;
  showSuccess(title: string, body: string, life?: number, closable?: boolean, sticky?: boolean): void;
  showSuccess(title: string, body: string, life?: number, closable?: boolean, sticky?: boolean): void {
    this.showMessageComponent({
      contentType: LmToastDefaultContentComponent,
      data: { summary: title, detail: body, severity: 'success' },
      position: 'top-right',
      life: life ?? 4000,
      severity: 'success',
      closable: closable ?? true,
      sticky: sticky ?? false,
      injector: this.injector,
      id: 'root'
    });
  }

  showWarning(title: string, body: string): void;
  showWarning(title: string, body: string, life?: number): void;
  showWarning(title: string, body: string, life?: number, closable?: boolean): void;
  showWarning(title: string, body: string, life?: number, closable?: boolean, sticky?: boolean): void;
  showWarning(title: string, body: string, life?: number, closable?: boolean, sticky?: boolean): void {
    this.showMessageComponent({
      contentType: LmToastDefaultContentComponent,
      data: { summary: title, detail: body, severity: 'warn' },
      position: 'top-right',
      life: life ?? 6000,
      // life: life ?? 600000,
      severity: 'warn',
      closable: closable ?? true,
      sticky: sticky ?? false,
      injector: this.injector,
      id: 'root'
    });
  }

  showError(title: string, body: string): void;
  showError(title: string, body: string, life?: number): void;
  showError(title: string, body: string, life?: number, closable?: boolean): void;
  showError(title: string, body: string, life?: number, closable?: boolean, sticky?: boolean): void;
  showError(title: string, body: string, life?: number, closable?: boolean, sticky?: boolean): void {
    this.showMessageComponent({
      contentType: LmToastDefaultContentComponent,
      data: { summary: title, detail: body, severity: 'error' },
      position: 'top-right',
      life: life ?? 8000,
      severity: 'error',
      closable: closable ?? true,
      sticky: sticky ?? false,
      injector: this.injector,
      id: 'root'
    });
  }

  showQuestion(title: string, message: string): Observable<boolean>;
  showQuestion(title: string, message: string, okLabel?: string, cancelLabel?: string): Observable<boolean>;
  showQuestion(title: string, message: string, okLabel?: string, cancelLabel?: string): Observable<boolean> {
    return this.showConfirmComponent({
      contentType: LmConfirmDialogComponent,
      header: title,
      message: message,
      acceptLabel: okLabel ?? 'OK',
      rejectLabel: cancelLabel ?? 'CANCEL',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      position: 'center',
      injector: this.injector
    });
  }

  showMultiple(title: string, messages: { body: string; severity: LmSeverity }[]): void;
  showMultiple(title: string, messages: { body: string; severity: LmSeverity }[], life?: number): void;
  showMultiple(title: string, messages: { body: string; severity: LmSeverity }[], life?: number, closable?: boolean): void;
  showMultiple(title: string, messages: { body: string; severity: LmSeverity }[], life?: number, closable?: boolean, sticky?: boolean): void;
  showMultiple(title: string, messages: { body: string; severity: LmSeverity }[], life?: number, closable?: boolean, sticky?: boolean): void {
    this.showMessageComponent({
      contentType: LmToastMultipleContentComponent,
      data: { title, messages: messages },
      position: 'top-right',
      life: life ?? 8000,
      severity: 'info',
      closable: closable ?? true,
      sticky: sticky ?? false,
      injector: this.injector,
      id: 'rootmulti'
    });
  }

  showMessageComponent(args: LmNotificationInfo): void | Observable<unknown> {
    /* Create a toasts host component only once */
    if (!this.toastHostCompRef) {
      
      this.toastHostCompRef = this.vcr.createComponent(LmToastHostComponent, {injector: this.injector} );
      // this.appRef.attachView(this.toastHostCompRef.hostView);
      this.document.body.appendChild(this.toastHostCompRef.location.nativeElement);
    }

    /* Create a new or get cached toast component */
    let toastCompInfo = this.toastCompRefsMap.get(args.id);
    if (!toastCompInfo) {
      const compRef = this.toastHostCompRef.instance.vcr.createComponent(LmToastComponent, {injector: this.injector} );

      toastCompInfo = {
        compRef: compRef,
        close$: new Subject<unknown>()
      };

      toastCompInfo.compRef.instance.setClose((res?: unknown) => {
        toastCompInfo.close$.next(res);
      });

      this.toastCompRefsMap.set(args.id, toastCompInfo);
    }

    /* Setup plumbing for closing from inside the created toast item component */
    const self = this;
    const ctx = new LmNotificationContext(args.id);
    ctx.data = args.data;
    ctx.close = function(res?: unknown) {
      let toastCompInfo = self.toastCompRefsMap.get(this.id);
      toastCompInfo.compRef.instance.close(res);
    };

    // /* Provide toast context */
    const parentInjector = args.injector;
    let injector = Injector.create({
      providers: [{ provide: LmNotificationContext, useValue: ctx }],
      parent: parentInjector
    });
    args.injector = injector;

    /* Show toast */
    toastCompInfo.compRef.instance.showMessage(args);

    /* Return close event */
    return toastCompInfo.close$.asObservable();
  }


  showConfirmComponent(args: LmConfirmationInfo): Observable<boolean> {
    return new Observable((observer) => {
      // const fact = this.cfr.resolveComponentFactory(LmConfirmDialogComponent);
      // const confirmDialogRef = fact.create(args.injector);

      const confirmDialogRef = this.vcr.createComponent(LmConfirmDialogComponent, {injector: args.injector} );

      this.appRef.attachView(confirmDialogRef.hostView);
      this.document.body.appendChild(confirmDialogRef.location.nativeElement);

      const result$ = new Subject<boolean>();

      confirmDialogRef.instance.showQuestion(args, result$);

      result$.pipe(take(1)).subscribe((result) => {
        confirmDialogRef.destroy();
        this.appRef.detachView(confirmDialogRef.hostView);
        observer.next(result);
        observer.complete();
      });
    });
  }

  ngOnDestroy() {}
}
