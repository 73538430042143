import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';

export type loadingType = 'progressSpinner' | 'progressBar' | 'skeleton';

@Component({
  selector: 'lm-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LmLoadingComponent {
  @Input() isLoading = false;
  @Input() type: loadingType = 'progressSpinner';
}

@NgModule({
  imports: [CommonModule, ProgressSpinnerModule, ProgressBarModule, SkeletonModule],
  exports: [LmLoadingComponent],
  declarations: [LmLoadingComponent]
})
export class LMLoadingModule {}
