import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { SettingsRevaluationsViewModelService } from '../revaluations-viewmodel.service';
import { ISettingsRevaluationDto } from '@app/api/models/settings-revaluations-dto';
import { lmEnumTypeToVeronaType } from '@app/shared/utils';


@UntilDestroy()
@Component({
  templateUrl: 'revaluation-modal.component.html'
})
export class RevaluationModalComponent implements OnInit, AfterViewInit, OnDestroy {
  VM: SettingsRevaluationsViewModelService;
  globals: any;
  saveBttn: LmButtonConfig;
  item: ISettingsRevaluationDto;

  options: any[];
  operatorOptions: any[];
  optionValue
  applicable_price_list_type: number;
  radioControlApplicableTypes: any[];
  revaluationType = {};

  constructor(
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService
  )
  {
    const {data:{item, globals}} = config;
    const {priceListRevaluationApplicablePriceListTypeEnum, priceListRevaluationValueTypeEnum, companyId} = globals;
    this.globals = globals;
    
    this.operatorOptions = [{label: '+', id: '+'}, {label: '-', id: '-'}];
    this.options = lmEnumTypeToVeronaType(priceListRevaluationValueTypeEnum);

    this.VM = config.data.VM;
    this.item = item ?? {...this.VM.newItem(), company_id: companyId};
    this.updateButtons();
    this.saveBttn = {
      label: this.VM.modalConfig.data.translations['SAVE'], 
      command: () => this.ok(),
      styleClass: 'lm-bg-color-mily'
    };
    this.radioControlApplicableTypes = lmEnumTypeToVeronaType(priceListRevaluationApplicablePriceListTypeEnum, null, 'value');
  }

  private isFormValid(): boolean {
    return Boolean(this.item.value && this.item.categories?.length);
  }

  private updateButtons() {
    setTimeout(() => {
      this.saveBttn.disabled = !this.isFormValid();
    }, 100);
  }

  handleRevaluationType(e){
    if(e){
      this.item.operator = e.operator;
      this.item.value_type = e.selection;
    }
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {  }
  ngOnDestroy(): void {}

  ok = () => {
    if (this.isFormValid()) if (confirm(this.VM.modalConfig.data.translations['REVALUATION_CONFIRM'])) {
      this.VM.postItem({ revaluation: [this.item] })
        .pipe(take(1), untilDestroyed(this),)
        .subscribe(_ => this.config.close())
    }
  }
  cancel = () => this.config.close();
}

