import { Directive, Input, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormButtonComponent } from '../formButton/formButton.component';
import { FormCheckboxComponent } from '../formCheckbox/formCheckbox.component';
import { FormInputComponent } from '../formInput/formInput.component';
import { FormSelectComponent } from '../formSelect/formSelect.component';

const components = {
  button: FormButtonComponent,
  checkbox: FormCheckboxComponent,
  input: FormInputComponent,
  select: FormSelectComponent
};

@Directive({
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit {
  @Input()
  config;

  @Input()
  group: FormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  component;

  ngOnInit() {
    const component = components[this.config.type];
    const factory = this.resolver.resolveComponentFactory<any>(component);
    this.component = this.container.createComponent(factory);
  }
}
