// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#customer-payment-invoices-print-container {
  height: 100%;
}

.invoice-info .values {
  white-space: pre;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbWVyLXBheW1lbnQtaW52b2ljZS1wcmludC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNJLHNCQUFBO0VBQ0EsMkJBQUE7QUFESjs7QUFJQTtFQUNJLFlBQUE7QUFESjs7QUFLSTtFQUNJLGdCQUFBO0FBRlIiLCJmaWxlIjoiY3VzdG9tZXItcGF5bWVudC1pbnZvaWNlLXByaW50LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnc3R5bGVzL2Fic3RyYWN0cy92YXJpYWJsZXMnO1xuXG4qIHtcbiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICAgIC1tb3otYm94LXNpemluZzogYm9yZGVyLWJveDtcbn1cblxuI2N1c3RvbWVyLXBheW1lbnQtaW52b2ljZXMtcHJpbnQtY29udGFpbmVyIHtcbiAgICBoZWlnaHQ6IDEwMCU7XG59XG5cbi5pbnZvaWNlLWluZm8ge1xuICAgIC52YWx1ZXMge1xuICAgICAgICB3aGl0ZS1zcGFjZTogcHJlO1xuICAgIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/prints/customer-payment-invoice-print/customer-payment-invoice-print.component.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;EACA,2BAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAKI;EACI,gBAAA;AAFR;AACA,gtBAAgtB","sourcesContent":["@import 'styles/abstracts/variables';\n\n* {\n    box-sizing: border-box;\n    -moz-box-sizing: border-box;\n}\n\n#customer-payment-invoices-print-container {\n    height: 100%;\n}\n\n.invoice-info {\n    .values {\n        white-space: pre;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
