import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { GridsService } from '@app/services/grids.service';

@Component({
    selector: 'app-dashboard-projects-live-dummy',
    templateUrl: './dashboard-projects-live-dummy.component.html',
    styleUrls: ['./dashboard-projects-live-dummy.component.scss', '../../../../grids/grids.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardProjectsLiveDummyComponent implements OnInit, AfterViewInit {

    @Output() onGridReadyEvent = new EventEmitter<string>();
    @Output() rowClickedEvent = new EventEmitter<string>();

    @Input() rowData: Array<any>;
    @Input() columnDefs: Array<any>;

    constructor(
        public gridsService: GridsService,
    ) { }

    onGridReady($event) {
        this.onGridReadyEvent.emit($event);
    }

    rowClicked($event) {
        this.rowClickedEvent.emit($event);
    }

    ngOnInit() { }

    ngAfterViewInit() { }
}
