// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        :host ::ng-deep .lm-table-other-surcharges .lm-table-action-delete{display: none}
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1cmNoYXJnZXMuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7UUFDUSxtRUFBbUUsYUFBYSIsImZpbGUiOiJzdXJjaGFyZ2VzLmNvbXBvbmVudC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgICA6aG9zdCA6Om5nLWRlZXAgLmxtLXRhYmxlLW90aGVyLXN1cmNoYXJnZXMgLmxtLXRhYmxlLWFjdGlvbi1kZWxldGV7ZGlzcGxheTogbm9uZX1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/settings/surcharges/surcharges.component.ts"],"names":[],"mappings":";QACQ,mEAAmE,aAAa;;AAExF,4XAA4X","sourcesContent":["\n        :host ::ng-deep .lm-table-other-surcharges .lm-table-action-delete{display: none}\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
