import { Component, OnInit, ViewChild, OnDestroy, HostBinding } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-move-stops-to-problem-modal',
  templateUrl: './move-stops-to-problem-modal.component.html',
  styleUrls: ['./move-stops-to-problem-modal.component.scss']
})
export class MoveStopsToProblemModalComponent implements OnInit, OnDestroy {

  moveStopPointsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/move-stop-points';
  listen = [];
  isClickedOnce = false;
  projectProblemId;
  projectProblems = [];
  selectedProjectProblemId;
  selectedStopPointIds = [];
  updateStopPointIds = [];

  constructor(
    private http: HttpClient,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    public globals: Globals,
    public genericService: GenericService,
  ) {
    this.listen.push(this.viewProjectProblemService.moveStopPointsToProblemListen().subscribe((selectedStopPointIds) => {
      this.loadMoveStopsData();
      this.selectedStopPointIds = selectedStopPointIds;
      this.openMoveStopsModal();
    }));
  }

  loadMoveStopsData() {
    const self = this;
    this.http.get('api/v1/project/problems?pageSize=200&projectProblemId=' + this.projectProblemId).pipe(take(1)).subscribe(projectProblems => {
      projectProblems['items'].forEach(projectProblem => {
        if (
          projectProblem.projectProblem.entity_status !== self.globals.projectProblemEntityStatusConstants['COMPLETED'] &&
          projectProblem.projectProblem.id !== Number(self.projectProblemId)
        ) {
          projectProblem['date'] = moment(projectProblem['projectProblem']['departure_datetime']).format('DD MMM YYYY');
          self.projectProblems.push(projectProblem);
          projectProblem['drivers'] = projectProblem['projectProblem']['routeCount'];
          if (projectProblem['projectProblem']['totalDistance'] || projectProblem['projectProblem']['totalTime']) {
            let totalTimeString = '';
            if (projectProblem['projectProblem']['totalTime']) {
              const totalTimeHours = moment.duration(projectProblem['projectProblem']['totalTime']).asHours();
              const totalTimeHoursFloor = Math.floor(totalTimeHours);
              const totalTimeHoursMinutes = moment.duration((totalTimeHours - totalTimeHoursFloor), 'hours').asMinutes();
              totalTimeString = totalTimeHoursFloor + 'h ' + Math.round(totalTimeHoursMinutes) + 'm';
            }
            projectProblem['stats'] = projectProblem['projectProblem']['totalDistance'] + 'km/' + totalTimeString;
          }
        }
      });
    });
  }

  boxClicked(projectProblem) {
    this.selectedProjectProblemId = projectProblem.projectProblem.id;
    this.projectProblems.forEach(function (currentProjectProblem, index) {
      currentProjectProblem.selected = false;
    });
    projectProblem.selected = true;
  }

  submitStops() {
    if (this.selectedProjectProblemId) {
      const self = this;
      this.isClickedOnce = true;
      self.selectedStopPointIds.forEach(selectedStopPointId => {
        self.updateStopPointIds.push(selectedStopPointId);
      });
      const data = {
        moveStopPoints: {
          toProjectProblemId: this.selectedProjectProblemId,
          stopPointsIds: self.updateStopPointIds
        }
      };
      const myObserver = {
        next: (response) => {
          self.isClickedOnce = false;
          const sameDayDeliveryStops = this.projectProblemDataService.sameDayDeliveryChecker(self.updateStopPointIds);
          if (sameDayDeliveryStops.length) {
            self.updateStopPointIds = self.updateStopPointIds.concat(sameDayDeliveryStops);
          }
          self.updateStopPointIds.forEach(stopPointId => {
            self.projectProblemDataService.removeStopPoint(self.projectProblemDataService.stopPoints[stopPointId]);

          });
          self.viewProjectProblemService.updateProjectProblemStatus();
          self.closeMoveStopsModal();
          this.updateStopPointIds = [];
        },
        error: (error) => {
          self.isClickedOnce = false;
          this.updateStopPointIds = [];
          console.error(error);
        },
        complete: () => { },
      };
      const moveStopPointsUrl = this.moveStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      this.http.post(moveStopPointsUrl, JSON.stringify(data)).pipe(take(1)).subscribe(myObserver);
    }
  }


  emptyMoveStopsModal() {
    const selectedBox = document.querySelector('.modal-box.selected');
    if (selectedBox) {
      selectedBox.classList.remove('selected');
    }
    // this.manualModifiedRouteData = {};
    this.projectProblems = [];
    this.selectedProjectProblemId = null;
    this.selectedStopPointIds = [];
  }

  openMoveStopsModal() {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.move-stops-to-problem-modal');
    const modalBackground = document.querySelector('.move-stops-to-problem-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
  }

  closeMoveStopsModal() {
    const modal = document.querySelector('.move-stops-to-problem-modal');
    const modalBackground = document.querySelector('.move-stops-to-problem-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyMoveStopsModal();
    this.viewProjectProblemService.disableMapMultipleSelect();
  }

  ngOnInit() {
    this.projectProblemId = this.projectProblemDataService.projectProblemId;
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
