import { Inject, Injectable, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { Globals } from '@app/services/globals';
import { INITIAL_DATA } from '@app/model/initial-data';
import { ColourService } from '@app/services/colour.service';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable()
export class DashboardReportViewModelService implements OnInit, OnDestroy {

    constructor(
        injector: Injector, 
        // @Inject(forwardRef(() => LmDashboardReportApiServices)) public apiSvc: LmDashboardReportApiServices,
        // public globals: Globals,
        // private colourService: ColourService,
        // private doms : DomSanitizer
    ){
    }

    getShipments(): Observable<any>{
        return of(null)
    }
    getProductivity(): Observable<any>{
        return of(null)
    }
    getDrivers(): Observable<any>{
        return of(null)
    }
    getDeliveries(): Observable<any>{
        return of(null)
    }
    getBranches(): Observable<any>{
        return of(null)
    }
    getExperience(): Observable<any>{
        return of(null)
    }
    getLive(): Observable<any>{
        return of(null)
    }
    getActivity(): Observable<any>{
        return of(null)
    }
    ngOnInit(): void {
    }

    ngOnDestroy() {
    }



}
