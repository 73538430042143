/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LmReportsApiService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
      super(config, http);
    }

  //shipments-statistics
  static readonly apiReportsOverviewGetPath = 'api/internal/v2/statistics/shipments-overview-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiReportsShipmentsGetPath = 'api/internal/v2/statistics/shipment-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiReportsDriversGetPath = 'api/internal/v2/statistics/driver-shipment-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';
  static readonly apiCancelationGetPath = 'api/internal/v2/statistics/canceled-shipment-statistics?untilDateTime={untilDateTime}&daysBeforeUntilDateCount={daysBeforeUntilDateCount}&projectId={projectId}&shipment_type={shipment_type}';


     // OVERVIEW
     apiReportsOverviewGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
      const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsOverviewGetPath, 'get');
      if(params){
          rb.path('untilDateTime', params.untilDateTime);
          rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
          rb.path('projectId', params.projectId);
          rb.path('shipment_type', params.shipment_type);
      }
      return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
    }
    
    apiReportsOverviewGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
        return this.apiReportsOverviewGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
    }


   // SHIPMENTS
   apiReportsShipmentsGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsShipmentsGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiReportsShipmentsGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
      return this.apiReportsShipmentsGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }
  
 
  // DRIVERS
  apiReportsDriversGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiReportsDriversGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiReportsDriversGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
      return this.apiReportsDriversGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }

  // DRIVERS
  apiCancelationGet$Response(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, LmReportsApiService.apiCancelationGetPath, 'get');
    if(params){
        rb.path('untilDateTime', params.untilDateTime);
        rb.path('daysBeforeUntilDateCount', params.daysBeforeUntilDateCount);
        rb.path('projectId', params.projectId);
        rb.path('shipment_type', params.shipment_type);
    }
    return this.http.request(rb.build({responseType: 'json', accept: 'application/json'})).pipe(filter((r: any) => r instanceof HttpResponse), map((r: HttpResponse<any>) => r as StrictHttpResponse<Array<any>>));
  }

  apiCancelationGet(params?: { untilDateTime?: string, daysBeforeUntilDateCount?: string, projectId?: string, shipment_type?: string}): Observable<Array<any>> {
      return this.apiCancelationGet$Response(params).pipe(map((r: StrictHttpResponse<Array<any>>) => r.body as Array<any>));
  }


   

}