import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, AfterViewInit, NgModule, ViewChild, ElementRef } from '@angular/core';
import { ILmCSemiCircleChartOptions, ILmCSemiCircleChartWidgetTab } from '@app/model/widget';
import { HEXtoRGB, mergeObjects } from '../utils';

@Component({
    selector: 'lm-semicircle-chart',
    template: `
        <div class="grid lm-height lm-pdt10">
            <div class="col-12 lm-height lm-ai-center-flex">
                <div class="lm-jc-center-flex lm-ai-center-flex lm-width lm-rel lm-semicircle" [style.height.px]="height">
                    <div class="-content lm-jc-center-flex">
                        <i [ngClass]="{'lm-icon': true}" 
                            [ngStyle]="{'color':chartOptions.valuecolor, 'backgroundColor': iconBgColor ?? '#eee'}"
                            [attr.class]="chartOptions.iconClass" 
                            style="">
                        </i>
                        <div class="-value">
                            <span>{{value}}</span>
                            <span *ngIf="chartOptions.percent">%</span>
                        </div>
                    </div>
                    <canvas #rangeCanvas [attr.width]="width" [attr.height]="height"></canvas>
                    <canvas #valueCanvas [attr.width]="width" [attr.height]="height"></canvas>
                    <div class="-limits lm-jc-space-between-flex" [style.width.px]="width">
                        <div class="-limit lm-txt-center">
                            <span>{{chartOptions.min}}</span>
                            <span *ngIf="chartOptions.percent">%</span>
                        </div>
                        <div class="-limit lm-txt-center">
                            <span>{{chartOptions.max}}</span>
                            <span *ngIf="chartOptions.percent">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        canvas{display: block; position: absolute}
        .-content{position: absolute; flex-flow: column nowrap; text-align:center;border-bottom: 1px solid #ccc;padding: 1.5rem 1.2rem .5rem 1.2rem}
        .-content i[class*="icon"]{margin: 0 auto;display: block; width: 56px; height: 56px; border-radius: 100%; font-size: 2.3rem !important; line-height: 180%;}
        .lm-icon{font-size:3.2rem !important}
        .-value{font-size:3.2rem; font-weight:500}
        .-limits{position:absolute;bottom:0;}
        .-limit{min-width:27px}
    `]
})

export class LmSemiCircleChartComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('rangeCanvas') rangeCanvas: ElementRef;
    @ViewChild('valueCanvas') valueCanvas: ElementRef;
    rctx: CanvasRenderingContext2D | CanvasState | any;;
    vctx: CanvasRenderingContext2D | CanvasState | any;

    @Input() value: number;
    @Input() options: ILmCSemiCircleChartOptions;
    @Input() width = 300;
    @Input() height = 190;
    @Input() lineWidth = 26;
    protected readonly rotate = -90;
    protected readonly lineCap = 'round';
    private raf: number;
    private radius: number;
    private xy: number;
    private step = 0;
    
    iconBgColor: string;
    chartOptions: ILmCSemiCircleChartOptions;
    baseOptions: ILmCSemiCircleChartOptions ={
        iconClass: 'icon-dashboard-menu',
        min: 0,
        max: 100,
        percent: false,
        arcColor: '#eee',
        valuecolor: undefined
    };

    
    constructor(){}

    refresh(tab:ILmCSemiCircleChartWidgetTab){
        this.chartOptions = mergeObjects(this.baseOptions, tab.chartOptions ?? {});
        if(this.raf) cancelAnimationFrame(this.raf);
        this.step = 0;
        const {valuecolor} = this.chartOptions;
        this.iconBgColor = HEXtoRGB(valuecolor, 0.3);
        this.animate(this.vctx, this.step, valuecolor);
    }

    private animate(_ctx:CanvasRenderingContext2D, _value, _color){
        const lim = this.xy-this.lineWidth;
        const layer = this.width*2;

        _ctx.save()
        _ctx.setTransform(1,0,0,1,0,0);
        _ctx.clearRect(lim, lim, layer, layer);
        _ctx.restore();    

        this.render(_ctx, this.step/100, _color);
        this.step++;
        
        if(this.step < this.value+1) this.raf = requestAnimationFrame(()=> this.animate(_ctx, this.step/100, _color));
    }

    private render(_ctx, _value, _color){
        const percent = Math.min(Math.max(0, _value), 1);
        _ctx.beginPath();
		_ctx.arc(this.xy, this.xy, this.radius, 0, Math.PI * percent, false);
        this.colorize(_ctx, _color);
    }


    private bootstrap(){
        this.radius = (this.width - this.lineWidth) / 2;
        this.xy = (this.width/2)*-1;
        this.rctx = this.rangeCanvas.nativeElement.getContext('2d');
        this.vctx = this.valueCanvas.nativeElement.getContext('2d');
        this.setCanvas(this.rctx);
        this.setCanvas(this.vctx);
    }

    private setCanvas(_ctx:CanvasRenderingContext2D){
        _ctx.rotate((-1 / 2 + this.rotate / 180) * Math.PI);
        _ctx.lineCap = this.lineCap;
		_ctx.lineWidth = this.lineWidth;
    }

    private colorize(_ctx, _color){
        _ctx.strokeStyle = _color;
        _ctx.stroke();
    }


    ngOnInit() {
        this.chartOptions = mergeObjects(this.baseOptions, this.options ?? {});
        const {valuecolor} = this.chartOptions;
        this.iconBgColor = HEXtoRGB(valuecolor, 0.3);
    }

    ngAfterViewInit(): void {
        this.bootstrap();
        this.render(this.rctx, 100 / 100, this.chartOptions.arcColor);
        if(this.value) this.render(this.vctx, this.value / 100, this.chartOptions.valuecolor);
    }

    ngOnDestroy() {}
}

@NgModule({
    imports: [CommonModule],
    exports: [LmSemiCircleChartComponent],
    declarations: [LmSemiCircleChartComponent]
  })
  export class LmSemiCircleChartModule {}