import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Observable, Subscription, finalize, mergeWith, of, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardReportViewModelService, LmDashboardReportQueryType, LmReportsQueryFragmentType } from './dashboard-report-viewmodel.service';
import { ILmCSemiCircleChartWidgetTab, ILmChartWidgetTab } from '@app/model/widget';
import { SHIPMENTS as sc, PRODUCTIVITY as pc, VERTICAL as vc, VERTICAL_OPTIONS as vco, DOUGHNUT as dc, DRIVERS as drc, BRANCHES as brc, SEMICIRCLE as smc, GENERAL_PERFORMANCE, DELIVERIES_OVERVIEW, EXPERIENCE_OVERVIEW, PRODUCTIVITY_OVERVIEW, SHIPMENTS_OVERVIEW} from './dashboard-report-dummy-data';
import { DELIVERIES as deliveriesTabs} from './dashboard-report-dummy-data';
import { LmProgressCardConfig } from '@app/shared/structure/progress-card/progress-card.component';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { Globals } from '@app/services/globals';
import { ImageUtils } from '@app/utils/image-utils';
import { HttpClient } from '@angular/common/http';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmNotificationService } from '@app/core/services/notification.service';
import { TransportationRegionModalComponent } from '@app/settings/transportation-regions/new-transportation-region-modal/new-transportation-region-modal.component';
import { LmCalendarComponent } from '@app/shared/containers/calendar-dialog/calendar-dialog.component';

@UntilDestroy()
@Component({
    selector: 'dashboard-report',
    templateUrl: './dashboard-report.component.html',
    providers: [DashboardReportViewModelService] 
})
export class DashboardReportComponent implements OnInit, OnDestroy, AfterViewInit {
  calendarComponent = LmCalendarComponent;
  shipments: ILmChartWidgetTab[];
  productivity: ILmChartWidgetTab[];
  drivers: ILmChartWidgetTab[];
  branches: ILmChartWidgetTab[];
  cancelation: ChartData | any;
  doughnut: ChartData | any;
  deliveries: any;
  semicircle: ILmCSemiCircleChartWidgetTab[];
  performance: any;

  verticalOptions: ChartOptions;
  shipmentProgressOptions: LmProgressCardConfig;
  productivityProgressOptions: LmProgressCardConfig;
  experienceProgressOptions: LmProgressCardConfig;
  deliveriesProgressOptions: LmProgressCardConfig;

  activeProjects;
  projectsArrayForSelect;
  
  periodFragments: LmButtonConfig[];
  liveNowFilterQuery:string;
  
  filterLiveNow$: (e) => Observable<any[]>;
  fragmentRequest$: (...args) => Subscription;
  promptCalendar: () => void;

  constructor(
    private notificationSvc: LmNotificationService, 
    public VM: DashboardReportViewModelService, 
    public globals: Globals, 
    private _injector: Injector,
    public selectMapperSvc: SelectMapperService,
  ){
    // this.shipments = [...sc];
    this.productivity = [...pc];
    this.drivers = [...drc];
    this.branches = [...brc];
    this.cancelation = {...vc};
    this.doughnut = {...dc}
    this.semicircle = [...smc];
    this.deliveries = deliveriesTabs;
    this.performance = GENERAL_PERFORMANCE
    this.verticalOptions = {...vco};
    this.shipmentProgressOptions = SHIPMENTS_OVERVIEW
    this.productivityProgressOptions = PRODUCTIVITY_OVERVIEW
    this.experienceProgressOptions = EXPERIENCE_OVERVIEW
    this.deliveriesProgressOptions =DELIVERIES_OVERVIEW

    this.activeProjects = [];
    this.projectsArrayForSelect = this.globals.projectsArrayForSelect;
    this.periodFragments = ['day', 'week', 'month','year'].map(fragment => ({label: fragment}))
    
    this.filterLiveNow$ = (e) => {
      return of(null)
    }

    this.fragmentRequest$ = (fragment) => {
      return this.VM.fragmentRequest$(fragment).pipe(untilDestroyed(this)).subscribe(res => {
        console.log('res:', res)
      });
    }

    this.promptCalendar = () => {
      this.notificationSvc
            .showDialog<boolean>({ 
              componentType: this.calendarComponent,   
              injector: this._injector, 
              data: {VM: this, globals: this.globals} 
            })
            .subscribe();
    }
  }

  

  newProjectProblem(){}


  ngAfterViewInit(): void {}
  ngOnInit() {
    this.VM.getAll$(this.VM.query).pipe(untilDestroyed(this)).subscribe();
  //   if (this.globals.companyLogoHash || (this.globals.collaboratorModeEnabled && this.globals.portalSettings['live_tracking_image_hash'])) {
  //     this.loadMainLogoAsync(this.globals.companyLogoHash);
  // }
  }
  ngOnDestroy() {}
}
