import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Globals } from '@app/services/globals';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  // constructor(public router: Router) { }
  constructor(
    public auth: AuthService,
    public router: Router,
    public globalConstants: Globals,
  ) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
