import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

declare var H: any;

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent implements OnInit, AfterViewInit, OnDestroy {

  isPlace = '';

  constructor(  ) {  }

  openTermsModal() {
    const modal = document.querySelector('.terms-modal');
    const modalBackground = document.querySelector('.terms-modal-background');
    modal.classList.remove('closed');
    modal.classList.add('open');
    modalBackground.classList.remove('hidden');
  }

  closeTermsModal() {
    const modal = document.querySelector('.terms-modal');
    const modalBackground = document.querySelector('.terms-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
  }

  ngOnInit() {
  }

  public ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

}
