import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, HostBinding, Injector, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LmInputBase } from '../input-base';
import { LmTextIconStyle } from '@app/model/icons-model';

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmSwitchComponent), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmSwitchComponent) };

@Component({
  selector: 'lm-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LmSwitchComponent extends LmInputBase implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  searchType = 'string';

  @HostBinding() id = `lm-switch-${LmSwitchComponent.nextId++}`;

  @ViewChild(NgModel) model: NgModel;

  @Input() tooltip?: string;
  @Input() tooltipEvent = 'focus';
  @Input() tooltipPosition = 'top';
  @Input() iconClass = '';
  @Input() iconSvgClass = '';
  @Input() iconSvg?: string;
  @Input() iconSize?: string;
  @Input() iconColor?: string;
  @Input() inputId?: string;
  @Input() ariaLabelledBy?: string;
  @Input() ariaLabel?: string;
  @Input() style?: object;
  @Input() styleClass?: string;
  @Input() labelStyleClass?: string;
  @Input() labelEnd?: string;
  @Input() checked: boolean;
  @Input() readonly = false;
  @Input() trueValue?: any;
  @Input() falseValue?: any;
  @Input() customIconName = "";
  iconStyle: LmTextIconStyle;
  iconSvgPath: string;
  private svgsPath = 'assets/icons/icons.svg#'

  constructor(injector: Injector) {
    super(injector);

    this.iconStyle = {
      'font-size': this.iconSize || '1.4rem',
      'color': this.iconColor || '#d3d3d3'
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.iconSvg) this.iconSvgPath = this.svgsPath + this.iconSvg;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputSwitchModule
  ],
  exports: [LmSwitchComponent, InputSwitchModule],
  declarations: [LmSwitchComponent]
})
export class LMSwitchModule {}
