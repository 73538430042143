import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageUtils {
    FETCH_VIA_HASH_REQUESTS_LIMIT = 500; // Hard limit for requests in case something goes wrong

    constructor(
        private http: HttpClient,
    ) { }

    async fetchImagesViaHashes(url: string, imageHashes: Array<string>) {
        let fetchedImages = [];
        let requestsCount = 0;

        const fetchImage = (hash) => {
            return new Promise<void>((resolve) => {
                const imageUrl = `${url}/${hash}`;
                requestsCount++;

                this.http.get(imageUrl).subscribe(res => {
                    if (res['base64']) {
                        fetchedImages.push(res['base64']);
                        fetchedImages = [...fetchedImages];
                    }

                    resolve();
                });
            });
        };

        // An array of promises that we will wait for using .all
        const fetchPromises = imageHashes.map(hash => {
            const hasHitRequestLimit = requestsCount >= this.FETCH_VIA_HASH_REQUESTS_LIMIT ? true : false;
            if (requestsCount < imageHashes.length && !hasHitRequestLimit) {
                return fetchImage(hash);
            } else if (hasHitRequestLimit) {
                console.warn(`[WARNING] Maximum requests limit reached! Make sure that you actually want to perform more than ${this.FETCH_VIA_HASH_REQUESTS_LIMIT} requests & change FETCH_VIA_HASH_REQUESTS_LIMIT!`);
                console.warn(`[WARNING] You might be missing a few images because the request limit was reached!`);
            }
            return Promise.resolve();
        });

        await Promise.all(fetchPromises);

        return fetchedImages;
    }
}
