import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { AddButtonBaseConfig as abbc } from '@app/model/buttons-model';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { SettingsRevaluationsViewModelService } from './revaluations-viewmodel.service';
import { RevaluationModalComponent } from './revaluation-modal/revaluation-modal.component';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';


@Component({
    selector: 'revaluations',
    template: `<lm-table-list [cols]="cols" [VM]="VM" [headerBtn]="hdrBtn" [title]="title" [hideActions]="true"></lm-table-list>`,
    providers: [SettingsRevaluationsViewModelService, LmModelProxyService]
})
export class RevaluationsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() title: string;
    revaluationModal = RevaluationModalComponent;
    cols: LmTableBaseColumnDefs[];
    hdrBtn: LmButtonConfig;

    addonCols: LmTableBaseColumnDefs[];
    addonHdrBtn: LmButtonConfig;
    listen;

    constructor(
        public VM: SettingsRevaluationsViewModelService,
        private notificationSvc: LmNotificationService, 
        private _injector: Injector,
        public translate: TranslateService,
        public globals: Globals,
        private activatedRoute: ActivatedRoute,
    ) {
        this.listen = this.activatedRoute.params.subscribe(params => {
            if(params['id']) this.VM.collaboratorId = params['id'];
        });
        this.hdrBtn = {...abbc, command: this.showRevaluationModal}
        this.translate.get('REVALUATIONS').pipe(
            tap(response => {
                this.cols = [
                    {header: response['DATE'], field: 'creation_datetime', date: true}, 
                    {header: response['AMOUNT'], field: 'valueCaption', raw: 'value'},
                    {header: response['PRICE_LISTS'], field: 'priceListCaption', raw: 'applicable_price_list_type'}, 
                    {header: response['CHARGES'], field: 'categoriesCaption', raw: 'categories'}
                ]  
                this.VM.modalConfig.data['translations'] = response;
            })
        ).subscribe();
        this.translate.get('WARNINGS').pipe(
            tap(response => {
                this.VM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
            })
        ).subscribe();
    }



    private showRevaluationModal = () => {
        const newItem = {...this.VM.newItem()};
        if (this.VM.collaboratorId) {
            newItem.is_collaborator_specific = true;
            newItem.collaborator_id = Number(this.VM.collaboratorId);
        }
        this.notificationSvc.showDialog<boolean>({ 
            componentType: this.revaluationModal,   
            injector: this._injector, 
            data: {VM: this.VM, globals: this.globals, item: newItem} 
        })
        .subscribe();
    }
    
    ngAfterViewInit(): void {}
    ngOnInit() {  }
    ngOnDestroy() { this.listen.unsubscribe(); }
}
