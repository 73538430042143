import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { MemberFormComponent } from '@app/forms/member-form/member-form.component';
import { VehicleFormComponent } from '@app/forms/vehicle-form/vehicle-form.component';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GenericService } from '@app/services/generic.service';

@Component({
  selector: 'app-right-form-modal',
  templateUrl: './right-form-modal.component.html',
  styleUrls: ['./right-form-modal.component.scss']
})
export class RightFormModalComponent implements OnInit, OnDestroy {
  ActionType: String = '';
  FormName: String = '';
  newLabel;
  editLabel;
  memberLabel;
  vehicleLabel;
  @ViewChild(MemberFormComponent, { static: false }) memberFormComponent: MemberFormComponent;
  @ViewChild(VehicleFormComponent, { static: false }) vehicleFormComponent: VehicleFormComponent;

  listen = [];

  constructor(
    public translate: TranslateService,
    private modalService: ModalService,
    private modalGridService: ModalGridService,
    public genericService: GenericService,
  ) {
    // TODO add type (create/delete)
    // this.modalService.listen().subscribe((form: any) => {
    //   this.toggleModal(form);
    // });
    this.listen.push(this.modalService.listen().subscribe((response: any) => {
      this.toggleModal(response.form, response.type, response.id);
    }));
    this.listen.push(this.modalGridService.listenCloseRightModal().subscribe((response: any) => {
      this.closeModal();
    }));
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.EDIT').subscribe((res: string) => { this.editLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.NEW').subscribe((res: string) => { this.newLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.VEHICLE').subscribe((res: string) => { this.vehicleLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.OFFICE_MEMBER').subscribe((res: string) => { this.memberLabel = res; }));
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  closeModal() {
    const modal = document.querySelector('.right-form-modal');
    const modalBackground = document.querySelector('.right-form-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.memberFormComponent.resetForm();
    this.vehicleFormComponent.resetForm();
  }

  toggleModal(form, type, id) {
    this.genericService.comm100ZIndexFix();
    const modal = document.querySelector('.right-form-modal');
    const modalBackground = document.querySelector('.right-form-modal-background');
    modal.classList.toggle('closed');
    modal.classList.toggle('open');
    modalBackground.classList.toggle('hidden');

    // if modal is opening display only selected form
    // if it's closing reset the forms
    if (modal.classList.contains('open')) {
      [].map.call(modal.querySelectorAll('.modal-form'), function (element) {
        element.classList.add('hidden');
      });
      const formToDisplay = modal.querySelector('#' + form + '-form-container');
      formToDisplay.classList.remove('hidden');
      this.memberFormComponent.initializeSelect();
      this.vehicleFormComponent.initializeSelect();
    } else if (modal.classList.contains('closed')) {
      this.memberFormComponent.resetForm();
      this.vehicleFormComponent.resetForm();
    }

    // if modal is for edit
    if (type === 1) {
      if (form === 'member') {
        this.memberFormComponent.getFormData(id);
      } else if (form === 'vehicle') {
        this.vehicleFormComponent.getFormData(id);
      }
      this.changeModalTitle(this.editLabel, form);
    } else if (type === 0) {
      this.changeModalTitle(this.newLabel, form);
    }

  }

  changeModalTitle(actionType: String, formName: String) {
    this.ActionType = actionType;
    if (formName === 'member') {
      this.FormName = this.memberLabel;
    } else if (formName === 'vehicle') {
      this.FormName = this.vehicleLabel;
    }
  }

}
