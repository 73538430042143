import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { GroupValueFn } from '@ng-select/ng-select/lib/ng-select.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CheckboxModule } from 'primeng/checkbox';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject, isObservable, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { LmHeaderTemplateDirective, LmLabelTemplateDirective, LmOptionTemplateDirective, LmSelectorTemplatesModule } from '../../../directives/selector-templates.directive';
import { LmSelectColumnDefs } from '../../../../model/select-column-defs';
import { LmColumnMetadataToGridColSizeModule } from '../../../pipes/column-metadata-to-grid-col-size.pipe';
import { LmSelectorDisplayValueModule } from '../../../pipes/selector-label.pipe';
import { LmSelectorOptionValueModule } from '../../../pipes/selector-option.pipe';
import { LmSelectorCacheService } from '../../../../core/services/cache/selector-cache.service'
import { LmNotificationService } from '../../../../core/services/notification.service';
import { hasValue } from '../../../utils';
import { LmFilterOperatorModule } from '../../filter-operator/filter-operator.component';
import { LmInputBase } from '../input-base';
import { LmInputActionsModule } from '../../input-actions/input-actions.component';

export type SelectOptionsKeys = Exclude<keyof LmSelect2Component, 'selectOptions' | 'id' | 'ngSelect' | 'value' | 'disabled' | 'dataSource$' | 'isLoading' | Function>;
export type SelectOptions = Partial<{ [key in SelectOptionsKeys]: LmSelect2Component[key] }>;
export type CacheKeyFn = () => string;

const VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LmSelect2Component), multi: true };
const LM_INPUT_BASE = { provide: LmInputBase, useExisting: forwardRef(() => LmSelect2Component) };

@UntilDestroy()
@Component({
  selector: 'lm-select2',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, LM_INPUT_BASE]
})
export class LmSelect2Component extends LmInputBase implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, ControlValueAccessor {
  @HostListener('focusout')
  ngSelectFocusOut() {
    // Display the label span.
    if (!!this.labelHTMLCollection && this.labelHTMLCollection.length > 0) (this.labelHTMLCollection[0] as HTMLElement).style.display = 'block';
  }
  protected static _nextId = 0;

  @Input() selectOptions: SelectOptions;
  @Input() data$: Observable<any[]> | ((term: string) => Observable<any[]>);
  @Input() valueResolver: (id: any) => Observable<any>;
  @Input() columnMetadata: Array<LmSelectColumnDefs> = [];
  @Input() columnChecker: boolean = false;
  @Input() useCacheForData: boolean;
  @Input() cacheKey: string | CacheKeyFn | Observable<string>;
  @Input() searchable = true;
  @Input() bindLabel = 'description';
  @Input() bindValue = 'id';
  @Input() isLoading: boolean;
  @Input() clearable = true;
  @Input() multiple = false;
  @Input() closeOnSelect = true;
  @Input() hideSelected = true;
  @Input() showTitleInOptions = true;
  @Input() showOptionsHeader: boolean;
  @Input() optionsDropdownWidth: string;
  @Input() virtualScroll = true;
  @Input() autoWrapItems = true;
  @Input() clearAllText = 'Καθαρισμός';
  @Input() loadingText = 'Φόρτωση...';
  @Input() notFoundText = 'Δε βρέθηκαν εγγραφές';
  @Input() typeToSearchText = 'Πληκτρολογήστε για αναζήτηση...';
  @Input() groupByFn: string | Function;
  @Input() appendTo: string;
  @Input() ellipsis = false;
  @Input() outlined = false;
  @Input() iconClass = '';
  @Input() iconSvgClass = '';
  @Input() iconSvg?: string;
  @Input() iconSize?: string;
  @Input() iconColor?: string;
  @Input() isGridEditor = false;
  @Input() isPhoneInput = false;
  @Input() resolvedData = (item: any) => Observable<any | any[]>;
  @Input() groupValueFn: GroupValueFn;
  @Input() selectorDisplayValue: (item: any) => Observable<string>;
  @Input() selectorOptionValue: (item: any) => Observable<string>;
  @Input() selectorSearchValue: (data: any[], term: string) => Observable<any[]>;
  @Input() optionsSort: (data: any[], sortField: string, sort: 'asc' | 'desc') => Observable<any[]> = (data: any[], sortField: string, sort: 'asc' | 'desc') => {
    data = data.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sort === 'asc' ? -1 : 1;
      else if (a[sortField] > b[sortField]) return sort === 'asc' ? 1 : -1;
      return 0;
    });

    return of(data);
  };

  @HostBinding()
  id = `lm-select-${LmSelect2Component._nextId++}`;
  name = `lm-select-${LmSelect2Component._nextId++}`;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild(Dropdown) operatorCtrl: Dropdown;
  @ViewChild('selectCtrl') ngSelectCtrl: NgSelectComponent;

  @ContentChild(LmLabelTemplateDirective, { read: TemplateRef }) labelTemplate: TemplateRef<any>;
  @ContentChild(LmHeaderTemplateDirective, { read: TemplateRef }) headerTemplate: TemplateRef<any>;
  @ContentChild(LmOptionTemplateDirective, { read: TemplateRef }) optionTemplate: TemplateRef<any>;

  searchType = 'select';
  displayValue: string;
  curSort: { field: string; sort: 'asc' | 'desc' };
  __dataSource$ = new BehaviorSubject<any>([]);
  __typeahead$ = new Subject<string>();
  __resolvedResponce$ = new BehaviorSubject<any>([] || {})
  labelHTMLCollection: any;
  inputHTMLCollection: any;
  clearHTMLCollection: any;
  floatLabel = false;

  protected elRef: ElementRef;

  constructor(injector: Injector, protected cacheSvc: LmSelectorCacheService) {
    super(injector);
    this.elRef = injector.get<ElementRef>(ElementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setSelectOptions();
    this.setupSearch();

    this.changed.push(() => this.resolveDisplayValue());

    if (!!this.optionsDropdownWidth) {
      this.elRef.nativeElement.style.setProperty('--ng-option-width', this.optionsDropdownWidth);
    }
  }

  ngAfterContentInit(): void {
    super.ngAfterContentInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // Initialise select input & label.
    this.labelHTMLCollection = this.ngSelectCtrl.element.getElementsByClassName('ng-value-label');
    this.inputHTMLCollection = this.ngSelectCtrl.element.getElementsByTagName('input');
    this.clearHTMLCollection = this.ngSelectCtrl.element.getElementsByClassName('ng-clear');

    if (this.autoWrapItems) {
      this.ngSelectCtrl.classes += ' lm-wrap-items';
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.__dataSource$.complete();
    this.__resolvedResponce$.complete();
  }

  // *Input element is different from first span shown the display value.
  private instantEditing(): void {
    // Firstly show the input element.
    (this.inputHTMLCollection[0] as HTMLElement).style.display = 'block';

    // Ηide the label span.
    if (!!this.labelHTMLCollection && this.labelHTMLCollection.length > 0 && this.searchable) (this.labelHTMLCollection[0] as HTMLElement).style.display = 'none';

    // Assign the resolved value to user's Input so he can edit it.
    if (!!this.inputHTMLCollection && this.inputHTMLCollection.length > 0 && this.displayValue !== undefined) {
      this.inputHTMLCollection[0].value = this.displayValue;
    }

    // Clear icon by default erase the display value.
    // We want to erase the input value as well.
    if (!!this.clearHTMLCollection && this.clearHTMLCollection.length > 0) {
      let clearIcon = this.clearHTMLCollection[0] as HTMLElement;

      if (this.displayValue !== undefined) {
        clearIcon.style.display = 'block';
        clearIcon.style.pointerEvents = 'all';
        clearIcon.addEventListener('click', () => {
          this.inputHTMLCollection[0].value = '';
          this.displayValue = '';
          this.value = '';
          clearIcon.style.display = 'none';
        });
      } else {
        clearIcon.style.display = 'none';
      }
    }
  }

  onChange(change): void {
    if (change !== undefined) {
      (this.inputHTMLCollection[0] as HTMLElement).style.display = 'none';
      setTimeout(() => {
        if ((this.labelHTMLCollection[0] as HTMLElement)?.innerText !== undefined && (this.labelHTMLCollection[0] as HTMLElement)?.innerText !== '') {
          this.displayValue = (this.labelHTMLCollection[0] as HTMLElement).innerText;
        }
        this.inputHTMLCollection[0].value = this.displayValue;
        let span = this.labelHTMLCollection[0] as any;
        if (span !== undefined) span.parentElement.style.visibility = 'inherit';
      });
    }
  }

  onClose(): void {
    (this.inputHTMLCollection[0] as HTMLElement).style.display = 'none';
    setTimeout(() => {
      if ((this.labelHTMLCollection[0] as HTMLElement)?.innerText !== undefined && (this.labelHTMLCollection[0] as HTMLElement)?.innerText !== '') {
        this.displayValue = (this.labelHTMLCollection[0] as HTMLElement).innerText;
      }
      this.inputHTMLCollection[0].value = this.displayValue;
    }, 200);
  }

  onKey(event: KeyboardEvent): void {
    // Revert clear icon if user type something..
    let typedValue = (event.target as HTMLInputElement).value;
    if (typedValue !== '') {
      if ((this.clearHTMLCollection[0] as HTMLElement) !== undefined) (this.clearHTMLCollection[0] as HTMLElement).style.display = 'block';
    }
  }

  resolveDisplayValue(): void {
    if (this.skipResolveDisplayValue()) return;
    if (hasValue(this.value)) {
      // if (this.multiple) {
      //   this.getDataByIds(this.value)
      //     .pipe(
      //       untilDestroyed(this),
      //       take(1),
      //       switchMap((res: {}[]) => {
      //         this.refreshDataSource(res);
      //         if (!!this.selectorDisplayValue) {
      //           return this.selectorDisplayValue(res);
      //         } else {
      //           return of(Reflect.get(res, this.bindLabel));
      //         }
      //       })
      //     )
      //     .subscribe((displayValue: string) => this.refreshDisplayValue(displayValue));
      // } else { 
        this.getDataById(this.value)
          .pipe(
            untilDestroyed(this),
            take(1),
            switchMap((res: {}) => {
              if(!this.multiple) this.refreshDataSource([res]);
              this.__resolvedResponce$.next(res);

              if (!!this.selectorDisplayValue) {
                return this.selectorDisplayValue(res);
              } else {
                if (typeof res == 'object' && (typeof res == 'function' || res !== null)) {
                  return of(Reflect.get(res, this.bindLabel));
                } else {
                  return of({});
                }
              }
            })
          )
          .subscribe((displayValue: string) => this.refreshDisplayValue(displayValue));
      // }
    } else {
      this.refreshDataSource([]);
      this.refreshDisplayValue(null);
    }
  }

  skipResolveDisplayValue(): boolean {
    return this.ngSelectCtrl?.isOpen;
  }

  refreshDisplayValue(value: string) {
    this.displayValue = value;
    this.cdr.markForCheck();
  }

  onOpenDropdown(): void {
    this.refreshData();
    if (!this.ngSelectCtrl.multiple) this.instantEditing();
  }

  trackByFn(item: any): any {
    return item[this.bindValue];
  }

  refreshData(): void {
    this.isLoading = true;

    this.getData()
      .pipe(
        untilDestroyed(this),
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res) => this.refreshDataSource(res));
  }

  setupSearch(): void {
    if (!this.selectorSearchValue) {
      this.selectorSearchValue = (data: any[], term: string) => {
        const searchableColumns = this.columnMetadata?.filter((p) => !!p.searchable);
        return of(
          data.filter((val) => {
            if (searchableColumns.length) {
              for (let i = 0; i < searchableColumns.length; i++) {
                if (val[searchableColumns[i].field].toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
                  return true;
                }
              }
              return false;
            } // 
            else {
              return val[this.bindLabel].toLocaleLowerCase().includes(term.toLocaleLowerCase());
            }
          })
        );
      };
    }

    this.__typeahead$
      .pipe(
        untilDestroyed(this),
        switchMap((term) => this.getData().pipe(map((data) => ({ data, term })))),
        switchMap((res) => {
          if (!hasValue(res.term)) {
            return of(res.data);
          }
          return this.selectorSearchValue(res.data, res.term);
        })
      )
      .subscribe((res) => this.refreshDataSource(res));
  }

  sortData(sortField: string): void {
    this.getData()
      .pipe(
        untilDestroyed(this),
        take(1),
        switchMap((data) => {
          if (this.curSort?.field !== sortField) {
            this.curSort = null;
          }

          const newSort = this.curSort?.sort === 'asc' ? 'desc' : 'asc';
          this.curSort = { field: sortField, sort: newSort };

          return this.optionsSort(data, sortField, newSort);
        }),
        switchMap((sortedData) => this.refreshCachedData(sortedData)),
        map((sortedData) => sortedData.slice())
      )
      .subscribe((res) => this.refreshDataSource(res));
  }

  refreshCachedData(data: any[]): Observable<any[]> {
    if (!this.useCacheForData) return of(data);

    return this.getCacheKey().pipe(
      switchMap((cacheKey) => {
        if (!cacheKey) throw new Error(`Invalid cache key for ${this.id}`);

        return this.cacheSvc.get(cacheKey);
      }),
      map((cacheData$: ReplaySubject<any>) => {
        cacheData$?.next(data);

        return data;
      })
    );
  }

  protected setSelectOptions() {
    if (!this.selectOptions) return;

    for (const key in this.selectOptions) {
      if (this.selectOptions.hasOwnProperty(key)) {
        const element = this.selectOptions[key];
        this[key] = element;
      }
    }
  }

  protected getData(): Observable<any[]> {
    if (!this.data$) return of([]);
    const data$ = this.data$ as Observable<any[]>;

    if (this.useCacheForData) {
      return this.getCacheKey().pipe(
        switchMap((cacheKey) => {
          if (!cacheKey) throw new Error(`Invalid cache key for ${this.id}`);

          return this.cacheSvc.getOrAdd(cacheKey, () => data$);
        })
      );
    } else {
      return data$;
    }
  }

  protected getDataById(id: any): Observable<any> {
    let res$: Observable<any>;

    if (this.useCacheForData) {
      res$ = this.getCacheKey().pipe(
        switchMap((cacheKey) => {
          if (!cacheKey) throw new Error(`Invalid cache key for ${this.id}`);

          cacheKey += `/${id}`;
          return this.cacheSvc.getOrAdd(cacheKey, () => this.valueResolver(id));
        })
      );
    } else {
      res$ = this.valueResolver(id);
    }

    return res$.pipe(
      map((res) => {
        if (Array.isArray(res)) return res[0]; 
        else return res;
      })
    );
  }

  protected getDataByIds(ids: any[]): Observable<any[]> {
    let res$: Observable<any>;

    // TODO: We must add cache for multiple values
    // if (this.useCacheForData) {
    //   res$ = this.getCacheKey().pipe(
    //     switchMap((cacheKey) => {
    //       if (!cacheKey) throw new Error(`Invalid cache key for ${this.id}`);

    //       const cacheKeysMap = new Map<string, Observable<any>>();
    //       ids.forEach((id) => cacheKeysMap.set(id, this.cacheSvc.get(`${cacheKey}/${id}`)));

    //       const cacheKeys = Array.from(cacheKeysMap.keys());
    //       const cacheValues = Array.from(cacheKeysMap.values());

    //       return combineLatest(cacheValues).pipe(
    //         switchMap((results: any[]) => {

    //           const idsWithoutValue = [];
    //           for (let i = 0; i < results.length; i++) {
    //             if (!hasValue(results[i])) {
    //               idsWithoutValue.push(cacheKeys[i]);
    //             } else {

    //             }
    //           }

    //           return of(null).pipe(
    //             mergeMap(() => forkJoin([results, this.valueResolver(idsWithoutValue)])),
    //             tap((res) => {
    //               console.log(res);
    //             }),
    //             map(() => null)
    //           );
    //         })
    //       );
    //     })
    //   );
    // } else {
    //   res$ = this.valueResolver(ids);
    // }

    res$ = this.valueResolver(ids);
    return res$;
  }

  protected refreshDataSource(res: any[]): void {
    this.__dataSource$.next(res);
  }

  protected getCacheKey(): Observable<string> {
    if (isObservable(this.cacheKey)) return this.cacheKey; 
    else if (this.isFunction(this.cacheKey)) return of(this.cacheKey()); 
    else return of(this.cacheKey as string);
  }

  protected isFunction(value: any): value is CacheKeyFn {
    return value instanceof Function;
  }

  /* COLLAPSIBLE GROUPING */

  /*  Tip: lm-select2's virtualScroll property must be false plus groupByFn
      and groupValueFn should be given to collapsible grouping work properly */

  getAllChildNodes(elem): HTMLElement[] {
    let siblings = [];
    // Get the next sibling element
    elem = elem.nextElementSibling;

    while (elem) {
      // If we've reached next title group, bail
      if (elem.classList.contains('ng-optgroup')) break;

      // Otherwise, push elem to the siblings array
      siblings.push(elem);

      elem = elem.nextElementSibling;
    }
    return siblings;
  }

  hideChildOptions(): void {
    setTimeout(() => {
      // Get ng-options except group titles
      let clildOptions: any = document.getElementsByClassName('ng-option-child');
      for (let child of clildOptions) {
        child.classList.add('lm-display-none');
      }
    }, 50);
  }

  groupClicked(e): void {
    // Add - remove class open to group title
    let element: HTMLElement = e.target.parentNode;
    element.classList.toggle('open');

    // Arrows
    if (element.classList.contains('open')) {
      for (let i = 0; i < element.childNodes.length; i++) {
        let down: any = element.childNodes[2].childNodes[0];
        let up: any = element.childNodes[2].childNodes[1];

        down.style.display = 'none';
        up.style.display = 'block';
      }
    } // 
    else {
      for (let i = 0; i < element.childNodes.length; i++) {
        let down: any = element.childNodes[2].childNodes[0];
        let up: any = element.childNodes[2].childNodes[1];

        down.style.display = 'block';
        up.style.display = 'none';
      }
    }

    // Show - hide options
    let childrens = this.getAllChildNodes(element);
    childrens.forEach((child) => {
      child.classList.toggle('dispNone');
    });
  }

  /* COLLAPSIBLE GROUPING END */
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MessageModule,
    DropdownModule,
    TooltipModule,
    LmInputActionsModule,
    CheckboxModule,
    LmSelectorDisplayValueModule,
    LmSelectorOptionValueModule,
    LmColumnMetadataToGridColSizeModule,
    LmSelectorTemplatesModule,
    LmFilterOperatorModule
  ],
  exports: [LmSelect2Component, LmSelectorTemplatesModule],
  declarations: [LmSelect2Component]
})
export class LmSelect2Module {}
