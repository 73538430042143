import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { StopFormComponent } from '@app/forms/stop-form/stop-form.component';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { DeliveryFormComponent } from '@app/forms/delivery-form/delivery-form.component';
import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';

@Component({
    selector: 'app-stop-form-modal',
    templateUrl: './stop-form-modal.component.html',
    styleUrls: ['./stop-form-modal.component.scss']
})
export class StopFormModalComponent implements OnInit, OnDestroy {
    @ViewChild(StopFormComponent, { static: false }) stopFormComponent: StopFormComponent;
    @ViewChild(DeliveryFormComponent, { static: false }) deliveryFormComponent: DeliveryFormComponent;
    @ViewChild('modal', { static: false }) modal;

    updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
    loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';
    stopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/';

    modalOpen = false;

    projectProblemId; // is set in project view component
    projectProblemDepartureDatetime;
    entityStatus;
    listen = [];

    stopPointId = null;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        private viewProjectProblemService: ViewProjectProblemService,
        private draggableMarkerService: DraggableMarkerService,
        public router: Router,
        public genericService: GenericService,
    ) {
        this.listen.push(this.viewProjectProblemService.openStopFormModalListen().subscribe((noVoucher) => {
            if ((!this.globals.vouchersEnabled || noVoucher) && this.projectProblemId) {
                this.openModal();
            }
            else if (noVoucher && this.globals.collaboratorModeEnabled && this.router.url.includes('newShipmentsView')) {
                this.openModal();
            } else if (noVoucher && this.router.url.includes('stops')) {
                this.openModal();
            }
        }));
        this.listen.push(this.viewProjectProblemService.listenEditStopPoint().subscribe((data) => {
            // open voucher form if voucher are disabled or the SP doesn't have a voucher
            if (!this.globals.vouchersEnabled || !data.hasVoucher) {
                this.openModal();
                setTimeout(() => {
                    // this.stopFormComponent.tempProjectProblemId = data.tempProjectProblemId;
                    this.stopFormComponent.getFormData(data.stopPointId, data.projectProblemId);
                    this.viewProjectProblemService.closeStopModal();
                }, 100);
            }
        }));
        this.listen.push(this.viewProjectProblemService.createStopFromMapClickListen().subscribe((data) => {
            if (!this.viewProjectProblemService.isStopPointModalOpen) {
                if ((!data.helperOpen && !this.globals.vouchersEnabled) && this.projectProblemId) {
                    this.openModal();
                    setTimeout(() => {
                        this.stopFormComponent.createStopFromMapClick(data);
                    }, 100);
                }
            }
            // if modal is open, then just update the address in the current form
            else if (!data.forSameDayDelivery) {
                setTimeout(() => {
                    this.stopFormComponent.setAddressFromMapClick(data);
                }, 100);
            }
        }));
    }

    checkFormValidity() {
        this.stopFormComponent.isFormValid = false;

        // if delivery is activated, then check only the delivery's address for form validation
        if (this.stopFormComponent.serviceTypeRadioButtonSelected == 'delivery' || this.stopFormComponent.noDeliverySelected) {
            this.stopFormComponent.isFormValid = this.stopFormComponent.myForm.valid;
        }
        // if pickup (next OR same day) is activated, then check the address of delivery-form, too
        else if (this.stopFormComponent.myForm.valid && this.deliveryFormComponent.myForm.value.stopPoint.address.value) {
            this.stopFormComponent.isFormValid = true;
        }
    }

    dataLoadingListen(event) {
        if (event === 'true') {
            this.stopFormComponent.isClickedOnce = true;
        } else if (event === 'false') {
            this.stopFormComponent.isClickedOnce = false;
        }
    }

    expandModalListen(event) {
        if (event === 'true') {
            this.expandModal();
        } else if (event === 'false') {
            this.shrinkModal();
        }
    }

    toggleModalListen(event) {
        if (event === 'open') {
            this.openModal();
        } else if (event === 'close') {
            this.closeModal();
        }
    }

    public setStopFormData(projectProblemId, projectProblemDepartureDatetime, entityStatus) {
        this.projectProblemId = projectProblemId;
        this.projectProblemDepartureDatetime = projectProblemDepartureDatetime;
        this.entityStatus = entityStatus;
    }

    expandModal() {
        this.modal.nativeElement.classList.add('expanded');

    }

    shrinkModal() {
        this.modal.nativeElement.classList.remove('expanded');

    }

    openModal() {
        this.genericService.comm100ZIndexFix();
        this.modalOpen = true;
        this.viewProjectProblemService.isStopPointModalOpen = true;
        setTimeout(() => {
            this.stopFormComponent.setStopFormData(this.projectProblemId, this.projectProblemDepartureDatetime, this.entityStatus);
            this.deliveryFormComponent.setStopFormData(this.projectProblemId, this.projectProblemDepartureDatetime, this.entityStatus);
            const modal = document.querySelector('.stop-form-modal');
            const modalBackground = document.querySelector('.stop-form-modal-background');
            modal.classList.remove('closed');
            modal.classList.add('open');
            modalBackground.classList.remove('hidden');
            this.stopFormComponent.agreedShippingDateChanged = false;
        }, 100);
    }

    closeModal() {
        const modal = document.querySelector('.stop-form-modal');
        const modalBackground = document.querySelector('.stop-form-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.shrinkModal();
        this.stopFormComponent.resetForm();
        this.deliveryFormComponent.resetForm();
        this.draggableMarkerService.removeDraggableMarker();
        setTimeout(() => {
            this.modalOpen = false;
            this.viewProjectProblemService.isStopPointModalOpen = false;
        }, 1000);
    }

    getTranslations() { }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
