import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CsrfTokenInterceptor implements HttpInterceptor {

    constructor(
        private cookieService: CookieService,
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const csrfToken = this.cookieService.get('csrfToken');
        if (
            csrfToken &&
            (request.method === 'POST')
        ) {
            const authReq = request.clone({
                headers: request.headers.set('x-csrf-token', csrfToken)
            });
            return next.handle(authReq);
        } else {
            return next.handle(request);
        }
    }
}
