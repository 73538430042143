import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PreloadStopPointsService {

    possibleProjectProblemsListener = new Subject<any>()

    projectsUrl = 'api/v1/projects';
    projectProblemsUrl = 'api/v1/project/PROJECT_ID/problem';
    allStopPointsUrlOld = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points';
    allStopPointsUrl = 'api/internal/v2/project/problems/PROJECT_PROBLEM_ID/stop-points?pageSize=-1';

    stopPointsArrayByProjectProblemId = {};

    constructor(
        public http: HttpClient,
        private globals: Globals,
    ) { }

    listenForPossibleProjectProblems(): Observable<any> {
        return this.possibleProjectProblemsListener.asObservable();
    }

    // returns the ids of the PPs that are most possible to be loaded today
    // they are todays PPs
    getPossibleProjectProblems() {
        if (this.globals.isPreloadEnabled) {
            const today = moment().format('YYYY/MM');
            // const today = '2021/01';
            const tomorow = moment().add(1, 'days').format('YYYY/MM/DD');
            const possibleProjectProblemIds = [];
            this.getProjects().subscribe(projectsResponse => {
                const projectsCount = projectsResponse['items'].length;
                let projectsChecked = 0;
                projectsResponse['items'].forEach(projectData => {
                    const projectId = projectData.project.id;
                    this.getProjectProblems(projectId).pipe(take(1)).subscribe(projectProblemsResponse => {
                        projectsChecked++;
                        projectProblemsResponse['items'].forEach(projectProblemData => {
                            const projectProblemId = projectProblemData.projectProblem.id;
                            const projectProblemDepartureDatetime = projectProblemData.projectProblem.departure_datetime;
                            const projectProblemDate = moment(projectProblemDepartureDatetime).format('YYYY/MM');
                            if (projectProblemDate === today || projectProblemDate === tomorow) {
                                possibleProjectProblemIds.push(projectProblemId);
                            }
                        });
                        if (projectsChecked === projectsCount) {
                            this.possibleProjectProblemsListener.next(possibleProjectProblemIds);
                        }
                    });
                });
            });
            return possibleProjectProblemIds;
        }
    }

    loadPossibleProjectProblemsStopPoints() {
        if (this.globals.isPreloadEnabled) {
            this.getPossibleProjectProblems();
            this.listenForPossibleProjectProblems().pipe(take(1)).subscribe(possibleProjectProblemIds => {
                possibleProjectProblemIds.forEach(projectProblemId => {
                    const allStopPointsUrl = this.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', projectProblemId);
                    this.http.get(allStopPointsUrl).pipe(take(1)).subscribe(stopPointsResponse => {
                        this.stopPointsArrayByProjectProblemId[projectProblemId] = stopPointsResponse['items']['stopPoints'];
                    });
                });
            });
        }
    }

    getProjectProblems(projectId) {
        const params = '?pageSize=3&page=0'
        const projectProblemsUrl = this.projectProblemsUrl.replace('PROJECT_ID', projectId) + params;
        return this.http.get(projectProblemsUrl);
    }

    getProjects() {
        return this.http.get(this.projectsUrl);
    }

}