import { AfterViewInit, Component, NgModule, OnDestroy } from '@angular/core';
import { LmDialogContext } from '@app/model/dialog';

@Component({
  template: `
    <lm-dialog [modal]="true" label="{{ 'TRANSPORTATION_REGIONS.NEW' | translate }}" [closable]="true" [dismissableMask]="true" [style]="{ width: '70vw', maxWith: '600px', height: '80vh' }">
      <ng-template lmTemplate="body">
        <div class="grid lm-pdt40">
          <lm-calendar
              [timeOnly]="false"
              [inline]="true"
              [selectionMode]="'range'"
              [(ngModel)]="dates"
              [ngStyle]="{'width': '100%'}"
          ></lm-calendar>
        </div>
      </ng-template>

    <ng-template lmTemplate="footer">
      <lm-button [config]="okBttn" (click)="okBttn.command()"></lm-button>
    </ng-template>
  </lm-dialog>
  `
})
export class LmCalendarComponent implements AfterViewInit, OnDestroy {
  VM;
  dates: Date[];
  okBttn = {label: 'OK', command: () => this.onOkClick()};

  constructor(
    public config: LmDialogContext<any>,
  ) {
    this.VM = config.data.VM;
  }

  onOkClick() {
    let start = this.VM.Period.formatter.formatDateForUI(this.dates[0]);
    start = Object.assign({}, this.VM.Period.formatter.convertDateForAPI(start.UIdate));

    let end = this.VM.Period.formatter.formatDateForUI(this.dates[1]);
    end = Object.assign({}, this.VM.Period.formatter.convertDateForAPI(end.UIdate));

    const daysBefore = this.VM.Period.formatter.countDaysBetween(start.APIdate, end.APIdate);

    this.VM.query = {
      ...this.VM.query,
      untilDateTime: end.APIdate,
      daysBeforeUntilDateCount: daysBefore,
    }
    this.VM.getAll$(this.VM.query);
    this.config.close(true);
  }

  ngAfterViewInit() {}
  ngOnDestroy() {}
}
