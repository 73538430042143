import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { LmSelectColumnDefs } from '../../model/select-column-defs';

@Pipe({
  name: 'lmColumnMetadataToGridColSize'
})
export class LmColumnMetadataToGridColSizePipe implements PipeTransform {
  constructor() {}

  transform(columnMetadata: LmSelectColumnDefs[]): string {
    return columnMetadata.map((p) => p.width || '1fr').join(' ');
  }
}

@NgModule({
  imports: [],
  exports: [LmColumnMetadataToGridColSizePipe],
  declarations: [LmColumnMetadataToGridColSizePipe]
})
export class LmColumnMetadataToGridColSizeModule {}
