export function priceListModalSetup(parent, translations){
    const tableItemDropDownOptions = [
        {value: translations['UP_TO'], label: translations['UP_TO'], is_additional: false}, 
        {value: translations['FOR_EVERY'], label: translations['FOR_EVERY'], is_additional: true}
    ]
    return{
        tableItemDropDownOptions: tableItemDropDownOptions,
        dropDownEditorTableCol:{
            header:translations['TYPE'], field: 'type', cellEditor: 'dropDown', dropDownOptions: tableItemDropDownOptions, cellClass: 'lm-price-list-table-operator-cell'
        },
        weightTableCols: [
            { header:translations['TYPE'], field: 'type', cellClass: 'lm-price-list-table-operator-cell'},
            { header:translations['QUANTITY'], field: 'charge_value', cellEditor: 'inputNumber', kilograms: true, cellClass: 'lm-price-list-table-charge-cell' },
            { header:translations['CHARGE'], field: 'price_value', cellEditor: 'inputNumber', currency: true, cellClass: 'lm-price-list-table-price-cell' }
        ],
        piecesTableCols: [
            { header:translations['TYPE'], field: 'type', cellClass: 'lm-price-list-table-operator-cell'},
            { header:translations['QUANTITY'], field: 'charge_value', cellEditor: 'inputNumber', cellClass: 'lm-price-list-table-charge-cell' },
            { header:translations['CHARGE'], field: 'price_value', cellEditor: 'inputNumber', currency: true, cellClass: 'lm-price-list-table-price-cell' }
        ],
        distanceTableCols: [
            { header:translations['TYPE'], field: 'type', cellClass: 'lm-price-list-table-operator-cell'},
            { header:translations['QUANTITY'], field: 'charge_value', cellEditor: 'inputNumber', cellClass: 'lm-price-list-table-charge-cell' },
            { header:translations['CHARGE'], field: 'price_value', cellEditor: 'inputNumber', currency: true, cellClass: 'lm-price-list-table-price-cell' }
        ],
        serviceTableCols: [
            { header:translations['NAME'], field: 'name', cellClass: 'lm-price-list-table-name-cell'},
            { header:translations['CHARGE'], field: 'value', cellEditor: 'inputNumber', currency: true, cellClass: 'lm-price-list-table-price-cell' }
        ],
        saveBttn: {
            label: translations['SAVE'],
            command: () => parent.ok(),
            styleClass:'lm-bg-color-mily'
        },
        duplicateBttn: {
            label: translations['SAVE_AND_NEW'], 
            command: () => parent.ok(true), 
            styleClass:'lm-bg-dark-color-mily'
        },
        newRowBtn: {
            label: translations['NEW_LINE'], 
            command: (table)=> parent.promptNewRow(table),
            textOnly: true,
            icon: 'pi pi-plus',
            small: true,
            styleClass: 'lm-txt-color-mily'
        },
        newServiceRowBtn: {
            label: translations['NEW_LINE'], 
            command: ()=> parent.promptNewServiceRow(),
            textOnly: true,
            icon: 'pi pi-plus',
            small: true,
            styleClass: 'lm-txt-color-mily'
        },
        addRowBtn: {
            label: translations['ADD'], 
            command: (table)=> parent.addNewRow(table),
            textOnly: true,
            icon: 'pi pi-check',
            small: true,
            styleClass: 'lm-txt-color-mily'
        },
        addServiceRowBtn: {
            label: translations['ADD'], 
            command: ()=> parent.addNewServiceRow(),
            textOnly: true,
            icon: 'pi pi-check',
            small: true,
            styleClass: 'lm-txt-color-mily'
        },
        cancelRowBtn: {
            label: translations['CANCEL'], 
            command: (table)=> parent.cancelNewRow(table),
            textOnly: true,
            icon: 'pi pi-times',
            small: true,
            styleClass: 'lm-txt-color-mily'
        }
    }
}