import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GuideTourService {

    private triggerRightClickListeners = new Subject<any>();
    private focusOnMarkerListeners = new Subject<any>();
    private clearMapFromTourListeners = new Subject<any>();

    triggerRightClickListen(): Observable<any> {
        return this.triggerRightClickListeners.asObservable();
    }

    focusOnMarkerListen(): Observable<any> {
        return this.focusOnMarkerListeners.asObservable();
    }

    clearMapFromTourListen(): Observable<any> {
        return this.clearMapFromTourListeners.asObservable();
    }

    triggerRightClick(id) {
        this.triggerRightClickListeners.next(id);
    }

    focusOnMarker(id) {
        this.focusOnMarkerListeners.next(id);
    }

    clearMapFromTour() {
        this.clearMapFromTourListeners.next(1);
    }

    generateMilyMessage(text) {
        return '<span class="bold-letters">Mily:</span> ' + text;
    }

}
