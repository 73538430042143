import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, NgModule, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FILTER_OPERATORS, FILTER_OPERATORS_EN, FILTER_OPERATORS_DE, LmFilterOperatorId, LmFilterOperatorInfo } from '../../../model/operator-types';
import { hasValue } from '../../utils';

@Component({
  selector: 'lm-filter-operator',
  templateUrl: './filter-operator.component.html',
  styleUrls: ['./filter-operator.component.scss']
})
export class LmFilterOperatorComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() operators: LmFilterOperatorId[];
  @Input() operator: LmFilterOperatorId;
  @Input() disabled: boolean;

  @Output() operatorChange = new EventEmitter<LmFilterOperatorId>();

  operatorsData: LmFilterOperatorInfo[];
  currentOperator: LmFilterOperatorInfo;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const operatorChange = changes['operator'];
    if (!!operatorChange) {
      this.refreshCurrentOperator(operatorChange.currentValue);

      if (operatorChange.isFirstChange()) {
        this.operatorChange.next(operatorChange.currentValue);
      }
    }

    if (changes['operators']) {
      this.refreshOperatorsData(changes['operators'].currentValue);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  refreshCurrentOperator(operatorId: LmFilterOperatorId) {
    if (!hasValue(operatorId)) {
      this.currentOperator = null;
    } else {
      this.currentOperator = FILTER_OPERATORS.find((p) => p.id === operatorId);
    }
  }

  refreshOperatorsData(operatorIds: LmFilterOperatorId[]) {
    if (!operatorIds?.length) {
      this.operatorsData = [];
    } else {
      this.operatorsData = FILTER_OPERATORS.filter((p) => operatorIds.indexOf(p.id) >= 0);
    }
  }

  modelChanged(e: LmFilterOperatorInfo): void {
    this.currentOperator = e;
    this.operatorChange.next(e?.id);
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DropdownModule],
  exports: [LmFilterOperatorComponent],
  declarations: [LmFilterOperatorComponent]
})
export class LmFilterOperatorModule {}
